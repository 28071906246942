function range(start, stop, step) {
  if (typeof stop == 'undefined') {
    // one param defined
    stop = start;
    start = 0;
  }

  if (typeof step == 'undefined') {
    step = 1;
  }

  if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
    return [];
  }

  var result = [];
  for (var i = start; step > 0 ? i < stop : i > stop; i += step) {
    result.push(i);
  }

  return result;
}

export const TESTS = [
  // order tests with newly date order
  // loveWeight
  {
    info: {
      mainTitle: '00 연애 체중 테스트',
      subTitle: '연인을 향한 내 애정 체중은 몇 kg일까?',
      mainUrl: 'loveWeight',
      scoreType: 'typeCountingMBTIName',
      mainImage: 'https://images.ktestone.com/introImages/loveWeight-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveWeight-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
      nameLocation: {
        intro: {
          font: 'Noto Sans',
          fontSize: 1.4,
          bottom: 8,
          pcFontSize: 2,
          pcBottom: 7,
        },
        result: {
          font: 'Noto Sans',
          fontSize: 2.7,
          fontColor: '#535258',
          result: 5.8,
          left: 6,
          pcFontSize: 5,
          pcResult: 10,
          pcLeft: 10.5,
        },
      },
    },
    questions: [
      {
        which: 'EI',
        question: '평소 연인을 만나 데이트할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '내게 있었던 일이나 일과를 조잘조잘 떠듦',
          },
          {
            type: 'I',
            score: 5,
            content: '연인이 말해주는 얘기를 주의 깊게 들음',
          },
        ],
      },
      {
        which: 'EI',
        question: '최근 다이어트 성공으로 몸무게가 빠졌다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '주변 친구들에게 자랑하며 칭찬해달라고 함',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자 조용히 뿌듯해하며 내 자신을 칭찬함',
          },
        ],
      },
      {
        which: 'EI',
        question: '연인에게 서운했던 마음이 풀리는 때는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `직접 만나서 얼굴을 보고 대화로 털어놓을 때`,
          },
          {
            type: 'I',
            score: 5,
            content: `생각을 정리하고 장문의 카톡을 보낸 후 만족스러운 답변이 왔을 때`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '연인을 향한 사랑의 무게를 재주는 체중계가 출시되었다고 한다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `말이 안 되는 데? 직접 보기 전까지는 못 믿겠어`,
          },
          {
            type: 'N',
            score: 5,
            content: `너무 신기해! 우리 러브 무게는 몇 나올까? 분명 무겁겠지?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '사랑의 무게를 재주는 체중계에 올라갈 땐 연인의 별명을 불러야 한다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '굳이 별명을? 이해는 안 가지만 일단 하라는 대로 해보자?',
          },
          {
            type: 'N',
            score: 5,
            content: `왜 별명을? 궁금한데 이유를 알고 하고 싶어! `,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인과 함께 할 새로운 취미를 찾아보는 중!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `잘 알고 있는 분야나 재미를 느낄 확률이 높은 분야 위주로 찾음`,
          },
          {
            type: 'N',
            score: 5,
            content: `해보지 않은 새로운 분야 위주로 찾음`,
          },
        ],
      },
      {
        which: 'TF',
        question: `‘요즘 살이 좀 쪄 보인다?’라는 말을 연인에게 들으면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '그치 좀 찐 거 같아 다이어트해야겠다',
          },
          {
            type: 'F',
            score: 5,
            content: '살 많이 쪄 보여.? 그래서 별로야..?',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '다이어트하는 연인과 식사 중 혼자만 닭가슴살을 꺼내 먹는다면!',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `어쩔 수 없다고 생각하며 응원해 줌`,
          },
          {
            type: 'F',
            score: 5,
            content: '혼자만 맛있는 걸 못 먹는 게 안타까움',
          },
        ],
      },
      {
        which: 'TF',
        question: `‘다이어트해야 해서 우울하다.. 요즘 다이어트 식품들이 더 비싼 거 같아’라는 연인에게 내 대답은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '내가 맛있고 가성비 좋은 걸로 찾아 줄까?',
          },
          {
            type: 'F',
            score: 5,
            content: '다이어트 힘들지ㅜㅜ 그래도 잘 챙겨 먹고 건강 신경 써!',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '누군가 내 체중계를 빌려 가 놓고 원래 있던 자리에 두지 않는다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `왜 원래 있던 자리에 놓지 않는지 이해가 안 가고 불편함`,
          },
          {
            type: 'P',
            score: 5,
            content: `지금 놓은 그 자리가 원래 자리라 상관없음`,
          },
        ],
      },
      {
        which: 'JP',
        question: '내일 연인과 함께 집 앞 한강에서 러닝을 뛰기로 했다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '여기서부터 여기까지 가면 한 시간 정도 걸리고 돌아올 땐 이렇게~',
          },
          {
            type: 'P',
            score: 5,
            content: '여기까지 목표이긴 한데 중간에 힘들면 돌아오자!',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 다이어트를 한다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '목표와 기간을 확실하게 정해놓고 맞춰서 감량하지 못하면 스트레스 받음',
          },
          {
            type: 'P',
            score: 5,
            content: '원하는 날짜 안에 감량하지 못하면 자율적으로 기간을 늘림',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/loveWeight/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/loveWeight/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/loveWeight/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/loveWeight/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/loveWeight/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/loveWeight/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/loveWeight/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/loveWeight/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/loveWeight/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/loveWeight/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/loveWeight/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/loveWeight/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/loveWeight/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/loveWeight/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/loveWeight/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/loveWeight/INFP.jpg',
      },
    ],
  },
  // korDictChar
  {
    info: {
      mainTitle: '국어사전 성격 테스트',
      subTitle: '국어사전에 검색된 내 성격은 어떨까?',
      mainUrl: 'korDictChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/korDictChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/korDictChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '내 마음과 생각의 움직임은 어떨까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '내면의 움직임이 적극적이며 밖으로 나타남',
          },
          {
            type: 'I',
            score: 5,
            content: '내면의 움직임이 주로 자신에게 향함',
          },
        ],
      },
      {
        which: 'EI',
        question: '국어사전에 등록된 내 성격 풀이가 꽤 재미있다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '재밌네? 친구들도 해보라고 공유해야지',
          },
          {
            type: 'I',
            score: 5,
            content:
              '재밌다~ 내 풀이 알리긴 부끄러우니까 애들 것도 내가 검색해 봐야지',
          },
        ],
      },
      {
        which: 'EI',
        question: '어떤 문제가 발생하면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `문제를 빠르게 해결하고 싶어서 논의할 시간을 원함`,
          },
          {
            type: 'I',
            score: 5,
            content: `문제에 대해 충분히 생각할 수 있는 시간을 원함`,
          },
        ],
      },
      {
        which: 'SN',
        question: '사전 두께의 흥미로운 소설을 읽을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `인물이나 사건을 중심으로 몰입하며 앞으로 쭉쭉 읽는다`,
          },
          {
            type: 'N',
            score: 5,
            content: `전체적인 줄거리 파악을 위해 앞을 들춰 내용을 다시 읽는다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '길에 누군가 공부한 흔적이 있는 국어사전이 떨어져 있다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '누가 길에 저런 걸 버려놨지',
          },
          {
            type: 'N',
            score: 5,
            content: `누가 뛰어가나 떨어뜨렸나 봐! 근데 사전 공부를 왜 하지?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '사전에서 본 신기한 단어에 대해 설명하려고 한다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `구체적인 뜻풀이와 사전에 나와있는 근거를 들어 설명함`,
          },
          {
            type: 'N',
            score: 5,
            content: `단어를 봤을 때 들었던 신기한 느낌에 대해 비유를 활용해 설명함`,
          },
        ],
      },
      {
        which: 'TF',
        question: `전에 얘기했던 내용과는 다른 말로 얼버무리는 친구!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '너 전엔 이렇게 말했었는데? 왜 말이 달라? (순수한 질문)',
          },
          {
            type: 'F',
            score: 5,
            content:
              '(전엔 그렇게 말 안 했는데 굳이 말하면 무안하겠지) 아 그래?',
          },
        ],
      },
      {
        which: 'TF',
        question: '내가 매력을 느끼는 분야를 고르라면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `기술, 시스템, 데이터, 과학, 역사 등`,
          },
          {
            type: 'F',
            score: 5,
            content: '심리학, 사회 복지, 소설, 미술 등',
          },
        ],
      },
      {
        which: 'TF',
        question: `‘머리는 차갑게 가슴은 뜨겁게’ 라는 말을 들으면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '머리는 차가운데 몸이 뜨거울 수가 있나',
          },
          {
            type: 'F',
            score: 5,
            content: '머리 차갑게 하는 건 좀 어려운데.. 가슴은 뜨겁게는 인정!',
          },
        ],
      },
      {
        which: 'JP',
        question: '국어사전에 단어를 검색할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `필요한 단어만을 정리해 정확하게 검색하고 꼼꼼히 잃음`,
          },
          {
            type: 'P',
            score: 5,
            content: `검색 중 흥미로운 다른 단어들을 발견하면 자연스럽게 살펴 봄`,
          },
        ],
      },
      {
        which: 'JP',
        question: '내게 여유로운 시간은 언제 생긴 걸까?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '할 일을 일찍 끝내고 나서 남은 시간',
          },
          {
            type: 'P',
            score: 5,
            content: '할 일을 뒤로 미뤄서 비어버린 시간',
          },
        ],
      },
      {
        which: 'JP',
        question: '계획을 모두 짜놓고 안 지키는 사람을 보면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '계획 짜놓고 안 지키는 건 게으른 거지',
          },
          {
            type: 'P',
            score: 5,
            content: '계획을 짠 것 만으로도 부지런한 거 같은데?',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/korDictChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/korDictChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/korDictChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/korDictChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/korDictChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/korDictChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/korDictChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/korDictChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/korDictChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/korDictChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/korDictChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/korDictChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/korDictChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/korDictChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/korDictChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/korDictChar/INFP.jpg',
      },
    ],
  },
  // idealTypeGraphJP
  {
    info: {
      mainTitle: '理想のタイプグラフテスト',
      subTitle: 'あなたの理想のタイプは？グラフで調べよう！',
      mainUrl: 'idealTypeGraphJP',
      scoreType: 'typeCountingMBTIName',
      mainImage:
        'https://images.ktestone.com/introImages/idealTypeGraphJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/idealTypeGraphJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'characteristic',
      nameLocation: {
        intro: {
          font: 'Noto Sans',
          fontSize: 1.2,
          bottom: 5.2,
          pcFontSize: 2,
          pcBottom: 4,
        },
        result: {
          font: 'Noto Sans',
          fontSize: 1,
          fontColor: '#848484',
          result: 2.8,
          left: 5,
          pcFontSize: 2,
          pcResult: 4.9,
          pcLeft: 8,
        },
      },
    },
    questions: [
      {
        which: 'EI',
        question: '友達から異性の相手を紹介されることに！そのときの私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `どんな人か楽しみ！`,
          },
          {
            type: 'I',
            score: 5,
            content: `緊張して何を話せばいいかわからない…`,
          },
        ],
      },
      {
        which: 'EI',
        question:
          '実際会話してみると自分と合う人だと感じた！そのとき、どう行動する？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `相手に伝わるように積極的に好意を表す`,
          },
          {
            type: 'I',
            score: 5,
            content: `好意を表すことや行動に出すことは絶対にしない`,
          },
        ],
      },
      {
        which: 'EI',
        question:
          '紹介相手が「もし付き合ったら、私に望むことはありますか？」と聞いてきたら？',
        answers: [
          {
            type: 'I',
            score: 2,
            content: `ひとりの時間を大切にしてほしいです。`,
          },
          {
            type: 'E',
            score: 5,
            content: `毎日は無理でも、できるだけ頻繁に会いたいかな。`,
          },
        ],
      },
      {
        which: 'SN',
        question: '初めてデートに誘われた！そのとき、私の心の声は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `私に好意があるのかも。`,
          },
          {
            type: 'N',
            score: 5,
            content: `会ってすぐに告白されてカップル成立！？`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '一緒に歩いていたら、相手から「ここ、だいぶ変わったな」と独り言が聞こえてきた！そのとき、私の心の声は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `前にも来たことがあるのかな？`,
          },
          {
            type: 'N',
            score: 5,
            content: `ここに住んでたのかな？それとも、前の恋人と来たことあるとか。`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '紹介してくれた友達から、相手のことをどう思っているか聞かれた！どう答える？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `～～について話したけど、～～なところが気に入って、～～なところが印象的だった。`,
          },
          {
            type: 'N',
            score: 5,
            content: `初めて会ったときは～～な感じだったけど、今は〜～かな。`,
          },
        ],
      },
      {
        which: 'TF',
        question:
          '紹介相手が初めて会ったときに話したことを覚えてくれてプレゼントを用意してくれた！そのときの私は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `すごい記憶力ですね！ありがとうございます。`,
          },
          {
            type: 'F',
            score: 5,
            content: `まさか覚えてくれていたなんて…感動です(涙)`,
          },
        ],
      },
      {
        which: 'TF',
        question:
          'Instagramを見ていたら、相手の過去の恋人っぽい人を見つけた！そのときの私は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `今の交際相手は私だし関係ないけど、気になるから削除してもらおう。`,
          },
          {
            type: 'F',
            score: 5,
            content: `なんでまだ消してないの？気づいていないだけかもしれないけど、ちょっと寂しいかな。`,
          },
        ],
      },
      {
        which: 'TF',
        question:
          '紹介相手が過去の恋愛で辛い経験をしたと話してくれた！私の心の声は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `辛かったと思うけど、その経験から学んだこともありそう。やっぱり、この人のこともっと知りたいかも。`,
          },
          {
            type: 'F',
            score: 5,
            content: `辛い経験なのに話してくれてありがたいし、もっと側で支えていきたい。`,
          },
        ],
      },
      {
        which: 'JP',
        question: '突然ドライブに行こうと誘われたら？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `突然すぎるし、無計画なところが気に入らない。`,
          },
          {
            type: 'P',
            score: 5,
            content: `フットワークの軽さに興味を感じる。`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          '玄関を出ようとした瞬間「今日雨が降るかもしれないから傘を持ってきて」といわれた！そのとき、私はどうする？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `すぐに天気予報を確認して小さな傘をバッグに入れる`,
          },
          {
            type: 'P',
            score: 5,
            content: `天気予報は当たらないこともあるし、とりあえず外に出てから考える`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          '紹介相手と楽しい時間を過ごしていたら終電の時間に…名残惜しいと思った私、どうする？ ',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `もっと一緒にいたいけど仕方がない。今日は帰るとする。`,
          },
          {
            type: 'P',
            score: 5,
            content: `帰りはなんとかなるし、今を楽しむことにする`,
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: '',
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraphJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: '',
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraphJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: '',
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraphJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: '',
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraphJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: '',
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraphJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: '',
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraphJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: '',
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraphJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: '',
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraphJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: '',
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraphJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: '',
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraphJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: '',
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraphJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: '',
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraphJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: '',
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraphJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: '',
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraphJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: '',
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraphJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: '',
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraphJP/INFP.jpg',
      },
    ],
  },
  // idealTypeGraph
  {
    info: {
      mainTitle: '이상형 그래프 테스트',
      subTitle: '내 이상형이 어떨지 궁금하다면, 그래프를 분석해 보자!',
      mainUrl: 'idealTypeGraph',
      scoreType: 'typeCountingMBTIName',
      mainImage:
        'https://images.ktestone.com/introImages/idealTypeGraph-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/idealTypeGraph-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
      nameLocation: {
        intro: {
          font: 'Noto Sans',
          fontSize: 1.2,
          bottom: 5.2,
          pcFontSize: 2,
          pcBottom: 4,
        },
        result: {
          font: 'Noto Sans',
          fontSize: 1.7,
          fontColor: '#848484',
          result: 2.7,
          left: 6,
          pcFontSize: 3.1,
          pcResult: 4.8,
          pcLeft: 10,
        },
      },
    },
    questions: [
      {
        which: 'EI',
        question: '친구를 통해 새로운 인연을 소개받게 되었다! 이때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '어떤 사람일까? 알아가는 재미가 있을 것 같아',
          },
          {
            type: 'I',
            score: 5,
            content: '긴장되네 무슨 말을 해야 할지 벌써부터 머리가 새하얘',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '대화를 나눠보니 나와 잘 맞는 사람이라고 느껴졌다! 이럴 때 어떻게 행동하는 게 좋을까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '상대방이 알 수 있도록 적극적으로 호감을 드러냄',
          },
          {
            type: 'I',
            score: 5,
            content: '절대 티를 내지 않고 처음 보여준 모습 그대로 행동함',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '소개팅 상대가 “만약 사귀게 된다면 내게 바라는 게 있어요?”라고 물어봤다면?',
        answers: [
          {
            type: 'I',
            score: 2,
            content: `혼자만의 시간은 존중해 주셨으면 좋겠어요.`,
          },
          {
            type: 'E',
            score: 5,
            content: `매일은 아니더라도 자주 만났으면 좋겠어요.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '소개팅 후 애프터 신청을 받았다! 내 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `나에 대한 호감이 있으셨구나`,
          },
          {
            type: 'N',
            score: 5,
            content: `만나자마자 고백받아서 연인으로 발전하는 거 아냐?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '소개팅 상대와 길을 걷던 중 옆에서 “여기 많이 바뀌었네”라는 혼잣말이 들려온다! 내 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '이 동네에 예전에 온 적이 있나 보네~',
          },
          {
            type: 'N',
            score: 5,
            content: `바뀌었네? 여기 살았었나? 아니면 예전 애인이랑 왔나?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '주선자에게서 소개팅 상대에 대해 내 의견을 묻는 연락이 왔다! 그때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `이 사람과 이런 대화를 했는데 무슨 점이 좋았고 이러한 점이 인상 깊었어`,
          },
          {
            type: 'N',
            score: 5,
            content: `첫 만남에서는 이런 느낌이었는데 지금은 ~으로 변하게 된 것 같아`,
          },
        ],
      },
      {
        which: 'TF',
        question: `소개팅 상대가 첫 만남에서 가볍게 언급했던 것을 기억하고 선물을 준비해 왔다! 이때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '기억력 진짜 좋으시네요! 너무 감사해요.',
          },
          {
            type: 'F',
            score: 5,
            content: '이걸 기억해 주셨다니 감동이에요ㅠㅠ',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'Instagram을 탐색하다가 과거 연인의 흔적을 발견하였다! 이때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `지금 만나고 있는 사람은 나니까 상관은 없지만 거슬리니까 삭제해 달라고 해야지`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '이걸 왜 삭제를 안 했지? 잊어버린 것일 수도 있지만 서운한 마음이 들어',
          },
        ],
      },
      {
        which: 'TF',
        question: `소개팅 상대가 전 연애에서 아픈 경험이 있었다는 것을 털어놓았다! 내 생각은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '안타깝지만, 그 경험에서 배운 점도 있을 것 같네 이 사람을 더 알아가고 싶어',
          },
          {
            type: 'F',
            score: 5,
            content:
              '찡하고 아픈 경험인데 나에게 말해줘서 고마운 생각이 들고 옆에서 더 챙겨주고 싶어',
          },
        ],
      },
      {
        which: 'JP',
        question: '갑작스럽게 무계획으로 드라이브를 가자고 한다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `예상치 못한 드라이브와 무계획이라는 점이 마음에 안 듦`,
          },
          {
            type: 'P',
            score: 5,
            content: `즉흥적으로 움직이는 것에 흥미를 느낌`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          "현관문을 나서려는 순간 '오늘 비 올 수도 있으니까 우산 챙겨”라는 말을 들었다! 이때 나는?'",
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '바로 핸드폰으로 일기예보를 확인하고 조그만 우산을 가방에 챙긴다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '비 올 수도 있다는 건 안 올 수도 있는 가능성이 있으니 일단 나가서 생각한다.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '소개팅 상대와 즐거운 시간을 보내다 보니 막차 시간이 되었지만 아쉬운 마음이 든다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '아쉽지만 다음을 기약하며 집으로 돌아간다.',
          },
          {
            type: 'P',
            score: 5,
            content: '막차 시간 일은 나중에 생각하고 지금을 더 즐기기로 한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraph/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraph/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraph/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraph/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraph/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraph/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraph/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraph/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraph/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraph/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraph/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraph/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraph/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraph/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraph/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeGraph/INFP.jpg',
      },
    ],
  },
  // scaryGhostChar
  {
    info: {
      mainTitle: '으스스 유령 성격 테스트',
      subTitle: '만약 유령이 된다면? 성격은 어떻게 될까?',
      mainUrl: 'scaryGhostChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/scaryGhostChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/scaryGhostChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '유령 세계를 향한 특별한 버스! 어떤 자리에 앉게 될까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '여러 사람이 마주 보면서 수다를 떨며 갈 수 있는 자리!',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자만의 시간을 보내며 조용히 갈 수 있는 자리!',
          },
        ],
      },
      {
        which: 'EI',
        question: '두 팀으로 나뉘어 움직인다고 한다! 이때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '대규모 팀',
          },
          {
            type: 'I',
            score: 5,
            content: '소수 정예 팀',
          },
        ],
      },
      {
        which: 'EI',
        question: '유령 세계에 입성하니 텅텅 빈 마을이 보인다! 이때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `너무 텅 빈 거 아닌가.. 밖에서 놀 것도 없겠네`,
          },
          {
            type: 'I',
            score: 5,
            content: `평온하고 고요하게 살 수 있겠네 마음에 들어!`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '유령 세계 입구 앞에 많은 양의 무지개 물약이 보인다! 이때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `물약이 많은 걸 보니 가져가라고 내버려뒀나 본데?`,
          },
          {
            type: 'N',
            score: 5,
            content: `함정일 수도 있으니 챙기지 않겠어`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '유령 세계 안내원이 “입주해 보니 어때요?”라고 물어봤다! 이때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '세부적인 감상을 이야기함',
          },
          {
            type: 'N',
            score: 5,
            content: `전체적인 감상을 이야기함`,
          },
        ],
      },
      {
        which: 'SN',
        question: '유령이 된 첫날! 이때 내 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `내가 죽었나 보네`,
          },
          {
            type: 'N',
            score: 5,
            content: `역시 유령은 존재했어!`,
          },
        ],
      },
      {
        which: 'TF',
        question: `유령 친구들이 당신을 가운데 두고 싸우기 시작했다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜 날 중간에 두고.. 다른 곳 가서 싸우라고 말해야지',
          },
          {
            type: 'F',
            score: 5,
            content: '누굴 편들어야 하는 거지? 싸움은 나빠 애들아 ㅠㅠ',
          },
        ],
      },
      {
        which: 'TF',
        question: '유령 세계 게시판에 당신의 흑역사가 폭로되었다! 이때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `흑역사를 어떻게 알아서 폭로한 거지? 누가?`,
          },
          {
            type: 'F',
            score: 5,
            content: '갑자기 내 흑역사는 왜…창피해 ㅠ',
          },
        ],
      },
      {
        which: 'TF',
        question: `잘못된 행동으로 인해 소멸되고 있는 유령을 보았다! 이때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '잘못을 저질러 벌을 받는 것은 당연해',
          },
          {
            type: 'F',
            score: 5,
            content: '큰 잘못인가? 아무리 그래도 소멸은 심한 것 같아',
          },
        ],
      },
      {
        which: 'JP',
        question: '경쟁률이 치열한 예약에 대비하는 자세는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `내게 실패는 없어! 더 철저하게 준비해야지`,
          },
          {
            type: 'P',
            score: 5,
            content: `일단 노려보고 실패하면 어쩔 수 없지`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          '유령 세계에서 안내원을 맡게 되었다! 이때 준비해야 할 게 뭐가 있을까?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '미리 알고 가면 좋지 않을까? 주변 유령들에게 안내원 일을 물어봐야지',
          },
          {
            type: 'P',
            score: 5,
            content: '음.. 미리 준비해야 할까? 당일에 잘 배워서 일해야지',
          },
        ],
      },
      {
        which: 'JP',
        question: '중요한 일정을 하루 앞두고 몸 상태가 안 좋아졌다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '중요한 일정을 이렇게 날릴 수는 없어 내일까지 몸 관리해야지',
          },
          {
            type: 'P',
            score: 5,
            content:
              '몸이 갑작스럽게 아파진 건 어쩔 수 없으니 내일 나아지길 기도해야지',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/scaryGhostChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/scaryGhostChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/scaryGhostChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/scaryGhostChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/scaryGhostChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/scaryGhostChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/scaryGhostChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/scaryGhostChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/scaryGhostChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/scaryGhostChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/scaryGhostChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/scaryGhostChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/scaryGhostChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/scaryGhostChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/scaryGhostChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/scaryGhostChar/INFP.jpg',
      },
    ],
  },
  // insigniaEng
  {
    info: {
      mainTitle: 'Insignia Test',
      subTitle: 'What is the insignia that matches my personality?',
      mainUrl: 'insigniaEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/insigniaEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/insigniaEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          'What if you were asked to be interviewed in front of a lot of people?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `It feels good to receive attention, so I respond right away without hesitation.`,
          },
          {
            type: 'I',
            score: 5,
            content: `It feels good to receive attention, so I respond right away without hesitation.`,
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What if you were caught on an electronic display camera while watching the performance?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I wave my hands around excitedly`,
          },
          {
            type: 'I',
            score: 5,
            content: `I quickly lower my head in surprise to avoid being caught on camera`,
          },
        ],
      },
      {
        which: 'EI',
        question:
          "What if there's a lot of noise between floors and you complain to your upper house?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I am ashamed to meet and talk with strangers, so I protest timidly with a note`,
          },
          {
            type: 'I',
            score: 5,
            content: `I lead my close friend up and confidently make a complaint`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'When you come up with an idea?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I think about the practical part first`,
          },
          {
            type: 'N',
            score: 5,
            content: `I first imagine various possibilities`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What if a friend talks about an unknown unknown world?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Is there really such a place?`,
          },
          {
            type: 'N',
            score: 5,
            content: `Wouldn't there be a lot of strange things and creatures in such places?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'If all the nostalgic old stores have been replaced by new ones?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `It seems they vanished due to the changing times`,
          },
          {
            type: 'N',
            score: 5,
            content: `Give back my memories! This was a stationery store, this was a bakery`,
          },
        ],
      },
      {
        which: 'TF',
        question:
          'While talking with my friends, I found out that I had different appointments',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Ask the cause of the time difference in appointments`,
          },
          {
            type: 'F',
            score: 5,
            content: `Swallowing my disappointment, I cautiously continue the conversation`,
          },
        ],
      },
      {
        which: 'TF',
        question: 'What if a close friend forgot your birthday?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I guess you forgot because you're busy. It's possible`,
          },
          {
            type: 'F',
            score: 5,
            content: `How can you forget my birthday? I'm so sad`,
          },
        ],
      },
      {
        which: 'TF',
        question:
          'If you find out later that a friend took all the credit for something good you did together?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I'm a little upset, but maybe there was some reason?`,
          },
          {
            type: 'F',
            score: 5,
            content: `I don't understand the situation right now, so I need to clarify the facts clearly`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          "What if a friend depends on you and says you don't have to care about planning or preparing?",
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Still, I make a different plan with my friend just in case`,
          },
          {
            type: 'P',
            score: 5,
            content: `I really don't care about anything and believe in my friend's plans`,
          },
        ],
      },
      {
        which: 'JP',
        question: "What's your normal recycling routine?",
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Separate the bugs before they get twisted`,
          },
          {
            type: 'P',
            score: 5,
            content: `I separate the recycling only when it starts to smell after piling up`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'The place I booked over the phone has been reserved for a different date!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I think I must have made a mistake, so I'll look into other options`,
          },
          {
            type: 'P',
            score: 5,
            content: `I blame myself and quickly start checking reviews for other places`,
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/insigniaEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/insigniaEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/insigniaEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/insigniaEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/insigniaEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/insigniaEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/insigniaEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/insigniaEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/insigniaEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/insigniaEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/insigniaEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/insigniaEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/insigniaEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/insigniaEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/insigniaEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/insigniaEng/INFP.jpg',
      },
    ],
  },
  // insignia
  {
    info: {
      mainTitle: '휘장 테스트',
      subTitle: '나의 성격과 어울리는 휘장은 무엇일까?',
      mainUrl: 'insignia',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/insignia-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/insignia-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '많은 사람들 앞에서 인터뷰를 요청받는다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '관심을 받는 것이 기분 좋기에 고민 없이 바로 응한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '인터뷰는 부담스러운 것 같아 거부해도 될까?',
          },
        ],
      },
      {
        which: 'EI',
        question: '공연을 관람하던 중 전광판 카메라에 포착되었다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '신이 나서 손을 여기저기 흔들며 카메라에 찍힌 모습을 친구에게 자랑한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '화들짝 놀라며 카메라에 찍히지 않도록 고개를 푹 숙인다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '층간 소음이 심하여 윗집에게 항의를 하게 된다면?',
        answers: [
          {
            type: 'I',
            score: 2,
            content: `낯선 사람과 만나서 말하는 것은 부끄러워 쪽지로 소심하게 항의한다.`,
          },
          {
            type: 'E',
            score: 5,
            content: `친한 친구를 이끌고 올라가 당당하게 항의한다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '아이디어를 떠올릴 때는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `현실적인 부분을 먼저 고려해 본다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `다양한 가능성을 먼저 상상한다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구가 알 수 없는 미지 세계에 대해 말한다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '과연 그런 곳이 진짜 있을까?',
          },
          {
            type: 'N',
            score: 5,
            content: `그런 곳에는 이상한 물건과 생명체가 많지 않을까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '추억의 가게들이 모두 새로운 가게로 바뀌어 있다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `시대가 많이 변한 탓에 유지하지 못하고 사라졌나 보네`,
          },
          {
            type: 'N',
            score: 5,
            content: `내 추억 돌려내!! 여긴 문구점이었고 저긴 빵집이었는데 아 저기 빵 진짜 맛있었는데`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구들과 이야기 중 당신만 약속 시간이 달랐다는 사실을 알게 되었다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '약속 시간 차이의 원인을 물어본다.',
          },
          {
            type: 'F',
            score: 5,
            content: '속으로 서운함을 삼키고 조심스럽게 이야기를 이어 나간다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '친한 친구가 당신의 생일을 까먹었다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `바쁘게 사느냐고 잊었나 보네 뭐 그럴 수 있지~`,
          },
          {
            type: 'F',
            score: 5,
            content: '어떻게 내 생일을 까먹을 수 있지..? 너무 서운한데 ㅠ',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 당신과 함께한 좋은 일을 혼자만의 공으로 돌린 것을 뒤늦게 알게 됐다면?`,
        answers: [
          {
            type: 'F',
            score: 2,
            content: '조금 속상하긴 하지만 무슨 이유가 있었던 것이 아닐까?',
          },
          {
            type: 'T',
            score: 5,
            content:
              '지금 상황 자체가 이해가 안 되는데 명확하게 사실을 밝혀야겠어',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '친구가 전적으로 의존하고 너는 계획이나 준비를 신경 쓸 필요 없다고 말했다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `그래도 혹시 모를 상황을 대비해 친구와 다른 계획을 세운다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `진짜 아무것도 신경 쓰지 않고 친구의 계획을 믿는다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '평소 분리수거 루틴은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '벌레가 꼬이기 전 미리미리 분리수거를 한다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '왕창 쌓아 두고 냄새가 나기 시작했을 때쯤 분리수거를 한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '직접 통화로 예약한 곳이 다른 날짜로 예약되었다!',
        answers: [
          {
            type: 'P',
            score: 2,
            content: '잘못 말했나 보다 생각하며 다른 곳을 알아본다.',
          },
          {
            type: 'J',
            score: 5,
            content: '자책하며 다른 곳의 리뷰부터 빠르게 살펴본다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/insignia/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/insignia/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/insignia/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/insignia/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/insignia/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/insignia/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/insignia/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/insignia/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/insignia/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/insignia/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/insignia/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/insignia/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/insignia/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/insignia/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/insignia/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/insignia/INFP.jpg',
      },
    ],
  },
  // whatsInMyTem
  {
    info: {
      mainTitle: '와츠 인 마이 템 테스트',
      subTitle: '너의 성격과 어울리는 꿀템을 소개해 줄게!',
      mainUrl: 'whatsInMyTem',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/whatsInMyTem-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/whatsInMyTem-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '평화로운 주말에 주로 내가 머무는 곳은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '활기찬 외부 세계',
          },
          {
            type: 'I',
            score: 5,
            content: '조용한 내면 세계',
          },
        ],
      },
      {
        which: 'EI',
        question: '내게 유용하고 마음에 쏙 드는 제품을 구매하고 난 후에는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '주변 사람들에게 너무 좋다고 추천한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '물어보지 않는 이상 먼저 말하지 않는다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '새로운 물건을 구입했는데 사용 방법을 익힐 때?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `직접 만져보고 경험해 봐야 익히기 편하다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `어떻게 사용하는 건지 먼저 이해부터 해야 익히기 편하다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '제품을 살 때 제일 우선시하는 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `제품의 내구성 또는 우수한 품질`,
          },
          {
            type: 'N',
            score: 5,
            content: `신제품 또는 신박한 제품`,
          },
        ],
      },
      {
        which: 'SN',
        question: '설명서가 동봉되어 있는 조립 제품을 구입하면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '설명서를 읽어보고 그대로 따라 한다.',
          },
          {
            type: 'N',
            score: 5,
            content: `설명서를 훑어보고 내 감과 느낌에 따라 만든다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '마음에 드는 제품의 가격이 비쌀 때 가장 먼저 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `구매하고 난 후의 자금 상태에 대해 생각한다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `제품을 구매하였을 때 배치와 만족도를 상상한다.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 우울한 마음에 큰맘 먹고 비싼 제품을 구매했다고 한다!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '얼마인데? 많이 비싸?',
          },
          {
            type: 'F',
            score: 5,
            content: '왜 우울해? 사니까 기분이 좀 풀렸어?',
          },
        ],
      },
      {
        which: 'TF',
        question: '큰 기대를 했던 제품이 파손된 채로 배송 되었다는 친구에게?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `빨리 교환이나 반품 처리하도록 해.`,
          },
          {
            type: 'F',
            score: 5,
            content: '엄청 기대했던 제품 아니었어..? 기분 안 좋겠네..',
          },
        ],
      },
      {
        which: 'TF',
        question: `내겐 전혀 필요가 없고 쓸데가 없는 물건을 생일 선물로 받았을 때는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '어디다 쓰라고 사준 것인지 물어본다.',
          },
          {
            type: 'F',
            score: 5,
            content: '아무 말 하지 않고 티를 안 내며 고맙다고 말한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '새로운 제품을 구매할 때 생각하는 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `무조건 성공적인 구매를 하고 싶다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `실패해도 어쩔 수 없다고 생각한다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '생필품을 구입할 때는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '떨어지기 전 미리미리 사둔다.',
          },
          {
            type: 'P',
            score: 5,
            content: '제품이 없는 것을 확인하고 부랴부랴 산다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '제품을 구매할 때는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '가격을 비교해 보고 제일 합리적인 곳에서 구매한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 제품을 파는 찾아보고 평이 좋은 곳에서 구매한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/whatsInMyTem/ESTJ.jpg',
        coupang_link:
          'https://www.coupang.com/vp/products/1238433181?itemId=2234016614&vendorItemId=70231578777&q=%EB%93%9C%EB%9D%BC%EC%9D%B4%EA%B8%B0%EA%B1%B0%EC%B9%98%EB%8C%80&itemsCount=35&searchId=76aef868bc7348ef84e40b576e3adc99&rank=1&isAddedCart=',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/whatsInMyTem/ESTP.jpg',
        coupang_link:
          'https://www.coupang.com/vp/products/6545212058?itemId=14589586979&vendorItemId=82236542373&q=%ED%85%8C%EB%B8%94%EB%A6%BF+%EA%B1%B0%EC%B9%98%EB%8C%80&itemsCount=36&searchId=4675e4e616fb48c9830ce49188c26b95&rank=3&isAddedCart=',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/whatsInMyTem/ESFJ.jpg',
        coupang_link:
          'https://www.coupang.com/vp/products/5392673575?itemId=8046548842&vendorItemId=75335117842&q=%EA%B0%90%EC%84%B1&itemsCount=36&searchId=0c698467067f4562b347fbcb70830879&rank=7&isAddedCart=',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/whatsInMyTem/ESFP.jpg',
        coupang_link:
          'https://www.coupang.com/vp/products/319472657?itemId=1021618280&vendorItemId=75195151067&q=%ED%98%80%ED%81%B4%EB%A6%AC%EB%84%88&itemsCount=36&searchId=c13b2a4f79aa45eda0875a168a7e6f01&rank=15&isAddedCart=',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/whatsInMyTem/ENTJ.jpg',
        coupang_link:
          'https://www.coupang.com/vp/products/256361133?itemId=805289569&vendorItemId=5532144918&q=%EC%97%AC%ED%96%89%EC%9A%A9+%EC%96%B4%EB%8E%81%ED%84%B0&itemsCount=36&searchId=856e6678b6264927b61a70bd9d49ed8f&rank=25&isAddedCart=',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/whatsInMyTem/ENTP.jpg',
        coupang_link:
          'https://www.coupang.com/vp/products/6718631489?itemId=15514396239&vendorItemId=3016260544&pickType=COU_PICK&q=%ED%96%A5%EC%88%98+%EA%B3%B5%EB%B3%91&itemsCount=36&searchId=81ea90252ae14920835a0cc4d3162fc2&rank=1&isAddedCart',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/whatsInMyTem/ENFJ.jpg',
        coupang_link:
          'https://www.coupang.com/vp/products/6831900272?itemId=16220365094&vendorItemId=83414027898&q=%EC%97%AC%ED%96%89%EC%9A%A9+%ED%8C%8C%EC%9A%B0%EC%B9%98+%EC%95%95%EC%B6%95&itemsCount=36&searchId=1fccf74e02a044e7976d8621f247a64b&rank=9&isAddedCart=',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/whatsInMyTem/ENFP.jpg',
        coupang_link:
          'https://www.coupang.com/vp/products/2228290078?itemId=3801328837&vendorItemId=71786302869&q=%EB%A7%81%EC%A2%85%EC%95%84%EB%A6%AC+%EB%B0%9C%EB%A7%88%EC%82%AC%EC%A7%80%EA%B8%B0&itemsCount=36&searchId=5370647dcd0c4ce6999405f1f49029b3&rank=2&isAddedCart=',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/whatsInMyTem/ISTJ.jpg',
        coupang_link:
          'https://www.coupang.com/vp/products/5054115440?itemId=18741535753&vendorItemId=3000262624&pickType=COU_PICK&q=%EA%B7%80%EB%A7%88%EA%B0%9C&itemsCount=36&searchId=65acefb5b70d420191bde8c5f20bc673&rank=2&isAddedCart=',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/whatsInMyTem/ISTP.jpg',
        coupang_link:
          'https://www.coupang.com/vp/products/6801270713?itemId=16055875936&vendorItemId=82114354005&pickType=COU_PICK&sourceType=srp_product_ads&clickEventId=93cdb330-2234-11ef-a88a-6be2dd1d78c1&korePlacement=15&koreSubPlacement=1&q=%EC%86%A1%EC%9E%A5%EC%A7%80%EC%9A%B0%EA%B0%9C&itemsCount=36&searchId=2d8a4e6fd77344518a8dec0274a13df9&rank=0&isAddedCart=',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/whatsInMyTem/ISFJ.jpg',
        coupang_link:
          'https://www.coupang.com/vp/products/5228263414?itemId=7352532345&vendorItemId=74643716316&q=%EB%B2%84%EB%B8%94%ED%84%B0%EC%B9%98&itemsCount=36&searchId=657defffe8654ba29c50290efecd1005&rank=1&isAddedCart=',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/whatsInMyTem/ISFP.jpg',
        coupang_link:
          'https://www.coupang.com/vp/products/7249201578?itemId=18436199519&vendorItemId=85577532199&q=%EA%B1%B0%EB%B6%81%EC%9D%B4%EC%9D%B8%ED%98%95&itemsCount=36&searchId=a58e89deb5144709b169cc8e18376f0a&rank=9&isAddedCart=',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/whatsInMyTem/INTJ.jpg',
        coupang_link:
          'https://www.coupang.com/vp/products/4971807620?itemId=6602194612&vendorItemId=73896221901&q=%EB%8F%85%EC%84%9C&itemsCount=35&searchId=4e2c15d03f324d39992a57f6f2a798a4&rank=1&isAddedCart=',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/whatsInMyTem/INTP.jpg',
        coupang_link:
          'https://www.coupang.com/vp/products/7478813247?itemId=19530683142&vendorItemId=86639140972&pickType=COU_PICK&q=%EC%BD%98%EC%84%BC%ED%8A%B8+%EC%86%8C%ED%99%94+%ED%8C%A8%EC%B9%98&itemsCount=36&searchId=f2c95e6c8e8f41d697bf115b340e2a84&rank=1&isAddedCart=',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/whatsInMyTem/INFJ.jpg',
        coupang_link:
          'https://www.coupang.com/vp/products/7432652127?itemId=19308030210&vendorItemId=86422347325&sourceType=srp_product_ads&clickEventId=0ccc6c40-2226-11ef-9390-452aa264e2f9&korePlacement=15&koreSubPlacement=11&q=%EC%97%AC%ED%96%89&itemsCount=36&searchId=ce58d9a6c7044062a44b7d99cf14cbce&rank=10&isAddedCart=',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/whatsInMyTem/INFP.jpg',
        coupang_link:
          'https://www.coupang.com/vp/products/7613894901?itemId=20171601997&vendorItemId=87031542605&q=%EB%B0%94%EB%94%94%ED%95%84%EB%A1%9C%EC%9A%B0+%EB%A1%B1%EC%BF%A0%EC%85%98+%EC%9D%B8%ED%98%95&itemsCount=36&searchId=f798bde94d5043afbaff4d2895feeb46&rank=1&isAddedCart=',
      },
    ],
  },
  // retroPhoneChar
  {
    info: {
      mainTitle: '레트로 폰 성격 테스트',
      subTitle: '추억의 레트로 폰으로 알아보는 내 성격은?',
      mainUrl: 'retroPhoneChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/retroPhoneChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/retroPhoneChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '평소 나는 친구들이랑 연락을?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '심심하니까!! 일상 대화를 나누며 자주 연락함',
          },
          {
            type: 'I',
            score: 5,
            content: '무소식이 희소식이지.. 특별한 일 없으면 거의 안 함',
          },
        ],
      },
      {
        which: 'EI',
        question: '내 잘못으로 친구와 다툰 상황! 사과를 전달하기 더 편한 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '만나자고 약속을 잡거나 통화로 전달',
          },
          {
            type: 'I',
            score: 5,
            content: '장문의 카톡을 여러 번 수정 후 전달',
          },
        ],
      },
      {
        which: 'EI',
        question: '오늘은 내 생일이지만 헬요일이다..!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `그래도 내 생일인데.. 축하 파티해줘~~~`,
          },
          {
            type: 'I',
            score: 5,
            content: `생일이라도 평일 외출은 힘들어ㅜ 집에서 쉴래~`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구에게 그립톡을 선물하려고 한다! 내 선택은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `무난 무난~ 하고 실용적인 기본 디자인`,
          },
          {
            type: 'N',
            score: 5,
            content: `유니크하고 새로 나온 특별한 디자인`,
          },
        ],
      },
      {
        which: 'SN',
        question: '길을 가다가 옛날 옛적 오래된 폰을 주웠다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '요즘도 이런 폰을 쓰네 신기하다',
          },
          {
            type: 'N',
            score: 5,
            content: `이게 왜 여기…! 과거에서 누가 온 건 아니겠지 크크`,
          },
        ],
      },
      {
        which: 'SN',
        question: '지금 세상에서 스마트폰이 사라진다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `유하 어케 함? 큰일 나겠네`,
          },
          {
            type: 'N',
            score: 5,
            content: `헐 그럼 카톡도 못하겠네? 예전 폴더폰도 사라져?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `예전에 쓰던 레트로 폰을 모으는 취미가 있는 친구!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '어디다 쓰려고? 팔면 비싸겠는데~',
          },
          {
            type: 'F',
            score: 5,
            content: '와 완전 추억이다.. 이 옛날 갬성..',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '비가 오는 날! 앞 사람이 쫄딱 젖은 채로 뛰고있다면 내 생각은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `편의점에서 우산 사면 되지 않나`,
          },
          {
            type: 'F',
            score: 5,
            content: '우산 씌워드리고 싶지만 오지랖이겠지..',
          },
        ],
      },
      {
        which: 'TF',
        question: `나는 상대방에게 궁금한 게 있을 때?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '눈치 보지 않고 그냥 물어본다',
          },
          {
            type: 'F',
            score: 5,
            content: '상황을 봐가며 눈치껏 조심스럽게 물어본다',
          },
        ],
      },
      {
        which: 'JP',
        question: '나는 법과 질서를?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `나도, 내 주변 사람들도 잘 지켰으면 좋겠음`,
          },
          {
            type: 'P',
            score: 5,
            content: `꼭 엄격하게 지키지 않아도 괜찮다고 생각함`,
          },
        ],
      },
      {
        which: 'JP',
        question: '친구와의 약속! “얼마나 걸려?” 라는 질문에 내 대답은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '나 역 도착 시간이 5시 13분이니까 내려서 걸으면 17분 뒤 도착해!',
          },
          {
            type: 'P',
            score: 5,
            content: '나 한 15분쯤 뒤에 도착할 것 같아!',
          },
        ],
      },
      {
        which: 'JP',
        question: '어떤 결정을 내릴 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '빠르게 옳은 결정을 내리고 싶음',
          },
          {
            type: 'P',
            score: 5,
            content: '어떤 결정을 내릴지 고민하는 게 재밌음',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/retroPhoneChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/retroPhoneChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/retroPhoneChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/retroPhoneChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/retroPhoneChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/retroPhoneChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/retroPhoneChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/retroPhoneChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/retroPhoneChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/retroPhoneChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/retroPhoneChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/retroPhoneChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/retroPhoneChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/retroPhoneChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/retroPhoneChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/retroPhoneChar/INFP.jpg',
      },
    ],
  },
  // olympicChar
  {
    info: {
      mainTitle: '올림픽 성격 테스트',
      subTitle: '국가대표가 되어 파리 올림픽에 나간다면?',
      mainUrl: 'olympicChar',
      scoreType: 'typeCountingMBTIName',
      mainImage:
        'https://images.ktestone.com/introImages/olympicChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/olympicChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
      nameLocation: {
        intro: {
          font: 'Noto Sans',
          fontSize: 1.5,
          bottom: 8,
          pcFontSize: 2,
          pcBottom: 7.5,
        },
        result: {
          font: 'Noto Sans',
          fontSize: 1.7,
          fontColor: 'white',
          result: 12,
          left: 17,
          pcFontSize: 2.8,
          pcResult: 20.8,
          pcLeft: 30,
        },
      },
    },
    questions: [
      {
        which: 'EI',
        question: '선수촌에 입성하게 되었다! 희망하는 방은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '북적북적 여러 사람이 사용하는 3인실',
          },
          {
            type: 'I',
            score: 5,
            content: '조용히 혼자 쓸 수 있는 1인실',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '같이 훈련하는 선수들 앞에서 자기소개를 하게 되었다! 이때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '주변 사람들과 눈을 맞추며 자기소개를 한 후 자리에 앉는다.',
          },
          {
            type: 'I',
            score: 5,
            content: '바닥을 보며 자기소개를 한 후 신속하게 자리에 앉는다.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '선수들과 친해지는 시간을 가질 겸 외식을 하게 되었다! 어떤 곳으로 갈까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `대화하며 오래 시간을 보낼 수 있는 식당`,
          },
          {
            type: 'I',
            score: 5,
            content: `빠르게 먹고 숙소로 들어갈 수 있는 식당`,
          },
        ],
      },
      {
        which: 'SN',
        question: '드디어 첫 훈련 시간! 코치가 엉뚱한 훈련법을 알려준다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `진짜 이상하네.. 그래도 코치님이 알려주신 거니까 일단 해보자`,
          },
          {
            type: 'N',
            score: 5,
            content: `이건 무슨 훈련이지..? 몰래카메라 아냐?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '상대 선수에 대한 정보를 받았을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '강점은 이렇고 약점은 이거구나',
          },
          {
            type: 'N',
            score: 5,
            content: `왠지 무서운 느낌이야 포스가 장난 아니네`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '오랜 훈련을 거쳐 마침내 올림픽에 출전하게 되었다! 내 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `할 수 있다!! 실수하지 말고 훈련한 대로만 하자`,
          },
          {
            type: 'N',
            score: 5,
            content: `막판에 극적으로 승리해서 금메달 따고 영웅이 되는 상상함`,
          },
        ],
      },
      {
        which: 'TF',
        question: `동료가 경기 중 실수를 하여 지고 말았다! 이때 무슨 말을 해주면 좋을까?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '이번 실수를 기회 삼아 다음엔 더 잘할 수 있을 거예요. 파이팅!',
          },
          {
            type: 'F',
            score: 5,
            content:
              '아쉽고 속상하시겠지만 다음 기회도 있으니까요! 같이 힘내 봐요!',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '경기가 종료된 후 심판의 불합리한 판정에 대해 항의를 했다! 이때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `불합리한 게 확실하니까 당연히 내 항의가 받아들여지겠지`,
          },
          {
            type: 'F',
            score: 5,
            content: '불합리한 거 맞겠지? 항의 하긴 했지만 너무 불안해',
          },
        ],
      },
      {
        which: 'TF',
        question: `지난 올림픽 때 내 악수를 무시하고 지나간 상대 선수를 다시 만났다!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '무시 하던지 말던지 신경 쓰지 않음',
          },
          {
            type: 'F',
            score: 5,
            content: '이번에도 무시당할까 봐 눈치 봄',
          },
        ],
      },
      {
        which: 'JP',
        question: '내게 더 필요한 훈련법은 무엇일까?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `시시각각 바뀌는 경기 흐름에 빠르게 적응할 수 있도록 하는 훈련`,
          },
          {
            type: 'P',
            score: 5,
            content: `장기적인 플레이 시 집중력을 잃지 않도록 연습하는 훈련`,
          },
        ],
      },
      {
        which: 'JP',
        question: '금메달을 획득했다! 내가 감격에 차오르는 이유는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '결국 금메달을 땄다는 만족스러운 결과에 감격함',
          },
          {
            type: 'P',
            score: 5,
            content: '금메달을 따기 위해 노력했던 과정이 떠오르며 감격함',
          },
        ],
      },
      {
        which: 'JP',
        question: '올림픽이 끝나고 난 이후 일정은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '이때 끝난다면 이렇게, 결승까지 올라간다면 이후 이렇게 저렇게',
          },
          {
            type: 'P',
            score: 5,
            content: '어떻게 될지 알 수 없으니깐 그때 가서 정하자',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/olympicChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/olympicChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/olympicChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/olympicChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/olympicChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/olympicChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/olympicChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/olympicChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/olympicChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/olympicChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/olympicChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/olympicChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/olympicChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/olympicChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/olympicChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/olympicChar/INFP.jpg',
      },
    ],
  },
  // pressTestEng
  {
    info: {
      mainTitle: 'Press Conference Test',
      subTitle: `What if there was a press conference about my personality?`,
      mainUrl: 'pressTestEng',
      scoreType: 'typeCountingMBTIName',
      mainImage:
        'https://images.ktestone.com/introImages/pressTestEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/pressTestEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
      nameLocation: {
        intro: {
          font: 'Noto Sans',
          fontSize: 1.2,
          bottom: 5.2,
          pcFontSize: 2,
          pcBottom: 4,
        },
        result: {
          font: 'Noto Sans',
          fontSize: 1.5,
          fontColor: 'white',
          result: 2.1,
          left: 6,
          pcFontSize: 3.3,
          pcResult: 4,
          pcLeft: 12,
        },
      },
    },
    questions: [
      {
        which: 'EI',
        question:
          'What do you think about presenting in front of a lot of people?',
        answers: [
          {
            type: 'I',
            score: 2,
            content: "'I'm so nervous, and I think it's burdensome. '",
          },
          {
            type: 'E',
            score: 5,
            content: "'I think I'll get a lot of attention and it'll be fun.'",
          },
        ],
      },
      {
        which: 'EI',
        question: 'What about you when you meet someone you are not close to?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              "I think it's a chance to get to know each other and I'm excited!",
          },
          {
            type: 'I',
            score: 5,
            content: 'I feel awkward and uncomfortable and want to go home.. ',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What kind of person do you think I am to your acquaintances?',
        answers: [
          {
            type: 'I',
            score: 2,
            content: `It can be a little frustrating because it's quiet and timid. `,
          },
          {
            type: 'E',
            score: 5,
            content: `Bright, energetic, likes to play and is loud `,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What if there was a press conference about your personality?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I wondered what was wrong with my personality`,
          },
          {
            type: 'N',
            score: 5,
            content: `I can imagine myself doing a press conference.`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'If you could be invisible for a day, what would you do?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              "Well, I think I'll just go somewhere that's hard to go normally.",
          },
          {
            type: 'N',
            score: 5,
            content: `Then I think I'll go to my favorite celebrity's house or try this and that!?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'You recommended a destination before, and I wonder why you chose it!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `There are many things to see and the location is close.`,
          },
          {
            type: 'N',
            score: 5,
            content: `The bright atmosphere there felt perfect for traveling.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `When you have to convince someone you don't agree with, what about you?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Convince by presenting a solid logical basis.',
          },
          {
            type: 'F',
            score: 5,
            content:
              "Build consensus and persuade the other person's feelings.",
          },
        ],
      },
      {
        which: 'TF',
        question:
          'How do you respond to conflicts between friends over minor issues?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `It's none of my business. They'll make it up on their own!`,
          },
          {
            type: 'F',
            score: 5,
            content: 'I try to soothe my friends so they can make up.',
          },
        ],
      },
      {
        which: 'TF',
        question: `What is it like when you see someone acting emotionally?`,
        answers: [
          {
            type: 'F',
            score: 2,
            content: "It's a human being! Emotions can't be fooled!",
          },
          {
            type: 'T',
            score: 5,
            content: "I think it's stupid to be swayed by emotions.",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What will you do if the number of people changes at the meeting?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I think we will take measures in advance, such as reservation or schedule. `,
          },
          {
            type: 'P',
            score: 5,
            content: `I think we'll meet and talk about what we're going to do with our schedule.`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'How do you act when you put off what you have to do until tomorrow?',
        answers: [
          {
            type: 'P',
            score: 2,
            content:
              'I rationalize myself that I can put it off until tomorrow',
          },
          {
            type: 'J',
            score: 5,
            content:
              "That's part of the plan, so I have another schedule in the meantime.",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'I heard that you have an important schedule today, can you explain it to me?',
        answers: [
          {
            type: 'P',
            score: 2,
            content:
              "Oh right! I almost forgot if you didn't tell me. Thank you.",
          },
          {
            type: 'J',
            score: 5,
            content:
              "It's scheduled exactly 36 minutes later, and it takes about 2 hours.",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/pressTestEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/pressTestEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/pressTestEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/pressTestEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/pressTestEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/pressTestEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/pressTestEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/pressTestEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/pressTestEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/pressTestEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/pressTestEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/pressTestEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/pressTestEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/pressTestEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/pressTestEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/pressTestEng/INFP.jpg',
      },
    ],
  },
  // pressTest
  {
    info: {
      mainTitle: '기자회견 테스트',
      subTitle: '내 성격에 큰 논란이 생겨 기자회견이 열린다면?',
      mainUrl: 'pressTest',
      scoreType: 'typeCountingMBTIName',
      mainImage: 'https://images.ktestone.com/introImages/pressTest-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/pressTest-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
      nameLocation: {
        intro: {
          font: 'Noto Sans',
          fontSize: 1.2,
          bottom: 5.2,
          pcFontSize: 2,
          pcBottom: 4,
        },
        result: {
          font: 'Noto Sans',
          fontSize: 1.9,
          fontColor: 'white',
          result: 2.3,
          left: 7,
          pcFontSize: 3.3,
          pcResult: 4,
          pcLeft: 12,
        },
      },
    },
    questions: [
      {
        which: 'EI',
        question: '많은 사람들 앞에서 발표하는 것에 대해 어떻게 생각하시나요?',
        answers: [
          {
            type: 'I',
            score: 2,
            content: '너무 떨리고, 부담스러운 일이라고 생각해요.',
          },
          {
            type: 'E',
            score: 5,
            content: '합법적으로 주목받을 수 있고, 재미있을 것 같아요',
          },
        ],
      },
      {
        which: 'EI',
        question: '친하지 않은 사람과 약속이 잡혔을 때는 어떠실 지 궁금합니다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '그 사람과 친해질 수 있는 기회라고 생각하고 설레여요!',
          },
          {
            type: 'I',
            score: 5,
            content:
              '어색해서 무슨 말을 해야할 지도 모르겠고 불편해서 집에 가고 싶어져요..',
          },
        ],
      },
      {
        which: 'EI',
        question: '지인들에게 어떤 사람으로 보여지고 있다고 생각하시나요?',
        answers: [
          {
            type: 'I',
            score: 2,
            content: `조용하고 말 수가 적고 좀 답답해 할 수도 있을 것 같아요.`,
          },
          {
            type: 'E',
            score: 5,
            content: `밝고 활발하고 노는 거 좋아하는? 근데 좀 시끄러우려나요?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '본인 성격의 논란에 대한 기자회견이 열린다는 소식을 들었을 때 어떠셨나요?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `기자회견이 열릴 정도로 제 성격에 무슨 문제가 있었을까 생각했습니다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `기자회견이 시작되고 말 실수 해서 논란이 커지는 상상을 했습니다.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '만약 하루동안 투명인간이 될 수 있다면 무엇을 하고 싶으신가요?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '음.. 그냥 평소에 가기 어려운 곳을 몰래 들어가보지 않을까요?',
          },
          {
            type: 'N',
            score: 5,
            content: `그럼 저는 좋아하는 연예인 집에도 가보고 이렇게 저렇게 다 해볼 것 같아요!`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '이 전에 여행지 추천을 해주셨는데요. 그 곳을 선택한 이유가 궁금합니다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `그 곳은 구경할 게 많고 무엇보다 위치가 가까워서 추천했습니다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `그 곳의 밝은 분위기가 여행 가기 딱 좋은 느낌이었어요.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `당신과 의견이 다른 사람을 설득해야 할 때, 어떻게 행동하시나요?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '논리적으로 확실한 근거를 제시하며 설득합니다.',
          },
          {
            type: 'F',
            score: 5,
            content: '상대방의 감정을 공략해서 공감대를 형성하며 설득합니다.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '사소한 문제로 친구들 사이에 갈등이 발생했다면 어떻게 대응하시나요?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `제 일 아니면 관심 없는데. . 알아서 화해 하겠죠!`,
          },
          {
            type: 'F',
            score: 5,
            content: '살살 달래서 친구들이 화해할 수 있도록 노력해요.',
          },
        ],
      },
      {
        which: 'TF',
        question: `감정적으로 행동하는 사람을 대할 때 어떠실 지 궁금합니다!`,
        answers: [
          {
            type: 'F',
            score: 2,
            content: '사람인데 그럴 수 있죠! 감정은 속일 수 없어요!',
          },
          {
            type: 'T',
            score: 5,
            content: '감정에 휘둘리다니.. 어리석다고 생각해요.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '주최자인 모임에서 인원 수가 변동되면 어떻게 대처하실 건가요?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `인원 수 변경에 대한 예약이나, 일정 등 미리 조취를 취할 것 같아요`,
          },
          {
            type: 'P',
            score: 5,
            content: `일단 만나서 일정 어떻게 할 건지 대화 나눠볼 것 같아요`,
          },
        ],
      },
      {
        which: 'JP',
        question: '해야할 일을 내일로 미뤄버릴 때 어떻게 행동하시나요?',
        answers: [
          {
            type: 'P',
            score: 2,
            content: '그냥 내일 하면 된다고 제 자신을 합리화 시켜요.',
          },
          {
            type: 'J',
            score: 5,
            content:
              '미루는 것도 계획의 일부이기 때문에 그 사이에 다른 일정을 소화해요',
          },
        ],
      },
      {
        which: 'JP',
        question: '오늘 중요한 일정이 있다고 들었는데, 설명해 주실 수 있나요?',
        answers: [
          {
            type: 'P',
            score: 2,
            content: '아 맞다! 말씀 안 해주셨으면 까먹을 뻔 했네요 감사합니다.',
          },
          {
            type: 'J',
            score: 5,
            content:
              '네 정확히 36분 50초 뒤 일정인데, 약 2시간 정도 소요됩니다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/pressTest/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/pressTest/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/pressTest/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/pressTest/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/pressTest/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/pressTest/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/pressTest/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/pressTest/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/pressTest/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/pressTest/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/pressTest/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/pressTest/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/pressTest/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/pressTest/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/pressTest/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/pressTest/INFP.jpg',
      },
    ],
  },
  // criticalTshirtsEng
  {
    info: {
      mainTitle: 'Fatal T-shirt Test',
      subTitle: 'What is my personality that I can tell from a fatal T-shirt?',
      mainUrl: 'criticalTshirtsEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/criticalTshirtsEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/criticalTshirtsEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          "'What about me when it's time to hang out with my friend and go back home?'",
        answers: [
          {
            type: 'I',
            score: 2,
            content: "'I can't wait to break up and relax at home'",
          },
          {
            type: 'E',
            score: 5,
            content:
              "'Should I ask if we can hang out a bit longer because it feels like it's not enough yet?'",
          },
        ],
      },
      {
        which: 'EI',
        question:
          "If I really liked the accessory someone I don't know was wearing",
        answers: [
          {
            type: 'E',
            score: 2,
            content: "I'm sorry, but where did you buy it?",
          },
          {
            type: 'I',
            score: 5,
            content: "Wow, I really want that, but I'm shy to talk to him",
          },
        ],
      },
      {
        which: 'EI',
        question: 'What if the taxi driver talks to you in a friendly way?',
        answers: [
          {
            type: 'I',
            score: 2,
            content: `Why are you talking when you want to go quietly by yourself?`,
          },
          {
            type: 'E',
            score: 5,
            content: `You won't be bored on your way to your destination. It's so nice`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'If I give a gift to a friend who gave birth, what should I give her?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `a practical gift`,
          },
          {
            type: 'N',
            score: 5,
            content: `sophisticated gift`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What if I see someone selling in front of the store with a bunch of T-shirts?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: "Looks like business isn't going too well, huh?",
          },
          {
            type: 'N',
            score: 5,
            content: `I feel like I'm at Dongdaemun Market`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What if a foreigner is wearing a Korean T-shirt with strange phrases on it?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I guess I didn't know what it meant and wore it because it was cool`,
          },
          {
            type: 'N',
            score: 5,
            content: `It wouldn't be deliberate, would it?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What if your friend gave you a embarrassing T-shirt to wear outside?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              "How do you wear this outside? I'll wear it as pajamas at home! Thank you",
          },
          {
            type: 'F',
            score: 5,
            content: "Wow, thank you so much. I'll try it on someday",
          },
        ],
      },
      {
        which: 'TF',
        question:
          'If a friend asks you to go to a T-shirt store on a day when they can’t go out',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Do you have to go out today? Can't you go later?`,
          },
          {
            type: 'F',
            score: 5,
            content: "I can't help it. I'm tired, but I'll go with you",
          },
        ],
      },
      {
        which: 'TF',
        question: `What if your friend tells you an old story you can't even think of and forces you to reminisce?`,
        answers: [
          {
            type: 'F',
            score: 2,
            content: "Yes, I think so! It's a memory",
          },
          {
            type: 'T',
            score: 5,
            content:
              "I'm sorry, but I don't remember. Can we talk about something else?",
          },
        ],
      },
      {
        which: 'JP',
        question: 'When you do the first wash after buying a T-shirt?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I'll check the store’s washing instructions and then wash it`,
          },
          {
            type: 'P',
            score: 5,
            content: `I don't care about my first wash and wash it as usual`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'What kind of company do you want?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: "It's good when a free company works",
          },
          {
            type: 'P',
            score: 5,
            content: "It's good when an organized company works",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What if a friend says he goes around looking for a room for a move?',
        answers: [
          {
            type: 'P',
            score: 2,
            content: "It's better to live near convenient transportation!",
          },
          {
            type: 'J',
            score: 5,
            content:
              "Don't just look at one, look around several and make a good decision!",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirtsEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirtsEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirtsEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirtsEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirtsEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirtsEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirtsEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirtsEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirtsEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirtsEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirtsEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirtsEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirtsEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirtsEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirtsEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirtsEng/INFP.jpg',
      },
    ],
  },
  // criticalTshirts
  {
    info: {
      mainTitle: '치명적인 티셔츠 테스트',
      subTitle: '치명적인 티셔츠를 통해 알 수 있는 나의 성격은 무엇일까?',
      mainUrl: 'criticalTshirts',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/criticalTshirts-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/criticalTshirts-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '친구와 놀고 집으로 돌아갈 시간에는?',
        answers: [
          {
            type: 'I',
            score: 2,
            content: '빨리 헤어져서 집에서 쉬고 싶어',
          },
          {
            type: 'E',
            score: 5,
            content: '아직 부족한데 더 놀자고 해볼까?',
          },
        ],
      },
      {
        which: 'EI',
        question: '모르는 사람이 착용한 액세서리가 너무 마음에 들었다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '죄송한데 그거 어디서 사셨어요? ',
          },
          {
            type: 'I',
            score: 5,
            content: '와 저거 너무 갖고 싶은데 말 걸기 부끄러워',
          },
        ],
      },
      {
        which: 'EI',
        question: '택시 기사님이 친근하게 말을 걸어온다면?',
        answers: [
          {
            type: 'I',
            score: 2,
            content: `조용한 상태로 가고 싶은데 왜 말을 거는 거야`,
          },
          {
            type: 'E',
            score: 5,
            content: `목적지까지 가는 동안 심심하지 않겠네 너무 좋아~`,
          },
        ],
      },
      {
        which: 'SN',
        question: '출산한 친구에게 선물을 준다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `실용성 있는 선물`,
          },
          {
            type: 'N',
            score: 5,
            content: `미적으로 세련된 선물`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '티셔츠를 무더기로 들고 가게 앞에서 판매를 하고 있는 사람을 본다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '장사가 진짜 안 되나 보네',
          },
          {
            type: 'N',
            score: 5,
            content: `동대문 구제 시장 온 것 같아`,
          },
        ],
      },
      {
        which: 'SN',
        question: '외국인이 이상한 문구가 적힌 한국어 티셔츠를 입고 있다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `뜻 모르고 한국어 멋져서 입었나 보네`,
          },
          {
            type: 'N',
            score: 5,
            content: `저거 뜻 알면 극대노하는 거 아냐? 혹시 알고 입었을 수도?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 밖에서 입기 부끄러운 티셔츠를 선물해 줬다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '이걸 밖에서 어떻게 입고 다녀? 집에서 잠옷으로 쓸게! 고마워',
          },
          {
            type: 'F',
            score: 5,
            content: '와 너무 고마워~ 언젠가 멋지게 입어볼게',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '친구가 폭염으로 외출이 어려운 날 사정을 하며 티셔츠 매장에 같이 가자고 한다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `꼭 오늘 나가야 해? 나중에 가면 안 될까?`,
          },
          {
            type: 'F',
            score: 5,
            content: '이렇게까지 부탁하는데 어쩔 수 없지 힘들지만 같이 가줄게',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 생각도 나지 않는 옛날이야기를 말하며 추억 회상을 강요한다면?`,
        answers: [
          {
            type: 'F',
            score: 2,
            content: '어어 맞아 그랬던 것 같은데! 추억이다~',
          },
          {
            type: 'T',
            score: 5,
            content: '미안한데 잘 기억 안 나서 다른 이야기하면 안될까?',
          },
        ],
      },
      {
        which: 'JP',
        question: '티셔츠를 구매한 후 첫 세탁을 할 때는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `구입한 쇼핑몰을 통해 세탁 방법을 알아보고 난 후 세탁한다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `첫 세탁에 대해 신경 쓰지 않고 평소처럼 세탁을 한다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '원하는 회사 분위기는?',
        answers: [
          {
            type: 'P',
            score: 2,
            content: '딱딱하지 않고 자유로운 회사가 일할 때 좋아',
          },
          {
            type: 'J',
            score: 5,
            content: '체계적인 회사가 일할 때 좋아',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구가 이사를 위한 방을 보러 다닌다고 하면?',
        answers: [
          {
            type: 'P',
            score: 2,
            content: '집은 교통편이랑 가까운 곳이 좋더라!',
          },
          {
            type: 'J',
            score: 5,
            content: '한 개만 보지 말고 여러 개 둘러보고 잘 정하도록 해!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirts/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirts/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirts/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirts/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirts/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirts/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirts/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirts/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirts/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirts/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirts/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirts/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirts/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirts/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirts/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/criticalTshirts/INFP.jpg',
      },
    ],
  },
  // charSignEng
  {
    info: {
      mainTitle: 'Personality Sign Test',
      subTitle: 'What is my Personality that I can tell from Personality Sign?',
      mainUrl: 'charSignEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/charSignEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/charSignEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'What if you went to a festival with a large crowd?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              "'This is the fun of the festival. It's so nice because there are so many people'",
          },
          {
            type: 'I',
            score: 5,
            content:
              "'Are you sure there are this many people? It's hard as soon as I see it'",
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What if a friend suggested that you participate in activities or events with strangers?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              "It's great to be able to meet new people and have different experiences!",
          },
          {
            type: 'I',
            score: 5,
            content:
              "I don't like being with strangers; it feels like it's going to be difficult",
          },
        ],
      },
      {
        which: 'EI',
        question:
          "What if a friend catches people's attention while dancing to exciting music at a festival?",
        answers: [
          {
            type: 'I',
            score: 2,
            content: `I really hate being the center of attention. I need to move somewhere else quickly`,
          },
          {
            type: 'E',
            score: 5,
            content: `With all the attention and the upbeat music playing, I feel good!!`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          "If a friend asks, 'What happens if you fall down a hill on a bicycle?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I think I'm going to get seriously hurt`,
          },
          {
            type: 'N',
            score: 5,
            content: `Wouldn't your whole body get scraped and start bleeding? It would hurt a lot, right?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'How would it be if there were really a magical helper like in the folktale?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              "Do you have to think about it when it can't actually be there?",
          },
          {
            type: 'N',
            score: 5,
            content: `I think it'll be very comfortable. But what does it look like?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'If one by one, your friends started announcing their wedding plans',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I guess it's about that time to get married since everyone else is`,
          },
          {
            type: 'N',
            score: 5,
            content: `What if I get married? Oh, I'm suddenly reminded of my friends' dark history`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What if your friend went storm shopping to get rid of the gloomy mood?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'What did you buy?',
          },
          {
            type: 'F',
            score: 5,
            content: 'Why were you feeling down? Are you okay now?',
          },
        ],
      },
      {
        which: 'TF',
        question:
          "What would you say if a friend asked you, 'What image would you like to leave?",
        answers: [
          {
            type: 'T',
            score: 2,
            content: `A person who is consistent and genuine!`,
          },
          {
            type: 'F',
            score: 5,
            content: 'Nice and sweet person!',
          },
        ],
      },
      {
        which: 'TF',
        question: `If a friend tries to solve a problem in a risky way based on emotional impulses`,
        answers: [
          {
            type: 'F',
            score: 2,
            content:
              "I understand your feelings, but that seems too extreme. Let's find another way together",
          },
          {
            type: 'T',
            score: 5,
            content:
              "Let's not get caught up in emotions and try to approach this more rationally",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'If a friend says they want to go somewhere different from the planned location',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Is it really necessary to go to a place that's not on our plan?`,
          },
          {
            type: 'P',
            score: 5,
            content: `If it's far from where we are now, let's skip it; if it's nearby, let's go!`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          "What if a friend asks you for a solution that he's too tired because he put off work too much?",
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "Let's prioritize and solve the most important things one by one!",
          },
          {
            type: 'P',
            score: 5,
            content:
              "Wouldn't it be less difficult later if we went from easy to difficult?",
          },
        ],
      },
      {
        which: 'JP',
        question:
          "'If a friend says they have a movie coupon that's about to expire and asks you to go with them'",
        answers: [
          {
            type: 'P',
            score: 2,
            content:
              "'Sure, when should we go? Let's decide on a date before the coupon expires!",
          },
          {
            type: 'J',
            score: 5,
            content:
              "Let's hurry up and schedule! I have time at this time, do you have time?",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/charSignEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/charSignEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/charSignEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/charSignEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/charSignEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/charSignEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/charSignEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/charSignEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/charSignEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/charSignEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/charSignEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/charSignEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/charSignEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/charSignEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/charSignEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/charSignEng/INFP.jpg',
      },
    ],
  },
  // charSign
  {
    info: {
      mainTitle: '성격 표지판 테스트',
      subTitle: '성격 표지판을 통해 알 수 있는 내 성격은?',
      mainUrl: 'charSign',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/charSign-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/charSign-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '많은 인파가 몰린 축제에 가게 됐다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '이게 바로 축제의 재미~ 사람도 많고 너무 좋아~~',
          },
          {
            type: 'I',
            score: 5,
            content: '이렇게까지 사람이 많은 거 맞아..? 보자마자 힘들어',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '친구가 낯선 사람들과 함께하는 활동이나 이벤트에 참여하자고 제안했다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '새로운 사람들을 만나고 다양한 경험도 할 수 있다니 정말 좋아!',
          },
          {
            type: 'I',
            score: 5,
            content:
              '낯선 사람들과 함께하는 것부터 마음에 들지 않아 힘들 것 같아..',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '축제에서 친구가 신나는 음악 박자에 맞춰 춤을 추면서 사람들의 주목을 받게 되었다면?',
        answers: [
          {
            type: 'I',
            score: 2,
            content: `주목받는 것은 진짜 싫은데 빨리 다른 곳으로 움직여야지`,
          },
          {
            type: 'E',
            score: 5,
            content: `사람들의 시선도 받고 신나는 노래도 흐르니까 기분 정말 최고야!!`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '친구가 “언덕길에서 자전거를 타고 내려오다가 넘어지면 어떻게 될까?”라고 물어봤다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `뭐 크게 다치지 않을까?`,
          },
          {
            type: 'N',
            score: 5,
            content: `온몸이 다 까지고 피가 나지 않을까? 엄청 아프겠지?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '친구가 “우렁각시가 실제로 있다면 어떨 것 같아?”라고 물어봤다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '실제로 있을 수가 없는데 굳이 생각해야 해?',
          },
          {
            type: 'N',
            score: 5,
            content: `엄청 편할 것 같은데? 근데 우렁각시는 어떻게 생겼을까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '주변 친구들이 하나 둘 결혼 소식을 전해온다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `이제 슬슬 결혼할 나이가 되긴 했나 봐 다 결혼하네`,
          },
          {
            type: 'N',
            score: 5,
            content: `내가 결혼을 하게 되면 어떨까? 아 갑자기 결혼하는 애들 흑역사가 떠오르는데?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 우울한 기분을 없애기 위해 폭풍 쇼핑을 했다고 한다면??`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '뭐 샀는데?',
          },
          {
            type: 'F',
            score: 5,
            content: '왜 우울했어? ㅜ 지금은 괜찮고?',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '친구가 “너는 사람들에게 어떤 이미지를 남기고 싶어?”라고 물어봤다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `앞뒤가 똑같은 사람!`,
          },
          {
            type: 'F',
            score: 5,
            content: '착하고 다정한 사람!',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 감정적 충동에 따라 위험한 방식으로 문제를 해결하려고 한다면?`,
        answers: [
          {
            type: 'F',
            score: 2,
            content:
              '너의 감정을 이해하지만 그건 너무 극단적인 것 같아ㅠ 함께 다른 방법을 찾아보자',
          },
          {
            type: 'T',
            score: 5,
            content: '감정에 휘말리지 말고 좀 더 이성적으로 접근해 보자',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구가 계획된 장소와는 다른 곳으로 가고 싶다고 말한다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `우리가 세운 계획에는 없는 곳인데 꼭 가야 해? `,
          },
          {
            type: 'P',
            score: 5,
            content: `지금 있는 곳에서 거리가 멀면 패스하고 가까우면 가자~`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          '친구가 일을 너무 미룬 탓에 너무 힘들다고 해결책을 알려 달라고 한다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '우선순위를 정해서 가장 중요한 일부터 하나씩 해결해 봐!',
          },
          {
            type: 'P',
            score: 5,
            content:
              '쉬운 일부터 시작해서 점점 어려운 일로 넘어가면 나중에 힘든 걸 덜 느낄 수 있지 않을까?',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '친구가 유효기간이 얼마 남지 않은 영화쿠폰이 있다며 같이 가자고 했다면?',
        answers: [
          {
            type: 'P',
            score: 2,
            content:
              '오 언제 보러 갈까? 유효기간이 끝나기 전에 일정은 차차 정하자고!',
          },
          {
            type: 'J',
            score: 5,
            content:
              '유효기간이 얼마 남지 않았다면 빨리 일정 잡자! 난 이때 시간 되는데 넌 시간 돼?',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/charSign/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/charSign/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/charSign/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/charSign/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/charSign/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/charSign/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/charSign/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/charSign/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/charSign/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/charSign/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/charSign/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/charSign/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/charSign/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/charSign/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/charSign/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/charSign/INFP.jpg',
      },
    ],
  },
  // underTheSeaCharEng
  {
    info: {
      mainTitle: 'Under the Sea Personality Test',
      subTitle: 'What would my deep sea look like?',
      mainUrl: 'underTheSeaCharEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/underTheSeaCharEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/underTheSeaCharEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'Where would my underwater living space be?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Center on a large rock with many sea friends',
          },
          {
            type: 'I',
            score: 5,
            content: 'A corner between unnoticed seaweed',
          },
        ],
      },
      {
        which: 'EI',
        question: 'After a long and tiring day, what about me?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: "I'm so tired and tired, do you want to meet me today?",
          },
          {
            type: 'I',
            score: 5,
            content: "I'm so tired and tired that I don't want to go anywhere",
          },
        ],
      },
      {
        which: 'EI',
        question: 'I found a secret place while swimming in the sea!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Invited several people to share a secret place`,
          },
          {
            type: 'I',
            score: 5,
            content: `Make it your own space without sharing it with anyone`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          "I'm appreciating a huge picture of the deep sea deep in the ocean!",
        answers: [
          {
            type: 'S',
            score: 2,
            content: `That's cool. The water is clear`,
          },
          {
            type: 'N',
            score: 5,
            content: `Wow, but how did that happen? What kind of life does it have?`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'When I explain and express something?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'Explaining what you can see with your eyes based on real experience',
          },
          {
            type: 'N',
            score: 5,
            content: `I draw a picture on my head and express it metaphorically`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What if you could breathe under the sea?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `You can still breathe with the equipment`,
          },
          {
            type: 'N',
            score: 5,
            content: `Then I want to go to the deep sea that no one has ever been to!`,
          },
        ],
      },
      {
        which: 'TF',
        question: `A friend who said that a strange deep-sea creature in the sea looks like me!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'With me? Where are you going? (Pure question)',
          },
          {
            type: 'F',
            score: 5,
            content: "Really? That's how I look? (Depressed)",
          },
        ],
      },
      {
        which: 'TF',
        question: 'What should I consider more when talking to people?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Objectivity of the conversation, final conclusion`,
          },
          {
            type: 'F',
            score: 5,
            content: 'Relationships with those people, feelings',
          },
        ],
      },
      {
        which: 'TF',
        question: `Saw a video of seals saving a drowning man!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "Seals do that? Isn't that a hoax?",
          },
          {
            type: 'F',
            score: 5,
            content: "How... It's really touching... I'm touched",
          },
        ],
      },
      {
        which: 'JP',
        question:
          "We decided to go on an underwater cave expedition! What's better?",
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Exploration that moves regularly according to the guide`,
          },
          {
            type: 'P',
            score: 5,
            content: `Exploration to see and move freely`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          "What about me in a situation where many people can't decide?",
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Direct lead',
          },
          {
            type: 'P',
            score: 5,
            content: 'Wandering together',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Time keeps getting delayed due to a mechanical failure while watching the deep sea with a submarine!',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "I'm under a lot of stress because my plan is not being followed",
          },
          {
            type: 'P',
            score: 5,
            content: "I think it's better to be able to see the ocean longer",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaCharEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaCharEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaCharEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaCharEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaCharEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaCharEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaCharEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaCharEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaCharEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaCharEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaCharEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaCharEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaCharEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaCharEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaCharEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaCharEng/INFP.jpg',
      },
    ],
  },
  // underTheSeaChar
  {
    info: {
      mainTitle: '언더 더 씨 성격 테스트',
      subTitle: '내 바닷속 심해는 어떤 모습일까?',
      mainUrl: 'underTheSeaChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/underTheSeaChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/underTheSeaChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '내 바닷속 생활 공간은 어디에 있을까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '바다 친구들이 많은 큰 바위 위 중심',
          },
          {
            type: 'I',
            score: 5,
            content: '최대한 눈에 띄지 않는 해초 사이 구석',
          },
        ],
      },
      {
        which: 'EI',
        question: '고단하고 지친 하루를 마치고 나서 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '너무 피곤하고 힘든데 오늘 만날래?',
          },
          {
            type: 'I',
            score: 5,
            content: '너무 피곤하고 힘들어서 아무 데도 안 나갈래..',
          },
        ],
      },
      {
        which: 'EI',
        question: '바닷속을 헤엄치다 비밀 장소를 발견했다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `여러 명을 초대해서 비밀 장소를 공유함`,
          },
          {
            type: 'I',
            score: 5,
            content: `아무에게도 공유하지 않고 혼자만의 공간으로 만듦`,
          },
        ],
      },
      {
        which: 'SN',
        question: '바닷속 깊은 심해를 찍은 거대한 사진을 감상 중이다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `멋지다 물이 맑네`,
          },
          {
            type: 'N',
            score: 5,
            content: `우와.. 근데 사진을 어떻게 찍은 거지? 어떤 생물들이 살까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 무언가를 설명하며 표현할 때 어떨까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '실제 경험하고 눈으로 본 것 위주로 표현',
          },
          {
            type: 'N',
            score: 5,
            content: `머릿속으로 그려가며 은유적으로 표현`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 바닷속에서 숨을 쉴 수 있다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `지금도 장비 있으면 숨 쉴 수 있잖아`,
          },
          {
            type: 'N',
            score: 5,
            content: `그러면 아무도 안 가본 저 심해 끝까지 가보고 싶어!`,
          },
        ],
      },
      {
        which: 'TF',
        question: `신기하게 생긴 바닷속 심해 생물이 나와 닮았다는 친구!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '나랑? 어디가? (순수한 질문)',
          },
          {
            type: 'F',
            score: 5,
            content: '진짜..? 내가 저렇게 생겼다는 거야..? (우울..)',
          },
        ],
      },
      {
        which: 'TF',
        question: '평소 사람들과 대화할 때 내가 더 고려 하는 것은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `그 대화의 객관적인 사실 여부, 마지막 결론`,
          },
          {
            type: 'F',
            score: 5,
            content: '그 사람들과의 관계, 느끼는 감정',
          },
        ],
      },
      {
        which: 'TF',
        question: `바다표범들이 물에 빠진 사람을 구해주는 영상을 보았다!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '바다표범들이 저런다고? 주작 아냐?',
          },
          {
            type: 'F',
            score: 5,
            content: '어떻게…ㅜ 진짜 너무너무 감동적이야… 마음이 뭉클해..',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '바닷속 동굴 탐사를 떠나기로 했다! 가이드 탐사 / 자유 탐사 중?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `정해준 규칙과 일정대로 움직이는 가이드 탐사`,
          },
          {
            type: 'P',
            score: 5,
            content: `자유롭게 구경하고 내 마음대로 움직이는 자유 탐사`,
          },
        ],
      },
      {
        which: 'JP',
        question: '여럿이 모여 결정하지 못하고 우물쭈물하는 상황에서 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '답답함에 직접 나서서 리드함',
          },
          {
            type: 'P',
            score: 5,
            content: '같이 우물쭈물하고 있음',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '잠수함으로 심해 구경 중! 그런데 기계 고장으로 시간이 계속 지연되고 있다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '계획이 틀어지는 이 상황에 굉장한 스트레스를 받음',
          },
          {
            type: 'P',
            score: 5,
            content: '심해를 더 구경할 수 있음에 오히려 좋다고 생각함',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/underTheSeaChar/INFP.jpg',
      },
    ],
  },
  // loveLegoEng
  {
    info: {
      mainTitle: 'Love LEGO! Test',
      subTitle: 'Love What is my love style that I see with Lego?',
      mainUrl: 'loveLegoEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveLegoEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveLegoEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: "'Are you stressed out? You don't look wel'",
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I elaborate on my troubles in detail',
          },
          {
            type: 'I',
            score: 5,
            content: 'I hide my troubles without mentioning them',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What if your lover asks you to go on a double date with your friend couple?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Double dates with new people? Sounds like fun',
          },
          {
            type: 'I',
            score: 5,
            content:
              "Let's not do it because I think it would be awkward to meet someone new",
          },
        ],
      },
      {
        which: 'EI',
        question: 'What if you have someone you like?',
        answers: [
          {
            type: 'I',
            score: 2,
            content: `I don't make it obvious, I only have feelings in my heart`,
          },
          {
            type: 'E',
            score: 5,
            content: `I actively appeal my favorite feelings`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What if your lover suddenly runs out saying she has a stomachache?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `It looks like you really had to go to the bathroom`,
          },
          {
            type: 'N',
            score: 5,
            content: `Was I in a hurry? I guess they went to the bathroom. Isn't it like a surprise event?`,
          },
        ],
      },
      {
        which: 'SN',
        question: `What if your lover asked, "What would you do if I turned small like a doll?"`,
        answers: [
          {
            type: 'S',
            score: 2,
            content: "It can't happen that a person turns into a doll",
          },
          {
            type: 'N',
            score: 5,
            content: `Should I put it in my bag? But if it changes, do I have to live as a doll forever?`,
          },
        ],
      },
      {
        which: 'SN',
        question: `What if an employee at a restaurant you visited for the second time with your lover says, "You're back again?"`,
        answers: [
          {
            type: 'N',
            score: 2,
            content: `Did my lover come here with someone else?`,
          },
          {
            type: 'S',
            score: 5,
            content: `It's been a while since I came here, how do you remember it? It's amazing`,
          },
        ],
      },
      {
        which: 'TF',
        question: `How do you feel when you see a couple crying and fighting in the middle of the street?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              "I don't feel much emotion because it's someone else's business",
          },
          {
            type: 'F',
            score: 5,
            content: 'Watching both couples fight in tears, I feel sad',
          },
        ],
      },
      {
        which: 'TF',
        question: 'What if your lover whines and asks for counseling?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Try to understand emotions and give solutions`,
          },
          {
            type: 'F',
            score: 5,
            content: "listen to one's concerns and provide comfort",
          },
        ],
      },
      {
        which: 'TF',
        question: `What if a lover asks you to apologize for cheating in a dream?`,
        answers: [
          {
            type: 'F',
            score: 2,
            content:
              "You may feel sad, but it's a dream, so why should I apologize?",
          },
          {
            type: 'T',
            score: 5,
            content: "I'm sorry (I don't understand but sympathize)",
          },
        ],
      },
      {
        which: 'JP',
        question:
          "What if your lover doesn't follow her planned date schedule?",
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I feel uncomfortable.`,
          },
          {
            type: 'P',
            score: 5,
            content: `I don't care.`,
          },
        ],
      },
      {
        which: 'JP',
        question: "When you talk about your week's schedule with your lover?",
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I tell the schedule by looking at my organized calenda',
          },
          {
            type: 'P',
            score: 5,
            content: 'I tell you according to the schedule that comes to mind',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What if my lover calls me for being late because miscalculated the time?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: "How can I miscalculate my time? I don't understand",
          },
          {
            type: 'P',
            score: 5,
            content:
              "I'm angry, but I'll move on because it's something I can do one day",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveLegoEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveLegoEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveLegoEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveLegoEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveLegoEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveLegoEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveLegoEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveLegoEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveLegoEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveLegoEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveLegoEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveLegoEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveLegoEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveLegoEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveLegoEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveLegoEng/INFP.jpg',
      },
    ],
  },
  // loveLego
  {
    info: {
      mainTitle: '연애 LEGO! 테스트',
      subTitle: '연애 LEGO로 보는 나의 연애 스타일은?',
      mainUrl: 'loveLego',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/loveLego-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveLego-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '“너 스트레스를 받았어? 기분 안 좋아 보여”라는 말을 들으면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '구구절절 자신의 고민을 말한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '고민을 말하지 않고 숨긴다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '연인이 자신의 친구 커플과 더블데이트를 하자고 한다면',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '새로운 사람들과 더블데이트? 재미있을 것 같은데?',
          },
          {
            type: 'I',
            score: 5,
            content: '새로운 사람 만나면 어색할 것 같으니 둘만의 데이트를 하자',
          },
        ],
      },
      {
        which: 'EI',
        question: '좋아하는 사람이 생겼다면?',
        answers: [
          {
            type: 'I',
            score: 2,
            content: `티 내지 않고 속으로 마음속으로만 감정을 품는다.`,
          },
          {
            type: 'E',
            score: 5,
            content: `적극적으로 좋아하는 감정을 어필한다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '데이트 도중 연인이 갑자기 배가 아프다며 뛰쳐나간다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `볼일이 많이 급했나 보네`,
          },
          {
            type: 'N',
            score: 5,
            content: `많이 급했나? 근데 진짜 화장실 간 것이겠지? 깜짝 이벤트 같은 거 아니야?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '연인이“만약에 내가 인형처럼 조그맣게 변하면 어떻게 할 거야?”라고 물어봤다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '사람이 인형으로 변하는 일은 일어날 수 없는데?',
          },
          {
            type: 'N',
            score: 5,
            content: `헐 귀여울 것 같아 가방 속에 넣고 다닐까? 근데 변하면 평생 인형으로 살아야 해?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '연인과 함께 두 번째로 방문한 식당에서 직원이 “또 오셨네요?”라고 한다면?',
        answers: [
          {
            type: 'N',
            score: 2,
            content: `전에 특별한 인상을 남겨줄 일이 있었나? 아니면 연인이 다른 사람이랑 왔던 건 아닐까?`,
          },
          {
            type: 'S',
            score: 5,
            content: `전에 온 것이 한참 전인데 어떻게 기억하시는 거지 신기해`,
          },
        ],
      },
      {
        which: 'TF',
        question: `길 한복판에서 커플이 울면서 싸우고 있는 것을 봤을 때 감정은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '남 일이기에 별 감정이 느껴지지 않는다.',
          },
          {
            type: 'F',
            score: 5,
            content: '커플 둘 다 울면서 싸우는 걸 보니 짠한 감정을 느낀다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '연인이 찡찡거리며 고민 상담을 요청한다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `감정을 이해하려고 노력하고 해결책을 제시해 준다.`,
          },
          {
            type: 'F',
            score: 5,
            content: '감정 이입을 하며 고민을 들어주고 위로를 해준다.',
          },
        ],
      },
      {
        which: 'TF',
        question: `연인이 꿈에서 당신이 바람을 피웠다고 사과하라고 한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '서운한 느낌이 들 수는 있지만 그건 꿈인데 왜 사과해야 해?',
          },
          {
            type: 'F',
            score: 5,
            content: '꿈속에 내가 나빴네! 미안해 (이해는 안 되지만 공감해 줌)',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인이 계획해 둔 데이트 일정대로 하지 않는다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `기분이 불편하다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `신경 쓰지 않는다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 한 주 스케줄에 대해 말할 때는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '정리해 둔 캘린더를 보며 스케줄을 말해준다.',
          },
          {
            type: 'P',
            score: 5,
            content: '생각나는 스케줄 대로 말해준다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인에게 시간 계산을 잘못해 늦을 것 같다는 연락을 받는다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '시간 계산을 어떻게 잘못할 수 있지? 이해할 수 없네',
          },
          {
            type: 'P',
            score: 5,
            content: '화나지만 나도 언젠가 그럴 수 있는 일이니까 넘어가 줄게',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/loveLego/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/loveLego/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/loveLego/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/loveLego/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/loveLego/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/loveLego/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/loveLego/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/loveLego/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/loveLego/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/loveLego/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/loveLego/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/loveLego/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/loveLego/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/loveLego/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/loveLego/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/loveLego/INFP.jpg',
      },
    ],
  },
  // likeLoveThought
  {
    info: {
      mainTitle: '00적 연애 사고력 테스트',
      subTitle: '날 한방에 꼬실 수 있는, 내 연애 사고력 알려드림!',
      mainUrl: 'likeLoveThought',
      scoreType: 'typeCountingMBTIName',
      mainImage:
        'https://images.ktestone.com/introImages/likeLoveThought-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/likeLoveThought-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
      nameLocation: {
        intro: {
          font: 'Jua',
          fontSize: 1.2,
          bottom: 25,
          pcFontSize: 2,
          pcBottom: 24,
        },
        result: {
          font: 'Jua',
          fontSize: 1.5,
          fontColor: 'black',
          result: 3.7,
          left: 8,
          pcFontSize: 2.5,
          pcResult: 6.3,
          pcLeft: 14,
        },
      },
    },
    questions: [
      {
        which: 'EI',
        question: '빵을 사러 갔더니 앞에서 다 팔렸네?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '다른 빵집을 가볼까?',
          },
          {
            type: 'I',
            score: 5,
            content: '괜히 나왔잖아ㅠ 바로 집으로 가야지..',
          },
        ],
      },
      {
        which: 'EI',
        question: '새로 이사한 집에서 귀신이 보인다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '귀신 얘기는 신박한 이야깃거리인데? 오늘부터 난 썰 부자?',
          },
          {
            type: 'I',
            score: 5,
            content: '우리 집에 귀신이 산다? 뭔가 특별한 친구가 생긴 것 같아',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구가 나오라고 공동현관 호출을 멈추지 않고 계속 시도하네?',
        answers: [
          {
            type: 'I',
            score: 2,
            content: `집 밖은 위험해.. 돌아가 줘~`,
          },
          {
            type: 'E',
            score: 5,
            content: `밖에 나가 놀 생각에 설레잖아~`,
          },
        ],
      },
      {
        which: 'SN',
        question: '애인의 자취방 냉장고에 반찬이 가득 차 있다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `혼자 밥 잘 챙겨 먹네~ 굶어 죽진 않겠어~`,
          },
          {
            type: 'N',
            score: 5,
            content: `부모님이 챙겨주나? 이러다 갑자기 오시는 거 아냐?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '애인이 삼겹살 사진을 보내면서 당신이 생각났다고 했다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '맛있겠따.. 왜 너만 먹어? ',
          },
          {
            type: 'N',
            score: 5,
            content: `뭐여 내가 돼지라는 겨?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '약속 장소로 향하던 중 개똥 냄새를 맡게 되었다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `똥 냄새가 나는 곳을 찾기 위해 고개를 두리번두리번 ~`,
          },
          {
            type: 'N',
            score: 5,
            content: `추억의 시골 냄새~ 할머니 뭐 하실까~?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `애인과 손잡고 걷는 중! 근데 내 손에서 땀이 줄줄 난다..`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '더우니까 당장 이 손 떼라잇!!!',
          },
          {
            type: 'F',
            score: 5,
            content: '더워도.. 우린 함께니까 내 땀도 나누자!!',
          },
        ],
      },
      {
        which: 'TF',
        question: '애인의 어릴 적 과거 사진을 보게 되었다!',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `역시 아기 때는 다 귀여운 듯?`,
          },
          {
            type: 'F',
            score: 5,
            content: '너무 귀여워ㅜㅜ 어릴 때 많이 홀리고 다녔지?',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 티켓 결제 직전 새로 고침을 눌러서 중요한 공연 예매를 놓쳤어`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '그걸 누르면 어떡해!! 아까운 내 티켓..',
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮아 다음이 있겠지..',
          },
        ],
      },
      {
        which: 'JP',
        question: '애인이 모든 생각을 긍정적으로만 한다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `그럼 긍정적으로 결과가 나옴?`,
          },
          {
            type: 'P',
            score: 5,
            content: `그럼 나도 긍정적인 사람 할게`,
          },
        ],
      },
      {
        which: 'JP',
        question: '약속 장소에 도착했는데 친구가 약속 파토를 냈다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '다른 거 할 시간 생김!',
          },
          {
            type: 'P',
            score: 5,
            content: '오 자유시간 생김!',
          },
        ],
      },
      {
        which: 'JP',
        question: '애인이 데이트하다가 주운 강아지를 키우자고 한다.',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '바로 동물 병원에 데려가 건강검진부터 해봄',
          },
          {
            type: 'P',
            score: 5,
            content: '냅다 이름부터 짓고 집으로 데려감',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/likeLoveThought/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/likeLoveThought/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/likeLoveThought/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/likeLoveThought/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/likeLoveThought/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/likeLoveThought/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/likeLoveThought/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/likeLoveThought/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/likeLoveThought/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/likeLoveThought/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/likeLoveThought/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/likeLoveThought/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/likeLoveThought/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/likeLoveThought/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/likeLoveThought/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/likeLoveThought/INFP.jpg',
      },
    ],
  },
  // dirtChar
  {
    info: {
      mainTitle: '먼지 캐릭터 성격 테스트',
      subTitle: '내 성격에 맞는 먼지 캐릭터는 무엇일까?',
      mainUrl: 'dirtChar',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/dirtChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/dirtChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '머리를 하러 미용실에 갔다! 그런데 자꾸 말을 거는 미용사!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '맞장구치며 친근하게 대화를 이어감',
          },
          {
            type: 'I',
            score: 5,
            content: '대답만 하고 말 안 걸어줬으면.. 하고 생각함',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구들에게 더 자주 듣는 말은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '좀 집에 가서 쉬어라 안 피곤해?',
          },
          {
            type: 'I',
            score: 5,
            content: '집 밖으로 좀 나와라 안 심심해?',
          },
        ],
      },
      {
        which: 'EI',
        question: '어색한 친구가 내 옷에 붙은 먼지를 떼어준다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `이렇게 친해지는 거지! 아무 상관 없음`,
          },
          {
            type: 'I',
            score: 5,
            content: `안 친한데 이런 터치는 좀 부담스럽다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '집에 가는 길에 먼지가 잔뜩 쌓인 인형이 버려져 있다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `인형을 저런 곳에 버려 놨네`,
          },
          {
            type: 'N',
            score: 5,
            content: `오래된 거 같은데 누가 왜 어쩌다가 저기에 버린 걸까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '나는 뭔가를 말로 표현할 때?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '직접 본 걸 토대로 확실히 전달될 수 있게 구체적으로 표현함',
          },
          {
            type: 'N',
            score: 5,
            content: `~같이 ~ 느낌을 사용해서 은유적으로 표현함`,
          },
        ],
      },
      {
        which: 'SN',
        question: '자주 가는 식당에 신 메뉴가 나왔다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `나중에 리뷰 보고 먹어 봐야겠다`,
          },
          {
            type: 'N',
            score: 5,
            content: `무슨 맛일지 궁금해! 먹어 볼래`,
          },
        ],
      },
      {
        which: 'TF',
        question: `내게 생긴 일에 대해 알려주는 이유는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '일이 생겼으니 알아 두라고',
          },
          {
            type: 'F',
            score: 5,
            content: '이런 일이 생겼으니 걱정해달라고',
          },
        ],
      },
      {
        which: 'TF',
        question: '대화 중 내 기분을 더 상하게 하는 것은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `사실이 아닌 내용을 우길 때`,
          },
          {
            type: 'F',
            score: 5,
            content: '내 기분 생각 안 하는 행동, 말투',
          },
        ],
      },
      {
        which: 'TF',
        question: `요즘 내 표정이 안 좋다며 무슨 일 있냐고 물어보는 지인!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '아무 일 없는데? 내가 힘들어 보여?',
          },
          {
            type: 'F',
            score: 5,
            content: '아무 일 없는데.. 날 신경 쓰고 있었구나 고마워ㅜ',
          },
        ],
      },
      {
        which: 'JP',
        question: '내 방의 평소 상태는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `먼지 쌓일 틈 없이 깔끔하게 정리 정돈`,
          },
          {
            type: 'P',
            score: 5,
            content: `여기저기 널브러져 있는 옷과 물건들`,
          },
        ],
      },
      {
        which: 'JP',
        question: '방 청소를 하려고 한다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '정해둔 순서대로 차근차근 청소',
          },
          {
            type: 'P',
            score: 5,
            content: '눈앞에 보이는 먼지부터 닦기',
          },
        ],
      },
      {
        which: 'JP',
        question: '나는 평소에?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '추진력이 있고 정한 결정은 바꾸지 않는다',
          },
          {
            type: 'P',
            score: 5,
            content: '결정을 잘 못 내리고 우유부단한 편이다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/dirtChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/dirtChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/dirtChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/dirtChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/dirtChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/dirtChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/dirtChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/dirtChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/dirtChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/dirtChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/dirtChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/dirtChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/dirtChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/dirtChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/dirtChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/dirtChar/INFP.jpg',
      },
    ],
  },
  // chatSalesEng
  {
    info: {
      mainTitle: 'Personality Special Discount Test',
      subTitle: 'What is the special discount rate for my personality today?',
      mainUrl: 'chatSalesEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/chatSalesEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/chatSalesEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          'The product I wanted to buy is on sale! What about me when I go to buy?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I go with my friend to buy, play, and come home.',
          },
          {
            type: 'I',
            score: 5,
            content: 'I go quietly alone and buy and come home right away',
          },
        ],
      },
      {
        which: 'EI',
        question:
          "I'm looking at discounted products. But the clerk is approaching!",
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I talk to the staff to get product recommendations',
          },
          {
            type: 'I',
            score: 5,
            content: "I pass quietly as if I didn't see it",
          },
        ],
      },
      {
        which: 'EI',
        question: 'A new friend confides in me a tough concern!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `You're my best friend now, telling me your concerns!`,
          },
          {
            type: 'I',
            score: 5,
            content: `Isn't this a bit of a burden between us?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'It is said that a product that was only sold at normal prices is suddenly getting a special discount!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Oh, it's on sale. I should go buy it.`,
          },
          {
            type: 'N',
            score: 5,
            content: `Why is there a sale all of a sudden? I wonder why.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'People are rushing in at the news of the start of the special discount!',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'There are too many people. They are giving a lot of discounts.',
          },
          {
            type: 'N',
            score: 5,
            content: `It's like a zombie.. I'm in big trouble if it falls over there.`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What about me when I buy the product?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Realistically, I only buy things that I really want to use`,
          },
          {
            type: 'N',
            score: 5,
            content: `I also buy things that I feel like I'll need later.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `My answer to a friend who is sad that she can't buy it because it's a first-come, first-served discount?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Well, go faster next time!',
          },
          {
            type: 'F',
            score: 5,
            content: "That's a shame. You'll have a chance!",
          },
        ],
      },
      {
        which: 'TF',
        question:
          "Clothes I bought after much consideration! But my friend says they don't go well with me.",
        answers: [
          {
            type: 'T',
            score: 2,
            content: `So I thought about it when I bought it too... It doesn't fit me?`,
          },
          {
            type: 'F',
            score: 5,
            content:
              "Why do you need to be so straightforward? I'm a little disappointed",
          },
        ],
      },
      {
        which: 'TF',
        question: `A friend who bought me a gift for giving me a discount on things I don't need!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "You don't have to buy me one!",
          },
          {
            type: 'F',
            score: 5,
            content:
              "Did you buy it because you thought of it? Thank you very much. I'll use it well!",
          },
        ],
      },
      {
        which: 'JP',
        question: "I'm going to throw away my desk at home and buy a new one!",
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Accurate size measurement and purchase`,
          },
          {
            type: 'P',
            score: 5,
            content: `Approximate measurement and purchase`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'I came to the department store to buy a discount product today!',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I only buy the product I was going to buy and it comes out.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'I look at other discount products and make impulsive purchases',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What about me when something unexpected happens?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I need time to get used to this situation',
          },
          {
            type: 'P',
            score: 5,
            content:
              "I'm quick to adapt to situations, and it's rather interesting",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/chatSalesEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/chatSalesEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/chatSalesEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/chatSalesEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/chatSalesEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/chatSalesEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/chatSalesEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/chatSalesEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/chatSalesEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/chatSalesEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/chatSalesEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/chatSalesEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/chatSalesEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/chatSalesEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/chatSalesEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/chatSalesEng/INFP.jpg',
      },
    ],
  },
  // chatSales
  {
    info: {
      mainTitle: '성격 특별 할인 테스트',
      subTitle: '오늘의 내 성격 특별 할인율은 얼마일까?',
      mainUrl: 'chatSales',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/chatSales-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/chatSales-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '관심 있던 물건이 오늘 특별 할인을 한다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구를 불러 같이 가서 산 후 더 놀다가 온다',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자 조용히 가서 사고 바로 집으로 온다',
          },
        ],
      },
      {
        which: 'EI',
        question: '할인 제품들을 구경 중! 그런데 점원이 다가온다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '직원에게 말을 걸며 제품을 추천받는다',
          },
          {
            type: 'I',
            score: 5,
            content: '못 본 척 조용히 자리를 피하며 지나간다',
          },
        ],
      },
      {
        which: 'EI',
        question: '새로 알게 된 친구가 나에게 힘든 고민을 털어놓는다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `나에게 고민을 털어놓는 너는 이제 나랑 절친!!!`,
          },
          {
            type: 'I',
            score: 5,
            content: `이런 얘기는 우리 사이에 좀 부담이지 않나..`,
          },
        ],
      },
      {
        which: 'SN',
        question: '늘 정상가에만 판매하던 제품이 갑자기 특별 할인을 한다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `오 할인하네 사러 가야겠다`,
          },
          {
            type: 'N',
            score: 5,
            content: `왜 갑자기 할인을 하는 거지? 이유가 뭘까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '특별 할인 시작 소식에 사람들이 일제히 우르르 달려간다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '사람 진짜 많다. 할인을 크게 하나 보네',
          },
          {
            type: 'N',
            score: 5,
            content: `뭔가 좀비들 같아.. 저기서 넘어지면 큰일 나겠는 걸`,
          },
        ],
      },
      {
        which: 'SN',
        question: '제품을 구매할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `현실적으로 내가 진짜 사용할 물건들만 구매`,
          },
          {
            type: 'N',
            score: 5,
            content: `뭔가 나중에 필요할 것 같은 느낌이 드는 물건들 구매`,
          },
        ],
      },
      {
        which: 'TF',
        question: `선착순으로 진행하는 특별 할인에 늦어 구매를 못했다는 친구에게 내 대답은? `,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '뭐 그럴 수 있지 다음에 할인할 땐 더 빨리 가봐!',
          },
          {
            type: 'F',
            score: 5,
            content: '사고 싶어 하던 건데 아쉽겠다.. 기회가 있을 거야!',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '고민하다가 구입한 특별 할인 옷! 그런데 친구가 나와 어울리지 않는다고 한다.?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `좀 안 어울리나.. 그래서 고민했던 건데 역시`,
          },
          {
            type: 'F',
            score: 5,
            content: '그렇게 직설적으로 말할 필요 있나.. 좀 서운하네',
          },
        ],
      },
      {
        which: 'TF',
        question: `내게는 필요 없는 물건을 할인한다며 선물로 사다 준 친구!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '엥 굳이 안 사다 줘도 됐는데!',
          },
          {
            type: 'F',
            score: 5,
            content: '내 생각나서 사 온 거야?ㅜ 너무 고마워 잘 쓸게!',
          },
        ],
      },
      {
        which: 'JP',
        question: '집에 있는 책상을 버리고 새로 구매하려고 한다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `정확한 사이즈를 재서 길이, 높이 등 확인 후 구매하러 간다`,
          },
          {
            type: 'P',
            score: 5,
            content: `대충 팔로 이 정도, 감으로 확인 후 구매하러 간다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '오늘 진행하는 할인 제품을 사려고 백화점에 왔다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '사려고 했던 제품만 사고 나온다',
          },
          {
            type: 'P',
            score: 5,
            content: '이왕 온 거 다른 할인 제품도 구경하고 충동구매한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '전혀 예상하지 못했던 일이 생겼을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '적응하는 데 시간이 걸리고 당황함',
          },
          {
            type: 'P',
            score: 5,
            content: '금방 적응해서 오히려 흥미진진해 함',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/chatSales/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/chatSales/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/chatSales/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/chatSales/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/chatSales/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/chatSales/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/chatSales/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/chatSales/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/chatSales/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/chatSales/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/chatSales/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/chatSales/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/chatSales/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/chatSales/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/chatSales/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/chatSales/INFP.jpg',
      },
    ],
  },
  // loveGeneEng
  {
    info: {
      mainTitle: 'Love Gene Test',
      subTitle: 'What does my dating gene look like?',
      mainUrl: 'loveGeneEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveGeneEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveGeneEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: 'What about me to the person you like?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Actively approach and express what you like.',
          },
          {
            type: 'I',
            score: 5,
            content: 'Expressing likeability in a secret way that only I know',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What genes did I inherit?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Active, Bright, Pleasant Genes',
          },
          {
            type: 'I',
            score: 5,
            content: 'A timid, shy, quiet gene',
          },
        ],
      },
      {
        which: 'EI',
        question: "What if I choose a time that's more precious to me?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Time to date a lover`,
          },
          {
            type: 'I',
            score: 5,
            content: `Personal time in your own space`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What does my gene look like?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `The human genes all look similar.`,
          },
          {
            type: 'N',
            score: 5,
            content: `I'm curious! I want to see it in person! Wouldn't it look like this?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          "I'm curious! I want to see it in person! Wouldn't it look like this?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Tells about the first meeting place, weather, etc.',
          },
          {
            type: 'N',
            score: 5,
            content: `Tells the atmosphere and feelings of the first meeting.`,
          },
        ],
      },
      {
        which: 'SN',
        question: "I can't reach you after arguing with my lover!",
        answers: [
          {
            type: 'S',
            score: 2,
            content: `You must be very angry. Let's find a way to relieve your anger.`,
          },
          {
            type: 'N',
            score: 5,
            content: `Are we breaking up...? I can imagine myself having a hard time after we break up`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What if you offered your lover a date you wanted to go on but refused?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Why? What should we do then? (Nothing)',
          },
          {
            type: 'F',
            score: 5,
            content: "Huh? (Sending a signal that's upset) Okay... ",
          },
        ],
      },
      {
        which: 'TF',
        question: 'My lover always gives me realistic advice..',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `It's the answer I want and I like it because it's helpful`,
          },
          {
            type: 'F',
            score: 5,
            content: "That's not what I want. I'm a little sad.",
          },
        ],
      },
      {
        which: 'TF',
        question: `What is the DNA that flows to me?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Efficient DNA',
          },
          {
            type: 'F',
            score: 5,
            content: 'Tears flowing DNA',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Keywords that can succeed in combining with my genes?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Organized, driven, organized`,
          },
          {
            type: 'P',
            score: 5,
            content: `Improvisational, Free, and Unplanned`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          "A lover who's upset with me because he's busy with his work!",
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "Lover and work are all important! Let's get things done first.",
          },
          {
            type: 'P',
            score: 5,
            content: "It's important who you love! I'll put my work behind me.",
          },
        ],
      },
      {
        which: 'JP',
        question: 'Time is left due to variable occurrence during the date!',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "It's such a waste of time.. let's see what we can do in the meantime.",
          },
          {
            type: 'P',
            score: 5,
            content:
              "There's nothing we can do about it. Shall we go in there and think about it?",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: ``,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveGeneEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: ``,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveGeneEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: ``,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveGeneEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: ``,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveGeneEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: ``,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveGeneEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: ``,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveGeneEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: ``,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveGeneEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: ``,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveGeneEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: ``,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveGeneEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: ``,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveGeneEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: ``,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveGeneEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: ``,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveGeneEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: ``,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveGeneEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: ``,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveGeneEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: ``,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveGeneEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: ``,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveGeneEng/INFP.jpg',
      },
    ],
  },
  // loveGene
  {
    info: {
      mainTitle: '연애 유전자 테스트',
      subTitle: '내 연애 유전자는 어떻게 생겼을까?',
      mainUrl: 'loveGene',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/loveGene-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveGene-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '호감이 가는 이성에게 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '적극적으로 다가가서 호감을 표현함',
          },
          {
            type: 'I',
            score: 5,
            content: '나만 알 수 있는 조용한 플러팅을 함',
          },
        ],
      },
      {
        which: 'EI',
        question: '나는 어떤 성향의 유전자를 물려받았을까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '활동적이고 밝고 유쾌한 유전자',
          },
          {
            type: 'I',
            score: 5,
            content: '소심하고 낯가리며 조용한 유전자',
          },
        ],
      },
      {
        which: 'EI',
        question: '내게 조금 더 소중한 시간을 고르라면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `연인과 함께 데이트하는 시간`,
          },
          {
            type: 'I',
            score: 5,
            content: `혼자만의 공간에서 가지는 개인적인 시간`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내 유전자는 어떻게 생겼을까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `사람 유전자가 다 비슷하게 생겼지`,
          },
          {
            type: 'N',
            score: 5,
            content: `궁금하다! 직접 보고 싶어! 이렇게 생기지 않았을까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인과의 첫 만남을 묻는 질문에 내 대답은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '첫 만남의 장소, 날씨 등을 얘기해 줌',
          },
          {
            type: 'N',
            score: 5,
            content: `첫 만남 때의 분위기, 느낌 등을 얘기해 줌`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인과 다투고 난 후 연락이 안 된다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `화가 많이 났나 보네.. 화를 풀어 줄 방법을 찾음`,
          },
          {
            type: 'N',
            score: 5,
            content: `우리 이별하는 거야..? 이별 후 힘들어하는 내 모습이 떠오름`,
          },
        ],
      },
      {
        which: 'TF',
        question: `하고 싶은 데이트를 애인에게 제안했지만 거절한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜? 그럼 뭐 할까? (아무렇지 않음)',
          },
          {
            type: 'F',
            score: 5,
            content: '응..? (삐친 거 시그널 보내기) 알겠어…',
          },
        ],
      },
      {
        which: 'TF',
        question: '내 연인은 항상 나에게 현실적인 조언만 해준다..',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `내가 바라는 답이고 도움이 되니까 오히려 좋음`,
          },
          {
            type: 'F',
            score: 5,
            content: '바라는 건 그게 아닌데.. 좀 서운해..',
          },
        ],
      },
      {
        which: 'TF',
        question: `내게 흐르는 DNA는 무엇일까?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '효율적으로 움직이고 생각하는 효율 DNA',
          },
          {
            type: 'F',
            score: 5,
            content: '툭하면 흐르는 눈물 DNA',
          },
        ],
      },
      {
        which: 'JP',
        question: '내 유전자와의 결합에 성공할 수 있는 키워드는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `체계적, 추진력, 정리 정돈`,
          },
          {
            type: 'P',
            score: 5,
            content: `유동적, 즉흥적, 대충대충`,
          },
        ],
      },
      {
        which: 'JP',
        question: '일이 바빠서 내게 서운하다는 연인!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '연인과 일 모두 중요해! 일단 일부터 끝낸다',
          },
          {
            type: 'P',
            score: 5,
            content: '연인이 중요하지! 일을 최대한 뒤로 미루고 만난다',
          },
        ],
      },
      {
        which: 'JP',
        question: '데이트 중 변수 발생으로 시간이 붕 뜬다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '시간이 너무 아까워.. 그동안 뭘 할 수 있을까 일정 다시 보자!',
          },
          {
            type: 'P',
            score: 5,
            content: '어쩔 수 없지 뭐.. 일단 저기 들어가서 생각해 볼까?',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: ``,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/loveGene/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: ``,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/loveGene/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: ``,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/loveGene/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: ``,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/loveGene/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: ``,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/loveGene/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: ``,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/loveGene/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: ``,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/loveGene/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: ``,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/loveGene/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: ``,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/loveGene/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: ``,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/loveGene/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: ``,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/loveGene/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: ``,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/loveGene/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: ``,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/loveGene/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: ``,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/loveGene/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: ``,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/loveGene/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: ``,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/loveGene/INFP.jpg',
      },
    ],
  },
  // bottleCap
  {
    info: {
      mainTitle: 'Bottle Cap 테스트',
      subTitle: 'Bottle cap을 통해 알 수 있는 너의 성격은?',
      mainUrl: 'bottleCap',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/bottleCap-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/bottleCap-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '주말에 집 밖을 나가는 것에 대한 생각은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구들과 놀 생각에 설렘 가득임',
          },
          {
            type: 'I',
            score: 5,
            content: '일단 나가는 것 자체가 큰 결심임',
          },
        ],
      },
      {
        which: 'EI',
        question: '집 안에 있다가 창문을 보니 햇살이 쏟아지고 있다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '날씨가 너무 좋은데? 밖에 당장 나가야 할 것 같아',
          },
          {
            type: 'I',
            score: 5,
            content: '날씨 참 좋네 집 안에서 편하게 바라보기만 하니까 더 좋고~',
          },
        ],
      },
      {
        which: 'EI',
        question: '약속 없이 집에서 쉬고 있을 때, 친구에게 전화가 걸려왔다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `놀자고 물어보는 연락이면 좋을 텐데 (고민 없이 전화를 바로 받는다)`,
          },
          {
            type: 'I',
            score: 5,
            content: `잘 쉬고 있는데 왜 연락이 온 거야.. 나오라고 연락한 건 아니겠지?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '비 소식은 없지만 먹구름으로 덮여 있다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `기온이 내려가고 바람이 불 수도 있겠네..`,
          },
          {
            type: 'N',
            score: 5,
            content: `비가 쏟아지면 어떡하지? 우산을 사야 하나? 갑자기 쏟아져도 내 옷은 괜찮나?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '귀여운 판다가 서울 도로를 건너고 있다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '..? 판다가 왜 서울 도로에 있지? 가능한 일인가?',
          },
          {
            type: 'N',
            score: 5,
            content: `귀여워 ㅠㅠ 판다가 굴러서 지나가고 있을까? 지나가고 있는 모습을 상상하게 돼`,
          },
        ],
      },
      {
        which: 'SN',
        question: '어린아이에게 T F 테스트를 하는 영상을 봤다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `저렇게 어린애한테도 T F가 가능하구나 신기해`,
          },
          {
            type: 'N',
            score: 5,
            content: `나도 아기 낳게 된다면 꼭 해볼까? 다른 질문으로 하게 되면 어떻게 대답할까?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 찡찡거리며 몇 시간 동안 같은 말을 반복해 끊고 싶은 상황이라면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '솔직하게 그만 통화하고 싶다고 말한다',
          },
          {
            type: 'F',
            score: 5,
            content: '끊자고 말하지 못하고 계속 통화를 이어 나간다',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 ‘너 진짜 성격 이상한 것 같아’라고 한다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `그렇게 느끼는 이유가 뭐야?`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '말을 너무 세게 말하는 거 아니야..? 속상해 ㅠㅠ 이유가 뭔데ㅠ 고칠 게',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 업무 중 사건이 발생해 멘붕이 왔다고 말한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '뭔 사건인데? 큰일이야?',
          },
          {
            type: 'F',
            score: 5,
            content: '너 멘탈은 괜찮아? 무슨 일이야!!',
          },
        ],
      },
      {
        which: 'JP',
        question: '번개 모임에 대한 생각은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `와 어떻게 즉흥적으로 약속을 잡지? 신기해`,
          },
          {
            type: 'P',
            score: 5,
            content: `너무 좋은데? 모든 일은 원래 즉흥적으로 움직이는 거지!`,
          },
        ],
      },
      {
        which: 'JP',
        question: '잘못된 정보로 인해 여행 스케줄이 꼬이게 됐다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '제대로 알아보지 못한 자신을 자책하고 다른 계획을 세운다',
          },
          {
            type: 'P',
            score: 5,
            content: '이것 또한 여행의 묘미라고 생각하고 다른 방향을 찾는다',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '친구에 집에 놀러 갔을 때 옷이 여기저기 흩어져 있는 것을 봤다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '깨끗하게 정리해 두는 것이 좋지 않나? 그래야 입고 싶은 옷을 한 번에 찾을 텐데',
          },
          {
            type: 'P',
            score: 5,
            content: '나와 비슷하게 살고 있네 뭐가 어디 있는 지만 알면 됐지!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/bottleCap/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/bottleCap/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/bottleCap/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/bottleCap/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/bottleCap/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/bottleCap/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/bottleCap/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/bottleCap/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/bottleCap/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/bottleCap/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/bottleCap/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/bottleCap/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/bottleCap/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/bottleCap/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/bottleCap/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/bottleCap/INFP.jpg',
      },
    ],
  },
  // systemErrorEng
  {
    info: {
      mainTitle: 'System Error Test',
      subTitle:
        'What is your factual character that you can tell from the system error?',
      mainUrl: 'systemErrorEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/systemErrorEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/systemErrorEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          "'What if it's raining on the day you have to go out for an appointment?'",
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              "'Since it's raining today, I guess I'll spend time at an indoor place.'",
          },
          {
            type: 'I',
            score: 5,
            content:
              "'Since it's raining, I feel even less like going out. I wonder if I can cancel...'",
          },
        ],
      },
      {
        which: 'EI',
        question: 'What if you happen to be left alone with an awkward friend?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'I see it as an opportunity to get closer and initiate a friendly conversation.',
          },
          {
            type: 'I',
            score: 5,
            content: 'I stay quiet while pondering what to say.',
          },
        ],
      },
      {
        which: 'EI',
        question: "What's your favorite expression method??",
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Verbal Expression`,
          },
          {
            type: 'I',
            score: 5,
            content: `Expressing in writing`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What if it suddenly went dead while you were excitedly messaging your friend?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Is there a fire in the data center?`,
          },
          {
            type: 'N',
            score: 5,
            content: `Am I the only one who can't do it? I'm not getting hacked right now, am I?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What if you saw someone doing weird things repeatedly abroad?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I think, "What's that? That person is strange, and move on.`,
          },
          {
            type: 'N',
            score: 5,
            content: `I imagine a lot here, maybe this isn't a weird thing to do.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What if you saw your friend wearing slippers on a rainy day?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `My feet are really wet; I wonder if they're slippery. (Considering only the currently visible aspects)`,
          },
          {
            type: 'N',
            score: 5,
            content: `I think I'm going to fall down while doing that (imagining what my friend looks like when he falls)`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What if your friend pointed out the wrong part during the consultation?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'I ask for a detailed explanation for what I said was the wrong part.',
          },
          {
            type: 'F',
            score: 5,
            content: 'I’m upset but try to hide it and say that I’ll fix it.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What if a friend tells you information sincerely that is not your concern?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I appreciate your soulless reaction or information, but let's talk about another topic.`,
          },
          {
            type: 'F',
            score: 5,
            content:
              'I give him a big reaction and listen to him until the end',
          },
        ],
      },
      {
        which: 'TF',
        question: `If a friend says they’ve scraped their knee after falling?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'How did you fall down? Did you apply medicine?',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Are you okay? Did you get hurt badly? Do you have any medicine? Be careful so you don’t end up with scars',
          },
        ],
      },
      {
        which: 'JP',
        question:
          "What if your friend asked you what you're doing this evening after you finish your work?",
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I provide the planned schedule.`,
          },
          {
            type: 'P',
            score: 5,
            content: `I say that there aren't any specific plans.`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What if your friend asked you what you were thinking the day before your trip',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "‘Think again in your head about the schedule and what you're missing.",
          },
          {
            type: 'P',
            score: 5,
            content: 'I think about having fun and enjoying the trip.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          "'What if a friend doesn't seem willing to make a travel plan?'",
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "I can't travel without a plan, so I plan it myself and share it.",
          },
          {
            type: 'P',
            score: 5,
            content:
              "I think that a lack of plans is part of the fun of traveling and don't make any arrangements together.",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/systemErrorEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/systemErrorEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/systemErrorEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/systemErrorEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/systemErrorEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/systemErrorEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/systemErrorEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/systemErrorEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/systemErrorEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/systemErrorEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/systemErrorEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/systemErrorEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/systemErrorEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/systemErrorEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/systemErrorEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/systemErrorEng/INFP.jpg',
      },
    ],
  },
  // systemError
  {
    info: {
      mainTitle: '시스템 에러 테스트',
      subTitle: '시스템 에러를 통해 알 수 있는 너의 팩폭 성격은?',
      mainUrl: 'systemError',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/systemError-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/systemError-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '약속을 나가야 하는 날 비가 오고 있다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '오늘은 비가 오니까 실내에 있는 가게에서 시간을 보내야겠네',
          },
          {
            type: 'I',
            score: 5,
            content: '비가 오니까 더 나가기 싫어지는데 취소 안 되려나..?',
          },
        ],
      },
      {
        which: 'EI',
        question: '우연히 어색한 친구와 둘이 남게 되었다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친해질 수 있는 기회라 생각하고 친근하게 말을 건다.',
          },
          {
            type: 'I',
            score: 5,
            content: '무슨 말을 꺼내야 할지 고민하면서 가만히 있는다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '평소 선호하는 표현 방법을 물어봤다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `말로 표현하는 것`,
          },
          {
            type: 'I',
            score: 5,
            content: `글로 표현하는 것`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구와 신나게 메시지를 주고받던 중 갑자기 먹통이 됐다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `엥 데이터센터에 불이라도 났나?`,
          },
          {
            type: 'N',
            score: 5,
            content: `나만 안되는 건가? 지금 해킹 당하고 있는 중 아니겠지?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '해외에서 이상한 행동을 반복하는 사람을 봤다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '뭐지 이상한 사람이네 하고 지나친다.',
          },
          {
            type: 'N',
            score: 5,
            content: `이곳에서는 이게 이상한 행동이 아닐 수도 있지 않을까? 등 많은 상상을 한다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '비가 많이 오는 날 친구가 슬리퍼를 신고 나온 것을 봤다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `발 엄청 젖었네 미끄럽지는 않나? (현재 보이는 부분만 생각함)`,
          },
          {
            type: 'N',
            score: 5,
            content: `저러다가 넘어질 것 같은데 (친구가 넘어졌을 때 모습을 상상함)`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구에게 고민 상담 도중 잘못된 부분을 지적받았다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '잘못된 부분이라고 말한 것에 대해 자세한 설명을 요청한다.',
          },
          {
            type: 'F',
            score: 5,
            content: '속상하지만 티는 못 내고 고치겠다고 말한다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 관심사가 아닌 정보를 정성껏 말해준다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `영혼 없는 리액션을 하거나 정보 제공은 고맙지만 다른 주제를 말하자고 한다.`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '이야기를 끊으면 친구가 속상할 것 같아 큰 리액션을 하며 이야기를 끝까지 들어준다.동',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 넘어져서 무릎이 까졌다고 말한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '어쩌다가 넘어지게 된 거야 약은 발랐어?',
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮아? 많이 다쳤어? 약은? 흉 지지 않게 조심해 ㅠㅠ',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구가 일과를 마친 후 오늘 저녁에 뭐 하냐고 물어봤다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `계획해 둔 스케줄을 말해준다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `딱히 별일 없다고 말해준다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '친구가 여행을 앞둔 하루 전날 어떤 생각을 하는지 물어봤다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '일정과 빠뜨린 물건이 없는지 다시 한번 머릿속으로 생각한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '여행에 가서 재미있게 놀 생각한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구가 여행 계획을 정하려는 의지가 없어 보인다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '계획 없이 여행을 갈 수 없어 직접 계획을 짠 후 공유한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '무계획도 여행의 재미라고 생각하며 같이 정하지 않는다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/systemError/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/systemError/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/systemError/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/systemError/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/systemError/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/systemError/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/systemError/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/systemError/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/systemError/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/systemError/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/systemError/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/systemError/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/systemError/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/systemError/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/systemError/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/systemError/INFP.jpg',
      },
    ],
  },
  // loveCalculator
  {
    info: {
      mainTitle: '연애도 계산 테스트',
      subTitle: '내 연애도를 계산기로 계산해 본 결과는?',
      mainUrl: 'loveCalculator',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveCalculator-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveCalculator-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '데이트를 할 때 주로 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '내 일상, 일과를 조잘조잘 떠들며 말하기 바쁨',
          },
          {
            type: 'I',
            score: 5,
            content: '연인이 하는 얘기를 집중해서 듣고 리액션 해 줌',
          },
        ],
      },
      {
        which: 'EI',
        question: '내 서운한 마음을 연인에게 전달하기 더 편한 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '서운하다고 직접 말로 하는 게 편함',
          },
          {
            type: 'I',
            score: 5,
            content: '서운한 마음을 글로 정리해 전달하는 게 마음 편함',
          },
        ],
      },
      {
        which: 'EI',
        question: '둘 중 데이트 일정을 하나만 고르라면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `평일에도 주말에도 데이트, 최소 4일 이상`,
          },
          {
            type: 'I',
            score: 5,
            content: `2주일에 딱 주말 하루 데이트, 한 달에 최대 3일`,
          },
        ],
      },
      {
        which: 'SN',
        question: '나는 연인이 하는 말들을?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `있는 그대로 받아들임`,
          },
          {
            type: 'N',
            score: 5,
            content: `한마디에도 의미를 부여하며 온갖 상상함`,
          },
        ],
      },
      {
        which: 'SN',
        question: '데이트 도중 내 가방을 잃어버려 찾고 있다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '최대한 찾아보자.. 못 찾으면 어쩔 수 없지..',
          },
          {
            type: 'N',
            score: 5,
            content: `못 찾으면 어쩌지? 다 다시 발급받으려면 얼마나 걸릴까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인에게 옷을 사주려고 한다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `후기나 제품 상세페이지를 꼼꼼하게 보고 구매`,
          },
          {
            type: 'N',
            score: 5,
            content: `연인이 이 옷을 입었을 때 느낌이 어떨지 머릿속으로 그려보고 구매`,
          },
        ],
      },
      {
        which: 'TF',
        question: `연인이 누군가 때문에 잔뜩 화가 났다면 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '그럴 땐 이렇게 저럴 땐 이렇게 조언해주기',
          },
          {
            type: 'F',
            score: 5,
            content: '같이 욕하면서 화내주기',
          },
        ],
      },
      {
        which: 'TF',
        question: '나를 위한 깜짝 선물을 준비한 연인! 내가 감동받은 포인트는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `내게 정말 필요한 선물을 해 준 것에 대한 감동`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '선물을 준비할 때 나를 위해 고민하고 생각한 과정에 대한 감동',
          },
        ],
      },
      {
        which: 'TF',
        question: `내 사소한 말 때문에 연인이 삐친 상태, 내 속마음은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '이런 거로 왜 삐치지? 이해 불가… ',
          },
          {
            type: 'F',
            score: 5,
            content: '내 말로 기분이 나쁠 수 있겠구나.. 조심해야지',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 약속을 잡을 때 보통?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `내가 찾아보고 어디 가자고 제시하는 편`,
          },
          {
            type: 'P',
            score: 5,
            content: `어디 가자고 하면 좋다고 따라가는 편`,
          },
        ],
      },
      {
        which: 'JP',
        question: '데이트 통장을 만들어 데이트 비용 예산을 미리 정해 두었다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '최대한 예산 안에서 데이트 일정을 짬',
          },
          {
            type: 'P',
            score: 5,
            content: '대략 짜놓고 혹시 부족하면 더 추가로 입금할 생각함',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '아무런 계획도 일정도 없이 자유롭게 움직이는 데이트를 한다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '불안하고 찝찝함 당장이라도 일정을 짜고 싶음',
          },
          {
            type: 'P',
            score: 5,
            content: '오늘은 어떤 데이트를 할까 설레고 즐거움',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: ``,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveCalculator/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: ``,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveCalculator/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: ``,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveCalculator/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: ``,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveCalculator/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: ``,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveCalculator/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: ``,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveCalculator/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: ``,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveCalculator/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: ``,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveCalculator/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: ``,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveCalculator/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: ``,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveCalculator/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: ``,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCalculator/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: ``,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveCalculator/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: ``,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveCalculator/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: ``,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCalculator/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: ``,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveCalculator/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: ``,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveCalculator/INFP.jpg',
      },
    ],
  },
  // teruterubozuEng
  {
    info: {
      mainTitle: 'Terteruboz test',
      subTitle: 'What is your personality that you see as a terteruboz?',
      mainUrl: 'teruterubozuEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/teruterubozuEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/teruterubozuEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
      hasBackground: true,
    },
    questions: [
      {
        which: 'EI',
        question: 'We went to a crowded festival! Where am I usually located?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'A crowded place',
          },
          {
            type: 'I',
            score: 5,
            content: 'A deserted corner',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Suddenly, my friend asked if I could call a friend!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: "It's better if there are a lot of people. Call me!",
          },
          {
            type: 'I',
            score: 5,
            content: "People who don't know me feel awkward and uncomfortable",
          },
        ],
      },
      {
        which: 'EI',
        question: "It's time to go home!",
        answers: [
          {
            type: 'E',
            score: 2,
            content: `It's sad that time goes by so fast!`,
          },
          {
            type: 'I',
            score: 5,
            content: `I'm finally going home~ I'm happy`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What if you mentioned the destination you went to with your friend?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Oh, it was fun. Let's go again next time`,
          },
          {
            type: 'N',
            score: 5,
            content: `The atmosphere was so nice and the food was delicious`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'Look at the sky. The clouds look like Teruteruboz.',
        answers: [
          {
            type: 'S',
            score: 2,
            content: "Oh, that's so cool. It's fascinating",
          },
          {
            type: 'N',
            score: 5,
            content: `Seeing that it's shaped like Teruteruboz., I think the weather will be nice today!`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What about me when I learn something from someone?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I want you to tell me everything in detail`,
          },
          {
            type: 'N',
            score: 5,
            content: `I just want you to tell me the overall direction`,
          },
        ],
      },
      {
        which: 'TF',
        question: `My friend who saw my Teruteruboz doll said, "Did you make it? As expected...`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "Why? It's not good?",
          },
          {
            type: 'F',
            score: 5,
            content:
              'Yes... Is it a little weird? (What do you mean? Is it weird?',
          },
        ],
      },
      {
        which: 'TF',
        question:
          "When you're not interested in the activities your friend asked you to do together?",
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I'm sorry, I don't want to`,
          },
          {
            type: 'F',
            score: 5,
            content: "I'll think about it...! (Failure to say no.)",
          },
        ],
      },
      {
        which: 'TF',
        question: `What if your friend looks angry?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "I ask why you're angry",
          },
          {
            type: 'F',
            score: 5,
            content: "I ask carefully what's going on",
          },
        ],
      },
      {
        which: 'JP',
        question: 'When shopping?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I go to the store and shop as it comes to mind`,
          },
          {
            type: 'P',
            score: 5,
            content: `Make a shopping list in advance and shop while checking`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'When hosting a meeting?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Set and prepare the meeting place, time, and number of people in advance',
          },
          {
            type: 'P',
            score: 5,
            content: 'The details will be decided on site',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What is your preferred appointment style?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Accurate reservation date and time',
          },
          {
            type: 'P',
            score: 5,
            content:
              'Rough plan, schedule that changes depending on the situation',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: ``,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozuEng/ESTJ.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=1vcBA38Ws4wzNf40KwYaGTCIuJCc0S3DX',
      },
      {
        type: 'ESTP',
        desc: ``,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozuEng/ESTP.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=1_rrJxw4gc-T1-YtHhacXeUrn-18B9uCW',
      },
      {
        type: 'ESFJ',
        desc: ``,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozuEng/ESFJ.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=1j-1XcHRfD0DA4FK7gYYbIHWCcR62pzUR',
      },
      {
        type: 'ESFP',
        desc: ``,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozuEng/ESFP.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=1kGioh-IUqICssR1xTYkrR_f707wl3Cm8',
      },
      {
        type: 'ENTJ',
        desc: ``,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozuEng/ENTJ.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=1K89DG9k_y8v0v3q8OMylT-HMCYg1LJ0k',
      },
      {
        type: 'ENTP',
        desc: ``,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozuEng/ENTP.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=1j5Uf3rDRLpwnVZk6r2wg8MD_KdJWAbG-',
      },
      {
        type: 'ENFJ',
        desc: ``,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozuEng/ENFJ.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=1pv4k2K4otOb3Jat1H6L2MZjKiydbLJcN',
      },
      {
        type: 'ENFP',
        desc: ``,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozuEng/ENFP.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=1hNSnxeIeP9Y3gTHBGuE-uUaOj9Snr1Wd',
      },
      {
        type: 'ISTJ',
        desc: ``,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozuEng/ISTJ.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=1isLYIMGQbSD7g0jdiAco1zaU1ZtioDfP',
      },
      {
        type: 'ISTP',
        desc: ``,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozuEng/ISTP.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=1Mf1ixFjPQFagI3E0RVsPQlV_J6GXm-cc',
      },
      {
        type: 'ISFJ',
        desc: ``,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozuEng/ISFJ.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=17XSVGVzSXU1PhA6ZTM0ww8NidIXcfBEM',
      },
      {
        type: 'ISFP',
        desc: ``,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozuEng/ISFP.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=1q9M3d_E9y_0-fagOdf8O1ETj8b9pJhde',
      },
      {
        type: 'INTJ',
        desc: ``,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozuEng/INTJ.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=1Po_geH5v-SR_6IHkZAns0aVvloCbkr3W',
      },
      {
        type: 'INTP',
        desc: ``,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozuEng/INTP.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=1Kcd7VG0oZ6iozCEHC03-XD3HALxd4l7y',
      },
      {
        type: 'INFJ',
        desc: ``,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozuEng/INFJ.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=1sKAt03Pyy3IEZpgpztYOcJePVfLfIi0f',
      },
      {
        type: 'INFP',
        desc: ``,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozuEng/INFP.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=1zfLF8e9fTXF-0stSPnkw-kg4-i55fncA',
      },
    ],
  },
  // teruterubozu
  {
    info: {
      mainTitle: '테루테루보즈 테스트',
      subTitle: '맑은 날씨를 불러온다는 테루테루보즈로 보는 너의 성격은?',
      mainUrl: 'teruterubozu',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/teruterubozu-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/teruterubozu-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
      hasBackground: true,
    },
    questions: [
      {
        which: 'EI',
        question:
          '사람이 많은 페스티벌을 가게 되었다! 그때 당신이 머무는 장소는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '인파가 붐비는 곳',
          },
          {
            type: 'I',
            score: 5,
            content: '사람이 없는 구석진 곳',
          },
        ],
      },
      {
        which: 'EI',
        question: '갑자기 친구가 자신의 친구를 불러도 되는지 물어봤다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '사람 많으면 오히려 좋아~ 불러!',
          },
          {
            type: 'I',
            score: 5,
            content: '나 모르는 사람은 어색하고 불편한데',
          },
        ],
      },
      {
        which: 'EI',
        question: '집으로 돌아갈 시간이 되었다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `시간 너무 빨리 지나가는 것 같아 아쉬워 ㅠㅠ`,
          },
          {
            type: 'I',
            score: 5,
            content: `드디어 집에 간다~~ 행복해`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구가 함께 갔던 여행지를 언급했다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `아 그때 재미있었지 다음에 또 가자`,
          },
          {
            type: 'N',
            score: 5,
            content: `거긴 ~가 유명한데 생각하니 먹고 싶어`,
          },
        ],
      },
      {
        which: 'SN',
        question: '“하늘 한번 봐 구름 모양이 꼭 테루테루보즈 같은데?”',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '오 진짜 느낌 있어 신기한데~~',
          },
          {
            type: 'N',
            score: 5,
            content: `저걸 보니 비가 올 것 같은데? 아 근데 내가 나오면서 우산을 챙겼었나?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '무언가를 배울 때는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `구체적인 세부사항으로 다 알려주길 원함`,
          },
          {
            type: 'N',
            score: 5,
            content: `전반적인 방향성만 알려주길 원함`,
          },
        ],
      },
      {
        which: 'TF',
        question: `내 테라테라보즈 인형을 본 친구가 “네가 만들었어? 아 역시..”라고 한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '뭐가 역시야? 별로야?',
          },
          {
            type: 'F',
            score: 5,
            content:
              '시중에 파는 것보다는 별로지..? (아 역시는 무슨 뜻이지? 이상하다는 건가..?',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 같이하자고 한 활동에 관심이 없을 때는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `미안해 난 그거에 대한 흥미가 없어서 같이 못할 것 같아`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '그래..! 근데 나 자신이 별로 없는데 괜찮아? (거절을 하지 못하고 돌려서 말함)',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 화난 것처럼 보인다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜 화가 난 것인지 물어본다',
          },
          {
            type: 'F',
            score: 5,
            content: '이유에 대해 물어보지 못하고 말해줄 때까지 눈치를 본다',
          },
        ],
      },
      {
        which: 'JP',
        question: '쇼핑을 할 때는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `쇼핑 목록을 미리 작성하고 체크하면서 쇼핑한다`,
          },
          {
            type: 'P',
            score: 5,
            content: `매장에 가서 그때그때 떠오르는 대로 쇼핑한다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '모임을 주최할 때는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '모임 장소, 시간, 참여 인원 등을 미리 정하고 철저히 준비한다',
          },
          {
            type: 'P',
            score: 5,
            content: '모임의 큰 틀만 정해두고 세부 사항은 현장에서 결정한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '선호하는 약속 스타일은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '약속 날짜와 시간을 정확하게 정한 것',
          },
          {
            type: 'P',
            score: 5,
            content: '대강 계획을 정하고 그날에 따라 약속을 조정하는 것',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: ``,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozu/ESTJ.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=1vrh31bvt_6RNndur95kvjFlU_kMuH6p3',
      },
      {
        type: 'ESTP',
        desc: ``,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozu/ESTP.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=15rst8BKNVZszFkYG_bvbJb-PjYO13VnL',
      },
      {
        type: 'ESFJ',
        desc: ``,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozu/ESFJ.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=1MrOQxzWNkaeBq6RwEP2rDr2dn5BaMbN1',
      },
      {
        type: 'ESFP',
        desc: ``,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozu/ESFP.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=1A3FHy8S2XHy1IID5ku1HKXFnJB_yFFOR',
      },
      {
        type: 'ENTJ',
        desc: ``,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozu/ENTJ.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=1nLUkbOYjsbMIcmtudlX0QXblOV8V3dVr',
      },
      {
        type: 'ENTP',
        desc: ``,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozu/ENTP.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=1gdW240z_ejn5VfemPbChq88eVpnD8d0s',
      },
      {
        type: 'ENFJ',
        desc: ``,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozu/ENFJ.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=1Z6nuOUp0AiNcHG-cCVpQfbIwfAfZf6Vw',
      },
      {
        type: 'ENFP',
        desc: ``,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozu/ENFP.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=19MwDlnsw-vCPKtjPVitPNvXwh529k7Wq',
      },
      {
        type: 'ISTJ',
        desc: ``,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozu/ISTJ.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=1IsobishaB_L4pqVyY5IvhC-DlDz1YTIs',
      },
      {
        type: 'ISTP',
        desc: ``,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozu/ISTP.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=1cJpQN6AIiNk9kG0pht3wLMQ19Kf56j_o',
      },
      {
        type: 'ISFJ',
        desc: ``,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozu/ISFJ.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=1xni9dfRdvmqsZINEiAcPIiQC9R9iUEy7',
      },
      {
        type: 'ISFP',
        desc: ``,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozu/ISFP.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=1e367Op5psQ8Dmw4X9X1Aa9Ffa3DwiJbG',
      },
      {
        type: 'INTJ',
        desc: ``,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozu/INTJ.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=1UTl4C2cWMM6--dFU4-3n3rdXHAxKBmEN',
      },
      {
        type: 'INTP',
        desc: ``,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozu/INTP.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=1tJU3Rg1_WhT_Onu54jSdLmlnSFaQLy-J',
      },
      {
        type: 'INFJ',
        desc: ``,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozu/INFJ.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=1JcyZneWQXa2PQvqHu1xy6RH0NhyxC4T_',
      },
      {
        type: 'INFP',
        desc: ``,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/teruterubozu/INFP.jpg',
        background_link:
          'https://docs.google.com/uc?export=download&id=1xYgP8oIk9UjS6kNWsMurhiWbvHNWtiRx',
      },
    ],
  },
  // messageBox
  {
    info: {
      mainTitle: '메시지 박스 테스트',
      subTitle: '메시지 박스로 보는 너의 성격은?',
      mainUrl: 'messageBox',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/messageBox-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/messageBox-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '눈앞에 메시지 박스가 생겼다! 그 안에 적힌 말은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '사람들이 많은 장소로 이동하자!',
          },
          {
            type: 'I',
            score: 5,
            content: '사람이 없는 한적한 장소로 이동하자!',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '메시지 박스가 ‘계획이 없는 주말, 오늘 하루는 어떻게 보낼 거야?’라고 물어봤다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '친구들에게 연락해서 만나거나 밖으로 나가서 활동을 즐길 거야!',
          },
          {
            type: 'I',
            score: 5,
            content: '집에서 혼자 뒹굴뒹굴하며 시간을 보낼 거야!',
          },
        ],
      },
      {
        which: 'EI',
        question: '메시지 박스를 통해 저녁 식사 모임에 초대받았다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `좋아! 사람들과 만날 생각에 신난다!`,
          },
          {
            type: 'I',
            score: 5,
            content: `집에서 쉬고 싶은데 꼭 가야 되는 걸까?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '잠자리에 들기 전 메시지 박스가 오늘 하루에 대해 요약해서 말해 달라고 했다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `하루 일과 중 주요사항만 나열하여 말한다`,
          },
          {
            type: 'N',
            score: 5,
            content: `하루 중 경험한 일과 느꼈던 기분에 대해 말한다`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '메시지 박스가 ‘10년 후 너는 무엇을 하고 있을까?’라고 물어봤다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '현재 다니는 직장에서 승진했거나 새로운 회사로 이직했을 듯?',
          },
          {
            type: 'N',
            score: 5,
            content: `지금껏 해보지 않은 일을 하고 있지 않을까?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '메시지 박스가 어디로든 이동시켜 주겠다며 장소를 말해 달라고 한다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `가보고 싶었던 해외 관광장소`,
          },
          {
            type: 'N',
            score: 5,
            content: `아무도 가보지 못한 미지의 장소`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 어려운 상황에 처해 있을 때 메시지 박스가 어떻게 도와주겠냐고 물어봤다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '문제를 해결하기 위한 해결책을 생각하고 제시해 준다',
          },
          {
            type: 'F',
            score: 5,
            content: '친구의 감정에 공감하며 위로와 해결책을 찾아준다',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '메시지 박스가 결정을 내릴 때 중요하게 고려하는 것에 대해 물어봤다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `자료와 데이터를 기반한 사실적 근거`,
          },
          {
            type: 'F',
            score: 5,
            content: '결정으로 인해 사람들에게 미치는 영향',
          },
        ],
      },
      {
        which: 'TF',
        question: `메시지 박스가 ‘너는 영화를 볼 때 눈물을 자주 흘리는 편이야?’라고 물어봤다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '실화가 아니면 거의 울지 않아',
          },
          {
            type: 'F',
            score: 5,
            content: '좀만 슬퍼도 눈물이 차올라',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '메시지 박스가 모임을 준비할 때에는 어떻게 하는지 물어봤다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `일정과 장소를 미리 정하고 참석자들과 일정을 조율한다`,
          },
          {
            type: 'P',
            score: 5,
            content: `일단 모임의 참여자를 구하고 나머지는 차차 생각한다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '메시지 박스와 함께 여행지를 고를 때 선택지는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '세세한 정보와 설명이 적혀 있는 것',
          },
          {
            type: 'P',
            score: 5,
            content: '간단한 그림과 짧은 설명이 적혀 있는 것',
          },
        ],
      },
      {
        which: 'JP',
        question: '메시지 박스가 당신에게 필요한 기능을 물어봤다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '계획한 일정을 원할 때마다 눈앞에 보여주는 기능',
          },
          {
            type: 'P',
            score: 5,
            content: '결정을 할 때마다 관련된 정보를 보여주는 기능',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: ``,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/messageBox/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: ``,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/messageBox/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: ``,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/messageBox/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: ``,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/messageBox/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: ``,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/messageBox/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: ``,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/messageBox/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: ``,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/messageBox/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: ``,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/messageBox/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: ``,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/messageBox/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: ``,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/messageBox/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: ``,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/messageBox/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: ``,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/messageBox/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: ``,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/messageBox/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: ``,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/messageBox/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: ``,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/messageBox/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: ``,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/messageBox/INFP.jpg',
      },
    ],
  },
  // charFactPokPickEng
  {
    info: {
      mainTitle: 'Personality Sick Burn Picking Test',
      subTitle: 'What is your personality hidden in the claw machine?',
      mainUrl: 'charFactPokPickEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/charFactPokPickEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/charFactPokPickEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'When you talk?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I tend to talk to the majority',
          },
          {
            type: 'I',
            score: 5,
            content: 'I tend to talk to only a few people',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What about me when I make an appointment with my friends?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I contact my friends first',
          },
          {
            type: 'I',
            score: 5,
            content: 'My friends contact me first',
          },
        ],
      },
      {
        which: 'EI',
        question: "When you're in the spotlight?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I'm happy and happy to receive attention`,
          },
          {
            type: 'I',
            score: 5,
            content: `I'm ashamed and don't want attention`,
          },
        ],
      },
      {
        which: 'SN',
        question: "What if a stranger's number is on your phone's friend list?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: `My friend must have changed her number`,
          },
          {
            type: 'N',
            score: 5,
            content: `What? Where did my number get sold?`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What if you were asked "what if"?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: "I don't understand why you're asking this question",
          },
          {
            type: 'N',
            score: 5,
            content: `Imagine the situation and give various answers`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'When you imagine the future?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Realistically imagine a possible future`,
          },
          {
            type: 'N',
            score: 5,
            content: `Imagine an ideal future`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What if a friend tells you his or her concerns?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Tells you the solution',
          },
          {
            type: 'F',
            score: 5,
            content: 'I sympathize with and comfort your concerns',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'When I talk to a counselor because there is a problem with the refund process?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I clearly state the reason for the refund`,
          },
          {
            type: 'F',
            score: 5,
            content:
              'I also talk about what I felt while explaining the reason for the refund',
          },
        ],
      },
      {
        which: 'TF',
        question: `What if a friend repeats the same story?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "I say I've heard this before",
          },
          {
            type: 'F',
            score: 5,
            content: 'I react as if I heard it for the first time',
          },
        ],
      },
      {
        which: 'JP',
        question: 'When you have an important schedule?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Write it down on your calendar in advance and prepare thoroughly`,
          },
          {
            type: 'P',
            score: 5,
            content: `Put the schedule roughly in your head and be aware of the previous day's schedule`,
          },
        ],
      },
      {
        which: 'JP',
        question: "What if there's a 50% chance of rain?",
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I take my umbrella with me because it might rain',
          },
          {
            type: 'P',
            score: 5,
            content:
              'When it rains, I go out without an umbrella thinking of buying one',
          },
        ],
      },
      {
        which: 'JP',
        question: "What if you plan a day's schedule?",
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I plan systematically',
          },
          {
            type: 'P',
            score: 5,
            content: 'I just sort out my things to do',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: ``,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPickEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: ``,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPickEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: ``,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPickEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: ``,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPickEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: ``,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPickEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: ``,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPickEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: ``,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPickEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: ``,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPickEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: ``,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPickEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: ``,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPickEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: ``,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPickEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: ``,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPickEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: ``,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPickEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: ``,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPickEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: ``,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPickEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: ``,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPickEng/INFP.jpg',
      },
    ],
  },
  // charFactPokPick
  {
    info: {
      mainTitle: '성격 팩폭 뽑기 테스트',
      subTitle: '뽑기 기계 속 숨겨져 있는 너의 팩폭 성격은?',
      mainUrl: 'charFactPokPick',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/charFactPokPick-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/charFactPokPick-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '이야기를 할 때는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '다수와 대화하는 편이다',
          },
          {
            type: 'I',
            score: 5,
            content: '소수와 대화하는 편이다',
          },
        ],
      },
      {
        which: 'EI',
        question: '약속을 잡을 때는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '먼저 친구에게 연락한다',
          },
          {
            type: 'I',
            score: 5,
            content: '친구한테서 연락이 온다',
          },
        ],
      },
      {
        which: 'EI',
        question: '많은 사람들에게 주목을 받을 때는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `신이 난 체 이야기를 주절주절 떠든다`,
          },
          {
            type: 'I',
            score: 5,
            content: `부끄럽고 관심받고 싶지 않다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '새로운 친구에 모르는 사람이 떴다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `누군가 번호를 바꿨나 보네`,
          },
          {
            type: 'N',
            score: 5,
            content: `뭐지? 내 번호가 어딘가로 팔렸나?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '만약에 질문을 받았을 때는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '왜 이런 걸 물어보는지 이해할 수 없다',
          },
          {
            type: 'N',
            score: 5,
            content: `그 상황을 상상하며 다양하게 답변을 낸다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '미래를 상상할 때에는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `현실적으로 가능한 미래를 상상함`,
          },
          {
            type: 'N',
            score: 5,
            content: `이상적으로 꿈꾸는 미래를 상상함`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 고민을 상담했다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '해결 방안을 말해준다',
          },
          {
            type: 'F',
            score: 5,
            content: '고민에 공감하여 위로를 해주고 같이 해결 방법을 찾는다',
          },
        ],
      },
      {
        which: 'TF',
        question: '환불 과정에 문제가 생겨 상담원과 통화를 할 때는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `명확하게 환불 사유에 대해 말한다`,
          },
          {
            type: 'F',
            score: 5,
            content: '환불 사유에 대해 설명하면서 느꼈던 감정도 토로한다',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 같은 이야기를 반복한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '전에 들었던 이야기라고 딱 잘라 말한다',
          },
          {
            type: 'F',
            score: 5,
            content: '처음 들은 것 마냥 리액션을 하며 들어준다',
          },
        ],
      },
      {
        which: 'JP',
        question: '중요한 일정이 생겼을 때는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `미리 캘린더에 적어 두고 철저하게 준비해 둔다`,
          },
          {
            type: 'P',
            score: 5,
            content: `일정을 머릿속으로 대강 넣어두고 전날 일정에 대해 인지한다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '비가 올 확률이 50%일 때는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '비가 올지도 모르니 일단 우산을 챙겨 외출한다',
          },
          {
            type: 'P',
            score: 5,
            content: '하늘에 맡기자는 생각으로 일단 우산 없이 외출한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '하루 일정을 계획한다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '실현 가능한 시간 안에서 체계적으로 계획한다',
          },
          {
            type: 'P',
            score: 5,
            content: '큰 틀로 해야 하는 일에 대해서만 정리한다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: ``,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPick/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: ``,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPick/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: ``,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPick/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: ``,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPick/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: ``,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPick/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: ``,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPick/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: ``,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPick/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: ``,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPick/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: ``,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPick/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: ``,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPick/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: ``,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPick/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: ``,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPick/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: ``,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPick/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: ``,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPick/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: ``,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPick/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: ``,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/charFactPokPick/INFP.jpg',
      },
    ],
  },
  // todayBentoCharEng
  {
    info: {
      mainTitle: `Today's lunch box personality test`,
      subTitle: `What is the right lunch box side dish for me?`,
      mainUrl: 'todayBentoCharEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/todayBentoCharEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/todayBentoCharEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: `I'm thinking of making and sharing lunch boxes separately with my friends!`,
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Call your friend and ask what menu you're going to make`,
          },
          {
            type: 'I',
            score: 5,
            content: `I search for what kind of lunch box to make and think about it alone`,
          },
        ],
      },
      {
        which: 'EI',
        question: `I arrived at the meeting place to eat lunch box, but there are so many people!`,
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'A crowded center place ',
          },
          {
            type: 'I',
            score: 5,
            content: 'A quiet place in a quiet corner',
          },
        ],
      },
      {
        which: 'EI',
        question: 'People sitting next to me talked to me to play together!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `It sounds fun. The more people there are, the better!`,
          },
          {
            type: 'I',
            score: 5,
            content: `I feel uncomfortable.. Let's just eat among ourselves`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'How are you going to make the lunch box?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I'll try my best to make it delicious!`,
          },
          {
            type: 'N',
            score: 5,
            content: `I'm going to shape it and make it look luxurious!`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What will be the main focus of the side dishes in my lunch box?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Side dishes with guaranteed flavors that I've made before`,
          },
          {
            type: 'N',
            score: 5,
            content: `Side dishes with new recipes that I'm trying for the first time`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'While working hard to make it, what am I thinking?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I think this will turn out well, and there should be enough quantity`,
          },
          {
            type: 'N',
            score: 5,
            content: `Won't everyone be surprised when they see my lunchbox? They'll think I bought it, right?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `If a friend who tasted my lunchbox says, 'It seems like you made it hastily, but it's delicious!'?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Oh, thank you, I feel proud.',
          },
          {
            type: 'F',
            score: 5,
            content: 'What do you mean... I worked really hard to make it...?',
          },
        ],
      },
      {
        which: 'TF',
        question: `It's hot where we planned to eat lunch, but there are lots of dense trees and birds flying around!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: `It's hot. Let's go to the shade because there are many trees.`,
          },
          {
            type: 'F',
            score: 5,
            content: `Is this summer sentiment? It's hot, but the scenery is so nice!`,
          },
        ],
      },
      {
        which: 'TF',
        question: `f a friend says during the meal that being with me is the most comfortable?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Really? Why? (Purely curious)',
          },
          {
            type: 'F',
            score: 5,
            content: `I'm glad to hear that! Of course, you're the most comfortable for me too!`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'After you decide what to put in your lunch box?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Make the menu without changing it`,
          },
          {
            type: 'P',
            score: 5,
            content: `The menu may change while making it`,
          },
        ],
      },
      {
        which: 'JP',
        question: `I'm making lunch boxes faster than I thought, so I think I'll have time!`,
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Make it slowly and leisurely in time.',
          },
          {
            type: 'P',
            score: 5,
            content: `Gets busier with thoughts of finishing quickly and resting`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'After finishing everything, what about the cleanup?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Kept tidy throughout, so there's not much to do afterward`,
          },
          {
            type: 'P',
            score: 5,
            content: `Piled up to do all at once, so there's a lot to tidy up afterward`,
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: ``,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoCharEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: ``,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoCharEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: ``,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoCharEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: ``,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoCharEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: ``,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoCharEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: ``,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoCharEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: ``,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoCharEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: ``,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoCharEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: ``,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoCharEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: ``,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoCharEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: ``,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoCharEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: ``,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoCharEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: ``,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoCharEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: ``,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoCharEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: ``,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoCharEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: ``,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoCharEng/INFP.jpg',
      },
    ],
  },
  // todayBentoChar
  {
    info: {
      mainTitle: '오늘의 도시락 성격 테스트',
      subTitle: '내게 맞는 도시락 반찬은 무엇일까?',
      mainUrl: 'todayBentoChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/todayBentoChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/todayBentoChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '친구들과 각자 도시락을 만들어 나눠 먹으려고 한다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구에게 전화해서 어떤 메뉴로 만들 건지 물어본다',
          },
          {
            type: 'I',
            score: 5,
            content: '어떤 도시락을 만들지 혼자 검색해 보며 고민한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '도시락을 먹으러 약속 장소에 도착했는데 사람이 너무 많다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '이왕이면 사람 많은 중심 자리',
          },
          {
            type: 'I',
            score: 5,
            content: '최대한 한적한 구석 조용한 자리',
          },
        ],
      },
      {
        which: 'EI',
        question: '옆자리에 앉은 사람들이 함께 놀자고 말을 건다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `재밌겠는데? 사람 많을수록 좋지!`,
          },
          {
            type: 'I',
            score: 5,
            content: `불편해.. 그냥 우리끼리 먹자`,
          },
        ],
      },
      {
        which: 'SN',
        question: '도시락을 어떻게 만들 거야?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `최대한 열심히 맛있게 만들어야지!`,
          },
          {
            type: 'N',
            score: 5,
            content: `난 ~모양을 내서 고급스러운 느낌으로 만들어 보려고!`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내 도시락 반찬들은 어떤 위주일까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '이전 만들어봤었던 맛이 보장된 반찬들',
          },
          {
            type: 'N',
            score: 5,
            content: `처음 만들어보는 새로운 레시피의 반찬들`,
          },
        ],
      },
      {
        which: 'SN',
        question: '열심히 만들던 도중에 내가 생각하는 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `이렇게 만들면 되겠지, 양은 충분하겠지`,
          },
          {
            type: 'N',
            score: 5,
            content: `내 도시락 보면 다들 놀라겠지? 사 온 거라고 생각하는 거 아냐?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `내 도시락을 맛본 친구가 ‘대충 만든 것 같은데 맛있다!’라고 한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '오 고마워 뿌듯하네',
          },
          {
            type: 'F',
            score: 5,
            content: '대충이라니.. 나 엄청 열심히 만들었는데…?',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '도시락을 먹기로 한 곳이 덥지만 울창한 나무가 많고 새들이 날아다닌다!',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `더워.. 나무 많으니까 저기 그늘로 가자`,
          },
          {
            type: 'F',
            score: 5,
            content: '이게 여름 감성인가~ 덥긴 하지만 풍경이 너무 좋다!',
          },
        ],
      },
      {
        which: 'TF',
        question: `먹던 도중 친구가 나랑 있는게 가장 편하다고 한다면 내 대답은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '그래? 왜?? (순수하게 궁금함)',
          },
          {
            type: 'F',
            score: 5,
            content: '(기분이 좋아짐) 나도 당연히 너가 제일 편하지!',
          },
        ],
      },
      {
        which: 'JP',
        question: '도시락에 넣을 메뉴를 정하고 나면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `정해둔 메뉴는 바꾸지 않고 만듦`,
          },
          {
            type: 'P',
            score: 5,
            content: `만들다가 메뉴가 달라지기도 함`,
          },
        ],
      },
      {
        which: 'JP',
        question: '생각보다 도시락을 빨리 만들고 있어서 시간이 남을 것 같다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '시간에 맞춰 만들기 위해 천천히 여유롭게 움직임',
          },
          {
            type: 'P',
            score: 5,
            content: '빨리 만들고 쉴 생각에 더 바빠짐',
          },
        ],
      },
      {
        which: 'JP',
        question: '다 만들고 나서 뒷정리는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '중간중간 깔끔하게 정리해놔서 크게 할 게 없음',
          },
          {
            type: 'P',
            score: 5,
            content: '한 번에 몰아서 하려고 쌓아놔서 정리할 게 많음',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: ``,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: ``,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: ``,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: ``,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: ``,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: ``,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: ``,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: ``,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: ``,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: ``,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: ``,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: ``,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: ``,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: ``,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: ``,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: ``,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/todayBentoChar/INFP.jpg',
      },
    ],
  },
  // insideEmotionControlJP
  {
    info: {
      mainTitle: 'インサイド感情コントロールテスト',
      subTitle: '私によく似合う感情は',
      mainUrl: 'insideEmotionControlJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/insideEmotionControlJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/insideEmotionControlJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'ストレスで涙が出たり不安な気持ちになったときは？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '他の人との時間を作ってストレスを発散する',
          },
          {
            type: 'I',
            score: 5,
            content: '一人の時間を作って気持ちを落ち着かせる',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '楽しくて明るくてうきうきした感情たちだけが集まったパーティーに呼ばれたら？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'ぜひ参加したいよ！一緒にパーティーで楽しもうよ！',
          },
          {
            type: 'I',
            score: 5,
            content:
              '参加はしたいけど、ちょっと落ち着いた感情が来たら行きたいな',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'ひとりぼっちになったような気分になったり、少し鬱になったら？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `ひとりぼっちなんて、耐えられないよ。誰でもいいからすぐ呼ぼう！`,
          },
          {
            type: 'I',
            score: 5,
            content: `少し寂しいかもしれないけど、悪くはないかな。しばらくはひとりでもいいかも`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'もしも、今まで私たちが感じた感情は「感情生命体」によって作られたものだったら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `感情生命体ってなんだよ。そういう非現実的なものは信じない。感情は感じるものではないの？`,
          },
          {
            type: 'N',
            score: 5,
            content: `私の感情生命体はどんなやつらなんだろう。お互い会話でもするのかな？気になるな`,
          },
        ],
      },
      {
        which: 'SN',
        question: '何もやるつもりはなかったのに、体が勝手に動いたら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '熱い鍋に触った時に思わず手を引っ込めたりする脊髄（せきずい）反射みたいなものなのかな',
          },
          {
            type: 'N',
            score: 5,
            content: `え？おかしいな。私って見えない誰かに操られているのかな`,
          },
        ],
      },
      {
        which: 'SN',
        question: '感情一つでこれからを生きるなら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `感情一つだけで人が生きるなんて不可能だよ`,
          },
          {
            type: 'N',
            score: 5,
            content: `一つだったら何がいいのかな。怒り？楽しい感情？`,
          },
        ],
      },
      {
        which: 'TF',
        question: `友達が隠し事をしているようだけどどうする？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '隠し事があるのか単刀直入に面と向かって聞いてみる',
          },
          {
            type: 'F',
            score: 5,
            content: '聞くことが怖くて結局聞けない',
          },
        ],
      },
      {
        which: 'TF',
        question: '不安そうでオドオドしている友達になんて助言する？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `不安なんて大したことないから克服しなよ。いつまでオドオドしているの`,
          },
          {
            type: 'F',
            score: 5,
            content: '大丈夫？もっとゆっくりでいいよ。きっとうまくいくからね！',
          },
        ],
      },
      {
        which: 'TF',
        question: `新しい友達と性格とか見解が違うことがあったら`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '自分の思うことについて正直に話す',
          },
          {
            type: 'F',
            score: 5,
            content: '友達の気を悪くするかもしれないから、何も言わず我慢する',
          },
        ],
      },
      {
        which: 'JP',
        question: '最近やることが多すぎる。寝る前にベッドで何を考えている？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `やり残したことについての不安感、明日は必ず全部終わらせたい`,
          },
          {
            type: 'P',
            score: 5,
            content: `どうしたら先延ばしにできるかな`,
          },
        ],
      },
      {
        which: 'JP',
        question: '普段自分が何かをするときに',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '計画を立てて行動する',
          },
          {
            type: 'P',
            score: 5,
            content: '行きあたりばったり行動する',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'やる気がない状態が続く中で大事な予定が明日まで近づいてきたら？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'まず、気持ちを整理して、大事な予定のための計画を立てる',
          },
          {
            type: 'P',
            score: 5,
            content:
              'やる気がない今なにかしてもうまくいかないから、明日気持ちが晴れたら対策する',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: ``,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: ``,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: ``,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: ``,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: ``,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: ``,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: ``,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: ``,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: ``,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: ``,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: ``,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: ``,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: ``,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: ``,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: ``,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: ``,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlJP/INFP.jpg',
      },
    ],
  },
  // insideEmotionControlEng
  {
    info: {
      mainTitle: 'Inside Emotion Control Test',
      subTitle: 'What kind of emotions would fit me well?',
      mainUrl: 'insideEmotionControlEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/insideEmotionControlEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/insideEmotionControlEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          'If you feel stressed and experience feelings of sadness and anxiety?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Express your emotions by meeting people and chatting',
          },
          {
            type: 'I',
            score: 5,
            content: 'Take time alone to sort out your emotions',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'If invited to a party filled with joyful and lively emotions?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'Those feelings are welcome! I want to have fun and fun, too!',
          },
          {
            type: 'I',
            score: 5,
            content: "I'll go when I gather some quiet emotions",
          },
        ],
      },
      {
        which: 'EI',
        question:
          "What if you feel depressed like you've fallen somewhere alone?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Alone? Absolutely unbearable. I need to call someone, anyone`,
          },
          {
            type: 'I',
            score: 5,
            content: `Feeling a bit lonely but not necessarily bad? It might even be comfortable`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What if you were living as intended by emotional beings without egos?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Emotional presence is unrealistic. Emotions are felt`,
          },
          {
            type: 'N',
            score: 5,
            content: `How would my emotional creature look like? Do they talk to each other?`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'If my body moved on its own despite not wanting to act?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'Is it an instinctive reaction, like a quick release of your hand when you touch a hot object?',
          },
          {
            type: 'N',
            score: 5,
            content: `I think someone invisible manipulated me?`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'If life were lived with only one emotion?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Can't live with just one emotion`,
          },
          {
            type: 'N',
            score: 5,
            content: `If only one, which emotion would be best to live with? Disgust? Joy?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `If it seems like friends are hiding a secret from you?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "Ask him straightforwardly if he's hiding anything",
          },
          {
            type: 'F',
            score: 5,
            content: 'I suffer silently without asking',
          },
        ],
      },
      {
        which: 'TF',
        question: 'If I see a friend struggling with anxious feelings?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Get through it! How long are you going to do that?`,
          },
          {
            type: 'F',
            score: 5,
            content:
              "Don't worry too much. Let's think positively; it will work out fine",
          },
        ],
      },
      {
        which: 'TF',
        question: `If the emotions I feel with newly made friends are different?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I am honest about my feelings',
          },
          {
            type: 'F',
            score: 5,
            content:
              "I don't tell my friends because I'm afraid they'll feel bad",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'These days, when I have a lot of work to do, what am I thinking in my head before going to bed?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Discomfort about unfinished tasks for the day, determination to finish them perfectly tomorrow`,
          },
          {
            type: 'P',
            score: 5,
            content: `Contemplation on how to delay things a bit longer`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'When I take action in daily life?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I intentionally plan and act',
          },
          {
            type: 'P',
            score: 5,
            content: 'Act as it flows',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What if an important schedule was just a day away from a situation where depressive feelings persisted?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I first calm my emotions and plan for the important event',
          },
          {
            type: 'P',
            score: 5,
            content:
              'First of all, we accept the current emotional state and respond when tomorrow approaches',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: ``,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlEng/ESTJ.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=1rPrYvK4B-G5isLOZea46cFsppaNYMztS',
      },
      {
        type: 'ESTP',
        desc: ``,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlEng/ESTP.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=1Up2Sr8bc8CYb4OBRetO3vpMJf-aafL9g',
      },
      {
        type: 'ESFJ',
        desc: ``,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlEng/ESFJ.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=1M47TMBEV9UZkeTlSul9wjBVRYhWNKWlI',
      },
      {
        type: 'ESFP',
        desc: ``,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlEng/ESFP.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=1KKiz1jADaCtGTPRla1QK83amD4dKvSqj',
      },
      {
        type: 'ENTJ',
        desc: ``,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlEng/ENTJ.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=18mS3nGn0V-LQSOwWkyTnq9xjwJoixbKf',
      },
      {
        type: 'ENTP',
        desc: ``,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlEng/ENTP.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=11rulNFspyfbsNRGmt9igFB2R908q7-wq',
      },
      {
        type: 'ENFJ',
        desc: ``,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlEng/ENFJ.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=19SudfmOFQVta9cYZe55x8J-LQFAycY_n',
      },
      {
        type: 'ENFP',
        desc: ``,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlEng/ENFP.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=1FkQSL_9rcyH0YAWBEHVKDrij_xIaJmOK',
      },
      {
        type: 'ISTJ',
        desc: ``,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlEng/ISTJ.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=1pysOtIVDev_7dzF9agN_Vs9qLcycKjuQ',
      },
      {
        type: 'ISTP',
        desc: ``,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlEng/ISTP.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=1Vl3HlRAZD9imkZhKeRcaGY-2WdWuHW_s',
      },
      {
        type: 'ISFJ',
        desc: ``,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlEng/ISFJ.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=1-tMgMVGxTLM6pYKAbPdBFuAk-UijrVUe',
      },
      {
        type: 'ISFP',
        desc: ``,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlEng/ISFP.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=1_YNpkppzRjPeSa8uq3nfwGjs34_s8H5F',
      },
      {
        type: 'INTJ',
        desc: ``,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlEng/INTJ.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=1GJNGLwmCE4osSC-Bz5UQ4vvKvwauMETZ',
      },
      {
        type: 'INTP',
        desc: ``,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlEng/INTP.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=1e9XNyI22F91HLoGEmDpGLbD_Sj0j7Luv',
      },
      {
        type: 'INFJ',
        desc: ``,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlEng/INFJ.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=1Ec4Xt2EU4SVWJo31c6IXrBOLrvJ0x2fG',
      },
      {
        type: 'INFP',
        desc: ``,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControlEng/INFP.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=1aRBkiain2eAzASDJ3XeEg0B8upSJQAPE',
      },
    ],
  },
  // insideEmotionControl
  {
    info: {
      mainTitle: '인사이드 감정 컨트롤 테스트',
      subTitle: '나는 어떤 감정이 잘 어울릴까?',
      mainUrl: 'insideEmotionControl',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/insideEmotionControl-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/insideEmotionControl-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
      hasWatchFace: true,
    },
    questions: [
      {
        which: 'EI',
        question: '스트레스를 받아서 슬프고 불안한 감정을 느낀다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '사람들을 만나 떠들면서 감정을 표출함',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자만의 시간을 가지면서 감정을 정리함',
          },
        ],
      },
      {
        which: 'EI',
        question: '즐겁고 신나는 시끄러운 감정들만 모인 파티에 초대된다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '그런 감정들은 환영이지! 나도 즐겁고 신나고 싶어!',
          },
          {
            type: 'I',
            score: 5,
            content: '좀 조용한 감정들 모이면 갈게..',
          },
        ],
      },
      {
        which: 'EI',
        question: '나 홀로 어딘가 떨어진 것 같은 우울한 느낌이 든다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `나 혼자라니.. 절대 견딜 수 없어 누구라도 불러야 해`,
          },
          {
            type: 'I',
            score: 5,
            content: `조금 외롭지만 나쁘지 않아 오히려 편할 수도?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '만약 자아가 없는 감정 생명체들의 의도대로 살아가고 있는 거라면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `감정 생명체라는 존재는 비현실적인데? 감정은 느끼지는 거지`,
          },
          {
            type: 'N',
            score: 5,
            content: `내 감정 생명체는 어떻게 생겼을까? 걔네끼리 대화도 하나?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '행동하고 싶지 않은데 몸이 멋대로 움직였다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '뜨거운 물체를 만졌을 때 손을 재빨리 떼는 것처럼 본능적인 반응인가?',
          },
          {
            type: 'N',
            score: 5,
            content: `보이지 않는 누군가가 나를 조종한 것 같은데?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '한 가지의 감정으로만 삶을 살게 된다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `한 가지 감정으로 못 살지`,
          },
          {
            type: 'N',
            score: 5,
            content: `딱 한 가지면 무슨 감정으로 사는 게 좋을까? 까칠? 기쁨?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구들이 당신만 모르는 비밀을 감추고 있는 것 같다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '숨기는 것이 있는지 단도직입적으로 물어본다',
          },
          {
            type: 'F',
            score: 5,
            content: '물어보지는 못하고 속으로 끙끙 앓는다',
          },
        ],
      },
      {
        which: 'TF',
        question: '불안한 감정에 휩싸여 허우적거리고 있는 친구를 본다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `이겨내! 언제까지 그럴 거야?`,
          },
          {
            type: 'F',
            score: 5,
            content: '너무 불안해하지 마~ 좋게 생각하자 잘 해결될 거야',
          },
        ],
      },
      {
        which: 'TF',
        question: `새로 사귄 친구들과 느끼는 감정이 다르다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '자신의 감정에 대해 솔직하게 말한다',
          },
          {
            type: 'F',
            score: 5,
            content: '친구들이 기분 나쁠까 봐 말하지 않고 참는다',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '할 일이 많은 요즘, 자기 전 내 머릿속에서는 무엇을 생각하고 있을까?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `오늘 끝내지 못한 일에 대한 불편함, 내일은 완벽히 지키겠다는 다짐`,
          },
          {
            type: 'P',
            score: 5,
            content: `어떻게 하면 좀 더 미룰 수 있는지에 대한 고민`,
          },
        ],
      },
      {
        which: 'JP',
        question: '일상생활에서 내가 어떤 행동을 할 때?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '의도적으로 계획하고 행동한다',
          },
          {
            type: 'P',
            score: 5,
            content: '흘러가는 대로 대처하여 행동한다',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '우울한 감정이 지속되는 상황에서 중요한 일정이 하루 앞으로 다가왔다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '일단 감정을 잠재우고 중요한 일정을 위한 일정을 짠다',
          },
          {
            type: 'P',
            score: 5,
            content:
              '우선 지금의 감정 상태를 받아들이고 내일이 다가왔을 때 대응하기로 한다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: ``,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControl/ESTJ.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=1zreSCYLmvoW2yONJkHDg79o-CovBPAbx',
      },
      {
        type: 'ESTP',
        desc: ``,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControl/ESTP.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=1ajom0qTeHGexXa2fPT75iIX5kKQcKn9L',
      },
      {
        type: 'ESFJ',
        desc: ``,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControl/ESFJ.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=1QEM9AuRZLtovOF-KKzTx-7he2SNOm-oJ',
      },
      {
        type: 'ESFP',
        desc: ``,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControl/ESFP.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=1gOagF2sTL8zyoNlQHAsE4VJuyopzN5UQ',
      },
      {
        type: 'ENTJ',
        desc: ``,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControl/ENTJ.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=1Cwa14cidQbLb4q7OdhTx8204cJZMjs9x',
      },
      {
        type: 'ENTP',
        desc: ``,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControl/ENTP.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=1OdV--6rlEY79JAcQ6h7xsVT8MkxTYqST',
      },
      {
        type: 'ENFJ',
        desc: ``,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControl/ENFJ.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=150-tqUT8KPbCiEtWUiIK3XZlEfDJ_gGy',
      },
      {
        type: 'ENFP',
        desc: ``,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControl/ENFP.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=1rVx-rTStRikEppjmiwQEleVm65Oael5O',
      },
      {
        type: 'ISTJ',
        desc: ``,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControl/ISTJ.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=1HQPysmlVzHhIX9uuvO5o-NMTJvtsbb4W',
      },
      {
        type: 'ISTP',
        desc: ``,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControl/ISTP.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=1ARdAiPQ0ggoqXSwXIoHmPv0e-crZbeyb',
      },
      {
        type: 'ISFJ',
        desc: ``,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControl/ISFJ.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=1t2G6RWCfgEQ2j9kgvE3Y1c9u1f5BvQtn',
      },
      {
        type: 'ISFP',
        desc: ``,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControl/ISFP.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=1Um5iqDbI_LqyChjnpfH84FW48NBnwrIP',
      },
      {
        type: 'INTJ',
        desc: ``,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControl/INTJ.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=1HCVn6Sq_38d3JdbO48iHE1sOhVt9uKo-',
      },
      {
        type: 'INTP',
        desc: ``,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControl/INTP.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=1bDBBH-Z2klQKs9mSd_YeaL5unRWFjiur',
      },
      {
        type: 'INFJ',
        desc: ``,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControl/INFJ.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=120d6cwofTwm2PxoWG9K1HQhaWYj-BLOa',
      },
      {
        type: 'INFP',
        desc: ``,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/insideEmotionControl/INFP.jpg',
        watch_face_link:
          'https://docs.google.com/uc?export=download&id=1ooKc13-JT8hJiUyhcLfE9Dy_kXBXg-Xq',
      },
    ],
  },
  // amazonSurvivor
  {
    info: {
      mainTitle: '아마존 생존 테스트',
      subTitle: '아마존에 홀로 떨어진 내가 챙긴 하나의 물건은?',
      mainUrl: 'amazonSurvivor',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/amazonSurvivor-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/amazonSurvivor-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '눈을 떠보니 아마존 한가운데에 홀로 서있다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '여기저기 돌아다니며 움직여 현 상황을 파악한다',
          },
          {
            type: 'I',
            score: 5,
            content: '어떻게 된 상황인지 가만히 서서 생각하며 파악한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '주변을 살펴보다 다행히 모여있는 사람들을 발견했다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '반가움에 다가가서 바로 말을 건다',
          },
          {
            type: 'I',
            score: 5,
            content: '반갑지만 선뜻 다가가진 못하고 서성거린다',
          },
        ],
      },
      {
        which: 'EI',
        question: '사람들과 함께 생존을 위한 집을 지으려고 한다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `같이 생활하면 그래도 외롭진 않을 듯? 다 함께 쓸 공간을 만들어야겠다`,
          },
          {
            type: 'I',
            score: 5,
            content: `같이 생활하는 건 너무 불편한데.. 나만의 공간을 만들어야겠다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 떨어진 아마존에 대해 설명한다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `나무랑 풀, 벌레가 엄청 많고 강이 있어서 습해`,
          },
          {
            type: 'N',
            score: 5,
            content: `당장 타잔이 튀어나올 것 같은 정글이야`,
          },
        ],
      },
      {
        which: 'SN',
        question: '아마존에 우리 말고 누군가의 흔적이 보인다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '원시인 같네 위험할 수 있으니 조심하자',
          },
          {
            type: 'N',
            score: 5,
            content: `원시인 아냐..? 어떻게 생겼을까? 식인종은 아니겠지`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '배가 너무 고파 식량을 찾다 왠지 먹을 수 있을 것 같은 버섯을 발견했다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `동물들이 먹었던 흔적이 있으니까 먹어도 되는 버섯 같아`,
          },
          {
            type: 'N',
            score: 5,
            content: `내 감과 느낌이 이 버섯은 먹어도 된다고 말하고 있어..!`,
          },
        ],
      },
      {
        which: 'TF',
        question: `일행 다 함께 머무를 집을 짓고 있는데 한 명이 농땡이를 피운다`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '이성을 유지하며 논리적으로 농땡이 피우지 말라고 경고한다',
          },
          {
            type: 'F',
            score: 5,
            content: '짜증 나고 화나는 감정이 앞서 뭐 하시는 거냐고 따진다',
          },
        ],
      },
      {
        which: 'TF',
        question: '잠시 휴식 중! 갑자기 순해 보이는 원숭이 무리가 찾아왔다!',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `우리 음식이라도 훔쳐 가면 곤란해 저리 가!`,
          },
          {
            type: 'F',
            score: 5,
            content: '쫓아내기엔 먼가 안쓰러운데.. 배가 고픈가 봐..',
          },
        ],
      },
      {
        which: 'TF',
        question: `어렵게 구한 식량으로 식사 중 일행 중 한명이 식량을 구정물에 떨어뜨려 먹지 못하게 되었다!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '본인 실수로 떨어뜨렸기에 어쩔 수 없다고 생각한다',
          },
          {
            type: 'F',
            score: 5,
            content:
              '안타까운 마음에 예의상 ‘제 것 좀 나눠드릴까요?’ 하고 물어본다',
          },
        ],
      },
      {
        which: 'JP',
        question: '생존을 위해 모은 식량과 물을 나눠 먹어야 한다',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `남은 식량으로 이 인원이 언제까지 버틸 수 있는지 철저하게 계산한다`,
          },
          {
            type: 'P',
            score: 5,
            content: `일단 대충 나눠서 먹고 나중에 식량이랑 물을 구하면 된다고 생각한다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '조사를 위해 무리를 지어 흩어졌다가 만나기로 한다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '시계 있으니까 몇 시, 몇 분까지 이 위치에 모여서 다음 장소로 이동하자',
          },
          {
            type: 'P',
            score: 5,
            content:
              '돌아다니다가 이 시간쯤에 모여 그리고 봐서 어디로 갈지 정하자',
          },
        ],
      },
      {
        which: 'JP',
        question: '갑자기 생각하지 못한 위기 상황이 발생한다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '전혀 생각하지 못했기에 뇌 정지가 오고 굉장히 당황한다',
          },
          {
            type: 'P',
            score: 5,
            content: '당황은 했지만 빠른 상황 파악으로 유연하게 대처한다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: ``,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/amazonSurvivor/ESTJ.jpg',
        coupang_link: 'https://link.coupang.com/a/bG1krG',
      },
      {
        type: 'ESTP',
        desc: ``,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/amazonSurvivor/ESTP.jpg',
        coupang_link: 'https://link.coupang.com/a/bG1kw9',
      },
      {
        type: 'ESFJ',
        desc: ``,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/amazonSurvivor/ESFJ.jpg',
        coupang_link: 'https://link.coupang.com/a/bG1kdG',
      },
      {
        type: 'ESFP',
        desc: ``,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/amazonSurvivor/ESFP.jpg',
        coupang_link: 'https://link.coupang.com/a/bG1kla',
      },
      {
        type: 'ENTJ',
        desc: ``,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/amazonSurvivor/ENTJ.jpg',
        coupang_link: 'https://link.coupang.com/a/bG1jMz',
      },
      {
        type: 'ENTP',
        desc: ``,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/amazonSurvivor/ENTP.jpg',
        coupang_link: 'https://link.coupang.com/a/bG1j4r',
      },
      {
        type: 'ENFJ',
        desc: ``,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/amazonSurvivor/ENFJ.jpg',
        coupang_link: 'https://link.coupang.com/a/bG1jqU',
      },
      {
        type: 'ENFP',
        desc: ``,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/amazonSurvivor/ENFP.jpg',
        coupang_link: 'https://link.coupang.com/a/bG1jvv',
      },
      {
        type: 'ISTJ',
        desc: ``,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/amazonSurvivor/ISTJ.jpg',
        coupang_link: 'https://link.coupang.com/a/bG1lb8',
      },
      {
        type: 'ISTP',
        desc: ``,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/amazonSurvivor/ISTP.jpg',
        coupang_link: 'https://link.coupang.com/a/bG1jhP',
      },
      {
        type: 'ISFJ',
        desc: ``,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/amazonSurvivor/ISFJ.jpg',
        coupang_link: 'https://link.coupang.com/a/bG1k0T',
      },
      {
        type: 'ISFP',
        desc: ``,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/amazonSurvivor/ISFP.jpg',
        coupang_link: 'https://link.coupang.com/a/bG1k81',
      },
      {
        type: 'INTJ',
        desc: ``,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/amazonSurvivor/INTJ.jpg',
        coupang_link: 'https://link.coupang.com/a/bG1kNA',
      },
      {
        type: 'INTP',
        desc: ``,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/amazonSurvivor/INTP.jpg',
        coupang_link: 'https://link.coupang.com/a/bG1kTO',
      },
      {
        type: 'INFJ',
        desc: ``,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/amazonSurvivor/INFJ.jpg',
        coupang_link: 'https://link.coupang.com/a/bG1kDZ',
      },
      {
        type: 'INFP',
        desc: ``,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/amazonSurvivor/INFP.jpg',
        coupang_link: 'https://link.coupang.com/a/bG1kIe',
      },
    ],
  },
  // colorBubbleLoveEng
  {
    info: {
      mainTitle: 'Color bubble love test',
      subTitle: 'My dating personality through color bubble',
      mainUrl: 'colorBubbleLoveEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/colorBubbleLoveEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/colorBubbleLoveEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: 'What if you went on a package trip with your lover?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              "It's nice to be together, but I think it'll be more fun because there are a lot of people",
          },
          {
            type: 'I',
            score: 5,
            content: "It's uncomfortable to go with people you don't know",
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What if your lover stepped away for a bit while you were with their friends?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: "Naturally, I talk with my lover's friends",
          },
          {
            type: 'I',
            score: 5,
            content:
              "It's awkward, so I can't talk to you first, but I just react",
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What if you had a full weekend schedule due to appointments with your lover and friends?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I like that I often get to go out instead of staying at home`,
          },
          {
            type: 'I',
            score: 5,
            content: `I already feel tired`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What if you got lost while taking a walk with your lover at the campsite?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `There's not going to be a dangerous wild animal all of a sudden, is there?`,
          },
          {
            type: 'N',
            score: 5,
            content: `What should I do? Should I call the manager?`,
          },
        ],
      },
      {
        which: 'SN',
        question: "What if you cook something you've never cooked before?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Follow the recipe through the Internet',
          },
          {
            type: 'N',
            score: 5,
            content: `I started by looking at the recipe, but later I cook with my own hunch`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What if you saw a dog tied up outside a pet supply store while waiting for your lover?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `The owner must have tied it up because they are inside buying something`,
          },
          {
            type: 'N',
            score: 5,
            content: `Isn't there a risk that someone other than the owner might take it away since it's left alone like that?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What if your lover canceled her plans because she wasn't feeling well?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Are you okay? Have you been to the hospital? Take medicine and get some rest',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Does it hurt a lot? Do you want me to get you some medicine?',
          },
        ],
      },
      {
        which: 'TF',
        question: 'What if your lover broke her savings and bought a car?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `How much did you pay? Show me your car`,
          },
          {
            type: 'F',
            score: 5,
            content: "Is it okay to break it since it's hard to save money?",
          },
        ],
      },
      {
        which: 'TF',
        question: `What if your lover is tired because she slept late?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'You should have gone to bed early!',
          },
          {
            type: 'F',
            score: 5,
            content: 'Are you tired? Can you make it through today?',
          },
        ],
      },
      {
        which: 'JP',
        question:
          "What do you do after you've decided where to go with your lover?",
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Search for a place and make a detailed schedule.`,
          },
          {
            type: 'P',
            score: 5,
            content: `Simply find a good restaurant or a place worth visiting`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What if something unexpected happened to your hard-working schedule for dating your lover?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "I'm stressed that things don't go according to the planned schedule",
          },
          {
            type: 'P',
            score: 5,
            content: 'Accept and respond quickly to a positive situation',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What if you pack for a trip with your lover?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Write down the necessary items on the list and check if there is anything missing and pack',
          },
          {
            type: 'P',
            score: 5,
            content: 'I pack what I need as soon as I think of it',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLoveEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLoveEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLoveEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLoveEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLoveEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLoveEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLoveEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLoveEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLoveEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLoveEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLoveEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLoveEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLoveEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLoveEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLoveEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLoveEng/INFP.jpg',
      },
    ],
  },
  // colorBubbleLove
  {
    info: {
      mainTitle: '색방울 연애 테스트',
      subTitle: '색방울로 보는 너의 연애 성격은?',
      mainUrl: 'colorBubbleLove',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/colorBubbleLove-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/colorBubbleLove-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '애인과 패키지여행을 가게 되었다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '둘이서도 좋지만 사람이 많으니까 더 즐거울 것 같아',
          },
          {
            type: 'I',
            score: 5,
            content: '모르는 사람들과 함께 가는 건 불편한데',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '애인의 친구들과의 약속 자리에서 애인이 잠시 자리를 비웠다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '자연스럽게 애인의 친구들과 이야기를 나눈다',
          },
          {
            type: 'I',
            score: 5,
            content: '어색해서 먼저 말을 걸지 못하고 리액션만 한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '애인과 친구들과의 약속으로 주말 스케줄이 꽉 찼다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `집이 아닌 밖에 나가는 일이 많아서 좋다`,
          },
          {
            type: 'I',
            score: 5,
            content: `벌써부터 기 빨리고 힘들다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '애인과 캠핑장에서 산책을 하던 중 길을 잃었다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `어떡하지? 관리인한테 전화를 해봐야 하나?`,
          },
          {
            type: 'N',
            score: 5,
            content: `갑자기 위험한 야생 동물이 나오는 건 아니겠지?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '애인에게 생전 해본 적 없는 요리를 해주게 된다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '인터넷을 통해 레시피를 그대로 따라 한다',
          },
          {
            type: 'N',
            score: 5,
            content: `레시피를 보며 시작했지만 나중에는 나만의 감을 따라서 요리한다`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '애인을 기다리던 도중 강아지가 생황용품점 앞에 묶여 있는 것을 봤다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `주인이 물건을 사느냐고 묶어 놨나 보네`,
          },
          {
            type: 'N',
            score: 5,
            content: `저렇게 혼자 있다가 주인이 아닌 모르는 사람이 데려가는 거 아냐?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `애인이 몸이 안 좋다며 약속을 취소했다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '괜찮아? 병원은 가봤고? 약 먹고 푹 쉬어',
          },
          {
            type: 'F',
            score: 5,
            content: '많이 아파? ㅠㅠ 내가 약 사다 줄까?',
          },
        ],
      },
      {
        which: 'TF',
        question: '애인이 저축한 돈을 깨고 차를 샀다고 한다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `얼마 주고 샀어? 차 한번 보여줘`,
          },
          {
            type: 'F',
            score: 5,
            content: '힘들게 저축한 돈인데 깨도 괜찮아?',
          },
        ],
      },
      {
        which: 'TF',
        question: `애인이 늦게 자서 그런지 피곤하다고 한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '그러게 일찍 잤어야지!',
          },
          {
            type: 'F',
            score: 5,
            content: '많이 피곤해? 오늘 하루 버틸 수 있겠어?',
          },
        ],
      },
      {
        which: 'JP',
        question: '애인과 놀러 갈 곳이 정해지고 난 후 행동은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `장소에 대해 검색해 보고 세세하게 일정을 짠다`,
          },
          {
            type: 'P',
            score: 5,
            content: `간단하게 맛집이나 가볼 만한 곳을 하나 정도 봐 둔다`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          '애인과의 데이트를 위해 열심히 계획한 일정에 예상치 못한 일이 생겼다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '계획한 일정대로 상황이 흘러가지 않아서 스트레스를 받는다',
          },
          {
            type: 'P',
            score: 5,
            content: '긍정적으로 흘러가는 상황을 받아들이고 빠르게 대처한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '애인과 여행을 위해 짐을 싸는 경우에는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '필요한 물건들을 리스트에 적고 빠트리는 것이 없는지 체크한 후 짐을 싼다',
          },
          {
            type: 'P',
            score: 5,
            content: '생각나는 대로 필요한 물건을 챙겨 짐을 싼다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLove/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLove/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLove/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLove/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLove/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLove/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLove/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLove/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLove/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLove/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLove/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLove/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLove/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLove/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLove/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/colorBubbleLove/INFP.jpg',
      },
    ],
  },
  // eggCharJP
  {
    info: {
      mainTitle: '卵キャラクター性格テスト',
      subTitle: '自分の性格を卵キャラクターで知ろう！',
      mainUrl: 'eggCharJP',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/eggCharJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/eggCharJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '友達と会って家に帰る途中、私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '少し遠回りしても友達に一緒に行こうと言う',
          },
          {
            type: 'I',
            score: 5,
            content: '一人で帰りたくて、寄るところがあると嘘をつく',
          },
        ],
      },
      {
        which: 'EI',
        question: '最近ダイエットのために卵ばかり食べている…',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '自分がダイエットしていることを周りのほとんどが知っている',
          },
          {
            type: 'I',
            score: 5,
            content: 'わざわざダイエットしていると言わないのでほとんど知らない',
          },
        ],
      },
      {
        which: 'EI',
        question: '家にいる時間について、私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `外の世界には経験すべきことがたくさんあるので、家にいる時間がもったいない`,
          },
          {
            type: 'I',
            score: 5,
            content: `外は危険で家は快適なので、家にいる時間がとても幸せ`,
          },
        ],
      },
      {
        which: 'SN',
        question: '「卵」と聞いて思い浮かぶのは？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `卵焼き、目玉焼き、卵を使った料理`,
          },
          {
            type: 'N',
            score: 5,
            content: `ダイエット、ツルツル卵肌、鶏が先か卵が先か`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'ダイエットのために卵だけ食べていたが、今日は暴食してしまった！',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '太りそうだから明日は軽く食べて運動をもっと頑張ろう',
          },
          {
            type: 'N',
            score: 5,
            content: `ダイエット失敗したんじゃない？食べる前に時間を戻したい…`,
          },
        ],
      },
      {
        which: 'SN',
        question: '初めての卵料理を作ろうとしている！',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `分量通りにレシピを守って、1カップ1スプーンずつ正確に料理する`,
          },
          {
            type: 'N',
            score: 5,
            content: `レシピは見るが、結局自分の感覚や感じに従って料理する`,
          },
        ],
      },
      {
        which: 'TF',
        question: `知人が作ってくれたオムレツ！でも卵の殻が混ざっている…`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '卵の殻が混ざっていると言う',
          },
          {
            type: 'F',
            score: 5,
            content: '言うと気まずいので、そのまま食べて美味しいと言う',
          },
        ],
      },
      {
        which: 'TF',
        question: '私をコントロールするものは何だろう？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `ほとんど感情よりも論理と理性がコントロールしている`,
          },
          {
            type: 'F',
            score: 5,
            content: '一般的に論理よりも感情が私をコントロールしている',
          },
        ],
      },
      {
        which: 'TF',
        question: `精巧で美しい絵が描かれた卵をもらった！`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '卵にこんなに精巧に描かなくてもいいのに…ありがとう！',
          },
          {
            type: 'F',
            score: 5,
            content:
              '本当に綺麗に描いたね？小さいのに描くのが大変だったろうね、ありがとう',
          },
        ],
      },
      {
        which: 'JP',
        question: '初めて経験する新しい状況に？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `適応するのに時間がかかる`,
          },
          {
            type: 'P',
            score: 5,
            content: `適応が早い`,
          },
        ],
      },
      {
        which: 'JP',
        question: '何かをすることに決めて動くとき、私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '明確な目的と方向を定めて動く',
          },
          {
            type: 'P',
            score: 5,
            content: '目的と方向はいつでも変わる可能性があり、動きながら決める',
          },
        ],
      },
      {
        which: 'JP',
        question: '時間という資源について、私はどう考えるか？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '期限が決まっている限られた資源',
          },
          {
            type: 'P',
            score: 5,
            content: '柔軟に変化する資源',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/eggCharJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/eggCharJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/eggCharJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/eggCharJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/eggCharJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/eggCharJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/eggCharJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/eggCharJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/eggCharJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/eggCharJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/eggCharJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/eggCharJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/eggCharJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/eggCharJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/eggCharJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/eggCharJP/INFP.jpg',
      },
    ],
  },
  // eggCharEng
  {
    info: {
      mainTitle: 'Egg character personality test',
      subTitle: `Let's find out my personality with egg characters!`,
      mainUrl: 'eggCharEng',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/eggCharEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/eggCharEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'What about me on my way home after meeting my friends?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I ask my friends to come with me',
          },
          {
            type: 'I',
            score: 5,
            content:
              "I make an excuse that there's a place to stop by because I want to go alone",
          },
        ],
      },
      {
        which: 'EI',
        question: "These days, I'm only eating eggs because I'm on a diet..",
        answers: [
          {
            type: 'E',
            score: 2,
            content: "Most people around me know that I'm on a diet",
          },
          {
            type: 'I',
            score: 5,
            content:
              "Most people don't know because I didn't tell them that I was on a diet",
          },
        ],
      },
      {
        which: 'EI',
        question: 'What if you stay home for a long time?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `There's a lot to experience outside, so it's fun and it's a waste of time to stay home`,
          },
          {
            type: 'I',
            score: 5,
            content: `It's so comfortable and nice to be home alone`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What comes to mind when you think of "egg"?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Egg rolls, fried eggs, foods using eggs`,
          },
          {
            type: 'N',
            score: 5,
            content: `Diet, Smooth Skin Like an Egg, Was the chicken born first or the egg`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'I only ate eggs because of my diet, but I binge ate today!',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              "You're going to gain weight. You have to eat lightly and work out harder tomorrow",
          },
          {
            type: 'N',
            score: 5,
            content: `I want to go back in time before I eat`,
          },
        ],
      },
      {
        which: 'SN',
        question: "I'm going to make an egg dish that I've never tried before!",
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Cook 1 cup and 1 spoon according to the recipe according to the quantity`,
          },
          {
            type: 'N',
            score: 5,
            content: `I look at the recipe, but I end up cooking according to my feelings and feelings`,
          },
        ],
      },
      {
        which: 'TF',
        question: `An omelette made by an acquaintance! But the eggshell is chewed!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'It says right away that the eggshell is chewed',
          },
          {
            type: 'F',
            score: 5,
            content:
              "I'm afraid it'll be embarrassing if I say it, so I just chew it and say it's delicious",
          },
        ],
      },
      {
        which: 'TF',
        question: 'What controls me?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `For the most part, logic and reason control rather than emotion`,
          },
          {
            type: 'F',
            score: 5,
            content: 'In general, emotions control me rather than logic',
          },
        ],
      },
      {
        which: 'TF',
        question: `Got an egg with an elaborate and pretty picture on it as a gift!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "You don't have to draw the egg so finely..thank you!!",
          },
          {
            type: 'F',
            score: 5,
            content:
              "You drew it really well. It must have been hard to draw it even though it's small. Thank you",
          },
        ],
      },
      {
        which: 'JP',
        question: "In a new situation that I've never experienced before?",
        answers: [
          {
            type: 'J',
            score: 2,
            content: `It takes time to adapt`,
          },
          {
            type: 'P',
            score: 5,
            content: `I tend to adapt well`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'What about me when I decide to do something and act?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Move with a clear purpose and direction',
          },
          {
            type: 'P',
            score: 5,
            content:
              'Objectives and directions are always changeable, so they are determined as they move',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What do I think of the resources of time?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Limited resources with fixed time limits',
          },
          {
            type: 'P',
            score: 5,
            content: 'Resources that change elastically from moment to moment',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/eggCharEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/eggCharEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/eggCharEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/eggCharEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/eggCharEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/eggCharEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/eggCharEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/eggCharEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/eggCharEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/eggCharEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/eggCharEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/eggCharEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/eggCharEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/eggCharEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/eggCharEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/eggCharEng/INFP.jpg',
      },
    ],
  },
  // eggChar
  {
    info: {
      mainTitle: '달걀 캐릭터 성격 테스트',
      subTitle: '내 성격을 달걀 캐릭터로 알아보자!',
      mainUrl: 'eggChar',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/eggChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/eggChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '친구들을 만나고 집에 가는 길에 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '좀 돌아가더라도 친구들한테 같이 가자고 함',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자 가고 싶어서 들를 곳 있다고 핑계 댐',
          },
        ],
      },
      {
        which: 'EI',
        question: '요즘 다이어트한다고 달걀만 먹고 있다..',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '내가 다이어트하는 걸 주변 대부분이 알고 있음',
          },
          {
            type: 'I',
            score: 5,
            content: '굳이 다이어트한다고 알리진 않아서 대부분 모른다',
          },
        ],
      },
      {
        which: 'EI',
        question: '나는 집에만 있는 시간이?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `바깥세상에 경험할 게 얼마나 많은데 집에만 있기엔 시간이 아까움`,
          },
          {
            type: 'I',
            score: 5,
            content: `밖은 위험하고 집은 편안하고, 집에만 있는 시간이 너무 행복함`,
          },
        ],
      },
      {
        which: 'SN',
        question: '‘달걀’ 하면 떠오르는 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `계란말이, 계란프라이, 계란을 이용한 음식들`,
          },
          {
            type: 'N',
            score: 5,
            content: `다이어트, 깐 달걀 피부, 닭이 먼저일까 달걀이 먼저일까`,
          },
        ],
      },
      {
        which: 'SN',
        question: '다이어트 때문에 달걀만 먹다가 오늘은 폭식을 해버렸다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '살찌겠다 내일은 가볍게 먹고 운동 더 열심히 해야지',
          },
          {
            type: 'N',
            score: 5,
            content: `나 다이어트 망한 거 아니야..? 먹기 전으로 시간 되돌리고 싶어..`,
          },
        ],
      },
      {
        which: 'SN',
        question: '처음 해보는 달걀 요리를 만들어 보려고 한다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `정량에 맞춰 레시피 대로 1컵 1스푼 지키며 요리함`,
          },
          {
            type: 'N',
            score: 5,
            content: `레시피는 보지만 결국 내 감, 느낌에 따라 요리함`,
          },
        ],
      },
      {
        which: 'TF',
        question: `지인이 만들어 준 오믈렛! 근데 달걀 껍데기가 씹힌다!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '달걀 껍데기가 씹힌다고 바로 말한다',
          },
          {
            type: 'F',
            score: 5,
            content: '말하면 무안할까 봐 그냥 씹어 먹고 맛있다고 말한다',
          },
        ],
      },
      {
        which: 'TF',
        question: '나를 컨트롤하는 것은 무엇일까?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `대부분 감정보다는 논리와 이성이 컨트롤함`,
          },
          {
            type: 'F',
            score: 5,
            content: '일반적으로 논리보다 감정이 나를 컨트롤함',
          },
        ],
      },
      {
        which: 'TF',
        question: `정교하고 예쁜 그림이 그려져 있는 달걀을 선물받았다!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '달걀에 이렇게까지 정교하게 안 그려도 되는데.. 고마워!!',
          },
          {
            type: 'F',
            score: 5,
            content:
              '진짜 예쁘게 잘 그렸네? 작은데 그리느라 힘들었겠다 고마워ㅜ',
          },
        ],
      },
      {
        which: 'JP',
        question: '나는 처음 겪는 새로운 상황에?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `적응하는 데 시간이 걸리는 편이다`,
          },
          {
            type: 'P',
            score: 5,
            content: `적응을 잘 하는 편이다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '뭔가를 하기로 정하고 움직일 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '분명한 목적과 방향을 정해놓고 움직임',
          },
          {
            type: 'P',
            score: 5,
            content: '목적과 방향은 언제든지 변화 가능함에 움직이면서 정함',
          },
        ],
      },
      {
        which: 'JP',
        question: '나는 시간이라는 자원을 어떻게 생각할까?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '기한이 정해져 있는 한정된 자원',
          },
          {
            type: 'P',
            score: 5,
            content: '탄력적으로 시시각각 바뀌는 자원',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/eggChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/eggChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/eggChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/eggChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/eggChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/eggChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/eggChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/eggChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/eggChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/eggChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/eggChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/eggChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/eggChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/eggChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/eggChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/eggChar/INFP.jpg',
      },
    ],
  },
  // chatDataClassEng
  {
    info: {
      mainTitle: 'Personality Data Classification Test',
      subTitle: `Let's categorize my personality by data!`,
      mainUrl: 'chatDataClassEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/chatDataClassEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/chatDataClassEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'What would my normal behavior be like?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Walking around in a noisy and lively manner',
          },
          {
            type: 'I',
            score: 5,
            content: 'Being awkward and shy',
          },
        ],
      },
      {
        which: 'EI',
        question: "What if I'm angry?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: "I have to tell you why I'm angry to relieve my anger",
          },
          {
            type: 'I',
            score: 5,
            content: 'I need time to organize my thoughts in my own space',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What does going out mean to me?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Exciting, fun, and relieving stress`,
          },
          {
            type: 'I',
            score: 5,
            content: `Tired and lazy and don't want to go out`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What is something I often say at meetings with my friends?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `It talks about things that really happened in everyday life`,
          },
          {
            type: 'N',
            score: 5,
            content: `Talk about the possibilities of what could happen in the future`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What about me when I judge people?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              "Judgment based on one's own clear criteria, such as behavior or speech",
          },
          {
            type: 'N',
            score: 5,
            content: `Judgment based on feeling at the time`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'My PC suddenly turned off while I was cleaning up the files!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I need to turn it on quickly and check if everything I organized is gone`,
          },
          {
            type: 'N',
            score: 5,
            content: `All the files are gone, reminding me of my miserable self doing it all over again`,
          },
        ],
      },
      {
        which: 'TF',
        question: `Friend who saved money and bought an expensive laptop!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Where do I turn it off? How much is it? Show us!',
          },
          {
            type: 'F',
            score: 5,
            content: 'You must have had a hard time saving money',
          },
        ],
      },
      {
        which: 'TF',
        question: 'What about me when I have to give someone bad feedback?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Provide direct and honest feedback`,
          },
          {
            type: 'F',
            score: 5,
            content:
              "Pay attention to the other person's feelings and give feedback in a good way",
          },
        ],
      },
      {
        which: 'TF',
        question: `What if you got second place in a field your friend never tried before?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'It would have been better if you were first place! Good job!',
          },
          {
            type: 'F',
            score: 5,
            content:
              'If you came in second, you would have done a great job! You worked hard!',
          },
        ],
      },
      {
        which: 'JP',
        question: 'When I find the file I want on my PC?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Categorized for easy viewing at a glance, making it easy to find`,
          },
          {
            type: 'P',
            score: 5,
            content: `I was confused about where I put the file I wanted, so I looked it up for a long time`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'What if you decide to do something?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'If I think I need to do it, I open the excel and organize it',
          },
          {
            type: 'P',
            score: 5,
            content: "I'll do it as soon as I decide to",
          },
        ],
      },
      {
        which: 'JP',
        question: "Today, let's organize the data we've collected so far!",
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I concentrate and organize until the end',
          },
          {
            type: 'P',
            score: 5,
            content: 'Shop or watch the news while organizing materials',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClassEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClassEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClassEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClassEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClassEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClassEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClassEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClassEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClassEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClassEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClassEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClassEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClassEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClassEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClassEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClassEng/INFP.jpg',
      },
    ],
  },
  // chatDataClass
  {
    info: {
      mainTitle: '성격 데이터 분류 테스트',
      subTitle: '내 성격을 데이터로 분류해 보자!',
      mainUrl: 'chatDataClass',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/chatDataClass-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/chatDataClass-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '나는 어떤 모습으로 분류될까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '떠들썩하고 활기차게 돌아다니는 모습',
          },
          {
            type: 'I',
            score: 5,
            content: '어색해하고 낯설어 하는 모습',
          },
        ],
      },
      {
        which: 'EI',
        question: '어떤 일로 화가 나면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '어떤 일로 화가 났는지 바로 털어놓고 대화해야 화가 풀림',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자만의 공간에서 가만히 생각을 정리할 시간이 필요함',
          },
        ],
      },
      {
        which: 'EI',
        question: '내가 밖으로 외출하는 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `약속을 잡고 준비해서 나가는 게 외출`,
          },
          {
            type: 'I',
            score: 5,
            content: `잠깐 뭔가를 사러 잠옷 입고 나가도 외출`,
          },
        ],
      },
      {
        which: 'SN',
        question: '모임에서 내가 자주 하는 얘기는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `일상에서 실제로 일어난 일들에 대한 얘기`,
          },
          {
            type: 'N',
            score: 5,
            content: `미래에 일어날 수 있는 가능성에 대한 얘기`,
          },
        ],
      },
      {
        which: 'SN',
        question: '사람을 판단할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '행동 말투 등 나만의 명확한 기준으로 판단함',
          },
          {
            type: 'N',
            score: 5,
            content: `그때그때 내 느낌에 따라 판단함`,
          },
        ],
      },
      {
        which: 'SN',
        question: '파일을 정리 중 갑자기 PC가 꺼졌다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `빨리 켜서 정리한 거 다 날라갔는지 확인해 봐야겠다`,
          },
          {
            type: 'N',
            score: 5,
            content: `파일들이 다 날라가서 처음부터 다시 하고 있는 처량한 내 모습이 떠오름`,
          },
        ],
      },
      {
        which: 'TF',
        question: `돈을 열심히 모아서 비싼 노트북을 구매했다는 친구!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '어디 꺼? 얼만데? 보여줘!',
          },
          {
            type: 'F',
            score: 5,
            content: '돈 모으느라 고생했겠는데~?',
          },
        ],
      },
      {
        which: 'TF',
        question: '누군가에게 안 좋은 피드백을 해야 할 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `직설적으로 솔직하게 피드백 한다`,
          },
          {
            type: 'F',
            score: 5,
            content: '상처받을 수 있으니 최대한 순화해서 피드백 한다',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 처음 해보는 어떤 분야에 2등을 했다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '1등이면 더 좋았을 텐데 아쉽겠다 그래도 고생 많았어!',
          },
          {
            type: 'F',
            score: 5,
            content: '처음인데 2등이면 잘한 거지~! 진짜 수고했어!',
          },
        ],
      },
      {
        which: 'JP',
        question: 'PC에서 내가 원하는 파일을 찾을 때?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `한눈에 보기 쉽게 분류되어 있어 쉽게 찾을 수 있음`,
          },
          {
            type: 'P',
            score: 5,
            content: `원하는 파일을 어디에 넣어 놨는지 헷갈려서 한참을 찾음`,
          },
        ],
      },
      {
        which: 'JP',
        question: '뭔가를 하려고 마음을 먹으면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '실행해야겠다고 생각하면 엑셀부터 킴',
          },
          {
            type: 'P',
            score: 5,
            content: '실행해야겠다고 마음 먹으면 일단 바로 진행함',
          },
        ],
      },
      {
        which: 'JP',
        question: '오늘은 그동안 모아온 데이터를 정리하려고 한다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '집중하여 끝까지 깔끔하게 정리함',
          },
          {
            type: 'P',
            score: 5,
            content: '데이터 정리하다가 딴 길로 새서 쇼핑하거나 뉴스 봄',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClass/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClass/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClass/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClass/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClass/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClass/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClass/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClass/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClass/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClass/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClass/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClass/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClass/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClass/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClass/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/chatDataClass/INFP.jpg',
      },
    ],
  },
  // popcornCharLoveEng
  {
    info: {
      mainTitle: 'Popcorn Character Love Test',
      subTitle: 'What kind of love popcorn am I?',
      mainUrl: 'popcornCharLoveEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/popcornCharLoveEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/popcornCharLoveEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: "What if you made an appointment with your lover's friends?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: "I'm looking forward to what kind of people they will be",
          },
          {
            type: 'I',
            score: 5,
            content: 'I feel uncomfortable even before we meet',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What date do you usually go on with your lover?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Activity-oriented Dating',
          },
          {
            type: 'I',
            score: 5,
            content: 'Indoor-focused dating',
          },
        ],
      },
      {
        which: 'EI',
        question: 'When You Feel Sad or Depressed?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I spend time with my lover and solve it`,
          },
          {
            type: 'I',
            score: 5,
            content: `I spend time alone at home`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What is the topic of conversation you often talk about with your lover?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Conversations related to daily life`,
          },
          {
            type: 'N',
            score: 5,
            content: `An imaginary conversation`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What if your lover suddenly sets a breakup song as your profile music?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I think you set it up because you liked the song',
          },
          {
            type: 'N',
            score: 5,
            content: `It gives meaning and thinks about many things`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What if you give a book to your lover?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `A book that may help one's lover`,
          },
          {
            type: 'N',
            score: 5,
            content: `A book with a wide range of interpretability`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What if you feel bad about your lover?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Says what he is upset about to his lover',
          },
          {
            type: 'F',
            score: 5,
            content: 'I keep it to myself and bring it up later',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'If you were to give advice on a situation clearly caused by your lover mistake, what would you say?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Listen quietly to the story or tell me what's wrong`,
          },
          {
            type: 'F',
            score: 5,
            content:
              "Tells me the answer he wants by thinking about his lover's feelings",
          },
        ],
      },
      {
        which: 'TF',
        question: `What if your lover says she had a depressing dream?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Ask about your dream content and try to understand emotions',
          },
          {
            type: 'F',
            score: 5,
            content: 'Give words that can help you find empathy and stability',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What is your preferred date plan?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `A date with all one's schedules planned`,
          },
          {
            type: 'P',
            score: 5,
            content: `An impromptu date according to the date`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'When I suddenly want to see my lover?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "I miss you, but I hold back thinking about my lover's schedule",
          },
          {
            type: 'P',
            score: 5,
            content: 'First, I call them to meet them',
          },
        ],
      },
      {
        which: 'JP',
        question: 'If I go shopping with my lover?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Create a shopping list and only buy necessary items',
          },
          {
            type: 'P',
            score: 5,
            content:
              'Roughly decide on needed items and make additional purchases while shopping',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLoveEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLoveEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLoveEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLoveEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLoveEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLoveEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLoveEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLoveEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLoveEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLoveEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLoveEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLoveEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLoveEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLoveEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLoveEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLoveEng/INFP.jpg',
      },
    ],
  },
  // popcornCharLove
  {
    info: {
      mainTitle: '팝콘 캐릭터 연애 테스트',
      subTitle: '팝콘 캐릭터로 보는 나의 연애 성격은?',
      mainUrl: 'popcornCharLove',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/popcornCharLove-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/popcornCharLove-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
      description: `팝콘 캐릭터로 보는 연애 성격 테스트 입니다\n
        좋아하는 연인,친구,나의 연애 스타일을 알아보세요!`,
    },
    questions: [
      {
        which: 'EI',
        question: '연인의 친구들과 약속이 잡았다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '어떤 사람들일지 기대된다',
          },
          {
            type: 'I',
            score: 5,
            content: '만나기 전부터 불편하다',
          },
        ],
      },
      {
        which: 'EI',
        question: '연인과 주로 하는 데이트는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '활동 중심의 데이트',
          },
          {
            type: 'I',
            score: 5,
            content: '실내 중심의 데이트',
          },
        ],
      },
      {
        which: 'EI',
        question: '우울한 감정이 들 때면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `연인과 함께 시간을 보내며 해결한다`,
          },
          {
            type: 'I',
            score: 5,
            content: `집에서 혼자 시간을 보낸다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인과 자주 나누는 대화 주제는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `일상생활에 관련된 대화`,
          },
          {
            type: 'N',
            score: 5,
            content: `상상의 나래를 펼치는 대화`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인이 갑자기 이별 노래를 프로필 뮤직으로 설정한다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '노래가 좋아서 설정했구나 생각한다',
          },
          {
            type: 'N',
            score: 5,
            content: `의미를 부여하며 여러 가지를 생각한다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '애인에게 책을 선물한다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `애인에게 도움이 될 것 같은 책`,
          },
          {
            type: 'N',
            score: 5,
            content: `다양한 해석 가능성을 지닌 책`,
          },
        ],
      },
      {
        which: 'TF',
        question: `연인에게 서운한 점이 생기면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '바로 연인에게 서운한 점을 말한다',
          },
          {
            type: 'F',
            score: 5,
            content: '마음속으로 삭이고 나중에 말한다',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '누가 봐도 연인의 잘못으로 일어난 일에 대한 고민을 상담해 준다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `조용히 이야기를 듣거나 잘못된 점을 말해준다`,
          },
          {
            type: 'F',
            score: 5,
            content: '연인의 기분을 생각해 원하는 답변을 말해준다',
          },
        ],
      },
      {
        which: 'TF',
        question: `연인이 우울한 꿈을 꿨다고 말한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '꿈 내용에 대해 물어보고 감정에 이해하려고 노력한다',
          },
          {
            type: 'F',
            score: 5,
            content: '공감과 함께 안정감을 찾을 수 있는 말을 건넨다',
          },
        ],
      },
      {
        which: 'JP',
        question: '선호하는 데이트는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `모든 일정을 계획한 데이트`,
          },
          {
            type: 'P',
            score: 5,
            content: `그날에 따라 즉흥적인 데이트`,
          },
        ],
      },
      {
        which: 'JP',
        question: '갑작스럽게 연인이 보고 싶은 순간에는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '보고 싶지만 연인의 스케줄을 생각해 참는다',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 만나자고 연락을 해본다',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 장을 보게 된다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '쇼핑 리스트를 작성하여 필요한 물건만 구매한다',
          },
          {
            type: 'P',
            score: 5,
            content:
              '대략적으로 필요한 물건을 정한 후 쇼핑 중에 추가적으로 구매한다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLove/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLove/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLove/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLove/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLove/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLove/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLove/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLove/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLove/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLove/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLove/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLove/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLove/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLove/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLove/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/popcornCharLove/INFP.jpg',
      },
    ],
  },
  // pollenLove
  {
    info: {
      mainTitle: '꽃가루 연애 테스트',
      subTitle: '꽃가루로 보는 너의 연애 성격은?',
      mainUrl: 'pollenLove',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/pollenLove-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/pollenLove-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '데이트가 없는 날에는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구들과 약속을 잡는다',
          },
          {
            type: 'I',
            score: 5,
            content: '집에서 혼자 시간을 보낸다',
          },
        ],
      },
      {
        which: 'EI',
        question: '데이트 중 길거리에서 애인의 친구를 만났을 때는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '자연스럽게 웃으며 먼저 소개를 한다',
          },
          {
            type: 'I',
            score: 5,
            content: '조용히 애인과 친구의 대화를 들으며 가만히 있는다',
          },
        ],
      },
      {
        which: 'EI',
        question: '애인과 여행 패키지를 고를 때는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `사람들과 함께 활동적인 활동을 할 수 있는 패키지`,
          },
          {
            type: 'I',
            score: 5,
            content: `느긋하게 일행들끼리 여행할 수 있는 패키지`,
          },
        ],
      },
      {
        which: 'SN',
        question: '애인에게 친구와 갔었던 맛집을 설명할 때는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `먹었던 음식에 대해 상세하게 설명한다`,
          },
          {
            type: 'N',
            score: 5,
            content: `전체적인 느낌 위주로 설명한다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '애인과 길을 걷던 중 귀여운 아기를 발견했을 때는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '저 아기 좀 봐 너무 귀여워',
          },
          {
            type: 'N',
            score: 5,
            content: `우리가 만약 결혼하게 되면 저렇게 귀여운 아기를 낳게 될까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '애인에게 줄 선물을 고를 때는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `애인이 필요할 것 같은 선물`,
          },
          {
            type: 'N',
            score: 5,
            content: `예쁘고 오래 기억에 남을 것 같은 선물`,
          },
        ],
      },
      {
        which: 'TF',
        question: `애인에게 잘못한 일을 사과할 때는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '잘못을 솔직하게 인정한 후 사과한다',
          },
          {
            type: 'F',
            score: 5,
            content: '상대가 느낄 수 있도록 잘못에 대한 진심을 담아 사과한다',
          },
        ],
      },
      {
        which: 'TF',
        question: '애인이 평소와 달리 안 어울리는 옷을 입었을 때는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `평소에 입던 옷과 다르네? 선물 받은 거야? 근데 이거 안 어울리는 것 같아`,
          },
          {
            type: 'F',
            score: 5,
            content: '와 처음 보는 옷이네~ 못 봤던 모습이라 새롭고 좋은데?',
          },
        ],
      },
      {
        which: 'TF',
        question: `애인이 우울하다고 말했을 때는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜 우울해? 무슨 일 때문에 우울한 거야?',
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮아? 누가 우울하게 만들었어 ㅠㅠ 다 이야기해',
          },
        ],
      },
      {
        which: 'JP',
        question: '애인과 데이트 계획을 세울 때는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `카페 갔다가 식당을 가고 마지막은 영화관 가자`,
          },
          {
            type: 'P',
            score: 5,
            content: `일단 카페에서 만나서 정하는 거 어때?`,
          },
        ],
      },
      {
        which: 'JP',
        question: '서프라이즈 이벤트를 준비할 때는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '여러 플랜을 짜며 계획적으로 준비한다',
          },
          {
            type: 'P',
            score: 5,
            content:
              '대략적으로 생각만 해둔 채 생각한 일정에 다가올 때 급하게 준비를 마무리한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '데이트 룩을 고를 때는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '전날부터 입고 나갈 옷을 준비해 둔다',
          },
          {
            type: 'P',
            score: 5,
            content: '나가기 직전 옷을 고르기 시작한다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/pollenLove/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/pollenLove/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/pollenLove/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/pollenLove/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/pollenLove/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/pollenLove/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/pollenLove/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/pollenLove/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/pollenLove/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/pollenLove/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/pollenLove/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/pollenLove/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/pollenLove/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/pollenLove/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/pollenLove/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/pollenLove/INFP.jpg',
      },
    ],
  },
  // flyJjackCharJP
  {
    info: {
      mainTitle: '飛べ、小鳥たちの性テスト',
      subTitle: 'かわいい小鳥たちが教えてくれる私の性格は？',
      mainUrl: 'flyJjackCharJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/flyJjackCharJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/flyJjackCharJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'characteristic',
      hashTag: '#ある日突然小鳥になった',
    },
    questions: [
      {
        which: 'EI',
        question: '私が可愛い小鳥になったら？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'ぺちゃくちゃ休む間もなく話しながら飛び回る小鳥',
          },
          {
            type: 'I',
            score: 5,
            content: 'その場におとなしく座って周りを観察する小鳥',
          },
        ],
      },
      {
        which: 'EI',
        question: '向こうに初めて見る小鳥たちが集まっている！',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '近づいて挨拶し自己紹介して仲良くなる',
          },
          {
            type: 'I',
            score: 5,
            content: '遠くからひそひそと気になるけど近づかない',
          },
        ],
      },
      {
        which: 'EI',
        question: 'たっぷり飛び回って遊んで夜になって寝る時間！',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `群れの中の小鳥の友達の間で眠る`,
          },
          {
            type: 'I',
            score: 5,
            content: `群れから離れて一人で余裕を楽しんで眠る`,
          },
        ],
      },
      {
        which: 'SN',
        question: '鳥たちが見る世界はどんな感じだろう？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `鳥は目が横についているから横に見えるはず`,
          },
          {
            type: 'N',
            score: 5,
            content: `私たちが巨人に見えるかも？何を考えて生きているのだろう？`,
          },
        ],
      },
      {
        which: 'SN',
        question: '鳥になった私が危険を察知する時、何に頼るかな？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '視覚、聴覚、嗅覚、五感に頼る',
          },
          {
            type: 'N',
            score: 5,
            content: `自分が感じる直感に頼る`,
          },
        ],
      },
      {
        which: 'SN',
        question: '可愛い鳥たちが鳴きながら飛び回っている！',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `鳥たちが可愛く見えるね`,
          },
          {
            type: 'N',
            score: 5,
            content: `彼ら同士で何を話しているのか全部わかるんだろうね？何を話しているのか気になる`,
          },
        ],
      },
      {
        which: 'TF',
        question: `鳥かごに閉じ込められた鳥たちを見て感じることは？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '時間が来たら餌と水をやって危険もなさそうだから安全に見える',
          },
          {
            type: 'F',
            score: 5,
            content: '自由に飛べず閉じ込められているのがかわいそう',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'テレビで何年も育てて放した鳥が飼い主を認識するシーンが出る！',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `鳥は記憶力が悪いと言われていたのに認識するんだね？`,
          },
          {
            type: 'F',
            score: 5,
            content: 'すごく感動的で涙が出そう',
          },
        ],
      },
      {
        which: 'TF',
        question: `友達がインコカフェに一緒に行こうと言っているけどあまり気乗りしない…！`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '行きたくないと正直に言う',
          },
          {
            type: 'F',
            score: 5,
            content: '友達が悲しむかもしれないので慎重に遠回しに言う',
          },
        ],
      },
      {
        which: 'JP',
        question: '私が可愛い鳥を迎えるなら？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `必要な物品、餌などをあらかじめ調べて購入しておく`,
          },
          {
            type: 'P',
            score: 5,
            content: `大きなものだけ購入しておいて、必要な時に買う`,
          },
        ],
      },
      {
        which: 'JP',
        question: '鳥を迎えた後に馴らす時、私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '完全に自分に懐くまで諦めない',
          },
          {
            type: 'P',
            score: 5,
            content: '馴らそうとしてダメなら途中で諦めて自由に育てる',
          },
        ],
      },
      {
        which: 'JP',
        question: '鳥を飼う時に必要な物品を私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '必要な時にすぐ使えるようにきちんと整理整頓する',
          },
          {
            type: 'P',
            score: 5,
            content: '一か所にまとめておいて、必要な時に探し回る',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharJP/INFP.jpg',
      },
    ],
  },
  // flyJjackCharEng
  {
    info: {
      mainTitle: 'Fly, sparrow personality test',
      subTitle: 'How about my personality that cute sparrows tell me?',
      mainUrl: 'flyJjackCharEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/flyJjackCharEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/flyJjackCharEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
      hashTag: '#One_day_I_suddenly_became_a_baby_bird',
    },
    questions: [
      {
        which: 'EI',
        question: 'If I become a cute little sparrow?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'A sparrow chirping non-stop while flitting around without rest',
          },
          {
            type: 'I',
            score: 5,
            content:
              'A sparrow that sits quietly in place, observing its surroundings',
          },
        ],
      },
      {
        which: 'EI',
        question: 'There are some unfamiliar sparrows gathered over there!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Approach, greet, introduce myself, and become friends',
          },
          {
            type: 'I',
            score: 5,
            content: 'Whisper from a far, curious but not approaching',
          },
        ],
      },
      {
        which: 'EI',
        question:
          "After flying around and playing to my heart's content, it's time for bed as night falls!",
        answers: [
          {
            type: 'E',
            score: 2,
            content: `build a nest among my friends and a nap`,
          },
          {
            type: 'I',
            score: 5,
            content: `Drifting away from the flock, enjoy some solitude and drift off to sleep`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What might the world look like to birds?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Birds have eyes on the sides of their heads, so they probably see to the sides`,
          },
          {
            type: 'N',
            score: 5,
            content: `Might perceive us as giants? What thoughts could they have?`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What would I rely on when sensing danger as a bird?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Relying on vision, hearing, smell, and overall senses',
          },
          {
            type: 'N',
            score: 5,
            content: `Relying on my senses`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'The cute sparrows are chirping and flying around!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `The sparrows look adorable!`,
          },
          {
            type: 'N',
            score: 5,
            content: `They probably understand each other perfectly! wonder what they're saying to each other`,
          },
        ],
      },
      {
        which: 'TF',
        question: `When seeing birds confined in cages, what emotion do you feel more strongly?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              "It seems safe as they're provided with meals and there are no dangerous situations",
          },
          {
            type: 'F',
            score: 5,
            content:
              "It's sad to see them unable to fly freely and being confined",
          },
        ],
      },
      {
        which: 'TF',
        question:
          "On TV, there's a scene where a bird, abandoned after being raised for several years, recognizes its owner",
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I heard birds have bad memory, how did they know?`,
          },
          {
            type: 'F',
            score: 5,
            content:
              "Such an overwhelming wave of emotions... It's almost bringing tears to my eyes",
          },
        ],
      },
      {
        which: 'TF',
        question: `My friend suggested going to a parrot cafe together, but I'm not really into it...`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "I'll be honest and say I don't want to go",
          },
          {
            type: 'F',
            score: 5,
            content:
              "I'll phrase it carefully so as not to hurt my friend's feelings",
          },
        ],
      },
      {
        which: 'JP',
        question: 'If I were to adopt a beautiful bird?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Researching and purchasing necessary supplies, food, and more in advance`,
          },
          {
            type: 'P',
            score: 5,
            content: `Purchase only the major items in advance and buy the rest as needed`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'When I tame the bird after adopting it?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "I won't give up until I successfully tame the bird to follow me perfectly",
          },
          {
            type: 'P',
            score: 5,
            content:
              "If I can't tame it along the way, I'll give up and let it live freely",
          },
        ],
      },
      {
        which: 'JP',
        question: 'When raising a bird, how do you organize your items?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'To have them readily available for use when needed, neatly organize and tidy up',
          },
          {
            type: 'P',
            score: 5,
            content:
              'keep everything in one place and rummage through it when I need something',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackCharEng/INFP.jpg',
      },
    ],
  },
  // flyJjackChar
  {
    info: {
      mainTitle: '날아라 짹짹이 성격 테스트',
      subTitle: '귀여운 짹짹이들이 알려주는 내 성격은 어떨까?',
      mainUrl: 'flyJjackChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/flyJjackChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/flyJjackChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
      hashTag: '#어느날_갑자기_내가짹짹이가되어버렸다',
    },
    questions: [
      {
        which: 'EI',
        question: '내가 귀여운 짹짹이가 된다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '쫑알쫑알 쉴 새 없이 떠들며 돌아다니는 짹짹이',
          },
          {
            type: 'I',
            score: 5,
            content: '그 자리에 얌전히 앉아 주변을 관찰하는 쨱짹이',
          },
        ],
      },
      {
        which: 'EI',
        question: '저쪽에 처음 보는 짹짹이들이 모여있다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '다가가서 인사하고 나를 소개하며 친해진다',
          },
          {
            type: 'I',
            score: 5,
            content: '멀리서 속닥속닥 거리며 궁금하지만 다가가진 않는다',
          },
        ],
      },
      {
        which: 'EI',
        question: '실컷 날아다니며 놀다가 밤이 되어 잘 시간!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `무리에 있는 짹짹이 친구들 사이에서 잠`,
          },
          {
            type: 'I',
            score: 5,
            content: `무리에서 떨어져 혼자 여유를 즐기다 잠`,
          },
        ],
      },
      {
        which: 'SN',
        question: '새들이 보는 세상은 어떨까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `새들은 눈이 옆으로 달려있으니까 옆으로 보이겠지`,
          },
          {
            type: 'N',
            score: 5,
            content: `우리가 다 거인처럼 보이겠지? 무슨 생각을 하고 살까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '새가 된 내가 위험을 감지할 때 무엇에 의존할까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '시각, 청각, 후각, 오감에 의존',
          },
          {
            type: 'N',
            score: 5,
            content: `내가 느끼는 육감에 의존`,
          },
        ],
      },
      {
        which: 'SN',
        question: '귀여운 새들이 짹짹거리며 날아다닌다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `새들이 귀엽게 생겼네`,
          },
          {
            type: 'N',
            score: 5,
            content: `자기들끼리는 뭔 말인지 다 알아듣겠지? 뭐라 하는 건지 궁금해`,
          },
        ],
      },
      {
        which: 'TF',
        question: `새장에 갇혀 있는 새들을 볼 때 더 크게 느끼는 것은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '시간 되면 밥 주고 물 주고 위험 요소도 없고 안전해 보임',
          },
          {
            type: 'F',
            score: 5,
            content: '자유롭게 날지 못하고 갇혀 있는 게 안타까움',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'TV에서 몇 년 동안 키우다가 방생한 새가 주인을 알아보는 장면이 나온다!',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `새는 기억력이 안 좋다던데 알아보네?`,
          },
          {
            type: 'F',
            score: 5,
            content: '너무나 감동의 쓰나미.. 눈물 나올 듯..',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 앵무새 카페에 같이 가자고 하는데 별로 내키지 않는다…!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '가고 싶지 않다고 솔직하게 말한다',
          },
          {
            type: 'F',
            score: 5,
            content: '친구가 서운해할까 봐 조심스럽게 돌려 말한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 예쁜 새를 입양한다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `필요한 물품, 먹는 모이 등등 미리 조사하고 구매해 둠`,
          },
          {
            type: 'P',
            score: 5,
            content: `큰 것들만 구매해 놓고 필요할 때마다 구매함`,
          },
        ],
      },
      {
        which: 'JP',
        question: '새를 입양한 후 길들일 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '완벽하게 나를 따르게 끔 길들일 때까지 포기하지 않는다',
          },
          {
            type: 'P',
            score: 5,
            content: '길들이려고 하다가 안되면 중간에 포기하고 자유롭게 키운다',
          },
        ],
      },
      {
        which: 'JP',
        question: '새를 키울 때 필요한 물품들을 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '필요할 때 바로 사용할 수 있도록 깔끔하게 정리정돈 함',
          },
          {
            type: 'P',
            score: 5,
            content: '한 곳에만 모아두고 필요할 때 뒤적거리며 찾음',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/flyJjackChar/INFP.jpg',
      },
    ],
  },
  // fruitSilhouetteCharEng
  {
    info: {
      mainTitle: 'Fruit silhouette personality test',
      subTitle: `Let's find out my personality hidden in the fruit silhouette!`,
      mainUrl: 'fruitSilhouetteCharEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/fruitSilhouetteCharEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/fruitSilhouetteCharEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'To a friend who wants to go to a fruit festival with you?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: "I think it'll be fun! Let's go together!",
          },
          {
            type: 'I',
            score: 5,
            content: "I don't like crowded and noisy places..",
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What will you look like when you come home from having fun outside?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              "I'm waiting for my next meeting appointment, saying my stress has been relieved",
          },
          {
            type: 'I',
            score: 5,
            content:
              'I will rest in bed without saying anything until tomorrow',
          },
        ],
      },
      {
        which: 'EI',
        question: 'How was my affinity made?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Friendship that was natural from the beginning`,
          },
          {
            type: 'I',
            score: 5,
            content: `The social affinity created by hard work`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'We gathered at a fruit festival with all kinds of fruit!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `The fruit looks delicious. There are many people`,
          },
          {
            type: 'N',
            score: 5,
            content: `Which country is this fruit from? What does it taste like?`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What is the atmosphere of the fruit festival like?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: "It's crowded, fruity, and noisy",
          },
          {
            type: 'N',
            score: 5,
            content: `It's colorful and full of fruity scent, so I feel like I'm a fruit`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'If I become a fruit, what kind of fruit will I become?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Well, apples? Apples are expensive these days`,
          },
          {
            type: 'N',
            score: 5,
            content: `Strawberry or orange? Someone wouldn't eat me, would they?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `Fruit picking experience at a festival! What if a friend who didn't pick a lot gets depressed?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              "That's possible. Why are you depressed about something like that?",
          },
          {
            type: 'F',
            score: 5,
            content: "It's okay! I'll give you mine later",
          },
        ],
      },
      {
        which: 'TF',
        question:
          "I was encouraged to try the dried fruit, so I tried it, but it doesn't taste good...!",
        answers: [
          {
            type: 'T',
            score: 2,
            content: `It's not my style. I'm just going to look around and come back`,
          },
          {
            type: 'F',
            score: 5,
            content:
              "It's not good, but since I ate this, I should buy it, right?",
          },
        ],
      },
      {
        which: 'TF',
        question: `My friend's favorite fruit is watermelon!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Everyone likes watermelon',
          },
          {
            type: 'F',
            score: 5,
            content: 'I like it, too! Watermelon is so good!',
          },
        ],
      },
      {
        which: 'JP',
        question: 'I bought all the fruits I wanted to buy!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I don't buy anymore, I only buy the fruit I was going to buy`,
          },
          {
            type: 'P',
            score: 5,
            content: `If there is a fruit that looks delicious while looking around, I buy extra`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What do you do when you try something new that you want to try?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Challenge after sufficient investigation to see if it suits me',
          },
          {
            type: 'P',
            score: 5,
            content:
              "Let's give it a try! If it doesn't fit me, we can do something else",
          },
        ],
      },
      {
        which: 'JP',
        question: 'On my way home after the fruit festival!',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "If you ride this, get off here, and go like this, it's fast",
          },
          {
            type: 'P',
            score: 5,
            content: "Let's find out on our way. I think we can ride this",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteCharEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteCharEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteCharEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteCharEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteCharEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteCharEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteCharEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteCharEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteCharEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteCharEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteCharEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteCharEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteCharEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteCharEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteCharEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteCharEng/INFP.jpg',
      },
    ],
  },
  // fruitSilhouetteChar
  {
    info: {
      mainTitle: '과일 실루엣 성격 테스트',
      subTitle: '과일 실루엣 속에 숨겨진 내 성격을 알아보자',
      mainUrl: 'fruitSilhouetteChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/fruitSilhouetteChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/fruitSilhouetteChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '과일 축제에 같이 가자는 친구에게 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '재밌겠는데? 요즘 핫플 아니야? 가자 가자~',
          },
          {
            type: 'I',
            score: 5,
            content: '엥..? 사람 많고 시끄러운 곳 별론데 나는..',
          },
        ],
      },
      {
        which: 'EI',
        question: '밖에서 신나게 떠들며 놀고 왔다! 그 이후에 내 모습은??',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '스트레스 풀렸다며 다음 모임 약속을 기다림',
          },
          {
            type: 'I',
            score: 5,
            content: '내일까지 말 한마디도 안 하고 침대에서 요양함',
          },
        ],
      },
      {
        which: 'EI',
        question: '내게 친화력은 어떻게 만들어졌을까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `원래부터 자연스럽게 있던 친화력`,
          },
          {
            type: 'I',
            score: 5,
            content: `영혼을 갈아서 만든 사회성 친화력`,
          },
        ],
      },
      {
        which: 'SN',
        question: '유명한 과일 축제에 온갖 과일들이 모여있다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `과일 맛있겠다 사람 많네`,
          },
          {
            type: 'N',
            score: 5,
            content: `이 과일은 어디 나라 과일일까? 어떤 맛일까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '과일 축제의 분위기는 어떨까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '사람 많고 과일 많고 시끌벅적해',
          },
          {
            type: 'N',
            score: 5,
            content: `알록달록하고 과일 향이 가득해서 내가 과일 된 느낌이야`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 과일이 된다면 어떤 과일일까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `음 사과? 요즘 사과 금 값이래`,
          },
          {
            type: 'N',
            score: 5,
            content: `난 아무래도 딸기? 아냐 오렌지? 설마 누군가 나 먹는 건 아니지?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `축제에서 과일 따기 체험 중! 많이 못 딴 친구가 속상해한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '많이 못 딸 수도 있지 뭘 그런 거로 속상해해!',
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮아 괜찮아! 이따가 내 꺼 나눠줄 게~',
          },
        ],
      },
      {
        which: 'TF',
        question: '말린 과일을 시식해 보라는 권유에 먹어봤는데 맛이 별로다..!',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `내 스타일은 아니네.. 바로 둘러보고 온다고 말함`,
          },
          {
            type: 'F',
            score: 5,
            content: '이거 먹었으니까 안 사면 좀 그렇겠지..? 눈치 봄',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 가장 좋아하는 과일은 수박이라고 한다!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '수박은 다 좋아하지~',
          },
          {
            type: 'F',
            score: 5,
            content: '나도 좋아하는데! 수박 너무 맛있지~',
          },
        ],
      },
      {
        which: 'JP',
        question: '사려고 했던 과일들을 모두 구매했다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `더 이상 구매하지 않고 사려고 했던 과일만 구매한다`,
          },
          {
            type: 'P',
            score: 5,
            content: `둘러보다가 맛있어 보이는 과일이 있으면 추가로 구매한다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '해보고 싶은 새로운 무언가에 도전할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '나에게 맞을지 충분한 조사 후 도전',
          },
          {
            type: 'P',
            score: 5,
            content: '아니면 말자는 마인드로 일단 도전',
          },
        ],
      },
      {
        which: 'JP',
        question: '과일 축제가 끝나고 집으로 가는 길!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '이거 타고 여기서 내린 다음 이렇게 가면 빨라',
          },
          {
            type: 'P',
            score: 5,
            content: '가면서 알아보자 대충 이거 타면 될 듯?',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/fruitSilhouetteChar/INFP.jpg',
      },
    ],
  },
  // degreeWeatherCharJP
  {
    info: {
      mainTitle: '天気の温度性格テスト',
      subTitle: '天気の温度で見るあなたの性格は？',
      mainUrl: 'degreeWeatherCharJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/degreeWeatherCharJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/degreeWeatherCharJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '集中が必要なときに好む環境は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '活気のある環境',
          },
          {
            type: 'I',
            score: 5,
            content: '静かな環境',
          },
        ],
      },
      {
        which: 'EI',
        question: '好む会話のタイプは？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'ささやかな会話',
          },
          {
            type: 'I',
            score: 5,
            content: '深くて意味のある会話',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '友達との集まりの後で「ちょっとどこか寄っていくね！」という意味は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `一緒に行く人いる？`,
          },
          {
            type: 'I',
            score: 5,
            content: `一人で家に帰りたいから、先に行ってくれない？`,
          },
        ],
      },
      {
        which: 'SN',
        question: '映画を観た後の会話は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `俳優の演技やストーリーについての会話`,
          },
          {
            type: 'N',
            score: 5,
            content: `面白さや興行成績の予想についての会話`,
          },
        ],
      },
      {
        which: 'SN',
        question: '車の中でぼんやりと窓の外を眺めるとき？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '窓の外が綺麗だね、天気がいいね〜',
          },
          {
            type: 'N',
            score: 5,
            content: `外の車たちはどこに行くんだろう？`,
          },
        ],
      },
      {
        which: 'SN',
        question: '四季が徐々になくなっているという記事を読んだ！',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `地球温暖化がひどいからそうなるよね`,
          },
          {
            type: 'N',
            score: 5,
            content: `そのうち夏と冬だけになるのかな？そうしたらずっと暑くて寒くて、外を歩くのが大変になるよね`,
          },
        ],
      },
      {
        which: 'TF',
        question: `友達が基本的なミスをして上司に怒られたと言ったとき？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '君が悪かったね、次からは気をつけよう',
          },
          {
            type: 'F',
            score: 5,
            content:
              'たくさん怒られたの？ミスだけど、もっと優しく言えたはずなのに',
          },
        ],
      },
      {
        which: 'TF',
        question: '友達がむっつりして何も言わないとき？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `自分の言い方で気分を害したかな？そっとしておこう`,
          },
          {
            type: 'F',
            score: 5,
            content: 'どうしたの？何か私が悪いことをした？',
          },
        ],
      },
      {
        which: 'TF',
        question: `悲しみを半分に分けると？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '悲しい人が二人になる',
          },
          {
            type: 'F',
            score: 5,
            content: '悲しみがなくなる',
          },
        ],
      },
      {
        which: 'JP',
        question: '日常のダイアリーを書くときは？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `日付ごとにしっかりと予定を整理して書く`,
          },
          {
            type: 'P',
            score: 5,
            content: `あれこれ思いつくままに予定を書く`,
          },
        ],
      },
      {
        which: 'JP',
        question: '人が多い場所に車で行かなければならないときは？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '駐車できる場所を事前に調べておく',
          },
          {
            type: 'P',
            score: 5,
            content: '約束の場所に近くの駐車場を探す',
          },
        ],
      },
      {
        which: 'JP',
        question: '友達との約束の場所を決めるとき？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '日付と時間、場所を具体的に伝える',
          },
          {
            type: 'P',
            score: 5,
            content: 'おおよそ来週あたりに会おう',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherCharJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherCharJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherCharJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherCharJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherCharJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherCharJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherCharJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherCharJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherCharJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherCharJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherCharJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherCharJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherCharJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherCharJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherCharJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherCharJP/INFP.jpg',
      },
    ],
  },
  // degreeWeatherChar
  {
    info: {
      mainTitle: '날씨의 온도 성격 테스트',
      subTitle: '날씨의 온도로 보는 너의 성격은?',
      mainUrl: 'degreeWeatherChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/degreeWeatherChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/degreeWeatherChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '집중이 필요할 때 좋아하는 환경은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '활기찬 환경',
          },
          {
            type: 'I',
            score: 5,
            content: '조용한 환경',
          },
        ],
      },
      {
        which: 'EI',
        question: '선호하는 대화는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '소소한 대화',
          },
          {
            type: 'I',
            score: 5,
            content: '깊고 의미 있는 대화',
          },
        ],
      },
      {
        which: 'EI',
        question: "친구들과의 모임 후 '잠깐 어디 들렀다 갈게~!'의 뜻은?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: `혹시 같이 갈 사람~?`,
          },
          {
            type: 'I',
            score: 5,
            content: `나 혼자 집 가고 싶으니까 제발 먼저 가줘ㅜ`,
          },
        ],
      },
      {
        which: 'SN',
        question: '영화를 보고 나서 대화를 할 때?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `배우의 연기와 줄거리에 대한 대화`,
          },
          {
            type: 'N',
            score: 5,
            content: `재미와 흥행 예상에 대한 대화`,
          },
        ],
      },
      {
        which: 'SN',
        question: '차 안에서 멍하니 창밖을 바라볼 때?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '창밖 너무 이쁘다 날씨 좋네~',
          },
          {
            type: 'N',
            score: 5,
            content: `밖에 차들은 다 어디 가는 걸까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '점점 4계절이 사라지고 있다는 기사를 보았다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `지구 온난화가 심하니까 그럴 수 있지`,
          },
          {
            type: 'N',
            score: 5,
            content: `이러다 여름 겨울만 남겠네 그럼 평생 덥고 춥겠지? 밖에 돌아다니기 힘들어지려나?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 기본적인 실수를 해 윗사람에게 혼났다고 했을 때?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '네가 잘못했네 다음부터는 그러지 말자',
          },
          {
            type: 'F',
            score: 5,
            content:
              '많이 혼났어? 실수지만 좋게 말할 수 있는 건데 말이 너무 심하셨네',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 뚱한 표정으로 아무 말도 안 하고 있을 때?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `무슨 일이야? 내가 뭐 실수한 거 있어?`,
          },
          {
            type: 'F',
            score: 5,
            content: '내 말투에 기분이 상했나? 가만히 내버려둬야겠다',
          },
        ],
      },
      {
        which: 'TF',
        question: `슬픔을 반으로 나누면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '슬픈 사람이 두 명이 되겠지',
          },
          {
            type: 'F',
            score: 5,
            content: '슬픔이 없어지지',
          },
        ],
      },
      {
        which: 'JP',
        question: '일상 다이어리를 적을 때는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `날짜별로 착착 일정을 정리하여 적는다`,
          },
          {
            type: 'P',
            score: 5,
            content: `이랬다저랬다 생각나는 대로 일정을 적는다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '사람이 많은 장소지만 차를 꼭 가져가야 할 때는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '주차할 수 있는 곳을 미리 검색해 둔다',
          },
          {
            type: 'P',
            score: 5,
            content: '약속 장소에 근처에서 주차장을 찾아본다',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구와 약속 장소를 정할 때?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '날짜와 시간 장소를 구체적으로 말한다',
          },
          {
            type: 'P',
            score: 5,
            content: '대략적으로 다음 주쯤 보자',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/degreeWeatherChar/INFP.jpg',
      },
    ],
  },
  // plopPoolCharEng
  {
    info: {
      mainTitle: 'Swimming Pool Personality Test',
      subTitle: `Let's find out my personality in the swimming pool`,
      mainUrl: 'plopPoolCharEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/plopPoolCharEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/plopPoolCharEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          'What would I be like if I went to a pool with a lot of people and an exciting atmosphere?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'I think the more people there are, the more exciting and fun it will be',
          },
          {
            type: 'I',
            score: 5,
            content: "I think I'll want to play a little bit and go home",
          },
        ],
      },
      {
        which: 'EI',
        question: 'To people around me?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I talk a lot about myself',
          },
          {
            type: 'I',
            score: 5,
            content: 'I listen to people around me and react to them',
          },
        ],
      },
      {
        which: 'EI',
        question: 'At a place where a lot of people gathered?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I want people to focus on me`,
          },
          {
            type: 'I',
            score: 5,
            content: `I move quietly so I don't let people focus on me`,
          },
        ],
      },
      {
        which: 'SN',
        question: "I'm watching a musical performance!",
        answers: [
          {
            type: 'S',
            score: 2,
            content: `It's fun. You sing well`,
          },
          {
            type: 'N',
            score: 5,
            content: `If I were to perform on that stage, I would be really nervous`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'When I explain something?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Explain mainly about the facts',
          },
          {
            type: 'N',
            score: 5,
            content: `Use metaphors to explain`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'Trying to get into the pool and the water looks too deep!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `The water is a bit deep. I should be careful`,
          },
          {
            type: 'N',
            score: 5,
            content: `I got a cramp in my foot and fell into the water, and someone did CPR...`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What if a close friend suddenly asks you, "Can you call me now?"`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'It tells you when you can make a call',
          },
          {
            type: 'F',
            score: 5,
            content: "I'm worried, so I ask what's going on",
          },
        ],
      },
      {
        which: 'TF',
        question:
          'Friend who fell down in a slippery pool while playing! My reaction?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `That's why it's dangerous to play pranks in slippery places! Let's go to the hospital!`,
          },
          {
            type: 'F',
            score: 5,
            content:
              'Are you okay? Can you get up? You must be sick. Do you want to go to the hospital together?',
          },
        ],
      },
      {
        which: 'TF',
        question: `What if you had to choose a more comfortable friend?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'A friend who solves my problems',
          },
          {
            type: 'F',
            score: 5,
            content: 'Friend who sympathizes with my problem',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What if I go to a famous swimming pool?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Investigate the type of pool, equipment, etc. in advance and organize what to do first`,
          },
          {
            type: 'P',
            score: 5,
            content: `Go and focus on places that look fun`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'If I buy a diary?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I used it until the end',
          },
          {
            type: 'P',
            score: 5,
            content: 'leave in the corner',
          },
        ],
      },
      {
        which: 'JP',
        question: 'How is your daily life?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'A certain pattern exists',
          },
          {
            type: 'P',
            score: 5,
            content: 'Every day is a different daily routine',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolCharEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolCharEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolCharEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolCharEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolCharEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolCharEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolCharEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolCharEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolCharEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolCharEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolCharEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolCharEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolCharEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolCharEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolCharEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolCharEng/INFP.jpg',
      },
    ],
  },
  // plopPoolChar
  {
    info: {
      mainTitle: '풍덩! 풀장 성격 테스트',
      subTitle: '시원한 풀장에 풍덩! 빠진 내 성격은?',
      mainUrl: 'plopPoolChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/plopPoolChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/plopPoolChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '사람이 많고 신나는 분위기의 핫한 풀장에 가면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '있을수록 더 신나고 재밌다',
          },
          {
            type: 'I',
            score: 5,
            content: '조금만 있어도 기가 빨리고 피곤하다',
          },
        ],
      },
      {
        which: 'EI',
        question: '나는 평소에 주변인들에게?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '내 얘기를 많이 하고 떠드는 편이다',
          },
          {
            type: 'I',
            score: 5,
            content: '주변 얘기를 많이 들어주고 리액션 해주는 편이다',
          },
        ],
      },
      {
        which: 'EI',
        question: '사람들이 많이 모인 자리에서?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `나서서 집중 받고 싶음`,
          },
          {
            type: 'I',
            score: 5,
            content: `있는 듯 없는 듯 조용히.. 관심받고 싶지 않음`,
          },
        ],
      },
      {
        which: 'SN',
        question: '뮤지컬 공연을 보고 있다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `재밌네 노래 잘한다`,
          },
          {
            type: 'N',
            score: 5,
            content: `내가 저기 서면 진짜 떨리겠지 삑사리 나면 웃기겠다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '뭔가를 설명할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '사실 위주로 팩트에 기반하여 설명한다',
          },
          {
            type: 'N',
            score: 5,
            content: `마치 ~ 처럼 ~ 같이 비유를 하면서 설명한다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '풀장에 들어가려고 하는데 물이 너무 깊어 보인다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `물이 좀 깊네 조심해야겠다`,
          },
          {
            type: 'N',
            score: 5,
            content: `발에 쥐나서 물에 빠져가지고 누군가 심폐소생술을…`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친한 친구가 갑자기 지금 통화 가능해..? 라고 물어본다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '통화 가능한 시간대를 알려준다',
          },
          {
            type: 'F',
            score: 5,
            content: '뭔 일 있나 걱정돼서 무슨 일 있는지 물어본다',
          },
        ],
      },
      {
        which: 'TF',
        question: '장난치다가 미끄러운 풀장에서 넘어진 친구! 내 반응은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `그러게 왜 미끄러운 데서 장난을 쳐! 병원 안 가도 되겠어?`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '너 괜찮아??? 일어날 수 있겠어? 아프겠다ㅜ 같이 병원 갈까?',
          },
        ],
      },
      {
        which: 'TF',
        question: `아프니까 청춘이다!!라는 말에 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '아프면 환자야ㅋ',
          },
          {
            type: 'F',
            score: 5,
            content: '맞아! 같이 파이팅 하자!',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 유명한 풀장에 간다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `미리 풀장 종류, 기구 등 조사하고 뭐부터 할지 정리해서 감`,
          },
          {
            type: 'P',
            score: 5,
            content: `가서 재밌어 보이는 곳 위주로 들어감`,
          },
        ],
      },
      {
        which: 'JP',
        question: '다이어리를 구입하면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '끝까지 빼곡하게 사용함',
          },
          {
            type: 'P',
            score: 5,
            content: '쓰다 안 쓰다 결국 구석에 방치함',
          },
        ],
      },
      {
        which: 'JP',
        question: '나의 일상에는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '일정한 패턴이 존재함',
          },
          {
            type: 'P',
            score: 5,
            content: '그날그날 매일이 다른 일상임',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/plopPoolChar/INFP.jpg',
      },
    ],
  },
  // zigzagCharEng
  {
    info: {
      mainTitle: 'Zigzag Personality Test',
      subTitle: 'What is your personality that you see in zigzag?',
      mainUrl: 'zigzagCharEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/zigzagCharEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/zigzagCharEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'What about you when asked?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Answer first before thinking about the question',
          },
          {
            type: 'I',
            score: 5,
            content: 'After thinking about the question, answer',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'When you attended a reunion after a long time, what about you?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: "I talk excitedly with my friend who I wasn't close to",
          },
          {
            type: 'I',
            score: 5,
            content: 'I only talk to my close friends',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'When you think about what to do over the weekend, what about you?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I'm going to hang out with my friend outside!`,
          },
          {
            type: 'I',
            score: 5,
            content: `I'm going to relax at home alone`,
          },
        ],
      },
      {
        which: 'SN',
        question: "I'm home alone, but someone suddenly knocks!",
        answers: [
          {
            type: 'S',
            score: 2,
            content: `What is it? Who is it? Should I pretend I'm not there?`,
          },
          {
            type: 'N',
            score: 5,
            content: `Who is it? Door-to-door salesperson? What if he's here to evangelize?`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What about you when you suddenly say you have superpowers?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: "You're talking nonsense",
          },
          {
            type: 'N',
            score: 5,
            content: `Well, what should I do first? How should I use this ability??`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What about you when you explain something to a friend?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I'm good at explaining as it is, but I'm not good at imagining it in my head`,
          },
          {
            type: 'N',
            score: 5,
            content: `I know it well with my head, but I can't explain it well with words`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What about you when a fight broke out between friends?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              "They'll figure it out on their own. But what's the reason for the fight?",
          },
          {
            type: 'F',
            score: 5,
            content: 'Why are friends fighting? How do I solve it?',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What about you when a friend feels sorry and puts off an appointment?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Okay, see you next time`,
          },
          {
            type: 'F',
            score: 5,
            content: "What's the matter? I hope it's no big deal",
          },
        ],
      },
      {
        which: 'TF',
        question: `What about you when a friend breaks up?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'A better person will show up',
          },
          {
            type: 'F',
            score: 5,
            content: 'Are you okay? How did you break up?',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What about you when you find a pretty cafe while traveling with a friend?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `It wasn't in today's schedule, so let's go next time`,
          },
          {
            type: 'P',
            score: 5,
            content: `Oh, that's pretty. Do you want to go in there?`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'What about you when you write a report?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Systematically write long before the deadline',
          },
          {
            type: 'P',
            score: 5,
            content:
              'I just check the schedule first and delay it and write it in a hurry',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What about you when you think about what a lazy person would be like?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: "Someone who doesn't plan what to do today",
          },
          {
            type: 'P',
            score: 5,
            content: 'A person who does nothing at home',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/zigzagCharEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/zigzagCharEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/zigzagCharEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/zigzagCharEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/zigzagCharEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/zigzagCharEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/zigzagCharEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/zigzagCharEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/zigzagCharEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/zigzagCharEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/zigzagCharEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/zigzagCharEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/zigzagCharEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/zigzagCharEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/zigzagCharEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/zigzagCharEng/INFP.jpg',
      },
    ],
  },
  // zigzagChar
  {
    info: {
      mainTitle: '지그재그 성격 테스트',
      subTitle: '지그재그로 보는 나의 성격은?',
      mainUrl: 'zigzagChar',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/zigzagChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/zigzagChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '질문을 받았을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '질문에 대해 생각하기 전 우선 대답',
          },
          {
            type: 'I',
            score: 5,
            content: '질문에 대해 생각해 본 후 대답',
          },
        ],
      },
      {
        which: 'EI',
        question: '오랜만에 동창회에 참석하였을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '안 친했던 친구와도 신나게 대화함',
          },
          {
            type: 'I',
            score: 5,
            content: '친했던 친구들하고만 대화함',
          },
        ],
      },
      {
        which: 'EI',
        question: '주말에 할 일을 생각할 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `밖에서 친구랑 놀아야지!`,
          },
          {
            type: 'I',
            score: 5,
            content: `집에서 혼자 휴식을 해야지`,
          },
        ],
      },
      {
        which: 'SN',
        question: '혼자 집에 있는데 누가 갑자기 노크를 한다! 이때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `뭐지? 누구지? 없는 척할까?`,
          },
          {
            type: 'N',
            score: 5,
            content: `누굴까? 방문 판매원? 전도하러 온 거면 어쩌지?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '갑자기 초능력이 생겼다고 가정 했을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '말 도 안 되는 소리하고 있네',
          },
          {
            type: 'N',
            score: 5,
            content: `음 뭐부터 하지? 이 능력을 어떻게 활용하면 좋을까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구에게 무언가를 설명할 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `있는 그대로 설명 잘하지만 머릿속 상상은 잘 못함`,
          },
          {
            type: 'N',
            score: 5,
            content: `머리로는 잘 아는데 말로 설명은 잘 못함`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구들 사이에서 싸움이 일어났을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '알아서 잘 해결하겠지~ 근데 싸운 이유가 뭐야?',
          },
          {
            type: 'F',
            score: 5,
            content: '친구끼리 왜 싸우는 거야 ㅠㅠ 어떻게 해결하지?',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 미안하면서 약속을 미뤘을 때 당신은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `그래 다음에 보자~`,
          },
          {
            type: 'F',
            score: 5,
            content: '무슨 일 있어? 별일 아니길 바라',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 이별을 하였을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '더 좋은 사람 나타날 거야',
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮아? 어쩌다가 헤어진 거야 ㅜ',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구와 여행 중 예쁜 카페를 발견하였을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `오늘 일정에는 없던 곳이니까 다음에 가자`,
          },
          {
            type: 'P',
            score: 5,
            content: `오 저기 예쁜데 한번 들어가 볼래?`,
          },
        ],
      },
      {
        which: 'JP',
        question: '보고서를 작성할 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '체계적으로 기한 한참 전에 작성한다',
          },
          {
            type: 'P',
            score: 5,
            content: '일정만 먼저 확인하고 미루다가 급하게 작성한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '게으른 사람은 어떤 사람일지 생각할 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '오늘 할 일을 계획하지도 않고 안 하는 사람',
          },
          {
            type: 'P',
            score: 5,
            content: '집에서 아무것도 안하는 사람',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/zigzagChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/zigzagChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/zigzagChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/zigzagChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/zigzagChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/zigzagChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/zigzagChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/zigzagChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/zigzagChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/zigzagChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/zigzagChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/zigzagChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/zigzagChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/zigzagChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/zigzagChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/zigzagChar/INFP.jpg',
      },
    ],
  },
  // loveDiceLoveEng
  {
    info: {
      mainTitle: 'Love Dice Romance Test',
      subTitle: 'What is my romance that you recognize by dice?',
      mainUrl: 'loveDiceLoveEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveDiceLoveEng-intro.jpeg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveDiceLoveEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'love',
      description: `Love Characteristic test with Dice\n
        Find out the love styles of your SO, Friends and Yourself!`,
    },
    questions: [
      {
        which: 'EI',
        question:
          'When your partner says they will introduce their friends, You would?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Great! When can we meet? (You immediately make plans)',
          },
          {
            type: 'I',
            score: 5,
            content:
              'Yeah, sure, introduce me next time. (You put it off for now)',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'When I went on two dates in a row early in the relationship, You would?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Ah, want to see again',
          },
          {
            type: 'I',
            score: 5,
            content: 'Ah, need a break now',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What is your preferred place to go on a date with your partner?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Hot place`,
          },
          {
            type: 'I',
            score: 5,
            content: `Indoor`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'When watching a drama with my partner, You would?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I focus on dramas`,
          },
          {
            type: 'N',
            score: 5,
            content: `I wonder how my partner would react in various situations`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'When partner asks, What will we be doing in 20 years?, You would?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Hmm, I think about it for a moment',
          },
          {
            type: 'N',
            score: 5,
            content: `A lot of different thoughts flash through my mind in an instant`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          "When my partner says, 'A friend said their meeting with the in-laws got called off, You would?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Oh, really? You really never know what might happen`,
          },
          {
            type: 'N',
            score: 5,
            content: `I’m really curious about why it fell apart, and I start imagining all sorts of scenarios`,
          },
        ],
      },
      {
        which: 'TF',
        question: `When your partner drops their phone drop, you would?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'You should have been careful. What did you do',
          },
          {
            type: 'F',
            score: 5,
            content: 'Oh no! Is the screen okay? Did it crack?',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'When your partner says they cut their hand while opening a package, You would?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `How much are you hurt? You have to be more careful`,
          },
          {
            type: 'F',
            score: 5,
            content: 'Are you okay? Oh no! Did you put any ointment on it?',
          },
        ],
      },
      {
        which: 'TF',
        question: `When my partner is late for a date, the message I want to receive is...?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'To explain the reason for being late and saying be there soon',
          },
          {
            type: 'F',
            score: 5,
            content: "Apologizing and saying they'be there soon",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'My trip to Europe with my partner is one week away, You would?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I've already finished planning and have roughly packed my bags`,
          },
          {
            type: 'P',
            score: 5,
            content: `What planning! The trip is spontaneous! Since it's Europe, I can pack two days before`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'When I go on a date with my partner, You would?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'plan in advance and mostly go on dates according to that plan',
          },
          {
            type: 'P',
            score: 5,
            content: 'Go on dates flexibly, adapting to the situation',
          },
        ],
      },
      {
        which: 'JP',
        question:
          "The day I decided with my partner to work out for an hour each day and I couldn't keep it right away, You would?",
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I feel disappointed with myself, so I get up and decide to work out for 30 minutes right now',
          },
          {
            type: 'P',
            score: 5,
            content: 'Oh~~~ from tomorrow~~~ I will definitely do it',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLoveEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLoveEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLoveEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLoveEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLoveEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLoveEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLoveEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLoveEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLoveEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLoveEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLoveEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLoveEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLoveEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLoveEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLoveEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLoveEng/INFP.jpg',
      },
    ],
  },
  // loveDiceLove
  {
    info: {
      mainTitle: '러브 다이스 연애 테스트',
      subTitle: '러브 다이스(주사위)로 보는 나의 연애는?',
      mainUrl: 'loveDiceLove',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveDiceLove-intro.jpeg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveDiceLove-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
      description: `주사위로보는 연애 성격 테스트 입니다\n
        좋아하는 연인,친구,나의 연애 스타일을 알아보세요!`,
    },
    questions: [
      {
        which: 'EI',
        question: '연인이 친구들을 소개해 준다고 할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '오 좋아! 언제 볼까?(바로 약속을 잡는다)',
          },
          {
            type: 'I',
            score: 5,
            content: '응 그래 다음에 소개해 줘~(일단 미루고 본다)',
          },
        ],
      },
      {
        which: 'EI',
        question: '연애 초반 이틀 연속 데이트를 했을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '아~ 또 만나고 싶다',
          },
          {
            type: 'I',
            score: 5,
            content: '아~ 이제 좀 쉬어야겠다',
          },
        ],
      },
      {
        which: 'EI',
        question: '연인과 데이트할 때 선호하는 공간은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `핫 플`,
          },
          {
            type: 'I',
            score: 5,
            content: `실내`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인과 드라마를 볼 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `드라마에 집중한다`,
          },
          {
            type: 'N',
            score: 5,
            content: `연인이라면 이런저런 상황에 어떨지 궁금하다`,
          },
        ],
      },
      {
        which: 'SN',
        question: "'20년 뒤 우린 뭘 하고 있을까?' 하고 연인이 물을 때 나는?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: '글쎄..? 하고 한번 생각해 본다',
          },
          {
            type: 'N',
            score: 5,
            content: `순식간에 머릿속에 수많은 상상이 스쳐간다`,
          },
        ],
      },
      {
        which: 'SN',
        question: "연인이 'OO이 상견례 자리에서 파토 났대~'라고 할 때 나는?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: `어머 진짜? 역시 사람 일은 몰라`,
          },
          {
            type: 'N',
            score: 5,
            content: `왜 파토 났는지 무지 궁금하면서 오만 상상 펼침`,
          },
        ],
      },
      {
        which: 'TF',
        question: `연인이 휴대폰을 떨어트렸을 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '조심 좀 하지 뭐 하다가 그랬어?',
          },
          {
            type: 'F',
            score: 5,
            content: '헐 액정 괜찮아? 안 깨졌어?',
          },
        ],
      },
      {
        which: 'TF',
        question: '연인이 택배 뜯다가 손이 베였다고 한다 나의 답변은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `얼마나 다쳤는데? 조심 좀 하지`,
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮아? 어떡해ㅠㅠ 약은 발랐어?',
          },
        ],
      },
      {
        which: 'TF',
        question: `데이트에 늦은 연인 내가 원하는 연락은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '늦은 이유를 대며 얼른 오겠다는 연락',
          },
          {
            type: 'F',
            score: 5,
            content: '미안하다는 사과와 함께 얼른 오겠다는 연락',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과의 유럽 여행이 일주일 남은 상황에서 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `이미 계획 끝나고 짐도 대충은 챙겨둠`,
          },
          {
            type: 'P',
            score: 5,
            content: `계획은 무슨 계획! 여행은 즉흥! 짐은 유럽이니까 이틀 전엔 챙기면 되겠지?`,
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 데이트를 할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '미리 계획하고 대부분 그 계획대로 데이트한다',
          },
          {
            type: 'P',
            score: 5,
            content: '상황 봐가면서 융통성 있게 데이트한다',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '연인과 각자 매일 1시간씩 운동하기로 결심한 날 그날 바로 못 지켰을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '자신이 한심해서,, 지금이라도 30분이라도 하자 하고 일어난다',
          },
          {
            type: 'P',
            score: 5,
            content: '아~~~ 내일부터~~~~꼭 한다!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLove/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLove/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLove/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLove/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLove/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLove/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLove/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLove/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLove/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLove/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLove/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLove/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLove/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLove/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLove/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveDiceLove/INFP.jpg',
      },
    ],
  },
  // dollarSaving
  {
    info: {
      mainTitle: '달러 저금 성격 테스트',
      subTitle: '달러 저금통에 들어간 내 성격은?',
      mainUrl: 'dollarSaving',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/dollarSaving-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/dollarSaving-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '약속을 나갈 때 드는 생각은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '벌써부터 신나~',
          },
          {
            type: 'I',
            score: 5,
            content: '아 나가기 싫어 약속 취소 안 하나?',
          },
        ],
      },
      {
        which: 'EI',
        question: '약속이 취소되었다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '놀 수 있는 다른 친구를 찾아볼까?',
          },
          {
            type: 'I',
            score: 5,
            content: '집에서 마음 편히 쉴 수 있겠네 편-안',
          },
        ],
      },
      {
        which: 'EI',
        question: '다 놀고 집에 돌아왔을 때는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `오늘도 재밌게 잘 놀았다~`,
          },
          {
            type: 'I',
            score: 5,
            content: `빨리 씻은 다음 나만의 힐링을 해야지`,
          },
        ],
      },
      {
        which: 'SN',
        question: '하루 일과를 말할 때는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `이런저런 일이 있었어`,
          },
          {
            type: 'N',
            score: 5,
            content: `이런 일이 있었는데 이때 기분이 진짜 좋았어`,
          },
        ],
      },
      {
        which: 'SN',
        question: '음식 주문 후 기다리던 중 서비스를 받았다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '뭐지? 남은 음식 주는 건가? 오래 걸리나?',
          },
          {
            type: 'N',
            score: 5,
            content: `무슨 일이지? 사장님 기분이 좋나? 아님 내가 예뻐서?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '갑자기 바퀴벌레로 변하면 어떻게 할 거야?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `갑자기 바퀴벌레로 변하다니 그럴 일은 없어`,
          },
          {
            type: 'N',
            score: 5,
            content: `헐 일단 죽지 않기 위해 숨어 지내야 되나? 너무 싫을 것 같아`,
          },
        ],
      },
      {
        which: 'TF',
        question: `힐링 하기 위해 여행을 갈 거야!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '오 어디로 놀러 가?',
          },
          {
            type: 'F',
            score: 5,
            content: '요즘 힘든 일 있었어? 잘 놀다 와~',
          },
        ],
      },
      {
        which: 'TF',
        question: '뒷담을 듣게 되었다!',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `기분 나쁘지만 별 상관없음`,
          },
          {
            type: 'F',
            score: 5,
            content: '마음 깊숙이 상처로 자리 잡음',
          },
        ],
      },
      {
        which: 'TF',
        question: `약속 시간에 늦었다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '미안해 어제 시간 계산을 잘못했어',
          },
          {
            type: 'F',
            score: 5,
            content: '기다리느라 힘들었지 늦어서 미안해',
          },
        ],
      },
      {
        which: 'JP',
        question: '처리하던 일에 변수가 생겼다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `다양한 플랜을 생각해 놔서 걱정이 없다`,
          },
          {
            type: 'P',
            score: 5,
            content: `어떻게 해야 되는가에 대한 걱정을 한다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '여행 캐리어 짐을 쌀 때는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '2~3일 전 미리 짐을 싸기 시작한다',
          },
          {
            type: 'P',
            score: 5,
            content: '짐 싸야지 생각만 하다가 여행 하루 전 부랴부랴 짐을 싼다',
          },
        ],
      },
      {
        which: 'JP',
        question: '혼자 여행 중 갑자기 하고 싶은 일이 생긴다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '계획에 어긋나는 일이라 하지 않는다',
          },
          {
            type: 'P',
            score: 5,
            content: '바로 하고 싶은 일을 하러 간다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/dollarSaving/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/dollarSaving/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/dollarSaving/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/dollarSaving/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/dollarSaving/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/dollarSaving/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/dollarSaving/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/dollarSaving/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/dollarSaving/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/dollarSaving/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/dollarSaving/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/dollarSaving/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/dollarSaving/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/dollarSaving/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/dollarSaving/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/dollarSaving/INFP.jpg',
      },
    ],
  },
  // charPartnerConnEng
  {
    info: {
      mainTitle: 'Personality partner Matching Test',
      subTitle: 'Who is the partner that connects to my personality?',
      mainUrl: 'charPartnerConnEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/charPartnerConnEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/charPartnerConnEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'What do you think when you leave an appointment?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: "I'm already excited",
          },
          {
            type: 'I',
            score: 5,
            content:
              "Oh, I don't feel like going out. Should I cancel the appointment?",
          },
        ],
      },
      {
        which: 'EI',
        question: 'The appointment has been cancelled!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Should I look for another friend to play with?',
          },
          {
            type: 'I',
            score: 5,
            content: 'You can relax at home. It is comfortable',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What about when you come home after playing?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I had a lot of fun today`,
          },
          {
            type: 'I',
            score: 5,
            content: `After taking a quick shower, I need to do my own healing`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'When you talk about your daily routine?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Various things happened`,
          },
          {
            type: 'N',
            score: 5,
            content: `This happened, and I felt really good`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'Been waiting after ordering food and got a service!',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              "What's this? Are you offering the leftover food? Is it taking a while?",
          },
          {
            type: 'N',
            score: 5,
            content: `What's going on? Does the boss feel good? Or is it because I'm pretty?`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What would you do if you suddenly turned into a cockroach?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Suddenly turning into a cockroach? That's not going to happen`,
          },
          {
            type: 'N',
            score: 5,
            content: `Wow, do I have to hide just to survive? That sounds awful`,
          },
        ],
      },
      {
        which: 'TF',
        question: `I'm going on a trip to heal!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Oh, where are you going?',
          },
          {
            type: 'F',
            score: 5,
            content: 'Have you had a hard time lately? Have fun!',
          },
        ],
      },
      {
        which: 'TF',
        question: 'I overheard gossip!',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I feel bad, but it doesn't matter`,
          },
          {
            type: 'F',
            score: 5,
            content: 'The hurt has deeply settled in my heart',
          },
        ],
      },
      {
        which: 'TF',
        question: `What if you're late for the appointment?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "I'm sorry. I calculated the time wrong yesterday",
          },
          {
            type: 'F',
            score: 5,
            content: "I had a hard time waiting, but I'm sorry I'm late",
          },
        ],
      },
      {
        which: 'JP',
        question: "There's a variable in what I was dealing with!",
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I have no worries because I have various plans in mind`,
          },
          {
            type: 'P',
            score: 5,
            content: `I worry about what to do`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'What about when you pack your travel luggage?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I start packing two or three days in advance',
          },
          {
            type: 'P',
            score: 5,
            content:
              "I need to pack; I've been thinking about it, and suddenly it's the day before the trip",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What if you suddenly want to do something while traveling alone?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: "I don't act because it's against the plan",
          },
          {
            type: 'P',
            score: 5,
            content: "I'm going to do what I want to do right away",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConnEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConnEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConnEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConnEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConnEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConnEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConnEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConnEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConnEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConnEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConnEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConnEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConnEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConnEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConnEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConnEng/INFP.jpg',
      },
    ],
  },
  // charPartnerConn
  {
    info: {
      mainTitle: '성격 짝꿍 연결 테스트',
      subTitle: '나의 성격과 연결되는 짝꿍은 누굴까?',
      mainUrl: 'charPartnerConn',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/charPartnerConn-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/charPartnerConn-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '약속을 나갈 때 드는 생각은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '벌써부터 신나~',
          },
          {
            type: 'I',
            score: 5,
            content: '아 나가기 싫어 약속 취소 안 하나?',
          },
        ],
      },
      {
        which: 'EI',
        question: '약속이 취소되었다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '놀 수 있는 다른 친구를 찾아볼까?',
          },
          {
            type: 'I',
            score: 5,
            content: '집에서 마음 편히 쉴 수 있겠네 편-안',
          },
        ],
      },
      {
        which: 'EI',
        question: '다 놀고 집에 돌아왔을 때는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `오늘도 재밌게 잘 놀았다~`,
          },
          {
            type: 'I',
            score: 5,
            content: `빨리 씻은 다음 나만의 힐링을 해야지`,
          },
        ],
      },
      {
        which: 'SN',
        question: '하루 일과를 말할 때는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `이런저런 일이 있었어`,
          },
          {
            type: 'N',
            score: 5,
            content: `이런 일이 있었는데 이때 기분이 진짜 좋았어`,
          },
        ],
      },
      {
        which: 'SN',
        question: '음식 주문 후 기다리던 중 서비스를 받았다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '뭐지? 남은 음식 주는 건가? 오래 걸리나?',
          },
          {
            type: 'N',
            score: 5,
            content: `무슨 일이지? 사장님 기분이 좋나? 아님 내가 예뻐서?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '갑자기 바퀴벌레로 변하면 어떻게 할 거야?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `갑자기 바퀴벌레로 변하다니 그럴 일은 없어`,
          },
          {
            type: 'N',
            score: 5,
            content: `헐 일단 죽지 않기 위해 숨어 지내야 되나? 너무 싫을 것 같아`,
          },
        ],
      },
      {
        which: 'TF',
        question: `힐링 하기 위해 여행을 갈 거야!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '오 어디로 놀러 가?',
          },
          {
            type: 'F',
            score: 5,
            content: '요즘 힘든 일 있었어? 잘 놀다 와~',
          },
        ],
      },
      {
        which: 'TF',
        question: '뒷담을 듣게 되었다!',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `기분 나쁘지만 별 상관없음`,
          },
          {
            type: 'F',
            score: 5,
            content: '마음 깊숙이 상처로 자리 잡음',
          },
        ],
      },
      {
        which: 'TF',
        question: `약속 시간에 늦었다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '미안해 어제 시간 계산을 잘못했어',
          },
          {
            type: 'F',
            score: 5,
            content: '기다리느라 힘들었지 늦어서 미안해',
          },
        ],
      },
      {
        which: 'JP',
        question: '처리하던 일에 변수가 생겼다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `다양한 플랜을 생각해 놔서 걱정이 없다`,
          },
          {
            type: 'P',
            score: 5,
            content: `어떻게 해야 되는가에 대한 걱정을 한다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '여행 캐리어 짐을 쌀 때는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '2~3일 전 미리 짐을 싸기 시작한다',
          },
          {
            type: 'P',
            score: 5,
            content: '짐 싸야지 생각만 하다가 여행 하루 전 부랴부랴 짐을 싼다',
          },
        ],
      },
      {
        which: 'JP',
        question: '혼자 여행 중 갑자기 하고 싶은 일이 생긴다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '계획에 어긋나는 일이라 하지 않는다',
          },
          {
            type: 'P',
            score: 5,
            content: '바로 하고 싶은 일을 하러 간다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConn/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConn/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConn/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConn/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConn/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConn/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConn/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConn/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConn/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConn/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConn/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConn/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConn/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConn/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConn/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/charPartnerConn/INFP.jpg',
      },
    ],
  },
  // doodleGlassBoardCharEng
  {
    info: {
      mainTitle: 'Doodle! Glassboard personality test',
      subTitle: `Let's write down my personality on a glass board!`,
      mainUrl: 'doodleGlassBoardCharEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/doodleGlassBoardCharEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/doodleGlassBoardCharEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'Trying to write on a glass board!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I write down what comes to my mind right away',
          },
          {
            type: 'I',
            score: 5,
            content: 'I write it down after I think about what to write',
          },
        ],
      },
      {
        which: 'EI',
        question: 'When I share my personal things?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I share it with my friends easily',
          },
          {
            type: 'I',
            score: 5,
            content: 'I only share it with the closest person',
          },
        ],
      },
      {
        which: 'EI',
        question: 'When I talk to someone?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Want to make others feel good`,
          },
          {
            type: 'I',
            score: 5,
            content: `I care so that other people don't feel bad`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'You have to memorize hard words!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I write it down in my notebook and memorize it recklessly`,
          },
          {
            type: 'N',
            score: 5,
            content: `I look at the words until I understand`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'The glass board is full of doodles!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'There are a lot of doodles',
          },
          {
            type: 'N',
            score: 5,
            content: `I wonder who wrote this. Are the couple seeing each other without breaking up?`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What comes to mind when you think of "Banana"?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `It's yellow. It looks delicious. It's long`,
          },
          {
            type: 'N',
            score: 5,
            content: `Monkey, banana milk`,
          },
        ],
      },
      {
        which: 'TF',
        question: `My answer to a friend who said, "I'm sorry, I don't think I can go to the meeting today`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Okay, see you next time when you have time',
          },
          {
            type: 'F',
            score: 5,
            content: "What's wrong? I'm worried because my voice is weak",
          },
        ],
      },
      {
        which: 'TF',
        question:
          '"You did a good job, but lets fix this part." What would I do when I hear compliments?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I'm happy to be praised for doing a good job`,
          },
          {
            type: 'F',
            score: 5,
            content: "I'm a little sad because I think I've been pointed out",
          },
        ],
      },
      {
        which: 'TF',
        question: `Someone wrote a gossipy doodle on a glass board!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Why would you write gossip here',
          },
          {
            type: 'F',
            score: 5,
            content: "If you see this doodle yourself, you'll feel bad",
          },
        ],
      },
      {
        which: 'JP',
        question:
          "What about me when I don't get good results for the work I prepared?",
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Where did it go wrong? What kind of problem did it have?`,
          },
          {
            type: 'P',
            score: 5,
            content: `Actually, I don't think it was good from the beginning. Let's start again!`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'When you choose a drink at a cafe?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Choose the drink you were supposed to eat',
          },
          {
            type: 'P',
            score: 5,
            content: 'I choose the drink I want to eat now',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What if I set a goal?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Do not modify until accomplished',
          },
          {
            type: 'P',
            score: 5,
            content: 'Changes in goal with frequent modifications',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardCharEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardCharEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardCharEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardCharEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardCharEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardCharEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardCharEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardCharEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardCharEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardCharEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardCharEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardCharEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardCharEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardCharEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardCharEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardCharEng/INFP.jpg',
      },
    ],
  },
  // doodleGlassBoardChar
  {
    info: {
      mainTitle: 'Doodle! 유리 보드 성격 테스트',
      subTitle: '내 성격을 유리보드에 적어보자!',
      mainUrl: 'doodleGlassBoardChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/doodleGlassBoardChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/doodleGlassBoardChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '유리 보드에 글을 써보려고 한다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '일단 생각나는 대로 우다다 적음',
          },
          {
            type: 'I',
            score: 5,
            content: '뭘 쓸지 충분히 고민하고 적음',
          },
        ],
      },
      {
        which: 'EI',
        question: '나는 내 개인적인 일을?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '주변 친구들에게 쉽게 공유한다',
          },
          {
            type: 'I',
            score: 5,
            content: '알리지 않고 가장 가까운 사람에게만 공유한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '나는 누군가와 대화할 때?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `상대방의 기분을 좋게 만들어주고 싶음`,
          },
          {
            type: 'I',
            score: 5,
            content: `상대방의 기분을 나쁘지 않게 하고 싶음`,
          },
        ],
      },
      {
        which: 'SN',
        question: '어려운 단어를 외워야 한다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `노트에 적어가며 무작정 외움`,
          },
          {
            type: 'N',
            score: 5,
            content: `일단 이해가 갈 때까지 쳐다 봄`,
          },
        ],
      },
      {
        which: 'SN',
        question: '유리 보드에 낙서가 가득하다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '낙서가 많네',
          },
          {
            type: 'N',
            score: 5,
            content: `어떤 사람들이 쓴 걸까? 이 커플은 잘 만나고 있을까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '‘바나나’하면 떠오르는 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `노랗다, 맛있겠다, 길다`,
          },
          {
            type: 'N',
            score: 5,
            content: `원숭이, 바나나우유, 필리핀`,
          },
        ],
      },
      {
        which: 'TF',
        question: `‘미안 오늘 모임에 난 못 나갈 것 같아.. ‘ 라는 친구에게 내 대답은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '그래 다음에 시간 날 때 보자~',
          },
          {
            type: 'F',
            score: 5,
            content: '무슨 일 있어..? 목소리에 기운이 없는데 걱정된다',
          },
        ],
      },
      {
        which: 'TF',
        question: '‘잘했는데 이 부분만 다시 고쳐보자’라는 칭찬을 들으면 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `잘했다고 칭찬받아서 기분이 좋다`,
          },
          {
            type: 'F',
            score: 5,
            content: '지적받았다고 생각이 들면서 조금 서럽다',
          },
        ],
      },
      {
        which: 'TF',
        question: `누군가 유리 보드에 남을 험담하는 낙서를 써놨다!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '살벌하네 ㄷㄷ',
          },
          {
            type: 'F',
            score: 5,
            content: '저런 글을 당사자가 보면 기분 나쁠 텐데.. 너무하네..',
          },
        ],
      },
      {
        which: 'JP',
        question: '준비한 일에 대한 성과가 좋지 않을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `어디서부터 잘못된 거지? 어떤 문제가 있었던 걸까`,
          },
          {
            type: 'P',
            score: 5,
            content: `사실 처음부터 좀 별로였던 거 같아 다시 시작해 보자!`,
          },
        ],
      },
      {
        which: 'JP',
        question: '카페에서 음료를 고를 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '먹기로 정했던 음료를 고른다',
          },
          {
            type: 'P',
            score: 5,
            content: '메뉴판을 보고 오늘 끌리는 음료를 고른다',
          },
        ],
      },
      {
        which: 'JP',
        question: '나는 내가 정한 목표를?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '이룰 때까지 수정하지 않음',
          },
          {
            type: 'P',
            score: 5,
            content: '자주 수정하며 목표가 바뀜',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/doodleGlassBoardChar/INFP.jpg',
      },
    ],
  },
  // colorStep
  {
    info: {
      mainTitle: '컬러 스텝 성격 테스트',
      subTitle: '컬러 스텝으로 보는 나의 성격',
      mainUrl: 'colorStep',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/colorStep-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/colorStep-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '소개팅으로 나간 자리. 이때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '적극적인 질문+표현하기',
          },
          {
            type: 'I',
            score: 5,
            content: '주의 깊게 경청+속으로 생각하기',
          },
        ],
      },
      {
        which: 'EI',
        question: '길 가다 우연히 학창 시절 친구들 만났다. 이때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '반가운 리액션과 동시에 인사하며 스몰토크 한다',
          },
          {
            type: 'I',
            score: 5,
            content: '못 본 척 땅을 보며 빠르게 지나간다',
          },
        ],
      },
      {
        which: 'EI',
        question: '화나는 일이 생겼을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `만나서 이야기하기`,
          },
          {
            type: 'I',
            score: 5,
            content: `카톡으로 이야기하기`,
          },
        ],
      },
      {
        which: 'SN',
        question: '평소에 겪기 힘든 신비로운 일을 겪게 되었을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `사실 위주로 이야기한다`,
          },
          {
            type: 'N',
            score: 5,
            content: `느낀 점 위주로 이야기한다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '좀비가 나오는 영화를 관람 후 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '좀비 바이러스는 어디서부터 시작된 걸까? 감염경로가 궁금해져',
          },
          {
            type: 'N',
            score: 5,
            content: `어떻게 생존하지? 다른 사람들도 살아있으려나?! 설마 내가 좀비가 되진 않겠지?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구와의 점심 약속이 있다. 음식점을 정할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `검증된 맛집+자주 먹던 메뉴로 간다`,
          },
          {
            type: 'N',
            score: 5,
            content: `새로운 맛집+평소에 잘 못 먹는 메뉴로 간다`,
          },
        ],
      },
      {
        which: 'TF',
        question: `내가 조금 더 신경 쓰이는 점은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '대화의 주제와 흐름',
          },
          {
            type: 'F',
            score: 5,
            content: '상대방의 표정과 리액션',
          },
        ],
      },
      {
        which: 'TF',
        question: '연인의 거슬리는 행동을 보았을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `이해하기 쉽게 사실을 근거로 정리해서 얘기해 준다`,
          },
          {
            type: 'F',
            score: 5,
            content: '상처받을까 싶어 애써 둘러서 얘기해 준다',
          },
        ],
      },
      {
        which: 'TF',
        question: `태풍 영향으로 비행기가 못 뜬다는 소식을 들었을 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '티켓 값 환불은 가능한가요?',
          },
          {
            type: 'F',
            score: 5,
            content: '어떻게 기다린 여행인데ㅜㅜ',
          },
        ],
      },
      {
        which: 'JP',
        question: '갑자기 일이 휘몰아쳐올 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `우선순위대로 정리 후 하나씩 처리해간다`,
          },
          {
            type: 'P',
            score: 5,
            content: `급한 일부터 빠르게 처리한다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '갑자기 휴가를 받게 되었다. 이때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '잔고 확인 후 여유자금이 없으면 포기한다',
          },
          {
            type: 'P',
            score: 5,
            content:
              '‘미래의 내가 책임지겠지’라는 생각으로 일단은 떠날 준비한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '놀이공원에 갔을 때 나와 더 가까운 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '이거 다섯 개는 꼭 타야 한다고 했던 것부터 차례대로 하나씩 클리어한다',
          },
          {
            type: 'P',
            score: 5,
            content: '재밌어 보이는 것부터 클리어한다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/colorStep/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/colorStep/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/colorStep/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/colorStep/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/colorStep/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/colorStep/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/colorStep/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/colorStep/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/colorStep/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/colorStep/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/colorStep/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/colorStep/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/colorStep/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/colorStep/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/colorStep/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/colorStep/INFP.jpg',
      },
    ],
  },
  // charSituationEng
  {
    info: {
      mainTitle: 'Personality Situation Q&A Test',
      subTitle: 'Write down your personality!',
      mainUrl: 'charSituationEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/charSituationEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/charSituationEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'What is your preferred hobby?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'A hobby with a lot of outdoor activities',
          },
          {
            type: 'I',
            score: 5,
            content: 'Indoor hobbies that can be done alone',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Is the company indoors or outdoors?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Outdoor',
          },
          {
            type: 'I',
            score: 5,
            content: 'Indoor',
          },
        ],
      },
      {
        which: 'EI',
        question: "May I invite my friend who you don't know is nearby?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: `We can get closer. That's nice`,
          },
          {
            type: 'I',
            score: 5,
            content: `I think it'll be uncomfortable because I'm not close to him`,
          },
        ],
      },
      {
        which: 'SN',
        question: "Don't think about anything from now on!",
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I really don't think about anything.`,
          },
          {
            type: 'N',
            score: 5,
            content: `I keep doing it because I told you not to think about it. What should I eat today?`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'Found a car stopped without an owner in front of the bush!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Is the car broken?',
          },
          {
            type: 'N',
            score: 5,
            content: `Are you stopped for urgent business? Aren't you on the lawn?`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What if the earth were to perish tomorrow?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `All of a sudden, the earth has come to an end? No way!`,
          },
          {
            type: 'N',
            score: 5,
            content: `What should I do? Do what I want to do? What if it doesn't end?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `Friend is showing signs of depression!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              "What's wrong with you? (If you ask and don't answer, stay put)",
          },
          {
            type: 'F',
            score: 5,
            content: "What's going on? I'll listen to you. Tell me.",
          },
        ],
      },
      {
        which: 'TF',
        question: 'I feel like COVID-19. What should I do?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `First, go to the hospital and get an examination`,
          },
          {
            type: 'F',
            score: 5,
            content:
              "Does it hurt a lot? What are your symptoms? Let's go to the hospital",
          },
        ],
      },
      {
        which: 'TF',
        question: `I have a problem. Please tell me a solution`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'It provides a practically possible solution',
          },
          {
            type: 'F',
            score: 5,
            content: 'Empathize with emotions and find solutions together',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What should I do on my day off?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `He moves intentionally, such as housework that he couldn't do`,
          },
          {
            type: 'P',
            score: 5,
            content: `First, I rest and do what comes to mind`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'When do you buy daily necessities?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Buy it in advance before it falls',
          },
          {
            type: 'P',
            score: 5,
            content:
              "Buy it when you know it's dropped. Or buy it when it's on sale if it's in front of you",
          },
        ],
      },
      {
        which: 'JP',
        question: 'What time do you usually arrive at your appointment?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I arrive earlier than my appointment',
          },
          {
            type: 'P',
            score: 5,
            content: 'Arrive on time or late for an appointment',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/charSituationEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/charSituationEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/charSituationEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/charSituationEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/charSituationEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/charSituationEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/charSituationEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/charSituationEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/charSituationEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/charSituationEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/charSituationEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/charSituationEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/charSituationEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/charSituationEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/charSituationEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/charSituationEng/INFP.jpg',
      },
    ],
  },
  // charSituation
  {
    info: {
      mainTitle: '성격 상황 문답 테스트',
      subTitle: '상황에 따른 너의 성격을 알아보고 적어봐!',
      mainUrl: 'charSituation',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/charSituation-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/charSituation-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '선호하는 취미 생활은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '야외 활동이 많은 취미 ',
          },
          {
            type: 'I',
            score: 5,
            content: '집 안에서 혼자 가능한 취미',
          },
        ],
      },
      {
        which: 'EI',
        question: '회사는 실내? 실외?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '실내',
          },
          {
            type: 'I',
            score: 5,
            content: '실외',
          },
        ],
      },
      {
        which: 'EI',
        question: '너 모르는 내 친구 근처라고 하는데 불러도 돼?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `친해지면 되지 좋아~`,
          },
          {
            type: 'I',
            score: 5,
            content: `친하지 않은 사람이라 불편할 것 같아`,
          },
        ],
      },
      {
        which: 'SN',
        question: '지금부터 아무 생각도 하지 마!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `진짜 아무 생각도 안 함`,
          },
          {
            type: 'N',
            score: 5,
            content: `생각하지 말라니까 더 하게 되는데? 오늘 밥 뭐 먹지? `,
          },
        ],
      },
      {
        which: 'SN',
        question: '풀숲 앞에 주인 없이 멈춰 있는 차를 발견했다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '차가 고장 난 건가? ',
          },
          {
            type: 'N',
            score: 5,
            content: `화장실 가고 싶어서 세웠나? 풀숲 안에 있는 거 아니야?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내일 당장 지구가 멸망한다면? ',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `갑자기 지구가 멸망? 말도 안 돼! `,
          },
          {
            type: 'N',
            score: 5,
            content: `어떡하지 하고 싶은 것 실컷 해야 하나? 그랬는데 멸망 안 하면 어떡하지?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 우울한 기색을 보이고 있다!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '너 기분 안 좋은 일 있어? 물어보고 답 없으면 끝 ',
          },
          {
            type: 'F',
            score: 5,
            content: '무슨 일이야 우울해? 내가 다 들어줄 게 말해봐',
          },
        ],
      },
      {
        which: 'TF',
        question: '나 지금 코로나 같은데 어쩌지?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `일단 병원 가서 검사부터 받아`,
          },
          {
            type: 'F',
            score: 5,
            content: '많이 아파? 증상이 뭔데? 병원 가보자 ㅠㅠ ',
          },
        ],
      },
      {
        which: 'TF',
        question: `고민이 있는데 이거 해결책 좀 알려줘`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '친구가 현실적으로 가능한 해결책을 제시해 준다. ',
          },
          {
            type: 'F',
            score: 5,
            content: '기분에 공감해 주며 같이 해결책을 찾아본다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '여유롭게 쉬는 날엔 뭐 하는 것이 좋을까? ',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `하지 못했던 집안일 등 계획적으로 움직인다. `,
          },
          {
            type: 'P',
            score: 5,
            content: `일단 쉬다가 생각나는 일을 한다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '생필품은 언제 사는 편? ',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '떨어지기 전 미리 사둔다. ',
          },
          {
            type: 'P',
            score: 5,
            content:
              '떨어진 것을 알았을 때 산다. or 세일할 때 눈앞에 보이면 산다. ',
          },
        ],
      },
      {
        which: 'JP',
        question: '평소 약속 장소에 도착하는 시간은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '약속 시간보다 일찍 도착한다. ',
          },
          {
            type: 'P',
            score: 5,
            content: '약속 시간에 딱 맞춰 도착하거나 늦는다. ',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/charSituation/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/charSituation/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/charSituation/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/charSituation/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/charSituation/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/charSituation/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/charSituation/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/charSituation/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/charSituation/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/charSituation/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/charSituation/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/charSituation/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/charSituation/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/charSituation/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/charSituation/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/charSituation/INFP.jpg',
      },
    ],
  },
  // onigiriCharJP
  {
    info: {
      mainTitle: 'おにぎりの性格テスト',
      subTitle: '私に似合うおにぎりとは何ですか？',
      mainUrl: 'onigiriCharJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/onigiriCharJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/onigiriCharJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'ご飯を食べるときの私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '誰かと一緒に食べるのが好き',
          },
          {
            type: 'I',
            score: 5,
            content: '一人で食べる方が楽',
          },
        ],
      },
      {
        which: 'EI',
        question: '知り合いが結婚招待状を渡すために会おうと言う！',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'いいね、予定もないしちょうど良かった！',
          },
          {
            type: 'I',
            score: 5,
            content:
              '私はモバイルで受け取ればいいんだけど…会って受け取らないといけないの？',
          },
        ],
      },
      {
        which: 'EI',
        question: '普段約束をするときは？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `自分から連絡して決める`,
          },
          {
            type: 'I',
            score: 5,
            content: `相手から連絡が来て決まる`,
          },
        ],
      },
      {
        which: 'SN',
        question: '誰かにアドバイスをするときの私は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `何をすべきか具体的に一つ一つアドバイスする`,
          },
          {
            type: 'N',
            score: 5,
            content: `ある程度の方向性だけを教えて試させる`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '異性がランチを作ってくれた！他の人には豆ご飯、私には白米をくれた！',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '私に興味があるのかな',
          },
          {
            type: 'N',
            score: 5,
            content: `私にだけ白米？これはシグナル？告白されたらどうしよう？`,
          },
        ],
      },
      {
        which: 'SN',
        question: '自分のニックネームをつけるなら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `近くにある物や思いついた名前をそのままつける`,
          },
          {
            type: 'N',
            score: 5,
            content: `いろんな意味を込めてつける（単語を検索）`,
          },
        ],
      },
      {
        which: 'TF',
        question: `嫌いな豆ご飯を作ってくれた`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'ああ、豆ご飯嫌いなんだけど…白米ないの？',
          },
          {
            type: 'F',
            score: 5,
            content: '(豆ご飯嫌いだけど…）美味しそう、いただきます！',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '自分の料理に対して「すごく適当に作ったみたいだけど美味しい！」と言われたら？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `美味しいと褒められて嬉しい`,
          },
          {
            type: 'F',
            score: 5,
            content: '適当に作ったんじゃないのに…少し悲しい',
          },
        ],
      },
      {
        which: 'TF',
        question: `「君とは本当に相性がいいと思う！」と言われたら？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '私と？どこが？どうして？',
          },
          {
            type: 'F',
            score: 5,
            content: '本当？私もそう思ってた！（感動）',
          },
        ],
      },
      {
        which: 'JP',
        question: '私が達成感を感じるのは？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `成功した結果から大きな達成感を感じる`,
          },
          {
            type: 'P',
            score: 5,
            content: `過程を通して小さな達成感を感じる`,
          },
        ],
      },
      {
        which: 'JP',
        question: '友達を呼んでご飯を作るなら？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '何人いるの？このくらい準備して足りなかったらこうしよう',
          },
          {
            type: 'P',
            score: 5,
            content: 'このくらい準備して足りなかったら注文すればいいか',
          },
        ],
      },
      {
        which: 'JP',
        question: '何かに集中するときの私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '一つのことに集中して終わらせる',
          },
          {
            type: 'P',
            score: 5,
            content: '一つに集中するのが難しく、いろいろ試す',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharJP/INFP.jpg',
      },
    ],
  },
  // onigiriCharEng
  {
    info: {
      mainTitle: 'Rice ball Character personality test',
      subTitle: 'What is my rice ball character?',
      mainUrl: 'onigiriCharEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/onigiriCharEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/onigiriCharEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'What about me when I eat?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Prefers eating with someone',
          },
          {
            type: 'I',
            score: 5,
            content: 'Prefers eating alone',
          },
        ],
      },
      {
        which: 'EI',
        question:
          "What if your friend asks you to meet him because he's giving you an Wedding invitation letter?",
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              "I love it! I don't even have an appointment, so that's great!",
          },
          {
            type: 'I',
            score: 5,
            content:
              'I could have received it on my phone... Do I really have to meet in person to get it...?',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Usually when you make an appointment?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I send a message first to make an appointment`,
          },
          {
            type: 'I',
            score: 5,
            content: `The other person sends me a message so I have an appointment`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What about me when I give advice to someone?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I provide specific, step-by-step guidance on what to do.`,
          },
          {
            type: 'N',
            score: 5,
            content: `I give some direction but leave room for exploration.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'Lunch made by someone else! They made bean rice for others, but only made white rice for me!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'You must have a crush on me',
          },
          {
            type: 'N',
            score: 5,
            content: `Only white rice for me? Is this a signal? What if I confess?`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'When I make my nickname?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I simply choose a name based on nearby objects or whatever comes to mind.`,
          },
          {
            type: 'N',
            score: 5,
            content: `I incorporate various meanings into it or use word searches for inspiration.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `My friend made me bean rice that I don't like!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              "I don't like rice with beans.. Don't you have Brown rice?",
          },
          {
            type: 'F',
            score: 5,
            content:
              "(I don't like bean rice....) It looks delicious. Thank you!",
          },
        ],
      },
      {
        which: 'TF',
        question:
          "If someone eats what I made and says, 'It seems like it was made kind of carelessly, but it tastes good,' how would you feel?",
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I'm happy to be praised for being delicious`,
          },
          {
            type: 'F',
            score: 5,
            content: "I didn't make it roughly... I'm a little sad",
          },
        ],
      },
      {
        which: 'TF',
        question: `What if I hear that I'm a good match for you?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'With me? Where are you going? Why?',
          },
          {
            type: 'F',
            score: 5,
            content: "Really? That's what I thought too!! (Touched)",
          },
        ],
      },
      {
        which: 'JP',
        question: 'When do you feel a sense of accomplishment?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I feel a great sense of accomplishment from the successful results`,
          },
          {
            type: 'P',
            score: 5,
            content: `I feel a sense of accomplishment through the process`,
          },
        ],
      },
      {
        which: 'JP',
        question: "I'm going to call my friends and cook for them",
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "How many people are there? If it's not enough, it'll be like this",
          },
          {
            type: 'P',
            score: 5,
            content: "I'll do this much and order if it's not enough",
          },
        ],
      },
      {
        which: 'JP',
        question: 'What do you do when you focus on something?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'completing a task to the end',
          },
          {
            type: 'P',
            score: 5,
            content:
              "It's hard to concentrate on one, and I try many different things",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriCharEng/INFP.jpg',
      },
    ],
  },
  // onigiriChar
  {
    info: {
      mainTitle: '주먹밥 캐릭터 성격 테스트',
      subTitle: '내게 어울리는 주먹밥 캐릭터는 무엇일까?',
      mainUrl: 'onigiriChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/onigiriChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/onigiriChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
      hasBackground: true,
    },
    questions: [
      {
        which: 'EI',
        question: '밥을 먹을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '누군가와 같이 먹는 게 좋음',
          },
          {
            type: 'I',
            score: 5,
            content: '혼밥 먹는 게 더 편함',
          },
        ],
      },
      {
        which: 'EI',
        question: '지인이 청첩장을 준다고 만나자고 한다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '너무 좋지 약속도 없는데 잘 됐다!',
          },
          {
            type: 'I',
            score: 5,
            content: '난 모바일로 받아도 되는데.. 꼭 만나서 받아야 해..?',
          },
        ],
      },
      {
        which: 'EI',
        question: '보통 약속을 잡을 때?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `먼저 연락해서 잡는다`,
          },
          {
            type: 'I',
            score: 5,
            content: `먼저 연락이 와서 생긴다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '누군가에게 조언을 해줄 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `무엇을 해야 하는지 구체적으로 하나하나 조언해 줌`,
          },
          {
            type: 'N',
            score: 5,
            content: `어느 정도 방향성만 알려주고 시켜 봄`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '이성이 해주는 점심! 다른 사람들에겐 콩밥을, 나에게만 쌀밥을 주었다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '나한테 관심 있나 보네',
          },
          {
            type: 'N',
            score: 5,
            content: `나에게만 쌀밥을? 이건 분명 시그널? 고백하면 어쩌지?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내 닉네임을 짓는다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `옆에 있는 사물이나 생각나는 이름 그냥 지음`,
          },
          {
            type: 'N',
            score: 5,
            content: `이렇고 저런 의미를 함축해서 지음 (단어 검색)`,
          },
        ],
      },
      {
        which: 'TF',
        question: `싫어하는 콩밥을 해주었다`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '아나 콩밥 싫어하는데.. 쌀밥 없어?',
          },
          {
            type: 'F',
            score: 5,
            content: '(콩밥 싫은데….) 맛있겠다 잘 먹을게!?',
          },
        ],
      },
      {
        which: 'TF',
        question: '내 요리를 ‘되게 대충 만든 것 같은데 맛있다!’라고 한다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `맛있다고 칭찬받아서 기분이 좋음`,
          },
          {
            type: 'F',
            score: 5,
            content: '대충 만든 거 아닌데… 조금 서운함',
          },
        ],
      },
      {
        which: 'TF',
        question: `너랑은 진짜 잘 맞는 거 같아!라는 말을 들으면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '나랑? 어디 가? 왜?',
          },
          {
            type: 'F',
            score: 5,
            content: '진짜..? 나도 그렇게 생각했어!! (감동)',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 성취감을 느낄 때는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `성공한 결과에서 큰 성취감을 느낌`,
          },
          {
            type: 'P',
            score: 5,
            content: `과정을 통해서 소소한 성취감을 느낌`,
          },
        ],
      },
      {
        which: 'JP',
        question: '친구들을 불러 밥을 차려준다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '몇 명이지? 이 정도 해놓고 모자라면 이렇게 저렇게',
          },
          {
            type: 'P',
            score: 5,
            content: '이 정도 해주고 부족하면 시켜 먹지 뭐',
          },
        ],
      },
      {
        which: 'JP',
        question: '뭔가에 집중할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '한 가지 일을 집중해서 끝냄',
          },
          {
            type: 'P',
            score: 5,
            content: '한 가지에 집중이 어렵고 여러 가지를 시도함',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/onigiriChar/INFP.jpg',
      },
    ],
  },
  // ethereumCharEng
  {
    info: {
      mainTitle: 'Ethereum personality test',
      subTitle: 'What is your personality in Ethereum?',
      mainUrl: 'ethereumCharEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/ethereumCharEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/ethereumCharEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'Where do you get energy?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Outside',
          },
          {
            type: 'I',
            score: 5,
            content: 'Home',
          },
        ],
      },
      {
        which: 'EI',
        question: 'How do you feel when you meet a new friend?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'New friends are welcome',
          },
          {
            type: 'I',
            score: 5,
            content: 'New friends are unnecessary',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'When you finish hanging out with your friend and return home, how do you feel?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Ah, that was fun~`,
          },
          {
            type: 'I',
            score: 5,
            content: `I want to go home quickly`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'When listening to music, where is your focus?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Focus on the melody`,
          },
          {
            type: 'N',
            score: 5,
            content: `Focus on the lyrics`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'When riding a roller coaster, how do you feel?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Too scary or fun',
          },
          {
            type: 'N',
            score: 5,
            content: `What if the hat of the person in front of me flies and lands on my head?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          "When asking about a place you haven't been to, what do you say?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I haven't been there, how can I say?`,
          },
          {
            type: 'N',
            score: 5,
            content: `Won't we have fun doing ~?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What about you when your friend told you he was in a car accident?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Are you okay? Did you have insurance?',
          },
          {
            type: 'F',
            score: 5,
            content: "Oh my, you're not hurt badly, are you? What happened?",
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What if you hear, "I think its hard to get close to you and me"?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Why? What's the reason?`,
          },
          {
            type: 'F',
            score: 5,
            content: "It's so hurtful",
          },
        ],
      },
      {
        which: 'TF',
        question: `My answer to "I had a hard time saving money to buy a luxury bag"?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'How much did you pay? Show me the bag!',
          },
          {
            type: 'F',
            score: 5,
            content: 'You did a great job! How long did you collect it for?',
          },
        ],
      },
      {
        which: 'JP',
        question: 'When studying for exams, how do you study?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Study after setting a planned scope`,
          },
          {
            type: 'P',
            score: 5,
            content: `Study as it comes to mind`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'When your friend is late for a promise, how do you feel?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Why are you late when we agreed on a time?',
          },
          {
            type: 'P',
            score: 5,
            content: "I've been late before too, so I don't really mind",
          },
        ],
      },
      {
        which: 'JP',
        question: 'How do you feel when you suddenly have an appointment?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: "I already have plans, so I can't",
          },
          {
            type: 'P',
            score: 5,
            content: 'It depends on how I feel that day',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumCharEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumCharEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumCharEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumCharEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumCharEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumCharEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumCharEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumCharEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumCharEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumCharEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumCharEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumCharEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumCharEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumCharEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumCharEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumCharEng/INFP.jpg',
      },
    ],
  },
  // ethereumChar
  {
    info: {
      mainTitle: '이더리움 성격 테스트',
      subTitle: '이더리움으로 보는 나의 성격은?',
      mainUrl: 'ethereumChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/ethereumChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/ethereumChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '에너지를 얻는 장소는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '밖',
          },
          {
            type: 'I',
            score: 5,
            content: '집',
          },
        ],
      },
      {
        which: 'EI',
        question: '새로운 친구를 만날 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '새로운 친구는 대환영',
          },
          {
            type: 'I',
            score: 5,
            content: '새로운 친구 불필요',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구와 놀고 집에 돌아갈 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `아 잘 놀았다~`,
          },
          {
            type: 'I',
            score: 5,
            content: `집에 빨리 가고 싶어`,
          },
        ],
      },
      {
        which: 'SN',
        question: '노래를 들을 때 집중 포인트는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `멜로디에 집중`,
          },
          {
            type: 'N',
            score: 5,
            content: `가사에 집중`,
          },
        ],
      },
      {
        which: 'SN',
        question: '롤러코스터를 탈 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '너무 무서워 or 재미있어',
          },
          {
            type: 'N',
            score: 5,
            content: `앞 사람 모자가 날아와서 내 머리에 안착하는 거 아냐?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '만약 가보지 않은 장소에 대해 물어볼 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `간 적이 없는데 어떻게 말해?`,
          },
          {
            type: 'N',
            score: 5,
            content: `~하면서 놀지 않을까?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `“나 교통사고 당했어” 이때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '괜찮아? 보험은 들었지?',
          },
          {
            type: 'F',
            score: 5,
            content: '어머 많이 안 다쳤지? 무슨 일이야 ㅠ',
          },
        ],
      },
      {
        which: 'TF',
        question: "'너랑은 진짜 안 맞는 것 같아' 이때 당신은?",
        answers: [
          {
            type: 'T',
            score: 2,
            content: `왜? 이유가 뭐야?`,
          },
          {
            type: 'F',
            score: 5,
            content: '너무 상처야',
          },
        ],
      },
      {
        which: 'TF',
        question: `“힘들게 돈을 모아 명품 가방을 샀어” 이때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '얼마 줬어? 가방 보여줘!',
          },
          {
            type: 'F',
            score: 5,
            content: '고생했네 얼마 동안 모은 거야?',
          },
        ],
      },
      {
        which: 'JP',
        question: '시험공부를 할 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `계획적으로 공부할 범위를 정한 후 공부`,
          },
          {
            type: 'P',
            score: 5,
            content: `일단 생각나는 과목 공부`,
          },
        ],
      },
      {
        which: 'JP',
        question: '친구가 약속 시간에 늦었을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '시간을 약속을 했는데 왜 늦는 거지?',
          },
          {
            type: 'P',
            score: 5,
            content: '나도 늦은 적 있기에 별 상관 안 해',
          },
        ],
      },
      {
        which: 'JP',
        question: '갑자기 약속이 잡혔을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '계획해둔 일정이 있어서 안돼',
          },
          {
            type: 'P',
            score: 5,
            content: '그날 기분에 따라 그래 or 싫어',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/ethereumChar/INFP.jpg',
      },
    ],
  },
  // colorPompomChar
  {
    info: {
      mainTitle: '컬러 폼폼이 성격 테스트',
      subTitle: '컬러 폼폼이로 보는 나의 성격은?',
      mainUrl: 'colorPompomChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/colorPompomChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/colorPompomChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '둘 중 나와 더 가까운 것은 무엇인가?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '나는 사교적이거나 사람을 좋아하는 것 같다',
          },
          {
            type: 'I',
            score: 5,
            content: '나는 사색적이거나 내성적인 것 같다',
          },
        ],
      },
      {
        which: 'EI',
        question: '나는 어떤 방식으로 일하는 걸 선호하는가?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '팀플로 일하는 게 좋다',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자서 일하는 게 좋다',
          },
        ],
      },
      {
        which: 'EI',
        question: '나의 인간관계는 어떤 편인가?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `다양한 친구를 사귀고 있으며, 많은 사람을 알고 있다`,
          },
          {
            type: 'I',
            score: 5,
            content: `소수의 사람과 깊고 신중한 관계를 맺고 있다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '일을 하기 싫을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `하 일하기 싫다..`,
          },
          {
            type: 'N',
            score: 5,
            content: `내가 왜 일을 해야 하지,, 언제까지 하는 걸까 갑자기 핵폭탄이,,`,
          },
        ],
      },
      {
        which: 'SN',
        question: '나는 생각과 말을 할 때 어떤 편인가?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '설명은 잘 하는데 머릿속으로 구상하는 걸 잘 못함',
          },
          {
            type: 'N',
            score: 5,
            content: `머리로는 잘 아는데 말로 설명하는 걸 잘 못함`,
          },
        ],
      },
      {
        which: 'SN',
        question: '나는 사람을 판단할 때 어떤 편인가?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `그때그때 나의 느낌에 따라 판단함`,
          },
          {
            type: 'N',
            score: 5,
            content: `명확한 나만의 판단 기준이 있음`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 해준 음식의 맛이 너무 처참할 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '너무 싱거운데..? or 너무 짠데?',
          },
          {
            type: 'F',
            score: 5,
            content: '맛있다! 이런 건 어디서 배웠어?',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '만약 내가 호빵맨이 된다면 가장 아끼는 친구를 위해 머리를 떼어줄 수 있는가?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `아끼는 거랑 머리 주는 건 별개지,,`,
          },
          {
            type: 'F',
            score: 5,
            content: '아끼는 친구면 당연 떼어줘야지!!',
          },
        ],
      },
      {
        which: 'TF',
        question: `칭찬하는 상황일 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '팩트를 기반한 결과를 중시하고 칭찬',
          },
          {
            type: 'F',
            score: 5,
            content: '과정을 겪으면서 얻은 감정을 중시',
          },
        ],
      },
      {
        which: 'JP',
        question: '처음 방문한 음식점에서 메뉴를 고를 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `빠르게 스캔해서 메뉴를 결정한다`,
          },
          {
            type: 'P',
            score: 5,
            content: `처음 메뉴는 종업원에게도 물어보고 상대방 따라서 주문한다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '이메일 확인을 하고 대응해야 하는 상황에 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '이메일을 확인하고 바로 작업을 하거나, 계획을 세운다',
          },
          {
            type: 'P',
            score: 5,
            content:
              '이메일을 확인하다 못 읽은 다른 메일을 읽는다. 그러다 뉴스 보고 있다',
          },
        ],
      },
      {
        which: 'JP',
        question: '두 가지의 극단적인 예중 나와 더 가까운 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '일 중독, 강박증, 완벽주의, 금욕주의',
          },
          {
            type: 'P',
            score: 5,
            content: '무기력, 나태함, 게으름, 유흥 중독',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorPompomChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorPompomChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/colorPompomChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/colorPompomChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorPompomChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/colorPompomChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/colorPompomChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/colorPompomChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorPompomChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorPompomChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/colorPompomChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/colorPompomChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorPompomChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/colorPompomChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/colorPompomChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/colorPompomChar/INFP.jpg',
      },
    ],
  },
  // paintDoodleCharEng
  {
    info: {
      mainTitle: 'Paint Doodle Personality Test',
      subTitle: 'My personality through paint scribbles',
      mainUrl: 'paintDoodleCharEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/paintDoodleCharEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/paintDoodleCharEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          "When you have to do a group project with someone you don't know, what do you do?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I think of it as a good opportunity to meet new people',
          },
          {
            type: 'I',
            score: 5,
            content: 'I prefer to do it with people I already know',
          },
        ],
      },
      {
        which: 'EI',
        question: 'When you think about your own affinity, what do you do?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'It seems like I am sociable and enjoy being around people',
          },
          {
            type: 'I',
            score: 5,
            content: 'I feel comfortable alone and consider myself introverted',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What do you do when you choose a place for hands-on learning?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `A place where many people can experience it`,
          },
          {
            type: 'I',
            score: 5,
            content: `A place where many people can experience it`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What about you when you said you were going to die right away?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Ah, I think I'm going to die`,
          },
          {
            type: 'N',
            score: 5,
            content: `Will I become a ghost if I die? Does the afterlife exist? Where do I go?`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'When you dream of a melodramatic TV show, what do you do?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: "It's a strange dream",
          },
          {
            type: 'N',
            score: 5,
            content: `What's the back story of your dream?`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'When you are staring blankly, what do you do?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Spacing out without thinking about anything`,
          },
          {
            type: 'N',
            score: 5,
            content: `Different imaginations depending on what you see in front of you`,
          },
        ],
      },
      {
        which: 'TF',
        question: `When a friend says they got in trouble for repeating the same mistake, what do you do?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Did you repeat it because you forgot the mistake you made?',
          },
          {
            type: 'F',
            score: 5,
            content:
              "Are you okay? You can make mistakes. You'll do better next time",
          },
        ],
      },
      {
        which: 'TF',
        question:
          'When a friend keeps their promise despite being unwell, how do you feel?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Why did you come out when you're sick? You can go to the hospital after breaking the appointment`,
          },
          {
            type: 'F',
            score: 5,
            content:
              "Doesn't it hurt a lot? It was okay to break the appointment, but let's go to the hospital together now",
          },
        ],
      },
      {
        which: 'TF',
        question: `What about you when your friend showed you he painted by himself?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              "Why did you do it alone? What's the name of this color? How much did you do it for?",
          },
          {
            type: 'F',
            score: 5,
            content:
              "Wow, that's amazing! You did such a great job! Well done!",
          },
        ],
      },
      {
        which: 'JP',
        question: 'When you order paint to paint a mural, what would you do?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Design a design in advance and calculate the color and amount accordingly and order it`,
          },
          {
            type: 'P',
            score: 5,
            content: `I don't know what to draw on the day, so I order what I need first`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'What about you when you paint?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'After finding out exactly how to paint, paint',
          },
          {
            type: 'P',
            score: 5,
            content:
              "I'm going to take a look at the method on YouTube and try it for now",
          },
        ],
      },
      {
        which: 'JP',
        question: 'What about you when you clean your room?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'We plan to decide where to clean first and then start cleaning',
          },
          {
            type: 'P',
            score: 5,
            content:
              "First of all, I'll clean up what I see in front of me and postpone it until later",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleCharEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleCharEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleCharEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleCharEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleCharEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleCharEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleCharEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleCharEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleCharEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleCharEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleCharEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleCharEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleCharEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleCharEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleCharEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleCharEng/INFP.jpg',
      },
    ],
  },
  // paintDoodleChar
  {
    info: {
      mainTitle: '페인트 낙서 성격 테스트',
      subTitle: '페인트 낙서로 보는 나의 성격은?',
      mainUrl: 'paintDoodleChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/paintDoodleChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/paintDoodleChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '모르는 사람과 조별 과제를 하게 되었을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '새로운 사람들을 만날 수 있는 좋은 기회라고 생각함',
          },
          {
            type: 'I',
            score: 5,
            content: '아는 사람들끼리 하고 싶다고 생각함',
          },
        ],
      },
      {
        which: 'EI',
        question: '스스로 자신의 친화력을 생각할 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '사교적이고 사람을 좋아하는 것 같음',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자가 편하고 내성적인 것 같음',
          },
        ],
      },
      {
        which: 'EI',
        question: '체험학습 장소를 고를 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `여러 명이서 체험을 할 수 있는 장소`,
          },
          {
            type: 'I',
            score: 5,
            content: `혼자만의 시간을 가질 수 있는 장소`,
          },
        ],
      },
      {
        which: 'SN',
        question: '당장 죽게 된다고 했을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `아 죽나 보다`,
          },
          {
            type: 'N',
            score: 5,
            content: `죽으면 귀신이 될까? 사후세계는 존재할까? 난 어디로 가지?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '막장 드라마 꿈을 꿨을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '이상한 꿈이네',
          },
          {
            type: 'N',
            score: 5,
            content: `지금 꿈의 뒤 내용은 뭘 까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '멍하니 있을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `아무 생각도 하지 않고 멍 때림`,
          },
          {
            type: 'N',
            score: 5,
            content: `눈앞에 보이는 물건에 따라서 각 다른 다양한 상상을 함`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 같은 실수를 반복하여 혼났다고 했을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '했던 실수를 잊어버려서 반복한 거야?',
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮아? 사람이 실수할 수도 있지 다음에는 더 잘 할 거야',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 아픈 상태에서 약속을 깨지 않고 나왔을 때 당신은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `아픈데 왜 나왔어 약속 깨고 병원 가지`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '많이 아프지 않아? 약속 깨도 괜찮았는데 지금이라도 같이 병원 가자',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 페인트칠을 혼자 했다고 보여줬을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜 혼자 했어? 이 색이름이 뭐야? 얼마에 한 거야?',
          },
          {
            type: 'F',
            score: 5,
            content: '대박 너무 이쁘게 잘했는데? 고생했어',
          },
        ],
      },
      {
        which: 'JP',
        question: '벽화를 그리기 위해 페인트를 주문할 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `미리 도안을 구상해 보고 그것에 맞는 색과 양을 계산해서 주문한다`,
          },
          {
            type: 'P',
            score: 5,
            content: `당일 뭐 그릴지 모르니 일단 필요해 보이는 것을 주문한다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '페인트를 칠할 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '칠하는 방법에 대해 정확하게 알아보고 난 뒤 칠한다',
          },
          {
            type: 'P',
            score: 5,
            content: '유튜브로 방법에 대해 슥 보고 일단 도전해 본다',
          },
        ],
      },
      {
        which: 'JP',
        question: '방 청소를 할 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '계획적으로 먼저 청소할 곳을 정한 다음 청소를 시작한다',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 눈앞에 보이는 것만 치우고 나중으로 미룬다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/paintDoodleChar/INFP.jpg',
      },
    ],
  },
  // pokerCharEng
  {
    info: {
      mainTitle: 'Poker Game Personality Test',
      subTitle: "What's your personality that you see as a poker game?",
      mainUrl: 'pokerCharEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/pokerCharEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/pokerCharEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'How do I look when I play poker?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Bright and active',
          },
          {
            type: 'I',
            score: 5,
            content: 'Somewhat static and calm',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What kind of person am I normally?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'An active person',
          },
          {
            type: 'I',
            score: 5,
            content: 'Passive person',
          },
        ],
      },
      {
        which: 'EI',
        question: 'If I play poker, where is my seat?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `The more people you have, the better, the center!`,
          },
          {
            type: 'I',
            score: 5,
            content: `I like quiet, the seat on the corner!`,
          },
        ],
      },
      {
        which: 'SN',
        question: "Let's predict the opponent's poker!",
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Relying on the five senses using visual hearing`,
          },
          {
            type: 'N',
            score: 5,
            content: `Relying on the sixth sense using feelings and intuition`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'I have a Four Cards!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I won this round no matter what',
          },
          {
            type: 'N',
            score: 5,
            content: `It's definitely my victory. You'll break it down, right? Aren't I so lucky? I'm going to buy a lottery ticket`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'How about a place to play poker?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `It's fun, but there are a lot of people and it's a little noisy`,
          },
          {
            type: 'N',
            score: 5,
            content: `It's bright and passionate and everyone is enjoying it!`,
          },
        ],
      },
      {
        which: 'TF',
        question: `A friend who's sad that poker luck hasn't followed her lately!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "Luck is also a skill, but they'll follow me someday",
          },
          {
            type: 'F',
            score: 5,
            content: "It's okay. I'm sure you'll get a good card. don't worry",
          },
        ],
      },
      {
        which: 'TF',
        question: "'I'll think about it…'What does it mean when you say",
        answers: [
          {
            type: 'T',
            score: 2,
            content: `It means to think about it again`,
          },
          {
            type: 'F',
            score: 5,
            content: 'Meaning of rejection',
          },
        ],
      },
      {
        which: 'TF',
        question: `What do I do when I draw a conclusion about the same content?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Always the same conclusion on the same content',
          },
          {
            type: 'F',
            score: 5,
            content: 'The same content changes from time to time',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What about me the day before I go to the poker game?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I memorized genealogy in advance and set the number of chips to hit`,
          },
          {
            type: 'P',
            score: 5,
            content: `Waiting for tomorrow thinking it would be fun`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'If I play poker, what time do I play until?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Until the pre-determined time',
          },
          {
            type: 'P',
            score: 5,
            content: "Until I'm sick of it",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'When a conversation starts talking about something off the topic?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "It's uncomfortable. Please focus on what you're talking about",
          },
          {
            type: 'P',
            score: 5,
            content: "I don't care if it's a funny story!",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/pokerCharEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/pokerCharEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/pokerCharEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/pokerCharEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/pokerCharEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/pokerCharEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/pokerCharEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/pokerCharEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/pokerCharEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/pokerCharEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/pokerCharEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/pokerCharEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/pokerCharEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/pokerCharEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/pokerCharEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/pokerCharEng/INFP.jpg',
      },
    ],
  },
  // pokerChar
  {
    info: {
      mainTitle: '포커 게임 성격 테스트',
      subTitle: '포커 게임으로 알아보는 내 성격은?',
      mainUrl: 'pokerChar',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/pokerChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/pokerChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '내가 포커를 칠 때 내 텐션은 어떨까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '밝고 활발함',
          },
          {
            type: 'I',
            score: 5,
            content: '다소 정적이고 차분함',
          },
        ],
      },
      {
        which: 'EI',
        question: '나는 평소에 어떤 사람일까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '능동적인 사람',
          },
          {
            type: 'I',
            score: 5,
            content: '수동적인 사람',
          },
        ],
      },
      {
        which: 'EI',
        question: '내가 포커를 치는 자리는 어디일까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `사람이 많을수록 좋으니까 중앙 쪽!`,
          },
          {
            type: 'I',
            score: 5,
            content: `조용한 게 좋으니까 외각 쪽!`,
          },
        ],
      },
      {
        which: 'SN',
        question: '상대방의 패를 예측할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `시각 청각을 이용한 오감에 의존`,
          },
          {
            type: 'N',
            score: 5,
            content: `느낌, 직관을 이용한 육감에 의존`,
          },
        ],
      },
      {
        which: 'SN',
        question: '나한테 포카드가 떴다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '이 판은 무조건 내가 이겼다',
          },
          {
            type: 'N',
            score: 5,
            content: `무조건 내 승리다 엄청 분해하겠지? 나 운 너무 좋은 거 아냐? 복권 사야지`,
          },
        ],
      },
      {
        which: 'SN',
        question: '포커 치러가는 곳은 어때?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `재밌는데 사람 많고 좀 시끄러워`,
          },
          {
            type: 'N',
            score: 5,
            content: `밝고 열정적이고 다들 즐기는 분위기야!`,
          },
        ],
      },
      {
        which: 'TF',
        question: `요즘 포커 운이 안 따라줘서 아쉽다는 친구!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '운도 실력이긴 한데 언젠간 따라주겠지~',
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮아 분명 조만간 한방에 올 거니까 걱정 마',
          },
        ],
      },
      {
        which: 'TF',
        question: '‘생각해 볼게…’라고 말한다면 어떤 뜻일까?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `다시 한번 곰곰이 정말 생각해 본다는 뜻`,
          },
          {
            type: 'F',
            score: 5,
            content: '싫다는 거절의 의미를 담은 뜻',
          },
        ],
      },
      {
        which: 'TF',
        question: `같은 내용에 대한 결론을 내릴 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '같은 내용에 대해서는 항상 같은 결론',
          },
          {
            type: 'F',
            score: 5,
            content: '같은 내용이어도 그때그때 달라짐',
          },
        ],
      },
      {
        which: 'JP',
        question: '포커 게임을 치러 가기 전날 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `미리 족보 외우고 얼마나 칠지 칩 개수도 정해 둠`,
          },
          {
            type: 'P',
            score: 5,
            content: `재밌겠다 생각하며 내일을 기다림`,
          },
        ],
      },
      {
        which: 'JP',
        question: '포커 게임을 난 몇 시까지 칠까?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '미리 정해둔 시간까지',
          },
          {
            type: 'P',
            score: 5,
            content: '내가 질릴 때까지',
          },
        ],
      },
      {
        which: 'JP',
        question: '대화 중 주제에서 벗어난 얘기가 시작되면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '불편해.. 하던 얘기에 집중 좀',
          },
          {
            type: 'P',
            score: 5,
            content: '재밌는 얘기면 상관없지!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/pokerChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/pokerChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/pokerChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/pokerChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/pokerChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/pokerChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/pokerChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/pokerChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/pokerChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/pokerChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/pokerChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/pokerChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/pokerChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/pokerChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/pokerChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/pokerChar/INFP.jpg',
      },
    ],
  },
  // bitcoinCharCN
  {
    info: {
      mainTitle: '比特币性格测试',
      subTitle: '用比特币看我的性格是？',
      mainUrl: 'bitcoinCharCN',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/bitcoinCharCN-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/bitcoinCharCN-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'CN',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '初次结识的朋友面前我是？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '通常我先说话。',
          },
          {
            type: 'I',
            score: 5,
            content: '通常对方先说话。',
          },
        ],
      },
      {
        which: 'EI',
        question: '周末我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '喜欢和其他人见面。',
          },
          {
            type: 'I',
            score: 5,
            content: '并不介意独自度过。',
          },
        ],
      },
      {
        which: 'EI',
        question: '和朋友们出行时我是？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '喜欢喧嚣热闹的气氛。',
          },
          {
            type: 'I',
            score: 5,
            content: '喜欢和少数朋友们一起聊天。',
          },
        ],
      },
      {
        which: 'SN',
        question: '我认为更重要的是？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '没有现在就意味着没有未来。',
          },
          {
            type: 'N',
            score: 5,
            content: '不考虑未来的话就不会有进步。',
          },
        ],
      },
      {
        which: 'SN',
        question: '工作时的我是？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '更偏向跟随别人的脚步。',
          },
          {
            type: 'N',
            score: 5,
            content: '更偏向采取自己的方式。',
          },
        ],
      },
      {
        which: 'SN',
        question: '周围的人认为我是？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '经常说我很有耐心和恒心。',
          },
          {
            type: 'N',
            score: 5,
            content: '经常说我很有自己独创的思维。',
          },
        ],
      },
      {
        which: 'TF',
        question: '遇到必须拒绝的情况时，我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '直接了当地拒绝。',
          },
          {
            type: 'F',
            score: 5,
            content: '深思熟虑后也往往会顺从。',
          },
        ],
      },
      {
        which: 'TF',
        question: '我生气的时候会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '说得很有道理，同时也很计较。',
          },
          {
            type: 'F',
            score: 5,
            content: '想说的话很多，但因为太生气，往往眼泪先流出来。',
          },
        ],
      },
      {
        which: 'TF',
        question: '朋友向我诉说烦恼，我却觉得是朋友的错时，我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '告诉他错的原因。',
          },
          {
            type: 'F',
            score: 5,
            content: '直接了当地说的话担心朋友会伤心，所以故意绕着弯子说。',
          },
        ],
      },
      {
        which: 'JP',
        question: '做准备工作时的我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '提前一天做好准备。',
          },
          {
            type: 'P',
            score: 5,
            content: '总想着‘明天再说明天再说’，经常忘记。',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '结束了一天的日程回家后准备学习，结果朋友们突然叫我出去玩，这时我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '不在我的计划范围内…很纠结。',
          },
          {
            type: 'P',
            score: 5,
            content: 'OK！果然人生不会按计划走！玩起！！！',
          },
        ],
      },
      {
        which: 'JP',
        question: '总体上我是？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '按我的计划进行！更偏向按计划的顺序进行！',
          },
          {
            type: 'P',
            score: 5,
            content: '想起什么就先做什么！更偏向灵活处理！',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharCN/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharCN/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharCN/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharCN/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharCN/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharCN/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharCN/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharCN/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharCN/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharCN/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharCN/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharCN/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharCN/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharCN/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharCN/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharCN/INFP.jpg',
      },
    ],
  },
  // bitcoinCharJP
  {
    info: {
      mainTitle: 'ビットコイン性格テスト',
      subTitle: 'ビットコインで見る私の性格は？',
      mainUrl: 'bitcoinCharJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/bitcoinCharJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/bitcoinCharJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '初対面の人とあったときの自分は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '自分から声をかける。',
          },
          {
            type: 'I',
            score: 5,
            content: 'ほとんどの場合、\nその人から声をかけてくれる。',
          },
        ],
      },
      {
        which: 'EI',
        question: ' 週末の過ごし方は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'よく人と会って過ごす。',
          },
          {
            type: 'I',
            score: 5,
            content: '一人で過ごすのも嫌いじゃない。',
          },
        ],
      },
      {
        which: 'EI',
        question: '友達と遊ぶときの自分は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'ワイワイと賑やかな雰囲気が好き。',
          },
          {
            type: 'I',
            score: 5,
            content: '少人数でのんびりおしゃべりすることが好き。',
          },
        ],
      },
      {
        which: 'SN',
        question: '自分がより重要だと思う価値観は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '今を生きないと将来もない。',
          },
          {
            type: 'N',
            score: 5,
            content: '将来のことを考えないと成長もない。',
          },
        ],
      },
      {
        which: 'SN',
        question: '仕事するときの自分は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '人が作ったレールに沿って行きたい。',
          },
          {
            type: 'N',
            score: 5,
            content: '自分だけのやり方を作りたい。',
          },
        ],
      },
      {
        which: 'SN',
        question: '周りの人によく言われる言葉は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '着実で忍耐強いとよく言われる。',
          },
          {
            type: 'N',
            score: 5,
            content: 'ユニークで創造的だとよく言われる。',
          },
        ],
      },
      {
        which: 'TF',
        question: '断らなきゃいけないときはどうする？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'できないものはできないと強く言う。',
          },
          {
            type: 'F',
            score: 5,
            content: '悩んだあげく、\nだいたいは聞いてあげる。',
          },
        ],
      },
      {
        which: 'TF',
        question: '怒ったときの自分は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '論理的に話しつつ問い詰める。',
          },
          {
            type: 'F',
            score: 5,
            content:
              '言いたいことはたくさんあるけど、\n悔しすぎて涙が出てしまう。',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '友達にお悩み相談をされたけど、\nよくよく聞いたらその友達のせいっぽい。\nそういうときの自分は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '友達の悪いところを指摘Ｓる。',
          },
          {
            type: 'F',
            score: 5,
            content: '友達を配慮してオブラートに包んで言ってあげる。',
          },
        ],
      },
      {
        which: 'JP',
        question: '明日の持ち物を用意するときの自分は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '前日から前もっと用意しておいく。',
          },
          {
            type: 'P',
            score: 5,
            content:
              '「明日用意しよう」と思って、\nいざ当日になると忘れてしまうことが多い。',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '終わったらお家に帰って勉強しよう、\nと思っていたら友達に遊びに誘われた。\nどうする？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '計画になかったし、\n非常に困るなぁ。',
          },
          {
            type: 'P',
            score: 5,
            content:
              'オッケー！計画通りにならないのが人生だし！\n遊んじゃおう！',
          },
        ],
      },
      {
        which: 'JP',
        question: 'おおむね自分はこんな感じです！',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '全ては計画したとおり！\n計画通りに物事を進めることが好き。',
          },
          {
            type: 'P',
            score: 5,
            content:
              'その場の思いつきによって物事を進める。\n柔軟に対応することが好き。',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharJP/INFP.jpg',
      },
    ],
  },
  // bitcoinCharEng
  {
    info: {
      mainTitle: 'Bitcoin personality test',
      subTitle: 'What is your personality that you recognize with Bitcoin?',
      mainUrl: 'bitcoinCharEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/bitcoinCharEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/bitcoinCharEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'What am I to my first-time friends?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I speak to others first.',
          },
          {
            type: 'I',
            score: 5,
            content: 'Most of the time, the other friend speaks to you first.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What do I do during the weekend?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I enjoy meeting people.',
          },
          {
            type: 'I',
            score: 5,
            content: "I don't hate being alone.",
          },
        ],
      },
      {
        which: 'EI',
        question: 'What do I do when playing with my friends?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I like the chatty and exciting atmosphere.',
          },
          {
            type: 'I',
            score: 5,
            content:
              'I like to have small conversations with a small number of friends.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do I think more important?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'No present, no future.',
          },
          {
            type: 'N',
            score: 5,
            content: 'No preparation for the future, no progress.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do I do when working?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'It is good to follow what others do.',
          },
          {
            type: 'N',
            score: 5,
            content: "It's good to go on your own way.",
          },
        ],
      },
      {
        which: 'SN',
        question: 'What are the others say to me?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'They often say that I am persistent and patient.',
          },
          {
            type: 'N',
            score: 5,
            content: 'They often say that I am creative and unique.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'What if I have to refuse?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: "I strongly say I can't do it.",
          },
          {
            type: 'F',
            score: 5,
            content:
              'I tend to accept most of what they ask after a little hesitation.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'What if I am angry?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I speak logically and argue.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'I have a lot to say,\nbut I am so angry that I start to tear up.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          "My friend talks about his worries.\nSeems to me, it's fault.\nThen, what is my response?",
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Tell the friend it is his fault.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Saying it directly may hurt his mind.\nI will tell him beating around the bush. ',
          },
        ],
      },
      {
        which: 'JP',
        question: 'When do I do when preparing the supplies?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Prepare in advance a day before.',
          },
          {
            type: 'P',
            score: 5,
            content:
              "'I will prepare them tomorrow'\nbut I frequent forget and leave behind.",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'After school, I want go home and study,\nbut my friends catch me up to play. Then?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: "It wasn't in the plan...\nVery embarrassing.",
          },
          {
            type: 'P',
            score: 5,
            content:
              "Okay! After all, life doesn't go as planned!\nLet's play!!!",
          },
        ],
      },
      {
        which: 'JP',
        question: 'In general, who am I?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'As I planned! I like to go through the order of things I made!',
          },
          {
            type: 'P',
            score: 5,
            content:
              'I take care of things as I think of!\nI love to be flexible!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinCharEng/INFP.jpg',
      },
    ],
  },
  // bitcoinChar
  {
    info: {
      mainTitle: '비트코인 성격 테스트',
      subTitle: '비트코인으로 보는 나의 성격은?',
      mainUrl: 'bitcoinChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/bitcoinChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/bitcoinChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '비트코인을 처음 시작할 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '주변 지인들에게 물어본다',
          },
          {
            type: 'I',
            score: 5,
            content: '주변 지인들에게 말하지 않고 조용히 시작한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '나쁜 일이 생겼을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '밖에서 사람들을 만나면서 기분을 푼다',
          },
          {
            type: 'I',
            score: 5,
            content: '집에서 조용히 생각을 정리해 본다',
          },
        ],
      },
      {
        which: 'EI',
        question: '잘 모르는 친구에 대한 질문을 받았을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `일단 대답 먼저 하고 나서 생각한다`,
          },
          {
            type: 'I',
            score: 5,
            content: `질문에 대해 충분히 생각하고 대답한다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '상승세인 비트코인을 보았을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `오 저기 건 사람들 좋겠네`,
          },
          {
            type: 'N',
            score: 5,
            content: `뭔데 이렇게 상승할 걸까? 내가 만약 저기다 걸었다면..!`,
          },
        ],
      },
      {
        which: 'SN',
        question: '요리를 할 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '정량화된 데이터를 믿고',
          },
          {
            type: 'N',
            score: 5,
            content: `내 느낌, 감을 믿고`,
          },
        ],
      },
      {
        which: 'SN',
        question: '비트코인에 2000만원을 넣었는데 두 배가 되는 꿈을 꾸었다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `에이 꿈이네 아쉽다..`,
          },
          {
            type: 'N',
            score: 5,
            content: `꿈은 이루어진다! 꿈처럼 이루어진 모습을 상상한다.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `비트코인 하락으로 큰돈을 잃은 친구!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '내가 그래서 팔라고 했지! 왜 안판 거야!!',
          },
          {
            type: 'F',
            score: 5,
            content:
              '괜찮아 괜찮아 또 오를 거야 분명! 너무 상심하지 말고 기다려봐..!',
          },
        ],
      },
      {
        which: 'TF',
        question: '사사건건 모든 일에 간섭하는 친구!',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `내가 알아서 할게`,
          },
          {
            type: 'F',
            score: 5,
            content: '아 정말? 생각해 볼게!',
          },
        ],
      },
      {
        which: 'TF',
        question: `조언해 준 대로 일을 처리했더니 칭찬을 받았다는 친구!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '내 말 듣기를 잘했지? 내 덕이야',
          },
          {
            type: 'F',
            score: 5,
            content: '너무 잘 됐다! 다른 고민 있으면 말해 다 조언해 줄게!',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 정해 둔 목표 금액으로 코인이 올라갔을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `계획 변동 없이 매도한다`,
          },
          {
            type: 'P',
            score: 5,
            content: `더 올라갈 수 있으니 지켜본다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '사고 싶은 종목이 생겼다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '이것저것 조사하고 알아보고 신중하게 매수',
          },
          {
            type: 'P',
            score: 5,
            content: '고민은 사치! 끌리는 종목 바로 매수',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구와 여행 계획을 세울 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '가고 싶은 장소와 교통편을 계획적으로 조사한다',
          },
          {
            type: 'P',
            score: 5,
            content: '대충 살펴보기만 하고 도착한 후 상황에 맡기자고 한다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/bitcoinChar/INFP.jpg',
      },
    ],
  },
  // whatRightEng
  {
    info: {
      mainTitle: 'What is the right thing? test',
      subTitle: 'What is right about your personality?',
      mainUrl: 'whatRightEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/whatRightEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/whatRightEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: 'When presenting in front of many people, you are?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'You proceed with the presentation confidently and loudly, without shyness.',
          },
          {
            type: 'I',
            score: 5,
            content: 'You proceed with the presentation timidly and quietly.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'When you have an appointment with your friends?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: "What should we do? I'm already looking forward to it!",
          },
          {
            type: 'I',
            score: 5,
            content:
              "I shouldn't have made an appointment…. I want to cancel it",
          },
        ],
      },
      {
        which: 'EI',
        question: "What about you when you're alone at home?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I want to go out and have fun.. I'm so bored`,
          },
          {
            type: 'I',
            score: 5,
            content: `I hope nobody interrupts me during this time.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          "When there's a clearly odd question on the exam paper, you are?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: `It seems like the teacher made a mistake`,
          },
          {
            type: 'N',
            score: 5,
            content: `Why did they make the question like this? I'm curious`,
          },
        ],
      },
      {
        which: 'SN',
        question: "What about you when you don't want to do assignments?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: "I don't want to, but.. I have to..",
          },
          {
            type: 'N',
            score: 5,
            content: `I hope the school explodes tomorrow.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'When the ending of a romance novel is an open ending, what do you do?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Why is it an open ending? It's a shame that the ending isn't clear.`,
          },
          {
            type: 'N',
            score: 5,
            content: `Wouldn't the main character be like this? If I were the main character, I would be like this`,
          },
        ],
      },
      {
        which: 'TF',
        question: `If you see a friend who cries while watching a drama, what about you?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Why are you crying? Where was the sad part?',
          },
          {
            type: 'F',
            score: 5,
            content: "Don't cry.. I'm getting sad too",
          },
        ],
      },
      {
        which: 'TF',
        question: 'What about you when your friend asks you for counseling?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `It gives realistically possible advice.`,
          },
          {
            type: 'F',
            score: 5,
            content: 'I empathize first and then offer advice.',
          },
        ],
      },
      {
        which: 'TF',
        question: `What about you when you had an argument with your friend?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Friend pushes by saying the wrong part.',
          },
          {
            type: 'F',
            score: 5,
            content: 'say anything emotionally',
          },
        ],
      },
      {
        which: 'JP',
        question: 'When I successfully achieve my goal, I feel?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I think it's natural because it was planned`,
          },
          {
            type: 'P',
            score: 5,
            content: `I'm so proud that I've achieved my goal`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'What about you when you have something you want to buy?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I search for the lowest price by searching through various sites.',
          },
          {
            type: 'P',
            score: 5,
            content: "I'll buy it first and think about it later",
          },
        ],
      },
      {
        which: 'JP',
        question: 'What do you do when you prepare for a sudden test?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Organize and prepare problems that can come out systematically.',
          },
          {
            type: 'P',
            score: 5,
            content: 'take a rough look and cram',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/whatRightEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/whatRightEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/whatRightEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/whatRightEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/whatRightEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/whatRightEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/whatRightEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/whatRightEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/whatRightEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/whatRightEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/whatRightEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/whatRightEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/whatRightEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/whatRightEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/whatRightEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/whatRightEng/INFP.jpg',
      },
    ],
  },
  // talkAlotLoveJP
  {
    info: {
      mainTitle: '言いたいこと満載の恋愛テスト',
      subTitle: '一私の恋愛にはどんな言いたいことが満載かな？',
      mainUrl: 'talkAlotLoveJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/talkAlotLoveJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/talkAlotLoveJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question:
          '忙しい日常の終わり、ついに休憩時間！突然、家の前に恋人が訪ねてきたら？',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'ちょうど仕事も終わったし、ちょうど良かった！すぐに出かけよう',
          },
          {
            type: 'I',
            score: 5,
            content: 'やっと休もうと思ったのに…ちょっと不便だな…',
          },
        ],
      },
      {
        which: 'EI',
        question: '恋人に必ず伝えたいことがある！自分にとって楽なのは？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '直接会って話す',
          },
          {
            type: 'I',
            score: 5,
            content: '文章にして伝える',
          },
        ],
      },
      {
        which: 'EI',
        question: '私は元々おしゃべり？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `元々おしゃべりで、恋人にも話が多い`,
          },
          {
            type: 'I',
            score: 5,
            content: `無口だけど、恋人には話が多い`,
          },
        ],
      },
      {
        which: 'SN',
        question: '私たち10年後はどんな姿だろう？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `きっと楽しく付き合っているだろうね。でもその時になってみないと分からないけどｗ`,
          },
          {
            type: 'N',
            score: 5,
            content: `10年後にはもう結婚してるかな？結婚式はどこで挙げたんだろう？`,
          },
        ],
      },
      {
        which: 'SN',
        question: '私が好むデートのスタイルは？',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '行ったことのあるレストラン、馴染みの場所でのリラックスデート',
          },
          {
            type: 'N',
            score: 5,
            content: `行ったことのない場所、食べたことのない料理、毎回新しいデート`,
          },
        ],
      },
      {
        which: 'SN',
        question: '気になる異性から「何してるの？」と先に連絡が来た！',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `どう返事しよう？`,
          },
          {
            type: 'N',
            score: 5,
            content: `どういう意味だろう…今会おうってこと？積極的だね？`,
          },
        ],
      },
      {
        which: 'TF',
        question: `恋人の誕生日！準備したプレゼントが他のプレゼントより小さい…！`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'いいプレゼントを用意したから、私のが一番だよ',
          },
          {
            type: 'F',
            score: 5,
            content:
              '小さすぎるかな…もっと大きいものを用意すればよかったかも…気になる',
          },
        ],
      },
      {
        which: 'TF',
        question: '約束の時間に大幅に遅れた恋人…私が聞きたい謝罪は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `なぜ遅れたのか、正確な理由`,
          },
          {
            type: 'F',
            score: 5,
            content: '真心のこもった謝罪',
          },
        ],
      },
      {
        which: 'TF',
        question: `恋人と些細なことでケンカして連絡を取っていない状態！`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'そのうち連絡してくるだろう',
          },
          {
            type: 'F',
            score: 5,
            content:
              'すごく怒ってるかな…でも私も怒ってるし…ご飯も食べてないんじゃないかな？',
          },
        ],
      },
      {
        which: 'JP',
        question: 'デートの約束時間に遅れることは？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `ほとんどない`,
          },
          {
            type: 'P',
            score: 5,
            content: `たまにある`,
          },
        ],
      },
      {
        which: 'JP',
        question: '片思いを終えて告白しようと思う！',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '今日すると決めたら必ずする',
          },
          {
            type: 'P',
            score: 5,
            content: '雰囲気を見て、今日できなかったら明日する',
          },
        ],
      },
      {
        which: 'JP',
        question: '恋人が突然家に遊びに来ると言ったら？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'いつも部屋がきれいだから関係ない',
          },
          {
            type: 'P',
            score: 5,
            content: '緊急！あちこちに脱ぎ捨てた服を慌てて片付ける',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveJP/INFP.jpg',
      },
    ],
  },
  // talkAlotLoveEng
  {
    info: {
      mainTitle: 'Full of words, the love test',
      subTitle: 'What words fill my love life?',
      mainUrl: 'talkAlotLoveEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/talkAlotLoveEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/talkAlotLoveEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question:
          "Finally, after a busy day, it's time to rest! What if your lover suddenly comes in front of your house?",
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              "I just finished my work, so that's great!! I'll be right out",
          },
          {
            type: 'I',
            score: 5,
            content:
              "I was going to take a break now... It's a little uncomfortable",
          },
        ],
      },
      {
        which: 'EI',
        question:
          'There is something I must tell my lover! What is more comfortable for me?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Meeting and talking in person',
          },
          {
            type: 'I',
            score: 5,
            content: "I'll write it down and deliver it to you",
          },
        ],
      },
      {
        which: 'EI',
        question: 'Do I usually talk a lot?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I usually talk a lot and I talk a lot to my lover`,
          },
          {
            type: 'I',
            score: 5,
            content: `I talk little, but I talk a lot to my lover`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What will we look like in 10 years?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `We're gonna be in a pretty relationship~ But you'll only know when the time comes`,
          },
          {
            type: 'N',
            score: 5,
            content: `In 10 years, we're already married, right? Where did the wedding take place? `,
          },
        ],
      },
      {
        which: 'SN',
        question: 'How about my favorite way of dating?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              "A comfortable date at a restaurant I've been to, a familiar place",
          },
          {
            type: 'N',
            score: 5,
            content: `Places you haven't been to, foods you haven't eaten, different dates every time`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          "An interested person sent me a message asking me what I'm doing now!",
        answers: [
          {
            type: 'S',
            score: 2,
            content: `How do I reply? `,
          },
          {
            type: 'N',
            score: 5,
            content: `What does it mean..? Does that mean we should meet now? `,
          },
        ],
      },
      {
        which: 'TF',
        question: `A lover's birthday! The prepared gift is smaller than the other gifts...!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I prepared a good present, so my present is the best',
          },
          {
            type: 'F',
            score: 5,
            content:
              "Is it too small.. Maybe I should've prepared something bigger.. It bothers me",
          },
        ],
      },
      {
        which: 'TF',
        question:
          'A lover who is very late for the appointment.. what do I want to hear??',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `The exact reason for being late `,
          },
          {
            type: 'F',
            score: 5,
            content: 'A sincere apology ',
          },
        ],
      },
      {
        which: 'TF',
        question: `The state of arguing with your lover over a minor matter and not being able to contact you!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Call me first ',
          },
          {
            type: 'F',
            score: 5,
            content:
              "Are you really angry.. But I'm angry, too.. What if you can't even eat?",
          },
        ],
      },
      {
        which: 'JP',
        question: 'Do I ever get late for a date?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I don't have any`,
          },
          {
            type: 'P',
            score: 5,
            content: `There is often`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          "I'm going to confess my feelings after ending my one-sided love!",
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'If I decide to do it today, I will definitely do it ',
          },
          {
            type: 'P',
            score: 5,
            content: "If I can't do it today, I'll do it tomorrow",
          },
        ],
      },
      {
        which: 'JP',
        question: 'What if your lover suddenly comes to your house to play?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: "It doesn't matter because the room is always clean",
          },
          {
            type: 'P',
            score: 5,
            content:
              "Emergency! He's scrambling to clean up the clothes he's taken off over there",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLoveEng/INFP.jpg',
      },
    ],
  },
  // talkAlotLove
  {
    info: {
      mainTitle: '할 말 가득 연애 테스트',
      subTitle: '내 연애엔 어떤 할 말들이 가득할까?',
      mainUrl: 'talkAlotLove',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/talkAlotLove-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/talkAlotLove-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question:
          '바쁜 일상 끝에 드디어 휴식 시간! 갑자기 집 앞으로 애인이 찾아온다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '마침 일도 끝났는데 잘 됐다!! 얼른 나가야지',
          },
          {
            type: 'I',
            score: 5,
            content: '이제 좀 쉬려고 했는데… 좀 불편하다.. ',
          },
        ],
      },
      {
        which: 'EI',
        question: '연인에게 꼭 전해야 할 말이 있다! 내게 더 편한 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '직접 만나서 대화하기',
          },
          {
            type: 'I',
            score: 5,
            content: '글로 적어서 전달하기',
          },
        ],
      },
      {
        which: 'EI',
        question: '나는 원래 말이?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `원래도 말이 많고 연인에게도 말이 많다 `,
          },
          {
            type: 'I',
            score: 5,
            content: `말 수가 적지만 연인에게는 말이 많다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '우린 10년 뒤에 어떤 모습일까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `예쁘게 잘 만나고 있겠지~ 근데 그때 되어봐야 알 듯ㅋㅋ`,
          },
          {
            type: 'N',
            score: 5,
            content: `10년 뒤면 이미 결혼했겠지? 결혼식은 어디서 했을까?? `,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 선호하는 데이트 방식은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '가봤던 식당, 익숙한 장소에서 하는 편안한 데이트',
          },
          {
            type: 'N',
            score: 5,
            content: `안 가봤던 장소, 안 먹어본 음식, 매번 색다른 데이트`,
          },
        ],
      },
      {
        which: 'SN',
        question: '썸 타는 이성에게 뭐하냐고 선연락이 왔다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `뭐라고 답장하지?`,
          },
          {
            type: 'N',
            score: 5,
            content: `무슨 뜻이지.. 지금 만나자는 건가? 적극적이네?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `연인의 생일! 준비한 선물이 다른 선물들보다 작다..!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '나름 좋은 선물 준비한 거니까 내 선물이 최고지 ',
          },
          {
            type: 'F',
            score: 5,
            content: '너무 작나.. 더 큰 걸 준비할 걸 그랬나 봐.. 신경 쓰여',
          },
        ],
      },
      {
        which: 'TF',
        question: '약속시간에 많이 늦은 연인.. 내가 듣고 싶은 사과는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `왜 늦은 건지 정확한 이유`,
          },
          {
            type: 'F',
            score: 5,
            content: '진심을 가득 담은 사과',
          },
        ],
      },
      {
        which: 'TF',
        question: `연인과 사소한 문제로 다퉈서 연락을 안 하는 상태!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '먼저 연락하겠지 뭐 ',
          },
          {
            type: 'F',
            score: 5,
            content:
              '많이 화났나.. 하지만 나도 화났는데.. 밥도 못 먹고 있는 거 아냐?',
          },
        ],
      },
      {
        which: 'JP',
        question: '난 데이트 약속 시간에 늦는 일이?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `대부분 없다`,
          },
          {
            type: 'P',
            score: 5,
            content: `종종 있다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '짝사랑을 끝내고 고백하려고 한다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '오늘 하기로 결정했으면 무조건 한다 ',
          },
          {
            type: 'P',
            score: 5,
            content: '분위기 보고 오늘 못하면 내일 한다 ',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인이 집에 갑자기 놀러 온다고 하면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '항상 방이 깨끗하기 때문에 상관없다 ',
          },
          {
            type: 'P',
            score: 5,
            content: '비상! 저기 벗어둔 옷가지를 허겁지겁 정리한다 ',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLove/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLove/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLove/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLove/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLove/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLove/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLove/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLove/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLove/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLove/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLove/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLove/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLove/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLove/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLove/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/talkAlotLove/INFP.jpg',
      },
    ],
  },
  // whatRight
  {
    info: {
      mainTitle: '옳은 것은 무엇일까요?',
      subTitle: '너의 성격 중 옳은 것은 무엇일지 맞춰봐',
      mainUrl: 'whatRight',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/whatRight-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/whatRight-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '많은 사람들 앞에서 발표를 할 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '부끄러움 없이 큰소리로 발표를 진행한다',
          },
          {
            type: 'I',
            score: 5,
            content: '작은 목소리로 소심하게 발표를 진행한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구와 약속이 잡혔을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '뭐 하고 놀지? 벌써 기대돼',
          },
          {
            type: 'I',
            score: 5,
            content: '괜히 잡았나? 벌써 나가기 싫어',
          },
        ],
      },
      {
        which: 'EI',
        question: '약속도 없고 집에서 혼자 있을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `지금이라도 약속 잡을까? 너무 심심한데`,
          },
          {
            type: 'I',
            score: 5,
            content: `이 시간을 아무도 방해하지 않았으면 좋겠어`,
          },
        ],
      },
      {
        which: 'SN',
        question: '누가 봐도 이상한 문제가 시험지에 있을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `선생님이 실수하셨나 보네`,
          },
          {
            type: 'N',
            score: 5,
            content: `뭐지? 문제를 이상하게 꼬아서 출제하신 걸까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '과제 하기 싫을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '지식인 또는 선배가 했던 걸 참고해 작성하자',
          },
          {
            type: 'N',
            score: 5,
            content: `내일 학교가 폭발해 버렸으면 좋겠어`,
          },
        ],
      },
      {
        which: 'SN',
        question: '로맨스 소설의 엔딩이 열린 결말일 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `왜 열린 결말이야 결말이 확실하지 않아서 아쉬워`,
          },
          {
            type: 'N',
            score: 5,
            content: `주인공은 이러지 않았을까? 내가 주인공이라면 이랬을 거야`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 드라마를 보다가 눈물을 흘렸을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜 우는 거야? 슬픈 부분이 어디였지?',
          },
          {
            type: 'F',
            score: 5,
            content: '울지 마 ㅠㅠ 나까지 슬퍼지잖아',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 고민 상담을 요청했을 때 당신은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `현실적으로 가능한 조언을 해준다`,
          },
          {
            type: 'F',
            score: 5,
            content: '선 공감 후 조언을 해준다',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구와 말다툼을 했을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '친구 잘못한 부분을 말하며 밀어붙인다',
          },
          {
            type: 'F',
            score: 5,
            content: '감정에 휩싸여 아무 말이나 내뱉는다',
          },
        ],
      },
      {
        which: 'JP',
        question: '목표했던 일이 성공적으로 끝났을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `계획적으로 준비했던 일이라 당연한 일이라 생각한다`,
          },
          {
            type: 'P',
            score: 5,
            content: `이게 이렇게 된다고? 놀라며 기뻐한다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '사고 싶은 물건이 생겼을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '이것저것 사이트를 뒤져가며 최저가를 찾는다',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 보이는 판매처에서 지르고 본다',
          },
        ],
      },
      {
        which: 'JP',
        question: '갑작스러운 쪽지시험을 준비할 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '체계적으로 나올 수 있는 문제들을 정리하며 준비한다',
          },
          {
            type: 'P',
            score: 5,
            content: '대충 살펴본 뒤 벼락치기로 준비한다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/whatRight/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/whatRight/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/whatRight/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/whatRight/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/whatRight/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/whatRight/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/whatRight/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/whatRight/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/whatRight/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/whatRight/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/whatRight/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/whatRight/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/whatRight/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/whatRight/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/whatRight/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/whatRight/INFP.jpg',
      },
    ],
  },
  // albumPlay
  {
    info: {
      mainTitle: '앨범 PLAY 테스트',
      subTitle: '나에게 맞는 앨범을 찾아보자',
      mainUrl: 'albumPlay',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/albumPlay-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/albumPlay-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '좋아하는 아이돌의 앨범을 사러 가는 길!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구한테 같이 가자고 졸라서 함께 다녀온다',
          },
          {
            type: 'I',
            score: 5,
            content: '조용히 혼자 다녀온다 왠지 부끄럽..',
          },
        ],
      },
      {
        which: 'EI',
        question: '앨범을 사려고 가게에 입장! 점원이 자꾸 말을 건다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '같이 대화하면서 천천히 사고 나온다',
          },
          {
            type: 'I',
            score: 5,
            content: '불편해서 후다닥 사고 빨리 나온다',
          },
        ],
      },
      {
        which: 'EI',
        question: '왜 그 가수를 좋아해?라는 질문을 받으면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `나도 모르게 대답부터 먼저 나온다`,
          },
          {
            type: 'I',
            score: 5,
            content: `충분히 생각 후 대답한다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내 최애 가수를 떠올린다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `예쁘고 멋지고 혼자 세상 다사는 연예인`,
          },
          {
            type: 'N',
            score: 5,
            content: `마치.. 하늘에서 떨어진 천사 같달까..?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '매일 밤 잠들기 전 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '오늘도 최애는 완벽했다 생각하며 잠듦',
          },
          {
            type: 'N',
            score: 5,
            content: `내 최애랑 만나서 알콩달콩 하는 여러 상상의 나래를 펼침`,
          },
        ],
      },
      {
        which: 'SN',
        question: '구입한 앨범 모양이 신기하게 생겼다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `구조가 특이하게 생겼네`,
          },
          {
            type: 'N',
            score: 5,
            content: `약간 그거처럼 생기지 않았어? 느낌이 비슷해`,
          },
        ],
      },
      {
        which: 'TF',
        question: `최애 앨범이 품절돼서 사지 못한 사람들을 지켜볼 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '와 진짜 인기 많다~~ 역시 내 최애 뿌듯해',
          },
          {
            type: 'F',
            score: 5,
            content: '어쪄냐.. 안타깝다 좀만 더 빨리 오셨으면 좋았을 텐데ㅜ',
          },
        ],
      },
      {
        which: 'TF',
        question: '최애의 굿즈를 힘든 노력 끝에 전부 다 모은 친구!',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `어떻게 다 모았어? 꿀팁 전수 좀`,
          },
          {
            type: 'F',
            score: 5,
            content: '다 모으느라 고생 좀 했겠다! 수고했어 완전 멋지다~',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 갑자기 탈덕 한다고 한다면 내 반응은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜? 이유가 뭔데 그래 네 맘이지 뭐',
          },
          {
            type: 'F',
            score: 5,
            content: '뭐라고…? 너무해.. 어떻게 네가 그럴 수 있어..?',
          },
        ],
      },
      {
        which: 'JP',
        question: '최애가 우리 동네에서 촬영을 한다고? 당장 달려가야지!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `최대한 빠른 경로를 계산해서 내가 예상한 시간대에 도착`,
          },
          {
            type: 'P',
            score: 5,
            content: `기적의 동선을 즉흥적으로 만들어 도착`,
          },
        ],
      },
      {
        which: 'JP',
        question: '앨범을 사와 방에 정리할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '전부터 미리 예쁘게 꾸며둔 자리에 올려 둠',
          },
          {
            type: 'P',
            score: 5,
            content: '들어가자마자 잘 보이는 자리에 바로 올려 둠',
          },
        ],
      },
      {
        which: 'JP',
        question: '오늘 할 일을 끝내고 시간이 남았다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '몇 시간 여유가 있으니까 앨범 정리하고 이거저거 하면 딱 맞겠다',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 침대에 눕고 보자~ 뭐 하지~ 나중에 생각해야지',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/albumPlay/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/albumPlay/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/albumPlay/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/albumPlay/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/albumPlay/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/albumPlay/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/albumPlay/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/albumPlay/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/albumPlay/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/albumPlay/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/albumPlay/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/albumPlay/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/albumPlay/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/albumPlay/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/albumPlay/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/albumPlay/INFP.jpg',
      },
    ],
  },
  // cherryBlossomLove
  {
    info: {
      mainTitle: '벚꽃 컬러 연애 테스트',
      subTitle: '나와 어울리는 벚꽃은 무슨 색일까?',
      mainUrl: 'cherryBlossomLove',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/cherryBlossomLove-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/cherryBlossomLove-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '약속 없는 주말에 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구들에게 당장 연락해서 약속을 잡는다',
          },
          {
            type: 'I',
            score: 5,
            content: '하루 종일 집에서 뒹굴뒹굴 하며 쉰다',
          },
        ],
      },
      {
        which: 'EI',
        question: '예정에 없던 약속이 갑자기 생기면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '오히려 좋다며 별일 없으면 당장 약속에 간다고 한다',
          },
          {
            type: 'I',
            score: 5,
            content: '말을 돌리며 못갈 것 같다고 말한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구와 함께 사람이 많은 벚꽃 명소로 간 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `신나게 즐기며 모르는 사람에게 사진도 찍어달라고 한다`,
          },
          {
            type: 'I',
            score: 5,
            content: `이쁘지만 벌써 집에 가고 싶다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '벚꽃보러 일본여행을 가는 날 비행기를 탄 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `얼른 도착해서 벚꽃 보고 놀고 싶다`,
          },
          {
            type: 'N',
            score: 5,
            content: `재밌겠다! 근데 비행기가 만약 추락하면 어떡해야하지?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '드디어 점심시간! 도시락을 먹으며 내가 하는 고민은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '이 도시락 맛있네',
          },
          {
            type: 'N',
            score: 5,
            content: `도시락 맛있다 저녁엔 또 뭐 먹을까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '벚꽃 축제에서 거리 공연을 봤을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `우와 진짜 잘한다`,
          },
          {
            type: 'N',
            score: 5,
            content: `우와 잘한다 얼마나 연습한거지? 나도 할 수 있나?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 벚꽃을 보다가 갑자기 우울해하는 것을 봤을 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜 이렇게 우울해해 벚꽃보고 기분 풀자!',
          },
          {
            type: 'F',
            score: 5,
            content: '많이 힘든 일이 있었나 보네.. 무슨 일 있어?ㅠㅠ',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 기념품 샵에서 쓸데 없는 선물을 사줬을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `와 고마워 근데 이건 왜 준거야? (왜 줬는지가 궁금함)`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '헐 너무 고마워 ㅠㅠ (별로지만 상처받을 수도 있으니 좋은척 함)',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 꼭 붙고 싶던 시험을 합격했다고 했을 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '오 무슨 시험인데? 축하해! 몇점 나왔어?',
          },
          {
            type: 'F',
            score: 5,
            content: '진짜 고생 많았겠다 ㅠㅠ 축하해 수고했어 ㅎㅎ',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구와 벚꽃 명소 근처 맛집을 가려고 했을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `미리 다 예약하고 메뉴까지 정해 놓는다`,
          },
          {
            type: 'P',
            score: 5,
            content: `밥 먹을 시간이 다가왔을 때 급하게 정한다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '데이트 룩을 고를 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '전날 머리부터 발끝까지 전부 세팅해 놓는다',
          },
          {
            type: 'P',
            score: 5,
            content: '당일 준비하면서 마음에 드는 옷으로 입고 나간다',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 벚꽃을 보러 가기로 했을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '시간별로 세세하게 계획을 준비한다',
          },
          {
            type: 'P',
            score: 5,
            content: '그날 벚꽃보면서 뭐할지 정해야겠다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomLove/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomLove/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomLove/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomLove/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomLove/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomLove/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomLove/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomLove/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomLove/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomLove/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomLove/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomLove/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomLove/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomLove/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomLove/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomLove/INFP.jpg',
      },
    ],
  },
  // monsterAttackEng
  {
    info: {
      mainTitle: 'What kind of monster is my personality?',
      mainUrl: 'monsterAttackEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/monsterAttackEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/monsterAttackEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: "When you're home alone with no one else around",
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I feel lonely',
          },
          {
            type: 'I',
            score: 5,
            content: 'I feel comfortable',
          },
        ],
      },
      {
        which: 'EI',
        question: 'When asked about alone time',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'It’s boring time',
          },
          {
            type: 'I',
            score: 5,
            content: 'It’s essential time',
          },
        ],
      },
      {
        which: 'EI',
        question:
          "When a meeting or study session ends and I'm going in the same direction as someone I'm not close to",
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Oh my gosh, I'm going there too, let's go together!`,
          },
          {
            type: 'I',
            score: 5,
            content: `I have something to buy, so go in first`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'When trying a recipe I’ve never cooked before',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I follow the recipe and adjust quantities and seasoning`,
          },
          {
            type: 'N',
            score: 5,
            content: `They cook with their own senses`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'The day before a trip, what do you usually do?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Check your luggage or check the weather',
          },
          {
            type: 'N',
            score: 5,
            content: `I imagine the food and atmosphere as if I've already arrived at the destination`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'If someone told you to memorize strange content for an exam, you would?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Well, since it's on the exam, I guess I'll memorize it`,
          },
          {
            type: 'N',
            score: 5,
            content: `I don't understand, how am I supposed to memorize this?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What would you do when your friend is carrying heavy things?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'It must be very heavy. Why are you holding it?',
          },
          {
            type: 'F',
            score: 5,
            content: 'It looks heavy. Do you want me to hold it for you?',
          },
        ],
      },
      {
        which: 'TF',
        question:
          "What about you when you see a scar on the back of your friend's hand?",
        answers: [
          {
            type: 'T',
            score: 2,
            content: `How did you get hurt? I applied ointment?`,
          },
          {
            type: 'F',
            score: 5,
            content: 'Are you okay? You look very sick',
          },
        ],
      },
      {
        which: 'TF',
        question: `When a friend says they lost something valuable, what do you do?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "Don't you remember where you last saw that thing?",
          },
          {
            type: 'F',
            score: 5,
            content: "Isn't it your precious item? I'll find it with you",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'On the morning when a friend is coming over to hang out at my place you would?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Waiting for my friend by moving around with my planned life routine`,
          },
          {
            type: 'P',
            score: 5,
            content: `Clean the messy room`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          "When there's a sudden free time during group activities, what do you do?",
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'It moves after recognizing the surrounding places',
          },
          {
            type: 'P',
            score: 5,
            content: 'Move freely to where the foot leads',
          },
        ],
      },
      {
        which: 'JP',
        question: 'When you make an appointment with a friend, what do you do?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'When and where shall we meet? Shall we make a reservation for the restaurant?',
          },
          {
            type: 'P',
            score: 5,
            content:
              "I don't know what will happen that day, so why don't we meet and decide?",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttackEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttackEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttackEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttackEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttackEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttackEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttackEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttackEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttackEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttackEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttackEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttackEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttackEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttackEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttackEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttackEng/INFP.jpg',
      },
    ],
  },
  // monsterAttack
  {
    info: {
      mainTitle: '괴수 침공 성격 테스트',
      subTitle: '내 성격은 어떤 괴수일까?',
      mainUrl: 'monsterAttack',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/monsterAttack-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/monsterAttack-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '아무도 없는 집에 혼자 있을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '외로움을 느낀다.',
          },
          {
            type: 'I',
            score: 5,
            content: '편안함을 느낀다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '혼자 있는 시간에 대해 물어봤을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '지루한 시간이다.',
          },
          {
            type: 'I',
            score: 5,
            content: '꼭 필요한 시간이다.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '모임 또는 스터디가 끝난 후 친하지 않은 사람과 방향이 같을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `어머 저도 그쪽으로 가는데 같이 가요!`,
          },
          {
            type: 'I',
            score: 5,
            content: `저는 살게 있어서 먼저 들어가세요~ `,
          },
        ],
      },
      {
        which: 'SN',
        question: '한 번도 해보지 않는 요리를 할 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `레시피 대로 양이나 간을 조절하며 요리한다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `자신만의 감으로 요리를 한다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '여행을 가기 하루 전 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '챙겨 둔 짐을 확인하거나 날씨를 확인한다.',
          },
          {
            type: 'N',
            score: 5,
            content: `여행지에 도착한 것 마냥 음식과 분위기에 대해 상상한다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '이상한 내용을 시험에 나온다고 외우라고 했을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `일단 시험에 나온다니까 외워야지`,
          },
          {
            type: 'N',
            score: 5,
            content: `이해가 안 되는데 어떻게 외우는 거지?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 무거운 물건을 들고 있을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '많이 무겁겠다. 그거 왜 들고 있는 거야?',
          },
          {
            type: 'F',
            score: 5,
            content: '무거워 보이는데 내가 들어줄까?',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구 손등에 상처가 있는 것을 봤을 때 당신은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `어쩌다 다쳤어? 연고는 발랐어?`,
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮아? 많이 아파 보여ㅜㅜ',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 아끼는 물건을 잃어버렸다고 했을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '그 물건 어디서 마지막으로 봤는지 기억 안 나?',
          },
          {
            type: 'F',
            score: 5,
            content: '그거 네가 아끼는 물건이잖아 어떡해ㅜㅜ',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구가 집에 놀러 오기로 한 당일 아침의 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `계획해둔 생활 루틴으로 움직이며 친구를 기다린다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `지저분한 방을 청소한다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '단체 활동 중 갑작스러운 자유시간이 생겼을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '주변 장소들을 알아본 후 움직인다.',
          },
          {
            type: 'P',
            score: 5,
            content: '자유롭게 발이 이끄는 곳으로 움직인다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구와 약속을 정할 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '언제 어디서 만날까? 식당은 예약해 둘까?',
          },
          {
            type: 'P',
            score: 5,
            content: '그날 어떻게 될지 모르는데 만나서 정하는 게 어때?',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttack/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttack/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttack/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttack/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttack/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttack/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttack/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttack/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttack/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttack/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttack/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttack/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttack/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttack/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttack/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/monsterAttack/INFP.jpg',
      },
    ],
  },
  // blockYouEng
  {
    info: {
      mainTitle: 'Your number blocking personality test',
      subTitle: 'Shall I check your personality by phone records?',
      mainUrl: 'blockYouEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/blockYouEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/blockYouEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          "Can you approach someone you don't know first and start a conversation with?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'It is possible',
          },
          {
            type: 'I',
            score: 5,
            content: "That's impossible",
          },
        ],
      },
      {
        which: 'EI',
        question: 'What if you watch a trending video on social media?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I want to film a video with my friends',
          },
          {
            type: 'I',
            score: 5,
            content: "I just think it's fun and move on",
          },
        ],
      },
      {
        which: 'EI',
        question: 'What about me when I see funny videos or memes?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I share it on SMS for everyone to see`,
          },
          {
            type: 'I',
            score: 5,
            content: `I'll save it quietly by myself`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What about me when I heard "Imagine anything!"?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I don't know what to imagine…`,
          },
          {
            type: 'N',
            score: 5,
            content: `What should I imagine? What's the purpose of the question?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '"Wear sunglasses, wear turtle shells, and ride clouds." What if you get asked?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I can only imagine wearing sunglasses',
          },
          {
            type: 'N',
            score: 5,
            content: `It's already on the clouds`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What is imagination to me?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Something that must have a reason`,
          },
          {
            type: 'N',
            score: 5,
            content: `It's always coexisting in my head`,
          },
        ],
      },
      {
        which: 'TF',
        question: `Do you remember a time when you were upset recently?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Well, did that happen recently..',
          },
          {
            type: 'F',
            score: 5,
            content: 'I remember when I got emotional and angry',
          },
        ],
      },
      {
        which: 'TF',
        question: 'What is the most important part for me?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Results~`,
          },
          {
            type: 'F',
            score: 5,
            content: 'Process',
          },
        ],
      },
      {
        which: 'TF',
        question: `What about me when the other person changes his or her way of speaking?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "I don't know if you changed the way you talk",
          },
          {
            type: 'F',
            score: 5,
            content: 'I notice right away and worry if I did something wrong',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What about me when I deal with what I have to do?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I try to finish as soon as possible`,
          },
          {
            type: 'P',
            score: 5,
            content: `Looking free and comfortable`,
          },
        ],
      },
      {
        which: 'JP',
        question: "Why don't you see someone improvising every moment?",
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Frustrating and uncomfortable to look at',
          },
          {
            type: 'P',
            score: 5,
            content: "It's me, it's me!",
          },
        ],
      },
      {
        which: 'JP',
        question: 'Do you adapt well to sudden changes in your daily life?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'A sudden change is always inconvenient',
          },
          {
            type: 'P',
            score: 5,
            content:
              "I get used to it quickly and have more fun because it's a new change",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/blockYouEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/blockYouEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/blockYouEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/blockYouEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/blockYouEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/blockYouEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/blockYouEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/blockYouEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/blockYouEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/blockYouEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/blockYouEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/blockYouEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/blockYouEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/blockYouEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/blockYouEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/blockYouEng/INFP.jpg',
      },
    ],
  },
  // blockYouJP
  {
    info: {
      mainTitle: 'うん～君はブロック性格テスト',
      subTitle: '通話記録からあなたの性格を見てみよう',
      mainUrl: 'blockYouJP',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/blockYouJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/blockYouJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '知らない人に先に話しかけることができる？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'できる、完全にできる！全然OK！',
          },
          {
            type: 'I',
            score: 5,
            content: 'できない、いや、話しかけない',
          },
        ],
      },
      {
        which: 'EI',
        question: 'インスタで流行しているリールチャレンジを撮ってみたい？',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '見るたびに体がうずうずする。すごくやってみたいし、実際にやってみた',
          },
          {
            type: 'I',
            score: 5,
            content: '無限リールのアルゴリズムに吸い込まれるだけ',
          },
        ],
      },
      {
        which: 'EI',
        question: 'すごく面白い動画やミームを見たとき、私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `みんなが見るインスタのストーリーで共有する`,
          },
          {
            type: 'I',
            score: 5,
            content: `友達にDMでだけ共有する`,
          },
        ],
      },
      {
        which: 'SN',
        question: '「何でも想像してみて！」と言われたとき、私は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `何を想像しよう…`,
          },
          {
            type: 'N',
            score: 5,
            content: `どんな想像をしよう？質問の意図は何だろう？無限に考え続ける`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '「サングラスをかけてカメの甲羅を背負って雲に乗ってみて」という質問に対して、私は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'サングラスをかけるところまでしか想像できない',
          },
          {
            type: 'N',
            score: 5,
            content: `すでに雲に乗っている`,
          },
        ],
      },
      {
        which: 'SN',
        question: '私にとって想像とは？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `理由が必要なもの`,
          },
          {
            type: 'N',
            score: 5,
            content: `いつも頭の中に共存しているもの`,
          },
        ],
      },
      {
        which: 'TF',
        question: `最近の感情がこみ上げたり悔しかったりした出来事を思い出せる？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'うーん…最近そんなことあったっけ…',
          },
          {
            type: 'F',
            score: 5,
            content:
              'あの時感情がこみ上げて、あの時悔しかった、とすぐに思い出せる',
          },
        ],
      },
      {
        which: 'TF',
        question: '私にとってすべての事柄で最も重要なことはなに？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `結果`,
          },
          {
            type: 'F',
            score: 5,
            content: '過程',
          },
        ],
      },
      {
        which: 'TF',
        question: `カカオトークで相手の微妙な言葉遣いの変化に気づいたとき、私は？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'まず気づかない',
          },
          {
            type: 'F',
            score: 5,
            content: 'すぐに気づいて、自分が何か間違えたかな？と心配する',
          },
        ],
      },
      {
        which: 'JP',
        question: 'やるべきことを処理するとき、私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `できるだけ早く終わらせようとする`,
          },
          {
            type: 'P',
            score: 5,
            content: `とりあえず後回しにする`,
          },
        ],
      },
      {
        which: 'JP',
        question: '毎瞬間を即興的に生きるヨーローを見たとき、私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'なんであんな風に生きるのか見ているだけで不快',
          },
          {
            type: 'P',
            score: 5,
            content: 'それがまさに私！',
          },
        ],
      },
      {
        which: 'JP',
        question: '突然の日常の変化にもよく適応できるか？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '突然のことはいつも不快だ',
          },
          {
            type: 'P',
            score: 5,
            content: '人生はもともと予測不可能なもの、来るものを受け入れる',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/blockYouJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/blockYouJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/blockYouJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/blockYouJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/blockYouJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/blockYouJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/blockYouJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/blockYouJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/blockYouJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/blockYouJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/blockYouJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/blockYouJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/blockYouJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/blockYouJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/blockYouJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/blockYouJP/INFP.jpg',
      },
    ],
  },
  // blockYou
  {
    info: {
      mainTitle: '응~ 너 차단 성격 테스트',
      subTitle: '통화기록으로 너의 성격을 들여다 봐',
      mainUrl: 'blockYou',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/blockYou-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/blockYou-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '모르는 사람에게 먼저 말을 건넬 수 있는가?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '가능 완전 가능~ 쌉가능~',
          },
          {
            type: 'I',
            score: 5,
            content: '못 건다, 아니 안 건다',
          },
        ],
      },
      {
        which: 'EI',
        question: '인스타에서 유행하는 릴스 챌린지 찍어보고 싶은가?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '볼 때마다 몸이 들썩 너무 찍어보고 싶고 실제로 찍어봄',
          },
          {
            type: 'I',
            score: 5,
            content: '무한 릴스 알고리즘에 빨려 들어가기만 할 뿐,,',
          },
        ],
      },
      {
        which: 'EI',
        question: '너무 웃기는 영상이나 짤을 봤을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `모두가 보는 인스타 스토리에 공유한다`,
          },
          {
            type: 'I',
            score: 5,
            content: `친구들한테 DM으로만 공유한다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '“아무거나 상상해 봐!”라는 말을 들었을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `무슨 상상을 하지..`,
          },
          {
            type: 'N',
            score: 5,
            content: `어떤 상상을 하지? 질문의 의도가 뭐지? 무한 꼬꼬무 시작`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '"선글라스를 딱 끼고 거북이 등껍질을 메고 구름을 타봐.”라는 질문의 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '선글라스 끼는 것까지만 상상감',
          },
          {
            type: 'N',
            score: 5,
            content: `이미 구름까지 탔음`,
          },
        ],
      },
      {
        which: 'SN',
        question: '나에게 상상이란?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `이유가 있어야만 하는 것`,
          },
          {
            type: 'N',
            score: 5,
            content: `항상 머릿속에 공존하는 것`,
          },
        ],
      },
      {
        which: 'TF',
        question: `근래의 울컥하고 분했던 감정이 언제였는지 생각이 나는가?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '음…. 근래의 그런 적이 있었나..',
          },
          {
            type: 'F',
            score: 5,
            content: '이때 울컥하고 그때 분했어 하고 떠오름',
          },
        ],
      },
      {
        which: 'TF',
        question: '나에게 모든 일에서 가장 중요한 부분이 무엇인가?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `결과`,
          },
          {
            type: 'F',
            score: 5,
            content: '과정',
          },
        ],
      },
      {
        which: 'TF',
        question: `카톡에서 상대방이 미세하게 말투가 변했을 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '눈치부터 잘 못 챔',
          },
          {
            type: 'F',
            score: 5,
            content: '바로 눈치채고 내가 뭐 잘못했나? 하고 걱정한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '해야 할 일을 처리할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `최대한 빨리 끝내려고 한다`,
          },
          {
            type: 'P',
            score: 5,
            content: `일단 미루고 본다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '매 순간을 즉흥적으로 살아가는 욜로를 볼 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '왜 저러고 살지 보는 것만으로도 불편',
          },
          {
            type: 'P',
            score: 5,
            content: '그게 바로 나~!',
          },
        ],
      },
      {
        which: 'JP',
        question: '갑작스러운 일상의 변화에도 잘 적응하는가?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '갑작스러운 건 언제나 불편하다',
          },
          {
            type: 'P',
            score: 5,
            content: '인생은 원래 알 수 없는 것 닥치는 대로 받아들인다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/blockYou/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/blockYou/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/blockYou/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/blockYou/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/blockYou/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/blockYou/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/blockYou/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/blockYou/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/blockYou/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/blockYou/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/blockYou/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/blockYou/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/blockYou/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/blockYou/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/blockYou/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/blockYou/INFP.jpg',
      },
    ],
  },
  // keyboardChar
  {
    info: {
      mainTitle: '키보드 성격 테스트',
      subTitle: '키보드로 알아보는 나의 성격은?',
      mainUrl: 'keyboardChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/keyboardChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/keyboardChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '미용실 원장님이 스몰토크를 건넨다. 이때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '‘스몰토크 하면 또 나지~’라고 생각하며 상황을 즐긴다.',
          },
          {
            type: 'I',
            score: 5,
            content:
              '‘그냥 머리만 해줬으면 좋겠다’라고 생각하며 상황을 맞춰준다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '기분전환이 필요하다고 느껴질 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구들과 함께 좋은 시간을 보내려고 한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자 좋아하는 것을 하면서 시간을 보내려고 한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '집 밖을 나갈 때 나의 심경은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `너무 신난다!`,
          },
          {
            type: 'I',
            score: 5,
            content: `다소 귀찮다!`,
          },
        ],
      },
      {
        which: 'SN',
        question: '생명이 존재할 수 있다는 행성을 발견했다는 뉴스를 본 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `‘진짜로 생명이 존재를 할까?’라고 생각한다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `가족들과 우주로 떠나는 나를 생각한다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '기억에 오래 남는 꿈은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '실제로 일어날 것 같은 생생한 꿈',
          },
          {
            type: 'N',
            score: 5,
            content: `현실에서 있을 수 없는 판타지 꿈`,
          },
        ],
      },
      {
        which: 'SN',
        question: '애인을 보고 더 자주 많이 드는 생각을 고른다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `어제 봤는데 오늘 또 보고싶은 생각`,
          },
          {
            type: 'N',
            score: 5,
            content: `함께하는 미래가 그려지는 생각`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 사회생활이 힘들다고 할 때 이때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '그럴만한 구체적인 현실 방안을 이야기해 준다.',
          },
          {
            type: 'F',
            score: 5,
            content: '잘하고 있다고 걱정하지 말라고 위로를 해준다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '조카가 놀이터에서 살짝 넘어진 것을 발견한 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `괜찮아! 괜찮아! 이제 일어나!`,
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮아? 어떡해 많이 아팠어?',
          },
        ],
      },
      {
        which: 'TF',
        question: `이상형에 조금 더 가까운 사람은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '누가 봐도 존경스럽고 배울 점이 많은 사람',
          },
          {
            type: 'F',
            score: 5,
            content: '말을 예쁘게 하고 한없이 다정한 사람',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '오늘부터 운동하기로 했는데 일주일째 지키지 못했다. 이때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `하지도 않은 나 자신에 대해 실망감이 커진다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `하겠다고 생각한 것에 기특하다고 생각한다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '잘 시간이 되었지만 유튜브가 너무 재밌다. 이때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '자정까지 보기로 했으니 바로 자야겠다.',
          },
          {
            type: 'P',
            score: 5,
            content: '이것까지만 보고 이제 진짜 유튜브 꺼야지.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '꼭 필요한 건 아니지만 갖고 싶다고 생각한 물건이 세일을 한다. 이때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '생활비 안에서 사용 가능한 금액 대인지 따져본 후에 구매한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '세일 기간에 사야 하므로 일단 사고 생활비를 줄여본다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/keyboardChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/keyboardChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/keyboardChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/keyboardChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/keyboardChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/keyboardChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/keyboardChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/keyboardChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/keyboardChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/keyboardChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/keyboardChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/keyboardChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/keyboardChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/keyboardChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/keyboardChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/keyboardChar/INFP.jpg',
      },
    ],
  },
  // knittingEng
  {
    info: {
      mainTitle: 'Knitting personality test',
      subTitle: 'My personality as seen through knitting is?',
      mainUrl: 'knittingEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/knittingEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/knittingEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          "When a friend I don't know well suddenly asks a question You would?",
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'I answer the question and then ask a follow-up question afterward.',
          },
          {
            type: 'I',
            score: 5,
            content:
              'I answer the question with surprise. (Why are they asking this all of a sudden?)',
          },
        ],
      },
      {
        which: 'EI',
        question: 'When asked about alone time, You would?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: "It's boring time.",
          },
          {
            type: 'I',
            score: 5,
            content: "It's essential time.",
          },
        ],
      },
      {
        which: 'EI',
        question: 'When sharing everyday stories, You would?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I easily share daily life with even acquaintances.`,
          },
          {
            type: 'I',
            score: 5,
            content: `I don't easily share everyday stories.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          "When talking about a restaurant I couldn't visit due to closing hours, You would?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: `We went too late last time and couldn't get in, so let's go earlier this time.`,
          },
          {
            type: 'N',
            score: 5,
            content: `I couldn't go because there were no people, right? Let's go this time.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'When there are items scattered all over the street, You would?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Looks like someone dropped them',
          },
          {
            type: 'N',
            score: 5,
            content: `Did someone run with their bag open? Why are these scattered like this?`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'When evaluating the taste of food, You would?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `It's delicious or it's not good`,
          },
          {
            type: 'N',
            score: 5,
            content: `The texture is just like ~, it reminds me of something I've had before!`,
          },
        ],
      },
      {
        which: 'TF',
        question: `When you're ruled out for not having the same interests, You would?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              "It's not interesting to me, so I don't really think about it.",
          },
          {
            type: 'F',
            score: 5,
            content: "you can't ask me? I felt like that",
          },
        ],
      },
      {
        which: 'TF',
        question:
          "When a friend cooks for me and the food doesn't taste good, You would?",
        answers: [
          {
            type: 'T',
            score: 2,
            content: `It's delicious, but I think I need to taste it a little bit?`,
          },
          {
            type: 'F',
            score: 5,
            content:
              "Wow, it's so delicious. (I don't mention the food after the reaction.)",
          },
        ],
      },
      {
        which: 'TF',
        question: `When a friend says they saved up and bought an expensive bag, You would?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'You bought a good bag. How much did you pay?',
          },
          {
            type: 'F',
            score: 5,
            content: "Wow, it must have been hard to save money. That's cool!!",
          },
        ],
      },
      {
        which: 'JP',
        question: 'When a friend suddenly suggests going on a trip, You would?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `You me to go without scheduling? I'm reluctant`,
          },
          {
            type: 'P',
            score: 5,
            content: `Oh, travel! That's so nice! It's nice!!`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'When I see someone acting without a plan, You would?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: "I don't think I can imagine a life of unplanned action",
          },
          {
            type: 'P',
            score: 5,
            content:
              "I think it's possible because I don't have a plan just like that.",
          },
        ],
      },
      {
        which: 'JP',
        question: 'When starting a new hobby, You would?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I research extensively and make plans for the new hobby.',
          },
          {
            type: 'P',
            score: 5,
            content:
              "First, try bumping it, and if it doesn't fit, look for something else.",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/knittingEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/knittingEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/knittingEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/knittingEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/knittingEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/knittingEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/knittingEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/knittingEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/knittingEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/knittingEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/knittingEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/knittingEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/knittingEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/knittingEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/knittingEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/knittingEng/INFP.jpg',
      },
    ],
  },
  // knitting
  {
    info: {
      mainTitle: '뜨개질 성격 테스트',
      subTitle: '뜨개질로 보는 나의 성격은?',
      mainUrl: 'knitting',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/knitting-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/knitting-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '잘 모르는 친구가 갑자기 질문을 했을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '질문에 대한 답을 하고 난 뒤 역 질문을 한다.',
          },
          {
            type: 'I',
            score: 5,
            content:
              '당황하며 질문에 답을 한다. (이걸 갑자기 질문 왜 하는 거지?)',
          },
        ],
      },
      {
        which: 'EI',
        question: '혼자 있는 시간에 대해 물어봤을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '지루한 시간이다.',
          },
          {
            type: 'I',
            score: 5,
            content: '꼭 필요한 시간이다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '일상 이야기를 공유할 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `친하지 않은 사람에게도 쉽게 일상을 공유한다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `일상 이야기를 잘 공유하지 않는다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '마감으로 인해 들어가지 못한 식당에 대해 이야기할 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `지난번에는 너무 늦게 가서 못 들어갔으니 이번엔 일찍 가보자`,
          },
          {
            type: 'N',
            score: 5,
            content: `그때 사람 없어서 못 갔지? 이번에는 꼭 가자`,
          },
        ],
      },
      {
        which: 'SN',
        question: '길거리에 물건들이 왕창 떨어져 있을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '누가 떨어뜨렸나 보네',
          },
          {
            type: 'N',
            score: 5,
            content: `가방을 열어 둔 상태로 뛰어갔나? 왜 이렇게 떨어져 있을까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '음식 맛 평가를 할 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `맛있네 or 맛없어`,
          },
          {
            type: 'N',
            score: 5,
            content: `식감이 꼭 ~ 같다 전에 먹었던 ~랑 비슷한 느낌이야!`,
          },
        ],
      },
      {
        which: 'TF',
        question: `관심사가 맞지 않는다는 이유로 약속을 배제 당했을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '흥미가 없는 일이기에 별생각 없음',
          },
          {
            type: 'F',
            score: 5,
            content: '물어봐 줄 수는 있는 거 아닌가 하는 느낌을 받음',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 요리를 해줬는데 맛이 없을 때 당신은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `맛있는데 간을 조금 봐야 할 것 같은데?`,
          },
          {
            type: 'F',
            score: 5,
            content: '와 너무 맛있는데? (그 뒤 음식에 대한 언급을 안 함)',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 돈 모아서 비싼 가방을 샀다고 했을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '가방 잘 샀네~ 얼마 줬어?',
          },
          {
            type: 'F',
            score: 5,
            content: '와.. 돈 모으는 거 힘들었을 텐데 멋지다!!',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구가 갑작스럽게 여행을 가자고 했을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `일정을 조율하지도 않고 가자고? 꺼려지네`,
          },
          {
            type: 'P',
            score: 5,
            content: `오 여행~? 너무 좋은데? 좋아!!`,
          },
        ],
      },
      {
        which: 'JP',
        question: '계획 없이 행동하는 사람을 봤을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '나는 계획 없는 행동의 삶을 상상할 수 없다고 생각함',
          },
          {
            type: 'P',
            score: 5,
            content: '똑같이 계획 없어서 그럴 수 있다고 생각함',
          },
        ],
      },
      {
        which: 'JP',
        question: '새로운 취미를 시작할 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '자세히 알아보고 새로운 취미에 대한 계획을 세운다.',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 부딪혀 보고 안 맞는다 싶으면 다른 것을 찾아본다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/knitting/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/knitting/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/knitting/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/knitting/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/knitting/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/knitting/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/knitting/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/knitting/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/knitting/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/knitting/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/knitting/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/knitting/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/knitting/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/knitting/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/knitting/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/knitting/INFP.jpg',
      },
    ],
  },
  // colorWoodstick
  {
    info: {
      mainTitle: '컬러 우드스틱 성격 테스트',
      subTitle: '컬러 우드스틱으로 보는 나의 성격은?',
      mainUrl: 'colorWoodstick',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/colorWoodstick-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/colorWoodstick-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '나에게 쉬는 날의 의미는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '밖에 나가서 놀아야 하는 날',
          },
          {
            type: 'I',
            score: 5,
            content: '말 그대로 집안에서 쉬는 날',
          },
        ],
      },
      {
        which: 'EI',
        question: '많은 사람 앞에서 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '말이 많아진다(존재감을 뽐내고픔)',
          },
          {
            type: 'I',
            score: 5,
            content: '말이 없어진다(존재감을 죽이고픔)',
          },
        ],
      },
      {
        which: 'EI',
        question: '나는 대화를 할 때 어떤 편인가?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `생각이 정리되기 전에 말한다`,
          },
          {
            type: 'I',
            score: 5,
            content: `생각이 정리된 후에 말한다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 더 선호하는 업무의 방식은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `기존에 하고 있던 익숙한 업무 방식`,
          },
          {
            type: 'N',
            score: 5,
            content: `안 해보던 새로운 업무 방식`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '자주 가는 식당에 최애 메뉴에 ‘오늘은 안 팝니다’라고 적혀 있을 때 나의 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '오늘 품절인가 보네 ㅠ',
          },
          {
            type: 'N',
            score: 5,
            content: `왜 품절이지,, 비싸졌나 앞으로 계속 품절이면 어떡하지`,
          },
        ],
      },
      {
        which: 'SN',
        question: '오랜만에 미세먼지 없는 맑은 하늘을 보며 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `와.. 날씨 좋다..`,
          },
          {
            type: 'N',
            score: 5,
            content: `맑은 하늘을 보며 여러 생각이 밀려온다`,
          },
        ],
      },
      {
        which: 'TF',
        question: `나를 더 잘 묘사한 것은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '편견이 없다',
          },
          {
            type: 'F',
            score: 5,
            content: '따듯한 정에 끌린다',
          },
        ],
      },
      {
        which: 'TF',
        question: '의사결정할 때 나에게 더 중요한 것은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `데이터를 기반한 객관적인 사실`,
          },
          {
            type: 'F',
            score: 5,
            content: '개인적인 가치와 감정',
          },
        ],
      },
      {
        which: 'TF',
        question: `나의 표현 방식은 무엇인가?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '질문형+호기심의 표현',
          },
          {
            type: 'F',
            score: 5,
            content: '리액션+공감의 표현',
          },
        ],
      },
      {
        which: 'JP',
        question: '무언가를 할 때 나는 주로 어떻게 하는 스타일인가?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `정해진 재로 하는 스타일`,
          },
          {
            type: 'P',
            score: 5,
            content: `상황을 보면서 하는 스타일`,
          },
        ],
      },
      {
        which: 'JP',
        question: '나의 사고와 더 잘 맞는 것은 무엇인가?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '경직된 사고',
          },
          {
            type: 'P',
            score: 5,
            content: '유연한 사고',
          },
        ],
      },
      {
        which: 'JP',
        question: '하루를 마친 뒤 집에 갈 때 나의 생각과 더 가까운 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '내일의 계획도 슬며시 생각해 본다.',
          },
          {
            type: 'P',
            score: 5,
            content: '하루가 어떻게 돌아간지 모르겠다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorWoodstick/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorWoodstick/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/colorWoodstick/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/colorWoodstick/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorWoodstick/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/colorWoodstick/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/colorWoodstick/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/colorWoodstick/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorWoodstick/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorWoodstick/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/colorWoodstick/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/colorWoodstick/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorWoodstick/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/colorWoodstick/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/colorWoodstick/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/colorWoodstick/INFP.jpg',
      },
    ],
  },
  // charFile
  {
    info: {
      mainTitle: '성격 파일속성 테스트',
      subTitle: '내 성격을 열어보면 어떨까?',
      mainUrl: 'charFile',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/charFile-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/charFile-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '조별 과제 중 쉬는 타임이다. 이때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '같이 음료 사러 갈 메이트가 있는지 물어본다.',
          },
          {
            type: 'I',
            score: 5,
            content:
              '조용히 자리를 뜬 후 학교 안 편의점에 가서 음료수를 사 온다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '휴무 때, 문득 외롭다고 느낄 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '연락할 만한 사람이 없는지 한번 쓱 본 후 없어도 일단 나가본다.',
          },
          {
            type: 'I',
            score: 5,
            content: '‘이대로 사라져도 아무도 신경 안 쓰겠지’라는 생각이 든다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '평소 대화할 때 나의 데시벨은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `높은 편`,
          },
          {
            type: 'I',
            score: 5,
            content: `낮은 편`,
          },
        ],
      },
      {
        which: 'SN',
        question: '메일함에 광고 제안 메일을 보게 되었다. 이때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `이거 사기 아닌가 하는 생각에 회사정보를 찾아본다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `앞으로 자주 광고가 들어오는 달콤한 상상을 한다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '이색 카페를 가게 되었다. 이때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '깔끔하고 특색 있어서 타 지역 사람들도 찾아오겠어.',
          },
          {
            type: 'N',
            score: 5,
            content: `마치 내가 숲속에 요정이 된 것 같아! 어떻게 이런 생각을 하셨을까..`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '약속 장소에 먼저 도착하게 되었다. 매장 안 음악을 들으며 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `이거 멜로디가 진짜 중독적이네.. 집 가서도 흥얼거릴 것 같아.`,
          },
          {
            type: 'N',
            score: 5,
            content: `친구가 추천해 준 노래 같은데 가사가 정말 지금의 나 같네.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `약속 장소로 오는 도중 사고가 나서 약속을 갈 수 없을 것 같다고 했을 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '헉.. 무슨 일이야.. 보험 불렀어? 보험사는 있지?',
          },
          {
            type: 'F',
            score: 5,
            content: '헉.. 무슨 일이야.. 많이 안 다쳤어? 괜찮은 거지?',
          },
        ],
      },
      {
        which: 'TF',
        question: '미래에 대한 고민을 털어놓는 친구에게 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `항상 열심히 하고 능력 있으니까 너무 걱정할 필요는 없을 것 같아.`,
          },
          {
            type: 'F',
            score: 5,
            content: '네가 노력한 만큼 꼭 해낼 거라고 난 믿어! 걱정은 넣어둬!',
          },
        ],
      },
      {
        which: 'TF',
        question: `이런저런 이야기하다가 솔직히 이때 좀 너에게 서운했다는 이야기를 들었을 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '(정말 궁금해서 묻는) 왜?',
          },
          {
            type: 'F',
            score: 5,
            content: '(덩달아 서운해서 묻는) 왜?',
          },
        ],
      },
      {
        which: 'JP',
        question: '프로젝트 진행 과정에 있어서 나에게 더 가까운 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `미리 계획한 대로 착착 맞춰서 기한까지 끝낸다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `순간 모든 집중력을 모아서 기한까지 맞춰서 끝낸다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'sns에서 너무 핫플이라고 같이 가자고 제안하는 친구에게 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '오! 좋아! 몇 시에 오픈이야? 브레이크 타임 있는지도 확인해 봐야겠어. 휴무도!',
          },
          {
            type: 'P',
            score: 5,
            content:
              '오! 좋아! 와 사진 진짜 맛있어 보인다. 이게 메인메뉴인가 봐. 맛있겠다 가자!',
          },
        ],
      },
      {
        which: 'JP',
        question: '전날 했던 등산이 무리했던 탓인지 피곤한 오늘, 이때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '피곤해도 하루 루틴을 망칠 순 없으니 움직인다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '‘하루쯤이야 괜찮겠지’하고 그냥 오늘은 침대와 물아 일체 모드다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/charFile/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/charFile/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/charFile/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/charFile/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/charFile/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/charFile/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/charFile/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/charFile/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/charFile/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/charFile/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/charFile/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/charFile/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/charFile/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/charFile/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/charFile/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/charFile/INFP.jpg',
      },
    ],
  },
  // koreanTable
  {
    info: {
      mainTitle: '한글 표 성격 테스트',
      subTitle: '한글 표로 보는 나의 성격은?',
      mainUrl: 'koreanTable',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/koreanTable-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/koreanTable-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '친구에 대한 당신의 생각은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구 폭이 넓을수록 좋다고 생각함',
          },
          {
            type: 'I',
            score: 5,
            content: '소수의 친구로도 충분하다고 생각함',
          },
        ],
      },
      {
        which: 'EI',
        question: '약속을 잡을 때 당신의 활동 범위는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '다양한 장소에서 약속을 잡는다.',
          },
          {
            type: 'I',
            score: 5,
            content: '집 근처에서 약속을 잡는다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '선호하는 당신의 의사소통은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `말을 통한 의사소통을 선호한다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `글을 통한 의사소통을 선호한다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '하늘에 풍선이 날아가는 것을 봤을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `풍선 잘 날아가고 있네`,
          },
          {
            type: 'N',
            score: 5,
            content: `저 풍선은 누가 놓쳐서 날아가고 있는 걸까? 갑자기 풍선 노래가 생각나네`,
          },
        ],
      },
      {
        which: 'SN',
        question: '핸드폰을 새로 구매했을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '일단 기능부터 확인해야지',
          },
          {
            type: 'N',
            score: 5,
            content: `만약 오늘 떨어뜨리면 무료 A/S 되겠지?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '자주 가던 밥집이 임시 휴업을 했을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `갑자기 임시 휴업이라니 다른 밥집 가야겠네`,
          },
          {
            type: 'N',
            score: 5,
            content: `장사가 안돼서 닫은 걸까? 무슨 일이지?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `좋아하는 사람을 대할 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '게임 좋아해? 좋아하는 음식이 뭐야?',
          },
          {
            type: 'F',
            score: 5,
            content: '와 진짜? 정말 대박이네',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '“아프면 병원 가지 약속은 왜 나왔어?”라는 말을 들었을 때 당신은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `지금이라도 병원 갈까라는 생각이 든다.`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '아픈 티 내지 말고 빨리 병원 가라는 말인가라는 생각이 든다.',
          },
        ],
      },
      {
        which: 'TF',
        question: `출근길 차 사고가 난 것을 봤을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '왜 하필 출근길에 사고가 난 걸까? 다친 사람은 없었으면 좋겠네',
          },
          {
            type: 'F',
            score: 5,
            content: '크게 다친 사람은 없어야 할 텐데 안타까워',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구가 약속 시간 보다 늦게 왔을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `약속 시간에 늦은 것은 예의를 지키지 못한 것이라고 생각한다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `나도 약속 늦었던 적이 있으니 그럴 수 있다고 생각한다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '여행 계획을 세울 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '정확하게 갈 수 있는 곳을 정한 후 일정을 세워 둔다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '가고 싶은 후보지만 정해 둔 후 도착한 시간에 따라 움직인다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '일을 처리할 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '일정을 확인한 후 미리 처리해 둔다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '대략적으로 처리한 후 나머지는 나중에 해야지 하다가 시간에 쫓겨 급하게 작업한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/koreanTable/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/koreanTable/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/koreanTable/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/koreanTable/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/koreanTable/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/koreanTable/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/koreanTable/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/koreanTable/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/koreanTable/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/koreanTable/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/koreanTable/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/koreanTable/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/koreanTable/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/koreanTable/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/koreanTable/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/koreanTable/INFP.jpg',
      },
    ],
  },
  // colorScream
  {
    info: {
      mainTitle: '컬러스크림 성격 테스트',
      subTitle: '컬러 아이스크림으로 보는 나의 성격은?',
      mainUrl: 'colorScream',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/colorScream-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/colorScream-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '친구와 약속 잡은 날이 다가온다 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '얼른 만나서 놀고 싶다',
          },
          {
            type: 'I',
            score: 5,
            content: '괜히 약속 잡았다…먼저 취소해 줬으면 좋겠다',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '해야 할 일을 다 끝내고 하늘을 보니 날씨가 너무 좋을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '날씨도 좋은데 좀 돌아다니다 가야겠다!',
          },
          {
            type: 'I',
            score: 5,
            content: '날씨 좋다~ 얼른 집에 가서 쉬어야지!',
          },
        ],
      },
      {
        which: 'EI',
        question: '여러 명이 모여있는 자리에서 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `말하기 담당`,
          },
          {
            type: 'I',
            score: 5,
            content: `리액션 담당`,
          },
        ],
      },
      {
        which: 'SN',
        question: '나는 1분 동안 아무 생각 안 하기가 가능한가?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `가능`,
          },
          {
            type: 'N',
            score: 5,
            content: `아무 생각을 안 하는 건 어떻게 하지? 일단 머리를 하얗게 만들어야 하나?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '무언가를 결정을 내릴 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '경험과 현재 상황에 초점을 맞춘다',
          },
          {
            type: 'N',
            score: 5,
            content: `미래의 가능성과 큰 그림을 고려한다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '변화에 직면했을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `검증된 방법과 익숙한 절차를 따름`,
          },
          {
            type: 'N',
            score: 5,
            content: `새롭고 창의적인 접근을 시도해 봄`,
          },
        ],
      },
      {
        which: 'TF',
        question: `스트레스 받을 때 나는 주로 어떤 편인가?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '문제를 분석하고 해결책을 찾으려고 한다',
          },
          {
            type: 'F',
            score: 5,
            content: '감정적 지원을 찾고 내 감정을 표현하려 한다',
          },
        ],
      },
      {
        which: 'TF',
        question: '대인관계를 하면서 주로 나는 어떤 성향인가?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `공정하고 객관적인 접근을 선호하는 편이다`,
          },
          {
            type: 'F',
            score: 5,
            content: '공감과 이해를 중시하는 편이다',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 엘리베이터에 갇혔다고 전화가 왔다 나의 반응은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '119에 전화는 해봤어?! 기다려 나도 해볼게',
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮아?! 다친 데는 없고?!!',
          },
        ],
      },
      {
        which: 'JP',
        question: '나는 무언가를 결정 내릴 때 어떤 편인가?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `미리 계획하고 신중하게 결정한다`,
          },
          {
            type: 'P',
            score: 5,
            content: `상황에 따라 유연하게 결정한다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '작업을 할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '시작 전 전체적인 틀을 잡는다',
          },
          {
            type: 'P',
            score: 5,
            content: '진행하면서 조정한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '새로운 변화에 대한 나의 태도는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '스트레스 받는다',
          },
          {
            type: 'P',
            score: 5,
            content: '쉽게 적응하여 즐긴다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorScream/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorScream/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/colorScream/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/colorScream/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorScream/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/colorScream/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/colorScream/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/colorScream/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorScream/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorScream/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/colorScream/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/colorScream/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorScream/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/colorScream/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/colorScream/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/colorScream/INFP.jpg',
      },
    ],
  },
  // oneSentence
  {
    info: {
      mainTitle: '나를 표현하는 한마디 테스트',
      subTitle: '나를 한마디로 표현한다면?',
      mainUrl: 'oneSentence',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/oneSentence-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/oneSentence-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          '새로 전학 와서 친구가 없어서 외롭다고 하는 같은 반 학우에게 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '새로 전학 와서 없는 게 당연하다고 하며 심심하면 나의 자리로 놀러 오라고 한다.',
          },
          {
            type: 'I',
            score: 5,
            content:
              '많이 외로울 것 같다며 금방 다른 친구들과도 친하게 지내게 될 것이라고 이야기한다.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '친구가 잠깐 카페에서 이야기하는 상황에서 내가 더 많이 하는 행동은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '친구의 고민거리가 있구나 생각하며 그것에 대한 나의 생각을 열심히 이야기한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '친구의 고민거리가 있구나 생각하며 이야기를 들어준다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '내내 춥다가 날씨가 많이 따뜻해지고 좋아졌다. 이때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `샤워하고 집 밖으로 나가서 산책하기`,
          },
          {
            type: 'I',
            score: 5,
            content: `샤워하고 침대에 누워서 배달 앱 켜기`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '학창 시절 절친이었던 친구가 사업가로 성공해서 티비에 나온다. 이때 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `항상 성실하고 올바른 아이였어서 어쩌면 당연한 일이라 생각한다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `항상 성실하고 올바른 아이였는데 어떻게 해서 성공했을지 생각한다.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '저 멀리 다른 행성에 대해 생각해 본 적 있어?라는 질문에 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '지구랑은 확실히 다른 환경이지 않을까 싶어.',
          },
          {
            type: 'N',
            score: 5,
            content: `다른 행성에도 우리 같이 생긴 생명체들이 우리랑 같은 생각하고 있겠지?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '공모전 모집 기간을 놓친 상황이다. 이때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `이 공모전은 추가모집을 자주 한다는 이야기가 있던데 미리 좀 알아봐야겠어!`,
          },
          {
            type: 'N',
            score: 5,
            content: `이거 한다고 소문 다 냈는데 주변 친구들은 나를 뭐라고 생각할까.. 왜 오늘까지였지? 난 왜 이렇게 게으를까!!!!`,
          },
        ],
      },
      {
        which: 'TF',
        question: `자주 가는 단골집에 갔더니 사장님이 서비스로 직접 만들었다는 케이크를 하나 주셨다. 이때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '솔직한 피드백이 사장님께도 도움이 될 것이라 생각이 들어 어떤 부분이 별로였는지 이야기한다.',
          },
          {
            type: 'F',
            score: 5,
            content:
              '열심히 만드신 거 같단 생각에 별로라도 괜찮다고 맛있다고 이야기한다.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '친구가 인스타 스토리에 우울한 심정을 표하는 글을 올렸다. 이때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `내가 해결해 줄 수 있는 부분이 아니라고 생각되어 일단 가만히 있는다.`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '스토리에 하트라도 눌러서 내가 걱정하고 있다는 것을 조금이나마 알린다.',
          },
        ],
      },
      {
        which: 'TF',
        question: `오랜만에 만난 친구의 근황이 새로운 사업을 시작한다고 한다. 이때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '오 사업? 어떤 사업? 초기 자본금은 얼마로 시작해?',
          },
          {
            type: 'F',
            score: 5,
            content:
              '우와 사업이라니! 정말 대단하다! 넌 진취적인 성격이라 잘할 것 같아!',
          },
        ],
      },
      {
        which: 'JP',
        question: '중요한 일정 전날 몸살이 와서 못 가게 되었다. 이때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `몸 관리를 제대로 하지 않았단 생각과 변수에 기분이 썩 좋지는 않다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `세상일이란 원래 계획대로 되지 않는 법이라 생각한다.`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          '집 밖을 나왔는데 휴대폰 배터리가 얼마 남지 않았다. 이때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '미리 챙겨 놓은 보조배터리로 긴급 수혈한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '‘친구한테 보조배터리 있겠지’라는 생각에 신경 안 쓰인다.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '새해가 지나고 벌써 한 달이란 시간이 흘렀다. 내가 바라는 앞으로의 한 달은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '변수가 계속 생기지 않는 평온한 한 달',
          },
          {
            type: 'P',
            score: 5,
            content: '변수가 생겨도 상황에 맞게 적응하며 지내는 한 달',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/oneSentence/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/oneSentence/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/oneSentence/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/oneSentence/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/oneSentence/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/oneSentence/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/oneSentence/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/oneSentence/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/oneSentence/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/oneSentence/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/oneSentence/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/oneSentence/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/oneSentence/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/oneSentence/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/oneSentence/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/oneSentence/INFP.jpg',
      },
    ],
  },
  // blackCherryEng
  {
    info: {
      mainTitle: 'Black Cherry Personality Test',
      subTitle: `What is my personality that recognizes black cherry?`,
      mainUrl: 'blackCherryEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/blackCherryEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/blackCherryEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          'An especially hard and tiring day... How do I relieve my stress?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'Make an appointment and go outside to meet people and chat',
          },
          {
            type: 'I',
            score: 5,
            content: 'Cancel all appointments and have time alone in the room',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What about me when I have a lot of work to do outside?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Work it out one by one over several days',
          },
          {
            type: 'I',
            score: 5,
            content:
              "I'll try to finish it within a day so it doesn't come out again",
          },
        ],
      },
      {
        which: 'EI',
        question: 'What if you stay in a crowded hot place for a long time?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `It's so fun and exciting. I want to play for a long time`,
          },
          {
            type: 'I',
            score: 5,
            content: `Getting tired and want to go home`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'Which of the two am I not good at?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I'm good at explaining, but I can't think of it in my head`,
          },
          {
            type: 'N',
            score: 5,
            content: `I know it well with my head, but I can't explain it well with words`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'How about when you watch a lot of movies or dramas at once?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I saw it all at once',
          },
          {
            type: 'N',
            score: 5,
            content: `Replay the impressive scene and watch it`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do I do when I buy something?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Realistically, I only buy what I need now`,
          },
          {
            type: 'N',
            score: 5,
            content: `Buying this and that, thinking they'll use it someday`,
          },
        ],
      },
      {
        which: 'TF',
        question: `When you do something?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I do what I want to do without looking at others',
          },
          {
            type: 'F',
            score: 5,
            content: 'I care about others and act carefully',
          },
        ],
      },
      {
        which: 'TF',
        question: "What about me when I refuse a friend's request?",
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I refuse with a cool head`,
          },
          {
            type: 'F',
            score: 5,
            content: 'I refuse carefully because I feel sorry',
          },
        ],
      },
      {
        which: 'TF',
        question: `Ambulance passes by while traveling in a car!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              "I think there's been an accident. There's going to be traffic",
          },
          {
            type: 'F',
            score: 5,
            content:
              'Is there an accident? Someone must have been hurt. What should I do?',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Back home, the room is a mess!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I'm going to clean my room the day after tomorrow, so I have to keep my plan`,
          },
          {
            type: 'P',
            score: 5,
            content: `Oh, no. Let's clean everything up right away`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'What do you do when you do something with a fixed deadline?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "It's up to this point, so we can start and finish it at this point",
          },
          {
            type: 'P',
            score: 5,
            content: "I still have a lot of free time. Let's do it tomorrow",
          },
        ],
      },
      {
        which: 'JP',
        question: "What if there's a notification on my phone?",
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I read it right away because it bothers me',
          },
          {
            type: 'P',
            score: 5,
            content: "It doesn't bother me much, and I read them all at once",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherryEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherryEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherryEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherryEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherryEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherryEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherryEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherryEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherryEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherryEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherryEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherryEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherryEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherryEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherryEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherryEng/INFP.jpg',
      },
    ],
  },
  // blackCherry
  {
    info: {
      mainTitle: '블랙 체리 성격 테스트',
      subTitle: '블랙 체리로 알아보는 내 성격은?',
      mainUrl: 'blackCherry',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/blackCherry-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/blackCherry-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '유난히 힘들고 지치는 하루.. 내 스트레스 해소 방법은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '약속 만들어서 밖으로 나가 사람들을 만나며 수다떨기',
          },
          {
            type: 'I',
            score: 5,
            content: '약속 모두 취소하고 방에서 혼자만의 시간 가지기',
          },
        ],
      },
      {
        which: 'EI',
        question: '밖에서 해야 할 일이 많을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '여러 날에 걸쳐 일을 나눠서 하나씩 해결함',
          },
          {
            type: 'I',
            score: 5,
            content: '또 안 나올 수 있도록 몰아서 되도록 하루 안에 끝내버림',
          },
        ],
      },
      {
        which: 'EI',
        question: '사람이 많은 핫플 장소에 오래 있으면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `너무 재밌고 신남 오래오래 더 놀고 싶음`,
          },
          {
            type: 'I',
            score: 5,
            content: `점점 기가 빨리고 피곤해지고 집에 가고 싶음`,
          },
        ],
      },
      {
        which: 'SN',
        question: '둘 중 내가 잘 못하는 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `설명은 잘 하는데 머릿속으로 구상을 잘 못함`,
          },
          {
            type: 'N',
            score: 5,
            content: `머리로는 잘 아는데 말로 잘 설명을 못함`,
          },
        ],
      },
      {
        which: 'SN',
        question: '영화나 드라마를 정주행할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '한 번에 쭈욱 다 보면서 정주행',
          },
          {
            type: 'N',
            score: 5,
            content: `인상 깊던 장면을 여러 번 보면서 정주행`,
          },
        ],
      },
      {
        which: 'SN',
        question: '뭔가를 구매할 때 나는??',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `현실적으로 지금 필요한 것만 구매`,
          },
          {
            type: 'N',
            score: 5,
            content: `언젠가 분명 쓸 일이 있을 거라고 생각하며 이것저것 구매`,
          },
        ],
      },
      {
        which: 'TF',
        question: `평소 어떤 행동을 할 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '남 눈치 잘 안 보고 하고 싶은 대로 함',
          },
          {
            type: 'F',
            score: 5,
            content: '다른 사람들 눈치를 잘 보고 신경 쓰면서 행동함',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구의 부탁을 거절할 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `냉정하게 거절한다`,
          },
          {
            type: 'F',
            score: 5,
            content: '미안해하며 거절한다',
          },
        ],
      },
      {
        which: 'TF',
        question: `차를 타고 이동 중 구급차가 지나간다!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '사고 났나 보네 차 막히겠다',
          },
          {
            type: 'F',
            score: 5,
            content: '사고 났나? 누가 다쳤나 봐 어쩌냐ㅜ',
          },
        ],
      },
      {
        which: 'JP',
        question: '집에 돌아와보니 방이 엉망이다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `방 청소는 모레 하기로 했으니까 대충만 치워 놓자`,
          },
          {
            type: 'P',
            score: 5,
            content: `어우 안되겠다 당장 싹 다 치우자`,
          },
        ],
      },
      {
        which: 'JP',
        question: '기한이 정해져 있는 일을 할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '이때 까지니까 이때쯤 시작해서 끝내면 되겠다',
          },
          {
            type: 'P',
            score: 5,
            content: '이때 까지니까 아직 여유 넘쳐 내일 하자 내일',
          },
        ],
      },
      {
        which: 'JP',
        question: '내 폰에 알림 표시가 떠있으면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '신경 쓰이고 거슬려서 바로 읽는다',
          },
          {
            type: 'P',
            score: 5,
            content: '크게 신경 쓰이지 않고 나중에 몰아서 읽는다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherry/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherry/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherry/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherry/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherry/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherry/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherry/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherry/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherry/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherry/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherry/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherry/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherry/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherry/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherry/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/blackCherry/INFP.jpg',
      },
    ],
  },
  // honeycombChar
  {
    info: {
      mainTitle: '벌집 성격 테스트',
      subTitle: '벌집으로 보는 나의 성격은?',
      mainUrl: 'honeycombChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/honeycombChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/honeycombChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '“쉬는 날에는 뭐해?”라고 질문받았을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구 만나서 밥 먹고 놀아',
          },
          {
            type: 'I',
            score: 5,
            content: '집에서 아무것도 안 하고 지내',
          },
        ],
      },
      {
        which: 'EI',
        question: '주말 약속이 없을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '어떡해서든 한가한 사람을 찾아 약속을 잡는다.',
          },
          {
            type: 'I',
            score: 5,
            content: '이대로 약속 연락이 안 오길 빈다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구가 취미 생활을 같이 하자고 했을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `같이 하면 더 즐거울 것 같으니 수락한다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `혼자서 하는 게 마음이 편하니 거절한다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '비싼 차를 봤을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `난 언제 살 수 있을까? 사도 유지비 장난 아니겠지?`,
          },
          {
            type: 'N',
            score: 5,
            content: `차주는 무슨 일을 하길래 비싼 차를 타고 다니는 걸까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구가 선물을 주며 몇 시간 뒤에 열어보라고 했을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '무슨 선물이지? 빨리 열고 보고 싶다.',
          },
          {
            type: 'N',
            score: 5,
            content: `선물을 왜 지금 열면 안 되는 거지? 내용물과 연관이 있나?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구와 여행을 가기 전 날 밤 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `빠뜨린 물건은 없는지 생각해 본 후 잠에 든다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `여행에서 생길 수 있는 이런저런 일을 생각하다가 잠에 든다.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 자꾸 허리가 아프다고 말했을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '허리는 진짜 중요한 곳인데 너 요즘 자주 말하더라 병원 한번 가보자',
          },
          {
            type: 'F',
            score: 5,
            content: '많이 아파? 전에 말했을 때 보다 더 아파 보여',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 애인과 싸웠다고 말을 했을 때 당신은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `왜 싸웠어? 누가 잘못한 건지 먼저 말해봐`,
          },
          {
            type: 'F',
            score: 5,
            content: '아고 속상하겠네 내가 다 들어줄게 얘기해 봐',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 궁금하지 않은 이야기를 신나게 떠들 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '대충 반응해 주다가 다른 이야기로 주제를 돌린다.',
          },
          {
            type: 'F',
            score: 5,
            content:
              '와 진짜? 대박이다. (반응 안 해주면 서운할 것 같아 리액션을 해준다)',
          },
        ],
      },
      {
        which: 'JP',
        question: '이사를 한 후 일주일이 지났을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `정돈된 방안을 보며 뿌듯한 마음을 느낀다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `방이 더러워져 다시 이사한 첫날로 돌아가고 싶다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '계획해 놨던 일정으로 하루가 돌아갈 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '딱딱 원하는 일정으로 돌아가니 기분이 좋다.',
          },
          {
            type: 'P',
            score: 5,
            content: '계획을 짜고 실천하다니 스스로가 대단하다고 생각한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구들과 일정을 정할 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '적극적으로 일정을 정리한 후 이야기를 꺼낸다.',
          },
          {
            type: 'P',
            score: 5,
            content: '누군가 알아서 짜주겠지 생각하며 가만히 구경한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/honeycombChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/honeycombChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/honeycombChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/honeycombChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/honeycombChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/honeycombChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/honeycombChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/honeycombChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/honeycombChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/honeycombChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/honeycombChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/honeycombChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/honeycombChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/honeycombChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/honeycombChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/honeycombChar/INFP.jpg',
      },
    ],
  },
  // charScore
  {
    info: {
      mainTitle: '성격 평점 테스트',
      subTitle: '나의 성격을 평점으로 매겨봐!',
      mainUrl: 'charScore',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/charScore-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/charScore-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '고객센터에 문의할 일이 생겼다. 이때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '궁금한 점을 전화로 바로 물어보는 것을 선호한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '할 말을 정리해서 고객센터 글을 남기는 것을 선호한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '나의 에너지의 방향은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '카페 창밖으로 지나가는 사람들 구경하기만 해도 충전되는 경우가 있다.',
          },
          {
            type: 'I',
            score: 5,
            content:
              '사람들은 유튜브나 OTT 안에서만 구경해야 에너지가 충전된다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구들과의 만남에서 스스로가 느끼는 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `시간이 지날수록 점점 더 신난 게 느껴지는 나 자신을 발견한다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `시간이 지날수록 눈빛이 점점 흐려지는 나 자신을 발견한다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '어떤 상황에 대한 상상을 해보라고 했을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `큰 틀을 안에서만 상상하고 그 이상으로 생각하지 않는다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `작은 틀에서 큰 틀이 되어 일어나지도 않을 상상에 대비하는 생각까지 한다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '상대방에게 내가 더 많이 하는 말은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '갑자기?',
          },
          {
            type: 'N',
            score: 5,
            content: `문득 생각난 건데`,
          },
        ],
      },
      {
        which: 'SN',
        question: '“넌 내일 죽어”라는 질문에 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `‘죽으면 죽는구나.. 그렇구나’라고 생각한다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `‘죽으면? 그럼 난.. 이제 앞으로 어떻게 되는 거지?’라고 생각한다.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `내가 가지고 있는 능력은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '어떤 돌발 상황이 발생했을 때 감정적으로 반응하지 않고 침착하게 대응할 수 있는 해결 능력',
          },
          {
            type: 'F',
            score: 5,
            content:
              '타인의 감정을 잘 이해하고 기분이 안 좋을 때 빠르게 알아차리고 풀어주려고 노력하는 능력',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '친구가 처음 가게 된 곳에서 길을 잃었다. 이때 내가 하는 말은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `그럼 빨리 앱 켜서 위치가 어딘지부터 확인해 보는 게 좋을 것 같아!`,
          },
          {
            type: 'F',
            score: 5,
            content: '지금 그래서 어디야? 괜찮은 거지?',
          },
        ],
      },
      {
        which: 'TF',
        question: `약속시간 늦은 것에 대해 조금 더 우선적으로 생각되는 부분은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '늦어도 왜 늦었는지 논리적으로 납득시키는 게 더 중요하다.',
          },
          {
            type: 'F',
            score: 5,
            content:
              '기다리다 화난 내 감정과 미안하다고 하면서 달래주는 게 더 중요하다.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '영화 보고 난 후 저녁을 먹을 예정이었지만 친구가 배가 고프다며 핫도그를 먹으면서 영화관람하자는 친구에게 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `일정이 틀어져서 아쉬운 마음을 뒤로한 채 빠른 플랜 B를 생각해서 결정을 내린다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `약간 출출한 것 같으니 핫도그와 어울리는 다른 메뉴도 사서 같이 먹는다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 더 선호하는 삶은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '질서 있는 삶을 선호한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '융통성 있는 삶을 선호한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '나에게 좀 더 가까운 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '오늘 계획한 대로 일 처리하고 청소까지 끝냈다! 이제 저녁거리 사러 나가야겠다!',
          },
          {
            type: 'P',
            score: 5,
            content:
              '요 정도까지 일 처리하고 청소하면 되는데.. 근데 뭐 먹고 싶네.. 아 저녁거리는 일단 그때 가서 정해야지!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/charScore/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/charScore/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/charScore/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/charScore/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/charScore/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/charScore/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/charScore/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/charScore/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/charScore/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/charScore/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/charScore/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/charScore/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/charScore/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/charScore/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/charScore/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/charScore/INFP.jpg',
      },
    ],
  },
  // graphicDesignChar
  {
    info: {
      mainTitle: '그래픽 디자인 성격 테스트',
      subTitle: '그래픽 디자인으로 보는 나의 성격은',
      mainUrl: 'graphicDesignChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/graphicDesignChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/graphicDesignChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '발표 담당자를 뽑을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '발표는 제가 할게요! (당당)',
          },
          {
            type: 'I',
            score: 5,
            content: '발표 내가 걸리면 어쩌지.. (시선회피)',
          },
        ],
      },
      {
        which: 'EI',
        question: '주말 내내 집에만 있다가 주말이 끝났다 나의 생각은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '너무 지루했다.. 역시 나가 놀아야 해',
          },
          {
            type: 'I',
            score: 5,
            content: '아.. 벌써 끝난 건가.. 아쉽다',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '약속에 나갈 준비를 다했는데 사정이 있어서 못 나온다는 친구 나의 반응은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `어쩔 수 없지ㅠ (아쉽지만 다른 약속이라도 잡아봄)`,
          },
          {
            type: 'I',
            score: 5,
            content: `어쩔 수 없지ㅠ (그래도 취소된 사실이 더 좋음)`,
          },
        ],
      },
      {
        which: 'SN',
        question: '나와 더 가까운 성격은 어느 것인가?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `그냥 그런가 보다 하는`,
          },
          {
            type: 'N',
            score: 5,
            content: `쓸데없이 생각 많은`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구가 주말 어떻게 보냈어?라는 질문에 내가 대답하는 느낌은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '호캉스,가족여행,데이트,알바,운동 등등',
          },
          {
            type: 'N',
            score: 5,
            content: `재미있었어, 괜찮았어, 무서웠어 등등`,
          },
        ],
      },
      {
        which: 'SN',
        question: '풍선을 보면 떠오르는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `풍선이다`,
          },
          {
            type: 'N',
            score: 5,
            content: `헬륨 마시고 목소리 변하고 싶다`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 나에게 슬픈 사정을 얘기하면서 울 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '갑자기 눈물을..! 울지 말고 이야기해 봐(휴지를 건네준다)',
          },
          {
            type: 'F',
            score: 5,
            content: '덩달아 눈물이 고인다',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 요즘 계속 악몽을 꿔서 힘들다고 얘기할 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `무슨 악몽을 꾸는데? 엎드려서 자면 악몽 꾼다던데 잠자리 때문은 아니야?`,
          },
          {
            type: 'F',
            score: 5,
            content: '헐,, 악몽 꾸면 힘든데 ㅜㅜㅜ 요즘 계속 그러는 거야?',
          },
        ],
      },
      {
        which: 'TF',
        question: `좋아하는 아이돌 그룹 멤버가 탈퇴한다고 슬퍼하는 친구에게 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '근데 어떻게 떠난다는데 보내줘야지..',
          },
          {
            type: 'F',
            score: 5,
            content: 'ㅠㅠㅠㅠㅠ안돼에에에에',
          },
        ],
      },
      {
        which: 'JP',
        question: '대청소를 하자!라고 마음먹은 뒤 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `청소는 물론 방 가구 구조변경까지 생각함`,
          },
          {
            type: 'P',
            score: 5,
            content: `책상 정리만 하다가 힘들어서 누움`,
          },
        ],
      },
      {
        which: 'JP',
        question: '하루 일과를 계획대로 마쳤을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '아~ 뿌듯해 내일은 뭐 해야지(바로 내일 계획)',
          },
          {
            type: 'P',
            score: 5,
            content: '뿌듯한데 지친다.. 내일은 평소처럼 살자',
          },
        ],
      },
      {
        which: 'JP',
        question: '병원 대기시간이 예상보다 길어질 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '다음 스케줄 생각하면서 초조해진다',
          },
          {
            type: 'P',
            score: 5,
            content: '별생각 없이 기다리다 그때 가서 생각한다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/graphicDesignChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/graphicDesignChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/graphicDesignChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/graphicDesignChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/graphicDesignChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/graphicDesignChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/graphicDesignChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/graphicDesignChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/graphicDesignChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/graphicDesignChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/graphicDesignChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/graphicDesignChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/graphicDesignChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/graphicDesignChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/graphicDesignChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/graphicDesignChar/INFP.jpg',
      },
    ],
  },
  // nagForYou
  {
    info: {
      mainTitle: '널 위한 잔소리 테스트',
      subTitle: '이게 다 널 위해서 하는 소리야~!',
      mainUrl: 'nagForYou',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/nagForYou-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/nagForYou-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '친하지 않았던 동창을 만났을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '반갑게 인사하며 자연스럽게 안부를 묻는다',
          },
          {
            type: 'I',
            score: 5,
            content: '인사를 할지 말지 고민한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '집 밖으로 나왔을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '집에 있으면 답답했는데 너무 좋다.',
          },
          {
            type: 'I',
            score: 5,
            content: '집 밖은 기 빨리는데.. 다시 들어가고 싶다.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '집에 혼자 있는데 친구가 집 근처를 지나간다고 했을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `너 어디 가? 약속 없으면 나랑 놀자`,
          },
          {
            type: 'I',
            score: 5,
            content: `아 그래? 잘 지나가고 다음에 보자`,
          },
        ],
      },
      {
        which: 'SN',
        question: '생각이 단순한 사람을 보았을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `단순하게 생각할 수 있지`,
          },
          {
            type: 'N',
            score: 5,
            content: `어떻게 생각이 단순할 수 있지? 여러 가지 생각이 떠오르지 않나?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '아무 생각 없이 앉아보라고 했을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '알겠다고 한 뒤 가만히 멍 때린다.',
          },
          {
            type: 'N',
            score: 5,
            content: `왜 생각하지 말라는 거야? 아 이것도 생각인데?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '이해할 수 없는 질문지를 받았을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `질문이 이상해서 뭘 선택해야 할지 모르겠어`,
          },
          {
            type: 'N',
            score: 5,
            content: `이것을 물어보는 의도가 뭐지? 무슨 뜻이 담긴 거지??`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 사람에 따라 기분 나쁜 수 있는 행동을 했을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '행동에 대해 명확하게 설명한다.',
          },
          {
            type: 'F',
            score: 5,
            content: '말하면 지적하는 것 같아서 가만히 있는다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '부모님께 혼났다며 말을 하는 친구 그때 당신은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `어쩌다가 혼났어? 네가 혼날 짓을 한 거 아냐?`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '많이 혼났어? 속상하지 ㅜㅜ 네가 좋아하는 음식 먹으러 가자!',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 물건을 망가뜨린 후 울면서 사과를 했을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '주인도 아닌데 왜 망가뜨린 사람이 우는지 이유를 모르겠다.',
          },
          {
            type: 'F',
            score: 5,
            content: '화가 났지만 우는 모습을 보니까 마음이 약해진다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '갑작스러운 일정 변경으로 인해 계획이 틀어졌을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `미뤄지게 된 일정을 생각해 보고 조율한다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `뭐 그럴 수도 있지~ `,
          },
        ],
      },
      {
        which: 'JP',
        question: '강압적으로 다른 사람이 계획한 일을 따를 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '다른 사람이 정해준 계획이라 그런지 맘에 안 든다.',
          },
          {
            type: 'P',
            score: 5,
            content: '계획해 준 일을 그대로 하니까 편하다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '일주일 뒤 약속을 정할 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '미리 약속 일정과 장소를 정해 둔다.',
          },
          {
            type: 'P',
            score: 5,
            content: '약속 일자가 다가왔을 때 정하자고 한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/nagForYou/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/nagForYou/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/nagForYou/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/nagForYou/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/nagForYou/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/nagForYou/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/nagForYou/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/nagForYou/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/nagForYou/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/nagForYou/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/nagForYou/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/nagForYou/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/nagForYou/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/nagForYou/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/nagForYou/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/nagForYou/INFP.jpg',
      },
    ],
  },
  // webCharEng
  {
    info: {
      mainTitle: 'Spider Web Personality Test',
      subTitle: 'What does my personality caught in a spider web reveal?',
      mainUrl: 'webCharEng',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/webCharEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/webCharEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: "What about you when you met a friend you weren't close to?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Greet warmly and naturally inquire about well-being',
          },
          {
            type: 'I',
            score: 5,
            content: 'I think about whether to say hello or not',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What about you when you came out of the house?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: "I was frustrated when I was at home, but it's so nice",
          },
          {
            type: 'I',
            score: 5,
            content: "It's hard outside the house, so I want to go back in",
          },
        ],
      },
      {
        which: 'EI',
        question:
          "When you're home alone and a friend mentions passing by your house, what do you do?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Where are you going? If you don't have an appointment, go play with me`,
          },
          {
            type: 'I',
            score: 5,
            content: `Oh, really? I hope it goes well. See you next time`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do you think when you see someone with a simple mind?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I think it's simple to think about`,
          },
          {
            type: 'N',
            score: 5,
            content: `How can you think so simply? Don't various thoughts come to mind?`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What about you when you told me to sit without thinking?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: "I understand,' and then sit still and space out",
          },
          {
            type: 'N',
            score: 5,
            content: `Why tell me not to think? But isn't this also a thought?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          "What about you when you are asked a questionnaire that you don't understand?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I don't know what to choose because the question is weird`,
          },
          {
            type: 'N',
            score: 5,
            content: `What is the point of asking this? What does it mean?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What about you when your friend does something that may make you feel bad depending on the person?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Explain clearly about the behavior',
          },
          {
            type: 'F',
            score: 5,
            content:
              "I stay quiet because it feels like pointing out what they're saying",
          },
        ],
      },
      {
        which: 'TF',
        question:
          'A friend who says she was scolded by her parents. What about you?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `How did you get scolded? Did you do something to deserve it?`,
          },
          {
            type: 'F',
            score: 5,
            content:
              "Did I get scolded a lot? I'm upset. Let's go eat what you like!",
          },
        ],
      },
      {
        which: 'TF',
        question: `What about you when your friend cried and apologized after breaking things?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              "I don't understand why someone who destroyed it is crying when they're not even the owner",
          },
          {
            type: 'F',
            score: 5,
            content: "I'm angry, but I feel weak when I see him crying",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What about you when your plans go awry due to a sudden change of schedule?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I consider and adjust the postponed schedule`,
          },
          {
            type: 'P',
            score: 5,
            content: `Maybe that could happen~`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          "When you are coercively following someone else's planned activities?",
        answers: [
          {
            type: 'J',
            score: 2,
            content: "I don't like it because it's someone else's plan",
          },
          {
            type: 'P',
            score: 5,
            content: "It's comfortable to do what others have planned",
          },
        ],
      },
      {
        which: 'JP',
        question: 'What about you when you set up an appointment in a week?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Set an appointment schedule and place in advance',
          },
          {
            type: 'P',
            score: 5,
            content:
              'When the date of appointment approaches, they ask to decide',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/webCharEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/webCharEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/webCharEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/webCharEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/webCharEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/webCharEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/webCharEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/webCharEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/webCharEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/webCharEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/webCharEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/webCharEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/webCharEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/webCharEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/webCharEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/webCharEng/INFP.jpg',
      },
    ],
  },
  // webChar
  {
    info: {
      mainTitle: '거미줄 성격 테스트',
      subTitle: '거미줄에 걸려있는 나의 성격은?',
      mainUrl: 'webChar',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/webChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/webChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '친하지 않았던 동창을 만났을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '반갑게 인사하며 자연스럽게 안부를 묻는다',
          },
          {
            type: 'I',
            score: 5,
            content: '인사를 할지 말지 고민한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '집 밖으로 나왔을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '집에 있으면 답답했는데 너무 좋다.',
          },
          {
            type: 'I',
            score: 5,
            content: '집 밖은 기 빨리는데.. 다시 들어가고 싶다.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '집에 혼자 있는데 친구가 집 근처를 지나간다고 했을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `너 어디 가? 약속 없으면 나랑 놀자`,
          },
          {
            type: 'I',
            score: 5,
            content: `아 그래? 잘 지나가고 다음에 보자`,
          },
        ],
      },
      {
        which: 'SN',
        question: '생각이 단순한 사람을 보았을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `단순하게 생각할 수 있지`,
          },
          {
            type: 'N',
            score: 5,
            content: `어떻게 생각이 단순할 수 있지? 여러 가지 생각이 떠오르지 않나?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '아무 생각 없이 앉아보라고 했을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '알겠다고 한 뒤 가만히 멍 때린다.',
          },
          {
            type: 'N',
            score: 5,
            content: `왜 생각하지 말라는 거야? 아 이것도 생각인데?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '이해할 수 없는 질문지를 받았을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `질문이 이상해서 뭘 선택해야 할지 모르겠어`,
          },
          {
            type: 'N',
            score: 5,
            content: `이것을 물어보는 의도가 뭐지? 무슨 뜻이 담긴 거지??`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 사람에 따라 기분 나쁜 수 있는 행동을 했을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '행동에 대해 명확하게 설명한다.',
          },
          {
            type: 'F',
            score: 5,
            content: '말하면 지적하는 것 같아서 가만히 있는다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '부모님께 혼났다며 말을 하는 친구 그때 당신은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `어쩌다가 혼났어? 네가 혼날 짓을 한 거 아냐?`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '많이 혼났어? 속상하지 ㅜㅜ 네가 좋아하는 음식 먹으러 가자!',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 물건을 망가뜨린 후 울면서 사과를 했을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '주인도 아닌데 왜 망가뜨린 사람이 우는지 이유를 모르겠다.',
          },
          {
            type: 'F',
            score: 5,
            content: '화가 났지만 우는 모습을 보니까 마음이 약해진다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '갑작스러운 일정 변경으로 인해 계획이 틀어졌을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `미뤄지게 된 일정을 생각해 보고 조율한다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `뭐 그럴 수도 있지~ `,
          },
        ],
      },
      {
        which: 'JP',
        question: '강압적으로 다른 사람이 계획한 일을 따를 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '다른 사람이 정해준 계획이라 그런지 맘에 안 든다.',
          },
          {
            type: 'P',
            score: 5,
            content: '계획해 준 일을 그대로 하니까 편하다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '일주일 뒤 약속을 정할 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '미리 약속 일정과 장소를 정해 둔다.',
          },
          {
            type: 'P',
            score: 5,
            content: '약속 일자가 다가왔을 때 정하자고 한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/webChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/webChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/webChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/webChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/webChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/webChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/webChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/webChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/webChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/webChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/webChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/webChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/webChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/webChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/webChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/webChar/INFP.jpg',
      },
    ],
  },
  // letYouKnowYou
  {
    info: {
      mainTitle: '너를 알려드려요 테스트',
      subTitle: '너가 알려주는 나의 성격은?',
      mainUrl: 'letYouKnowYou',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/letYouKnowYou-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/letYouKnowYou-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '새로운 사람들에게 자기소개를 해야 하는 상황에 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '안녕하세요 잘 부탁드립니다! 저는~(막힘없이 술술 잘함)',
          },
          {
            type: 'I',
            score: 5,
            content: '안녕하세요~.. (쭈뼛쭈뼛)',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구들과 모였을 때 나와 더 가까운 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '말하기 바쁨 할 말 너무 많음',
          },
          {
            type: 'I',
            score: 5,
            content: '들어주기 바쁨 정신 하나도 없음',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '길 가는데 저 멀리서 아는 사람이 반갑게 인사를 걸어올 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `반갑게 인사하며 자연스럽게 이야기를 나눈다`,
          },
          {
            type: 'I',
            score: 5,
            content: `앗.. 아는 척 안 했으면 좋겠다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '아무리 먹어도 살이 안 찌는 약이 나온다는 뉴스를 본 뒤 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `와 대박;; 세상이 이렇게나 발전을..`,
          },
          {
            type: 'N',
            score: 5,
            content: `뭐로 만든 거지? 그게 말이 되나? 부작용은 없나?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '집 앞에 만들어 둔 눈 오리가 자꾸 한 개씩 없어질 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '어떤 인간이 자꾸 내 눈 오리를 ㅡㅡ',
          },
          {
            type: 'N',
            score: 5,
            content: `뭐야? 소름 돋게 왜 자꾸 하나만 없어지지? 누가 나 지켜보고 있는 거 아니야? 아님 길고양이가?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '오후 7시 버스를 타고 창밖으로 보름달이 뜬 야경을 볼 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `이쁘다..`,
          },
          {
            type: 'N',
            score: 5,
            content: `저 달에서도 내가 보일까? 작은 점으로도 안 보이겠지?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `하고 있는 일이 안 맞아서 너무 힘들다고 속상해하는 친구에게 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '다른 일은 생각해 봤어? 너무 스트레스 받으면 다른 일도 고려해 봐',
          },
          {
            type: 'F',
            score: 5,
            content: '어휴 너무 스트레스받겠다 ㅠㅠ',
          },
        ],
      },
      {
        which: 'TF',
        question: '내일 일찍 일어나야 하는데 잠이 안 온다는 친구에게 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `핸드폰 하지 말고 눈 감고 있어봐`,
          },
          {
            type: 'F',
            score: 5,
            content: '꼭 그런 날엔 잠이 잘 안 오더라 ㅠㅠ',
          },
        ],
      },
      {
        which: 'TF',
        question: `최근 실수를 너무 많이 해서 속상하다는 친구에게 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '앞으로는 조금 더 차분하게 해봐',
          },
          {
            type: 'F',
            score: 5,
            content: '실수할 수도 있지 괜찮아!',
          },
        ],
      },
      {
        which: 'JP',
        question: '계획 한걸 못 지켰을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `나 자신에게 실망감이 든다`,
          },
          {
            type: 'P',
            score: 5,
            content: `사람이 어떻게 계획대로 살아~!`,
          },
        ],
      },
      {
        which: 'JP',
        question: '2024년 새해를 맞이한 나와 더 가까운 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '한 해의 새로운 버킷리스트를 작성해 본다',
          },
          {
            type: 'P',
            score: 5,
            content: '이번년도도 무난하게 잘 지내보자 하고 생각한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 더 마음이 편안한 ‘내일’은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '할 일이 정해져 있는 ‘내일’',
          },
          {
            type: 'P',
            score: 5,
            content: '할 일이 정해져 있지 않은 ‘내일',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/letYouKnowYou/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/letYouKnowYou/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/letYouKnowYou/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/letYouKnowYou/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/letYouKnowYou/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/letYouKnowYou/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/letYouKnowYou/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/letYouKnowYou/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/letYouKnowYou/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/letYouKnowYou/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/letYouKnowYou/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/letYouKnowYou/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/letYouKnowYou/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/letYouKnowYou/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/letYouKnowYou/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/letYouKnowYou/INFP.jpg',
      },
    ],
  },
  // guestCheck
  {
    info: {
      mainTitle: '주문서 성격 테스트',
      subTitle: '어떤 성격을 추가하거나 빼면 좋을까?',
      mainUrl: 'guestCheck',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/guestCheck-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/guestCheck-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '친구가 갑자기 보자고 했을 때 나의 표정은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '너무 좋다고 생각이 들며 얼굴에 화색이 돈다.',
          },
          {
            type: 'I',
            score: 5,
            content: '잠시 고민되고 생각이 깊어지며 웃음기가 사라진다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '나 스스로를 생각했을 때 드는 생각은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '나 혹시 아싸일까?! 라는 생각',
          },
          {
            type: 'I',
            score: 5,
            content: '단 한 번도 인싸라고 생각해 본 적 없다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '외출에 대한 나의 행동은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `이유 없어도 나가려고 한다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `이유 없어도 안 나가려고 하거나 나갈 이유 자체를 거의 안 만든다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '유명한 드라마 ost를 들으면서 하는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `다음 노래 뭐 들을지 생각한다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `순간 몇 초간 그 드라마의 장면이 떠오른다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '상대를 보며 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '왜 이렇게 복잡하게 생각하면서 살까?',
          },
          {
            type: 'N',
            score: 5,
            content: `왜 저런 생각을 하지 않는 걸까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구가 이상한 질문을 할 때 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `뭐라고 답해야 할지 생각이 안 나서 진짜 고민한다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `혼자만의 생각에 빠져서 진지하게 고민해 본다.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 귀여운 소품을 수집하는 취미가 있다. 이때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '그걸 좋아하는 이유가 있어?',
          },
          {
            type: 'F',
            score: 5,
            content: '우와 그거 너무 귀엽다!',
          },
        ],
      },
      {
        which: 'TF',
        question: '애인이 요즘 태도가 달라졌다. 이때 드는 생각은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `뭐가 불만인지 제발 말해줬으면 한다.`,
          },
          {
            type: 'F',
            score: 5,
            content: '점점 달라지는 상대방을 볼 때마다 놓칠까 겁이 난다.',
          },
        ],
      },
      {
        which: 'TF',
        question: `집 가서 요즘 핫하다는 게임을 한다고 할 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '그래서 집 가면 그거 하는 거야? 그거 재밌어?',
          },
          {
            type: 'F',
            score: 5,
            content: '오오 너 그런 장르의 게임도 좋아하는구나! 대박 신기해!!!',
          },
        ],
      },
      {
        which: 'JP',
        question: '팝업스토어 가기로 한 약속 전날 드는 생각은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `1시 약속이니까 10시에 기상해서 씻고 늦어도 12시 10분까지는 나가야겠다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `내일 가기로 한 팝업스토어 너무 재밌겠다. 키링이랑 쿠션은 꼭 사야지!`,
          },
        ],
      },
      {
        which: 'JP',
        question: '평소와 다른 계획을 맞이하게 되었을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '나 완전 즉흥적으로 변했네.. (흠칫)',
          },
          {
            type: 'P',
            score: 5,
            content: '나 이 정도면 완전 계획형 인간이잖아? (뿌듯)',
          },
        ],
      },
      {
        which: 'JP',
        question: '정말 하기 싫은 일을 마주하게 되었을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '다음 일정 계산해서 오늘 쉬었을 때 다음에 언제 해야 하는지 생각한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '내일의 내가 더 강하다고 믿고 미뤄본다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/guestCheck/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/guestCheck/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/guestCheck/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/guestCheck/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/guestCheck/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/guestCheck/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/guestCheck/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/guestCheck/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/guestCheck/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/guestCheck/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/guestCheck/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/guestCheck/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/guestCheck/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/guestCheck/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/guestCheck/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/guestCheck/INFP.jpg',
      },
    ],
  },
  // glitchCharEng
  {
    info: {
      mainTitle: 'Glitch Personality Test',
      subTitle: 'What is my personality that I see with the glitch effect?',
      mainUrl: 'glitchCharEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/glitchCharEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/glitchCharEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'What if you met a new friend through an acquaintance?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Wow, a new person! I need to get to know them quickly!',
          },
          {
            type: 'I',
            score: 5,
            content: "What should I say? It's awkward",
          },
        ],
      },
      {
        which: 'EI',
        question:
          'After work, when you walk through a busy street full of lively bars, what does it feel like?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'I feel like I want to play, so I check my contacts and see who I can call',
          },
          {
            type: 'I',
            score: 5,
            content: 'I head straight home past the pub street fast',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What about you when you are home alone on weekends without an appointment?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I feel restless staying inside all day. Maybe I should step out for a bit?`,
          },
          {
            type: 'I',
            score: 5,
            content: `This is so relaxing. I hope no one contacts me today`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What about you when you ask what comes to mind when you apologize?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `It's red. It looks delicious`,
          },
          {
            type: 'N',
            score: 5,
            content: `Snow White, poison apple, etc`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'When an accident occurs and you analyze the root cause?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I analyze the problem by focusing on the facts',
          },
          {
            type: 'N',
            score: 5,
            content: `I approach the problem by focusing on the overall possibilities`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What about you when you say what you felt after watching a movie?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Explain the details and feelings`,
          },
          {
            type: 'N',
            score: 5,
            content: `I describe the overall feeling and predict its success`,
          },
        ],
      },
      {
        which: 'TF',
        question: `When a friend says they caught a cold because of the rain, what do you do?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'You should have bought an umbrella from a nearby store. Why did you get wet?',
          },
          {
            type: 'F',
            score: 5,
            content: 'I heard colds are severe these days. Are you okay?',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What about you when you hear "I think you are a really good friend"?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Thank you. But what specifically makes you feel that way?`,
          },
          {
            type: 'F',
            score: 5,
            content:
              "I'm glad you feel that way. It makes me happy. You're a really good friend to me too!",
          },
        ],
      },
      {
        which: 'TF',
        question: `If a friend’s eagerly anticipated concert gets canceled, how might react?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              "What's the reason for the cancellation? Did you get a refund?",
          },
          {
            type: 'F',
            score: 5,
            content:
              "You weren't looking forward to that performance? That's disappointing",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What if a friend wants to change the meeting place on the day of the appointment?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `It's decided, so let's go next time`,
          },
          {
            type: 'P',
            score: 5,
            content: `I don't mind changing it`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'If your sibling has rearranged the location of things as they pleased?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I return the item to its original position',
          },
          {
            type: 'P',
            score: 5,
            content: "I don't really know the original position of the item",
          },
        ],
      },
      {
        which: 'JP',
        question: "When setting up a New Year's plan, what do you do?",
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I plan within the scope of what I can execute',
          },
          {
            type: 'P',
            score: 5,
            content: 'I roughly plan what I want to try or do',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/glitchCharEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/glitchCharEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/glitchCharEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/glitchCharEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/glitchCharEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/glitchCharEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/glitchCharEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/glitchCharEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/glitchCharEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/glitchCharEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/glitchCharEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/glitchCharEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/glitchCharEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/glitchCharEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/glitchCharEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/glitchCharEng/INFP.jpg',
      },
    ],
  },
  // glitchChar
  {
    info: {
      mainTitle: '글리치 성격 테스트',
      subTitle: '글리치 효과로 보는 나의 성격은?',
      mainUrl: 'glitchChar',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/glitchChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/glitchChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '아는 지인을 통해 새로운 친구를 만나게 됐다. 그때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '와 새로운 사람이라니 빨리 친해져야지',
          },
          {
            type: 'I',
            score: 5,
            content: '무슨 말을 건네야 할까? 어색해..',
          },
        ],
      },
      {
        which: 'EI',
        question: '퇴근 후 시끌벅적한 술집 거리를 지나갈 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '덩달아 놀고 싶은 마음이 들어 연락처를 살펴본다.',
          },
          {
            type: 'I',
            score: 5,
            content: '빠르게 술집 거리를 지나쳐 집으로 간다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '약속 없는 주말 집에 혼자 있을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `집에만 있으니 답답한데 잠깐 밖에 나갔다 올까?`,
          },
          {
            type: 'I',
            score: 5,
            content: `너무 편안해 오늘은 아무도 연락 안 했으면 좋겠어`,
          },
        ],
      },
      {
        which: 'SN',
        question: '사과하면 떠오르는 것을 물었을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `빨갛네, 맛있어 보여 등`,
          },
          {
            type: 'N',
            score: 5,
            content: `백설공주, 독 사과 등`,
          },
        ],
      },
      {
        which: 'SN',
        question: '사고가 발생하여 근본 원인을 분석할 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '사실에 초점을 맞춰 문제를 분석해 본다.',
          },
          {
            type: 'N',
            score: 5,
            content: `전체적인 가능성에 주목하며 문제에 접근해 본다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '영화를 보고 나서 느낀 점을 말할 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `세세한 내용과 느낌 점을 설명한다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `전반적인 느낌을 말하고 흥행 여부를 예상한다.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 비 때문에 감기에 걸렸다고 했을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '우산 없었으면 사지 비를 왜 맞았어 병원은 다녀왔고?',
          },
          {
            type: 'F',
            score: 5,
            content: '요즘 감기 독하다고 하던데 괜찮아?',
          },
        ],
      },
      {
        which: 'TF',
        question: '“넌 진짜 좋은 친구인 것 같아”라는 말을 들었을 때 당신은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `고마워 근데 어느 부분에서 느끼게 된 거야?`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '그렇게 생각했다니 기분 좋아진다 너도 나에게 진짜 좋은 친구야!!',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 기대하고 있던 공연이 취소되었다고 말을 했을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '취소 이유가 뭐야? 환불은 받았어?',
          },
          {
            type: 'F',
            score: 5,
            content: '너 기대하고 있던 공연 아니야? 내가 더 아쉬워 ㅠㅠ',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구가 약속 당일에 장소를 변경하고 싶다고 말했을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `이미 장소를 정했는데 바꾼다고? 다른 장소는 다음에 가자`,
          },
          {
            type: 'P',
            score: 5,
            content: `난 변경해도 상관없어`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          '동생이 한동안 쓰지 않았던 물건의 위치를 변경하였을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '물건 위치를 원래대로 돌려놓는다.',
          },
          {
            type: 'P',
            score: 5,
            content: '처음 물건의 위치도 잘 모른다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '신년 계획표를 세웠을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '실행할 수 있는 범위 내에서 계획을 세운다.',
          },
          {
            type: 'P',
            score: 5,
            content: '해보고 싶은 것 등 대략적으로 계획을 세운다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/glitchChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/glitchChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/glitchChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/glitchChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/glitchChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/glitchChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/glitchChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/glitchChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/glitchChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/glitchChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/glitchChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/glitchChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/glitchChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/glitchChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/glitchChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/glitchChar/INFP.jpg',
      },
    ],
  },
  // myHeart18
  {
    info: {
      mainTitle: '내 맘속 18번 테스트',
      subTitle: '나만의 18번 노래른 무엇일까?',
      mainUrl: 'myHeart18',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/myHeart18-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/myHeart18-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          '두 달 전 잡은 약속 전날! 그런데 친구가 아직까지 연락이 없다?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '연락해서 만나기로 한 거 잊지 말라고 당부함',
          },
          {
            type: 'I',
            score: 5,
            content: '연락 안 하고 기다리면서 취소되길 바람',
          },
        ],
      },
      {
        which: 'EI',
        question: '어쩌다 보니 어색한 친구들과 함께 모이게 된 자리!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '이참에 친해지고 좋지~ 말 통하면 절친 예약!',
          },
          {
            type: 'I',
            score: 5,
            content: '내가 왜 여기에…? 공기가 무거워 숨이 턱턱..',
          },
        ],
      },
      {
        which: 'EI',
        question: '어색한 사람들과 함께 노래방에 가게 되었다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `마이크는 내 꺼! 신명 나게 불러재낌`,
          },
          {
            type: 'I',
            score: 5,
            content: `노래 부르긴 좀 부끄.. 눈치 보며 박수만 침`,
          },
        ],
      },
      {
        which: 'SN',
        question: '노래를 부르려고 예약을 했다. 기다리는 중에 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `내 순서가 어디쯤인지 생각함`,
          },
          {
            type: 'N',
            score: 5,
            content: `삑사리가 나진 않을지, 너무 잘 불러서 놀라는 건 아닐지 생각함`,
          },
        ],
      },
      {
        which: 'SN',
        question: '옆방에서 남자가 거의 절규하며 노래를 부르고 있다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '좀 시끄럽네',
          },
          {
            type: 'N',
            score: 5,
            content: `뭐야 저 정도면 진짜 이별한 거 아니야? 아니면 군대 가나??`,
          },
        ],
      },
      {
        which: 'SN',
        question: '노래를 부르고 온 친구가 노래 어땠어? 라고 물어보면 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `노래? 이 노래 명곡이지~ 좋아!`,
          },
          {
            type: 'N',
            score: 5,
            content: `잘 부르더라!! 목소리랑 노래가 잘 어울리는 느낌이었어!`,
          },
        ],
      },
      {
        which: 'TF',
        question: `내가 노래를 부르는데 다 딴짓을 한다!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '아무 상관없이 내 노래에 심취',
          },
          {
            type: 'F',
            score: 5,
            content: '나 노래 부르고 있는데.. 서운.. 삐짐..',
          },
        ],
      },
      {
        which: 'TF',
        question: '노래를 부르고 자리에 앉았다!',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `이 정도면 만족! 다음 뭐 부를지 생각함`,
          },
          {
            type: 'F',
            score: 5,
            content: '다른 사람들이 어떻게 들었을까 신경 쓰임',
          },
        ],
      },
      {
        which: 'TF',
        question: `슬픈 노래를 슬프게 부르고 있는 친구!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '뭐야 노래 부르다 우는 거 아니지?',
          },
          {
            type: 'F',
            score: 5,
            content: '뭔 일 있어..? 완전 감정 가득인데..?',
          },
        ],
      },
      {
        which: 'JP',
        question: '노래방이 끝나고 다음 일정은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `다음은 저녁 식당은 여기로! 이동!`,
          },
          {
            type: 'P',
            score: 5,
            content: `그건 이제부터 생각해 봐야지`,
          },
        ],
      },
      {
        which: 'JP',
        question: '다음 장소로 이동 중 맛있어 보이는 식당 발견!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '어딘지 메모, 다음에 와 봐야겠다',
          },
          {
            type: 'P',
            score: 5,
            content: '우리 여기서 저녁 먹고 갈까?',
          },
        ],
      },
      {
        which: 'JP',
        question: '모임이 끝나고 집에 돌아왔다! 피곤한 상태에서 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '바로 환복하고 씻고 뒷정리한 뒤에 휴식',
          },
          {
            type: 'P',
            score: 5,
            content: '옷 벗어던진 다음 정리고 뭐고 일단 휴식',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/myHeart18/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/myHeart18/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/myHeart18/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/myHeart18/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/myHeart18/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/myHeart18/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/myHeart18/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/myHeart18/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/myHeart18/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/myHeart18/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/myHeart18/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/myHeart18/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/myHeart18/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/myHeart18/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/myHeart18/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/myHeart18/INFP.jpg',
      },
    ],
  },
  // warmCoolColorcode
  {
    info: {
      mainTitle: '웜쿨 컬러코드 성격 테스트',
      subTitle: '웜쿨 컬러코드로 보는 나의 성격은?',
      mainUrl: 'warmCoolColorcode',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/warmCoolColorcode-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/warmCoolColorcode-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '나의 표현 방식은 어떨까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '적극적인 태도로 바로 표현',
          },
          {
            type: 'I',
            score: 5,
            content: '신중하게 생각한 후에 표현',
          },
        ],
      },
      {
        which: 'EI',
        question: '나에게 주말이란..?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '약속을 채워야 하는 시간',
          },
          {
            type: 'I',
            score: 5,
            content: '약속을 비워야 하는 시간',
          },
        ],
      },
      {
        which: 'EI',
        question: '나에게 더 에너지가 충전되는 상황은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `밖에서 사람들을 보면 충전됨`,
          },
          {
            type: 'I',
            score: 5,
            content: `안에서 핸드폰을 보면 충전됨`,
          },
        ],
      },
      {
        which: 'SN',
        question: '길을 가는데 갑자기 펑펑 눈이 온다 나의 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `와 눈 온다…!`,
          },
          {
            type: 'N',
            score: 5,
            content: `솜털 같다 or 마음이 싱숭생숭하다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '노래를 들을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '멜로디>가사',
          },
          {
            type: 'N',
            score: 5,
            content: `멜로디<가사`,
          },
        ],
      },
      {
        which: 'SN',
        question: '‘관심 있는 영화나 드라마를 볼 때 내가 더 집중하는 곳은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `주인공 등 인물의 연기와 행동, 감정`,
          },
          {
            type: 'N',
            score: 5,
            content: `전체의 전반적인 스토리 전개와 흐름, 시대적 배경`,
          },
        ],
      },
      {
        which: 'TF',
        question: `확신의 쿨톤이라 생각하며 살았는데 친구가 ‘너 웜톤인 거 같은데?’라고 말하면 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '그런가?!..',
          },
          {
            type: 'F',
            score: 5,
            content: '조금 기분 상함..',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '인간관계 문제로 힘들어하는 친구가 나에게 고민을 털어놓을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `친구의 감정에 공감하며 위로한다`,
          },
          {
            type: 'F',
            score: 5,
            content: '어떻게 하면 좋을지 최대한 해결책을 내준다',
          },
        ],
      },
      {
        which: 'TF',
        question: `‘친구가 ‘나 뭐 잘못 먹어서 피부 완전 뒤집어졌어ᅲᅲ’라고 할 때 나의 반응은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '뭐 잘못 먹었는데??',
          },
          {
            type: 'F',
            score: 5,
            content: '헐..!!! 병원은 가봤어?ㅠㅠ',
          },
        ],
      },
      {
        which: 'JP',
        question: '생각했던 대로 일이 안 풀릴 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `짜증나고 스트레스 받는다`,
          },
          {
            type: 'P',
            score: 5,
            content: `이 또한 지나가리다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '나는 방 청소를 할 때 어떤 편인가?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '지저분함이 눈에 보일 때마다 청소한다',
          },
          {
            type: 'P',
            score: 5,
            content: '미루고 날 잡아서 싹~ 한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '나는 일을 할 때 어떤 편인가?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '결과 중시',
          },
          {
            type: 'P',
            score: 5,
            content: '과정 중시',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/warmCoolColorcode/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/warmCoolColorcode/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/warmCoolColorcode/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/warmCoolColorcode/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/warmCoolColorcode/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/warmCoolColorcode/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/warmCoolColorcode/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/warmCoolColorcode/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/warmCoolColorcode/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/warmCoolColorcode/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/warmCoolColorcode/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/warmCoolColorcode/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/warmCoolColorcode/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/warmCoolColorcode/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/warmCoolColorcode/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/warmCoolColorcode/INFP.jpg',
      },
    ],
  },
  // findHiddenChar
  {
    info: {
      mainTitle: '숨은 성격 찾기 테스트',
      subTitle: '숨어 있는 내 성격을 찾아보자',
      mainUrl: 'findHiddenChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/findHiddenChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/findHiddenChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '나가려고 했을 때 앞 집 도어록 소리가 났을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '앞집 사람도 나왔네 인사해야지 생각하고 문을 연다.',
          },
          {
            type: 'I',
            score: 5,
            content: '마주치면 뻘쭘할 것 같으니 기다렸다 나간다.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '엘리베이터 안에서 옆집에 사는 말 많은 아줌마가 말을 건넸을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친한 친구에게 말을 하듯 웃으며 스몰토크를 나눈다.',
          },
          {
            type: 'I',
            score: 5,
            content: '어색한 미소를 지으며 인사를 건넨 후 핸드폰을 본다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '내성향 친구와 놀 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `집 밖으로 나오게 끔 한다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `가끔 안부를 주고받으며 각자 집에서 논다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '물 웅덩이를 발견하였을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `피해서 가야지 잘못 밟으면 옷에 확 튀겠다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `이거 잘못 밟으면 다칠 것 같은데? 병원비 나오겠다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '공방을 가기 전 친구와 얘기를 나눌 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '해본 적이 없는 건데 가보면 재미 있는지 알겠지.',
          },
          {
            type: 'N',
            score: 5,
            content: `여기 이런 점이 재미있을 것 같지 않아?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '공방 약속 하루 전 잠자리에 들 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `늦잠 자면 안되니까 일찍 자야지`,
          },
          {
            type: 'N',
            score: 5,
            content: `늦잠 자서 예약 시간에 늦지 않겠지? 늦어도 체험 진행은 가능할까?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 냉정하게 잘못된 점을 지적했을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '말해줘서 고마워 근데 이게 왜 잘못된 부분인데?',
          },
          {
            type: 'F',
            score: 5,
            content: '고마워 역시 너 밖에 없어 (서운한 마음이 든다)',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구 집에 불이 났다는 연락을 받았을 때 당신은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `헐 어쩌다가 불이 났어 집주인한테는 연락해 봤어?`,
          },
          {
            type: 'F',
            score: 5,
            content: '너 안 다쳤어? 이게 무슨 일이야 병원은 안 가도 돼?',
          },
        ],
      },
      {
        which: 'TF',
        question: `제일 친한 친구가 다른 친구와 싸웠다고 했을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '엥 무슨 일이야? 왜 싸웠어',
          },
          {
            type: 'F',
            score: 5,
            content: '너 기분은 괜찮아?',
          },
        ],
      },
      {
        which: 'JP',
        question: '약속 일정에 변화가 생겼을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `미리 계획했던 방식에 따라 대응한다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `상황에 따라 즉흥적으로 행동한다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '중요한 일을 시작할 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '계획을 세우고 차근차근 하나씩 진행한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '대략적으로 틀을 잡아 둔 후 촉박해지면 빠르게 진행한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '책상정리를 할 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '깔끔하게 물건들을 정돈해 둔다.',
          },
          {
            type: 'P',
            score: 5,
            content: '대충 정리한 후 다른 일을 한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/findHiddenChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/findHiddenChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/findHiddenChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/findHiddenChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/findHiddenChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/findHiddenChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/findHiddenChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/findHiddenChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/findHiddenChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/findHiddenChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/findHiddenChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/findHiddenChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/findHiddenChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/findHiddenChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/findHiddenChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/findHiddenChar/INFP.jpg',
      },
    ],
  },
  // wishPlease
  {
    info: {
      mainTitle: '이루어지게 해주세용 테스트',
      subTitle: '2024년 새해를 맞아 변했으면 하는 부분은?',
      mainUrl: 'wishPlease',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/wishPlease-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/wishPlease-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '나의 새해 인사를 하는 스타일은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '진심을 가득 담아 먼저 새해 인사를 보내는 스타일',
          },
          {
            type: 'I',
            score: 5,
            content: '새해 인사가 오면 진심을 가득 담아 답장을 보내는 스타일',
          },
        ],
      },
      {
        which: 'EI',
        question: '할머니 댁에 도착해서 친척들과의 만남에 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '반갑게 인사를 건네며 먼저 안부를 묻는다.',
          },
          {
            type: 'I',
            score: 5,
            content:
              '가족들이 인사하면 그제야 쭈뼛쭈뼛 인사하고 방으로 들어가 버린다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '친척들과 앉아서 담소를 나눌 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `이야기하는 게 즐겁고 빨리 나의 이야기를 하고 싶어서 타이밍 보고 있다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `이야기하는 건 좋은데 그냥 방에 들어가서 핸드폰 보고 싶어서 타이밍 보고 있다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '고속도로에서 과속하는 차량을 보았다. 이때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `와.. 진짜 사고 나겠다. 언제 도착하지?`,
          },
          {
            type: 'N',
            score: 5,
            content: `차량이 방향을 잃고 큰 사고가 나서 나도 박고 구급차 오고 뉴스에 인터뷰하고 있는 거 아냐?!`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '휴게소에 화장실 줄이 길어서 대기해야 하는 상황이다. 이때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '10분 이상은 기다려야겠는데..?!',
          },
          {
            type: 'N',
            score: 5,
            content: `이러다가 바지에 싸서 말리는 거 아닌지 몰라..`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '휴게소를 나와 고속도로이다. 차가 생각보다 많이 막힌다. 이때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `누군가 교통정리를 빨리해주었으면 좋겠다고 생각한다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `순간 이동 능력이나 막강한 스피드 능력을 갖게 되었으면 좋겠다고 생각한다.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친척 동생이 나의 물건을 가지고 놀다가 망가뜨렸다. 이때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜 이렇게 되었는지에 대해서 납득을 받고 싶다.',
          },
          {
            type: 'F',
            score: 5,
            content: '진심이 가득 담긴 사과를 받고 싶다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '친척이 흥미 없고 이상한 이야기를 하는 자리에서 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `“아 그랬군요.. 아~ 힘내세요!”라고 영혼 없는 리액션을 한다.`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '“그래서 이렇게 되셨다는 말씀이신 거죠?”라고 공감보단 질문을 한다.',
          },
        ],
      },
      {
        which: 'TF',
        question: `다시 집으로 돌아가는 길에도 차가 막힐 것을 생각하니 막막하다고 이야기할 때 그것을 말하는 이유는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '그렇다는 사실을 알려주기 위해 이야기를 한다.',
          },
          {
            type: 'F',
            score: 5,
            content: '조금은 걱정을 해주었으면 하는 마음에 이야기를 한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '새해 계획이 세웠냐고 물었냐는 친구의 질문에 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `열심히 산 것 같으면서도 지키지 못한 게 생각나며 조금 아쉽다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `새해다~~~ 아무 생각 없이 신난다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '할머니 댁에 어떻게 갈 것인지에 대해 이야기를 할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '여기 구간이 차가 많이 막히니 그 구간을 지나서 고속도로를 타는 것이 좋을 것 같다는 계획을 짠다.',
          },
          {
            type: 'P',
            score: 5,
            content: '휴게소에서 먹을 간식이 어떤 것이 있는지 생각한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '명절 선물을 준비하려는 나 이때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '언제까지 받을 수 있는지까지 확인 후 구매한다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '베스트셀러 제품들 위주로 보고 가격대 괜찮은 것을 찾아 구매한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/wishPlease/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/wishPlease/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/wishPlease/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/wishPlease/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/wishPlease/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/wishPlease/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/wishPlease/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/wishPlease/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/wishPlease/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/wishPlease/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/wishPlease/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/wishPlease/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/wishPlease/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/wishPlease/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/wishPlease/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/wishPlease/INFP.jpg',
      },
    ],
  },
  // thirtyChallenge
  {
    info: {
      mainTitle: '30가지 챌린지 테스트',
      subTitle: '새로운 마음으로 시작하는 30가지 챌린지에 도전해 보자!',
      mainUrl: 'thirtyChallenge',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/thirtyChallenge-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/thirtyChallenge-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '서른 가지 챌린지에 도전해 보려고 한다! 나는 어떨까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '주변에 알려서 같이 할 사람을 찾음',
          },
          {
            type: 'I',
            score: 5,
            content: '아무도 모르게 비밀리에 도전함',
          },
        ],
      },
      {
        which: 'EI',
        question: '내가 이루기 어려운 챌린지는 무엇일까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '일주일 내내 집 밖으로 안 나가기',
          },
          {
            type: 'I',
            score: 5,
            content: '일주일 내내 약속 잡고 밖으로 나가기',
          },
        ],
      },
      {
        which: 'EI',
        question: '나는 어떤 방식을 더 선호할까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `선 경험 후 이해`,
          },
          {
            type: 'I',
            score: 5,
            content: `선 이해 후 경험`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 앞으로 진행할 챌린지를 선택한다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `현실적으로 이룰 수 있는 챌린지`,
          },
          {
            type: 'N',
            score: 5,
            content: `왠지 이룰 수 있을 것 같은 느낌이 드는 챌린지`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 의존하는 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '경험을 중시한 오감',
          },
          {
            type: 'N',
            score: 5,
            content: `의미를 추구한 육감과 영감`,
          },
        ],
      },
      {
        which: 'SN',
        question: '서른 가지 중 첫 챌린지를 시작하려고 할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `열심히 노력해 봐야겠다고 생각함`,
          },
          {
            type: 'N',
            score: 5,
            content: `벌써 다 이루고 뿌듯한 내 모습이 상상됨`,
          },
        ],
      },
      {
        which: 'TF',
        question: `챌린지에 도전할 때 내게 더 의미 있는 것은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '도전 후 나타나는 결과',
          },
          {
            type: 'F',
            score: 5,
            content: '도전을 하는 과정',
          },
        ],
      },
      {
        which: 'TF',
        question: '무언가에 대한 상황을 판단할 때 내 기준은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `맞다 틀리다의 기준으로 판단`,
          },
          {
            type: 'F',
            score: 5,
            content: '좋다 나쁘다의 기준으로 판단',
          },
        ],
      },
      {
        which: 'TF',
        question: `챌린지에 실패했다고 자책하는 친구에게 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '좀 더 열심히 해보지 그랬어ㅜ',
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮아!! 실패는 성공의 어머니랬어 자책하지 마~',
          },
        ],
      },
      {
        which: 'JP',
        question: '서른 가지의 챌린지 목표를 세웠다! 이후에 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `세운 목표는 바꾸지 않고 지키기 위해 노력함`,
          },
          {
            type: 'P',
            score: 5,
            content: `상황에 따라 중간중간 수정하면서 목표를 바꿈`,
          },
        ],
      },
      {
        which: 'JP',
        question: '오늘의 챌린지를 지키지 못했다..!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '스트레스 받아… 계속 신경 쓰임',
          },
          {
            type: 'P',
            score: 5,
            content: '하루쯤은 괜찮지~ 크게 신경 안 쓰임',
          },
        ],
      },
      {
        which: 'JP',
        question: '같이 챌린지를 하고 있는 친구가 포기하려고 한다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '뭐야.. 안돼..! 같이 지키기로 했잖아',
          },
          {
            type: 'P',
            score: 5,
            content: '그럼 나도 그냥 하지 말까..?',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/thirtyChallenge/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/thirtyChallenge/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/thirtyChallenge/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/thirtyChallenge/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/thirtyChallenge/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/thirtyChallenge/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/thirtyChallenge/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/thirtyChallenge/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/thirtyChallenge/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/thirtyChallenge/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/thirtyChallenge/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/thirtyChallenge/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/thirtyChallenge/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/thirtyChallenge/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/thirtyChallenge/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/thirtyChallenge/INFP.jpg',
      },
    ],
  },
  // rememberMe
  {
    info: {
      mainTitle: '나를 기억해줘 (Remember Me) 테스트',
      subTitle: '나는 이런 사람이야 리멤버 미!',
      mainUrl: 'rememberMe',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/rememberMe-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/rememberMe-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '내가 해봤던 상상과 더 가까운 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '마음속으로는 놀던 친구들 다 데리고 집에 가서 쭉 놀고 싶다는 상상',
          },
          {
            type: 'I',
            score: 5,
            content:
              '한 달이건 두 달이건 아무도 안 찾는 곳에서 혼자 쉬고 싶다는 상상',
          },
        ],
      },
      {
        which: 'EI',
        question: '나는 따로 약속 잡고 만나는 친구가',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '다섯 명 이상이다',
          },
          {
            type: 'I',
            score: 5,
            content: '다섯 명 이하이다',
          },
        ],
      },
      {
        which: 'EI',
        question: '나는 친구들 중에 어떤 사람인가?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `대다수의 친구들의 소식을 알고 있다`,
          },
          {
            type: 'I',
            score: 5,
            content: `대다수의 친구들의 소식을 마지막으로 듣는다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '나에게 ‘생각’이란?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `필요시 하는 것`,
          },
          {
            type: 'N',
            score: 5,
            content: `끝이 없는 것`,
          },
        ],
      },
      {
        which: 'SN',
        question: '문제를 결론지을 때 나는 주로 어떻게 결론짓는가?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '문제에 대한 결론만 짓는다',
          },
          {
            type: 'N',
            score: 5,
            content: `다른 추후적인 상황에 대해서도 생각해 본다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '‘나를 더 잘 표현한 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `현실적임, 똑부러짐`,
          },
          {
            type: 'N',
            score: 5,
            content: `무궁무진, 미래지향`,
          },
        ],
      },
      {
        which: 'TF',
        question: `나에 사소한 부분까지 기억해 준 친구 나의 반응은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '이것도 기억하네?! 대박이다',
          },
          {
            type: 'F',
            score: 5,
            content: '이런 것까지 기억해 주다니 너무 감동이야ㅠㅠ',
          },
        ],
      },
      {
        which: 'TF',
        question: '누군가에게 조언을 할 때 가장 우선순위는 무엇인가?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `가능한 중립적으로 조언한다`,
          },
          {
            type: 'F',
            score: 5,
            content: '상대의 상황을 고려하여 조언한다',
          },
        ],
      },
      {
        which: 'TF',
        question: `‘예외’는 규칙과는 상관없다’에 대한 나의 생각은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '맞다',
          },
          {
            type: 'F',
            score: 5,
            content: '아니다',
          },
        ],
      },
      {
        which: 'JP',
        question: '나의 사고와 더 잘 맞는 것은 무엇인가?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `경직된 사고`,
          },
          {
            type: 'P',
            score: 5,
            content: `유연한 사고`,
          },
        ],
      },
      {
        which: 'JP',
        question: '무언가를 할 때 나는 주로 어떻게 하는 스타일인가?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '정해진 재로 하는 스타일',
          },
          {
            type: 'P',
            score: 5,
            content: '상황을 보면서 하는 스타일',
          },
        ],
      },
      {
        which: 'JP',
        question: '다음날 약속이 있을 때 잠들기 전 내가 하는 생각은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '8시쯤 일어나서 밥 먹고 9시엔 씻고 10시쯤엔 나가야겠다',
          },
          {
            type: 'P',
            score: 5,
            content: '지금 자면 몇 시까지 잘 수 있지..',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/rememberMe/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/rememberMe/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/rememberMe/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/rememberMe/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/rememberMe/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/rememberMe/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/rememberMe/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/rememberMe/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/rememberMe/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/rememberMe/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/rememberMe/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/rememberMe/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/rememberMe/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/rememberMe/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/rememberMe/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/rememberMe/INFP.jpg',
      },
    ],
  },
  // worksheetColor
  {
    info: {
      mainTitle: '워크시트 색칠 테스트',
      subTitle: '워크시트로 자신의 성격을 색칠해보자',
      mainUrl: 'worksheetColor',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/worksheetColor-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/worksheetColor-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '친구가 갑자기 인원을 늘려서 놀자고 할 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '재밌을 것 같은데? 정말 좋아',
          },
          {
            type: 'I',
            score: 5,
            content: '사람 많은 거 견디기 힘들어',
          },
        ],
      },
      {
        which: 'EI',
        question: '모임이 끝난 후 다 같이 식사를 하자는 분위기 일 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '좋아요~ 맛있는 음식 먹으러 가요! ',
          },
          {
            type: 'I',
            score: 5,
            content: '피곤해서 먼저 집에 갈게요. (기 빨린지 오래다)',
          },
        ],
      },
      {
        which: 'EI',
        question: '취미 활동을 알아볼 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `많은 사람들과 같이 할 수 있는 취미 활동`,
          },
          {
            type: 'I',
            score: 5,
            content: `조용하게 혼자서도 가능한 취미활동`,
          },
        ],
      },
      {
        which: 'SN',
        question: '메시지를 입력 중인 표시 봤을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `메시지를 입력하고 있구나`,
          },
          {
            type: 'N',
            score: 5,
            content: `무슨 내용을 쓰고 있는 거지? 만나자는 약속? 안부?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '처음 들어보는 단어의 설명을 들을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '아~ 그렇구나',
          },
          {
            type: 'N',
            score: 5,
            content: `오 신기하네~ (비슷한 단어가 떠올라 혼자 상상 중임)`,
          },
        ],
      },
      {
        which: 'SN',
        question: '만약 지구에 혼자 남는다고 가정했을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `그럴 일은 일어날 리 없는데 굳이 생각해야 하나?`,
          },
          {
            type: 'N',
            score: 5,
            content: `진짜 혼자 남은 것 마냥 모든 상황을 상상해 본다.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 마주치고도 인사를 하지 않고 지나갈 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '바쁜 일이 있나 보네 (속상하지만 금방 잊어버림) ',
          },
          {
            type: 'F',
            score: 5,
            content:
              '분명 마주쳤는데 뭐지? 서운하네 (서운하지만 친구에게 말은 못함)',
          },
        ],
      },
      {
        which: 'TF',
        question: '갑작스럽게 약속 당일에 친구와 연락이 안 될 때 당신은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `왜 연락을 안 받지? 연락되면 한 소리 해야지`,
          },
          {
            type: 'F',
            score: 5,
            content: '무슨 일이지? 걱정되네',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 핸드폰을 떨어뜨려 액정이 깨졌다고 했을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '어디서 떨어뜨렸길래 액정이 깨져? ',
          },
          {
            type: 'F',
            score: 5,
            content: '헐 수리비 어떡해 많이 비싸지 않아?',
          },
        ],
      },
      {
        which: 'JP',
        question: '마트를 갔을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `미리 적어 둔 리스트대로 구매한다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `당장 필요한 물품만 산다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '하루 일과를 마무리하고 집에 들어왔을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '미리 계획했던 일정대로 일을 하거나 휴식을 취한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '생각나는 일을 하거나 휴식을 취한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '갑자기 가고 싶은 장소가 생겼을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '일정을 확인한 후 비어 있는 시간이 있으면 간다.',
          },
          {
            type: 'P',
            score: 5,
            content: '즉흥적으로 가고 싶은 장소를 향해 움직인다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/worksheetColor/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/worksheetColor/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/worksheetColor/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/worksheetColor/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/worksheetColor/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/worksheetColor/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/worksheetColor/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/worksheetColor/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/worksheetColor/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/worksheetColor/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/worksheetColor/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/worksheetColor/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/worksheetColor/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/worksheetColor/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/worksheetColor/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/worksheetColor/INFP.jpg',
      },
    ],
  },
  // brainChar
  {
    info: {
      mainTitle: '브레인 성격 테스트',
      subTitle: '좌뇌 우뇌로 보는 내가 좋아하는 것 과 싫어하는 것은?',
      mainUrl: 'brainChar',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/brainChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/brainChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '어떠한 문제가 생겼을 때 나의 모습은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '주변 사람에게 털어놓고 의견을 들으며 문제를 해결한다',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자서 문제를 해결하거나 생각을 정리한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '나와 더 가까운 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '적극적 ‘행동’',
          },
          {
            type: 'I',
            score: 5,
            content: '적극적 ‘사색’',
          },
        ],
      },
      {
        which: 'EI',
        question: '경험과 이해를 할 때 나는 주로 어떤 순서인가?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `경험한 다음에 이해`,
          },
          {
            type: 'I',
            score: 5,
            content: `이해한 다음에 경험`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 더 추구하는 삶은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `안정적인 삶`,
          },
          {
            type: 'N',
            score: 5,
            content: `변화하는 삶`,
          },
        ],
      },
      {
        which: 'SN',
        question: '미스터리 범죄 스릴러물을 볼 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '사건의 표면적인 특징과 세부 사항을 잘 기억한다',
          },
          {
            type: 'N',
            score: 5,
            content: `사건의 내재된 의미와 관련성을 생각하며 본다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '‘브레인’ 하면 떠오르는 단어는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `두뇌,좌뇌,우뇌`,
          },
          {
            type: 'N',
            score: 5,
            content: `똑똑함,징그러운 모양`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 약속시간에 늦어서 화가 난 상황에 얼굴 보자마자 사과부터 한다 나의 생각은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '빨리 상황 종료하려고 사과부터 하는건가 ㅡㅡ (화가 더 남)',
          },
          {
            type: 'F',
            score: 5,
            content: '진짜 미안한가보네..ㅠㅠ(화가 풀림)',
          },
        ],
      },
      {
        which: 'TF',
        question: '나는 사람들과 대화할 때 어떤 편인가?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `팩트로 결론만 말하는 편`,
          },
          {
            type: 'F',
            score: 5,
            content: '과정을 설명하고 돌려서 말하는 편',
          },
        ],
      },
      {
        which: 'TF',
        question: `내가 ‘생각 좀 해보고 말해줄게’라고 말할 때 뜻이 더 가까운 것은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '진짜 생각 좀 해본다는 뜻',
          },
          {
            type: 'F',
            score: 5,
            content: '거절의 의미..',
          },
        ],
      },
      {
        which: 'JP',
        question: '구체적인 계획표를 볼 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `구체적일수록 오히려 좋다`,
          },
          {
            type: 'P',
            score: 5,
            content: `구체적일수록 질린다..`,
          },
        ],
      },
      {
        which: 'JP',
        question: '여행 계획을 짜고 나서 내가 하는 일은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '혹시 모르니 플랜 B도 준비한다',
          },
          {
            type: 'P',
            score: 5,
            content: '가는 날만 기다린다',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '친구들과 이야기하다 너네랑 여행 가면 너무 좋겠다! 라고 말할 때 나의 의미는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '여행 한번 가자는 뜻!',
          },
          {
            type: 'P',
            score: 5,
            content: '그러면 좋겠다는 거지 가자는 의미는 아님',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/brainChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/brainChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/brainChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/brainChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/brainChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/brainChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/brainChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/brainChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/brainChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/brainChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/brainChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/brainChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/brainChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/brainChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/brainChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/brainChar/INFP.jpg',
      },
    ],
  },
  // heartCry
  {
    info: {
      mainTitle: '마음을 울리는 말 테스트',
      subTitle: '나의 마음을 울리는 말들이 궁금하다면?',
      mainUrl: 'heartCry',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/heartCry-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/heartCry-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          '수업 시간 선생님이 나를 지목하며 발표해 보라고 했을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '자연스럽게 소통하며 나의 의견을 자신 있게 이야기하는 나',
          },
          {
            type: 'I',
            score: 5,
            content: '큰 결심을 하고 나의 의견을 자신 있게 대답하는 나',
          },
        ],
      },
      {
        which: 'EI',
        question: '집에 이틀 동안 있을 때 나의 행동은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '힘들고 빨리 나가서 친구를 만나던 연락을 돌리던 약속 잡을 생각한다.',
          },
          {
            type: 'I',
            score: 5,
            content:
              '그저 평온하고 이따가 배달 앱으로 어떤 것을 먹을지 생각한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '상대방에게 끌리는 이유를 고르자면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `나의 이야기를 잘 들어주고 첫인상과 다른 모습을 발견하는 것`,
          },
          {
            type: 'I',
            score: 5,
            content: `굳이 말을 많이 하지 않아도 되고 기분 좋게 만드는데 능숙한 대화 매너`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '“게시글 좋아요랑 조회 수가 물에 빠졌다고 가정하면 너 어쩔 거야?”라고 했을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `그게.. 빠질 수가 있나? 있어?`,
          },
          {
            type: 'N',
            score: 5,
            content: `음.. 그게 둘 다 어쩌면 중요한 부분인데 어떡하지.. (고민 중..)`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '신데렐라 같은 전형적인 클리셰 드라마를 보고 있다. 이때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '저거 현실 고증 하나도 안 되어 있는 듯;',
          },
          {
            type: 'N',
            score: 5,
            content: `내가 여주&남주였다면 !@#$%^해서 !@#$%해서 우연을 가장한 만남을 그렸을 듯!`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 올린 글이 조회 수가 좋지 않을 때 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `주제가 별로였나? 아니면 내용의 글이 조금 부족했나? 조금 더 이해하기 쉽게 썼어야 했을까?`,
          },
          {
            type: 'N',
            score: 5,
            content: `이대로라면 나는 불려가서 한소리 듣고 좌천당해서 다른 부서에서 새로운 일을 하다가 끝내 적응하지 못해서 길거리에 나앉게 되겠지?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `피드백을 수용할 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '논리적이고 사실에 근거한 피드백으로 수정해 본다.',
          },
          {
            type: 'F',
            score: 5,
            content:
              '나의 생각은 이런데 다른 사람들의 의견은 어떨지 함께 이야기를 하며 협의점을 찾아 수정해 본다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '새로운 프로젝트를 진행할 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `방향성과 계획, 그리고 효율성을 따져본다.`,
          },
          {
            type: 'F',
            score: 5,
            content: '팀원들의 동기부여와 화합을 고려해 본다.',
          },
        ],
      },
      {
        which: 'TF',
        question: `내가 더 선호하는 대화 스타일은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '정확하고 논리적인 언어로 하는 대화',
          },
          {
            type: 'F',
            score: 5,
            content: '감정을 표현하고 대화를 통해 이해가 있는 대화',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 행동해야 하는 자세는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `내가 짠 계획보단 여유 있는 시간 배정하기`,
          },
          {
            type: 'P',
            score: 5,
            content: `의사 결정을 할 준비하기`,
          },
        ],
      },
      {
        which: 'JP',
        question: '집에 생필품을 살 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '떨어지기 전에 미리미리 구비해서 여유분을 구비해 둔다.',
          },
          {
            type: 'P',
            score: 5,
            content: '떨어졌을 때 부랴부랴 주문해서 구비해 둔다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 일 처리를 하는 방식은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '미리 다 해 놓고 여유로운 티타임을 즐겨본다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '티타임을 즐기며 이때쯤까지 마무리 지으면 할 수 있겠다고 생각한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/heartCry/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/heartCry/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/heartCry/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/heartCry/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/heartCry/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/heartCry/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/heartCry/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/heartCry/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/heartCry/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/heartCry/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/heartCry/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/heartCry/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/heartCry/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/heartCry/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/heartCry/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/heartCry/INFP.jpg',
      },
    ],
  },
  // letteringBeads
  {
    info: {
      mainTitle: '레터링 비즈 성격 테스트',
      subTitle: '비즈로 만들어보는 내 성격은?',
      mainUrl: 'letteringBeads',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/letteringBeads-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/letteringBeads-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '뭔가에 집중 해야할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구들과 함께 모여 대화하면서',
          },
          {
            type: 'I',
            score: 5,
            content: '조용한 장소에서 혼자 차분하게',
          },
        ],
      },
      {
        which: 'EI',
        question: '밖으로 나가 일정 시간이 지나면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '혈색이 점점 돌아옴',
          },
          {
            type: 'I',
            score: 5,
            content: '안색이 점점 안 좋아짐',
          },
        ],
      },
      {
        which: 'EI',
        question: '텐션이 높고 활발한 사람을 보면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `나랑 잘 맞을 것 같은데? 친해지고 싶다!!`,
          },
          {
            type: 'I',
            score: 5,
            content: `먼가 기 빨려.. 나랑은 안 맞을 듯`,
          },
        ],
      },
      {
        which: 'SN',
        question: '나는 일을 할 때 어떨까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `일정한 속도로 일한다`,
          },
          {
            type: 'N',
            score: 5,
            content: `순간적인 폭발 에너지로 일한다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '중요한 약속이 있는 전날 밤! 나는 어떨까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '일찍 일어나야지 하고 잠듦',
          },
          {
            type: 'N',
            score: 5,
            content: `늦게 일어나서 헐레벌떡 뛰고 있는 내가 상상되며 알람을 몇 번이고 확인함`,
          },
        ],
      },
      {
        which: 'SN',
        question: '무언가에 대한 의사 결정을 할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `과거의 경험에 따라 결정`,
          },
          {
            type: 'N',
            score: 5,
            content: `본능과 직감에 따라 결정`,
          },
        ],
      },
      {
        which: 'TF',
        question: `이 세상에 F들만 남는다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '이 세상 안 돌아감',
          },
          {
            type: 'F',
            score: 5,
            content: '따듯하고 정 넘치는 세상',
          },
        ],
      },
      {
        which: 'TF',
        question: '틀린 정보를 알려주고 있는 친구!',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `잘못된 정보라고 바로 말해줌`,
          },
          {
            type: 'F',
            score: 5,
            content: '잘못된 정보 같은데..라고 속으로만 생각함',
          },
        ],
      },
      {
        which: 'TF',
        question: `안 좋은 꿈을 꿔서 우울하다는 친구에게 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '무슨 꿈? 그냥 개꿈이네~',
          },
          {
            type: 'F',
            score: 5,
            content: '그랬어?ㅜ 괜찮아 꿈은 반대라더라 너무 신경 쓰지 마~',
          },
        ],
      },
      {
        which: 'JP',
        question: '길을 걷다가 분위기 좋은 식당을 발견했다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `따로 위치와 이름 등 메모 해놓거나 사진 찍어둠`,
          },
          {
            type: 'P',
            score: 5,
            content: `나중에 와야지 해놓고 어딘지 까먹음`,
          },
        ],
      },
      {
        which: 'JP',
        question: '내게 조금 더 편안함이 느껴지는 업무는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '체계적으로 해야 할 일이 딱딱 정해져 있는 업무',
          },
          {
            type: 'P',
            score: 5,
            content: '자유로운 분위기 속에서 자발적으로 처리하는 업무',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구 A와 B가C 때문에 다투었다고 한다! 내 반응은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '그건 C가 잘못했네',
          },
          {
            type: 'P',
            score: 5,
            content: 'A랑 B가 C 때문에 다퉜다고?',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/letteringBeads/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/letteringBeads/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/letteringBeads/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/letteringBeads/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/letteringBeads/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/letteringBeads/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/letteringBeads/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/letteringBeads/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/letteringBeads/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/letteringBeads/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/letteringBeads/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/letteringBeads/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/letteringBeads/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/letteringBeads/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/letteringBeads/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/letteringBeads/INFP.jpg',
      },
    ],
  },
  // notiLuck
  {
    info: {
      mainTitle: '알림톡 운세 테스트',
      subTitle: '알림톡 운세를 통해 성격을 알아보자!',
      mainUrl: 'notiLuck',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/notiLuck-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/notiLuck-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '잊고 있던 친구의 생일 알림톡을 받았을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '와 신기해 이걸 계기로 한번 연락해 봐야겠다.',
          },
          {
            type: 'I',
            score: 5,
            content: '이 친구는 잘 지내나? 연락해 볼까 생각하다가 이내 관둔다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '공유하기 좋은 운세 알림톡을 받았다! 이 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구에게 바로 공유한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자 조용히 보다 넘겨버린다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '오늘의 운세에 관련된 얘기를 나눌 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `주변 반응을 살피며 대화를 이끄는 편`,
          },
          {
            type: 'I',
            score: 5,
            content: `고개를 끄덕이거나 리액션을 하며 듣는 편`,
          },
        ],
      },
      {
        which: 'SN',
        question: '시간제한이 걸려있는 알림톡을 늦게 발견하였을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `내용 못 본 게 아쉽지만 어쩔 수 없지`,
          },
          {
            type: 'N',
            score: 5,
            content: `무슨 내용이었을까? 운세? 중요한 내용이었을 거 같은데!`,
          },
        ],
      },
      {
        which: 'SN',
        question: '지구 멸망일에 관련된 알림톡을 받았을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '지구 멸망일? 말이 안된다고 생각함',
          },
          {
            type: 'N',
            score: 5,
            content: `이게 진짜일까? 나만 받은게 아닐까?  등 다양한 상상을 함`,
          },
        ],
      },
      {
        which: 'SN',
        question: '사건를 주제로 한 알림톡을 구독하였을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `실제로 일어난 사건`,
          },
          {
            type: 'N',
            score: 5,
            content: `미스터리 사건`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 알림톡을 공유 받고 기분이 나쁘다 했을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '이게 왜 기분이 나쁜 거야? (순수하게 이유가 궁금함)',
          },
          {
            type: 'F',
            score: 5,
            content: '미안해 기분이 나쁠 거라고 생각 못 했어',
          },
        ],
      },
      {
        which: 'TF',
        question: '알림톡 문의 답변이 늦게 왔을 때 당신은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `늦게 답변하게 된 이유를 설명해 줬으면 좋겠음`,
          },
          {
            type: 'F',
            score: 5,
            content: '늦은 답변이 실망스럽지만 직원을 이해하려 노력함',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구의 알림톡 설문 조사를 받았을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '자세하게 문제점을 작성한다.',
          },
          {
            type: 'F',
            score: 5,
            content: '기분 나쁘지 않게 돌려서 작성한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '세일 날짜의 안내 알람톡을 받았을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `까먹을 수 있으니 캘린더에 적어 둔다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `대강 머릿속에 기억했다가 까먹는다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '세일이 시작되지 않았을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '기다리는 시간이 아깝다고 생각한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '시작했다는 사실조차 까먹고 있다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '약속 일정을 공유하는 알람톡을 보낼 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '일정을 빠르게 정한 후 미리 친구에게 보내 둔다.',
          },
          {
            type: 'P',
            score: 5,
            content: '나중에 보내지 뭐 생각하다가 전날 밤에 급하게 보낸다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/notiLuck/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/notiLuck/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/notiLuck/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/notiLuck/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/notiLuck/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/notiLuck/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/notiLuck/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/notiLuck/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/notiLuck/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/notiLuck/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/notiLuck/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/notiLuck/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/notiLuck/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/notiLuck/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/notiLuck/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/notiLuck/INFP.jpg',
      },
    ],
  },
  // leaflet
  {
    info: {
      mainTitle: '전단지 성격 테스트',
      subTitle: '전단지에 적혀 있는 성격을 알아보자!',
      mainUrl: 'leaflet',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/leaflet-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/leaflet-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '약속이 이틀 연속 잡혔을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '벌써부터 기대감에 행복함',
          },
          {
            type: 'I',
            score: 5,
            content: '피곤할 것 같다고 생각함',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구들 사이에서 처음 보는 사람을 보게 되었을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '빠르게 반가운 인사와 함께 자기소개를 먼저 한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '친구가 대신 다른 사람에게 소개를 시켜준다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구의 소식을 알게 되었을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `대부분의 친구들 소식은 이미 파악하고 있다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `맨 마지막으로 친구의 소식을 접한다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '기간 안에 일을 하지 못했을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `수행하지 못한 자신을 자책함`,
          },
          {
            type: 'N',
            score: 5,
            content: `왜 나는 기간안에 못 끝냈지 어떤 불이익이 생기게 될까 등 다양한 상상을 함`,
          },
        ],
      },
      {
        which: 'SN',
        question: '급하게 일을 해야 할 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '하기 싫다 빨리 해야지 등',
          },
          {
            type: 'N',
            score: 5,
            content: `안 하게 되면 어떻게 될까? 이걸 왜 해야 되지? 등`,
          },
        ],
      },
      {
        which: 'SN',
        question: '롤러코스터를 탈 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `와 너무 재미있겠다. 등 눈앞의 감정을 생각함`,
          },
          {
            type: 'N',
            score: 5,
            content: `고장 나서 멈추면 어떡하지? 등 일어나지 않는 일을 상상함`,
          },
        ],
      },
      {
        which: 'TF',
        question: `어떤 사람이 되고 싶은지 물어보았을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '거짓 없고 진실한 사람',
          },
          {
            type: 'F',
            score: 5,
            content: '호감을 주는 사람',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 감정표현을 하지 않을 때 당신은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `감정을 알아차리는 것은 어렵다.`,
          },
          {
            type: 'F',
            score: 5,
            content: '쉽게 감정을 이해할 수 있다.',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 중요한 시험에서 떨어졌을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '무슨 시험인데? 다음에 붙으면 되지',
          },
          {
            type: 'F',
            score: 5,
            content: '열심히 준비했는데 많이 속상하지.. 괜찮아?',
          },
        ],
      },
      {
        which: 'JP',
        question: '혼자만의 자유시간이 생겼을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `계획을 세우고 그게 맞춰 움직이는 편`,
          },
          {
            type: 'P',
            score: 5,
            content: `가만히 쉬다가 생각나는 일을 하는 편`,
          },
        ],
      },
      {
        which: 'JP',
        question: '일을 처리할 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '미리 자료를 정리하고 마감일 전에 완성을 함',
          },
          {
            type: 'P',
            score: 5,
            content: '마감 일이 다가왔을 때 부랴부랴 집중해서 일을 처리함',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구와 약속 전 식당을 정할 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '시간도 절약할 겸 미리 예약하고 가자',
          },
          {
            type: 'P',
            score: 5,
            content: '먹고 싶은 메뉴가 달라질 수도 있으니 그때 가서 정하자',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/leaflet/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/leaflet/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/leaflet/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/leaflet/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/leaflet/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/leaflet/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/leaflet/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/leaflet/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/leaflet/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/leaflet/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/leaflet/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/leaflet/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/leaflet/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/leaflet/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/leaflet/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/leaflet/INFP.jpg',
      },
    ],
  },
  // christmasSleighEng
  {
    info: {
      mainTitle: 'Christmas Sleigh Personality Test',
      subTitle: `Let's find out about love personality in Santa's sled`,
      mainUrl: 'christmasSleighEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/christmasSleighEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/christmasSleighEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          'What about you when you were offered a party on Christmas Day?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'A party? A lot of people will come, right? Good',
          },
          {
            type: 'I',
            score: 5,
            content: 'Want to spend time alone or with someone you love',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What if you were offered a toast in the crowd during a Christmas party?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I accept with confidence and make a toast',
          },
          {
            type: 'I',
            score: 5,
            content: "I don't think I can do it because I'm shy",
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What about you when you ask about your favorite Christmas vibe?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `A lively and exciting atmosphere`,
          },
          {
            type: 'I',
            score: 5,
            content: `A quiet, warm and calm atmosphere`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What if you saw a pile of gifts before the gift exchange ceremony?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I hope I get a good present`,
          },
          {
            type: 'N',
            score: 5,
            content: `I wonder who will receive my present`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What about you, assuming Santa is there?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: "In my opinion, it doesn't exist",
          },
          {
            type: 'N',
            score: 5,
            content: `Imagine the gift you want to receive`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'If a friend in the middle of the party,says they will go home and come back?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Did something urgent happen?`,
          },
          {
            type: 'N',
            score: 5,
            content: `Maybe he left a present?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What about you when you get a useless gift?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I jokingly thanked them for something really useless',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Say thank you happily without showing any signs of dislike',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What do you want to say to a friend who is late for an appointment?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Why were you late?`,
          },
          {
            type: 'F',
            score: 5,
            content: 'What happened?',
          },
        ],
      },
      {
        which: 'TF',
        question: `What if your friend doesn't know why you're solo?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I provide feedback',
          },
          {
            type: 'F',
            score: 5,
            content: 'I comfort them',
          },
        ],
      },
      {
        which: 'JP',
        question: 'When you prepare a Christmas present, what about you?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I research popular gifts and buy them in advance`,
          },
          {
            type: 'P',
            score: 5,
            content: `I just think about it and buy it in a hurry the day before`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What about you if your Christmas cake exceeds your set budget?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "I did the research in advance, so we don't go over the budget",
          },
          {
            type: 'P',
            score: 5,
            content: 'I just buy it thinking this is okay',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What about you when you plan your Christmas party schedule?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Calculate and plan up to minutes and prepare for possible variables',
          },
          {
            type: 'P',
            score: 5,
            content:
              "I'm going to take a rough look and pass it over thinking it'll work out somehow",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleighEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleighEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleighEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleighEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleighEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleighEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleighEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleighEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleighEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleighEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleighEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleighEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleighEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleighEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleighEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleighEng/INFP.jpg',
      },
    ],
  },
  // christmasSleigh
  {
    info: {
      mainTitle: '크리스마스 썰매 테스트',
      subTitle: '산타의 썰매에서 연애 성격을 알아보자',
      mainUrl: 'christmasSleigh',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/christmasSleigh-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/christmasSleigh-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '크리스마스 날 파티를 하자는 제안을 받았을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '파티라니 많은 사람이 오겠지? 좋아',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자 또는 사랑하는 사람과 시간을 보내고 싶어',
          },
        ],
      },
      {
        which: 'EI',
        question: '크리스마스 파티에서 건배사를 요청받았을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '관심을 즐기며 건배사를 외친다.',
          },
          {
            type: 'I',
            score: 5,
            content: '부끄러워서 못할 것 같다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '좋아하는 크리스마스 분위기를 물었을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `시끌벅적 활기차고 신나는 분위기`,
          },
          {
            type: 'I',
            score: 5,
            content: `조용하고 따듯하며 차분한 분위기`,
          },
        ],
      },
      {
        which: 'SN',
        question: '선물 증정식 전 쌓여있는 선물을 보았을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `좋은 선물을 받았으면 좋겠다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `내 선물은 누가 받게 될지 궁금하다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '만약 산타가 있다고 가정했을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '없다고 생각함',
          },
          {
            type: 'N',
            score: 5,
            content: `받고 싶은 선물을 상상함`,
          },
        ],
      },
      {
        which: 'SN',
        question: '급하게 집에 다녀온다는 친구의 말을 들었을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `급한 일이 생겼나?`,
          },
          {
            type: 'N',
            score: 5,
            content: `선물을 두고 온건 아닐까?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `쓸모없는 선물을 받았을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '장난식으로 정말 쓸모 없지만 고맙다는 말을 건넨다. ',
          },
          {
            type: 'F',
            score: 5,
            content:
              '싫은 티를 내면 상처받을 것을 고려해 기쁘게 고맙다는 말을 건넨다. ',
          },
        ],
      },
      {
        which: 'TF',
        question: '약속 시간에 늦은 친구에게 한마디를 건넬 때 당신은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `왜 늦었어?`,
          },
          {
            type: 'F',
            score: 5,
            content: '무슨 일 있었어?',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 자신은 왜 솔로인지 모르겠다고 할 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '피드백을 해준다.',
          },
          {
            type: 'F',
            score: 5,
            content: '위로를 해준다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '크리스마스 선물을 준비할 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `판매량이 높은 선물을 조사한 후 미리 구매해 둔다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `대략적으로 정해둔 후 크리스마스 전날에 부랴부랴 구매한다.`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          '크리스마스 케이크 금액이 정해놨던 예산을 초과했을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '미리 알아보고 왔기에 예산을 초과할 일이 없다.',
          },
          {
            type: 'P',
            score: 5,
            content: '이 정도는 괜찮지 않나 생각하고 그냥 구매한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '크리스마스 파티 일정을 계획하게 되었을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '분 단위까지 계산하여 계획한 후 혹시 모를 변수를 대비한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '대충 알아보다 어떻게든 되겠지 하고 넘긴다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleigh/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleigh/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleigh/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleigh/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleigh/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleigh/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleigh/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleigh/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleigh/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleigh/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleigh/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleigh/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleigh/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleigh/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleigh/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/christmasSleigh/INFP.jpg',
      },
    ],
  },
  // gradationChar
  {
    info: {
      mainTitle: '그라데이션 성격 테스트',
      subTitle: '그라데이션으로 표현한 나의 성격은?',
      mainUrl: 'gradationChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/gradationChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/gradationChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '나는 유행 밈, 짤 등을 잘 알고 있는 편인가?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '잘 알고 있다',
          },
          {
            type: 'I',
            score: 5,
            content: '잘 모르는 편이다',
          },
        ],
      },
      {
        which: 'EI',
        question: '사람들과 대화를 나눌 때 나와 가까운 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '굳이 생각을 안 해도 자연스럽게 대화가 흘러간다',
          },
          {
            type: 'I',
            score: 5,
            content:
              '머릿속으로 어떤 질문과 대답을 할지 생각하고 말하는 편이다',
          },
        ],
      },
      {
        which: 'EI',
        question: '나는 어떤 사람인가?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `사람들과 쉽게 교류하고 어울리는 능동적인 사람`,
          },
          {
            type: 'I',
            score: 5,
            content: `여러 사람과 있을 때 자신을 먼저 소개하기보단 소개를 받는 수동적인 사람`,
          },
        ],
      },
      {
        which: 'SN',
        question: '영화를 볼 때 내가 집중하는 곳은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `배우의 연기 및 스토리`,
          },
          {
            type: 'N',
            score: 5,
            content: `영화에서 주는 메시지가 무엇인가`,
          },
        ],
      },
      {
        which: 'SN',
        question: '매일 할 일이 정해져 있을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '할 일이 정해져 있으니 마음이 편하다',
          },
          {
            type: 'N',
            score: 5,
            content: `할 일이 정해져 있으면 마음이 답답하다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 하는 생각과 더 알맞은 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `큰 틀만 잡고 끝냄`,
          },
          {
            type: 'N',
            score: 5,
            content: `작은 틀에서도 막 생각이 저절로 됨`,
          },
        ],
      },
      {
        which: 'TF',
        question: `오해가 생겼을 때 내가 더 포커스를 맞추는 쪽은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '오해가 사실인가 사실이 아닌가',
          },
          {
            type: 'F',
            score: 5,
            content: '내 기분이 나빴는가 안 나빴는가',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 기분 나쁜 행동을 할 때 나의 행동은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `그런 행동 기분 안 좋다고 명확하게 말한다`,
          },
          {
            type: 'F',
            score: 5,
            content: '돌려 돌려 돌리고 돌려서 말한다',
          },
        ],
      },
      {
        which: 'TF',
        question: `내가 하는 ‘왜’에 담긴 뜻은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'why? -> 진짜 궁금해서 물어봄',
          },
          {
            type: 'F',
            score: 5,
            content: 'what? -> 그 안에 숨어있는 뜻이 궁금함',
          },
        ],
      },
      {
        which: 'JP',
        question: '주말에 나를 고르시오',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `아침부터 시작하고 저녁에 끝남`,
          },
          {
            type: 'P',
            score: 5,
            content: `저녁부터 시작하고 새벽에 끝남`,
          },
        ],
      },
      {
        which: 'JP',
        question: '나는 일을 할 때 어떤 위주로 먼저 하는가?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '해야 하는 걸 먼저',
          },
          {
            type: 'P',
            score: 5,
            content: '하고 싶은 걸 먼저',
          },
        ],
      },
      {
        which: 'JP',
        question: '무엇을 해야 할 때 내가 더 많이 생각하는 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '이걸 해야 하는 이유',
          },
          {
            type: 'P',
            score: 5,
            content: '이걸 안 해야 하는 이유',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/gradationChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/gradationChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/gradationChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/gradationChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/gradationChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/gradationChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/gradationChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/gradationChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/gradationChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/gradationChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/gradationChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/gradationChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/gradationChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/gradationChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/gradationChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/gradationChar/INFP.jpg',
      },
    ],
  },
  // ingredientChar
  {
    info: {
      mainTitle: '성분 성격 성격 테스트',
      subTitle: '나는 어떤 성분으로 구성되어 있을까?',
      mainUrl: 'ingredientChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/ingredientChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/ingredientChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '여러 사람이 모인 장소에 가게 되었다. 이때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '같이 신나게 떠들고 그 상황을 온전히 즐긴다.',
          },
          {
            type: 'I',
            score: 5,
            content: '신나거나 흥 많은 사람이 많아지면 조용히 빠진다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '나와 조금 더 가까운 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '어떤 이야기할지 어떤 또 즐거운 일이 생길지 궁금해져 빨리 친구를 만나고 싶다.',
          },
          {
            type: 'I',
            score: 5,
            content:
              '외출할 땐 세상 우울한데 귀가할 때 침대에 누울 생각에 신나진다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구랑 약속의 막바지에 접어들었다. 이때 드는 생각은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `쟤 말 끝나면 이 말 해야겠다!`,
          },
          {
            type: 'I',
            score: 5,
            content: `쟤 말 끝나면 이제 집에 가야겠다고 해야겠다!`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '친구가 요즘 피부가 건조해서 가렵다며 크림 추천해 줄 수 있냐고 했을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `예전에 이거 썼었는데 피부 장벽을 강화해 주고 수분감이 많아서 이걸 추천할게!`,
          },
          {
            type: 'N',
            score: 5,
            content: `이거 바르면 마치 아기피부처럼 보들보들해지고 피부 모델 광고까지 할 수 있을 것 같아!`,
          },
        ],
      },
      {
        which: 'SN',
        question: '새로운 맛집을 찾으러 갈 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '지도 앱을 켜고 어디서 돌고 꺾는지 OO 은행 나올 때까지 걸어가면 그 건물 2층에 위치함을 인지하고 간다.',
          },
          {
            type: 'N',
            score: 5,
            content: `지도 앱을 켜고 느낌적으로 이쪽으로 몇 분 걸어가면 OO 은행이 나오는 것 같단 생각으로 간다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '더 선호하는 동료는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `의견을 공유하고 의사소통은 탁월하지만 새로운 것을 시도하는 건 어려워하는 동료`,
          },
          {
            type: 'N',
            score: 5,
            content: `새로운 것을 도전하고 몰랐던 부분을 새롭게 알게 되지만 말도 안 되는 아이디어를 가져오는 동료`,
          },
        ],
      },
      {
        which: 'TF',
        question: `영화를 보다가 감동적인 장면이 나올 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '저건.. 살짝 억지 감동을 내려고 하는 것만 같은 느낌이라 말이 좀 안되는데?',
          },
          {
            type: 'F',
            score: 5,
            content:
              '가슴이 저려오고 너무 슬프네 눈물 안 흘리려고 했는데 나올 것 같아',
          },
        ],
      },
      {
        which: 'TF',
        question: '조별 과제 중 조원들끼리 불합리적인 일이 생겼을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `아닌 것은 아니라고 이야기하며 공평하게 해결하려고 한다.`,
          },
          {
            type: 'F',
            score: 5,
            content: '아닌 것은 아니지만 서로 대화를 통해 잘 풀었으면 한다.',
          },
        ],
      },
      {
        which: 'TF',
        question: `단톡방에 조금 늦어서 죄송하다는 카톡을 봤을 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '보고 그냥 ‘아 그렇구나’하고 이유가 궁금해진다.',
          },
          {
            type: 'F',
            score: 5,
            content: '울고 있는 이모티콘을 보내서 반응을 해준다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '계획했던 부분에 차질이 생겼다. 이때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `A가 안된다고? 그럼 플랜 B로 간다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `A가 안된다고? 그럼 그럴 수 있지.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 계획을 세우는 이유는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '지키기 위해서',
          },
          {
            type: 'P',
            score: 5,
            content: '까먹을까 봐',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 공부하는 스타일은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '한 과목만 판다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '이거하다 질리면 다른 과목, 질리면 또 다른 과목으로 공부한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/ingredientChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/ingredientChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/ingredientChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/ingredientChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/ingredientChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/ingredientChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/ingredientChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/ingredientChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/ingredientChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/ingredientChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/ingredientChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/ingredientChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/ingredientChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/ingredientChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/ingredientChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/ingredientChar/INFP.jpg',
      },
    ],
  },
  // watercolorCharEng
  {
    info: {
      mainTitle: 'Watercolor Paint Personality Test',
      subTitle:
        'What kind of color is my personality expressed in water colors?',
      mainUrl: 'watercolorCharEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/watercolorCharEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/watercolorCharEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'What about me when I have a hard problem?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I share my concerns with my acquaintance',
          },
          {
            type: 'I',
            score: 5,
            content: 'Need some alone time',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What am I missing?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Personal time and rest',
          },
          {
            type: 'I',
            score: 5,
            content: 'Forming new human relationships and activities',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What about me in a situation where awkward air flows?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I couldn't stand it. I just said anything and watched it`,
          },
          {
            type: 'I',
            score: 5,
            content: `Waiting for someone to say something`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What comes to your mind when you look at the colorful sky where the sun is setting?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `The sky is pretty`,
          },
          {
            type: 'N',
            score: 5,
            content: `Doesn't it look like a watercolor painting?`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What is more important to me?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              "The other person's feelings are more important than my feelings",
          },
          {
            type: 'N',
            score: 5,
            content: `My feelings are more important than the other person's feelings.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          "Rattling sound as I go up in the elevator! What's my reaction now?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I'm afraid it'll break down.`,
          },
          {
            type: 'N',
            score: 5,
            content: `What? What if I fall? Do I have to jump? I'm scared.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What about me to a friend who heard a conversation cursing herself for no reason?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Who? What did you curse at?',
          },
          {
            type: 'F',
            score: 5,
            content:
              "You must feel really bad. Bring him back. I'll scold him!",
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What about me when I hear compliments like,You did a great job. You worked hard',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I worked hard, but I don't think I did well.`,
          },
          {
            type: 'F',
            score: 5,
            content: 'I thought I was recognized for my efforts',
          },
        ],
      },
      {
        which: 'TF',
        question: `What about me in a situation where there is a conflict between people around me?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Objectively identify the current situation and identify problems',
          },
          {
            type: 'F',
            score: 5,
            content:
              "Mediates situations by taking into account a person's feelings",
          },
        ],
      },
      {
        which: 'JP',
        question: 'My life that I want to live?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `a systematic life of planning for the future`,
          },
          {
            type: 'P',
            score: 5,
            content: `a free life without control`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'What if you start a new job?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I try to do it until the end.',
          },
          {
            type: 'P',
            score: 5,
            content: 'I get sick of it quickly and look for something new',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What do you usually say to people around you?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Friend who prepares thoroughly and nags',
          },
          {
            type: 'P',
            score: 5,
            content: 'Friend who is unprepared and nagging',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorCharEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorCharEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorCharEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorCharEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorCharEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorCharEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorCharEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorCharEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorCharEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorCharEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorCharEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorCharEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorCharEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorCharEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorCharEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorCharEng/INFP.jpg',
      },
    ],
  },
  // watercolorChar
  {
    info: {
      mainTitle: '수채화 물감 성격 테스트',
      subTitle: '수채화로 표현해 보는 내 성격은 어떨까?',
      mainUrl: 'watercolorChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/watercolorChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/watercolorChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '어떤 힘든 문제가 닥쳤을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '누군가에게 털어놓음',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자만의 시간이 필요함',
          },
        ],
      },
      {
        which: 'EI',
        question: '내게 부족한 건 무엇일까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '개인적인 시간과 휴식',
          },
          {
            type: 'I',
            score: 5,
            content: '새로운 인간관계 형성과 활동',
          },
        ],
      },
      {
        which: 'EI',
        question: '어색한 공기가 흐르는 상황에서 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `참지 못해서 일단 아무 말이나 내뱉고 봄`,
          },
          {
            type: 'I',
            score: 5,
            content: `누군가 말을 해주길 기다리고 있음`,
          },
        ],
      },
      {
        which: 'SN',
        question: '석양이 지는 알록달록한 하늘을 보면 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `하늘이 예쁘네`,
          },
          {
            type: 'N',
            score: 5,
            content: `꼭 수채화로 그린 것 같지 않아?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내게 더 중요한 것은 무엇일까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '내 기분보다 상대방 기분이 더 중요',
          },
          {
            type: 'N',
            score: 5,
            content: `상대방의 기분보다 내 기분이 더 중요`,
          },
        ],
      },
      {
        which: 'SN',
        question: '엘리베이터를 타고 올라가던 중 덜컹거린다! 이때 내 반응은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `고장 날까 봐 무섭네`,
          },
          {
            type: 'N',
            score: 5,
            content: `뭐야 이거 떨어지는 거 아냐? 떨어질 때 점프해야 하나? 무서워`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 이유 없이 자신을 욕하는 뒷담화를 들었다고 한다. 내 반응은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '누가? 뭐라고 했는데?',
          },
          {
            type: 'F',
            score: 5,
            content: '정말?? 너무하다 진짜.. 데려와 내가 혼내줄게!',
          },
        ],
      },
      {
        which: 'TF',
        question: '‘고생했다 열심히 했네’ 라는 칭찬을 들으면 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `열심히는 했지만 잘하지는 못했다고 생각함`,
          },
          {
            type: 'F',
            score: 5,
            content: '내 노력을 인정받았다고 생각함',
          },
        ],
      },
      {
        which: 'TF',
        question: `주변인들끼리 갈등이 발생한 상황에서 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '객관적으로 현재 일어난 상황을 확인하고 문제를 파악함',
          },
          {
            type: 'F',
            score: 5,
            content: '사람들의 감정을 고려해가며 상황을 중재함',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 살고 싶은 나의 삶은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `미래가 계획되어 있어 체계적으로 살아가는 삶`,
          },
          {
            type: 'P',
            score: 5,
            content: `유유자적 통제 없이 자유롭게 살아가는 삶`,
          },
        ],
      },
      {
        which: 'JP',
        question: '어떤 일을 시작하면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '최대한 끝까지 하려고 노력한다',
          },
          {
            type: 'P',
            score: 5,
            content: '금방 질려서 다른 새로운 걸 찾는다',
          },
        ],
      },
      {
        which: 'JP',
        question: '평소에 주변인들에게 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '준비성 철저하고 잔소리하는 친구',
          },
          {
            type: 'P',
            score: 5,
            content: '준비성 부족하고 잔소리 듣는 친구',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/watercolorChar/INFP.jpg',
      },
    ],
  },
  // batteryChar
  {
    info: {
      mainTitle: '배터리 성격 테스트',
      subTitle: '배터리로 보는 나의 +,- 요인은?',
      mainUrl: 'batteryChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/batteryChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/batteryChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '내가 더 잘하는 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '대화',
          },
          {
            type: 'I',
            score: 5,
            content: '관찰',
          },
        ],
      },
      {
        which: 'EI',
        question: '나에게 + 가 되는 상황은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구들과 재미있게 놀고 난 뒤',
          },
          {
            type: 'I',
            score: 5,
            content: '집에서 혼자만의 시간을 충분히 보낸 뒤',
          },
        ],
      },
      {
        which: 'EI',
        question: '나는 나의 생각과 감정을 표현할 때 어느 쪽인가?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `즉시 표현하는 편이다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `내부에서 충분히 이해한 후 표현한다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '나는 ‘왜?’라는 질문을 자주 하는가?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `자주 하지 않는다`,
          },
          {
            type: 'N',
            score: 5,
            content: `자주 한다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '발생한 문제를 해결하는 나의 모습과 가까운 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '결과를 판단하고자 함',
          },
          {
            type: 'N',
            score: 5,
            content: `원인을 알아내고자 함`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내 생각은 주로 어디에 주의를 기울이는가?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `현재, 지금, 여기`,
          },
          {
            type: 'N',
            score: 5,
            content: `미래에 일어날 일들`,
          },
        ],
      },
      {
        which: 'TF',
        question: `내가 삶에 지쳐 배터리가 방전되었다고 하소연할 때 듣고 싶은 말은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '얼른 충전하고 다시 힘내야지!',
          },
          {
            type: 'F',
            score: 5,
            content: 'ㅠㅠ 많이 힘든 일 있었어?',
          },
        ],
      },
      {
        which: 'TF',
        question: '공감과 이해를 할 때 나의 모습은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `상황이 입력되어야 이해와 공감을 한다`,
          },
          {
            type: 'F',
            score: 5,
            content: '공감을 하고 이해를 하며 상황을 입력한다',
          },
        ],
      },
      {
        which: 'TF',
        question: `고민 상담을 할 때 내가 원하는 답변은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '이럴 때 넌 어떻게 해결할 건지 해결책을 내주는 것',
          },
          {
            type: 'F',
            score: 5,
            content: '얘기를 듣고 공감하고 위로해 주는 것',
          },
        ],
      },
      {
        which: 'JP',
        question: '나는 결정을 내릴 때 어떤 편인가?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `빠르고 쉽게 결정을 내린다`,
          },
          {
            type: 'P',
            score: 5,
            content: `결정을 내리기 어려워한다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '나는 미리 계획하지 않은 일을 시작하기가?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '어렵다',
          },
          {
            type: 'P',
            score: 5,
            content: '쉽다',
          },
        ],
      },
      {
        which: 'JP',
        question: '오래간만에 쉬는 주말에 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '그래도 아무것도 안 하기엔 시간이 아깝지.. 쉬면서 할 수 있는 거라도 찾아서 한다',
          },
          {
            type: 'P',
            score: 5,
            content:
              '이게 얼마만.. 별다른 걱정 없이 아무것도 안 하고 푸우욱 쉰다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/batteryChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/batteryChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/batteryChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/batteryChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/batteryChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/batteryChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/batteryChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/batteryChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/batteryChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/batteryChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/batteryChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/batteryChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/batteryChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/batteryChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/batteryChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/batteryChar/INFP.jpg',
      },
    ],
  },
  // rainbowNote
  {
    info: {
      mainTitle: '레인보우 노트 성격 테스트',
      subTitle: '레인보우 노트에 적혀 있는 성격을 알아보자',
      mainUrl: 'rainbowNote',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/rainbowNote-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/rainbowNote-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '약속 장소에 가던 중 친구를 마주쳤을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '혼자 심심하던 찰나에 마주치다니 너무 좋아',
          },
          {
            type: 'I',
            score: 5,
            content: '도착하기 전까지 혼자 여유롭게 갈라 했는데',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '친구와 소품점에서 구경을 하던 중 직원이 노트를 추천하였을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '노트에 대한 반응과 함께 다른 호기심으로 질문을 건넨다.',
          },
          {
            type: 'I',
            score: 5,
            content: '조용히 반응을 한 후 혼자 둘러보기 위해 자리를 피한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구와 다툼이 발생하여 약속이 파투가 났을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `다른 친구들을 만나서 기분을 푼다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `혼자 좋아하는 일을 하며 기분을 푼다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '길 한복판에 노트가 떨어져 있을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `누가 노트를 흘리고 갔네 보네`,
          },
          {
            type: 'N',
            score: 5,
            content: `노트? 누군가 애타게 찾고 있을 것 같네 이걸 찾아줄 순 있으려나`,
          },
        ],
      },
      {
        which: 'SN',
        question: '노트를 펼쳐보니 읽을 수 없는 언어가 적혀 있다. 그때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '이게 뭐지? 사람이 쓰는 언어가 맞나?',
          },
          {
            type: 'N',
            score: 5,
            content: `무슨 말이지..?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '노트의 첫 장에 감정에 따라 읽히는 내용이 다르다고 적혀 있을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `이 세상에 그런 노트가 어디 있어`,
          },
          {
            type: 'N',
            score: 5,
            content: `누가 만든 노트지? 지금 내 기분은 OO한데 어떻게 읽히는 거지?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `예쁜 노트 표지에 소름 돋는 저주의 말이 적혀 있을 때 그때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '저주 노트인가? 살벌하네',
          },
          {
            type: 'F',
            score: 5,
            content: '뭐야 이게 너무 무서워',
          },
        ],
      },
      {
        which: 'TF',
        question: '노트를 살펴보니 조그맣게 친구 이름이 적혀 있을 때 당신은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `놀릴 수 있는 내용이 있나 한번 훑어볼까?`,
          },
          {
            type: 'F',
            score: 5,
            content: '내용 궁금하지만 읽으면 기분 나빠하겠지?',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 우물쭈물하며 노트 속 내용을 읽어봤냐고 물어봤을 때 당신은`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '솔직하게 확인 여부를 말해준다.',
          },
          {
            type: 'F',
            score: 5,
            content: '민망해하는 친구를 위해 선의의 거짓말을 한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '노트에 하루 일정을 적어보았을 때 당신은? ',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `체계적으로 할 일을 정리하여 적는다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `대략적으로 할 일을 적어 둔다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '노트에 기분 좋지 않았던 일을 적을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '계획해 놨던 일정에 문제가 발생하였던 일',
          },
          {
            type: 'P',
            score: 5,
            content: '하고 싶었던 일을 까먹고 하지 못했던 일',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '노트를 사러 가는 길에 우연히 만난 친구가 놀자고 했을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '나 노트 사고 계획해 놨던 일정이 있어서 안될 것 같아',
          },
          {
            type: 'P',
            score: 5,
            content: '노트 사고나서 할 일도 없는데 그럴까?',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/rainbowNote/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/rainbowNote/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/rainbowNote/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/rainbowNote/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/rainbowNote/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/rainbowNote/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/rainbowNote/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/rainbowNote/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/rainbowNote/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/rainbowNote/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/rainbowNote/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/rainbowNote/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/rainbowNote/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/rainbowNote/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/rainbowNote/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/rainbowNote/INFP.jpg',
      },
    ],
  },
  // popupSketch
  {
    info: {
      mainTitle: '팝업 스케치 성격 테스트',
      subTitle: '팝업 스케치로 알아보는 나의 성격은?',
      mainUrl: 'popupSketch',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/popupSketch-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/popupSketch-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '취미로 그림을 그린다면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구와 함께 의견을 나누며 그림',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자 조용한 공간에서 생각하면서 그림',
          },
        ],
      },
      {
        which: 'EI',
        question: '주변 지인들이 나를 보면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '집에서 좀 쉬라고 말한다',
          },
          {
            type: 'I',
            score: 5,
            content: '나와서 좀 만나자고 말한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '나와 잘 맞는 스타일은 무엇일까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `서로 얘기가 잘 통하고 함께 떠들어주는 스타일`,
          },
          {
            type: 'I',
            score: 5,
            content: `함께 있으면 차분하고 침묵도 편안한 스타일`,
          },
        ],
      },
      {
        which: 'SN',
        question: '어딘가 급하게 뛰어가는 사람이 있다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `엄청 급해 보이네`,
          },
          {
            type: 'N',
            score: 5,
            content: `어딜 저렇게 급하게 가는 거지.. 무슨 일일까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '풍경 스케치를 그린다면 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '보는 그대로 스캐치',
          },
          {
            type: 'N',
            score: 5,
            content: `내가 느끼는 느낌대로 스케치`,
          },
        ],
      },
      {
        which: 'SN',
        question: '길에 은행이 엄청 떨어져 있는 걸 볼 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `저거 밟으면 냄새 장난 아니겠다`,
          },
          {
            type: 'N',
            score: 5,
            content: `실수로 밟아서 하루 종일 은행 냄새 풍기고 다니는 내가 상상됨`,
          },
        ],
      },
      {
        which: 'TF',
        question: `나는 내 모습이?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '공정하고 똑똑한 사람이고 싶음',
          },
          {
            type: 'F',
            score: 5,
            content: '친절하고 따듯한 사람이고 싶음',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구와 함께 엄청 슬픈 영화를 보고 있다. 내 모습은 어떨까?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `우는 친구 관찰하며 신기하게 바라봄`,
          },
          {
            type: 'F',
            score: 5,
            content: '어떻게ㅜㅜㅜ 이미 눈물 한 바가지',
          },
        ],
      },
      {
        which: 'TF',
        question: `둘 중 더 나은 성격을 고르라면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '답답한 타입보다는 똑 부러지고 냉정한 타입이 나음',
          },
          {
            type: 'F',
            score: 5,
            content: '매정하고 냉정한 타입보다는 느려도 따듯한 성격이 나음',
          },
        ],
      },
      {
        which: 'JP',
        question: '한 달 안에 스케치를 끝내야 하는 상황이라면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `한 달 밖에 안 남았어 플랜 짜자!`,
          },
          {
            type: 'P',
            score: 5,
            content: `한 달이나 남았는데 여유 있어!`,
          },
        ],
      },
      {
        which: 'JP',
        question: '스케치 할 용품을 사러 가야 한다면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '언제 사러 갈지 정하고 필요한 용품은 무엇인지 알아보고 구매',
          },
          {
            type: 'P',
            score: 5,
            content: '갑자기 생각나서 사러 간 다음 필요할 것 같은 용품들 구매',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 생각한 대로 일이 풀리지 않는다..',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '급격하게 몰려오는 스트레스',
          },
          {
            type: 'P',
            score: 5,
            content: '어쩔 수 없지 인생이란~',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/popupSketch/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/popupSketch/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/popupSketch/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/popupSketch/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/popupSketch/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/popupSketch/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/popupSketch/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/popupSketch/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/popupSketch/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/popupSketch/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/popupSketch/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/popupSketch/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/popupSketch/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/popupSketch/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/popupSketch/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/popupSketch/INFP.jpg',
      },
    ],
  },
  // balloonDartEng
  {
    info: {
      mainTitle: 'Balloon Dart Personality Test',
      subTitle: `Let's pop the balloon to find out your personality`,
      mainUrl: 'balloonDartEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/balloonDartEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/balloonDartEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'What about you when you newly opened Instagram?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I tell my friends about it and ask to following',
          },
          {
            type: 'I',
            score: 5,
            content: "I don't tell anyone",
          },
        ],
      },
      {
        which: 'EI',
        question:
          "What about you when you're in the spotlight by a lot of people?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I get excited and start talking about various things',
          },
          {
            type: 'I',
            score: 5,
            content: 'I feel shy and look away',
          },
        ],
      },
      {
        which: 'EI',
        question:
          "What about you when a friend says he's setting up a meeting with new people?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I'm going to attend`,
          },
          {
            type: 'I',
            score: 5,
            content: `It is said that I do not attend`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What about you when you watched a video about a bizarre phenomenon?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `It just feels fascinating`,
          },
          {
            type: 'N',
            score: 5,
            content: `Imagine when a bizarre phenomenon occurs and how to deal with it`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          "What about you when you listen to women's chats on the subway?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: "I think we're very close",
          },
          {
            type: 'N',
            score: 5,
            content: `Imagine the theme of the story in a different direction`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What about you when your body suddenly deteriorates?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Do a search or visit a hospital`,
          },
          {
            type: 'N',
            score: 5,
            content: `Imagine yourself falling down`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What about you when you feel left out by your friends?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I speak honestly about what I experience or feel',
          },
          {
            type: 'F',
            score: 5,
            content:
              "I feel bad and sad, but I can't say it and only in my heart",
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What about you when you infer the feelings of a friend who asks for counseling?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `It's because I'm good at teaching how to solve it realistically`,
          },
          {
            type: 'F',
            score: 5,
            content: "It's because I empathize well and talk kindly",
          },
        ],
      },
      {
        which: 'TF',
        question: `When you think about the way you talk to people around you?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "I think it's hard to say without being hurt",
          },
          {
            type: 'F',
            score: 5,
            content: 'I find it difficult to be straightforward',
          },
        ],
      },
      {
        which: 'JP',
        question:
          "What about you when you get a compliment for the job you've taken on by your superiors?",
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I think it's natural to praise the work because it's been systematically carried out`,
          },
          {
            type: 'P',
            score: 5,
            content: `I did it in a hurry before the deadline, but I'm glad the response was good`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'When choosing a travel accommodation with a friend, what about you?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I work out a list after considering a number of things',
          },
          {
            type: 'P',
            score: 5,
            content: 'I like the reviews and just pick somewhere close',
          },
        ],
      },
      {
        which: 'JP',
        question:
          "What about you when you couldn't carry out the schedule you planned on your own?",
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "I'm worried about the schedule I couldn't carry out and I feel bad",
          },
          {
            type: 'P',
            score: 5,
            content: 'I pass it over thinking that it could happen',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDartEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDartEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDartEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDartEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDartEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDartEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDartEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDartEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDartEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDartEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDartEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDartEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDartEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDartEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDartEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDartEng/INFP.jpg',
      },
    ],
  },
  // balloonDart
  {
    info: {
      mainTitle: '풍선 다트 성격 테스트',
      subTitle: '풍선을 터뜨려 성격을 알아보자',
      mainUrl: 'balloonDart',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/balloonDart-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/balloonDart-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '인스타그램을 새로 개설하였을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구들에게 새로 만들었다며 팔로잉을 요청한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '아무에게도 알리지 않고 활동한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '많은 사람들에게 스포트라이트를 받고 있을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '신나서 이것저것 이야기를 풀어놓는다.',
          },
          {
            type: 'I',
            score: 5,
            content: '부끄러워 시선을 다른 곳으로 돌린다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구가 새로운 사람들과 모임을 설립한다고 했을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `모임 참석 의사를 말한다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `모임 불참 의사를 말한다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '기이한 현상에 관한 영상을 시청하였을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `그냥 신기한 느낌이 든다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `기이한 현상이 일어나게 됐을 때와 대처방법에 대해 상상해 본다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '지하철에서 아주머니들의 수다를 듣게 되었을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '아줌마들끼리 엄청 친하신 것 같다고 생각한다.',
          },
          {
            type: 'N',
            score: 5,
            content: `이야기 주제를 다른 방향으로 상상해 본다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '갑작스럽게 몸 상태가 나빠졌을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `검색을 해보거나 병원에 가본다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `쓰러지는 자신의 모습을 상상해본다.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구들에게 소외되는 느낌을 받았을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '슬쩍 떠보거나 느끼게 된 감정을 직설적으로 말한다. ',
          },
          {
            type: 'F',
            score: 5,
            content: '기분이 나쁘고 서운하지만 말하지 못하고 속으로만 삭힌다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '고민 상담을 부탁하는 친구의 마음을 유추해 볼 때 당신은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `현실적으로 해결할 수 있는 방법을 잘 알려주기 때문이다.`,
          },
          {
            type: 'F',
            score: 5,
            content: '공감을 잘해주고 다정하게 잘 얘기해주기 때문이다.',
          },
        ],
      },
      {
        which: 'TF',
        question: `주변 사람들에게 말하는 말투를 생각해 보았을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '상처받지 않게 말하는 것이 어렵다고 생각한다.',
          },
          {
            type: 'F',
            score: 5,
            content: '직설적으로 말하는 것이 어렵다고 생각한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '윗 사람에게 맡았던 일에 관한 칭찬을 받았을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `체계적으로 일을 진행했으니 당연하다고 생각한다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `마감 기간 전에 급하게 했는데 반응이 좋아 다행이다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '친구와 여행 숙소를 고를 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '여러 가지를 고려한 후 리스트 업을 짜본다.',
          },
          {
            type: 'P',
            score: 5,
            content: '리뷰가 좋고 그냥 가까운 곳으로 고른다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '혼자서 계획했던 일정을 수행 못했을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '수행하지 못한 일정이 신경 쓰이고 기분이 나쁘다.',
          },
          {
            type: 'P',
            score: 5,
            content: '그럴 수도 있지 하고 넘겨버린다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDart/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDart/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDart/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDart/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDart/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDart/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDart/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDart/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDart/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDart/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDart/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDart/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDart/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDart/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDart/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/balloonDart/INFP.jpg',
      },
    ],
  },
  // angerExplosion
  {
    info: {
      mainTitle: '나의 분노 폭발 테스트',
      subTitle: '나의 분노 폭발 포인트는 언제일까?',
      mainUrl: 'angerExplosion',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/angerExplosion-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/angerExplosion-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '둘 중 더 짜증 나는 상황을 고르라면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '더 놀고 싶은데 다 집에 가겠다고 하는 상황',
          },
          {
            type: 'I',
            score: 5,
            content: '이제 그만 집에 가고 싶은데 친구들이 못 가게 하는 상황',
          },
        ],
      },
      {
        which: 'EI',
        question: '기분이 우울할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구들을 만남',
          },
          {
            type: 'I',
            score: 5,
            content: '집에서 안 나옴',
          },
        ],
      },
      {
        which: 'EI',
        question: '화가 나면 푸는 방법은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `주변 지인들에게 우다다 털어놓으며 품`,
          },
          {
            type: 'I',
            score: 5,
            content: `혼자 생각을 정리하고 차분히 마음을 다스림`,
          },
        ],
      },
      {
        which: 'SN',
        question: '길에서 서로 화내며 싸우고 있는 커플을 본다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `와우.. 살벌하네`,
          },
          {
            type: 'N',
            score: 5,
            content: `싸우는 이유가 뭘까? 설마 누가 바람폈나???`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구와 다투게 된 이유를 설명할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '구체적으로 왜 다투었는지 이유를 설명',
          },
          {
            type: 'N',
            score: 5,
            content: `이유와 함께 내가 느꼈던 기분과 느낌을 설명`,
          },
        ],
      },
      {
        which: 'SN',
        question: '‘넌 말투가 조금 쎈 거 같아’ 라는 말을 들으면 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `왜? 어느 부분이? (바로 물어봄)`,
          },
          {
            type: 'N',
            score: 5,
            content: `무슨 뜻이지..? 내 말투가 띠껍다는 건가? (뜻을 해석해 봄)`,
          },
        ],
      },
      {
        which: 'TF',
        question: `지금 살짝 소노인 상태, 나는 어떻게 할까?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '화난 이유를 바로 말한다',
          },
          {
            type: 'F',
            score: 5,
            content: '감정을 추스르며 일단 참는다',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '참지 못하고 친구에게 버럭 화를 내고 만 오늘, 집으로 돌아와서 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `화낼 만했으니까 냈지 후`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '내가 너무 급발진 했던 걸까.. 아냐 그럴만했어.. 아 아닌가.',
          },
        ],
      },
      {
        which: 'TF',
        question: `화가 나도 꾹 참는 친구를 보면 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜 참는지 이해 안 가고 답답함',
          },
          {
            type: 'F',
            score: 5,
            content: '항상 참는 친구를 보면 마음이 아픔',
          },
        ],
      },
      {
        which: 'JP',
        question: '내게 더 분노가 치밀 때는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `생각했던 계획이나 일정이 틀어질 때`,
          },
          {
            type: 'P',
            score: 5,
            content: `즉흥으로 생각난 꿀잼인 무언가를 못하게 할 때`,
          },
        ],
      },
      {
        which: 'JP',
        question: '주변 지인들은 나를 어떻게 볼까?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '조금 융통성은 없지만 꼼꼼한 친구',
          },
          {
            type: 'P',
            score: 5,
            content: '자유분방하고 대충대충 하는 친구',
          },
        ],
      },
      {
        which: 'JP',
        question: '나는 화가 나면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '일단 먼저 할 건 다 하고 화냄',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 미루고 화부터 냄',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/angerExplosion/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/angerExplosion/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/angerExplosion/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/angerExplosion/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/angerExplosion/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/angerExplosion/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/angerExplosion/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/angerExplosion/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/angerExplosion/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/angerExplosion/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/angerExplosion/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/angerExplosion/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/angerExplosion/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/angerExplosion/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/angerExplosion/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/angerExplosion/INFP.jpg',
      },
    ],
  },
  // heartWindowEng
  {
    info: {
      mainTitle: 'Inside the Window of the Mind Test',
      subTitle: 'Open the window of your hidden heart',
      mainUrl: 'heartWindowEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/heartWindowEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/heartWindowEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          'My friend suggested we go to a crowded festival. What about you?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'A crowded festival sounds so fun',
          },
          {
            type: 'I',
            score: 5,
            content:
              "It's hard for me to handle crowds, so I'll need to come up with an excuse",
          },
        ],
      },
      {
        which: 'EI',
        question:
          'When faced with a variety of options at a food stall and unable to decide, what do you do?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Get a recommendation from an employee or friend',
          },
          {
            type: 'I',
            score: 5,
            content: 'I think about it alone and decide',
          },
        ],
      },
      {
        which: 'EI',
        question:
          "When you made an appointment with a friend you haven't seen in years?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I'm excited because it's been a while since I saw him`,
          },
          {
            type: 'I',
            score: 5,
            content: `It won't be awkward when we meet, right?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          "As I look up at the sky, it's filled with beautiful clouds. What do you do in that moment?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: `There are so many clouds`,
          },
          {
            type: 'N',
            score: 5,
            content: `It's so pretty. How does it feel when you touch it?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          "When the drama series I've been enjoying ends with an open ending, what do I do?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: "It's too bad it's already over",
          },
          {
            type: 'N',
            score: 5,
            content: `If it's a happy ending, I think it'll be possible like this`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'When a friend asks what you did over the weekend, what do you say?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I say what I did that day as it is`,
          },
          {
            type: 'N',
            score: 5,
            content: `Talk about the weather on the weekend or how you felt at that time`,
          },
        ],
      },
      {
        which: 'TF',
        question: `When a friend sends a picture saying they bought a car, what do you do?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Is it a lump sum payment or installment payment?',
          },
          {
            type: 'F',
            score: 5,
            content: "Wow, it's pretty! I think I bought it well",
          },
        ],
      },
      {
        which: 'TF',
        question:
          'When a friend cancels plans due to an urgent matter, what do you do?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `There's nothing we can do. See you next time`,
          },
          {
            type: 'F',
            score: 5,
            content:
              "What's wrong? It's not a bad thing, is it? I hope you work it out",
          },
        ],
      },
      {
        which: 'TF',
        question: `When a friend confides that they had a fight with their boyfriend, what do you do?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I think you made a mistake in this part',
          },
          {
            type: 'F',
            score: 5,
            content: "You must be upset. Are you okay? It's the man's fault",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'When a carefully prepared schedule gets disrupted, what do you do?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I'm in a panic for a moment and find another way`,
          },
          {
            type: 'P',
            score: 5,
            content: `Find another way`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'When a friend suggests meeting up over the weekend, what do you do?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Ask for exact date and time of place',
          },
          {
            type: 'P',
            score: 5,
            content:
              'After answering, I fall into a different topic and the promise will fizzle out',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'When you arrive early at the meeting place and have extra time, what do you do?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "I'm going to waste time. Then I'll go here and do this and wait",
          },
          {
            type: 'P',
            score: 5,
            content: "I'm going to go to a nearby cafe",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindowEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindowEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindowEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindowEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindowEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindowEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindowEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindowEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindowEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindowEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindowEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindowEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindowEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindowEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindowEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindowEng/INFP.jpg',
      },
    ],
  },
  // heartWindow
  {
    info: {
      mainTitle: '마음의 창문 성격 테스트',
      subTitle: '숨겨놨던 마음의 창문을 열어봐',
      mainUrl: 'heartWindow',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/heartWindow-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/heartWindow-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '친구가 사람 많은 축제에 가자고 제안했다. 그때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '사람 많은 축제 너무 재밌겠다.',
          },
          {
            type: 'I',
            score: 5,
            content: '사람 많은 거 기 빨리는데 핑계 대야겠다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '노점에 많은 메뉴로 결정을 하지 못하고 있다. 그때 당신은? ',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '직원 또는 친구에게 추천을 받는다.',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자 고민해 보고 결정한다.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '몇 년 간 교류가 없던 친구와 약속을 잡게 되었다. 그때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `오랜만에 보는 친구라 그런가 설레는데?`,
          },
          {
            type: 'I',
            score: 5,
            content: `만났을 때 어색하진 않겠지?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '하늘을 쳐다보니 예쁜 구름이 가득하다. 그때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `구름 엄청 많네`,
          },
          {
            type: 'N',
            score: 5,
            content: `너무 예쁘다~ 만지면 어떤 느낌일까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '즐겨보던 드라마가 열린 결말로 끝이 났다. 그때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '벌써 끝이라니 아쉬워',
          },
          {
            type: 'N',
            score: 5,
            content: `해피 엔딩이면 이런 식으로 가능할 것 같은데`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구가 주말에 뭐 했냐고 물어봤을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `그날 한 것을 있는 그대로 말한다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `주말 날씨를 말하거나 그때 느꼈던 감정을 함께 말한다.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 자동차를 샀다며 사진을 보냈다. 그때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '일시불이야? 아님 할부야?',
          },
          {
            type: 'F',
            score: 5,
            content: '우와 예쁘다! 완전 잘 산 것 같아',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 급한 사정으로 인해 약속을 취소했다. 그때 당신은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `그래 어쩔 수 없지 다음에 보자`,
          },
          {
            type: 'F',
            score: 5,
            content: '무슨 일 있어? 안 좋은 일은 아니지? 잘 해결하길 바라',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 남자친구와 싸웠다며 하소연을 얘기한다. 그때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '이 부분은 네가 잘못한 것 같은데?',
          },
          {
            type: 'F',
            score: 5,
            content: '속상하겠다 괜찮아? 남자가 잘못했는데?',
          },
        ],
      },
      {
        which: 'JP',
        question: '열심히 준비했던 일정이 틀어지게 되었다. 그때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `한순간 멘붕에 빠졌다가 다른 방법을 찾는다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `다른 방법을 찾는다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '친구가 주말에 만나자고 하였다. 그때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '정확하게 날짜와 장소 시간을 물어본다.',
          },
          {
            type: 'P',
            score: 5,
            content: '답변을 한 후 다른 주제로 빠져 약속 내용은 흐지부지 된다.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '약속 장소에 일찍 도착해 시간이 남아돌게 되었다. 그때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '시간 낭비하게 생겼네 그럼 이곳에 갔다가 이걸 하면서 기다려야지',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 근처 카페나 들어가 있어야지',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindow/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindow/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindow/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindow/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindow/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindow/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindow/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindow/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindow/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindow/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindow/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindow/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindow/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindow/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindow/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/heartWindow/INFP.jpg',
      },
    ],
  },
  // colorPen
  {
    info: {
      mainTitle: '컬러펜 성격 테스트',
      subTitle: '다채로운 컬러펜으로 그려보는 나의 성격은?',
      mainUrl: 'colorPen',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/colorPen-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/colorPen-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '미용실 가서 머리 감는 상황일 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '그냥 보이는 천장만 뚫어지게 쳐다본다.',
          },
          {
            type: 'I',
            score: 5,
            content: '눈 마주치기 어색해서 눈을 감아버린다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '처음 본 사람과의 대화할 때 나의 모습은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '손을 가만히 안 두고 입을 열어야 한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '가만히 손을 꼼지락거리며 조용히 이야기를 시작한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구와 오해가 생긴 것 같아 풀고 싶을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `언제 시간되는지 만날 수 있는지를 먼저 확인해 본다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `나의 생각을 깔끔하게 정리한 후에 카톡으로 장문을 보내 본다.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '만약에 비행기가 가다가 추락하면 어떻게 되는 거지?라는 질문에 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `뭘.. 어떡해. 그냥 죽는 거지.`,
          },
          {
            type: 'N',
            score: 5,
            content: `추락하면 혹시 모르니까 구명조끼 찾아서 입고 가족들과 작별 인사하려고 휴대폰 찾아.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '갑자기 와이파이가 끊겨서 인터넷이 연결 안 될 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '음.. 근처 피시방이나 가서 다시 진행해 보자!',
          },
          {
            type: 'N',
            score: 5,
            content: `음... 이게 왜 안되는 거지? 아까까진 잘 됐었는데..`,
          },
        ],
      },
      {
        which: 'SN',
        question: '직장 상사로서 더 싫은 상황은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `“OO 씨~ 다음 주까지 이 주제로 업무 해오세요.” 하는 팀장`,
          },
          {
            type: 'N',
            score: 5,
            content: `“이거 했어요? 저거 했어요?”라고 계속 확인하는 팀장`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구에게 잘 되어가는 썸남/녀가 생겼다. 이때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '그 친구 어디가 맘에 들었어? 왜? 뭔데? 좋아하는 이유가 있어?',
          },
          {
            type: 'F',
            score: 5,
            content:
              '우와 너 그런 스타일 좋아하는구나!! 진짜 너무 잘 어울린다ㅠㅠ짱!',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 “야 OOO!”이라고 부를 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `왜?`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '나 성 붙이는 거 싫어해!!!!!!!!!!!!!!!!!!! 근데 왜 불렀어?',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 알람 전에 눈 떠졌다며 얼굴 사진 찍어서 보내줬을 때 나의 반응은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '눈… 감고 있는데?',
          },
          {
            type: 'F',
            score: 5,
            content: '알람 전 기상.. 최악인데…?!',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '마침 급한 사정으로 인해 금일 휴무라는 휴무 공지를 보았을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `혹시 몰라서 다른 비슷한 집도 찾아 놨지!`,
          },
          {
            type: 'P',
            score: 5,
            content: `그렇다면 어쩔 수 없지ㅠㅠ 다른데 저긴 어때? 저기 가보자!`,
          },
        ],
      },
      {
        which: 'JP',
        question: '스킨과 크림이 다 떨어져서 화장품 가게에 갔다. 이때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '미리 리스트업 해놓은 제품들을 하나씩 테스트해 보고 온라인과 가격비교 후 구매한다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '하나씩 테스트해 보고 좋은 제품들을 가격비교 후 바구니에 담는다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '과제가 산더미지만 컨디션이 좋지 않은 상황이다. 이때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '어떡하지.. 아 오늘은 여기까지.. 아 근데 어떡할지만 생각해 놓고 자야겠다.',
          },
          {
            type: 'P',
            score: 5,
            content: '아~ 몰라 어떻게든 되겠지. 내일의 나에게 맡긴다!!!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/colorPen/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/colorPen/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/colorPen/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/colorPen/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/colorPen/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/colorPen/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/colorPen/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/colorPen/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/colorPen/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/colorPen/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/colorPen/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/colorPen/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/colorPen/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/colorPen/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/colorPen/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/colorPen/INFP.jpg',
      },
    ],
  },
  // colorSquareEng
  {
    info: {
      mainTitle: 'Color Square Personality Test',
      subTitle: 'What is my personality in color squares?',
      mainUrl: 'colorSquareEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/colorSquareEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/colorSquareEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'Do people around me know about me?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'We talk a lot, so most of them know everything.',
          },
          {
            type: 'I',
            score: 5,
            content: "'I don't know much except my close friends.'",
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What if the place we were supposed to meet was a noisy downtown?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: "I think it's going to be so much fun!",
          },
          {
            type: 'I',
            score: 5,
            content: "I think I'm going to be so tired..",
          },
        ],
      },
      {
        which: 'EI',
        question: 'When I talk, what do I usually do?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Speaking`,
          },
          {
            type: 'I',
            score: 5,
            content: `In charge of reaction`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'I booked a famous restaurant! What about me the day before I went?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I wonder how delicious it will be! What if it's so delicious that I want to go again?`,
          },
          {
            type: 'N',
            score: 5,
            content: `It's a famous place, so there must be a lot of people.`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do you do when you explain something?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Abstractly compared and explained',
          },
          {
            type: 'N',
            score: 5,
            content: `Explain the point in detail`,
          },
        ],
      },
      {
        which: 'SN',
        question: "What if you're told to know yourself?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I know myself the best`,
          },
          {
            type: 'N',
            score: 5,
            content: `What do you mean I don't know myself?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `When you see a person eating alone?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "What's wrong with eating alone?",
          },
          {
            type: 'F',
            score: 5,
            content: 'I feel sorry for you.',
          },
        ],
      },
      {
        which: 'TF',
        question: "Why do you ask me what I'm doing this weekend?",
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I'm really curious about what you're doing on the weekend.`,
          },
          {
            type: 'F',
            score: 5,
            content:
              'Ask them with hidden meaning if they have time on the weekend or if they can meet.',
          },
        ],
      },
      {
        which: 'TF',
        question: `What should I do when I have to make a decision?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Determined by rational thought',
          },
          {
            type: 'F',
            score: 5,
            content: 'Decided by emotional thinking',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What about me when I make a plan?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `to plan carefully so that I can keep it`,
          },
          {
            type: 'P',
            score: 5,
            content: `I'm not going to keep it anyway, so I wonder why I have to squeeze it.`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'What should I do about my quality of life?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'A life where set plans and routines are followed',
          },
          {
            type: 'P',
            score: 5,
            content: 'Life of doing what you want to do freely',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What about me when I have a schedule behind me?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I am under great stress from unexpected variables',
          },
          {
            type: 'P',
            score: 5,
            content: "It doesn't matter if the schedule changes a little",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquareEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquareEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquareEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquareEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquareEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquareEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquareEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquareEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquareEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquareEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquareEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquareEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquareEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquareEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquareEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquareEng/INFP.jpg',
      },
    ],
  },
  // colorSquare
  {
    info: {
      mainTitle: '컬러 스퀘어 성격 테스트',
      subTitle: '컬러 스퀘어로 알아보는 내 성격은?',
      mainUrl: 'colorSquare',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/colorSquare-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/colorSquare-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '주변 사람들은 나에 대해서?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '얘기를 많이 하기 때문에 대부분 다 알고 있다',
          },
          {
            type: 'I',
            score: 5,
            content: '주로 듣기 때문에 친한 친구들 외에는 잘 모른다',
          },
        ],
      },
      {
        which: 'EI',
        question: '약속 장소가 사람이 많은 번화가라면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '점점 흥이 오름 너무 재밌다!',
          },
          {
            type: 'I',
            score: 5,
            content: '에너지 소진 중.. 기 빨린다',
          },
        ],
      },
      {
        which: 'EI',
        question: '대화를 할 때 나는 주로?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `말하기 담당`,
          },
          {
            type: 'I',
            score: 5,
            content: `리액션 담당`,
          },
        ],
      },
      {
        which: 'SN',
        question: '유명한 식당을 예약했다! 가기 전날 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `맛있는 곳이니까 사람 많겠지`,
          },
          {
            type: 'N',
            score: 5,
            content: `도대체 얼마나 맛있길래 예약까지? 사실은 그저 그럴 수도??`,
          },
        ],
      },
      {
        which: 'SN',
        question: '뭔가를 설명할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '상세하게 요점을 설명',
          },
          {
            type: 'N',
            score: 5,
            content: `추상적으로 비유하며 설명`,
          },
        ],
      },
      {
        which: 'SN',
        question: '너 자신을 알라 라는 말을 들으면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `내 자신 내가 알지 누가 알아~`,
          },
          {
            type: 'N',
            score: 5,
            content: `내가 내 자신을 모른다는 뜻?? 말하고자 하는 게 뭘까??`,
          },
        ],
      },
      {
        which: 'TF',
        question: `혼자 밥을 먹고 있는 사람을 보면 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '혼자 먹는구나 싶음',
          },
          {
            type: 'F',
            score: 5,
            content: '왠지 모르게 안쓰러움',
          },
        ],
      },
      {
        which: 'TF',
        question: '내가 이번 주말에 뭐해? 라고 묻는 이유는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `진짜 주말에 뭐 하는지 궁금해서 물어봄`,
          },
          {
            type: 'F',
            score: 5,
            content: '주말에 시간 있는지 만날 수 있는지 숨은 뜻을 담아 물어봄',
          },
        ],
      },
      {
        which: 'TF',
        question: `결정을 내려야 할 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '머리로 결정',
          },
          {
            type: 'F',
            score: 5,
            content: '마음으로 결정',
          },
        ],
      },
      {
        which: 'JP',
        question: '계획표를 짜야 할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `꼭 지키겠다는 마음으로 열심히 짬`,
          },
          {
            type: 'P',
            score: 5,
            content: `어차피 안 지킬 텐데 왜 짜야 하나 싶음`,
          },
        ],
      },
      {
        which: 'JP',
        question: '내 삶의 질은 어떻게 해야 좋아질까?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '정해진 계획과 루틴이 지켜지는 삶',
          },
          {
            type: 'P',
            score: 5,
            content: '자유롭게 하고 싶은 걸 하는 삶',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '친구의 차를 타고 이동 중에 길을 잘못 들어 시간이 지체되었다! 뒤에 일정이 있을 때 나는 어떨까?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '예상치 못한 변수에 큰 스트레스를 받는다',
          },
          {
            type: 'P',
            score: 5,
            content: '일정이 조금 변동되어도 아무 상관없다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquare/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquare/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquare/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquare/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquare/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquare/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquare/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquare/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquare/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquare/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquare/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquare/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquare/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquare/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquare/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/colorSquare/INFP.jpg',
      },
    ],
  },
  // colorChart
  {
    info: {
      mainTitle: '컬러 차트 성격 테스트',
      subTitle: '컬러 차트로 보는 나',
      mainUrl: 'colorChart',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/colorChart-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/colorChart-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '나는 대화할 때 주로 어떤 편인가?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '말을 하는 편',
          },
          {
            type: 'I',
            score: 5,
            content: '들어 주는 편',
          },
        ],
      },
      {
        which: 'EI',
        question: '나를 더 잘 설명하는 문항은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '상대를 기분 좋게 하는 방법을 아는 사람',
          },
          {
            type: 'I',
            score: 5,
            content: '상대를 기분 나쁘지 않게 배려하는 방법을 아는 사람',
          },
        ],
      },
      {
        which: 'EI',
        question: '약속이 하나도 없는 캘린더를 볼 때 나의 마음은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `얼른 약속 좀 잡아야겠다!!(불안)`,
          },
          {
            type: 'I',
            score: 5,
            content: `이대로 아무 약속도 안 생겼으면.. (편안)`,
          },
        ],
      },
      {
        which: 'SN',
        question: '나는 주로 공부할 때 어떤 편인가?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `노트에 써가면서 무작정 외움`,
          },
          {
            type: 'N',
            score: 5,
            content: `이해할 때까지 텍스트 쳐다봄`,
          },
        ],
      },
      {
        which: 'SN',
        question: '길을 가는데 긴 모자를 쓴 사람을 봤다 나의 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '와 모자 진짜 길다!!',
          },
          {
            type: 'N',
            score: 5,
            content: `저 안에 비둘기 있는 거 아니야..? 해리포터 같다 ㅋㅋ`,
          },
        ],
      },
      {
        which: 'SN',
        question: '나에게 ‘상상’이라는 의미는 어느 쪽에 더 가까운가?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `일어날 수 있는 일이든 아니든 일단 일어나지 않은 모든 생각`,
          },
          {
            type: 'N',
            score: 5,
            content: `말 그대로 순전히 ‘상상’ 현실적인 건 상상이 아니라 그냥 생각하는 것`,
          },
        ],
      },
      {
        which: 'TF',
        question: `나와 더 가까운 것은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '감정 케어가 잘 됨, 상처를 덜 받음',
          },
          {
            type: 'F',
            score: 5,
            content: '공감이 잘 됨, 친근감이 강함',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '갑자기 비 내리고 번개 치는데 골목길이라 너무 무섭다고 카톡 온 친구 나의 반응은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `우산은 챙겼어? 얼른 뛰어서 밝은 데로 가자!`,
          },
          {
            type: 'F',
            score: 5,
            content: '무섭겠다ㅜㅜ 괜찮아? 내가 전화해 줄까? ㅠㅠ',
          },
        ],
      },
      {
        which: 'TF',
        question: `상대방에게 관심이 생겼을 때 나의 행동은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '질문을 왕창해준다 ',
          },
          {
            type: 'F',
            score: 5,
            content: '호응과 리액션을 왕창해준다',
          },
        ],
      },
      {
        which: 'JP',
        question: '결과가 안 좋을 경우에 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `완벽하게 다 생각해놨는데 왜 성과가 안 좋은 거지? 하..`,
          },
          {
            type: 'P',
            score: 5,
            content: `뭐가 잘못됐나? 다시 처음부터 해볼까?`,
          },
        ],
      },
      {
        which: 'JP',
        question: '나에게 ‘계획’ 이란?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '계획이 행동에서 망설임을 없애줌',
          },
          {
            type: 'P',
            score: 5,
            content: '계획의 압박감이 행동을 더디게 함',
          },
        ],
      },
      {
        which: 'JP',
        question: '나의 생각과 더 가까운 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '해야만 한다는 생각이 있음',
          },
          {
            type: 'P',
            score: 5,
            content: '안 해도 된다는 생각만 있음',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/colorChart/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/colorChart/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/colorChart/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/colorChart/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/colorChart/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/colorChart/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/colorChart/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/colorChart/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/colorChart/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/colorChart/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/colorChart/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/colorChart/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/colorChart/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/colorChart/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/colorChart/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/colorChart/INFP.jpg',
      },
    ],
  },
  // naileage
  {
    info: {
      mainTitle: '나일리지 성격 테스트',
      subTitle: '나일리지를 통해 성격을 알아보자',
      mainUrl: 'naileage',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/naileage-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/naileage-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '평소 자주 듣는 말은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '오늘도 약속이 있어?',
          },
          {
            type: 'I',
            score: 5,
            content: '오늘은 시간 괜찮아?',
          },
        ],
      },
      {
        which: 'EI',
        question: '집과 밖 중 마음에 드는 곳은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '밖',
          },
          {
            type: 'I',
            score: 5,
            content: '집',
          },
        ],
      },
      {
        which: 'EI',
        question: '평소 약속을 잡으면 몇 명이서 보는가?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `여려 명의 친구`,
          },
          {
            type: 'I',
            score: 5,
            content: `소수의 친구`,
          },
        ],
      },
      {
        which: 'SN',
        question: '집에 가만히 있을 때 하는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `아무 생각도 하지 않고 멍하니 있어`,
          },
          {
            type: 'N',
            score: 5,
            content: `외계인이 우리 집 옆집이라면? 온갖 말도 안 되는 상상을 해`,
          },
        ],
      },
      {
        which: 'SN',
        question: '방 안 구석에서 이상한 상자를 발견하였다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '흔들어보거나 분해해 내용물을 확인해 봐',
          },
          {
            type: 'N',
            score: 5,
            content: `뭔가 이상한 물건이 들어가 있을 것 같아`,
          },
        ],
      },
      {
        which: 'SN',
        question: '어떤 주제로 걱정을 하는 편인가?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `현실에서 겪을 수 있는 일`,
          },
          {
            type: 'N',
            score: 5,
            content: `일어나지 않은 일과 일어날 일 모두`,
          },
        ],
      },
      {
        which: 'TF',
        question: `우울해서 놀이동산에 간다는 친구의 말에 집중되는 포인트는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '놀이동산에 집중함',
          },
          {
            type: 'F',
            score: 5,
            content: '우울함에 집중함',
          },
        ],
      },
      {
        which: 'TF',
        question: '추위로 벌벌 떨고 있는 사람을 발견했다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `많이 추운가 봐`,
          },
          {
            type: 'F',
            score: 5,
            content: '추위를 많이 타시나? 안타까워',
          },
        ],
      },
      {
        which: 'TF',
        question: `상사에게 혼난 친구의 하소연을 들을 때 드는 생각은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '상사의 입장을 생각하며 건넬 조언의 말을 생각',
          },
          {
            type: 'F',
            score: 5,
            content: '고민 내용에 공감을 하며 건넬 위로의 말을 생각',
          },
        ],
      },
      {
        which: 'JP',
        question: '약속 장소에 도착하는 시간은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `약속 시간에 맞춰서 도착하거나 미리 도착함`,
          },
          {
            type: 'P',
            score: 5,
            content: `약속 시간에 딱 맞춰서 도착하거나 늦음`,
          },
        ],
      },
      {
        which: 'JP',
        question: '계획은 무엇이라고 생각하고 있는가?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '체계적으로 정리해두는 것',
          },
          {
            type: 'P',
            score: 5,
            content: '확정된 사항을 대략적으로 정리하는 것',
          },
        ],
      },
      {
        which: 'JP',
        question: '여행을 갈 때 계획을 세우는 사람은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '본인',
          },
          {
            type: 'P',
            score: 5,
            content: '같이 가는 친구',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/naileage/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/naileage/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/naileage/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/naileage/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/naileage/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/naileage/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/naileage/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/naileage/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/naileage/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/naileage/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/naileage/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/naileage/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/naileage/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/naileage/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/naileage/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/naileage/INFP.jpg',
      },
    ],
  },
  // weekendScheduleEng
  {
    info: {
      mainTitle: 'Color Video Personality Test',
      subTitle: `Let's find out my personality through the color video`,
      mainUrl: 'weekendScheduleEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/weekendScheduleEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/weekendScheduleEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'What about you when your friend asks you to play a game?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I want to play a board cafe or a room escape game.',
          },
          {
            type: 'I',
            score: 5,
            content: 'I want to play online games at home separately.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What about you when you go back home after having fun with your friends?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              "I had a great time! I think I'll go home, wash up, and get some rest.",
          },
          {
            type: 'I',
            score: 5,
            content:
              "I'm tired. As soon as I get home, I'll wash up and spend some alone time.",
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What about you when you have a small talk with your friend at a cafe?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I think it's great to hang out with friends.`,
          },
          {
            type: 'I',
            score: 5,
            content: `I feel drained and want to go home quickly.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          "What about you when the package arrives that you don't remember when you ordered it?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Check the attached invoice and try to remember it.`,
          },
          {
            type: 'N',
            score: 5,
            content: `I imagine various scenarios, like whether it could be sent by a criminal.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What about you when you find a strange statue on the street?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I think about the feeling of things as they appear.',
          },
          {
            type: 'N',
            score: 5,
            content: `I think a lot about this, it's unusual, it's like an alien, etc.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What about you when you get to make a statement as a witness to a car accident?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I explain the events that I witnessed objectively.`,
          },
          {
            type: 'N',
            score: 5,
            content: `I use a metaphor to describe an event I witnessed.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `When you damage a friend's cherished item, what do you do?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'I explain the situation that led to the damage and then offer an apology.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'I apologize first to ease their feelings and then explain the situation.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What about you when you hear a whimper while watching a sad movie with a friend?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I watch movies regardless.`,
          },
          {
            type: 'F',
            score: 5,
            content: 'I also feel sadder.',
          },
        ],
      },
      {
        which: 'TF',
        question: `What about you when you give advice to a friend who acts young?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'I specifically mention the actions my friend took and tell them not to do that again.',
          },
          {
            type: 'F',
            score: 5,
            content:
              "I explain what went wrong in a way that doesn't make them feel bad.",
          },
        ],
      },
      {
        which: 'JP',
        question: 'What about you when a friend suddenly asks you to meet?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I say I can’t go because my schedule is already full.`,
          },
          {
            type: 'P',
            score: 5,
            content: `I decide whether to go out based on how I’m feeling that day.`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          "What about you when asked how you handle housework that you couldn't do on weekdays?",
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I systematically organize my backlog and put it into practice.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'I start household chores when I have spare time or when it’s a crucial time to do them.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What about you when a friend asks, "What should we meet and eat?"',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I ask about the preferred menu and then find a suitable restaurant based on that.',
          },
          {
            type: 'P',
            score: 5,
            content:
              "I'm going to make a rough decision on the food and then meet on the same day to decide.",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/weekendScheduleEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/weekendScheduleEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/weekendScheduleEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/weekendScheduleEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/weekendScheduleEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/weekendScheduleEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/weekendScheduleEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/weekendScheduleEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/weekendScheduleEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/weekendScheduleEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/weekendScheduleEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/weekendScheduleEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/weekendScheduleEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/weekendScheduleEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/weekendScheduleEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/weekendScheduleEng/INFP.jpg',
      },
    ],
  },
  // weekendSchedule
  {
    info: {
      mainTitle: '주말 계획표 성격 테스트',
      subTitle: '주말 계획표 속 적혀 있는 성격들을 확인해 보자',
      mainUrl: 'weekendSchedule',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/weekendSchedule-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/weekendSchedule-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '친구가 게임을 하자고 하였을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '보드 카페나 방 탈출 게임을 하자고 한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '각자 집에서 온라인 게임을 하자고 한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구들과 즐거운 시간을 보낸 후 집에 돌아갈 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '재미있게 놀았네 집에 가서 씻고 자야겠다.',
          },
          {
            type: 'I',
            score: 5,
            content: '피곤해 집에 가자마자 씻고 혼자만의 시간을 보내야겠다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '카페에서 친구와 스몰토크를 할 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `역시 친구를 만나서 노는 것은 좋다고 생각한다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `기 빨리고 집에 빨리 가고 싶다고 생각한다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '언제 주문했는지 기억나지 않는 택배가 도착하였을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `붙어있는 송장을 확인하며 기억을 더듬어 본다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `범죄자가 보낸 것은 아닐까 등 여러 상황을 상상해 본다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '길거리에서 이상한 동상을 발견하였을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '보이는 그대로의 느낌을 생각한다.',
          },
          {
            type: 'N',
            score: 5,
            content: `이거 특이하네 외계인 같기도 하고 등 많은 생각을 한다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '교통사고 목격자로 진술을 하게 되었을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `객관적으로 목격하였던 사건을 그대로 설명한다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `비유를 사용하며 목격하였던 사건을 설명한다.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 아끼는 물건을 망가뜨렸을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '망가뜨리게 된 상황 설명을 한 후 사과를 건넨다.',
          },
          {
            type: 'F',
            score: 5,
            content: '먼저 사과를 하여 기분을 풀어주고 상황 설명을 한다.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '친구와 슬픈 영화를 보던 중 훌쩍이는 소리가 들렸을 때 당신은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `별 상관하지 않고 영화를 감상한다.`,
          },
          {
            type: 'F',
            score: 5,
            content: '덩달아 더 슬퍼지는 느낌을 받는다.',
          },
        ],
      },
      {
        which: 'TF',
        question: `어리게 행동하는 친구에게 조언을 해줄 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '정확하게 친구가 했던 행동들을 말하며 그러지 말라고 한다. ',
          },
          {
            type: 'F',
            score: 5,
            content: '무엇이 잘못됐는지 기분 나쁘지 않게 말해준다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구가 갑자기 만나자고 연락이 했을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `이미 계획이 꽉 차 있기에 안된다고 말한다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `그날 컨디션에 따라 나갈지 말지를 결정한다.`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          '평일에 하지 못한 집안일은 어떻게 처리하는지 질문하였을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '체계적으로 밀린 집안일을 정리해 보고 실행으로 옮긴다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '시간이 남아돌거나 꼭 해야 할 시기일 때 집안일을 시작한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구가 “만나서 뭐 먹을까” 하고 질문하였을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '먹고 싶은 메뉴를 물어본 후 그에 맞는 음식점을 정리해본다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '먹고 싶은 음식만 대략적으로 정한 후 당일에 만나서 결정하자고 한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/weekendSchedule/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/weekendSchedule/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/weekendSchedule/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/weekendSchedule/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/weekendSchedule/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/weekendSchedule/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/weekendSchedule/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/weekendSchedule/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/weekendSchedule/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/weekendSchedule/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/weekendSchedule/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/weekendSchedule/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/weekendSchedule/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/weekendSchedule/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/weekendSchedule/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/weekendSchedule/INFP.jpg',
      },
    ],
  },
  // youAndI
  {
    info: {
      mainTitle: '유앤아이 퍼스널 테스트',
      subTitle: '너와 내가 생각하는 나의 성격은?',
      mainUrl: 'youAndI',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/youAndI-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/youAndI-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '내가 남에게 자주 듣는 말은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '그렇게 약속 많으면 안 피곤해? ',
          },
          {
            type: 'I',
            score: 5,
            content: '그렇게 집에만 있으면 안 심심해?',
          },
        ],
      },
      {
        which: 'EI',
        question: '조용하고 차분한 분위기 속의 실내에 오래 있으면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '지루하고 따분하고 이제 그만 나가고 싶음 ',
          },
          {
            type: 'I',
            score: 5,
            content: '마음이 진정되고 편안함과 안정감을 느낌 ',
          },
        ],
      },
      {
        which: 'EI',
        question: '사람들이 많아서 북적거리고 시끄러운 거리를 보면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `여기 사람 많다! 가보자!`,
          },
          {
            type: 'I',
            score: 5,
            content: `어우 기 빨려.. 돌아서 가자 `,
          },
        ],
      },
      {
        which: 'SN',
        question: '나는 남의 말을?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `내가 직접 겪은 게 아니면 잘 안 믿음 `,
          },
          {
            type: 'N',
            score: 5,
            content: `혹시나 진짜 아냐..? 하고 잘 믿음`,
          },
        ],
      },
      {
        which: 'SN',
        question: '자주 가던 식당에서 새로운 메뉴가 출시됐다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '생각할 것도 없이 원래 먹던 거 ',
          },
          {
            type: 'N',
            score: 5,
            content: `맛이 궁금하기 때문에 새로 나온 메뉴 `,
          },
        ],
      },
      {
        which: 'SN',
        question: '어떤 선택을 해야 할 때 보통 나는 어떨까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `어떤 선택이 이득일지 생각해 보고 결정 `,
          },
          {
            type: 'N',
            score: 5,
            content: `이 선택을 하면 미래에 어떻게 될지 상상해 본 후 결정 `,
          },
        ],
      },
      {
        which: 'TF',
        question: `다른 사람들이 보는 나는 어떨까?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '냉정하고 이성적이며 똑 부러진 나 ',
          },
          {
            type: 'F',
            score: 5,
            content: '따듯하고 친근하며 감성적인 나 ',
          },
        ],
      },
      {
        which: 'TF',
        question: '친한 친구가 결혼한다며 청첩장을 준다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `축하한다며 날짜는 언제인지 물어봄`,
          },
          {
            type: 'F',
            score: 5,
            content: '청첩장을 보는 순간 눈물이 나옴 ',
          },
        ],
      },
      {
        which: 'TF',
        question: `나는 타인을 위해서?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '항상 솔직하게 얘기한다 ',
          },
          {
            type: 'F',
            score: 5,
            content: '착한 거짓말을 한다 ',
          },
        ],
      },
      {
        which: 'JP',
        question: '내 방에 내 물건들은 보통?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `항상 있던 제자리에 `,
          },
          {
            type: 'P',
            score: 5,
            content: `자유분방 이곳저곳 `,
          },
        ],
      },
      {
        which: 'JP',
        question: '매사 즉흥적이고 게으른 친구를 볼 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '답답하고 이해가 안감',
          },
          {
            type: 'P',
            score: 5,
            content: '그럴 수 있다고 생각함 ',
          },
        ],
      },
      {
        which: 'JP',
        question: '뭔가 중요한 일을 시작하기 전 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '사소한 것 하나까지 리스트를 만들어 체크하며 준비 ',
          },
          {
            type: 'P',
            score: 5,
            content: '필요한 건 시작하면 알게 된다고 생각하며 대략 준비',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/youAndI/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/youAndI/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/youAndI/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/youAndI/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/youAndI/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/youAndI/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/youAndI/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/youAndI/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/youAndI/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/youAndI/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/youAndI/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/youAndI/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/youAndI/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/youAndI/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/youAndI/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/youAndI/INFP.jpg',
      },
    ],
  },
  // blogChar
  {
    info: {
      mainTitle: '블로그 성격 테스트',
      subTitle: '블로그로 보는 나의 성격은?',
      mainUrl: 'blogChar',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/blogChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/blogChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '블로그 활동을 하는 나는 어떤 편에 더 가까울까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '열심히 이웃도 걸고 댓글도 달고 활발한 편',
          },
          {
            type: 'I',
            score: 5,
            content: '다른 블로그 염탐하고 블로그 하는 거 티 안 내는 편',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '주말에 쉬고 있는데 친구에게 나오라는 전화를 받게 되었다 나의 반응은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '전화도 반갑고 나가는 것도 반갑고',
          },
          {
            type: 'I',
            score: 5,
            content: '전화는 반갑지만 나가는 건 거절...',
          },
        ],
      },
      {
        which: 'EI',
        question: '둘 중 나와 더 어울리는 단어는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `밖, 친구, 카페, 술, 모임`,
          },
          {
            type: 'I',
            score: 5,
            content: `집, 침대, 스마트폰, 유튜브, 넷플릭스`,
          },
        ],
      },
      {
        which: 'SN',
        question: '아끼던 기타가 젤리로 변했다는 질문에 대한 나의 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `음.. 상상하기도 힘들다`,
          },
          {
            type: 'N',
            score: 5,
            content: `와.. 아끼던 기타가 젤리?.. 먹을 수 있는 건가? (무한 상상 시작)`,
          },
        ],
      },
      {
        which: 'SN',
        question: '식당에 가서 요청한 반찬이 안 나왔을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '빨리 먹고 싶은데 왜 안 나오지;;',
          },
          {
            type: 'N',
            score: 5,
            content: `셀프인가..? 왜 안 주시지 까먹으셨나? 물은 셀프인가?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '나는 ‘만약에’ 라는 질문을 좋아하는 편인가?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `아니다`,
          },
          {
            type: 'N',
            score: 5,
            content: `그렇다`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 고민을 털어놓을 때 나는 보통 어떤 쪽인가?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '119 구조대(해결책)',
          },
          {
            type: 'F',
            score: 5,
            content: '친절한 상담사(공감과 위로)',
          },
        ],
      },
      {
        which: 'TF',
        question: '나를 표현하자면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `똑 부러지는 사람`,
          },
          {
            type: 'F',
            score: 5,
            content: '다정한 사람',
          },
        ],
      },
      {
        which: 'TF',
        question: `운전면허 시험을 치고서 내가 듣고 싶은 말은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '오 잘했네',
          },
          {
            type: 'F',
            score: 5,
            content: '수고했어 고생 많았어 ㅠㅠ',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 블로그를 한다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `주기적으로 업로드를 계획적으로 해준다`,
          },
          {
            type: 'P',
            score: 5,
            content: `생각날 때만 한 번씩 업로드한다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '퇴근하고 집에 간 뒤 나만의 생활 루트가 정해져 있는가?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '그렇다',
          },
          {
            type: 'P',
            score: 5,
            content: '아니다',
          },
        ],
      },
      {
        which: 'JP',
        question: '쉬고 있을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '할 일은 대략적으로 해두고 쉬는 중',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 쉬는 중~ ㅎㅎ',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/blogChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/blogChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/blogChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/blogChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/blogChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/blogChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/blogChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/blogChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/blogChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/blogChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/blogChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/blogChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/blogChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/blogChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/blogChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/blogChar/INFP.jpg',
      },
    ],
  },
  // catoonChar
  {
    info: {
      mainTitle: '카툰 성격 테스트',
      subTitle: '카툰을 통해 남이 보고 있는 성격을 알아보자!',
      mainUrl: 'catoonChar',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/catoonChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/catoonChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '선호하는 술집의 분위기를 질문했을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '자유로운 분위기의 술집',
          },
          {
            type: 'I',
            score: 5,
            content: '조용하게 이야기 나눌 수 있는 술집',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구들과 어떤 관계를 형성하는가 질문했을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '소수 친구들과 가까운 관계를 형성하는 편',
          },
          {
            type: 'I',
            score: 5,
            content: '많은 친구들과 얕은 관계를 형성하는 편',
          },
        ],
      },
      {
        which: 'EI',
        question: '새로운 친구와의 만남을 제안했을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `새로운 사람은 환영이야 긍정적으로 대답을 한다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `어색한 분위기가 예상돼 부정적으로 대답을 한다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '자주 가던 식당에서 갑자기 귤 디저트를 줬을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `귤이네 맛있겠다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `갑자기 귤? 부모님이 귤 농사를 하시나 등 다양한 상상을 한다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구가 반복적으로 만약에 질문을 하였을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '지겨워하며 만약에는 없다고 말한다.',
          },
          {
            type: 'N',
            score: 5,
            content: `진지하게 상상해 보고 답을 말해준다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구가 주말에 뭐 했냐고 물어봤을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `그날 한 것을 있는 그대로 말한다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `주말 날씨를 말하거나 그때 느꼈던 감정을 함께 말한다.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 누가 봐도 이상한 내용의 부탁을 간절히 요청했을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '바로 내용에 대해 물어본다.',
          },
          {
            type: 'F',
            score: 5,
            content: '꺼림직하지만 간절히 요청하니 일단 수락한다.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          "만약 성격이 좋지 않은 친구가 '나.. 성격 안 좋아?'라고 물어봤을 때 당신은?",
        answers: [
          {
            type: 'T',
            score: 2,
            content: `냉정하게 자신이 느끼는 모습을 말해준다.`,
          },
          {
            type: 'F',
            score: 5,
            content: '일단 부정을 한 후 기분이 상하지 않게 말해준다.',
          },
        ],
      },
      {
        which: 'TF',
        question: `울면서 고민 상담을 하는 친구에게 위로를 건넬 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '울지 말라고 얘기하며 현실적인 위로를 건넨다.',
          },
          {
            type: 'F',
            score: 5,
            content: '감정이입을 한 체 적극적으로 위로를 건넨다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '차를 끌고 주차장이 좁은 놀이동산에 가게 되었을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `주변에 다른 주차장이 있는지 살펴보고 간다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `도착해서 생각하자는 마인드로 일단 간다.`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          '친구와 약속을 한 날짜에 비 소식을 전날 밤 알게 됐을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '계획을 수정하지 않고 대비하는 방향으로 행동을 취한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '아침에 일어나서 날씨를 확인해 보고 취소 여부를 결정한다.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '여행 계획을 세우기로 했던 친구가 까먹었다고 말했을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '화가 난 체 혹시 몰라 미리 계획을 세워두었던 일정을 말한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '어쩔 수 없다 말하고 무계획 여행을 하자고 제안한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/catoonChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/catoonChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/catoonChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/catoonChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/catoonChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/catoonChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/catoonChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/catoonChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/catoonChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/catoonChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/catoonChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/catoonChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/catoonChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/catoonChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/catoonChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/catoonChar/INFP.jpg',
      },
    ],
  },
  // switchOnOff
  {
    info: {
      mainTitle: '스위치 ON OFF 테스트',
      subTitle: '어떤 성격을 ON 하고 OFF를 하는 것이 좋을까?',
      mainUrl: 'switchOnOff',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/switchOnOff-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/switchOnOff-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '지인의 파티에 초대된 나는 어떤 모습을 하고 있을까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '처음 보는 사람과도 스몰토크 시전하며 여기저기 다니느라고 바쁘다.',
          },
          {
            type: 'I',
            score: 5,
            content: '조용히 박수만 치고 이야기를 듣고 호응해 주고 온다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '진행자가 질문을 던졌을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '못 먹어도 go! 일단 자리에서 일어나서 돌진한다.',
          },
          {
            type: 'I',
            score: 5,
            content:
              '일단 곰곰이 생각해 보고 정답이라는 확신이 들면 자리에서 일어난다.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '과제를 위해 조원들끼리 바닥에 삼삼오오 모여서 앉았다. 이때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `짧은 시간이지만 벽에 기대서 있거나 왔다 갔다 하는 등 앉을 생각이 없어 보인다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `엉덩이가 무거운지 자리에만 앉아있는다.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '퇴근길 문득 하늘에 떠 있는 여객기를 발견했다. 이때 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `비행기 보니 나도 여행 가고 싶다!`,
          },
          {
            type: 'N',
            score: 5,
            content: `저 비행기는 어디서 출발해서 도착지가 어디일까? 무사히 안착하겠지?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '쇼핑몰을 차리게 되었다. 이때 하는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '이거 체크하고 저거 체크하고 내일 와서 할 일을 생각해 본다.',
          },
          {
            type: 'N',
            score: 5,
            content: `쇼핑몰이 대박 나서 여러 명의 직원을 두는 생각을 해본다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '작사를 지어야 하는 상황이다. 이때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `예전에 내가 직접 경험했던 것을 가져와서 써본다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `한 장면을 자기 나름대로 해석한 후 머릿속에서 재구성해 본다.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 갑자기 “너 이런 행동 진짜 좀 하지 마”라고 했을 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '“왜? 뭔가 문제야?”라고 바로 물어본다.',
          },
          {
            type: 'F',
            score: 5,
            content: '“그렇게 차갑게 말할 필요 없잖아” 하고 작게 이야기한다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '상대방이 “왜 이렇게 한 거야?”라고 물었을 때 드는 생각은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `기분 좋고 나에게 관심 있어서 하는 행동이구나라고 생각한다.`,
          },
          {
            type: 'F',
            score: 5,
            content: '뭔가 취조 당하는 느낌과 동시에 경계태세 발동한다.',
          },
        ],
      },
      {
        which: 'TF',
        question: `연장한 손톱이 들려서 통증이 온다는 친구에게 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '어쩌다가 그렇게 됐어? 다시 샵에 가기로 한 거야?',
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮아? 많이 아플 거 같아..',
          },
        ],
      },
      {
        which: 'JP',
        question: '일 막바지에 있는 나의 모습에 더 가까운 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `이걸 완벽히 마무리하는 나 너무 멋지다!`,
          },
          {
            type: 'P',
            score: 5,
            content: `이걸 완벽히 수습하는 나 너무 멋지다!`,
          },
        ],
      },
      {
        which: 'JP',
        question: '과제 제출 과정에서 나랑 더 가까운 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '계획 세우고 중간에 미루고 싶을 땐 계획적으로 미룬 후 마지막에 여유롭게 제출',
          },
          {
            type: 'P',
            score: 5,
            content:
              '일단 미루고 생각하고 다른 거 해볼까 싶다가 망했다는 생각에 마지막에 간신히 제출',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '친구가 점심쯤에 만나서 파스타 먹고 인생 네 컷 찍고 카페 갔다가 집! 이런 계획을 들었을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '몇 시쯤이 아닌 정확히 몇 시에 만나고 식당 예약은 했는지 웨이팅은 있을지 등 자세한 사항들이 궁금해진다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '아주 좋은 계획이고 아무런 문제 없이 아주 잘 짰다고 생각한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/switchOnOff/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/switchOnOff/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/switchOnOff/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/switchOnOff/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/switchOnOff/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/switchOnOff/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/switchOnOff/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/switchOnOff/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/switchOnOff/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/switchOnOff/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/switchOnOff/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/switchOnOff/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/switchOnOff/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/switchOnOff/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/switchOnOff/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/switchOnOff/INFP.jpg',
      },
    ],
  },
  // colorVideoEng
  {
    info: {
      mainTitle: 'Color Video Personality Test',
      subTitle: `Let's find out my personality through the color video`,
      mainUrl: 'colorVideoEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/colorVideoEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/colorVideoEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'A friend who suddenly came to my house!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I just happened to be bored, so hurry up and come in!',
          },
          {
            type: 'I',
            score: 5,
            content: 'I was going to rest... Why did you come all of a sudden?',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'If you have a group appointment with your friends next week?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I hope that day comes soon',
          },
          {
            type: 'I',
            score: 5,
            content: "I don't want to go with an excuse",
          },
        ],
      },
      {
        which: 'EI',
        question: "What do you often think when you're outside?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I'm so happy. I want to play more`,
          },
          {
            type: 'I',
            score: 5,
            content: `Let me go. I want to go home`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What is more important when arguing with someone?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `What's happening is more important`,
          },
          {
            type: 'N',
            score: 5,
            content: `How I felt is more important`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What about me when a video movie ends with an open ending?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: "It's an open ending",
          },
          {
            type: 'N',
            score: 5,
            content: `Is it a happy ending or a sad ending?`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What is more interesting about the conversation?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `A story that is happening realistically`,
          },
          {
            type: 'N',
            score: 5,
            content: `An improbable fantasy story`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What about me when I'm stressed out?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I try to relieve stress on my own',
          },
          {
            type: 'F',
            score: 5,
            content: 'Confess to someone and consult them',
          },
        ],
      },
      {
        which: 'TF',
        question: 'What about me if I give out flyers on the street?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I won't take it`,
          },
          {
            type: 'F',
            score: 5,
            content: "I'll take it all",
          },
        ],
      },
      {
        which: 'TF',
        question: `To a friend who says she's too tired while doing something together, what about me?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              "Let's get this over with and take a break. I can't help it",
          },
          {
            type: 'F',
            score: 5,
            content: 'Are you tired? Shall we take a break?',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Found a cafe looking good atmosphere while walking down the street!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `There's a place I was supposed to go, so I should come here next time`,
          },
          {
            type: 'P',
            score: 5,
            content: `Shall we go here instead of the cafe we were supposed to go to?`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'Which one is more important to me?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Whether you will be able to achieve your goal in the end',
          },
          {
            type: 'P',
            score: 5,
            content: 'Whether my curiosity can be satisfied',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What about me when I check the mail that came to me?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I check my mail right away and do what I need to do',
          },
          {
            type: 'P',
            score: 5,
            content:
              'I read other mails while checking my mail and see this and that',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorVideoEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorVideoEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/colorVideoEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/colorVideoEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorVideoEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/colorVideoEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/colorVideoEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/colorVideoEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorVideoEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorVideoEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/colorVideoEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/colorVideoEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorVideoEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/colorVideoEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/colorVideoEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/colorVideoEng/INFP.jpg',
      },
    ],
  },
  // colorVideo
  {
    info: {
      mainTitle: '컬러 비디오 성격 테스트',
      subTitle: '컬러 비디오로 알아보는 내 성격은?',
      mainUrl: 'colorVideo',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/colorVideo-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/colorVideo-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          '함께 비디오를 보자며 갑자기 집 앞으로 찾아온 친구! 내 반응은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '오 뭐야~ 심심했는데 오히려 좋아 얼른 들어와!',
          },
          {
            type: 'I',
            score: 5,
            content: '쉬려고 했는데.. 갑자기 왜 왔어..?',
          },
        ],
      },
      {
        which: 'EI',
        question: '다음 주에 단체 모임 약속이 잡혔다면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '그날이 빨리 왔으면 좋겠음',
          },
          {
            type: 'I',
            score: 5,
            content: '핑계 대고 안 나갈까 고민함',
          },
        ],
      },
      {
        which: 'EI',
        question: '밖에 있을 때 자주 하는 생각을 고르라면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `너무 행복해 더 놀고 싶다 집 가기 아쉬워ㅜ`,
          },
          {
            type: 'I',
            score: 5,
            content: `이제 그만.. 날 보내줘… 집에 가고 싶어..`,
          },
        ],
      },
      {
        which: 'SN',
        question: '누군가와 다툴 때 더 중요한 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `지금 현재 일어난 사실이 중요`,
          },
          {
            type: 'N',
            score: 5,
            content: `내가 어떻게 느꼈는지가 더 중요`,
          },
        ],
      },
      {
        which: 'SN',
        question: '비디오 영화가 열린 결말로 끝이 났을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '열린 결말 엔딩이네',
          },
          {
            type: 'N',
            score: 5,
            content: `그래서 이렇게 저렇게 돼서 해피엔딩이겠지? 아니면 새드 쪽일까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '대화할 때 내가 더 흥미를 느끼는 내용은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `현실적으로 일어나고 있는 이야기`,
          },
          {
            type: 'N',
            score: 5,
            content: `일어날 수 없는 판타지스러운 이야기`,
          },
        ],
      },
      {
        which: 'TF',
        question: `고민이 있거나 스트레스를 받을 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '스스로 해결해 보며 굳이 털어놓진 않음',
          },
          {
            type: 'F',
            score: 5,
            content: '누군가에게 고민을 털어놓으며 상담함',
          },
        ],
      },
      {
        which: 'TF',
        question: '길에서 전단지를 나누어 준다면 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `안 받는다`,
          },
          {
            type: 'F',
            score: 5,
            content: '다 받는다',
          },
        ],
      },
      {
        which: 'TF',
        question: `함께 무언가를 하는 도중 너무 피곤하다는 친구에게 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '이건 끝내고 쉬어야지 어쩔 수 없어',
          },
          {
            type: 'F',
            score: 5,
            content: '많이 피곤해? 그럼 우리 같이 조금만 쉬었다가 할까?',
          },
        ],
      },
      {
        which: 'JP',
        question: '길을 가다가 분위기가 좋아보이는 카페를 발견했다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `가기로 했던 곳이 있으니까 여긴 일정 보고 다시 와야겠다`,
          },
          {
            type: 'P',
            score: 5,
            content: `오 가기로 했던 곳 나중에 가고 여기 가 볼래?`,
          },
        ],
      },
      {
        which: 'JP',
        question: '둘 중 내게 더 중요한 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '결국 목표를 달성할 수 있을 것 인지',
          },
          {
            type: 'P',
            score: 5,
            content: '내 호기심이 충족될 수 있을 것 인지',
          },
        ],
      },
      {
        which: 'JP',
        question: '나에게 온 메일을 확인할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '바로 메일을 확인하고 할 일을 한다',
          },
          {
            type: 'P',
            score: 5,
            content: '메일을 확인하다가 다른 메일도 읽고 이것저것 본다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/colorVideo/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/colorVideo/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/colorVideo/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/colorVideo/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/colorVideo/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/colorVideo/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/colorVideo/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/colorVideo/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/colorVideo/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/colorVideo/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/colorVideo/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/colorVideo/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/colorVideo/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/colorVideo/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/colorVideo/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/colorVideo/INFP.jpg',
      },
    ],
  },
  // lovePepero
  {
    info: {
      mainTitle: '사랑의 빼빼로 연애 레시피',
      subTitle: '레시피로 보는 나의 연애는?',
      mainUrl: 'lovePepero',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/lovePepero-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/lovePepero-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '빼빼로데이에 빼빼로를 왕창 받았다 나는 어떤 편인가?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '받은 빼빼로를 티 내며 친구들과 함께 나눠먹는다.',
          },
          {
            type: 'I',
            score: 5,
            content: '티 안 내고 조용히 집에 가져가서 가족들과 먹는다.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '친구가 같이 빼빼로를 만들자고 우리 집으로 오라고 할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '같이 만들면 재미도 두 배! 후다닥 친구 집으로 간다.',
          },
          {
            type: 'I',
            score: 5,
            content: '조용하게 혼자 집중해서 만들고 싶다',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구들과 빼빼로를 함께 만들고 집에 가는 길 나의 생각은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `같이 만드니까 더 재밌다~!! 룰루랄라`,
          },
          {
            type: 'I',
            score: 5,
            content: `오우.. 기 빨려 얼른 가서 좀 쉬어야지`,
          },
        ],
      },
      {
        which: 'SN',
        question: '빼빼로를 만들 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `레시피 그대로 정량 그대로 만든다`,
          },
          {
            type: 'N',
            score: 5,
            content: `감잡고 대충 요령 있게 만든다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '빼빼로를 고를 때 나는 보통 어떤 쪽인가?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '예전에 먹어봤을 때 맛있었던 빼빼로를 고른다',
          },
          {
            type: 'N',
            score: 5,
            content: `먹어보지 못한 새로운 맛의 신상 빼빼로를 고른다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '화분에 빼빼로가 하나 꽂혀있는 걸 보았을 때 나의 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `누가 이런 짓을..`,
          },
          {
            type: 'N',
            score: 5,
            content: `왜 빼빼로가 화분에 꽂혀있지..? 먹어도 되는 건 가? 뭐지? 누가 장난친 건가?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친한 친구가 나한테만 빼빼로를 안 줬을 때 나의 생각은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '궁금해서 왜 안 주는지 물어보고 기분은 나쁘지만 금방 잊는다',
          },
          {
            type: 'F',
            score: 5,
            content:
              '몹시 서운하고 나에게 기분 나쁜 게 있나 하고 계속 생각한다',
          },
        ],
      },
      {
        which: 'TF',
        question: '빼빼로를 나눠주기로 한 나의 행동은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `눈치 안 보고 친한 애들한테만 준다`,
          },
          {
            type: 'F',
            score: 5,
            content: '혹시나 서운해할까 봐 안 친한 친구들에게도 준다',
          },
        ],
      },
      {
        which: 'TF',
        question: `빼빼로를 챙겨주지 않는 연인에 대한 나의 생각은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '별 신경 안 씀',
          },
          {
            type: 'F',
            score: 5,
            content: '살짝 서운..ㅠㅠ',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구랑 빼빼로를 만들기로 했을 때 나는 보통?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `어디서 언제 만들지 계획하고 비용도 생각해 본다`,
          },
          {
            type: 'P',
            score: 5,
            content: `일단 만들기 전날까지 별생각 없다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '나는 빼빼로를 만들 때 어떤가?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '무슨 모양으로 어떤 빼빼로를 만들지 구상한다',
          },
          {
            type: 'P',
            score: 5,
            content: '일단~ 만들고 본다!',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '빼빼로를 만들기로 했으나 틀어져서 빼빼로를 못 만들게 됐다 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '…다 계획해놨는데… ㅠ',
          },
          {
            type: 'P',
            score: 5,
            content: '그럼 하나 사야겠다!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/lovePepero/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/lovePepero/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/lovePepero/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/lovePepero/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/lovePepero/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/lovePepero/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/lovePepero/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/lovePepero/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/lovePepero/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/lovePepero/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/lovePepero/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/lovePepero/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/lovePepero/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/lovePepero/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/lovePepero/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/lovePepero/INFP.jpg',
      },
    ],
  },
  // cabinetCharEng
  {
    info: {
      mainTitle: 'Cabinet personality test',
      subTitle: `Let's find out your personality through the cabinet`,
      mainUrl: 'cabinetCharEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/cabinetCharEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/cabinetCharEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          'What about you when you have a chance to choose an empty seat?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: "Sit next to the person you haven't talked to",
          },
          {
            type: 'I',
            score: 5,
            content: 'Sits next to a close friend without hesitation',
          },
        ],
      },
      {
        which: 'EI',
        question:
          "What about you when a friend you don't remember said hello to?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: "I don't remember, but I'm greeted warmly",
          },
          {
            type: 'I',
            score: 5,
            content: 'Hesitates and receives an awkward greeting',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What do you do when you have a day off without any plans?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Looks through contacts to find someone to hang out with`,
          },
          {
            type: 'I',
            score: 5,
            content: `Relaxes in a comfortable space`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          "What about you when you don't get an answer after a while after sending an important mail?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I think the recipient did not check the email`,
          },
          {
            type: 'N',
            score: 5,
            content: `I get caught up in the idea that I might have entered the wrong email address`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'When all the items in the cabinet are gone, what do you do?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Ask the person in charge',
          },
          {
            type: 'N',
            score: 5,
            content: `I imagine scenarios of various situations`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What about you when your friend told you to follow him without thinking about anything?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I follow my friend who guides me without much thought`,
          },
          {
            type: 'N',
            score: 5,
            content: `I imagine various coping methods because I might be taken to a strange place`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What if I open the cabinet and there's a warning letter inside?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'I would ask the person who wrote the warning notice for the reason',
          },
          {
            type: 'F',
            score: 5,
            content:
              'I feel uneasy about the presence of someone holding a grudge',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'If a friend hid a gift for you in the cabinet, what would you do?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Say thank you and ask why you hid the gift`,
          },
          {
            type: 'F',
            score: 5,
            content:
              'I express emotions like happiness or being moved and convey gratitude',
          },
        ],
      },
      {
        which: 'TF',
        question: `What about you when you accidentally open someone else's cabinet?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I glance discreetly to figure out whose cabinet it is',
          },
          {
            type: 'F',
            score: 5,
            content: 'I hurriedly shut the door in case anyone sees it',
          },
        ],
      },
      {
        which: 'JP',
        question: 'When planning a trip with a friend, what do you do?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Calculate the distance of tourist attractions and efficiently plan the itinerary`,
          },
          {
            type: 'P',
            score: 5,
            content: `I quickly scan through tourist attractions and roughly organize them in my head`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'When you heard that your friend who was supposed to go on a trip with you would be late?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I proceed with the scheduled departure or adjust the itinerary accordingly',
          },
          {
            type: 'P',
            score: 5,
            content:
              "I don't know the details of the trip, so I wait for my friend",
          },
        ],
      },
      {
        which: 'JP',
        question: 'When asked how to organize the cabinet, how about you?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I tend to organize neatly to find what I need quickly',
          },
          {
            type: 'P',
            score: 5,
            content:
              'I tend to organize roughly and find what I need at that time',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetCharEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetCharEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetCharEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetCharEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetCharEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetCharEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetCharEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetCharEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetCharEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetCharEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetCharEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetCharEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetCharEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetCharEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetCharEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetCharEng/INFP.jpg',
      },
    ],
  },
  // cabinetChar
  {
    info: {
      mainTitle: '캐비닛 성격 테스트',
      subTitle: '캐비닛을 통해 성격을 알아보자',
      mainUrl: 'cabinetChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/cabinetChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/cabinetChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '빈자리를 고를 수 있는 기회가 생겼을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '말을 나눠보지 않았던 상대 옆에 앉는다.',
          },
          {
            type: 'I',
            score: 5,
            content: '고민도 없이 친한 사람 옆자리에 앉는다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '잘 기억 안나는 친구가 인사를 하였을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '기억은 안 나지만 반갑게 인사를 받는다.',
          },
          {
            type: 'I',
            score: 5,
            content: '머뭇거리다 어색하게 인사를 받는다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '쉬는 날 약속이 없을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `놀 사람을 찾기 위해 연락처를 살펴본다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `편안한 공간에서 휴식을 취한다.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '중요한 메일을 발송하고 난 후 시간이 지나도 답이 오지 않을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `수신자가 이메일을 확인하지 않은 것이라 생각한다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `잘못된 이메일 주소를 입력했을지도 모른다는 생각에 사로잡힌다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '캐비닛에 들어있던 물건들이 다 없어졌을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '관리하시는 분께 문의를 드린다.',
          },
          {
            type: 'N',
            score: 5,
            content: `여러 상황의 시나리오를 상상해 본다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구가 아무 생각 말고 자기를 따라오라고 하였을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `별 다른 생각없이 안내하는 친구를 따라간다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `이상한 곳으로 데려갈지도 모르니 여러 대처 방법을 상상해 본다.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `캐비닛을 열었더니 결투장이 있을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '결투장을 작성한 사람을 찾아 이유를 물어본다.',
          },
          {
            type: 'F',
            score: 5,
            content: '원한을 품고 있는 사람이 있는 것에 대해 불안해한다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 캐비닛에 선물을 숨겨 두었을 때 당신은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `감사 인사를 건네고 선물을 왜 숨겨 두었는지 물어본다.`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '기쁨이나 감동 등의 감정을 표현하고 감사의 마음을 전한다. ',
          },
        ],
      },
      {
        which: 'TF',
        question: `실수로 다른 사람의 캐비닛을 열어보게 되었을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '살짝 곁눈질해 어떤 사람의 캐비닛인지 파악한다.',
          },
          {
            type: 'F',
            score: 5,
            content: '누가 볼까 봐 황급히 문을 닫는다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구와 여행 계획을 세울 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `관광 명소들의 거리들을 계산하며 효율적으로 일정을 세운다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `관광 명소를 빠르게 훑어보고 대략적으로 머릿속으로 정리한다.`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          '함께 여행을 가기로 한 친구가 늦는다는 소식을 접했을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '예정된 일정대로 출발하거나 일정을 조정합니다.',
          },
          {
            type: 'P',
            score: 5,
            content: '여행의 세부 사항을 알지 못해 가만히 친구를 기다린다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '캐비닛 정리 방법에 대한 질문을 받았을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '필요한 것을 빨리 찾기 위해 깔끔하게 정리하는 편이다.',
          },
          {
            type: 'P',
            score: 5,
            content: '대충 정리해두고 그때그때 필요한 물건을 찾는 편이다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/cabinetChar/INFP.jpg',
      },
    ],
  },
  // receiptCharEng
  {
    info: {
      mainTitle: 'Receipt Personality Test',
      subTitle: `Let's find out the personality through the receipt`,
      mainUrl: 'receiptCharEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/receiptCharEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/receiptCharEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'What about you when you get to play with a lot of friends?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'Move from place to place and participate in many conversations',
          },
          {
            type: 'I',
            score: 5,
            content: 'Sit with a few friends and have conversations',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What about you when no employee comes no matter how long you wait in front of the counter?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Call the staff out loud',
          },
          {
            type: 'I',
            score: 5,
            content: 'Wait for the staff to approach you',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What about you when the host called you up on stage during the event?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I go on stage without hesitation`,
          },
          {
            type: 'I',
            score: 5,
            content: `Turn your eyes away or move to the corner by waving your hands`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What about you when you saw coffee spilling in front of the escalator?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Jump and climb without much thought`,
          },
          {
            type: 'N',
            score: 5,
            content: `Who spilled it? Imagine different situations, assuming that you will fall if you step on it incorrectly`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What is your reason for receiving the receipt?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I received it to check the purchase details and amount',
          },
          {
            type: 'N',
            score: 5,
            content: `When I saw the history of the wrong picture, I received it imagining how to deal with it`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'When you purchased an item and received a prize ticket, what would you do?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I think it's good to be able to apply for an unintended prize`,
          },
          {
            type: 'N',
            score: 5,
            content: `I imagine myself when I win the prize`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What are your thoughts when you find an employee being scolded in a corner?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "I don't feel anything",
          },
          {
            type: 'F',
            score: 5,
            content: ' I feel a bit sad for some reason',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What about you when you find an employee who responds with an unkind attitude?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Quietly report the situation to other employees and ask them to improve the problem`,
          },
          {
            type: 'F',
            score: 5,
            content:
              "I want to tell you the problem, but I'm holding it in because it might cause problems for the employee",
          },
        ],
      },
      {
        which: 'TF',
        question: `What about you when your close friend says she has a concern?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Listen quietly and tell them how to solve it with consolation',
          },
          {
            type: 'F',
            score: 5,
            content: 'I offer my sincere consolation to his response',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What about you before you leave an important appointment?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Try on several clothes in advance and choose carefully`,
          },
          {
            type: 'P',
            score: 5,
            content: `After thinking about what to wear, I prepare something to wear urgently before going to bed the day before`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          "What about you when a friend borrows something and doesn't give it back in time?",
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "I don't understand why I don't keep it when I set a certain period of time",
          },
          {
            type: 'P',
            score: 5,
            content:
              "I don't know if it's past, but I tell them to return it a long time later",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'When you first learned about the discount period thanks to a friend who works in the store?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Write down the discount period on your calendar and plan your spending',
          },
          {
            type: 'P',
            score: 5,
            content:
              'I remember it roughly and then visit it in a hurry when the last day approaches',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/receiptCharEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/receiptCharEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/receiptCharEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/receiptCharEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/receiptCharEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/receiptCharEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/receiptCharEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/receiptCharEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/receiptCharEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/receiptCharEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/receiptCharEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/receiptCharEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/receiptCharEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/receiptCharEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/receiptCharEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/receiptCharEng/INFP.jpg',
      },
    ],
  },
  // receiptChar
  {
    info: {
      mainTitle: '영수증 성격 테스트',
      subTitle: '영수증을 통해 성격을 알아보자',
      mainUrl: 'receiptChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/receiptChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/receiptChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '많은 친구들과 놀게 되었을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '여러 자리를 돌아다니며 이야기를 나눈다.',
          },
          {
            type: 'I',
            score: 5,
            content: '소수의 친구들과 자리를 잡고 이야기를 나눈다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '계산대 앞에서 아무리 기다려도 직원이 오지 않을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '큰 소리로 직원을 부른다.',
          },
          {
            type: 'I',
            score: 5,
            content: '멀리 있는 직원을 쳐다보며 다가오길 기다린다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '행사 구경 도중 사회자가 무대 위로 불러냈을 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `한 치의 망설임도 없이 무대 위로 올라간다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `손사래를 치며 시선을 다른 곳으로 돌리거나 구석으로 이동한다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '에스컬레이터 앞에 커피를 쏟아져 있는 것을 봤을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `별생각 하지 않고 뛰어넘어서 올라탄다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `누가 쏟은 거지? 잘못 밟으면 넘어지겠다 고 가정을 하며 여러 상황을 상상해 본다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '영수증을 받았을 때 당신의 이유는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '구매 내역과 금액을 확인하기 위해 받았다.',
          },
          {
            type: 'N',
            score: 5,
            content: `잘못 찍힌 내역을 보게 되었을 때 대처 방법을 상상하며 받았다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '물건을 구매하고 경품 응모권을 받았을 때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `의도하지 않았던 경품에 응모할 수 있어 좋다고 생각한다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `경품에 당첨되었을 때 내 모습을 상상해 본다.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `구석에서 혼나고 있는 직원을 발견하였을 때 드는 당신의 생각은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '아무 느낌도 들지 않는다.',
          },
          {
            type: 'F',
            score: 5,
            content: '왠지 짠한 느낌이 든다',
          },
        ],
      },
      {
        which: 'TF',
        question: '불 친절한 태도로 응대하는 직원을 발견하였을 때 당신은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `조용히 다른 직원에게 상황을 보고하고 문제를 개선하도록 요청한다.`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '문제를 말하고 싶지만 그로 인해 직원에게 문제가 생길 수도 있으니 참는다.',
          },
        ],
      },
      {
        which: 'TF',
        question: `친한 친구가 요즘 고민이 있다며 얘기를 꺼냈을 때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '조용히 들은 후 위로와 함께 해결 방법을 알려준다.',
          },
          {
            type: 'F',
            score: 5,
            content: '리액션과 함께 진심 어린 위로를 건넨다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '중요한 약속을 나가기 전 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `미리 여러 벌의 옷을 입어보고 신중히 결정하여 골라 둔다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `뭘 입지 고민만 하다 전날 자기 전에 급하게 입을 옷을 준비한다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '친구가 물건을 빌려 가서 기간 내에 돌려주지 않을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '일정한 기간을 정했는데 그걸 왜 지키지 않는 건지 이해할 수 없다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '기간이 지났는 지도 모르고 있다가 한참 뒤에 돌려 달라고 말한다.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '할인 기간을 매장에서 일하는 친구 덕분에 먼저 알게 되었을 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '캘린더에 할인 기간을 적어 두고 지출을 계획해 본다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '대충 머릿속에 새겨 놓고 행사 마지막이 다가올 때 급하게 방문한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/receiptChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/receiptChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/receiptChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/receiptChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/receiptChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/receiptChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/receiptChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/receiptChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/receiptChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/receiptChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/receiptChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/receiptChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/receiptChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/receiptChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/receiptChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/receiptChar/INFP.jpg',
      },
    ],
  },
  // checkMeSimpleEng
  {
    info: {
      mainTitle: 'Check me! Simple personality test',
      subTitle: `Let's check my personality`,
      mainUrl: 'checkMeSimpleEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/checkMeSimpleEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/checkMeSimpleEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'What if strangers call me to a place with a lot of people?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: "Let's get close! I'll run right away. It'll be fun!",
          },
          {
            type: 'I',
            score: 5,
            content:
              "I don't like it because it's uncomfortable. I'll go next time.",
          },
        ],
      },
      {
        which: 'EI',
        question: 'What is the moment when you felt the biggest happiness?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'The moment you gather outside and chat with your friends and play',
          },
          {
            type: 'I',
            score: 5,
            content: 'The moment I was outside and came home and lied down',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What is more common when having a conversation?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I often talk first and think later`,
          },
          {
            type: 'I',
            score: 5,
            content: `I can't talk because I think about it`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'A luxury car is making a noise on the road!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Wow, it's an expensive car. How much was it?`,
          },
          {
            type: 'N',
            score: 5,
            content: `Wow, what kind of life do I have to live to drive a car like that?`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What is the topic of conversation that interests me?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'Issues that are currently happening, issues that may arise',
          },
          {
            type: 'N',
            score: 5,
            content: `Fantasy that can't happen, endless questions`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'Walking on the Road! What if the floor was full of smelly fruits?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `If you step on it, the smell will be no joke. I think I should avoid it`,
          },
          {
            type: 'N',
            score: 5,
            content: `I accidentally stepped on it, so I was misunderstood as my smell, so I can imagine myself feeling unfair`,
          },
        ],
      },
      {
        which: 'TF',
        question: `A friend who had a stressful day today asks to meet!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Where are you going to meet? What do you want to eat?',
          },
          {
            type: 'F',
            score: 5,
            content: "What happened? Let's meet and relieve our stress!",
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What do you think when you get a compliment that says, "Well done. You put in a lot of effort?"',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I don't think I did a good job`,
          },
          {
            type: 'F',
            score: 5,
            content: "I think I've been recognized for my hard work",
          },
        ],
      },
      {
        which: 'TF',
        question: `What about me when two friends get into an emotional dispute with each other?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "I don't care because I think they'll make up well",
          },
          {
            type: 'F',
            score: 5,
            content: 'Trying in the middle so that you two can reconcile well',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What if the restaurant you were going to was closed?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I should have looked it up in advance, but I'm upset that the schedule went wrong`,
          },
          {
            type: 'P',
            score: 5,
            content: `I'm used to this kind of thing, so I'm looking for another restaurant right away`,
          },
        ],
      },
      {
        which: 'JP',
        question: "I couldn't keep any of the things I planned today",
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I get to blame myself',
          },
          {
            type: 'P',
            score: 5,
            content: 'We can put it off until tomorrow',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What is my life that I want?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'A life with a regular, stable, and set routine',
          },
          {
            type: 'P',
            score: 5,
            content: 'Free and Always a New Life',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimpleEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimpleEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimpleEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimpleEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimpleEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimpleEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimpleEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimpleEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimpleEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimpleEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimpleEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimpleEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimpleEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimpleEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimpleEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimpleEng/INFP.jpg',
      },
    ],
  },
  // checkMeSimple
  {
    info: {
      mainTitle: '체크 미! 심플 성격 테스트',
      subTitle: '내 성격을 체크해가며 알아보자!',
      mainUrl: 'checkMeSimple',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/checkMeSimple-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/checkMeSimple-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '처음 보는 사람들이 많은 장소에 나를 부른다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친해지면 되지! 바로 달려갈게 재밌겠다!',
          },
          {
            type: 'I',
            score: 5,
            content: '불편해서 싫어 난 다음에 갈게..',
          },
        ],
      },
      {
        which: 'EI',
        question: '내가 가장 큰 행복을 느끼는 순간을 체크 하라면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '밖에서 친구들과 모여 수다를 떨며 노는 순간',
          },
          {
            type: 'I',
            score: 5,
            content: '오랜 시간 밖에 있다가 집으로 돌아와서 눕는 순간',
          },
        ],
      },
      {
        which: 'EI',
        question: '대화를 할 때 어떤 경우가 더 많을까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `말로 먼저 내뱉고 후에 생각하는 경우`,
          },
          {
            type: 'I',
            score: 5,
            content: `속으로 생각만 하다가 말을 못 하는 경우`,
          },
        ],
      },
      {
        which: 'SN',
        question: '도로에 고급 차가 부앙 소리를 내며 지나간다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `와 비싼 차 모네 얼마였더라`,
          },
          {
            type: 'N',
            score: 5,
            content: `와 도대체 어떤 삶을 살아야 저런 차를 모는 걸까..? 근데 카푸어일 수도!`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내 흥미를 돋우는 대화 주제는 무엇일까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '현재 일어나고 있는 이슈들, 일어날 수 있는 이슈들',
          },
          {
            type: 'N',
            score: 5,
            content: `일어날 수 없는 판타지, 끝도 없는 만약에 질문`,
          },
        ],
      },
      {
        which: 'SN',
        question: '길을 걷는 중! 앞에 바닥이 온통 은행 밭이라면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `밟으면 냄새 장난 아니겠다 피해 가야지 하고 생각함`,
          },
          {
            type: 'N',
            score: 5,
            content: `실수로 밟아버려서 내 냄새라고 오해받아 억울해하는 내 모습이 상상됨`,
          },
        ],
      },
      {
        which: 'TF',
        question: `오늘 너무 스트레스 받는 일이 있었다며 만나자는 친구!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '어디서 만날 건데? 밥은 뭐 먹을래',
          },
          {
            type: 'F',
            score: 5,
            content: '왜 또 무슨 일 있어?? 그래 만나서 스트레스 풀자!',
          },
        ],
      },
      {
        which: 'TF',
        question: '고생 많았어! 열심히 하더라! 라는 칭찬을 받으면 내 생각은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `열심히만 하고 잘하지는 못했다고 생각함`,
          },
          {
            type: 'F',
            score: 5,
            content: '열심히 노력한 걸 인정받았다고 생각함',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구 두 명이 서로 감정이 상해 다툰 상황에서 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '알아서 잘 풀겠지 하고 크게 신경 쓰지 않음',
          },
          {
            type: 'F',
            score: 5,
            content: '둘이 잘 화해할 수 있도록 중간에서 계속 노력함',
          },
        ],
      },
      {
        which: 'JP',
        question: '먹으려고 했던 식당이 휴무로 문을 닫았다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `미리 알아보고 왔어야 하는데.. 일정이 틀어져서 기분이 언짢아짐`,
          },
          {
            type: 'P',
            score: 5,
            content: `이런 일이 익숙해서 바로 다른 식당을 찾아봄`,
          },
        ],
      },
      {
        which: 'JP',
        question: '오늘은 계획했던 일들을 하나도 지키지 못했다.',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '내 자신을 자책하게 된다',
          },
          {
            type: 'P',
            score: 5,
            content: '그럴 수 있다고 생각한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 원하는 나의 삶은 무엇일까?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '규칙적이고 안정적이며 정해진 루틴이 있는 삶',
          },
          {
            type: 'P',
            score: 5,
            content: '자유로우며 늘 새롭고 스펙타클한 삶',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimple/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimple/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimple/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimple/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimple/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimple/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimple/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimple/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimple/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimple/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimple/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimple/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimple/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimple/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimple/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/checkMeSimple/INFP.jpg',
      },
    ],
  },
  // askChar
  {
    info: {
      mainTitle: '무물보 성격 테스트',
      subTitle: '무물보로 보는 나',
      mainUrl: 'askChar',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/askChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/askChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '친구들과 모이는 것에 대한 나의 생각은',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '모인다=재밌겠다!',
          },
          {
            type: 'I',
            score: 5,
            content: '모인다=벌써 힘들다!',
          },
        ],
      },
      {
        which: 'EI',
        question: '나에게 혼자 있는 시간은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '외로운 시간..',
          },
          {
            type: 'I',
            score: 5,
            content: '이로운 시간..',
          },
        ],
      },
      {
        which: 'EI',
        question: '둘 중 주말에 하고 싶은 걸 고르라면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `밖에 나가서 알차게 놀기`,
          },
          {
            type: 'I',
            score: 5,
            content: `침대 속에서 알차게 쉬기`,
          },
        ],
      },
      {
        which: 'SN',
        question: '사람이나 사물을 볼 때 평소의 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `보이는 대로 본다`,
          },
          {
            type: 'N',
            score: 5,
            content: `보이지 않는 걸 본다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '‘우리는 왜 태어난 걸까’ 라는 질문에 대한 나의 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '어제 못 먹은 밥이 생각남',
          },
          {
            type: 'N',
            score: 5,
            content: `못 이룬 꿈이 생각남`,
          },
        ],
      },
      {
        which: 'SN',
        question: '상상을 하고 있을 때 보통 나의 모습은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `상상해 봐야지~ 하고 상상을 해야 함(의식)`,
          },
          {
            type: 'N',
            score: 5,
            content: `보거나 떠올리자마자 그와 연관된 게 인터넷 창 켜지는 거 마냥 상상됨(무의식)`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친한 친구가 슬퍼하고 있을 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '공감보다는 슬프게 한 근원을 찾아서 제거해주려 한다',
          },
          {
            type: 'F',
            score: 5,
            content: '공감과 위로를 해주며 감정적으로 지지해 준다',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '‘나 너무 힘들어서 떠나게’ 라는 문장에서 내가 집중하는 포인트는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `떠나게 = 어디로 떠나는가`,
          },
          {
            type: 'F',
            score: 5,
            content: '힘들어서 = 왜 힘든가',
          },
        ],
      },
      {
        which: 'TF',
        question: `내가 관심을 표현하는 방식은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '질문 세례',
          },
          {
            type: 'F',
            score: 5,
            content: '호응과 맞장구',
          },
        ],
      },
      {
        which: 'JP',
        question: '유튜브나 웹툰을 보고 있을 때 보통 나의 상황은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `할 일을 다하거나 휴식시간에 보고 있는 나`,
          },
          {
            type: 'P',
            score: 5,
            content: `할 일은 많은데 미루고 보고 있는 나`,
          },
        ],
      },
      {
        which: 'JP',
        question: '할 일을 미룰 때 나의 마인드는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '미루긴 미루지만 뭘 할지는 정해두고 미룸',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 미루고 봄',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 일을 하는 방식과 일에 대한 퀄리티 어떤가?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '3시간에 할거 3시간 만에 하고 퀄리티 좋음',
          },
          {
            type: 'P',
            score: 5,
            content: '3시간에 할거 30분 만에 하고 퀄리티 나쁘지 않음',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/askChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/askChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/askChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/askChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/askChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/askChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/askChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/askChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/askChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/askChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/askChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/askChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/askChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/askChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/askChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/askChar/INFP.jpg',
      },
    ],
  },
  // Y2KText
  {
    info: {
      mainTitle: 'Y2K 문자 감성 테스트',
      subTitle: '그 시절 그때의 감성으로 보는 나의 성격은?',
      mainUrl: 'Y2KText',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/Y2KText-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/Y2KText-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          '별로 안 친한 지인이 “이 스웨터 너무 예쁘다! 어디서 샀어요?”라고 하며 옷을 만지면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '바로 왜 그러시냐고 하면서 싫다는 의사를 바로 전한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '상대가 기분 나쁠 수도 있으니 천천히 싫은 내색을 낸다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구들과 모임에서 추억의 댄스 노래가 나왔다. 이때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '누구보다 먼저 일어나서 상황을 즐기며 춤춘다.',
          },
          {
            type: 'I',
            score: 5,
            content:
              '숨고 싶지만 안 해서 갑분싸 되는 것보다 하는 게 낫다고 생각한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '곧 나의 생일이다. 내가 선호하는 생일날 분위기는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `가족들과도 보내고 친구들도 다 불러서 왁자지껄하게 보내기.`,
          },
          {
            type: 'I',
            score: 5,
            content: `가족과 소박하게 지내거나 정말 친한 친구들만 불러서 소소하게 보내기.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '유명한 프로게이머 사인회에 가게 되었다. 이때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `오.. 실물로 봐도 정말 잘생겼네! 빨리 사인받고 싶다!!!`,
          },
          {
            type: 'N',
            score: 5,
            content: `오 첫사랑 st 재질인데.. 교회에 가면 있을법한 교회 오빠 느낌도 나!!!`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구의 이상한 설정을 한 질문을 했을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '이상한 설정에서부터 뇌정이 와서 상상 안 하게 된다.',
          },
          {
            type: 'N',
            score: 5,
            content: `가정 자체가 흥미롭게 다가와서 세상 진지하게 생각해 본다.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '내가 만든 콘텐츠 영상이 조회수가 수직 상승하기 시작했다. 이때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `이 영상이 잘 된다 하더라도 내 월급은 여전하겠지. 보너스 줬으면 좋겠다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `설마.. 나 여기저기에서 스카우트되고 진급하는 거 아냐?!`,
          },
        ],
      },
      {
        which: 'TF',
        question: `비둘기가 갑자기 가까이 날라와서 옆에 안착한다. 이때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '‘쟤는 우릴 보고 얼마나 놀랬겠니’라고 생각한다.',
          },
          {
            type: 'F',
            score: 5,
            content:
              '훠이훠이 멀리 비둘기를 쫓아낸 후 칭찬받고 싶은 마음에 계속 멘트 친다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '“성격은 별론데 일은 잘해”라는 말을 들었을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `일 잘하는 사람이 하면 괜찮은데 일 못하는 사람이 하면 기분 나쁘다.`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '네가 뭔데 날 판단해 싶지만 일 잘하다는 소리는 듣고 싶어 한다.',
          },
        ],
      },
      {
        which: 'TF',
        question: `퀴즈 맞히는 게임을 하던 중에 “얼마나 확실한데?”라는 질문에 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '관심이 있어서 묻는 질문이겠거니 생각하고 대답해 준다.',
          },
          {
            type: 'F',
            score: 5,
            content:
              '‘왜 맨날 물어보지. 날 그냥 믿어 줄 순 없는 걸까’ 라고 생각한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '하루를 마무리하고 누웠다. 이때 드는 생각은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `정해 놓은 것들을 마무리하지 못했네.. 찜찜하지만 늦었으니 자고 내일 열심히 해야지!`,
          },
          {
            type: 'P',
            score: 5,
            content: `내일의 나에게 맡긴다! 일단 자고 내일 생각해!`,
          },
        ],
      },
      {
        which: 'JP',
        question: '친구 집들이 선물을 고를 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '평소 필요하다고 했던 물건을 떠올라 미리 리스트업 후 고민해 본다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '쇼핑몰 홈페이지 들어가서 BEST 상품 위주로 둘러보다 꽂히는 걸로 고민해 본다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 더 두려워하는 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '내가 지금 선택하고 계획하지 않아서 못하게 되거나 포기해야 하는 상황',
          },
          {
            type: 'P',
            score: 5,
            content:
              '내가 지금 선택함으로써 이후에 선택을 하지 못하게 되는 상황',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/Y2KText/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/Y2KText/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/Y2KText/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/Y2KText/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/Y2KText/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/Y2KText/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/Y2KText/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/Y2KText/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/Y2KText/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/Y2KText/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/Y2KText/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/Y2KText/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/Y2KText/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/Y2KText/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/Y2KText/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/Y2KText/INFP.jpg',
      },
    ],
  },
  // agriGameChar
  {
    info: {
      mainTitle: '농사 체험판! 게임 성격 테스트',
      subTitle: '농사 게임으로 알 수 있는 나의 성격은?',
      mainUrl: 'agriGameChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/agriGameChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/agriGameChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          '도시에서 한적한 시골로 내려온 첫날 동네 주민과 마주쳤다. 그때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '어제 본 것처럼 반갑게 인사를 건네고 어디 가시냐고 묻는다.',
          },
          {
            type: 'I',
            score: 5,
            content: '조용히 고개를 숙이며 인사를 건네고 지나간다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '마을회관에서 잔치가 열린다고 한다. 그때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '마을 사람들과 친해질 수 있는 기회! 당장 가야지',
          },
          {
            type: 'I',
            score: 5,
            content: '아직은 불편할 것 같은데 꼭 참석해야 하나?',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '이장님께서 마을 사람들에게 인사를 돌리러 가자고 한다. 그때 당신의 속마음은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `마을 사람들에게 나를 알릴 수 있겠네 너무 좋다!`,
          },
          {
            type: 'I',
            score: 5,
            content: `혼자 어떻게 인사를 해야 할지 막막했는데 다행이다.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '농사를 시작하기 전 씨앗을 판매하는 종묘사에 방문했다. 그때 당신이 고르는 씨앗은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `그냥 간단하게 관리하기 쉬운 씨앗을 고른다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `작물이 자란 모습을 상상해 보며 씨앗을 고른다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '옆집 소가 새끼를 낳았다는 소식을 들었다. 그때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '와 너무 귀엽겠다 나중에 한번 보러 가야지',
          },
          {
            type: 'N',
            score: 5,
            content: `왜 소는 못 타고 다니는 거지? 크면 팔려가는 건가?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '밭에 심어 두었던 씨앗들이 싹을 트기 시작했다. 그때 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `잘 자라고 있는 걸 보니 너무 신기해`,
          },
          {
            type: 'N',
            score: 5,
            content: `다 자라면 뭐 하지? 친구들에게 자랑? 싸게 팔까?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `이웃 밭에 매일 같이 나오시던 사람이 보이지 않는다. 그때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '일을 다 마치셨나 집에 편안하게 계시겠네 부러워',
          },
          {
            type: 'F',
            score: 5,
            content: '매일 같이 나오셨는데 뭐지.. 어디 아프신 거 아냐?',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '귀농 체험을 온 학생들이 어렵게 일을 하고 있는 것을 발견하였다. 그때 당신은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `멍하니 쳐다보기만 한다.`,
          },
          {
            type: 'F',
            score: 5,
            content: '힘들어하는 모습을 보니 안타깝다고 생각한다.',
          },
        ],
      },
      {
        which: 'TF',
        question: `마을 어르신이 수확물을 맛보라고 나눠 주셨다. 그때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '오 안 주셔도 되는데! 잘 먹겠습니다.',
          },
          {
            type: 'F',
            score: 5,
            content:
              '정말 감사합니다! 아 집에 맛있는 과자가 있는데 한번 드셔 보시겠어요?',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '산에서 내려오는 야생 동물들 때문에 밭이 망가져 버렸다. 그때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `확실하게 막을 수 있는 방법들을 꼼꼼히 알아보고 방법을 실행해 본다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `인터넷에 방법을 검색한 후 상단에 뜨는 방법을 바로 실행해 본다.`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          '이장님께서 마을에 귀농체험을 하러 온 학생들에게 한 마디를 부탁했다. 그때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '힘들겠지만 정해진 일정과 시간은 꼭 지키고 딴 길로 새면 안 돼',
          },
          {
            type: 'P',
            score: 5,
            content: '힘들겠지만 재밌는 시간 보내고 너무 힘들면 쉬어도 돼',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '농산물 플리마켓에 참여할 수 있는 기회를 얻게 되었다. 그때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '플리마켓에 판매할 작물 리스트를 작성하고 미리미리 준비한다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '판매할 작물을 대략적으로 정해두고 고민에 빠지다 나중에 부랴부랴 준비한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/agriGameChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/agriGameChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/agriGameChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/agriGameChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/agriGameChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/agriGameChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/agriGameChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/agriGameChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/agriGameChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/agriGameChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/agriGameChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/agriGameChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/agriGameChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/agriGameChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/agriGameChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/agriGameChar/INFP.jpg',
      },
    ],
  },
  // dailyChar
  {
    info: {
      mainTitle: '데일리 성격 스티커 테스트',
      subTitle: '데일리 스티커로 알아보는 내 성격은?',
      mainUrl: 'dailyChar',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/dailyChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/dailyChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '혼자 있을 때 내가 느끼는 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '외로움과 고독함을 느낌',
          },
          {
            type: 'I',
            score: 5,
            content: '안정감과 편안함을 느낌',
          },
        ],
      },
      {
        which: 'EI',
        question: '오늘의 나는 어떤 모습일까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '오늘도 밖에서 친구들과 함께 어울려 있는 모습',
          },
          {
            type: 'I',
            score: 5,
            content: '오늘도 집에서 나가지 않고 누워 휴식을 취하는 모습',
          },
        ],
      },
      {
        which: 'EI',
        question: '나는 평소에 보통?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `이것저것 여기저기 얕게 관심이 많다`,
          },
          {
            type: 'I',
            score: 5,
            content: `무엇인가 하나에 깊게 빠져 몰두한다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 자주 하는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `그냥 그럴 수 있지 뭐`,
          },
          {
            type: 'N',
            score: 5,
            content: `아니 어떻게 그럴 수 있지? 너무 궁금해`,
          },
        ],
      },
      {
        which: 'SN',
        question: '무엇인가를 고민할 때 나는 어떨까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '현재 상황에서 무엇이 최선인지 고민',
          },
          {
            type: 'N',
            score: 5,
            content: `이 선택을 했을 때 추후에 어떤 상황이 펼쳐질지 생각하면서 고민`,
          },
        ],
      },
      {
        which: 'SN',
        question: '길가에 캐리어가 버려져 있는 걸 본다면 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `누가 길에다 저런 걸 버린담`,
          },
          {
            type: 'N',
            score: 5,
            content: `저 안에 뭐가 들어있을까..? 살짝 무서운데 누가 왜 버린 거지??`,
          },
        ],
      },
      {
        which: 'TF',
        question: `상대방의 말을 받아들일 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'A=A 말하는 그대로 받아들임',
          },
          {
            type: 'F',
            score: 5,
            content: 'A=B, C, D 숨은 뜻을 해석해 보고 다르게 받아들임',
          },
        ],
      },
      {
        which: 'TF',
        question: '누군가에게 화를 내고 난 후 내 모습은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `화낼 만 했으니까 냈지 후`,
          },
          {
            type: 'F',
            score: 5,
            content: '너무 크게 화냈나. 아냐 낼만 했어! 아 아닌가',
          },
        ],
      },
      {
        which: 'TF',
        question: `전혀 마음에도 안 들고 필요도 없는 선물을 받았다! 내 대답은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '돈 아깝게 뭐 하러 이런 걸 샀어..!',
          },
          {
            type: 'F',
            score: 5,
            content: '와 진짜 고마워! 마음에 들지 당연히~',
          },
        ],
      },
      {
        which: 'JP',
        question: '오늘의 할 일을 모두 끝내고 보니 시간이 여유롭게 남았다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `뭘 하면서 몇 시까지 쉴지, 일정 수정 완료! 이제 쉬자`,
          },
          {
            type: 'P',
            score: 5,
            content: `투데이 이즈 프리덤.. 일단 쉴 거야!`,
          },
        ],
      },
      {
        which: 'JP',
        question: '내일 우리 몇 시에 만날까? 라고 묻는 친구에게 내 대답은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '음 이 시간에는 만나야 이거 하고 저거 하겠는데?',
          },
          {
            type: 'P',
            score: 5,
            content: '음 일단 일어난 시간 보고 정하자!!',
          },
        ],
      },
      {
        which: 'JP',
        question: '자주 가는 카페에서 음료를 주문할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '늘 먹던 음료로 빠르게 주문한다',
          },
          {
            type: 'P',
            score: 5,
            content:
              '새로 나온 음료는 없는지 다른 걸 먹을지 고민하다가 주문한다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/dailyChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/dailyChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/dailyChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/dailyChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/dailyChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/dailyChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/dailyChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/dailyChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/dailyChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/dailyChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/dailyChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/dailyChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/dailyChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/dailyChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/dailyChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/dailyChar/INFP.jpg',
      },
    ],
  },
  // consumeHabit
  {
    info: {
      mainTitle: '소비습관 성격 테스트',
      subTitle: '소비로 볼 수 있는 나의 성격은?',
      mainUrl: 'consumeHabit',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/consumeHabit-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/consumeHabit-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          '지난 한 달 동안 많이 지출한 것을 알려 달라는 친구 그때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '모임이나 약속과 같은 사회적 활동과 연결된 지출',
          },
          {
            type: 'I',
            score: 5,
            content: '인터넷 구독과 같이 혼자 즐길 수 있는 활동과 연결된 지출',
          },
        ],
      },
      {
        which: 'EI',
        question: '한가로운 주말 식사를 할 때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '주말에는 친구들과 함께 식사하는 경우가 많아요.',
          },
          {
            type: 'I',
            score: 5,
            content: '대부분 주말 식사는 가족과 함께 하는 편이에요.',
          },
        ],
      },
      {
        which: 'EI',
        question: '같이 쇼핑을 하자는 친구 그때 당신의 대답은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `오 좋아 네가 잘 골라 줘야 해 알았지?`,
          },
          {
            type: 'I',
            score: 5,
            content: `쇼핑센터에 사람 많아서 싫어`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구와 체험형 카페 약속을 잡았을 때 당신의 행동은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `리뷰, 평가, 가격 등을 자세히 확인하며 정보를 수집한다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `체험 내용을 살펴보고 하고 있는 자신을 상상해 본다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '갑자기 돈이 빠져나갔을 때 당신의 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '엥 뭐지 카드사에 전화해 봐야 하나?',
          },
          {
            type: 'N',
            score: 5,
            content: `내 카드 도용 당한 거 아냐? 나쁜 일에 휘말린 건가?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '체험형 카페 어땠어 하고 묻는 친구 그때 당신의 대답은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `체험과 맛있는 음식을 먹었던 것이 좋았어`,
          },
          {
            type: 'N',
            score: 5,
            content: `카페에서 느낀 분위기와 특별한 경험이 좋았어`,
          },
        ],
      },
      {
        which: 'TF',
        question: `카드 청구서를 보며 슬퍼하는 친구 그때 당신의 행동은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '저번에 보니까 많이 쓰더니 그럴 줄 알았어',
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮아? 얼마나 많이 쓴 거야 도움 필요해?',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '나란히 길을 걷던 중 돈을 잃어버린 것 같다는 친구 그때 당신은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `어디서 잃어버렸는지 감잡혀? 길 되돌아가 보자`,
          },
          {
            type: 'F',
            score: 5,
            content: '많이 잃어버렸어? 같이 찾아 줄게 걱정 마',
          },
        ],
      },
      {
        which: 'TF',
        question: `추운 날씨에도 장사를 하시는 할머니를 보았을 때 당신의 생각은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '안 추우시나? 아직까지도 장사를 하시네',
          },
          {
            type: 'F',
            score: 5,
            content: '힘드시겠다 나라도 사드려야 하나?',
          },
        ],
      },
      {
        which: 'JP',
        question: '갖고 싶은 거 있냐고 묻는 친구 그때 당신의 대답은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `리스트에 적어 두었던 제품을 말한다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `좋아하거나 필요한 제품을 말한다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '카톡에 생일인 친구가 뜬 것을 보게 되었을 때 당신의 생각은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '이미 캘린더를 통해 생일인 것을 확인한지 오래다.',
          },
          {
            type: 'P',
            score: 5,
            content: '이 친구 생일이었구나 생각지도 못한 지출이 생겨 버렸다',
          },
        ],
      },
      {
        which: 'JP',
        question: '스트레스가 생겼을 때 당신의 행동은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '지출을 하지 않는 선에서 스트레스를 해소한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '기분을 풀 수 있는 것을 먹거나 장소에 간다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/consumeHabit/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/consumeHabit/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/consumeHabit/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/consumeHabit/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/consumeHabit/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/consumeHabit/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/consumeHabit/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/consumeHabit/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/consumeHabit/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/consumeHabit/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/consumeHabit/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/consumeHabit/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/consumeHabit/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/consumeHabit/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/consumeHabit/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/consumeHabit/INFP.jpg',
      },
    ],
  },
  // secretLetter
  {
    info: {
      mainTitle: '비밀 편지 테스트',
      subTitle: '비밀 편지로 보는 나의 성격은?',
      mainUrl: 'secretLetter',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/secretLetter-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/secretLetter-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '친구가 같이 카페 가서 편지 쓸래?!라고 했을 때 나의 반응은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '그래!! 어디 카페로 갈래?',
          },
          {
            type: 'I',
            score: 5,
            content: '집에서 쓰고 싶은데..',
          },
        ],
      },
      {
        which: 'EI',
        question: '내가 생각하는 학교의 기준은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '학교는 안이니깐 ‘실내’',
          },
          {
            type: 'I',
            score: 5,
            content: '집 밖이니깐 ‘실외’',
          },
        ],
      },
      {
        which: 'EI',
        question: '하루 종일 집에만 있을 때 드는 생각은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `아 지루하다.. 지금이라도 나갈까`,
          },
          {
            type: 'I',
            score: 5,
            content: `시간이 왜 이렇게 빨라! 내일아 오지 마!`,
          },
        ],
      },
      {
        which: 'SN',
        question: '나에게 ‘상상’ 이란?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `특정 상황에서 한 가지 상상을 발휘하는 것`,
          },
          {
            type: 'N',
            score: 5,
            content: `일상생활 불가능이 의심되는 기출 변형 끝판왕`,
          },
        ],
      },
      {
        which: 'SN',
        question: '길을 가는데 ‘사과’가 떨어져 있는 걸 보고 드는 나의 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '엇 사과네?(근원지 별로 안 궁금함)',
          },
          {
            type: 'N',
            score: 5,
            content: `이거 뭐지? 어디서 떨어졌지? 누가 장보고 흘렸나?(근원지 매우 궁금)`,
          },
        ],
      },
      {
        which: 'SN',
        question: '편지를 열었는데 나에게 온 편지가 아닐 때 나의 반응은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `앗 나한테 온 게 아니네 다시 넣어둬야겠다`,
          },
          {
            type: 'N',
            score: 5,
            content: `내 편지가 아니네 내가 뜯어서 어떡하지 다시 넣어두면 티 안 나려나`,
          },
        ],
      },
      {
        which: 'TF',
        question: `매일 함께 하교하던 친구가 ‘오늘은 너 혼자가’라고 했을 때 나의 반응은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜? (별생각 없음 단지 이유가 궁금)',
          },
          {
            type: 'F',
            score: 5,
            content: '왜? (갑자기 왜 그러지 걱정+서운함 먼저)',
          },
        ],
      },
      {
        which: 'TF',
        question: '‘네가 그냥 좋아’라는 질문을 받았을 때 나의 반응은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `왜 인지 의문이 먼저 든다.`,
          },
          {
            type: 'F',
            score: 5,
            content: '감동을 먼저 한다.',
          },
        ],
      },
      {
        which: 'TF',
        question: `‘네가 알아서 잘 하겠지’라는 답변을 받았을 때 나의 생각은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "'알아서 잘'에서 인정받은 느낌이 든다.",
          },
          {
            type: 'F',
            score: 5,
            content: "'알아서' '하겠지'에서 묘하게 상처받는다.",
          },
        ],
      },
      {
        which: 'JP',
        question:
          '‘처음 만나는 사람들과 2시 40분까지 친해져 보세요’라는 미션을 받았을 때 나의 반응은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `몇 시인지 파악하고 계산하기 위해 시간부터 본다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `엥..? 그게 가능한가 생각부터 들고 시간은 뒷전이다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '나의 성향은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '매일 꾸준히',
          },
          {
            type: 'P',
            score: 5,
            content: '한 번에 몰아서',
          },
        ],
      },
      {
        which: 'JP',
        question: '일하기 너무 싫을 때 나의 행동은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "'오늘은 그냥 안 할래.. 근데 밀린 거 해결은 어떡하지..' 걱정하고 고민하다 시간 보냄",
          },
          {
            type: 'P',
            score: 5,
            content:
              "'아 몰라 어떻게든 되겠지. 내일의 내가 할 거야.' 고민없이 해결 완료",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/secretLetter/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/secretLetter/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/secretLetter/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/secretLetter/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/secretLetter/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/secretLetter/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/secretLetter/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/secretLetter/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/secretLetter/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/secretLetter/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/secretLetter/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/secretLetter/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/secretLetter/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/secretLetter/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/secretLetter/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/secretLetter/INFP.jpg',
      },
    ],
  },
  // meOtherJP
  {
    info: {
      mainTitle: '自分や他人によるテスト',
      subTitle:
        '私は自分のことをどう思っているのか、他人は私のことをどう思っているのか？',
      mainUrl: 'meOtherJP',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/meOtherJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/meOtherJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'なぜいつもお金がないのですか？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '私はいつも友達に会って休みの日に出かけます',
          },
          {
            type: 'I',
            score: 5,
            content: '私は家で出前を頼んで、OTTサービスを利用しています',
          },
        ],
      },
      {
        which: 'EI',
        question: 'あなたはアルバイトをしている間、休憩時間に何をしますか？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '今がちょうどいい時期のようなので、同僚とチャットをする',
          },
          {
            type: 'I',
            score: 5,
            content:
              '私はただ一人で携帯電話を使っています。なぜなら、人と話す=エネルギーも使い果たしてしまうからです',
          },
        ],
      },
      {
        which: 'EI',
        question: 'あなたは好きな人とどうやって行動しますか？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `私は目を合わせ、笑顔で毎日小さなコメントを追加します`,
          },
          {
            type: 'I',
            score: 5,
            content: `多くの勇気と決意、そして複数のシミュレーションを経て、私はついに諦めます`,
          },
        ],
      },
      {
        which: 'SN',
        question: '映画を見てCGについて話すとき、あなたは何と言いますか？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `あ、本当にすごい.. やっぱり監督は有名で才能もあるので、映画に没頭しました。 かっこよかったです`,
          },
          {
            type: 'N',
            score: 5,
            content: `わぁ、みんな人だったんですか？！私もエキストラで出演したいです！`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'タクシーで窓の外を見るとどう思いますか？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '私は天気がだいぶ涼しくなりました。 かっこよくていい',
          },
          {
            type: 'N',
            score: 5,
            content: `私は旅行に行きたい··· あっ、この前ネットで注文したものが届きませんでした`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '私たちはなぜ生まれたのですか？ その質問についてどう思いますか？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `よく分かりません。 お腹がすいてチキンを注文したいんです。 一緒に食べますか？`,
          },
          {
            type: 'N',
            score: 5,
            content: `そうですね.. 私たちはなぜ本当に生まれてくるのか？ どこに行くんですか？`,
          },
        ],
      },
      {
        which: 'TF',
        question: `もし、恋人が「病院に行ってから約束の場所に行こうかな！」と言ったら？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'あ、大丈夫ですか？ 実費保険はありますか？',
          },
          {
            type: 'F',
            score: 5,
            content: 'あ、大丈夫ですか？ 具合が悪くて病院に行くのですか？',
          },
        ],
      },
      {
        which: 'TF',
        question: 'イチョウの実をあちこちで見ると、どう思いますか？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `イチョウの香り…これは本物ですか？`,
          },
          {
            type: 'F',
            score: 5,
            content: 'ああ、もう涼しい秋ですね！',
          },
        ],
      },
      {
        which: 'TF',
        question: `お友達がフルーツジュースを作ってくれて、お粥のような味になったらどうしますか？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'あ、そうではありません。 でも食感がお粥みたいなので、次はバナナの量を減らせばちょうどいいと思います！ とにかくありがとう!',
          },
          {
            type: 'F',
            score: 5,
            content:
              'ということで、朝食を抜くことを覚えていますよね。 本当に感動しました。 ありがとう！',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Bプランはあなたにとって何を意味しますか？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `プランBは事前に計画中`,
          },
          {
            type: 'P',
            score: 5,
            content: `プランBが頭に浮かびました`,
          },
        ],
      },
      {
        which: 'JP',
        question: '趣味で一生懸命作った動画ファイルがなくなったらどうしよう？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '私は本当に腹が立ちます',
          },
          {
            type: 'P',
            score: 5,
            content: 'とにかく楽しかったので、失うものは何もありません',
          },
        ],
      },
      {
        which: 'JP',
        question: '旅行中においしいピザ屋に行ったらどうでしょうか？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'この場所は明日行くルートに近いので、明日の午後に行った方がいいですね',
          },
          {
            type: 'P',
            score: 5,
            content:
              'イタリア旅行の時にピザを食べてみます。 とにかくとてもおいしそうですね！',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/meOtherJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/meOtherJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/meOtherJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/meOtherJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/meOtherJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/meOtherJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/meOtherJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/meOtherJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/meOtherJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/meOtherJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/meOtherJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/meOtherJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/meOtherJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/meOtherJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/meOtherJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/meOtherJP/INFP.jpg',
      },
    ],
  },
  // meOtherEng
  {
    info: {
      mainTitle: 'Test by myself and others',
      subTitle: 'What I think of myself and what others think of me?',
      mainUrl: 'meOtherEng',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/meOtherEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/meOtherEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'Why do you always have no money?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I always meet my friends and go out on my days off',
          },
          {
            type: 'I',
            score: 5,
            content:
              'I always order delivery food at home and use OTT services',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What do you do during your break while working part-time?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'Now seems like the right time, so I chat with my colleagues',
          },
          {
            type: 'I',
            score: 5,
            content:
              'I just use my cell phone alone because I think talking people = using up energy as well',
          },
        ],
      },
      {
        which: 'EI',
        question: 'How do you act with someone you like?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I make eye contact, smile, and add small daily comments`,
          },
          {
            type: 'I',
            score: 5,
            content: `After a lot of courage, determination, and multiple simulations, I finally give up`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'When you talk about CG after watching a movie, what do you say?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Oh, it's really amazing.. As expected, the director is famous and talented, so I was really immersed in the movie. It was cool`,
          },
          {
            type: 'N',
            score: 5,
            content: `Wow, those were all people? ! I also want to appear as an extra!`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do you think when you look out the window in a taxi?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'As for me, the weather has become much cooler. Cool and good',
          },
          {
            type: 'N',
            score: 5,
            content: `I want to go on a trip... Oh wait, I didn't receive what I ordered online last time`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'Why are we born? What do you think about the question?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I'm not sure. . I'm hungry and want to order some chicken. Do you want to eat together?`,
          },
          {
            type: 'N',
            score: 5,
            content: `Right.. Why are we really born? And where are we going?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What if your lover said “I think I’ll go to the hospital and then go to the appointment!”?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Oh, are you okay? Do you have actual cost insurance?',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Oh, are you okay? Are you going to the hospital because you’re sick?',
          },
        ],
      },
      {
        which: 'TF',
        question: 'What do you think when you see ginkgo fruits everywhere?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Oh smell of ginkgo… is this real?`,
          },
          {
            type: 'F',
            score: 5,
            content: "Oh, it's cool fall now!",
          },
        ],
      },
      {
        which: 'TF',
        question: `What if your friend makes fruit juice for you and it tastes like porridge?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Oh, it is not that. But the texture is a bit like porridge, so I think it would be perfect if you reduce the amount of bananas next time! Thanks anyway!',
          },
          {
            type: 'F',
            score: 5,
            content:
              'So, you remember that I skip breakfast, huh. I am so touched. Thanks!',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What does Plan B mean to you?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Plan B planed in advance`,
          },
          {
            type: 'P',
            score: 5,
            content: `Plan B just came to my mind`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What if the video file you worked so hard to create as a hobby was lost?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I will be really upset',
          },
          {
            type: 'P',
            score: 5,
            content: 'Anyway, I had fun making it, so I have nothing to lose',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What if you ended up going to a great pizza place while travelling?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Since this location is close to the route we will take tomorrow, it would be better to go tomorrow afternoon.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'I can try the pizza when I travel to Italy. Anyway it looks very delicious!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/meOtherEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/meOtherEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/meOtherEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/meOtherEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/meOtherEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/meOtherEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/meOtherEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/meOtherEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/meOtherEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/meOtherEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/meOtherEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/meOtherEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/meOtherEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/meOtherEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/meOtherEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/meOtherEng/INFP.jpg',
      },
    ],
  },
  // loveSimulation
  {
    info: {
      mainTitle: '연애 시뮬레이션 테스트',
      subTitle: '당신의 선택에 따른 연애 특성을 진단받아 보자',
      mainUrl: 'loveSimulation',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveSimulation-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveSimulation-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '소개팅 상대와의 대화방이 개설되었다. 그때 당신의 속마음은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '빨리 대화를 나눠봐야겠다. 어떤 사람일까 기대돼.',
          },
          {
            type: 'I',
            score: 5,
            content: '너무 긴장돼 대화를 주도할 수 있는 사람이면 좋겠다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '조용한 카페에서 소개팅 상대와 만나게 되었다. 그때 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '자기소개를 한 후 상대방에게 질문을 건넨다.',
          },
          {
            type: 'I',
            score: 5,
            content: '자기소개를 한 후 상대방의 말을 기다린다.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '대화 중에 취미가 겹친다는 것을 알게 되었다. 그때 당신의 반응은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `나중에 같이 해보자고 적극적으로 얘기한다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `신기하다는 반응만 취한 후 다른 주제로 대화를 넘긴다.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '시간 가는 줄 모르고 떠들다 보니 식사 시간이 되었을 때 당신의 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `대화 코드도 맞고 키나 외모도 괜찮네.`,
          },
          {
            type: 'N',
            score: 5,
            content: `대화도 잘 통하고 분위기 너무 괜찮다~ 느낌이 왔어!`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '맛집을 예약해 놓았다고 자리를 옮기자고 말을 건넸다. 그때 당신의 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '우와 맛집!! 맛있는 음식을 먹으러 간다니 너무 좋다.',
          },
          {
            type: 'N',
            score: 5,
            content: `음식 종류는 뭐지? 어떤 맛집일까 여러 가지 생각에 빠진다.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '소개팅 상대와 대화를 나눌수록 호감도가 깊어지고 있는 상황이다. 그때 당신의 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `대화가 잘 통하니 너무 좋다고 생각한다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `상대방과 미래 계획을 상상해 본다.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `밥을 먹던 도중 상대방이 “맛있게 잘 드시네요. 보기 좋아요”라고 말했다. 그때 당신의 생각은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '그냥 밥 맛있게 먹는 사람을 좋아하나 보네.',
          },
          {
            type: 'F',
            score: 5,
            content:
              '너무 돼지처럼 먹었나..? 더 조심스럽게 먹는 게 좋았을지도 모르겠어.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '소개팅 상대의 회사가 바쁜 시즌임을 알게 되었을 때 당신의 반응은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `바쁜 시즌인데 어떻게 나오신 거예요. 바빠서 힘드시겠어요.`,
          },
          {
            type: 'F',
            score: 5,
            content: '피곤하지 않으세요? 바쁜 시즌이라니 힘내세요ㅠㅠ',
          },
        ],
      },
      {
        which: 'TF',
        question: `오늘을 위해 미용실도 다녀왔다는 상대방을 말을 듣게 되었다. 그때 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '어디서 하셨어요? 괜찮은데요!?',
          },
          {
            type: 'F',
            score: 5,
            content: '신경 써 주셔서 감사해요 머리 정말 잘 하신 것 같아요!!',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '쉬는 날에는 어떻게 시간을 보내는지 질문을 건넸다. 그때 당신의 대답은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `구체적으로 짜여 있는 일과를 말한다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `상황에 따라 다른 것 같다고 말한다.`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          '호감인 소개팅 상대방이 다음 주에 또 볼 수 있냐는 질문을 건넸다. 그때 당신의 대답은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '일정을 확인해 보고 말씀드려도 될까요?',
          },
          {
            type: 'P',
            score: 5,
            content:
              '(다음 주 뭐 있었던 것 같은데 아닌가) 네! 저희 다음 주에 또 봐요!',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '소개팅 상대방이 다음에 같이 가고 싶은 장소를 하나씩 가자고 제안했다. 그때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '신중하게 검색하고 계획을 세운 후 장소를 말한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '평소 가보고 싶었던 장소를 즉각적으로 말한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveSimulation/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSimulation/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveSimulation/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSimulation/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveSimulation/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSimulation/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveSimulation/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveSimulation/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveSimulation/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSimulation/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveSimulation/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveSimulation/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveSimulation/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveSimulation/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveSimulation/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveSimulation/INFP.jpg',
      },
    ],
  },
  // highteenStickerEng
  {
    info: {
      mainTitle: '하이틴 스티커 테스트',
      subTitle: 'If I put on a highteen sticker, will I become a highteen too?',
      mainUrl: 'highteenStickerEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/highteenStickerEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/highteenStickerEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'What about me when I met my first roommate in the dorm?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I greet you warmly, introduce myself, and talk to you',
          },
          {
            type: 'I',
            score: 5,
            content: 'Say hello shyly and unwrap quietly',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'Trembling first day of school! What if everyone suddenly focuses on me?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: "It's burdensome, but I enjoy the attention",
          },
          {
            type: 'I',
            score: 5,
            content: "It's so burdensome that I run away without realizing it",
          },
        ],
      },
      {
        which: 'EI',
        question:
          "Friends I've never seen before come to me and ask me questions!",
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I was a little embarrassed, but after adapting quickly, I answer questions actively`,
          },
          {
            type: 'I',
            score: 5,
            content: `I want to stay away because it's too much and I hope you go quickly`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do you think about your classmates?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Someone was really tall. Someone had a unique hairstyle`,
          },
          {
            type: 'N',
            score: 5,
            content: `Some were very nice and cute, some were a bit cold`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          "It's already lunchtime! The popular group wants to eat together!",
        answers: [
          {
            type: 'S',
            score: 2,
            content: "I don't think much about it and ask why",
          },
          {
            type: 'N',
            score: 5,
            content: `What is it, perhaps harassment..? I imagine myself becoming an outcast`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What would you do if you became friends with a group of pretty and handsome people and went around with them?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I think I'm very proud to be in this group`,
          },
          {
            type: 'N',
            score: 5,
            content: `Why is everyone looking at us? Are you jealous of us?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `I hear someone swearing at me in the bathroom!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "I'll go out right away and ask why",
          },
          {
            type: 'F',
            score: 5,
            content: "Did I do something wrong? It's too much..",
          },
        ],
      },
      {
        which: 'TF',
        question:
          'To a friend who is sitting still with a rather anxious look on her face today, what do you say?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `It doesn't bother me that much`,
          },
          {
            type: 'F',
            score: 5,
            content: "I'm constantly worried, so I ask what's going on",
          },
        ],
      },
      {
        which: 'TF',
        question: `A friend who asks me to help him confess to the person I have a crush on What is my answer?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "I say honestly that I can't help you",
          },
          {
            type: 'F',
            score: 5,
            content:
              "I can't be honest with my friend and I use excuses to avoid my seat",
          },
        ],
      },
      {
        which: 'JP',
        question: 'Which class would be more comfortable for me?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `The way the program is systematically organized over time`,
          },
          {
            type: 'P',
            score: 5,
            content: `How to autonomously choose the class you want`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          "The prom party, the school's big event, is just around the corner. What do I do when I choose my outfit?",
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Make a list of what concept outfits to wear in advance',
          },
          {
            type: 'P',
            score: 5,
            content:
              "After deciding on the concept briefly, I'll go to the shop and think about it",
          },
        ],
      },
      {
        which: 'JP',
        question: 'A successful prom party! What makes you satisfied?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Satisfaction with the result that the party ended in a fun way',
          },
          {
            type: 'P',
            score: 5,
            content:
              'I am satisfied with the process I prepared to go to the party and the fun times',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/highteenStickerEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/highteenStickerEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/highteenStickerEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/highteenStickerEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/highteenStickerEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/highteenStickerEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/highteenStickerEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/highteenStickerEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/highteenStickerEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/highteenStickerEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/highteenStickerEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/highteenStickerEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/highteenStickerEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/highteenStickerEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/highteenStickerEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/highteenStickerEng/INFP.jpg',
      },
    ],
  },
  // highteenSticker
  {
    info: {
      mainTitle: '하이틴 스티커 테스트',
      subTitle: '하이틴 스키커를 붙이면 나도 하이틴이?',
      mainUrl: 'highteenSticker',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/highteenSticker-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/highteenSticker-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          '전학을 가게 된 학교는 기숙 학교이다. 기숙사에서 첫 룸메이트를 만났을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '반갑게 인사하며 내 소개를 하고 말을 건다',
          },
          {
            type: 'I',
            score: 5,
            content: '수줍게 인사를 건네고 조용히 짐을 푼다 ',
          },
        ],
      },
      {
        which: 'EI',
        question: '떨리는 첫 등교 날! 갑자기 시선이 나에게 집중한다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '부담스럽지만 은근 관심을 즐긴다 ',
          },
          {
            type: 'I',
            score: 5,
            content: '너무 부담스러워서 나도 모르게 냅다 도망친다 ',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '새로운 전학생이 왔다며 친구들이 우르르 모여들어 질문을 한다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `조금 당황했지만 금방 적응 후 질문에 적극적으로 대답을 한다 `,
          },
          {
            type: 'I',
            score: 5,
            content: `어버버 하며 제대로 대답하지 못하고 빨리 가줬으면 하고 바란다 `,
          },
        ],
      },
      {
        which: 'SN',
        question: '같은 반 친구들은 어떤지 설명할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `누구는 키가 정말 크더라 누구는 헤어스타일이 특이했어 `,
          },
          {
            type: 'N',
            score: 5,
            content: `누구는 엄청 착하고 귀여웠어 누구는 좀 차갑더라 `,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '어느덧 점심시간! 학교에 잘나가는 무리들이 같이 밥을 먹자고 한다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '별생각 하지 않고 이유를 물어본다 ',
          },
          {
            type: 'N',
            score: 5,
            content: `뭐지 갑자기 왜일까? 혹시 괴롭힘..? 왕따가 되는 내 모습이 상상된다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '예쁘고 잘생긴 무리들과 친해져서 함께 다니게 되었다면 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `이 사이에 있다니 너무 좋은데? 뿌듯하다 `,
          },
          {
            type: 'N',
            score: 5,
            content: `다들 우리를 쳐다보네 내가 부럽겠지? ㅎㅎㅎ `,
          },
        ],
      },
      {
        which: 'TF',
        question: `화장실에서 누군가 내 뒷담화를 하며 떠드는 소리가 들린다!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '바로 나가서 이유를 물어본다',
          },
          {
            type: 'F',
            score: 5,
            content: '내가 뭘 그렇게 잘못한 걸까 상처받는다 ',
          },
        ],
      },
      {
        which: 'TF',
        question: '오늘따라 어두운 표정으로 가만히 앉아있는 친구에게 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `그다지 큰 신경이 쓰이진 않는다 `,
          },
          {
            type: 'F',
            score: 5,
            content: '계속 신경 쓰이고 걱정돼서 무슨 일 있는지 물어본다 ',
          },
        ],
      },
      {
        which: 'TF',
        question: `내가 짝사랑하고 있는 이성에게 고백을 도와달라는 친구! 내 대답은 무엇일까?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '도와주기 어렵다며 솔직한 마음을 말한다 ',
          },
          {
            type: 'F',
            score: 5,
            content: '돌려 말하며 일단 자리를 피하고 고민해 본다',
          },
        ],
      },
      {
        which: 'JP',
        question: '어떤 수업 방식이 내게는 더 편할까?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `체계적으로 프로그램이 시간별로 짜여 있는 방식 `,
          },
          {
            type: 'P',
            score: 5,
            content: `자율적으로 원하는 수업을 선택하는 방식 `,
          },
        ],
      },
      {
        which: 'JP',
        question:
          '학교 빅 이벤트인 프롬 파티가 얼마 남지 않았다. 의상을 정할 때 나는? ',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '미리 어떤 컨셉의 의상을 입을지 리스트를 작성한다',
          },
          {
            type: 'P',
            score: 5,
            content: '컨셉만 간략하게 정한 후 일단 샵에 가서 고민한다 ',
          },
        ],
      },
      {
        which: 'JP',
        question: '성공적으로 끝난 프롬 파티! 어떤 부분에서 만족할까?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '별 탈 없이 재미있게 파티를 끝냈다는 결과에 대한 만족 ',
          },
          {
            type: 'P',
            score: 5,
            content:
              '파티에 가기 위해서 준비했던 과정과 즐거웠던 시간들에 만족 ',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/highteenSticker/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/highteenSticker/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/highteenSticker/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/highteenSticker/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/highteenSticker/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/highteenSticker/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/highteenSticker/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/highteenSticker/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/highteenSticker/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/highteenSticker/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/highteenSticker/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/highteenSticker/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/highteenSticker/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/highteenSticker/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/highteenSticker/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/highteenSticker/INFP.jpg',
      },
    ],
  },
  // searchResult
  {
    info: {
      mainTitle: '검색 결과 테스트',
      subTitle: '나의 성격을 검색창에 쳐보면?',
      mainUrl: 'searchResult',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/searchResult-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/searchResult-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '외출했을 때 더 좋은 상황은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구들끼리 팔짱 끼고 여기저기 돌아다니면서 쇼핑하기',
          },
          {
            type: 'I',
            score: 5,
            content: '이어폰 끼고 혼자 공원 산책 다니면서 걷기 ',
          },
        ],
      },
      {
        which: 'EI',
        question: '내가 더 참기 힘든 상황은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '다 같이 가만히 침묵인 분위기 사이에 있는 나',
          },
          {
            type: 'I',
            score: 5,
            content: '다 같이 춤추고 노래하는 분위기 사이에 있는 나',
          },
        ],
      },
      {
        which: 'EI',
        question: '3개월 동안 나의 약속 스타일은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `거의 맨날 매주 주말에 약속 있는 편이다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `3개월에 한 번씩 간간이 중간에 있다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구가 “만약에 말이야~”라고 할 때 나의 반응은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `아…!! 이제 그만!! 만약이 어딨어!`,
          },
          {
            type: 'N',
            score: 5,
            content: `오호 말해봐. 내가 딱 드는 생각은 말이지~ @#$%^&`,
          },
        ],
      },
      {
        which: 'SN',
        question: '과제 발표 망했을 때 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '정말.. 다시 하면 잘할 수 있을 거 같은데.. 시간 다시 되돌리고 싶다!!!',
          },
          {
            type: 'N',
            score: 5,
            content: `다른 동기들은 A+ 받고 나 혼자 D- 받아서 재수강하는 거 아니야?!`,
          },
        ],
      },
      {
        which: 'SN',
        question: '학교 반 배정 결과 나오기 전 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `반 배정 망할까 봐 걱정한다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `나 빼고 다 같은 반 배정돼서 혼자 밥 먹고 혼자 매점 가는 생각한다.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구와 다퉈서 사이가 틀어졌다고 말하는 친구에게 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜? 무슨 일 있었어? 무슨 상황이었어?',
          },
          {
            type: 'F',
            score: 5,
            content:
              '엥? 속상하겠어.. 그래도 둘이 잘 이야기해 봐! 연락은 해봤어?',
          },
        ],
      },
      {
        which: 'TF',
        question: '광고를 한마디로 설명해 보라고 했을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `구매자가 제품 정보를 보고 구매까지 이어지게 하는 것`,
          },
          {
            type: 'F',
            score: 5,
            content: '구매자가 마음에 들도록 해서 구매까지 이어지게 하는 것',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 지갑을 분실했다. 이때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '헉!! 무슨 일이야!!! 너 마지막 동선이 어디였어? 언제 마지막으로 봤는데?',
          },
          {
            type: 'F',
            score: 5,
            content: '헉!! 선물 받은 거라 더 소중한 거라며!! 어떡해!',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 생각하는 계획의 의미는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `내가 계획했던 대로 되지 않으면 짜증 나고 화나`,
          },
          {
            type: 'P',
            score: 5,
            content: `계획을 세우기는 세워 근데 바꿔도 돼!`,
          },
        ],
      },
      {
        which: 'JP',
        question: '월급을 받았다! 이때 내가 하는 행동은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '따로 생활비나 적금을 빼둔 뒤 필요했던 것들을 구매한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '필요했던 물건들을 먼저 구매 후 생활비나 적금을 뺀다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '밥 먹다 친구가 좋은 여행지를 추천해 주었다 이때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '일단 메모만 해두고 밥을 마저 먹는다.',
          },
          {
            type: 'P',
            score: 5,
            content: '밥숟가락 내려놓고 바로 검색에 돌입한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/searchResult/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/searchResult/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/searchResult/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/searchResult/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/searchResult/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/searchResult/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/searchResult/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/searchResult/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/searchResult/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/searchResult/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/searchResult/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/searchResult/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/searchResult/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/searchResult/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/searchResult/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/searchResult/INFP.jpg',
      },
    ],
  },
  // zookeeper
  {
    info: {
      mainTitle: '동물 사육사 테스트',
      subTitle: '만약 내가 사육사가 된다면 돌보게 될 동물은?',
      mainUrl: 'zookeeper',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/zookeeper-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/zookeeper-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '동물 사육사로 일을 시작한다면? 그 이유는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '동료 사육사와 동물들과 다 함께 놀고 조련해 보고 싶어서',
          },
          {
            type: 'I',
            score: 5,
            content: '동물들과 함께 조용한 시간을 보내기 위해',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '첫 출근 날, 다양한 동물들을 만나게 됐다! 마음에 드는 동물은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '지속적으로 많은 사람들의 관심을 받고 싶어 하는 동물',
          },
          {
            type: 'I',
            score: 5,
            content: '조용한 시간을 즐기며 깊은 교감을 원하는 동물',
          },
        ],
      },
      {
        which: 'EI',
        question: '담당 동물을 배정받기 전 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `동료 사육사분들과 친한 친구처럼 이야기꽃을 펼친다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `동료 사육사분들과 인사를 나눈 후 얌전히 배정을 기다린다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '동료 사육사분이 돌보고 싶은 동물을 물어보았다! 그때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `평소 자신이 좋아했거나 귀여운 동물을 말한다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `여러 동물을 생각에 빠졌다가 뒤늦게 좋아했던 동물을 말한다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '돌보고 싶은 동물과 만나게 되었다! 그때 내 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '너무 귀엽다. 너무 사랑스러워!',
          },
          {
            type: 'N',
            score: 5,
            content: `내가 사육하게 되면 어떻게 소통해야 할지 생각에 잠긴다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '구석에 혼자 있는 동물을 보게 된 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `저 동물은 왜 혼자 있는 거지..?`,
          },
          {
            type: 'N',
            score: 5,
            content: `혼자 있는 것에 대한 많은 이유들을 상상해 본다.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `동료 사육사가 구석에 있는 동물은 무리에서 겉돈다고 말해줬다. 그때 든 생각은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '저런.. 안타깝네...',
          },
          {
            type: 'F',
            score: 5,
            content: '겉돈다니 너무 불쌍하다.. 내가 더 많이 챙겨줘야지',
          },
        ],
      },
      {
        which: 'TF',
        question: '앞으로 사육해야 하는 동물이 결정되었다. 그때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `내가 동물을 사육하다니 기대된다.`,
          },
          {
            type: 'F',
            score: 5,
            content: '동물들이 항상 건강하게 신경 쓰면서 놀아줘야지',
          },
        ],
      },
      {
        which: 'TF',
        question: `낯을 가리는지 나에게 다가오지 않는다. 그때 내 행동은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '상황을 분석하며 동물을 이해하려고 노력한다.',
          },
          {
            type: 'F',
            score: 5,
            content: '계속 다가오지 않으면 어쩌지 걱정하며 방법을 고민해 본다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '동물을 돌보는 요일을 조율해야 한다. 그때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `캘린더를 확인해 보고 하고 싶은 요일을 말한다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `즉흥적으로 쉬고 싶은 요일을 말한다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '동물을 돌보기에 앞서 가장 먼저 할 행동은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '동물의 특성을 파악하고 공부한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '동물이 신나도록 함께 놀아준다.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '집에 돌아가려 하는데 동물이 아쉬워하는 모습을 보인다. 그때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '다음 일정이 있기에 일정을 조율한 후 놀아주고 간다.',
          },
          {
            type: 'P',
            score: 5,
            content: '집에 가야 하지만.. 조금만 놀아주고 가지 뭐..',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/zookeeper/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/zookeeper/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/zookeeper/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/zookeeper/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/zookeeper/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/zookeeper/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/zookeeper/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/zookeeper/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/zookeeper/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/zookeeper/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/zookeeper/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/zookeeper/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/zookeeper/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/zookeeper/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/zookeeper/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/zookeeper/INFP.jpg',
      },
    ],
  },
  // distributionTable
  {
    info: {
      mainTitle: '분포표 성격 테스트',
      subTitle: '분포표로 알아보는 내 성격은?',
      mainUrl: 'distributionTable',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/distributionTable-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/distributionTable-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '조금은 어색한 친구가 밝게 인사하며 다가온다면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '같이 반갑게 인사하며 말을 건다',
          },
          {
            type: 'I',
            score: 5,
            content: '소심하게 인사하고 급한 척 자리를 뜬다 ',
          },
        ],
      },
      {
        which: 'EI',
        question: '어떤 문제가 생겼을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '다른 사람에게 도움을 요청해서 해결하려고 함',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자 스스로 해결해 보려고 노력함',
          },
        ],
      },
      {
        which: 'EI',
        question: '대인 관계에서 내 장점은 무엇일까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `상대의 기분이 좋아질 수 있는 말과 행동을 함`,
          },
          {
            type: 'I',
            score: 5,
            content: `상대의 기분이 나빠지지 않도록 조심하면서 배려함 `,
          },
        ],
      },
      {
        which: 'SN',
        question: '눈앞에 예쁜 분홍색 꽃이 있다. 나의 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `분홍색 꽃이네`,
          },
          {
            type: 'N',
            score: 5,
            content: `뭔가 좋은 향기 날 것 같은데 맡아볼까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '걷다가 길에 떨어진 노트 한 권을 발견했다면 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '누가 떨어트렸나 보네',
          },
          {
            type: 'N',
            score: 5,
            content: `뭐지 안에 어떤 내용이 쓰여있을까 혹시 데스노트?ㅋㅋ `,
          },
        ],
      },
      {
        which: 'SN',
        question: '뭔가에 집중할 때 나는 어떨까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `몰입해서 끝까지 집중이 가능함`,
          },
          {
            type: 'N',
            score: 5,
            content: `몰입하다가 중간에 딴 생각을 자주 함`,
          },
        ],
      },
      {
        which: 'TF',
        question: `대화 중 전혀 관심 없는 얘기가 나온다면 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '대화에 참여하지 않고 딴짓함 ',
          },
          {
            type: 'F',
            score: 5,
            content: '그래도 경청하며 리액션 해 줌',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '최선을 다해서 열심히 노력했던 일에 대한 결과가 좋았을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `좋은 결과를 얻어낸 점이 기쁨`,
          },
          {
            type: 'F',
            score: 5,
            content: '노력했던 과정이 생각하며 감동이 벅차오름 ',
          },
        ],
      },
      {
        which: 'TF',
        question: `내가 더 민감하게 반응하는 것은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '나의 감정, 나의 상황 ',
          },
          {
            type: 'F',
            score: 5,
            content: '타인의 감정, 타인의 상황 ',
          },
        ],
      },
      {
        which: 'JP',
        question: '나의 게으름 정도는 어떨까?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `일단 계획은 시간별로 짜 놓지만 안 지키는 것 `,
          },
          {
            type: 'P',
            score: 5,
            content: `아무런 계획도 없이 누워있는 것 `,
          },
        ],
      },
      {
        which: 'JP',
        question: '약속 시간에 나갈 때 보통?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '약속 시간 전에 도착하는 등 늦지 않는 편이다 ',
          },
          {
            type: 'P',
            score: 5,
            content: '몇 분 늦을 것 같다고 연락하는 편이다 ',
          },
        ],
      },
      {
        which: 'JP',
        question: '평소 나의 하루는 어떻게 흘러갈까?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '정해진 루틴 대로 체계적으로 흘러감',
          },
          {
            type: 'P',
            score: 5,
            content: '흐르면 흐르는 대로 물 흘러가듯 살아감',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/distributionTable/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/distributionTable/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/distributionTable/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/distributionTable/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/distributionTable/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/distributionTable/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/distributionTable/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/distributionTable/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/distributionTable/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/distributionTable/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/distributionTable/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/distributionTable/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/distributionTable/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/distributionTable/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/distributionTable/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/distributionTable/INFP.jpg',
      },
    ],
  },
  // correspondence
  {
    info: {
      mainTitle: '가정통신문 성격 테스트',
      subTitle: '가정통신문으로 보는 나의 성격은?',
      mainUrl: 'correspondence',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/correspondence-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/correspondence-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '친구들과 대화할 때 나는 대화를 주도하는 편인가?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '맞다',
          },
          {
            type: 'I',
            score: 5,
            content: '아니다',
          },
        ],
      },
      {
        which: 'EI',
        question: '학교 끝나고 나의 발걸음이 향하는 곳은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구들이 있는 곳으로 향한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '집으로 향한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '발표를 할 때 나는 어떤 학생이었는가?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `거침없이 잘 말하며 발표하는 걸 즐기는 학생`,
          },
          {
            type: 'I',
            score: 5,
            content: `필요한 부분만 말하고 발표하는 걸 즐기지 않는 학생`,
          },
        ],
      },
      {
        which: 'SN',
        question: '평소 나의 머릿속은 어떤가?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `별 생각 안한다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `상상력과 아이디어가 풍부하다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '사물함에서 아주 오래된 교과서를 발견됐을 때 나의 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '와 대박.. 언제 적 거야',
          },
          {
            type: 'N',
            score: 5,
            content: `헐.. 이건 누가 썼던 걸까.. 누가 여기에다가 둔 걸까 지금은 뭐하고 살지 혹시 시간여행…?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '급식 메뉴판을 보는데 ‘외계인 말이’ 라는 메뉴가 있다 이름을 보고 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `이게 뭐지.. 하고 별생각 없다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `외계인 말이? 계란말이 김말이? 왜 이름을 이렇게 지었지?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 오늘 속상해서 빵 사 먹었다고 했을 때 나의 대답은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '속상한데 빵을 왜 먹었어?',
          },
          {
            type: 'F',
            score: 5,
            content: '왜 속상했어? ㅠㅠ',
          },
        ],
      },
      {
        which: 'TF',
        question: '내 앞에서 친구 둘이서 싸운다 나의 행동은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `지켜본다`,
          },
          {
            type: 'F',
            score: 5,
            content: '말린다',
          },
        ],
      },
      {
        which: 'TF',
        question: `마스크를 쓴 날 친구가 나에게 너 마스크 쓰니까 어려 보여 라고 한다 나의 생각은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '마스크 쓰면 어려 보이는구나',
          },
          {
            type: 'F',
            score: 5,
            content: '마스크 벗으면 삭아 보인다는 건가..',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 하루를 보내는 방식은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `무의미한 일상은 노노 계획하고 알차게 보내자`,
          },
          {
            type: 'P',
            score: 5,
            content: `무의미하게 누워있기 오히려 좋아~`,
          },
        ],
      },
      {
        which: 'JP',
        question: '현장체험학습 가기 전날 밤 나의 행동은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '내일 입을 옷과 준비물을 정리하고 잔다.',
          },
          {
            type: 'P',
            score: 5,
            content: '일찍 일어나서 하면 되지~ 모든 건 아침에 승부본다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '다가오는 겨울방학 나는 방학을 어떻게 보낼지',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '알차게 계획해 본다',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 맞이한다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/correspondence/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/correspondence/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/correspondence/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/correspondence/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/correspondence/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/correspondence/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/correspondence/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/correspondence/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/correspondence/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/correspondence/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/correspondence/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/correspondence/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/correspondence/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/correspondence/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/correspondence/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/correspondence/INFP.jpg',
      },
    ],
  },
  // romanceNovelChar
  {
    info: {
      mainTitle: '로맨스 소설 속 성격 테스트',
      subTitle: '로맨스 소설 속에 숨겨져 있는 내 성격을 찾아보자',
      mainUrl: 'romanceNovelChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/romanceNovelChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/romanceNovelChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          '소설을 읽다가 잠이 들어 깨 보니 낯선 방이다! 그때 내 행동은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '대화 상대를 찾으러 방 밖으로 나간다.',
          },
          {
            type: 'I',
            score: 5,
            content: '누군가 방 안으로 들어오기를 기다린다. ',
          },
        ],
      },
      {
        which: 'EI',
        question: '갑자기 방 안으로 소설 속에서만 봤던 유모가 찾아왔다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '소설 속 등장인물처럼 친한 척 말을 건다. ',
          },
          {
            type: 'I',
            score: 5,
            content: '신기하지만 부끄러운 마음에 빤히 쳐다본다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '무도회 초대장을 건네받았을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `누구누구 참석해? 무도회라니 기대된다!!`,
          },
          {
            type: 'I',
            score: 5,
            content: `아.. 귀찮은데.. 사람 많이 올 텐데.. 꼭 가야 해? `,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '무도회장에 도착하니 저 멀리서 주인공이 걸어온다! 내 속마음은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `우와!! 소설 속 주인공을 실제로 보게 되다니 신기해!! `,
          },
          {
            type: 'N',
            score: 5,
            content: `주인공!? 혹시 나한테 오는 거야? 말 걸면 어쩌지? `,
          },
        ],
      },
      {
        which: 'SN',
        question: '주인공이 아무 생각 없이 같이 춤을 추자고 한다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '주인공의 춤 요청인데 바로 수락해야지!! ',
          },
          {
            type: 'N',
            score: 5,
            content: `굳이 아무 생각 없이 추자고 하는 의미가 뭘까? `,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '신기한 얼굴로 빤히 바라보다 주인공에게 들켜버렸다! 내 속마음은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `어머 부끄러워라`,
          },
          {
            type: 'N',
            score: 5,
            content: `이상하게 생각하면 어떡하지?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `주인공과 춤을 추던 중 발을 밟혔다 나의 반응은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '일단 참고 나중에 춤추다 발을 밟힌 것 아팠다고 말한다 ',
          },
          {
            type: 'F',
            score: 5,
            content: '아프지만 꾹 참고 아무렇지 않은 척 행동한다. ',
          },
        ],
      },
      {
        which: 'TF',
        question: '주인공과 오붓한 찰나의 순간 흑막이 나타나 말을 걸었다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `신경 쓰지 않고 시큰둥하게 반응한다. `,
          },
          {
            type: 'F',
            score: 5,
            content:
              '좋은 시간을 방해한 것이 화나지만 조심스럽게 대화에 응한다. ',
          },
        ],
      },
      {
        which: 'TF',
        question: `흑막이 나를 싫어하는 티를 낸다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '싫어하는 이유를 물어본다. ',
          },
          {
            type: 'F',
            score: 5,
            content: '실수 한 부분이 있는지 기억을 되짚어본다. ',
          },
        ],
      },
      {
        which: 'JP',
        question: '흑막이 내게 죄를 뒤집어씌워 궁지에 몰리게 되었을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `증거를 상세하게 모으기 위한 계획을 짠다. `,
          },
          {
            type: 'P',
            score: 5,
            content: `간략하게 알고 있는 증거를 말하며 무죄를 주장한다. `,
          },
        ],
      },
      {
        which: 'JP',
        question: '진범이 잡히고 주인공이 나에게 형벌을 결정할 기회를 줬다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '어떻게 하면 더 고통스러운 형벌을 내릴지 리스트를 작성하며 생각해 본다.',
          },
          {
            type: 'P',
            score: 5,
            content: '즉흥적으로 떠오르는 형벌을 얘기한다. ',
          },
        ],
      },
      {
        which: 'JP',
        question: '갑자기 현실로 돌아오게 되었다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '꼼꼼하게 작성해 둔 위시리스트가 떠오르며 아쉬워한다. ',
          },
          {
            type: 'P',
            score: 5,
            content: '하고 싶었던 걸 까먹고 못해서 아쉬워한다. ',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/romanceNovelChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/romanceNovelChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/romanceNovelChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/romanceNovelChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/romanceNovelChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/romanceNovelChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/romanceNovelChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/romanceNovelChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/romanceNovelChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/romanceNovelChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/romanceNovelChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/romanceNovelChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/romanceNovelChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/romanceNovelChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/romanceNovelChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/romanceNovelChar/INFP.jpg',
      },
    ],
  },
  // colorRibbon
  {
    info: {
      mainTitle: '컬러 리본 테스트',
      subTitle: '컬러 리본으로 보는 나의 성격은',
      mainUrl: 'colorRibbon',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/colorRibbon-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/colorRibbon-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '지하철 맞은편 아는 지인을 만났다. 이때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '마치 어제 졸업한 것 마냥 반갑게 먼저 인사를 날린다.',
          },
          {
            type: 'I',
            score: 5,
            content: '모른 척하며 열심히 핸드폰 화면만 뚫어지게 쳐다본다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '고민이 깊어지는 중에 내가 하는 행동은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '당장 친구한테 연락해서 고민이 있다고 말한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '어떻게 해결할 수 있을까 생각 회로를 돌려본다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '갑자기 먹고 싶은 음식이 떠올랐다! 내 행동은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `나랑 이거 먹으러 갈 사람??? 대답할 때까지 단톡방에 물어본다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `혼자 조용히 배달시켜서 먹는다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '어딘가로 이동 중에 차가 너무 막힌다. 내 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `기어가네 기어가 어휴`,
          },
          {
            type: 'N',
            score: 5,
            content: `왜 이렇게 막히는 거야..? 도대체 다 어디를 가는 거야ㅜ`,
          },
        ],
      },
      {
        which: 'SN',
        question: '평소 대화를 할 때 내 모습은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '있었던 사실만을 구체적으로 애기함',
          },
          {
            type: 'N',
            score: 5,
            content: `~같이 ~느낌 알지? 비유적으로 표현하며 얘기함`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '길에서 급하게 전력 질주를 하고 있는 사람을 보면 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `‘급해 보이네’라고 생각한다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `왜 저렇게 뛰는지 이유가 궁금하다.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 잘못해서 연인과 이별하게 된 상황이다. 이때 내 반응은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '그건 근데 네 잘못이긴 해. 사과하고 잘 얘기해 봐.',
          },
          {
            type: 'F',
            score: 5,
            content: '그렇다고 이별까지?ㅜ 괜찮아 화해할 수 있을 거야!',
          },
        ],
      },
      {
        which: 'TF',
        question: '한 친구가 울자 친구들이 덩달아 눈물을 흘린다. 나는 어떨까?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `??? 아니 갑자기 왜 우는 거야??`,
          },
          {
            type: 'F',
            score: 5,
            content: '흡.. 나도 눈물이 나려고 하네.. ㅠ',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 다소 난해한 옷을 입고 왔다. 이때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '흠 너랑 그렇게 잘 어울리지는 않는 것 같아ㅎㅎ',
          },
          {
            type: 'F',
            score: 5,
            content: '옷 새로 샀구나?! 괜찮은 거 같아!',
          },
        ],
      },
      {
        which: 'JP',
        question: '예상하지 못한 변수가 발생했을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `행동이 멈추고 당황하며 새로운 계획을 다시 세운다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `빠르게 적응해서 유연하게 대처한다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '가고 싶은 곳이 생기면 나는 어떻게 행동할까?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '예약은 가능한지 언제 누구와 어떻게 갈지 플랜을 짠다.',
          },
          {
            type: 'P',
            score: 5,
            content: '대략적인 위치를 보고 일단 가보자고 한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '물건이 원래 있던 제자리에 없으면 어떨까?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '굉장히 불편하다. 당장 제자리에 돌려놓는다.',
          },
          {
            type: 'P',
            score: 5,
            content: '아무렇지 않다. 지금 있는 그 자리가 제자리다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorRibbon/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorRibbon/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/colorRibbon/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/colorRibbon/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorRibbon/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/colorRibbon/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/colorRibbon/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/colorRibbon/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorRibbon/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorRibbon/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/colorRibbon/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/colorRibbon/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorRibbon/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/colorRibbon/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/colorRibbon/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/colorRibbon/INFP.jpg',
      },
    ],
  },
  // mindFlowEng
  {
    info: {
      mainTitle: 'Stream of consciousness test',
      subTitle: 'Myself in a stream of consciousness',
      mainUrl: 'mindFlowEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/mindFlowEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/mindFlowEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          'If you follow the stream of consciousness, which one is closer to you?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Usually I don’t stay home much',
          },
          {
            type: 'I',
            score: 5,
            content: 'I tend to rest in bed at home',
          },
        ],
      },
      {
        which: 'EI',
        question: 'How do you feel after your trip?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I can go out and play again right away.',
          },
          {
            type: 'I',
            score: 5,
            content: 'I must rest at home first.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What do you think about during the month when you have a lot of appointments?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Oh, I have a lot of plans this month. It’s going to be fun!`,
          },
          {
            type: 'I',
            score: 5,
            content: `Just looking at the plans makes me tired.`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do you think when you see a road that looks slippery?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `It will be slippery. I have to walk carefully`,
          },
          {
            type: 'N',
            score: 5,
            content: `Oh, people might slip and get hurt. Injuries cost a lot of money, and if they hurt their leg, they won't be able to walk.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'On the way, I came across an abandoned house. When your friend says, ‘ What do you think it would be like to go there?’, what is your response?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'I don’t know because I haven’t been there. Wouldn’t it be really scary?',
          },
          {
            type: 'N',
            score: 5,
            content: `Maybe there is a ghost there, maybe a murderer is hiding there.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What do you think when you lie in bed the night before an interview?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Because there is heavy traffic at the interview location, I will go to bed early tonight and check my route carefully.`,
          },
          {
            type: 'N',
            score: 5,
            content: `What if I oversleep? What if there is traffic jam tomorrow? If there is a convenience store on the way, I will have to stop by.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What if your friend keeps talking when you want to go to the bathroom?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I will interrupt my friend to go to the bathroom.',
          },
          {
            type: 'F',
            score: 5,
            content:
              "I'll try to listen a little more first, but if I can't hold, I'll go to the bathroom.",
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What if you bought a Tanghulu even if your friend doesn’t want to eat?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I just eat Tanghulu as my friend won’t eat it`,
          },
          {
            type: 'F',
            score: 5,
            content:
              "I just don't feel comfortable eating alone, so I ask my friend again.",
          },
        ],
      },
      {
        which: 'TF',
        question: `What do you do when your friend offends you?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I honestly say I am offended.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'I pass it on without saying anything because I am afraid that we will fight.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Can you live with stream of consciousness without a plan?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `impossible`,
          },
          {
            type: 'P',
            score: 5,
            content: `possible`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'What do you do when it comes to holidays?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I make a plan for what to do.',
          },
          {
            type: 'P',
            score: 5,
            content: 'I just enjoy the holiday without any special plans.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Do you have a plan for what to do first when working?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'at least, I have rough plans',
          },
          {
            type: 'P',
            score: 5,
            content: 'I just do whatever I want to do first.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/mindFlowEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/mindFlowEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/mindFlowEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/mindFlowEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/mindFlowEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/mindFlowEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/mindFlowEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/mindFlowEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/mindFlowEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/mindFlowEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/mindFlowEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/mindFlowEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/mindFlowEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/mindFlowEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/mindFlowEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/mindFlowEng/INFP.jpg',
      },
    ],
  },
  // mindFlow
  {
    info: {
      mainTitle: '의식의 흐름 테스트',
      subTitle: '의식의 흐름으로 보는 나',
      mainUrl: 'mindFlow',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/mindFlow-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/mindFlow-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '의식의 흐름대로 따라가면 나의 행동은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '집 밖으로',
          },
          {
            type: 'I',
            score: 5,
            content: '침대로',
          },
        ],
      },
      {
        which: 'EI',
        question: '여행을 다녀온 뒤 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '바로 또 나가서 놀 수 있다.',
          },
          {
            type: 'I',
            score: 5,
            content: '무조건 집에서 쉬어야 한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '약속이 많은 달에 드는 생각은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `이번 달은 약속 많네 재밌겠다!`,
          },
          {
            type: 'I',
            score: 5,
            content: `보는 것만으로도 지치고 힘들다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '미끄러워 보이는 길을 볼 때 나의 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `미끄럽겠다 조심히 걸어야지`,
          },
          {
            type: 'N',
            score: 5,
            content: `누가 미끄러져서 다치는 거 아냐? 다치면 병원비 많이 나올 텐데 다리 다치면 못 걸을 텐데`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '길을 가다 폐가를 마주쳤다. 친구가 ‘저기 가면 어떨 거 같아?’라고 말했을 때 나의 대답은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '안 가봐서 모르겠다 진짜 무섭지 않을까?',
          },
          {
            type: 'N',
            score: 5,
            content: `귀신 나오는 거 아니야? 알고 보니 살인마라도 숨어있는 거 아니야?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '면접 보기 전날 밤 침대에 누워서 하는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `그 길은 차 막히니깐 지하철 타고 가야겠다 최소 환승 알아봐두고 얼른 자야지.`,
          },
          {
            type: 'N',
            score: 5,
            content: `내일 늦잠 자면 어쩌지? 길 가다가 차 막히면 어쩌지 가다가 편의점 있으면 들려야겠다.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `화장실에 가고 싶은데 친구가 말을 계속한다 나의 행동은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '나 화장실 가고 싶다고 하고 친구 말을 끊는다.',
          },
          {
            type: 'F',
            score: 5,
            content:
              '일단 참을 수 있을 때까지 듣다가 도저히 안되겠으면 말한다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구는 안 먹는다고 해서 혼자 탕후루를 샀을 때 나의 행동은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `신경 안 쓰고 나 혼자 먹는다`,
          },
          {
            type: 'F',
            score: 5,
            content: '혼자 먹기는 조금 그래서 한 입 먹을래? 라고 물어본다.',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구에게 기분이 상했을 때 나의 모습은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '기분 상했다고 솔직하게 말한다.',
          },
          {
            type: 'F',
            score: 5,
            content: '말하면 다툴까봐 말 안 하고 넘긴다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '나는 계획 없이 의식의 흐름대로 살 수 있는가?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `불가능하다`,
          },
          {
            type: 'P',
            score: 5,
            content: `가능하다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '휴일을 맞이할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '뭐 할지 계획이라도 세워둔다.',
          },
          {
            type: 'P',
            score: 5,
            content: '있는 그대로 휴일을 맞이한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '업무를 할 때 뭐부터 할지 계획이 세워져 있는가?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '대충이라도 세워져있다.',
          },
          {
            type: 'P',
            score: 5,
            content: '땡기는 것부터 한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/mindFlow/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/mindFlow/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/mindFlow/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/mindFlow/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/mindFlow/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/mindFlow/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/mindFlow/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/mindFlow/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/mindFlow/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/mindFlow/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/mindFlow/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/mindFlow/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/mindFlow/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/mindFlow/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/mindFlow/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/mindFlow/INFP.jpg',
      },
    ],
  },
  // colorThemeEng
  {
    info: {
      mainTitle: 'Color theme personality test',
      subTitle: 'What is your personality that you see as a color theme?',
      mainUrl: 'colorThemeEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/colorThemeEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/colorThemeEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'What about me when you have a lot to say?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: "It's convenient to talk in person",
          },
          {
            type: 'I',
            score: 5,
            content: "It's easier to write down and deliver",
          },
        ],
      },
      {
        which: 'EI',
        question: "What do I usually do when I don't have an appointment?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'First, you have to go out and do something',
          },
          {
            type: 'I',
            score: 5,
            content: 'Resting without taking a step away from home',
          },
        ],
      },
      {
        which: 'EI',
        question: "What do you think when you're asked?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Answer first and then think`,
          },
          {
            type: 'I',
            score: 5,
            content: `Answer after thought`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do you think when you watch fantasy movies or dramas?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `It's ridiculous, but it's fun`,
          },
          {
            type: 'N',
            score: 5,
            content: `If I were the main character, I would do this with this ability!`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do you usually do to people around you?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I tend to get questions',
          },
          {
            type: 'N',
            score: 5,
            content: `I tend to ask questions`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What would I be like when judging a person?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Judging by my own clear criteria`,
          },
          {
            type: 'N',
            score: 5,
            content: `There is no standard, but I judge it according to my feelings`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What do you do when you're with people?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Do what you want to do without looking at others',
          },
          {
            type: 'F',
            score: 5,
            content: "Act with consideration for people's feelings",
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What about me when I see a person who fell while walking on the street?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I think the road is slippery. I have to be careful`,
          },
          {
            type: 'F',
            score: 5,
            content: "You must be sick. Aren't you hurt a lot?",
          },
        ],
      },
      {
        which: 'TF',
        question: `What do you want from the other person when you talk about your concerns?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'A clear solution',
          },
          {
            type: 'F',
            score: 5,
            content: 'Empathy and warm consolation for my feelings',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What do you usually do as soon as you get home?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I organize my clothes first and do what I have to do`,
          },
          {
            type: 'P',
            score: 5,
            content: `First, lie down and rest`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Made a perfect plan for my standards! What about me after this?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Move according to the plan and try to keep it',
          },
          {
            type: 'P',
            score: 5,
            content:
              "In the end, I keep revising the schedule because I can't keep it",
          },
        ],
      },
      {
        which: 'JP',
        question:
          "What about me if there's something you couldn't finish within a set time?",
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "I feel uncomfortable if I can't keep the time. I really want to end it",
          },
          {
            type: 'P',
            score: 5,
            content:
              "I can't help it. I can do what's left tomorrow, so it's okay!",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorThemeEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorThemeEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/colorThemeEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/colorThemeEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorThemeEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/colorThemeEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/colorThemeEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/colorThemeEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorThemeEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorThemeEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/colorThemeEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/colorThemeEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorThemeEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/colorThemeEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/colorThemeEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/colorThemeEng/INFP.jpg',
      },
    ],
  },
  // colorTheme
  {
    info: {
      mainTitle: '컬러 테마 테스트',
      subTitle: '테마 별 컬러로 알아보는 내 성격은?',
      mainUrl: 'colorTheme',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/colorTheme-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/colorTheme-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '하고 싶은 말이 많을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '직접 만나 말로 하는 게 편함 ',
          },
          {
            type: 'I',
            score: 5,
            content: '글로 적어서 전달하는 게 편함',
          },
        ],
      },
      {
        which: 'EI',
        question: '약속이 없는 날에 주로 나는 뭘 할까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '일단 밖으로 나가 뭐라도 해야 함',
          },
          {
            type: 'I',
            score: 5,
            content: '집에서 한 발자국도 안 나가고 쉼',
          },
        ],
      },
      {
        which: 'EI',
        question: '질문을 받았을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `먼저 대답 한 후에 생각해 봄`,
          },
          {
            type: 'I',
            score: 5,
            content: `충분히 생각한 후에 대답함`,
          },
        ],
      },
      {
        which: 'SN',
        question: '판타지 영화나 드라마를 보면 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `말도 안 되는 내용이지만 재밌네 인정! `,
          },
          {
            type: 'N',
            score: 5,
            content: `내가 주인공이었다면 이 능력으로 이렇게 저렇게!!! `,
          },
        ],
      },
      {
        which: 'SN',
        question: '평소에 주변인들에게 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '질문을 받는 편이다',
          },
          {
            type: 'N',
            score: 5,
            content: `질문을 하는 편이다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '사람을 판단할 때 나는 어떨까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `나만의 명확한 기준으로 판단함`,
          },
          {
            type: 'N',
            score: 5,
            content: `기준은 없고 내 느낌대로 판단함`,
          },
        ],
      },
      {
        which: 'TF',
        question: `사람들과 있을 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '눈치 보지 않고 하고 싶은 걸 한다 ',
          },
          {
            type: 'F',
            score: 5,
            content: '주변 눈치를 보고 기분을 살핀다',
          },
        ],
      },
      {
        which: 'TF',
        question: '길을 걷다가 꽈당 넘어진 사람을 보면 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `좀 창피하실 듯.. `,
          },
          {
            type: 'F',
            score: 5,
            content: '아이고 다치신 거 아냐..? 아프겠다.. ',
          },
        ],
      },
      {
        which: 'TF',
        question: `고민을 얘기할 때 상대방에게 바라는 점은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '명확하고 똑 부러지는 해결 방안 ',
          },
          {
            type: 'F',
            score: 5,
            content: '내 감정에 대한 공감과 따듯한 위로',
          },
        ],
      },
      {
        which: 'JP',
        question: '집에 가자마자 주로 하는 일은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `입은 옷 등 정리 정돈 먼저`,
          },
          {
            type: 'P',
            score: 5,
            content: `일단 누워서 휴식 먼저 `,
          },
        ],
      },
      {
        which: 'JP',
        question: '내 기준 완벽한 계획표를 작성했다! 이후에 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '계획표에 따라서 움직이며 지키려고 노력한다',
          },
          {
            type: 'P',
            score: 5,
            content: '결국 지키지 못해서 계획표를 계속 수정한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '정해진 시간 내로 끝내지 못한 일이 있다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '짜증나고 불편 어떻게든 끝까지 해결하려고 함 ',
          },
          {
            type: 'P',
            score: 5,
            content: '어쩔 수 없다고 생각하며 내일은 꼭 끝내기로 함',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/colorTheme/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/colorTheme/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/colorTheme/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/colorTheme/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/colorTheme/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/colorTheme/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/colorTheme/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/colorTheme/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/colorTheme/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/colorTheme/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/colorTheme/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/colorTheme/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/colorTheme/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/colorTheme/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/colorTheme/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/colorTheme/INFP.jpg',
      },
    ],
  },
  // dmChar
  {
    info: {
      mainTitle: 'DM 성격 테스트',
      subTitle: 'DM으로 알아보는 나의 성격은?',
      mainUrl: 'dmChar',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/dmChar-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/dmChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '조별 과제 중 문제가 닥쳤다 이때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '“우리 이렇게 해보면 어떨까?”라고 먼저 의견을 먼저 말해 본다.',
          },
          {
            type: 'I',
            score: 5,
            content: '“이렇게 해보면 어떨까?”라고 속으로 먼저 생각해 본다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구들과 밥 먹을 때 나의 남은 밥 양은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '말하느라 밥이 줄지를 않는다.',
          },
          {
            type: 'I',
            score: 5,
            content: '들으면서 밥 먹는다고 거의 다 먹어 간다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '내가 생각하는 실외의 기준은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `건물 밖에 나와있으면 다 실외라고 생각한다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `건물 밖과 친척 집, 친구 집도 실외라고 생각한다.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '친구가 갑자기 “내가 외계인일 거라는 생각은 안 해봤어?”라는 질문에 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `무슨.. 그런 말도 안 되는…`,
          },
          {
            type: 'N',
            score: 5,
            content: `난 그런 상상해 본 적 있는데! 얼굴 뜯으면 외계인 얼굴 나올 것 같아!`,
          },
        ],
      },
      {
        which: 'SN',
        question: '자격증 시험이 코앞인데 공부가 잘 안된다. 이때 하는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '아 하기 싫어… 저번 회차에 비슷한 문제가 나왔었나.. 이 문제는 출제 빈도수가 몇 퍼일까..',
          },
          {
            type: 'N',
            score: 5,
            content: `아 하기 싫어… 시험이라는 건 왜 존재하는 걸까.. 시험이 없는 세상은 없는 걸까..`,
          },
        ],
      },
      {
        which: 'SN',
        question: '멋진 뮤지컬 무대를 보며 하는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `와.. 진짜 성량 좋으시다.. 다른 배우분도 하는 걸 보고 싶네!`,
          },
          {
            type: 'N',
            score: 5,
            content: `와.. 카메라는 몇 대나 있는 거지? 무대장치랑 연출에 얼마나 많은 노력과 사람들이 투입됐을까?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 핸드폰 떨어트려서 액정필름이 나갔다 이때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '어디서?? 뭐 하다가? 액정 안 깨졌어?',
          },
          {
            type: 'F',
            score: 5,
            content: '헐.. 괜찮아? 바꾼지 얼마 안 됐다며 짜증 나겠어',
          },
        ],
      },
      {
        which: 'TF',
        question: '“넌 왜 그렇게 잘난 척해?”라고 했을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `내가 어느 부분에서 잘난 척했다고 생각하는데?`,
          },
          {
            type: 'F',
            score: 5,
            content: '아.. 내가 언제?(마상)',
          },
        ],
      },
      {
        which: 'TF',
        question: `취업 준비 중이라 힘든 친구에게 내가 할 수 있는 말은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '준비 어떻게 하고 있어? 이력서는 넣어봤어?',
          },
          {
            type: 'F',
            score: 5,
            content: '힘들겠어ㅠㅠ 지금 열심히 하고 있으니까 잘될 거야!',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '여행 계획을 짰는데 친구가 갑자기 꼭 하고 싶은 것이 생겼다며 얘기하는 친구에게 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `앞에 일정을 30분 정도 당겨서 여기 잠깐 보고 가자! 뒤 일정에 차질 안 가게 하려면 이렇게 해야 해!`,
          },
          {
            type: 'P',
            score: 5,
            content: `오호 좋아! 그럼 가보자고!`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          '새로운 친목 모임에 가서 사회자가 “2시 30분까지 친해지세요!”라고 했을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '시간이 얼마나 남았는지 시간부터 확인한다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '이미 얘기를 잘하고 있어서 ‘이미 친해진 거 아닌가’라고 생각한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '한 친구가 여행 계획 브리핑 중에 드는 생각은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '정말 잘 짠 것 같아.. 맘에 들어! 계획대로만 가면 아주 멋진 여행이 될거야!',
          },
          {
            type: 'P',
            score: 5,
            content: '우리가 언제 일어날 줄 알고 하루 계획을 빡빡하게 짰을까?',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/dmChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/dmChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/dmChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/dmChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/dmChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/dmChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/dmChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/dmChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/dmChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/dmChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/dmChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/dmChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/dmChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/dmChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/dmChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/dmChar/INFP.jpg',
      },
    ],
  },
  // calendarChar
  {
    info: {
      mainTitle: '캘린더 성격 테스트',
      subTitle: '캘린더로 보는 나의 성격은?',
      mainUrl: 'calendarChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/calendarChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/calendarChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '나에게 쉬는 날의 의미는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '약속 잡고 노는 날',
          },
          {
            type: 'I',
            score: 5,
            content: '집에서 쉬는 날',
          },
        ],
      },
      {
        which: 'EI',
        question: '내가 에너지를 얻는 상황은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구들과 함께 시간을 보내는 상황',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자만의 시간을 즐기는 것',
          },
        ],
      },
      {
        which: 'EI',
        question: '우울할 때 혼자 집에 있으면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `더 우울해진다`,
          },
          {
            type: 'I',
            score: 5,
            content: `우울함이 해소된다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '‘아무 생각도 안 해!’의 담긴 나의 뜻은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `정말로 아무 생각 안 하고 있다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `머릿속엔 다른 상상을 하고 있다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '새로 산 캘린더에 짧은 글귀가 적혀있다. 나의 반응은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '아,, 뭐야 누가 썼던 건가?',
          },
          {
            type: 'N',
            score: 5,
            content: `엇,, 이건 뭐지 누군가 남겨둔 건가 뭔가 조금 더 특별하게 느껴진다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '캘린더에 짧게 일기를 쓴다면 내가 쓰는 방식은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `오늘 하루 있었던 사실만을 구체적으로`,
          },
          {
            type: 'N',
            score: 5,
            content: `오늘 하루 느꼈던 분위기 위주로`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 다른 친구랑 다퉜다며 이야기를 해주는데 명백하게 친구 잘못일 때 나의 대답은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '내가 듣기엔 너 잘못이 맞는 것 같아',
          },
          {
            type: 'F',
            score: 5,
            content:
              '화났겠다, 속상했겠다 등의 위로의 말을 건네며 모르는 척 한다.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '친구가 ‘나 우울해서 혼자 여행 다녀오게!’ 라고 말한다. 나의 반응은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `어디로 가게?!`,
          },
          {
            type: 'F',
            score: 5,
            content: '헐 무슨 일 있어? 왜 우울해?',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 옷을 샀다고 보여주는데 너무 안 어울릴 때 나의 반응은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '안 어울린다고 솔직하게 말해준다.',
          },
          {
            type: 'F',
            score: 5,
            content: '친구가 상처받을까 봐 솔직하게는 못 말한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '새로 산 캘린더를 작성할 때의 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `어떻게 작성할지 구상 후 유용하게 쓴다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `몇 번 끄적여보고 잊고 산다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '책상 정리를 할 때 나의 모습은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '경험이 많아 척척척 정리하고 끝낸다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '정리 과정에 나타난 물건에 관심이 생긴다. 그리고 정리는 잊어버린다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '이미 짜여진 계획표에 대한 나의 태도는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '특이사항 아니면 바꾸려 하지 않음',
          },
          {
            type: 'P',
            score: 5,
            content: '그때그때 더 좋은 게 있으면 바꿈',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/calendarChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/calendarChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/calendarChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/calendarChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/calendarChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/calendarChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/calendarChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/calendarChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/calendarChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/calendarChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/calendarChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/calendarChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/calendarChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/calendarChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/calendarChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/calendarChar/INFP.jpg',
      },
    ],
  },
  // moonRabbit
  {
    info: {
      mainTitle: '달토끼 연애 테스트',
      subTitle: '나는 연애할 때 어떤 달토끼를 닮았을까?',
      mainUrl: 'moonRabbit',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/moonRabbit-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/moonRabbit-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          '연인과 추석연휴에 데이트를 하기로 했다! \n내가 선호하는 데이트는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '요즘 여기가 핫하다던데?',
          },
          {
            type: 'I',
            score: 5,
            content: '밀린 드라마 몰아보자!',
          },
        ],
      },
      {
        which: 'EI',
        question: '좋아하는 사람에게 내가 하는 행동은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '적극적으로 호감을 표시한다',
          },
          {
            type: 'I',
            score: 5,
            content: '은근하게 그 사람을 챙겨준다',
          },
        ],
      },
      {
        which: 'EI',
        question: '연인이 친구 커플과의\n더블 데이트를 제안하는데…',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `새로운 사람들이랑 함께 놀면\n더 즐거울 것 같은데?`,
          },
          {
            type: 'I',
            score: 5,
            content: `처음 보는데 괜히 어색할 것 같은데…`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인이 맛있는 맛집을 발견했다며\n카톡을 보내는데…',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `와 여기 맛있겠다`,
          },
          {
            type: 'N',
            score: 5,
            content: `와 여기 맛있겠다\n(다음 데이트 때 여기 가자고 해야지)`,
          },
        ],
      },
      {
        which: 'SN',
        question: '자기 전, 오늘 있었던 썸남(썸녀)와의 데이트가 생각이 났다.',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '기분 좋게 꿀잠을 잔다',
          },
          {
            type: 'N',
            score: 5,
            content: `이 사람에게 언제 고백해야 하나 고민한다 `,
          },
        ],
      },
      {
        which: 'SN',
        question: '애인에게 자주하는 말버릇은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `그럴 수 있지 / 근데?`,
          },
          {
            type: 'N',
            score: 5,
            content: `만약에..! / 왜?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `내가 연인으로 더 만나고 싶은 사람은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '외모가 내 스타일인데 본업까지 잘하는 사람',
          },
          {
            type: 'F',
            score: 5,
            content: '외모가 내 스타일인데 성격까지 좋은 사람',
          },
        ],
      },
      {
        which: 'TF',
        question: '썸남(썸녀)과 데이트하기로 한 나. 20분 정도 늦고 말았다.',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `지하철이 연착되는 바람에 늦어버렸어 미안해`,
          },
          {
            type: 'F',
            score: 5,
            content: '날도 더운데 기다리느라 힘들었겠다 미안해',
          },
        ],
      },
      {
        which: 'TF',
        question: `애인이 ‘슬픔을 나누면?’이라고 묻는데…`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '‘슬’과 ‘픔’',
          },
          {
            type: 'F',
            score: 5,
            content: '함께 이겨낼 수 있지!',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '시험이 당장 내일인데, 오늘 저녁에 볼 수 있냐는 썸남(썸녀)의 카톡',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `시험까지 남은 시간을 확인하고 만난다`,
          },
          {
            type: 'P',
            score: 5,
            content: `어떻게든 되겠지 일단 보자`,
          },
        ],
      },
      {
        which: 'JP',
        question: '함께 가려고 했던 곳이 추석연휴라고 문을 닫았다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '이럴 줄 알고 미리 플랜 B를 준비했지',
          },
          {
            type: 'P',
            score: 5,
            content: '주위에 분위기 좋아 보이는 곳을 빠르게 스캔한다 ',
          },
        ],
      },
      {
        which: 'JP',
        question: '추석연휴도 긴데 여행 가자는 애인의 말에 나는',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '내가 숙소 알아볼게 너는 항공권 알아볼래?',
          },
          {
            type: 'P',
            score: 5,
            content: '오 굿굿 좋다 제주도 가서 드라이브 고?',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/moonRabbit/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/moonRabbit/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/moonRabbit/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/moonRabbit/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/moonRabbit/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/moonRabbit/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/moonRabbit/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/moonRabbit/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/moonRabbit/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/moonRabbit/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/moonRabbit/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/moonRabbit/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/moonRabbit/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/moonRabbit/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/moonRabbit/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/moonRabbit/INFP.jpg',
      },
    ],
  },
  // profilePosterEng
  {
    info: {
      mainTitle: 'Profile Poster Personality Test',
      subTitle: 'What if my profile was written on the poster?',
      mainUrl: 'profilePosterEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/profilePosterEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/profilePosterEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          "How many friends do you meet often and think you're close to?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Difficult to count with one hand',
          },
          {
            type: 'I',
            score: 5,
            content: 'Can be counted in five fingers',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What is a true day off for me?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'To go out and meet someone and play',
          },
          {
            type: 'I',
            score: 5,
            content: 'To be alone, such as taking a rest or taking a walk',
          },
        ],
      },
      {
        which: 'EI',
        question: 'How about when you talk to someone?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Look into the eyes and say it`,
          },
          {
            type: 'I',
            score: 5,
            content: `I speak while avoiding my eyes`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What if I write my own profile?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Realistically what I'm doing now`,
          },
          {
            type: 'N',
            score: 5,
            content: `Abstractly write down what you want to do in the future`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do you look like when you watch a drama?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'I keep looking back on the impressive parts and think about the hidden meaning',
          },
          {
            type: 'N',
            score: 5,
            content: `I watch dramas while focusing on the content and acting`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'A pair of shoes were abandoned on the street! What do you think?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `There's only one pair of shoes?`,
          },
          {
            type: 'N',
            score: 5,
            content: `Whose shoes are these? Did the owner wear just one pair?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `My friend lost something that she really cares about. What's my reaction?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'When was the last time we saw it?',
          },
          {
            type: 'F',
            score: 5,
            content: "It's okay! You'll find it. Don't worry too much",
          },
        ],
      },
      {
        which: 'TF',
        question: 'To the people around you?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I often hear that I'm upset`,
          },
          {
            type: 'F',
            score: 5,
            content: "I often say that I'm upset",
          },
        ],
      },
      {
        which: 'TF',
        question: `What is my answer to a friend who tells me that I look particularly tired today?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Do I look tired? Where am I going?',
          },
          {
            type: 'F',
            score: 5,
            content: 'Right! I had a hard time today',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Which situation do I not understand more?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `The situation of wasting time thinking about where to go on the street`,
          },
          {
            type: 'P',
            score: 5,
            content: `A situation in which a person has to move only on a fixed schedule without flexibility`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'What about me when I find things at home?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: "You can find it right away because it's always in place",
          },
          {
            type: 'P',
            score: 5,
            content: 'It takes a long time to put it anywhere and find it',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What do I do when I look for good restaurants?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I made a list by weighing this and that, including the optimal movement and reasonable price',
          },
          {
            type: 'P',
            score: 5,
            content:
              'I decide after looking at the reviews of the restaurant that looks delicious',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/profilePosterEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/profilePosterEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/profilePosterEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/profilePosterEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/profilePosterEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/profilePosterEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/profilePosterEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/profilePosterEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/profilePosterEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/profilePosterEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/profilePosterEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/profilePosterEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/profilePosterEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/profilePosterEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/profilePosterEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/profilePosterEng/INFP.jpg',
      },
    ],
  },
  // profilePoster
  {
    info: {
      mainTitle: '프로필 포스터 성격 테스트',
      subTitle: '포스터에 내 프로필이 적힌다면?',
      mainUrl: 'profilePoster',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/profilePoster-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/profilePoster-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '자주 만나고 친하다고 생각하는 친구는 몇 명?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '한 손으로 세기 어려움',
          },
          {
            type: 'I',
            score: 5,
            content: '다섯 손가락 안에 꼽을 수 있음 ',
          },
        ],
      },
      {
        which: 'EI',
        question: '나에게 진정한 쉬는 날은 무엇일까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '밖으로 나가서 누군가를 만나 노는 것 ',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자 쉬거나 산책 등 혼자 있는 것 ',
          },
        ],
      },
      {
        which: 'EI',
        question: '누군가와 대화할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `눈을 바라보며 말한다 `,
          },
          {
            type: 'I',
            score: 5,
            content: `힐끔힐끔 보며 말한다 `,
          },
        ],
      },
      {
        which: 'SN',
        question: '나만의 프로필을 작성한다면 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `현재 내가 뭘 하고 있는지 현실적으로 적음 `,
          },
          {
            type: 'N',
            score: 5,
            content: `미래에 뭘 하고 싶은지 추상적으로 적음`,
          },
        ],
      },
      {
        which: 'SN',
        question: '밀린 드라마를 몰아서 볼 때 내 모습은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '드라마 내용과 연기에 집중하며 정주행함 ',
          },
          {
            type: 'N',
            score: 5,
            content: `인상적이었던 부분을 계속 돌려보며 숨은 뜻을 생각해 봄`,
          },
        ],
      },
      {
        which: 'SN',
        question: '길가에 신발 한 짝이 버려져 있다! 이때 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `신발이 한 짝만 있네 `,
          },
          {
            type: 'N',
            score: 5,
            content: `누구 신발일까 저 신발 주인은 한 짝만 신고 간 건가? `,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 굉장히 아끼는 물건을 잃어버렸다 나의 반응은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '어디서 마지막으로 봤어? 잘 기억해 봐 찾아야지',
          },
          {
            type: 'F',
            score: 5,
            content: '어떡해 아끼던 건데ㅜ 괜찮아 우리 같이 찾아보자! ',
          },
        ],
      },
      {
        which: 'TF',
        question: '평소 주변인들에게 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `서운하다는 말을 많이 듣는 편이다`,
          },
          {
            type: 'F',
            score: 5,
            content: '서운하다는 말을 많이 하는 편이다 ',
          },
        ],
      },
      {
        which: 'TF',
        question: `나에게 오늘 유난히 힘들어 보인다는 친구에게 내 대답은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '나 힘들어 보여? 내 어디가?',
          },
          {
            type: 'F',
            score: 5,
            content: '그치! 나 오늘 완전 힘들었잖아ㅜㅜ 역시 아는구나?? ',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 더 이해가 안 가는 상황은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `길바닥에서 어디 갈지 고민하며 시간 낭비하는 상황`,
          },
          {
            type: 'P',
            score: 5,
            content: `융통성 없이 정해진 일정대로만 움직여야 하는 상황 `,
          },
        ],
      },
      {
        which: 'JP',
        question: '집에서 어떤 물건을 찾을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '항상 제자리에 있기 때문에 바로 찾을 수 있음 ',
          },
          {
            type: 'P',
            score: 5,
            content: '아무 곳에나 두었다가 찾으려니 오래 걸림',
          },
        ],
      },
      {
        which: 'JP',
        question: '맛집을 알아볼 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '최적의 동선과 합리적인 가격 등 이것저것 따져가며 리스트를 짬',
          },
          {
            type: 'P',
            score: 5,
            content:
              '맛있어 보이는 식당 리뷰 등 대충 알아보다가 일단 가서 정하자고 함 ',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/profilePoster/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/profilePoster/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/profilePoster/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/profilePoster/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/profilePoster/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/profilePoster/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/profilePoster/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/profilePoster/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/profilePoster/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/profilePoster/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/profilePoster/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/profilePoster/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/profilePoster/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/profilePoster/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/profilePoster/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/profilePoster/INFP.jpg',
      },
    ],
  },
  // colorComboEng
  {
    info: {
      mainTitle: 'Color Combination Personality Test',
      subTitle: `Let's combine colors that suit my personality!`,
      mainUrl: 'colorComboEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/colorComboEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/colorComboEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'What if people suddenly pay a lot of attention to me?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Be more curious about me!',
          },
          {
            type: 'I',
            score: 5,
            content: 'Please turn off your attention to me.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What if the owner talks to you in a friendly way at the restaurant you went to for the first time?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: "You're a pleasant person. It's fun. I'll be back",
          },
          {
            type: 'I',
            score: 5,
            content:
              "Thank you, but it's a little uncomfortable... I'm thinking about whether to come again or not",
          },
        ],
      },
      {
        which: 'EI',
        question: 'If you had to choose between the two?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Introduce yourself for 10 minutes in front of strangers`,
          },
          {
            type: 'I',
            score: 5,
            content: `I can't meet my close friends for a month`,
          },
        ],
      },
      {
        which: 'SN',
        question: "If you're going up the high stairs, what do you think?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: `When will it all go up... It's hard`,
          },
          {
            type: 'N',
            score: 5,
            content: `Will I be able to live if I fall from here..?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What comes to your mind when you see a puddle formed by rain?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Just a puddle',
          },
          {
            type: 'N',
            score: 5,
            content: `If I jump in here, won't I go to another world`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          "What about me after watching the movie that ended with 'I lived happily'?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Oh, it's a happy ending`,
          },
          {
            type: 'N',
            score: 5,
            content: `How do the main characters live happily? I'm curious.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `When I express my interest?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Expressing Facts Only',
          },
          {
            type: 'F',
            score: 5,
            content: 'Express it as a compliment',
          },
        ],
      },
      {
        which: 'TF',
        question: "What about me when I'm angry at the other person?",
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I say right away that I'm offended`,
          },
          {
            type: 'F',
            score: 5,
            content:
              "I want you to know my feelings first, even if I don't say it",
          },
        ],
      },
      {
        which: 'TF',
        question: `Everyone is choosing a menu together, but what about me?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'They say they have a menu they want to eat',
          },
          {
            type: 'F',
            score: 5,
            content: 'NOTE Choose the same menu as everyone',
          },
        ],
      },
      {
        which: 'JP',
        question: "What about me when things don't work out the way I thought?",
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Annoying and frustrating`,
          },
          {
            type: 'P',
            score: 5,
            content: `I can't help it.`,
          },
        ],
      },
      {
        which: 'JP',
        question: "What's harder for me?",
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Forgetting or putting off what needs to be done',
          },
          {
            type: 'P',
            score: 5,
            content: 'To do things in time or in advance',
          },
        ],
      },
      {
        which: 'JP',
        question: 'How do I organize things?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Tidy up so that you can see it at a glance when you need it!',
          },
          {
            type: 'P',
            score: 5,
            content: 'organize roughly so that we can find it later',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorComboEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorComboEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/colorComboEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/colorComboEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorComboEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/colorComboEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/colorComboEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/colorComboEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorComboEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorComboEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/colorComboEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/colorComboEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorComboEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/colorComboEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/colorComboEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/colorComboEng/INFP.jpg',
      },
    ],
  },
  // colorCombo
  {
    info: {
      mainTitle: '색 조합 성격 테스트',
      subTitle: '내 성격에 어울리는 색을 조합해보자!',
      mainUrl: 'colorCombo',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/colorCombo-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/colorCombo-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '갑자기 사람들이 나에 대해 많은 관심을 가진다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '관심을 즐김 나를 더 궁금해해줘!',
          },
          {
            type: 'I',
            score: 5,
            content: '도망가고 싶음 나에게 관심 꺼줘..',
          },
        ],
      },
      {
        which: 'EI',
        question: '처음 간 식당에서 주인분이 친근하게 말을 건다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '유쾌한 분이시네~ 재밌다 여기 다음에 또 와야지',
          },
          {
            type: 'I',
            score: 5,
            content: '감사하지만 조금 불편.. 또 올지 말지 고민함',
          },
        ],
      },
      {
        which: 'EI',
        question: '둘 중에 하나를 고르라면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `낯선 사람들 앞에서 자기소개 10분 하기`,
          },
          {
            type: 'I',
            score: 5,
            content: `친한 친구들 한 달 동안 못 만나기`,
          },
        ],
      },
      {
        which: 'SN',
        question: '높은 계단을 올라가는 중이다! 이때 난 무슨 생각을 할까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `언제 다 올라가지.. 어우 힘들어 `,
          },
          {
            type: 'N',
            score: 5,
            content: `힘들다,,ㅜ 근데.. 여기서 떨어지면 살 수 있을까..?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '비가 와서 생긴 물웅덩이를 보고 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '그냥 물웅덩이',
          },
          {
            type: 'N',
            score: 5,
            content: `여기로 뛰어들면 과거로 가고 막 그러는 거 아냐?ㅎㅎ`,
          },
        ],
      },
      {
        which: 'SN',
        question: '‘행복하게 살았답니다’ 로 끝난 영화를 보고 난 후 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `오~ 해피엔딩이네`,
          },
          {
            type: 'N',
            score: 5,
            content: `어떻게 행복하게 살까?? 결혼하고 오순도순?? 궁금하다`,
          },
        ],
      },
      {
        which: 'TF',
        question: `내게 표현하기 더 자연스러운 것은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '팩트로 표현',
          },
          {
            type: 'F',
            score: 5,
            content: '칭찬으로 표현',
          },
        ],
      },
      {
        which: 'TF',
        question: '상대방에게 화가 났을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `기분 나쁘다고 바로 말한다`,
          },
          {
            type: 'F',
            score: 5,
            content: '말하지 않아도 서운한 내 감정을 먼저 알아줬으면 좋겠다',
          },
        ],
      },
      {
        which: 'TF',
        question: `모두 함께 메뉴를 고르는 데 먹고 싶은 메뉴가 따로 있는 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '먹고 싶은 메뉴가 있다고 말한다',
          },
          {
            type: 'F',
            score: 5,
            content: '참고 모두와 같은 메뉴를 고른다',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 생각한 대로 일이 풀리지 않을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `짜증 나고 답답함`,
          },
          {
            type: 'P',
            score: 5,
            content: `그런가 보다 하고 넘김`,
          },
        ],
      },
      {
        which: 'JP',
        question: '내게 더 어려운 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '미루고 미루다가 결국 까먹기',
          },
          {
            type: 'P',
            score: 5,
            content: '제때 맞춰서 혹은 미리미리 할 일 해놓기',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 물건을 정리하는 방법은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '딱딱 맞춰서 필요할 때 한눈에 보이도록 정리',
          },
          {
            type: 'P',
            score: 5,
            content: '대충 내가 찾아볼 수 있도록 정리',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/colorCombo/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/colorCombo/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/colorCombo/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/colorCombo/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/colorCombo/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/colorCombo/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/colorCombo/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/colorCombo/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/colorCombo/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/colorCombo/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/colorCombo/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/colorCombo/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/colorCombo/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/colorCombo/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/colorCombo/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/colorCombo/INFP.jpg',
      },
    ],
  },
  // meOther
  {
    info: {
      mainTitle: '나와 남 테스트',
      subTitle: '내가 생각하는 나와 남이 생각하는 나는?',
      mainUrl: 'meOther',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/meOther-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/meOther-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '내가 돈이 늘 없는 이유는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '맨날 친구들 만나고 쉬는 날 밖으로 나가서',
          },
          {
            type: 'I',
            score: 5,
            content: '집에서 맨날 배달음식 시켜 먹고 OTT 서비스 이용해서',
          },
        ],
      },
      {
        which: 'EI',
        question: '아르바이트 중 쉬는 시간일 때 나의 행동은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '이때다 싶어서 동료들과 스몰토크 수다 타임을 가진다.',
          },
          {
            type: 'I',
            score: 5,
            content:
              '떠들고 사람 사귀는 것 = 에너지를 쓰는 것이라고 생각해서 혼자 핸드폰 한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '짝사랑하는 이성에게 하는 행동은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `눈 마주치면서 웃으면서 소소한 일상 멘트를 추가해서 날려준다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `엄청난 용기와 다짐 그리고 여러 번의 시뮬레이션 끝에 결국 못하고 뒤돌아선다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '영화 본 후 CG에 대해서 이야기할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `아 진짜? 엄청나다.. 역시 감독이 유명하고 실력 있어서 그런지 몰입감이 장난 아니네.`,
          },
          {
            type: 'N',
            score: 5,
            content: `와 그게 진짜 다 사람이었다고?! 나도 엑스트라로 출연해 보고 싶다!`,
          },
        ],
      },
      {
        which: 'SN',
        question: '택시 타고 창밖을 보며 이동 중에 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '아 날씨 많이 선선해졌다. 시원하고 좋아.',
          },
          {
            type: 'N',
            score: 5,
            content: `여행 가고 싶다.. 아 잠깐만 저번에 직구로 시킨 거 왜 안 오지?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '우리는 왜 태어난 걸까?라는 질문에 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `글쎄.. 나는 배고파서 치킨이나 시켜 먹으려고 하는데 같이 먹을래?`,
          },
          {
            type: 'N',
            score: 5,
            content: `그러게.. 우리는 진짜 왜 태어난 걸까? 그리고 우리는 어디로 향해 앞으로 나가가고 있는 걸까..`,
          },
        ],
      },
      {
        which: 'TF',
        question: `애인이 “나 병원 좀 갔다가 약속 장소에 갈 것 같아!”라고 했을 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '헐 괜찮아? 실비보험은 들어놨고?',
          },
          {
            type: 'F',
            score: 5,
            content: '헐 괜찮아? 어디가 아파서 병원에 가는거야?ㅠㅠ',
          },
        ],
      },
      {
        which: 'TF',
        question: '곳곳에 은행들이 떨어져 있다. 이때 드는 생각은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `은행 냄새…으 냄새 실화냐!`,
          },
          {
            type: 'F',
            score: 5,
            content: '오 이제 선선한 가을이다!',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 나를 위해 과일주스를 만들어줬는데 곤죽 같은 식감일 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '오 나쁘지 않은데? 근데 약간 식감이 죽 같아서 다음번엔 바나나 양을 줄이는 게 완벽할 것 같아! 고마워!',
          },
          {
            type: 'F',
            score: 5,
            content:
              '내가 아침 끼니 못 챙겨 먹는다고 한 게 생각이 났구나?! 나 너무 감동이야ㅠㅠ 고마워!',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 생각하는 플랜 B의 의미는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `계획해 뒀던 플랜 B`,
          },
          {
            type: 'P',
            score: 5,
            content: `방금 생각난 플랜 B`,
          },
        ],
      },
      {
        which: 'JP',
        question: '취미로 열심히 만든 영상파일이 날아갔다. 이때 드는 생각은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '단전에서부터 분노가 끓어오른다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '그래도 우리 만들면서 재밌었으니까 그걸로 됐다고 생각한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '여행 계획 중 엄청난 피자 맛집을 가게 됐다. 이때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '여기 위치가 우리가 내일 가는 동선 근처에 있으니까 내일 오후에 가는 게 효율적이야.',
          },
          {
            type: 'P',
            score: 5,
            content: '이탈리아 여행 중에 가면 되겠다! 아주 맛있겠어!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/meOther/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/meOther/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/meOther/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/meOther/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/meOther/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/meOther/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/meOther/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/meOther/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/meOther/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/meOther/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/meOther/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/meOther/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/meOther/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/meOther/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/meOther/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/meOther/INFP.jpg',
      },
    ],
  },
  // neonsignChar
  {
    info: {
      mainTitle: '네온사인 성격 테스트',
      subTitle: '네온사인으로 그려보는 나의 성격은?',
      mainUrl: 'neonsignChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/neonsignChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/neonsignChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '혼자 해외여행을 가려고 한다면 내 선택은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '새로운 사람들과 어울리며 놀 수 있는 만남이 가득한 여행',
          },
          {
            type: 'I',
            score: 5,
            content: '나 혼자 여러 곳을 돌며 힐링하는 여행',
          },
        ],
      },
      {
        which: 'EI',
        question: '둘 중 하나를 고른다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '1년 내내 꽉 차 있는 스케줄',
          },
          {
            type: 'I',
            score: 5,
            content: '1년 내내 집에만 있기',
          },
        ],
      },
      {
        which: 'EI',
        question: '사람이 많은 장소에서 발표를 하게 된다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `떨지 않고, 자신 있게 발표할 수 있다`,
          },
          {
            type: 'I',
            score: 5,
            content: `사람이 너무 많아 긴장이 된다`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '길을 가는데 어린아이들이 차도 가까이 뛰어다니는 상황, 내 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `위험하게 왜 저렇게 뛰어다녀?`,
          },
          {
            type: 'N',
            score: 5,
            content: `걱정하며, 일어나지 않을 끔찍한 상상을 한다`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '친구가 “만약에 네가 우주여행을 떠난다면 어떨 것 같아?”라고 물어본다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '야 우주여행 가려면 얼마나 많은 돈이 드는지 알아? 말이 되는 소리를 해',
          },
          {
            type: 'N',
            score: 5,
            content: `우주여행 함부로 갔다가 우주 미아가 되면 어떡하지.. 꼭 가야 하는 거야?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '길을 가다가 특이하고, 이상한 옷을 입은 사람을 봤다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `요즘 패션은 진짜 난해하구나..`,
          },
          {
            type: 'N',
            score: 5,
            content: `저 옷을 만든 사람의 의도는 무엇 이였을까? 저 사람은 어떤 생각으로 저 옷을 골랐을까?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 요즘 부쩍 외로워 보인다며, 나에게 이성 친구를 소개해 준다고 했다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '누군데? 사진 좀 보여줘! 근데 내가 외로워 보였어?',
          },
          {
            type: 'F',
            score: 5,
            content: '나를 생각해 줘서 고마워! 어떤 사람이야?',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '술자리 중 어떤 할머니가 들어오시며, 물건을 판매하려는 상황 내 생각은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `지금 나는 저 물건이 필요가 없어서 사드릴 수는 없어..`,
          },
          {
            type: 'F',
            score: 5,
            content: '왠지 사드려야만 할 것 같은 이 기분',
          },
        ],
      },
      {
        which: 'TF',
        question: `선물을 받는다면 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '요즘 나에게 꼭 필요했던 물건',
          },
          {
            type: 'F',
            score: 5,
            content: '주는 사람의 정성과, 마음이 담긴 물건',
          },
        ],
      },
      {
        which: 'JP',
        question: '약속이 있어, 외출을 할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `목적지까지 최단 시간 경로와, 버스가 언제 오는지 미리 알아보고 나간다`,
          },
          {
            type: 'P',
            score: 5,
            content: `약속 시간에 늦지 않을 정도로만 맞춰서 나간다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '둘 중 나와 비슷한 사람은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '하루 동안의 계획을 미리 정해 놓고, 루틴대로 딱딱 맞춰 사는 사람',
          },
          {
            type: 'P',
            score: 5,
            content:
              '뭐 할지 큰 틀만 정해 놓고, 융통성 있게 하루를 보내는 사람',
          },
        ],
      },
      {
        which: 'JP',
        question: '내 휴대폰의 앱 상태는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '알림은 모두 읽어서 없애고, 폴더 별로 앱을 정리해 놓는다',
          },
          {
            type: 'P',
            score: 5,
            content:
              '안 읽은 톡과 알림이 999+에 가깝고, 앱 정리가 되어있지 않다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/neonsignChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/neonsignChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/neonsignChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/neonsignChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/neonsignChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/neonsignChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/neonsignChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/neonsignChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/neonsignChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/neonsignChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/neonsignChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/neonsignChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/neonsignChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/neonsignChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/neonsignChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/neonsignChar/INFP.jpg',
      },
    ],
  },
  // stoneChar
  {
    info: {
      mainTitle: '돌멩이 성격 테스트',
      subTitle: '돌멩이로 알아보는 내 성격은?',
      mainUrl: 'stoneChar',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/stoneChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/stoneChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '말을 하는 돌멩이를 주웠다 나의 반응은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구들에게 나 말하는 돌멩이 주웠다고 구경시켜준다.',
          },
          {
            type: 'I',
            score: 5,
            content: '조용히 혼자 집으로 가져가서 지켜본다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '돌멩이와 친구가 된 나, 나의 일상은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '돌멩이를 데리고 매일 밖에 나가서 이곳저곳 구경시켜준다.',
          },
          {
            type: 'I',
            score: 5,
            content: '소중한 친구 돌멩이와 함께 집에서 은밀한 데이트를 즐긴다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구가 된 돌멩이 매일 나가서 놀자고 한다 나의 반응은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `오늘은 어디 갈래?! 바로 덥석 물어본다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `요즘 매일 나갔는데 오늘은 집에 있으면 안 될까?...`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '돌멩이가 사실 나는 우주에서 떨어진 운석이라며 지구에 외계인이 침략 한다는 사실을 전해주러 왔다고 한다. 나의 반응은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `외계인..? 진짜..? 언제 침략하는데?`,
          },
          {
            type: 'N',
            score: 5,
            content: `외계인이 진짜 있구나.. 어떡하지.. 뭐부터 준비해야 할지 머릿속이 복잡하다.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '돌멩이가 자신이 살던 우주 행성엔 청소기 외계인이 있다고 이야기해준다. 나의 반응은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '우와.. 너무 신기해 대박이다!',
          },
          {
            type: 'N',
            score: 5,
            content: `머릿속으로 상상의 나래를 펼쳐본다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '돌멩이와 함께 지내면서 많이 하는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `말하는 돌멩이가 생기다니 너무 특별하고 좋다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `말하는 돌멩이가 또 있을까?, 내가 안 주웠으면 다른 사람이 주웠을까?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `돌멩이가 이제 본인의 행성으로 떠날 시간이라고 말한다. 나의 반응은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '그래 어쩔 수 없지 조심히 가..',
          },
          {
            type: 'F',
            score: 5,
            content: '그냥 나랑 살면 안 돼..? 눈물이 왈칵 나려 한다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '돌멩이가 오늘따라 잠이 안 온다고 한다. 나의 반응은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `잠이 왜 안와?`,
          },
          {
            type: 'F',
            score: 5,
            content: '왜?.. 무슨 고민 있어?',
          },
        ],
      },
      {
        which: 'TF',
        question: `돌멩이가 다른 사람이랑도 살아보고 싶다고 한다. 나의 반응은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜??',
          },
          {
            type: 'F',
            score: 5,
            content: '이제 다른 사람한테 떠나려고 하나..',
          },
        ],
      },
      {
        which: 'JP',
        question: '앞으로 돌멩이와 함께할 수 있는 시간은 D-30일 나의 계획은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `알차고 유익하게 보낼 수 있게 계획을 짜봐야겠다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `30일 밖에 안 남았다니.. 생각만 하고 계획은 딱히 안 짠다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '돌멩이와 함께 놀이동산에 가기로 했다 나의 행동은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '몇 시에 개장이니까 맞춰서 가서 제일 먼저 이거 타고 저거 타자',
          },
          {
            type: 'P',
            score: 5,
            content: '와 우리 이것도 저것도 타고 공포체험도 하자!!',
          },
        ],
      },
      {
        which: 'JP',
        question: '돌멩이와 함께 할 일정에 문제가 생겼다. 나의 생각은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '스트레스와 함께 다른 계획을 생각해 본다.',
          },
          {
            type: 'P',
            score: 5,
            content: '앗 그럼 이날은 다른 거 해야겠다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/stoneChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/stoneChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/stoneChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/stoneChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/stoneChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/stoneChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/stoneChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/stoneChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/stoneChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/stoneChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/stoneChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/stoneChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/stoneChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/stoneChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/stoneChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/stoneChar/INFP.jpg',
      },
    ],
  },
  // lovePotion
  {
    info: {
      mainTitle: '사랑의 묘약 테스트',
      subTitle: '나의 성격으로 제조해 보는 사랑의 묘약은?',
      mainUrl: 'lovePotion',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/lovePotion-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/lovePotion-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '모임에서 자리가 중간과 끝자리만 딱 남았을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '중간에 앉아야지 소통을 편하고 즐겁게 할 수 있을 것 같아.',
          },
          {
            type: 'I',
            score: 5,
            content: '끝자리에 앉아야지 누가 나한테 말을 안 걸겠지.',
          },
        ],
      },
      {
        which: 'EI',
        question: '집에 있을 때 드는 생각은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '집에 있으면 아무것도 하는 게 없는데 나가서 산책이라도 좀 할까..',
          },
          {
            type: 'I',
            score: 5,
            content: '집에서 할 게 얼마나 많은데~ 우선 OTT부터 켜고~',
          },
        ],
      },
      {
        which: 'EI',
        question: '나에게 있어 에너지 충전의 의미는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `사람을 만나 에너지를 충전`,
          },
          {
            type: 'I',
            score: 5,
            content: `사람을 만나기 위해 에너지를 충전`,
          },
        ],
      },
      {
        which: 'SN',
        question: '카페에 부서진 의자가 보일 때 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `‘음.. 이게 말로만 듣던 인스타 감성 카페 느낌이구나..’하고 내 할 일 한다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `‘설마 앉다가 부러진 건가? 어쩌다가 저렇게 됐을까’ 어떤 일이 일어났을지 이미 시나리오 짜고 있다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 아이돌 가수가 됐다고 상상하면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '돈 많이 벌겠다. 부러워.. 근데 웃고 싶지 않은데도 웃어야 하고 힘들 것 같아. 난 못해!',
          },
          {
            type: 'N',
            score: 5,
            content: `엔딩 포즈는 이거 할 거고.. 만약에 최애 만나게 되면은 팬이라고 해야지! 그러다가 연락처 교환하고 잘되면…ㅎㅎ`,
          },
        ],
      },
      {
        which: 'SN',
        question: '토익 공부할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `아 하기 싫다.. 5분만 누워있다가 할까?! (하고 냅다 눕는다.)`,
          },
          {
            type: 'N',
            score: 5,
            content: `아 하기 싫다.. 근데 토익 점수 못 따면 취업 안 될 텐데.. 그럼 엄마가 슬퍼하시겠지?! 아 맞다 엄마한테 보낸 택배는 잘 도착했으려나?!`,
          },
        ],
      },
      {
        which: 'TF',
        question: `인테리어 후 나는 페인트 냄새가 진동 나서 어지럽다고 할 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '창문, 현관문까지 활짝 다 열어 놨어? 요즘 냄새 덜 나는 페인트 있던데 다음엔 그걸로 인테리어 해봐!',
          },
          {
            type: 'F',
            score: 5,
            content:
              '어지러우면 속도같이 울렁거렸을 것 같은데 괜찮아? 지금은 어때?',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 공모전 금상 수상했을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `축하해! 내가 너 이 부분에 분명히 재능 있다고 했었지?!`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '축하해! 너 며칠 동안 잠도 못 자고 속앓이 하더니 수고 많았어!',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 “나 오늘 너무 피곤해서 드라이 샴푸로 대충 쓱 하고 나왔어”라고 했을 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '오~ 드라이 샴푸 좋아? 그거 그냥 뿌리기만 하면 되는 거야?',
          },
          {
            type: 'F',
            score: 5,
            content: '헐? 얼마나 피곤했으면 드라이 샴푸로 하고 나왔어ㅠㅠ',
          },
        ],
      },
      {
        which: 'JP',
        question: '새로운 업무를 받았을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `바로 시작하기보다 이걸 어떻게 더 빨리 그릴 수 있고 이걸 어떻게 더 잘 그릴 수 있는지에 대해 고민한다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `어떻게 효율적으로 할까 이런 생각보다는 일단 빨리하자!`,
          },
        ],
      },
      {
        which: 'JP',
        question: '청소에 대한 나의 스타일은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '주기적으로 정리가 되어 있어야 한다고 생각하고 청소를 끝낸 후에 쉰다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '내가 하고 싶을 때 하는 게 좋다고 생각하고 추억의 물건 발견 후 구경에 빠진다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '작업 중 친구가 맛있는 치킨을 포장해 왔다. 이때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '10분만 더 하면 끝날 것 같으니 마무리하고 먹는다.',
          },
          {
            type: 'P',
            score: 5,
            content: '치킨은 바삭할 때 먹어야 하니 먹고 마저 한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/lovePotion/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/lovePotion/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/lovePotion/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/lovePotion/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/lovePotion/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/lovePotion/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/lovePotion/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/lovePotion/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/lovePotion/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/lovePotion/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/lovePotion/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/lovePotion/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/lovePotion/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/lovePotion/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/lovePotion/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/lovePotion/INFP.jpg',
      },
    ],
  },
  // puffyClouds
  {
    info: {
      mainTitle: '뭉게구름 연애 테스트',
      subTitle: '뭉게뭉게 내 마음을 뭉개봐~',
      mainUrl: 'puffyClouds',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/puffyClouds-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/puffyClouds-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '연인과 데이트 중 연인의 지인을 마주쳤을 때 나의 반응은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '인사하고 자연스럽게 이야기 많이 들었다며 함께 이야기를 나눈다.',
          },
          {
            type: 'I',
            score: 5,
            content: '인사만 가볍게 하고 옆에서 대화 나누는 걸 듣는다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '좋아하는 사람이 생겼을 때 나의 행동은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '좋아하는 걸 상대가 알 수 있도록 적극적으로 티를 낸다.',
          },
          {
            type: 'I',
            score: 5,
            content:
              '티 안 내고 조용하게 좋아하다 상대방도 쌍방인 것 같을 때 티 낸다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '연인과 트러블이 있을 때 나의 행동은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `친구들을 만나서 놀며 스트레스를 푼다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `혼자만의 시간을 가지면서 스트레스를 푼다.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '연인과 한강 데이트를 가서 두둥실 떠다니는 구름을 보고 있을 때 나의 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `와~ 날씨 맑다~!`,
          },
          {
            type: 'N',
            score: 5,
            content: `구름을 보면서도 머릿속엔 이런저런 여러 가지 생각들이 든다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인에게 요리를 해주기로 마음먹은 날! 나의 요리방법은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '레시피에 나온 대로 정량을 딱 맞춘다.',
          },
          {
            type: 'N',
            score: 5,
            content: `레시피를 보며 손대중으로 이 정도면 되겠지 하고 넣는다.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '사귄 지 얼마 안 됐을 때 연인이 미래의 우리에 대해 이야기한다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `별로 만나지도 않았는데 벌써 미래를..?`,
          },
          {
            type: 'N',
            score: 5,
            content: `상상은 자유니까 미래를 함께 상상해 본다.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `요즘 일이 너무 바빠서 입병 났다는 연인 이때 나의 반응은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '에구.. 무슨 일이 그렇게 바빴어?',
          },
          {
            type: 'F',
            score: 5,
            content: '에구.. 많이 바빴구나 힘들었겠다ㅠㅠ 많이 아파??!!',
          },
        ],
      },
      {
        which: 'TF',
        question: '할 말이 있다고 이따 잠깐 보자는 연인 나의 생각은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `무슨 일 있나? 이따 보면 알겠지.`,
          },
          {
            type: 'F',
            score: 5,
            content: '머릿속으로 무슨 말을 하려 하는지 수많은 것들을 생각한다.',
          },
        ],
      },
      {
        which: 'TF',
        question: `연인에게 서운할 때 나의 반응은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '이런 부분이 서운하다고 솔직하게 말한다.',
          },
          {
            type: 'F',
            score: 5,
            content: '바로 입이 삐쭉 나와서 서운함을 티 낸다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '내일은 데이트하는 날 나의 계획은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `어디에서 몇 시에 만나고 어디에 가서 무엇을 먹을지 알아본다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `어디에서 몇 시에 만날지만 알고 나간다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '할 일을 끝내니 시간이 남았다 나의 행동은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '시간 여유가 있네? 남은 시간은 그럼 운동이라도 좀 해볼까?',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 바로 침대로 골인~!',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 세 달 뒤 유럽여행을 계획했다 나의 계획 스타일은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '우선 비행기 표부터 보고 어디로 돌지 정하고 숙소 찾아봐야겠다.',
          },
          {
            type: 'P',
            score: 5,
            content: '뭐부터 해야 하지…? 일단 비행기 표부터 보자.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/puffyClouds/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/puffyClouds/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/puffyClouds/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/puffyClouds/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/puffyClouds/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/puffyClouds/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/puffyClouds/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/puffyClouds/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/puffyClouds/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/puffyClouds/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/puffyClouds/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/puffyClouds/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/puffyClouds/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/puffyClouds/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/puffyClouds/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/puffyClouds/INFP.jpg',
      },
    ],
  },
  // guestbookChar
  {
    info: {
      mainTitle: '나의 방명록 성격 테스트',
      subTitle: '내 미니홈피엔 어떤 방명록을 달아줄까?',
      mainUrl: 'guestbookChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/guestbookChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/guestbookChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '내게 새로운 취미가 생겼다! 주변에 얘기할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '지인들에게 같이 하자고 적극적으로 얘기한다',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자 조용히 하다가 나중에 얘기해 준다 ',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '집에서 친구와 함께 하루 종일 재미있게 놀았다! 친구가 집으로 간 뒤 내 모습은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '재밌었다! 다음에 또 오라고 해야지~ ',
          },
          {
            type: 'I',
            score: 5,
            content: '재밌었지만 피곤하다.. 드디어 쉴 수 있어.. ! ',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '오늘 하루 쉬지 않고 말을 정말 많이 했다 그 후 나의 모습은 어떨까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `후 스트레스 다 풀렸다 굿 `,
          },
          {
            type: 'I',
            score: 5,
            content: `어느 순간 급 피곤 이제 말 그만하고 싶어.. `,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '비밀이 있다며 의미심장하게 방명록을 쓰고 간 친구에게 내 반응은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `전화해서 뭔지 바로 불어 봄 `,
          },
          {
            type: 'N',
            score: 5,
            content: `물어보기 전 짧은 시간에 수많은 생각들이 머릿속에 떠다님 `,
          },
        ],
      },
      {
        which: 'SN',
        question: '잘 모르는 이성 친구가 반갑다며 방명록을 달았다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '그냥 인사하는 거겠지 ',
          },
          {
            type: 'N',
            score: 5,
            content: `뭐지..? 혹시 나한테 관심 있는 거 아니야? `,
          },
        ],
      },
      {
        which: 'SN',
        question: '뮤지컬을 보고 나왔다! 재밌었냐는 질문에 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `뮤지컬 스토리 좋던데? 연기도 잘하고 재밌었어`,
          },
          {
            type: 'N',
            score: 5,
            content: `재밌었어!! 스토리에 뭔가 숨은 뜻이 있는 게 분명해`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구의 말에 기분이 상해 화가 났을 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '기분 나쁘다고 바로 말한다 ',
          },
          {
            type: 'F',
            score: 5,
            content: '바로 말은 못 하고 조금씩 기분 나쁜 티를 낸다 ',
          },
        ],
      },
      {
        which: 'TF',
        question: '기분이 안 좋은 하루 어떤 방명록 글에 기분이 좋아질까?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `진심을 담은 조언 멘트와 해결 방안을 적어둔 글 `,
          },
          {
            type: 'F',
            score: 5,
            content: '따듯한 공감 멘트와 힘내라는 응원 글 ',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 나에게만 방명록을 안 써준다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '별로 신경 쓰지 않는다 ',
          },
          {
            type: 'F',
            score: 5,
            content: '왜 나만… 서운하다.. ',
          },
        ],
      },
      {
        which: 'JP',
        question: '외출을 할 때 보통 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `분명한 목적을 가지고 일정표를 확인 뒤 외출`,
          },
          {
            type: 'P',
            score: 5,
            content: `목적이 있어도 일단 그냥 나가 봄`,
          },
        ],
      },
      {
        which: 'JP',
        question: '즉흥으로 떠나게 된 여행! 나의 속마음은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '불편하고 불안하기 짝이 없음 ',
          },
          {
            type: 'P',
            score: 5,
            content: '오히려 더 재밌고 짜릿함 ',
          },
        ],
      },
      {
        which: 'JP',
        question: '하고 싶은 일이 생겨 시작할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '완벽하게 준비가 되면 시작한다 ',
          },
          {
            type: 'P',
            score: 5,
            content: '대략 틀을 잡아놓고 시작한 뒤 수정한다 ',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/guestbookChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/guestbookChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/guestbookChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/guestbookChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/guestbookChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/guestbookChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/guestbookChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/guestbookChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/guestbookChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/guestbookChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/guestbookChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/guestbookChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/guestbookChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/guestbookChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/guestbookChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/guestbookChar/INFP.jpg',
      },
    ],
  },
  // bookChar
  {
    info: {
      mainTitle: '책 성격 테스트',
      subTitle: '책에 쓰여 있는 나의 성격은?',
      mainUrl: 'bookChar',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/bookChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/bookChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '친구가 독서 모임에 같이 들어가자고 한다면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '다양한 사람들과 만나서 대화하는 것이 재밌을 것 같아, 그러자고 한다',
          },
          {
            type: 'I',
            score: 5,
            content:
              '좋을 것 같긴 하지만, 많은 사람과 대화라니 벌써 기 빨린다..',
          },
        ],
      },
      {
        which: 'EI',
        question: '내가 책을 읽는 장소를 고른다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '카페, 도서관, 서점 등',
          },
          {
            type: 'I',
            score: 5,
            content: '조용한 내 방, 집',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구들과 밥 먹을 때 나와 가까운 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `계속 말하면서 먹어, 먹는 속도가 느린 편이다`,
          },
          {
            type: 'I',
            score: 5,
            content: `친구들의 얘기를 들으면서, 먹는 것에 집중한다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '판타지 소설을 재밌게 읽은 후 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `너무 재밌는데?! 다음 시리즈 더 없나?`,
          },
          {
            type: 'N',
            score: 5,
            content: `내가 판타지 주인공이 되어, 악당을 물리치는 상상을 한다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '책을 읽을 때 나와 가까운 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '책 목차, 저자까지 다 훑어보고 처음부터 끝까지 꼼꼼하게 읽는다',
          },
          {
            type: 'N',
            score: 5,
            content: `책 제목을 보고 어떤 내용일지 미리 상상해 보며 읽는다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구와 대화할 때 내가 선호하는 대화 방식은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `한 가지 주제에 대해 끝까지 대화하고, 다음 주제에 대해 대화하는 것	`,
          },
          {
            type: 'N',
            score: 5,
            content: `의식의 흐름에 따라 대화 주제를 바꿔가며 대화하는 것`,
          },
        ],
      },
      {
        which: 'TF',
        question: `내가 지금 책 한 권을 읽는다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '자기 분야에서 성공한 사람의 자서전',
          },
          {
            type: 'F',
            score: 5,
            content: '감성을 충전할 수 있는 따뜻한 감성 에세이',
          },
        ],
      },
      {
        which: 'TF',
        question: '내가 읽고 싶은 책이 있다면 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `효율적으로 집에서 휴대폰으로 주문한다`,
          },
          {
            type: 'F',
            score: 5,
            content: '직접 서점에 가서 내가 읽고 싶은 책을 찾아보고, 산다',
          },
        ],
      },
      {
        which: 'TF',
        question: `내가 친구에게 “뭐 해?”라고 묻는 이유는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '진짜 지금 뭐 하고 있는지 궁금해서',
          },
          {
            type: 'F',
            score: 5,
            content: '혹시 약속이 없다면 만날 수 있는지 물어보려고',
          },
        ],
      },
      {
        which: 'JP',
        question: '책을 읽기로 결심했다면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `읽을 시간을 따로 정해 놓고, 그 시간에만 읽는다`,
          },
          {
            type: 'P',
            score: 5,
            content: `아무 때나 필 받으면 읽는다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '퇴근 후 나의 생활은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '미리 정해 놓은 계획대로 사는 생활',
          },
          {
            type: 'P',
            score: 5,
            content: '그때그때 상황에 따라 바뀌는 생활',
          },
        ],
      },
      {
        which: 'JP',
        question: '자주 가는 맛집에 신메뉴가 생겼다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '궁금하긴 하지만, 그래도 맛이 보장된 안정적인 메뉴를 주문한다',
          },
          {
            type: 'P',
            score: 5,
            content: '바로 과감하게 신메뉴를 주문한다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/bookChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/bookChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/bookChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/bookChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/bookChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/bookChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/bookChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/bookChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/bookChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/bookChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/bookChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/bookChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/bookChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/bookChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/bookChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/bookChar/INFP.jpg',
      },
    ],
  },
  // newsChar
  {
    info: {
      mainTitle: '뉴스 성격 테스트',
      subTitle: '뉴스로 나의 성격을 알아보자',
      mainUrl: 'newsChar',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/newsChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/newsChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '친구들과 대화할 때 나와 가까운 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '그냥 생각나는 대로 말하는 편이다.',
          },
          {
            type: 'I',
            score: 5,
            content: '한번은 생각하고 말하는 편이다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '한 달 동안 나에게 휴일이 딱 하루만 주어진다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구나, 연인과 밖에서 열심히 논다',
          },
          {
            type: 'I',
            score: 5,
            content: '그냥 집에서 푹 쉰다',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구들과 놀던 중 분위기가 집에 가야 할 분위기라면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `야 2차 가자 애들아!! 밤새 놀아야지!`,
          },
          {
            type: 'I',
            score: 5,
            content: `야 이제 집에 가자~ 내일 출근도 해야 하고..`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 선호하는 대화 주제는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `핫 한 이슈, 관심사에 대한 대화`,
          },
          {
            type: 'N',
            score: 5,
            content: `미래 지향적인 아이디어에 대한 대화`,
          },
        ],
      },
      {
        which: 'SN',
        question: '재밌는 드라마를 보고 난 후 나와 가까운 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '이 드라마 고증 완전 잘 표현했네? 재밌었다!',
          },
          {
            type: 'N',
            score: 5,
            content: `나였으면 결말을 다른 방식으로 생각해 봤을 텐데~`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구들과 여름 바캉스를 가기 전날, 자기 전에 드는 내 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `재밌겠다! 빨리 자야지~`,
          },
          {
            type: 'N',
            score: 5,
            content: `가서 물놀이하는데, 갑자기 식인 상어가 출몰하고 그러지는 않겠지?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `요즘 너무 힘들다는 친구에게 연락한다면 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '진심이 담긴 조언과 해결책에 대해 이야기한다.',
          },
          {
            type: 'F',
            score: 5,
            content: '친구에 감정에 대한 공감과 위로의 말을 건넨다',
          },
        ],
      },
      {
        which: 'TF',
        question: '친해지고 싶은 사람이 생겼다면 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `지속적인 관심과 질문으로 다가간다`,
          },
          {
            type: 'F',
            score: 5,
            content: '진심을 담은 리액션과 공감으로 다가간다',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구 두 명이 서로 싸우고 있다면? 내가 먼저 드는 생각은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜 싸우지? 누가 먼저 시비를 걸었을까?',
          },
          {
            type: 'F',
            score: 5,
            content: '둘 다 너무 격앙되어 있어, 일단 싸움을 말린다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 세운 계획이 틀어졌다면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `이런 상황에 대비해 준비한 플랜 B를 꺼낸다`,
          },
          {
            type: 'P',
            score: 5,
            content: `이제부터는 임기응변으로 대처한다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '나의 전자 메일함 정리 상태는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '필요한 메일만 남기고, 전부 깔끔하게 정리되어 있다.',
          },
          {
            type: 'P',
            score: 5,
            content: '안 읽은 메일이 쌓여 있고, 정리는 가끔 몰아서 한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 선호하는 일은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '틀이 있고, 반복적이고, 안정적인 일',
          },
          {
            type: 'P',
            score: 5,
            content: '틀이 정해져 있지 않은, 새로운 가능성이 있는 일',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/newsChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/newsChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/newsChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/newsChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/newsChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/newsChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/newsChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/newsChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/newsChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/newsChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/newsChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/newsChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/newsChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/newsChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/newsChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/newsChar/INFP.jpg',
      },
    ],
  },
  // zgChar
  {
    info: {
      mainTitle: '찌글이 성격 테스트',
      subTitle: '찌글이로 보는 나의 성격은?',
      mainUrl: 'zgChar',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/zgChar-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/zgChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '갑자기 놀자며 집으로 찾아온 친구! 내 반응은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '오늘 무슨 날이야?? 갑자기 머야~ 일단 들어와!',
          },
          {
            type: 'I',
            score: 5,
            content: '아니 갑자기 무슨 일이야..? (다시 돌아가 주면 안 될까..?)',
          },
        ],
      },
      {
        which: 'EI',
        question: '내게 친구가 생긴다면 어떻게?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '내가 먼저 다가가 말을 걸며 친해진다',
          },
          {
            type: 'I',
            score: 5,
            content: '친구가 먼저 다가와 줘서 친해진다',
          },
        ],
      },
      {
        which: 'EI',
        question: '약속의 빈도는 어떨까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `약속 없는 날이 거의 없음`,
          },
          {
            type: 'I',
            score: 5,
            content: `약속보다 집에 있는 날이 더 많음`,
          },
        ],
      },
      {
        which: 'SN',
        question: '어떤 문제가 닥쳤을 때 보통 나의 반응은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `그냥 그럴 수 있지`,
          },
          {
            type: 'N',
            score: 5,
            content: `어떻게 그럴 수가 있지..?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '대화를 할 때 말하는 방식은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '비유 없이 있었던 사실만을 구체적으로 얘기함',
          },
          {
            type: 'N',
            score: 5,
            content: `비유적으로 묘사하며 다 알아듣겠지라고 생각함`,
          },
        ],
      },
      {
        which: 'SN',
        question: '어떤 작품을 볼 때 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `멋지네 비싸 보여`,
          },
          {
            type: 'N',
            score: 5,
            content: `어떤 심오한 뜻이 담긴 걸까..?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `결과가 별로인데 위로를 받았을 때 솔직한 내 속마음은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '위로가 무슨 소용이지.. 해결되는 것도 아닌데.. 놀리는 건가?',
          },
          {
            type: 'F',
            score: 5,
            content: '위로받으니까 힘이 난다 고마워 정말',
          },
        ],
      },
      {
        which: 'TF',
        question: '사과를 받을 때 변명처럼 들리는 건?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `미안해 늦어서 화났지 진짜 미안해ㅜㅜ웅앵우우ㅜㅜ`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '내가 이거 하고 저거 하고 이러느라 늦은 건데 미안해 이유가 있었어',
          },
        ],
      },
      {
        which: 'TF',
        question: `오늘따라 피곤하다고 하는 친구에게 내 대답은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜? 그니까 일찍 잤어야지!',
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮아? 많이 피곤해?? 어쩐지 피곤해 보이더라ㅜ',
          },
        ],
      },
      {
        which: 'JP',
        question: '해보고 싶은 무언가가 생겼을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `언제 시작하면 좋을지 계획 먼저 짠다`,
          },
          {
            type: 'P',
            score: 5,
            content: `각 나오면 일단 먼저 시작하고 본다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '무계획으로 움직이는 것에 대해 나는 어떻게 생각할까?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '비효율적이라고 생각하고 스트레스를 받음',
          },
          {
            type: 'P',
            score: 5,
            content: '자유로움을 느끼고 크게 불편하지 않음',
          },
        ],
      },
      {
        which: 'JP',
        question: '예상하지 못했던 변수가 발생한다면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '당황스럽고 바로 대처하기가 어려움',
          },
          {
            type: 'P',
            score: 5,
            content: '당황스럽지만 금박 적응해서 잘 헤쳐나감',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/zgChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/zgChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/zgChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/zgChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/zgChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/zgChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/zgChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/zgChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/zgChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/zgChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/zgChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/zgChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/zgChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/zgChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/zgChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/zgChar/INFP.jpg',
      },
    ],
  },
  // remindProfile
  {
    info: {
      mainTitle: '리마인드 프로필 성격 테스트',
      subTitle: '다시 상기시켜보는 나의 성격은?',
      mainUrl: 'remindProfile',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/remindProfile-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/remindProfile-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '한주 동안 스트레스가 극에 달했다. 이때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '집에만 있으면 우울하니까 밖으로 나가서 친구랑 맛있는 것도 먹고 쇼핑도 한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '아무도 안 만나고 침대랑 물아 일체가 돼서 배달 앱을 켠다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '무증상이지만 코로나에 걸려서 격리를 해야 할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '지금쯤 친구들은 뭐 하고 있으려나.. 빨리 나아서 자유롭고 싶다!',
          },
          {
            type: 'I',
            score: 5,
            content:
              '이거 시즌 2 나왔다던데 이거 보면서 불 족발이나 뜯어야겠다~',
          },
        ],
      },
      {
        which: 'EI',
        question: '새로운 프로젝트에 들어가게 됐다. 이때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `“이렇게 진행하는 게 반응이 좋을 거 같은데 어때?”라고 의견을 말해 본다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `“이렇게 진행하는 게 반응이 좋을 거 같은데 괜찮을까?”라고 생각에 우선 집중해 본다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '수업 중 밖에서 이상한 소리가 들렸을 때 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `집중력이 순간 떨어지는 듯한 느낌에 살짝 짜증이 난다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `교실에 갑자기 괴한이 들어오는 상상을 잠시나마 해본다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '요즘 핫한 드라마를 시청 중에 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '이 드라마 진짜 너무 재밌어! 근데 저기 나오는 여배우분은 몸매 관리를 어떻게 한 걸까..',
          },
          {
            type: 'N',
            score: 5,
            content: `와 공복 유산소 자주 할 것 같아. 근데 어떻게 공복으로 운동을 할 수가 있지.. 아니야.. 그래도 할 수 있지 않을까.. 하루에 조금씩 시간 늘려가면서 하면 안 될 게 없지!`,
          },
        ],
      },
      {
        which: 'SN',
        question: '시험공부 중에 떠오는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `시험범위 요 정도만 줄이면 진짜 꿀인데!`,
          },
          {
            type: 'N',
            score: 5,
            content: `이 기억 그대로 돌아가면 진짜 100점 맞을 자신 있는데!`,
          },
        ],
      },
      {
        which: 'TF',
        question: `열심히 준비한 제안서를 냈는데 상사가 별로라고 했을 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '(속으로) 두고 바라. 결과가 어떻게 나오지는 지 내가 반박 못하게 해줄 테다.',
          },
          {
            type: 'F',
            score: 5,
            content: '(속으로) 네가 이 갬성을 몰라서 그런 거야..',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '길 가다가 큰 곰인형이 헌 옷 수거함 옆 길바닥에 버려진 것을 발견했다. 이때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `저거 대형폐기물로 버리거나 재활용이 안돼서 종량제 봉투에 담아서 버려야 하는데..`,
          },
          {
            type: 'F',
            score: 5,
            content: '곰인형.. 귀여운데 조금은 불쌍해 보인다ㅠㅠ',
          },
        ],
      },
      {
        which: 'TF',
        question: `내가 좋아할 것 같은 맛이라고 음식 추천을 해줄 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '어떤 맛이길래 내가 좋아하는 맛일 거 같다고 추천을 해주는 건지 궁금해진다.',
          },
          {
            type: 'F',
            score: 5,
            content:
              '내 입맛을 기억해 주고 생각해서 추천까지 해줬다고 생각이 드니 기분이 좋아진다.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '친구들과 주말에 피크닉 약속이 있는데 비 예보를 알게 되었다. 이때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `비가 온다고?! 그러면 피크닉은 아쉽지만 다음에 하고 장소를 카페로 옮겨서 디저트 먹는 걸로 진행해야겠다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `비가 온다고?! 그래 놓고 또 안 올 수도 있으니까~ 당일 아침에 날씨 보고 그때 다시 얘기해 봐야겠다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '물건을 사러 나왔을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '이거 샀고, 저것도 다 샀고 3시까지 세탁물 찾으러 가야 하니 저쪽으로 이동해야겠다!',
          },
          {
            type: 'P',
            score: 5,
            content:
              '이거 샀고, 저것도 다 샀고 어라?! 이런 곳도 있었다니.. 한번 가볼까나?!',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '칼국수 먹으러 나왔는데 어디선가 고기 굽는 냄새에 이끌려 친구가 갑자기 “칼국수 말고 고기가 먹고 싶어”라고 말할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '근처에 고깃집이 어디가 괜찮을지 검색해 본다. 리뷰, 화장실 위치, 용량 대비 가격 등 비교해서 괜찮은 곳으로 간다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '냄새가 나는 곳으로 고개를 돌리니 고깃집이 바로 보여서 바로 들어간다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/remindProfile/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/remindProfile/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/remindProfile/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/remindProfile/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/remindProfile/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/remindProfile/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/remindProfile/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/remindProfile/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/remindProfile/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/remindProfile/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/remindProfile/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/remindProfile/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/remindProfile/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/remindProfile/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/remindProfile/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/remindProfile/INFP.jpg',
      },
    ],
  },
  // iPhoneMemoJP
  {
    info: {
      mainTitle: 'iPhone notepad personality test',
      subTitle: "Let's try to write down your personality in iPhone notepad",
      mainUrl: 'iPhoneMemoJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/iPhoneMemoJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/iPhoneMemoJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'あなたにとって休日とは？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '遊びに行く日',
          },
          {
            type: 'I',
            score: 5,
            content: '家でくつろぐ日',
          },
        ],
      },
      {
        which: 'EI',
        question: '会話をするとき、あなたはどんなことを考えていますか？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '会話を中断して自分のことを話したい',
          },
          {
            type: 'I',
            score: 5,
            content: '会話に加わってもいいかなと思っているんだ ',
          },
        ],
      },
      {
        which: 'EI',
        question: '疲労困憊しているとき、どうやってエネルギーを充電するのか？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `友人と会っておしゃべりすると、フル充電された気分になる`,
          },
          {
            type: 'I',
            score: 5,
            content: `何もしないでベッドに横になって休んでいると、だんだん充電されてくる`,
          },
        ],
      },
      {
        which: 'SN',
        question: '今日からダイエットを始めると決めたらどう思う？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `難しいかもしれないけど、頑張らないと`,
          },
          {
            type: 'N',
            score: 5,
            content: `痩せてかっこよくなった自分を想像する`,
          },
        ],
      },
      {
        which: 'SN',
        question: '何かをどう表現する？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '事実に基づいた説明を重視する',
          },
          {
            type: 'N',
            score: 5,
            content: `物事を理解しやすいように、例えを使って説明する`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'もし、旅行中に渋滞で立ち往生したらどう思うだろうか？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `そうですか...いつ着けますか？ 事故でもあったんですか？`,
          },
          {
            type: 'N',
            score: 5,
            content: `どうしてこんなに渋滞しているんだろう？みんなどこに行くんだろう`,
          },
        ],
      },
      {
        which: 'TF',
        question: `もし、あなたの友人が全く理解できないことを言ったら？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '私はただ聞いて、それから何か他のことを言う',
          },
          {
            type: 'F',
            score: 5,
            content: 'まず話を聞き、それに応える',
          },
        ],
      },
      {
        which: 'TF',
        question: '道を歩いていて転んだ人を見たらどう思う？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `ああ、これは痛いに違いない...（と、自分の道を行く）`,
          },
          {
            type: 'F',
            score: 5,
            content:
              'なんてことだ！これは痛いに違いない...彼は大丈夫だろうか？助けてあげようか？',
          },
        ],
      },
      {
        which: 'TF',
        question: `会話中に突然無言になる友人を見たとき、あなたはどう思うだろうか？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '友達は話したくないだけだと思う',
          },
          {
            type: 'F',
            score: 5,
            content:
              '私は友人を嫌な気分にさせるようなことをしただろうか？どうしてそんなに静かなんだ？',
          },
        ],
      },
      {
        which: 'JP',
        question: '計画なしに一日を過ごさなければならないとしたら？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `何かをしなければならないような気がして、不安が続く`,
          },
          {
            type: 'P',
            score: 5,
            content: `普段は何も予定がないので、心配はしていません`,
          },
        ],
      },
      {
        which: 'JP',
        question: '重要な決断をした後はどうする？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '自分の決断に後悔はしていない',
          },
          {
            type: 'P',
            score: 5,
            content: 'よく気が変わって後で後悔するんだ',
          },
        ],
      },
      {
        which: 'JP',
        question: '将来の計画はどのように立てるのですか？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '具体的な将来設計を書いている',
          },
          {
            type: 'P',
            score: 5,
            content: '大雑把に計画して、そのままにしておく',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoJP/INFP.jpg',
      },
    ],
  },
  // iPhoneMemoEng
  {
    info: {
      mainTitle: 'iPhone notepad personality test',
      subTitle: "Let's try to write down your personality in iPhone notepad",
      mainUrl: 'iPhoneMemoEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/iPhoneMemoEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/iPhoneMemoEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'What does a day off mean to you?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'A day to go out and play ',
          },
          {
            type: 'I',
            score: 5,
            content: 'A day to relax at home',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What are your thoughts when you have a conversation?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'I want to interrupt the conversation and talk about myself ',
          },
          {
            type: 'I',
            score: 5,
            content: 'I am wondering if I can join the conversation',
          },
        ],
      },
      {
        which: 'EI',
        question: 'How do you recharge your energy when you are exhausted?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I feel fully charged when I meet friends and chat`,
          },
          {
            type: 'I',
            score: 5,
            content: `I am getting gradually recharged when I lie down in bed and rest without doing anything`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What would you think if you decided to go on a diet starting today?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `It may be difficult, but I have to try hard `,
          },
          {
            type: 'N',
            score: 5,
            content: `I imagine myself looking cool and slimmer after losing all the weight`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'How do you describe something?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I focus on fact-based explanations ',
          },
          {
            type: 'N',
            score: 5,
            content: `I use analogies to explain things so they can be understood easily`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What would you think if you were stuck on the road due to traffic while you were traveling?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Oh well… when can I arrive?  Was there an accident? `,
          },
          {
            type: 'N',
            score: 5,
            content: `Why is there so much traffic? I wonder where everyone is going.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What if your friend says something you can't understand at all?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I just listen and then say something else ',
          },
          {
            type: 'F',
            score: 5,
            content: 'I listen and respond first ',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What do you think when you see someone who has fallen while walking down the street?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Oh my, this must hurt… ( And, I go my way)`,
          },
          {
            type: 'F',
            score: 5,
            content: 'Oh my! This must hurt... is he okay? Should I help him? ',
          },
        ],
      },
      {
        which: 'TF',
        question: `What do you think when you see a friend who suddenly becomes silent during a conversation?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I think my friend just doesn’t want to talk perhaps~ ',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Did I do something to make my friend feel bad? Why so quiet?',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What if you had to spend your day without a plan?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I feel like I need to do something and feel anxious continuously`,
          },
          {
            type: 'P',
            score: 5,
            content: `I usually don't have any plans, so I don't worry about it `,
          },
        ],
      },
      {
        which: 'JP',
        question: 'What do you do after making an important decision?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: "I don't regret my decision",
          },
          {
            type: 'P',
            score: 5,
            content: 'I often change my mind and then regret it later',
          },
        ],
      },
      {
        which: 'JP',
        question: 'How do you make a plan for your future?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I specifically write a plan for my desired future',
          },
          {
            type: 'P',
            score: 5,
            content: 'I plan it roughly and leave it',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/iPhoneMemoEng/INFP.jpg',
      },
    ],
  },
  // iPhoneMemo
  {
    info: {
      mainTitle: '아이폰 메모장 성격 테스트',
      subTitle: '아이폰 메모장에 내 성격을 적어보자',
      mainUrl: 'iPhoneMemo',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/iPhoneMemo-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/iPhoneMemo-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '나에게 쉬는 날의 의미는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '밖에 나가서 노는 날 ',
          },
          {
            type: 'I',
            score: 5,
            content: '집에서 휴식을 취하는 날 ',
          },
        ],
      },
      {
        which: 'EI',
        question: '평소 대화를 나눌 때 내 속마음은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '얼른 말 끊고 내 얘기 하고 싶음 ',
          },
          {
            type: 'I',
            score: 5,
            content: '말이 끝난 건지, 내 얘기 해도 되는 건지 고민함 ',
          },
        ],
      },
      {
        which: 'EI',
        question: '지치고 피곤한 상태.. 나의 에너지 충전법은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `친구들을 만나 떠들다 보면 만땅으로 충전됨`,
          },
          {
            type: 'I',
            score: 5,
            content: `아무것도 안 하고 침대에 누워 쉬다 보면 서서히 충전됨 `,
          },
        ],
      },
      {
        which: 'SN',
        question: '오늘부터 다이어트를 하기로 결심한 나! 난 어떤 생각을 할까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `힘들겠지만 열심히 해봐야지`,
          },
          {
            type: 'N',
            score: 5,
            content: `살이 쭉 빠져 날씬해진 위풍당당한 내 모습이 상상됨 `,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 무엇인가를 설명할 때 어떤 방식일까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '사실 위주로 팩트에 기반한 설명 ',
          },
          {
            type: 'N',
            score: 5,
            content: `비유를 사용해서 이해할 수 있도록 설명 `,
          },
        ],
      },
      {
        which: 'SN',
        question: '이동 중 도로에 차가 막혀 옴짝달싹을 안 한다! 내 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `하.. 언제 가지.. 앞에 사고 났나`,
          },
          {
            type: 'N',
            score: 5,
            content: `도대체 차는 왜 막히는 걸까..? 다들 어디를 가는 거야?? `,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 공감이 1도 안 가는 얘기를 하고 있다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '한 귀로 듣고 흘리다가 딴소리를 한다 ',
          },
          {
            type: 'F',
            score: 5,
            content: '그래도 일단 들어주고 반응해 준다',
          },
        ],
      },
      {
        which: 'TF',
        question: '길을 가다가 넘어진 사람을 보면 드는 생각은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `아이고 아프겠다.. (갈 길 간다) `,
          },
          {
            type: 'F',
            score: 5,
            content:
              '아이고! 아프겠다 어떡해ㅜ 괜찮으신가..? 부축해드려야 하나?ㅜ ',
          },
        ],
      },
      {
        which: 'TF',
        question: `대화 중 갑자기 말이 없어진 친구를 보며 드는 생각은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '그냥 말하기 싫은가 보지~',
          },
          {
            type: 'F',
            score: 5,
            content: '내가 뭔가 서운하게 했나 왜 말이 없지..? ',
          },
        ],
      },
      {
        which: 'JP',
        question: '오늘 하루 무계획으로 지내야 한다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `뭔가를 해야 할 것 같고 계속 불안함 `,
          },
          {
            type: 'P',
            score: 5,
            content: `원래 무계획이라 아무렇지 않음 `,
          },
        ],
      },
      {
        which: 'JP',
        question: '중요한 결정을 내리고 난 후 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '한번 결정한 것에 대한 후회는 하지 않음',
          },
          {
            type: 'P',
            score: 5,
            content: '항상 이랬다저랬다 하다가 나중에 후회함 ',
          },
        ],
      },
      {
        which: 'JP',
        question: '나의 미래에 대한 플랜을 짠다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '구체적으로 원하는 나의 미래에 대한 플랜 작성 ',
          },
          {
            type: 'P',
            score: 5,
            content: '대충 짜놓고 미래의 나에게 맡김 ',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/iPhoneMemo/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/iPhoneMemo/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/iPhoneMemo/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/iPhoneMemo/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/iPhoneMemo/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/iPhoneMemo/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/iPhoneMemo/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/iPhoneMemo/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/iPhoneMemo/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/iPhoneMemo/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/iPhoneMemo/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/iPhoneMemo/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/iPhoneMemo/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/iPhoneMemo/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/iPhoneMemo/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/iPhoneMemo/INFP.jpg',
      },
    ],
  },
  // egoProfileJP
  {
    info: {
      mainTitle: '私の自我プロフィールテスト',
      subTitle: '私の自我を覗いてみて〜',
      mainUrl: 'egoProfileJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/egoProfileJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/egoProfileJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          '道を歩いていると、あの~前を知り合いが通り過ぎるこの時の私の行動は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'お！！近づいてあいさつを交わす',
          },
          {
            type: 'I',
            score: 5,
            content: 'あまり出くわしたくないのに.. わざわざゆっくり行く',
          },
        ],
      },
      {
        which: 'EI',
        question: '新しい集まりに出た私,沈黙が維持されている時の私の行動は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '一旦沈黙を破るためにまず質問を投げかける',
          },
          {
            type: 'I',
            score: 5,
            content: '誰かが口を開くまでじっとしている',
          },
        ],
      },
      {
        which: 'EI',
        question: '約束なしで家にいる時、私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `退屈だから誰かに会おうと連絡でもしてみようか？`,
          },
          {
            type: 'I',
            score: 5,
            content: `やっぱり家が最高だよ~ヒーリングヒーリングデー~`,
          },
        ],
      },
      {
        which: 'SN',
        question: '私の妄想の種類は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `ロトに当たった私、素敵な職場を持った私、お金を稼ぐ私`,
          },
          {
            type: 'N',
            score: 5,
            content: `超能力を持った私、拳一つで学校長になった私、かっこよく戦うマフィアになった私`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '家に帰る途中、空に穴が開いたように雨が降り注ぐこの時の私の考えは？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'あ~服が濡れそうだね、いつ家に帰るの！',
          },
          {
            type: 'N',
            score: 5,
            content: `本当に地球終末が来るんじゃないの.. 地球の終末が来たら何からすればいいんだろう`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'うんち味カレーvsカレー味うんち選べという質問に、私の考えは？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `なんでそんな質問をするんだよ~`,
          },
          {
            type: 'N',
            score: 5,
            content: `あ、でもうんちはうんちだからうんち味のカレーがいいんじゃないか`,
          },
        ],
      },
      {
        which: 'TF',
        question: `週末まで働きながらマックブックを買ったという友達の言葉に対する私の反応は？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'え、すごい。いくらだった？？ 最新なの？！',
          },
          {
            type: 'F',
            score: 5,
            content: 'わぁ、お疲れ様。すごい！ 大変だっただろうね',
          },
        ],
      },
      {
        which: 'TF',
        question: '道行くのに子供が一人泣きしている この時の私の行動は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `どうしてここで一人で泣いているの？！と聞く`,
          },
          {
            type: 'F',
            score: 5,
            content: '心配でひとまずなだめる',
          },
        ],
      },
      {
        which: 'TF',
        question: `私の愛情表現の仕方は何か？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '質問型+好奇心の愛情表現',
          },
          {
            type: 'F',
            score: 5,
            content: 'リアクション+共感の愛情表現',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '今日すべきことをすべて終えていない状態で、この時の私の考えは？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `それでもやることは全部終わらせないと。 明日もまた終わらせられないよ`,
          },
          {
            type: 'P',
            score: 5,
            content: `十分にお疲れ様。 明日の私がやるから大丈夫`,
          },
        ],
      },
      {
        which: 'JP',
        question: '卒業を控えた私の頭の中はどう？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'あ、もう本当に社会人だね。これからどう生きていくか計画してみる',
          },
          {
            type: 'P',
            score: 5,
            content: 'やった~！！卒業だよ！！！ もう私も立派な社会人！',
          },
        ],
      },
      {
        which: 'JP',
        question: '旅行に行く1ヶ月前の私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'そろそろ車の便も調べて宿も調べて、前もって予約する',
          },
          {
            type: 'P',
            score: 5,
            content: 'もう一ヶ月しか残ってない！ 予約しようと思ってるだけ',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileJP/INFP.jpg',
      },
    ],
  },
  // egoProfileEng
  {
    info: {
      mainTitle: 'My self profile test',
      subTitle: 'Look into vourself~',
      mainUrl: 'egoProfileEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/egoProfileEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/egoProfileEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          'What do you do if you are walking down the street and someone you know passes by?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Oh! I approach the person and exchange greetings',
          },
          {
            type: 'I',
            score: 5,
            content: 'I walk slowly on purpose so as not to be bothered.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What if the place you just attended for the first time is very quiet?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I ask a question first to break the silence.',
          },
          {
            type: 'I',
            score: 5,
            content: 'I stay still until someone opens their mouth.',
          },
        ],
      },
      {
        which: 'EI',
        question: "What do you do when you're home without an appointment?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I'm bored. Should I call someone to meet up?`,
          },
          {
            type: 'I',
            score: 5,
            content: `Home is the best for me~ yes it is my day for healing~`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What type of delusion do you have?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Winning the lottery, having a great job, being rich`,
          },
          {
            type: 'N',
            score: 5,
            content: `Having superpowers, becoming the best in school with my fists, becoming a good fighter in mafia`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do you think if it rains heavily on your way home?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'when can I get home? I think my clothes will all get wet~~!',
          },
          {
            type: 'N',
            score: 5,
            content: `Is this the real apocalypse? What should I do first when the end of the world comes?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What are your thoughts on the question of choosing poop-flavored curry vs. curry-flavored poop? ',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Oh, what kind of dirty question are you asking?`,
          },
          {
            type: 'N',
            score: 5,
            content: `Ah... but poop is poop, so poop-flavored curry Isn't it better?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What would your reaction be if your friend told you that he just bought a MacBook with the money he saved from working all weekend?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Wow, how much was it?? Is it the latest?!',
          },
          {
            type: 'F',
            score: 5,
            content:
              "Wow, you worked hard, it's amazing! It must have been hard",
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What would you do if you saw a child crying alone on the street?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I would ask the child, “Why are you crying here alone?”`,
          },
          {
            type: 'F',
            score: 5,
            content: 'First, I would comfort the child.',
          },
        ],
      },
      {
        which: 'TF',
        question: `What is your way of expressing affection?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Questions + expression of affection of curiosity',
          },
          {
            type: 'F',
            score: 5,
            content: 'Reactions + expression of sympathy',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What do you think when you haven’t finished things to do today?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Yes, I have to finish everything I have to do today, otherwise I won't be able to finish it again tomorrow`,
          },
          {
            type: 'P',
            score: 5,
            content: `Okay I did well for today. I can do it tomorrow. I will do it.`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'What are you thinking about before graduation?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Well, I am a real member of society. I might plan how to live in the future.',
          },
          {
            type: 'P',
            score: 5,
            content:
              "Wow ~!! It's graduation!!! Now I can be a good member of society!",
          },
        ],
      },
      {
        which: 'JP',
        question: 'What do you do when you a trip in a month?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "I'm slowly looking into transportation, finding accommodations, and making reservations in advance",
          },
          {
            type: 'P',
            score: 5,
            content:
              'Oh, only one month left! I might keep thinking about the plans',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/egoProfileEng/INFP.jpg',
      },
    ],
  },
  // egoProfile
  {
    info: {
      mainTitle: '나의 자아 프로필 테스트',
      subTitle: '나의 자아를 들여다 봐~',
      mainUrl: 'egoProfile',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/egoProfile-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/egoProfile-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          '길을 가고 있는데 저~ 앞에 아는 사람이 지나간다 이때 나의 행동은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '오!! 다가가서 반갑게 인사 나눈다',
          },
          {
            type: 'I',
            score: 5,
            content: '별로 마주치고 싶지 않은데.. 일부러 천천히 간다',
          },
        ],
      },
      {
        which: 'EI',
        question: '새로운 모임에 나간 나 침묵이 유지되고 있을 때 나의 행동은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '일단 침묵을 깨려고 먼저 질문을 던진다',
          },
          {
            type: 'I',
            score: 5,
            content: '누군가 입을 열 때까지 가만히 있는다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '약속 없이 집에 있을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `심심한데 oo한테 만나자고 연락이라도 해볼까?`,
          },
          {
            type: 'I',
            score: 5,
            content: `역시 집이 최고야~ 힐링 힐링 데이~`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 하는 망상의 종류는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `로또에 당첨된 나, 멋진 직장을 가진 나, 돈을 잘 버는 나`,
          },
          {
            type: 'N',
            score: 5,
            content: `초능력을 가지게 된 나, 주먹 하나로 학교장이 된 나, 멋있게 싸우는 마피아가 된 나`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '집에 가는 길 하늘에 구멍 난 것처럼 비가 쏟아져 내린다 이때 나의 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '아~옷 다 젖겠네 집 언제가~~!',
          },
          {
            type: 'N',
            score: 5,
            content: `진짜 지구 종말 오는 거 아냐?.. 지구 종말 오면 뭐부터 해야 하지`,
          },
        ],
      },
      {
        which: 'SN',
        question: '똥 맛 카레 vs 카레맛 똥 고르라는 질문에 나의 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `아우 더럽게 무슨 그런 질문을 해~`,
          },
          {
            type: 'N',
            score: 5,
            content: `아.. 그래도 똥은 똥이니깐 똥 맛 카레가 낫지 않나...`,
          },
        ],
      },
      {
        which: 'TF',
        question: `주말까지 일하면서 맥북을 샀다는 친구의 말의 나의 반응은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '헐 대박 얼마였어?? 최신이야?!',
          },
          {
            type: 'F',
            score: 5,
            content: '헐 고생 많았네 대단하다! 힘들었겠네',
          },
        ],
      },
      {
        which: 'TF',
        question: '길가는데 아이가 혼자 펑펑 울고 있다 이때 나의 행동은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `왜 여기서 혼자 울고 있어?!라고 물어본다`,
          },
          {
            type: 'F',
            score: 5,
            content: '걱정돼서 일단 달래준다',
          },
        ],
      },
      {
        which: 'TF',
        question: `나의 애정표현 방식은 무엇인가?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '질문형+호기심의 애정표현',
          },
          {
            type: 'F',
            score: 5,
            content: '리액션+공감의 애정표현',
          },
        ],
      },
      {
        which: 'JP',
        question: '오늘 할 일을 다 못 끝낸 상태 이때 나의 생각은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `그래도 할 건 다 끝내야지 아니면 내일도 또 못 끝낼 거야`,
          },
          {
            type: 'P',
            score: 5,
            content: `충분히 수고했다. 내일의 내가 할 거니깐 괜찮아`,
          },
        ],
      },
      {
        which: 'JP',
        question: '졸업을 앞둔 나 나의 머릿속은 어떤가?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '아 이제 진짜 사회인이구나 앞으로 어떻게 살아갈지 계획해 본다',
          },
          {
            type: 'P',
            score: 5,
            content: '아싸~!! 졸업이다!!! 이제 나도 어엿한 사회인!',
          },
        ],
      },
      {
        which: 'JP',
        question: '제주도 여행을 떠나기 한 달 전 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '슬슬 차편도 알아보고 숙소도 알아보고 미리 예약한다',
          },
          {
            type: 'P',
            score: 5,
            content: '벌써 한 달밖에 안 남았네! 예약해야지 생각만 함',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/egoProfile/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/egoProfile/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/egoProfile/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/egoProfile/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/egoProfile/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/egoProfile/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/egoProfile/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/egoProfile/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/egoProfile/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/egoProfile/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/egoProfile/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/egoProfile/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/egoProfile/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/egoProfile/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/egoProfile/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/egoProfile/INFP.jpg',
      },
    ],
  },
  // bloodTransfusionJP
  {
    info: {
      mainTitle: '연애 수혈 테스트',
      subTitle: '피로 알아보는 나의 연애 성격은?',
      mainUrl: 'bloodTransfusionJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/bloodTransfusionJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/bloodTransfusionJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question:
          '友達と二人で遊んでいたら、友達の友達から一緒に遊ぼうという連絡があったら？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'そうだ、一緒に遊ぼう！ 今日から友達になろうよ！',
          },
          {
            type: 'I',
            score: 5,
            content: 'いきなり？ それはちょっと...二人で遊ぼうよ～',
          },
        ],
      },
      {
        which: 'EI',
        question: '友達の紹介で新しいサークルに入ることになったら？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '友達以外にも、他の人にまず声をかけて仲良くなる',
          },
          {
            type: 'I',
            score: 5,
            content: '友達にしか話しかけず、自分についての質問に答えるだけ',
          },
        ],
      },
      {
        which: 'EI',
        question: '友達との約束が終わった後、家に一人でいるときの私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `エネルギーはチャージされたが、一人なので少し虚しさを感じる`,
          },
          {
            type: 'I',
            score: 5,
            content: `ふぅ～、たっぷり遊んだ！もうゆっくり休まないとね`,
          },
        ],
      },
      {
        which: 'SN',
        question: '小説を読むときの私は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `小説の主人公とストーリーそのものに没頭する`,
          },
          {
            type: 'N',
            score: 5,
            content: `この小説本の結末はどうなるのか、この小説が何を言いたいのか考えてみる`,
          },
        ],
      },
      {
        which: 'SN',
        question: '何かを説明するとき、自分に近いものは？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '起こった出来事を正確かつ詳細に説明する',
          },
          {
            type: 'N',
            score: 5,
            content: `起こった出来事をもとに、比喩を適切に混ぜて説明する`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'もし友達が「もし君に超能力ができたら、どんな超能力を持ちたいか」と聞かれたら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `なにそれ、いきなり超能力？ おまえが選べよ`,
          },
          {
            type: 'N',
            score: 5,
            content: `うーん。一つしか持てないの？ 慎重に選ばないとな...`,
          },
        ],
      },
      {
        which: 'TF',
        question: `髪の毛を伸ばしていた友人が、突然髪を短く切ったとしたら？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'いきなり？ どうやって切ったの？ 写真見せてよ',
          },
          {
            type: 'F',
            score: 5,
            content: '急に？ もしかして何かあったの？',
          },
        ],
      },
      {
        which: 'TF',
        question: '誰かが私に悪いことをした場合、私が敏感に感じる部分は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `なぜそんな過ちを犯したのか明確な理由`,
          },
          {
            type: 'F',
            score: 5,
            content: '謝罪を先にするのか、しないのか',
          },
        ],
      },
      {
        which: 'TF',
        question: `友達に病気だと言ったのに「薬を飲んで」と答えが来たら？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'そうだね。とりあえず薬を飲んでみないと。 食べないよりはましだよ',
          },
          {
            type: 'F',
            score: 5,
            content:
              'それでも痛いというのに、そのまま薬を飲めとは.. 少し悔しい',
          },
        ],
      },
      {
        which: 'JP',
        question: '私が望む仕事のやり方は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `反復的で枠組みが定められている業務`,
          },
          {
            type: 'P',
            score: 5,
            content: `型のきまっていない新しい業務`,
          },
        ],
      },
      {
        which: 'JP',
        question: '二つのうち、私がもっと重要だと思うのは？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '過程がどうであれ, 結果が大事だ',
          },
          {
            type: 'P',
            score: 5,
            content: '結果も重要だが, 過程の方が重要だ',
          },
        ],
      },
      {
        which: 'JP',
        question: 'やるべきことがあるとき、私と近いことは？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'やるべきことをしないと、心が不安で前もって終わらせておく',
          },
          {
            type: 'P',
            score: 5,
            content:
              '本当にもうやらないといけないような 瞬間までも後回しにする',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionJP/INFP.jpg',
      },
    ],
  },
  // colorPaletteJP
  {
    info: {
      mainTitle: 'カラーパレット性格テスト',
      subTitle: 'カラーパレットで見る私の性格は？',
      mainUrl: 'colorPaletteJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/colorPaletteJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/colorPaletteJP-thumb.png',
      horizontalBanner: '',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '新しいプロジェクトを始める時、私が好むことは？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'チームを組んで一緒に働くこと',
          },
          {
            type: 'I',
            score: 5,
            content: '一人で働くこと',
          },
        ],
      },
      {
        which: 'EI',
        question: '多くの人が集まっている場所で私に注目が集まるとしたら？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '注目されるのを楽しむ',
          },
          {
            type: 'I',
            score: 5,
            content: '注目されるのが負担で、急いで事態をもみ消す',
          },
        ],
      },
      {
        which: 'EI',
        question: '人々の間で私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `主として物を言う人`,
          },
          {
            type: 'I',
            score: 5,
            content: `主として言うことを聞く人`,
          },
        ],
      },
      {
        which: 'SN',
        question: '横断歩道を渡るとき、私は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `信号が変われば何の考えもなくただ渡る`,
          },
          {
            type: 'N',
            score: 5,
            content: `あたりを見渡し、起こらない恐ろしい想像をして渡る`,
          },
        ],
      },
      {
        which: 'SN',
        question: '眠る前に私と近いものは？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '横になるとほとんどすぐ寝る方',
          },
          {
            type: 'N',
            score: 5,
            content: `あれこれ考えて寝る時間を逃すことが多い`,
          },
        ],
      },
      {
        which: 'SN',
        question: '友達がとんでもない質問をしたら、私は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `そんなことは話にならないと言って、君なりに考えろと言う`,
          },
          {
            type: 'N',
            score: 5,
            content: `現実の可能性はないが、それでも真剣に考えて答えてあげる`,
          },
        ],
      },
      {
        which: 'TF',
        question: `恋人が「私たち合わないと思う」と言ったら？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'なんで？どの部分が合わないの？合わせて行こう',
          },
          {
            type: 'F',
            score: 5,
            content: '私が何か悪いことをしたのかな？悔しい···',
          },
        ],
      },
      {
        which: 'TF',
        question: '友達とカフェから出た後、友達が持ち物を置いてきた場合は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `早く行ってきて~ゆっくり行くから！`,
          },
          {
            type: 'F',
            score: 5,
            content: '一緒に持ってこよう！',
          },
        ],
      },
      {
        which: 'TF',
        question: `隣にいる友達が悲しんで泣いている姿を見たら？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '黙って背中を叩きながら、大丈夫かと聞く',
          },
          {
            type: 'F',
            score: 5,
            content: '一瞬感情移入して一緒に目頭が赤くなる',
          },
        ],
      },
      {
        which: 'JP',
        question: '普段より無理して体が疲れすぎる状態だったら？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `疲れても私が決めたルーティンを全部やって休む`,
          },
          {
            type: 'P',
            score: 5,
            content: `一日の計画があるけど、今日は疲れすぎて休む`,
          },
        ],
      },
      {
        which: 'JP',
        question: '私の考えに近いのは？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '計画を細かく完璧に立てておいたが、計画が狂うのではないかと不安だ',
          },
          {
            type: 'P',
            score: 5,
            content:
              '大きな枠だけ決めておいたが、なんだかうまくいきそうな気がする',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '一緒に旅行に行くと約束した友達が私にエクセルで整理した旅行計画表をくれたら私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '私もエクセルで細かく計画して友達との調整が必要だと思う',
          },
          {
            type: 'P',
            score: 5,
            content: '少し息が詰まるけど、それでも心強い',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteJP/INFP.jpg',
      },
    ],
  },
  // colorPaletteEng
  {
    info: {
      mainTitle: 'Color palette personality test',
      subTitle: 'What does my color palette say about me?',
      mainUrl: 'colorPaletteEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/colorPaletteEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/colorPaletteEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'What do you prefer when starting a new project?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I prefer to work together as a team',
          },
          {
            type: 'I',
            score: 5,
            content: 'I prefer to work alone',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What if attention is drawn to you in a place where many people gather?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I enjoy being in the spotlight',
          },
          {
            type: 'I',
            score: 5,
            content:
              "I don't feel comfortable with the attention, so I end this situation hastily",
          },
        ],
      },
      {
        which: 'EI',
        question: 'How do you do when you are with people?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I am mostly a talker`,
          },
          {
            type: 'I',
            score: 5,
            content: `I am mostly a listener`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'When crossing a crosswalk, how do you do?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `When the signal changes, I just cross without thinking much`,
          },
          {
            type: 'N',
            score: 5,
            content: `I look around and cross myself with a terrible imagination that won't happen`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What is close to you before going to sleep?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I tend to fall asleep almost immediately when I lie down',
          },
          {
            type: 'N',
            score: 5,
            content: `I often miss the time to sleep while thinking about this and that`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'If your friend asks you a ridiculous question, what would you do?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I'd say that's nonsense. Take care of yourself first.`,
          },
          {
            type: 'N',
            score: 5,
            content: `Even if it's not realistic, I would still answer seriously`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What if your lover says “We can't get along well”?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "Why? Which part doesn't fit? We can try",
          },
          {
            type: 'F',
            score: 5,
            content: "What did I do wrong? I'm just upset...",
          },
        ],
      },
      {
        which: 'TF',
        question: 'What if your friend left something at the cafe?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `go and get it~ I will walk slowly!`,
          },
          {
            type: 'F',
            score: 5,
            content: "Let's go get it together",
          },
        ],
      },
      {
        which: 'TF',
        question: `What if you see a friend next to you crying in pain?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "I quietly encourage him and ask if he's okay",
          },
          {
            type: 'F',
            score: 5,
            content: 'I sympathize for a moment and my eyes become red',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What if you are too tired after unusual work hard?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Even if i'm tired, I want to complete my daily routine and take a break`,
          },
          {
            type: 'P',
            score: 5,
            content: `I have plans for the day, but today I'm so tired that I just take a break`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'Which one is closest to your thoughts?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "I made a detailed and perfect plan, but I'm afraid and anxious that it might go wrong",
          },
          {
            type: 'P',
            score: 5,
            content:
              'I only set the big frame, but for some reason, I think it will work out well',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What if your friend you plan a trip with gives you very detailed details in an Excel file?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I also made a detailed plan so that we might need to adjust together a bit',
          },
          {
            type: 'P',
            score: 5,
            content:
              "It's a little out of breath, but it's good to have a plan",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/colorPaletteEng/INFP.jpg',
      },
    ],
  },
  // colorPalette
  {
    info: {
      mainTitle: '컬러 팔레트 성격 테스트',
      subTitle: '컬러 팔레트로 보는 나의 성격은?',
      mainUrl: 'colorPalette',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/colorPalette-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/colorPalette-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '새로운 프로젝트를 시작할 때 내가 선호하는 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '팀을 이루어 함께 일하는 것',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자 일하는 것',
          },
        ],
      },
      {
        which: 'EI',
        question: '많은 사람들이 모여 있는 장소에서 나에게 이목이 쏠린다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '주목받는 것을 즐긴다',
          },
          {
            type: 'I',
            score: 5,
            content: '주목받는 것이 부담스럽고, 급하게 상황을 마무리 짓는다',
          },
        ],
      },
      {
        which: 'EI',
        question: '사람들 사이에서 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `주로 말을 하는 사람이다`,
          },
          {
            type: 'I',
            score: 5,
            content: `주로 말을 듣는 사람이다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '횡단보도를 건널 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `신호가 바뀌면 별생각 없이 그냥 건넌다`,
          },
          {
            type: 'N',
            score: 5,
            content: `주위를 살피며, 일어나지 않을 끔찍한 상상을 하며 건넌다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '잠들기 전 나와 가까운 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '누우면 거의 바로 잠드는 편이다.',
          },
          {
            type: 'N',
            score: 5,
            content: `이런저런 생각 하다가 자야 할 시간을 놓칠 때가 많다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구가 말도 안 되는 황당한 질문을 한다면 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `그런 건 말도 안 된다며, 너나 생각하라고 한다`,
          },
          {
            type: 'N',
            score: 5,
            content: `현실 가능성은 없지만, 그래도 진지하게 생각해서 답해준다`,
          },
        ],
      },
      {
        which: 'TF',
        question: `연인이 “우리 잘 안 맞는 것 같아”라고 한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜? 어떤 부분이 안 맞는데? 맞춰 가자',
          },
          {
            type: 'F',
            score: 5,
            content: '내가 뭐 잘못했나? 속상하다…',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '친구와 카페에서 나왔는데, 친구가 소지품을 놓고 온 상황이라면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `빨리 갔다 와~ 천천히 가고 있을게!`,
          },
          {
            type: 'F',
            score: 5,
            content: '같이 가지러 갔다 오자',
          },
        ],
      },
      {
        which: 'TF',
        question: `옆에 있는 친구가 속상해하며 울고 있는 모습을 본다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '말없이 등을 토닥여주며, 괜찮냐고 물어본다',
          },
          {
            type: 'F',
            score: 5,
            content: '순간 감정이입으로 나도 덩달아 눈시울이 붉어진다',
          },
        ],
      },
      {
        which: 'JP',
        question: '평소보다 무리해 몸이 너무 피곤한 상태라면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `피곤해도 내가 정한 루틴을 다 하고 쉰다`,
          },
          {
            type: 'P',
            score: 5,
            content: `하루 계획이 있지만, 오늘은 너무 피곤해서 그냥 쉰다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '내 생각과 가까운 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '계획을 세밀하고 완벽하게 세워 놨지만, 계획이 틀어질까 봐 불안하다',
          },
          {
            type: 'P',
            score: 5,
            content: '큰 틀만 정해 놨지만, 왠지 잘 될 것 같다는 생각이 든다',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '함께 여행을 가기로 약속한 친구가 나에게 엑셀로 정리된 여행 계획표를 준다면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '나도 엑셀로 세세하게 계획해서 친구와 조율이 필요할 것 같다',
          },
          {
            type: 'P',
            score: 5,
            content: '조금 숨이 막히지만, 그래도 든든하다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorPalette/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorPalette/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/colorPalette/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/colorPalette/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorPalette/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/colorPalette/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/colorPalette/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/colorPalette/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorPalette/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorPalette/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/colorPalette/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/colorPalette/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorPalette/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/colorPalette/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/colorPalette/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/colorPalette/INFP.jpg',
      },
    ],
  },
  // tanghuluEng
  {
    info: {
      mainTitle: 'Tanghulu Ideal Type Test',
      subTitle: `Let's find out my ideal type with sweet tanghulu!`,
      mainUrl: 'tanghuluEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/tanghuluEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/tanghuluEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: 'What is my image?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Bright images of playing outside',
          },
          {
            type: 'I',
            score: 5,
            content: 'Silent image of liking quiet places',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Ideal type I met at the meeting! What is my approach?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I naturally approach and laugh and talk.',
          },
          {
            type: 'I',
            score: 5,
            content: 'I glimpses from behind and stares secretly.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What is my honest answer to a question asking about my hobbies?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I meet my friends and go to cafes and famous restaurant`,
          },
          {
            type: 'I',
            score: 5,
            content: `It's my hobby to just lie in bed and rest`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What do you do when your favorite opposite sex friend asks you to eat?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Maybe interested in me? I think we're already dating`,
          },
          {
            type: 'N',
            score: 5,
            content: `I'm asking what's going on.`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'If I met my ideal type, where would it be?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'If I met my ideal type, where would it be?',
          },
          {
            type: 'N',
            score: 5,
            content: `A blind date, a job, a drinking party, maybe?`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'How was your blind date last time?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `We communicate well and have a good personality!`,
          },
          {
            type: 'N',
            score: 5,
            content: `We communicate well and have a good personality!`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What about me to a friend who wants to be in a relationship?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'You can! Do you want me to introduce you?',
          },
          {
            type: 'F',
            score: 5,
            content:
              "Why all of a sudden? Are you lonely these days? What's going on?",
          },
        ],
      },
      {
        which: 'TF',
        question:
          'To a lover who is having a hard time these days due to a lot of work?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Oh really? Then you should have rested at home!`,
          },
          {
            type: 'F',
            score: 5,
            content:
              "It's really...? (Touched) You must be tired. Are you okay?",
          },
        ],
      },
      {
        which: 'TF',
        question: `How to express your interest in a crush?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Why? What are you doing now? Do you like it? (Expressing it with questions)',
          },
          {
            type: 'F',
            score: 5,
            content:
              "Wow, really? It's so much fun! Good job! (Expressing with response)",
          },
        ],
      },
      {
        which: 'JP',
        question:
          "Why don't we talk about something off-the-topic during the conversation?",
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Unconvenient to talk about things that don't fit the topic`,
          },
          {
            type: 'P',
            score: 5,
            content: `It doesn't matter if it's an interesting topic`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'What do you want to eat on a date?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Open the list of restaurants, etc.',
          },
          {
            type: 'P',
            score: 5,
            content: 'Walk into a restaurant that looks delicious',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'I chose this for someone elses birthday! What about me after that?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: "I don't change it easily once I decide",
          },
          {
            type: 'P',
            score: 5,
            content:
              'If a better gift comes to mind, it can change as much as you want',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/tanghuluEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/tanghuluEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/tanghuluEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/tanghuluEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/tanghuluEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/tanghuluEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/tanghuluEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/tanghuluEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/tanghuluEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/tanghuluEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/tanghuluEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/tanghuluEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/tanghuluEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/tanghuluEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/tanghuluEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/tanghuluEng/INFP.jpg',
      },
    ],
  },
  // tanghulu
  {
    info: {
      mainTitle: '탕후루 이상형 테스트',
      subTitle: '달달한 탕후루로 나의 이상형을 알아보자!',
      mainUrl: 'tanghulu',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/tanghulu-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/tanghulu-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '내가 원하는 이상형의 이미지는 무엇일까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '밖에서 노는 걸 좋아하는 밝은 이미지 ',
          },
          {
            type: 'I',
            score: 5,
            content: '조용한 장소를 좋아하는 묵묵한 이미지',
          },
        ],
      },
      {
        which: 'EI',
        question: '모임에서 만나게 된 나의 이상형! 나의 접근 방식은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '자연스럽게 다가가 웃으며 말을 건다',
          },
          {
            type: 'I',
            score: 5,
            content: '힐끔거리며 뒤에서 말 걸 타이밍을 본다',
          },
        ],
      },
      {
        which: 'EI',
        question: '취미를 묻는 이상형에게 내 솔직한 대답은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `친구들 만나서 카페 가거나 맛집 탐방? `,
          },
          {
            type: 'I',
            score: 5,
            content: `음.. 그냥 침대에 누워서 유하하기..?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '얼굴만 알던 이성 친구에게 밥 먹자고 연락이 온다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `무슨 일인지 바로 물어본다  `,
          },
          {
            type: 'N',
            score: 5,
            content: `혹시 나한테 보내는 시그널..? 고민하다 답장한다 `,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 이상형을 만난다면 장소는 어디일까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '소개팅이나 직장.. 술자리 이런데 아닐까?',
          },
          {
            type: 'N',
            score: 5,
            content: `우연이 겹치고 겹쳐져 만드는 운명적인 장소!!ㅎㅎ`,
          },
        ],
      },
      {
        which: 'SN',
        question: '지난번 소개팅 어땠어? 라고 묻는다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `대화도 잘 통하고 성격도 좋더라!`,
          },
          {
            type: 'N',
            score: 5,
            content: `분위기 좋았어! 나 완전 설렜잖아~ `,
          },
        ],
      },
      {
        which: 'TF',
        question: `갑자기 연애가 하고 싶다는 친구에게 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '하면 되지! 소개 시켜줘? ',
          },
          {
            type: 'F',
            score: 5,
            content: '갑자기 왜~? 요즘 외로워? 뭔 일인데~ ',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '일이 많아 피곤했지만 보고 싶어서 나왔다는 상대방에게 내 대답은 어떨까?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `아 진짜? 그럼 집에서 쉬지 그랬어! `,
          },
          {
            type: 'F',
            score: 5,
            content: '정말…? (감동) 피곤할 텐데 괜찮아? ',
          },
        ],
      },
      {
        which: 'TF',
        question: `짝사랑하는 이성에게 관심을 표현하는 방법은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜? 지금 뭐 하는데? 원래 그거 좋아해? (질문으로 표현)',
          },
          {
            type: 'F',
            score: 5,
            content: '와 정말?? 진짜 재밌다!! 고생 많았어ㅜ (리액션으로 표현)',
          },
        ],
      },
      {
        which: 'JP',
        question: '대화 중에 얘기가 딴 길로 새고 있는 중이다. 나는 어떨까?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `주제와 맞지 않는 이야기를 하면 불편함  `,
          },
          {
            type: 'P',
            score: 5,
            content: `재미있는 주제라면 상관없음 `,
          },
        ],
      },
      {
        which: 'JP',
        question: '데이트 중 먹고 싶은 거 있어? 라는 물음에 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '맛집 정보 등 미리 알아둔 리스트를 펼침 ',
          },
          {
            type: 'P',
            score: 5,
            content: '걷다가 맛있어 보이는 식당으로 들어감 ',
          },
        ],
      },
      {
        which: 'JP',
        question: '상대에게 줄 생일 선물을 결정했다! 그 후에 나는? ',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '한번 내린 결정은 쉽게 바꾸지 않음 ',
          },
          {
            type: 'P',
            score: 5,
            content: '더 좋은 선물이 떠오르면 얼마든지 바뀔 수 있음 ',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/tanghulu/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/tanghulu/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/tanghulu/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/tanghulu/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/tanghulu/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/tanghulu/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/tanghulu/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/tanghulu/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/tanghulu/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/tanghulu/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/tanghulu/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/tanghulu/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/tanghulu/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/tanghulu/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/tanghulu/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/tanghulu/INFP.jpg',
      },
    ],
  },
  // bloodTransfusionEng
  {
    info: {
      mainTitle: 'A Love Blood Transfusion test',
      subTitle: 'What kind of love personality is my blood?',
      mainUrl: 'bloodTransfusionEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/bloodTransfusionEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/bloodTransfusionEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question:
          "I was playing with my friend, but a friend I didn't know contacted me to play with!",
        answers: [
          {
            type: 'E',
            score: 2,
            content: "Yes, let's play together! We can be friends from today!",
          },
          {
            type: 'I',
            score: 5,
            content: "All of a sudden? That's a bit...",
          },
        ],
      },
      {
        which: 'EI',
        question: 'What if your friend introduced you to a new group?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I get close by talking to other people, too',
          },
          {
            type: 'I',
            score: 5,
            content: 'I only talk to my friends',
          },
        ],
      },
      {
        which: 'EI',
        question:
          "What do you do when you're alone at home after meeting your friends?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I feel a little empty by myself`,
          },
          {
            type: 'I',
            score: 5,
            content: `I'm happy. I need to rest well now!`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'When you read a novel?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Immerse oneself in the protagonist of the novel and the story itself`,
          },
          {
            type: 'N',
            score: 5,
            content: `I think about what the end of this novel will be like and what the novel is trying to say`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What if a friend asked, "If you had a superpower what would it be?"',
        answers: [
          {
            type: 'S',
            score: 2,
            content: "What kind of superpower? That's not going to happen",
          },
          {
            type: 'N',
            score: 5,
            content: `What kind of super power should I have? I'll choose carefully`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'When I explain something to you?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `It explains exactly what happened in detail`,
          },
          {
            type: 'N',
            score: 5,
            content: `Based on the events that occurred, the metaphor is properly mixed and explained`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What if the friend who grew out his hair suddenly cut it short?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'All of a sudden? How did you cut it? Show me the picture',
          },
          {
            type: 'F',
            score: 5,
            content: 'All of a sudden? Is something wrong?',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What apology do you want if someone did something wrong to me?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `A clear reason for why such a mistake was committed`,
          },
          {
            type: 'F',
            score: 5,
            content: "Genuine apology from the bottom of one's heart",
          },
        ],
      },
      {
        which: 'TF',
        question: `What if you told a friend that you were sick and the friend replied, "Take medicine"?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              "Yes. I should try the medicine first. It's better than not taking it",
          },
          {
            type: 'F',
            score: 5,
            content:
              "You said you were sick, but you told me to take medicine... I'm a little upset",
          },
        ],
      },
      {
        which: 'JP',
        question: 'How do I want to work?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Repetitive, prescribed tasks`,
          },
          {
            type: 'P',
            score: 5,
            content: `A new task with no fixed framework`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'Which of the two do I think is more important?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Whatever the process, the outcome is important',
          },
          {
            type: 'P',
            score: 5,
            content:
              'The outcome is important, but the process is more important',
          },
        ],
      },
      {
        which: 'JP',
        question: 'When you have something to do, what is close to me?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "If I don't do what I have to do, I'm nervous, so I finish everything in advance",
          },
          {
            type: 'P',
            score: 5,
            content:
              "I'll postpone it until the moment I feel like I have to do it",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusionEng/INFP.jpg',
      },
    ],
  },
  // bloodTransfusion
  {
    info: {
      mainTitle: '연애 수혈 테스트',
      subTitle: '피로 알아보는 나의 연애 성격은?',
      mainUrl: 'bloodTransfusion',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/bloodTransfusion-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/bloodTransfusion-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question:
          '친구와 단둘이 노는데, 친구의 친구가 같이 놀자고 연락이 왔다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '그래 같이 놀자! 오늘부터 친구 하면 되지!',
          },
          {
            type: 'I',
            score: 5,
            content: '갑자기? 그건 좀.. 그냥 둘이 놀자~',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구의 소개로 새로운 모임에 들어가게 되었다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구 말고도, 다른 사람에게 먼저 말을 걸며 친해진다',
          },
          {
            type: 'I',
            score: 5,
            content: '친구에게만 말을 걸고, 나에 대한 질문에 대답만 한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구들과 약속이 끝난 후, 집에 혼자 있을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `에너지가 충전됐지만, 혼자라 약간의 공허함이 느껴진다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `휴~ 알차게 놀았다! 이제 푹 쉬어야겠다!`,
          },
        ],
      },
      {
        which: 'SN',
        question: '소설책을 읽을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `소설의 주인공과 스토리 자체에 몰입한다`,
          },
          {
            type: 'N',
            score: 5,
            content: `이 소설책의 결말은 어떨지, 이 소설이 말하고자 하는 바는 무엇인지 생각해 본다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '무언가를 설명할 때, 나와 가까운 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '일어났던 사건을 정확하고 자세하게 설명한다.',
          },
          {
            type: 'N',
            score: 5,
            content: `일어났던 사건을 바탕으로 비유를 적절히 섞어 설명한다.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '친구가 “만약에 너에게 초능력이 생긴다면 어떤 초능력을 가지고 싶어?”라고 물어본다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `무슨 소리야 그게? 무슨 갑자기 초능력? 너나 골라`,
          },
          {
            type: 'N',
            score: 5,
            content: `음.. 하나만 가질 수 있는 거야? 신중히 골라야겠군..`,
          },
        ],
      },
      {
        which: 'TF',
        question: `머리를 기르던 친구가 갑자기 짧게 머리 잘랐다고 한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '갑자기? 어떻게 잘랐는데? 사진 좀 보여줘',
          },
          {
            type: 'F',
            score: 5,
            content: '갑자기? 혹시 무슨 일 있는 거 아니야?',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '누군가 나에게 잘 못을 저질렀다면, 내가 민감하게 생각하는 부분은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `왜 그런 잘 못을 저질렀는지에 대한 명확한 이유`,
          },
          {
            type: 'F',
            score: 5,
            content: '사과를 먼저 하는지, 안 하는지',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구에게 아프다고 했는데 “약을 먹어”라고 답이 왔다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '그래. 일단 약을 먹어 봐야겠다. 안 먹는 것보다 낫지.',
          },
          {
            type: 'F',
            score: 5,
            content: '그래도 아프다는데, 그냥 약을 먹으라니.. 조금 속상하다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 원하는 업무 방식은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `반복적이고, 틀이 정해져 있는 업무`,
          },
          {
            type: 'P',
            score: 5,
            content: `틀이 정해져 있지 않은 새로운 업무`,
          },
        ],
      },
      {
        which: 'JP',
        question: '둘 중 내가 더 중요하게 생각하는 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '과정이 어찌 됐든, 결과가 중요하다',
          },
          {
            type: 'P',
            score: 5,
            content: '결과도 중요하지만, 과정이 더 중요하다',
          },
        ],
      },
      {
        which: 'JP',
        question: '해야 할 일이 있을 때, 나와 가까운 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '해야 할 일을 하지 않으면, 마음이 불안해서 미리 다 끝내 놓는다',
          },
          {
            type: 'P',
            score: 5,
            content: '진짜 이제 안 하면 안 될 것 같은 순간까지도 미룬다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusion/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusion/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusion/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusion/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusion/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusion/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusion/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusion/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusion/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusion/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusion/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusion/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusion/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusion/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusion/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/bloodTransfusion/INFP.jpg',
      },
    ],
  },
  // unlockMyHeartJP
  {
    info: {
      mainTitle: '私の心のロック解除テスト',
      subTitle: '私の心をロック解除して！',
      mainUrl: 'unlockMyHeartJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/unlockMyHeartJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/unlockMyHeartJP-thumb.png',
      horizontalBanner: '',
      lang: 'JP',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '私の好きなデートは何だろう？',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '展示会、カフェ、美味しい店などを歩き回る充実した屋外デート',
          },
          {
            type: 'I',
            score: 5,
            content: '家でゴロゴロしながら一緒に休む室内デート',
          },
        ],
      },
      {
        which: 'EI',
        question: '恋人が親しい友達を紹介してくれると言う時、私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'お、いいね！いつやってくれるの？ 楽しみだ。どんな人たちなのか！',
          },
          {
            type: 'I',
            score: 5,
            content: 'まだ恥ずかしいから今度ぜひ紹介してね~！',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '恋人と海外旅行に来た！ 変数が生じて日程の中で一日空いたら、私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `せっかく海外まで来たのに、一日でも無駄に過ごすわけにはいかない!!! とりあえず出かけよう!!!`,
          },
          {
            type: 'I',
            score: 5,
            content: `ただでさえ疲れたのに、一日くらい休むのは...。むしろいい!`,
          },
        ],
      },
      {
        which: 'SN',
        question: '恋人が「ああ、早く結婚したい」と言った時の私の気持ちは？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `結婚は現実だったのに...結婚するにはお金はいくら必要？`,
          },
          {
            type: 'N',
            score: 5,
            content: `結婚したら毎日一緒なんて...! 想像するだけで幸せです`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '恋人が「もし私が先に死んだら、あなたはどう思う？」と言われたときの私の答えは？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'えっ...そんな馬鹿なことを...？ まず死なない！？',
          },
          {
            type: 'N',
            score: 5,
            content: `なぜ死ぬの...？絶対ダメだ。俺も一緒に死ぬ`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '恋人と一緒に夜空の無数の星を見ながら寝ている! 私はどんなことを考えているのだろう？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `星が本当に多いね～いいね`,
          },
          {
            type: 'N',
            score: 5,
            content: `星は全部で何個あるんだろう、あそこは空なのか宇宙なのか美しい、実際に宇宙には宇宙人もいるんだろう？`,
          },
        ],
      },
      {
        which: 'TF',
        question: `寝る前にいつも「愛してる」と言っていた恋人が最近言ってくれない。この時、私の考えは？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '何も考えてない。実はやっていないかもよくわからない',
          },
          {
            type: 'F',
            score: 5,
            content:
              '最近、なぜ愛してるって言ってくれないんだろう。愛が冷めたのか...？ もう私が嫌いなのか...',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '私に恋人が「ちょっとぽっちゃりしたみたい」と言った時の私の反応は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `ああ、最近たくさん食べたら...! 太ったかも...!`,
          },
          {
            type: 'F',
            score: 5,
            content: '私が太ったってことかな？ ずっと気にしている',
          },
        ],
      },
      {
        which: 'TF',
        question: `私と一緒に撮った写真を容量不足で消したという恋人！私の反応は？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'よくやった！！ 私も一度消去しなければならない写真が容量をかなり多く占有するんだ',
          },
          {
            type: 'F',
            score: 5,
            content:
              'それでも私との思い出なのに...無言で消すなんてㅜ内心とても残念',
          },
        ],
      },
      {
        which: 'JP',
        question: '私の心が心地よく開く計画表は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `綿密に練り上げられた計画表`,
          },
          {
            type: 'P',
            score: 5,
            content: `簡潔にいくつかだけ書かれた計画表`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          '恋人と一緒に旅行に行こうと思っている。経費をどうするか話し合うとき、私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '一人あたりこのくらいの予算を決めて、予算案で解決できるようにルートを組む',
          },
          {
            type: 'P',
            score: 5,
            content:
              '一人あたりこのくらいの予算で、足りなければもっと両替しよう～!',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '海外旅行から帰ってきて、家に着いたばかりの荷物を整理するときの私の姿は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '面倒くさくても荷物の整理から済ませて休もう',
          },
          {
            type: 'P',
            score: 5,
            content: 'とてもとても疲れたので、一旦休んで整理しよう...！？',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeartJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeartJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeartJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeartJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeartJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeartJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeartJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeartJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeartJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeartJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeartJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeartJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeartJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeartJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeartJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeartJP/INFP.jpg',
      },
    ],
  },
  // unlockMyHeart
  {
    info: {
      mainTitle: '내 마음을 Unlock! 테스트',
      subTitle: '나의 마음을 언록해줘!',
      mainUrl: 'unlockMyHeart',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/unlockMyHeart-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/unlockMyHeart-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '내가 선호하는 데이트는 무엇일까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '전시회, 카페, 맛집 등등 돌아다니는 알찬 실외 데이트',
          },
          {
            type: 'I',
            score: 5,
            content: '집에서 뒹굴거리며 함께 쉬어가는 실내 데이트',
          },
        ],
      },
      {
        which: 'EI',
        question: '애인이 친한 친구들을 소개해 준다고 할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '오 좋아! 언제 해줄 거야?! 기대된다 어떤 사람들일지!',
          },
          {
            type: 'I',
            score: 5,
            content: '아직은 부끄러워 다음에 꼭 소개해 줘~!',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '애인과 해외여행을 왔다! 변수가 생겨 일정 중 하루가 빈다면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `모처럼 해외까지 나왔는데 하루라도 허투루 보낼 수는 없지!! 일단 나가보자!!`,
          },
          {
            type: 'I',
            score: 5,
            content: `안 그래도 피곤했는데 하루 정도 쉬는 건.. 오히려 좋아!`,
          },
        ],
      },
      {
        which: 'SN',
        question: '애인이 ‘아 얼른 결혼하고 싶다’라고 했을 때 나의 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `결혼은 현실 이랬는데.. 결혼하려면 돈은 얼마 정도 드려나`,
          },
          {
            type: 'N',
            score: 5,
            content: `결혼하면 매일 함께라니..! 상상만 해도 행복하다..`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '애인이 ‘내가 만약에 먼저 죽으면 자기는 어떨 거 같아?’라고 했을 때 나의 대답은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '엥?.. 그런 말도 안 되는 소리를..?  먼저 안 죽어!',
          },
          {
            type: 'N',
            score: 5,
            content: `왜 죽는데..? 절대 안 돼.. 나도 같이 죽어!`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '애인과 함께 밤 하늘의 수많은 별을 보며 누워있다! 난 어떤 생각을 할까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `별 진짜 많네 ~ 좋다`,
          },
          {
            type: 'N',
            score: 5,
            content: `별이 총 몇 개일까 저기는 하늘일까 우주일까 아름답다 실제로 우주엔 외계인도 있겠지?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `자기 전 항상 사랑한다고 해주던 애인이 근래에는 해주지 않는다. 이때 나의 생각은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '별생각 없다. 사실 안 해줬는지도 잘 모름',
          },
          {
            type: 'F',
            score: 5,
            content:
              '요즘 왜 사랑한다고 안 해주지.. 사랑이 식었나..? 이제 내가 별로인가..',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '나에게 애인이 ‘좀 통통해진 것 같다?!’라고 했을 때 나의 반응은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `앗 요즘 많이 먹었더니..! 살쪘나 보다..!`,
          },
          {
            type: 'F',
            score: 5,
            content: '내가 살쪘다는 소린가..? 계속 신경 쓰고 있는다',
          },
        ],
      },
      {
        which: 'TF',
        question: `나와 함께 찍었던 사진들을 용량이 부족해서 지웠다는 애인! 나의 반응은 어떨까?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '잘했네!! 나도 한번 지워야겠다 사진이 용량 은근 많이 차지하더라',
          },
          {
            type: 'F',
            score: 5,
            content:
              '그래도 나와의 추억인데.. 말없이 지우다니ㅜ 내심 매우 서운함..',
          },
        ],
      },
      {
        which: 'JP',
        question: '내 마음이 편안하게 열리는 계획표는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `상세하고 디테일하게 짜인 계획표`,
          },
          {
            type: 'P',
            score: 5,
            content: `간략하게 몇 가지만 적혀 있는 계획표`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          '애인과 함께 가는 여행을 가려고 한다. 경비는 어떻게 할지 의논할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '인당 이 정도로 예산 잡고 예산안에서 해결할 수 있게 루트를 짠다.',
          },
          {
            type: 'P',
            score: 5,
            content: '인당 이 정도로 예산 잡고 모자라면 더 환전하자~!',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '해외여행에 다녀오고 난 뒤 막 집에 도착해서 짐 정리할 때 나의 모습은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '귀찮아도 짐 정리부터 다 하고 쉬자!',
          },
          {
            type: 'P',
            score: 5,
            content: '너무너무 피곤해 일단 쉬고 정리하자..!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeart/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeart/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeart/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeart/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeart/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeart/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeart/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeart/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeart/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeart/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeart/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeart/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeart/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeart/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeart/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/unlockMyHeart/INFP.jpg',
      },
    ],
  },
  // beachChar
  {
    info: {
      mainTitle: '해변 성격 테스트',
      subTitle: '나의 성격으로 알아보는 해변은?',
      mainUrl: 'beachChar',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/beachChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/beachChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '모임 후 헤어질 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '2차 안가? 가자 가자 가자!!!!! 아직 10시밖에 안됐어!!!',
          },
          {
            type: 'I',
            score: 5,
            content: '2차는 다음에 갈게! 컨디션이 영 별로네..',
          },
        ],
      },
      {
        which: 'EI',
        question: '캘린더에 약속이 많이 잡힌 것을 봤을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '친한 친구 만날 생각에 설레고 맛집 갈 생각에 빨리 이날이 되기만을 기다려진다.',
          },
          {
            type: 'I',
            score: 5,
            content:
              '친구들 만날 생각에 좋으면서도 한편으로는 약속이 너무 많으면 불편하고 피로감 쌓이는 느낌을 받는다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '새로운 취미활동을 시작하려고 한다 이때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `인터넷에 동호회가 있는지 검색부터 하고 있다면 바로 가입한다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `혼자 할 수 있는 취미가 무엇이 있는지 찾아본다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '지나가는 차들을 보고 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `차 멋있다.. 근데 저차는 얼마 하려나.. 신상인가..?!`,
          },
          {
            type: 'N',
            score: 5,
            content: `차 멋있다.. 근데 차 앞부분 모양이 쟤는 성나있고 쟤는 선해 보이는 표정이네 ㅋㅋㅋ`,
          },
        ],
      },
      {
        which: 'SN',
        question: '카페에서 음료 받을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '이게 7500원?! 근데 진짜 빨리 나왔네..',
          },
          {
            type: 'N',
            score: 5,
            content: `들고 가다가 엎어지는 건 아니겠지. 그럼 다시 해주시려나?!`,
          },
        ],
      },
      {
        which: 'SN',
        question: '비행기 탈 때 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `여행 재밌겠다! 근데 멀미는 안 하려나?!`,
          },
          {
            type: 'N',
            score: 5,
            content: `여행지가서 운명의 상대를 만나는 거 아니야?!`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 “나 졸려ㅠㅠ” 라고 했을 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '아 그래?! 그럼 자! 아니면 나가서 물 좀 먹고 와!',
          },
          {
            type: 'F',
            score: 5,
            content: '근데 너 어제 늦게 잤어? 조금 눈 붙여 내가 깨워 줄게!',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '친구가 “몸이 좀 뻐근해서 스트레칭 겸 산책 나왔어!”라고 했을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `아 그래?! 산책 어디로 갔어? 근처 산책로 괜찮은 곳이 있었어?!`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '아 진짜?! 어제 무리해서 그런 거 아냐?! 지금은 좀 괜찮아진 거 같아?',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 “공모전 1차 또 떨어졌네..”라고 했을 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '기획안 준비는 어떻게 했어? 다른 비슷한 공모전에도 넣어 봤어?',
          },
          {
            type: 'F',
            score: 5,
            content: '열심히 준비했을 텐데 속상하겠다.. 그래도 고생 많았어!!!',
          },
        ],
      },
      {
        which: 'JP',
        question: '놀러 가서 주차해야 하는 상황이라면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `주변에 주차장이 있는지 시간당 얼마인지 몇 시쯤 다 차는지 가려는 곳과 거리는 가까운지 모든 경우의 수를 알아본다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `상황에 따라 그날은 차량이 적을 수도 있으니 일단 가본다.`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          '도예 공방에서 도자기를 만드는데 생각보다 어렵고 시간이 오래 걸렸을 때 드는 생각은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '허리와 어깨가 뻐근하지만 완성한 것을 보니 뿌듯하다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '허리와 어깨가 뻐근하지만 색다른 느낌이라 재밌었으니 됐다고 생각한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '지하철 환승할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '한 번만 환승하면 되고 8-2에서 타면 빠른 환승할 수 있어!',
          },
          {
            type: 'P',
            score: 5,
            content:
              '4호선에서 9호선 한 번만 환승하면 돼! 자리는 여기가 사람 적을 거 같으니 여기 서자!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/beachChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/beachChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/beachChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/beachChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/beachChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/beachChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/beachChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/beachChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/beachChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/beachChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/beachChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/beachChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/beachChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/beachChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/beachChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/beachChar/INFP.jpg',
      },
    ],
  },
  // lovePill
  {
    info: {
      mainTitle: '연애 알약 테스트',
      subTitle: '알약으로 알아보는 나의 연애 스타일은?',
      mainUrl: 'lovePill',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/lovePill-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/lovePill-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '지인들과 연락할 때, 둘 중 나와 가까운 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '지인들에게 먼저 안부 인사를 묻는 편이다.',
          },
          {
            type: 'I',
            score: 5,
            content: '지인들에게 먼저 안부 인사가 오는 편이다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '한 달 동안 주말에 약속이 꽉 찼다면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '한 달 동안 주말을 알차게 보낼 수 있어서 좋다.',
          },
          {
            type: 'I',
            score: 5,
            content: '이러면 내 시간이 없는데… 약속 하나만 취소됐으면…',
          },
        ],
      },
      {
        which: 'EI',
        question: '둘 중 나와 가까운 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `어떤 모임이든 대화에 잘 참여하고, 인원이 많아도 말을 잘한다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `여러 사람이 모인 자리보다, 1:1로 만났을 때 더 편하게 말한다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '운전 중 갑자기 차가 막힌다면 내 반응은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `오늘따라 갑자기 왜 이렇게 막혀~ 답답하게!`,
          },
          {
            type: 'N',
            score: 5,
            content: `길이 너무 막히네.. 순간 이동 능력 있었으면 좋겠다..`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '음식점에서 음식을 주문했는데, 직원이 음식을 너무 불안하게 들고 온다면 내 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '음식을 보고 맛있겠다고 생각한다',
          },
          {
            type: 'N',
            score: 5,
            content: `불안하게 들고 오는 직원이 음식을 나에게 쏟는 상상을 한다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '집에 오는 길, 처음 보는 낯선 길을 발견했다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `“처음 보는 길이네.” 생각하고, 가던 길을 마저 간다`,
          },
          {
            type: 'N',
            score: 5,
            content: `“처음 보는 길이네? 이 길로 한번 가볼까?”`,
          },
        ],
      },
      {
        which: 'TF',
        question: `애인이 음식을 만들어 줬는데, 너무 맛있다면 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '우와 이거 어떻게 만든 거야? 너무 맛있다!',
          },
          {
            type: 'F',
            score: 5,
            content:
              '날 위해 음식을 만들어 주다니, 진짜 감동이야ㅠ 너무 맛있어!!',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '중요한 업무를 하던 중, 갑자기 시스템 전원이 나가 업무가 다 날아갔다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `화나지만, 일단 바로 새로 시작해서 업무를 마무리한다.`,
          },
          {
            type: 'F',
            score: 5,
            content: '너무 어이없는 상황에, 억장이 무너진다.',
          },
        ],
      },
      {
        which: 'TF',
        question: `나에게 고민 상담을 하는 친구에게 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '같이 고민하고, 해결책을 제시해 준다.',
          },
          {
            type: 'F',
            score: 5,
            content: '위로를 해주고, 감정적인 공감을 한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '아직 할 일이 쌓여 있지만, 놀러 나왔을 때 내 생각은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `할 일이 아직 많은데, 놀러 나와서 제대로 놀지 못한다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `이왕 놀러 나온 거, 잊어버리고 신나게 논다. `,
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 더 중요하게 생각하는 목표는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '완벽한 계획과, 확실한 목표',
          },
          {
            type: 'P',
            score: 5,
            content: '확실하지 않지만, 새로운 가능성이 보이는 목표',
          },
        ],
      },
      {
        which: 'JP',
        question: '나에게 약속은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '무슨 일이 있더라도 꼭 지켜야 하는 것',
          },
          {
            type: 'P',
            score: 5,
            content: '어쩔 수 없는 상황이라면, 유연하게 바뀔 수도 있는 것',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/lovePill/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/lovePill/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/lovePill/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/lovePill/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/lovePill/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/lovePill/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/lovePill/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/lovePill/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/lovePill/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/lovePill/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/lovePill/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/lovePill/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/lovePill/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/lovePill/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/lovePill/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/lovePill/INFP.jpg',
      },
    ],
  },
  // charDegree
  {
    info: {
      mainTitle: '성격 온도 테스트',
      subTitle: '나의 성격의 온도가 궁금하다면?',
      mainUrl: 'charDegree',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/charDegree-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/charDegree-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          '길 걷다가 “영이 맑으세요”라고 하는 도쟁이들을 만났을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '(육성으로) 와.. 아직도 저렇게 다니는 사람들이 있어?',
          },
          {
            type: 'I',
            score: 5,
            content:
              '(속으로) 와.. 아직도 저렇게 다니는 사람들이 있어? 후다닥 갈 길 마저 간다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '“선착순 1명”이라는 소리를 들었을 때?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '바로 고민 없이 손들면서 “저요!”라고 외친다.',
          },
          {
            type: 'I',
            score: 5,
            content:
              '저 경쟁에 끼기 싫어서 그냥 조용히 구경하지만 한편으로는 무엇인지 궁금해서 계속 구경은 한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '약속 나가기 전에 나의 모습은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `놀 생각에 더 신나서 노래도 틀고 나갈 준비하는 과정에서도 텐션이 높아져 있다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `친한 친구 만날 생각이 기분은 좋지만 이상하게 나갈 준비하면서 벌써 지쳐 있다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '저승사자가 내가 내일 죽을 거라고 서신을 들고 찾아왔다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `서신이고 뭐고 죽었다니까 그냥 따라간다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `저 사람은 어쩌다가 저승사자가 된 걸까.. 무섭게 생겼지만 착해 보이는데..`,
          },
        ],
      },
      {
        which: 'SN',
        question: '최애 배우의 영화시사회에 당첨돼서 갔다 왔을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'MC는 박 OO이 와서 진행했고, 최애 배우가 악수도 해주고 사인도 받아왔어!',
          },
          {
            type: 'N',
            score: 5,
            content: `진짜 대박~이였어! 분위기 아주 후끈후끈한 게 시사회가 아니라 팬미팅 갔다 온 줄~~~`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '만약에 하늘에서 음식이 진짜로 떨어진다면 어떨 거 같냐는 질문에 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `하늘에서 음식이 왜 떨어져? 떨어져 본 적이 없는데 내가 어떻게 알아?`,
          },
          {
            type: 'N',
            score: 5,
            content: `나는 초콜릿 좋아하니까 초콜릿부터 담을 거고, 아! 파스타는 면이랑 소스가 따로 떨어지는 걸까? 머리에 맞으면 머리 망가지는데 그건 어떡하지?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `“화나니까 나한테 말 걸 생각하지도 마!”라고 했을 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '갑자기 왜 그러는 건데??? 이유가 뭔데?',
          },
          {
            type: 'F',
            score: 5,
            content: '야~ 너나 나한테 말 걸지 마~ 어이가 없네',
          },
        ],
      },
      {
        which: 'TF',
        question: '읽씹 당했을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `“바쁜가 보네~ 나중에 보내겠지” 하고 그냥 내 할 일 한다.`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '“내가 말실수한 게 있나?!” 하고 채팅방 위로 올려서 대화 내용 다시 확인해 본다.',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 나의 뒷담을 했단 사실을 알게 됐을 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '그래?! 걔도 참 피곤하게 사네.. 근데 나도 걔 별로야.',
          },
          {
            type: 'F',
            score: 5,
            content:
              '아 진짜?! 나는.. 그래도 잘해줬다고 생각했는데 어떤 부분에서 날 싫어하는 거래? 속상하네..',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구들을 집으로 초대해서 음식을 준비해야 할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `8명 오기로 했으니까 치킨은 8마리, 떡볶이 세트 3개, 마라탕 2개에 과일도 준비됐고.. 음 이 정도면 부족하진 않겠지?!`,
          },
          {
            type: 'P',
            score: 5,
            content: `일단 이 정도로 준비하고 중간에 부족하면 더 시키지 뭐~`,
          },
        ],
      },
      {
        which: 'JP',
        question: '여행 계획 중에 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '숙소는 여기고 근처에 시장 있는데 여긴 이게 유명하대~ 이거 구매 후 저녁 6시까지 숙소로 돌아와서 샤워 후 넷플 보면서 먹으면 되겠다!',
          },
          {
            type: 'P',
            score: 5,
            content:
              '숙소가 바다 뷰라니.. 너무 예쁘잖아~ 저녁은 숙소 가서 뭐 먹을지 좀 정해볼까?',
          },
        ],
      },
      {
        which: 'JP',
        question: '약속 전날에 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '내일 자전거 탄다고 했으니까 치마보다는 바지가 편할 거고 팔 타기 싫으니까 덥더라도 긴 팔을 입는 게 좋겠어!',
          },
          {
            type: 'P',
            score: 5,
            content:
              '오늘 날씨가 좋으니까 화사한 색상의 원피스가 끌리니까 이거 입어야지!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/charDegree/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/charDegree/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/charDegree/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/charDegree/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/charDegree/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/charDegree/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/charDegree/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/charDegree/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/charDegree/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/charDegree/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/charDegree/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/charDegree/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/charDegree/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/charDegree/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/charDegree/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/charDegree/INFP.jpg',
      },
    ],
  },
  // deepOceanOutlawJP
  {
    info: {
      mainTitle: '深海の無法者性格テスト',
      subTitle: '深海の中で気づく私の性格は？',
      mainUrl: 'deepOceanOutlawJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/deepOceanOutlawJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/deepOceanOutlawJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          '立て続けに予定があった! 休むことになった今日、ナオという友人に私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'ちょうど退屈していたので、よかったと出かける準備をする',
          },
          {
            type: 'I',
            score: 5,
            content: '私も昨日も約束があったから..今日は休みたい',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'のんびり寝転がって休んでいたら、突然友達が海を見に行こうって言ったら？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'やることもないのに無気力にいるより、海に遊びに行こう!',
          },
          {
            type: 'I',
            score: 5,
            content: '私は今、とても幸せなのに...？次に行こう!',
          },
        ],
      },
      {
        which: 'EI',
        question: 'ベルのないレストランで注文するときの私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `社長～と叫んで注文する`,
          },
          {
            type: 'I',
            score: 5,
            content: `スタッフが通り過ぎるタイミングに合わせて、手を軽く上げて目が合うのを待つ`,
          },
        ],
      },
      {
        which: 'SN',
        question: '普段歌を聴くときの私は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `イントロ＆メロディー合格`,
          },
          {
            type: 'N',
            score: 5,
            content: `わぁ、歌詞が僕のスタイルのメロディーもいいし`,
          },
        ],
      },
      {
        which: 'SN',
        question: '完璧な体型の私の理想像を見たとき、私は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'うわー..完全に大当たり..',
          },
          {
            type: 'N',
            score: 5,
            content: `ああ、あんな子と出会ったら、一緒に毎日運動したり、食事も一緒にしたり..`,
          },
        ],
      },
      {
        which: 'SN',
        question: '机の上になかったお菓子が置かれているとき、私の考えは？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `ああ、私の一番好きな味のキャンディーだ 誰が置いていったのかな？`,
          },
          {
            type: 'N',
            score: 5,
            content: `私の一番好きな味のキャンディーだ！ でも、なぜこれがここにあるんだ？ 誰かが毒を塗ったんじゃないの？ 食べると突然眠くなるんじゃないの？ 新種の人身売買？`,
          },
        ],
      },
      {
        which: 'TF',
        question: `友達が「実は私、あなたが私にこうしてくれたこと、ちょっと腹が立ったんだ」と言ったら？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'なぜそこが悔しかったの？ 本当に気になったから聞いてるのよ！',
          },
          {
            type: 'F',
            score: 5,
            content:
              'えっ、本当に？ そんなつもりじゃなかったのに、そう感じたならごめんなさい',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'ダイエット中、友だちが悔しいことがあると言って、おいしいものを一緒に食べようと懇願するとき、私は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `ダメだ、そんなもの食べたら明日まで飢えなきゃいけないと断る`,
          },
          {
            type: 'F',
            score: 5,
            content: '困ったけど...懇願に負けて食べてあげるふりでもする',
          },
        ],
      },
      {
        which: 'TF',
        question: `「私はあなたと合わないと思う」って 友達が言った時、私は？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'なぜ合わないと思うのか説明してくれる？',
          },
          {
            type: 'F',
            score: 5,
            content: '衝撃に言葉が続かない',
          },
        ],
      },
      {
        which: 'JP',
        question: '給料/小遣いをもらった後、私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `積金通帳カード代のへそくりをすぐに引いて生活費を始める`,
          },
          {
            type: 'P',
            score: 5,
            content: `お金が入った～！生活費を先に使い、余ったら貯金する`,
          },
        ],
      },
      {
        which: 'JP',
        question: '自分が考えていたことが思い通りにいかないとき、私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'イライラが募る',
          },
          {
            type: 'P',
            score: 5,
            content: 'えーっと、仕方ないね、どうしよう',
          },
        ],
      },
      {
        which: 'JP',
        question: '上半期が終わった8月の私は何を考えているのか？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'もう上半期が終わってしまうなんてもったいない。下半期はあれもやってみて、これもやってみて、もう少し自分にとって有益な時間を過ごそう!',
          },
          {
            type: 'P',
            score: 5,
            content: 'うわー、もう半年も経つんだ、時間が経つのは早いですね！',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlawJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlawJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlawJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlawJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlawJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlawJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlawJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlawJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlawJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlawJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlawJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlawJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlawJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlawJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlawJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlawJP/INFP.jpg',
      },
    ],
  },
  // deepOceanOutlaw
  {
    info: {
      mainTitle: '심해 속 무법자 성격 테스트',
      subTitle: '심해 속에서 알아보는 내성격은?',
      mainUrl: 'deepOceanOutlaw',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/deepOceanOutlaw-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/deepOceanOutlaw-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          '연달아 약속이 있었다! 쉬기로 한 오늘, 나오라는 친구에게 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '오오 그래! 마침 지루했는데 잘 됐다 하고 나갈 채비를 한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '나 어제도 약속 있었어.. 오늘은 쉴래 ㅠㅠ',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '여유롭게 누워서 쉬고 있는데 갑자기 친구가 바다 보러 가자고 한다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '할 일도 없는데 무료하게 있을 바에 바다나 다녀오자!',
          },
          {
            type: 'I',
            score: 5,
            content: '나 지금 너무 행복한데..? 다음에 가자!',
          },
        ],
      },
      {
        which: 'EI',
        question: '벨이 없는 식당에서 주문할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `사장님~ 외친 후 주문한다`,
          },
          {
            type: 'I',
            score: 5,
            content: `직원 지나가는 타이밍 맞춰서 손을 살짝 들고 눈 마주치길 기다린다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '평소 노래 들을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `인트로 & 멜로디 합격`,
          },
          {
            type: 'N',
            score: 5,
            content: `와 가사 완전 내 스타일ᅲ 멜로디도 좋고`,
          },
        ],
      },
      {
        which: 'SN',
        question: '완전 몸 좋은 내 이상형을 보았을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '와.. 완전 대박...',
          },
          {
            type: 'N',
            score: 5,
            content: `아 저런 애랑 만나면 같이 매일 운동도 하고 식단도 하고...`,
          },
        ],
      },
      {
        which: 'SN',
        question: '책상 위에 없던 사탕이 놓여 있을 때 나의 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `오 내가 제일 좋아하는 맛 사탕이다! 누가 놓고 갔지?`,
          },
          {
            type: 'N',
            score: 5,
            content: `오 내가 제일 좋아하는 맛 사탕이다! 근데 이게 왜 여깄지? 누가 독 묻혀놓은 거 아님? 먹으면 갑자기 잠드는 거 아냐? 신종 인신매매?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 ‘사실 나 네가 나한테 이랬던 부분 좀 속상했어’라고 말한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '그 부분이 왜 속상했던 건데? 진짜 궁금해서 물어보는 거야!',
          },
          {
            type: 'F',
            score: 5,
            content: '헐 진짜? 그런 의도는 아니었는데 그렇게 느꼈다면 미안',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '식단 중 일 때 친구가 속상한 일이 있다며 맛있는 거 같이 먹자고 애원할 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `오바임 그거 먹음 내일까지 굶어야 해 하며 거절한다`,
          },
          {
            type: 'F',
            score: 5,
            content: '곤란하지만.. 애원에 못 이겨 가서 먹어주는 척이라도 한다',
          },
        ],
      },
      {
        which: 'TF',
        question: `'나 너랑 안 맞는 것 같아' 라고 친구에게 들었을 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜 안 맞는다고 생각하는지 설명해 줄래?',
          },
          {
            type: 'F',
            score: 5,
            content: '..충격에 말을 잇지 못함',
          },
        ],
      },
      {
        which: 'JP',
        question: '월급/용돈을 받은 후 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `적금통장 카드값 비상금을 바로 빼두고 생활비 시작한다`,
          },
          {
            type: 'P',
            score: 5,
            content: `오예 돈 들어왔다~! 생활비 먼저 쓰고 남으면 또 모아두고 한다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 생각했던 일이 뜻대로 안 풀릴 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '짜증이 밀려온다',
          },
          {
            type: 'P',
            score: 5,
            content: '에효 어쩔 수 없지 뭐 어쩔 거야',
          },
        ],
      },
      {
        which: 'JP',
        question: '상반기가 지나버린 8월 나는 무슨 생각을 하는가?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '벌써 상반기가 지났네 아깝다.. 하반기엔 이것도 해보고 저것도 해보고 조금 더 나에게 유익한 시간을 보내야지!',
          },
          {
            type: 'P',
            score: 5,
            content: '우와 벌써 반년이 지났네 시간 겁나빠르다!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlaw/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlaw/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlaw/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlaw/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlaw/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlaw/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlaw/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlaw/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlaw/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlaw/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlaw/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlaw/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlaw/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlaw/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlaw/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/deepOceanOutlaw/INFP.jpg',
      },
    ],
  },
  // loveTVJP
  {
    info: {
      mainTitle: '恋愛TVテスト',
      subTitle: 'TVで調べる私の恋愛性向は？',
      mainUrl: 'loveTVJP',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/loveTVJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveTVJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '好きな人ができたら私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'ためらうことなくLINEをし、すぐに約束をして会おうとする',
          },
          {
            type: 'I',
            score: 5,
            content:
              '周りをウロウロしながら連絡方法を探し、ゆっくり仲良くなろうとしている',
          },
        ],
      },
      {
        which: 'EI',
        question: '週末にデートがない場合は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '友達に連絡を回して約束をする',
          },
          {
            type: 'I',
            score: 5,
            content: '自宅でゆっくりくつろぎながら癒される',
          },
        ],
      },
      {
        which: 'EI',
        question: '恋人と旅行中にコースを選ぶなら、私の選択は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `人は多いけど、有名で、ホットな必須観光コース`,
          },
          {
            type: 'I',
            score: 5,
            content: `静かで有名ではありませんが、癒される観光コースです`,
          },
        ],
      },
      {
        which: 'SN',
        question: '恋愛を始める前の私は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `ワクワク、嬉しい`,
          },
          {
            type: 'N',
            score: 5,
            content: `あらかじめ私たちの恋愛を描いて、想像してみる`,
          },
        ],
      },
      {
        which: 'SN',
        question: '好意を抱いている相手と初対面後、相手の返信が遅くなったら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '今日は忙しいのか？ まあ、そうかもね～',
          },
          {
            type: 'N',
            score: 5,
            content: `もしかして昨日デートがイマイチだったっけ？ まさか私が嫌いになったのかな？`,
          },
        ],
      },
      {
        which: 'SN',
        question: '恋人にサプライズプレゼントをもらったら、私の選択は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `意味は少しなくても、最近私に必要だった実用的なプレゼント`,
          },
          {
            type: 'N',
            score: 5,
            content: `実用性は落ちても、きれいで、真心のこもったプレゼント`,
          },
        ],
      },
      {
        which: 'TF',
        question: `私は好きな人に？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '質問をたくさんする',
          },
          {
            type: 'F',
            score: 5,
            content: 'リアクションをたくさんする',
          },
        ],
      },
      {
        which: 'TF',
        question: '恋人との約束に遅れたら？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `遅れた具体的な状況と理由を説明する`,
          },
          {
            type: 'F',
            score: 5,
            content: 'まず謝罪からして、恋人の気持ちを把握する',
          },
        ],
      },
      {
        which: 'TF',
        question: `恋人と一緒にデート計画中に意見の衝突で喧嘩が起きたら？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '私の意見がより良い理由を詳しく説明する',
          },
          {
            type: 'F',
            score: 5,
            content: '私も言いたいことはたくさんあるのですが、残念です',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '一人の時間を楽しんでいたところ、恋人が突然会いたいと言ってきたら？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `いくら恋人でも突然の出会いは難しい`,
          },
          {
            type: 'P',
            score: 5,
            content: `突然の出会いがプレッシャーになるけど、出かける`,
          },
        ],
      },
      {
        which: 'JP',
        question: '恋人との記念日を準備するときの私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '数ヶ月あるいは数週間前から事前に準備する',
          },
          {
            type: 'P',
            score: 5,
            content: '1週間あれば十分だと思う',
          },
        ],
      },
      {
        which: 'JP',
        question: '恋人と旅行に行くなら、私はどんな恋人に近いだろうか？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '旅行計画だけA4用紙に3枚ずつ整理して渡す恋人たち',
          },
          {
            type: 'P',
            score: 5,
            content: '場所だけ決めて、好きなところに行こうという恋人たち',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/loveTVJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/loveTVJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/loveTVJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/loveTVJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/loveTVJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/loveTVJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/loveTVJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/loveTVJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/loveTVJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/loveTVJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/loveTVJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/loveTVJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/loveTVJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/loveTVJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/loveTVJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/loveTVJP/INFP.jpg',
      },
    ],
  },
  // bubbleCharJP
  {
    info: {
      mainTitle: 'シャボン玉性格テスト',
      subTitle: 'シャボン玉で調べる私の性格は？',
      mainUrl: 'bubbleCharJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/bubbleCharJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/bubbleCharJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '初対面の人とたくさん話した後、私はどうなるのでしょうか？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '新しい人といろいろなことを話すのはいい気分だ。',
          },
          {
            type: 'I',
            score: 5,
            content: '初対面の人と話すのは好きだったけど、疲れたよ。',
          },
        ],
      },
      {
        which: 'EI',
        question: '何カ月も家から出られないとしたら、私はどうなるの？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '体がくすぐられ、拷問としか思えない。',
          },
          {
            type: 'I',
            score: 5,
            content: '家にいてリラックスできていいですね。',
          },
        ],
      },
      {
        which: 'EI',
        question: '大勢で音楽祭に行ったらどうだろう？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `今日はたくさん楽しめそうだね！とても楽しみだ`,
          },
          {
            type: 'I',
            score: 5,
            content: `思ったより人が多い...。楽しみだけど、待ちきれない...。 `,
          },
        ],
      },
      {
        which: 'SN',
        question: '私はぼーっとする時？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `何も考えない。`,
          },
          {
            type: 'N',
            score: 5,
            content: `考えが尾を引く。`,
          },
        ],
      },
      {
        which: 'SN',
        question: '飛行機に乗っていて、飛行機が激しく揺れたらどうする？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '乗り物酔いしそうだから、できるだけじっとしているよ。',
          },
          {
            type: 'N',
            score: 5,
            content: `すごく揺れてるんだけど？ もしかして墜落はしないよね？`,
          },
        ],
      },
      {
        which: 'SN',
        question: '私が勉強をしたら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `一応覚えて、後で理解する。`,
          },
          {
            type: 'N',
            score: 5,
            content: `意味がわかるまで読み、暗記する。`,
          },
        ],
      },
      {
        which: 'TF',
        question: `もし日記をつけていたら？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '具体的には、今日起こったことを詳しく書く。',
          },
          {
            type: 'F',
            score: 5,
            content: '今日あったこと、感じたことを書く。',
          },
        ],
      },
      {
        which: 'TF',
        question: '私が謝る方法は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `詳細な遅刻理由＋謝罪`,
          },
          {
            type: 'F',
            score: 5,
            content: '誠実な謝罪＋理由の説明',
          },
        ],
      },
      {
        which: 'TF',
        question: `最近抜け毛が多いから、抜け毛防止用のシャンプーを使っているんだ」という友人がいたらどうだろ`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'どうだ？シャンプーの調子はどう？大丈夫か？',
          },
          {
            type: 'F',
            score: 5,
            content: '大丈夫ですか？何が足りないの？医者には行った？',
          },
        ],
      },
      {
        which: 'JP',
        question: '部屋の掃除はどうしていますか？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `こまめに掃除し、ゴミが出たらすぐに取り除く。`,
          },
          {
            type: 'P',
            score: 5,
            content: `捕まえて一気に掃除する`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'お気に入りのレストランが閉まっていたら？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '残念だが、念のためリサーチ済みの別の店に向かう。',
          },
          {
            type: 'P',
            score: 5,
            content:
              '残念だが、選択の余地はない。別のレストランを探して立ち去る。',
          },
        ],
      },
      {
        which: 'JP',
        question: '約束の準備をする時、私と近いものは？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '約束時間の数時間前から、面倒くさがりながらあらかじめ準備を始める。',
          },
          {
            type: 'P',
            score: 5,
            content: 'しばらくうろついてから急いで準備する',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharJP/INFP.jpg',
      },
    ],
  },
  // bubbleCharEng
  {
    info: {
      mainTitle: 'Bubble personality test',
      subTitle: 'What is your personality that recognizes you through bubbles?',
      mainUrl: 'bubbleCharEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/bubbleCharEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/bubbleCharEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'Meeting new people, after talking a lot, what about me?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'It feels good to talk to new people about different things.',
          },
          {
            type: 'I',
            score: 5,
            content: "I loved talking to new people, but I'm tired",
          },
        ],
      },
      {
        which: 'EI',
        question: "What about me if I can't go out of the house for months?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: "My body is aching, and I feel like I'm being tortured.",
          },
          {
            type: 'I',
            score: 5,
            content: "It's nice to be home and relaxed.",
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What about me if I went to a music festival with a lot of many people?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Looks like we're going to have a lot of fun today! I'm so excited!`,
          },
          {
            type: 'I',
            score: 5,
            content: `There are more people than I thought... It's going to be fun, but I can't wait... `,
          },
        ],
      },
      {
        which: 'SN',
        question: 'When I space out?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Don't think about it.`,
          },
          {
            type: 'N',
            score: 5,
            content: `A thought bites you in the ass.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What if you were flying on an airplane and the plane shook violently?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              "I'm getting motion sickness, so I'm going to stay as still as possible.",
          },
          {
            type: 'N',
            score: 5,
            content: `It's so wobbly, I wonder if it's going to crash?`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What if I study?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Memorize first, understand later.`,
          },
          {
            type: 'N',
            score: 5,
            content: `Read it, memorize it, until it makes sense.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What if I kept a journal?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Specifically, I write in detail what happened today in detail.',
          },
          {
            type: 'F',
            score: 5,
            content: 'I write what happened today and how I felt.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'How do I apologize?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Detailed reason for late + apology`,
          },
          {
            type: 'F',
            score: 5,
            content: 'Sincere apology + explanation of why',
          },
        ],
      },
      {
        which: 'TF',
        question: `What if a friend says, "I've been losing my hair lately, so I'm using an anti-hair loss shampoo"?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "Yeah? How's the shampoo working? You okay?",
          },
          {
            type: 'F',
            score: 5,
            content:
              'Are you okay? What are you missing? Have you been to the doctor?',
          },
        ],
      },
      {
        which: 'JP',
        question: 'How do you clean your room?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Clean frequently and remove trash as soon as it appears `,
          },
          {
            type: 'P',
            score: 5,
            content: `Catch me and clean me in one fell swoop`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'What if your favorite restaurant is closed?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "Unfortunately, I head to another restaurant I've already researched, just in case.",
          },
          {
            type: 'P',
            score: 5,
            content:
              "It's a shame, but you have no choice. You walk away in search of another restaurant.",
          },
        ],
      },
      {
        which: 'JP',
        question: 'When you prepare for an appointment, what is close to you?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Hours before your appointment, you start fussing and getting ready.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'He rolls around for a long time and gets ready in a hurry.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/bubbleCharEng/INFP.jpg',
      },
    ],
  },
  // bubbleChar
  {
    info: {
      mainTitle: '비눗방울 성격 테스트',
      subTitle: '비눗방울로 알아보는 나의 성격은?',
      mainUrl: 'bubbleChar',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/bubbleChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/bubbleChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '새로운 사람들을 만나, 많은 이야기를 나눈 후 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '새로운 사람들과 다양한 이야기를 나눠 기분이 좋다.',
          },
          {
            type: 'I',
            score: 5,
            content: '새로운 사람들과 이야기는 좋았지만, 왠지 피곤하다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '몇 개월 동안 집 밖으로 나갈 수 없는 상황이라면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '몸이 근질거리고, 고문이 따로 없다고 생각한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '집에서 푹 쉬니까 오히려 좋다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '사람 많은 뮤직 페스티벌 콘서트에 갔다면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `역시 사람이 많네? 오늘 너무 재밌겠다! 완전 기대돼!`,
          },
          {
            type: 'I',
            score: 5,
            content: `사람이 생각 보다 더 많네.. 재밌을 것 같지만, 벌써 기 빨려… `,
          },
        ],
      },
      {
        which: 'SN',
        question: '나는 멍 때릴 때?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `아무 생각도 하지 않는다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `생각이 꼬리에 꼬리를 문다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '비행기를 타고 가던 중, 비행기가 급격하게 흔들린다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '멀미 나니까 최대한 가만히 있어야겠다..',
          },
          {
            type: 'N',
            score: 5,
            content: `너무 흔들리는데? 혹시 추락하지는 않겠지?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 공부를 한다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `일단 외우고, 나중에 이해한다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `이해가 될 때까지 읽어 보고, 외운다`,
          },
        ],
      },
      {
        which: 'TF',
        question: `내가 일기를 쓴다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '구체적으로 오늘 있었던 일을 자세하게 쓴다.',
          },
          {
            type: 'F',
            score: 5,
            content: '오늘 있었던 일과 내가 느낀 감정을 쓴다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '내가 사과하는 방법은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `자세한 늦은 이유 설명 + 사과`,
          },
          {
            type: 'F',
            score: 5,
            content: '진심을 담은 사과 + 이유 설명',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 “나 요즘 머리카락이 빠지는 것 같아서 탈모 방지 샴푸 사용 중이야”라고 한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '그래? 샴푸 효과는 어때? 괜찮아?',
          },
          {
            type: 'F',
            score: 5,
            content: '헉 괜찮아? 뭐 때문에 빠지는 거야? 병원은 가봤어?',
          },
        ],
      },
      {
        which: 'JP',
        question: '나의 방 청소 스타일은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `자주 청소하고, 쓰레기는 생기면 바로바로 치운다 `,
          },
          {
            type: 'P',
            score: 5,
            content: `날 잡아서 한 번에 몰아서 청소한다 `,
          },
        ],
      },
      {
        which: 'JP',
        question: '가려던 맛집이 문을 닫았다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '아쉽지만, 혹시나 해서 미리 찾아본 다른 맛집으로 향한다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '아쉽지만, 어쩔 수 없다. 다른 음식점을 찾아 발걸음을 옮긴다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '약속 준비할 때, 나와 가까운 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '약속 시간 몇 시간 전부터, 귀찮아하면서 미리 준비를 시작한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '한참 뒹굴뒹굴하다 급하게 준비한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/bubbleChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/bubbleChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/bubbleChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/bubbleChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/bubbleChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/bubbleChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/bubbleChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/bubbleChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/bubbleChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/bubbleChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/bubbleChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/bubbleChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/bubbleChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/bubbleChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/bubbleChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/bubbleChar/INFP.jpg',
      },
    ],
  },
  // catFootLove
  {
    info: {
      mainTitle: '냥 발바닥 연애 테스트',
      subTitle: '냥 발바닥으로 알아보는 나의 연애 스타일은?',
      mainUrl: 'catFootLove',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/catFootLove-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/catFootLove-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '자기 친구들을 소개해 준다는 애인! 내 대답은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '오 너무 좋지!! 궁금했는데 재밌겠다!',
          },
          {
            type: 'I',
            score: 5,
            content: '좀 불편한데.. 나중에 천천히 해주라',
          },
        ],
      },
      {
        which: 'EI',
        question: '식당에서 메뉴를 추가 주문할 때 내 모습은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '바로 사장님을 불러서 주문함',
          },
          {
            type: 'I',
            score: 5,
            content: '주문해달라고 애인에게 슬쩍 부탁함',
          },
        ],
      },
      {
        which: 'EI',
        question: '연인과의 데이트가 생각보다 일찍 끝났다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `친구들한테 만나자고 연락해 봐야지`,
          },
          {
            type: 'I',
            score: 5,
            content: `오히려 좋아~ 얼른 집 가서 쉬어야지 `,
          },
        ],
      },
      {
        which: 'SN',
        question: '갑자기 카톡 프사를 다 내린 애인! 난 어떤 생각을 할까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `(아무 생각 없이 바로 물어봄) 엥 프사 내렸네? `,
          },
          {
            type: 'N',
            score: 5,
            content: `프사 왜 내렸지..? 무슨 심경의 변화일까..? `,
          },
        ],
      },
      {
        which: 'SN',
        question: '애인과 놀러 갔던 여행지에 대해 얘기할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '거기 거리도 깨끗하고 음식도 맛있더라! ',
          },
          {
            type: 'N',
            score: 5,
            content: `거기 분위기 너무 좋았어 또 가면 얼마나 좋을까? `,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인에게 선물을 받으면 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `내게 필요한 선물을 사줘서 감동`,
          },
          {
            type: 'N',
            score: 5,
            content: `선물을 사기까지의 과정이 상상되며 감동 `,
          },
        ],
      },
      {
        which: 'TF',
        question: `연애를 할 때 주로 내 모습은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '서운하다는 얘기를 자주 들음',
          },
          {
            type: 'F',
            score: 5,
            content: '서운하다는 얘기를 자주 함 ',
          },
        ],
      },
      {
        which: 'TF',
        question: '애인이 나에게 ‘난 그냥 네가 좋아!’라고 한다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `나도 좋지~! 근데 어떤 부분이 제일 좋아?`,
          },
          {
            type: 'F',
            score: 5,
            content: '나도 나도!! (감동) 역시 우린 통하나 봐!',
          },
        ],
      },
      {
        which: 'TF',
        question: `기분이 안 좋아 보이는 애인에게 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '기프티콘을 보내거나 좋아하는 걸 주려고 함',
          },
          {
            type: 'F',
            score: 5,
            content: '전화나 카톡으로 기분을 풀어주려고 노력함',
          },
        ],
      },
      {
        which: 'JP',
        question: '데이트 중 예쁜 카페를 발견했다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `메모해 뒀다가 다음 데이트 일정에 추가함 `,
          },
          {
            type: 'P',
            score: 5,
            content: `저기 다음에 꼭 가보자! (나중에 까먹음) `,
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 대화 중 얘기가 딴 길로 새고 있다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '주제랑 안 맞는 얘기 불편함',
          },
          {
            type: 'P',
            score: 5,
            content: '재미있는 얘기라면 같이 딴 길로 샘',
          },
        ],
      },
      {
        which: 'JP',
        question: '갑자기 너무 보고 싶다는 애인에게 내 대답은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '일단 이건 해야 해서 저녁 8시에 잠깐 볼까?',
          },
          {
            type: 'P',
            score: 5,
            content: '이거 나중에 하면 돼! 지금 만날래?',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/catFootLove/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/catFootLove/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/catFootLove/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/catFootLove/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/catFootLove/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/catFootLove/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/catFootLove/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/catFootLove/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/catFootLove/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/catFootLove/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/catFootLove/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/catFootLove/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/catFootLove/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/catFootLove/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/catFootLove/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/catFootLove/INFP.jpg',
      },
    ],
  },
  // whyLoveJP
  {
    info: {
      mainTitle: 'あなたを愛する11の理由テスト',
      subTitle: '私を愛する理由が知りたいなら？',
      mainUrl: 'whyLoveJP',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/whyLoveJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/whyLoveJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '週末は何をしていますか？',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '平日しか働いていないので、週末は自分を癒しに出かけなければならない。',
          },
          {
            type: 'I',
            score: 5,
            content:
              '平日しか働いていないので、週末は家で休まなければならない。',
          },
        ],
      },
      {
        which: 'EI',
        question: 'もしあなたの友人が卒業制作を台無しにしてしまったら？',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '私なら、気分転換に他の友人と出かけるよう友人に誘うだろう。',
          },
          {
            type: 'I',
            score: 5,
            content:
              '私なら、友人が大丈夫かどうか尋ねて、デッサン喫茶に行って練習できないか提案する。',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'もしあなたの友人が、しばらくの間、あなたの家の近くで会いたいと言ったら？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `友人に会って、家の近くのマートに寄るつもりだ。`,
          },
          {
            type: 'I',
            score: 5,
            content: `友人に会って、まっすぐ家に帰って一人で休むよ。`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'ラーメンはどのように調理するのですか？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `おいしいラーメンの作り方を検索して、そのレシピに従う。`,
          },
          {
            type: 'N',
            score: 5,
            content: `何千回もラーメンを作っているから、感覚で料理するんだ。`,
          },
        ],
      },
      {
        which: 'SN',
        question: '街で工事現場を見かけたらどうする？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '不便でうるさいので、早く終わってほしい。',
          },
          {
            type: 'N',
            score: 5,
            content: `突然何かが落ちてくるのが怖くて、さっさと通り過ぎてしまう。`,
          },
        ],
      },
      {
        which: 'SN',
        question: '傘立てにたくさんの傘が並んでいたら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `たくさんの人が来たんだなと思います。`,
          },
          {
            type: 'N',
            score: 5,
            content: `似たようなデザインはたくさんあると思うので、他の人の傘を取ることもできる。`,
          },
        ],
      },
      {
        which: 'TF',
        question: `もし、あなたが作った製品を宣伝するとしたら？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '既存製品との性能向上を比較することで、数字の信頼性を強調している。',
          },
          {
            type: 'F',
            score: 5,
            content:
              '私は商品の情緒を刺激するストーリーテリングを作り、デザインをすっきりときれいに仕上げる。',
          },
        ],
      },
      {
        which: 'TF',
        question: '友達が重い鍋を持っているのを見つけたらどうする？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `重い鉄鍋なのだろうか。`,
          },
          {
            type: 'F',
            score: 5,
            content: '重くないのかと心配になる。',
          },
        ],
      },
      {
        which: 'TF',
        question: `友達が機嫌が悪くて散歩に出かけたとしたら？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'いきなり散歩ですか？どこに行くのか聞いてみる。',
          },
          {
            type: 'F',
            score: 5,
            content: '今日は何があった？どうして機嫌が悪いの？',
          },
        ],
      },
      {
        which: 'JP',
        question: 'プロジェクトの業績が思わしくなかったら？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `私の計画に何か問題があったのだろうかと考える。`,
          },
          {
            type: 'P',
            score: 5,
            content: `実は、最初から少し悪かったのかもしれない。`,
          },
        ],
      },
      {
        which: 'JP',
        question: '企画書を書くときはどうするのですか？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'できるだけ計画通りに仕事をする。',
          },
          {
            type: 'P',
            score: 5,
            content: 'まずは試してみて、後で修正する。',
          },
        ],
      },
      {
        which: 'JP',
        question: 'まもなく飛行機が離陸するという案内放送が聞こえる時か？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '事前にスケジュールを決めておいたから、心配しないで、信じてついてきてね！',
          },
          {
            type: 'P',
            score: 5,
            content:
              'とても興奮している！初めてだから心配だけど、すごく楽しいと思う！',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/whyLoveJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/whyLoveJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/whyLoveJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/whyLoveJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/whyLoveJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/whyLoveJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/whyLoveJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/whyLoveJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/whyLoveJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/whyLoveJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/whyLoveJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/whyLoveJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/whyLoveJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/whyLoveJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/whyLoveJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/whyLoveJP/INFP.jpg',
      },
    ],
  },
  // ferrisWheel
  {
    info: {
      mainTitle: '관람차 성격 테스트',
      subTitle: '관람차로 알아보는 나의 성격은?',
      mainUrl: 'ferrisWheel',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/ferrisWheel-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/ferrisWheel-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '친구가 이번주 주말에 놀이공원가자고 할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '이번 주? 오~ 좋아 좋아~ 잠시만 나 스케줄 확인 한 번만 하고 말해 줄게!',
          },
          {
            type: 'I',
            score: 5,
            content:
              '이번 주? 아.. 미안 나 일정 있어! 다음에 같이 가자! (사실은 일정 없음)',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '오픈전인데도 불구하고 입구에 사람들이 줄 서 있는 모습을 본 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '놀이 기구 탈 생각과 간식 먹을 생각에 잔뜩 기대가 된다.',
          },
          {
            type: 'I',
            score: 5,
            content:
              '많은 인파에 벌써부터 기 빨리는 느낌이라 집 생각이 스멀스멀 올라온다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '모르는 사람과 기구를 타게 된다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `오! 재밌겠다! 혼자 오셨어요? 짝수가 안 맞아서 혼자 탔어요ㅠㅠ`,
          },
          {
            type: 'I',
            score: 5,
            content: `차라리 그냥 혼자 타고 싶은데.. 옆 사람이 말 안 걸어 줬으면 좋겠다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '롤러코스터를 탄 후에 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `진짜 타보고 싶었던 건데 재밌겠다! 이따가 또 타야지!!!`,
          },
          {
            type: 'N',
            score: 5,
            content: `안전바 이거 왜 덜컹거리는 거 같지.. 타다가 떨어져서 나 뉴스에 나오는 거 아냐?!`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '귀신의 집 투어 후 만약에 진짜 귀신이 붙어서 나오면 어떡하지?라고 했을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '음.. 귀신의 집 안에 진짜 귀신이 있을 리가 없잖아. 밥이나 먹으러 가자!',
          },
          {
            type: 'N',
            score: 5,
            content: `혹시나 빙의 되거나 그러면 어떡하지.. 귀신 보이는거 아냐?! 잠시만 그러면은 소금이나 팥을 가지고 다녀야 하나..`,
          },
        ],
      },
      {
        which: 'SN',
        question: '놀이공원 안에 귀여운 고양이가 쓱 지나간다 이때 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `오호 이 동네 사니? 나도 고양이 키우고 싶다!!! 너무 귀여워..!!!`,
          },
          {
            type: 'N',
            score: 5,
            content: `야옹~ 이러면 내 말 알아듣고 오려나? 고양이 키우려면 돈 많이 든다던데..ㅠㅠ`,
          },
        ],
      },
      {
        which: 'TF',
        question: `밥 먹으러 가서 메뉴 선정 할 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '무슨 음식 좋아해? 매운 거는 잘 먹어? 좋아해?',
          },
          {
            type: 'F',
            score: 5,
            content:
              '아 진짜?! 오오 나도 그 음식 진짜 좋아해! 음식 취향 비슷해서 진짜 신기하다!',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '시킨 메뉴가 생각보다 맛도 별로고 가격 대비 양도 아쉬웠을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `아 맛 별론데? 그냥 담엔 딴 데 가자.`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '여기 리뷰 좋다고 해서 왔는데 리뷰 조작이네 이거 ㅂㄷㅂㄷ 조만간에 망할 듯!!!',
          },
        ],
      },
      {
        which: 'TF',
        question: `2시간 대기해야 탈 수 있다는 말에 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '다른 줄 적은 거 타는게 맞지. 다른 거 타자!',
          },
          {
            type: 'F',
            score: 5,
            content:
              '다른 거도 줄 대부분 다 길 텐데 오히려 더 기다리게 될 수도 있어~',
          },
        ],
      },
      {
        which: 'JP',
        question: '이동 중 예쁜 공원을 발견했다 이때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `이제 이쪽으로 이동해서 여기로 가야 할 거 같아!`,
          },
          {
            type: 'P',
            score: 5,
            content: `우와~ 여기에 이런 데가 있었어?! 저기도 가볼까?!`,
          },
        ],
      },
      {
        which: 'JP',
        question: '집으로 가는 길 차가 끊긴 상황이라면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '재빠르게 지도 앱을 켜서 새로운 경로를 확인한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '잠시 당황하다 택시 탈까? 하는 생각을 한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '놀이공원에서 신나게 놀고 집으로 돌아온 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '머리띠랑 기념품 산 거 정리하고 10분 정도 쉬다가 씻고 나와서 사진 정리해야지',
          },
          {
            type: 'P',
            score: 5,
            content:
              '나갔다 왔으니 일단 손 씻고 나머지는 일단 누워서 쉬고 생각해 볼까?!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/ferrisWheel/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/ferrisWheel/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/ferrisWheel/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/ferrisWheel/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/ferrisWheel/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/ferrisWheel/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/ferrisWheel/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/ferrisWheel/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/ferrisWheel/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/ferrisWheel/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/ferrisWheel/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/ferrisWheel/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/ferrisWheel/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/ferrisWheel/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/ferrisWheel/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/ferrisWheel/INFP.jpg',
      },
    ],
  },
  // charRoadJP
  {
    info: {
      mainTitle: '性格道路テスト',
      subTitle: '道で調べる私の性格は？',
      mainUrl: 'charRoadJP',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/charRoadJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/charRoadJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '私が好む人間関係は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '浅いけど、多くの人と親しくなること',
          },
          {
            type: 'I',
            score: 5,
            content: '少数の人と深く親しくなること',
          },
        ],
      },
      {
        which: 'EI',
        question: '私は初めて会う人の前で？',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '初めに人見知りをするが、たちまち思いっきり会話を引っ張っていく',
          },
          {
            type: 'I',
            score: 5,
            content: '人見知りをし、人の言うことを傾聴する',
          },
        ],
      },
      {
        which: 'EI',
        question: '友達と歩いていて道で偶然友達の知人に会った時、私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `まず挨拶して、自然に会話に参加する`,
          },
          {
            type: 'I',
            score: 5,
            content: `友達が会話が終わるまでそばでじっと待っている`,
          },
        ],
      },
      {
        which: 'SN',
        question: '連絡中に友達が見て無視したら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `まあまあだと思う`,
          },
          {
            type: 'N',
            score: 5,
            content: `もしかして私が何か失言したのかな？ と会話をさかのぼってみる`,
          },
        ],
      },
      {
        which: 'SN',
        question: '悪い夢を見て冷や汗を流しながら起きた。 私の考えは？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'ふぅ、夢だね··· 現実かと..',
          },
          {
            type: 'N',
            score: 5,
            content: `何の夢がこうなの？ ひょっとして悪いことが起きるんじゃないよね？`,
          },
        ],
      },
      {
        which: 'SN',
        question: '過去に戻ったら何がしたいかという友達の言葉に私は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `意味がない~そんなことは起こらない~`,
          },
          {
            type: 'N',
            score: 5,
            content: `当然ロトの番号をいっぱい覚えていくべきではない？`,
          },
        ],
      },
      {
        which: 'TF',
        question: `相手と大きな葛藤が生じた時、私は？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '最大限理性的で論理的に話す',
          },
          {
            type: 'F',
            score: 5,
            content:
              '私も言いたいことはたくさんあるけど、整理がうまくいかなくて涙から出そうだ',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '長い間就職を準備していた友達が望んでいた会社に就職に成功したら、私は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `おめでとう！就職した感想はどう？ どんな会社なの？`,
          },
          {
            type: 'F',
            score: 5,
            content:
              'おめでとう！長い間準備したのに、一生懸命努力した甲斐があるだろうね！',
          },
        ],
      },
      {
        which: 'TF',
        question: `相手と会話する時、私がもっと気にすることは？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '会話のテーマとファクト',
          },
          {
            type: 'F',
            score: 5,
            content: '話し方と表情',
          },
        ],
      },
      {
        which: 'JP',
        question: '家の近くに私の好きな料理をする食堂が新しくできたら？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `お、私の好きな食べ物！日程に合わせて事前に予約しておかないと！`,
          },
          {
            type: 'P',
            score: 5,
            content: `お、私の一番好きな食べ物！近いうちに一度行ってみないと~`,
          },
        ],
      },
      {
        which: 'JP',
        question: '友達と約束がある日、私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '約束時間の数時間前から準備を終えても時間が少し残っている',
          },
          {
            type: 'P',
            score: 5,
            content: '約束時間の何分前から急いで準備する',
          },
        ],
      },
      {
        which: 'JP',
        question: '私がもっと嫌な状況は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '私の立てた計画が狂っている状況',
          },
          {
            type: 'P',
            score: 5,
            content: '退屈な空間に長くいなければならない状況',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/charRoadJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/charRoadJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/charRoadJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/charRoadJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/charRoadJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/charRoadJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/charRoadJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/charRoadJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/charRoadJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/charRoadJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/charRoadJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/charRoadJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/charRoadJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/charRoadJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/charRoadJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/charRoadJP/INFP.jpg',
      },
    ],
  },
  // tetrisChar
  {
    info: {
      mainTitle: '테트리스 성격 테스트',
      subTitle: '나의 성격을 테트리스로 플레이해 본다면?',
      mainUrl: 'tetrisChar',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/tetrisChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/tetrisChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '당황스러운 상황이 발생했을 때 나의 반응은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '말이 많아진다',
          },
          {
            type: 'I',
            score: 5,
            content: '생각이 많아진다',
          },
        ],
      },
      {
        which: 'EI',
        question: '주말에 내가 하고 싶은 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '사람들과 만나 신나게 노는 것',
          },
          {
            type: 'I',
            score: 5,
            content: '집에서 혼자 편하게 쉬는 것',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구와 만나 놀던 중, 친구의 친구가 같이 놀자고 했다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `난 상관없어! 오늘부터 친구 하면 되지!`,
          },
          {
            type: 'I',
            score: 5,
            content: `응?..그건 좀..(절대 싫어..)`,
          },
        ],
      },
      {
        which: 'SN',
        question: '폭우가 쏟아지는 날 비를 쫄딱 맞으며 걷는 사람을 본다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `어떡해.. 우산을 깜빡 했나 보다…`,
          },
          {
            type: 'N',
            score: 5,
            content: `저 사람 혹시 실연당한 건 아닐까? 왠지 표정도 슬퍼 보여..`,
          },
        ],
      },
      {
        which: 'SN',
        question: '어떤 결정을 내린 후 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '한 번 내린 결정에 대해서는 다시 생각하지 않는다',
          },
          {
            type: 'N',
            score: 5,
            content: `내가 내린 결정이 맞는지 생각하면서 고민에 빠진다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '둘 중 내가 선호하는 업무는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `할 일이 구체적으로 딱딱 정해져 있는 업무`,
          },
          {
            type: 'N',
            score: 5,
            content: `방향성만 정해져 있는 자유로운 업무`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 갑자기 술 한잔하자는 말에 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '안주는 뭐 먹을 건데? 나와~',
          },
          {
            type: 'F',
            score: 5,
            content: '갑자기? 무슨 일 있어?',
          },
        ],
      },
      {
        which: 'TF',
        question: '내가 말하는 “생각해 볼게”라는 말의 뜻은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `말 그대로 고민할 시간이 필요해서`,
          },
          {
            type: 'F',
            score: 5,
            content: '약간은 거절의 의미가 담겨 있음',
          },
        ],
      },
      {
        which: 'TF',
        question: `나에게 고민 상담이 들어온다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '최대한 해결책을 제시해 주려고 한다',
          },
          {
            type: 'F',
            score: 5,
            content: '최대한 들어주고 공감하려고 한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '시험공부를 한다고 했는데 친구가 갑자기 놀러 왔다면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `나 시험공부 한다니까 왜 놀러 왔어? 못 놀아 나`,
          },
          {
            type: 'P',
            score: 5,
            content: `어? 무슨 일이야?? (분명 나 시험공부 한다고 했는데..)`,
          },
        ],
      },
      {
        which: 'JP',
        question: '친구에게 “야 뭐해 나와”라는 연락이 왔다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '아 지금은 해야 할 일이 있어서.. 이렇게 갑자기는 어려울 것 같아',
          },
          {
            type: 'P',
            score: 5,
            content: '콜! 좀만 기다려~',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구들과 여름휴가를 가기로 했다면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '시간, 장소, 어디서, 무엇을, 어떻게 할 것인지 바로 세세하게 계획에 들어간다',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 장소만 정하고, 거의 끝났다고 생각한다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/tetrisChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/tetrisChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/tetrisChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/tetrisChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/tetrisChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/tetrisChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/tetrisChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/tetrisChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/tetrisChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/tetrisChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/tetrisChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/tetrisChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/tetrisChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/tetrisChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/tetrisChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/tetrisChar/INFP.jpg',
      },
    ],
  },
  // scrapBookCharJP
  {
    info: {
      mainTitle: 'スクラップブック性格テスト',
      subTitle: '私の性格をスクラップブックに集めてみよう！',
      mainUrl: 'scrapBookCharJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/scrapBookCharJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/scrapBookCharJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '人の多い場所で私が感じることは？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'すごく楽しいじゃん~ エネルギーフル充電中',
          },
          {
            type: 'I',
            score: 5,
            content: '気が滅びる··· エネルギープール消尽中',
          },
        ],
      },
      {
        which: 'EI',
        question: '道で知り合いに会ったら？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'うれしそうにあいさつしてどこへ行くのか聞いてみる',
          },
          {
            type: 'I',
            score: 5,
            content: '一応知らんぷりしてそっと隠れて通り過ぎる',
          },
        ],
      },
      {
        which: 'EI',
        question: '2人のうち、もっと苦しい状況は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `一週間ずっと家からどこにも出られない`,
          },
          {
            type: 'I',
            score: 5,
            content: `一週間ずっと外で夜遅くまで遊ぶ`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'スイカと言えば思い浮かぶのは？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `赤い、おいしそう、最近の旬の果物`,
          },
          {
            type: 'N',
            score: 5,
            content: `スイカバー、涼しい渓谷、夏だね~`,
          },
        ],
      },
      {
        which: 'SN',
        question: '急に海外に行って暮らしたいという友達に私の答えは？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '旅行ならともかく、生きるのは現実的に不可能だよ~',
          },
          {
            type: 'N',
            score: 5,
            content: `私はもし行くならこの国に行って買う！ どんなに幸せだろうか？`,
          },
        ],
      },
      {
        which: 'SN',
        question: '友達と災難映画を見て出てきた。 私はどんな会話をしようかな？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `話にならない内容ではあるが.. それでも面白かった！`,
          },
          {
            type: 'N',
            score: 5,
            content: `もし本当に災害が起きたら、あなたは何からするの？`,
          },
        ],
      },
      {
        which: 'TF',
        question: `後ろで私の悪口を言っているという友達！ 私の反応は？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'うん~私もあの子イマイチ。 でもなんで後ろでやるの？',
          },
          {
            type: 'F',
            score: 5,
            content: '本当に…？どうしてそんなことができるの？（傷…）',
          },
        ],
      },
      {
        which: 'TF',
        question: '私がもっと聞きたい言葉を選ぶとしたら？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `君からいつも勉強になる！ / 何でもうまくできるんだね？`,
          },
          {
            type: 'F',
            score: 5,
            content: 'あなたは私に本当に必要な人だよ/とても大切！',
          },
        ],
      },
      {
        which: 'TF',
        question: `目に何かが入ってとても痛いという友達に私の答えは？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'じゃあ早く抜かないと！ 吹いてあげようか？ すごく痛かったら眼科に行こう',
          },
          {
            type: 'F',
            score: 5,
            content: '痛い？どうしよう、吹いてあげようか？？',
          },
        ],
      },
      {
        which: 'JP',
        question: '品物を探す時、主に自分の姿は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `常に元の場所に置いてあるため、すぐに見つけることができる`,
          },
          {
            type: 'P',
            score: 5,
            content: `適当に投げてどこにあるのか探し続ける`,
          },
        ],
      },
      {
        which: 'JP',
        question: '私に一日の自由時間ができたら？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '計画から練って休む',
          },
          {
            type: 'P',
            score: 5,
            content: '休みながら何をするか考えてみる',
          },
        ],
      },
      {
        which: 'JP',
        question: '私にとって時間とは何だろう？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '効率的に使わなければならない限られた資源',
          },
          {
            type: 'P',
            score: 5,
            content: '弾力的に使うべき新しく変わる資源',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookCharJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookCharJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookCharJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookCharJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookCharJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookCharJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookCharJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookCharJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookCharJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookCharJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookCharJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookCharJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookCharJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookCharJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookCharJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookCharJP/INFP.jpg',
      },
    ],
  },
  // hologramLoveEng
  {
    info: {
      mainTitle: 'Hologram romance test',
      subTitle: `Let's find out my love style with holograms!`,
      mainUrl: 'hologramLoveEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/hologramLoveEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/hologramLoveEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: "I've been dating all day! When you get home?",
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              "It was so much fun! Tomorrow is the day I meet my friends. I'm excited",
          },
          {
            type: 'I',
            score: 5,
            content:
              "I had fun, but I'm so tired that I have to rest tomorrow..",
          },
        ],
      },
      {
        which: 'EI',
        question:
          "If there's something you want to say to your lover, what's the easier way to convey it?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Direct Conversation',
          },
          {
            type: 'I',
            score: 5,
            content:
              'Write down what you want to say in the message and deliver it',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What if you have a full week of appointments with your lovers and friends?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `You'll be a little tired, but it's better than staying home all week!`,
          },
          {
            type: 'I',
            score: 5,
            content: `Just thinking about it makes me want to go home already..`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do you think a lot at the beginning of a relationship?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Don't fight and let's meet for a long time!`,
          },
          {
            type: 'N',
            score: 5,
            content: `We'll be able to see each other for a long time, right? What will we look like in the future?`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What if you saw your lover with a woman (man)?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: "I'll ask you who it is right away",
          },
          {
            type: 'N',
            score: 5,
            content: `Are you cheating on me? Who is it? I'm imagining a lot`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What do you think of when you receive a gift from your lover?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I'm touched that you bought me a present I needed`,
          },
          {
            type: 'N',
            score: 5,
            content: `I'm moved by imagining the process of buying gifts`,
          },
        ],
      },
      {
        which: 'TF',
        question: `My answer to a lover who is stressed out and has no appetite?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "But you have to eat! You'll be hungry later",
          },
          {
            type: 'F',
            score: 5,
            content:
              'Do you have any hard time? Shall we go eat something delicious later?',
          },
        ],
      },
      {
        which: 'TF',
        question: 'How to express my interest in a lover?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I ask a lot of questions about my lover`,
          },
          {
            type: 'F',
            score: 5,
            content: 'Being a reaction master even at small things',
          },
        ],
      },
      {
        which: 'TF',
        question: `A friend's concern that her lover is too slow to contact her! My answer?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "I'm sure you're busy with work or something",
          },
          {
            type: 'F',
            score: 5,
            content: "If you're too slow in contacting me, I'd be sad, too",
          },
        ],
      },
      {
        which: 'JP',
        question: 'What if your date suddenly asks you to meet today?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I have a schedule now, so how about this time at this place later?`,
          },
          {
            type: 'P',
            score: 5,
            content: `Right now? You can watch it. Do you want to come out?`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Two weeks until my anniversary with my lover! What do you think?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: "It's only 2 weeks left? I should get ready quickly",
          },
          {
            type: 'P',
            score: 5,
            content:
              'We have two weeks left. We have to prepare starting next week',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What do you usually look like when you make an appointment with your lover?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Meeting with a schedule in advance',
          },
          {
            type: 'P',
            score: 5,
            content: "Let's meet after deciding what to do roughly",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveEng/INFP.jpg',
      },
    ],
  },
  // hologramLoveJP
  {
    info: {
      mainTitle: 'ホログラム恋愛テスト',
      subTitle: 'ホログラムで私の恋愛スタイルを調べてみよう！',
      mainUrl: 'hologramLoveJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/hologramLoveJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/hologramLoveJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '一日中デートをしてきた！ 家に帰ってきて思った私の考えは？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'とても面白かった！ 明日は友達に会う日~楽しい',
          },
          {
            type: 'I',
            score: 5,
            content: '楽しかったけどバッテリー切れ··· 明日は必ず休まないと..',
          },
        ],
      },
      {
        which: 'EI',
        question: '恋人に言いたいことがあれば伝えるのにもっと楽な方法は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '直接対話を通じて伝達',
          },
          {
            type: 'I',
            score: 5,
            content: 'メッセージに言いたいことを書いて伝達',
          },
        ],
      },
      {
        which: 'EI',
        question: '恋人、友達との約束が一週間ずっと決まっていたら？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `ちょっと疲れるだろうけど、一週間ずっと家にいるよりはましだよ！`,
          },
          {
            type: 'I',
            score: 5,
            content: `考えただけでももう家に帰りたい.. 私にはありえないこと`,
          },
        ],
      },
      {
        which: 'SN',
        question: '恋愛初期に私がよく考えることは？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `喧嘩しないで末永く会おう！`,
          },
          {
            type: 'N',
            score: 5,
            content: `これから長く会えるよね？ 未来に私たちはどんな姿だろうか？`,
          },
        ],
      },
      {
        which: 'SN',
        question: '恋人が私の知らない異性と一緒にいる姿を見たら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'その人は誰かすぐ聞く',
          },
          {
            type: 'N',
            score: 5,
            content: `聞く前に短い瞬間に不倫する想像をつくしてみる`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '恋人からプレゼントをもらったらどんなことを思い浮かべますか？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `必要なプレゼントを買ってくれて感動`,
          },
          {
            type: 'N',
            score: 5,
            content: `プレゼントを買うまでの過程を想像して感動`,
          },
        ],
      },
      {
        which: 'TF',
        question: `ストレスで食欲がないという恋人に私の答えは？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'でもご飯は食べなきゃ！ 後でお腹が空くだろうし',
          },
          {
            type: 'F',
            score: 5,
            content:
              '何か大変なことでもあったのか...後で美味しいもの食べに行こうか？',
          },
        ],
      },
      {
        which: 'TF',
        question: '恋人に自分の気持ちを伝えるには？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `質問が多くなり疑問符キラーになる`,
          },
          {
            type: 'F',
            score: 5,
            content: '些細なことでもリアクション職人になる',
          },
        ],
      },
      {
        which: 'TF',
        question: `友達が恋人の連絡トラブルの悩みを相談する! 私の答えは？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '何か仕事が忙しいとか、事情があるんだろうな～',
          },
          {
            type: 'F',
            score: 5,
            content: '連絡が遅いと、私でも残念です',
          },
        ],
      },
      {
        which: 'JP',
        question: 'もし、恋人が突然「今日会わない？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `今は予定があるので...この時、ここで何時？`,
          },
          {
            type: 'P',
            score: 5,
            content: `今？見ればいいんだよ。出てくる？`,
          },
        ],
      },
      {
        which: 'JP',
        question: '恋人との記念日まであと2週間！ 私の考えは？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '2週間しか残ってないの？ 早く準備しないと',
          },
          {
            type: 'P',
            score: 5,
            content: 'あと2週間だね~来週から準備しないと',
          },
        ],
      },
      {
        which: 'JP',
        question: '恋人との約束を決める時、主に私の姿は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '日程を前もって決めておいて出会い',
          },
          {
            type: 'P',
            score: 5,
            content: '大まかに何をするかだけ決めておいて出会い',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLoveJP/INFP.jpg',
      },
    ],
  },
  // playlist
  {
    info: {
      mainTitle: '성격 플레이 리스트 테스트',
      subTitle: '나에게 어울리는 플레이 리스트는?',
      mainUrl: 'playlist',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/playlist-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/playlist-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '쉬고 있는데 나오라는 친구의 전화를 받았다! 내 대답은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '귀찮지만 내가 빠질 순 없지! 빨리 갈게~',
          },
          {
            type: 'I',
            score: 5,
            content: '귀찮아.. 담에 보자 난 오늘 쉴 거야',
          },
        ],
      },
      {
        which: 'EI',
        question: '내게 어울리는 키워드는 무엇일까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '#밝은 #활기찬 #밖이 좋아',
          },
          {
            type: 'I',
            score: 5,
            content: '#조용한 #신중한 #침대 좋아',
          },
        ],
      },
      {
        which: 'EI',
        question: '몇 시간 동안 수다를 떤 뒤 내 모습은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `아직 모자라! 내 얘기 좀 더 들어줘`,
          },
          {
            type: 'I',
            score: 5,
            content: `이제.. 말 그만하고 싶다.. 피곤.. `,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '비가 많이 내려 호우 경보 알람이 울린다! 난 어떤 생각을 할까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `비가 많이 오나 보네.. 아 근데 알람 그만 좀 `,
          },
          {
            type: 'N',
            score: 5,
            content: `집에 물이 다 새서 바가지로 물 푸고 있는 내가 상상됨`,
          },
        ],
      },
      {
        which: 'SN',
        question: '평소 연락하지 않던 친구가 갑자기 만나자고 한다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '아무 생각 없이 무슨 일 때문인지 물어봄',
          },
          {
            type: 'N',
            score: 5,
            content: `갑자기 왜 만나자는 거지..? 돈 빌리러..? 고민하다 물어봄 `,
          },
        ],
      },
      {
        which: 'SN',
        question: '누군가에게 지시 혹은 부탁을 할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `자세하고 구체적으로 지시`,
          },
          {
            type: 'N',
            score: 5,
            content: `전체적인 느낌과 방향성만 지시`,
          },
        ],
      },
      {
        which: 'TF',
        question: `우산이 없어 내리는 비를 쫄딱 맞고 온 친구에게 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '편의점에서 우산 하나 사지 그랬어! ',
          },
          {
            type: 'F',
            score: 5,
            content: '에고 감기 걸리겠다ㅜ 우산 없었어?',
          },
        ],
      },
      {
        which: 'TF',
        question: '갑자기 읽씹을 당한다면 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `바쁜가.. 나중에 보내겠지 뭐 `,
          },
          {
            type: 'F',
            score: 5,
            content: '뭐지.. 내가 기분 나쁘게 했나..?',
          },
        ],
      },
      {
        which: 'TF',
        question: `대화를 할 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '관심 없는 대화는 들리지도 않음',
          },
          {
            type: 'F',
            score: 5,
            content: '관심 없더라도 리액션 해주고 대답해 줌',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구와 식사 메뉴를 고를 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `(찾아 놓은 리스트를 보며) 여기는 어때? `,
          },
          {
            type: 'P',
            score: 5,
            content: `(길을 돌아다니며) 맛있어 보이는데 여기는 어때?`,
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 세운 계획이 틀어졌다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '불안.. 10초 안에 새로운 계획을 다시 세워야만 해',
          },
          {
            type: 'P',
            score: 5,
            content: '그럴 수 있지 뭐~ 가다가 다른 거 찾아보자!',
          },
        ],
      },
      {
        which: 'JP',
        question: '해야 할 일이 있을 때 나는 어떨까?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '해야 할 일 먼저 끝내고 지금을 즐김',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 지금을 즐겨! 나중에 하면 되지 ',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/playlist/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/playlist/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/playlist/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/playlist/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/playlist/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/playlist/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/playlist/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/playlist/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/playlist/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/playlist/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/playlist/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/playlist/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/playlist/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/playlist/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/playlist/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/playlist/INFP.jpg',
      },
    ],
  },
  // alienCharJP
  {
    info: {
      mainTitle: 'エイリアン性格テスト',
      subTitle: 'MBTIで調べてみる私の宇宙人キャラクターは？',
      mainUrl: 'alienCharJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/alienCharJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/alienCharJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '友達と過ごした後、普段はどんな気分ですか？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '私はいつも疲れていますが、友達に会うとすっきりします。',
          },
          {
            type: 'I',
            score: 5,
            content: '友達と楽しく遊んだらとても疲れます',
          },
        ],
      },
      {
        which: 'EI',
        question: '友人が突然あなたの家を訪ねてきたらどうしますか？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'どうしたんですか？ どうぞお入りください。',
          },
          {
            type: 'I',
            score: 5,
            content: 'どうしたんですか？ 私は服を持って外に出て話をします。',
          },
        ],
      },
      {
        which: 'EI',
        question: '週末には主に何をしますか？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `外に出て、友達と展示会に行ったり、レストランに行ったり、それからカフェに行ってカフェインを摂取したりするんです！`,
          },
          {
            type: 'I',
            score: 5,
            content: `家で料理もして掃除もして、植木鉢の水もあげて、ベーキングもして、仕上げは映画で！`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'Tシャツを買いに行くときはどうしますか？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `何を買えばいいですか？ どれもデザインが似ているので、近くにあるものにします。`,
          },
          {
            type: 'N',
            score: 5,
            content: `わぁ、すごくきれいだね！ これらの色とロゴはヒップホップ戦士を思い出させます！`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'お店からいい匂いがするときはどうしますか？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '香りいいですね。 一度、入ってみましょうか？',
          },
          {
            type: 'N',
            score: 5,
            content: `ロマンス漫画の匂いがする！ 主人公のような気がします！`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'バスに座って窓の外を見たらどうするんですか？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `天気が本当にいいと思うので、いつ届くのか気になります。`,
          },
          {
            type: 'N',
            score: 5,
            content: `バスの運転手さんは、どのように体調を維持しているのか、トイレにはどうやって行くのか気になります。`,
          },
        ],
      },
      {
        which: 'TF',
        question: `友達が急に「好き」って言ったらどうする？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '私を？なんで？(なんでだろう?)',
          },
          {
            type: 'F',
            score: 5,
            content: 'どうしよう…(感動)',
          },
        ],
      },
      {
        which: 'TF',
        question: 'お友達に”お腹が痛いみたい”と言われたらどうしますか？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `何を食べましたか？ 何か食べ間違えたんですか？`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '大丈夫ですか？ なぜ病気なのですか？ 下痢はしていますか？ お腹をこわしましたか？',
          },
        ],
      },
      {
        which: 'TF',
        question: `私、3ヶ月の給料を貯めてマックブック買ったよ！！と言った時？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'おぉ！いいですね！ どのモデルを買ったのですか？',
          },
          {
            type: 'F',
            score: 5,
            content:
              'あ、本当ですか？！ 3ヶ月間保存していたら本当に大変でした。 本当にお疲れ様でした!!',
          },
        ],
      },
      {
        which: 'JP',
        question: '週末にどこへ行くかどうやって決めますか？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `いつ食べるか、次はどこに行くかなど、細かい計画を立てています。`,
          },
          {
            type: 'P',
            score: 5,
            content: `私は友達に会ってから何をするか決めます。`,
          },
        ],
      },
      {
        which: 'JP',
        question: '突然旅行に行こうと言われて、どんな気持ちになりますか？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '急でも頭の中で大まかな計画ができるように、せめて計画が必要です。',
          },
          {
            type: 'P',
            score: 5,
            content:
              '急に行くのは不安ですが、どこに行くか考えるとすぐワクワクします。',
          },
        ],
      },
      {
        which: 'JP',
        question: 'もし友達が”今日は何をするの？”と聞いたら？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '今日の4時にコインランドリーに行って洗濯して掃除をして、後で夜7時にチキンを包んでくる予定だよ。',
          },
          {
            type: 'P',
            score: 5,
            content:
              '洗濯して部屋の掃除が終わったら何しようかな？ 何か食べたいんだけど、とりあえず···.. 休んで考えてみる。',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharJP/INFP.jpg',
      },
    ],
  },
  // alienCharEng
  {
    info: {
      mainTitle: 'Alien personality test',
      subTitle: 'Which alien character do I recognize with mv MBTI?',
      mainUrl: 'alienCharEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/alienCharEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/alienCharEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'How do you usually feel after spending time with friends?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'im always tired, but when I see my friends, I get refreshed.',
          },
          {
            type: 'I',
            score: 5,
            content: 'I get very tired after having fun with my friends',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What if your friend suddenly visit your house?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'What’s going on? Please come in.',
          },
          {
            type: 'I',
            score: 5,
            content: 'What is happening? I grab my clothes and go out to talk.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What do you usually do on weekend?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I go out, go to exhibitions with friends, go to restaurants, and then I go to a cafe to get some caffeine!`,
          },
          {
            type: 'I',
            score: 5,
            content: `I cook at home, water the pots, bake and watch a movie at the end!`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do you do when you go to buy a t-shirt?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `What should I buy? They all look similar in design, ok I'll just take the one that's near me.`,
          },
          {
            type: 'N',
            score: 5,
            content: `Wow it looks so pretty! These colors and logos remind me of hip hop warriors!`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'お店からいい匂いがするときはどうしますか？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I like the scent~ Shall we go in and try it?',
          },
          {
            type: 'N',
            score: 5,
            content: `It smells like something out of a romance cartoon! I feel like the main character!`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What if you sit on the bus and look out the window?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I think the weather is really nice and wonder when it will arrive.`,
          },
          {
            type: 'N',
            score: 5,
            content: `I wonder how the bus driver maintains his condition and how he goes to the bathroom.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What if your friend suddenly says “I just like you”?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Me? Why? (I think for some reason)',
          },
          {
            type: 'F',
            score: 5,
            content: "Oh my ... (I'm impressed)",
          },
        ],
      },
      {
        which: 'TF',
        question: 'What if your friend says “I think I have a stomach ache”?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `What did you eat? Did you eat something wrong?`,
          },
          {
            type: 'F',
            score: 5,
            content:
              'Are you okay? Why are you sick? Do you have diarrhea? Did you get an upset stomach?',
          },
        ],
      },
      {
        which: 'TF',
        question: `What if your friend saved up three months’ salary and bought a Macbook?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Oh! That is great! Which model did you buy?',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Oh really?! It would have been really difficult if I had saved it for three months. You worked hard!!',
          },
        ],
      },
      {
        which: 'JP',
        question: 'How do you decide where to go on the weekend?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I make detailed plans, such as when to eat and where to go next.`,
          },
          {
            type: 'P',
            score: 5,
            content: `I decide what to do after I meet my friends.`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'How do you feel when you are suddenly offered to go on a trip?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "I need at least a plan so that I can have a rough plan in my head, even if it's sudden.",
          },
          {
            type: 'P',
            score: 5,
            content:
              "I'm worried about going suddenly, but I'm quickly filled with excitement thinking about where I'm going.",
          },
        ],
      },
      {
        which: 'JP',
        question: 'What if your friend asked, “What are you doing today?”',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "I'm going to the laundromat at 4 o'clock today to wash and clean. And I'm going to take out fried chicken later and come back at 7pm.",
          },
          {
            type: 'P',
            score: 5,
            content:
              'What do I do after I do the laundry and clean my room? I want to eat something, but first ... I want to rest and think about it.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/alienCharEng/INFP.jpg',
      },
    ],
  },
  // alienChar
  {
    info: {
      mainTitle: '외계인 성격 테스트',
      subTitle: 'MBT로 알아보는 나의 외계인 캐릭터는?',
      mainUrl: 'alienChar',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/alienChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/alienChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '친구와 시간을 보내고 온 후의 나의 모습은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '맨날 피곤하지만 친구만 보면 피로가 싹 사라진다.',
          },
          {
            type: 'I',
            score: 5,
            content: '잘 놀다 들어왔지만 침대에 바로 앓아눕기 시작한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '집에 있다가 갑자기 친구가 연락도 없이 왔다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '무슨 일이냐며 일단 들어오라고 한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '무슨 일이냐며 은근 슬쩍 겉옷 챙긴 후 나가서 이야기한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '황금 같은 주말에 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `나가서 친구들이랑 전시회도 가고, 맛집도 가고, 마무리는 카페 가서 카페인으로!`,
          },
          {
            type: 'I',
            score: 5,
            content: `집에서 요리도 하고, 청소도 하고, 화분 물도 주고, 베이킹도 하고 마무리는 영화로!`,
          },
        ],
      },
      {
        which: 'SN',
        question: '티셔츠를 사러 갔을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `뭐 사지? 디자인 비슷하니 그냥 제일 가까운 거 짚어야겠다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `우와 너무 예쁘다! 색감이랑 여기 로고가 힙합 전사가 생각나는 디자인이야!`,
          },
        ],
      },
      {
        which: 'SN',
        question: '지나가다 매장에서 좋은 향이 날 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '향기 좋네~ 들어가서 제대로 시향이나 해볼까?',
          },
          {
            type: 'N',
            score: 5,
            content: `순정만화에 나올 것 같은 그런 향이야!!! 마치 주인공이 된 것만 같아!`,
          },
        ],
      },
      {
        which: 'SN',
        question: '버스에 앉아 창가를 바라볼 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `날씨가 좋다고 생각하며 언제쯤 도착하는지 생각한다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `기사분은 컨디션 조절은 어떻게 하는 건지, 화장실은 어떻게 가시는지 생각한다.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 갑자기 “난 네가 그냥 좋아”라고 했을 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '나를? 왜? (어떤 이유에서 인지라는 생각이 든다.)',
          },
          {
            type: 'F',
            score: 5,
            content: '어머.. (감동받는다.)',
          },
        ],
      },
      {
        which: 'TF',
        question: '나 배가 아픈 거 같아라고 했을 때?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `뭐 먹었는데? 뭐 잘못 먹었어?`,
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮아? 왜 아프지? 화장실배야? 배탈난거야?ㅠㅠ',
          },
        ],
      },
      {
        which: 'TF',
        question: `나 세 달 월급 모아서 맥북 샀어!!라고 했을 때?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '오! 대박! 어떤 모델 산 거야?',
          },
          {
            type: 'F',
            score: 5,
            content:
              '아 진짜?! 세 달 모은 거면 진짜 힘들었겠다ㅠㅠ 고생 많았어!!',
          },
        ],
      },
      {
        which: 'JP',
        question: '주말에 놀러 갈 곳을 정할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `몇 시까지 만나서 몇 시에 밥을 먹고 다음 일정까지 생각해 놓는다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `뭐 할지는 일단 만나서 정한다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '갑자기 여행 가자고 제안했을 때?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '즉흥 속에 계획이 다 있어야 해서 어디 가자~ 듣는 순간 머리에서 대충의 계획이라도 짜기 시작한다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '갑자기 가는 것에 대해서 걱정은 되지만 어디 갈지 생각에 금세 설렘으로 가득 차 있다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구가 “너 오늘 뭐 해?”라고 물었을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '오늘 4시에 코인 빨래방 가서 빨래하고 청소 좀 하고 이따 저녁 7시에 치킨 포장해서 올 예정이야.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '빨래하고 방 청소 끝나고 뭐 하지? 뭔가 먹고 싶긴 한데 일단.. 쉬고 생각해 볼래.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/alienChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/alienChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/alienChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/alienChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/alienChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/alienChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/alienChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/alienChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/alienChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/alienChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/alienChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/alienChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/alienChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/alienChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/alienChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/alienChar/INFP.jpg',
      },
    ],
  },
  // loveTVEng
  {
    info: {
      mainTitle: 'Love TV Test',
      subTitle: 'What is your relationship tendency on TV?',
      mainUrl: 'loveTVEng',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/loveTVEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveTVEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: 'When you have someone you like, what do you do?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              "I don't hesitate to send text message and try to make an appointment quickly to meet",
          },
          {
            type: 'I',
            score: 5,
            content:
              'I try to hang around, find a way to contact and slowly get to know the person',
          },
        ],
      },
      {
        which: 'EI',
        question: "What if you don't have any appointment on the weekend?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I contact my friends to make an appointment.',
          },
          {
            type: 'I',
            score: 5,
            content: 'I just relax and heal at home.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'If you have to decide where to go on a trip with your lover, what would be your choice?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Even if it is crowded, I definitely want to visit famous and hot places`,
          },
          {
            type: 'I',
            score: 5,
            content: `I prefer a nice and quiet healing place even if it is not famous`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'Just before starting a relationship, how do you do?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I feel excited and happy.`,
          },
          {
            type: 'N',
            score: 5,
            content: `I imagine our relationship in advance.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What if the person you care about suddenly responds slowly to you?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Maybe too busy? Well, it can be~',
          },
          {
            type: 'N',
            score: 5,
            content: `Perhaps yesterday date wasn’t that good? Am I dumped? Haha`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'If you were to receive a surprise gift from your lover, what gift would you like to receive?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Even if the gift lacks meaning, I want to receive a practical gift that I need these days`,
          },
          {
            type: 'N',
            score: 5,
            content: `I want to receive a pretty gift with sincerity even if it is less practical`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What do you do when you have someone you like?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I ask a lot of questions',
          },
          {
            type: 'F',
            score: 5,
            content: 'I react a lot',
          },
        ],
      },
      {
        which: 'TF',
        question: 'What if you are late for an appointment with your lover?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I explain the specific circumstances and reasons for being late`,
          },
          {
            type: 'F',
            score: 5,
            content: 'I apologize first and find out how my lover feels',
          },
        ],
      },
      {
        which: 'TF',
        question: `What if you plan a date and get into an argument with your lover?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I elaborate on why my idea is better',
          },
          {
            type: 'F',
            score: 5,
            content: "I have a lot to say, but I'm disappointed",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What if your lover suddenly asks to meet you while you are enjoying your alone time?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Anyway, it's hard to meet suddenly`,
          },
          {
            type: 'P',
            score: 5,
            content: `It's embarrassing, but I'm going to meet my lover`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'How do you prepare for your anniversary with your lover?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I prepare months or weeks in advance',
          },
          {
            type: 'P',
            score: 5,
            content: 'I think one week is enough to prepare',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What if you are traveling with your lover?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I organize the travel plan in 3 sheets of A4 paper and hands it over to my lover',
          },
          {
            type: 'P',
            score: 5,
            content:
              'I just pick a place and then we can decide where we want to be',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/loveTVEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/loveTVEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/loveTVEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/loveTVEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/loveTVEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/loveTVEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/loveTVEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/loveTVEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/loveTVEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/loveTVEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/loveTVEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/loveTVEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/loveTVEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/loveTVEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/loveTVEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/loveTVEng/INFP.jpg',
      },
    ],
  },
  // loveTV
  {
    info: {
      mainTitle: '연애 TV 테스트',
      subTitle: 'TV로 알아보는 나의 연애 성향은?',
      mainUrl: 'loveTV',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/loveTV-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/loveTV-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '좋아하는 사람이 생겼을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '망설임 없이 선 톡을 하고, 빠르게 약속을 잡아 만나려고 한다',
          },
          {
            type: 'I',
            score: 5,
            content: '주변을 맴돌며 연락할 방법을 찾고, 천천히 알아가려고 한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '주말에 데이트가 없다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구들에게 연락을 돌려서 약속을 잡는다',
          },
          {
            type: 'I',
            score: 5,
            content: '집에서 푹 쉬면서 힐링 한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '연인과 여행 중 코스를 고른다면, 내 선택은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `사람은 많지만, 유명하고, 핫 한 필수 관광 코스`,
          },
          {
            type: 'I',
            score: 5,
            content: `조용하고 유명하지 않지만, 힐링 할 수 있는 관광 코스`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연애를 시작하기 전 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `설레고, 기쁘다`,
          },
          {
            type: 'N',
            score: 5,
            content: `미리 우리 연애를 그려보고, 상상해 본다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '호감가는 상대와 첫 만남 이후 상대의 답장이 느려진다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '오늘 많이 바쁜가? 뭐, 그럴 수 있지~',
          },
          {
            type: 'N',
            score: 5,
            content: `혹시 어제 데이트가 별로였나? 설마 나 까이는 거?ㅠㅠ`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인에게 깜짝 선물을 받는다면 내 선택은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `의미는 조금 없어도, 요즘 나에게 필요했던 실용적인 선물`,
          },
          {
            type: 'N',
            score: 5,
            content: `실용성은 떨어져도, 예쁘고, 정성이 담긴 선물`,
          },
        ],
      },
      {
        which: 'TF',
        question: `나는 좋아하는 사람에게?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '질문을 많이 한다',
          },
          {
            type: 'F',
            score: 5,
            content: '리액션을 많이 한다',
          },
        ],
      },
      {
        which: 'TF',
        question: '연인과의 약속에 늦었다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `늦은 구체적인 상황과 이유를 설명한다`,
          },
          {
            type: 'F',
            score: 5,
            content: '일단 사과부터 하고, 연인의 기분을 파악한다',
          },
        ],
      },
      {
        which: 'TF',
        question: `연인과 함께 데이트 계획 중 의견 충돌로 다툼이 생긴다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '내 의견이 더 나은 이유를 자세히 설명한다',
          },
          {
            type: 'F',
            score: 5,
            content: '나도 할 많은 많지만, 서운하다',
          },
        ],
      },
      {
        which: 'JP',
        question: '혼자만의 시간을 즐기던 중, 연인이 갑자기 만나자고 한다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `아무리 연인이라도 갑작스러운 만남은 어렵다`,
          },
          {
            type: 'P',
            score: 5,
            content: `갑작스러운 만남이 부담스럽긴 하지만, 나간다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과의 기념일을 준비할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '몇 달 혹은 몇 주 전부터 미리 준비한다',
          },
          {
            type: 'P',
            score: 5,
            content: '1주일만 있어도 충분하다고 생각한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 여행을 간다면 나는 어떤 연인에 가까울까?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '여행 계획만 A4용지로 3장씩 정리해서 건네는 연인',
          },
          {
            type: 'P',
            score: 5,
            content: '장소만 정하고 끌리는 대로 가자는 연인',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/loveTV/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/loveTV/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/loveTV/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/loveTV/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/loveTV/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/loveTV/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/loveTV/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/loveTV/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/loveTV/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/loveTV/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/loveTV/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/loveTV/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/loveTV/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/loveTV/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/loveTV/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/loveTV/INFP.jpg',
      },
    ],
  },
  // loveStyle
  {
    info: {
      mainTitle: '연애 스타일 스티커 테스트',
      subTitle: '스티커로 알아보는 나의 연애 스타일!',
      mainUrl: 'loveStyle',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/loveStyle-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveStyle-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '연애 시 나의 스타일과 가까운 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '애인보다 말을 더 많이 하고, 적극적으로 표현하는 스타일',
          },
          {
            type: 'I',
            score: 5,
            content:
              '애인의 말을 잘 들어주고, 눈빛과 표정으로 애정을 티 내는 스타일',
          },
        ],
      },
      {
        which: 'EI',
        question: '데이트 중 애인에게 불만이 생겼다면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '그 자리에서 바로 이야기한다',
          },
          {
            type: 'I',
            score: 5,
            content: '고민하다가, 타이밍을 봐서 이야기한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '데이트 중 애인의 부모님을 마주쳤다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `인사 후 자연스럽게 대화에 참여한다`,
          },
          {
            type: 'I',
            score: 5,
            content: `인사 후 왠지 모르게 뚝딱거리게 된다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '애인과 점심 메뉴를 고르면서 하는 내 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `어떤 메뉴를 시켜야 맛있게 먹을까?`,
          },
          {
            type: 'N',
            score: 5,
            content: `이따 저녁은 뭐 먹지? 다 먹고 카페 가서 디저트도 먹어야 하는데..ㅎ`,
          },
        ],
      },
      {
        which: 'SN',
        question: '애인에게 직접 요리를 해주려고 할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '검색해서 나온 레시피 그대로 음식을 만든다',
          },
          {
            type: 'N',
            score: 5,
            content: `눈대중으로 마음을 담아 정성스럽게 만들어 준다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 원하는 연애는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `현재에 충실한 연애`,
          },
          {
            type: 'N',
            score: 5,
            content: `미래를 함께 꿈꾸는 연애 `,
          },
        ],
      },
      {
        which: 'TF',
        question: `애인이 스트레스받는다며 “맛있는 것 먹으러 가자”라고 한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '바로 가자! 뭐 먹고 싶은 것 있어?',
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮아?ㅠ 무슨 일 있어?',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '친구가 “나 조만간 애인 생길 것 같아”라고 말한다면 내 대답은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `오~ 어떤 사람인데? 언제부터 만났어?`,
          },
          {
            type: 'F',
            score: 5,
            content: '헐~ 대박 잘됐다! 어쩐지 요즘 기분 좋아 보이더라~',
          },
        ],
      },
      {
        which: 'TF',
        question: `애인이 “나 지금 몸이 너무 아파”라고 한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '병원 가봐야 하는 거 아니야? 괜찮아?',
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮아? 약 사다 줄까? ㅠ',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 놀이동산에 놀러 왔다. 내 스타일과 가까운 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `롤러코스터, 바이킹, 자이로드롭 순으로 타면 딱 맞겠어!`,
          },
          {
            type: 'P',
            score: 5,
            content: `뭐 부터 탈까? 일단 롤러코스터부터 타고 생각하자!`,
          },
        ],
      },
      {
        which: 'JP',
        question: '나의 데이트 스타일은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '(빼곡하게 데이트 코스가 정리된 메모장을 보여주며) 나만 믿고 따라와!',
          },
          {
            type: 'P',
            score: 5,
            content:
              '(방금 검색한 맛집을 보여주며) 오~ 여기 괜찮은데? 일단 여기 가보자!',
          },
        ],
      },
      {
        which: 'JP',
        question: '애인과 해외 여행지 숙소에 도착했을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '피곤하지만, 짐부터 빠르게 정리하고, 일정대로 바로 움직인다',
          },
          {
            type: 'P',
            score: 5,
            content: '피곤해서 잠깐 쉬고, 짐 정리를 한다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/loveStyle/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/loveStyle/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/loveStyle/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/loveStyle/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/loveStyle/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/loveStyle/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/loveStyle/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/loveStyle/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/loveStyle/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/loveStyle/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/loveStyle/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/loveStyle/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/loveStyle/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/loveStyle/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/loveStyle/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/loveStyle/INFP.jpg',
      },
    ],
  },
  // rainDropJP
  {
    info: {
      mainTitle: '雨滴性格テスト',
      subTitle: '落ちる雨粒で見抜く私の性格は？',
      mainUrl: 'rainDropJP',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/rainDropJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/rainDropJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '梅雨の始まりで雨が多い週末、友達と事前に約束がある場合は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '雨が多いな、残念だけど、室内で遊ぼう',
          },
          {
            type: 'I',
            score: 5,
            content:
              '雨が多いな...もしかして後で会おうという\n約束のキャンセル連絡が来ないかな？',
          },
        ],
      },
      {
        which: 'EI',
        question: '私の好みのコミュニケーション方法は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '直接会って言葉でコミュニケーションすること',
          },
          {
            type: 'I',
            score: 5,
            content: 'メールやトークなど文章でコミュニケーションすること',
          },
        ],
      },
      {
        which: 'EI',
        question: 'ついにやってきた週末！私の選択は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `外で友達と会って楽しく遊ぶ`,
          },
          {
            type: 'I',
            score: 5,
            content: `自宅で癒されながら、ゆっくり休む`,
          },
        ],
      },
      {
        which: 'SN',
        question: '雨の多い梅雨時の退勤道、私の考えは？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `めっちゃ雨降ってるね...早く行かないと!`,
          },
          {
            type: 'N',
            score: 5,
            content: `もしかして、途中で落雷に見舞われたりしないよね？`,
          },
        ],
      },
      {
        which: 'SN',
        question: '家に寝ていて、外で突然雨が降ってきたらどう思う？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '急に雨が降ってきた。今日のような日に予定がなくてよかった',
          },
          {
            type: 'N',
            score: 5,
            content: `突然聞こえてくる雨音に、パジョン食べる想像が自然と浮かんできた`,
          },
        ],
      },
      {
        which: 'SN',
        question: '配達を頼んだら、予想時間より遅かったら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `どうしたのか気になって電話する`,
          },
          {
            type: 'N',
            score: 5,
            content: `もしかして配達員さんに何かあったのではと心配になる`,
          },
        ],
      },
      {
        which: 'TF',
        question: `恋人が雨の道で転んだと連絡が来たら私は？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'えっ、大丈夫か？ 何してて転んだの？',
          },
          {
            type: 'F',
            score: 5,
            content: 'はっ、大丈夫？ どこか怪我はないの？',
          },
        ],
      },
      {
        which: 'TF',
        question: '友人が本音を打ち明けたとき、私は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `友達の話を最後まで聞いて、アドバイスしてくれる`,
          },
          {
            type: 'F',
            score: 5,
            content: '友達の言葉に感情移入し、リアクションをとる',
          },
        ],
      },
      {
        which: 'TF',
        question: `友達が道で誰かと喧嘩している、聞いてみると友達の不手際だった時、私は？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '素直に友達のダメなところをしっかり教えてくれる',
          },
          {
            type: 'F',
            score: 5,
            content:
              '時間を置いて状況が安定した後、友人に間違ったことを知らせる',
          },
        ],
      },
      {
        which: 'JP',
        question: '梅雨の雨の日、外でやるべきことがあったら私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `雨がたくさん降っても.. 今日やることにしたことだからやらないと！`,
          },
          {
            type: 'P',
            score: 5,
            content: `雨がたくさん降ってますが.. 後で天気がよくなったらやらないと`,
          },
        ],
      },
      {
        which: 'JP',
        question: '友達が急に集まろうとしたら、私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'こんな急に会うのは難しいと言う',
          },
          {
            type: 'P',
            score: 5,
            content: '何をするか聞いて、引かれたら出る',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '道を歩いていて急に夕立が降る、目の前に傘が見える状況、私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'こんな急な夕立に傘を買うわけにはいかない！！ ちょっと避けてから行かないと！',
          },
          {
            type: 'P',
            score: 5,
            content: '雨に降られるよりましだよ！ 傘をすぐに買う',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/rainDropJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/rainDropJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/rainDropJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/rainDropJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/rainDropJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/rainDropJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/rainDropJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/rainDropJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/rainDropJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/rainDropJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/rainDropJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/rainDropJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/rainDropJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/rainDropJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/rainDropJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/rainDropJP/INFP.jpg',
      },
    ],
  },
  // rainDropEng
  {
    info: {
      mainTitle: 'Raindrop personality Test',
      subTitle: 'What is my personality as revealed by falling raindrops?',
      mainUrl: 'rainDropEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/rainDropEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/rainDropEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'What if you have an appointment in a rainy weekend?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'It rains a lot. Sorry but I think we should play indoors',
          },
          {
            type: 'I',
            score: 5,
            content: 'It rains a lot.. Perhaps, it will be cancelled?',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What kind of communication method do you prefer?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I like meeting in person to communicate',
          },
          {
            type: 'I',
            score: 5,
            content: 'I prefer text message to communicate',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What do you want to do on weekend?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I would like to go out with my friends to have fun`,
          },
          {
            type: 'I',
            score: 5,
            content: `I want to heal and rest at home`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What do you think on the way home from work during the rainy season?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `It's raining a lot... I have to go quickly!`,
          },
          {
            type: 'N',
            score: 5,
            content: `Could it be that I was struck by lightning?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'If it suddenly rains outside while you are lying at home, what do you think?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              "It is raining suddenly…. I'm glad I didn't make an appointment on a day like today",
          },
          {
            type: 'N',
            score: 5,
            content: `I just imagining easting Korean pan cake at the sudden sound of rain The sudden sound of rain makes me think of pizza. 
                        `,
          },
        ],
      },
      {
        which: 'SN',
        question: "What if the delivery food doesn't arrive?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I will call and ask what is happening as I am curious.`,
          },
          {
            type: 'N',
            score: 5,
            content: `I'm just worried that something happened to the delivery driver.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `If you get a call that your lover fell on the road in the rain, what would you do?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Hey are you okay? What did you do when you fell?!',
          },
          {
            type: 'F',
            score: 5,
            content: 'Hey are you okay ? Did you get hurt?',
          },
        ],
      },
      {
        which: 'TF',
        question: 'When your friend tells you a deep story, how do you feel?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I will listen to my friend and give them advice`,
          },
          {
            type: 'F',
            score: 5,
            content: "I will sympathize with and respond to my friend's words.",
          },
        ],
      },
      {
        which: 'TF',
        question: `What if your friend gets into a fight with someone and it turns out to be your friend's fault?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Honestly, I would talk to my friend about it clearly',
          },
          {
            type: 'F',
            score: 5,
            content:
              'After the situation is over, I would explain the fact that it is my friend’s fault',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'On a rainy day, If you have something to do outside, what would you do?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Even though it rains a lot. I have to do it because that's what I was supposed to do today!`,
          },
          {
            type: 'P',
            score: 5,
            content: `It's raining too much.. I'll just do it later when the weather gets better`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What would you do if your friends suddenly asked you to get together?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: "It's hard for me to meet so suddenly",
          },
          {
            type: 'P',
            score: 5,
            content:
              "I would ask what's going on first, and if I like it, I would go out",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What would you do if you saw an umbrella store in front of you on a rainy day?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "I can't buy an umbrella this way! I'll find a place to shelter from the rain and leave later!",
          },
          {
            type: 'P',
            score: 5,
            content:
              "It's better than getting rained on, so I would buy an umbrella.",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/rainDropEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/rainDropEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/rainDropEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/rainDropEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/rainDropEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/rainDropEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/rainDropEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/rainDropEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/rainDropEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/rainDropEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/rainDropEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/rainDropEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/rainDropEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/rainDropEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/rainDropEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/rainDropEng/INFP.jpg',
      },
    ],
  },
  // charRoad
  {
    info: {
      mainTitle: '성격 로드 테스트',
      subTitle: '길로 알아보는 나의 성격은?',
      mainUrl: 'charRoad',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/charRoad-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/charRoad-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '내가 선호하는 인간관계는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '얕지만, 많은 사람과 친해지기',
          },
          {
            type: 'I',
            score: 5,
            content: '소수의 사람과 깊이 친해지기',
          },
        ],
      },
      {
        which: 'EI',
        question: '나는 처음 보는 사람들 앞에서?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '처음에 낯을 가리지만, 금세 거침없이 대화를 이끌어 나간다',
          },
          {
            type: 'I',
            score: 5,
            content: '낯을 많이 가리고, 다른 사람의 말을 경청한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구와 걷다가 길에서 우연히 친구의 지인을 만났을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `먼저 인사하고, 자연스럽게 대화에 참여한다`,
          },
          {
            type: 'I',
            score: 5,
            content: `친구가 대화가 끝날 때까지 옆에서 가만히 기다린다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연락 중 친구가 읽씹을 했다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `그냥 그런가 보다 한다`,
          },
          {
            type: 'N',
            score: 5,
            content: `혹시 내가 무슨 말실수했나? 하며 대화를 거슬러 올라가 본다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '안 좋은 꿈을 꿔 식은 땀을 흘리며 일어났다. 나의 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '휴 꿈이네… 현실인 줄..',
          },
          {
            type: 'N',
            score: 5,
            content: `무슨 꿈이 이래? 혹시 안 좋은 일 일어나는 건 아니겠지?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '과거로 돌아가면 뭘 하고 싶냐는 친구의 말에 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `의미 없다~ 그런 일은 일어나지 않아`,
          },
          {
            type: 'N',
            score: 5,
            content: `당연히 로또 번호 잔뜩 외워가야 하지 않겠어?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `상대방과 큰 갈등이 생겼을 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '최대한 이성적이고, 논리적으로 말한다',
          },
          {
            type: 'F',
            score: 5,
            content:
              '나도 할 말은 많은데, 정리가 잘 안 되어 눈물부터 나올 것 같다',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '오랫동안 취업을 준비하던 친구가 원하던 회사에 취업에 성공했다면 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `축하해! 취업한 소감이 어때? 어떤 회사야?`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '축하해! 오랫동안 준비했는데, 열심히 노력한 보람이 있겠다!',
          },
        ],
      },
      {
        which: 'TF',
        question: `상대방과 대화할 때 내가 더 신경 쓰는 것은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '대화 주제와 팩트',
          },
          {
            type: 'F',
            score: 5,
            content: '말투와 표정',
          },
        ],
      },
      {
        which: 'JP',
        question: '집 근처에 내 최애 음식을 하는 식당이 새로 생겼다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `오 내 최애 음식! 일정에 맞춰서 미리 예약해 둬야겠다!`,
          },
          {
            type: 'P',
            score: 5,
            content: `오 내 최애 음식! 조만간 한번 가봐야지~`,
          },
        ],
      },
      {
        which: 'JP',
        question: '친구들과 약속이 있는 날 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '약속시간 몇 시간 전부터 미리 준비를 끝내고도 시간이 좀 남는다',
          },
          {
            type: 'P',
            score: 5,
            content: '약속시간 몇 분 전부터 급하게 준비한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 더 싫은 상황은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '내가 세운 계획이 틀어진 상황',
          },
          {
            type: 'P',
            score: 5,
            content: '너무 지루한 공간에 오랫동안 있어야 하는 상황',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/charRoad/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/charRoad/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/charRoad/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/charRoad/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/charRoad/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/charRoad/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/charRoad/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/charRoad/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/charRoad/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/charRoad/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/charRoad/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/charRoad/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/charRoad/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/charRoad/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/charRoad/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/charRoad/INFP.jpg',
      },
    ],
  },
  // whyLove
  {
    info: {
      mainTitle: '나를 사랑하는 11가지 이유 테스트',
      subTitle: '나를 사랑하는 이유가 궁금하다면?',
      mainUrl: 'whyLove',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/whyLove-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/whyLove-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '주말에 나의 모습은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '주중에 일만 했으니 주말엔 나가서 힐링 해야 한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '주중에 일만 했으니 주말엔 집에 있어야 한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구가 졸업작품에 내야 하는 그림을 망쳤다고 한다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '놀면서 잊는 거라며 기분 전환할 겸 친구들 불러서 놀러 가자고 한다.',
          },
          {
            type: 'I',
            score: 5,
            content:
              '괜찮냐고 물어보며 다음에 카페 가서 같이 그림공부하자고 제안한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구가 집 근처라 잠깐 나오라고 했을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `잠깐 만나 시간을 보낸 후 집 근처 마트라도 들렸다가 온다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `잠깐 만나 시간을 보낸 후 집으로 바로 돌아와 나만의 휴식을 취한다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '라면 끓일 때 나의 모습은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `인터넷에서 본 라면 맛있게 만드는 법 레시피를 검색해서 정량으로 만든다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `자주 먹어왔던 것이기에 감으로 만든다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '길을 가다가 공사현장을 발견했을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '통행에 불편하고 시끄러우니 빨리 끝났으면 좋겠다.',
          },
          {
            type: 'N',
            score: 5,
            content: `철근이 갑자기 떨어질까 봐 빨리 지나간다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '우산 꽂이에 우산이 많이 꽂힌 것을 본 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `사람들이 많이 왔구나라고 생각한다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `비슷한 디자인이 많아 잘못 들고 가면 어쩌지라고 생각한다.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `내가 만든 제품을 홍보할 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '기존 제품보다 성능 향상 비교를 보여주며 숫자의 신뢰성을 강조한다.',
          },
          {
            type: 'F',
            score: 5,
            content:
              '감성 자극 스토리텔링과 디자인은 깔끔하지만 예쁘게 만든다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 무거운 냄비를 들고 있을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `그거 쇠야?라고 쇠라서 무거운 것인지 궁금해서 물어본다.`,
          },
          {
            type: 'F',
            score: 5,
            content: '무겁지?라고 물어보며 걱정부터 한다.',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 기분이 안 좋아서 산책하러 나왔다고 한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '갑자기 산책을? 어디로 산책 가는지 목적지를 물어본다.',
          },
          {
            type: 'F',
            score: 5,
            content: '오늘 무슨 일 있었냐고 기분이 왜 안 좋냐고 물어본다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '프로젝트 성과가 좋지 않았을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `나의 계획서에 무슨 문제가 있었던 건지 생각해 본다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `사실 처음부터 좀 별로였던 것 같기도 하다고 생각한다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '기획서 작성 시 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '최대한 기획서에 맞추어서 작업하기',
          },
          {
            type: 'P',
            score: 5,
            content: '해보면서 수정해 나가기',
          },
        ],
      },
      {
        which: 'JP',
        question: '잠시 후 비행기가 이륙한다는 안내방송이 들릴 때?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '미리 다 일정을 세워 두었으니 걱정 말고 나만 믿고 따라와!',
          },
          {
            type: 'P',
            score: 5,
            content:
              '너무 기대된다! 처음 가는 곳이라 걱정은 되지만 너무 재밌을 거 같아!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/whyLove/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/whyLove/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/whyLove/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/whyLove/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/whyLove/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/whyLove/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/whyLove/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/whyLove/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/whyLove/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/whyLove/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/whyLove/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/whyLove/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/whyLove/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/whyLove/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/whyLove/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/whyLove/INFP.jpg',
      },
    ],
  },
  // fatecharmtest
  {
    info: {
      mainTitle: '운명 부적 테스트',
      subTitle: '운명 부적 펼처보기 테스트',
      mainUrl: 'fatecharmtest',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/fatecharmtest-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/fatecharmtest-thumb.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '하반기에 수많은 약속이 잡혔다. 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '너무 재밌겠다 빨리 와라 미래의 약속들!',
          },
          {
            type: 'I',
            score: 5,
            content: '피곤할 미래의 나 자신에게.. 정말 미안..',
          },
        ],
      },
      {
        which: 'EI',
        question: '나에게 더 소중한 시간을 고르라면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '밖에서 친구들을 만나 하루 종일 떠드는 수다 타임',
          },
          {
            type: 'I',
            score: 5,
            content: '하루 종일 침대에 누워서 가지는 유~하 타임',
          },
        ],
      },
      {
        which: 'EI',
        question: '내가 생각하는 나와 가까운 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `시끌시끌 활발한 인싸`,
          },
          {
            type: 'I',
            score: 5,
            content: `차분하고 조용한 집돌이, 집순이`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 받고 싶은 선물을 고르라면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `실용적으로 쓸 수 있는 기프티콘`,
          },
          {
            type: 'N',
            score: 5,
            content: `고민해서 샀다는 깜짝 선물`,
          },
        ],
      },
      {
        which: 'SN',
        question: '처음 가는 길을 걸을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '가장 빠르게 갈 수 있는 길을 폭풍검색',
          },
          {
            type: 'N',
            score: 5,
            content: `이것 또한 데스티니..★ 발길이 닿는 대로 걷는다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '‘만약에~’ 질문에 대한 내 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `만약에 공격 또 시작.. 제발 멈춰..`,
          },
          {
            type: 'N',
            score: 5,
            content: `만약 나라면 이렇게 저렇게 할 듯!! 재밌다ㅎㅎ`,
          },
        ],
      },
      {
        which: 'TF',
        question: `내가 누군가에게 고민을 털어놓는 이유는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '실질적인 해결책을 얻을 수 있을까 해서',
          },
          {
            type: 'F',
            score: 5,
            content: '위로와 격려, 응원을 듣고 싶어서',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 갑자기 멀리 여행을 떠난다고 한다면 내 반응은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `어디로? 언제 가는데?`,
          },
          {
            type: 'F',
            score: 5,
            content: '엥 갑자기? 무슨 일 있어…?',
          },
        ],
      },
      {
        which: 'TF',
        question: `오늘의 운세를 봤는데 결과가 좋지 않다면 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '운세는 운세일 뿐! 신경 1도 안 쓰임',
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮은 척하지만 속으로는 계속 걱정함',
          },
        ],
      },
      {
        which: 'JP',
        question: '새로운 휴대폰이 출시되어 구매하려고 한다면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `미리 사전 예약 알림 신청을 하고,\n꼼꼼히 알아본 후 사전 예약 날짜에 맞춰 예약!`,
          },
          {
            type: 'P',
            score: 5,
            content: `색상 미쳤는데? 당장 사전예약 고`,
          },
        ],
      },
      {
        which: 'JP',
        question: '구매할 휴대폰의 컬러를 결정할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '기본 컬러가 안 질리고 좋지~\n무난한 블랙!',
          },
          {
            type: 'P',
            score: 5,
            content: '남들이 안 하는 독보적인 컬러가 좋아!\n민트 컬러 사볼까?',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 만족하는 나의 하루는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '내가 짠 일정대로 흘러간 하루',
          },
          {
            type: 'P',
            score: 5,
            content: '변수가 가득한 새로운 하루',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: ``,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: ``,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: ``,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: ``,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: ``,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: ``,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: ``,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: ``,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: ``,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: ``,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: ``,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: ``,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: ``,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: ``,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: ``,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: ``,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/INFP.jpg',
      },
    ],
  },
  // labelStickerSKT
  {
    info: {
      mainTitle: '라벨 스티커 테스트 청년 생활력 만렙편',
      subTitle: '스티커로 보는 나의 생활력 0(Young) 지수는?',
      mainUrl: 'labelStickerSKT',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/labelStickerSKT-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/labelStickerSKT-thumb.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '친구와 연락할 때 내가 더 선호하는 방식은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '통화로 직접 말로 하는 연락',
          },
          {
            type: 'I',
            score: 5,
            content: '카톡이나, 문자 등 글로 하는 연락',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구들과 약속을 잡을 때 나와 가까운 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구들에게 먼저 연락해서 약속을 잡는 편이다',
          },
          {
            type: 'I',
            score: 5,
            content: '친구들에게 먼저 연락이 와서 약속이 잡히는 편이다',
          },
        ],
      },
      {
        which: 'EI',
        question: '드디어 찾아온 주말 나와 가까운 생활은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `밖에서 사람들을 만나며 에너지를 충전하는\n활동적인 생활`,
          },
          {
            type: 'I',
            score: 5,
            content: `집에서 쉬면서 소소하게 에너지를 충전하는\n정적인 생활`,
          },
        ],
      },
      {
        which: 'SN',
        question: '핸드폰 요금제를 선택할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `현실적으로 생각해야지!\n데이터 적당한, 내 상황에 맞는 합리적인 요금제`,
          },
          {
            type: 'N',
            score: 5,
            content: `사람 일 모르지!\n제휴 혜택 많은, 데이터 빵빵한 고가 요금제`,
          },
        ],
      },
      {
        which: 'SN',
        question: '나의 재테크 관리 상태는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '미래를 위한 재테크는 필수지!\n열심히 관리하고 있다',
          },
          {
            type: 'N',
            score: 5,
            content: `재테크?\n현생 사느라 바빠서 신경 잘 못 씀`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '내가 물건을 고를 때 “어머! 이건 당장 사야 해!” 하는 포인트는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `합리적인 가격, 어메이징한 성능과 효율!`,
          },
          {
            type: 'N',
            score: 5,
            content: `디자인과 그때 그때 내 Feel에 따라서…☆`,
          },
        ],
      },
      {
        which: 'TF',
        question: `여행 시 목적지로 이동할 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '편하고, 효율적인 게 최고!\n택시 또는 렌터카를 적극적으로 이용한다',
          },
          {
            type: 'F',
            score: 5,
            content:
              '대중교통 이용 또는 걸으면서\n온몸으로 여행지 감성을 만끽한다',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구와 해외 여행 중, 가고 싶은 곳이 서로 다르다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `오히려 좋아. 각자 가고 싶은 곳 갔다 오자!`,
          },
          {
            type: 'F',
            score: 5,
            content: '아니 그래도 같이 온 여행인데 같이 가자..ㅠ(마상)',
          },
        ],
      },
      {
        which: 'TF',
        question: `사치로 통장이 텅장이 되어버린 친구가 우울해한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '자업자득이지 뭐~ 이제부터라도 아껴 써!',
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮아? 많이 힘들겠다 ㅠ\n(요즘 돈 많이 쓰긴 하던데..)',
          },
        ],
      },
      {
        which: 'JP',
        question: '카페에서 음료를 주문할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `주문하기 전 미리 할인 혜택이 있는지 찾아보고\n받을 수 있는 할인은 모두 받고 결제한다`,
          },
          {
            type: 'P',
            score: 5,
            content: `이미 결제하고 나서, 가지고 있던 쿠폰, 할인 혜택들이 떠오른다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '쇼핑을 하던 중 너무 사고 싶은 고가의 제품을 발견했다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '아쉽지만, 그래도 이번 달 소비 계획이 있으니까.\n언젠가 꼭 사고 만다 내가!',
          },
          {
            type: 'P',
            score: 5,
            content: '고민은 배송만 늦출 뿐!\n다음 달의 나에게 수습을 맡긴다',
          },
        ],
      },
      {
        which: 'JP',
        question: '휴대폰으로 영화 예매할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '항상 미리 예매해서 명당자리를 선점하는 편이다.',
          },
          {
            type: 'P',
            score: 5,
            content: '내가 예매할 때 명당자리는 거의 없는 편이다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: ``,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: ``,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: ``,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: ``,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: ``,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: ``,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: ``,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: ``,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: ``,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: ``,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: ``,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: ``,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: ``,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: ``,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: ``,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: ``,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerSKT/INFP.jpg',
      },
    ],
  },
  // charPyramid
  {
    info: {
      mainTitle: '성격 피라미드 테스트',
      subTitle: '피라미드에서 내 성격 발굴하기!',
      mainUrl: 'charPyramid',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/charPyramid-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/charPyramid-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '새로운 모임에 참여했을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '아는 사람이 없어도 거침없이 대화를 잘함',
          },
          {
            type: 'I',
            score: 5,
            content: '아는 사람이 3명 이상 있어야 마음이 편함',
          },
        ],
      },
      {
        which: 'EI',
        question: '내가 더 견디기 힘든 상황은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '하루 종일 혼자 있는 상황',
          },
          {
            type: 'I',
            score: 5,
            content: '하루 종일 수많은 사람과 있는 상황',
          },
        ],
      },
      {
        which: 'EI',
        question: '내가 더 말을 많이 하는 상황은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `누구와 있어도 말이 많은 편임`,
          },
          {
            type: 'I',
            score: 5,
            content: `친한 사람과 1:1로 있을 때`,
          },
        ],
      },
      {
        which: 'SN',
        question: '어제 본 영화를 친구에게 설명할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `영화 내용 그대로 친구에게 설명해 준다`,
          },
          {
            type: 'N',
            score: 5,
            content: `영화 내용을 장황하게 설명 후 개인 평까지 친구에게 말해준다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '화장실에서 샤워하는 동안 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '별생각 없이 그냥 샤워에 집중한다',
          },
          {
            type: 'N',
            score: 5,
            content: `에코 빵빵한 화장실에서 나만의 무대를 펼친다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '화려한 액션 영화를 보면서 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `화려한 액션에 감탄하고, 멋있다고 생각한다"`,
          },
          {
            type: 'N',
            score: 5,
            content: `내가 화려한 액션 영화의 주인공처럼 되는 상상을 한다`,
          },
        ],
      },
      {
        which: 'TF',
        question: `상대방에게 내가 더 많이 하는 반응은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '질문 (왜?, 그거 좋아해?, 재밌어?)',
          },
          {
            type: 'F',
            score: 5,
            content: '리액션 (아 진짜?, 대박!, 그렇구나!)',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 나에게 “힘내”하고 선물을 준다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `고마워!! (내가 힘들어 보였나?)`,
          },
          {
            type: 'F',
            score: 5,
            content: '진짜 고마워! 감동이야 ㅠ',
          },
        ],
      },
      {
        which: 'TF',
        question: `누군가 나에게 잘 못을 했을 때, 화가 누그러지는 포인트는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '이유가 타당하면 마음이 누그러짐',
          },
          {
            type: 'F',
            score: 5,
            content: '진정성 있는 사과에 마음이 누그러짐',
          },
        ],
      },
      {
        which: 'JP',
        question: '시험이 2주밖에 남지 않은 상황이라면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `시험이 2주밖에 안 남았어?! 다시 한번 훑어봐야 하는데!!`,
          },
          {
            type: 'P',
            score: 5,
            content: `2주? 슬슬 시작할까? 1주일 남았을 때부터 시작해야겠다!`,
          },
        ],
      },
      {
        which: 'JP',
        question: '약속을 잡을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '약속은 미리! 최소 하루 전날에 말해줘야 만날 수 있음',
          },
          {
            type: 'P',
            score: 5,
            content: '다른 약속 없다면 언제든지! 번개 약속도 가능!',
          },
        ],
      },
      {
        which: 'JP',
        question: '퇴근 후 내 계획은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '퇴근 후 7시 30분에 저녁 먹고, 1시간 청소하고, 2시간 자기계발 후, 11시에 취침 예정',
          },
          {
            type: 'P',
            score: 5,
            content: '좀 이따 집 가서 저녁 먹기',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/charPyramid/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/charPyramid/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/charPyramid/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/charPyramid/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/charPyramid/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/charPyramid/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/charPyramid/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/charPyramid/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/charPyramid/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/charPyramid/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/charPyramid/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/charPyramid/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/charPyramid/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/charPyramid/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/charPyramid/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/charPyramid/INFP.jpg',
      },
    ],
  },
  // rainDrop
  {
    info: {
      mainTitle: '빗 방울 성격 테스트',
      subTitle: '떨어지는 빗 방울로 알아보는 나의 성격은?',
      mainUrl: 'rainDrop',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/rainDrop-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/rainDrop-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          '장마가 시작되어 비가 많이 내리는 주말, 친구와 미리 잡은 약속이 있다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '비가 많이 오네? 아쉽지만, 실내에서 놀자고 해야겠다',
          },
          {
            type: 'I',
            score: 5,
            content:
              '비가 많이 오네.. 혹시 나중에 만나자고 약속 취소 연락 오지 않을까??',
          },
        ],
      },
      {
        which: 'EI',
        question: '내가 선호하는 의사소통 방식은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '직접 만나서 말로 소통하는 것',
          },
          {
            type: 'I',
            score: 5,
            content: '문자나, 톡 등 글로 소통하는 것',
          },
        ],
      },
      {
        which: 'EI',
        question: '드디어 찾아온 주말! 나의 선택은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `밖에서 친구들을 만나 신나게 놀기`,
          },
          {
            type: 'I',
            score: 5,
            content: `집에서 힐링 하며, 푹 쉬기`,
          },
        ],
      },
      {
        which: 'SN',
        question: '비가 많이 내리는 장마철 퇴근길, 내 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `비 엄청 많이 오네.. 빨리 가야지!`,
          },
          {
            type: 'N',
            score: 5,
            content: `혹시 길 가다가 벼락 맞는 건 아니겠지?..`,
          },
        ],
      },
      {
        which: 'SN',
        question: '집에 누워 있는데, 밖에 갑자기 비가 쏟아진다면 내 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '갑자기 비가 내리네.. 오늘 같은 날 약속 안 잡아서 다행이다',
          },
          {
            type: 'N',
            score: 5,
            content: `갑자기 들려오는 빗소리에 파전 먹는 상상이 저절로 떠올랐다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '배달을 시켰는데, 예상 시간보다 너무 늦는다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `어떻게 된 건지 궁금해서 전화한다`,
          },
          {
            type: 'N',
            score: 5,
            content: `혹시 배달기사님에게 무슨 일이 생긴 것은 아닌지 걱정한다`,
          },
        ],
      },
      {
        which: 'TF',
        question: `연인이 빗 길에 넘어졌다고 연락이 온다면 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '헉 괜찮아? 뭐 하다가 넘어졌어?!',
          },
          {
            type: 'F',
            score: 5,
            content: '헉 괜찮아? 어디 다친 곳은 없어?',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 속 깊은 이야기를 털어놨을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `친구의 말을 끝까지 듣고, 조언해준다`,
          },
          {
            type: 'F',
            score: 5,
            content: '친구의 말에 감정이입 하며, 리액션을 한다',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 길에서 누군가와 싸우고 있다, 들어보니 친구의 잘 못 일 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '솔직하게 친구의 잘 못이라고 확실하게 말해준다',
          },
          {
            type: 'F',
            score: 5,
            content:
              '시간을 두고, 상황이 마무리 된 후 친구에게 잘 못을 알려준다',
          },
        ],
      },
      {
        which: 'JP',
        question: '장맛비 내리는 날, 밖에서 해야 할 일이 있다면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `비가 많이 오긴 해도.. 오늘 하기로 한 일이니까 해야지!`,
          },
          {
            type: 'P',
            score: 5,
            content: `비가 너무 많이 오는데.. 그냥 나중에 날씨 풀리면 해야겠다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '친구들이 갑자기 모이자고 한다면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '이렇게 갑자기 만나는 것은 어렵다고 말한다',
          },
          {
            type: 'P',
            score: 5,
            content: '뭐 할 건지 물어보고, 끌리면 나간다',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '길을 걷다 갑자기 소나기가 내린다, 눈앞에 우산이 보이는 상황, 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '이런 갑작스러운 소나기에 우산을 살 수는 없지!! 잠깐 피해 있다 가야지!',
          },
          {
            type: 'P',
            score: 5,
            content: '비 맞는 것 보다 낫지! 우산을 바로 구매한다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/rainDrop/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/rainDrop/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/rainDrop/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/rainDrop/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/rainDrop/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/rainDrop/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/rainDrop/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/rainDrop/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/rainDrop/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/rainDrop/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/rainDrop/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/rainDrop/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/rainDrop/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/rainDrop/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/rainDrop/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/rainDrop/INFP.jpg',
      },
    ],
  },
  // flowerGardenJP
  {
    info: {
      mainTitle: '恋愛花畑テスト',
      subTitle: '花畑で見る私の恋愛スタイルは？',
      mainUrl: 'flowerGardenJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/flowerGardenJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/flowerGardenJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '約束があって外に出たときの私の姿は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'もう行くって？ まだ始まったばかりなのに何言ってるの？',
          },
          {
            type: 'I',
            score: 5,
            content: 'そろそろ家に帰りたいけど...ベッドが恋しいよ',
          },
        ],
      },
      {
        which: 'EI',
        question: '恋人に怒られた時の私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'なぜ怒っているのかをすぐに話しながら解決する',
          },
          {
            type: 'I',
            score: 5,
            content: 'とりあえず考えを整理する時間が必要（沈黙）',
          },
        ],
      },
      {
        which: 'EI',
        question: '会話中に静寂が流れると？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `この静寂、私は我慢できない··· 何でも言い出す`,
          },
          {
            type: 'I',
            score: 5,
            content: `目配せしながら誰かが言うのを待ってみる`,
          },
        ],
      },
      {
        which: 'SN',
        question: '待ち合わせた恋人が今日疲れていると言ったら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `疲れたら今日休む？と聞く`,
          },
          {
            type: 'N',
            score: 5,
            content: `疲れたというのは、今日は休みたいってことなのかな...？と悩む`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'カフェで飲み物を持って階段を登っている。この時、私の思いは？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '階段が多いな、早く行って飲みたい',
          },
          {
            type: 'N',
            score: 5,
            content: `階段を登るときは、転倒して飲み物をこぼすこともあるので注意が必要です`,
          },
        ],
      },
      {
        which: 'SN',
        question: '恋愛初期によく考えることは？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `喧嘩しないで長く会いましょう`,
          },
          {
            type: 'N',
            score: 5,
            content: `これから私たちはどうなるのかな？長く会えるかな？`,
          },
        ],
      },
      {
        which: 'TF',
        question: `今日はすごく疲れたけど、君が見たくて出てきた！」と言われたら、私の答えは？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '家で休んでもいいのに、なんでそんなことしたの！？',
          },
          {
            type: 'F',
            score: 5,
            content: '本当に...？(感動)すごく疲れそうだけど.... 大丈夫...？',
          },
        ],
      },
      {
        which: 'TF',
        question: '異性に興味を示す方法は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `質問爆弾、疑問符キラーになる`,
          },
          {
            type: 'F',
            score: 5,
            content: '些細なことでもリアクション職人になる',
          },
        ],
      },
      {
        which: 'TF',
        question: `恋人と別れたと泣いている友達、私の反応は？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'なぜ別れたの？ 落ち着いて話せよ.',
          },
          {
            type: 'F',
            score: 5,
            content: '大変だね...うん、泣きたいくらい泣くよ',
          },
        ],
      },
      {
        which: 'JP',
        question: '私は何の予定もなく家で休むことができるのだろうか？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `大まかにでも何をしながら休むか計画を立てなければならない`,
          },
          {
            type: 'P',
            score: 5,
            content: `計画を立てるならその日は休みの日ではない`,
          },
        ],
      },
      {
        which: 'JP',
        question: '会話中に話がそれてしまったら、私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'テーマに合わない話をすると不愉快',
          },
          {
            type: 'P',
            score: 5,
            content: '他の話をしても面白ければOK',
          },
        ],
      },
      {
        which: 'JP',
        question: '通常、どんな決断をしたとき私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '一度決めたことは簡単に変えない',
          },
          {
            type: 'P',
            score: 5,
            content: 'より良い決定があればすぐに変更可能',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenJP/INFP.jpg',
      },
    ],
  },
  // digitalCharEng
  {
    info: {
      mainTitle: 'Digital Personality Test',
      subTitle: 'Your personality type through Big Data!',
      mainUrl: 'digitalCharEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/digitalCharEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/digitalCharEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          'On the way home after hanging out with friends, what do you think?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I had fun! I got my energy back!',
          },
          {
            type: 'I',
            score: 5,
            content: "I'm tired and want to go home and rest.",
          },
        ],
      },
      {
        which: 'EI',
        question: 'What if you get all the attention at a crowded gathering?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: "I don't really care and keep talking.",
          },
          {
            type: 'I',
            score: 5,
            content: 'My heart races because I feel burdened by the attention.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'Which of the two situations is more difficult for you to endure?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Extremely awkward situations.`,
          },
          {
            type: 'I',
            score: 5,
            content: `A situation where many people talk to me at a gathering.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What do you think about when riding roller coaster at amusement park?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I'll have fun! It's been a long time since I rode it, so I think I'm a bit nervous~`,
          },
          {
            type: 'N',
            score: 5,
            content: `I think I'm scared. Wouldn't the seat belt come loose in the middle of riding?`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do you focus on when watching a movie or a drama?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: "I focus on the actor's performance and story.",
          },
          {
            type: 'N',
            score: 5,
            content: `I focus on touching or stimulating parts.`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do you do when you study?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I recklessly memorize while writing.`,
          },
          {
            type: 'N',
            score: 5,
            content: `I take notes and read until I understand.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What if your friend cries?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "I don't talk, but I just cheer.",
          },
          {
            type: 'F',
            score: 5,
            content: "Are you okay? Why are you crying? What's going on?",
          },
        ],
      },
      {
        which: 'TF',
        question:
          'If you ask your friends what they are doing today, what are your intentions?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I am really curious what they are doing today.`,
          },
          {
            type: 'F',
            score: 5,
            content:
              'I wonder if there is an appointment today and if we can meet today.',
          },
        ],
      },
      {
        which: 'TF',
        question: `What would you do if two of your friends were arguing?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'I would ask the reason for the argument and think who is wrong.',
          },
          {
            type: 'F',
            score: 5,
            content: 'I would try to stop argument and listen to the story.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What do you do first when you want to do something?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I make a plan first.`,
          },
          {
            type: 'P',
            score: 5,
            content: `I just start and think later.`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What if you were at home together with your friend and suddenly your friend asked you to go out?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "Where do you want to go? Let's go out after deciding where we go first and go.",
          },
          {
            type: 'P',
            score: 5,
            content: "Okay, let's go.",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What do you do when you decide to have a meal with a friend?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I looked it up. How about here (while showing the search screen)?',
          },
          {
            type: 'P',
            score: 5,
            content:
              'Hey, it looks delicious just over there. How about there?',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/digitalCharEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/digitalCharEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/digitalCharEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/digitalCharEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/digitalCharEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/digitalCharEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/digitalCharEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/digitalCharEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/digitalCharEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/digitalCharEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/digitalCharEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/digitalCharEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/digitalCharEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/digitalCharEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/digitalCharEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/digitalCharEng/INFP.jpg',
      },
    ],
  },
  // digitalChar
  {
    info: {
      mainTitle: '디지털 성격 테스트',
      subTitle: '빅데이터로 알아보는 나의 성격 유형은?',
      mainUrl: 'digitalChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/digitalChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/digitalChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '친구들과 놀고 집에 가는 길, 내 생각은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '재밌게 놀았다! 에너지 충전 완료!',
          },
          {
            type: 'I',
            score: 5,
            content: '피곤해서 빨리 집에 가서 쉬고 싶다',
          },
        ],
      },
      {
        which: 'EI',
        question: '사람 많은 모임에서 관심이 나에게만 집중된다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '별로 신경 쓰지 않고, 이야기한다',
          },
          {
            type: 'I',
            score: 5,
            content: '관심이 집중된 것이 부담스러워, 뚝딱거린다',
          },
        ],
      },
      {
        which: 'EI',
        question: '둘 중 내가 더 견디기 힘든 상황은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `극도로 어색한 상황`,
          },
          {
            type: 'I',
            score: 5,
            content: `모임에서 여러 사람이 나에게 말을 거는 상황`,
          },
        ],
      },
      {
        which: 'SN',
        question: '놀이공원에서 롤러코스터를 탈 때 내가 하는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `재밌겠다! 오랜만에 타서 살짝 긴장되는 것 같기도 하고~`,
          },
          {
            type: 'N',
            score: 5,
            content: `무서울 것 같은데 혹시 안전벨트가 중간에 풀리지는 않겠지?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '드라마, 영화를 볼 때 내가 집중하는 부분은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '배우의 연기와 스토리에 집중',
          },
          {
            type: 'N',
            score: 5,
            content: `감동적이거나, 자극적인 부분에 집중`,
          },
        ],
      },
      {
        which: 'SN',
        question: '공부할 때 나와 가까운 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `필기하면서 무작정 외움`,
          },
          {
            type: 'N',
            score: 5,
            content: `필기하고, 이해할 때까지 읽어 봄`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 울고 있다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '굳이 말은 걸지 않고, 토닥여 준다',
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮아?? 왜 울어?? 무슨 일이야 ㅠㅠ',
          },
        ],
      },
      {
        which: 'TF',
        question: '지금 내가 친구에게 오늘 ‘뭐해?’라고 물어본 이유는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `진짜 오늘 뭐 하는지 궁금해서`,
          },
          {
            type: 'F',
            score: 5,
            content: '혹시 오늘 약속이 있는지, 만날 수 있을지 물어보려고',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구 두 명이 서로 언쟁을 벌이고 있다면? 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '상황과 싸운 이유를 들어보고, 누구 잘못인지 생각해 본다',
          },
          {
            type: 'F',
            score: 5,
            content: '일단 싸움을 말리고, 이야기를 들어 본다',
          },
        ],
      },
      {
        which: 'JP',
        question: '무언가 하려고 할 때 내가 먼저 하는 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `계획 세우기`,
          },
          {
            type: 'P',
            score: 5,
            content: `일단 시작하고, 생각`,
          },
        ],
      },
      {
        which: 'JP',
        question: '집에 같이 있던 친구가 갑자기 나가자고 한다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '어디 갈 건데? 무작정 나가지 말고, 정하고 나가자',
          },
          {
            type: 'P',
            score: 5,
            content: '그래, 일단 나가자',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구와 밥을 같이 먹기로 했을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '내가 찾아봤는데, (검색 화면 보여줌) 여기어때?',
          },
          {
            type: 'P',
            score: 5,
            content: '야 저기 맛있어 보이는데 저기 어때?',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/digitalChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/digitalChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/digitalChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/digitalChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/digitalChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/digitalChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/digitalChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/digitalChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/digitalChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/digitalChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/digitalChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/digitalChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/digitalChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/digitalChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/digitalChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/digitalChar/INFP.jpg',
      },
    ],
  },
  // postCardEng
  {
    info: {
      mainTitle: 'Postcard personality test',
      subTitle: "Let's decorate my personality with postcards",
      mainUrl: 'postCardEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/postCardEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/postCardEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'How do you talk when you talk to your friend?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I should speak after my friend has spoken',
          },
          {
            type: 'I',
            score: 5,
            content: 'I response based on what my friend says',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What can be your reaction when an appointment with a friend is canceled?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I urgently contact other friends',
          },
          {
            type: 'I',
            score: 5,
            content: 'I secretly like it and rest well at home',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'On the weekend after working hard for a week, what do you do?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I've only been inside the company all week, so I should go outside and have fun with my friends`,
          },
          {
            type: 'I',
            score: 5,
            content: `I have been working all week, so I should rest at home on the weekend`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What can you do better?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I can do things that require meticulousness and delicacy`,
          },
          {
            type: 'N',
            score: 5,
            content: `I can do things that requires imagination and creativity`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '“What if you could have one superpower?” what can be your response?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I don’t think it could happen',
          },
          {
            type: 'N',
            score: 5,
            content: `Only one superpower? Hmmm (thinking..)`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What if the movie you really enjoyed had an open ending?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `It's a pity that the movie I enjoyed had an unpleasant open ending`,
          },
          {
            type: 'N',
            score: 5,
            content: `I can freely imagine the story after the ending`,
          },
        ],
      },
      {
        which: 'TF',
        question: `Which one is closer to you when it comes to explaining what you know to the other person?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I even clearly explain the dictionary meaning',
          },
          {
            type: 'F',
            score: 5,
            content:
              'I explain with an example so that the other person can easily understand',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What if your friend who is driving is sleepy while on summer vacation?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I try to find next resting area and inform the location`,
          },
          {
            type: 'F',
            score: 5,
            content:
              'I keep trying to talk to my friend and say I should drive instead',
          },
        ],
      },
      {
        which: 'TF',
        question: `What do you do more to someone who you like?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I am constantly expressing interest and asking questions',
          },
          {
            type: 'F',
            score: 5,
            content: 'I sincerely sympathize with the reaction',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Who do you feel more uncomfortable with?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `A person who ignores all the original plans and does things their own way`,
          },
          {
            type: 'P',
            score: 5,
            content: `A person who forces me to stick to the plan even if there is a better way`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'When you try to reach your goal, how do you do?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I set a goal and stick to it',
          },
          {
            type: 'P',
            score: 5,
            content: 'I set several goals and try different things',
          },
        ],
      },
      {
        which: 'JP',
        question: 'When using public transportation, how do you do?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I find the shortest route to my destination, check when it arrives, and then go out on time',
          },
          {
            type: 'P',
            score: 5,
            content:
              "I just go to bus stop and wait, and if it doesn't come, I look for when it will arrives",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/postCardEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/postCardEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/postCardEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/postCardEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/postCardEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/postCardEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/postCardEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/postCardEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/postCardEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/postCardEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/postCardEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/postCardEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/postCardEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/postCardEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/postCardEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/postCardEng/INFP.jpg',
      },
    ],
  },
  // blackboardChar
  {
    info: {
      mainTitle: '칠판 성격 테스트',
      subTitle: '나의 성격을 칠판에 그려보면?',
      mainUrl: 'blackboardChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/blackboardChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/blackboardChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '여행에서 인생 샷을 건졌다. 나의 행동은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '일단 SNS에 올리고, 다른 사진도 구경한다',
          },
          {
            type: 'I',
            score: 5,
            content: 'SNS에 올릴까, 말까 한참 고민한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '새로 사귄 친구가 SNS를 하는 것을 봤다면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '고민 없이 팔로우를 신청한다',
          },
          {
            type: 'I',
            score: 5,
            content: '먼저 팔로우를 걸까, 말까 한참 고민한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '사람 많은 음식점에서 직원을 불렀는데, 직원이 오지 않는다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `바로 큰소리로 한 번 더 직원을 부른다`,
          },
          {
            type: 'I',
            score: 5,
            content: `무슨 이유가 있겠지, 싶어서 기다려 본다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '엄청 기대했던 영화가 열린 결말로 끝났다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `실망하면서, 열린 결말에 불만을 느낀다`,
          },
          {
            type: 'N',
            score: 5,
            content: `영화의 결말을 내 마음대로 상상해 보고, 만족해한다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '이사 갈 집을 보러 간다면, 내가 중점적으로 보는 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '연식, 구조, 수압 체크 등 꼼꼼하게 확인한다',
          },
          {
            type: 'N',
            score: 5,
            content: `청결, 크기, 색상, 들어갔을 때 느낌 등을 체크해 본다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '새로운 전자기기를 샀을 때 먼저 하는 행동은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `설명서부터 쭉 정독하고, 기능 파악 후, 효율적으로 사용하려고 한다`,
          },
          {
            type: 'N',
            score: 5,
            content: `일단 이것저것 먼저 만져보고, 모르는 부분을 찾아서 읽어 본다`,
          },
        ],
      },
      {
        which: 'TF',
        question: `지인이 무리한 부탁을 해온다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '어려운 부탁인 것을 충분히 설명한 후, 거절한다',
          },
          {
            type: 'F',
            score: 5,
            content: '거절이 어려워, 무리한 부탁임에도 들어주는 경우가 있다',
          },
        ],
      },
      {
        which: 'TF',
        question: '영화 시청 중 슬픈 장면에서 내가 눈물을 흘린 이유는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `스토리 전개상 슬픈 장면이고, 슬픈 감정이 들어서`,
          },
          {
            type: 'F',
            score: 5,
            content: '영화 주인공에게 너무 감정이입을 해서',
          },
        ],
      },
      {
        which: 'TF',
        question: `좋아하는 사람이 생겼다, 나의 관심 표현 방법은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '끝없는 질문으로 관심을 표현한다',
          },
          {
            type: 'F',
            score: 5,
            content: '상대의 말을 경청하며, 공감으로 관심을 표현한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '둘 중 하나의 여행을 간다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `모든 것이 미리 계획대로 정해진 여행`,
          },
          {
            type: 'P',
            score: 5,
            content: `여러 가지 경험을 해볼 수 있는 자유가 보장된 여행`,
          },
        ],
      },
      {
        which: 'JP',
        question: '새로운 일을 받았을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '계획을 세세하게 짜고, 시작한다',
          },
          {
            type: 'P',
            score: 5,
            content: '가능성을 열어 두고, 일단 시작한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구와 약속이 있는 날 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '저녁 약속이니까, 이때부터 준비하면 늦지 않게 도착하겠다!',
          },
          {
            type: 'P',
            score: 5,
            content: '아, 늦겠다! 빨리 준비해야지!!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/blackboardChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/blackboardChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/blackboardChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/blackboardChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/blackboardChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/blackboardChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/blackboardChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/blackboardChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/blackboardChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/blackboardChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/blackboardChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/blackboardChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/blackboardChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/blackboardChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/blackboardChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/blackboardChar/INFP.jpg',
      },
    ],
  },
  // scrapBookChar
  {
    info: {
      mainTitle: '스크랩북 성격 테스트',
      subTitle: '내 성격을 스크랩으로 모아보자',
      mainUrl: 'scrapBookChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/scrapBookChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/scrapBookChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '사람이 많은 장소에서 내가 느끼는 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '너무 신나잖아~ 에너지 풀 충전 중',
          },
          {
            type: 'I',
            score: 5,
            content: '기가 다 빨린다.. 에너지 풀 소진 중',
          },
        ],
      },
      {
        which: 'EI',
        question: '길에서 아는 친구를 마주친다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '반갑게 인사하며 어디 가는 길인지 물어본다',
          },
          {
            type: 'I',
            score: 5,
            content: '일단 모르는 척하고 살짝 숨어서 지나친다',
          },
        ],
      },
      {
        which: 'EI',
        question: '둘 중 더 고통스러운 상황은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `일주일 내내 밖에서 밤늦게까지 놀기`,
          },
          {
            type: 'I',
            score: 5,
            content: `일주일 내내 집에서 아무 데도 못 나가기`,
          },
        ],
      },
      {
        which: 'SN',
        question: '수박하면 떠오르는 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `빨갛다, 맛있겠다, 요즘 제철 과일`,
          },
          {
            type: 'N',
            score: 5,
            content: `수박바, 시원한 계곡, 여름이네~`,
          },
        ],
      },
      {
        which: 'SN',
        question: '갑자기 해외로 떠나서 살고 싶다는 친구에게 내 대답은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '여행이면 몰라도 사는 건 현실적으로 불가능하지~',
          },
          {
            type: 'N',
            score: 5,
            content: `나는 만약에 간다면 이 나라로 가서 살래! 얼마나 행복할까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구와 재난 영화를 보고 나왔다. 난 어떤 대화를 할까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `말도 안 되는 내용이긴 하지만.. 그래도 재밌었다!`,
          },
          {
            type: 'N',
            score: 5,
            content: `만약 진짜 재난이 일어나면 넌 뭐부터 할 거야?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `뒤에서 내 욕을 하고 다닌다는 친구! 내 반응은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '응~ 나도 걔 별로~ 근데 왜 뒤에서 함?',
          },
          {
            type: 'F',
            score: 5,
            content: '진짜..? 어떻게 그럴 수가 있지..? (상..처..)',
          },
        ],
      },
      {
        which: 'TF',
        question: '내가 더 듣고 싶은 말을 고르라면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `너한테 항상 많이 배워! / 뭐든지 다 잘하는구나?`,
          },
          {
            type: 'F',
            score: 5,
            content: '너는 나에게 정말 필요한 사람이야 / 너무 소중해!',
          },
        ],
      },
      {
        which: 'TF',
        question: `눈에 뭔가 들어가서 너무 아프다는 친구에게 내 대답은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '얼른 빼야지 그럼! 불어줘? 많이 아프면 안과 ㄱㄱ',
          },
          {
            type: 'F',
            score: 5,
            content: '많이 아파?? 아이고 어떡하지 불어줄까??',
          },
        ],
      },
      {
        which: 'JP',
        question: '물건을 찾을 때 주로 내 모습은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `항상 제자리에 두기 때문에 바로 찾을 수 있음`,
          },
          {
            type: 'P',
            score: 5,
            content: `대충 던져놔서 어디 있는지 한참을 찾음`,
          },
        ],
      },
      {
        which: 'JP',
        question: '나에게 하루 동안의 자유시간이 생긴다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '계획부터 짠 후 쉬기',
          },
          {
            type: 'P',
            score: 5,
            content: '쉬면서 뭐 할지 생각해 보기',
          },
        ],
      },
      {
        which: 'JP',
        question: '나에게 있어 시간이란 무엇일까?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '효율적으로 써야 하는 한정된 자원',
          },
          {
            type: 'P',
            score: 5,
            content: '탄력적으로 써야 하는 새롭게 바뀌는 자원',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/scrapBookChar/INFP.jpg',
      },
    ],
  },
  // expressionChar
  {
    info: {
      mainTitle: '표정 성격 테스트',
      subTitle: '나의 숨어있는 표정은 무엇일까?',
      mainUrl: 'expressionChar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/expressionChar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/expressionChar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '친구들과 수다 떨 때 나와 가까운 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '할 말이 쏟아져 나오는 편이다',
          },
          {
            type: 'I',
            score: 5,
            content: '할 말이 별로 없어서, 듣고 공감하는 편이다',
          },
        ],
      },
      {
        which: 'EI',
        question: '나와 더 가까운 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '주변 분위기가 조용하면, 가장 말이 많은 편이다',
          },
          {
            type: 'I',
            score: 5,
            content: '친화력은 좀 있지만, 낯을 많이 가린다',
          },
        ],
      },
      {
        which: 'EI',
        question: '사람이 많은 파티 모임이 끝나고, 집에 도착했을 때 내 기분은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `사람들이 많은 공간에 있다 와서 쓸쓸하다`,
          },
          {
            type: 'I',
            score: 5,
            content: `드디어 끝났다. 이제 푹 쉬어야지!`,
          },
        ],
      },
      {
        which: 'SN',
        question: '멍때릴 때, 내가 하는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `아무 생각 없다`,
          },
          {
            type: 'N',
            score: 5,
            content: `이런저런 상상과, 끝없는 고민`,
          },
        ],
      },
      {
        which: 'SN',
        question: '노래를 들을 때 내가 더 집중하는 부분은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '가사보다 멜로디',
          },
          {
            type: 'N',
            score: 5,
            content: `멜로디보다 가사`,
          },
        ],
      },
      {
        which: 'SN',
        question: '공공장소에서 모르는 사람과 자꾸 눈이 마주칠 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `그냥 그런 가보다`,
          },
          {
            type: 'N',
            score: 5,
            content: `뭐 묻었나? 왜 자꾸 쳐다보지? 뭐지..`,
          },
        ],
      },
      {
        which: 'TF',
        question: `음식을 주문했는데, 시간이 많이 지나도 음식이 나오지 않는다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '바로 직원을 불러서 음식이 왜 안 나오냐며 따져 묻는다',
          },
          {
            type: 'F',
            score: 5,
            content: '뭔가 사정이 있겠지, 일단 더 기다려 보자',
          },
        ],
      },
      {
        which: 'TF',
        question: '갑자기 뜻밖의 문제가 발생했을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `생각할 시간에 빠르게 해결한다`,
          },
          {
            type: 'F',
            score: 5,
            content: '멘붕이 와서 벙쪄있다가 해결한다',
          },
        ],
      },
      {
        which: 'TF',
        question: `누군가 나에게 모르면서 아는 척하지 말라고 한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '바로 아는 반박 100가지 준비해서 팩폭을 날린다',
          },
          {
            type: 'F',
            score: 5,
            content: '어떻게 그렇게 심한말을…ㅠ',
          },
        ],
      },
      {
        which: 'JP',
        question: '캠핑장에 도착하자마자 내가 하는 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `일단 짐부터 빠르게 정리하고, 텐트부터 치고 쉰다`,
          },
          {
            type: 'P',
            score: 5,
            content: `캠핑장, 풍경 사진 찍고, 구경하다가 텐트를 친다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '나의 정리 스타일은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '사용한 물건은 꼭 제자리에! 매일 꾸준히 정리하는 루틴이 있다',
          },
          {
            type: 'P',
            score: 5,
            content: '치워야겠다 생각이 들면 한 번에 몰아서 치운다',
          },
        ],
      },
      {
        which: 'JP',
        question: '내 휴대폰에 읽지 않은 어플 알림 메시지 상태는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '1~2개? 거의 없다.',
          },
          {
            type: 'P',
            score: 5,
            content: '많다. 오래전에 온 알림들이 쌓여 있다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/expressionChar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/expressionChar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/expressionChar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/expressionChar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/expressionChar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/expressionChar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/expressionChar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/expressionChar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/expressionChar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/expressionChar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/expressionChar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/expressionChar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/expressionChar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/expressionChar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/expressionChar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/expressionChar/INFP.jpg',
      },
    ],
  },
  // loveLotteryEng
  {
    info: {
      mainTitle: 'Love Lottery Test',
      subTitle: 'lam a not scratched lottery ticket! Who is the lucky one?',
      mainUrl: 'loveLotteryEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveLotteryEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveLotteryEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: 'What kind of date do you prefer?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I like exciting and fun outdoor dates.',
          },
          {
            type: 'I',
            score: 5,
            content: 'I like a quiet and calm indoor date.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What if you get home after a date with your lover over the weekend?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'I had a happy date this weekend too! I am totally refreshed!',
          },
          {
            type: 'I',
            score: 5,
            content: 'I had good weekend again~ now, I need to rest now~',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What if your lover suddenly propose a double date?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Okay! Let’s have fun! It will be fun!`,
          },
          {
            type: 'I',
            score: 5,
            content: `Oh, it is kinda… We can try it next time`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What are your thoughts at the beginning of a relationship?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I'm so happy haha. I will keep this relationship happily~`,
          },
          {
            type: 'N',
            score: 5,
            content: `I am so happy, but can I be happy for a long time without fighting?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What if you are enjoying a picnic with your lover by the Han River?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'I like the weather, the food is delicious, and I feel good~',
          },
          {
            type: 'N',
            score: 5,
            content: `The weather is nice~ But should we be worried that pigeons will come all the way here and eat our food?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What do you think when you go out to eat lunch together with your lover?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `What should I eat? Everything on the menu looks delicious...`,
          },
          {
            type: 'N',
            score: 5,
            content: `What should I have for dinner later? I want to eat something delicious for dinner as well.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What if your lover made a gift for you by hand?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              "How did I get the idea to make something like this? It's really great!",
          },
          {
            type: 'F',
            score: 5,
            content:
              'Oh my god! It must have taken a lot of effort to make this. I am impressed!',
          },
        ],
      },
      {
        which: 'TF',
        question: 'How would you react if your lover dropped their cell phone?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Well, what were you doing? Is the screen okay? Do you have insurance on it?`,
          },
          {
            type: 'F',
            score: 5,
            content:
              'Well, are you okay? You didn’t break the screen, did you?',
          },
        ],
      },
      {
        which: 'TF',
        question: `What if your lover is too logical in an argument?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "I can't lose! I also prepare to be logical.",
          },
          {
            type: 'F',
            score: 5,
            content:
              'I can agree, but I am also have things to say and I feel sorry.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What if you go on a trip with your lover?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I will share all the detailed plan with my lover.`,
          },
          {
            type: 'P',
            score: 5,
            content: `I only make rough plans and follow my lover’s plans.`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What if you found a new tourist spot on the way to your destination on a trip with your lover?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I just follow my original plan and plan to visit this new spot next time.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'I’m too curious to pass by, so I ask my lover to stop by.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What do you do when you go on a date with your lover?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I go on a date with detail plans.',
          },
          {
            type: 'P',
            score: 5,
            content: 'I go on an impromptu date without detail plans.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveLotteryEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveLotteryEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveLotteryEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveLotteryEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveLotteryEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveLotteryEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveLotteryEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveLotteryEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveLotteryEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveLotteryEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveLotteryEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveLotteryEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveLotteryEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveLotteryEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveLotteryEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveLotteryEng/INFP.jpg',
      },
    ],
  },
  // loveLottery
  {
    info: {
      mainTitle: '연애 복권 테스트',
      subTitle: '나는 긁지 않은 복권! 행운의 주인공은 과연 누구?!',
      mainUrl: 'loveLottery',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveLottery-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveLottery-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '내가 선호하는 데이트는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '신나고 즐거운 실외 데이트',
          },
          {
            type: 'I',
            score: 5,
            content: '조용하고 차분한 실내 데이트',
          },
        ],
      },
      {
        which: 'EI',
        question: '주말 동안 연인과 데이트 후 집에 도착했을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '이번 주말도 행복한 데이트였다! 에너지 충전 완료!',
          },
          {
            type: 'I',
            score: 5,
            content: '이번 주말도 행복한 데이트였다~ 이제 쉬어야지~',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '데이트 중 연인이 갑자기 친구 커플과 더블데이트를 제안한다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `그래! 같이 놀자! 재밌겠다!`,
          },
          {
            type: 'I',
            score: 5,
            content: `아 그건 좀.. 나중에 만나자고 하자`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연애 초기에 내가 하는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `너무 행복하다ㅎㅎ 행복하게 잘 사귀어야지~`,
          },
          {
            type: 'N',
            score: 5,
            content: `너무 행복하긴 한데, 연인이랑 안 싸우고 오래 행복할 수 있을까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인과 한강 피크닉 데이트를 즐기고 있을 때 내 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '날씨도 좋고, 음식도 맛있고, 기분 좋다~',
          },
          {
            type: 'N',
            score: 5,
            content: `날씨 좋다~ 근데 혹시 저 비둘기가 이쪽으로 와서 우리 음식을 쪼아 먹지는 않겠지?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인과 점심을 먹으려고 식당에 들어왔을 때 내 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `뭐 먹지? 메뉴가 다 맛있어 보이는데..`,
          },
          {
            type: 'N',
            score: 5,
            content: `이따 저녁은 또 뭐 먹지?? 저녁도 맛있는 거 먹고 싶은데`,
          },
        ],
      },
      {
        which: 'TF',
        question: `연인이 나를 위해서 손수 선물을 만들어 줬다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '어떻게 이런 걸 만들 생각을 했어? 진짜 대박이다!',
          },
          {
            type: 'F',
            score: 5,
            content: '세상에!! 이거 만드느라 너무 수고 많았겠다ㅠ 감동이야ㅠ',
          },
        ],
      },
      {
        which: 'TF',
        question: '연인이 휴대폰을 떨어뜨렸다고 한다면 나의 반응은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `헉 뭐 하다가? 액정 깨진 거 아니야? 보험은 들었었어?`,
          },
          {
            type: 'F',
            score: 5,
            content: '헉 괜찮아?? 혹시 액정 깨진 건 아니지..?',
          },
        ],
      },
      {
        which: 'TF',
        question: `연인과 갈등 상황에 연인이 논리적으로 따져 묻는다면 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '질 수 없지! 나도 논리적인 반박을 준비한다',
          },
          {
            type: 'F',
            score: 5,
            content: '맞는 말이긴 하지만, 나도 할 말 있고 너무 서운하다',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 여행을 간다면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `분 단위로 쪼갠 디테일한 계획을 짜서 연인에게 공유한다`,
          },
          {
            type: 'P',
            score: 5,
            content: `큰 계획만 같이 세우고, 연인에 계획에 따라간다`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          '연인과 여행 중 목적지로 이동하던 중에 처음 보는 새로운 관광지를 발견했다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '나중에 가봐 야지 하고, 일단 계획된 목적지로 간다',
          },
          {
            type: 'P',
            score: 5,
            content: '궁금해서 연인에게 저기 가보자고 해본다',
          },
        ],
      },
      {
        which: 'JP',
        question: '평소 연인과 하는 데이트는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '하루 일정이 세세하게 정해져 있는 계획적인 데이트',
          },
          {
            type: 'P',
            score: 5,
            content: '하루 일정이 정해져 있지 않은 즉흥적인 데이트',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveLottery/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveLottery/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveLottery/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveLottery/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveLottery/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveLottery/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveLottery/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveLottery/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveLottery/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveLottery/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveLottery/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveLottery/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveLottery/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveLottery/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveLottery/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveLottery/INFP.jpg',
      },
    ],
  },
  // hologramLove
  {
    info: {
      mainTitle: '홀로그램 연애 테스트',
      subTitle: '홀로그램으로 내 연애 스타일을 알아보자!',
      mainUrl: 'hologramLove',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/hologramLove-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/hologramLove-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '하루 종일 데이트를 하고 왔다! 집에 와서 드는 나의 생각은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '너무 재밌었다! 내일은 친구들 만나는 날~ 신난다',
          },
          {
            type: 'I',
            score: 5,
            content: '즐거웠지만 배터리 방전… 내일은 꼭 쉬어야지..ㅎㅎ',
          },
        ],
      },
      {
        which: 'EI',
        question: '연인에게 하고싶은 말이 있다면 전달하기 더 편한 방법은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '직접 대화를 통해서 전달',
          },
          {
            type: 'I',
            score: 5,
            content: '메시지에 하고 싶은 말을 적어서 전달',
          },
        ],
      },
      {
        which: 'EI',
        question: '연인, 친구들과의 약속이 일주일 내내 잡혀있다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `좀 피곤하겠지만 일주일 내내 집에 있는 것보단 낫지!`,
          },
          {
            type: 'I',
            score: 5,
            content: `생각만 해도 벌써 집에 가고 싶어.. 나에겐 있을 수 없는 일`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연애 초기에 내가 많이 하는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `싸우지 말고 오래오래 만나자!`,
          },
          {
            type: 'N',
            score: 5,
            content: `앞으로 오래 만날 수 있겠지? 미래에 우린 어떤 모습일까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '애인이 내가 모르는 이성과 함께 있는 모습을 본다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '그 사람은 누군지 바로 물어봄',
          },
          {
            type: 'N',
            score: 5,
            content: `물어보기 전 짧은 순간에 바람 피는 온갖 상상을 다 해봄`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인에게 선물을 받으면 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `필요한 선물을 사줘서 감동`,
          },
          {
            type: 'N',
            score: 5,
            content: `선물을 사기까지의 과정을 상상하며 감동`,
          },
        ],
      },
      {
        which: 'TF',
        question: `스트레스 받아서 입맛이 없다는 애인에게 내 대답은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '그래도 밥은 먹어야지! 이따 배고플 텐데',
          },
          {
            type: 'F',
            score: 5,
            content: '무슨 힘든 일 있어..? 이따 맛난 거 먹으러 갈까?',
          },
        ],
      },
      {
        which: 'TF',
        question: '연인에게 내 관심을 표현하는 방법은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `질문이 많아져 물음표 살인마가 됨`,
          },
          {
            type: 'F',
            score: 5,
            content: '사소한 일에도 리액션 장인이 됨',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 연인의 연락 문제에 대한 고민을 상담한다! 내 대답은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '뭐 일이 바쁘거나 사정이 있겠지~',
          },
          {
            type: 'F',
            score: 5,
            content: '연락이 너무 느리면 나 같아도 서운하지ㅜ',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인이 갑자기 오늘 볼래?라고 한다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `지금은 일정이 있어서.. 이때 여기서 몇 시 어때??`,
          },
          {
            type: 'P',
            score: 5,
            content: `지금? 보면 되지 나올래?`,
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과의 기념일까지 2주가 남았다! 내 생각은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '2주 밖에 안 남았어..? 빨리 준비해야겠다',
          },
          {
            type: 'P',
            score: 5,
            content: '2주나 남았네~ 담 주부터 준비해야지',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과의 약속을 정할 때 주로 내 모습은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '일정을 미리 짜놓고 만남',
          },
          {
            type: 'P',
            score: 5,
            content: '대략적으로 뭐 할지만 정해두고 만남',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLove/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLove/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLove/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLove/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLove/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLove/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLove/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLove/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLove/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLove/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLove/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLove/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLove/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLove/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLove/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/hologramLove/INFP.jpg',
      },
    ],
  },
  // loveProfile
  {
    info: {
      mainTitle: '연애 프로파일 테스트',
      subTitle: 'MBTI로 알아보는 나의 연애 프로파일!',
      mainUrl: 'loveProfile',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveProfile-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveProfile-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '친구에게 소개받은 이성과 연락할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '주도적으로 자연스럽게 대화를 이끌어 나간다',
          },
          {
            type: 'I',
            score: 5,
            content: '한마디 할 때 마다 신중하게 생각하고 말 하는 편이다',
          },
        ],
      },
      {
        which: 'EI',
        question: '소개받은 사람과 서로 알아가는 단계에서 고민이 생겼다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구들이나, 지인에게 고민 상담을 한다',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자 충분히 생각해 보고, 결정을 한다',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '소개받은 이성과 사귀게 되었다! 첫 데이트 중 우연히 연인의 친구를 만난다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `상황 봐서 자연스럽게 함께 대화한다`,
          },
          {
            type: 'I',
            score: 5,
            content: `옆에서 가만히 둘의 대화가 끝나기만 기다린다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '나와 연인과의 관계에서 중요한 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `현재에 연애에 충실한 것`,
          },
          {
            type: 'N',
            score: 5,
            content: `함께하는 미래에 관해 이야기하고 준비하는 것`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구를 만난다던 연인이 몇 시간 동안 연락이 안 된다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '뭐야 그렇게 재밌나? 근데 벌써 이러는 건 아니지 않나?',
          },
          {
            type: 'N',
            score: 5,
            content: `아니 벌써부터 이런다고?!(부글부글) 근데 혹시 무슨 사고 난건 아니겠지?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내일은 연인과 첫 여행을 가는 날! 자기 전 내 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `재밌겠다! 빨리 자야겠ㄷㅏ....zZ`,
          },
          {
            type: 'N',
            score: 5,
            content: `여행 생각하다가 설레서 잠도 제대로 못 잠`,
          },
        ],
      },
      {
        which: 'TF',
        question: `연인이 갑자기 배가 너무 아프다고 한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '오늘 뭐 먹었어? 병원 가봐야 하는 거 아니야??ㅠ',
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮아?? 많이 아파?? 약 사다 줄까?ㅠ',
          },
        ],
      },
      {
        which: 'TF',
        question: '연인이 갑자기 스트레스받는다며 맛집에 가자고 한다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `그래! 맛있는 거 먹으러 가자! 어떤 음식이 땡겨? 다 사줄게!`,
          },
          {
            type: 'F',
            score: 5,
            content: '헉..ㅠㅠ 무슨 일 있었어?? 누가 스트레스받게 했어!!',
          },
        ],
      },
      {
        which: 'TF',
        question: `연인이 나랑 잘 어울릴 것 같다며 옷 선물을 했다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '고마워! 잘 입을게!! 근데 어떤 부분이 나랑 잘 어울린다고 생각한 거야??',
          },
          {
            type: 'F',
            score: 5,
            content: '나랑 잘 어울릴 것 같아서 샀다고?! 감동 ㅠ 고마워 ㅠㅠ',
          },
        ],
      },
      {
        which: 'JP',
        question: '약속이 있는 날 나와 가까운 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `(약속 시간 몇 시간 전) 아 벌써 약속 시간 다가오네 귀찮지만 슬슬 준비 해야겠다`,
          },
          {
            type: 'P',
            score: 5,
            content: `(약속 시간 몇 분 전) 아 벌써 약속 시간 다가오네 준비해야 하는데 귀찮네`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          '연인과 주말 데이트! 만족스러운 계획을 세웠지만 계획에 변수가 발생했다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '극심한 스트레스를 받으며, 미리 플랜 B를 세워 놔서 다행이라고 생각한다',
          },
          {
            type: 'P',
            score: 5,
            content: '당황스럽긴 하지만, 임기응변을 발휘해 빠르게 대처한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '나와 가까운 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '배터리 항상 50% 이상 유지, 용도에 따른 어플 정리, 알람 수시로 읽어서 없앰',
          },
          {
            type: 'P',
            score: 5,
            content:
              '주변에 충전기 없으면 충전 안 함, 어플 정리는 몰아서, 안 읽은 알람 999+',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveProfile/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveProfile/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveProfile/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveProfile/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveProfile/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveProfile/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveProfile/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveProfile/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveProfile/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveProfile/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveProfile/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveProfile/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveProfile/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveProfile/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveProfile/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveProfile/INFP.jpg',
      },
    ],
  },
  // mazeHeartJP
  {
    info: {
      mainTitle: '私の心の迷路テスト',
      subTitle: '私の心の中を迷路で確かめてみよう',
      mainUrl: 'mazeHeartJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/mazeHeartJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/mazeHeartJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '誰かと会話をする時、私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '思わず言葉がおのずと出る',
          },
          {
            type: 'I',
            score: 5,
            content: '考えを整理した後、少し経ってから話した',
          },
        ],
      },
      {
        which: 'EI',
        question: '外出後、家に帰って横にならなければならない理由は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '家に帰ってきたら元気がなくなる.. 横にならないと',
          },
          {
            type: 'I',
            score: 5,
            content: '外に出たら疲れてしまう...横にならなければならない',
          },
        ],
      },
      {
        which: 'EI',
        question: '一週間の間、友達との約束回数は平均何回かな？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `3~4回以上、5回を超えたこともある`,
          },
          {
            type: 'I',
            score: 5,
            content: `1~2回未満、平日は家で休まなければならない`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'もし私一人で迷路の中に閉じ込められたら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `私がどうして迷路の中に閉じ込められるんだ..`,
          },
          {
            type: 'N',
            score: 5,
            content: `とりあえず落ち着いて壁に沿って行こう！ でもすごく怖そう`,
          },
        ],
      },
      {
        which: 'SN',
        question: '休暇に海外旅行に行きたいという友達に私の答えは？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '行けばいいじゃん~よく調べてみて！',
          },
          {
            type: 'N',
            score: 5,
            content: `もし一緒に海外旅行に行くならどこがいいかな？`,
          },
        ],
      },
      {
        which: 'SN',
        question: '雷が激しく鳴る空を見ながら思う私の考えは？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `わぁ、雷の音がすごく大きい`,
          },
          {
            type: 'N',
            score: 5,
            content: `わぁ、あの稲妻に当たったら僕はどうなるんだろう？`,
          },
        ],
      },
      {
        which: 'TF',
        question: `最近眠れないという友達に私の答えは？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'うーん、こうやってああやってみたの？ こういう方法がいいんだって',
          },
          {
            type: 'F',
            score: 5,
            content: '疲れただろうね。どうして眠れないの.. どうかした？',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '誰かが道にかわいい動物のぬいぐるみを捨てていたら、思う私の考えは？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `あそこにああやって捨てたらダメなのに`,
          },
          {
            type: 'F',
            score: 5,
            content: 'なんだ？人形かわいそう.. 寒そう..',
          },
        ],
      },
      {
        which: 'TF',
        question: `私が悩み相談をする理由は何だろうか？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '実質的な解決策が得られるかと思って',
          },
          {
            type: 'F',
            score: 5,
            content: '慰労と激励、応援を聞きたくて',
          },
        ],
      },
      {
        which: 'JP',
        question: '私の一週間はどう流れるかな？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `一週間のスケジュールがすでに決まっている`,
          },
          {
            type: 'P',
            score: 5,
            content: `流れるままに~明日は明日の私が~`,
          },
        ],
      },
      {
        which: 'JP',
        question: '急に会おうという友達に私の答えは？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '何時に会って何をするの？ ひとまず今は何かしてるからだめだよ',
          },
          {
            type: 'P',
            score: 5,
            content: '今？？うん、じゃあ何をするかは会って決めようか？',
          },
        ],
      },
      {
        which: 'JP',
        question: '家の前に新しいカフェができたら私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '元々行っていたところより近いね？ 明日のこの時間に一度行ってみないと',
          },
          {
            type: 'P',
            score: 5,
            content: 'ここにカフェができたね？ 後で行ってみないと',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartJP/INFP.jpg',
      },
    ],
  },
  // mazeHeartEng
  {
    info: {
      mainTitle: 'Maze test in my heart',
      subTitle: "Let's check my heart through a maze",
      mainUrl: 'mazeHeartEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/mazeHeartEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/mazeHeartEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'When you are talking to someone, how do you do?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I just say what comes out of my mouth',
          },
          {
            type: 'I',
            score: 5,
            content: 'After organizing my thoughts, I say things',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Why do you have to lie down at home after going out?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'Because I lose my energy suddenly when I get home.. I need to lie down',
          },
          {
            type: 'I',
            score: 5,
            content: "I'm tired from going out.. I have to lie down",
          },
        ],
      },
      {
        which: 'EI',
        question:
          'On average, how many times do you make appointments with your friends in a week?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I have more than 3-4 appointments, sometimes 5 or more`,
          },
          {
            type: 'I',
            score: 5,
            content: `I have one or two appointments, so I have to rest at home on weekdays`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What if I get stuck in a maze alone?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Why am I stuck in a maze?`,
          },
          {
            type: 'N',
            score: 5,
            content: `For now, I will stay calm and follow the wall! But, it must be so scary`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What can be your answer to a friend who wants to go abroad on vacation?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Why not? search for some good information!',
          },
          {
            type: 'N',
            score: 5,
            content: `If we were to travel abroad together, where would be the best place to go?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What do you think of when you look at the sky with heavy thunder and lightning?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Wow the thunder is so loud`,
          },
          {
            type: 'N',
            score: 5,
            content: `Wow, what if you get hit by that lightning?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What is your answer to a friend who has been unable to sleep lately?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Um, have you tried this? It works for me',
          },
          {
            type: 'F',
            score: 5,
            content:
              "Oh you must be tired.. Why can't you sleep? What's going on?",
          },
        ],
      },
      {
        which: 'TF',
        question: 'How would you feel if someone left a cute doll on the road?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `It should not be thrown away like that`,
          },
          {
            type: 'F',
            score: 5,
            content: 'Pity doll. I think it looks cold',
          },
        ],
      },
      {
        which: 'TF',
        question: `What motivated you to seek counseling?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I am just wondering if I can get a practical solution',
          },
          {
            type: 'F',
            score: 5,
            content: 'I want to hear comfort, encouragement, and support',
          },
        ],
      },
      {
        which: 'JP',
        question: 'How will your week go?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `My schedule for the week is already set`,
          },
          {
            type: 'P',
            score: 5,
            content: `As the water flows, Tomorrow is tomorrow~`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What can be your answer to a friend who suddenly asks to meet you?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "What time will we meet? But I think I can't because I'm doing something right now",
          },
          {
            type: 'P',
            score: 5,
            content:
              'Now? Well then, shall we meet first and decide what to do later?',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What would you do if a new cafe opened in front of your house?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'It is closer than the place I used to go. I will try there around this time tomorrow',
          },
          {
            type: 'P',
            score: 5,
            content: 'Oh, there is a café here, I will check it out later',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/mazeHeartEng/INFP.jpg',
      },
    ],
  },
  // readyToBoneIT
  {
    info: {
      mainTitle: "Siete pronti per l'amaro rimpianto?",
      subTitle: 'Siete pronti ad avere dolorosi rimpianti?',
      mainUrl: 'readyToBoneIT',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/readyToBoneIT-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/readyToBoneIT-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'IT',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          'Cosa fareste se un appuntamento del fine settimana venisse cancellato all improvviso?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'Prendo subito un altro appuntamento per il fine settimana',
          },
          {
            type: 'I',
            score: 5,
            content: 'Potrebbe piacermi e riposare a casa',
          },
        ],
      },
      {
        which: 'EI',
        question:
          "Come reagirebbe se ricevesse un'ottima accoglienza dal club in cui si è appena iscritto?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: "Sarei molto felice e andrei d'accordo con le persone",
          },
          {
            type: 'I',
            score: 5,
            content:
              'Sarà un peso per troppe accoglienze, ma sto cercando di adattarmi lentamente e con attenzione',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Cosa fareste quando vi sentite in imbarazzo a un evento?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Non sopporto l'imbarazzo e cercherei di parlare con qualcuno che non è in grado di farlo`,
          },
          {
            type: 'I',
            score: 5,
            content: `Potrebbe essere molto imbarazzante, ma aspetterei che qualcuno parli con me`,
          },
        ],
      },
      {
        which: 'SN',
        question: "Qual è l'amore che preferisco?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Una data pratica che mi permette di pensare al future`,
          },
          {
            type: 'N',
            score: 5,
            content: `Un appuntamento ideale che sognavo da tempo`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'Se lei fosse il protagonista di un dramma, che tipo di protagonista vorrebbe essere?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Uomo ricco, bello e popolare',
          },
          {
            type: 'N',
            score: 5,
            content: `Un eroe in un mondo fantastico che usa superpoteri`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'Quale può essere la vostra reazione se il vostro amico cambia improvvisamente argomento nel bel mezzo di una conversazione?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Non riesco a capire perché`,
          },
          {
            type: 'N',
            score: 5,
            content: `Continuo la conversazione`,
          },
        ],
      },
      {
        which: 'TF',
        question: `Cosa fareste se un amico fidato vi imprecasse alle spalle?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Mi arrabbiavo e mi chiedevo perché',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Ti sentiresti tradito e arrabbiato per il fatto che non ci vediamo più',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'Cosa fareste se un vostro amico dicesse qualcosa di inappropriato?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Parlerei direttamente`,
          },
          {
            type: 'F',
            score: 5,
            content:
              "Lo direi indirettamente per paura di ferire l'altra persona",
          },
        ],
      },
      {
        which: 'TF',
        question: `Cosa fareste se un vostro amico fosse molto malato?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Che cosa è successo? Dovresti andare in ospedale',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Stai bene? Deve farti molto male, anche se il tempo è così freddo',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Quando siete stressati?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Mi stresso quando le cose non vanno come programmato`,
          },
          {
            type: 'P',
            score: 5,
            content: `Mi stresso quando devo continuare a fare cose ripetitive`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'Qual è quello più vicino a voi?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Finisco le cose che devo fare e basta',
          },
          {
            type: 'P',
            score: 5,
            content: 'Spesso rimando le cose che devo fare',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Con quale tipo di persona si sente frustrato?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Una persona che inizia a lavorare poco prima della scadenza',
          },
          {
            type: 'P',
            score: 5,
            content: 'Pianificatore meticoloso',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneIT/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneIT/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneIT/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneIT/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneIT/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneIT/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneIT/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneIT/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneIT/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneIT/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneIT/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneIT/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneIT/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneIT/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneIT/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneIT/INFP.jpg',
      },
    ],
  },
  // speechHabitJP
  {
    info: {
      mainTitle: 'よく言う言葉のテスト',
      subTitle: '私がよく言うことは？',
      mainUrl: 'speechHabitJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/speechHabitJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/speechHabitJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          'もし、あなたが友人と遊んでいて、友人が知人を誘いたいと言ってきたらどうしますか？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '問題ないです。一緒に楽しもうよ！',
          },
          {
            type: 'I',
            score: 5,
            content: 'いきなりですか、それはちょっと違和感がありますね...',
          },
        ],
      },
      {
        which: 'EI',
        question: '甘い休日に友達から出勤の連絡が来たらどうする？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'そうですか！今は特に何もないんです！支度して行きます！',
          },
          {
            type: 'I',
            score: 5,
            content: 'いきなりですか？すみません、今日は仕事なんです',
          },
        ],
      },
      {
        which: 'EI',
        question: '大勢の人が集まる場に行くと、もっと嫌なことがあるのでは？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `気まずい沈黙がある状況`,
          },
          {
            type: 'I',
            score: 5,
            content: `多くの質問を通して、みんなが注目している状況`,
          },
        ],
      },
      {
        which: 'SN',
        question: '眠りにつく直前にすることは？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `何も考えずにただ眠ることに集中する`,
          },
          {
            type: 'N',
            score: 5,
            content: `あれこれ考えているうちに、ついつい遅くまで寝てしまう`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '久しぶりに楽しく観た映画がオープンエンドで終わってしまったら、あなたはどうしますか？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'それでも楽しめそうだが、エンディングがオープンなのが残念',
          },
          {
            type: 'N',
            score: 5,
            content: `気ままにオープンエンディングを想像してしまう`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '友達に「新しい人と別れたほうがいいのか、連絡を絶って別れたほうがいいのか」と聞かれたら、どうしますか？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `私なら、「私ではなく、あなたが選んでください」と言うでしょうし、なぜ友人がそんな無駄なことを聞くのか不思議に思います。`,
          },
          {
            type: 'N',
            score: 5,
            content: `私なら「あぁ、どっちも最悪だなぁ」くらいで、真剣に考えますね`,
          },
        ],
      },
      {
        which: 'TF',
        question: `もし、あなたの友人が約束の時間に遅れて言い訳をしたら、どうしますか？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '理解できる と言いたいところですが(大丈夫です、ご安心ください！)',
          },
          {
            type: 'F',
            score: 5,
            content: '私なら、まずは謝ってください と言いますね。(怒るかも)',
          },
        ],
      },
      {
        which: 'TF',
        question: '自分の知っていることを、他の人にどう説明する？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `辞書的意味と意味を正確に説明してくれる`,
          },
          {
            type: 'F',
            score: 5,
            content: '他の人に分かりやすく説明する',
          },
        ],
      },
      {
        which: 'TF',
        question: `悩みを打ち明けるとき、どんなことを期待しますか？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '共感と解決に期待します',
          },
          {
            type: 'F',
            score: 5,
            content: '自分の言葉に共感と安らぎを求める',
          },
        ],
      },
      {
        which: 'JP',
        question: 'どんなことに自信があるのでしょうか？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `一つのことをやり遂げる`,
          },
          {
            type: 'P',
            score: 5,
            content: `マルチタスクをこなす`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'もし、1ヶ月という期限付きのタスクや課題を与えられたら、あなたはどうしますか？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '後から追加があるかもしれないので、事前に終わらせておくと',
          },
          {
            type: 'P',
            score: 5,
            content:
              'まだ時間はたっぷりあるので、一歩一歩ゆっくりやっていこうと思います',
          },
        ],
      },
      {
        which: 'JP',
        question: 'やることがある中で遊びに来たら、どうするんでしょう？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'やるべきことがどんどん思い浮かんで気になる',
          },
          {
            type: 'P',
            score: 5,
            content: 'とにかくもう決めたことなので、楽しもうと思います',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitJP/INFP.jpg',
      },
    ],
  },
  // mazeHeart
  {
    info: {
      mainTitle: '내 맘속 미로 테스트',
      subTitle: '내 마음속을 미로로 확인해 보자',
      mainUrl: 'mazeHeart',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/mazeHeart-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/mazeHeart-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '누군가와 대화를 할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '나도 모르게 말이 저절로 나온다',
          },
          {
            type: 'I',
            score: 5,
            content: '생각으로 정리한 후 조금 있다가 나온다',
          },
        ],
      },
      {
        which: 'EI',
        question: '외출 후 집에 돌아와서 누워야 하는 이유는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '집에만 들어오면 기운이 없어져.. 누워야 해',
          },
          {
            type: 'I',
            score: 5,
            content: '밖에 나갔다 와서 기 다 빨렸어.. 누워야 해',
          },
        ],
      },
      {
        which: 'EI',
        question: '일주일 동안 친구들과의 약속 횟수는 평균 몇 번일까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `3~4번 이상, 5번 넘은 적도 있음`,
          },
          {
            type: 'I',
            score: 5,
            content: `1~2번 미만, 평일엔 집에서 쉬어야 함`,
          },
        ],
      },
      {
        which: 'SN',
        question: '만약에 나 혼자 미로 속에 갇히게 된다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `내가 왜 미로 속에 갇히느냔 말이야..`,
          },
          {
            type: 'N',
            score: 5,
            content: `일단 침착하고 벽을 따라가봐야지! 근데 너무 무섭겠다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '휴가 때 해외여행 가고 싶다는 친구에게 나의 대답은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '가면 되지~ 잘 알아봐바!',
          },
          {
            type: 'N',
            score: 5,
            content: `만약에 같이 해외여행을 간다면 어디가 좋을까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '천둥 번개가 심하게 치는 하늘을 보며 드는 나의 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `와 천둥 소리 뭐야 엄첨 크다`,
          },
          {
            type: 'N',
            score: 5,
            content: `와 저 번개 맞으면 난 어떻게 되는 걸까? ㄷㄷㄷ`,
          },
        ],
      },
      {
        which: 'TF',
        question: `요즘 잠을 못 잔다는 친구에게 내 대답은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '음 이렇게 저렇게 해봤어? 이런 방법이 좋데',
          },
          {
            type: 'F',
            score: 5,
            content: '아고 피곤하겠다.. 왜 잠을 못 자지ㅜ 무슨 일 있어?',
          },
        ],
      },
      {
        which: 'TF',
        question: '누군가 길에 귀여운 동물 인형을 버려 놨다면 드는 내 생각은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `저기다 저렇게 버리면 안 될 텐데`,
          },
          {
            type: 'F',
            score: 5,
            content: '뭐야 인형 불쌍해.. 추워 보여..',
          },
        ],
      },
      {
        which: 'TF',
        question: `내가 고민 상담을 하는 이유는 무엇일까?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '실질적인 해결책을 얻을 수 있을까 해서',
          },
          {
            type: 'F',
            score: 5,
            content: '위로와 격려, 응원을 듣고 싶어서',
          },
        ],
      },
      {
        which: 'JP',
        question: '나의 일주일은 어떻게 흘러갈까?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `일주일 동안의 스케줄이 이미 다 정해져 있음`,
          },
          {
            type: 'P',
            score: 5,
            content: `흘러가면 흘러가는 대로~ 내일은 내일의 내가~`,
          },
        ],
      },
      {
        which: 'JP',
        question: '갑자기 만나자는 친구에게 내 대답은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '몇 시에 만나서 뭐 할 건데? 일단 지금은 뭐 하고 있어서 안돼',
          },
          {
            type: 'P',
            score: 5,
            content: '지금?? 음 그럼 뭐 할지는 만나서 정할까?',
          },
        ],
      },
      {
        which: 'JP',
        question: '집 앞에 새로운 카페가 생겼다면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '원래 가던 곳 보다 가깝네? 내일 이 시간에 한번 가봐야겠다',
          },
          {
            type: 'P',
            score: 5,
            content: '여기 카페가 생겼네? 나중에 가봐야겠다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/mazeHeart/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/mazeHeart/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/mazeHeart/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/mazeHeart/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/mazeHeart/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/mazeHeart/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/mazeHeart/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/mazeHeart/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/mazeHeart/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/mazeHeart/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/mazeHeart/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/mazeHeart/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/mazeHeart/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/mazeHeart/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/mazeHeart/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/mazeHeart/INFP.jpg',
      },
    ],
  },
  // loveRecipe
  {
    info: {
      mainTitle: '연애 레시피 테스트',
      subTitle: '나의 연애 성향을 레시피로 요리해보자',
      mainUrl: 'loveRecipe',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/loveRecipe-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveRecipe-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '연인과의 데이트 사람 많은 곳에서 데이트 한다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '역시 핫플이라 사람이 많네! 신난다!',
          },
          {
            type: 'I',
            score: 5,
            content: '사람 너무 많아.. 기빨려..',
          },
        ],
      },
      {
        which: 'EI',
        question: '나는 연인에게?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '적극적으로 먼저 애정 표현하는 편이다.',
          },
          {
            type: 'I',
            score: 5,
            content: '먼저 애정 표현을 받고, 애정 표현하는 편이다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '관심 있는 사람에게 내가 하는 표현 방식은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `질문과 관심으로 관심을 표현한다`,
          },
          {
            type: 'I',
            score: 5,
            content: `공감과 리액션으로 관심을 표현한다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인에게 요리를 해준다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `검색해서 나온 요리 레시피대로 만든다`,
          },
          {
            type: 'N',
            score: 5,
            content: `요리는 정성이지! 나의 손맛으로 만든다`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '연인이 “만약에 나랑 너 절친이랑 바다에 빠졌어 누구 먼저 구할 거야?”라고 한다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '그런 일은 일어나지 않아..',
          },
          {
            type: 'N',
            score: 5,
            content: `왜 둘이 같이 빠져 있는 거지??!!`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연애하면서 내가 더 많이 걱정하는 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `현실 걱정`,
          },
          {
            type: 'N',
            score: 5,
            content: `미래 걱정`,
          },
        ],
      },
      {
        which: 'TF',
        question: `연인이 고민을 이야기했는데 이해가 잘 안 간다면 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '이해는 잘 안 가지만, 일단 다 들어주고, 내 의견을 제시한다',
          },
          {
            type: 'F',
            score: 5,
            content: '이해는 안 가지만, 공감은 가서 그럴 수 있겠다고 한다',
          },
        ],
      },
      {
        which: 'TF',
        question: '연인과 갈등 상황에 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `화가 나더라도 논리적으로 이야기한다`,
          },
          {
            type: 'F',
            score: 5,
            content: '할 말은 많지만, 눈물부터 나올 것 같다',
          },
        ],
      },
      {
        which: 'TF',
        question: `두 연인 중 내가 더 싫은 연인은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '갈등 상황에 논리 0% 징징거리기만 하는 연인',
          },
          {
            type: 'F',
            score: 5,
            content: '내가 감정적으로 힘든데, 공감 0% 해결책만 제시하는 연인',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 여행을 간다면 나와 가까운 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `스스로 세세하게 계획을 세우는 편이다`,
          },
          {
            type: 'P',
            score: 5,
            content: `연인이 세우는 계획에 적극적으로 따르는 편이다`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          '목적지로 향하던 중 인파가 몰려 있는 곳을 발견했는데, 연인이 가보자고 한다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '그냥 가던 길을 마저 가자~',
          },
          {
            type: 'P',
            score: 5,
            content: '그래! 가보자!',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 더 불편한 연인은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '정리에 집착, 계획에 집착하는 연인',
          },
          {
            type: 'P',
            score: 5,
            content: '아무 대책 없이 무계획인 연인',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/loveRecipe/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/loveRecipe/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/loveRecipe/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/loveRecipe/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/loveRecipe/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/loveRecipe/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/loveRecipe/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/loveRecipe/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/loveRecipe/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/loveRecipe/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/loveRecipe/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/loveRecipe/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/loveRecipe/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/loveRecipe/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/loveRecipe/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/loveRecipe/INFP.jpg',
      },
    ],
  },
  // loveFlameIT
  {
    info: {
      mainTitle: "Test della fiamma dell'amore",
      subTitle: "In effetti, quanto sarà calda la vostra storia d'amore?",
      mainUrl: 'loveFlameIT',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveFlameIT-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveFlameIT-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'IT',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question:
          "Cosa fareste se all'improvviso incontraste qualcuno che vi piace a un evento?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Cercherei di conoscere attivamente la persona.',
          },
          {
            type: 'I',
            score: 5,
            content:
              "Lentamente cercherò di cogliere l'occasione per chiacchierare con la persona.",
          },
        ],
      },
      {
        which: 'EI',
        question:
          'Cosa si dice quando si è appena iniziato a frequentare qualcuno?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'Dico tutto di me, esagerando con il TMI (too much information).',
          },
          {
            type: 'I',
            score: 5,
            content:
              'Non parlo molto di me e rispondo solo alle domande che mi vengono poste.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          "Come reagireste se il vostro amante vi proponesse di uscire con i suoi amici all'inizio della relazione?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Anche se è un po' improvviso, voglio dire "Sì, perché no".`,
          },
          {
            type: 'I',
            score: 5,
            content: `Rifiuterei perché è improvviso e un po' gravoso.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'Come reagireste se il vostro amante parlasse improvvisamente di un futuro ideale?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Mi piacerebbe, ma penso che sia impossibile nella realtà.`,
          },
          {
            type: 'N',
            score: 5,
            content: `Condividerei i miei pensieri ideali.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'Quale può essere la vostra reazione quando il vostro amante vi pone domande come "e se"?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Dirò: "Non preoccuparti, non succederà".',
          },
          {
            type: 'N',
            score: 5,
            content: `Ci penserò seriamente e risponderò.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          "Se dovesse preparare il cibo per l'amante, come lo preparerebbe?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Cucinerei secondo la ricetta.`,
          },
          {
            type: 'N',
            score: 5,
            content: `Cucinerei con tutto il cuore senza pesare.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `Se il vostro amante vi mentisse, quale sarebbe la vostra reazione?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Sarei così delusa, ma prima chiederei perché il mio amante ha mentito.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Sarei molto arrabbiata perché il mio amante mi ha mentito.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'Cosa fareste se il vostro amante fosse molto arrabbiato con voi?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Mi scuserei per eventuali errori e spiegherei in seguito.`,
          },
          {
            type: 'F',
            score: 5,
            content: 'Mi scuserei e cercherei di risolvere il problema.',
          },
        ],
      },
      {
        which: 'TF',
        question: `Quali potrebbero essere le vostre intenzioni se chiedeste al vostro amore un po' di tempo da soli?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Avevo solo bisogno di tempo per risolvere la relazione, perché stavo pensando in modo troppo emotivo.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Avevo già risolto la relazione, ma cercavo di dare al mio amante la possibilità di riflettere.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Cosa farete subito dopo aver rotto con il mio amante?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Farò di tutto per liberarmi del blues, ma alla fine mi tratterrò.`,
          },
          {
            type: 'P',
            score: 5,
            content: `Per liberarmi dal sentimento depressivo, cambio pettinatura o compro cose.`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'Cosa farete quando vi sentirete meglio dopo la rottura?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Cancello i miei piani con il mio ex e ne faccio uno nuovo.',
          },
          {
            type: 'P',
            score: 5,
            content: 'Sarò pronto a incontrare qualcuno di nuovo.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Cosa fareste il giorno prima di un appuntamento al buio con una persona nuova?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: "Penserei a cosa indossare, all'acconciatura, ecc.",
          },
          {
            type: 'P',
            score: 5,
            content:
              'Prima di tutto dormirei a sufficienza perché decido sempre in giornata.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameIT/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameIT/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameIT/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameIT/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameIT/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameIT/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameIT/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameIT/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameIT/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameIT/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameIT/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameIT/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameIT/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameIT/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameIT/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameIT/INFP.jpg',
      },
    ],
  },
  // lettering
  {
    info: {
      mainTitle: '레터링 성격 테스트',
      subTitle: '레터링으로 알아보는 내 성격은?',
      mainUrl: 'lettering',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/lettering-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/lettering-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '내게 가장 행복한 시간을 고르라면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구들과 바깥세상을 구경하며 수다 떠는 시간',
          },
          {
            type: 'I',
            score: 5,
            content: '침대와 물아일체 되어 쉬는 혼자만의 시간',
          },
        ],
      },
      {
        which: 'EI',
        question: '버스를 탔는데 아는 친구가 앉아있다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '뭐야! 여기서 다 만나네? 반갑다~~ 잘 지냈어??',
          },
          {
            type: 'I',
            score: 5,
            content: '어쩌지 일단 모르는 척해야겠다 (살짝 숨기)',
          },
        ],
      },
      {
        which: 'EI',
        question: '나에게 갑자기 어떤 질문을 한다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `나도 모르게 대답부터 해버린 후 생각한다`,
          },
          {
            type: 'I',
            score: 5,
            content: `어떤 대답을 할지 생각 후 천천히 대답한다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내게 할 말이 있다며 저녁에 보자는 친구! 내 모습은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `무슨 말인지 이따 만나보면 알겠지 뭐`,
          },
          {
            type: 'N',
            score: 5,
            content: `도대체 어떤 할 말이지?? 큰일인가? 궁금해 미치겠네`,
          },
        ],
      },
      {
        which: 'SN',
        question: '차가 너무 막히는 도로에서 드는 내 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '이래서 언제 도착하나.. 한세월 걸리겠네',
          },
          {
            type: 'N',
            score: 5,
            content: `버튼 누르면 차가 납작해져서 차 사이를 그냥 슝슝~`,
          },
        ],
      },
      {
        which: 'SN',
        question: '만약에~ 라면? 질문에 대한 내 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `또 시작이네 피곤.. 그냥 대충 대답 해야지`,
          },
          {
            type: 'N',
            score: 5,
            content: `만약 나라면 이렇게 저렇게 꿀잼이잖아? 킄킄`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구와 말다툼 후 화해를 했다! 이후 내 모습은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '화해했으니 끝! 이후 생각하지 않음',
          },
          {
            type: 'F',
            score: 5,
            content: '아직도 기분이 상해 있는 건 아닌지 계속 걱정됨',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '친구가 더운 날 땀을 뻘뻘 흘리며 요리를 만들어줬다! 내 반응은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `오 맛있어 보이는데? 어떻게 만들었어?`,
          },
          {
            type: 'F',
            score: 5,
            content: '더운데 고생 많았어ㅜ 얼른 앉아서 같이 먹자!',
          },
        ],
      },
      {
        which: 'TF',
        question: `어제 일 때문에 밤을 새웠다는 친구에게 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '일이 그렇게 바빠? 야근수당은 줘?',
          },
          {
            type: 'F',
            score: 5,
            content: '아이구ㅜ 진짜 피곤하겠다ㅜ 일이 많은가 보네?',
          },
        ],
      },
      {
        which: 'JP',
        question: '오늘 만나기로 한 친구가 약속 장소를 바꾸자고 한다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `그럼 일정 다 바꿔야 하잖아.. 별론데`,
          },
          {
            type: 'P',
            score: 5,
            content: `바꿔도 상관 없지~! 그럼 어디서 만날까?`,
          },
        ],
      },
      {
        which: 'JP',
        question: '오늘 하려고 했던 일을 다 못 마치면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '잘 때까지 계속 찝찝하고 생각남',
          },
          {
            type: 'P',
            score: 5,
            content: '내일의 내가 하면 되기 때문에 괜찮음',
          },
        ],
      },
      {
        which: 'JP',
        question: '갑자기 카페를 가자는 친구! 내 대답은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '어떤 카페? 몇 시에 만나서 어디로 갈 건데?',
          },
          {
            type: 'P',
            score: 5,
            content: '그래! 이따 저녁쯤 만나서 어디로 갈지 정하자!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/lettering/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/lettering/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/lettering/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/lettering/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/lettering/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/lettering/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/lettering/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/lettering/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/lettering/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/lettering/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/lettering/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/lettering/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/lettering/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/lettering/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/lettering/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/lettering/INFP.jpg',
      },
    ],
  },
  // tapeCharacteristic
  {
    info: {
      mainTitle: '테이프 성격 테스트',
      subTitle: '나의 성격을 테이프로 붙여보자!',
      mainUrl: 'tapeCharacteristic',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/tapeCharacteristic-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/tapeCharacteristic-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '친구들과 대화 중 쉬지 않고, 떠드는 친구를 볼 때 내 생각은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '나도 할 말 많은데 쟤 자꾸 왜 나랑 오디오 겹치지?',
          },
          {
            type: 'I',
            score: 5,
            content: '쟤는 지치지도 않나, 쉬지 않고 얘기하네 신기..',
          },
        ],
      },
      {
        which: 'EI',
        question: '주말에 뭐 하냐고 묻는 친구에게 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '딱히 뭐 없는데 왜? 만날래?',
          },
          {
            type: 'I',
            score: 5,
            content: '아.. 나 약속 있어! (사실 약속 없음)',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구들이 밤이 늦었는데, 날 안 보내주려고 한다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `피할 수 없으면 즐겨! 지칠 때까지 신나게 논다!`,
          },
          {
            type: 'I',
            score: 5,
            content: `돔황챠~! 계속 집에 갈 타이밍을 노린다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '여행을 가려고 비행기를 탔을 때 내 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `기내식 맛없다는데 뭐 라도 사올 걸 그랬나?`,
          },
          {
            type: 'N',
            score: 5,
            content: `갑자기 바다에 떨어지면 어떡하지? 구명조끼 어디 있더라..`,
          },
        ],
      },
      {
        which: 'SN',
        question: '미술작품 전시회를 갔을 때, 내가 중점적으로 보는 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '작가의 그림 솜씨, 작품 설명',
          },
          {
            type: 'N',
            score: 5,
            content: `작품을 만든 작가의 의도, 숨겨진 의미 분석`,
          },
        ],
      },
      {
        which: 'SN',
        question: '멍하니 있는 나에게 “갑자기 무슨 생각해?” 친구의 말에 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `아무 생각 없는데?`,
          },
          {
            type: 'N',
            score: 5,
            content: `아, 별거 아니야!! 설명하자면 길어서~`,
          },
        ],
      },
      {
        which: 'TF',
        question: `보고 있는 영화에서 주인공이 위험에 빠졌다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '오우.. 흥미진진한데?? 아니 근데 저걸 왜 저렇게 설정했지?',
          },
          {
            type: 'F',
            score: 5,
            content: '안돼!! 도망가! 아오 답답해!! ㅠㅠ',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 “나 차 사고 났어”라고 연락이 왔다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `헐 보험 들었어? 아니 뭐하다가??`,
          },
          {
            type: 'F',
            score: 5,
            content: '헉!.. 괜찮아?! 다치진 않았어??',
          },
        ],
      },
      {
        which: 'TF',
        question: `누군가 나를 싫어 한다고 전해 들었을 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '누구? 왜 나를 싫어하지? 뭐 그러든지 말든지~',
          },
          {
            type: 'F',
            score: 5,
            content: '내가 뭐 기분 나쁘게 한적 있나..ㅠ(마상)',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '같이 여행을 가기로 한 친구가 시간, 분 단위로 짠 계획표를 보여준다면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `비슷하게 짠 나의 계획표을 보여주며, 합의 점을 찾아서 같이 계획을 수정한다`,
          },
          {
            type: 'P',
            score: 5,
            content: `조금 숨 막히지만, 그래도 든든하다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '갑자기 만나자는 친구의 연락을 받았다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '아 갑자기는 안될 거 같아, 다음엔 늦어도 전날 알려줘!',
          },
          {
            type: 'P',
            score: 5,
            content: '마침 뭐 없었는데, 오케이 콜. 나갈 게 좀만 기다려.',
          },
        ],
      },
      {
        which: 'JP',
        question: '세워 놓은 계획이 틀어졌을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '매우 화가 나지만, 혹시 모를 상황을 대비한 플랜 B를 실행한다',
          },
          {
            type: 'P',
            score: 5,
            content: '어차피 세워 놓은 계획도 미약했다. 임기응변으로 대처한다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/tapeCharacteristic/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/tapeCharacteristic/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/tapeCharacteristic/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/tapeCharacteristic/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/tapeCharacteristic/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/tapeCharacteristic/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/tapeCharacteristic/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/tapeCharacteristic/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/tapeCharacteristic/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/tapeCharacteristic/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/tapeCharacteristic/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/tapeCharacteristic/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/tapeCharacteristic/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/tapeCharacteristic/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/tapeCharacteristic/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/tapeCharacteristic/INFP.jpg',
      },
    ],
  },
  // colorLabelStickerEng
  {
    info: {
      mainTitle: 'Label Sticker Color Test',
      subTitle: 'What is your label sticker that is different for each color?',
      mainUrl: 'colorLabelStickerEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/colorLabelStickerEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/colorLabelStickerEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          'If your friend asks to take a picture with you in a crowded tourist destination, what can be your reaction?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              "I don't care about the surroundings and take several shots until I get the best one",
          },
          {
            type: 'I',
            score: 5,
            content:
              'I will be conscious of my surroundings so I will quickly take pictures',
          },
        ],
      },
      {
        which: 'EI',
        question: 'If you have an appointment this weekend, what do you think?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I hope the weekend comes soon! So, I can have fun.',
          },
          {
            type: 'I',
            score: 5,
            content:
              "It can be annoying…? I think I shouldn't cancel my appointment...",
          },
        ],
      },
      {
        which: 'EI',
        question:
          'When you say “bye” to your friend after spending time together, which one is closer to you?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I feel sorry and want to spend more time with my friend`,
          },
          {
            type: 'I',
            score: 5,
            content: `I'm happy that I can finally go home.`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do you think about when you come down after climbing?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I'm tired. .Gotta go down quickly and rest`,
          },
          {
            type: 'N',
            score: 5,
            content: `I wish I could slide all the way down the mountain`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do you often say when arguing?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: "I'm just talking right now / I don't mean that?",
          },
          {
            type: 'N',
            score: 5,
            content: `I know you did before / I felt that way!!`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What would you think if there was a thunderstorm in rainy day?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Oh the thunder is so loud `,
          },
          {
            type: 'N',
            score: 5,
            content: `If I get struck by lightning and have superpowers, will I be able to teleport? oh i'm worried`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What compliment would you like to hear more?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Wow, you did really well! How did you do it? ',
          },
          {
            type: 'F',
            score: 5,
            content:
              "You've had a lot of trouble~ You've worked hard! You are the best!",
          },
        ],
      },
      {
        which: 'TF',
        question: 'What do you usually do when you have a conversation?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `When I say A, it is A. There is no hidden meaning`,
          },
          {
            type: 'F',
            score: 5,
            content: 'I said A, but I believe you will know B, C, D, etc.',
          },
        ],
      },
      {
        which: 'TF',
        question: `Why do you share your concerns?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I am talking about it to solve the concerns',
          },
          {
            type: 'F',
            score: 5,
            content:
              "I don't need to solve the concerns but sympathize with my feeling",
          },
        ],
      },
      {
        which: 'JP',
        question: 'What is your shopping style?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I have a set time for shopping, so I rush to buy only what I need`,
          },
          {
            type: 'P',
            score: 5,
            content: `I look around many other stores slowly and enjoy shopping itself`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What do you think of when you see a schedule organized by hour?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'It seems stable and good plan. Gotta follow this schedule as much as possible',
          },
          {
            type: 'P',
            score: 5,
            content: "Should it be like this? I can't breath…",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What is your style of choosing the menu when you go to a restaurant?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I do research in advance and decide exactly what to eat',
          },
          {
            type: 'P',
            score: 5,
            content:
              'I take a quick glance at the menu from internet and decide it at the restaurant.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelStickerEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelStickerEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelStickerEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelStickerEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelStickerEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelStickerEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelStickerEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelStickerEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelStickerEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelStickerEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelStickerEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelStickerEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelStickerEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelStickerEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelStickerEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelStickerEng/INFP.jpg',
      },
    ],
  },
  // postCard
  {
    info: {
      mainTitle: '엽서 성격 테스트',
      subTitle: '나의 성격을 엽서로 꾸며보자!',
      mainUrl: 'postCard',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/postCard-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/postCard-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '친구들과 대화할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '쟤 말 끝나면 나는 이 말 해야지',
          },
          {
            type: 'I',
            score: 5,
            content: '친구들의 말에 따라 리액션이나, 대답한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구와 약속이 취소되었을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '급하게 다른 친구들에게 연락을 돌려 본다',
          },
          {
            type: 'I',
            score: 5,
            content: '은근히 좋아하면서 집에서 푹 쉰다',
          },
        ],
      },
      {
        which: 'EI',
        question: '일주일 동안 열심히 일하고 난 뒤 찾아온 주말에 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `일주일 내내 회사 안에만 있었으니, 밖에서 친구들 만나서 신나게 놀아야지`,
          },
          {
            type: 'I',
            score: 5,
            content: `일주일 내내 회사에만 나갔으니, 주말엔 집에서 푹 쉬어야지`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 더 잘할 수 있는 일은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `꼼꼼하고 섬세한 작업을 필요로 하는 일`,
          },
          {
            type: 'N',
            score: 5,
            content: `상상력과 창의력을 필요로 하는 일`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '“만약 초능력 1개를 가질 수 있다면?” 이라는 질문에 내 대답은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '그런 일은 일어나지 않아.',
          },
          {
            type: 'N',
            score: 5,
            content: `초능력 1개?? 나는 음…(고민 중)`,
          },
        ],
      },
      {
        which: 'SN',
        question: '정말 재밌게 본 영화가 열린 결말로 끝이 났다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `재밌게 본 영화가 찝찝한 열린 결말이라 아쉽다`,
          },
          {
            type: 'N',
            score: 5,
            content: `결말 이후의 스토리를 내 마음대로 상상해 본다`,
          },
        ],
      },
      {
        which: 'TF',
        question: `내가 아는 지식을 상대방에게 설명할 때 나와 가까운 것은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '사전적 뜻과 의미를 명확하게 설명한다',
          },
          {
            type: 'F',
            score: 5,
            content: '뜻과 의미를 상대방이 알아듣기 쉽게 비유해서 설명한다',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구들과 여름휴가를 가는데 운전하는 친구가 졸려 한다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `휴게소 및 졸음 쉼터 위치를 찾아서 알려준다`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '옆에서 계속 말을 걸거나, 너무 힘들면 바꿔 가면서 하자고 말해준다.',
          },
        ],
      },
      {
        which: 'TF',
        question: `호감이 있는 상대에게 내가 더 많이 하는 것은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '지속적인 관심과 질문',
          },
          {
            type: 'F',
            score: 5,
            content: '진심 담은 리액션과 공감',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 더 불편함을 느끼는 사람은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `미리 세워 둔 계획 다 무시하고 자기 마음대로 하려는 사람`,
          },
          {
            type: 'P',
            score: 5,
            content: `더 좋은 방법이 생겨도 계획대로만 하자고 강요하는 사람`,
          },
        ],
      },
      {
        which: 'JP',
        question: '목표를 이루어 나갈 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '한 가지 목표를 정해 놓고 꾸준히 한다',
          },
          {
            type: 'P',
            score: 5,
            content: '여러 가지 목표를 정하고 다양한 시도를 해본다',
          },
        ],
      },
      {
        which: 'JP',
        question: '대중교통을 이용할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '목적지까지 최단 경로를 찾고, 언제 오는지 확인 후 맞춰서 나간다.',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 나가서 기다리다가, 안 오면 언제 오는지 찾아본다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/postCard/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/postCard/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/postCard/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/postCard/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/postCard/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/postCard/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/postCard/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/postCard/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/postCard/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/postCard/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/postCard/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/postCard/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/postCard/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/postCard/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/postCard/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/postCard/INFP.jpg',
      },
    ],
  },
  // characteristicChracterIT
  {
    info: {
      mainTitle: 'Test di personalità',
      subTitle: 'Qual è la sua personalità come personaggio?',
      mainUrl: 'characteristicChracterIT',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/characteristicChracterIT-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/characteristicChracterIT-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'IT',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'Qual è quello più vicino a voi?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'Non sono timida e vado facilmente d accordo con le persone',
          },
          {
            type: 'I',
            score: 5,
            content: 'Ci metto molto tempo a conoscere le persone',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Come ti comporti con i tuoi amici?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Tendo a contattare prima',
          },
          {
            type: 'I',
            score: 5,
            content: 'Tendo a rispondere solo alle chiamate in entrata',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Cosa si fa quando succede qualcosa di brutto?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Cerco di uscire per incontrare le persone per tranquillizzarmi`,
          },
          {
            type: 'I',
            score: 5,
            content: `Organizzo tranquillamente i miei pensieri a casa`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'Se state per uscire e improvvisamente piove, quale può essere la vostra reazione?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Oh, sta piovendo. Devo portare un ombrello`,
          },
          {
            type: 'N',
            score: 5,
            content: `Ho paura di cadere sulla strada scivolosa`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'Come reagireste se il personale di un bar vi servisse con noncuranza la birra su un vassoio minuscolo?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Oh, arriva la birra',
          },
          {
            type: 'N',
            score: 5,
            content: `Mi viene l'ansia a pensare di rovesciarlo sul nostro tavolo`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'Cosa fate quando fate la doccia?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Faccio la doccia senza pensarci`,
          },
          {
            type: 'N',
            score: 5,
            content: `Faccio la doccia canticchiando una canzone nel bagno pieno di eco`,
          },
        ],
      },
      {
        which: 'TF',
        question: `Quale può essere la vostra reazione quando il vostro amico tira fuori un oggetto strano che non avete mai visto prima?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "Wow, cosa c'è?",
          },
          {
            type: 'F',
            score: 5,
            content: 'Wow, sembra incredibile',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'Quale può essere la vostra reazione quando acquistate nuovi mobili per la vostra nuova casa?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Verificherò attentamente la convenienza, il prezzo, le dimensioni, ecc.`,
          },
          {
            type: 'F',
            score: 5,
            content:
              'Il design è molto bello e penserò a come inserirlo nella mia nuova casa',
          },
        ],
      },
      {
        which: 'TF',
        question: `Cosa fareste quando un vostro amico vi chiede un consiglio sul suo problema?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Ascolterei e cercherei di risolvere il problema',
          },
          {
            type: 'F',
            score: 5,
            content: 'Ascolto e conforto',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Cosa fareste se doveste passare del tempo con gli amici mentre avete del lavoro da fare?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Non riesco a godermi il mio tempo a causa delle preoccupazioni del lavoro`,
          },
          {
            type: 'P',
            score: 5,
            content: `In ogni caso mi godo il tempo`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Cosa fareste se il vostro amico cambiasse improvvisamente posto?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Sarebbe confuso perché abbiamo già un piano',
          },
          {
            type: 'P',
            score: 5,
            content: "Anche se è improvviso, sono d'accordo",
          },
        ],
      },
      {
        which: 'JP',
        question: 'Quale preferisce?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Piano e ordine',
          },
          {
            type: 'P',
            score: 5,
            content: 'Possibilità e novità',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterIT/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qnsdnlrlapdlzj',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterIT/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'rkstjqdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterIT/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dlsTkdkTk',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterIT/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'rPghlrdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterIT/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qksgkddk',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterIT/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhalstkdekatk',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterIT/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'tkckdnjs',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterIT/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'wjswktkwjs',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterIT/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'akssmdwwnRns',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterIT/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'vmfhdlfrTlqfj',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterIT/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'thdnffltm',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterIT/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rkawjdzjsxmfhffj',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterIT/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'vorvhrfj',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterIT/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dosmfrdmsdl',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterIT/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'dbflapsxkf',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterIT/INFP.jpg',
      },
    ],
  },
  // characteristicChracterGer
  {
    info: {
      mainTitle: 'Charaktertest zur Persönlichkeit',
      subTitle: 'Was ist Ihre Persönlichkeit als Figur?',
      mainUrl: 'characteristicChracterGer',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/characteristicChracterGer-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/characteristicChracterGer-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Ger',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'Welche ist in Ihrer Nähe?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'Ich bin nicht schüchtern und komme leicht mit Menschen zurecht',
          },
          {
            type: 'I',
            score: 5,
            content: 'Ich brauche viel Zeit, um Menschen kennen zu lernen',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Was machen Sie mit Ihren Freunden?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Ich neige dazu, zuerst Kontakt aufzunehmen',
          },
          {
            type: 'I',
            score: 5,
            content: 'Ich neige dazu, nur auf eingehende Anrufe zu antworten',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Was tun Sie, wenn etwas Schlimmes passiert?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Ich versuche, Leute zu treffen, um mich zu beruhigen`,
          },
          {
            type: 'I',
            score: 5,
            content: `Ich ordne meine Gedanken in aller Ruhe zu Hause`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'Wie reagieren Sie, wenn Sie gerade ausgehen wollen und es plötzlich regnet?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Oh, es regnet. Ich muss einen Regenschirm mitnehmen`,
          },
          {
            type: 'N',
            score: 5,
            content: `Ich habe Angst, auf der glatten Straße zu stürzen`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'Wie würden Sie reagieren, wenn Ihnen das Personal in einer Bar achtlos ein Bier auf einem winzigen Tablett servieren würde?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Oh, hier kommt das Bier',
          },
          {
            type: 'N',
            score: 5,
            content: `Ich bekomme Angst, wenn ich daran denke, dass ich auf unseren Tisch kleckere.`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'Was machen Sie, wenn Sie duschen?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Ich dusche einfach, ohne nachzudenken`,
          },
          {
            type: 'N',
            score: 5,
            content: `Ich dusche und summe dabei ein Lied im hallenden Badezimmer`,
          },
        ],
      },
      {
        which: 'TF',
        question: `Wie kannst du reagieren, wenn dein Freund einen seltsamen Gegenstand mitbringt, den du noch nie gesehen hast?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Wow, was ist das?',
          },
          {
            type: 'F',
            score: 5,
            content: 'Wow, das sieht toll aus',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'Wie können Sie reagieren, wenn Sie neue Möbel für Ihre neue Wohnung kaufen?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Ich werde die Bequemlichkeit, den Preis, die Größe usw. sorgfältig prüfen`,
          },
          {
            type: 'F',
            score: 5,
            content:
              'Oh, das Design sieht sehr schön aus, und ich werde mir überlegen, wie es in mein neues Zuhause passen wird',
          },
        ],
      },
      {
        which: 'TF',
        question: `Was würden Sie tun, wenn Ihr Freund oder Ihre Freundin Sie bei einem Problem um Rat fragt?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Ich würde zuhören und versuchen, das Problem zu lösen',
          },
          {
            type: 'F',
            score: 5,
            content: 'Ich würde zuhören und trösten',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Was würdest du tun, wenn du Zeit mit Freunden verbringen müsstest, während du arbeiten müsstest?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Ich kann meine Zeit nicht genießen, weil ich mir Sorgen um die Arbeit mache`,
          },
          {
            type: 'P',
            score: 5,
            content: `Ich genieße die Zeit einfach so`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Was würden Sie tun, wenn Ihr Freund plötzlich den Platz wechseln würde?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Das wäre verwirrend, weil wir bereits einen Plan haben',
          },
          {
            type: 'P',
            score: 5,
            content: 'Obwohl es plötzlich ist, stimme ich dem zu',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Welches ist Ihnen lieber?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Plan und Auftrag',
          },
          {
            type: 'P',
            score: 5,
            content: 'Möglichkeit und Neuartigkeit',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterGer/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qnsdnlrlapdlzj',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterGer/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'rkstjqdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterGer/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dlsTkdkTk',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterGer/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'rPghlrdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterGer/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qksgkddk',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterGer/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhalstkdekatk',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterGer/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'tkckdnjs',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterGer/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'wjswktkwjs',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterGer/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'akssmdwwnRns',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterGer/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'vmfhdlfrTlqfj',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterGer/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'thdnffltm',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterGer/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rkawjdzjsxmfhffj',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterGer/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'vorvhrfj',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterGer/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dosmfrdmsdl',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterGer/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'dbflapsxkf',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterGer/INFP.jpg',
      },
    ],
  },
  // characteristicChracterES
  {
    info: {
      mainTitle: 'Test de personalidad',
      subTitle: '¿Cuál es su personalidad como personaje?',
      mainUrl: 'characteristicChracterES',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/characteristicChracterES-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/characteristicChracterES-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'ES',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '¿Cuál le queda más cerca?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'No soy tímido y me llevo bien con la gente fácilmente',
          },
          {
            type: 'I',
            score: 5,
            content: 'Tardo mucho en conocer a la gente',
          },
        ],
      },
      {
        which: 'EI',
        question: '¿Qué haces con tus amigos?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Suelo contactar primero con',
          },
          {
            type: 'I',
            score: 5,
            content: 'Suelo responder sólo a las llamadas entrantes',
          },
        ],
      },
      {
        which: 'EI',
        question: '¿Qué haces cuando pasa algo malo?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Trato de salir a conocer gente para calmarme`,
          },
          {
            type: 'I',
            score: 5,
            content: `Organizo tranquilamente mis pensamientos en casa`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'Si estás a punto de salir y de repente llueve, ¿cuál puede ser tu reacción?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Oh, está lloviendo. Necesito llevar un paraguas`,
          },
          {
            type: 'N',
            score: 5,
            content: `Tengo miedo de caer en el camino resbaladizo`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '¿Cómo reaccionaría si el personal de un bar le sirviera cerveza en una bandeja diminuta por descuido?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Oh, aquí viene la cerveza',
          },
          {
            type: 'N',
            score: 5,
            content: `Me pongo ansioso pensando en derramar en nuestra mesa`,
          },
        ],
      },
      {
        which: 'SN',
        question: '¿Qué haces cuando te duchas?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Me ducho sin pensar`,
          },
          {
            type: 'N',
            score: 5,
            content: `Me ducho mientras tarareo una canción en el baño lleno de eco`,
          },
        ],
      },
      {
        which: 'TF',
        question: `¿Cuál puede ser tu reacción cuando tu amigo saca un objeto extraño en el que nunca habías estado?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '¿Qué es esto?',
          },
          {
            type: 'F',
            score: 5,
            content: 'Wow, eso se ve increíble',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '¿Cuál puede ser su reacción al comprar muebles nuevos para su nuevo hogar?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Comprobaré detenidamente la comodidad, el precio, el tamaño, etc.`,
          },
          {
            type: 'F',
            score: 5,
            content:
              'Oh, el diseño parece muy bonito y pensaré cómo encajará en mi nuevo hogar',
          },
        ],
      },
      {
        which: 'TF',
        question: `¿Qué harías cuando tu amigo te pide consejo sobre su problema?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'escucharía e intentaría resolverlo',
          },
          {
            type: 'F',
            score: 5,
            content: 'Yo escucharía y consolaría',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '¿Qué harías si tuvieras que pasar tiempo con amigos mientras tienes trabajo que hacer?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `No puedo disfrutar de mi tiempo por culpa de las preocupaciones laborales`,
          },
          {
            type: 'P',
            score: 5,
            content: `De todos modos, disfruto del tiempo`,
          },
        ],
      },
      {
        which: 'JP',
        question: '¿Qué harías si un amigo cambiara repentinamente de planes?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Sería confuso porque ya tenemos un plan',
          },
          {
            type: 'P',
            score: 5,
            content: 'Aunque sea repentino, estoy de acuerdo',
          },
        ],
      },
      {
        which: 'JP',
        question: '¿Cuál prefiere?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Plan y Orden',
          },
          {
            type: 'P',
            score: 5,
            content: 'Posibilidad y novedad',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterES/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qnsdnlrlapdlzj',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterES/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'rkstjqdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterES/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dlsTkdkTk',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterES/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'rPghlrdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterES/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qksgkddk',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterES/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhalstkdekatk',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterES/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'tkckdnjs',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterES/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'wjswktkwjs',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterES/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'akssmdwwnRns',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterES/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'vmfhdlfrTlqfj',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterES/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'thdnffltm',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterES/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rkawjdzjsxmfhffj',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterES/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'vorvhrfj',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterES/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dosmfrdmsdl',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterES/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'dbflapsxkf',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterES/INFP.jpg',
      },
    ],
  },
  // colorLabelSticker
  {
    info: {
      mainTitle: '컬러 라벨 스티커 테스트',
      subTitle: '컬러별로 다른 나의 라벨 스티커는 무엇일까?',
      mainUrl: 'colorLabelSticker',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/colorLabelSticker-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/colorLabelSticker-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          '사람이 많은 관광지에서 같이 사진 찍자는 친구! 나는 어떤 모습일까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '주변 신경 쓰지 않고 잘 나올 때까지 여러 번 찍음',
          },
          {
            type: 'I',
            score: 5,
            content: '주변이 의식되어서 후다닥 빠르게 찍음',
          },
        ],
      },
      {
        which: 'EI',
        question: '이번 주 주말 약속이 생겼다! 내 모습을 고르라면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '빨리 주말 왔으면 좋겠다~ 얼른 만나서 놀고 싶어',
          },
          {
            type: 'I',
            score: 5,
            content: '슬슬 귀찮아지는걸..? 약속 취소.. 하면 안 되겠지?',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구들을 만나고 헤어질 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `먼가 아쉽고 더 놀다 가고 싶음`,
          },
          {
            type: 'I',
            score: 5,
            content: `드디어 집에 갈 수 있다는 사실에 행복 그 잡채`,
          },
        ],
      },
      {
        which: 'SN',
        question: '등산을 하고 내려오는 중에 나는 어떤 생각을 할까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `힘들다.. 빨리 내려가서 쉬어야지`,
          },
          {
            type: 'N',
            score: 5,
            content: `포대 자루 타고 한 번에 미끄러져 내려갈 수 있지 않을까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '평소 말다툼을 할 때 내가 자주 하는 말은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '지금 얘기만 해 / 내 말은 그런 뜻이 아니라니까?',
          },
          {
            type: 'N',
            score: 5,
            content: `네가 전에도 그랬잖아 / 난 그렇게 느껴졌어!!`,
          },
        ],
      },
      {
        which: 'SN',
        question: '비오는 날, 천둥 번개가 치고있다! 이 때 드는 내 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `오 천둥 소리 엄청 크네`,
          },
          {
            type: 'N',
            score: 5,
            content: `번개를 맞아서 초능력이 생겨버린다면 나는 순간이동? 아 고민`,
          },
        ],
      },
      {
        which: 'TF',
        question: `내가 더 듣고 싶은 칭찬은 무엇일까?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '우와 너 진짜 잘했다! 어떻게 한 거야?',
          },
          {
            type: 'F',
            score: 5,
            content: '고생 많았어~ 노력 많이 했구나! 역시 최고야!',
          },
        ],
      },
      {
        which: 'TF',
        question: '대화를 할 때 주로 내 모습은 어떨까?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `A는 말 그대로 A지! 따로 숨은 뜻은 없음`,
          },
          {
            type: 'F',
            score: 5,
            content: 'A를 말했지만 B, C, D, 까지 알아줄 거라 믿음',
          },
        ],
      },
      {
        which: 'TF',
        question: `내가 고민을 털어놓는 이유는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '고민은 해결하려고 말하는 거 아니야?',
          },
          {
            type: 'F',
            score: 5,
            content: '해결은 중요하지 않아! 내 상황에 공감을 해줘',
          },
        ],
      },
      {
        which: 'JP',
        question: '나의 쇼핑 스타일은 어떨까?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `쇼핑 시간을 정해놨기 때문에 필요한 것만 후다닥 구입`,
          },
          {
            type: 'P',
            score: 5,
            content: `다른 가게들도 구경하며 쇼핑 자체를 천천히 즐김`,
          },
        ],
      },
      {
        which: 'JP',
        question: '시간 단위로 짜여 있는 계획표를 보면 드는 생각은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '안정감 있고 좋은 걸? 최대한 지켜야지',
          },
          {
            type: 'P',
            score: 5,
            content: '꼭 이렇게까지 짜야 해..? 숨이 그냥 턱턱 막히는걸..',
          },
        ],
      },
      {
        which: 'JP',
        question: '맛집에 가기로 했다! 메뉴를 고르는 나의 스타일은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '미리 검색해서 조사해 보고 뭘 먹을지 딱 정해놓고 감',
          },
          {
            type: 'P',
            score: 5,
            content: '대충 메뉴 한번 스윽 보고 식당으로 가서 확실히 정함',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelSticker/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelSticker/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelSticker/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelSticker/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelSticker/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelSticker/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelSticker/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelSticker/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelSticker/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelSticker/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelSticker/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelSticker/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelSticker/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelSticker/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelSticker/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/colorLabelSticker/INFP.jpg',
      },
    ],
  },
  // labelStickerEng
  {
    info: {
      mainTitle: 'Label Sticker Test',
      subTitle: "Let's put a sticker on my heart",
      mainUrl: 'labelStickerEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/labelStickerEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/labelStickerEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          'when you are having a good time with your friends and notice that it is very late in the evening, what can be your reaction?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'I will look around people to find the right time to go home.',
          },
          {
            type: 'I',
            score: 5,
            content: `I'm going to claim no one can go home because we have to have a real good time tonight`,
          },
        ],
      },
      {
        which: 'EI',
        question:
          'When you go home after a gathering with friends, which way do you prefer?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Going home with friends together',
          },
          {
            type: 'I',
            score: 5,
            content: 'Going home quietly alone',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'If an appointment was canceled on the weekend, what can be your reaction?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Damn, Is there anyone to meet? I should try to contact some friends.`,
          },
          {
            type: 'I',
            score: 5,
            content: `It's unfortunate, but it is better. I can be free.`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'After you make a decision, what can be your reaction?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I keep thinking about whether the decision I made is right or wrong.`,
          },
          {
            type: 'N',
            score: 5,
            content: `I do not reconsider a decision once made`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'When you cross the crosswalk, how do you do?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I just rush across without thinking',
          },
          {
            type: 'N',
            score: 5,
            content: `What if I had an accident here and it was a hit and run?  I would imagine this and that`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'When no one sits next to you on the subway, what can be your reaction?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I feel just happy the fact that I have a seat to sit comfortably.`,
          },
          {
            type: 'N',
            score: 5,
            content: `Why? I would ask myself if I look like a weirdo.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `When your close friends argue each other, what can be your reaction?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Why do they fight over something like that? I might be a little excited anyway. ',
          },
          {
            type: 'F',
            score: 5,
            content: 'Why do they fight? Should I stop them?',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'If your friend is crying sadly at the meeting place, how would you react?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Why are you crying? Perhaps it is not right time to talk so I will wait`,
          },
          {
            type: 'F',
            score: 5,
            content: 'Why are you crying Just talk. You make me cry too',
          },
        ],
      },
      {
        which: 'TF',
        question: `If your friend reads your KakaoTalk and doesn't reply, what can be your reaction?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "I guess busy? and I don't really care",
          },
          {
            type: 'F',
            score: 5,
            content:
              'I recheck the conversation to see if I typed something wrong',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What do you do when getting ready to go out?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I am always relaxed because I prepare in advance`,
          },
          {
            type: 'P',
            score: 5,
            content: `I'm sure I prepare everything, but strangely, I barely make it`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'How do you organize your desk?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I take all out and arrange them neatly in a convenient order',
          },
          {
            type: 'P',
            score: 5,
            content:
              'It seems like things are everywhere, but I can find what I need anytime',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'If you are too tired to do housework, what can be your reaction?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: "Even if I'm tired, I tidy up first and then take a break",
          },
          {
            type: 'P',
            score: 5,
            content: 'I rest first and do housework later！',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerEng/INFP.jpg',
      },
    ],
  },
  // DIYEng
  {
    info: {
      mainTitle: 'DIY personality test',
      subTitle: 'If you assemble your personality?',
      mainUrl: 'DIYEng',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/DIYEng-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/DIYEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          'When you go to a group gathering, which one is closest to you?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I am very active and lead the mood in the group.',
          },
          {
            type: 'I',
            score: 5,
            content:
              'I feel a little uncomfortable when someone im not very close with talks to me.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Which situation are you more uncomfortable with?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'A situation that many people are around me and there is an awkward silence.',
          },
          {
            type: 'I',
            score: 5,
            content: 'A situation that many people are all focused only on me',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What do you hate most about having a one-on-one conversation with someone?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `When I ask a question and the other person doesn't answer right away`,
          },
          {
            type: 'I',
            score: 5,
            content: `When I am thinking and the other person urges me to answer quickly.`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'Which one do you feel more confident with?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Doing something meticulously`,
          },
          {
            type: 'N',
            score: 5,
            content: `Thinking about some creative ideas`,
          },
        ],
      },
      {
        which: 'SN',
        question: "What do you do when you think it's time to go to bed?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I just focus on sleeping.',
          },
          {
            type: 'N',
            score: 5,
            content: `I try to force myself to sleep, but I tend to think about things.`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do you think about on the bus on the way to work?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `When can I arrive and when can I leave?`,
          },
          {
            type: 'N',
            score: 5,
            content: `I imagine teleporting to the office.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What part are you more confident in when someone asks you for counseling?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'I am confident in providing realistic advice and solutions',
          },
          {
            type: 'F',
            score: 5,
            content: 'I am confident in my ability to listen and empathize.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          "If you take a class in a field that interests you, what's your preference?",
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I prefer quick summaries and efficient lectures that point out the key points.`,
          },
          {
            type: 'F',
            score: 5,
            content:
              'Even if it is slow, I prefer friendly lectures that explain things step by step.',
          },
        ],
      },
      {
        which: 'TF',
        question: `When your friend really fails a test, what can be your reaction?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              "What mistake did you make? Next time, don't make any mistakes.",
          },
          {
            type: 'F',
            score: 5,
            content: 'You must be upset. You can make mistakes.',
          },
        ],
      },
      {
        which: 'JP',
        question: "When you're determined to do something, what do you do?",
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I plan what to do first, then put it into action.`,
          },
          {
            type: 'P',
            score: 5,
            content: `If I am determined, I should start right away.`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'How do you plan your trip?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I plan meticulously and perfectly including plan B.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'I plan only the important parts roughly and think about it during the trip.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Which day are you more satisfied with?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'The day things went smoothly as I expected',
          },
          {
            type: 'P',
            score: 5,
            content: 'The day that I experience new things and changes.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/DIYEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/DIYEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/DIYEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/DIYEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/DIYEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/DIYEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/DIYEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/DIYEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/DIYEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/DIYEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/DIYEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/DIYEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/DIYEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/DIYEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/DIYEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/DIYEng/INFP.jpg',
      },
    ],
  },
  // DNA
  {
    info: {
      mainTitle: 'DNA 성격 테스트',
      subTitle: '나의 숨어있는 DNA정보는 무엇일까?',
      mainUrl: 'DNA',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/DNA-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/DNA-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
      blogUrl: 'DNAblog',
    },
    questions: [
      {
        which: 'EI',
        question: '기분이 좋지 않을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '사람들과 밖에서 놀면서 기분을 푼다',
          },
          {
            type: 'I',
            score: 5,
            content: '집에서 조용히 방해받지 않고 쉬면서 기분을 푼다',
          },
        ],
      },
      {
        which: 'EI',
        question: '사람들과 친해질 때 나와 가까운 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '낯가림 없이 사람들과 자연스럽게 이야기를 나눈다',
          },
          {
            type: 'I',
            score: 5,
            content: '낯가림이 있고, 사람들과 친해지는 데 긴 시간이 걸린다',
          },
        ],
      },
      {
        which: 'EI',
        question: '사람들과 연락할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `주로 사람들에게 먼저 연락하는 편이다`,
          },
          {
            type: 'I',
            score: 5,
            content: `주로 사람들에게서 먼저 연락이 오는 편이다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '무언가를 새로 시작할 때 내가 더 중요하게 생각하는 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `실제 경험을 토대로 나와 있는 결과 데이터`,
          },
          {
            type: 'N',
            score: 5,
            content: `새로운 다양한 아이디어와 나에게 느껴지는 영감`,
          },
        ],
      },
      {
        which: 'SN',
        question: '상황 설명할 때 나와 비슷한 스타일은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '오늘 늦잠 자서 급하게 택시 탔지만 차가 너무 밀리는 거야 그래도 늦지 않게 도착했어 휴.. 다행..',
          },
          {
            type: 'N',
            score: 5,
            content: `오늘 늦잠 자는 바람에 택시를 탔는데 차들이 거북이처럼 느린 거 있지? 식은땀이 줄줄 나더라
                        그래도 다행히 늦지 않게 세이프 했지~`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '친구가 갑자기 “만약 네가 1년만 살 수 있다면 어떻게 할 거야?”라는 질문을 했다면, 내 대답은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `내가 왜 1년만 사는데?`,
          },
          {
            type: 'N',
            score: 5,
            content: `일단 하고 싶은 거 다 해야겠지? 근데 왜 1년이야? 무슨 병 걸린 거야?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 기분 전환하러 쇼핑하러 갔다고 한다면 나의 대답은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '오 뭐 샀어? 사진 찍어서 보내 봐',
          },
          {
            type: 'F',
            score: 5,
            content: '무슨 일 있었어? 이따 한잔할까?',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 약속에 늦었을 때 화나는 마음이 풀리는 이유는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `늦은 이유를 타당하게 잘 설명하고, 진중한 사과를 해서`,
          },
          {
            type: 'F',
            score: 5,
            content: '진중한 사과를 먼저 하고, 내 감정을 잘 달래주어서',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 슬픈 일이 있다며 내 앞에서 펑펑 운다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '빨리 울음을 그치게 해주고 싶어, 해결 방법을 찾는다',
          },
          {
            type: 'F',
            score: 5,
            content: '너무 감정 이입해서 순간 나도 눈물이 나올 것만 같다',
          },
        ],
      },
      {
        which: 'JP',
        question: '나와 더 가까운 모습은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `무언가를 계획을 세워 완벽히 마무리하는 모습`,
          },
          {
            type: 'P',
            score: 5,
            content: `갑자기 생긴 돌발 상황을, 기지를 발휘해 재빠르게 수습하는 모습`,
          },
        ],
      },
      {
        which: 'JP',
        question: '외출할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '미리 계획을 세워 놓고, 정해 놓은 대로 움직인다',
          },
          {
            type: 'P',
            score: 5,
            content: '고민한다고 목적지가 명확해지는 건 아니니 일단 나간다',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 더 불편한 상황은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '내가 계획한 대로 되지 않는 상황',
          },
          {
            type: 'P',
            score: 5,
            content: '너무 지루한 상황',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/DNA/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/DNA/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/DNA/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/DNA/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/DNA/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/DNA/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/DNA/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/DNA/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/DNA/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/DNA/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/DNA/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/DNA/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/DNA/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/DNA/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/DNA/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/DNA/INFP.jpg',
      },
    ],
  },
  // flowerGardenEng
  {
    info: {
      mainTitle: 'Love Flower Field Test',
      subTitle: 'My romantic personality seen through a flower garden',
      mainUrl: 'flowerGardenEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/flowerGardenEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/flowerGardenEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: 'I came out because I had an appointment!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: "I'm excited. I'm going to have fun and go home late",
          },
          {
            type: 'I',
            score: 5,
            content: 'I want to go home. I want to see the bed',
          },
        ],
      },
      {
        which: 'EI',
        question: "What about me when I'm angry with my lover?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: "Tell your lover why I'm angry right away",
          },
          {
            type: 'I',
            score: 5,
            content: 'First, I need time to organize my thoughts',
          },
        ],
      },
      {
        which: 'EI',
        question: "What if there's silence during the conversation?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I can't stand this silence... but I'll say it first`,
          },
          {
            type: 'I',
            score: 5,
            content: `Look around and wait until someone says`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'My lover who was supposed to meet me today contacted me that she was tired!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `If you are very tired, ask if you want to take a rest today`,
          },
          {
            type: 'N',
            score: 5,
            content: `Are you saying you can't be bothered to meet me today?`,
          },
        ],
      },
      {
        which: 'SN',
        question: "I'm climbing the stairs with a drink!",
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'There are a lot of stairs. I need to go up quickly',
          },
          {
            type: 'N',
            score: 5,
            content: `I might fall down the stairs and spill my drink, so I'll be careful`,
          },
        ],
      },
      {
        which: 'SN',
        question: "What do you think a lot when you're in a relationship?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Don't fight and let's meet for a long time`,
          },
          {
            type: 'N',
            score: 5,
            content: `What will happen to us in the future? Can we see each other for a long time?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `A lover who says, "I was really tired today, but I came because I missed you!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Why did you do that? You can rest at home!',
          },
          {
            type: 'F',
            score: 5,
            content: "Really? I'm touched. You're very tired, aren't you?",
          },
        ],
      },
      {
        which: 'TF',
        question: 'How to express your interest in the opposite sex?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I have a lot of questions`,
          },
          {
            type: 'F',
            score: 5,
            content: 'React to the smallest things',
          },
        ],
      },
      {
        which: 'TF',
        question: `Friend who is crying after breaking up with her lover, what would my reaction be?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Why did you break up? Calm down and tell me',
          },
          {
            type: 'F',
            score: 5,
            content: "It's hard... I cry as much as I want to",
          },
        ],
      },
      {
        which: 'JP',
        question: 'Will I be able to rest at home without any plans?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `You have to plan what to do and rest`,
          },
          {
            type: 'P',
            score: 5,
            content: `If you make a plan, it's not a day off`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          "What about me if we're talking about something else during our conversation?",
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "It's uncomfortable to talk about things that don't fit the topic",
          },
          {
            type: 'P',
            score: 5,
            content: "If it's fun to talk about something else, okay",
          },
        ],
      },
      {
        which: 'JP',
        question: 'What about me when I make a decision?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: "I don't change the decision I made once",
          },
          {
            type: 'P',
            score: 5,
            content:
              'If you have a better decision, you can change it right away',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGardenEng/INFP.jpg',
      },
    ],
  },
  // flowerGarden
  {
    info: {
      mainTitle: '연애 꽃밭 테스트',
      subTitle: '꽃밭으로 보는 나의 연애 스타일은?',
      mainUrl: 'flowerGarden',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/flowerGarden-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/flowerGarden-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
      blogUrl: 'flowerGardenblog',
    },
    questions: [
      {
        which: 'EI',
        question: '약속이 있어 밖에 나왔을 때 주로 내 모습은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '벌써 간다고? 이제 시작인데 무슨 소리람?',
          },
          {
            type: 'I',
            score: 5,
            content: '이제 슬슬 집 가고 싶은데.. 침대 보고싶다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '애인에게 화가 났을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '왜 화가 났는지 바로 이야기하면서 품',
          },
          {
            type: 'I',
            score: 5,
            content: '일단 생각을 정리할 시간이 필요함 (침묵)',
          },
        ],
      },
      {
        which: 'EI',
        question: '대화 중 정적이 흐르면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `이 정적 난 참을 수 없어… 아무 말 대잔치 시전`,
          },
          {
            type: 'I',
            score: 5,
            content: `눈치 보면서 누군가 말할 때까지 기다려 봄`,
          },
        ],
      },
      {
        which: 'SN',
        question: '만나기로 했던 애인이 오늘따라 피곤하다고 한다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `많이 피곤하면 오늘 쉴래?라고 물어본다.`,
          },
          {
            type: 'N',
            score: 5,
            content: `피곤하다는 얘기는 오늘 쉬고 싶다는 뜻인 걸까..? 고민한다.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '카페에서 음료를 들고 계단을 오르고 있다. 이때 드는 내 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '계단이 좀 많네 빨리 가서 마시고 싶다. ',
          },
          {
            type: 'N',
            score: 5,
            content: `계단을 올라가다가 대차게 넘어져서 음료를 쏟을 수도 있으니까 조심해야지`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연애 초기에 많이 하는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `싸우지 말고 오래오래 만나자`,
          },
          {
            type: 'N',
            score: 5,
            content: `앞으로 우린 어떻게 될까? 오래 만날 수 있을까?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `오늘 되게 피곤했지만 네가 보고 싶어서 나왔어! 라고 한다면 내 대답은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '굳이 왜 그랬어! 집에서 쉬어도 되는데! ',
          },
          {
            type: 'F',
            score: 5,
            content: '정말..? (감동의 쓰나미) 많이 피곤하지.. 괜찮아?ㅜ',
          },
        ],
      },
      {
        which: 'TF',
        question: '이성에게 관심을 표현하는 방법은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `질문 폭탄, 물음표 살인마가 됨`,
          },
          {
            type: 'F',
            score: 5,
            content: '사소한 거에도 리액션 장인이 됨',
          },
        ],
      },
      {
        which: 'TF',
        question: `연인과 헤어졌다며 울고 있는 친구, 내 반응은 어떨까?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜 헤어졌어? 진정하고 얘기해 봐',
          },
          {
            type: 'F',
            score: 5,
            content: '많이 힘들구나.. 그래 울고 싶은 만큼 울어ㅜㅜ',
          },
        ],
      },
      {
        which: 'JP',
        question: '나는 아무런 계획 없이 집에서 쉴 수 있을까?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `대략이라도 뭘 하면서 쉴지 계획을 짜야 함`,
          },
          {
            type: 'P',
            score: 5,
            content: `계획을 짠다면 그날은 쉬는 날이 아님`,
          },
        ],
      },
      {
        which: 'JP',
        question: '대화 중에 얘기가 딴 길로 새고 있다면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '주제랑 안 맞는 얘기하면 불편함',
          },
          {
            type: 'P',
            score: 5,
            content: '딴 얘기 하더라도 재밌으면 오케이',
          },
        ],
      },
      {
        which: 'JP',
        question: '보통 어떤 결정을 했을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '한번 정한 결정은 쉽게 바꾸지 않음',
          },
          {
            type: 'P',
            score: 5,
            content: '더 좋은 결정이 있다면 바로 바꿀 수 있음',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGarden/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGarden/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGarden/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGarden/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGarden/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGarden/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGarden/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGarden/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGarden/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGarden/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGarden/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGarden/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGarden/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGarden/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGarden/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/flowerGarden/INFP.jpg',
      },
    ],
  },
  // loveFlameJP
  {
    info: {
      mainTitle: '恋愛の火花テスト',
      subTitle: '炎で見る私の恋愛は?',
      mainUrl: 'loveFlameJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveFlameJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveFlameJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'JP',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '好きな異性が現れたとき、自分に近いものは？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '積極的にアプローチして相手を知ろうとしている',
          },
          {
            type: 'I',
            score: 5,
            content: 'ゆっくりと周りを回りながら、少しずつ好意のサインを流す',
          },
        ],
      },
      {
        which: 'EI',
        question: '好きな異性と意気投合したときの私の会話スタイルは？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'TMIを乱発しながら私のすべてを語る',
          },
          {
            type: 'I',
            score: 5,
            content: '自分のことはまだ話さず、聞かれた質問に答えるだけ',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '恋愛を始めたのに、恋人が恋人の友達と一緒に遊ぼうと提案してきたら？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `突然だけど、むしろいい！そうしよう！と言う`,
          },
          {
            type: 'I',
            score: 5,
            content: `突然のことで、ちょっと気が引けるので断る`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '恋人が突然、私たちの未来について理想的な話ばかりしていたら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `そうなればいいが、現実的に不可能だと思う`,
          },
          {
            type: 'N',
            score: 5,
            content: `私も考えていた理想的な考えを出して、一緒に話し合ってみる`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'もし恋人が「もし～」と起こらないことを聞いてきたら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'そんなことは起こらないから心配するなと言ってくれる',
          },
          {
            type: 'N',
            score: 5,
            content: `真剣に考えて答えてくれる`,
          },
        ],
      },
      {
        which: 'SN',
        question: '恋人のために料理を作ってくれるなら私は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `定量通りに比率を合わせて料理を作る`,
          },
          {
            type: 'N',
            score: 5,
            content: `こだわりが大切！心を込めた柿の実で料理を作る`,
          },
        ],
      },
      {
        which: 'TF',
        question: `恋人が私に嘘をついたら？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'とても残念だが、とりあえずなぜ嘘をついたのか聞いてみる',
          },
          {
            type: 'F',
            score: 5,
            content: '恋人が私に嘘をついたこと自体に、もう悲しくて腹が立つ',
          },
        ],
      },
      {
        which: 'TF',
        question: '私のミスで恋人が怒ってしまったら？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `まず間違えたことを謝罪し、そうするしかなかった理由を説明する`,
          },
          {
            type: 'F',
            score: 5,
            content: '謝罪し、怒りが収まるように努力する',
          },
        ],
      },
      {
        which: 'TF',
        question: `恋人に「私たちの時間を持とう」と言った私の意図は？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '感情が先行しているようで、本当の関係を整理する時間が必要だからだ',
          },
          {
            type: 'F',
            score: 5,
            content:
              '感情の整理が終わった状態なので別れる準備はできているが、チャンスを与えたのだ',
          },
        ],
      },
      {
        which: 'JP',
        question: '恋人と別れた後の私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `何かしないと憂鬱感を払拭できないと思い悩むが、結局我慢する`,
          },
          {
            type: 'P',
            score: 5,
            content: `ヘアスタイルを変えたり、欲しかったものを買い漁ったりして、憂鬱な気分を解消する`,
          },
        ],
      },
      {
        which: 'JP',
        question: '時間が経って元気になった私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '元恋人と立てた計画を消して、再び新しい計画を立てる',
          },
          {
            type: 'P',
            score: 5,
            content: '新しい人に会う準備をする',
          },
        ],
      },
      {
        which: 'JP',
        question: '新しい異性とのお見合い前日の私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '着る服、髪型などあらかじめ考えておく',
          },
          {
            type: 'P',
            score: 5,
            content: '明日の気分で決めるからとりあえず寝る',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameJP/INFP.jpg',
      },
    ],
  },
  // readyToBoneJP
  {
    info: {
      mainTitle: '骨が痛くなる準備はできた？テスト',
      subTitle: 'みんな骨が痛くなる準備はできた?',
      mainUrl: 'readyToBoneJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/readyToBoneJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/readyToBoneJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'もし週末の予定がキャンセルされたら？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'すぐに別の約束をする',
          },
          {
            type: 'I',
            score: 5,
            content: 'こっそり好きで家で休む',
          },
        ],
      },
      {
        which: 'EI',
        question: '新しく入ったサークルが私を熱烈に歓迎してくれたら？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '激しい歓迎に気分が良く、人とすぐに仲良くなり適応する',
          },
          {
            type: 'I',
            score: 5,
            content:
              '激しい歓迎が負担になり、慎重に、ゆっくりと適応しようとする',
          },
        ],
      },
      {
        which: 'EI',
        question: '集まりで気まずい雰囲気の時、私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `ぎこちなさに耐えられず、何か言葉を出してみる`,
          },
          {
            type: 'I',
            score: 5,
            content: `極度のぎこちなさを感じるが、誰かが話しかけるまで我慢する`,
          },
        ],
      },
      {
        which: 'SN',
        question: '私がより好む恋愛は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `未来を考える現実的な恋愛`,
          },
          {
            type: 'N',
            score: 5,
            content: `憧れの理想の恋愛`,
          },
        ],
      },
      {
        which: 'SN',
        question: '私がドラマの主人公だったら、私の選択は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '人に人気があり、容姿端麗な財閥',
          },
          {
            type: 'N',
            score: 5,
            content: `超能力を使うファンタジー世界の主人公 `,
          },
        ],
      },
      {
        which: 'SN',
        question: '友達と話している最中に、友達が突然別の話をしたら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `なぜ違う話をするのか理解できない`,
          },
          {
            type: 'N',
            score: 5,
            content: `自然に変わったその話で会話を続ける `,
          },
        ],
      },
      {
        which: 'TF',
        question: `信頼していた友人が裏で私の悪口を言ったら？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '腹が立つけど、どういう理由でなぜ罵倒したのか気になる',
          },
          {
            type: 'F',
            score: 5,
            content:
              '押し寄せる裏切られた気持ちに怒り、友人関係を断つことまで考えてしまう',
          },
        ],
      },
      {
        which: 'TF',
        question: '友人が正しくないことを言ったら私は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `確実にそうでないことはないと教えてくれる`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '万が一感情を傷つけることを恐れて言わないか、できるだけ言い返す',
          },
        ],
      },
      {
        which: 'TF',
        question: `友人が突然骨が痛いと言ったら？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'なんで急に骨が痛いんだ？ 病院に行った方がいいんじゃないの？',
          },
          {
            type: 'F',
            score: 5,
            content: '大丈夫？ 寒い日なのに、たくさん痛そうだね',
          },
        ],
      },
      {
        which: 'JP',
        question: '私がストレスを感じる理由を選ぶとしたら？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `計画通りに事が進まなかった時`,
          },
          {
            type: 'P',
            score: 5,
            content: `反復的な仕事を継続的に続ける必要がある場合`,
          },
        ],
      },
      {
        which: 'JP',
        question: '自分に近いものは？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'やるべきことはすぐに実行する',
          },
          {
            type: 'P',
            score: 5,
            content: 'やるべきことをよく先延ばしする',
          },
        ],
      },
      {
        which: 'JP',
        question: '私がもっと息苦しく感じる人は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '締め切りの1時間前まで先延ばしして始まらない人',
          },
          {
            type: 'P',
            score: 5,
            content: '時間、分、秒単位まで細かく計画している人',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneJP/INFP.jpg',
      },
    ],
  },
  // earthCharacteristicJP
  {
    info: {
      mainTitle: '地球性格テスト',
      subTitle: '地球を通したあなたの個性は何ですか？',
      mainUrl: 'earthCharacteristicJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/earthCharacteristicJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/earthCharacteristicJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '週末に休むときの私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '一旦外に出る',
          },
          {
            type: 'I',
            score: 5,
            content: '一日中家に横たわることができる',
          },
        ],
      },
      {
        which: 'EI',
        question: 'よく行くお店で、知り合いのふりをしてサービスしてくれたら？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '気分がいいので、もっと頻繁に行かなきゃと思う',
          },
          {
            type: 'I',
            score: 5,
            content: '気持ちはいいけど負担になる',
          },
        ],
      },
      {
        which: 'EI',
        question: '友達と些細なトラブルが発生したら？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `今すぐ葛藤を解決しようとする`,
          },
          {
            type: 'I',
            score: 5,
            content: `一度考えて、ゆっくり葛藤を解決しようとする`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'もし友人が私に「もし地球が滅亡したらどうするの？」と聞かれたら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `いきなり？ いや、なんでそんなこと考えるの？`,
          },
          {
            type: 'N',
            score: 5,
            content: `考えるだけで怖い...生きる方法あるかな...?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '友達が私に「夏に冬服を着るVS冬に夏服を着る」バランスゲームを聞かれたら?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '「こんなのお前がやれよ」と答えない',
          },
          {
            type: 'N',
            score: 5,
            content: `"何着まで着られるの？"と尋ねながら真剣に悩む`,
          },
        ],
      },
      {
        which: 'SN',
        question: '友達と話すとき、自分に近いものは？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `現在の話のテーマに集中する`,
          },
          {
            type: 'N',
            score: 5,
            content: `話しながら別のことを考える`,
          },
        ],
      },
      {
        which: 'TF',
        question: `友人が地球が滅びるという内容の映画を見たとしたら？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '面白かったか？ ありきたりな結末だったような気もするが',
          },
          {
            type: 'F',
            score: 5,
            content: 'スリル満点だったでしょう 楽しかった？',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '友達がストレスが溜まっていると言ってお酒を飲もうって言ったら？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `おつまみは何を食べるの？ そんな時は美味しいものを食べないとね`,
          },
          {
            type: 'F',
            score: 5,
            content: 'どうしたの、どうしたの？ 誰が、誰に苦労させたの？',
          },
        ],
      },
      {
        which: 'TF',
        question: `人に興味を示すとき、私は？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '無限の関心と質問をする',
          },
          {
            type: 'F',
            score: 5,
            content: '傾聴とリアクションをする',
          },
        ],
      },
      {
        which: 'JP',
        question: '私がもっと不便な状況は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `突然の変化が発生したとき`,
          },
          {
            type: 'P',
            score: 5,
            content: `もっと良い方法があるのに、計画通りにしかできないとき`,
          },
        ],
      },
      {
        which: 'JP',
        question: '物を買うときに私が悩むのは？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '事前に消費計画を立てておくので、特に悩むことはない',
          },
          {
            type: 'P',
            score: 5,
            content:
              '私の目を引いたのに何の悩みが必要なのか？ とりあえず購入する',
          },
        ],
      },
      {
        which: 'JP',
        question: '一ヶ月間世界一周をすると言われたとき、私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'まさに一ヶ月を細かく分けて綿密な計画を立てます',
          },
          {
            type: 'P',
            score: 5,
            content:
              '行くまでに大事な大きなことだけを決めて、ドキドキする気持ちを満喫する',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicJP/INFP.jpg',
      },
    ],
  },
  // DIY
  {
    info: {
      mainTitle: 'DIY 성격테스트',
      subTitle: '직접 만들어보는 나의 성격 DIY는?',
      mainUrl: 'DIY',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/DIY-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/DIY-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
      blogUrl: 'DIYblog',
    },
    questions: [
      {
        which: 'EI',
        question: '단체 모임에서 나와 가까운 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '활발하고, 분위기를 주도하는 편이다',
          },
          {
            type: 'I',
            score: 5,
            content: '상대방의 말에 경청하고 주로 질문에 대답하는 편이다',
          },
        ],
      },
      {
        which: 'EI',
        question: '내가 더 불편한 상황은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '많은 사람과 있는데, 어색한 침묵이 감도는 상황',
          },
          {
            type: 'I',
            score: 5,
            content: '많은 사람이 모두 나에게만 초집중하는 상황',
          },
        ],
      },
      {
        which: 'EI',
        question: '쉬는 주말 밖에서 신나게 놀고 난 후 나의 상태는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `역시 쉬는 날에는 밖에서 놀아야지! 에너지 충전 완료!`,
          },
          {
            type: 'I',
            score: 5,
            content: `오랜만에 너무 재밌었다! 이제 집에 가서 좀 쉬어야지!`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 더 자신 있는 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `세밀하고, 꼼꼼한 일 처리`,
          },
          {
            type: 'N',
            score: 5,
            content: `창의적인 아이디어를 내는 것`,
          },
        ],
      },
      {
        which: 'SN',
        question: '“이제 자야지”라고 생각했을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '바로 자는 것에 집중한다',
          },
          {
            type: 'N',
            score: 5,
            content: `자야지, 자야지 하다가 생각이 다른 길로 샌다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '배달음식 주문할 때 나와 가까운 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `일단 배고프니까 이미지보고 맛있어 보이는 것을 주문한다`,
          },
          {
            type: 'N',
            score: 5,
            content: `여긴 왜 배달비가 비쌀까? 여기는 리뷰가 왜 별로 없을까? 궁금한 것 다 찾아보고 늦게 주문한다`,
          },
        ],
      },
      {
        which: 'TF',
        question: `고민 상담이 들어왔을 때 내가 더 자신 있는 것은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '현실적인 조언과 해결 방안 제시',
          },
          {
            type: 'F',
            score: 5,
            content: '경청과 감정적인 공감',
          },
        ],
      },
      {
        which: 'TF',
        question: '관심 있는 분야의 강의 수업을 듣는다면 내 선택은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `빠른 요약 정리와 핵심을 콕 짚어주는 효율적인 강의`,
          },
          {
            type: 'F',
            score: 5,
            content: '느리지만 하나하나 이해하기 쉽게 설명해 주는 친절한 강의',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 실수해서 시험을 망쳤다고 했다면 나의 대답은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '헐 어떤 실수를 했길래? 다음에는 실수 안 하면 되지',
          },
          {
            type: 'F',
            score: 5,
            content: '헐 속상하겠다 ㅠ 실수할 수도 있지..!',
          },
        ],
      },
      {
        which: 'JP',
        question: '무언가를 하겠다고 다짐했을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `어떻게 할지 계획을 먼저 세워놓고, 실행에 옮긴다`,
          },
          {
            type: 'P',
            score: 5,
            content: `다짐했으면, 바로 시작부터 하고 생각한다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '여행 계획을 짤 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '플랜 B까지 꼼꼼하고 완벽하게 계획한다',
          },
          {
            type: 'P',
            score: 5,
            content: '중요한 부분과, 틀만 대략적으로 생각하고 간다',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구와 약속이 있는 당일에 나와 가까운 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '2~3시간 전부터 느긋하게 준비를 끝내고 약속 장소에 늦지 않게 간다',
          },
          {
            type: 'P',
            score: 5,
            content: '시간 거의 다 되었을 때부터 급하게 준비를 할 때가 많다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/DIY/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/DIY/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/DIY/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/DIY/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/DIY/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/DIY/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/DIY/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/DIY/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/DIY/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/DIY/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/DIY/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/DIY/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/DIY/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/DIY/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/DIY/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/DIY/INFP.jpg',
      },
    ],
  },
  // earthCharacteristicEng
  {
    info: {
      mainTitle: 'Earth personality test',
      subTitle: 'What is your personality through Earth?',
      mainUrl: 'earthCharacteristicEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/earthCharacteristicEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/earthCharacteristicEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'How do you rest on the weekend?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'At first, I just leave my house',
          },
          {
            type: 'I',
            score: 5,
            content: 'Probably, I can relax at home all day',
          },
        ],
      },
      {
        which: 'EI',
        question: `How would you react if the restaurant owner greeted you with a "hello" and served you an extra dish?`,
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'I would feel happy and think I should visit here more often',
          },
          {
            type: 'I',
            score: 5,
            content: 'It is nice but I would be burdened',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What do you do when you have a minor conflict with a friend?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I try to resolve the conflict right away`,
          },
          {
            type: 'I',
            score: 5,
            content: `I think once slowly and try to resolve the conflict`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What would your reaction be if your friend asked you what you would do if the earth were to end?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Suddenly? No way, what made you think like that?`,
          },
          {
            type: 'N',
            score: 5,
            content: `I'm scared just thinking about it. Is there a way to survive?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'If your friend ask you to play a balance game about “Wearing winter clothes in summer VS Wearing summer clothes in winter”, what can be your response?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I would just say “you do it, not me”',
          },
          {
            type: 'N',
            score: 5,
            content: `I would say “How many clothes can I wear?” while thinking seriously`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'When talking to friends, which one is closer to you?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I focus on the topic of the current topic`,
          },
          {
            type: 'N',
            score: 5,
            content: `I think of other things while talking`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What would be your reaction if your friend saw a movie about the end of the earth?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Was it fun? It must be an obvious ending',
          },
          {
            type: 'F',
            score: 5,
            content: 'I must be full of thrills. Was it fun?',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'If your friend is stressed out and asks you for a drink, what might be your reaction?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `What are you going to eat for the drink? You should eat something very delicious when you are down`,
          },
          {
            type: 'F',
            score: 5,
            content: 'What happened? Who made you hard?',
          },
        ],
      },
      {
        which: 'TF',
        question: `How do you express interest in people?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I express infinite interest and ask lots of questions',
          },
          {
            type: 'F',
            score: 5,
            content: 'I just listen and react',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Which situation are you more uncomfortable with?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `When sudden changes occur`,
          },
          {
            type: 'P',
            score: 5,
            content: `When I just have to stick to the plan even though there's a better way`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'What do you worry about when buying something?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I don’t worry as I tend to make a spending plan in advance',
          },
          {
            type: 'P',
            score: 5,
            content: 'I just buy without worries when I really like something',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What would you do if you were to travel around the world for one month?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I carefully divided the month into detailed plans',
          },
          {
            type: 'P',
            score: 5,
            content:
              'I decide only the big things that are important until I go, and enjoy the thrilling emotions',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristicEng/INFP.jpg',
      },
    ],
  },
  // cantWaitJP
  {
    info: {
      mainTitle: `"これには耐えられない"テスト`,
      subTitle: 'MBTIで見る私が我慢できないことは？',
      mainUrl: 'cantWaitJP',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/cantWaitJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/cantWaitJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '私がもっと我慢できない状況は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '人が多い集まりで雰囲気がぎこちないこと',
          },
          {
            type: 'I',
            score: 5,
            content: '瞬間多くの人が私に集中すること',
          },
        ],
      },
      {
        which: 'EI',
        question: '人に伝えたいことがあるとき、私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '言葉で自分が伝えたいことを直接伝えるのが良い',
          },
          {
            type: 'I',
            score: 5,
            content: '文字や、SNSなどを利用して文章で伝えるのが良い',
          },
        ],
      },
      {
        which: 'EI',
        question: '私は友達に？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `まずは連絡をする方がいい`,
          },
          {
            type: 'I',
            score: 5,
            content: `友達から先に連絡が来ることが多いですね`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'ぼーっとしているときに考えることはありますか？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `何も考えないことができる`,
          },
          {
            type: 'N',
            score: 5,
            content: `何も考えるべきではないのに、思考が止まらない`,
          },
        ],
      },
      {
        which: 'SN',
        question: '長距離運転中に車が渋滞したら私は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '車が渋滞している。どうしたんだろう、もしかして事故でも起こしたのかなと思う',
          },
          {
            type: 'N',
            score: 5,
            content: `渋滞する車の真ん中に道が開き、涼しく走ることを想像する`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'ストリーミング映画、ドラマを見るとき私は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `登場人物の感情と演技に集中して見る`,
          },
          {
            type: 'N',
            score: 5,
            content: `作家や監督が伝えたい意味を探したり、結末以降の内容を想像する`,
          },
        ],
      },
      {
        which: 'TF',
        question: `友達が辛いと連絡が来たら？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'なぜそうなのか聞いて慰めてくれる',
          },
          {
            type: 'F',
            score: 5,
            content: 'まず慰める、なぜそうなのかを聞く',
          },
        ],
      },
      {
        which: 'TF',
        question: '約束に遅れた友人を許した理由は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `遅れるしかない正当な理由を聞いたからです`,
          },
          {
            type: 'F',
            score: 5,
            content: '到着してすぐにすぐにお詫びをしたため',
          },
        ],
      },
      {
        which: 'TF',
        question: `悲しいドラマや映画を見るとき私は？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '悲しくて涙が出ることもありますが、それは一瞬だけです',
          },
          {
            type: 'F',
            score: 5,
            content: '涙が止まらず、感動の余韻が長く続く',
          },
        ],
      },
      {
        which: 'JP',
        question: '友達との集まりで次の場所に移動するとき、私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `とりあえず決めて出かけようという感じです`,
          },
          {
            type: 'P',
            score: 5,
            content: `とりあえず外に出てから決めようという感じです`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          '家の前に新しくできた飲食店に行ったら、初めて見るメニューがあったら？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '気になるけどよくわからないので、あらかじめ考えておいたメニューを選ぶ',
          },
          {
            type: 'P',
            score: 5,
            content: '考えていたメニューもなく、気になったので食べてみる',
          },
        ],
      },
      {
        which: 'JP',
        question: 'フードメニューを選ぶとき、私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'メニューの決定を容易にする',
          },
          {
            type: 'P',
            score: 5,
            content: 'メニューを決めるのに時間がかかる',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitJP/INFP.jpg',
      },
    ],
  },
  // puzzleLoveEng
  {
    info: {
      mainTitle: 'Love puzzle test',
      subTitle: "Let's match your dating tendency through a puzzle",
      mainUrl: 'puzzleLoveEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/puzzleLoveEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/puzzleLoveEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question:
          'If you played around whole weekend at home with your friends, what do you want to do?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I've been at home too long and I have to go out tomorrow`,
          },
          {
            type: 'I',
            score: 5,
            content:
              'I still need my alone time at home because I couldn’t do my own things.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What if you were at home two days in a row?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: "I'm so bored. I want to go out. Please get me out.",
          },
          {
            type: 'I',
            score: 5,
            content: 'For me, this is real relaxation and happiness~',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What would you say to a friend who asked to meet you today?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Okay, I am free today. Where do you want to meet?`,
          },
          {
            type: 'I',
            score: 5,
            content: `Not today, I have to rest today`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What if you pass by your ideal type while walking down the street?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Oh, really this person is my deal type.`,
          },
          {
            type: 'N',
            score: 5,
            content: `What if the person likes me or ask for my number?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What can be your answer if you are asked how the café we went to on the weekend?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I liked the soft and quiet music and the interior.',
          },
          {
            type: 'N',
            score: 5,
            content: `It was really great! It was cozy and nice!`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'How do you feel when you watch the sunset with your lover?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Wow, it is a beautiful sunset.`,
          },
          {
            type: 'N',
            score: 5,
            content: `This sunset can be seen from the other side of the earth because the earth is round`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What can be your answer if you are asked why there is not enough rain these days?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Around this time of year, it is like that.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Right! it is not really nice that it’s raining occasionally.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          "What is your answer when your friend asks you about their lover's not replying after checking the massage?",
        answers: [
          {
            type: 'T',
            score: 2,
            content: `There must have been a situation where they couldn't contact you~`,
          },
          {
            type: 'F',
            score: 5,
            content: "What's the reason? If I were you, I'd feel sad.",
          },
        ],
      },
      {
        which: 'TF',
        question: `What if your lover has to work overtime because he has a lot of work today?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'What is that about? Why you have to work overtime?',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Oh really? It must be hard to work overtime. Do you have to stay till late?',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What If your lover made a so detail plan to do together?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `It is nice and simple. I feel comfortable that we have a nice plan`,
          },
          {
            type: 'P',
            score: 5,
            content: `I'm out of breath just by looking at it`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What do you usually do when you go on a date with your lover?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I tend to decide what time to meet and what to do before in advance.',
          },
          {
            type: 'P',
            score: 5,
            content: 'I prefer to meet first and decide what to do',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What if you find a cafe that looks good on a date with your lover?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "I'll visit next time. Where we were supposed to go today?",
          },
          {
            type: 'P',
            score: 5,
            content:
              "Oh, let's go here, we can always visit back to where we were to go later ",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveEng/INFP.jpg',
      },
    ],
  },
  // puzzleLoveJP
  {
    info: {
      mainTitle: 'パズル恋愛テスト',
      subTitle: 'パズルで交際傾向をマッチングさせよう',
      mainUrl: 'puzzleLoveJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/puzzleLoveJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/puzzleLoveJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '週末のある日、友達と家で遊んでいたら私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '室内指数過多状態。明日は外で遊びたい',
          },
          {
            type: 'I',
            score: 5,
            content: 'とにかく遊んだので、別途ホームタイムが必要',
          },
        ],
      },
      {
        which: 'EI',
        question: '二日連続で家にいることになった私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '退屈すぎる。出かけたいから出してよ',
          },
          {
            type: 'I',
            score: 5,
            content: 'これが休息であり、幸せだ',
          },
        ],
      },
      {
        which: 'EI',
        question: '今日会おうという友達！主に私がする答えは？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `そうだ！ 今日は約束がない！ どこで会おうか？`,
          },
          {
            type: 'I',
            score: 5,
            content: `いや、また今度ね、今日は休まなきゃ`,
          },
        ],
      },
      {
        which: 'SN',
        question: '道で理想の人と目が合ったら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `ああ、完全に私の理想形だ`,
          },
          {
            type: 'N',
            score: 5,
            content: `なんだ、もしかしてあの人も私を？ 私の番号を聞かれたらどうしよう `,
          },
        ],
      },
      {
        which: 'SN',
        question: '週末に行ったカフェはどうだった？ と聞かれたら、私の答えは？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '音楽も静かに流れていて、静かでインテリアも良いですね',
          },
          {
            type: 'N',
            score: 5,
            content: `あそこの雰囲気めっちゃ良かった！ 感性そのものです`,
          },
        ],
      },
      {
        which: 'SN',
        question: '恋人と一緒に沈む夕日を見ながら！この時、私の思いは？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `うわー、夕日が素敵`,
          },
          {
            type: 'N',
            score: 5,
            content: `あの夕焼けは他の世界でも見えるんだろう？ パラレルワールドとか`,
          },
        ],
      },
      {
        which: 'TF',
        question: `最近、雨がよく降らないの？と聞かれたら、私の答えは？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '今がまさにそんな季節だからできることだ ',
          },
          {
            type: 'F',
            score: 5,
            content:
              'そうですね！たまに雨が降るのは、あまりいいことではありませんね',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '友達が恋人の連絡が来ないという悩みを相談されたら、私の答えは？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `連絡が取れない状況があったんだろうな～`,
          },
          {
            type: 'F',
            score: 5,
            content: '何でだろう、よくそうなると、私だって悔しいよ',
          },
        ],
      },
      {
        which: 'TF',
        question: `恋人が今日仕事が忙しくて夜勤をしなければならないとしたら？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'どんな業務？ 何で夜勤までしなければならないんだ？',
          },
          {
            type: 'F',
            score: 5,
            content: '夜勤大変だろうなぁ......だいぶ遅れるの？',
          },
        ],
      },
      {
        which: 'JP',
        question: '恋人が時間単位に分けて計画表を組んでいたら、私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `すっきりしていてとても快適`,
          },
          {
            type: 'P',
            score: 5,
            content: `見ているだけで息をのむほど`,
          },
        ],
      },
      {
        which: 'JP',
        question: '恋人とデートの約束をするとき、普段私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '何時に会って何をするかをあらかじめ決めてから会う方',
          },
          {
            type: 'P',
            score: 5,
            content: '一度会ってから何をするか決める方',
          },
        ],
      },
      {
        which: 'JP',
        question: '恋人とデート中に雰囲気の良さそうなカフェを見つけたら？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'そこは後回しにして、今日は行こうと思っていたところへ行こう! ',
          },
          {
            type: 'P',
            score: 5,
            content:
              'おお、良さそうだ、行ってみよう！行こうと思っていた場所は後で行けばいい！',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/puzzleLoveJP/INFP.jpg',
      },
    ],
  },
  // puzzleLove
  {
    info: {
      mainTitle: '퍼즐 연애 테스트',
      subTitle: '나의 연애 성향을 퍼즐로 맞춰보자',
      mainUrl: 'puzzleLove',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/puzzleLove-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/puzzleLove-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '주말 중 하루 친구와 집에서 놀았다면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '실내 지수 과다 상태.. 내일은 밖에서 놀고 싶음',
          },
          {
            type: 'I',
            score: 5,
            content: '어쨌든 놀았기 때문에 별도의 홈 타임 필요',
          },
        ],
      },
      {
        which: 'EI',
        question: '이틀 연속 집에 있게 되었다면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '너무 심심해.. 나가고 싶어 날 꺼내 줘..',
          },
          {
            type: 'I',
            score: 5,
            content: '이것이 휴식이고 행복이지~',
          },
        ],
      },
      {
        which: 'EI',
        question: '오늘 만나자는 친구! 주로 내가 하는 대답은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `그래! 오늘은 약속 없어! 어디서 볼까?`,
          },
          {
            type: 'I',
            score: 5,
            content: `아냐 담에 보자 오늘은 쉬어야 해`,
          },
        ],
      },
      {
        which: 'SN',
        question: '길을 가다 이상형인 사람과 눈이 마주친다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `오 완전 내 이상형이네`,
          },
          {
            type: 'N',
            score: 5,
            content: `뭐야 혹시 저분도 나를? 내 번호 물어보면 어쩌지 ㅎㅎ`,
          },
        ],
      },
      {
        which: 'SN',
        question: '주말에 갔던 카페는 어땠어? 라고 물으면 내 대답은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '음악도 잔잔~하니 조용하고 인테리어가 굿이던데?',
          },
          {
            type: 'N',
            score: 5,
            content: `거기 분위기 완전 좋았어! 감성 그 잡채!`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인과 함께 지는 노을을 보는 중! 이때 드는 내 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `우와 노을 멋지네`,
          },
          {
            type: 'N',
            score: 5,
            content: `저 노을은 다른 세상에서도 보이겠지? 평행세계라던지`,
          },
        ],
      },
      {
        which: 'TF',
        question: `요즘 비 너무 자주 오지 않아? 라고 물으면 내 대답은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '지금 한창 그럴 계절이니까 그럴 수 있지',
          },
          {
            type: 'F',
            score: 5,
            content: '그치!! 오다 말다 하니까 찝찝해~',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 연인의 읽씹에 대한 고민을 상담할 때 내 대답은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `연락할 수 없었던 상황이 있었겠지~`,
          },
          {
            type: 'F',
            score: 5,
            content: '왜 그럴까? 자주 그러면 나 같아도 서운하겠다ㅜ',
          },
        ],
      },
      {
        which: 'TF',
        question: `연인이 오늘 업무가 많아 야근을 해야 한다고 하면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '어떤 업무?? 뭐길래 야근까지 해야 한대',
          },
          {
            type: 'F',
            score: 5,
            content: '아 진짜? 야근 힘들텐데ㅜ 많이 늦어?',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인이 시간 단위로 나누어 계획표를 짜왔다면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `깔끔하니 아주 편안함`,
          },
          {
            type: 'P',
            score: 5,
            content: `그냥 보기만 해도 숨이 턱턱 막힘`,
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 데이트 약속을 잡을 때 보통 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '몇 시에 만나서 뭘 할지 미리 다 정하고 만나는 편',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 만나서 뭐 할지 정하는 편',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 데이트 중 분위기 좋아 보이는 카페를 발견했다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '나중에 가봐야지 (메모) 오늘은 가기로 했던 곳으로!',
          },
          {
            type: 'P',
            score: 5,
            content: '오 좋아 보여 가보자! 가려던 곳은 나중에 가면 됨!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/puzzleLove/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/puzzleLove/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/puzzleLove/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/puzzleLove/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/puzzleLove/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/puzzleLove/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/puzzleLove/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/puzzleLove/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/puzzleLove/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/puzzleLove/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/puzzleLove/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/puzzleLove/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/puzzleLove/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/puzzleLove/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/puzzleLove/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/puzzleLove/INFP.jpg',
      },
    ],
  },
  // jenga
  {
    info: {
      mainTitle: '젠가 성격 테스트',
      subTitle: '젠가로 보는 나의 성격은?',
      mainUrl: 'jenga',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/jenga-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/jenga-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '친구와 길을 가다가 유행하는 최신가요가 나온다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '갑자기 흥이 차올라 따라 부르거나, 삘 받으면 춤도 춘다',
          },
          {
            type: 'I',
            score: 5,
            content: '나대는 친구를 급하게 막거나, 모른 척한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '내가 더 선호하는 인간관계는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '얕지만 많은 사람과 친해지는 것',
          },
          {
            type: 'I',
            score: 5,
            content: '소수의 사람과 깊이 친해지는 것',
          },
        ],
      },
      {
        which: 'EI',
        question: '나와 가까운 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `모든 사람 앞에서 말이 많다`,
          },
          {
            type: 'I',
            score: 5,
            content: `한 번 보고 말 사이나, 친한 사람 앞에서만 말이 많다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '요리할 때 나와 가까운 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `레시피를 보고 정석대로 만든다`,
          },
          {
            type: 'N',
            score: 5,
            content: `손대중으로 간을 맞추며 감각적으로 만든다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '파티를 위해 풍선을 열심히 불고 있을 때 내 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '어후 힘들어 죽겠네! 현기증 나!',
          },
          {
            type: 'N',
            score: 5,
            content: '갑자기 터지는 거 아니야? 무서워 ㅠ',
          },
        ],
      },
      {
        which: 'SN',
        question: '밤에 잘 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `그냥 자는 것에 집중한다`,
          },
          {
            type: 'N',
            score: 5,
            content: `이런저런 생각을 하다가 늦게 잠이 든다`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구와 약속이 있는데 친구가 약속 시간에 늦었다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '화가 나지만, 왜 이렇게 늦었는지 이유를 먼저 물어본다',
          },
          {
            type: 'F',
            score: 5,
            content: '이유가 뭐가 됐든 사과하지 않으면 뭐라고 한다',
          },
        ],
      },
      {
        which: 'TF',
        question: '내가 더 중요하게 생각하는 것은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `객관적인 사실과 논리`,
          },
          {
            type: 'F',
            score: 5,
            content: '상황적인 이해와 정서',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 길에서 시비가 붙었는데, 들어보니 친구의 잘못인 것 같을 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '이건 너가 잘 못한 게 맞아”라고 바로 확실하게 짚어 준다',
          },
          {
            type: 'F',
            score: 5,
            content:
              '“아무리 그래도 아까 그건 좋지 못한 행동인 것 같아”라고 시간을 두고, 돌려 말한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '주말에 친구들과 약속이 있는 날 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `놀 계획은 물론, 나간 김에 개인 잡무까지 한 번에 처리할 계획을 이미 세워 놓았다`,
          },
          {
            type: 'P',
            score: 5,
            content: `뭐 입을지, 뭐 먹을지, 뭐 하고 놀지 친구들에게 물어본다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '새로운 업무를 받았을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '어떻게 할지 단계를 나눠 미리 계획을 정하고 시작한다',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 자료조사부터 해서 일단 시작한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 더 답답한 상황은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '계획대로 일이 풀리지 않을 때',
          },
          {
            type: 'P',
            score: 5,
            content: '새로운 것을 하고 싶은데 같은 것만 반복해야 할 때',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/jenga/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/jenga/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/jenga/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/jenga/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/jenga/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/jenga/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/jenga/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/jenga/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/jenga/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/jenga/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/jenga/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/jenga/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/jenga/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/jenga/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/jenga/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/jenga/INFP.jpg',
      },
    ],
  },
  // mbtiFeaturesJP
  {
    info: {
      mainTitle: 'MBTI 特徴テスト_恋愛編',
      subTitle: '私の恋愛特徴と恋愛力指数は:???',
      mainUrl: 'mbtiFeaturesJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/mbtiFeaturesJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/mbtiFeaturesJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'JP',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question:
          '週末に恋人と楽しんだ後、どのような反応をすることができるのでしょうか。',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'この週末は本当に最高でした！すっかりリフレッシュできました！',
          },
          {
            type: 'I',
            score: 5,
            content: 'この週末も楽しかったです！これから家でゆっくり休みます！',
          },
        ],
      },
      {
        which: 'EI',
        question: 'もし、混雑した集まりに参加したら、どうする？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '熱意を持って、自分の意見を自信をもって伝える',
          },
          {
            type: 'I',
            score: 5,
            content: '相手の言葉に静かに共感してしまう',
          },
        ],
      },
      {
        which: 'EI',
        question: 'どちらの日付がいい？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `ワクワクするようなアウトドアデート`,
          },
          {
            type: 'I',
            score: 5,
            content: `静かな室内デート`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '他の友人と飲みに行っている時に、恋人と連絡が取れなくなったらどうしますか？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `よくもまあ、答えてくれないものだ！`,
          },
          {
            type: 'N',
            score: 5,
            content: `本当に他の女の子とつるんでいないのか？`,
          },
        ],
      },
      {
        which: 'SN',
        question: '恋人と旅行に行く前日、あなたはどうしますか？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '早く行きたいので、早く寝ます',
          },
          {
            type: 'N',
            score: 5,
            content: `旅を想像して眠りにつく`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'どちらの会話がお好みですか？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `今のトレンドや興味のあることについての会話`,
          },
          {
            type: 'N',
            score: 5,
            content: `想像力が必要な未来志向の会話`,
          },
        ],
      },
      {
        which: 'TF',
        question: `恋人と衝突したとき、どうする？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '悔しいけど、合理的に解決するために冷静に話す',
          },
          {
            type: 'F',
            score: 5,
            content:
              '言いたいことは山ほどあるが、心が傷ついているので正気に戻れない',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'もし、恋人が真剣な顔で突然悩みを告白してきたら、あなたはどうしますか？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `最後まで聞いて、問題を解決しようと思います`,
          },
          {
            type: 'F',
            score: 5,
            content: '恋人に共感し、真摯に耳を傾けてしまう',
          },
        ],
      },
      {
        which: 'TF',
        question: `もし恋人が大切なものを落として壊してしまったら、どうしますか？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'どうやって落としたんですか？あ、ごめんね。どうしてもっと気をつけなかったの？',
          },
          {
            type: 'F',
            score: 5,
            content: 'あ、ごめんなさい。あなたが大切にしていたものです',
          },
        ],
      },
      {
        which: 'JP',
        question: 'デートするとき、どうする？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `先回りして顔を出す`,
          },
          {
            type: 'P',
            score: 5,
            content: `約束の時間に遅れることが多い`,
          },
        ],
      },
      {
        which: 'JP',
        question: '恋人とデートする前に、何を準備しますか？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'どこで何をするのか、細かく決めていく',
          },
          {
            type: 'P',
            score: 5,
            content: '場所と時間を決めるのは自分だけ',
          },
        ],
      },
      {
        which: 'JP',
        question: 'もし、恋人に手紙を書くとしたら、どんなことを書きますか？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '頭の中で何を書くか考え、それを書き留める',
          },
          {
            type: 'P',
            score: 5,
            content: '座ってその場で思いついたことを書く',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesJP/INFP.jpg',
      },
    ],
  },
  // emotionGraphJP
  {
    info: {
      mainTitle: 'エモーショングラフテスト',
      subTitle: '感情の起伏をグラフで確認してみよう！',
      mainUrl: 'emotionGraphJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/emotionGraphJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/emotionGraphJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'どちらの状況がより気になりますか？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '家から一歩も出られない日が続くと',
          },
          {
            type: 'I',
            score: 5,
            content: '数日間、遅くまで遊んだりするときは',
          },
        ],
      },
      {
        which: 'EI',
        question: '初対面の友人と二人きりになったらどうしますか？',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '最初はぎこちなくても、コミュニケーションがうまくいけば、気づかないうちに親友になれるんです。',
          },
          {
            type: 'I',
            score: 5,
            content: '会話はできるけど、気まずくて落ち着かない。 ',
          },
        ],
      },
      {
        which: 'EI',
        question: '今日一日外で遊んだとしたら、どんな反応ができるでしょうか。',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `休みたいけど、もうスケジュールが忙しすぎる。`,
          },
          {
            type: 'I',
            score: 5,
            content: `しばらくは家にいるしかないんだから、ほっといてよ。`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'もし、友人に「一人で海外旅行に行こう」と言われたら、どうしますか？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `一人で行くのは楽しいですか？どこに行くんですか？と聞くことがあります。`,
          },
          {
            type: 'N',
            score: 5,
            content: `スリや誘拐の心配がある`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'もし、あなたの友人が急に急用ができて先に帰った場合、あなたはどのような反応をしますか？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '後日、友人が教えてくれると思います',
          },
          {
            type: 'N',
            score: 5,
            content: `緊急ですか？何か重大なことだと思うのですが`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '友達と遊んで帰ろうとバスを待っているとき、どんなリアクションができるでしょうか。',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `早く帰って休まないとね。今日は楽しかった`,
          },
          {
            type: 'N',
            score: 5,
            content: `もしかしたら、最終バスがもう出てしまって、どうやって帰ろうかなぁ`,
          },
        ],
      },
      {
        which: 'TF',
        question: `普段、どのような場面で「可能性がある」とおっしゃるのでしょうか？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '全く理解できないのに、大雑把な答えを出さなければならないとき。',
          },
          {
            type: 'F',
            score: 5,
            content: '相手を慰めるとき。',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'もし友人が私のことを悲しんで、少し動揺していたら、あなたはどう答えますか？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `どうして？何がそうさせたのか、教えてください。`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '可能性があるのです。ごめんなさい、そんなつもりじゃなかったんです。',
          },
        ],
      },
      {
        which: 'TF',
        question: `友達が「最近ずっと機嫌が悪いんだ」と言ったとき、あなたはどんな反応をすることができるでしょうか？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '誰にでもそういう時期があるものです。',
          },
          {
            type: 'F',
            score: 5,
            content: 'もう心配してたんだよ、どうしたんだよ',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'もし、友人が突然会話の話題を変えたら、あなたはどう反応しますか？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `話題を変えないでください。私たちは別の話をしていたんです。`,
          },
          {
            type: 'P',
            score: 5,
            content: `え、何ですか？おかしいな......ハハ とにかく、今何の話をしたんだっけ？`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'もし、あなたの友人が突然あなたの家に来たいと言い出したら、あなたはどんな反応をすることができるでしょうか？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'え、なんで？今からですか（今日は予定があるので...落ち着かない...）。',
          },
          {
            type: 'P',
            score: 5,
            content:
              'どうしたいんですか？やりたいことがあるんだけど、明日でもいいや。元気になったよ',
          },
        ],
      },
      {
        which: 'JP',
        question: 'お出かけ前の洋服選びで工夫していることは？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '天気によって着るものを決めたり、一日の計画を立てたりすると、気持ちがいいんです。',
          },
          {
            type: 'P',
            score: 5,
            content:
              '着るものは自分で決めたが、その日の気分で変えることができる',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphJP/INFP.jpg',
      },
    ],
  },
  // polaroidCharacteristicJP
  {
    info: {
      mainTitle: 'ポラロイド性格診断',
      subTitle: 'ポラロイド写真でわかる、あなたの個性とは？',
      mainUrl: 'polaroidCharacteristicJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/polaroidCharacteristicJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/polaroidCharacteristicJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'あなたにとって、休息とは何ですか？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '外出すること、人に会うこと',
          },
          {
            type: 'I',
            score: 5,
            content: '一日中、家で休んでいる',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'もしあなたが、気の置けない仲の良い友人とカラオケに来たとしたら。あなたならどうしますか？',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'いいね、今ならみんなと仲良くなれるよ！歌ったり、楽しんだり',
          },
          {
            type: 'I',
            score: 5,
            content:
              'いいね、今ならみんなと仲良くなれるよ！歌ったり、楽しんだり',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '旅行先で友人と集合写真を撮りたいとき、誰かに頼まなければいけないとしたら？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `直接、周りの人に撮影をお願いしている`,
          },
          {
            type: 'I',
            score: 5,
            content: `私は友人に小声で「あそこにいる人に聞いてみて」と言う。`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'レストランで食事をするとき、店主から追加の料理を勧められたら、どんな反応をしますか？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `いいなぁ～、おいしそうです！`,
          },
          {
            type: 'N',
            score: 5,
            content: `このサービスはメニューに含まれているのでしょうか？このサービスをすることで利益は残るのでしょうかね？`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'もし、連絡を取っていなかった友人から突然会いたいと言われたら、どんな反応をすればいいのでしょうか。',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'すぐに「どうしたんですか？',
          },
          {
            type: 'N',
            score: 5,
            content: `いきなり？お金を借りたいんですか？と心配になり聞いてみる。`,
          },
        ],
      },
      {
        which: 'SN',
        question: '友人と震災映画を観に行った場合、どちらが近いでしょうか？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `友人と震災映画を観に行った場合、どちらが近いでしょうか？`,
          },
          {
            type: 'N',
            score: 5,
            content: `自分が主人公だったら、まず何をすべきか？まずマートを襲うべき？`,
          },
        ],
      },
      {
        which: 'TF',
        question: `もし、友人が映画を見て感動して泣いてしまったら、あなたはどうしますか？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'なぜ泣くのですか？何のために泣いたのか？',
          },
          {
            type: 'F',
            score: 5,
            content: '私も涙が出そうです、私も完全に感動しています',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'もし、あなたの友人が悩みを打ち明け、「動揺している」と言ったら、あなたは何と言いますか？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `あれこれ試してみてください」と言いたいですね。`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '私なら、「ああ、わかりますよ、動揺しているんでしょう。すぐに元気になりますよ！',
          },
        ],
      },
      {
        which: 'TF',
        question: `もし、何人かでメニューを選ばなければならないとしたら、どうしますか？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '早速、すぐにメニューを提案します。',
          },
          {
            type: 'F',
            score: 5,
            content: '食べたいメニューがあるのですが、待ちます。',
          },
        ],
      },
      {
        which: 'JP',
        question: '全く知らない土地で出会った時、どうする？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `前日の行き方、高速ルートなど、事前に調べておくようにしています。`,
          },
          {
            type: 'P',
            score: 5,
            content: `前日に所要時間を確認し、当日はルート検索をするだけです`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          '恋人に「今度の週末に予定を入れよう」と言われたら、どう反応しますか？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'もう、どうしたらいいのか考えています',
          },
          {
            type: 'P',
            score: 5,
            content: '来週なのに、なぜもうスケジュールが決まっているのだろう？',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'もし、あなたの友人が「約束の時間に30分遅れる」と言ったら、あなたはどう反応しますか?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '30分ですか?もう帰っちゃったよ...じゃあ、スケジュールも調整しないとね',
          },
          {
            type: 'P',
            score: 5,
            content: '実は私も遅刻しそうになりました(笑) むしろ好きです～(笑)',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicJP/INFP.jpg',
      },
    ],
  },
  // earthCharacteristic
  {
    info: {
      mainTitle: '지구 성격 테스트',
      subTitle: '지구로 보는 나의 성격은?',
      mainUrl: 'earthCharacteristic',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/earthCharacteristic-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/earthCharacteristic-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '주말에 쉴 때 나는??',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '밖으로 나간다',
          },
          {
            type: 'I',
            score: 5,
            content: '하루 종일 집에 누워 있을 수 있다',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '자주 가는 맛집 사장님이 나에게 아는 척을 하며 서비스를 준다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '기분이 좋아서 더 자주 가야지 생각 한다',
          },
          {
            type: 'I',
            score: 5,
            content: '기분은 좋지만 부담스럽다',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구와 사소한 갈등이 생겼다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `당장 갈등을 해결하려고 한다`,
          },
          {
            type: 'I',
            score: 5,
            content: `일단 생각을 하고, 천천히 갈등을 해결하려고 한다`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '친구가 나에게 “만약에 지구가 멸망하면 어떻게 할 거야?”라고 물어본다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `갑자기? 아니 그런 생각을 왜 하는 거야?`,
          },
          {
            type: 'N',
            score: 5,
            content: `생각만 해도 무서워..ㅠ 살 방법이 있으려나..?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '친구가 나에게 ‘여름에 겨울옷 입기 VS 겨울에 여름옷 입기’ 밸런스 게임을 물어본다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '“이런 거 너나 해”하면서 대답하지 않는다',
          },
          {
            type: 'N',
            score: 5,
            content: `"옷 입는 거 몇 벌까지 가능한데?” 물어보면서 진지하게 고민한다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구들과 이야기할 때 나와 가까운 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `현재 이야기 주제에 집중한다`,
          },
          {
            type: 'N',
            score: 5,
            content: `이야기하면서 다른 생각을 한다`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 지구가 멸망하는 내용의 영화를 봤다고 한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '재밌었어? 뻔한 결말이었을 것 같은데',
          },
          {
            type: 'F',
            score: 5,
            content: '오우 스릴 넘쳤겠는데? 재밌었어?',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 스트레스받는다며 술 먹자고 한다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `안주는 뭐 먹을 거야? 그럴 때는 맛있는 걸 먹어야 해`,
          },
          {
            type: 'F',
            score: 5,
            content: '무슨 일이야 왜 그래?? 누구야 누가 힘들게 했어',
          },
        ],
      },
      {
        which: 'TF',
        question: `사람들에게 관심을 표현할 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '무한한 관심과 질문을 한다',
          },
          {
            type: 'F',
            score: 5,
            content: '경청과 리액션을 한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 더 불편한 상황은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `갑작스러운 변화가 발생했을 때`,
          },
          {
            type: 'P',
            score: 5,
            content: `더 좋은 방법이 있는데, 계획대로만 해야 할 때`,
          },
        ],
      },
      {
        which: 'JP',
        question: '물건을 살 때 내가 고민하는 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '어차피 계획된 소비라 고민 없이 구매한다',
          },
          {
            type: 'P',
            score: 5,
            content:
              '나의 눈길을 사로잡았는데 무슨 고민이 필요한가? 일단 구매한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '한 달 동안 세계 일주를 한다고 했을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '바로 한 달을 세세하게 나눠서 치밀하게 계획 세운다',
          },
          {
            type: 'P',
            score: 5,
            content:
              '가기 전까지 중요한 큰 것들만 정하고, 설레는 감정을 만끽한다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristic/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristic/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristic/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristic/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristic/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristic/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristic/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristic/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristic/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristic/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristic/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristic/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristic/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristic/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristic/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/earthCharacteristic/INFP.png',
      },
    ],
  },
  // polaroidCharacteristicEng
  {
    info: {
      mainTitle: 'Polaroid Personality Test',
      subTitle:
        'What is your personality that can be recognized through Polaroid photos?',
      mainUrl: 'polaroidCharacteristicEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/polaroidCharacteristicEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/polaroidCharacteristicEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'What is the meaning of rest for you?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Going out and meeting people',
          },
          {
            type: 'I',
            score: 5,
            content: 'Resting at home all day',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'If you Somehow came to a karaoke with your friends who are not that comfortable and close to you. What would you do?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'Great, I can get to know everyone right now! I would sing and have fun',
          },
          {
            type: 'I',
            score: 5,
            content:
              "I think it would be a little awkward. I'll just sit and listen",
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What if you want to take a group photo with your friends while traveling and you have to ask someone to do it?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I directly ask someone around me to take a picture`,
          },
          {
            type: 'I',
            score: 5,
            content: `I whisper to my friend to ask someone over there`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'When you eat at a restaurant, how would you react if the owner offered you an extra dish?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I love it~ it looks delicious!`,
          },
          {
            type: 'N',
            score: 5,
            content: `Is this service included in the menu? I wonder if there is any profit left by doing this service?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          "If a friend you hadn't been in contact with all of a sudden asked to see you, what can be your reaction?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: "I immediately ask what's the matter.",
          },
          {
            type: 'N',
            score: 5,
            content: `Suddenly? You want to borrow money? I worry and ask.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'If you went out to see a disaster movie with your friend, which one is closer to you?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I enjoyed the movie~ It was good to see it`,
          },
          {
            type: 'N',
            score: 5,
            content: `What should I do first if I were the main character? Should I rob the mart first`,
          },
        ],
      },
      {
        which: 'TF',
        question: `If your friend is moved to tears by the movie, what will you do?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Why are you crying? What did you cry for?',
          },
          {
            type: 'F',
            score: 5,
            content: "I'm in tears too, I'm completely moved as well",
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What would you say if your friend told you about his troubles and said he was upset?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I would say “try this and that”`,
          },
          {
            type: 'F',
            score: 5,
            content:
              'I would say “oh I understand, you must be upset. You will feel better soon!',
          },
        ],
      },
      {
        which: 'TF',
        question: `What if you have to choose a menu with several people, what would you do?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I immediately suggest a menu right away.',
          },
          {
            type: 'F',
            score: 5,
            content: 'There is a menu I want to eat, but I wait. ',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What do you do when you meet someone in a completely new place?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I try to find out in advance how to get there the day before, the fast route, etc.`,
          },
          {
            type: 'P',
            score: 5,
            content: `I just check the time it takes the day before and searched for the route on the day`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'How would you react if your lover asked you to make plans for the upcoming weekend?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I have already thought of what to do',
          },
          {
            type: 'P',
            score: 5,
            content: "It's next week, why do we have to schedule already?",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'How would you react if your friend told you that he would be 30 minutes late for an appointment?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "30 minutes? I've already left... then, I have to adjust the schedule too. .",
          },
          {
            type: 'P',
            score: 5,
            content: 'Actually, I was almost late too haha I rather like it~',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristicEng/INFP.jpg',
      },
    ],
  },
  // hmall
  {
    info: {
      mainTitle: '브랜드 테스트',
      subTitle: '브랜드 테스트',
      mainUrl: 'hmall',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/hmall-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/hmall-thumb.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '날씨 온도 습도 모든 것이 완벽한 간만에 쉬는 주말, 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '날씨가 이렇게 좋은데 이러고 있을 수 없어 나가야지!',
          },
          {
            type: 'I',
            score: 5,
            content: '이런 온도 습도 완벽해! 낮잠자기 딱 좋잖아?!..zZ',
          },
        ],
      },
      {
        which: 'EI',
        question: '때 마침 친구가 자기도 쉬는 날이라고 만나자며 연락이 왔다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '진짜? 대박 잘됐다! 콜! 마침 나가려던 참 이였는데!',
          },
          {
            type: 'I',
            score: 5,
            content: '아.. 지금? 갑자기..? (고민중..)',
          },
        ],
      },
      {
        which: 'EI',
        question: '결국 만난 친구가 쇼핑하자며, 백화점에 가자고 한다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `완전 좋아! 안 그래도 요즘 쇼핑하고 싶었는데!!`,
          },
          {
            type: 'I',
            score: 5,
            content: `어차피 폰으로 다 볼 수 있는데 조용한 카페나 가서 구경하자!`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '카페에서 폰으로 쇼핑을 하기로 했다 그런데 마침 경품 이벤트 알림이 뜬다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `경품 이벤트? 뭐 주는 거지? 클릭해서 자세히 알아본다`,
          },
          {
            type: 'N',
            score: 5,
            content: `경품 이벤트? 이미 당첨 돼서 1등 상품을 받는 상상을 해본다`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '이벤트 참여 중에 카페 진동벨이 울렸다, 평소 덤벙거리는 친구가 음료를 불안하게 들고 온다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '왜 저렇게 불안하게 들고오는거야?..',
          },
          {
            type: 'N',
            score: 5,
            content: `이미 내 앞에서 음료를 쏟는 상상을 한다`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '무사히 도착 후 다시 쇼핑을 하는 중, 마음에 드는 제품을 보게 되었다 내가 중점적으로 보는 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `성능과, 효율, 어떤 점이 편리하게 설계되었는지 본다`,
          },
          {
            type: 'N',
            score: 5,
            content: `디자인과 컬러 느낌 적인 부분을 중점적으로 본다`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 쇼핑 중 사려는 2가지 제품 중에 고민이라며 어떤 제품을 살지 물어본다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '결정해서 말해주고 왜 이 물건을 선택했는지 설명해준다 ',
          },
          {
            type: 'F',
            score: 5,
            content: '너무 어려워.. 나도 덩달아 고민에 빠진다',
          },
        ],
      },
      {
        which: 'TF',
        question: '내가 결정을 해줬는데도 친구가 결정을 쉽게 못한다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `결정 잘해 준거 같은데? 왜 지?`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '나름 신중하게 골랐는데.. 혹시 내가 결정한게 기분이 나빴나?',
          },
        ],
      },
      {
        which: 'TF',
        question: `마침내 친구가 물건을 고르고, 나에게 고맙다고 말하려는 상황 내가 듣고 싶은 칭찬은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '너 진짜 설득력 좋다! 어떻게 그런 생각을 했어? 너 말 듣고 이거 질렀다!',
          },
          {
            type: 'F',
            score: 5,
            content: '고마워!! 진짜 고민 많았는데 역시 너 밖에 없다!!',
          },
        ],
      },
      {
        which: 'JP',
        question: '어느새 저녁을 먹을 시간 어떤 음식점을 갈지 정할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `여기서 정하고 나가자 어디 갈까? 생각해 둔 곳이 있긴 한데..`,
          },
          {
            type: 'P',
            score: 5,
            content: `어디 갈래? 일단 나가서 정할까?`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          '친구와 갈 맛집을 정한 후 가는 길에 사람들이 몰려 있는 구간이 있다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '뭐지? 싶지만 원래 가려던 길을 간다',
          },
          {
            type: 'P',
            score: 5,
            content: '뭔지 궁금해서 친구에게 한번 가보자고 한다',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '친구와 밥을 먹고 집에 돌아가는 길 동네에서 처음 보는 낯선 길을 발견했다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '원래 가던 길로 빠르게 집에 간다',
          },
          {
            type: 'P',
            score: 5,
            content: '동네에 이런 길이 있었어? 하며 새로운 길로 가본다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: ``,
        query: 'ESTJ',
        img_src: 'https://images.ktestone.com/resultImages/hmall/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: ``,
        query: 'ESTP',
        img_src: 'https://images.ktestone.com/resultImages/hmall/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: ``,
        query: 'ESFJ',
        img_src: 'https://images.ktestone.com/resultImages/hmall/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: ``,
        query: 'ESFP',
        img_src: 'https://images.ktestone.com/resultImages/hmall/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: ``,
        query: 'ENTJ',
        img_src: 'https://images.ktestone.com/resultImages/hmall/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: ``,
        query: 'ENTP',
        img_src: 'https://images.ktestone.com/resultImages/hmall/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: ``,
        query: 'ENFJ',
        img_src: 'https://images.ktestone.com/resultImages/hmall/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: ``,
        query: 'ENFP',
        img_src: 'https://images.ktestone.com/resultImages/hmall/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: ``,
        query: 'ISTJ',
        img_src: 'https://images.ktestone.com/resultImages/hmall/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: ``,
        query: 'ISTP',
        img_src: 'https://images.ktestone.com/resultImages/hmall/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: ``,
        query: 'ISFJ',
        img_src: 'https://images.ktestone.com/resultImages/hmall/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: ``,
        query: 'ISFP',
        img_src: 'https://images.ktestone.com/resultImages/hmall/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: ``,
        query: 'INTJ',
        img_src: 'https://images.ktestone.com/resultImages/hmall/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: ``,
        query: 'INTP',
        img_src: 'https://images.ktestone.com/resultImages/hmall/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: ``,
        query: 'INFJ',
        img_src: 'https://images.ktestone.com/resultImages/hmall/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: ``,
        query: 'INFP',
        img_src: 'https://images.ktestone.com/resultImages/hmall/INFP.jpg',
      },
    ],
  },
  // polaroidCharacteristic
  {
    info: {
      mainTitle: '폴라로이드 성격 테스트',
      subTitle: '내 성격을 폴라로이드 사진으로 찍어보자',
      mainUrl: 'polaroidCharacteristic',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/polaroidCharacteristic-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/polaroidCharacteristic-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '나에게 진정한 휴식시간 이란 무엇일까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '바깥으로 나가 사람들을 만나며 보내는 시간',
          },
          {
            type: 'I',
            score: 5,
            content: '하루 종일 집에서 쉬며 보내는 시간',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '어쩌다 보니 어색한 친구들과 함께 노래방에 왔다! 내 모습은 어떨까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '이참에 다 친해지는 거지! 신나게 노래 부르고 놈',
          },
          {
            type: 'I',
            score: 5,
            content: '뭔가 노래 부르긴 좀 어색.. 일단 앉아서 부르는 노래 들음',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '여행 중 친구들과 다 함께 단체 사진을 찍고 싶다!\n누군가에게 부탁을 해야 한다면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `주변에 있는 분께 사진 찍어달라고 직접 부탁한다`,
          },
          {
            type: 'I',
            score: 5,
            content: `저분께 부탁해 보라고 친구에게 속삭인다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '식당에서 밥을 먹는데 갑자기 서비스를 주신다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `아싸 서비스 야미~ 맛있겠다!`,
          },
          {
            type: 'N',
            score: 5,
            content: `원래 다 주시는 걸까? 근데 이렇게 주시면 남는 게 있으신가`,
          },
        ],
      },
      {
        which: 'SN',
        question: '평소 연락하지 않던 친구가 갑자기 만나자고 한다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '무슨 일 때문인지 바로 물어본다',
          },
          {
            type: 'N',
            score: 5,
            content: `갑자기 뭐지..? 혹시 돈 빌리러..? 고민하다 물어본다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구와 함께 재난 영화를 보고 나왔다! 내 모습은 어떨까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `영화 재밌었다~ 보길 잘했어`,
          },
          {
            type: 'N',
            score: 5,
            content: `만약에 내가 주인공이라면 뭐부터 해야 할까? 일단 마트부터 털어야겠지?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `영화를 보고 감동을 받아 울고 있는 친구를 본다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜 우는 거지..  어느 부분 때문에 우는 거야?',
          },
          {
            type: 'F',
            score: 5,
            content: '나도 눈물 나ㅜㅜ 완전 감동이지ㅜㅜ',
          },
        ],
      },
      {
        which: 'TF',
        question: '고민을 얘기하며 속상하다는 내 친구! 나의 대답은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `그럼 일단 이렇게 해봐. 저렇게는 해봤어?`,
          },
          {
            type: 'F',
            score: 5,
            content: '그랬구나ㅜ 나였어도 속상했을 거 같아 괜찮아질 거야!!',
          },
        ],
      },
      {
        which: 'TF',
        question: `여러 명이 함께 식사 메뉴를 고를 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '이 메뉴는 어떤지 바로 물어봄',
          },
          {
            type: 'F',
            score: 5,
            content:
              '난 먹고 싶은 메뉴가 있지만 혹시 싫어할 수도 있으니까 일단 눈치 봄',
          },
        ],
      },
      {
        which: 'JP',
        question: '만나기로 한 약속 장소가 처음 가보는 곳일 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `전날 어떻게 가야 할지, 빠른 경로 등 미리 알아봄`,
          },
          {
            type: 'P',
            score: 5,
            content: `전날 대충 걸리는 시간만 보고 당일에 경로 검색해 보면서 감`,
          },
        ],
      },
      {
        which: 'JP',
        question: '다음 주 주말에 약속이 있다! 뭐 할지 일정을 짜볼까?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '이미 뭐 할지 다 생각해 놨음',
          },
          {
            type: 'P',
            score: 5,
            content: '다음 주인데 왜 벌써 일정을 짬?',
          },
        ],
      },
      {
        which: 'JP',
        question: '약속 시간에 30분 정도 늦는다는 친구! 내 반응은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '30분?? 난 이미 출발했는데.. 일정도 수정해야 하잖아..',
          },
          {
            type: 'P',
            score: 5,
            content: '사실 나도 늦을 뻔ㅎㅎ 오히려 좋아~',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristic/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristic/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristic/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristic/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristic/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristic/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristic/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristic/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristic/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristic/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristic/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristic/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristic/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristic/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristic/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/polaroidCharacteristic/INFP.jpg',
      },
    ],
  },
  // loveTicketJP
  {
    info: {
      mainTitle: 'ラブバウチャーテスト',
      subTitle: '恋人からどんなクーポンをもらいたいですか？',
      mainUrl: 'loveTicketJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveTicketJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveTicketJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '恋人からどんなクーポンをもらいたいですか？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'めっちゃ楽しかったー！明日は友達に会いに行かなきゃ～',
          },
          {
            type: 'I',
            score: 5,
            content:
              'とても楽しかったけど、ちょっと疲れた。明日は家でゆっくり休まないと',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'もし、用事があって出かけたが、予定より早く終わってしまった場合、どんな反応が考えられますか？',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '思ったより早く終わっちゃったね、じゃあ、友達呼んで遊びに行くよ',
          },
          {
            type: 'I',
            score: 5,
            content: 'Great!家に帰ってゆっくり休むのが楽しみです',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '頑張った後の金曜日、明日会うはずだった恋人が今日会いましょうと言われたら、どんな反応になるでしょうか？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `もしかして？ 結局、他に何もない！？よし！`,
          },
          {
            type: 'I',
            score: 5,
            content: `今日は家に帰ってゆっくり休みたいので、明日も楽しみましょう`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'Iもし恋人に「突然戦争が起きたらどうしよう」と言われたら、あなたの反応はどうなりますか？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `んなことは起きないので心配しないでください。`,
          },
          {
            type: 'N',
            score: 5,
            content: `っさと必要なものを手に入れて、地下バンカーに行きましょう。 地下バンカーはどこですか？`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'ダイエットでお腹がすいたとき、どう思いますか？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '辞めたくなるほどつらいです',
          },
          {
            type: 'N',
            score: 5,
            content: `もし私がいくら食べても太らない人だったら、今何を食べますか？`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'もし恋人と結婚するとしたら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `私たちの関係はまだ結婚には程遠いです。 後から考えても遅くないと思います。`,
          },
          {
            type: 'N',
            score: 5,
            content: `新婚旅行や結婚式で行く場所を想像するだけで幸せな気分になります。`,
          },
        ],
      },
      {
        which: 'TF',
        question: `恋人から「好きなだけだよ」と言われたら、あなたの反応はどうなりますか？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'そうなんですか、私のどこが好きなんですか～～～～～～～～～～～～～',
          },
          {
            type: 'F',
            score: 5,
            content: '俺も俺も！ 仲良くなれるんだな！（感動）',
          },
        ],
      },
      {
        which: 'TF',
        question: '恋人の体調不良を知ったらどう思いますか？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `恋人から理由を聞きたいです`,
          },
          {
            type: 'F',
            score: 5,
            content: 'どうしたんだろう、私のせいじゃないのかな、どうなんだろう',
          },
        ],
      },
      {
        which: 'TF',
        question: `恋人が「今日は悲しい夢を見たから泣いた」と言ったら、あなたの反応は？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'どんな夢を見たの？ 夢は夢だから泣かないでね',
          },
          {
            type: 'F',
            score: 5,
            content:
              'そんな悲しい夢だったのでしょうか、どんな夢だったのでしょうか',
          },
        ],
      },
      {
        which: 'JP',
        question: '恋人との記念日があと2週間、あなたの反応は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `あと2週間しかないって？ 大変だね、とりあえずリストから作成しよう.`,
          },
          {
            type: 'P',
            score: 5,
            content: `まだ準備期間があるので、ゆっくり考えた方がいいと思います.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '恋人の誕生日プレゼントを買うとき私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'ブランドや口コミなどをよく確認してから買うべきですね',
          },
          {
            type: 'P',
            score: 5,
            content:
              '買うプレゼントは決めたけど、もっといいプレゼントがあれば変えられます',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'ばらく掃除をしていなかった家を掃除しようとしたら、突然恋人が訪ねてきたとしたら、どんな反 応になるでしょうか？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '恋人に会えるのは嬉しいけど、予定があるからちょっと気が引ける (ジェイ)',
          },
          {
            type: 'P',
            score: 5,
            content: '突然ですが、明日は家の掃除ができますね(ﾟ▽ﾟ)',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketJP/INFP.jpg',
      },
    ],
  },
  // loveTicketEng
  {
    info: {
      mainTitle: 'Love Voucher Test',
      subTitle: 'What voucher would you like to receive from your lover?',
      mainUrl: 'loveTicketEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveTicketEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveTicketEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question:
          'How do you feel when you arrive home after a fun date with your lover?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'It was so much fun! I have to meet some friends tomorrow~',
          },
          {
            type: 'I',
            score: 5,
            content:
              'It was so fun but a bit tired. I should rest at home tomorrow ',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'if you went out because you had something to do, but it was finished sooner than expected, what can be your reaction?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              "It's over sooner than I thought. Okay, I will call my friends to hang out. ",
          },
          {
            type: 'I',
            score: 5,
            content: "Great! I'm excited to go home and rest.",
          },
        ],
      },
      {
        which: 'EI',
        question:
          'On Friday after hard work, if your lover you were supposed to see tomorrow asks to meet you today, what can be your reaction?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Maybe? After all, there is nothing else! Okay! `,
          },
          {
            type: 'I',
            score: 5,
            content: `I want to go home and rest today, so let's have fun tomorrow`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'If your lover asks “What should we do if there is a sudden war?” what can be your reaction?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Don’t worry because that doesn't happen.`,
          },
          {
            type: 'N',
            score: 5,
            content: `Let’s quickly grab what we need and go to the underground bunker. Where is the underground bunker?`,
          },
        ],
      },
      {
        which: 'SN',
        question: "When you're so hungry on a diet, what do you think?",
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'If I were a person who would not gain weight no matter how much you ate, what would I eat now?',
          },
          {
            type: 'N',
            score: 5,
            content: `I think my friend will tell me what happens later`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What if you were to marry your lover?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Our relationship is still far from marriage. I don't think it's too late to think about it later.`,
          },
          {
            type: 'N',
            score: 5,
            content: `Just imagining where we will go for our honeymoon or wedding makes me happy.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `If you lover said to you “I just like you” what can be your reaction?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Really? What do you like about me? I like you about ~~~',
          },
          {
            type: 'F',
            score: 5,
            content: 'me too me too! I guess we can get along! (Impressed)',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What do you think when you find out that your lover is not feeling well?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I wish to hear from my lover why.`,
          },
          {
            type: 'F',
            score: 5,
            content: "What's going on? Maybe it's not because of me? I wonder.",
          },
        ],
      },
      {
        which: 'TF',
        question: `your lover says “I cried because I had a sad dream today”, what can be your reaction?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'What did you dream about? A dream is just a dream, so don’t cry.',
          },
          {
            type: 'F',
            score: 5,
            content: 'Was it such a sad dream, what kind of dream was it?',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Your anniversary with your lover is in two weeks, what can be your reaction?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Only two weeks left? I have to hurry up. Let's start with the list first.`,
          },
          {
            type: 'P',
            score: 5,
            content: `I think I should think about it slowly because I still have enough time to prepare.`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          "When you buy a present for your lover's birthday, what do you do?",
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I should buy it after carefully checking the brand, reviews, etc.',
          },
          {
            type: 'P',
            score: 5,
            content:
              "I've decided which gift to buy, but I can change it if I find a better gift",
          },
        ],
      },
      {
        which: 'JP',
        question:
          "If you were trying to clean your house that hadn't been cleaned for a while, but your lover suddenly came to visit you, what can be your reaction?",
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "It's nice to see my lover, but I'm a little uncomfortable because I have plans.",
          },
          {
            type: 'P',
            score: 5,
            content: "It's sudden, but I can clean my house tomorrow.",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveTicketEng/INFP.jpg',
      },
    ],
  },
  // loveTicket
  {
    info: {
      mainTitle: '연애 이용권 테스트',
      subTitle: '연인에게 받고 싶은 이용권은 무엇일까?',
      mainUrl: 'loveTicket',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/loveTicket-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveTicket-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '연인과 즐거운 데이트 후 집에 도착했을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '너무 재밌었다!! 내일은 친구들 좀 만나야지~',
          },
          {
            type: 'I',
            score: 5,
            content: '재밌었지만 좀 피곤하다 내일은 집에서 쉬어야지',
          },
        ],
      },
      {
        which: 'EI',
        question: '해야 할 일이 있어 나갔는데 생각보다 빨리 끝났다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '오 생각보다 빨리 끝났네? 친구들한테 연락해 봐야지',
          },
          {
            type: 'I',
            score: 5,
            content: '아싸ㅎㅎ 빨리 집에 가서 쉬어야지 신난다',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '고된 업무가 끝난 금요일!\n내일 보기로 했던 애인이 오늘도 만나자고 한다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `그럴까? 어차피 다른 일도 없으니까! 좋아!!`,
          },
          {
            type: 'I',
            score: 5,
            content: `오늘은 집에 가서 쉬고 싶어ㅜ 내일 재밌게 놀자!`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '애인이 ‘만약 갑자기 전쟁이 난다면 어떻게 해야할까?’ 라고 묻는다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `그런 일은 일어나지 않으니까 걱정하지 마^^`,
          },
          {
            type: 'N',
            score: 5,
            content: `일단 필요한 거 빨리 챙겨서 지하 벙커로… 근데 지하 벙커는 어딨을까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '다이어트를 하는 중! 배가 너무 고플 때 하는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '다이어트 힘들다.. 때려 치고 싶다..',
          },
          {
            type: 'N',
            score: 5,
            content: `아무리 먹어도 살이 안 찌는 체질이라면 난 지금 뭘 먹고있을까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '만약 애인과 결혼을 하게 된다면 어떨까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `아직 결혼은 먼 얘기지~ 그때 가서 생각해도 안 늦어`,
          },
          {
            type: 'N',
            score: 5,
            content: `일단 결혼식은 이런 곳, 신혼여행은 여기~ 상상만 해도 행복하다`,
          },
        ],
      },
      {
        which: 'TF',
        question: `애인이 나에게 ‘난 그냥 네가 좋아!’라고 한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '정말? 어떤 부분이?? 난 너의 이런 부분이 좋아!',
          },
          {
            type: 'F',
            score: 5,
            content: '나도 나도! 역시 우린 통하나 봐~!! (감동의 도가니)',
          },
        ],
      },
      {
        which: 'TF',
        question: '기분이 안 좋아 보이는 애인을 볼 때 드는 내 생각은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `기분이 왜 안 좋은지 말로 해주면 좋을 텐데`,
          },
          {
            type: 'F',
            score: 5,
            content: '안 좋은 일이라도 있는 걸까.. 혹시 나 때문은 아니겠지..?',
          },
        ],
      },
      {
        which: 'TF',
        question: `애인이 ‘오늘 슬픈 꿈 꿔서 울었어ㅜ’ 라고 한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '무슨 꿈?? 꿈은 꿈이니까 울지마~',
          },
          {
            type: 'F',
            score: 5,
            content: '많이 슬펐어?ㅜ 무슨 꿈이길래 울었어~?',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과의 기념일이 2주 뒤라면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `2주밖에 안 남았다고?? 큰일이네 일단 리스트부터 작성하자`,
          },
          {
            type: 'P',
            score: 5,
            content: `2주 뒤면 여유 있지! 선물 천천히 생각해 봐야겠다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '애인의 생일선물을 살 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '미리 여러 브랜드, 후기 등 다 찾아보고 결정되면 바로 구입',
          },
          {
            type: 'P',
            score: 5,
            content:
              '어떤 선물을 살지 정해 두었지만 더 나은 선물을 발견하면 변경 가능',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '오늘은 집에서 밀린 집 청소를 하기로 결정!\n그런데 갑자기 애인이 집 앞으로 찾아온다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '보는 건 좋지만.. 오늘의 내 계획이 있는데 조금 불편..',
          },
          {
            type: 'P',
            score: 5,
            content: '갑작스럽지만.. 집 청소는 내일 하지 뭐~',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/loveTicket/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/loveTicket/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/loveTicket/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/loveTicket/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/loveTicket/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/loveTicket/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/loveTicket/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/loveTicket/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/loveTicket/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/loveTicket/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/loveTicket/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/loveTicket/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/loveTicket/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/loveTicket/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/loveTicket/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/loveTicket/INFP.jpg',
      },
    ],
  },
  // labelStickerCN
  {
    info: {
      mainTitle: '标签贴纸测试',
      subTitle: '用贴纸洞察一下我的内心吧',
      mainUrl: 'labelStickerCN',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/labelStickerCN-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/labelStickerCN-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'CN',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '和朋友们玩着玩着发现已经到晚上10点了！',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '今晚要嗨通宵，谁都不准回家',
          },
          {
            type: 'I',
            score: 5,
            content: '慢慢地想回家了，一直看周围的眼色',
          },
        ],
      },
      {
        which: 'EI',
        question: '朋友聚会结束后回家的时候我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '想和其他人一起边聊边走',
          },
          {
            type: 'I',
            score: 5,
            content: '想独自一个人安静地走',
          },
        ],
      },
      {
        which: 'EI',
        question: '周末的约会取消了的时候？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `我晕…没人和我见面了么？得联系联系别人`,
          },
          {
            type: 'I',
            score: 5,
            content: `虽然有点可惜..也挺好的呀？这样我也不用出门了？`,
          },
        ],
      },
      {
        which: 'SN',
        question: '在下决定的时候我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `一旦下定决心了就不会反复思考`,
          },
          {
            type: 'N',
            score: 5,
            content: `一直边思考我下的决定是否正确边苦恼`,
          },
        ],
      },
      {
        which: 'SN',
        question: '过马路的时候我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '什么也不想赶快过马路',
          },
          {
            type: 'N',
            score: 5,
            content: `想象着这里如果出车祸司机逃跑的话我该怎么办？`,
          },
        ],
      },
      {
        which: 'SN',
        question: '上地铁啦！但谁都不坐在我旁边的话？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `大家都站着呀，空座位那么多，我运气真好`,
          },
          {
            type: 'N',
            score: 5,
            content: `为什么没人坐呢？难道我看起来很奇怪吗?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `好朋友们吵架时我会？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '因为那点儿事儿吵，哇，真有意思',
          },
          {
            type: 'F',
            score: 5,
            content: '为什么吵架呢..?该怎么劝呢？',
          },
        ],
      },
      {
        which: 'TF',
        question: '朋友在约会地点哭得很伤心的话？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `干嘛哭啊..问的话不太好吧，还是别打扰他了`,
          },
          {
            type: 'F',
            score: 5,
            content: '干嘛哭啊~告诉我啊，搞得我也想哭了呢T',
          },
        ],
      },
      {
        which: 'TF',
        question: `朋友看了我的卡考却不回复的话？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '可能太忙了吧，不太在意',
          },
          {
            type: 'F',
            score: 5,
            content: '重新看一遍聊天记录，看看是不是我说错话了',
          },
        ],
      },
      {
        which: 'JP',
        question: '准备出门时我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `计划好的时间起开始准备，所以总是很悠闲`,
          },
          {
            type: 'P',
            score: 5,
            content: `明明很早就开始准备了，为什么总是快迟到呢`,
          },
        ],
      },
      {
        which: 'JP',
        question: '整理书桌的时候我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '按照经常使用的顺序整理整齐',
          },
          {
            type: 'P',
            score: 5,
            content: '即使看起来很乱也能马上找出自己需要的东西',
          },
        ],
      },
      {
        which: 'JP',
        question: '家务堆了一堆却很疲惫的话？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '累也要先完成家务之后再休息',
          },
          {
            type: 'P',
            score: 5,
            content: '先休息，家务活完了再考虑',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerCN/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerCN/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerCN/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerCN/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerCN/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerCN/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerCN/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerCN/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerCN/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerCN/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerCN/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerCN/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerCN/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerCN/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerCN/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerCN/INFP.jpg',
      },
    ],
  },
  // emotionGraphCN
  {
    info: {
      mainTitle: '情绪图表测试',
      subTitle: '通过图表确认我的情绪起伏',
      mainUrl: 'emotionGraphCN',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/emotionGraphCN-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/emotionGraphCN-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'CN',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '什么样的情况更折磨我？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '几天不出门一步',
          },
          {
            type: 'I',
            score: 5,
            content: '几天一直要在外面玩几个通宵',
          },
        ],
      },
      {
        which: 'EI',
        question: '和初次见面的人单独相处时我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '虽然初次见面会有点尴尬，但聊得来的话马上会成为好朋友',
          },
          {
            type: 'I',
            score: 5,
            content: '虽然会聊天，但尴尬的空气中会感到不舒服',
          },
        ],
      },
      {
        which: 'EI',
        question: '今天在外面玩了一整天的话？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `虽然想休息但已经有很多约会等着我了`,
          },
          {
            type: 'I',
            score: 5,
            content: `接下来要宅一段时间，谁都别管我`,
          },
        ],
      },
      {
        which: 'SN',
        question: '朋友说想一个人去旅游时我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `一个人去的话有意思吗？要去哪里？`,
          },
          {
            type: 'N',
            score: 5,
            content: `一个人去的话会不会遇到小偷或被绑架？`,
          },
        ],
      },
      {
        which: 'SN',
        question: '朋友突然有急事要先走的时候我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '以后问发生什么事的话应该会告诉我的',
          },
          {
            type: 'N',
            score: 5,
            content: `이到底是什么事那么着急？？明明是出大事了`,
          },
        ],
      },
      {
        which: 'SN',
        question: '和朋友见面之后在等回家的公交时我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `快回家休息吧，今天玩得真嗨`,
          },
          {
            type: 'N',
            score: 5,
            content: `其实最后一班公交已经走了，考虑该怎么回家`,
          },
        ],
      },
      {
        which: 'TF',
        question: `什么情况下会说‘也有可能会这样’`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '完全无法理解，很郁闷的时候',
          },
          {
            type: 'F',
            score: 5,
            content: '安慰对方的时候或说没事儿的时候',
          },
        ],
      },
      {
        which: 'TF',
        question: '朋友对我说自己很伤心时我的回复是？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `咦？为什么？哪里让你伤心了？`,
          },
          {
            type: 'F',
            score: 5,
            content: '这也许会让你伤心，对不起我不是故意的',
          },
        ],
      },
      {
        which: 'TF',
        question: `对方说‘最近一整天情绪都不稳定’时我会？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '人嘛，有时候会这样',
          },
          {
            type: 'F',
            score: 5,
            content: '你不说我也在担心..最近发生什么事了？？',
          },
        ],
      },
      {
        which: 'JP',
        question: '聊天中朋友突然说其他话题时我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `喔，什么情况呀？好搞笑hh 但我们不是在聊别的吗`,
          },
          {
            type: 'P',
            score: 5,
            content: `别说其他的，我们不是在聊别的话题嘛`,
          },
        ],
      },
      {
        which: 'JP',
        question: '朋友突然说要来我家玩时我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '啊..为什么？现在吗？（今天有其它日程..不舒服..）',
          },
          {
            type: 'P',
            score: 5,
            content: '干嘛呀？本来有别的事，那就推到明天喽，反而更好呢~',
          },
        ],
      },
      {
        which: 'JP',
        question: '出门前选衣服的时候我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '提前看好天气再考虑好日程，然后选好搭配',
          },
          {
            type: 'P',
            score: 5,
            content: '虽然有提前想好要穿什么，但当天又受心情影响改变想法',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphCN/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphCN/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphCN/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphCN/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphCN/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphCN/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphCN/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphCN/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphCN/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphCN/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphCN/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphCN/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphCN/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphCN/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphCN/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphCN/INFP.jpg',
      },
    ],
  },
  // labelStickerLoveEng
  {
    info: {
      mainTitle: 'Label Sticker Test Love Edition',
      subTitle: "Let's express our feelings to our lovers with stickers",
      mainUrl: 'labelStickerLoveEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/labelStickerLoveEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/labelStickerLoveEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question:
          'If you come to a restaurant to eat and the owner of the restaurant talks to you in a friendly way, what can be your reaction?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'So kind! I think I should come often.',
          },
          {
            type: 'I',
            score: 5,
            content: "I'm grateful, but a little uncomfortable.",
          },
        ],
      },
      {
        which: 'EI',
        question:
          'If you have appointments all week, what can be your reaction?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              "It might be a bit tiring, but it's better than being at home.",
          },
          {
            type: 'I',
            score: 5,
            content:
              'It is a terrible question. I am not going to be that busy.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'When you come home after your appointment, which one is close to you?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I had so much fun today, tomorrow will be fun too haha`,
          },
          {
            type: 'I',
            score: 5,
            content: `Oh, I am finally home I'm tired. I have to rest unconditionally from tomorrow.`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'When you blame the elevator, which one is close to you?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I hope to arrive soon.`,
          },
          {
            type: 'N',
            score: 5,
            content: `I worry about what to do if the elevator stops and my cell phone is not working.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          "If you lover is with someone of the opposite sex you don't know, what can be your reaction?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I immediately ask who the person is.',
          },
          {
            type: 'N',
            score: 5,
            content: `I would imagine all sorts of things in a brief moment before asking.`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do you often say?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `It can be, well, what are you talking about?`,
          },
          {
            type: 'N',
            score: 5,
            content: `If, maybe, it could be, why?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What does "I'll think about it" mean in your definition?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I really need some time to think about it',
          },
          {
            type: 'F',
            score: 5,
            content:
              "It's a way of talking that I want to say no but don't want to hurt the other person's feelings.",
          },
        ],
      },
      {
        which: 'TF',
        question:
          'How would you react if your lover talked about their worries?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I try to solve it as much as possible.`,
          },
          {
            type: 'F',
            score: 5,
            content: 'I try to listen as much as possible.',
          },
        ],
      },
      {
        which: 'TF',
        question: `My lover said, “You dressed very comfortably today” what can be your reaction?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Eh, I wanted to dress comfortably today',
          },
          {
            type: 'F',
            score: 5,
            content:
              "Am I too underdressed? I think I'll have to pay more attention next time",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Today is the day I am supposed to clean the house, but if If your lover asks you to go out on the day you have to clean the house, what can be your reaction?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I have to clean up today... it's a bit uncomfortable…`,
          },
          {
            type: 'P',
            score: 5,
            content: `I'll do the cleaning later! I think I can do it tomorrow.`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'How do you usually go on a date?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "I always find out what I'm going to do in advance and meet.",
          },
          {
            type: 'P',
            score: 5,
            content: 'I prefer to meet first and decide what to do later.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'How do you usually make appointments?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I am not available now, but why don’t we have dinner together next weekend.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'I ask first this and that, such as some questions like what are you doing now? You want to try pork belly?',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLoveEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLoveEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLoveEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLoveEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLoveEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLoveEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLoveEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLoveEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLoveEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLoveEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLoveEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLoveEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLoveEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLoveEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLoveEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLoveEng/INFP.jpg',
      },
    ],
  },
  // labelStickerFriendship
  {
    info: {
      mainTitle: '라벨 스티커 테스트 우정편',
      subTitle: '스티커로 우리의 우정을 확인해 보자!',
      mainUrl: 'labelStickerFriendship',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/labelStickerFriendship-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/labelStickerFriendship-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '나의 스트레스 해소법은 무엇일까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구들을 만나 수다 떨고 놀기',
          },
          {
            type: 'I',
            score: 5,
            content: '집에서 아무것도 안 하고 누워 쉬기',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구들과의 단체 모임에서 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '안 친한 친구들과도 이참에 친해지면 좋겠다',
          },
          {
            type: 'I',
            score: 5,
            content: '안 친한 친구가 말 걸면 불편한데',
          },
        ],
      },
      {
        which: 'EI',
        question: '일주일 동안 친구들과 약속을 잡는 횟수는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `일주일에 최소 3~4일 일주일 내내도 가능`,
          },
          {
            type: 'I',
            score: 5,
            content: `일주일에 0~2일 일단 연달아서 약속 안 잡음`,
          },
        ],
      },
      {
        which: 'SN',
        question: '달리는 버스에 앉아 창밖을 바라볼 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `날씨 좋네 나가서 놀고 싶다`,
          },
          {
            type: 'N',
            score: 5,
            content: `운전기사님은 하루에 몇 번이나 이 길을 다니시는걸까?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '일주일 동안 피자만 먹기 가능? 이라고 질문 했을 때 내 반응은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '오우 불가능 느끼해서 안돼',
          },
          {
            type: 'N',
            score: 5,
            content: `(매일 피자 먹는 상상 중) 피자 종류는 매일 다름?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구와 공사장을 지나가는 중 나의 모습은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `공사 중이군 (아무 생각 없이 지나간다)`,
          },
          {
            type: 'N',
            score: 5,
            content: `저거 떨어지면 어떡해? 날라올 것 같아 무서워`,
          },
        ],
      },
      {
        which: 'TF',
        question: `넌 나랑 친구 왜 해? 라고 묻는다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '질문이 그게 뭐임 넌 왜 하는데',
          },
          {
            type: 'F',
            score: 5,
            content: '왜 이런 질문을 하는 거지.. ? 내가 뭐 잘못했나?',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 다쳤다고 연락이 왔다! 내 반응은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `어쩌다가 다친 거야 병원 다녀왔어?`,
          },
          {
            type: 'F',
            score: 5,
            content: '무슨 일이야? 괜찮아? 많이 아파?',
          },
        ],
      },
      {
        which: 'TF',
        question: `오전에 출근해야 하는 친구가 새벽까지 놀고 있다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '체력 좋다? 언제 가려고?',
          },
          {
            type: 'F',
            score: 5,
            content: '곧 출근해야 하잖아 힘들 텐데 괜찮겠어?',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구들을 집에 초대하기로 했다면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `1인이 최소 이 정도 먹는다고 치고.. 충분하겠다`,
          },
          {
            type: 'P',
            score: 5,
            content: `일단 이 정도 하고 모자라면 더 시키자`,
          },
        ],
      },
      {
        which: 'JP',
        question: '외출하기 귀찮을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '(약속 시간 한참 전) 그래도 슬슬 준비해야지',
          },
          {
            type: 'P',
            score: 5,
            content: '(약속 시간 아슬아슬) 이제는 진짜 준비 해야지',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구와 만나 밥을 먹으러 가는 중! 내 모습은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '원래 이거 먹기로 했잖아 예약도 다 해놨음',
          },
          {
            type: 'P',
            score: 5,
            content: '저게 더 맛있어 보이지 않아? 저거 먹을래?',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerFriendship/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerFriendship/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerFriendship/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerFriendship/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerFriendship/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerFriendship/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerFriendship/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerFriendship/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerFriendship/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerFriendship/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerFriendship/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerFriendship/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerFriendship/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerFriendship/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerFriendship/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerFriendship/INFP.jpg',
      },
    ],
  },
  // emotionGraphEng
  {
    info: {
      mainTitle: 'Emotion Graph Test',
      subTitle: "Let's check your emotional ups and downs with a graph!",
      mainUrl: 'emotionGraphEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/emotionGraphEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/emotionGraphEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'Which situation bothers you more?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `When I can't even step out of my house for days`,
          },
          {
            type: 'I',
            score: 5,
            content: 'When I have to hang out till late for several days',
          },
        ],
      },
      {
        which: 'EI',
        question:
          "What would you do if you were alone with a friend you hadn't seen before?",
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              "Even if I'm awkward at first, if we communicate well, we can be best friends without noticing it.",
          },
          {
            type: 'I',
            score: 5,
            content:
              "I can have a conversation but it's awkward and uncomfortable.",
          },
        ],
      },
      {
        which: 'EI',
        question:
          "If you've been playing outside all day today, what can be your reaction?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I want to rest, but my schedule is already too busy.`,
          },
          {
            type: 'I',
            score: 5,
            content: `I'll have to stay at home for a while, leave me alone.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What would you do if your friend told you to go on a trip abroad alone?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Is it fun to go alone? Where are you going? I might ask.`,
          },
          {
            type: 'N',
            score: 5,
            content: `I worry about being pickpocketed or kidnapped`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What would be your reaction if your friend suddenly had something urgent and left first?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Urgent? I guess it seems something serious.',
          },
          {
            type: 'N',
            score: 5,
            content: `I think my friend will tell me what happens later`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'When you wait for the bus with your friend to go home after hanging out, what can be your reaction?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I should go home quickly and rest. Today was fun`,
          },
          {
            type: 'N',
            score: 5,
            content: `Perhaps, the last bus has already left, so I wonder how I will go home`,
          },
        ],
      },
      {
        which: 'TF',
        question: `In what situations do you usually say “it is possible” ?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'When I have to give a rough answer even though I can’t understand at all.',
          },
          {
            type: 'F',
            score: 5,
            content: 'When I comfort the other person.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'If my friend is sad and a bit upset about me, what is your answer?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Why? Just tell me what made you feel like that.`,
          },
          {
            type: 'F',
            score: 5,
            content: "It can be possible. I'm sorry, that wasn't my intention.",
          },
        ],
      },
      {
        which: 'TF',
        question: `When your friend says “I've been in a bad mood all day lately” , what can be your reaction?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Everyone has times like that.',
          },
          {
            type: 'F',
            score: 5,
            content: "I already worried about you. what's the matter?",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'How would you react if your friend suddenly changed the subject of conversation?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Please don’t change the topic. We were talking about something else.`,
          },
          {
            type: 'P',
            score: 5,
            content: `Oh, what is it? That's funny haha Anyway, what did we just talk about?`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'If your friend suddenly wants to come over to your house, what can be your reaction?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Oh, why? Now? (I have a schedule for today.. uncomfortable...',
          },
          {
            type: 'P',
            score: 5,
            content:
              'What do you want to do? I have something to do, but I can do it tomorrow. I feel better.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What do you do when choosing clothes before going out?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I feel good when I decide what to wear according to the weather and plan the day ahead.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'I decided what to wear, but it can be changed based on my mood on the day',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraphEng/INFP.jpg',
      },
    ],
  },
  // labelStickerJP
  {
    info: {
      mainTitle: 'ラベルシールテストの結果シート',
      subTitle: '自分の心をシールで確認してみよう',
      mainUrl: 'labelStickerJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/labelStickerJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/labelStickerJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '友達と遊んでいたらもう夜10時！',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '今夜は燃え尽きる！誰も帰らせない',
          },
          {
            type: 'I',
            score: 5,
            content: 'もうそろそろ帰りたくてタイミングを計っている',
          },
        ],
      },
      {
        which: 'EI',
        question: '友達と遊んで家に帰るとき、どうする？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '一緒にはしゃぎながら帰りたい',
          },
          {
            type: 'I',
            score: 5,
            content: '一人で帰りたい',
          },
        ],
      },
      {
        which: 'EI',
        question: '週末の予定がキャンセルされた！',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `やだ…他に予定ない人いないかな？連絡してみよう`,
          },
          {
            type: 'I',
            score: 5,
            content: `ちょっとは寂しいけど…まあ、いい！お出かけしなくていいから!`,
          },
        ],
      },
      {
        which: 'SN',
        question: '何かを決定したとき、どんな感じ？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `一回下した決定はもう振り返らない`,
          },
          {
            type: 'N',
            score: 5,
            content: `自分が下した決定でいいのか、ずっと振り返りながら考える`,
          },
        ],
      },
      {
        which: 'SN',
        question: '横断歩道を渡るとき、どんな感じ？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '何も考えずにさっさと渡る',
          },
          {
            type: 'N',
            score: 5,
            content: `ここでひき逃げ事故が発生したらどうすればいいか想像する`,
          },
        ],
      },
      {
        which: 'SN',
        question: '電車に乗った！だけど誰も私の隣の席に座らない！',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `みんな立っているの好きだね！席が空いていてラッキー`,
          },
          {
            type: 'N',
            score: 5,
            content: `なんでだろう…私って不審者っぽい？`,
          },
        ],
      },
      {
        which: 'TF',
        question: `友達同士でけんかするとき、どうする？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'なんであんなことでもめるんだろう…興味深いな',
          },
          {
            type: 'F',
            score: 5,
            content: 'どうしよう…止めないと',
          },
        ],
      },
      {
        which: 'TF',
        question: '待ち合わせの場所で友達が大泣きしている！',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `なんでだろう…話しかけちゃやばいな…ほっておこう`,
          },
          {
            type: 'F',
            score: 5,
            content: '何かあったの？話してみて！私まで泣きそうになっちゃう',
          },
        ],
      },
      {
        which: 'TF',
        question: `友達が私のラインを既読スルーしている`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '忙しいだろうと思ってあまり気にしない',
          },
          {
            type: 'F',
            score: 5,
            content:
              '私の言い方に問題があったのかもう一度チャットの内容をチェックする',
          },
        ],
      },
      {
        which: 'JP',
        question: 'お出かけの準備をするとき、どんな感じ？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `決まった時間から準備するのでいつも余裕がある`,
          },
          {
            type: 'P',
            score: 5,
            content: `急いで準備しているつもりだけど、いつもギリギリ間に合う`,
          },
        ],
      },
      {
        which: 'JP',
        question: '机の上を片づけるとき、どんな感じ？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '使いやすい順できれいにまとめる',
          },
          {
            type: 'P',
            score: 5,
            content: 'ぐちゃぐちゃに見えるけど必要なものはすぐ見つけられる',
          },
        ],
      },
      {
        which: 'JP',
        question: '家事がたまっているけど、とても疲れている！',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '疲れていても今日やるべき家事はさっさと済ませてから休む',
          },
          {
            type: 'P',
            score: 5,
            content: 'とにかく休みたい！家事は後でやろう',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerJP/INFP.jpg',
      },
    ],
  },
  // labelStickerLove
  {
    info: {
      mainTitle: '라벨 스티커 테스트 연애편',
      subTitle: '스티커로 연인에게 내 맘을 표현해보자',
      mainUrl: 'labelStickerLove',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/labelStickerLove-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/labelStickerLove-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question:
          '식당에 밥을 먹으러 왔다!\n그런데 사장님이 친근하게 말을 건다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '자주 와야겠당 너무 친절하시네',
          },
          {
            type: 'I',
            score: 5,
            content: '감사하긴 하지만 살짝 불편쓰..',
          },
        ],
      },
      {
        which: 'EI',
        question: '일주일 내내 약속이 생겨 나가야 한다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '좀 피곤하려나? 그래도 집에 계속 있는 거보단 나아',
          },
          {
            type: 'I',
            score: 5,
            content: '그럴 일은 없어 이런 끔찍한 질문을 하다니',
          },
        ],
      },
      {
        which: 'EI',
        question: '약속이 끝나고 집에 왔을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `오늘 너무 재밌었다ㅎㅎ 내일도 재밌겠다ㅎㅎ`,
          },
          {
            type: 'I',
            score: 5,
            content: `후 드디어 집이다. 피곤해.. 내일은 무조건 쉬어야지`,
          },
        ],
      },
      {
        which: 'SN',
        question: '엘리베이터를 탔을 때 나의 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `빨리 도착해라`,
          },
          {
            type: 'N',
            score: 5,
            content: `엘베가 멈췄는데 핸드폰 먹통되면 어떻게 해야하지?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '애인이 내가 모르는 이성과 함께 있다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '누군지 바로 물어봄',
          },
          {
            type: 'N',
            score: 5,
            content: `물어보기 전 그 짧은 순간 온갖 상상의 상상을 다 해봄`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 자주 하는 말은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `그럴 수 있지, 글쎄, 뭔 소리야`,
          },
          {
            type: 'N',
            score: 5,
            content: `만약에, 혹시나, 그럴 수 있잖아, 왜 때문이지?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `‘생각 좀 해볼게’의 뜻을 고르라면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '실제로 고민할 시간이 필요함',
          },
          {
            type: 'F',
            score: 5,
            content: '거절하고 싶은데 상대방 기분 상할까 봐 눈치 보는 중',
          },
        ],
      },
      {
        which: 'TF',
        question: '애인이 나에게 고민 상담을 한다면',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `최대한 해결해 주려고 함`,
          },
          {
            type: 'F',
            score: 5,
            content: '최대한 들어주려고 함',
          },
        ],
      },
      {
        which: 'TF',
        question: `애인이 ‘오늘 되게 편하게 입고 왔네?’라고 하면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '엉 오늘은 좀 편하게 입고 싶었어',
          },
          {
            type: 'F',
            score: 5,
            content: '너무 추리하게 입고 왔나 봐ㅜ 신경 좀 쓸 걸..',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '오늘은 집 대청소 하기로 한 날!\n그런데 애인이 집 앞으로 나오라고 한다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `오늘 대청소 하려고 했는데.. 이렇게 갑자기 오는 건 좀…`,
          },
          {
            type: 'P',
            score: 5,
            content: `청소 이따가 하면 되지! 내일 해도 되고!`,
          },
        ],
      },
      {
        which: 'JP',
        question: '보통 데이트할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '항상 뭘 할지 미리 알아보고 정한 후 만나는 편',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 만나서 뭐 할지 정하는 편',
          },
        ],
      },
      {
        which: 'JP',
        question: '평소 나의 약속을 잡는 방식은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '지금은 안되고 다음 주 주말 저녁은 어떤데',
          },
          {
            type: 'P',
            score: 5,
            content: '지금 뭐 해? 삼겹살 ㄱ?',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLove/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLove/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLove/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLove/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLove/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLove/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLove/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLove/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLove/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLove/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLove/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLove/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLove/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLove/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLove/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/labelStickerLove/INFP.jpg',
      },
    ],
  },
  // cloudLoveCN
  {
    info: {
      mainTitle: '云浪漫的理想化测试',
      subTitle: '我理想的云计算类型是什么？',
      mainUrl: 'cloudLoveCN',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/cloudLoveCN-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/cloudLoveCN-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'CN',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '接到朋友的联络说突然要办同学聚会的话我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '没问题，当然要去！',
          },
          {
            type: 'I',
            score: 5,
            content: '我下次再去..!',
          },
        ],
      },
      {
        which: 'EI',
        question: '最后去了同学聚会但聚会结束的比想象中的要晚时我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '越玩越嗨反而觉得更好',
          },
          {
            type: 'I',
            score: 5,
            content: '慢慢准备离开',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '聚会快结束的时候，发现和自己关系一般的一个朋友的回家方向和自己一样时我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `反正同一条路就一起边聊边走呗`,
          },
          {
            type: 'I',
            score: 5,
            content: `已经身心疲惫，就算绕路也想自己一个人走`,
          },
        ],
      },
      {
        which: 'SN',
        question: '聚会中只有一面之识的异性朋友突然联系我时？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `干嘛会联系我？`,
          },
          {
            type: 'N',
            score: 5,
            content: `什么事呢？难道..`,
          },
        ],
      },
      {
        which: 'SN',
        question: '异性老同学突然约我吃饭时我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '和我？为啥？这么突然？',
          },
          {
            type: 'N',
            score: 5,
            content: `这难道是要和我约会的意思！？`,
          },
        ],
      },
      {
        which: 'SN',
        question: '和异性朋友发展成恋人了。一起听心动的歌曲时我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `听起来很浪漫的音乐`,
          },
          {
            type: 'N',
            score: 5,
            content: `包含代表我心意的歌词的音乐`,
          },
        ],
      },
      {
        which: 'TF',
        question: `给恋人准备了便当，恋人很满意还表扬我的话？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '那当然~谁做的呢~',
          },
          {
            type: 'F',
            score: 5,
            content: '谢谢，我真的很努力做好的..TT',
          },
        ],
      },
      {
        which: 'TF',
        question: '给恋人诉说我的烦恼时我希望？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `现实的问题解决方案和主动的行动`,
          },
          {
            type: 'F',
            score: 5,
            content: '情感上的共鸣和说一起去吃好吃的话',
          },
        ],
      },
      {
        which: 'TF',
        question: `恋人说买到了想买的东西时我的反应是？?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '买到了感觉怎么样？开心吗？给我看看照片！',
          },
          {
            type: 'F',
            score: 5,
            content: '真棒！这是你很久前就一直想买的呢',
          },
        ],
      },
      {
        which: 'JP',
        question: '第一次和恋人去海外旅游时我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `主动列好旅游计划`,
          },
          {
            type: 'P',
            score: 5,
            content: `主要由恋人做主，同意恋人的所有意见`,
          },
        ],
      },
      {
        which: 'JP',
        question: '和恋人结束了幸福的旅游，回到家时我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '直接整理好所有行李和照片，然后休息',
          },
          {
            type: 'P',
            score: 5,
            content: '先放下所有行李，休息一会儿之后再整理',
          },
        ],
      },
      {
        which: 'JP',
        question: '朋友突然说要见面时我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '这么突然？现在不行呢，下次提前告诉我！',
          },
          {
            type: 'P',
            score: 5,
            content: '这么突然？现在刚好有时间，见面吧！',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/cloudLoveCN/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/cloudLoveCN/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/cloudLoveCN/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/cloudLoveCN/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/cloudLoveCN/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/cloudLoveCN/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/cloudLoveCN/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/cloudLoveCN/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/cloudLoveCN/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/cloudLoveCN/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/cloudLoveCN/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/cloudLoveCN/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/cloudLoveCN/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/cloudLoveCN/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/cloudLoveCN/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/cloudLoveCN/INFP.jpg',
      },
    ],
  },
  // labelSticker
  {
    info: {
      mainTitle: '라벨 스티커 테스트',
      subTitle: '내 맘속에 스티커를 붙여보자',
      mainUrl: 'labelSticker',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/labelSticker-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/labelSticker-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '친구들과 노는 중 시간은 벌써 저녁 10시다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '오늘 밤을 불태워야 함 아무도 집에 못 감',
          },
          {
            type: 'I',
            score: 5,
            content: '이제 슬슬 집에 가고 싶어서 눈치 보는 중',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구들과의 모임이 끝난 후 집에 갈 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '함께 떠들면서 가고 싶음',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자 조용히 가고 싶음',
          },
        ],
      },
      {
        which: 'EI',
        question: '주말에 약속이 취소 됐다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `젠장.. 만날 사람 없나? 연락해 봐야지`,
          },
          {
            type: 'I',
            score: 5,
            content: `아쉽긴 하지만.. 좋은데? 나 안 나가도 되잖아?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '어떤 결정을 내렸을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `한번 내린 결정은 다시 생각하지 않는다`,
          },
          {
            type: 'N',
            score: 5,
            content: `내가 내린 결정이 맞는지 계속 생각하며 고민한다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '횡단보도를 건널 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '별 생각 없이 그냥 후다닥 건넘',
          },
          {
            type: 'N',
            score: 5,
            content: `여기서 사고가 났는데 뺑소니라면? 난 어떻게 해야 할까 상상해 봄`,
          },
        ],
      },
      {
        which: 'SN',
        question: '지하철에 탔다! 그런데 아무도 내 옆에 앉지 않는다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `다들 서서 가네 자리 많아 개꿀`,
          },
          {
            type: 'N',
            score: 5,
            content: `왜 안 앉지? 내가 이상한 사람처럼 보이나?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친한 친구들끼리 다툴 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '뭘 저런 걸로 싸우지 오 흥미진진',
          },
          {
            type: 'F',
            score: 5,
            content: '왜 싸우지..? 어쩌지 말려야 하나?',
          },
        ],
      },
      {
        which: 'TF',
        question: '약속 장소에서 친구가 서럽게 울고 있다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `왜 울지.. 말 걸면 안 되겠다 일단 내버려 두자`,
          },
          {
            type: 'F',
            score: 5,
            content: '왜 울어~ 얘기해 봐 나까지 눈물 나잖아ㅜ',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 내 카톡을 읽씹한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '바쁜가 보다 하고 별로 신경 쓰지 않음',
          },
          {
            type: 'F',
            score: 5,
            content: '내가 뭔가 실수를 한 건지 대화 내용을 다시 읽어봄',
          },
        ],
      },
      {
        which: 'JP',
        question: '외출 준비를 할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `정해둔 시간부터 준비를 하기 때문에 항상 여유로움`,
          },
          {
            type: 'P',
            score: 5,
            content: `분명 빨리 준비하는데 이상하게 항상 아슬아슬하게 도착함`,
          },
        ],
      },
      {
        which: 'JP',
        question: '책상을 정리할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '꺼내서 사용하기 편한 순서대로 깔끔하게 정리',
          },
          {
            type: 'P',
            score: 5,
            content: '막 놓은 것 같아도 필요한 건 바로 찾을 수 있음',
          },
        ],
      },
      {
        which: 'JP',
        question: '집안일이 쌓여있는데 너무 피곤하다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '피곤해도 하기로 한 집안일 먼저 한 후 휴식을 취한다',
          },
          {
            type: 'P',
            score: 5,
            content: '휴식이 먼저 집안일은 나중에 한다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/labelSticker/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/labelSticker/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/labelSticker/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/labelSticker/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/labelSticker/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/labelSticker/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/labelSticker/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/labelSticker/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/labelSticker/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/labelSticker/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/labelSticker/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/labelSticker/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/labelSticker/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/labelSticker/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/labelSticker/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/labelSticker/INFP.jpg',
      },
    ],
  },
  // cloudLove
  {
    info: {
      mainTitle: '구름 연애 이상형 테스트',
      subTitle: '구름으로 보는 나의 이상형은?',
      mainUrl: 'cloudLove',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/cloudLove-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/cloudLove-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '갑작스럽게 동창회를 한다며, 친구들에게 연락이 온다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '무조건 콜이지 갈게!',
          },
          {
            type: 'I',
            score: 5,
            content: '나는 다음에 갈게..!',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '결국 동창회에 갔는데, 동창회가 생각보다 늦게까지 이어진다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '시간이 지날수록 신나져서 오히려 좋다',
          },
          {
            type: 'I',
            score: 5,
            content: '이제 슬슬 가려고 각을 잰다',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '모임이 마무리가 되어 갈 때쯤 어색했던 한 친구가 집 방향이 같다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `어차피 가는 길 같이 떠들면서 간다`,
          },
          {
            type: 'I',
            score: 5,
            content: `이미 지칠 대로 지쳐서 돌아서라도 혼자 간다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '동창회에 있었던 얼굴만 아는 이성 친구에게 연락이 온다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `왜 연락한 거지?`,
          },
          {
            type: 'N',
            score: 5,
            content: `무슨 일이지? 혹시..`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연락이 온 이성 친구가 나에게 밥 먹자고 한다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '나랑? 왜? 갑자기?',
          },
          {
            type: 'N',
            score: 5,
            content: `이거 혹시 데이트 신청!?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '연락 온 이성 친구와 밥을 먹고 난 후 사이가 더욱 가까워 졌을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `설레고, 더 자주 만나고 싶다는 생각을 한다`,
          },
          {
            type: 'N',
            score: 5,
            content: `사귀고, 이성과 데이트 하는 상상을 한다`,
          },
        ],
      },
      {
        which: 'TF',
        question: `연인에게 도시락을 싸줬는데, 연인이 마음에 쏙 드는 칭찬을 한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '당연하지~ 누가 만든 건데~',
          },
          {
            type: 'F',
            score: 5,
            content: '고마워 내가 진짜 열심히 만들었거든...ㅠㅠ',
          },
        ],
      },
      {
        which: 'TF',
        question: '연인에게 고민을 털어놓는다면 내가 원하는 것은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `현실적인 문제 해결 방법과 적극적인 행동`,
          },
          {
            type: 'F',
            score: 5,
            content: '감정적인 공감과 맛있는 것을 먹으러 가자는 말',
          },
        ],
      },
      {
        which: 'TF',
        question: `연인이 사고 싶었던 물건을 샀다고 했을 때 나의 반응은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '사보니까 어때? 좋아? 나도 사진으로 보여줘!',
          },
          {
            type: 'F',
            score: 5,
            content: '완전 잘했다! 전부터 사고 싶어했던거잖아',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 함께 처음으로 해외여행을 간다면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `주도적으로 여행 계획을 세우는 편이다`,
          },
          {
            type: 'P',
            score: 5,
            content: `주로 연인이 주도하고, 연인의 의견에 동의하는 편이다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 행복한 여행을 다녀온 후 집에 도착한 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '바로 짐 정리하고, 사진 정리까지 싹 끝낸 후 쉰다',
          },
          {
            type: 'P',
            score: 5,
            content: '짐은 일단 내려놓고, 좀 쉬었다가 정리한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구들에게 갑자기 만나자는 연락이 온다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '갑자기? 지금은 안될 것 같은데 다음엔 미리 말해 줘!',
          },
          {
            type: 'P',
            score: 5,
            content: '갑자기? 지금은 마침 시간이 비긴 하니까 콜!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/cloudLove/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/cloudLove/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/cloudLove/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/cloudLove/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/cloudLove/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/cloudLove/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/cloudLove/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/cloudLove/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/cloudLove/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/cloudLove/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/cloudLove/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/cloudLove/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/cloudLove/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/cloudLove/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/cloudLove/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/cloudLove/INFP.jpg',
      },
    ],
  },
  // loveMagazineEng
  {
    info: {
      mainTitle: 'Dating magazine test',
      subTitle: 'What kind of dating style do you see in magazines?',
      mainUrl: 'loveMagazineEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveMagazineEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveMagazineEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: 'What if you greet to someone you meet for the first time?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I can talk and approach easily.',
          },
          {
            type: 'I',
            score: 5,
            content:
              'I think I should just say hello and get to know the person slowly.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What can be your reaction when your friend introduces you to someone new?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'I tend to take the initiative in contacting the person first.',
          },
          {
            type: 'I',
            score: 5,
            content: 'I tend to wait for the person to contact me first.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'At the stage of getting to know the person you were introduced to, what do you do when you have concerns with the person?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I usually solve the problem by meeting and talking with people I know.`,
          },
          {
            type: 'I',
            score: 5,
            content: `I usually solve the problem by taking time alone to think.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What can be your reaction when you first meet an introduced person?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I feel happy and thrilled.`,
          },
          {
            type: 'N',
            score: 5,
            content: `I picture our relationship in advance with all kinds of imagination.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What do you do before going camping with your lover for the first time?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'I look up how to set up a tent and think about what the essentials are.',
          },
          {
            type: 'N',
            score: 5,
            content: `I imagine camping that I have seen in dramas and movies.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What kind of reaction would you get if you arrived at the campsite, finished all the preparations, and sat down to enjoy the beautiful sunset?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Wow, it's so pretty, but when are we going to wrap this up and go back.`,
          },
          {
            type: 'N',
            score: 5,
            content: `Wow, it's so pretty, I hope this moment with my lover lasts forever.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What if you are late for a date with your lover after a long time?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "I'll explain why I'm late and say sorry.",
          },
          {
            type: 'F',
            score: 5,
            content: 'I will say sorry and try to get there ASAP.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'If your lover usually keeps in touch, but does not contact you when he is working, what can be your reaction?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I can fully understand that it's not easy to get in touch at work.`,
          },
          {
            type: 'F',
            score: 5,
            content: 'I can understand but I might be still a bit sad.',
          },
        ],
      },
      {
        which: 'TF',
        question: `What would you do if you had an argument with your lover over small things?？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'I might be angry, but I calmly say my thoughts first to my lover.',
          },
          {
            type: 'F',
            score: 5,
            content: 'I have a lot to say, but the emotions come first.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What would you do if you went to a flower garden with your lover?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I would try to go with a plan, such as deciding when and where to go.`,
          },
          {
            type: 'P',
            score: 5,
            content: `I would just follow what my lover wants.`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What would you do if a lot of people gathered in a certain area while visiting a flower garden?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "I would be curious what's going on there, but I would ignore it and go on my way.",
          },
          {
            type: 'P',
            score: 5,
            content:
              "I would be curious about what's there, so I would take a look.",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'If you found a new way to go to a restaurant you often go to with your lover, what would you do?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I would just take the usual way.',
          },
          {
            type: 'P',
            score: 5,
            content: 'I would try the new way to get there.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineEng/INFP.jpg',
      },
    ],
  },
  // loveMagazineCN
  {
    info: {
      mainTitle: '杂志恋爱测试',
      subTitle: '通过杂志了解我的恋爱风格',
      mainUrl: 'loveMagazineCN',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveMagazineCN-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveMagazineCN-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'CN',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '和初次见面的人打招呼时我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '能轻松地开始对话并亲切地接近对方',
          },
          {
            type: 'I',
            score: 5,
            content: '只打打招呼然后心想着以后慢慢了解对方',
          },
        ],
      },
      {
        which: 'EI',
        question: '朋友给我介绍异性时我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '和会主动联系对方的人',
          },
          {
            type: 'I',
            score: 5,
            content: '对方主动联系我之前一直等候',
          },
        ],
      },
      {
        which: 'EI',
        question: '和介绍对象相互了解的阶段对这份关系产生烦恼时我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `通过和朋友们见面诉说来解决烦恼`,
          },
          {
            type: 'I',
            score: 5,
            content: `独自一个人充分思考之后再解决烦恼`,
          },
        ],
      },
      {
        which: 'SN',
        question: '和介绍对象终于迎来交往第1天的时候我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `很开心，也很感慨`,
          },
          {
            type: 'N',
            score: 5,
            content: `脑海里想象着我们谈恋爱的模样`,
          },
        ],
      },
      {
        which: 'SN',
        question: '第一次和恋人去野营之前我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '搜索安装帐篷的方法，思考需要哪些必需品',
          },
          {
            type: 'N',
            score: 5,
            content: `想象浪漫、如同电视剧和电影中曾看到的野营场面`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '抵达野营场所做好一切准备之后一起坐下来的时候刚好看到美丽的夕阳时我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `真美啊，但这些东西什么时候整理好回家啊..`,
          },
          {
            type: 'N',
            score: 5,
            content: `真的好美啊, 希望和恋人在一起的这一瞬间暂停`,
          },
        ],
      },
      {
        which: 'TF',
        question: `好不容易等到和恋人约会的日子却迟到了的话？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '解释迟到的理由然后道歉',
          },
          {
            type: 'F',
            score: 5,
            content: '先说抱歉然后告诉恋人会尽快到达',
          },
        ],
      },
      {
        which: 'TF',
        question: '平时一直保持联络的恋人一旦上班了就不容易联系上的话？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `认为工作的时候当然会不方便联络，能充分理解`,
          },
          {
            type: 'F',
            score: 5,
            content: '虽然知道他很忙但也有点小伤心',
          },
        ],
      },
      {
        which: 'TF',
        question: `因为小事和恋人吵架时我会？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '虽然很生气但也会冷静地诉说自己的想法',
          },
          {
            type: 'F',
            score: 5,
            content: '虽然有很多话想说，但总是先受情绪影响',
          },
        ],
      },
      {
        which: 'JP',
        question: '和恋人去赏花时我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `提前选好地点和时间，按计划行动`,
          },
          {
            type: 'P',
            score: 5,
            content: `恋人说去哪里就去哪里`,
          },
        ],
      },
      {
        which: 'JP',
        question: '赏花时有一段路段人群满满的话我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '虽然内心想知道是怎么回事但只会路过',
          },
          {
            type: 'P',
            score: 5,
            content: '好奇发生了什么事，前往查看',
          },
        ],
      },
      {
        which: 'JP',
        question: '和恋人去经常光顾的餐厅的路上发现了新路径时我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '还是走平时走的路',
          },
          {
            type: 'P',
            score: 5,
            content: '尝试走一次新路线',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineCN/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineCN/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineCN/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineCN/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineCN/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineCN/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineCN/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineCN/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineCN/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineCN/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineCN/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineCN/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineCN/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineCN/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineCN/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazineCN/INFP.jpg',
      },
    ],
  },
  // loveMagazine
  {
    info: {
      mainTitle: '연애 잡지 테스트',
      subTitle: '잡지로 보는 내 연애 스타일은?',
      mainUrl: 'loveMagazine',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveMagazine-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveMagazine-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '처음 만난 사람들과 인사를 나눈다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '쉽게 말을 걸고 친근하게 다가간다',
          },
          {
            type: 'I',
            score: 5,
            content: '가벼운 인사 정도만 하고 천천히 알아가야지 생각한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구가 새로운 이성을 소개해 줬을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '상대에게 먼저 연락을 주도적으로 하는 편이다',
          },
          {
            type: 'I',
            score: 5,
            content: '상대에게 먼저 연락해 오기를 기다리는 편이다',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '소개받은 사람과 서로 알아가는 단계, 상대와 관계에서 고민이 생겼을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `친구, 지인 등 사람들을 만나 이야기를 하면서 고민을 해결하는 편이다`,
          },
          {
            type: 'I',
            score: 5,
            content: `혼자 생각할 시간을 충분히 가지고 고민을 해결하는 편이다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '소개받은 이성과 드디어 1일이 되었을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `기쁘고, 감격스럽다`,
          },
          {
            type: 'N',
            score: 5,
            content: `상상으로 미리 우리 연애를 그려 본다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인과 처음으로 캠핑을 가기 전 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '텐트 설치하는 법을 찾아보고, 필수 템들이 뭐가 있는지 생각해 본다',
          },
          {
            type: 'N',
            score: 5,
            content: `낭만적이고, 드라마 영화에서 보는 캠핑을 상상해본다`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '캠핑 장소에 도착해서 모든 준비를 끝내고, 앉았는데 노을이 예쁘게 지고 있다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `예쁘다, 근데 이거 언제 다 정리하고 돌아가냐..`,
          },
          {
            type: 'N',
            score: 5,
            content: `너무 예쁘다 연인과 함께하는 이 순간이 멈췄으면 좋겠다`,
          },
        ],
      },
      {
        which: 'TF',
        question: `연인과 사소한 문제로 다툼이 벌어진다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '화나지만 일단 내 생각부터 침착하게 말해 본다',
          },
          {
            type: 'F',
            score: 5,
            content: '할 말은 많지만, 감정부터 앞서게 된다',
          },
        ],
      },
      {
        which: 'TF',
        question: '연인과 오랜만에 데이트하는 날 약속 장소에 늦는다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `늦은 이유를 설명하고 미안하다고 말한다`,
          },
          {
            type: 'F',
            score: 5,
            content: '미안하다고 말하고 최대한 빨리 가겠다고 말한다',
          },
        ],
      },
      {
        which: 'TF',
        question: `평소에는 꾸준히 연락 잘해주는 연인이 회사만 가면 연락이 잘 안된다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '일 한다면 당연한 거라고 생각한다 충분히 이해된다',
          },
          {
            type: 'F',
            score: 5,
            content: '바쁜 것은 알지만, 그래도 좀 서운하다',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 꽃 구경을 간다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `장소와 시간 등 미리 찾아보고 계획적으로 간다`,
          },
          {
            type: 'P',
            score: 5,
            content: `그냥 연인이 가자고 하는 곳으로 간다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '꽃구경 중 어느 구간에 사람이 북적북적 몰려 있다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '뭐지? 싶지만 무시하고 가던 길을 간다',
          },
          {
            type: 'P',
            score: 5,
            content: '뭐가 있는지 궁금해서 한 번 구경하러 간다',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '연인과 자주 가는 맛집을 가는 길, 맛집으로 가는 새로운 길을 발견했다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '그냥 평소 가던 길로 간다 ',
          },
          {
            type: 'P',
            score: 5,
            content: '그쪽으로도 한 번 가본다 ',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazine/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazine/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazine/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazine/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazine/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazine/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazine/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazine/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazine/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazine/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazine/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazine/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazine/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazine/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazine/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveMagazine/INFP.jpg',
      },
    ],
  },
  // loveInMyHeadCN
  {
    info: {
      mainTitle: '我脑海里的恋爱测试',
      subTitle: '通过MBTI了解态爱时我脑海里的想法',
      mainUrl: 'loveInMyHeadCN',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveInMyHeadCN-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveInMyHeadCN-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'CN',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '和有好感的异性互相都感觉到对方的心意时我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '稍稍提起一些有关我们关系的话题',
          },
          {
            type: 'I',
            score: 5,
            content: '主动说起有些负担，所以先选择等待',
          },
        ],
      },
      {
        which: 'EI',
        question: '我更想和哪种人交往？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '和我不同而且可以一起体验新鲜事物的人',
          },
          {
            type: 'I',
            score: 5,
            content: '在一起时感到舒服的人',
          },
        ],
      },
      {
        which: 'EI',
        question: '在家忧郁的我希望恋人给予怎样的反应？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `先带我出门然后一起体验某事的恋人`,
          },
          {
            type: 'I',
            score: 5,
            content: `在我整理好思绪和忧郁感彻底消失之前一直等候的恋人`,
          },
        ],
      },
      {
        which: 'SN',
        question: '恋人生病的时候我亲自煲粥的话？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `搜索粥菜谱然后亲手做`,
          },
          {
            type: 'N',
            score: 5,
            content: `完全用自己的手艺投入真心煲粥`,
          },
        ],
      },
      {
        which: 'SN',
        question: '观赏展览作品之后我的想法是？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '应该会很贵，大概多少钱呢？',
          },
          {
            type: 'N',
            score: 5,
            content: `这些作品到底是怎么完成的呢？`,
          },
        ],
      },
      {
        which: 'SN',
        question: '在去经常光顾的餐厅的路上，恋人突然提议走其他路线时我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `这条路本来就很快，还是走这条路比较好吧？`,
          },
          {
            type: 'N',
            score: 5,
            content: `好的！那就走其他路线边走边观赏风景吧！`,
          },
        ],
      },
      {
        which: 'TF',
        question: `恋人很累在哭的话我会？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '为了尽快安慰恋人，思考解决问题的方法然后告诉恋人。',
          },
          {
            type: 'F',
            score: 5,
            content: '尽量和对方产生共鸣的同时安慰他',
          },
        ],
      },
      {
        which: 'TF',
        question: '和朋友聊天的时候朋友突然用现实讽刺我的话我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `你算什么这样判断我？嗯~管好你自己吧~`,
          },
          {
            type: 'F',
            score: 5,
            content: '你说的我也都知道，但没必要直接这样说出来吧…TT（伤心）',
          },
        ],
      },
      {
        which: 'TF',
        question: `和恋人去看樱花时人山人海的话我会？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '樱花美是美，但人也太多了吧..去其它地方吧..',
          },
          {
            type: 'F',
            score: 5,
            content: '樱花好美呀，人也好多..春天真的到来了呢',
          },
        ],
      },
      {
        which: 'JP',
        question: '集体活动的社团突然改变了聚集时间和地点时我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `大家都有各自的计划，感到不满并生气`,
          },
          {
            type: 'P',
            score: 5,
            content: `觉得合理的话会理解`,
          },
        ],
      },
      {
        which: 'JP',
        question: '聚会中大家在聊天的时候某人突然说出和主题不符的话时我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '感到非常不舒服',
          },
          {
            type: 'P',
            score: 5,
            content: '如果也让人感到有趣的话就无所谓',
          },
        ],
      },
      {
        which: 'JP',
        question: '休息的时候恋人突然出现在家门口时我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '无论是什么理由，突然出现让我感到不舒服',
          },
          {
            type: 'P',
            score: 5,
            content: '突然出现也很有趣，如果恋人提议体验新鲜事物的话就无所谓',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadCN/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadCN/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadCN/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadCN/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadCN/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadCN/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadCN/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadCN/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadCN/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadCN/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadCN/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadCN/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadCN/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadCN/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadCN/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadCN/INFP.jpg',
      },
    ],
  },
  // emotionGraph
  {
    info: {
      mainTitle: '감정 그래프 테스트',
      subTitle: '나의 감정 기복을 그래프로 확인해 보자!',
      mainUrl: 'emotionGraph',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/emotionGraph-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/emotionGraph-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '나를 더 괴롭게 하는 상황은 무엇일까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '며칠 동안 집구석 밖으로 한 발자국도 못 나감',
          },
          {
            type: 'I',
            score: 5,
            content: '며칠 동안 계속 밖에서 밤늦게까지 놀아야 함',
          },
        ],
      },
      {
        which: 'EI',
        question: '처음 보는 친구와 단둘이 있게 된다면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '처음엔 어색해도 말 잘 통하면 어느새 절친되어 있음',
          },
          {
            type: 'I',
            score: 5,
            content: '대화는 하지만 어색한 공기가 흐르고 뭔가 계속 불편',
          },
        ],
      },
      {
        which: 'EI',
        question: '오늘 하루 종일 밖에서 신나게 놀았다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `쉬고 싶긴 한데 이미 약속이 꽉 차있음`,
          },
          {
            type: 'I',
            score: 5,
            content: `당분간은 집 콕 해야지 날 내버려 두셈`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구가 혼자 해외여행을 간다고 하면 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `혼자 가면 재밌음? 어디로 가는데?`,
          },
          {
            type: 'N',
            score: 5,
            content: `혼자 갔다가 소매치기나 납치당하는 거 아냐?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '갑자기 급한 일이 있어 먼저 간다는 친구 내 모습은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '나중에 뭔 일인지 얘기해 주겠지',
          },
          {
            type: 'N',
            score: 5,
            content: `뭔 일이지 어떤 급한 일?? 분명 큰일 같은데`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구와 놀고 집으로 가는 버스를 기다릴 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `빨리 집 가서 쉬어야지 오늘 재밌었다`,
          },
          {
            type: 'N',
            score: 5,
            content: `사실은 이미 막차가 떠났던 거지 그럼 집 어케 갈지 의문`,
          },
        ],
      },
      {
        which: 'TF',
        question: `‘그럴 수 있지’는 주로 어떤 상황일 때 말할까?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '이해가 1도 되지 않지만 대충 대답할 때',
          },
          {
            type: 'F',
            score: 5,
            content: '상대방을 위로할 때나 괜찮다고 말할 때',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 나에게 서운하다고 한다. 내 대답은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `엥 왜지? 그게 왜 어떤 부분이?`,
          },
          {
            type: 'F',
            score: 5,
            content: '서운할 수 있겠다 미안해 그럴 의도는 아니었어',
          },
        ],
      },
      {
        which: 'TF',
        question: `‘요즘 하루 종일 기분이 오락가락해’라고 하면 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '뭐 사람이 가끔 그럴 수 있지',
          },
          {
            type: 'F',
            score: 5,
            content: '안 그래도 걱정했어.. 요즘 무슨 일 있어??',
          },
        ],
      },
      {
        which: 'JP',
        question: '대화 중에 친구가 딴 소리를 한다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `딴 소리 하지 말아 봐 우리 다른 얘기 중이었잖아`,
          },
          {
            type: 'P',
            score: 5,
            content: `오 뭔 얘기야? 웃기네ㅋㅋ 근데 우리 뭔 얘기 중이었더라`,
          },
        ],
      },
      {
        which: 'JP',
        question: '친구가 갑자기 집으로 놀러 오겠다 하면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '아.. 왜? 지금? (오늘 정해 둔 일정이 있는데.. 불편..)',
          },
          {
            type: 'P',
            score: 5,
            content: '뭐 하게? 할 일 있었는데 내일 하지 뭐 오히려 좋아~',
          },
        ],
      },
      {
        which: 'JP',
        question: '외출 전 옷을 고를 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '미리 전날에 날씨나 일정을 생각해서 코디 해놔야 편안',
          },
          {
            type: 'P',
            score: 5,
            content: '뭐 입을지 생각해놓긴 했지만 당일 내 기분에 따라 바뀜',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraph/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraph/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraph/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraph/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraph/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraph/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraph/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraph/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraph/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraph/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraph/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraph/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraph/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraph/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraph/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/emotionGraph/INFP.png',
      },
    ],
  },
  // loveInMyHeadEng
  {
    info: {
      mainTitle: 'Love test in your head',
      subTitle: "What's on your mind when you're dating?",
      mainUrl: 'loveInMyHeadEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveInMyHeadEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveInMyHeadEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: 'What do you do when you learn that you like each other?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I quietly start talking about our relationship.',
          },
          {
            type: 'I',
            score: 5,
            content: `I'm afraid to talk first, so I'll just wait.`,
          },
        ],
      },
      {
        which: 'EI',
        question: 'What kind of person do you like more?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'Someone who is different from me and can share a different experience.',
          },
          {
            type: 'I',
            score: 5,
            content: "Someone I feel comfortable with when I'm with them.",
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What do you expect from your lover when you are depressed at home?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I expect my lover to take me out and do something together.`,
          },
          {
            type: 'I',
            score: 5,
            content: `I expect my lover to wait until I clear my mind and overcome my depression.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'If you were to cook porridge when your lover was sick, what would you make?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I would make it with the porridge recipe from internet.`,
          },
          {
            type: 'N',
            score: 5,
            content: `I would cook porridge with all my heart and my cooking skills.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What do you think of when you go to an exhibition and see art works?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              "I'm curious about the selling price because I think it must be expensive.",
          },
          {
            type: 'N',
            score: 5,
            content: `I’m curious about why they made these works like this.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'If your lover wants to go another way to a restaurant you used to go together, what can be your reaction?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `It would be better to go the original way because it is the best way to get there.`,
          },
          {
            type: 'N',
            score: 5,
            content: `Okay, let's try the other way. We can look around on the way.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `If your lover struggles and cries, what would you do?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I would try to find quick remedy or solution for that.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'I would sympathize and comfort my lover as much as possible.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'If your friend does fact attack to you while talking, what can be your reaction?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `You have no right to judge me. Just take care of yourself well.`,
          },
          {
            type: 'F',
            score: 5,
            content: "You don't need to mention what I already know.",
          },
        ],
      },
      {
        which: 'TF',
        question: `If there are a lot of people when you go cherry blossom viewing with your lover, what can be your reaction?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'It looks so beautiful but too crowded. Want to try somewhere else?',
          },
          {
            type: 'F',
            score: 5,
            content:
              "Cherry blossoms are really pretty, there are so many people, as expected. Now it's really spring",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What can be your reaction if the appointment time and place suddenly changed in a club activity?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Oh, I already made a plan, it makes me uncomfortable and angry.`,
          },
          {
            type: 'P',
            score: 5,
            content: `It's okay if there is a good excuse.`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'If someone in a meeting goes off-topic, what can be your reaction?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I may feel very uncomfortable.',
          },
          {
            type: 'P',
            score: 5,
            content: 'That is fine as long as the new topic is interesting. ',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'How would you react if your lover suddenly came to your house while you were resting?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "I'm a bit uncomfortable with sudden visits for whatever reason.",
          },
          {
            type: 'P',
            score: 5,
            content:
              'Even a sudden visit is fine as long as there are things interesting or tempting.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHeadEng/INFP.jpg',
      },
    ],
  },
  // loveSpicyEng
  {
    info: {
      mainTitle: 'Spiciness test of your love',
      subTitle: 'The spiciness of your love through MBTI!',
      mainUrl: 'loveSpicyEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveSpicyEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveSpicyEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question:
          'What are the important things for you when observing the opposite sex?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '比I focus more on appearance than personality.',
          },
          {
            type: 'I',
            score: 5,
            content: 'I focus more on personality than looks.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          "What do you do when your lover does or says something you don't like?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I tend to say everything I want to say at that time.',
          },
          {
            type: 'I',
            score: 5,
            content:
              'I organize my thoughts and speak slowly or express them in letters.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What would you do if someone on the street talks to you?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `If I can have a good conversation, I can be friendly. `,
          },
          {
            type: 'I',
            score: 5,
            content: `First of all, I will be alert and I'm afraid I won't be able to speak properly.`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What kind of love do you prefer?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `A love that focuses on the present rather than the future.`,
          },
          {
            type: 'N',
            score: 5,
            content: `A love to focuses on and prepare for the future.`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'Which of the following applies to you in your relationship?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I often propose to do something.',
          },
          {
            type: 'N',
            score: 5,
            content: `After agreeing to my lover's proposal, I give feedback whether it's okay or not.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'How do you remember when you talk about your memories with your lover?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I remember the exact location, weather, time, and atmosphere, etc.`,
          },
          {
            type: 'N',
            score: 5,
            content: `I remember that time compared to something.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `If two friends of you suddenly fight, what can be your reaction?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'After listening to both sides, I try to come up with a solution.',
          },
          {
            type: 'F',
            score: 5,
            content: 'I will stop fighting and hear what happened.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'If your lover passed an exam, what would be your reaction?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `wow! congratulation! I knew you would pass in one go!`,
          },
          {
            type: 'F',
            score: 5,
            content: "Wow, that's really good, it's worth the hard work, huh!",
          },
        ],
      },
      {
        which: 'TF',
        question: `What would you do if your friend told you that your lover is cheating on you?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I will ask my friend to take a picture of the scene.',
          },
          {
            type: 'F',
            score: 5,
            content: "I'm angry and I'm going to ask where they are now.",
          },
        ],
      },
      {
        which: 'JP',
        question: 'What do you do when preparing for a trip with your lover?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I prepare what I need exactly.`,
          },
          {
            type: 'P',
            score: 5,
            content: `I think I need a lot, so I prepare a lot.`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'What do you do before a date with your lover?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I tend to decide beforehand where to go and what to wear.',
          },
          {
            type: 'P',
            score: 5,
            content: 'I simply decide where to go and enjoy spontaneous dates.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What would you do if you went to a department store with your lover?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I would not make impulse purchases even for pretty clothes.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'There are times when I buy pretty things or clothes impulsively.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyEng/INFP.jpg',
      },
    ],
  },
  // loveSpicyCN
  {
    info: {
      mainTitle: '麻辣味恋爱测试',
      subTitle: '我的恋爱究竟有辣？',
      mainUrl: 'loveSpicyCN',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveSpicyCN-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveSpicyCN-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'CN',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '会看异性的哪个部分？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '比起性格外貌更重要',
          },
          {
            type: 'I',
            score: 5,
            content: '比起外貌性格更重要',
          },
        ],
      },
      {
        which: 'EI',
        question: '恋人做或说了讨厌的事情时会?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '会立即说想说的话',
          },
          {
            type: 'I',
            score: 5,
            content: '会先整理逻辑后慢慢说或写段话',
          },
        ],
      },
      {
        which: 'EI',
        question: '走路时，有陌生的异性搭讪会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `可以很自然的聊天并变得亲近`,
          },
          {
            type: 'I',
            score: 5,
            content: `警惕心变重，说话会卡壳`,
          },
        ],
      },
      {
        which: 'SN',
        question: '我希望的恋人类型是？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `比起未来更注重现在关系的恋人`,
          },
          {
            type: 'N',
            score: 5,
            content: `比起现在更注重未来并设想的恋人`,
          },
        ],
      },
      {
        which: 'SN',
        question: '在恋爱关系，我属于？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '会先说想做什么的情况更多',
          },
          {
            type: 'N',
            score: 5,
            content: `同意恋人的意见，想着可不可行之后回答的情况更多`,
          },
        ],
      },
      {
        which: 'SN',
        question: '和恋人聊回忆时，我属于？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `会很清晰的记得当时的场景、天气、时间及氛围`,
          },
          {
            type: 'N',
            score: 5,
            content: `会把当时的回忆比喻成某个东西`,
          },
        ],
      },
      {
        which: 'TF',
        question: `两个朋友突然吵起来，我会？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '听完两个朋友的话后，提出建议',
          },
          {
            type: 'F',
            score: 5,
            content: '先拦住他们之后聆听',
          },
        ],
      },
      {
        which: 'TF',
        question: '在正在准备的考试中，恋人合格了的话会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `哇，不愧是你！恭喜你！就知道你会一次考上！`,
          },
          {
            type: 'F',
            score: 5,
            content: '哇，真不错，认真学习还是很有用的！',
          },
        ],
      },
      {
        which: 'TF',
        question: `朋友说看到了跟我说在工作的恋人和其他异性玩耍时，我会？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '想确认现场照片，让朋友马上拍照发过来',
          },
          {
            type: 'F',
            score: 5,
            content: '感到背叛，马上问是哪里之后冲过去',
          },
        ],
      },
      {
        which: 'JP',
        question: '和恋人为旅行做准备时，我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `会准确知道需要的东西过后整理`,
          },
          {
            type: 'P',
            score: 5,
            content: `感觉什么都需要，所以需要整理的东西很多`,
          },
        ],
      },
      {
        which: 'JP',
        question: '在与恋人约会前，我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '会事前定好衣服与要去的地方',
          },
          {
            type: 'P',
            score: 5,
            content: '粗略定好要去哪里后，享受即兴约会',
          },
        ],
      },
      {
        which: 'JP',
        question: '和恋人逛百货商店时，我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '就算是漂亮的东西或是衣服，如果在计划外就不会购买',
          },
          {
            type: 'P',
            score: 5,
            content: '会冲动购买漂亮的东西或衣服',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyCN/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyCN/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyCN/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyCN/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyCN/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyCN/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyCN/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyCN/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyCN/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyCN/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyCN/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyCN/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyCN/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyCN/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyCN/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpicyCN/INFP.jpg',
      },
    ],
  },
  // loveInMyHead
  {
    info: {
      mainTitle: '내 머릿속 연애 테스트',
      subTitle: 'MBTI로 보는 연애 할 때 나의 머릿속은?',
      mainUrl: 'loveInMyHead',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveInMyHead-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveInMyHead-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question:
          '호감인 이성과 서로의 마음을 어느 정도 눈치 채고 있는 상황에 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '슬쩍 우리 관계에 관한 이야기를 꺼낸다',
          },
          {
            type: 'I',
            score: 5,
            content: '먼저 이야기하는 것은 부담스러우니 일단 기다려 본다',
          },
        ],
      },
      {
        which: 'EI',
        question: '내가 더 만나고 싶은 사람은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '나와는 달라 색다른 경험을 함께 할 수 있는 사람',
          },
          {
            type: 'I',
            score: 5,
            content: '같이 있을 때 편안함을 느끼는 사람',
          },
        ],
      },
      {
        which: 'EI',
        question: '집에서 우울한 상태로 있을 내가 원하는 연인의 행동은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `일단 나를 밖으로 데리고 나가 무언가를 함께해주는 연인`,
          },
          {
            type: 'I',
            score: 5,
            content: `생각을 정리하고 우울감이 사라질 때까지 나를 기다려주는 연인`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인이 아픈 상황에 내가 직접 죽을 만들어 준다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `검색하고 찾아본 죽 레시피로 만든다`,
          },
          {
            type: 'N',
            score: 5,
            content: `나의 정성스러운 손맛이 담긴 죽을 만들어 준다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '전시회에 가서 작품들을 보고 든 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '비쌀 것 같은데 얼마에 판매하려나?',
          },
          {
            type: 'N',
            score: 5,
            content: `이 작품들은 왜 이렇게 만들었을까?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '자주 가던 맛집을 가는 길, 연인이 갑자기 다른 길로 가보자고 한다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `원래 가던 길이 빠르니까 원래 가던 길로 가는 게 낫지 않을까?`,
          },
          {
            type: 'N',
            score: 5,
            content: `그래! 다른 길로 가서 구경하면서 가보자!`,
          },
        ],
      },
      {
        which: 'TF',
        question: `연인이 힘들다고 운다면 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '빠르게 달래기 위해 문제 해결 방법을 생각해서 말해준다',
          },
          {
            type: 'F',
            score: 5,
            content: '최대한 공감해주고 달래 준다',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구와 대화 중 친구가 나에게 날카로운 팩폭을 날렸다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `니가 뭔데 날 판단해? 응~ 너나 잘해~`,
          },
          {
            type: 'F',
            score: 5,
            content: '나도 아는 건데 굳이 말할 필요는 없잖아…ㅠㅠ(마상)',
          },
        ],
      },
      {
        which: 'TF',
        question: `연인과 벚꽃 구경 갔을 때 사람들이 많다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '예쁘긴 한데 사람 너무 많네.. 다른 곳으로 갈까..',
          },
          {
            type: 'F',
            score: 5,
            content: '벚꽃 완전 예쁘다 사람들도 많네 역시.. 이제 진짜 봄이네',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '단체 활동을 하는 동아리에서 약속 시간과 장소가 갑자기 변경된다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `하루 계획이 있는데, 불편하고 화가 난다`,
          },
          {
            type: 'P',
            score: 5,
            content: `이유가 타당하면 그럴 수 있다고 생각한다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '모임에서 대화 중 누군가 대화 주제에 벗어난 이야기를 한다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '매우 불편하다',
          },
          {
            type: 'P',
            score: 5,
            content: '흥미가 생기는 말이라면, 괜찮다',
          },
        ],
      },
      {
        which: 'JP',
        question: '쉬고 있는 상황에 연인이 갑자기 집 앞에 찾아온다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '무슨 이유든 갑작스럽게 찾아오는 건 좀 불편하다',
          },
          {
            type: 'P',
            score: 5,
            content:
              '갑자기 찾아와도 흥미롭거나, 끌리는 무언가를 하자고 한다면 괜찮다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHead/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHead/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHead/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHead/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHead/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHead/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHead/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHead/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHead/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHead/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHead/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHead/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHead/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHead/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHead/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveInMyHead/INFP.jpg',
      },
    ],
  },
  // cherryBlossomSite
  {
    info: {
      mainTitle: '벚꽃 명소 테스트',
      subTitle: 'MBTI로 보는 나와 맞는 벚꽃 명소는?',
      mainUrl: 'cherryBlossomSite',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/cherryBlossomSite-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/cherryBlossomSite-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'etc',
    },
    questions: [
      {
        which: 'EI',
        question: '꽃 구경 갈 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구들과 왁자지껄하게 가는 게 좋다.',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자 구경하는 것도 나는 좋다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구가 다른 친구도 함께 가자고 물어본다. 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '여럿이서 놀면 더 재밌지! ',
          },
          {
            type: 'I',
            score: 5,
            content: '좀 어색할거같은데...',
          },
        ],
      },
      {
        which: 'EI',
        question: '지나가다가 포토스팟을 발견! 사진을 찍고 싶은 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `우리 여기서 사진 찍자! 한 번 서봐!`,
          },
          {
            type: 'I',
            score: 5,
            content: `혼자서 폰으로 풍경만 찍는다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '꽃놀이 가기 전 밤 나의 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `내일 구경해야하니까 일찍 자야지.`,
          },
          {
            type: 'N',
            score: 5,
            content: `자기 전 설렘에 온갖 상상을 하다 잠든다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '꽃구경중 배가고파 식당에 들어갔다. 가장 먼저 보이는 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '맛있는 음식들.',
          },
          {
            type: 'N',
            score: 5,
            content: `아기자기 예쁘게 꾸며둔 인테리어.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '나는 어떤 성향?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `현재 일어나는 일에 대한 관찰과 분석을 즐김.`,
          },
          {
            type: 'N',
            score: 5,
            content: `상상력이 풍부하고 말도 안되는 상상을 즐김.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `좋아하는 사람이 어려움에 처했다.`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '잘 이겨낼 수 있을 거라고 다독여준다.',
          },
          {
            type: 'F',
            score: 5,
            content: '문제를 듣고 해결방안을 찾아준다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '꽃구경중 멋진 건출물을 보았다. 먼저 드는 생각은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `폼 미쳤다! 대박..`,
          },
          {
            type: 'F',
            score: 5,
            content: '이걸 어떻게 지었지..?',
          },
        ],
      },
      {
        which: 'TF',
        question: `예쁜 벚꽃을 보고 먼저 든 생각은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '너무 예쁘다고 감탄한다.',
          },
          {
            type: 'F',
            score: 5,
            content: '벌써 4월이구나 봄이네.',
          },
        ],
      },
      {
        which: 'JP',
        question: '계획이 어그러졌을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `이미 준비된 플랜 B가 있다. 그걸로 하자.`,
          },
          {
            type: 'P',
            score: 5,
            content: `아까 거기 느낌 좋던데 거길 가볼까?`,
          },
        ],
      },
      {
        which: 'JP',
        question: '연꽃 구경 가자고 친구에게 얘기를 한다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '언제 어디로 갈지 친구와 상세하게 얘기를 나눈다.',
          },
          {
            type: 'P',
            score: 5,
            content: '내일 꽃 보러 ㄱㄱ?',
          },
        ],
      },
      {
        which: 'JP',
        question: '벚꽃놀이 후 사진첩 정리를 할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '그 날 저녁 바로 정리해서 친구에게 보내준다.',
          },
          {
            type: 'P',
            score: 5,
            content: '여유 될 때 해야지. 미루다가 까먹는다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomSite/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomSite/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomSite/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomSite/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomSite/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomSite/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomSite/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomSite/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomSite/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomSite/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomSite/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomSite/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomSite/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomSite/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomSite/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/cherryBlossomSite/INFP.jpg',
      },
    ],
  },
  // loveSpicy
  {
    info: {
      mainTitle: '연애 매운맛 테스트',
      subTitle: 'MBTI로 보는 나의 연애의 매운 맛!',
      mainUrl: 'loveSpicy',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/loveSpicy-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveSpicy-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '이성을 볼 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '성격보다는 외모를 중점적으로 본다',
          },
          {
            type: 'I',
            score: 5,
            content: '외모보다는 성격을 중점적으로 본다',
          },
        ],
      },
      {
        which: 'EI',
        question: '연인이 마음에 안 드는 행동이나 말을 했을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '그 때 그 때 하고 싶은 말들을 다 하는 편이다',
          },
          {
            type: 'I',
            score: 5,
            content: '생각 정리부터 한 뒤 천천히 말하거나, 글로 표현을 한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '길 가다 모르는 이성이 말을 걸어 온 다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `자연스럽게 대화하다가 좀 맞으면 친해질 수도 있다`,
          },
          {
            type: 'I',
            score: 5,
            content: `경계심이 심해지고 말할 때 뭔가 고장 난다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 선호하는 연인은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `미래 보다는 현재 우리 관계에 집중하자는 연인`,
          },
          {
            type: 'N',
            score: 5,
            content: `현재 보다는 미래를 생각하고, 대비하면서 만나자는 연인`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인과의 관계에서 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '무언가를 하자고 제시하는 경우가 많다',
          },
          {
            type: 'N',
            score: 5,
            content: `연인의 의견 제시에 동의하고, 그게 가능한지 생각하고 답하는 경우가 많다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인과 추억에 관한 이야기를 할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `그 때 그 장소 날씨 시간 분위기 등 정확하게 기억함`,
          },
          {
            type: 'N',
            score: 5,
            content: `그 때의 추억을 무언가에 비유해서 기억함`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 힘들다고 연락이 온다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜 그러는지 물어보고 위로해준다',
          },
          {
            type: 'F',
            score: 5,
            content: '위로 먼저 하고 왜 그러는지 물어본다',
          },
        ],
      },
      {
        which: 'TF',
        question: '연인이 준비 중이던 시험에 붙었다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `와 역시..! 축하해! 한 번에 붙을 줄 알았어!`,
          },
          {
            type: 'F',
            score: 5,
            content: '와 완전 잘됐다 열심히 공부한 보람이 있네!',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 갑자기 일 중인 내 연인이 다른 이성이랑 놀고 있는 것 같다는 말을 했을 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '현장 사진으로 확인하고 싶어서 찍어서 보내 보라고 한다',
          },
          {
            type: 'F',
            score: 5,
            content: '배신감에 화가 치밀어 올라 당장 거기가 어디냐 묻는다',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 데이트전 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `입을 옷과 어디를 갈지 다 정해 놓는 편이다`,
          },
          {
            type: 'P',
            score: 5,
            content: `어디를 갈지 간단하게만 정하고, 즉흥적인 데이트를 즐긴다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 여행 준비를 할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '필요한 것을 정확하게 파악해서 챙긴다',
          },
          {
            type: 'P',
            score: 5,
            content: '뭔가 다 필요할 것 같아서 일단 챙기는 물건이 많다',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 백화점 구경을 한다면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '예쁜 물건이나, 옷이라도 소비 계획이 없다면 사지 않는다',
          },
          {
            type: 'P',
            score: 5,
            content: '예쁜 물건이나, 옷을 충동적으로 구매할 때가 있다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/loveSpicy/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/loveSpicy/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/loveSpicy/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/loveSpicy/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/loveSpicy/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/loveSpicy/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/loveSpicy/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/loveSpicy/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/loveSpicy/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/loveSpicy/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/loveSpicy/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/loveSpicy/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/loveSpicy/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/loveSpicy/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/loveSpicy/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/loveSpicy/INFP.jpg',
      },
    ],
  },
  // springPicninc
  {
    info: {
      mainTitle: '봄 소풍 테스트',
      subTitle: '내 소풍 스타일은 어떨까?',
      mainUrl: 'springPicninc',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/springPicninc-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/springPicninc-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'etc',
    },
    questions: [
      {
        which: 'EI',
        question: '둘 중 더 힐링 되는 소풍 스타일을 고르라면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구들과 우르르 함께 가서 게임하고 노는 피크닉',
          },
          {
            type: 'I',
            score: 5,
            content: '친한 친구와 돗자리 펴고 대화하면서 쉬는 피크닉',
          },
        ],
      },
      {
        which: 'EI',
        question: '주말에 야외로 소풍을 가자고 한다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '날도 좋은데 피크닉 좋다! 가보자잇!',
          },
          {
            type: 'I',
            score: 5,
            content: '살짝 귀찮달까.. 갈지 말지 고민됨',
          },
        ],
      },
      {
        which: 'EI',
        question: '즐거웠던 소풍이 끝나고 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `이제 다른 데 가서 놀고 싶음`,
          },
          {
            type: 'I',
            score: 5,
            content: `이제 집에 가서 쉬고 싶음`,
          },
        ],
      },
      {
        which: 'SN',
        question: '도시락을 쌀 때 드는 내 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `나 소질 있네 맛있게 잘 싼 듯!`,
          },
          {
            type: 'N',
            score: 5,
            content: `너무 맛있어서 애들이 기절하면 어쩐담`,
          },
        ],
      },
      {
        which: 'SN',
        question: '소풍 가기 전날 나의 모습은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '소풍 재밌겠당 도시락 맛있겠당',
          },
          {
            type: 'N',
            score: 5,
            content: `늦잠 자서 도시락 못 싸고 허둥거리는 내 모습이 상상됨`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구가 소풍 어땠냐고 물어보면 내 대답은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `꽃도 예쁘고 날씨도 좋았어 근데 사람 무지 많더라`,
          },
          {
            type: 'N',
            score: 5,
            content: `거기 분위기 너무 좋고 낭만 그 잡채~ `,
          },
        ],
      },
      {
        which: 'TF',
        question: `필요한 물품을 살 때 내 모습은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '소풍 바구니 한번 쓰고 안 쓸 거잖아 사지 말자',
          },
          {
            type: 'F',
            score: 5,
            content: '저 소풍 바구니도 사야 댐 갬성템이라구',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '친구가 ‘도시락 대충 싼 것 같은데 되게 맛있다’ 라고 하면 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `그치 야무지게 잘 싸왔지! 나 좀 재능 있는 듯`,
          },
          {
            type: 'F',
            score: 5,
            content: '대충 아냐.. 일찍 일어나서 되게 열심히 싸온 건데?',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 ‘아침부터 도시락 싸느라고 힘들었어’ 라고 하면 내 대답은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '도시락 메뉴 뭔데? 한번 볼래!',
          },
          {
            type: 'F',
            score: 5,
            content: '아침부터 고생했겠다ㅜ 도시락 맛있을 거 같은데??',
          },
        ],
      },
      {
        which: 'JP',
        question: '나의 약속을 잡는 방식은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `몇 시에 만나서 뭘 하고 끝나면 몇 시니깐 어쩌구저쩌구`,
          },
          {
            type: 'P',
            score: 5,
            content: `일단 만나서 정하자 소풍 갈래?`,
          },
        ],
      },
      {
        which: 'JP',
        question: '갑자기 지금 소풍을 떠나자고 한다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '지금?? 무작정 어떻게 가? 담에 계획 잡고 가야지',
          },
          {
            type: 'P',
            score: 5,
            content: '소풍 좋지! 일단 가보자 여기 어때?',
          },
        ],
      },
      {
        which: 'JP',
        question: '소풍 장소에 돗자리 펼 자리가 없다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '이러면 내 계획에 차질이 생기는데 아 스트레스',
          },
          {
            type: 'P',
            score: 5,
            content: '아 큰일이네 그냥 딴 데 갈까?',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/springPicninc/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/springPicninc/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/springPicninc/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/springPicninc/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/springPicninc/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/springPicninc/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/springPicninc/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/springPicninc/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/springPicninc/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/springPicninc/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/springPicninc/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/springPicninc/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/springPicninc/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/springPicninc/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/springPicninc/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/springPicninc/INFP.jpg',
      },
    ],
  },
  // onFire
  {
    info: {
      mainTitle: '폼 미쳤다! 테스트',
      subTitle: 'MBTI로 보는 나의 폼 근황은?',
      mainUrl: 'onFire',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/onFire-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/onFire-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '둘 중 내가 더 잘하는 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '나는 모임을 주도하는 것을 잘하는 것 같다',
          },
          {
            type: 'I',
            score: 5,
            content: '나는 혼자 재밌게 노는 것을 잘하는 것 같다',
          },
        ],
      },
      {
        which: 'EI',
        question: '둘 중 나의 의견을 전달할 때 더 폼이 좋다고 생각하는 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '사람들 앞에서 직접 말로 내 의견을 전달하는 것',
          },
          {
            type: 'I',
            score: 5,
            content: '나의 의견을 글로 작성하고 사람들에게 전달하는 것',
          },
        ],
      },
      {
        which: 'EI',
        question: '내가 더 기 빨리는 상황은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `핵아싸들 사이에 있기`,
          },
          {
            type: 'I',
            score: 5,
            content: `핵인싸들 사이에 있기`,
          },
        ],
      },
      {
        which: 'SN',
        question: '조립해야 하는 제품을 조립할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `무조건 설명서를 보고 조립을 한다`,
          },
          {
            type: 'N',
            score: 5,
            content: `일단 설명서 없이 감으로 조립을 시도한다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '음악을 들을 때 내가 먼저 신경 쓰는 순서는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '멜로디가 먼저 가사가 다음',
          },
          {
            type: 'N',
            score: 5,
            content: `가사가 먼저 멜로디가 다음`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 놀이기구를 무서워하는 이유는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `그냥 겁이 많다`,
          },
          {
            type: 'N',
            score: 5,
            content: `놀이 기구를 타고 떨어지는 상상을 많이 한다`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 나랑 안 맞는 것 같다고 한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜 그렇게 생각하는데? 하며 궁금해한다',
          },
          {
            type: 'F',
            score: 5,
            content: '마음에 상처를 받고 혼자 생각에 빠진다',
          },
        ],
      },
      {
        which: 'TF',
        question: '무언가 해냈을 때 내가 더 듣고 싶은 칭찬은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `와 폼 미쳤네! 역시 악마의 재능..ㄷㄷ `,
          },
          {
            type: 'F',
            score: 5,
            content: '고생한 만큼 엄청 잘 했다 수고 많았어!',
          },
        ],
      },
      {
        which: 'TF',
        question: `내가 더 잘하는 것은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '무언가를 알려주고, 문제 해결에 도움을 주는 것',
          },
          {
            type: 'F',
            score: 5,
            content: '감정적으로 공감을 해주고, 챙겨 주는 것',
          },
        ],
      },
      {
        which: 'JP',
        question: '사고 싶었던 물건을 구매할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `미리 찾아서 꼼꼼히 따져보고 구매한다`,
          },
          {
            type: 'P',
            score: 5,
            content: `상황에 맞춰서 유연하게 구매를 결정한다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 희열을 느끼는 부분은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '계획했던 부분을 끝까지 완벽하게 마무리를 잘하는 것',
          },
          {
            type: 'P',
            score: 5,
            content: '갑자기 생긴 문제를 임기응변으로 잘 수습했을 때',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 더 불편한 상황은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '계획을 미리 세워놓았는데 수정해야 할 일이 생기는 것',
          },
          {
            type: 'P',
            score: 5,
            content: '중간에 더 좋은 길을 찾아도 계획대로 쭉 실행해야 하는 것',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/onFire/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/onFire/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/onFire/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/onFire/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/onFire/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/onFire/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/onFire/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/onFire/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/onFire/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/onFire/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/onFire/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/onFire/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/onFire/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/onFire/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/onFire/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/onFire/INFP.jpg',
      },
    ],
  },
  // cantWaitEng
  {
    info: {
      mainTitle: `"I can't stand this" test`,
      subTitle: "Things you can't stand through MBTI?",
      mainUrl: 'cantWaitEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/cantWaitEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/cantWaitEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'Which one is more unbearable to you?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Awkward atmosphere when there are many people around',
          },
          {
            type: 'I',
            score: 5,
            content: 'The moment a lot of people focus on me.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What methods do you use when you need to communicate with people?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I like face-to-face and direct communication.',
          },
          {
            type: 'I',
            score: 5,
            content:
              'I prefer to communicate in writing using text or social media.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'How do you contact your friends?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I prefer to contact my friends first.`,
          },
          {
            type: 'I',
            score: 5,
            content: `My friends tend to contact me first.`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'Do you think when you spacing out?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I can think nothing.`,
          },
          {
            type: 'N',
            score: 5,
            content: `I can't stop thinking even when I shouldn't.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'If your car gets too stuck during a long drive, what can be your reaction?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: "Oh, traffic jam again. What's going on? Had an accident?",
          },
          {
            type: 'N',
            score: 5,
            content: `I would imagine the road is open in the middle of a traffic jam and I am driving.`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do you focus on when watching dramas or movies?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I focused on the emotions and acting of the characters.`,
          },
          {
            type: 'N',
            score: 5,
            content: `I find the meaning the writer or director wants to say or imagine what will happen after the ending.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What do you do when your friend calls you saying they are having a hard time?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I ask why first and comfort.',
          },
          {
            type: 'F',
            score: 5,
            content: 'I comfort first and ask why.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'If you forgive a friend who is late for an appointment, what can be the reason?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Because there are good reasons for being late.`,
          },
          {
            type: 'F',
            score: 5,
            content: 'Because I hear a quick apology right away.',
          },
        ],
      },
      {
        which: 'TF',
        question: `What do you do when you watch a sad drama or movie?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'There are times when I shed tears because I am sad, but only for a moment.',
          },
          {
            type: 'F',
            score: 5,
            content: "I can't hold back my tears and my emotions linger.",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What do you do when you have to go somewhere else from a gathering with friends?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I prefer to decide next place first and leave. `,
          },
          {
            type: 'P',
            score: 5,
            content: `It's okay to go out and decide later.`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'If you go to a new restaurant in front of your house and there is a menu that you have never seen before, what would be your reaction?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "I'm curious but I'm not sure what to eat, so I choose a menu I've thought of beforehand.",
          },
          {
            type: 'P',
            score: 5,
            content: 'I am not sure what to eat, so I would just try anything.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'How do you choose your food?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I make decisions easily.',
          },
          {
            type: 'P',
            score: 5,
            content: 'I take a long time to decide on the menu',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/cantWaitEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/cantWaitEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/cantWaitEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/cantWaitEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/cantWaitEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/cantWaitEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/cantWaitEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/cantWaitEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/cantWaitEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/cantWaitEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/cantWaitEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/cantWaitEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/cantWaitEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/cantWaitEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/cantWaitEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/cantWaitEng/INFP.jpg',
      },
    ],
  },
  // cantWaitCN
  {
    info: {
      mainTitle: '这我可忍受不了测试',
      subTitle: '通过MBTI了 解我无法忍受的情况',
      mainUrl: 'cantWaitCN',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/cantWaitCN-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/cantWaitCN-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'CN',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '我最忍受不了的情况是？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '人数较多气氛很尴尬的聚会',
          },
          {
            type: 'I',
            score: 5,
            content: '一瞬间太多人突然关注我',
          },
        ],
      },
      {
        which: 'EI',
        question: '有话想对大家说的时候我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '直截了当地口头来传达我想说的话',
          },
          {
            type: 'I',
            score: 5,
            content: '通过短信、SNS等渠道用文字传达我想说的话',
          },
        ],
      },
      {
        which: 'EI',
        question: '对于朋友而言我是？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `会主动联络对方的类型`,
          },
          {
            type: 'I',
            score: 5,
            content: `等待对方主动联络我的类型`,
          },
        ],
      },
      {
        which: 'SN',
        question: '发呆时我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `什么都不想`,
          },
          {
            type: 'N',
            score: 5,
            content: `虽然心想着别胡思乱想，但也停止不下来忧虑的心`,
          },
        ],
      },
      {
        which: 'SN',
        question: '长途开车的时候堵车特别严重的话我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '心想这车也太堵了吧…是出什么事了吗？难道是出车祸了？',
          },
          {
            type: 'N',
            score: 5,
            content: `想象自己在堵塞的车道中央自由行驶的情景`,
          },
        ],
      },
      {
        which: 'SN',
        question: '观看电影、电视剧的时候我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `集中观察演员的情感和演技`,
          },
          {
            type: 'N',
            score: 5,
            content: `探寻编剧和导演想传递的意思, 或者想象结局之后的内容`,
          },
        ],
      },
      {
        which: 'TF',
        question: `朋友联络我说自己很累的时候我会？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '先问他发生了什么事情，然后再安慰他',
          },
          {
            type: 'F',
            score: 5,
            content: '先安慰他，然后再问发生了什么事情',
          },
        ],
      },
      {
        which: 'TF',
        question: '原谅约会迟到的朋友的原因是？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `因为听到了不得不迟到的合理原因`,
          },
          {
            type: 'F',
            score: 5,
            content: '因为朋友一到就立马道歉了',
          },
        ],
      },
      {
        which: 'TF',
        question: `观看悲剧或电影的时候我会？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '虽然有时候也会过于伤感而流泪，但也是一瞬间的事儿',
          },
          {
            type: 'F',
            score: 5,
            content: '忍不住眼泪，情绪停留时间很长',
          },
        ],
      },
      {
        which: 'JP',
        question: '朋友聚会要转移到另一个场所的时候我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `先决定好要去哪，然后再出发`,
          },
          {
            type: 'P',
            score: 5,
            content: `先出发，然后再决定去哪里`,
          },
        ],
      },
      {
        which: 'JP',
        question: '去家门口新开的餐厅吃饭，发现有生以来从未吃过的菜品时我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '虽然挺好奇的，但不知道是什么味道，所以还是选自己想好要吃的菜品',
          },
          {
            type: 'P',
            score: 5,
            content: '反正也没想好要吃什么，既然好奇就尝尝新',
          },
        ],
      },
      {
        which: 'JP',
        question: '点菜的时候我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '很容易选好菜品',
          },
          {
            type: 'P',
            score: 5,
            content: '点菜往往需要很久的时间',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitCN/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitCN/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitCN/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitCN/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitCN/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitCN/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitCN/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitCN/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitCN/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitCN/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitCN/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitCN/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitCN/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitCN/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitCN/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/cantWaitCN/INFP.jpg',
      },
    ],
  },
  // cantWait
  {
    info: {
      mainTitle: '이건 못 참지! 테스트',
      subTitle: 'MBTI로 보는 내가 못 참는 것은?',
      mainUrl: 'cantWait',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/cantWait-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/cantWait-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '내가 더 못 참는 상황은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '사람들이 많은 모임에서 분위기가 어색한 것',
          },
          {
            type: 'I',
            score: 5,
            content: '순간 많은 사람들이 나에게 집중하는 것',
          },
        ],
      },
      {
        which: 'EI',
        question: '사람들에게 하고 싶은 말이 있을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '말로 내가 하고 싶은 직접 전달하는 것이 좋다',
          },
          {
            type: 'I',
            score: 5,
            content: '문자나, SNS 등을 이용해 글로 전달하는 것이 좋다',
          },
        ],
      },
      {
        which: 'EI',
        question: '나는 친구들에게?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `먼저 연락을 하는 편이다`,
          },
          {
            type: 'I',
            score: 5,
            content: `먼저 연락이 오는 편이다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '멍 때릴 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `아무 생각 안 할 수 있다`,
          },
          {
            type: 'N',
            score: 5,
            content: `아무 생각 하지 말아야지 하는 와중에도 생각을 멈출 수 없다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '장거리 운전 중 차가 너무 막힌다면 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '차 너무 막히네.. 무슨 일이지? 혹시 사고 났나? 생각한다',
          },
          {
            type: 'N',
            score: 5,
            content: `막히는 차 가운데로 길이 열려 시원하게 달리는 상상을 한다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '스트리밍 영화, 드라마를 시청할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `등장인물의 감정과 연기에 집중해서 본다`,
          },
          {
            type: 'N',
            score: 5,
            content: `작가와 감독이 전달하고자 하는 뜻을 찾거나, 결말 이후의 내용을 상상한다`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 힘들다고 연락이 온다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜 그러는지 물어보고 위로해준다',
          },
          {
            type: 'F',
            score: 5,
            content: '위로 먼저 하고 왜 그러는지 물어본다',
          },
        ],
      },
      {
        which: 'TF',
        question: '약속에 늦은 친구를 용서한 이유는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `늦을 수밖에 없는 타당한 이유를 들었기 때문에`,
          },
          {
            type: 'F',
            score: 5,
            content: '도착하자마자 빠르게 사과부터 했기 때문에',
          },
        ],
      },
      {
        which: 'TF',
        question: `슬픈 드라마나 영화를 볼 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '슬퍼서 눈물은 흘릴 때도 있지만 잠시뿐이다',
          },
          {
            type: 'F',
            score: 5,
            content: '눈물을 참지 못하고 감정의 여운이 오래 간다',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구들과 모임에서 다음 장소로 옮길 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `일단 정하고 나가자고 하는 편이다`,
          },
          {
            type: 'P',
            score: 5,
            content: `일단 나가서 정하자고 하는 편이다`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          '집 앞에 새로 생긴 음식점에 갔는데 난생처음 보는 메뉴가 있다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '궁금하지만 잘 모르니 미리 생각해 놓은 메뉴를 고른다',
          },
          {
            type: 'P',
            score: 5,
            content: '생각해 놓은 메뉴도 없고 궁금하니 한 번 먹어 본다',
          },
        ],
      },
      {
        which: 'JP',
        question: '음식 메뉴를 고를 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '메뉴 결정을 쉽게 한다',
          },
          {
            type: 'P',
            score: 5,
            content: '메뉴 결정하는 데 오래 걸린다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/cantWait/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/cantWait/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/cantWait/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/cantWait/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/cantWait/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/cantWait/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/cantWait/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/cantWait/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/cantWait/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/cantWait/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/cantWait/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/cantWait/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/cantWait/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/cantWait/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/cantWait/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/cantWait/INFP.jpg',
      },
    ],
  },
  // loveSpringFlowerJP
  {
    info: {
      mainTitle: '恋愛春の花びらテスト',
      subTitle: '私が花びらだったら、果たしてどんな花びらだろう？',
      mainUrl: 'loveSpringFlowerJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveSpringFlowerJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveSpringFlowerJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '人と接するときの私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '明るく熱心に会話をリードしていく',
          },
          {
            type: 'I',
            score: 5,
            content: '真剣に会話に参加し、積極的に共感する',
          },
        ],
      },
      {
        which: 'EI',
        question: '好きな人ができたら私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '積極的に意見を言い、約束をする方',
          },
          {
            type: 'I',
            score: 5,
            content:
              '相手の意見に同意し、状況に応じたリアクションをとることが多い。',
          },
        ],
      },
      {
        which: 'EI',
        question: '恋人とトラブルが起きた時、私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `言葉が多くなる`,
          },
          {
            type: 'I',
            score: 5,
            content: `考えが多くなる`,
          },
        ],
      },
      {
        which: 'SN',
        question: '私がもっと理解しにくい恋人の行動は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `おやすみなさいと言っておきながら、寝ないで連絡し続ける恋人。`,
          },
          {
            type: 'N',
            score: 5,
            content: `眠いと言って返事もせずに先に寝てしまった恋人。`,
          },
        ],
      },
      {
        which: 'SN',
        question: '私がより好む恋愛は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '未来を考える恋愛',
          },
          {
            type: 'N',
            score: 5,
            content: `今に忠実な恋愛`,
          },
        ],
      },
      {
        which: 'SN',
        question: '恋人が突然、今までしなかった行動をとったらどう思う？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `いきなり何だこれ？（本当に何でこんなことするんだろう？）`,
          },
          {
            type: 'N',
            score: 5,
            content: `何かあったのか？ (無限の想像をする)`,
          },
        ],
      },
      {
        which: 'TF',
        question: `友人が恋人の不満を打ち明けたら、私の反応は？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '最後まで全部聞いて、はっきりよく考えて自分の意見を言ってくれる。',
          },
          {
            type: 'F',
            score: 5,
            content:
              '最後まで聞いてみるとどちらも納得できるが、とりあえず友達の味方をする。',
          },
        ],
      },
      {
        which: 'TF',
        question: '友人が長年付き合った恋人と別れたとしたら？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `えっ、君たちが？`,
          },
          {
            type: 'F',
            score: 5,
            content: 'あらあら、大丈夫？',
          },
        ],
      },
      {
        which: 'TF',
        question: `恋人が理解できない行動をしたとき、私は？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'なぜあんな行動をしたのか理解できないので聞いてみる。',
          },
          {
            type: 'F',
            score: 5,
            content:
              '恋人がなぜそのような行動をしたのか、できるだけ理解しようとする',
          },
        ],
      },
      {
        which: 'JP',
        question: 'デートプランを立てるとき、私が恋人に怒る理由は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `恋人がデートプランの立案に積極的に参加してくれないので`,
          },
          {
            type: 'P',
            score: 5,
            content: `毎回同じような退屈なデートを要求して`,
          },
        ],
      },
      {
        which: 'JP',
        question: '恋人との初デートで私が不安になる理由は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '行こうと思っていたレストランの一つが、オープン締め切りのスケジュールが明確に記載されていなかったので',
          },
          {
            type: 'P',
            score: 5,
            content:
              'デートが近いのに、まだレストランの予約を忘れてしまったので',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'デート当日にレストランの予約をしたのに、恋人が突然他のメニューを食べたいと言ったら？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '恋人を説得して、もともと行こうと思っていたレストランに行く。',
          },
          {
            type: 'P',
            score: 5,
            content:
              '他のメニューも構わないので、予約をキャンセルしてそのメニューを食べようとする。',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerJP/INFP.jpg',
      },
    ],
  },
  // loveSpringFlowerEng
  {
    info: {
      mainTitle: 'Spring petal love test',
      subTitle:
        'What is your style of love compared to petals that bloom in spring?',
      mainUrl: 'loveSpringFlowerEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveSpringFlowerEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveSpringFlowerEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: 'How do you communicate with people?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '开I lead conversations enthusiastically.',
          },
          {
            type: 'I',
            score: 5,
            content:
              'I have a sincere and active conversation while empathizing.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What do you do when you meet someone you like?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'I actively express my thoughts and try to make an appointment with that person',
          },
          {
            type: 'I',
            score: 5,
            content:
              "I tend to agree with the other person's opinion and react appropriately to the situation.",
          },
        ],
      },
      {
        which: 'EI',
        question: 'What do you do when you have a problem with your lover?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `话I tend to talk a lot.`,
          },
          {
            type: 'I',
            score: 5,
            content: `I tend to have a lot of thoughts.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          "Which situation is more difficult to understand your lover's behavior?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: `It's hard for me to understand my lover who says good night but keeps talking without sleeping.`,
          },
          {
            type: 'N',
            score: 5,
            content: `It's hard to understand my lover who says he's sleepy and goes to sleep without replying.`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'Which relationship do you prefer?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'A relationship thinking about the future',
          },
          {
            type: 'N',
            score: 5,
            content: `A relationship faithful to the present`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'If your lover suddenly does unusual things, what can be your reaction?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Why? All of sudden? (what’s wrong with you?)`,
          },
          {
            type: 'N',
            score: 5,
            content: `What happened? (I'll imagine this and that endlessly)`,
          },
        ],
      },
      {
        which: 'TF',
        question: `If your friend complaints about his/her lover, what can be your reaction?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I listen to the end, think clearly, and give my opinion.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'After listening to the end, I understand the situation, but for now, I sympathize with the friend next to me.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'If your friend broke up with a long-time lover what can be your reaction?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Oh well, why? `,
          },
          {
            type: 'F',
            score: 5,
            content: 'Oh my god, are you okay now?',
          },
        ],
      },
      {
        which: 'TF',
        question: `What do you do when your lover does something crazy?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I will ask why my love did that.',
          },
          {
            type: 'F',
            score: 5,
            content: 'I will try understanding why my lover did it.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What can upset you when planning a date with your lover?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `It can be upsetting if my lover isn't proactive about making plans.`,
          },
          {
            type: 'P',
            score: 5,
            content: `It can be upsetting if my lover wants to have the same boring date every time.`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'What can make you anxious on the first date?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I feel anxious when the restaurant we are going to visit does not have business hours specified.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'I get anxious when I forget to make a reservation at a restaurant.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'If your lover suddenly wants to eat a different menu than you planned, how would you react?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I would persuade my lover to go to the restaurant we originally intended to go to.',
          },
          {
            type: 'P',
            score: 5,
            content: "As I don't care, it is okay to change the menu.",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerEng/INFP.jpg',
      },
    ],
  },
  // loveSpringFlowerCN
  {
    info: {
      mainTitle: '恋爱春季花卉测试',
      subTitle: '通过春季开放的花朵来看我的恋爱风格是？',
      mainUrl: 'loveSpringFlowerCN',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveSpringFlowerCN-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveSpringFlowerCN-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'CN',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '与他人聊天时我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '开朗热情地主导整个聊天',
          },
          {
            type: 'I',
            score: 5,
            content: '认真地参与进聊天并积极地与大家产生共鸣',
          },
        ],
      },
      {
        which: 'EI',
        question: '遇到心仪的对象时我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '主动告诉对方自己的心意，然后计划约会日期',
          },
          {
            type: 'I',
            score: 5,
            content: '同意对方的意见，根据具体情况给予相应的反应',
          },
        ],
      },
      {
        which: 'EI',
        question: '和恋人之间产生问题时我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `话语变得越来越多`,
          },
          {
            type: 'I',
            score: 5,
            content: `心思越来越繁多`,
          },
        ],
      },
      {
        which: 'SN',
        question: '那些恋人的举动会让我很难理解？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `明明让我去睡却一直联系的恋人`,
          },
          {
            type: 'N',
            score: 5,
            content: `说自己很困，然后毫无任何回复就先入睡的恋人`,
          },
        ],
      },
      {
        which: 'SN',
        question: '我更喜欢的恋爱风格是？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '思考未来的恋爱',
          },
          {
            type: 'N',
            score: 5,
            content: `忠实于现今的恋爱`,
          },
        ],
      },
      {
        which: 'SN',
        question: '如果恋人的举动突然和平时不同时，我会想？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `为什么突然会这样？（真是的，干嘛要这样？`,
          },
          {
            type: 'N',
            score: 5,
            content: `发生什么事了吗？（不停地想象）`,
          },
        ],
      },
      {
        which: 'TF',
        question: `朋友向我诉说有关恋人的不满时，我的反应是？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '听到最后，然后明确地思考不对的地方，再表达自己的意见',
          },
          {
            type: 'F',
            score: 5,
            content: '听到最后觉得双方都有理，但也会先站在朋友这边',
          },
        ],
      },
      {
        which: 'TF',
        question: '朋友说和交往很久的恋人分手了时我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `啊，你们俩分了啊？为什么？`,
          },
          {
            type: 'F',
            score: 5,
            content: '额..怎么是好..你没事吧？',
          },
        ],
      },
      {
        which: 'TF',
        question: `当恋人做出让我无法理解的举动时我会？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '无法理解为什么会做出这种举动，所以直接问恋人',
          },
          {
            type: 'F',
            score: 5,
            content: '尽最大的努力去理解恋人为什么会做出这种举动',
          },
        ],
      },
      {
        which: 'JP',
        question: '计划约会时让我跟恋人发火的理由是？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `恋人不主动和我一起计划约会`,
          },
          {
            type: 'P',
            score: 5,
            content: `每次都要求一样无聊的约会`,
          },
        ],
      },
      {
        which: 'JP',
        question: '和恋人初次约会时让我不满的理由是？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '准备去的一家美食餐厅的营业时间不准确',
          },
          {
            type: 'P',
            score: 5,
            content: '马上就要去约会了，却还没有预约好餐厅',
          },
        ],
      },
      {
        which: 'JP',
        question: '约会当天预约了餐厅，结果恋人却说自己想吃别的时我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '说服恋人，还是去预约好的餐厅',
          },
          {
            type: 'P',
            score: 5,
            content: '吃别的也无所谓，取消预约去恋人想吃的餐厅',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerCN/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerCN/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerCN/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerCN/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerCN/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerCN/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerCN/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerCN/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerCN/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerCN/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerCN/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerCN/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerCN/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerCN/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerCN/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlowerCN/INFP.jpg',
      },
    ],
  },
  // loveUniversity
  {
    info: {
      mainTitle: '연애 대학교 테스트',
      subTitle: '나의 연애 성적은?',
      mainUrl: 'loveUniversity',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveUniversity-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveUniversity-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '오늘은 대학교 신입생 OT! 내 모습은 어떨까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '처음 보는 애들한테도 다가가서 말 걸고 친해짐',
          },
          {
            type: 'I',
            score: 5,
            content: '먼저 말 걸어줄 때까지 일단 기다려 봄',
          },
        ],
      },
      {
        which: 'EI',
        question: '다음 강의까지는 시간이 남는데 뭐하고 싶어?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '수다 떨고 싶어서 친구들을 찾음',
          },
          {
            type: 'I',
            score: 5,
            content: '휴식 필요.. 조용한 장소를 찾음',
          },
        ],
      },
      {
        which: 'EI',
        question: '학교에서 과제를 준다면 나에게 더 편한 과제는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `말로 설명하며 발표하는 과제`,
          },
          {
            type: 'I',
            score: 5,
            content: `글로 써서 제출하는 과제`,
          },
        ],
      },
      {
        which: 'SN',
        question: '오늘따라 강의가 듣기 싫을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `이유는 없음 그냥 피곤하니까 듣기 싫은 거`,
          },
          {
            type: 'N',
            score: 5,
            content: `이 강의를 왜 들어야 하는지 갑자기 의문이 생김`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구가 오늘 말도 없이 강의에 무단결석했다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '늦잠 잤겠지 안 봐도 비디오임',
          },
          {
            type: 'N',
            score: 5,
            content: `뭔 일 있는 게 분명함 어디 아파서 쓰러져있는 거 아님?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '대학의 꽃 MT를 가게 되었다! 가기 전날 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `빨리 가고 싶어서 짐을 싸고 바로 누워 자려고 노력함`,
          },
          {
            type: 'N',
            score: 5,
            content: `도대체 어떤 두근거리는 일들이 생길까 상상하느라 잠이 안 옴`,
          },
        ],
      },
      {
        which: 'TF',
        question: `내일은 개강! 자기소개 시간이 있다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '어떻게든 되겠지. 별로 신경 쓰지 않음',
          },
          {
            type: 'F',
            score: 5,
            content:
              '처음 보는 사람 천지일 텐데 실수하면 어쩌지. 걱정이 한 무더기',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 CC였는데 이별을 당해서 슬퍼하고 있다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `왜 헤어지자고 했데? 근데 너 이제 학교 어케 다님ㅋ`,
          },
          {
            type: 'F',
            score: 5,
            content: '어쩌냐.. 괜찮음? 얼굴이 말이 아닌데..',
          },
        ],
      },
      {
        which: 'TF',
        question: `동기가 나를 좋아한다는 소문이 들린다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '나를? 왜? 그냥 소문이겠지',
          },
          {
            type: 'F',
            score: 5,
            content: '진짜..? 나 좋아한데..? (약간 감동)',
          },
        ],
      },
      {
        which: 'JP',
        question: '강의 시간표를 짤 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `미리 강의실 위치와 공강 등 정리해 놓고 플랜 B까지 준비`,
          },
          {
            type: 'P',
            score: 5,
            content: `수강 신청 전날 갑자기 생각나서 광클`,
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 과제를 하는 방식은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '이미 과제는 다 끝냄. 자 다음 할 일',
          },
          {
            type: 'P',
            score: 5,
            content: '과제는 당연히 벼락치기 우다다',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 더 싫은 진상 선배를 고르라면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '약속 시간에 맨날 늦고 딴 짓 하는 선배',
          },
          {
            type: 'P',
            score: 5,
            content: '약속 시간 지키라고 맨날 닦달하는 선배',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveUniversity/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveUniversity/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveUniversity/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveUniversity/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveUniversity/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveUniversity/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveUniversity/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveUniversity/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveUniversity/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveUniversity/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveUniversity/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveUniversity/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveUniversity/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveUniversity/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveUniversity/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveUniversity/INFP.jpg',
      },
    ],
  },
  // loveSpringFlower
  {
    info: {
      mainTitle: '연애 봄 꽃잎 테스트',
      subTitle: '커플 캐릭터로 보는 나의 연애 성향은?',
      mainUrl: 'loveSpringFlower',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveSpringFlower-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveSpringFlower-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '사람들을 대할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '밝고 열정적으로 대화를 이끌어 나간다',
          },
          {
            type: 'I',
            score: 5,
            content: '진지하게 대화에 참여하고 적극적으로 공감한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '좋아하는 사람이 생겼을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '적극적으로 의견을 말하고, 약속을 잡는 편이다',
          },
          {
            type: 'I',
            score: 5,
            content:
              '상대의 의견에 동의하고, 상황에 알맞은 리액션을 하는 편이다',
          },
        ],
      },
      {
        which: 'EI',
        question: '연인과 문제가 생겼을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `말이 많아진다`,
          },
          {
            type: 'I',
            score: 5,
            content: `생각이 많아진다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 더 이해하기 힘든 연인의 행동은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `졸리다고 하고 답장도 없이 먼저 잠든 연인`,
          },
          {
            type: 'N',
            score: 5,
            content: `잘 자라고 해놓고 안자고 계속 연락을 이어가는 연인`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 더 선호하는 연애는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '현재에 충실한 연애',
          },
          {
            type: 'N',
            score: 5,
            content: `미래를 생각하는 연애`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인이 갑자기 안 하던 행동을 한다면 내 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `갑자기 왜 이래? (진짜 왜 이러는 거지?)`,
          },
          {
            type: 'N',
            score: 5,
            content: `무슨 일 있나? (끝없는 상상을 한다)`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 연인에 대한 불만을 털어놓는다면 나의 반응은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '끝까지 다 들어보고 명확하게 잘 잘못을 생각해보고 내 의견을 말해준다',
          },
          {
            type: 'F',
            score: 5,
            content:
              '끝까지 들어보니 둘 다 이해가 가지만 일단 친구 편을 들어준다',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 오래 사귄 연인이랑 헤어졌다고 한다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `헉 너희가? 왜?`,
          },
          {
            type: 'F',
            score: 5,
            content: '헐 어떡해.. 괜찮아?',
          },
        ],
      },
      {
        which: 'TF',
        question: `연인이 이해할 수 없는 행동을 했을 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜 저런 행동을 했는지 이해가 안 가서 물어본다',
          },
          {
            type: 'F',
            score: 5,
            content: '연인이 왜 그런 행동을 했는지 최대한 이해하려고 해본다',
          },
        ],
      },
      {
        which: 'JP',
        question: '데이트 계획을 짤 때 내가 연인에게 화나는 이유는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `연인이 데이트 계획 짜는 것에 적극적으로 참여하지 않아서`,
          },
          {
            type: 'P',
            score: 5,
            content: `매번 똑같은 지루한 데이트를 요구해서`,
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과의 첫 데이트에서 내가 불안한 이유는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '가려 했던 맛집 중 한 곳이 오픈 마감 일정이 명확하게 나와 있지 않아서',
          },
          {
            type: 'P',
            score: 5,
            content: '데이트가 코앞인데 아직 식당 예약을 깜빡해서',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '데이트 당일 식당 예약을 했는데 연인이 갑자기 다른 메뉴가 먹고 싶다고 한다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '연인을 설득해서 원래 가려고 했던 식당에 간다',
          },
          {
            type: 'P',
            score: 5,
            content:
              '다른 메뉴도 상관없어서 예약을 취소하고 그 메뉴를 먹자고 한다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlower/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlower/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlower/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlower/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlower/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlower/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlower/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlower/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlower/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlower/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlower/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlower/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlower/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlower/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlower/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpringFlower/INFP.jpg',
      },
    ],
  },
  // loveSpeedJP
  {
    info: {
      mainTitle: '恋愛速度テスト',
      subTitle: '私の恋愛成功速度はどれくらい速いかな?',
      mainUrl: 'loveSpeedJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveSpeedJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveSpeedJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'JP',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '好きな人と話す時はどうする？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '自然と会話をリードすることが多いですね。',
          },
          {
            type: 'I',
            score: 5,
            content:
              '私は普段から、人と話すときはよく考えてから話すようにしています。',
          },
        ],
      },
      {
        which: 'EI',
        question: '恋人と何をするか決める時、私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '主体的に意思決定をしている',
          },
          {
            type: 'I',
            score: 5,
            content: '恋人がどんな決定をするのか見守る。',
          },
        ],
      },
      {
        which: 'EI',
        question: '友だちと話すときはどうする？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `友達が話しているときに割り込むことが多い`,
          },
          {
            type: 'I',
            score: 5,
            content: `私が話しているときに、友達が割り込んでくることがよくあるんです。`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'サム中だった異性と付き合うようになったら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `付き合うことに成功したので、きっと気持ちよく幸せになれると思います。`,
          },
          {
            type: 'N',
            score: 5,
            content: `ワクワクするような未来を思い浮かべて、幸せを感じるのです。`,
          },
        ],
      },
      {
        which: 'SN',
        question: '恋人との関係で大切にしていることは何ですか？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '現在、恋愛に集中すること',
          },
          {
            type: 'N',
            score: 5,
            content: `二人の未来を語り合い、そのために何かを準備する。`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '普段、恋人とどのようなコミュニケーションをとっているのでしょうか？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `最近あったことや、自分の経験談を話す。`,
          },
          {
            type: 'N',
            score: 5,
            content: `自分の想像を話したり、それについて質問したりします。`,
          },
        ],
      },
      {
        which: 'TF',
        question: `恋人が誰かと口論していたら、どうするのですか？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'なぜそうするのか聞いて、恋人に大丈夫なのか聞いてみる。',
          },
          {
            type: 'F',
            score: 5,
            content: '今は恋人の味方をして、後で事情を知ることにしよう。',
          },
        ],
      },
      {
        which: 'TF',
        question: 'あなたは恋愛において、何をより重視しますか？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `信頼関係を大切にし、問題解決を第一に考えています。`,
          },
          {
            type: 'F',
            score: 5,
            content: '私は感情的な交流を大切にし、共感が第一です。',
          },
        ],
      },
      {
        which: 'TF',
        question: `恋人と対立しているときはどうするのでしょうか？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '自分が思う問題点を正直に話し、解決しようとする。',
          },
          {
            type: 'F',
            score: 5,
            content: 'まずは頭の中を整理して、後で解決するようにしています。',
          },
        ],
      },
      {
        which: 'JP',
        question: '恋人とデートするときはどうする？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `恋人とのデートは毎回事前に細かく計画する傾向がある`,
          },
          {
            type: 'P',
            score: 5,
            content: `恋人とのデートで即興的に新しいことを経験してみる方だ。`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'あなたの生活に密着したものは何ですか？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '私の人生には、計画的なルーティンがたくさんあります。',
          },
          {
            type: 'P',
            score: 5,
            content: '私の人生には、即興的なことがたくさんあります。',
          },
        ],
      },
      {
        which: 'JP',
        question: 'あなたにとって恋人との約束の意味とは？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '恋人との約束は何があっても守らなければならない。',
          },
          {
            type: 'P',
            score: 5,
            content: '恋人との約束は状況に応じて柔軟に変更できるんです。',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedJP/INFP.jpg',
      },
    ],
  },
  // loveSpeedEng
  {
    info: {
      mainTitle: 'Dating speed test',
      subTitle: 'Your dating tendency through speed',
      mainUrl: 'loveSpeedEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveSpeedEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveSpeedEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: 'What do you do when you talk to someone you like?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I tend to lead conversations naturally',
          },
          {
            type: 'I',
            score: 5,
            content: 'I usually think carefully before talking to someone',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What do you do when you have to decide what to do with your lover?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I take initiative in making decisions',
          },
          {
            type: 'I',
            score: 5,
            content: "I'm just waiting for my lover to decide",
          },
        ],
      },
      {
        which: 'EI',
        question: 'What do you do when you talk to your friends?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I often interrupt when my friends are talking`,
          },
          {
            type: 'I',
            score: 5,
            content: `I often have friends interrupt when I am talking`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What would you do if the person you were dating became your boyfriend or girlfriend?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I'm sure you would feel good and happy because I've succeeded in dating`,
          },
          {
            type: 'N',
            score: 5,
            content: `I would picture an exciting future and feel happy`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'How do you usually communicate with your lover?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'I talk about something that happened recently or about my experience',
          },
          {
            type: 'N',
            score: 5,
            content: `I talk about my imagination and ask questions about it`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What is important to you in your relationship with your lover?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Focusing on dating in the present`,
          },
          {
            type: 'N',
            score: 5,
            content: `Talking about the future together and prepare anything for that`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What do you do if your lover is arguing with someone?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'I ask why, and check with my lover to see if things are getting better now',
          },
          {
            type: 'F',
            score: 5,
            content:
              "I'll take my lover's side for now and find out what's going on later",
          },
        ],
      },
      {
        which: 'TF',
        question: 'What do you value more in a relationship?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I value trust and problem solving comes first`,
          },
          {
            type: 'F',
            score: 5,
            content: 'I value emotional exchange and empathy comes first',
          },
        ],
      },
      {
        which: 'TF',
        question: `What do you do when you are in conflict with your lover?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'I talk honestly about the problems I think and try to solve them',
          },
          {
            type: 'F',
            score: 5,
            content:
              'I try to clear my mind first and then work things out later',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What do you do when dating your lover?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I tend to plan every date with my lover in detail in advance`,
          },
          {
            type: 'P',
            score: 5,
            content: `When I'm in a relationship, I naturally try to experience new things`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'What is close to your life?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'There are many planned routines in my life',
          },
          {
            type: 'P',
            score: 5,
            content: 'There are a lot of impromptu things in my life',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What is the meaning of a promise with your lover to you?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I must keep my promise to my lover no matter what',
          },
          {
            type: 'P',
            score: 5,
            content:
              'I can flexibly change my appointments with my lover depending on the situation',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveSpeedEng/INFP.jpg',
      },
    ],
  },
  // loveSpeed
  {
    info: {
      mainTitle: '연애 속도 테스트',
      subTitle: '과연 나의 연애 성공 속도와 연애 성향은 어떨까?',
      mainUrl: 'loveSpeed',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/loveSpeed-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveSpeed-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '호감이 있는 이성과 대화할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '자연스럽게 대화를 이끌어 나가는 편이다',
          },
          {
            type: 'I',
            score: 5,
            content: '말 할 때 마다 생각하고 말하는 편이다',
          },
        ],
      },
      {
        which: 'EI',
        question: '연인과 무엇을 할지 결정할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '주도적으로 결정을 한다',
          },
          {
            type: 'I',
            score: 5,
            content: '연인이 어떤 결정을 하는지 지켜본다',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구들과 대화할 때 나와 가까운 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `친구가 이야기하고 있는데 내가 끼어드는 경우가 많다`,
          },
          {
            type: 'I',
            score: 5,
            content: `내가 이야기할 때 친구가 끼어드는 경우가 많다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '썸 중이던 이성과 사귀게 되었다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `연애에 성공해 기분이 좋고, 행복하다`,
          },
          {
            type: 'N',
            score: 5,
            content: `설레는 미래를 그려보고, 행복해한다 `,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인과의 관계에서 나에게 중요한 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '현재에 연애에 집중하는 것',
          },
          {
            type: 'N',
            score: 5,
            content: `함께하는 미래에 대해 이야기하고 준비하는 것`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인에게 내가 더 많이 하는 대화 방식은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `최근에 일어난 일이나, 내가 경험했던 이야기를 하는 것`,
          },
          {
            type: 'N',
            score: 5,
            content: `일어나지 않은 일에 대해 이야기 하고, 어떻게 할지 물어보는 것`,
          },
        ],
      },
      {
        which: 'TF',
        question: `연인이 누군가와 실랑이를 벌이고 있다면 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜 그러는지 물어보고, 연인에게 괜찮은 지 물어본다',
          },
          {
            type: 'F',
            score: 5,
            content: '일단 연인의 편을 들고, 무슨 일인지 알아본다',
          },
        ],
      },
      {
        which: 'TF',
        question: '내가 연애에서 더 중요하게 생각하는 것은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `신뢰하는 관계와 문제 해결이 우선인 연애`,
          },
          {
            type: 'F',
            score: 5,
            content: '감정적인 교류와 공감이 먼저인 연애',
          },
        ],
      },
      {
        which: 'TF',
        question: `연인과 갈등 상황 시에 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '내가 생각하는 문제점을 솔직하게 이야기하고 해결하려고 한다',
          },
          {
            type: 'F',
            score: 5,
            content: '내가 느끼는 감정을 먼저 충분히 정리한 후 해결한다 ',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 데이트 시에 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `연인과 데이트는 뭐든 미리 세세하게 계획해서 하는 편이다`,
          },
          {
            type: 'P',
            score: 5,
            content: `연인과 데이트에서 즉흥적으로 새로운 것을 경험해 보는 편이다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '나의 생활과 가까운 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '나의 생활에는 계획적인 루틴이 많은 편이다',
          },
          {
            type: 'P',
            score: 5,
            content: '나의 생활에는 새롭고 즉흥적인 일들이 많은 편이다',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 생각하는 연인간의 약속은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '연인과의 약속은 무슨 일이 있더라도 꼭 지켜야 한다',
          },
          {
            type: 'P',
            score: 5,
            content: '연인과의 약속은 상황에 따라 유연하게 바뀔 수 있다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/loveSpeed/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/loveSpeed/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/loveSpeed/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/loveSpeed/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/loveSpeed/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/loveSpeed/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/loveSpeed/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/loveSpeed/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/loveSpeed/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/loveSpeed/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/loveSpeed/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/loveSpeed/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/loveSpeed/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/loveSpeed/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/loveSpeed/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/loveSpeed/INFP.jpg',
      },
    ],
  },
  // shootingStar
  {
    info: {
      mainTitle: '별똥별 이상형 테스트',
      subTitle: '별똥별에게 이상형 소원을 빌어보자',
      mainUrl: 'shootingStar',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/shootingStar-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/shootingStar-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question:
          '이번 주 내내 약속이 있었는데 주말에 산속 캠핑을 가자고 한다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '갈래!! 산이면 별똥별도 보이려나? 완전 힐링 ㅜ',
          },
          {
            type: 'I',
            score: 5,
            content: '미안.. 이번 주 계속 나가서 나 힘들어.. 휴식이 필요해ㅜ',
          },
        ],
      },
      {
        which: 'EI',
        question: '결국 도착한 캠핑장! 그런데 사람들이 우글우글하다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '와우 사람 많다 여긴 핫플이 분명해 우리 잘 찾아온 듯!',
          },
          {
            type: 'I',
            score: 5,
            content: '사람이 왜 이렇게 많지 아무래도 우리 날을 잘못 잡은 듯',
          },
        ],
      },
      {
        which: 'EI',
        question: '캠핑장에서 만난 사람들이 별똥별을 보러 다 같이 가자고 하면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `다 같이 보면 더 좋지! 이참에 친해지면 재밌겠다`,
          },
          {
            type: 'I',
            score: 5,
            content: `우리는 따로 가자.. 모르는 사람들 많잖아 불편쓰..`,
          },
        ],
      },
      {
        which: 'SN',
        question: '별똥별을 보러 캄캄한 산속을 올라가는 중에 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `아우 어두워 언제 도착한담`,
          },
          {
            type: 'N',
            score: 5,
            content: `갑자기 곰이 튀어나오는 건 아니겠지? 근데 여기 곰이 사나?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '별똥별을 보고 소원을 빌자고 하면 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '어차피 소원 안 들어줄 텐데 그래도 예쁘긴 하다',
          },
          {
            type: 'N',
            score: 5,
            content: `이미 소원이 이루어져 행복해하는 내 모습을 상상하고 있음`,
          },
        ],
      },
      {
        which: 'SN',
        question: '하늘에서 떨어지는 별똥별을 보면 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `우주에서 떨어지는 불타는 돌이랄까`,
          },
          {
            type: 'N',
            score: 5,
            content: `저 안에 외계인 들어있어서 지구 침략 당할 수도 있음`,
          },
        ],
      },
      {
        which: 'TF',
        question: `연인이 요즘 힘들어 보인다며 힐링 여행을 가자고 하면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '여행? 좋다 어디로 갈까? (내가 힘들어 보이나?)',
          },
          {
            type: 'F',
            score: 5,
            content: '헐ㅜ 고마워 신경 써줘서ㅜ (감동의 쓰나미가 몰려옴)',
          },
        ],
      },
      {
        which: 'TF',
        question: '연인과 여행 중 뭔가를 사러 밖에 다녀온다고 한다!',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `다녀올 꺼야? 잘 다녀와 올 때 메로나!`,
          },
          {
            type: 'F',
            score: 5,
            content: '같이 가까? 혼자 가면 심심할 텐데',
          },
        ],
      },
      {
        which: 'TF',
        question: `여행지에서 옆 커플이 크게 싸우고 있다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '뭔 일이래? 뭔 소리까지 지른담',
          },
          {
            type: 'F',
            score: 5,
            content: '저 사람 우는데? 어뜨케ㅜㅜ 여기서 왜 싸우지? 어쩌냐ㅜㅜ',
          },
        ],
      },
      {
        which: 'JP',
        question: '여행을 계획할 때 나의 스타일은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `9시에 만나서 12시에 도착하면 밥을 여기서 먹고 나가서 이러쿵저러쿵`,
          },
          {
            type: 'P',
            score: 5,
            content: `저녁밥은 여기서 뇸뇸~ 담날은 여기서 룰루~ 계획 끝`,
          },
        ],
      },
      {
        which: 'JP',
        question: '여행 계획을 다 짜놨는데 한 명이 더 간다고 한다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '이미 다 짜놨는데.. 다시 짜야겠네… 후',
          },
          {
            type: 'P',
            score: 5,
            content: '같이 가자! 어떻게든 되겠지!',
          },
        ],
      },
      {
        which: 'JP',
        question: '별똥별을 보러 왔는데 비가 와서 결국 못 봤다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '분명 비 안 온다고 했는데.. 하지만 난 우산을 챙겨왔지',
          },
          {
            type: 'P',
            score: 5,
            content: '내 별똥별ㅜㅜ 우산이나 사야겠다. 오늘 비 온다고 했었나?',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/shootingStar/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/shootingStar/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/shootingStar/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/shootingStar/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/shootingStar/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/shootingStar/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/shootingStar/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/shootingStar/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/shootingStar/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/shootingStar/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/shootingStar/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/shootingStar/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/shootingStar/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/shootingStar/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/shootingStar/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/shootingStar/INFP.jpg',
      },
    ],
  },
  // loveCellJP
  {
    info: {
      mainTitle: '恋愛細胞キャラクターテスト',
      subTitle: '私の恋愛細胞キャラクターと恋愛細胞活動数値は？',
      mainUrl: 'loveCellJP',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/loveCellJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveCellJP-thumb.png',
      horizontalBanner: '',
      lang: 'JP',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '好きな人に私の興味を引く方法は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '積極的な表現と質問で注目を集める',
          },
          {
            type: 'I',
            score: 5,
            content: '積極的な傾聴と共感で注目を集める',
          },
        ],
      },
      {
        which: 'EI',
        question: '新しい人を作るときの私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '新しい人とすぐに打ち解けることができる。',
          },
          {
            type: 'I',
            score: 5,
            content: '新しい人と仲良くなるのに時間がかかることが多い',
          },
        ],
      },
      {
        which: 'EI',
        question: '恋人と遊びに来た遊園地に人がたくさんいたら？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `うわー、やっぱり！！人が多い！！わくわくするわー！`,
          },
          {
            type: 'I',
            score: 5,
            content: `うわぁ、人が多くて疲れるなぁ…`,
          },
        ],
      },
      {
        which: 'SN',
        question: '気になる異性と連絡中、相手が長い間連絡が取れない場合は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `少し待てば連絡が来るだろう～。`,
          },
          {
            type: 'N',
            score: 5,
            content: `私が何か言い間違えた？ もしかして私が嫌われた？`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '恋人と一緒に海外旅行に行くことになった日、飛行機に乗るときに私は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'わくわくする～、行ってみたらすごく楽しいだろう？',
          },
          {
            type: 'N',
            score: 5,
            content: `もしこの飛行機が飛行中に突然墜落したら...？`,
          },
        ],
      },
      {
        which: 'SN',
        question: '恋人が浮気をしたけど、実は夢だったとしたら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `ヒュー。夢だね〜。`,
          },
          {
            type: 'N',
            score: 5,
            content: `もしかして...？ 無理して恋人に「もしかして私に何か隠してるんじゃないの？`,
          },
        ],
      },
      {
        which: 'TF',
        question: `友達から連絡が来る異性ができたとしたら？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'イ・ヨル～誰だ？ イケメン/美人か？',
          },
          {
            type: 'F',
            score: 5,
            content: '本当に？ すごい！ おめでとう！ うまくいくよ。',
          },
        ],
      },
      {
        which: 'TF',
        question: '恋人との葛藤の状況に私は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `できるだけ理性的に論理的に話す`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '感情が高ぶり、言いたいことはたくさんあるが、うまくまとまらない。',
          },
        ],
      },
      {
        which: 'TF',
        question: `友達が以前会った異性の話をしている時に突然泣いたら？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'ねえ、なんで急に泣いてるの？ あの子のせいで泣いてるの？',
          },
          {
            type: 'F',
            score: 5,
            content:
              'おい、大丈夫か？ そんなくだらないことは忘れてくれ、お前の涙がもったいない。',
          },
        ],
      },
      {
        which: 'JP',
        question: '気になる異性が突然家に遊びに来ると宣言したら？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `隅々まできれいに掃除する`,
          },
          {
            type: 'P',
            score: 5,
            content: `まずは目に見えるところだけ簡単に掃除する`,
          },
        ],
      },
      {
        which: 'JP',
        question: '新しい仕事を任された私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'まずはどうするか計画から立てる',
          },
          {
            type: 'P',
            score: 5,
            content: 'まずは始めてみて、状況を見て柔軟性を発揮する。',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '恋人と映画を見に行くことになった日、突然恋人が別の映画が観たいと言ったら？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'ただ、もともと見ようと思っていた映画を見ようとする',
          },
          {
            type: 'P',
            score: 5,
            content: 'どっちでもいいからそうしよう、と言う',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/loveCellJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/loveCellJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/loveCellJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/loveCellJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/loveCellJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/loveCellJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/loveCellJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/loveCellJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/loveCellJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/loveCellJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/loveCellJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/loveCellJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/loveCellJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/loveCellJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/loveCellJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/loveCellJP/INFP.jpg',
      },
    ],
  },
  // loveCellCN
  {
    info: {
      mainTitle: '恋爱细胞人物测试',
      subTitle: '通过恋爱细胞了解我的恋爱取向',
      mainUrl: 'loveCellCN',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/loveCellCN-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveCellCN-thumb.png',
      horizontalBanner: '',
      lang: 'CN',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '遇到有好感的异性时，我吸引对方的方式是？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '主动表达和提问，引起对方的注意',
          },
          {
            type: 'I',
            score: 5,
            content: '主动倾听对方的话语并产生共鸣，从而引起对方的注意',
          },
        ],
      },
      {
        which: 'EI',
        question: '新结识他人时我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '即使是新结识的朋友也会一样容易很快亲近',
          },
          {
            type: 'I',
            score: 5,
            content: '要想和新结识的朋友亲近起来需要投入很长的时间',
          },
        ],
      },
      {
        which: 'EI',
        question: '和恋人去游乐园玩时发现人非常多的话?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `哇，果然！！人好多呀，好嗨呀！`,
          },
          {
            type: 'I',
            score: 5,
            content: `哇，这人也太多了，好累啊..TT`,
          },
        ],
      },
      {
        which: 'SN',
        question: '一直联络的暧昧异性很久没联络我时我会?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `有什么事吗？一会儿应该会回电话吧~`,
          },
          {
            type: 'N',
            score: 5,
            content: `不会是我说错什么话了吧？还是不喜欢我了？ `,
          },
        ],
      },
      {
        which: 'SN',
        question: '和恋人一起去国外旅游的那一天，乘飞机时的我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '好激动呀~一定会很有趣!',
          },
          {
            type: 'N',
            score: 5,
            content: `飞机起飞之后不会突然坠落吧..?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '恋人出轨了，但后来发现是个梦时我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `哎..原来是个梦呀~`,
          },
          {
            type: 'N',
            score: 5,
            content: `难道..?问恋人是不是有事情瞒着我`,
          },
        ],
      },
      {
        which: 'TF',
        question: `朋友说自己有保持联络的异性时我会？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '哇~是谁呀？帅不帅？/漂亮不？',
          },
          {
            type: 'F',
            score: 5,
            content: '真的吗？厉害！恭喜呀，但愿有好的进展呀',
          },
        ],
      },
      {
        which: 'TF',
        question: '和恋人产生矛盾时我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `尽量理智地有道理地说话`,
          },
          {
            type: 'F',
            score: 5,
            content: '受情绪影响虽然想说的话很多，但一直整理不好思绪',
          },
        ],
      },
      {
        which: 'TF',
        question: `朋友诉说之前交往过的X时突然哭了，这时我会?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '哎呀，干嘛突然哭啊？因为他哭啊？',
          },
          {
            type: 'F',
            score: 5,
            content: '哎呀，没事吧？那种垃圾赶快忘掉，不值得你流眼泪',
          },
        ],
      },
      {
        which: 'JP',
        question: '搞暧昧的异性突然说要来我家时我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `先尽快随便打扫干净容易看到的地方`,
          },
          {
            type: 'P',
            score: 5,
            content: `干净地清理好每一个角落`,
          },
        ],
      },
      {
        which: 'JP',
        question: '开始负责新任务的我会?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '首先列好具体的计划再行动',
          },
          {
            type: 'P',
            score: 5,
            content: '先开始步入工作，一边进行一边看情况变通地处理',
          },
        ],
      },
      {
        which: 'JP',
        question: '和恋人看电影的当天，突然恋人说想看另一部电影时我会?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '不介意，同意看另一部电影',
          },
          {
            type: 'P',
            score: 5,
            content: '给恋人说还是看之前决定看的那部电影',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/loveCellCN/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/loveCellCN/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/loveCellCN/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/loveCellCN/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/loveCellCN/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/loveCellCN/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/loveCellCN/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/loveCellCN/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/loveCellCN/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/loveCellCN/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/loveCellCN/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/loveCellCN/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/loveCellCN/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/loveCellCN/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/loveCellCN/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/loveCellCN/INFP.jpg',
      },
    ],
  },
  // loveCellEng
  {
    info: {
      mainTitle: 'Love cell character test',
      subTitle: 'Recognizing your Love Tendency with Love Cells',
      mainUrl: 'loveCellEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveCellEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveCellEng-thumb.png',
      horizontalBanner: '',
      lang: 'Eng',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: 'How do you get the attention of the person you like?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'I attract attention with assertive expressions and questions.',
          },
          {
            type: 'I',
            score: 5,
            content: 'I attract attention with empathy and a good listener',
          },
        ],
      },
      {
        which: 'EI',
        question: 'When you meet someone new, how do you do?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I tend to get along easily with new people',
          },
          {
            type: 'I',
            score: 5,
            content: 'I tend to take a lot of time to get to know new people',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'How would you react if there were a lot of people at an amusement park where you and your lover came to play?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Wow, there are a lot of people. It's exciting!`,
          },
          {
            type: 'I',
            score: 5,
            content: `Wow, so many people, I'm tired`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          "What do you do if you haven't heard from someone you've been dating for a long time?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: `what's the matter? A call will come soon`,
          },
          {
            type: 'N',
            score: 5,
            content: `Did I make any mistake? Maybe, he hates me? `,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'When you go on an overseas trip with your lover, what is your reaction before boarding?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: "I'm excited~ It must be very fun, right?",
          },
          {
            type: 'N',
            score: 5,
            content: `I'm afraid this plane might crash suddenly?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'If your lover cheated on you and it turned out to be a dream, what would be your reaction?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Hmmm, It's a dream~`,
          },
          {
            type: 'N',
            score: 5,
            content: `I would ask if my lover is cheating on me`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What would you do if your friend had a new friend of the opposite sex?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Who is it? handsome? / pretty?',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Really? That is great! Congratulations! It should work out',
          },
        ],
      },
      {
        which: 'TF',
        question: 'What do you do when you have a conflict with your lover?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I speak as rationally and logically as possible`,
          },
          {
            type: 'F',
            score: 5,
            content:
              "I have a lot to say, but I can't organize it well because I am emotional",
          },
        ],
      },
      {
        which: 'TF',
        question: `What do you do when your friend suddenly cries while talking about his ex?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Hey, why are you crying all of a sudden? Are you crying over your ex?',
          },
          {
            type: 'F',
            score: 5,
            content: 'Are you okay? Forget the trash, your tears are a waste',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What would you do if your lover suddenly told you that he was coming your home?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I only clean what I can see quickly`,
          },
          {
            type: 'P',
            score: 5,
            content: `I clean everything perfectly`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'What do you do when you take on a new job?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I plan what to do first',
          },
          {
            type: 'P',
            score: 5,
            content: 'I just start, see the situation and be flexible',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What would you do if your lover suddenly wants to watch another movie?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: "I don't mind either, so I say yes",
          },
          {
            type: 'P',
            score: 5,
            content:
              'I would ask to see the movie we were originally going to see',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveCellEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveCellEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveCellEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveCellEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveCellEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveCellEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveCellEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveCellEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveCellEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveCellEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCellEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveCellEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveCellEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCellEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveCellEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveCellEng/INFP.jpg',
      },
    ],
  },
  // chatGPTCharacteristic
  {
    info: {
      mainTitle: '챗 GPT 인공지능 성격 테스트',
      subTitle: '인공지능 캐릭터로 알아보는 나의 성격은?',
      mainUrl: 'chatGPTCharacteristic',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/chatGPTCharacteristic-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/chatGPTCharacteristic-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '당신은 어떻게 에너지를 충전합니까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '밖에서 사람들과 만나면서 충전한다',
          },
          {
            type: 'I',
            score: 5,
            content: '집에서 쉬면서 충전한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '모임에서 당신은 어떤 사람 입니까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '적극적으로 분위기를 주도하는 사람',
          },
          {
            type: 'I',
            score: 5,
            content: '적극적으로 분위기에 맞추는 사람',
          },
        ],
      },
      {
        which: 'EI',
        question: '새로운 사람을 사귈 때 당신은 어떤 유형입니까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `넓고 얕게 사귀는 유형`,
          },
          {
            type: 'I',
            score: 5,
            content: `좁고 깊게 사귀는 유형`,
          },
        ],
      },
      {
        which: 'SN',
        question: '당신은 어떤 유형의 사람입니까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `현재에 집중하는 사람`,
          },
          {
            type: 'N',
            score: 5,
            content: `미래 지향적인 사람`,
          },
        ],
      },
      {
        which: 'SN',
        question: '당신은 영화, 드라마를 볼 때 어떤 타입 입니까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '배우의 연기와 스토리에 집중하는 타입',
          },
          {
            type: 'N',
            score: 5,
            content: `감정적, 자극적인 부분에 집중 및 상상으로 결말을 추론하는 타입`,
          },
        ],
      },
      {
        which: 'SN',
        question: '당신은 아무 생각 없이 30분 이상 있을 수 있습니까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `있다`,
          },
          {
            type: 'N',
            score: 5,
            content: `“없다`,
          },
        ],
      },
      {
        which: 'TF',
        question: `드라마, 영화 시청 중 슬픈 장면이 나올 때 당신은 자주 눈물을 흘립니까?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '눈물을 흘리지 않는다',
          },
          {
            type: 'F',
            score: 5,
            content: '자주 눈물을 흘린다',
          },
        ],
      },
      {
        which: 'TF',
        question: '누군가 당신에게 선물을 한다면 당신은 무슨 감정입니까?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `왜 주는지 몰라서 당황스럽다`,
          },
          {
            type: 'F',
            score: 5,
            content: '먼저 선물에 감동한다',
          },
        ],
      },
      {
        which: 'TF',
        question: `문제 상황이 일어났을 때 당신의 상태는 어떻게 변합니까?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '이성적으로 바뀐다',
          },
          {
            type: 'F',
            score: 5,
            content: '감정적으로 바뀐다',
          },
        ],
      },
      {
        which: 'JP',
        question: '둘 중 당신은 어떤 사람에 해당합니까?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `한 가지 일을 완벽하게 끝내는 사람`,
          },
          {
            type: 'P',
            score: 5,
            content: `여러가지 일을 동시에 잘하는 사람`,
          },
        ],
      },
      {
        which: 'JP',
        question: '약속이 있는 당신은 어느 경우가 더 많습니까?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '약속 시간 보다 일찍 도착하는 경우가 많다',
          },
          {
            type: 'P',
            score: 5,
            content: '약속 시간 보다 늦는 경우가 많다',
          },
        ],
      },
      {
        which: 'JP',
        question: '당신의 여행은 어떤 스타일 입니까?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '여러 경험을 해볼 수 있는 자유로운 여행',
          },
          {
            type: 'P',
            score: 5,
            content: '가는 장소를 미리 정해 놓은 계획적인 여행',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/chatGPTCharacteristic/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/chatGPTCharacteristic/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/chatGPTCharacteristic/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/chatGPTCharacteristic/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/chatGPTCharacteristic/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/chatGPTCharacteristic/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/chatGPTCharacteristic/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/chatGPTCharacteristic/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/chatGPTCharacteristic/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/chatGPTCharacteristic/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/chatGPTCharacteristic/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/chatGPTCharacteristic/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/chatGPTCharacteristic/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/chatGPTCharacteristic/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/chatGPTCharacteristic/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/chatGPTCharacteristic/INFP.jpg',
      },
    ],
  },
  // speechHabitEng
  {
    info: {
      mainTitle: 'Frequently spoken words Test',
      subTitle: 'What do you often say?',
      mainUrl: 'speechHabitEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/speechHabitEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/speechHabitEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          'What would you do if you were hanging out with a friend and your friend wants to invite an acquaintance?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: "No problem. Let's have fun together!",
          },
          {
            type: 'I',
            score: 5,
            content: 'Suddenly? that would be a bit uncomfortable…',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What do you do when your friend calls to come out on a sweet holiday?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: "Okay! I have nothing special now! I'll get ready and go!",
          },
          {
            type: 'I',
            score: 5,
            content: 'Suddenly? Sorry, I have work to do today.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What do you hate more when you go to a gathering with a lot of people?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `A situation with awkward silence`,
          },
          {
            type: 'I',
            score: 5,
            content: `A situation where everyone pays attention through many questions`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do you do right before you fall asleep?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I just focus on sleeping without thinking`,
          },
          {
            type: 'N',
            score: 5,
            content: `I fall asleep late while thinking about this and that.`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What would you do if the movie you enjoyed watching after a long time ended with an open ending?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              "I would still enjoy it, but it's a pity that it has an open ending",
          },
          {
            type: 'N',
            score: 5,
            content: `I would imagine an open ending at will`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What would you do if your friend asked you if it would be better to break up with someone new or cut off contact and break up?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I would just say, “you can pick one not me” and wonder why my friend ask such a useless question. `,
          },
          {
            type: 'N',
            score: 5,
            content: `“I would just say “Oh, both are the worst” and think about it seriously`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What would you do if your friend were late for an appointment and make excuses?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'I would say “it is understandable” (it is okay don’t worry!)',
          },
          {
            type: 'F',
            score: 5,
            content: 'I would say “Please say sorry first.” (I might angry)',
          },
        ],
      },
      {
        which: 'TF',
        question: 'How would you explain what you know to others?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I would explain it as it is`,
          },
          {
            type: 'F',
            score: 5,
            content: 'I would explain in an easy way for others to understand.',
          },
        ],
      },
      {
        which: 'TF',
        question: `What do you expect when you share your concerns with a friend?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I look forward to empathy and resolution of the problem',
          },
          {
            type: 'F',
            score: 5,
            content: 'I want empathy and comfort in my words',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What are you more confident about?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Finishing up one thing. `,
          },
          {
            type: 'P',
            score: 5,
            content: `Doing multitasking. `,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'If you were given a task or assignment with a deadline of one month, what would you do?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'There may be additional things later, so I would finish it in advance.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'I still have plenty of time, so I would just do it step by step slowly ',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'If you come out to play while there are things to do, what would you do?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'It would bother me continuously as I might keep thinking of things to do. ',
          },
          {
            type: 'P',
            score: 5,
            content: "I've already decided anyway, so I would try to enjoy. ",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitEng/INFP.jpg',
      },
    ],
  },
  // speechHabitCN
  {
    info: {
      mainTitle: '经常说的话测试',
      subTitle: '我经常说什么话呢？',
      mainUrl: 'speechHabitCN',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/speechHabitCN-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/speechHabitCN-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'CN',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '和朋友玩的时候朋友突然问能不能叫别人一起来时我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '不介意一起玩！',
          },
          {
            type: 'I',
            score: 5,
            content: '这么突然？有点…',
          },
        ],
      },
      {
        which: 'EI',
        question: '好不容易休假了，但朋友突然叫我出门时我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '反正也没事~好呀！准备出门！',
          },
          {
            type: 'I',
            score: 5,
            content: '这么突然？今天我有事，不好意思..',
          },
        ],
      },
      {
        which: 'EI',
        question: '参加人数较多的聚会时我不喜欢的氛围是？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `所有人都处于尴尬而且静默的状态`,
          },
          {
            type: 'I',
            score: 5,
            content: `备受大家的瞩目，被问很多问题的情况`,
          },
        ],
      },
      {
        which: 'SN',
        question: '晚上睡觉时我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `睡觉的时候什么也不想，只集中于睡眠`,
          },
          {
            type: 'N',
            score: 5,
            content: `思绪连篇，很晚入睡`,
          },
        ],
      },
      {
        which: 'SN',
        question: '好久没看过这么有趣的电影了，电影的结局是开放式的话我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '好不容易看到这么有趣的电影，但结局居然是开放式的，感到好可惜',
          },
          {
            type: 'N',
            score: 5,
            content: `开放式的结局让我不由地联想起来`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '朋友突然玩起平衡问答游戏，让我从“潜水分手 VS 换乘分手”中选一时我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `휴.. 꿈이네~`,
          },
          {
            type: 'N',
            score: 5,
            content: `“一边说“要选你自己选吧”一边心想为什么会问这种没意思的问题`,
          },
        ],
      },
      {
        which: 'TF',
        question: `约会迟到很久的朋友一到就先解释自己迟到的原因时我会？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '迟到的原因挺有说服力的，没办法啦（慢慢消气）',
          },
          {
            type: 'F',
            score: 5,
            content: '不应该先道歉吗？（更生气）',
          },
        ],
      },
      {
        which: 'TF',
        question: '给对方讲解我熟知的知识时我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `准确详细地按照字典里的意思和含义来讲解`,
          },
          {
            type: 'F',
            score: 5,
            content: '尽量把意思和含义变通成让对方容易理解的内容来讲解',
          },
        ],
      },
      {
        which: 'TF',
        question: `向朋友诉说烦恼时我希望？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '同意我说的话，解决问题',
          },
          {
            type: 'F',
            score: 5,
            content: '与我产生共鸣并安慰我',
          },
        ],
      },
      {
        which: 'JP',
        question: '我更有自信的是',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `一件事情坚持到底`,
          },
          {
            type: 'P',
            score: 5,
            content: `同时进行多个事情`,
          },
        ],
      },
      {
        which: 'JP',
        question: '如果我在负责时长一个月的工作或课题时我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '即使后面会发生一些变动，也会选择提前完成',
          },
          {
            type: 'P',
            score: 5,
            content: '反正时间还很长，展开所有可能性，慢慢地推进',
          },
        ],
      },
      {
        which: 'JP',
        question: '手上有要办的事情却不得不出门和朋友们玩的时候我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '总是惦记着要做的事情',
          },
          {
            type: 'P',
            score: 5,
            content: '反正都出来了，决定先玩嗨起再说',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitCN/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitCN/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitCN/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitCN/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitCN/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitCN/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitCN/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitCN/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitCN/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitCN/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitCN/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitCN/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitCN/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitCN/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitCN/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabitCN/INFP.jpg',
      },
    ],
  },
  // loveCell
  {
    info: {
      mainTitle: '연애 세포 캐릭터 테스트',
      subTitle: '연애 세포 캐릭터로 알아보는 나의 연애 성향',
      mainUrl: 'loveCell',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/loveCell-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveCell-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '좋아하는 사람에게 내가 관심 끄는 방법은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '적극적인 표현과 질문으로 관심을 끈다',
          },
          {
            type: 'I',
            score: 5,
            content: '경청과 공감으로 관심을 끈다',
          },
        ],
      },
      {
        which: 'EI',
        question: '새로운 사람을 사귈 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '새로운 사람과 터울 없이 쉽게 친해지는 편이다',
          },
          {
            type: 'I',
            score: 5,
            content: '새로운 사람과 친해지는데 많은 시간이 걸리는 편이다',
          },
        ],
      },
      {
        which: 'EI',
        question: '연인과 놀러 온 놀이동산에 사람이 엄청 많다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `와 역시!! 사람이 많네 신난다!!`,
          },
          {
            type: 'I',
            score: 5,
            content: `와 사람 너무 많아 기빨려…ㅠ`,
          },
        ],
      },
      {
        which: 'SN',
        question: '썸 타는 이성과 연락 중 상대가 오랫동안 연락이 없다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `무슨 일 있나? 이따 오겠지 뭐~`,
          },
          {
            type: 'N',
            score: 5,
            content: `내가 무슨 말실수 했나? 혹시 내가 싫어졌나?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인과 같이 해외여행 가기로 한 날 비행기 탈 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '설렌다~ 가면 엄청 재밌겠지?',
          },
          {
            type: 'N',
            score: 5,
            content: `혹시 이 비행기가 비행 중 갑자기 추락한다면..?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인이 바람을 피웠는데 알고 보니 꿈이 였다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `휴.. 꿈이네~`,
          },
          {
            type: 'N',
            score: 5,
            content: `“괜히 기분 나빠서 연인에게 혹시 나한테 숨기는 거 없냐고 물어본다`,
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 연락하는 이성이 생겼다고 한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '이열~ 누군데? 잘 생겼어?/예쁘냐?',
          },
          {
            type: 'F',
            score: 5,
            content: '진짜? 대박! 축하해 잘돼야 할 텐데',
          },
        ],
      },
      {
        which: 'TF',
        question: '연인과 갈등 상황에 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `최대한 이성적으로 논리적으로 말한다`,
          },
          {
            type: 'F',
            score: 5,
            content: '감정이 북받쳐 할 말이많지만, 정리가 잘 안된다',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 전에 만나던 X이야기하다가 갑자기 운다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '야 갑자기 왜 울어? 걔 때문에 우는 거야?',
          },
          {
            type: 'F',
            score: 5,
            content: '야 괜찮아? 그딴 쓰레기 잊어버려 너 눈물이 아깝다',
          },
        ],
      },
      {
        which: 'JP',
        question: '새로운 일을 맡게 된 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `일단 어떻게 할지 계획부터 세운다`,
          },
          {
            type: 'P',
            score: 5,
            content: `일단 시작하고 상황을 봐서 융통성을 발휘한다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '썸 타는 이성이 갑자기 집에 놀러온다고 선언 한다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '구석구석 깨끗하게 청소한다',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 빠르게 보이는 곳만 간단하게 청소한다',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '연인과 영화를 보기로 한 날, 갑자기 연인이 다른 영화가 보고 싶다고 한다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '그냥 원래 보려고했던 영화를 보자고 한다',
          },
          {
            type: 'P',
            score: 5,
            content: '둘 다 상관 없으니 그러자고 한다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/loveCell/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/loveCell/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/loveCell/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/loveCell/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/loveCell/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/loveCell/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/loveCell/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/loveCell/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/loveCell/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/loveCell/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/loveCell/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/loveCell/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/loveCell/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/loveCell/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/loveCell/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/loveCell/INFP.jpg',
      },
    ],
  },
  // thatDiary
  {
    info: {
      mainTitle: '그 시절 일기장 테스트',
      subTitle: '초등학생 시절 나는 어떤 아이였을까?',
      mainUrl: 'thatDiary',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/thatDiary-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/thatDiary-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '오늘은 학교에 전학생이 왔다! 전학생이 내 옆자리에 앉는다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '오 새로운 친구 생겼잖아! 어디서 왔어? 너 딱지치기 잘해??',
          },
          {
            type: 'I',
            score: 5,
            content: '두근두근 어떡하지 말을 한번 걸어볼까? 어색할 것 같은데..',
          },
        ],
      },
      {
        which: 'EI',
        question: '짝꿍이 된 전학생이 교과서가 없어 곤란해 보인다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '교과서 안 가져왔구나?? 말을 하지 그랬어~ 내꺼 보여줄게!',
          },
          {
            type: 'I',
            score: 5,
            content: '교과서를 옆으로 슬금슬금 밀어 같이 보자고 한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '수업 후 쉬는 시간 나의 모습은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `친구들과 우당탕탕 모여 말뚝박기나 공기놀이를 한다`,
          },
          {
            type: 'I',
            score: 5,
            content: `자리에 앉아 짝꿍과 실뜨기 놀이를 한다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '부모님께서 전학 온 친구는 어떻냐고 묻는다면 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `키가 크고 뽀글 머리였어 근데 오늘 교과서를 안 가져왔던데?`,
          },
          {
            type: 'N',
            score: 5,
            content: `되게 착한 것 같아! 오늘은 나한테 지우개도 빌려줬다?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '학교에서 색칠 수업을 한다면 어느 쪽이 더 하고 싶을까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '그려진 그림에 색칠하는 수업',
          },
          {
            type: 'N',
            score: 5,
            content: `원하는 그림을 직접 그려서 색칠하는 수업`,
          },
        ],
      },
      {
        which: 'SN',
        question: '동생이 학교 앞에서 파는 병아리를 사왔다면 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `병아리 귀엽네 잘 키워 봐~`,
          },
          {
            type: 'N',
            score: 5,
            content: `엄마한테 혼날 거 같은데.. 근데 얘네 아무래도 배고픈가 봐 뭘 줘야 하지..? 이러다가 커서 닭 되는 거 아냐??`,
          },
        ],
      },
      {
        which: 'TF',
        question: `찰흙으로 만들기 수업 중 짝꿍이 망쳤다며 울고 있다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜 울어~? 시간 많은데 천천히 다시 하면 되지',
          },
          {
            type: 'F',
            score: 5,
            content: '울지마ㅠ 괜찮아 다시 해보자! 내가 도와줄게!',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '짝꿍이 받아쓰기 시험을 잘 봐서 칭찬을 받았다고 한다 나의 반응은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `몇 점 맞았는데? 오 나보다 잘 봤네?`,
          },
          {
            type: 'F',
            score: 5,
            content: '우와 좋겠다!! 나도 잘 봐서 칭찬받고 싶다~',
          },
        ],
      },
      {
        which: 'TF',
        question: `수련회를 가는데 같이 다니기로 한 친구가 못 간다고 하면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜 못 가? 그럼 다른 친구한테 같이 다니자고 물어봐야겠다',
          },
          {
            type: 'F',
            score: 5,
            content:
              '절대 절대 못 가는 거야? 난 너랑 같이 가고 싶었는데 아쉽다ㅠ',
          },
        ],
      },
      {
        which: 'JP',
        question: '내일 수업을 위해 선생님이 준비물을 가져오라고 한다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `하교 후 준비물을 미리 준비해 가방에 챙겨 둔다`,
          },
          {
            type: 'P',
            score: 5,
            content: `완전 까먹고 있다가 학교 가는 길에 생각나서 부랴부랴 산다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '심부름 가는 길에 재밌어 보이는 오락기를 발견한다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '심부름을 후다닥 먼저 하고 발견했던 오락을 하러 간다',
          },
          {
            type: 'P',
            score: 5,
            content: '발견 즉시 앉아서 오락을 하다가 나중에 심부름을 마저 한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '오늘은 방학 마지막 날! 방학 숙제가 일기장 쓰기였다면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '난 매일매일 일기를 써놨지~ 방학 숙제는 오늘 거만 쓰면 끝!!',
          },
          {
            type: 'P',
            score: 5,
            content: '아 맞다 깜빡했네 엄청 밀려 있는데 언제 다 쓰지 비상이다!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/thatDiary/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/thatDiary/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/thatDiary/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/thatDiary/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/thatDiary/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/thatDiary/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/thatDiary/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/thatDiary/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/thatDiary/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/thatDiary/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/thatDiary/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/thatDiary/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/thatDiary/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/thatDiary/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/thatDiary/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/thatDiary/INFP.jpg',
      },
    ],
  },
  // newYearSemester
  {
    info: {
      mainTitle: '자주 하는 말 테스트',
      subTitle: '내가 자주 하는 말은 어떤 말 일까?',
      mainUrl: 'newYearSemester',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/newYearSemester-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/newYearSemester-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          '드디어 새 학년 새 학기가 시작된다!! 반 배정을 봤는데 헉 친한 친구가 없어…!!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '떨리지만 괜찮아. 금방 친구가 생기겠지 ',
          },
          {
            type: 'I',
            score: 5,
            content: '벌써 1년이 걱정된다ㅠㅠ',
          },
        ],
      },
      {
        which: 'EI',
        question: '긴장되고 설레는 마음으로 들어간 교실! 어디에 앉을까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친해지고 싶게 생긴 친구 옆자리에 슬쩍~',
          },
          {
            type: 'I',
            score: 5,
            content: '조용하고 구석진 벽쪽자리 찜',
          },
        ],
      },
      {
        which: 'EI',
        question: '옆자리에 앉은 짝이랑 친해지고 싶은데…',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `내가 먼저 말을 걸어본다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `짝이 말 걸어 줄 때까지 기다린다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '두근거리는 등교 전날에 내가 한 생각은',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `내일 수업 안 하겠지?`,
          },
          {
            type: 'N',
            score: 5,
            content: `내일 수업을 제대로 하려나? 만약에 안 한다고 그러면 바로 집에 가야 되나 아니면 새로 사귄 친구랑 놀다 가는 게 좋을까`,
          },
        ],
      },
      {
        which: 'SN',
        question: '새 학기 첫날인데 1교시부터 수업을 한다고 한다!!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '아 미쳤나 봐 하기 싫어',
          },
          {
            type: 'N',
            score: 5,
            content: `도대체 왜 첫날부터 수업을 하지..? 굳이 벌써부터 해야 되나? 내용이 머리에 들어올까?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '이상하게 생긴 반찬이 급식으로 나왔다. 엑…뭐야 이게…? 괴식아닌가…?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `안 먹을래`,
          },
          {
            type: 'N',
            score: 5,
            content: `근데 일단 도전!! 맛있을 수도 있잖아`,
          },
        ],
      },
      {
        which: 'TF',
        question: `옆 짝꿍이 종이를 자르다가 손이 베였다!!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '으악!!! 휴지 어딨어?! 야 보건실 가자!!',
          },
          {
            type: 'F',
            score: 5,
            content: '악!!! 아프겠다!!! 괜찮아?? 어떡해!!!',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '쉬는 시간. 아직도 새로운 반이 익숙해지지 않아서 옆반에 있는 친한 친구를 찾아갔다. 어떤 말이 더 듣고 싶어?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `나 그 반에 같은 학원 다니는 친구 있는데 걔 소개 받을래? 너랑 성격 잘 맞아서 금방 친해질 듯`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '나도 적응이 안돼ㅠㅠ 그래도 우리 힘내자. 비록 반이 떨어졌지만 너랑 나는 계속 친하게 지내는 거야!!',
          },
        ],
      },
      {
        which: 'TF',
        question: `주변에 앉은 친구들이랑 수다를 떨게 됐다`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '그거 먹어 봤어? 뭐 좋아해? 질문을 하는 나',
          },
          {
            type: 'F',
            score: 5,
            content: '우와!! 맞아맞아!! 리액션을 하는 나',
          },
        ],
      },
      {
        which: 'JP',
        question: '짝꿍이랑 학교 끝나고 분식집에 가기로 했다',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `참새분식은 좀 달달한 편이고 까치분식은 매운편인데 어디가 좋아? 혹시 학원가? 몇 시까지 가야 돼? `,
          },
          {
            type: 'P',
            score: 5,
            content: `맛있겠다! 뭐 먹을지 메뉴는 가서 정하자~~`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          '오늘 수업 끝!! 근데 갑자기 짝꿍이 오늘은 분식 못 먹을 것 같다고 다음에 먹자고 한다.',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '왜, 무슨 일 있어? 아까는 된다며…',
          },
          {
            type: 'P',
            score: 5,
            content: '아쉽다 다음엔 꼭 같이 먹자!',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '집에 와서 옆반 친구랑 통화를 했다. 친구가 오늘 친구 좀 사귀었냐고 물었다',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '내일은 애들 줄 간식이라도 가져갈까 계획 중이야',
          },
          {
            type: 'P',
            score: 5,
            content: '몰라… 뭐 어떻게든 되겠지',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/newYearSemester/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/newYearSemester/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/newYearSemester/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/newYearSemester/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/newYearSemester/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/newYearSemester/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/newYearSemester/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/newYearSemester/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/newYearSemester/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/newYearSemester/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/newYearSemester/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/newYearSemester/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/newYearSemester/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/newYearSemester/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/newYearSemester/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/newYearSemester/INFP.jpg',
      },
    ],
  },
  // speechHabit
  {
    info: {
      mainTitle: '자주 하는 말 테스트',
      subTitle: '내가 자주 하는 말은 어떤 말 일까?',
      mainUrl: 'speechHabit',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/speechHabit-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/speechHabit-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '친구와 놀던 중 친구가 지인을 불러도 되냐는 말에 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '노 상관! 같이 놀자!',
          },
          {
            type: 'I',
            score: 5,
            content: '갑자기? 그건 좀..',
          },
        ],
      },
      {
        which: 'EI',
        question: '간만에 찾아온 꿀 같은 휴일 친구가 나오라고 연락이 온다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '별일 없으니까~ 콜! 준비하고 나갈게!',
          },
          {
            type: 'I',
            score: 5,
            content: '갑자기? 오늘 할 일이 있어서 미안..',
          },
        ],
      },
      {
        which: 'EI',
        question: '많은 사람이 있는 모임에 갔을 때 내가 더 싫은 상황은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `모두가 느끼는 어색한 침묵이 흐르고 있는 상황`,
          },
          {
            type: 'I',
            score: 5,
            content: `모두에게 주목받은 상태로 많은 질문을 받는 상황`,
          },
        ],
      },
      {
        which: 'SN',
        question: '밤에 잘 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `자는데 생각은 무슨.. 자는 데 집중한다`,
          },
          {
            type: 'N',
            score: 5,
            content: `다양한 생각을 하다가 늦게 잠이 든다 `,
          },
        ],
      },
      {
        which: 'SN',
        question: '오랜만에 재밌게 본 영화가 열린 결말로 끝이 났다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '나름 재밌게 본 영화가 찝찝한 열린 결말이라 아쉽다',
          },
          {
            type: 'N',
            score: 5,
            content: `열린 결말을 내 마음대로 상상해 본다`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '친구가 갑자기 “잠수 이별 VS 환승 이별” 고르라면서 밸런스 질문을 던진다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `“그런 거 너나 골라” 라고 한다`,
          },
          {
            type: 'N',
            score: 5,
            content: `“아 둘 다 최악인데?” 하면서 진지하게 생각해 본다`,
          },
        ],
      },
      {
        which: 'TF',
        question: `약속 시간에 많이 늦은 친구가 도착하자마자 늦은 이유부터 설명한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '늦은 이유가 타당하네 어쩔 수 없었겠다 (화가 누그러진다)',
          },
          {
            type: 'F',
            score: 5,
            content: '아니 그래도 사과가 먼저 아닌가? (더 화가 난다)',
          },
        ],
      },
      {
        which: 'TF',
        question: '내가 아는 지식을 상대방에게 설명할 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `사전적 뜻과 의미를 정확하게 설명해 준다`,
          },
          {
            type: 'F',
            score: 5,
            content: '뜻과 의미를 상대방이 알아듣기 쉽게 바꿔서 설명해 준다',
          },
        ],
      },
      {
        which: 'TF',
        question: `내가 친구에게 고민 상담을 할 때 원하는 것은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '내 말에 동의나, 문제 해결을 원한다',
          },
          {
            type: 'F',
            score: 5,
            content: '내 말에 공감과 위로를 원한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 더 자신 있는 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `한 가지 일을 끝까지 하기`,
          },
          {
            type: 'P',
            score: 5,
            content: `여러가지 일을 동시에 하기`,
          },
        ],
      },
      {
        which: 'JP',
        question: '마감 기한이 한 달인 업무나, 과제가 주어진다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '나중에 추가 사항이 생기더라도, 일단 미리 끝내 놓는다',
          },
          {
            type: 'P',
            score: 5,
            content: '아직 시간이 많으니, 가능성을 열어놓고 천천히 진행한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '해야 할 일이 있는데 어쩔 수 없이 놀러 나왔을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '자꾸만 할 일들이 떠올라 신경 쓰인다',
          },
          {
            type: 'P',
            score: 5,
            content: '이미 놀기로 한 거 일단 신나게 놀고 본다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabit/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabit/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabit/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabit/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabit/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabit/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabit/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabit/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabit/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabit/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabit/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabit/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabit/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabit/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabit/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/speechHabit/INFP.jpg',
      },
    ],
  },
  // mbtiFeaturesEng
  {
    info: {
      mainTitle: 'MBTI Characteristics Test_Dating',
      subTitle: 'What are my love characteristics and love index? | MBTI Test',
      mainUrl: 'mbtiFeaturesEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/mbtiFeaturesEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/mbtiFeaturesEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Eng',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question:
          'What can be your reaction after having fun with your lover on the weekend?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'This weekend has been really great! I was completely refreshed!',
          },
          {
            type: 'I',
            score: 5,
            content: 'This weekend has been also fun! I will rest at home now!',
          },
        ],
      },
      {
        which: 'EI',
        question: 'If you attended in a crowded gathering, what would you do?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'I would be enthusiastic and express my opinion confidently',
          },
          {
            type: 'I',
            score: 5,
            content: "I would quietly sympathize with the other person's words",
          },
        ],
      },
      {
        which: 'EI',
        question: 'Which date do you prefer?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `An exciting outdoor date`,
          },
          {
            type: 'I',
            score: 5,
            content: `A quiet indoor date`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What would you do if you lost contact with your lover while out drinking with other friends?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `How dare you not answer me!`,
          },
          {
            type: 'N',
            score: 5,
            content: `Are you sure you don't hang out with other girls?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'The day before you go on a trip with your lover, what will you do?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I want to go quickly so I go to bed early',
          },
          {
            type: 'N',
            score: 5,
            content: `I fall asleep imagining the trip`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'Which conversation do you prefer?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `A conversation about current trend or interests`,
          },
          {
            type: 'N',
            score: 5,
            content: `Future-oriented conversations that require imagination`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What do you do when you are in a conflict with your lover?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              "It's frustrating, but I speak calmly to resolve the conflict rationally",
          },
          {
            type: 'F',
            score: 5,
            content:
              "I have a lot to say, but I can't come to my senses because my heart is hurt",
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What would you do if your lover suddenly confessed his/her troubles with a serious face?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I would listen to the end and try to solve the problem`,
          },
          {
            type: 'F',
            score: 5,
            content: 'I would empathize with my lover and listen sincerely',
          },
        ],
      },
      {
        which: 'TF',
        question: `What would you do if your lover dropped a treasured item and broke it?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'How did you drop it? Oh, sorry. Why didn’t you be more careful?',
          },
          {
            type: 'F',
            score: 5,
            content: "Oh, sorry. It's something you cherished",
          },
        ],
      },
      {
        which: 'JP',
        question: 'When you date, how do you do?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I show up ahead of time`,
          },
          {
            type: 'P',
            score: 5,
            content: `I am often late for appointments`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'Before dating your lover, what do you prepare?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I decide in detail where and what we will do',
          },
          {
            type: 'P',
            score: 5,
            content: 'I only decide the place and time',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'If you were to write a letter to your lover, what would you write?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I think about what to write in my head and write it down',
          },
          {
            type: 'P',
            score: 5,
            content: 'I sit down and write whatever comes to mind on the spot',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeaturesEng/INFP.jpg',
      },
    ],
  },
  // mzScore
  {
    info: {
      mainTitle: 'MZ 수치 테스트 | 당신의 MZ력은?',
      subTitle: '캐릭터로 보는 나의 MZ 수치는?',
      mainUrl: 'mzScore',
      scoreType: 'numberScoring',
      mainImage: 'https://images.ktestone.com/introImages/mzScore-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/mzScore-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'etc',
    },
    questions: [
      {
        question: `인터뷰에서 중꺾마(중요한 것은 꺾이지 않는 마음)를 처음으로 말 한 사람은?`,
        answers: [
          {
            type: '',
            score: 0,
            content: '국가대표 축구선수 조규성',
          },
          {
            type: '',
            score: 0,
            content: '롤 프로게이머 이상혁(Faker)',
          },
          {
            type: '',
            score: 1,
            content: '롤 프로게이머 김혁규(Deft)',
          },
          {
            type: '',
            score: 0,
            content: '국가대표 축구선수 나상호',
          },
        ],
      },
      {
        question: `‘모에모에 큥’과 연관된 단어와 인물은?`,
        answers: [
          {
            type: '',
            score: 0,
            content: '렌고쿠 쿄주로 (귀멸의 칼날) – 우마이! (おいしい)',
          },
          {
            type: '',
            score: 0,
            content: '토아 (유투버) - 오하요우데얀스 (おはようでやんす)',
          },
          {
            type: '',
            score: 1,
            content: '호스트 다나카상 (유투버) - 오이시쿠나레 (美味しくなれ)',
          },
          {
            type: '',
            score: 0,
            content: '니코 (러브 라이브!) – 니코니코니! (にっこにっこに)',
          },
        ],
      },
      {
        question: `신조어 ‘스불재’는 무엇의 줄임 말일까?`,
        answers: [
          {
            type: '',
            score: 0,
            content: '스타성 불타는 재능',
          },
          {
            type: '',
            score: 0,
            content: '스케줄 불만족시 재수정',
          },
          {
            type: '',
            score: 1,
            content: '스스로 불러온 재앙',
          },
          {
            type: '',
            score: 0,
            content: '스근하게 불맛 내는 재료',
          },
        ],
      },
      {
        question: `“내일 봬요 누나” (내봬누)가 나온 연애 프로그램은?`,
        answers: [
          {
            type: '',
            score: 0,
            content: '하트시그널 시즌3',
          },
          {
            type: '',
            score: 0,
            content: '나는솔로',
          },
          {
            type: '',
            score: 1,
            content: '환승연애 시즌2',
          },
          {
            type: '',
            score: 0,
            content: '솔로지옥 시즌2',
          },
        ],
      },
      {
        question: `신조어 “쿠쿠루삥뽕”을 사용할 수 있는 상황은?`,
        answers: [
          {
            type: '',
            score: 0,
            content: '연인을 그윽하게 바라보는 상황',
          },
          {
            type: '',
            score: 0,
            content: '친구가 연인과 이별한 상황',
          },
          {
            type: '',
            score: 1,
            content: '친구와 내기에서 이긴 상황',
          },
          {
            type: '',
            score: 0,
            content: '맛있는 음식이 눈앞에 있는 상황',
          },
        ],
      },
      {
        question: `신조어 ‘저메추’와 관련된 단어는?`,
        answers: [
          {
            type: '',
            score: 0,
            content: '목걸이',
          },
          {
            type: '',
            score: 0,
            content: '세탁기',
          },
          {
            type: '',
            score: 1,
            content: '떡볶이',
          },
          {
            type: '',
            score: 0,
            content: '가을',
          },
        ],
      },
      {
        question: `열 받다, 킹받네, KG받네 와 같은 뜻으로 사용하는 신조어는?`,
        answers: [
          {
            type: '',
            score: 0,
            content: '쿙받네',
          },
          {
            type: '',
            score: 0,
            content: '킹G받네',
          },
          {
            type: '',
            score: 1,
            content: '왕위 계승',
          },
          {
            type: '',
            score: 0,
            content: 'H워얼V',
          },
        ],
      },
      {
        question: `“하남자 특”을 유행시킨 유투버는?`,
        answers: [
          {
            type: '',
            score: 1,
            content: '침착맨',
          },
          {
            type: '',
            score: 0,
            content: '다나카상',
          },
          {
            type: '',
            score: 0,
            content: '숏박스',
          },
          {
            type: '',
            score: 0,
            content: '피식대학',
          },
        ],
      },
      {
        question: `줄임말 신조어 “오뱅알”의 뜻은?`,
        answers: [
          {
            type: '',
            score: 0,
            content: '오일 뱅크 알바',
          },
          {
            type: '',
            score: 0,
            content: '오늘의 방송 알림',
          },
          {
            type: '',
            score: 1,
            content: '오늘 방송 알찼다',
          },
          {
            type: '',
            score: 0,
            content: '오늘의 비행기 특가 알림',
          },
        ],
      },
      {
        question: `다음 중 ‘예상치 못한 어려운 일도 긍정적으로 보자’는 의미의 신조어는?`,
        answers: [
          {
            type: '',
            score: 0,
            content: '드가자!',
          },
          {
            type: '',
            score: 0,
            content: '이겨내!',
          },
          {
            type: '',
            score: 1,
            content: '오히려 좋아',
          },
          {
            type: '',
            score: 0,
            content: '오케이 가자!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'vldrjvmflstptm',
        desc: ``,
        query: 'vldrjvmflstptm',
        img_src:
          'https://images.ktestone.com/resultImages/mzScore/vldrjvmflstptm.jpg',
      },
      {
        type: 'wjfadmsRhseo',
        desc: ``,
        query: 'wjfadmsRhseo',
        img_src:
          'https://images.ktestone.com/resultImages/mzScore/wjfadmsRhseo.jpg',
      },
      {
        type: 'shfurgksms',
        desc: ``,
        query: 'shfurgksms',
        img_src:
          'https://images.ktestone.com/resultImages/mzScore/shfurgksms.jpg',
      },
      {
        type: 'qht',
        desc: ``,
        query: 'qht',
        img_src: 'https://images.ktestone.com/resultImages/mzScore/qht.jpg',
      },
      {
        type: 'dnwnchlrkd',
        desc: ``,
        query: 'dnwnchlrkd',
        img_src:
          'https://images.ktestone.com/resultImages/mzScore/dnwnchlrkd.jpg',
      },
    ],
  },
  // bondeeCN
  {
    info: {
      mainTitle: 'Bondee性格测试',
      subTitle: '通过Bondee人物来看我的性格 | bondee test',
      mainUrl: 'bondeeCN',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/bondeeCN-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/bondeeCN-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'CN',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '和朋友一起散步时遇到朋友认识的人的话我会',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '发挥自己的亲和力，一起聊天',
          },
          {
            type: 'I',
            score: 5,
            content: '简短地问候，然后在朋友身边听他们的对话',
          },
        ],
      },
      {
        which: 'EI',
        question: '我更偏爱的沟通方式是？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '直接见面或打电话沟通',
          },
          {
            type: 'I',
            score: 5,
            content: '使用kakao短信等软件沟通',
          },
        ],
      },
      {
        which: 'EI',
        question: '好不容易能休息了！设置心情状态时我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `好不容易到了休息的日子，想和我玩的请举手！`,
          },
          {
            type: 'I',
            score: 5,
            content: `自我充电中，联系不到我`,
          },
        ],
      },
      {
        which: 'SN',
        question: '游戏中装潢家园的话我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `以我之前想要的装修风格现实地装潢家园`,
          },
          {
            type: 'N',
            score: 5,
            content: `现实生活中不可能的梦幻感觉来装潢`,
          },
        ],
      },
      {
        which: 'SN',
        question: '游戏中打造角色时我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '赋有我独特风格的现实版角色',
          },
          {
            type: 'N',
            score: 5,
            content: `游戏中像主人公一般！既勇敢又梦幻的角色`,
          },
        ],
      },
      {
        which: 'SN',
        question: '朋友说虚拟现实游戏过于现实，感到很害怕时，我的反应是？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `只是游戏而已嘛`,
          },
          {
            type: 'N',
            score: 5,
            content: `未来也许会迎来无法分离游戏与现实的世界呢`,
          },
        ],
      },
      {
        which: 'TF',
        question: `发表PT之后朋友说“感觉你好像没有很努力，看来你很有才呀”时我会？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '嘿嘿…！果然我很有才',
          },
          {
            type: 'F',
            score: 5,
            content: '我明明很努力地准备了，为什么会这么想呢？TT',
          },
        ],
      },
      {
        which: 'TF',
        question: '我给对方表示自己关注的方式是？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `经常提问，主动帮助对方`,
          },
          {
            type: 'F',
            score: 5,
            content: '迎合对方，倾听对方的话语',
          },
        ],
      },
      {
        which: 'TF',
        question: `情况极其恶劣的环境下成功完成某事时我想听到的表扬是？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '哇，你居然能成功？到底是怎么做到的呀？',
          },
          {
            type: 'F',
            score: 5,
            content: '哇，果然不出所料~肯定很累，辛苦啦',
          },
        ],
      },
      {
        which: 'JP',
        question: '游戏中装修家园时我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `提前计划好家具、色调、氛围等等，然后再开始装修`,
          },
          {
            type: 'P',
            score: 5,
            content: `展开所有可能性，随意装修家园`,
          },
        ],
      },
      {
        which: 'JP',
        question: '突然想吃某个食物的时候我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '认真地考虑，不属于消费计划中，所以尽量忍一忍',
          },
          {
            type: 'P',
            score: 5,
            content: '想吃什么当然要吃！立刻点餐',
          },
        ],
      },
      {
        which: 'JP',
        question: '准备和朋友们的约会时我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '经常准备好了以后还有很多剩余时间',
          },
          {
            type: 'P',
            score: 5,
            content: '经常过了约会时间也还在准备',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/bondeeCN/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/bondeeCN/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/bondeeCN/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/bondeeCN/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/bondeeCN/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/bondeeCN/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/bondeeCN/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/bondeeCN/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/bondeeCN/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/bondeeCN/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/bondeeCN/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/bondeeCN/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/bondeeCN/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/bondeeCN/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/bondeeCN/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/bondeeCN/INFP.jpg',
      },
    ],
  },
  // bondeeEng
  {
    info: {
      mainTitle: 'Bondee Personality Test',
      subTitle:
        'What is my personality through the Bondi characters? | bondee test',
      mainUrl: 'bondeeEng',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/bondeeEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/bondeeEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          'What would you do if you met a friend of your friend while walking down the street?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I just use my sociability to talk together',
          },
          {
            type: 'I',
            score: 5,
            content:
              'I just say “hello” and listen to what my friend is talking about',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What is your preferred method of communication?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I communicate in person or over the phone',
          },
          {
            type: 'I',
            score: 5,
            content:
              'I communicate using messengers such as KakaoTalk text messages',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What if you set a status message on your day off after a long time, what would you do?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I'm taking a break today after a long time. Who wants to play with me?`,
          },
          {
            type: 'I',
            score: 5,
            content: `I can't reply. I am on my break`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'If you do house decoration in game, what would you do?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I would like to decorate a realistic house with my own interior`,
          },
          {
            type: 'N',
            score: 5,
            content: `I would like to decorate my house fantastically`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'If you create a character in a game, what would you do?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'I would create a character that reflects reality with my own unique sensibility',
          },
          {
            type: 'N',
            score: 5,
            content: `I would make a bold and fantasy character like the main character in the game`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'If your friend says that virtual reality games are too realistic and scary, what would you say?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `It's just a game`,
          },
          {
            type: 'N',
            score: 5,
            content: `In the future, there may come a world where games and reality cannot be distinguished`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What would you do if your friend said, “You have a lot of talent,\nbut I thought you didn’t prepare much for it” after your Power Point presentation?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Yeah, I guess I have a talent, right?',
          },
          {
            type: 'F',
            score: 5,
            content: 'I prepared a lot, but why do you think so?',
          },
        ],
      },
      {
        which: 'TF',
        question: 'How do you express interest in the other person?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I often ask questions or help`,
          },
          {
            type: 'F',
            score: 5,
            content: 'I respond and listen well',
          },
        ],
      },
      {
        which: 'TF',
        question: `What praise do you want to hear when you've done something great in a very difficult situation?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Wow, you made it! How could you do that?',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Wow, I believed it too ~ It must have been hard, but you worked hard!',
          },
        ],
      },
      {
        which: 'JP',
        question: 'If you decorate your house in the game What will you do?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I start planning and decorating everything in advance, including the furniture, colors, and atmosphere `,
          },
          {
            type: 'P',
            score: 5,
            content: `I keep open to possibilities and decorate as I feel`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What do you do when you suddenly think of something you want to eat?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I would put up with it because it is not planned spending',
          },
          {
            type: 'P',
            score: 5,
            content: 'I have to eat what I want to eat! Order now',
          },
        ],
      },
      {
        which: 'JP',
        question: 'How do you prepare for an appointment with your friends?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I prepare everything in advance',
          },
          {
            type: 'P',
            score: 5,
            content: 'I am often in a last minute rush',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/bondeeEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/bondeeEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/bondeeEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/bondeeEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/bondeeEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/bondeeEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/bondeeEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/bondeeEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/bondeeEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/bondeeEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/bondeeEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/bondeeEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/bondeeEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/bondeeEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/bondeeEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/bondeeEng/INFP.jpg',
      },
    ],
  },
  // loveFlameEng
  {
    info: {
      mainTitle: 'Love flame test',
      subTitle: 'What kind of relationship do you see through fireworks?',
      mainUrl: 'loveFlameEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveFlameEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveFlameEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Eng',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question:
          'What would you do if you suddenly met someone you like at an event?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I would try to get to know the person actively.',
          },
          {
            type: 'I',
            score: 5,
            content:
              'I will slowly try to seize the chance to chat with the person.',
          },
        ],
      },
      {
        which: 'EI',
        question: "What do you say when you've just started dating someone?",
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'I say everything about myself while overdoing TMI(too much information).',
          },
          {
            type: 'I',
            score: 5,
            content:
              "I don't talk much about myself and only answer questions that are asked",
          },
        ],
      },
      {
        which: 'EI',
        question:
          'How would you react if your lover suggested hanging out with their friends early in the relationship?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Even if it's a little sudden, I want to say "Yes, why not".`,
          },
          {
            type: 'I',
            score: 5,
            content: `I would refuse because it is sudden and somewhat burdensome`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'How would you react if your lover suddenly talked about the future ideally?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I would like that, but I think it's impossible in reality`,
          },
          {
            type: 'N',
            score: 5,
            content: `I would share my ideal thoughts`,
          },
        ],
      },
      {
        which: 'SN',
        question: `What can be your reaction when your lover asks questions like "what if"?`,
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I will say “Don't worry, it won't happen”.`,
          },
          {
            type: 'N',
            score: 5,
            content: `I will seriously think about it and answer it`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'If you were to make food for lover, how would you prepare it?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I would cook according to the recipe.`,
          },
          {
            type: 'N',
            score: 5,
            content: `I would cook with all my heart without weighing`,
          },
        ],
      },
      {
        which: 'TF',
        question: `If your lover lied to you, what would be your reaction?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'I would be so disappointed, but first I would ask why my lover lied',
          },
          {
            type: 'F',
            score: 5,
            content: 'I would be very angry that my lover lied to me',
          },
        ],
      },
      {
        which: 'TF',
        question: 'What would you do if your lover was very angry with you?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I would apologize for any mistakes and explain later`,
          },
          {
            type: 'F',
            score: 5,
            content: 'I would apologize and try to resolve it',
          },
        ],
      },
      {
        which: 'TF',
        question: `What could be your intentions if you asked your love for some alone time?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'I just needed time to sort out the relationship because I had been thinking too emotionally.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'I had already sorted out the relationship, but I was trying to give my lover a chance to think.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What will you do just after breaking up with my lover?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I'll do anything to get rid of the blues, but I'll hold back in the end`,
          },
          {
            type: 'P',
            score: 5,
            content: `To get rid of depressive feeling, I’ll change my hairstyle or buy things`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'What will you do when you feel better after breaking up?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I will erase my plans with my ex and make a new plan',
          },
          {
            type: 'P',
            score: 5,
            content: 'I will be ready to meet someone new',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What would you do the day before a blind date with someone new?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I would think about what to wear, hairstyle, etc.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'I would get enough sleep first because I always decide on the day',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameEng/INFP.jpg',
      },
    ],
  },
  // mbtiFeatures
  {
    info: {
      mainTitle: 'MBTI 특징 테스트 연애편',
      subTitle: '나의 연애 특징과 연애력 지수는? | MBTI 테스트 | 연애력 테스트',
      mainUrl: 'mbtiFeatures',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/mbtiFeatures-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/mbtiFeatures-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '주말에 연인과 신나게 놀고 나서 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '이번 주말도 알찼다! 충전 완료!',
          },
          {
            type: 'I',
            score: 5,
            content: '이번 주말도 재밌었다! 이제 집에서 푹 쉬어야지!',
          },
        ],
      },
      {
        which: 'EI',
        question: '평소 사람들이 많은 모임에서 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '열정적이고 내 의견을 자신 있게 말한다',
          },
          {
            type: 'I',
            score: 5,
            content: '조용하고 상대방 말에 공감을 잘한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '내가 선호하는 데이트는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `신나는 실외 데이트`,
          },
          {
            type: 'I',
            score: 5,
            content: `조용한 실내 데이트`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구들과 술자리 약속에 간 연인이 연락 두절 상태라면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `감히 내 연락을 안 받아?!`,
          },
          {
            type: 'N',
            score: 5,
            content: `설마 다른 이성이랑 놀고 있는 건 아니겠지?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인과 여행가기 전날 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '빨리 가고 싶어서 일찍 잔다',
          },
          {
            type: 'N',
            score: 5,
            content: `도착해서 신나게 노는 상상을 하다가 잔다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 더 선호하는 대화는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `현재 유행하는 것이나, 관심사에 관해 이야기하는 대화`,
          },
          {
            type: 'N',
            score: 5,
            content: `상상력이 필요한 미래지향적인 대화`,
          },
        ],
      },
      {
        which: 'TF',
        question: `연인과 갈등 상황에 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '답답하지만 이성적으로 갈등을 풀어가려고 침착하게 말한다',
          },
          {
            type: 'F',
            score: 5,
            content: '할 말은 많은데 감정이 먼저 상해서 생각 정리가 잘 안된다',
          },
        ],
      },
      {
        which: 'TF',
        question: '활기차던 연인이 갑자기 심각한 얼굴로 고민을 털어 놓는다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `연인의 말을 끝까지 듣고, 문제를 해결해주려 한다`,
          },
          {
            type: 'F',
            score: 5,
            content: '연인에게 감정이입을 하면서, 진지하게 듣고 공감한다',
          },
        ],
      },
      {
        which: 'TF',
        question: `연인이 아끼는 물건을 떨어뜨려서 부서졌다고 한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '어쩌다가 떨어진 거야? 어떡해 조심하지ㅠ',
          },
          {
            type: 'F',
            score: 5,
            content: '그거 너가 아끼던 거였는데ㅠ 어떡해ㅠ',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 데이트 약속 시간에 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `약속 시간보다 미리 나와 있는다`,
          },
          {
            type: 'P',
            score: 5,
            content: `약속 시간에 자주 늦는다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 데이트하기 전 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '약속 장소와 시간은 물론 어디서 무엇을 할 건지 세세하게 정한다',
          },
          {
            type: 'P',
            score: 5,
            content: '약속 장소와 시간 정도만 정해 놓고 만난다',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인에게 편지를 쓴다면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '어떻게 적을지 미리 생각해 놓고 옮겨 적는다',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 앉아서 그 자리에서 생각나는 대로 적는다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeatures/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeatures/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeatures/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeatures/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeatures/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeatures/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeatures/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeatures/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeatures/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeatures/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeatures/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeatures/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeatures/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeatures/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeatures/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/mbtiFeatures/INFP.jpg',
      },
    ],
  },
  // loveFlameCN
  {
    info: {
      mainTitle: '恋爰火花测试',
      subTitle: '通过火花了解我的恋爱倾向是？',
      mainUrl: 'loveFlameCN',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveFlameCN-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveFlameCN-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'CN',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '遇到心仪的异性时我更倾向于？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '主动接近并尝试了解对方',
          },
          {
            type: 'I',
            score: 5,
            content: '在周围徘徊，慢慢地表示自己的好感',
          },
        ],
      },
      {
        which: 'EI',
        question: '与心仪的异性搞暧昧时我的聊天风格是？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '胡说八道TMI型，说有关自己的一切',
          },
          {
            type: 'I',
            score: 5,
            content: '先不说有关自己的话题，只回答对方问的问题',
          },
        ],
      },
      {
        which: 'EI',
        question: '开始恋爱之后，恋人提议和他/她的朋友一起见面时我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `虽然有点突然但反而觉得很不错！果断答应`,
          },
          {
            type: 'I',
            score: 5,
            content: `有点突然也感到有压力，所以拒绝`,
          },
        ],
      },
      {
        which: 'SN',
        question: '恋人突然理想地聊起有关我们的未来时我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `能实现就好了，但认为现实上是不可能的`,
          },
          {
            type: 'N',
            score: 5,
            content: `自己也说出曾经想象过的理想未来，和恋人一起聊`,
          },
        ],
      },
      {
        which: 'SN',
        question: '恋人说着“如果~”问我尚未发生的事情时我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '告诉对方还没有发生的事情不要担心',
          },
          {
            type: 'N',
            score: 5,
            content: `认真地思考之后回答`,
          },
        ],
      },
      {
        which: 'SN',
        question: '为恋人做饭时我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `精确地调好比率，按比率烹饪`,
          },
          {
            type: 'N',
            score: 5,
            content: `心最重要！投入真心，按自己的直觉烹饪`,
          },
        ],
      },
      {
        which: 'TF',
        question: `恋人对我说谎时我会？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '虽然很失望，但会先问对方为什么要说谎',
          },
          {
            type: 'F',
            score: 5,
            content: '恋人对自己说谎的事实充分让自己感到失望而且很生气',
          },
        ],
      },
      {
        which: 'TF',
        question: '我的失误导致恋人很生气时我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `先对自己的失误道歉，然后解释之所以会失误的理由`,
          },
          {
            type: 'F',
            score: 5,
            content: '向对方道歉并努力让对方消气',
          },
        ],
      },
      {
        which: 'TF',
        question: `对恋人说给我们一些时间的自己的意图是？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '有点过于情绪化，所以是真的需要时间去整理关系',
          },
          {
            type: 'F',
            score: 5,
            content:
              '虽然已经整理好了感情也做好了分手的准备，但也是给对方最后一次机会',
          },
        ],
      },
      {
        which: 'JP',
        question: '和恋人分手之后的我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `觉得必须做点什么才能逃脱忧郁状态，虽然会苦恼但最终还是选择忍耐`,
          },
          {
            type: 'P',
            score: 5,
            content: `换发型或购买自己之前想买的东西来缓解忧郁的心情`,
          },
        ],
      },
      {
        which: 'JP',
        question: '过了一段时间以后状态好转的我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '删除曾与前男/女友列过的计划，重新列新计划',
          },
          {
            type: 'P',
            score: 5,
            content: '做好结识新朋友的准备',
          },
        ],
      },
      {
        which: 'JP',
        question: '和新异性相亲的前一天我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '提前想好要穿的衣服和发型等',
          },
          {
            type: 'P',
            score: 5,
            content: '明天随意打扮好了，先睡觉',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameCN/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameCN/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameCN/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameCN/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameCN/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameCN/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameCN/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameCN/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameCN/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameCN/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameCN/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameCN/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameCN/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameCN/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameCN/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveFlameCN/INFP.jpg',
      },
    ],
  },
  // bondee
  {
    info: {
      mainTitle: '본디 성격 테스트',
      subTitle: '본디 캐릭터로 보는 나의 성격은? | 본디 테스트 | bondee test',
      mainUrl: 'bondee',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/bondee-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/bondee-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '친구와 길을 가던 중 친구의 지인을 만났다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친화력을 발휘해서 함께 이야기한다',
          },
          {
            type: 'I',
            score: 5,
            content:
              '짧은 인사만 하고 친구가 이야기하는 것을 옆에서 듣고 있는다',
          },
        ],
      },
      {
        which: 'EI',
        question: '내가 선호하는 소통 방식은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '직접 만나거나 전화로 하는 소통',
          },
          {
            type: 'I',
            score: 5,
            content: '카톡 문자 등 메신저를 이용한 소통',
          },
        ],
      },
      {
        which: 'EI',
        question: '오랜만에 쉬는 날! 상태 메시지를 설정한다면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `간만에 쉬는데 나랑 놀 사람 손!`,
          },
          {
            type: 'I',
            score: 5,
            content: `에너지 충전 중 연락 잘 안돼요`,
          },
        ],
      },
      {
        which: 'SN',
        question: '게임 속 집 꾸미기를 한다면 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `내가 원했던 인테리어 느낌으로 현실적인 집 꾸미기`,
          },
          {
            type: 'N',
            score: 5,
            content: `현실에서는 불가능한 판타지한 느낌으로 집 꾸미기`,
          },
        ],
      },
      {
        which: 'SN',
        question: '게임 속 캐릭터를 만든다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '나만의 고유한 느낌이 드는 현실 반영 캐릭터',
          },
          {
            type: 'N',
            score: 5,
            content: `게임에서는 주인공처럼! 과감하고 판타지한 캐릭터`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '친구가 가상현실 게임이 너무 현실적이라 무섭다고 한다면 나의 반응은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `그건 그냥 게임일 뿐이야`,
          },
          {
            type: 'N',
            score: 5,
            content: `미래에는 진짜 게임과 현실을 구분 못 하는 세상이 올 수도 있어`,
          },
        ],
      },
      {
        which: 'TF',
        question: `PT발표 후 친구가 “넌 열심히 안 하는 것 같았는데 재능이 엄청 나” 라고 한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '후훗..! 역시 나의 재능은 대단해',
          },
          {
            type: 'F',
            score: 5,
            content: '열심히 준비했는데 왜 그렇게 생각하지?ㅠ',
          },
        ],
      },
      {
        which: 'TF',
        question: '내가 상대방에게 관심을 표현하는 방식은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `질문을 자주하고, 적극적으로 도와준다`,
          },
          {
            type: 'F',
            score: 5,
            content: '리액션을 잘하고, 말을 잘 들어준다',
          },
        ],
      },
      {
        which: 'TF',
        question: `극악의 상황에서 무언가를 해냈을 때 내가 듣고 싶은 칭찬은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '와 이걸 성공하네? 도대체 어떻게 한 거야?',
          },
          {
            type: 'F',
            score: 5,
            content: '와 역시 믿고 있었다~ 힘들었을 텐데 고생했어!',
          },
        ],
      },
      {
        which: 'JP',
        question: '게임 속 집을 꾸민다면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `가구, 컬러, 분위기 등 모든 것을 미리 계획하고 꾸미기 시작한다`,
          },
          {
            type: 'P',
            score: 5,
            content: `가능성을 열어 놓고 느낌이 가는 대로 꾸며 본다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '갑자기 땡기는 음식이 생각났을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '진지하게 고민하지만, 계획에 없던 소비라 참아본다',
          },
          {
            type: 'P',
            score: 5,
            content: '먹고 싶은 건 먹어야지! 바로 주문한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구들과 약속 준비할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '준비 끝나고도 시간이 남는 편이다',
          },
          {
            type: 'P',
            score: 5,
            content: '약속 시간 지나서까지도 준비할 때가 많다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/bondee/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/bondee/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/bondee/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/bondee/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/bondee/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/bondee/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/bondee/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/bondee/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/bondee/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/bondee/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/bondee/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/bondee/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/bondee/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/bondee/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/bondee/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/bondee/INFP.jpg',
      },
    ],
  },
  // readyToBoneEng
  {
    info: {
      mainTitle: 'Are you ready for the bitter regret? test',
      subTitle: 'Are you ready to have painful regrets?',
      mainUrl: 'readyToBoneEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/readyToBoneEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/readyToBoneEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          'What would you do if a weekend appointment was canceled suddenly?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I quickly make another appointment for the weekend',
          },
          {
            type: 'I',
            score: 5,
            content: 'I might like it and rest at home',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'How would you react if you received a great welcome from the club where you just joined?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I would be very happy and get along with people well',
          },
          {
            type: 'I',
            score: 5,
            content:
              "It will be a burden for too many welcomes, but I'm trying to adapt slowly and carefully",
          },
        ],
      },
      {
        which: 'EI',
        question: 'What would you do when you feel awkward at an event?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `I can't stand the awkwardness and would try to talk to someone`,
          },
          {
            type: 'I',
            score: 5,
            content: `It might be very awkward, but I would wait until someone talk to me`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'Which date do you prefer?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `A practical date that I can think about the future`,
          },
          {
            type: 'N',
            score: 5,
            content: `A ideal date that I have been dreaming of`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'If you were the main character in a drama, what kind of main character would you like to be?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Handsome and popular rich man',
          },
          {
            type: 'N',
            score: 5,
            content: `A hero in a fantasy world who uses superpowers`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What can be your reaction if your friend suddenly changes the topic in the middle of a conversation?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I can not understand why`,
          },
          {
            type: 'N',
            score: 5,
            content: `I just continue the conversation`,
          },
        ],
      },
      {
        which: 'TF',
        question: `What would you do if a trusted friend cursed at you behind your back?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I would get mad and wonder why',
          },
          {
            type: 'F',
            score: 5,
            content:
              "You would feel betrayed and angry that I won't see each other anymore",
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What would you do if your friend said something inappropriate?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I would just talk directly`,
          },
          {
            type: 'F',
            score: 5,
            content:
              'I would say it indirectly for fear of hurting the other person',
          },
        ],
      },
      {
        which: 'TF',
        question: `What would you do if your friend was very sick?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'What happened? You should go to the hospital',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Are you okay? it must hurt a lot even the weather is so cold',
          },
        ],
      },
      {
        which: 'JP',
        question: 'When are you stressed out?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I get stressed out when things don't go as planned`,
          },
          {
            type: 'P',
            score: 5,
            content: `I get stressed when I have to keep doing repetitive things`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'Which one is close to you?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I just finish up things that I have to do',
          },
          {
            type: 'P',
            score: 5,
            content: 'I often put off things that I have to do',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 더 답답하게 느껴지는 사람은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'A person who starts work right before the deadline',
          },
          {
            type: 'P',
            score: 5,
            content: 'What kind of person do you feel frustrated with?',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneEng/INFP.jpg',
      },
    ],
  },
  // readyToBoneCN
  {
    info: {
      mainTitle: '뼈 아플 준비 됐어? 테스트',
      subTitle: '다들 뼈 아플 준비됐어? | 뼈 테스트',
      mainUrl: 'readyToBoneCN',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/readyToBoneCN-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/readyToBoneCN-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'CN',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '如果周末的约会取消了的话我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '赶快想办法约别人',
          },
          {
            type: 'I',
            score: 5,
            content: '暗自欢喜在家休息',
          },
        ],
      },
      {
        which: 'EI',
        question: '新加入的社团非常欢迎我时我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '心情特别好，很快和大家亲近并适应环境',
          },
          {
            type: 'I',
            score: 5,
            content: '有点感到负担，慎重地慢慢地适应',
          },
        ],
      },
      {
        which: 'EI',
        question: '聚会气氛较尴尬时我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `忍受不了尴尬的氛围，无论什么先说出来`,
          },
          {
            type: 'I',
            score: 5,
            content: `虽然感觉到极度尴尬，但也会坚持到别人先说话`,
          },
        ],
      },
      {
        which: 'SN',
        question: '我更倾向于的恋爱是？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `思考未来的现实版恋爱`,
          },
          {
            type: 'N',
            score: 5,
            content: `梦幻般理想型的恋爱`,
          },
        ],
      },
      {
        which: 'SN',
        question: '如果我是电视剧主角的话我的选择是？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '周围人气很高而且外貌突出的富二代',
          },
          {
            type: 'N',
            score: 5,
            content: `使用超能力的奇幻世界主角`,
          },
        ],
      },
      {
        which: 'SN',
        question: '和朋友聊天时朋友突然换其他话题时我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `无法理解为什么突然说其他话题`,
          },
          {
            type: 'N',
            score: 5,
            content: `自然地一起聊新话题`,
          },
        ],
      },
      {
        which: 'TF',
        question: `信任的朋友在背后说我坏话时我会？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '虽然很生气，但好奇为什么会说我坏话',
          },
          {
            type: 'F',
            score: 5,
            content: '因倍感背叛而感到非常愤怒，考虑绝交',
          },
        ],
      },
      {
        which: 'TF',
        question: '朋友说出不合理的话时我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `明确告诉他说得不对`,
          },
          {
            type: 'F',
            score: 5,
            content: '担心他会受伤，所以尽量不说或者绕着弯子告诉他',
          },
        ],
      },
      {
        which: 'TF',
        question: `朋友突然说骨头疼的话我会？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '怎么突然骨头疼？要不要去医院看一下？',
          },
          {
            type: 'F',
            score: 5,
            content: '没事吧？天气这么冷，肯定会很疼啊',
          },
        ],
      },
      {
        which: 'JP',
        question: '如果要我选一个让自己感到有压力的理由时我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `事情不按计划进行时`,
          },
          {
            type: 'P',
            score: 5,
            content: `一直要反复做同样的工作时`,
          },
        ],
      },
      {
        which: 'JP',
        question: '更接近我的是？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '马上去做应做的事情',
          },
          {
            type: 'P',
            score: 5,
            content: '经常推迟应该做的事情',
          },
        ],
      },
      {
        which: 'JP',
        question: '让我感到无语的人是？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '距离最后期限1个小时之前也不会开始行动的人',
          },
          {
            type: 'P',
            score: 5,
            content: '计划仔细到小时、分钟、秒的人',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneCN/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneCN/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneCN/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneCN/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneCN/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneCN/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneCN/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneCN/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneCN/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneCN/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneCN/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneCN/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneCN/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneCN/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneCN/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBoneCN/INFP.jpg',
      },
    ],
  },
  // loveFlame
  {
    info: {
      mainTitle: '연애 불꽃 테스트',
      subTitle: '불꽃으로 보는 나의 연애는? | 불꽃 테스트',
      mainUrl: 'loveFlame',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/loveFlame-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveFlame-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '마음에 드는 이성이 나타났을 때 나와 가까운 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '적극적으로 다가가서 그 사람을 알아가려고 한다 ',
          },
          {
            type: 'I',
            score: 5,
            content: '천천히 주변을 맴돌며 조금씩 호감의 표시를 흘린다',
          },
        ],
      },
      {
        which: 'EI',
        question: '마음에 드는 이성과 썸일 때 나의 대화 스타일은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'TMI 남발하면서 나의 모든 것을 말한다',
          },
          {
            type: 'I',
            score: 5,
            content:
              '나에 대한 이야기는 아직 하지 않고, 물어보는 질문에만 대답한다',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '연애를 시작했는데, 연인이 연인의 친구들과 함께 놀자고 제안했다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `갑작스럽긴 하지만 오히려 좋다! 그러자고 한다`,
          },
          {
            type: 'I',
            score: 5,
            content: `갑작스럽고 좀 부담스러워서 거절한다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인이 갑자기 우리 미래에 대해 이상적으로만 이야기한다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `그러면 좋겠지만 현실적으로 불가능하다고 생각한다`,
          },
          {
            type: 'N',
            score: 5,
            content: `나도 생각해왔던 이상적인 생각들을 꺼내며 같이 이야기해본다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인이 “만약에~” 하면서 일어나지 않을 일을 물어본다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '그런 일은 일어나지 않는다고 걱정하지 말라고 말해준다',
          },
          {
            type: 'N',
            score: 5,
            content: `진지하게 생각해 보고 대답해준다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인을 위해 음식을 만들어 준다면 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `정량대로 비율을 맞춰서 음식을 만든다`,
          },
          {
            type: 'N',
            score: 5,
            content: `정성이 중요하지! 마음을 담아 감으로 음식을 만든다`,
          },
        ],
      },
      {
        which: 'TF',
        question: `연인이 나에게 거짓말을 했다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '너무 실망스럽지만, 일단 왜 거짓말을 한 건지 물어본다',
          },
          {
            type: 'F',
            score: 5,
            content:
              '연인이 나에게 거짓말을 했다는 것 자체에 이미 서럽고 화가 난다',
          },
        ],
      },
      {
        which: 'TF',
        question: '나의 실수로 연인이 화가 많이 났다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `일단 실수한 것에 사과하고 그럴 수밖에 없었던 이유를 설명한다`,
          },
          {
            type: 'F',
            score: 5,
            content: '사과하고, 화가 풀릴 수 있도록 노력한다',
          },
        ],
      },
      {
        which: 'TF',
        question: `연인에게 우리 시간 좀 갖자고 말한 나의 의도는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '감정이 앞서는 것 같아 진짜 관계를 정리할 시간이 필요해서이다',
          },
          {
            type: 'F',
            score: 5,
            content:
              '감정의 정리가 끝난 상태라 헤어질 준비가 되어있지만, 기회를 준 것이다',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 헤어진 후 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `뭐라도 저질러야 우울감을 떨쳐 낼 것 같아서 고민하지만 결국 참는다`,
          },
          {
            type: 'P',
            score: 5,
            content: `헤어스타일을 바꾸거나, 사고 싶었던 물건들을 마구 사면서 우울감을 떨쳐낸다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '시간이 지나 괜찮아진 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '전 연인과 세웠던 계획을 지우고 다시 새로운 계획을 세운다',
          },
          {
            type: 'P',
            score: 5,
            content: '새로운 사람을 만날 준비를 한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '새로운 이성과 소개팅하기 전날 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '입을 옷, 헤어스타일 등 미리 생각해 놓는다',
          },
          {
            type: 'P',
            score: 5,
            content: '내일 기분에 따라 정할 거라 일단 잔다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/loveFlame/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/loveFlame/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/loveFlame/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/loveFlame/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/loveFlame/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/loveFlame/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/loveFlame/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/loveFlame/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/loveFlame/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/loveFlame/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/loveFlame/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/loveFlame/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/loveFlame/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/loveFlame/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/loveFlame/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/loveFlame/INFP.jpg',
      },
    ],
  },
  // quokka
  {
    info: {
      mainTitle: '쿼카 성격 테스트',
      subTitle: '쿼카로 보는 나의 성격은? | 쿼카 테스트',
      mainUrl: 'quokka',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/quokka-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/quokka-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '나는 주기적으로 새로운 친구를 만드려고 노력한다',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '당연하지!, 친구는 많으면 많을수록 좋아',
          },
          {
            type: 'I',
            score: 5,
            content:
              '숙소에서 풍경을 바라보며 혼자 조용한 시간을 가지는 힐링 타임',
          },
        ],
      },
      {
        which: 'EI',
        question: '정말 맛있는 맛집을 찾았을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '“여기 꼭 가봐!”, 주변에 추천하고 다닌다.',
          },
          {
            type: 'I',
            score: 5,
            content: '“여기 진짜 맛있다”, 혼자만 생각한다. ',
          },
        ],
      },
      {
        which: 'EI',
        question: '파티나 행사 갔을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `새로운 사람에게 먼저 자신을 소개하는 편이다`,
          },
          {
            type: 'I',
            score: 5,
            content: `주로 이미 알고 있는 사람과 대화하는 편이다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '가봤던 장소를 어떤 식으로 기억해?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `먹었던 음식, 방문한 장소 등으로 기억`,
          },
          {
            type: 'N',
            score: 5,
            content: `그날의 분위기, 날씨, 향기 등으로 기억`,
          },
        ],
      },
      {
        which: 'SN',
        question: '버스에 앉아서 창밖을 바라볼 때 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '창밖 이쁘다 나가서 놀고 싶다.',
          },
          {
            type: 'N',
            score: 5,
            content: `이 버스는 하루에 몇대나 배차될까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '로또 당첨에 대한 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `“1등 되면 대체 어떤 기분일까?”`,
          },
          {
            type: 'N',
            score: 5,
            content: `“로또 조작은 진짜 없을까?”`,
          },
        ],
      },
      {
        which: 'TF',
        question: `“오늘 저녁에 뭐해?” 라는 말 뜻은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '진짜 뭐하는지 궁금해서',
          },
          {
            type: 'F',
            score: 5,
            content: '시간되는지 물어보려고',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구의 부탁에 “잠시 생각해볼게”라고 답했을 때 그 의미는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `실제로 고민할 시간이 필요 하다.`,
          },
          {
            type: 'F',
            score: 5,
            content: '거절의 의미가 약간 들어가 있다.',
          },
        ],
      },
      {
        which: 'TF',
        question: `나와 의견이 다른 사람과 토론을 할 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '어떻게든 내 의견을 상대방에게 설득시키려고 노력한다.',
          },
          {
            type: 'F',
            score: 5,
            content: '상대의 의견을 최대한 이해해보려고 노력한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '휴가 계획을 짤 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `“6시 30분까지는 관람을 마쳐야해”, 최대한 구체적으로 짠다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `“이날은 배터지게 먹는날~~~”, 대충 컨셉만 생각해둔다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '시험기간에 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '“오늘은 45p까지 공부해야겠다.”, 계획대로 차근차근 공부한다',
          },
          {
            type: 'P',
            score: 5,
            content:
              '“오늘 잠 안잘거니까 지금은 핸드폰 좀 보자”, 쉬다가 벼락하기를 한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '정해진 계획에 차질이 생기면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '최대한 빨리 계획으로 돌아가기 위해 노력한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '상황마다 계획을 바꾸며 유동적으로 진행한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/quokka/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/quokka/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/quokka/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/quokka/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/quokka/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/quokka/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/quokka/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/quokka/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/quokka/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/quokka/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/quokka/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/quokka/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/quokka/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/quokka/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/quokka/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/quokka/INFP.jpg',
      },
    ],
  },
  // travelCarrier
  {
    info: {
      mainTitle: '여행 캐리어 테스트',
      subTitle: '나의 여행 스타일은 어떨까? | 여행 테스트',
      mainUrl: 'travelCarrier',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/travelCarrier-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/travelCarrier-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '내가 꿈에 그리는 여행 스타일은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '유명한 관광지를 신명 나게 돌아다니며 밤까지 노는 파티 타임',
          },
          {
            type: 'I',
            score: 5,
            content:
              '숙소에서 풍경을 바라보며 혼자 조용한 시간을 가지는 힐링 타임',
          },
        ],
      },
      {
        which: 'EI',
        question: '커플 여행을 가기로 했던 연인과 대판 싸워 우울하다면 당신은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '친구들을 만나 스펙터클 했던 싸움 스토리를 풀며 스트레스를 해소한다',
          },
          {
            type: 'I',
            score: 5,
            content:
              '집에서 혼자만의 시간을 가지며 생각을 정리하고 극복하려고 노력한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '여행은 떠나고 싶지만 친구들이 모두 가지 못하는 상황이라면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `여행이란 우르르 다 같이 가야 재밌는 법! 시간 맞춰서 다음에 가야겠다`,
          },
          {
            type: 'I',
            score: 5,
            content: `이참에 혼자서 여행 다녀와볼까..? 혼자도 난 재밌을 거 같은데`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '외국 공항에 도착했다 그런데 사람이 어마 무시하게 많은 상황이라면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `공항에 사람이 엄청 많네 빨리 숙소로 이동하자`,
          },
          {
            type: 'N',
            score: 5,
            content: `일행의 손을 놓치고 길을 잃어버려 국제 미아가 되는 상상을 함`,
          },
        ],
      },
      {
        which: 'SN',
        question: '비행기 이륙 전, 창문으로 날아가는 풍선이 보인다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '풍선이 왜 있지 하고는 신경 쓰지 않음',
          },
          {
            type: 'N',
            score: 5,
            content: `여기 풍선이 왜 있담? 무슨 색깔이지?? 누가 놓쳤을까??`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '여행 중 배가 꼬르륵거려 밥을 먹을 식당을 찾기로 했다면 당신은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `음식 사진을 봤을 때 맛있어 보이는 식당으로 결정한다`,
          },
          {
            type: 'N',
            score: 5,
            content: `검색을 통해 맛있어 보이는 식당의 리뷰나 이벤트 등을 찾아보고 결정한다`,
          },
        ],
      },
      {
        which: 'TF',
        question: `여행에 가기로 한 친구 중 한 명이 못 가게 되어 아쉬워한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '뭐 어쩔 수 없지 한 명이 줄어서 우리 일정 다시 봐야겠다.',
          },
          {
            type: 'F',
            score: 5,
            content:
              '헐 못 가? 어쩌지 너무 아쉬운걸.. 다른 날로 다시 잡을 순 없나?',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 크고 무거운 여행용 캐리어를 간신히 끌고 왔다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `그 캐리어 어디서 샀음? 엄청 크네`,
          },
          {
            type: 'F',
            score: 5,
            content: '아이고 힘들었겠다 고생했어 이따가 같이 끌어줄까??',
          },
        ],
      },
      {
        which: 'TF',
        question: `공항에서 친구가 짐을 잃어버려 멘붕 상태라면 당신은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '큰일인데? 빨리 유실물 관리소에 전화해 봐',
          },
          {
            type: 'F',
            score: 5,
            content: '헐 어떡함? 큰일 났다! 빨리 어디 있는지 찾아보자',
          },
        ],
      },
      {
        which: 'JP',
        question: '기대하던 맛집에 드디어 도착했는데 문이 닫혀있다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `이런 변수를 대비해 미리 알아두었던 식당으로 간다`,
          },
          {
            type: 'P',
            score: 5,
            content: `이런 변수는 예상하지 못했기 때문에 가까운 식당으로 간다`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          '목적지로 이동 중 배가 너무 고파 꼬르륵거린다.. 이럴 때 당신은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '저기 식당이 있긴 한데.. 먹고 가면 일정이 밀릴텐데 일단 얼른 가야겠다',
          },
          {
            type: 'P',
            score: 5,
            content:
              '오 저 식당 어떰? 배고파.. 맛있어 보이는데 저기 들려서 먹고 가자',
          },
        ],
      },
      {
        which: 'JP',
        question: '즐거웠던 여행이 끝나고 집으로 왔다. 당신의 모습은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '집에 오자마자 짐부터 풀어 정리한 후 휴식 타임',
          },
          {
            type: 'P',
            score: 5,
            content:
              '아 몰랑~ 일단 누워서 대신 누가 짐을 정리해 줬으면 좋겠다고 생각함',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/travelCarrier/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/travelCarrier/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/travelCarrier/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/travelCarrier/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/travelCarrier/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/travelCarrier/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/travelCarrier/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/travelCarrier/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/travelCarrier/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/travelCarrier/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/travelCarrier/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/travelCarrier/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/travelCarrier/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/travelCarrier/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/travelCarrier/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/travelCarrier/INFP.jpg',
      },
    ],
  },
  // readyToBone
  {
    info: {
      mainTitle: '뼈 아플 준비 됐어? 테스트',
      subTitle: '다들 뼈 아플 준비됐어? | 뼈 테스트',
      mainUrl: 'readyToBone',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/readyToBone-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/readyToBone-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '만약 주말 약속이 취소된다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '빠르게 다른 약속을 잡는다',
          },
          {
            type: 'I',
            score: 5,
            content: '은근히 좋아하면서 집에서 쉰다',
          },
        ],
      },
      {
        which: 'EI',
        question: '새로 들어간 동호회에서 나를 격하게 반겨준다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '격한 환영에 기분이 좋고, 사람들과 빠르게 친해지고 적응한다',
          },
          {
            type: 'I',
            score: 5,
            content: '격한 환영이 부담스럽고, 신중하고 천천히 적응하려고 한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '모임에서 어색한 분위기일 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `어색함을 견디지 못하고 무슨 말이라도 꺼내 본다`,
          },
          {
            type: 'I',
            score: 5,
            content: `극도의 어색함을 느끼지만 누가 말을 할 때까지 견딘다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 더 선호하는 연애는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `미래를 생각하는 현실적인 연애`,
          },
          {
            type: 'N',
            score: 5,
            content: `꿈꿔왔던 이상적인 연애`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 드라마 속 주인공이라면 나의 선택은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '사람들에게 인기 많고, 외모가 뛰어난 재벌',
          },
          {
            type: 'N',
            score: 5,
            content: `초능력을 사용하는 판타지 세상 속 주인공`,
          },
        ],
      },
      {
        which: 'SN',
        question: '친구와 이야기하던 중 친구가 갑자기 다른 이야기를 한다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `왜 다른 이야기를 하는 건지 이해가 안 된다`,
          },
          {
            type: 'N',
            score: 5,
            content: `자연스럽게 달라진 그 이야기로 대화를 이어간다`,
          },
        ],
      },
      {
        which: 'TF',
        question: `믿었던 친구가 뒤에서 내 욕을 했다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '화나지만 무슨 이유로 왜 욕을 했는지 궁금하다',
          },
          {
            type: 'F',
            score: 5,
            content: '밀려오는 배신감에 화나서 손절까지 생각한다',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 옳지 않은 말을 한다면 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `확실하게 아닌 것은 아니라고 말해준다`,
          },
          {
            type: 'F',
            score: 5,
            content:
              '혹시나 감정 상해 할까 봐 말하지 않거나, 최대한 돌려 말한다',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 갑자기 뼈가 아프다고 한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜 갑자기 뼈가 아파? 병원 가봐야 하는 거 아니야?',
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮아? 날도 추운데 많이 아프겠다',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 스트레스받는 이유를 고른다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `계획대로 일이 진행되지 않았을 때`,
          },
          {
            type: 'P',
            score: 5,
            content: `반복적인 일을 지속적으로 계속 해야 할 때`,
          },
        ],
      },
      {
        which: 'JP',
        question: '나와 가까운 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '해야 할 일은 당장 실행한다',
          },
          {
            type: 'P',
            score: 5,
            content: '해야 할 일을 자주 미룬다',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 더 답답하게 느껴지는 사람은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '마감기한 1시간 전까지도 미루고 시작하지 않는 사람',
          },
          {
            type: 'P',
            score: 5,
            content: '시간, 분, 초 단위까지 세세하게 계획해 놓는 사람',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBone/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBone/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBone/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBone/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBone/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBone/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBone/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBone/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBone/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBone/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBone/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBone/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBone/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBone/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBone/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/readyToBone/INFP.jpg',
      },
    ],
  },
  // hallInTheWall
  {
    info: {
      mainTitle: '맛집 성격 테스트',
      subTitle: '맛집으로 알아보는 나의 성격은? | 맛집 테스트',
      mainUrl: 'hallInTheWall',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/hallInTheWall-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/hallInTheWall-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          '친구가 한시간 이상 웨이팅있는 맛집을 당장 오늘 저녁에 가자고 할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '안그래도 거기 가보고 싶었는데! 가자!',
          },
          {
            type: 'I',
            score: 5,
            content: '오늘 저녁에? 혹시 주말에는 안될까?',
          },
        ],
      },
      {
        which: 'EI',
        question: '음식점에 들어가면 어느 자리에 앉을까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '중앙 부분, 왁자지껄한 분위기가 좋아!',
          },
          {
            type: 'I',
            score: 5,
            content: '벽 근처, 밥은 조용하게 먹고싶어',
          },
        ],
      },
      {
        which: 'EI',
        question: '자주가던 맛집거리를 오랜만에 갔을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `오랜만에 왔으니 새로운 맛집들을 찾아가본다`,
          },
          {
            type: 'I',
            score: 5,
            content: `예전에 갔었던 맛집 위주로 간다`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '저녁식사 준비를 해야하는데 너무 귀찮을 때, 무슨 생각을 할까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `“아 뭐해먹지?”, “그냥 배달시켜 먹을까?”`,
          },
          {
            type: 'N',
            score: 5,
            content: `“배고픔이 사라지는 약은 없을까?”`,
          },
        ],
      },
      {
        which: 'SN',
        question: '“배고픔이 사라지는 약은 없을까?”',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '눈대중으로 양념을 하면서 요리한다',
          },
          {
            type: 'N',
            score: 5,
            content: `“자동으로 계량해주는 기계는 없을까?”와 같은 잡생각을 하며 요리한다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '비행기 타기 전 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `“기내식 뭐 신청했더라? 맛있었으면 좋겠다…”`,
          },
          {
            type: 'N',
            score: 5,
            content: `“비행기 위험하진 않겠지? 비행기 사고 확률이 얼마더라…”`,
          },
        ],
      },
      {
        which: 'TF',
        question: `“너 요리 진짜 못한다”라는 말에 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '그정도로 맛없어? 뭐가 문제지…',
          },
          {
            type: 'F',
            score: 5,
            content: '열심히 준비했는데ㅠ 너무 심하게 말하니까 상처받는다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '친한 친구가 안좋은일이 있어서 우울하다고 할 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `“많이 안좋은 일이야? 무슨 일인지 물어봐도 돼?”`,
          },
          {
            type: 'F',
            score: 5,
            content: '같이 우울해 하며 맛있는 거 먹으러가자고 한다',
          },
        ],
      },
      {
        which: 'TF',
        question: `내가 갔던 맛집에 대해 설명했을 때, “별로 맛없을 것 같은데?”하면 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '그런가? 난 맛있게 먹었는데. 이런거 싫어하나보다.',
          },
          {
            type: 'F',
            score: 5,
            content: '맛집 공유해 주고 싶었던 건데… 속상하네ㅠ',
          },
        ],
      },
      {
        which: 'JP',
        question: '뷔페에서 음식을 먹을 때 내 스타일은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `‘샐러드 먼저 먹고… 그 다음 고기하고 생선… 마지막으로 밥, 빵!’ , 순서대로 먹는다`,
          },
          {
            type: 'P',
            score: 5,
            content: `‘먹고 싶은거 다 먹자!’, 눈에 보이는대로 먹고싶은걸 먹는다.`,
          },
        ],
      },
      {
        which: 'JP',
        question: '주말에 친구와 맛집 탐방을 가기로 한 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '시간대별로 어디 음식점에 도착해야하는지, 그리고 음식 먹는 시간까지 다 계획한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '맛집을 어디로 갈지만 정하고 간다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '나의 식사 스타일은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '몇 개의 메뉴를 정해 놓고 계속 반복',
          },
          {
            type: 'P',
            score: 5,
            content: '매일 먹는 메뉴가 달라진다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/hallInTheWall/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/hallInTheWall/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src:
          'https://images.ktestone.com/resultImages/hallInTheWall/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src:
          'https://images.ktestone.com/resultImages/hallInTheWall/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src:
          'https://images.ktestone.com/resultImages/hallInTheWall/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/hallInTheWall/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/hallInTheWall/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src:
          'https://images.ktestone.com/resultImages/hallInTheWall/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src:
          'https://images.ktestone.com/resultImages/hallInTheWall/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/hallInTheWall/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/hallInTheWall/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'qpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/hallInTheWall/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src:
          'https://images.ktestone.com/resultImages/hallInTheWall/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/hallInTheWall/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/hallInTheWall/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/hallInTheWall/INFP.jpg',
      },
    ],
  },
  // mailForMeCN
  {
    info: {
      mainTitle: 'Test on letters sent to me',
      subTitle: 'What is the content of the letter sent to me?',
      mainUrl: 'mailForMeCN',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/mailForMeCN-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/mailForMeCN-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'CN',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '我更偏爱的沟通方式是？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '打电话或面对面说话进行沟通',
          },
          {
            type: 'I',
            score: 5,
            content: '用文字在聊天窗口里进行沟通',
          },
        ],
      },
      {
        which: 'EI',
        question: '我更倾向于？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '聚会中主动参与大家的聊天，人数越多越嗨',
          },
          {
            type: 'I',
            score: 5,
            content: '相比多数人聚集的聚会，和对方1对1见面时话更多',
          },
        ],
      },
      {
        which: 'EI',
        question: '如果发生让我有负担的事时我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '和朋友见面或出门解压',
          },
          {
            type: 'I',
            score: 5,
            content: '独自一个人解除心理压力',
          },
        ],
      },
      {
        which: 'SN',
        question: '邮箱里有一封来信时我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '没人会给我寄信呀，到底是谁呀？是寄给我的没错吗？',
          },
          {
            type: 'N',
            score: 5,
            content: '是谁呢？不会是谁写给我的情书吧？><',
          },
        ],
      },
      {
        which: 'SN',
        question: '我更偏爱的对话是？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '聊最近比较火热的话题或感兴趣的领域',
          },
          {
            type: 'N',
            score: 5,
            content: '聊充满想象力的某种可能性或有关未来的话题',
          },
        ],
      },
      {
        which: 'SN',
        question: '我更偏爱的工作是？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '反复且稳定的工作',
          },
          {
            type: 'N',
            score: 5,
            content: '虽然不稳定但充满无限可能性的工作',
          },
        ],
      },
      {
        which: 'TF',
        question: '朋友说要给喜欢的异性写信时我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '直接见面说啊，干嘛写什么信呀..所以你写了什么？',
          },
          {
            type: 'F',
            score: 5,
            content: '真的？好浪漫呀，但是对方不会感到有负担吗？',
          },
        ],
      },
      {
        which: 'TF',
        question: '结果发现那封信是朋友写给我的，那么我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '哇哇，是写给我的？真好奇你写了什么呀？',
          },
          {
            type: 'F',
            score: 5,
            content: '超级感动啊，我也能收到充满真心的手写信..',
          },
        ],
      },
      {
        which: 'TF',
        question: '给苦恼多且很累的朋友写一封信时我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '写一些对朋友有帮助的话和解决问题的方法',
          },
          {
            type: 'F',
            score: 5,
            content: '写一些安慰朋友的话和产生共鸣的内容',
          },
        ],
      },
      {
        which: 'JP',
        question: '给朋友写信时我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '提前想好要写什么，然后按自己脑子里的提纲顺序写',
          },
          {
            type: 'P',
            score: 5,
            content: '坐下来，然后一边想着朋友一边随意地写',
          },
        ],
      },
      {
        which: 'JP',
        question: '随便走进一家工艺品店铺，发现自己喜欢的小物件时我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '虽然非常吸引自己，但不在计划当中，所以不会花没必要的支出',
          },
          {
            type: 'P',
            score: 5,
            content: '无法错过吸引眼球的小物件，冲动购买',
          },
        ],
      },
      {
        which: 'JP',
        question: '我手机的短信信箱状态是？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '平时坚持整理信箱，只有一些自己需要的短信，整理得有理有序',
          },
          {
            type: 'P',
            score: 5,
            content: '记不清上一次什么时候整理的，累积了一堆短信',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeCN/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeCN/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'qnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeCN/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeCN/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeCN/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeCN/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeCN/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeCN/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'gksmftor',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeCN/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tkfrntor',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeCN/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'cjdfhrtor',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeCN/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'skator',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeCN/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'tnvtor',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeCN/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ghltor',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeCN/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'qkator',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeCN/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeCN/INFP.jpg',
      },
    ],
  },
  // instaStory
  {
    info: {
      mainTitle: '인스타 스토리 성격 테스트',
      subTitle: '인스타로 알아보는 나의 성향은? | 인스타 테스트',
      mainUrl: 'instaStory',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/instaStory-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/instaStory-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '여행 가서 예쁜 풍경을 찍은 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '인스타에 올리고, 지인들에게 보여준다',
          },
          {
            type: 'I',
            score: 5,
            content: '찍은 사진을 혼자 간직한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구들 사이에서 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '보통 내가 먼저 스토리를 올린다',
          },
          {
            type: 'I',
            score: 5,
            content: '보통 친구가 올린 스토리를 공유한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '새로 사귄 친구에게 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `먼저 팔로우를 건다`,
          },
          {
            type: 'I',
            score: 5,
            content: `먼저 팔로우가 오면 팔로우한다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '인스타를 올린 후 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '올리고 난 후에는 별로 신경 쓰지 않는다',
          },
          {
            type: 'N',
            score: 5,
            content: '사람들이 어떤 반응일까 궁금해한다',
          },
        ],
      },
      {
        which: 'SN',
        question: '친구의 스토리를 보고 DM을 보냈지만, 답장이 없다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '“바쁜가 보다” 하고 별로 신경 쓰지 않는다',
          },
          {
            type: 'N',
            score: 5,
            content: '왜 답장을 하지 않는 거지? 하며 깊은 생각에 빠진다',
          },
        ],
      },
      {
        which: 'SN',
        question: '전 애인이 나의 스토리를 봤다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '잘못 누른거겠지?..',
          },
          {
            type: 'N',
            score: 5,
            content: '내 스토리를 왜 본 거지? 혹시 아직 미련이 남았나?',
          },
        ],
      },
      {
        which: 'TF',
        question: '약간 불편했던 친구에게 팔로우 신청이 온다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '모른척하고 받지 않는다',
          },
          {
            type: 'F',
            score: 5,
            content: '불편하긴 하지만 감정 상해할까 봐 일단 받는다',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 피드에 올린 사진이 보정한 게 너무 티가 난다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '보정한 거 다 티 난다고 말하면서 놀린다',
          },
          {
            type: 'F',
            score: 5,
            content: '다 티 난다고 말해주고 싶지만, 기분 나빠 할까 봐 참는다',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '친구가 맛있어 보이는 음식점 사진을 스토리에 올렸을 때 내 반응은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '대박 여기 어디야? 맛있어?',
          },
          {
            type: 'F',
            score: 5,
            content: '너무 맛있었겠다 다음에는 나도 같이 가자',
          },
        ],
      },
      {
        which: 'JP',
        question: '인스타 감성 맛집이나, 카페를 가기 전 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '메뉴를 미리 정해 놓고, 어떻게 사진을 찍을지 미리 생각해 놓는다',
          },
          {
            type: 'P',
            score: 5,
            content: '가서 보고 메뉴를 정하고, 느낌이 가는 대로 사진을 찍는다',
          },
        ],
      },
      {
        which: 'JP',
        question: '나와 가까운 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '인스타 피드를 주기적으로 정리하고, 깔끔한 느낌을 유지한다',
          },
          {
            type: 'P',
            score: 5,
            content: '꼭 필요한 정리만 하는 편이다',
          },
        ],
      },
      {
        which: 'JP',
        question: '인스타 올릴 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '올릴 사진과 글을 미리 생각해 놓는다',
          },
          {
            type: 'P',
            score: 5,
            content: '즉흥적으로 사진을 고르고, 생각나는 대로 글을 쓴다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/instaStory/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/instaStory/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/instaStory/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/instaStory/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/instaStory/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/instaStory/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/instaStory/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/instaStory/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/instaStory/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/instaStory/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/instaStory/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/instaStory/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/instaStory/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/instaStory/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/instaStory/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/instaStory/INFP.jpg',
      },
    ],
  },
  // princess
  {
    info: {
      mainTitle: '나는 어떤 나라의 공주일까? 테스트',
      subTitle: '공주로 알아보는 나의 성격은? | 공주 테스트',
      mainUrl: 'princess',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/princess-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/princess-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '이번 공주 파티는 내가 주최하게 되었다.',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '갹!!!!! 아는 공주 다 불러!!!! 세상에서 제일 화려하고 신나는 파티다!!!!뒤지버지게 놀아보자',
          },
          {
            type: 'I',
            score: 5,
            content: '친한 공주들만 모여서 우아하게 놀아보자…^^',
          },
        ],
      },
      {
        which: 'EI',
        question: '공주들이 다 집으로 돌아가고 나는…',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '내 성이 원래 이렇게 조용했던가…? 다음번 파티가 벌써 기다려져',
          },
          {
            type: 'I',
            score: 5,
            content: '재밌었지만 당분간은 기력 충전하면서 좀 쉬어야지!!',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '공주 파티에 처음 온 옆 나라 공주가 초대해 줘서 감사하다는 인사를 했다.',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `공주 파티가 처음이라고요?! 저 따라오세요!! 제가 진짜 재밌는 공주들 소개해 줄게요!! `,
          },
          {
            type: 'I',
            score: 5,
            content: `어머머 감사하긴요~ 재밌게 놀다 가세요~ ^^`,
          },
        ],
      },
      {
        which: 'SN',
        question: '이왕 주최하게 된 거 성공적인 공주 파티를 만들고 싶은데…',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '역대 공주 파티들 레퍼런스를 모아봐야겠다',
          },
          {
            type: 'N',
            score: 5,
            content:
              '훔… 뭔가.. 이렇게 이렇게 하면 잼쓸듯…? 다 좋아할 것 같은뎅?',
          },
        ],
      },
      {
        which: 'SN',
        question: '드디어 공주 파티 전날!! 잠자리에 든 나는',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '드르렁…',
          },
          {
            type: 'N',
            score: 5,
            content:
              '오랜만에 공주들 만날 생각에 너무 설렌다!! 빨리 자야 되는데 잠이 안 오네!! 아 아무 생각 하지 말고 자자!! 근데 아무 생각을 안 하는건 어떤 거지? 생각이란 뭘까?',
          },
        ],
      },
      {
        which: 'SN',
        question: '조금 친해진 옆 나라 공주가 자기 오빠를 소개해주겠다고 한다.',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '옆 나라 왕자님 존잘인데 개꿀',
          },
          {
            type: 'N',
            score: 5,
            content:
              '헉 옆 나라 왕자님이라면 진짜 잘생기기로 유명한데… 나 보자마자 첫눈에 반해서 한 달도 안돼서 결혼하고 막 그러는 거 아냐? 근데 옆 나라 왕자님 전여친들이 나타나서 방해하면 어쩌지',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '파티 준비를 하는데 손이 느리고 실수투성이인 하인을 발견!! 다른 하인들이 하는 말이 들린다. “쟤는 온 지 석 달이 넘었는데 아직도 일을 못해” “남편 죽고 일 시작한거래잖아~ 궂은일 해본 적 없이 살았대”',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '사정이 어쨌든 솔직히 내 성에 일 못하는 하인은 필요 없어 민폐야',
          },
          {
            type: 'F',
            score: 5,
            content: '그런 사정이 있었구나… 마음이 아파…ㅠㅠ',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '공주 파티 당일!! 공주들이 모이기 시작하는데!! 갑자기 친한 00공주의 불참 연락이 왔다.',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜, 무슨 일 있어?',
          },
          {
            type: 'F',
            score: 5,
            content: '헉!! 너무 속상해 ㅠㅠ',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '공주 파티 성공적으로 끝!! 공주들이 저마다 주최자인 나를 칭찬해준다. 제일 듣기 좋은 칭찬은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '크~~ 이제까지 공주 파티중에 제일 잘 준비하신 것 같아요. 이렇게 배워갑니다~',
          },
          {
            type: 'F',
            score: 5,
            content:
              '공주님 너무 수고 많으셨어요ㅠㅠ 재밌는 시간 만들어주셔서 너무 고마워요!!',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '파티 장소를 꾸밀 차례. 나를 닮아 로맨틱한 핑크 장미를 데코하려는데 핑크 장미의 수가 부족해서 다른 꽃으로 바꿔야 된다는 정원사의 말…!!',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '아 스트레스 이 몸 하면 로맨틱한 핑크장민데… 그럼 두 번째 후보인 흰 장미로 부탁해요 ',
          },
          {
            type: 'P',
            score: 5,
            content: '흠… 그럼 흰 장미로 바꾸지 뭐!! 흰 장미도 예쁘니까 괜춘~',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '다 같이 즐기려고 며칠 동안 구상해서 준비해놓은 게임들이 있는데 공주들이 게임은 무슨, 수다나 떨자고 한다.',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '열심히 준비했는데… 수다 타임은 따로 있는데… 기분이 조금 상했다…',
          },
          {
            type: 'P',
            score: 5,
            content: '구래!! 수다 떠는 거도 좋아 꺄르르',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '뒷나라 공주가 넘어지면서 내 드레스에 와인을 쏟아버렸다!! “헉 어떡해… 죄송해요ㅠㅠ”',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '혹시 몰라서 여분의 드레스를 준비해놨어요. 갈아입고 오면 돼요~^^',
          },
          {
            type: 'P',
            score: 5,
            content: '괜찮아요~ 드레스 빨간색이라 티도 안 남!!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/princess/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/princess/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/princess/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/princess/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/princess/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/princess/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/princess/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/princess/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/princess/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/princess/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/princess/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/princess/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/princess/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/princess/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/princess/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/princess/INFP.jpg',
      },
    ],
  },
  // mailForMeEng
  {
    info: {
      mainTitle: 'Test on letters sent to me',
      subTitle: 'What is the content of the letter sent to me?',
      mainUrl: 'mailForMeEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/mailForMeEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/mailForMeEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'What is your preferred method of communicating with others?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Through phone call or face-to-face',
          },
          {
            type: 'I',
            score: 5,
            content: 'Through text message or chat room chat',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Which of the below is closer to you?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'I speak loudly in groups,\nand the more people there are,\nthe louder I speak',
          },
          {
            type: 'I',
            score: 5,
            content:
              'Rather than having a conversation with many people,\nI tend to talk a lot in one-on-one situations',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What do you do when you suddenly have a lot of stress?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I relieve stress by meeting people or going out',
          },
          {
            type: 'I',
            score: 5,
            content: 'I relieve stress by spending time alone',
          },
        ],
      },
      {
        which: 'SN',
        question: 'What would you do if you found a letter in your mailbox?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I would be surprised, but who sent it? is it for me?',
          },
          {
            type: 'N',
            score: 5,
            content: 'Who sent it? Could this be a love letter? ><',
          },
        ],
      },
      {
        which: 'SN',
        question: 'What type of conversation do you prefer?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'Conversation to talk about hot issues or interests these days',
          },
          {
            type: 'N',
            score: 5,
            content:
              'Conversation about imaginative possibilities or the future',
          },
        ],
      },
      {
        which: 'SN',
        question: 'What type of work do you prefer?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Repeatable and stable work',
          },
          {
            type: 'N',
            score: 5,
            content:
              'I like works that have infinite possibilities even if they are not stable',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What would be your reaction if your friend wrote a love letter to the opposite sex?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: "Why don't you just meet up? Anyway, what is it about?",
          },
          {
            type: 'F',
            score: 5,
            content:
              "Really? It sounds romantic, but doesn't it feel burdensome?",
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What would you do if it turns out that the letter was written to you?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Oh, was it mine? I wonder what it is',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Wow, I would be touched to receive a thoughtful handwritten letter…',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'If you had to write a letter to a friend who is struggling with a lot of worries, what would you write?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'I would write about advice and problem solving with affection',
          },
          {
            type: 'F',
            score: 5,
            content: 'I would share sympathy and consolation',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'If you were writing a letter to a friend, what would you write?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: "I'll write what's been on my mind",
          },
          {
            type: 'P',
            score: 5,
            content: 'I would write impromptu thinking',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What would you do if you stumbled into a prop shop and found a prop you liked?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "It is eye catching, but I don't make unnecessary unplanned expenses",
          },
          {
            type: 'P',
            score: 5,
            content: 'I would make an impulse purchase without passing by',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Do you organize text messages on your phone?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'It is well organized as I keep cleaning up',
          },
          {
            type: 'P',
            score: 5,
            content: "It's just piled up so much since I didn't organize it",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'qnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'gksmftor',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tkfrntor',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'cjdfhrtor',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'skator',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'tnvtor',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ghltor',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'qkator',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/mailForMeEng/INFP.jpg',
      },
    ],
  },
  // gypsophila
  {
    info: {
      mainTitle: '사랑의 안개꽃 테스트',
      subTitle: '나에게 어울리는 안개꽃 색깔은? | 안개꽃 테스트 | 사랑 테스트',
      mainUrl: 'gypsophila',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/gypsophila-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/gypsophila-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '연인이 사람이 많은 장소에서 꽃을 선물한다면? ',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '남들의 시선은 신경 쓰이지 않고 꽃을 받아 기분이 좋다.',
          },
          {
            type: 'I',
            score: 5,
            content: '시선이 부끄러워 꽃을 받고 빠르게 그 장소를 벗어난다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '연인과 데이트 후 집에 가는 길에 친구가 만나자고 한다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '바로 친구를 만나 데이트 후기를 푼다.',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자만의 시간이 필요하므로 약속은 다음으로 미룬다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '지하철을 탔는데 아는 친구가 같은 칸에 앉아 있다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `반가움에 바로 옆자리로 가서 인사하고 대화한다.`,
          },
          {
            type: 'I',
            score: 5,
            content: `먼저 인사하기는 부끄러워서 모른척한다.`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인과 차를 타고 가던 중 눈이 펑펑 내린다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '눈 때문에 차가 막힐 수 있으니 빠르게 지름길로 간다.',
          },
          {
            type: 'N',
            score: 5,
            content:
              '눈이 오는 건 너무 낭만적이지만 나중에 눈을 치우는 사람들은 힘들겠다 하고 생각함.',
          },
        ],
      },
      {
        which: 'SN',
        question: '연인과 길을 걷다가 대기 줄이 어마어마한 식당을 발견했다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '저기 줄이 엄청 길구나 하고 그냥 지나침',
          },
          {
            type: 'N',
            score: 5,
            content:
              '줄이 엄청 긴 걸로 봐서는 맛집이 분명하다며 나중에 꼭 먹어야겠다고 생각함',
          },
        ],
      },
      {
        which: 'SN',
        question: '평소 짝사랑하던 이성과 사귀는 꿈을 꾸었다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '뭐야 꿈이었네 하고 다시 잠에 든다',
          },
          {
            type: 'N',
            score: 5,
            content:
              '꿈 내용을 다시 한번 생각하며 실제로 사귀게 된다면 어떨까 상상한다',
          },
        ],
      },
      {
        which: 'TF',
        question: '연인과 데이트 중 갑자기 기분이 안 좋아 보인다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '기분이 왜 안 좋은지 바로 물어본다',
          },
          {
            type: 'F',
            score: 5,
            content: '혹시 내가 기분을 상하게 한 행동이 있나 걱정한다',
          },
        ],
      },
      {
        which: 'TF',
        question: '연인이 오늘은 기분이 좋아서 나를 위해 꽃을 샀다고 한다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '어떤 꽃을 샀는지 궁금하다',
          },
          {
            type: 'F',
            score: 5,
            content: '기분이 좋은 이유가 궁금하다',
          },
        ],
      },
      {
        which: 'TF',
        question: '내 집에서 연인과 식사 후 배가 아프다고 한다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '어떤 음식 때문인지 찾는다',
          },
          {
            type: 'F',
            score: 5,
            content: '어떻게 아픈지, 약은 먹었는지 물어본다',
          },
        ],
      },
      {
        which: 'JP',
        question: '오랜만에 친구들과 주말에 여행을 가기로 다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '여행 갈 지역의 맛집 , 명소 등을 알아보고 예약하며 일정을 미리 계획한다',
          },
          {
            type: 'P',
            score: 5,
            content: '여행 가서 신나게 놀 생각에 설렌다',
          },
        ],
      },
      {
        which: 'JP',
        question: '이번 주말에 연인의 부모님을 뵙기로 했다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '약속 장소에 따라 맞는 옷을 미리 정해놓고 질문에 대한 대답을 머릿속으로 정리해둔다',
          },
          {
            type: 'P',
            score: 5,
            content: '실수하진 않을지 떨려 한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인의 친구와 내 친구가 서로 호감이 있다는 걸 알게 된다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '둘의 만남을 성사시키기 위해 치밀하게 계획을 짠다',
          },
          {
            type: 'P',
            score: 5,
            content: '둘이 잘 됐으면 좋겠다고 생각한다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/gypsophila/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/gypsophila/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gksmf',
        img_src: 'https://images.ktestone.com/resultImages/gypsophila/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/gypsophila/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlsghl',
        img_src: 'https://images.ktestone.com/resultImages/gypsophila/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/gypsophila/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/gypsophila/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ska',
        img_src: 'https://images.ktestone.com/resultImages/gypsophila/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghl',
        img_src: 'https://images.ktestone.com/resultImages/gypsophila/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/gypsophila/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/gypsophila/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'qpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/gypsophila/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/gypsophila/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dusqnsghd',
        img_src: 'https://images.ktestone.com/resultImages/gypsophila/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/gypsophila/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/gypsophila/INFP.jpg',
      },
    ],
  },
  // diet
  {
    info: {
      mainTitle: '다이어트 성격 테스트',
      subTitle: '나의 다이어트 유형은?',
      mainUrl: 'diet',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/diet-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/diet-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '다이어트를 결심했을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '동호회 같은 곳에 들어서 사람들하고 운동하는 편',
          },
          {
            type: 'I',
            score: 5,
            content: '집에서 홈트하는게 최고!',
          },
        ],
      },
      {
        which: 'EI',
        question: '헬스장 갔는데 사람이 아무도 없다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '혼자 운동하면 외로운데 누구 안오나…',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자 헬스장 사용하니까 조용하고 좋네!',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구가 갑작스럽게 등산을 가자 그러면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `유산소 개꿀! “지금 바로 나갈게!”`,
          },
          {
            type: 'I',
            score: 5,
            content: `집에서 홈트하고싶은데… “오늘은 몸이 좀 안좋은데ㅠ”`,
          },
        ],
      },
      {
        which: 'SN',
        question: '다이어트를 하는데 살이 잘 안빠질 때 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '운동법을 바꿔야하나? 아님 식단이 문젠가?',
          },
          {
            type: 'N',
            score: 5,
            content:
              '살은 왜 빼야하는걸까…먹어도 살이 안찌는 세상이 올 순 없을까?',
          },
        ],
      },
      {
        which: 'SN',
        question: '다이어트 하는 중 밤에 치킨을 먹고싶다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '치킨은 살 많이 찌겠지? 먹으면 다이어트 실패야…',
          },
          {
            type: 'N',
            score: 5,
            content: '튀김옷 벗기고 먹으면 괜찮지 않을까…? 제로 치킨은 없나…?',
          },
        ],
      },
      {
        which: 'SN',
        question: '헬스를 한다면 더 신경쓰이는 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '운동의 강도',
          },
          {
            type: 'N',
            score: 5,
            content: '근육의 움직임',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '친구가 애인과 헤어졌다고 술 한잔 하자고 했을 때 다이어트중인 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '친구의 고민은 들어주되 다이어트 신경쓰느라 음식은 잘 안먹는다.',
          },
          {
            type: 'F',
            score: 5,
            content: '친구의 감정에 공감해주며 그날만큼은 같이 먹어준다.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '금방 그만둘 줄 알았는데 진짜 열심히 하네? 라는 말을 들었다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '당연하지! 이게 나야~ (기분 좋아서 웃음 남)',
          },
          {
            type: 'F',
            score: 5,
            content: '금방 그만둘 줄 알았다니ㅠ 왜 말 그렇게 해ㅠ',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 살이 너무 안빠진다고 고민을 털어놓을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '지금 운동 어떻게 하고있는데? 식단은 제대로 하고있어?',
          },
          {
            type: 'F',
            score: 5,
            content: '힘들지ㅠㅠ 우리 같이 힘내서 다이어트 해보자!',
          },
        ],
      },
      {
        which: 'JP',
        question: '다이어트 시작했을 때 마음가짐은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '세달정도 여유 있으니까 한달에 2kg씩만 뺄 수 있도록 하자!',
          },
          {
            type: 'P',
            score: 5,
            content: '굶고 운동하다보면 살 빠지겠지~',
          },
        ],
      },
      {
        which: 'JP',
        question: '다이어트 식단 짤 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '정확한 칼로리 계산과 규칙적인 식사시간 준수!',
          },
          {
            type: 'P',
            score: 5,
            content: '그냥 다이어트 식품만 끼니 때마다 챙겨먹기!',
          },
        ],
      },
      {
        which: 'JP',
        question: '나의 운동 스타일은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '요일마다 운동 스케줄 짜놓고 계획적으로 실천',
          },
          {
            type: 'P',
            score: 5,
            content: '운동방법이나 시간에 구애 받지 않고 자유롭게 하는편',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'wlswlgks',
        img_src: 'https://images.ktestone.com/resultImages/diet/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'wlrtjfwjrdls',
        img_src: 'https://images.ktestone.com/resultImages/diet/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'rmdwjdwjrdls',
        img_src: 'https://images.ktestone.com/resultImages/diet/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/diet/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ehehgks',
        img_src: 'https://images.ktestone.com/resultImages/diet/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'dbajfjtmgks',
        img_src: 'https://images.ktestone.com/resultImages/diet/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ekwjdgks',
        img_src: 'https://images.ktestone.com/resultImages/diet/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'clswjfgks',
        img_src: 'https://images.ktestone.com/resultImages/diet/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'rhwlqtmfjs',
        img_src: 'https://images.ktestone.com/resultImages/diet/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gustlfwjrdls',
        img_src: 'https://images.ktestone.com/resultImages/diet/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tnsenddl',
        img_src: 'https://images.ktestone.com/resultImages/diet/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'qnRmfjawoddl',
        img_src: 'https://images.ktestone.com/resultImages/diet/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'tlswndgks',
        img_src: 'https://images.ktestone.com/resultImages/diet/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'snscl',
        img_src: 'https://images.ktestone.com/resultImages/diet/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'rkatjdwjrdls',
        img_src: 'https://images.ktestone.com/resultImages/diet/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'godqhrgks',
        img_src: 'https://images.ktestone.com/resultImages/diet/INFP.jpg',
      },
    ],
  },
  // smileColorTestES
  {
    info: {
      mainTitle: 'Prueba de citas de sonrisa',
      subTitle: 'Descubre tu tendencia de citas con personajes sonrientes.',
      mainUrl: 'smileColorTestES',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/smileColorTestES-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/smileColorTestES-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'ES',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: 'En un fin de semana sin planes, ¿qué harías?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'Envío mensajes a mis amigos de inmediato para tener unos planes.',
          },
          {
            type: 'I',
            score: 5,
            content: 'Descanso en mi cama todo el día.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Tienes una cita que no se ha plneado antes, ¿qué harías?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '¡Se ve divertido! Si no tengo otros planes, voy a la cita.',
          },
          {
            type: 'I',
            score: 5,
            content: 'No era de mis planes... pongo unas excusas para no ir.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Un amigo te trajo a una reunión, ¿qué harías?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'Dirijo las palabras a la gente primero e intento crear un ambiente agradable.',
          },
          {
            type: 'I',
            score: 5,
            content: 'Espero hasta que me dirijan las palasbras.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'Subes al avión para un viaje. ¿Qué piensas?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Quiero llegar rápido.',
          },
          {
            type: 'N',
            score: 5,
            content:
              '¡Será divertido! Pero, ¿qué es lo qe hace volar un avión?',
          },
        ],
      },
      {
        which: 'SN',
        question: '¿En qué piensas al comer?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'Pienso en cuál debería elegir entre las dos comidas favoritas del restaurante.',
          },
          {
            type: 'N',
            score: 5,
            content: 'Al almorzar, pienso en qué comer para la cena.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'Viendo un programa de concurso musical, ¿qué piensas?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '¡Qué maravilloso! ¡Voy a escuchar el tema cada día cuando lo lancen!',
          },
          {
            type: 'N',
            score: 5,
            content:
              'Guau... ¿La letra es de su experiencia personal? ¿Cuánto habrá practicado?',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'Tu amigo te dice que fue de compras porque se sentía deprimido, ¿qué le dirías?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '¿Qué compraste? ¿Por si acaso compraste cualquier cosa?',
          },
          {
            type: 'F',
            score: 5,
            content: '¿Qué te paso? ¿De repente fuiste de compras?',
          },
        ],
      },
      {
        which: 'TF',
        question: 'Tu amigo te dice que pasó un exmane, ¿qué le dirías?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '¿Qué examen fue? ¡Felicidades!',
          },
          {
            type: 'F',
            score: 5,
            content:
              '¡Guau te felicito! ¡Imagino que sería muy duro!¡Bien hecho!',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'Tu novia ha perdido algo precioso para ella, ¿qué le dirías?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '¿Cómo lo has perdido? ¿Dónde lo has visto por última vez?',
          },
          {
            type: 'F',
            score: 5,
            content: 'Ay ¿qué hacemos? Te importaba eso...',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Quieres ir a un restaurante popular con su novio/a, ¿qué harías?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Reservas de antemano y eliges hasta el menú previamente.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'Cuando no te queda mucho tiempo hasta la cita, reserva con mucha prisa.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Cuando eliges ropa para citas, ¿qué harías?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Un día antes elijo toda la ropa de los pies a la cabeza.',
          },
          {
            type: 'P',
            score: 5,
            content: 'Al preparar para salir, me pongo la ropa que me apetece.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Caundo quieres viajar con tu novio/a, ¿qué harías?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Desarrollo planes de manera detallada para cada día.',
          },
          {
            type: 'P',
            score: 5,
            content: 'Comprar el boleto de avión es el 70%del plan.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'mint',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestES/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'yellow',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestES/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'pink',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestES/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'purple',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestES/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'blue',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestES/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'red',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestES/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'orange',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestES/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'olive',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestES/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'burgundy',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestES/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'beige',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestES/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'navy',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestES/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'grey',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestES/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'khaki',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestES/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'green',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestES/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'brown',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestES/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sky',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestES/INFP.jpg',
      },
    ],
  },
  // mailForMeEng
  {
    info: {
      mainTitle: '나에게 온 편지 테스트',
      subTitle: '나에게 온 편지는 어떤 편지 내용일까?',
      mainUrl: 'mailForMe',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/mailForMe-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/mailForMe-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '소통 시 내가 더 선호하는 대화 방식은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '전화하거나, 직접 얼굴을 보면서 말로 하는 소통',
          },
          {
            type: 'I',
            score: 5,
            content: '톡 방에서 대화, 문자 등 글로 하는 소통',
          },
        ],
      },
      {
        which: 'EI',
        question: '나와 가까운 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '모임에서 대화 참여에 적극적이고, 인원이 많을수록 데시벨이 커진다',
          },
          {
            type: 'I',
            score: 5,
            content: '여럿이 있는 모임 보다 1:1로 만났을 때 말이 많은 편이다',
          },
        ],
      },
      {
        which: 'EI',
        question: '스트레스 받는 일이 있다면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '사람들을 만나거나 밖으로 나가서 스트레스를 푼다',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자만의 시간을 보내며 스트레스를 푼다',
          },
        ],
      },
      {
        which: 'SN',
        question: '우편함에 누군가 나에게 보낸 편지가 있다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '편지 올 일이 없었는데 누구지? 나한테 온 게 맞나?',
          },
          {
            type: 'N',
            score: 5,
            content: '누구지? 혹시 이거 러브레터 그런 건 아닐까?><',
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 더 선호하는 대화는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '요즘 핫 한 이슈나, 관심사에 대해 이야기하는 대화',
          },
          {
            type: 'N',
            score: 5,
            content: '상상력이 가미된 어떠한 가능성이나, 미래에 대한 대화',
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 더 선호하는 일은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '반복적이고 안정적인 일',
          },
          {
            type: 'N',
            score: 5,
            content: '안정적이지 않지만 무한한 가능성이 있는 일',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 좋아하던 이성에게 편지를 썼다고 한다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '그냥 만나서 말하지 무슨 편지야.. 그래서 뭐라고 썼는데?',
          },
          {
            type: 'F',
            score: 5,
            content: '진짜? 낭만적이다 근데 혹시 부담스러워하지는 않을까?',
          },
        ],
      },
      {
        which: 'TF',
        question: '알고 보니 친구가 나에게 쓴 편지였다며 나에게 준다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '오오 내거였어? 뭐라고 썼는지 궁금한데?',
          },
          {
            type: 'F',
            score: 5,
            content: '완전 감동이야 정성스러운 손 편지를 받다니..',
          },
        ],
      },
      {
        which: 'TF',
        question: '고민이 많아 힘들다는 친구에게 편지를 쓴다면 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '애정이 담긴 조언과 문제 해결 방법에 대해 쓴다',
          },
          {
            type: 'F',
            score: 5,
            content: '공감과 위로의 내용을 쓴다',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구에게 편지를 쓴다면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '어떻게 적을지 미리 생각해 놓은 대로 술술 적어 내려간다',
          },
          {
            type: 'P',
            score: 5,
            content:
              '일단 앉아서 그 자리에서 친구에 대해 생각나는 대로 적어 내려간다',
          },
        ],
      },
      {
        which: 'JP',
        question: '우연히 들어가 소품 샵에서 마음에 드는 소품을 발견했다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '눈길은 사로잡혔지만 계획되지 않은 불필요한 지출은 하지 않는다',
          },
          {
            type: 'P',
            score: 5,
            content:
              '눈길을 사로잡은 소품을 그냥 지나치지 못하고 충동구매를 한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '나의 핸드폰의 문자 정리 상태는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '정리를 꾸준히 해서 필요한 문자 빼고는 정리가 잘되어 있다',
          },
          {
            type: 'P',
            score: 5,
            content: '정리를 언제부터 안 했는지 모를 정도로 많이 쌓여 있다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/mailForMe/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/mailForMe/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'qnsghd',
        img_src: 'https://images.ktestone.com/resultImages/mailForMe/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/mailForMe/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/mailForMe/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/mailForMe/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/mailForMe/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/mailForMe/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'gksmftor',
        img_src: 'https://images.ktestone.com/resultImages/mailForMe/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tkfrntor',
        img_src: 'https://images.ktestone.com/resultImages/mailForMe/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'cjdfhrtor',
        img_src: 'https://images.ktestone.com/resultImages/mailForMe/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'skator',
        img_src: 'https://images.ktestone.com/resultImages/mailForMe/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'tnvtor',
        img_src: 'https://images.ktestone.com/resultImages/mailForMe/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ghltor',
        img_src: 'https://images.ktestone.com/resultImages/mailForMe/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'qkator',
        img_src: 'https://images.ktestone.com/resultImages/mailForMe/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/mailForMe/INFP.jpg',
      },
    ],
  },
  // characteristicChracterCN
  {
    info: {
      mainTitle: '性格角色测试',
      subTitle: '用角色看我的性格是？',
      mainUrl: 'characteristicChracterCN',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/characteristicChracterCN-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/characteristicChracterCN-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'CN',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '和我比较相似的是？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '不认生',
          },
          {
            type: 'I',
            score: 5,
            content: '和他人亲近需要投入很多时间',
          },
        ],
      },
      {
        which: 'EI',
        question: '对于朋友们来说我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '主动先联系对方',
          },
          {
            type: 'I',
            score: 5,
            content: '答复联系我的朋友',
          },
        ],
      },
      {
        which: 'EI',
        question: '发生心情不好的事情时我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '出门见朋友们缓解不愉快',
          },
          {
            type: 'I',
            score: 5,
            content: '一个人在家里静静地整理思绪',
          },
        ],
      },
      {
        which: 'SN',
        question: '准备出门的时候突然下雨我会想？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '下雨了呀，得拿伞出去啦',
          },
          {
            type: 'N',
            score: 5,
            content: '下雨的话地面会很滑，我不会走着走着摔倒吧？',
          },
        ],
      },
      {
        which: 'SN',
        question:
          '大伙儿一起去酒吧，结果发现往我们桌位走来的服务员端的啤酒杯盘子很小时我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '想着啤酒终于要来啦',
          },
          {
            type: 'N',
            score: 5,
            content: '想象他这么危险地走的话啤酒杯可能会掉下去，内心感到不安',
          },
        ],
      },
      {
        which: 'SN',
        question: '洗澡时我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '什么也不想，只洗澡',
          },
          {
            type: 'N',
            score: 5,
            content: '在回音很大的卫生间里一边哼着小曲一边洗澡',
          },
        ],
      },
      {
        which: 'TF',
        question: '朋友拿出我第一次见到的神奇物品时我的反应是？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '哇！这是什么啊？',
          },
          {
            type: 'F',
            score: 5,
            content: '哇！好神奇啊',
          },
        ],
      },
      {
        which: 'TF',
        question: '要搬去新家了，在挑选新家家具时的我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '仔细考虑家具的便利性、价格、大小等等',
          },
          {
            type: 'F',
            score: 5,
            content: '考虑家具的设计是否好看，是否适合新家的装潢',
          },
        ],
      },
      {
        which: 'TF',
        question: '朋友感到很累，找我谈心时我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '倾听朋友的话并安慰他',
          },
          {
            type: 'F',
            score: 5,
            content: '倾听朋友的话之后想办法帮他解决',
          },
        ],
      },
      {
        which: 'JP',
        question: '有要去做的事却在和朋友们玩的话？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '心想着还有要去做的事情，玩得不舒心',
          },
          {
            type: 'P',
            score: 5,
            content: '反正已经在玩了就玩开心点喽',
          },
        ],
      },
      {
        which: 'JP',
        question: '和朋友约好要去A地区玩，结果朋友突然提议一起去B地区的话？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '已经有说好的计划却突然要改变，心里比较混乱',
          },
          {
            type: 'P',
            score: 5,
            content: '虽然有些突然，但反正决定去玩的意图一样，就说知道了',
          },
        ],
      },
      {
        which: 'JP',
        question: '我更喜欢？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '计划和秩序',
          },
          {
            type: 'P',
            score: 5,
            content: '可能性和新鲜事物',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterCN/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qnsdnlrlapdlzj',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterCN/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'rkstjqdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterCN/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dlsTkdkTk',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterCN/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'rPghlrdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterCN/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'qksgkddk',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterCN/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhalstkdekatk',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterCN/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'tkckdnjs',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterCN/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'wjswktkwjs',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterCN/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'akssmdwwnRns',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterCN/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'vmfhdlfrTlqfj',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterCN/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'thdnffltm',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterCN/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rkawjdzjsxmfhffj',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterCN/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'vorvhrfj',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterCN/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dosmfrdmsdl',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterCN/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'dbflapsxkf',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterCN/INFP.jpg',
      },
    ],
  },
  // characteristicChracterJP
  {
    info: {
      mainTitle: '性格キャラクターテスト',
      subTitle: 'キャラクターで見る私の性格は？',
      mainUrl: 'characteristicChracterJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/characteristicChracterJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/characteristicChracterJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '私と近いのは？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '人見知りがあまりない。',
          },
          {
            type: 'I',
            score: 5,
            content: '人と仲良くなるまで時間が長くかかる。',
          },
        ],
      },
      {
        which: 'EI',
        question: '友達に私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '連絡を先にするほうである。',
          },
          {
            type: 'I',
            score: 5,
            content: '来る連絡に答えるほうである。',
          },
        ],
      },
      {
        which: 'EI',
        question: '気分が良くないことが起きた時私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '外で友達に会いながら気分を晴らす。',
          },
          {
            type: 'I',
            score: 5,
            content: '家で静かに考えを整理してみる。',
          },
        ],
      },
      {
        which: 'SN',
        question: '出かけようとしたら急に雨が降ると私の考えは？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '雨だな、傘持っていこう。',
          },
          {
            type: 'N',
            score: 5,
            content:
              '雨降ると滑るけど、もしかして歩いてる途中転んだりはしないだろうな。',
          },
        ],
      },
      {
        which: 'SN',
        question:
          '団体で居酒屋に行った時、店員が狭く見えるトレーにビールを持って来ていると？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'いよいよビールが来るのかと思う。',
          },
          {
            type: 'N',
            score: 5,
            content:
              '危うい状態で私のテーブルに来てからビールをこぼす想像をしながら不安を感じる。',
          },
        ],
      },
      {
        which: 'SN',
        question: 'シャワーを浴びる時私は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '何も考えずただシャワーを浴びる。',
          },
          {
            type: 'N',
            score: 5,
            content:
              'エコーがかかってるシャワー室で歌を口ずさみながらシャワーを浴びる。',
          },
        ],
      },
      {
        which: 'TF',
        question: '友人が初めて見る珍しいものを取り出した時に私の反応は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'おぉ～何これ？',
          },
          {
            type: 'F',
            score: 5,
            content: 'おぉ～珍しい！',
          },
        ],
      },
      {
        which: 'TF',
        question: '新しい家に引っ越すとき、交換する家具らを探す時私は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '便利性、値段、大きさなどを細かく調べてみる。',
          },
          {
            type: 'F',
            score: 5,
            content:
              'デザインが素敵か、引っ越していく家とよく似合うかを考える。',
          },
        ],
      },
      {
        which: 'TF',
        question: '友人がつらいと言いながら悩みの相談をしてくると？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '聞いてみて解決してあげようとする。',
          },
          {
            type: 'F',
            score: 5,
            content: '聞いてあげて慰める。',
          },
        ],
      },
      {
        which: 'JP',
        question: 'やることがあるのに、友達と遊ぶと？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'まだやるべきことがあるという思いで気楽に遊べない。',
          },
          {
            type: 'P',
            score: 5,
            content: 'どうせ遊ぶこと、楽しめるだけ楽しむ。',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '友人がA地域に遊びに行くと約束したのに、急にB地域に行ってみようとすると？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '既に立てた計画があるのに混乱する。',
          },
          {
            type: 'P',
            score: 5,
            content:
              '急ではあるが、行こうとする意図は同じなので分かったという。',
          },
        ],
      },
      {
        which: 'JP',
        question: '私がもっと好むことは？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '計画と秩序。',
          },
          {
            type: 'P',
            score: 5,
            content: '可能性と新しさ。',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qnsdnlrlapdlzj',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'rkstjqdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dlsTkdkTk',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'rPghlrdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'qksgkddk',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhalstkdekatk',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'tkckdnjs',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'wjswktkwjs',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'akssmdwwnRns',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'vmfhdlfrTlqfj',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'thdnffltm',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rkawjdzjsxmfhffj',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'vorvhrfj',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dosmfrdmsdl',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'dbflapsxkf',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterJP/INFP.jpg',
      },
    ],
  },
  // maltese
  {
    info: {
      mainTitle: '말티즈 성격 테스트',
      subTitle: '말티즈로 보는 나의 성향은? | 말티즈 테스트',
      mainUrl: 'maltese',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/maltese-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/maltese-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '어떤 질문을 받았을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '질문에 대해서 생각하기 전에 우선 대답을 해야 함',
          },
          {
            type: 'I',
            score: 5,
            content: '질문에 대해 충분히 생각한 뒤 대답을 함',
          },
        ],
      },
      {
        which: 'EI',
        question: '취미생활을 하려고 할 때 어떤 취미를 고를까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '많은 사람들과 만날 수 있는 동호회에 가입한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '나 혼자서 즐길 수 있는 취미를 찾는다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '아무도 없는 집에 왔을 때 어떤 감정을 느낄까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '깜깜하고 너무 외롭다.',
          },
          {
            type: 'I',
            score: 5,
            content: '드디어 나만의 시간! 행복하다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '24시간 후에 죽게된다면 어떤 생각이 들까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '무서워ㅜㅜ 이게 대체 무슨일이야',
          },
          {
            type: 'N',
            score: 5,
            content: '사후세계가 진짜 있을까? 난 죽으면 어디로 갈라나…',
          },
        ],
      },
      {
        which: 'SN',
        question: '노래들을 때 더 좋아하는 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '멜로디',
          },
          {
            type: 'N',
            score: 5,
            content: '가사',
          },
        ],
      },
      {
        which: 'SN',
        question: '아무 생각을 안 할 수 있다? 없다?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '정말 아무 생각 안 하기 가능',
          },
          {
            type: 'N',
            score: 5,
            content: '아무 생각을 안하려고 해도 머릿속에 상상이 멈추지 않음',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '친구가 “나 오늘 너무 피곤해서 드라이샴푸로 머리 감았어” 했을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '드라이샴푸 좋아? 근데 그 시간이면 그냥 샴푸하는 게 낫지 않나?',
          },
          {
            type: 'F',
            score: 5,
            content: '오늘 피곤해? 어제 잘 못잤어',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '“너 별로 열심히 안하는 줄 알았는데 진짜 잘한다”라는 말을 들었다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '훗, 고마워^^(입꼬리 주체 불가. 너무 좋음)',
          },
          {
            type: 'F',
            score: 5,
            content: '너 왜 말을 그렇게 해ㅠㅠ? 나 진짜 열심히 했어',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 취업이 안돼 힘들어하는 상황에 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '무슨 준비하고 있는데? 이력서는 넣어봤어?',
          },
          {
            type: 'F',
            score: 5,
            content: '(곤란한 질문은 하지 말자) 힘들지ㅠㅠ',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 선호하는 나의 생활 루틴은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '반복되는 일상/업무 선호, 편-안',
          },
          {
            type: 'P',
            score: 5,
            content: '쳇바퀴 같은 인생은 질색! 매일매일 변화가 있어야 함',
          },
        ],
      },
      {
        which: 'JP',
        question: '나의 정리정돈 스타일은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '정리정돈이 잘 되어 있음, 메모장이나 컴퓨터 폴더도 깔끔',
          },
          {
            type: 'P',
            score: 5,
            content: '한번에 몰아서 치움, 폰메모장에 완성된 메모가 잘 없음',
          },
        ],
      },
      {
        which: 'JP',
        question: '약속을 앞두었을 때 내 준비스타일은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '2~3시간 전부터 준비',
          },
          {
            type: 'P',
            score: 5,
            content: '시간 다 돼서 준비, 약속 많이 바꿈',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'wlswlgks',
        img_src: 'https://images.ktestone.com/resultImages/maltese/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'wlrtjfwjrdls',
        img_src: 'https://images.ktestone.com/resultImages/maltese/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'rmdwjdwjrdls',
        img_src: 'https://images.ktestone.com/resultImages/maltese/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ghkfqkfgks',
        img_src: 'https://images.ktestone.com/resultImages/maltese/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ehehgks',
        img_src: 'https://images.ktestone.com/resultImages/maltese/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'dbajfjtmgks',
        img_src: 'https://images.ktestone.com/resultImages/maltese/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ekwjdgks',
        img_src: 'https://images.ktestone.com/resultImages/maltese/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'clswjfgks',
        img_src: 'https://images.ktestone.com/resultImages/maltese/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'rhwlqtmfjs',
        img_src: 'https://images.ktestone.com/resultImages/maltese/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gustlfwjrdls',
        img_src: 'https://images.ktestone.com/resultImages/maltese/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tnsenddl',
        img_src: 'https://images.ktestone.com/resultImages/maltese/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'qnRmfjawoddl',
        img_src: 'https://images.ktestone.com/resultImages/maltese/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'tlswndgks',
        img_src: 'https://images.ktestone.com/resultImages/maltese/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'snscl',
        img_src: 'https://images.ktestone.com/resultImages/maltese/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'rkatjdwjrdls',
        img_src: 'https://images.ktestone.com/resultImages/maltese/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'godqhrgks',
        img_src: 'https://images.ktestone.com/resultImages/maltese/INFP.jpg',
      },
    ],
  },
  // loveWarningES
  {
    info: {
      mainTitle: 'Test de la carta de advertencia del novizago',
      subTitle: 'Cuál es mi tendencia de citas de la carta de advertencia?',
      mainUrl: 'loveWarningES',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveWarningES-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveWarningES-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'ES',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question:
          'En una reunión donde hay muchos amigos/as del sexo opuesto, ¿qué harías?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Yo lidero conversaciones y hablo de manera activa',
          },
          {
            type: 'I',
            score: 5,
            content: 'Yo respondo bien a las preguntas y muestro empatías',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'Decidiste viajar con su novio en vacacciones largas después de mucho tiempo, ¿qué tipo de viaje harías?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Viaje alegre y divertido donde se puede activar energía',
          },
          {
            type: 'I',
            score: 5,
            content:
              'Viaje tranquilo y de recuperación donde se puede recargar energía',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'Si te encuentras a un/a amigo/a de tu novio/a por casualidad durante una cita, ¿qué harías?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Naturalmente hablas junto con él/ella',
          },
          {
            type: 'I',
            score: 5,
            content:
              'Solo le saludas y estás quieto sin hacer nada a lado de tu novio/a',
          },
        ],
      },
      {
        which: 'SN',
        question:
          'Te gusta un/a hombre/mujer y estabas hablando con él/ella mucho, pero de repente no puedes tener contacto con él/ella durante mucho tiempo. ¿Qué harías?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '¿Qué? ¿Está ocupado/a?',
          },
          {
            type: 'N',
            score: 5,
            content:
              '¿Qué? ¿Ya dejé de gustarle? ¿Ya no quiere mantener contacto conmigo?',
          },
        ],
      },
      {
        which: 'SN',
        question:
          'Después de ver una película, tu novio/a dice que quiere vivir la vida como la pelí. ¿Qué le dirías?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'No pasaría algo así. Pero si pasara, ¡me encantaría!',
          },
          {
            type: 'N',
            score: 5,
            content:
              'Alguna vez he pensado lo mismo, si pudiera vivir la vida como así, ¡me gustaría hacer muchas cosas!',
          },
        ],
      },
      {
        which: 'SN',
        question: `En un noviazgo largo, tu novio/a dice ‘ya hemos salido tanto tiempo’. ¿Qué dirías tú?`,
        answers: [
          {
            type: 'S',
            score: 2,
            content: '¡Ya ha pasado tanto tiempo! (El tiempo pasa volando)',
          },
          {
            type: 'N',
            score: 5,
            content:
              '¡Ya ha pasado tanto tiempo! (¿Yo podría casarme con esta persona algún día?)',
          },
        ],
      },
      {
        which: 'TF',
        question: 'Llegaste tarde a una cita por algo inevitable, ¿qué harías?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Primero le explico por qué llegué tarde, y luego le pido disculpa',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Primero le pido disculpa, y luego le explico por qué llegué tarde',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'Llevaste a tu novio/a a un restaurante popular, pero él/ella dice que no le gusta',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Le pregunto por qué no le gusta y si hay una razón justa, lo admito',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Puede ser, pero me siento malhumorado y creo que lo que dice es demasiado',
          },
        ],
      },
      {
        which: 'TF',
        question: 'Un amigo dice que va a casarse de repente.',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '¿De repente? ¿Por qué? ¿Con quién? ¿Qué pasó?',
          },
          {
            type: 'F',
            score: 5,
            content:
              '¿De repente? ¡Felicitaciones! ¡Esperaré la invitación de boda!',
          },
        ],
      },
      {
        which: 'JP',
        question: '¿Qué tipo de citas prefieres?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Una cita totalmente planeada',
          },
          {
            type: 'P',
            score: 5,
            content: 'Una cita improvisada, que tiene muchas posibilidades',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Te queda un día antes de la primera cita. ¿Qué harías?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Ya he reservado un restaurante. He planeado todo minuciosamente, como la cafetería que voy a visitar durante la cita, ropa que voy a usar, hasta cuándo voy a salir, etc.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'He reservado un restaurante, pero ya es mucho. Pienso que voy a usar cualquier ropa bonita mañana.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Has llegado a un restaurante que quería visitar, pero hay mucha cola y tienes que esperar mucho. ¿Qué harías?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Quiero que todo salga de aceurdo con mi pan, así que espero. O aunque me molesta un poco, voy a seguir mi plan b, otro restaurante que tenía en mente',
          },
          {
            type: 'P',
            score: 5,
            content: 'Aunque me da pena, busco otros restaurantes de inmediato',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningES/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningES/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'qnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningES/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningES/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningES/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningES/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningES/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningES/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'gksmftor',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningES/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tkfrntor',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningES/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'cjdfhrtor',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningES/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'skator',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningES/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'tnvtor',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningES/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ghltor',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningES/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'qkator',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningES/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningES/INFP.jpg',
      },
    ],
  },
  // characteristicChracterEng
  {
    info: {
      mainTitle: 'Personality character test',
      subTitle: 'What is your personality as a character?',
      mainUrl: 'characteristicChracterEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/characteristicChracterEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/characteristicChracterEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'Which one is close to you?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: "I'm not shy and get along with people easily",
          },
          {
            type: 'I',
            score: 5,
            content: 'I take a long time to get to know people',
          },
        ],
      },
      {
        which: 'EI',
        question: 'How do you do to your friends?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I tend to contact first',
          },
          {
            type: 'I',
            score: 5,
            content: 'I tend to reply only to incoming calls',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What do you do when something bad happens?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I try to go out to meet people to calm myself',
          },
          {
            type: 'I',
            score: 5,
            content: 'I quietly organize my thoughts at home',
          },
        ],
      },
      {
        which: 'SN',
        question:
          'If you are about to go out and it suddenly rains, what can be your reaction?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Oh, it’s raining. I need to bring an umbrella',
          },
          {
            type: 'N',
            score: 5,
            content: "I'm afraid I'll fall on the slippery road",
          },
        ],
      },
      {
        which: 'SN',
        question:
          'How would you react if a staff in a bar carelessly served you beer on a tiny tray?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Oh, here comes the beer',
          },
          {
            type: 'N',
            score: 5,
            content: 'I get anxious thinking about spilling on our table',
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do you do when you shower?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I just take a shower without thinking',
          },
          {
            type: 'N',
            score: 5,
            content:
              'I shower while humming a song in the echo-filled bathroom',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What can be your reaction when your friend brings out a strange object you never been before?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Wow, what is it?',
          },
          {
            type: 'F',
            score: 5,
            content: 'Wow, that looks amazing',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What can be your reaction when buying new furniture for your new home?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'I will carefully check the convenience, price, size, etc.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Oh, the design looks very nice and I will think how it will fit into my new home.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What would you do when your friend asks for advice about their problem?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I would listen and try to solve it',
          },
          {
            type: 'F',
            score: 5,
            content: 'I would listen and comfort',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What would you do if you had to spend time with friends while you had work to do?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: "I can't enjoy my time because of work worries",
          },
          {
            type: 'P',
            score: 5,
            content: 'I just enjoy the time anyway',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What would you do if your friend suddenly changed places?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'It would be confusing because we already have a plan',
          },
          {
            type: 'P',
            score: 5,
            content: 'Although it is sudden, I agree on it',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Which one do you prefer?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Plan and Order',
          },
          {
            type: 'P',
            score: 5,
            content: 'Possibility and Novelty',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qnsdnlrlapdlzj',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'rkstjqdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dlsTkdkTk',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'rPghlrdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'qksgkddk',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhalstkdekatk',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'tkckdnjs',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'wjswktkwjs',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'akssmdwwnRns',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'vmfhdlfrTlqfj',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'thdnffltm',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rkawjdzjsxmfhffj',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'vorvhrfj',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dosmfrdmsdl',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'dbflapsxkf',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracterEng/INFP.jpg',
      },
    ],
  },
  // loveWarningCN
  {
    info: {
      mainTitle: '恋爱警告文测试',
      subTitle: '从警告文看我的恋爱倾向是？',
      mainUrl: 'loveWarningCN',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveWarningCN-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveWarningCN-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'CN',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '异性朋友多的聚会中我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '主导聊天话题，主动跟他人说话',
          },
          {
            type: 'I',
            score: 5,
            content: '认真回答对方的问题并产生共鸣',
          },
        ],
      },
      {
        which: 'EI',
        question: '难得放假和恋人一起出行旅游的我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '充满活力开心愉快地享受旅游',
          },
          {
            type: 'I',
            score: 5,
            content: '当做给自己充电的机会，安静地享受旅游治愈自己',
          },
        ],
      },
      {
        which: 'EI',
        question: '和恋人约会的时候碰到恋人的朋友时我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '自然地一起聊天',
          },
          {
            type: 'I',
            score: 5,
            content: '只打招呼，然后静静地待在一旁',
          },
        ],
      },
      {
        which: 'SN',
        question: '经常联络的暧昧异性突然很长时间联络不上时我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '怎么搞的，难道很忙？',
          },
          {
            type: 'N',
            score: 5,
            content: '怎么回事？是不喜欢我了吗？TT还是突然潜水了..?TT',
          },
        ],
      },
      {
        which: 'SN',
        question: '和恋人看完电影后对方说自己也想过电影里的生活时我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '这种事情虽然不会发生，但如果发生的话就太好啦',
          },
          {
            type: 'N',
            score: 5,
            content:
              '我也有过这种想法，但如果我过上那种生活的话会想做很多事情！',
          },
        ],
      },
      {
        which: 'SN',
        question: `长期恋爱中恋人说“我们都交往这么久了呀！”的时候我会？`,
        answers: [
          {
            type: 'S',
            score: 2,
            content: '不知不觉都这么久了呀！（时间可真快）',
          },
          {
            type: 'N',
            score: 5,
            content:
              '不知不觉都这么久了呢！（到底什么时候才能和恋人结婚呢..?）',
          },
        ],
      },
      {
        which: 'TF',
        question: '和恋人约会的那天突然有急事导致会迟到时我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '先告诉对方迟到的理由然后道歉',
          },
          {
            type: 'F',
            score: 5,
            content: '先道歉然后再说明理由',
          },
        ],
      },
      {
        which: 'TF',
        question: '和恋人去自己常去的美食餐馆吃饭，但恋人却说不太好吃时我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '问对方为什么不好吃，如果理由恰当的话就承认',
          },
          {
            type: 'F',
            score: 5,
            content: '虽然有可能是恋人没胃口，但也觉得很伤心，有点过分',
          },
        ],
      },
      {
        which: 'TF',
        question: '朋友突然说要结婚了时我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '这么突然？为啥？和谁？什么嘛什么嘛？',
          },
          {
            type: 'F',
            score: 5,
            content: '这么突然？哇塞，恭喜你！记得给我请帖~',
          },
        ],
      },
      {
        which: 'JP',
        question: '我比较喜欢的约会方式是？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '从一到十列好计划的计划性约会',
          },
          {
            type: 'P',
            score: 5,
            content: '充满所有可能性的即兴约会',
          },
        ],
      },
      {
        which: 'JP',
        question: '和暧昧男/暧昧女的第一次约会的前一天我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '提前预约好餐厅，仔细查好咖啡厅的位置，选好要穿的衣服，仔细做好所有计划',
          },
          {
            type: 'P',
            score: 5,
            content: '只预约好餐厅，明天再选要穿的衣服，认为穿得简洁大方就好',
          },
        ],
      },
      {
        which: 'JP',
        question: '到了我想去的餐厅却发现排着长队时我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '不愿意打乱自己的计划而选择等候，或者转移到提前找好的第二个后补餐厅',
          },
          {
            type: 'P',
            score: 5,
            content: '虽然很可惜，但也决定赶紧找另外一个地方',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningCN/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningCN/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'qnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningCN/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningCN/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningCN/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningCN/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningCN/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningCN/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'gksmftor',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningCN/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tkfrntor',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningCN/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'cjdfhrtor',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningCN/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'skator',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningCN/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'tnvtor',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningCN/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ghltor',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningCN/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'qkator',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningCN/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningCN/INFP.jpg',
      },
    ],
  },
  // loveWarningJP
  {
    info: {
      mainTitle: '恋愛警告状テスト',
      subTitle: '警告状で見る私の恋愛性向は？',
      mainUrl: 'loveWarningJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveWarningJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveWarningJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'JP',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '異性の友達が多い集まりで私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '会話をリードし積極的に話す。',
          },
          {
            type: 'I',
            score: 5,
            content: '質問によく答えてあげながら、共感してあげる。',
          },
        ],
      },
      {
        which: 'EI',
        question: '久しぶりの連休に恋人と一緒に旅行に行くと私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'エネルギーを得られる楽しく面白い旅行',
          },
          {
            type: 'I',
            score: 5,
            content: 'エネルギーを充填する穏やかな癒しの旅行。',
          },
        ],
      },
      {
        which: 'EI',
        question: '恋人とデート中、恋人の友人に出会ったら？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '自然に一緒に話し合う。',
          },
          {
            type: 'I',
            score: 5,
            content: '挨拶だけして隣で静かに待つ。',
          },
        ],
      },
      {
        which: 'SN',
        question:
          '連絡を交わしていた関係築き中の異性が突然長時間連絡が取れない場合、私は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '何だ、忙しいのかな。',
          },
          {
            type: 'N',
            score: 5,
            content:
              '何だ、私の事いやになったのかな(涙)、それか急に音信不通(涙)。',
          },
        ],
      },
      {
        which: 'SN',
        question:
          '恋人が映画を見て、自分もああいう人生を生きてみたいと言うと？',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'そんなことは起きないだろうけど、起きたら本当に良さそうだね。',
          },
          {
            type: 'N',
            score: 5,
            content:
              '私も同じこと考えてみたけど、私がもしそういう人生を生きるとやりたい事がいっぱいある！',
          },
        ],
      },
      {
        which: 'SN',
        question: `長期恋愛中、恋人が“私達もう付き合ってこんなになったよ！”と言うと？`,
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'もうそんなになったのか！(時間の流れ早い)',
          },
          {
            type: 'N',
            score: 5,
            content:
              'もうそんなになったのか！(果たしてこの人と結婚までいけるかな。。)',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '恋人とデートの約束がある日、やむを得ない事情で約束に遅れたとき私は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '遅れた理由を先に話してから謝る。',
          },
          {
            type: 'F',
            score: 5,
            content: '先に謝ってから理由を話す。',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '前から知っている美味しいお店に恋人と訪ねた時、あまり美味しくないと言ったら？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'なぜ美味しくないか聞いて、理由が妥当だと認める。',
          },
          {
            type: 'F',
            score: 5,
            content: '口に合わないかも知れないが、がっかりするしひどいと思う。',
          },
        ],
      },
      {
        which: 'TF',
        question: '友人が急に結婚すると言うと？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '急に？なぜ？だれと？何何？',
          },
          {
            type: 'F',
            score: 5,
            content: '急に？すごい、おめでとう！私にも招待状送ってね。',
          },
        ],
      },
      {
        which: 'JP',
        question: '私が好むデートのスタイルは？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '一から十まで計画を立てた計画的なデート。',
          },
          {
            type: 'P',
            score: 5,
            content:
              '全ての可能性をオープンにしてアウトラインだけを決めた即興的なデート。',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '良い関係を築いてる途中の彼(彼女)との最初のデートの前日に私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'もうレストランは予約したし、行くカフェーの位置、着る服、時間まで徹底的に計画しておく。',
          },
          {
            type: 'P',
            score: 5,
            content:
              'レストランの予約はぎりぎりで終わらせ、服装は明日とにかくピシッとしたので着て行こうと思う。',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '自分が選んだ美味しいレストランに到着したけど、待ち時間があまりにも長いと？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '計画がずれるのが嫌で待つか、残念だけど予め探しておいた二番目のお店に移動する。',
          },
          {
            type: 'P',
            score: 5,
            content: '残念だけと早くほかのお店を探してみる。',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'qnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'gksmftor',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tkfrntor',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'cjdfhrtor',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'skator',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'tnvtor',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ghltor',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'qkator',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningJP/INFP.jpg',
      },
    ],
  },
  // hamster
  {
    info: {
      mainTitle: '햄스터 성격 테스트',
      subTitle: '햄스터로 보는 나의 성향은? | 햄스터 테스트',
      mainUrl: 'hamster',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/hamster-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/hamster-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '쳇바퀴를 돌릴 때 나는',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '같이 타야 재밌지~! 주변 햄스터들한테 연락한다',
          },
          {
            type: 'I',
            score: 5,
            content: '나 혼자 집중해서 타는게 좋아…굴려보자…탈탈탈…',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '밥 다먹고 핸드폰을 보니 친구에게 연락이 왔다. 이번 주말에 다같이 만나자고?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '완전 좋아! 이게 얼마만에 다같이 만나는거지? 빨리 만나고 싶다!',
          },
          {
            type: 'I',
            score: 5,
            content:
              '만나는건 좋은데 벌써 귀찮고 기 빨린다.. 일찍 만나고 일찍 헤어져야지..',
          },
        ],
      },
      {
        which: 'EI',
        question: '난 어느쪽에 가까운 햄스터일까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '약속? 없다면 만들어야지! 밖순이 햄스터',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자만의 시간을 즐기는 집순이 햄스터',
          },
        ],
      },
      {
        which: 'SN',
        question: '친구가 최근 내가 구매한 신상 장난감에 대해 어떤지 묻는다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '갖고 놀기엔 좋은데, 보관할 때 불편해.',
          },
          {
            type: 'N',
            score: 5,
            content: '음 괜찮은 것 같아! 재밌어!',
          },
        ],
      },
      {
        which: 'SN',
        question: '도착한 카페의 바닥이 조금 미끄럽다.',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '바닥이 좀 미끄럽네. 넘어지지 않게 조심해야겠다.',
          },
          {
            type: 'N',
            score: 5,
            content: '내가 넘어지면서 친구를 잡아버리면…같이 우당탕..',
          },
        ],
      },
      {
        which: 'SN',
        question: '친구들과 재밌게 놀고 집에 가서 씻고 누웠더니…',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '아 오늘 재밌었다. 다음엔 여기 가보자고 해야지',
          },
          {
            type: 'N',
            score: 5,
            content:
              '만약 우리가 갔던 카페에 연예인이 왔다면? 하고 상상해 본다',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '새로운 장난감을 둘 자리가 없다. 내 첫번째 장난감을 빼면 딱 공간이 남는데..',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '이제 필요 없으니 버려도 될 것 같아',
          },
          {
            type: 'F',
            score: 5,
            content: '안돼 이건 의미가 있으니까 추억으로 남겨둘래..',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '오랜만에 친구 햄스터를 만났다. 친구가 속 깊은 이야기를 털어놓는다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '그럴 땐 이렇게 해보는게 어때?',
          },
          {
            type: 'F',
            score: 5,
            content: '헉 그랬구나 ㅠ 괜찮아? 힘들었겠다 ㅠㅠ',
          },
        ],
      },
      {
        which: 'TF',
        question: '약속장소에 도착한 나! 근데 한 친구가 늦는다고 연락이 왔다.',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '알겠어! 얼마나 늦을 거 같아?',
          },
          {
            type: 'F',
            score: 5,
            content: '조심히 천천히 와 ㅠㅠ',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '열심히 쳇바퀴를 굴린 후 배가 고파졌다. 해바라기씨가 13개 있다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '지금 점심이니까 6개 먹고, 저녁에 7개 먹어야지.',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 배를 먼저 채워야지. 먹을 수 있는 만큼 먹어!',
          },
        ],
      },
      {
        which: 'JP',
        question: '얼마전에 주문한 택배가 도착했다. 가만보자 내가 뭘 샀더라…',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '가격과 기능을 따져 나에게 딱 필요한 것만.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '필요한 걸 사려고 보다가 예뻐 보이는 것도! (내가 이걸 샀었구나..?)',
          },
        ],
      },
      {
        which: 'JP',
        question: '핫플 장소에 친구가 같이 가보자고 하면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '언제, 어디서, 몇시에 만날지 정하자.',
          },
          {
            type: 'P',
            score: 5,
            content: '완전 좋아! 우리 맛있는 것도 먹고, 햄찌네컷도 찍자!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'dhksqurwndml',
        img_src: 'https://images.ktestone.com/resultImages/hamster/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dpsjwkdlwj',
        img_src: 'https://images.ktestone.com/resultImages/hamster/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dowjdrufvlq',
        img_src: 'https://images.ktestone.com/resultImages/hamster/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'wkdbdmldudghs',
        img_src: 'https://images.ktestone.com/resultImages/hamster/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ehrwowk',
        img_src: 'https://images.ktestone.com/resultImages/hamster/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'akdldnpdl',
        img_src: 'https://images.ktestone.com/resultImages/hamster/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dhwlfkv',
        img_src: 'https://images.ktestone.com/resultImages/hamster/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'rhkahfdlq',
        img_src: 'https://images.ktestone.com/resultImages/hamster/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'RhseofkEp',
        img_src: 'https://images.ktestone.com/resultImages/hamster/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'rhehr',
        img_src: 'https://images.ktestone.com/resultImages/hamster/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'rmflawk',
        img_src: 'https://images.ktestone.com/resultImages/hamster/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'apfxld',
        img_src: 'https://images.ktestone.com/resultImages/hamster/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ehsEktwnlal',
        img_src: 'https://images.ktestone.com/resultImages/hamster/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'xjfandcl',
        img_src: 'https://images.ktestone.com/resultImages/hamster/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'rhkeoakdtkd',
        img_src: 'https://images.ktestone.com/resultImages/hamster/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'snsanftoa',
        img_src: 'https://images.ktestone.com/resultImages/hamster/INFP.jpg',
      },
    ],
  },
  // loveWarningEng
  {
    info: {
      mainTitle: 'Love warning test',
      subTitle: 'What is my dating tendency as a warning letter?',
      mainUrl: 'loveWarningEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveWarningEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveWarningEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Eng',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question:
          'What would you do if you attended an event where there are lots of the opposite sex?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I would lead conversations and speak positively',
          },
          {
            type: 'I',
            score: 5,
            content:
              'I would be confident in answering questions and empathizing',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'If you were going on a trip with your lover after a long time,\nwhat kind of trip would you like to take?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'I would like to have an exciting and enjoyable trip where I can get energy.',
          },
          {
            type: 'I',
            score: 5,
            content:
              'I would like to go on a calm healing trip of refreshing myself',
          },
        ],
      },
      {
        which: 'EI',
        question:
          "What would you do if you were with your lover and you ran into your lover's friend?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I would just talk naturally',
          },
          {
            type: 'I',
            score: 5,
            content: 'I would just say hello and stay by the side',
          },
        ],
      },
      {
        which: 'SN',
        question:
          "What if the person you're dating for fun doesn't contact you?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: "What's up? Maybe busy?",
          },
          {
            type: 'N',
            score: 5,
            content: "What's up? Maybe bored? If not, why disappeared?",
          },
        ],
      },
      {
        which: 'SN',
        question:
          'If your lover wants to live a movie-like life after watching a movie,\nwhat can be your reaction?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              "I don't think that's going to happen\n but if it does it'd be great!",
          },
          {
            type: 'N',
            score: 5,
            content:
              'I thought about it as well.\nIf I could live that kind of life,\nI would expect lots of things.',
          },
        ],
      },
      {
        which: 'SN',
        question: `In a long-term relationship,\nhow would you react if your lover said,\n"We've been together for such a long time!"?`,
        answers: [
          {
            type: 'S',
            score: 2,
            content: "Yes, it's been a long time! (Time flies so quickly)",
          },
          {
            type: 'N',
            score: 5,
            content: 'Yes, it has been long time! (Should I get married?)',
          },
        ],
      },
      {
        which: 'TF',
        question: 'What do you do when you are unavoidably late for a date?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: "I'll explain why I'm late and apologize",
          },
          {
            type: 'F',
            score: 5,
            content: 'I will apologize first and tell the reason',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What would you do if you took your lover to a must-visit restaurant you know and your lover said it is not that tasty. ',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'I would ask why?\nAnd if it is reasonable, I would admit it',
          },
          {
            type: 'F',
            score: 5,
            content: 'It is okay but I would be a bit sad and angry',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'If your friend suddenly gets married,\nwhat can be your reaction?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: "Suddenly? why? with whom? What's going on?",
          },
          {
            type: 'F',
            score: 5,
            content:
              'Suddenly? Great congratulations!\nPlease take care of the wedding invitation letter~',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What kind of date do you like?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'A planned date in detail',
          },
          {
            type: 'P',
            score: 5,
            content: 'A not planed date or an impromptu date',
          },
        ],
      },
      {
        which: 'JP',
        question: 'How will you prepare for your first date?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I will prepare everything,\nsuch as restaurant reservations, cafes to go to, clothes to wear, etc.',
          },
          {
            type: 'P',
            score: 5,
            content:
              "I'll just make a restaurant reservation and simply decide what to wear",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What will you do when you go to a restaurant and the line is too long?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "I'll wait because I don't want to change it. But going to another restaurant I found beforehand could be my second choice",
          },
          {
            type: 'P',
            score: 5,
            content: "I'm sorry, but I quickly find another place",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'qnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'gksmftor',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tkfrntor',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'cjdfhrtor',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'skator',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'tnvtor',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ghltor',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'qkator',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarningEng/INFP.jpg',
      },
    ],
  },
  // characteristicChracter
  {
    info: {
      mainTitle: '성격 캐릭터 테스트',
      subTitle: '캐릭터로 보는 나의 성격은? | 성격 테스트',
      mainUrl: 'characteristicChracter',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/characteristicChracter-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/characteristicChracter-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '나와 가까운 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '낯가림이 별로 없다',
          },
          {
            type: 'I',
            score: 5,
            content: '사람들과 친해지는 시간이 오래 걸린다',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구들에게 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '연락을 먼저 하는 편이다',
          },
          {
            type: 'I',
            score: 5,
            content: '오는 연락에 답장하는 편이다',
          },
        ],
      },
      {
        which: 'EI',
        question: '기분이 좋지 않은 일이 생겼을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '밖에서 사람들을 만나면서 기분을 푼다',
          },
          {
            type: 'I',
            score: 5,
            content: '집에서 조용히 생각을 정리해본다',
          },
        ],
      },
      {
        which: 'SN',
        question: '나가려고 하는데 갑자기 비가 온다면 나의 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '비 오네 우산 챙겨야겠다',
          },
          {
            type: 'N',
            score: 5,
            content:
              '비 오면 바닥 미끄러운데 혹시나 길 가다 넘어지는 거 아니겠지?',
          },
        ],
      },
      {
        which: 'SN',
        question:
          '단체로 술집에 갔는데 직원이 좁아 보이는 쟁반에 맥주잔들을 들고 오고 있다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '이제 맥주 나오는구나 생각한다',
          },
          {
            type: 'N',
            score: 5,
            content:
              '위태롭게 오다가 우리 테이블 왔을 때 쏟는 상상을 하면서 불안해한다',
          },
        ],
      },
      {
        which: 'SN',
        question: '샤워할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '아무 생각 없이 그냥 샤워만 한다',
          },
          {
            type: 'N',
            score: 5,
            content: '에코가 빵빵한 화장실에서 노래를 흥얼거리면서 샤워한다',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 처음 보는 신기한 물건을 꺼냈을 때 나의 반응은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '와! 이게 뭐야?',
          },
          {
            type: 'F',
            score: 5,
            content: '와! 신기하다',
          },
        ],
      },
      {
        which: 'TF',
        question: '새집으로 이사 가면서 바꿀 가구들을 볼 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '편리성, 가격, 크기 등 꼼꼼히 따져본다',
          },
          {
            type: 'F',
            score: 5,
            content: '디자인이 예쁜지, 이사 갈 집과 잘 어울리는지를 생각해본다',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 힘들다며 고민 상담을 한다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '들어보고 해결해주려고 한다',
          },
          {
            type: 'F',
            score: 5,
            content: '들어주고 위로해 준다',
          },
        ],
      },
      {
        which: 'JP',
        question: '할 일이 있는데 친구들과 논다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '아직 할 일이 남아 있다는 생각에 편하게 놀지 못한다',
          },
          {
            type: 'P',
            score: 5,
            content: '어차피 노는 거 그냥 재밌게 논다',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '친구가 A 지역으로 놀러 가기로 약속했는데,\n갑자기 B 지역으로 가보자고 한다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '이미 세워놓은 계획이 있는데 혼란스럽다',
          },
          {
            type: 'P',
            score: 5,
            content: '갑작스럽긴 하지만 가려는 의도는 같으니, 알겠다고 한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 더 선호하는 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '계획과 질서',
          },
          {
            type: 'P',
            score: 5,
            content: '가능성과 새로움',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracter/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qnsdnlrlapdlzj',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracter/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'rkstjqdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracter/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dlsTkdkTk',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracter/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'rPghlrdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracter/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'qksgkddk',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracter/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhalstkdekatk',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracter/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'tkckdnjs',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracter/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'wjswktkwjs',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracter/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'akssmdwwnRns',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracter/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'vmfhdlfrTlqfj',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracter/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'thdnffltm',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracter/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rkawjdzjsxmfhffj',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracter/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'vorvhrfj',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracter/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dosmfrdmsdl',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracter/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'dbflapsxkf',
        img_src:
          'https://images.ktestone.com/resultImages/characteristicChracter/INFP.jpg',
      },
    ],
  },
  // meGuideEng
  {
    info: {
      mainTitle: 'Me Use Guide',
      subTitle: 'you should treat me like this',
      mainUrl: 'meGuideEng',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/meGuideEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/meGuideEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'Which of the following applies to you?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: "I get some energy when I'm with people",
          },
          {
            type: 'I',
            score: 5,
            content: 'I get recharged when I am alone',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What kind of overseas trip do you want?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'A trip that I can see famous cities and meet new people',
          },
          {
            type: 'I',
            score: 5,
            content:
              'A trip that I can be immersed in contemplation while looking at beautiful scenery',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What would be your reaction if an idol song you used to listen to came out in a crowded bar?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I get excited while singing along',
          },
          {
            type: 'I',
            score: 5,
            content: 'I tell my friend singing along to be quiet',
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do you do before going to sleep?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I go to sleep without thinking anything',
          },
          {
            type: 'N',
            score: 5,
            content: 'I fall asleep while imagining all kinds of things',
          },
        ],
      },
      {
        which: 'SN',
        question: 'When you buy new electronics, what do you do?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I look at the manual and just follow it',
          },
          {
            type: 'N',
            score: 5,
            content: 'I’m trying to get used to it by pressing this and that ',
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do you expect when you receive a new project at work?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I expect there are certain guidelines in detail',
          },
          {
            type: 'N',
            score: 5,
            content:
              'I expect there are simple guidelines or directions to follow',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'How do you react when you eat a delicious cookie made by your friend?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Wow, how did you make this? Are you sure you made it?',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Wow, this is so delicious! You must have worked hard to make it',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What is your reaction if your team is losing a sporting event?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I try to find a way to reverse it.',
          },
          {
            type: 'F',
            score: 5,
            content: 'Feeling sorry, I cheer our team by shouting “fighting”.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What if a system error causes you to lose an important job that needs to be submitted by tomorrow?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'I might get upset at the moment, but I quickly start over again',
          },
          {
            type: 'F',
            score: 5,
            content: "I'm mentally broken I'm upset and I barely write again",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What would you do if you were hanging out with your friends and you had to move somewhere else?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Let’s decide where to go first',
          },
          {
            type: 'P',
            score: 5,
            content: 'We can decide while walking',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What can be your reaction when plans go awry?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "You'll be angry and upset, but luckily, we have a plan B, so it's okay",
          },
          {
            type: 'P',
            score: 5,
            content:
              "It's embarrassing, but I have no choice but to look for something else",
          },
        ],
      },
      {
        which: 'JP',
        question: 'Who is more mysterious from your point of view?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'A friend who does not organize and improvises at random',
          },
          {
            type: 'P',
            score: 5,
            content:
              'A friend who has detailed schedules in minutes and seconds in an Excel file',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'mint',
        img_src: 'https://images.ktestone.com/resultImages/meGuideEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'yellow',
        img_src: 'https://images.ktestone.com/resultImages/meGuideEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'pink',
        img_src: 'https://images.ktestone.com/resultImages/meGuideEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'purple',
        img_src: 'https://images.ktestone.com/resultImages/meGuideEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'blue',
        img_src: 'https://images.ktestone.com/resultImages/meGuideEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'red',
        img_src: 'https://images.ktestone.com/resultImages/meGuideEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'orange',
        img_src: 'https://images.ktestone.com/resultImages/meGuideEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'olive',
        img_src: 'https://images.ktestone.com/resultImages/meGuideEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'burgundy',
        img_src: 'https://images.ktestone.com/resultImages/meGuideEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'beige',
        img_src: 'https://images.ktestone.com/resultImages/meGuideEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'navy',
        img_src: 'https://images.ktestone.com/resultImages/meGuideEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'grey',
        img_src: 'https://images.ktestone.com/resultImages/meGuideEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'khaki',
        img_src: 'https://images.ktestone.com/resultImages/meGuideEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'green',
        img_src: 'https://images.ktestone.com/resultImages/meGuideEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'brown',
        img_src: 'https://images.ktestone.com/resultImages/meGuideEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sky',
        img_src: 'https://images.ktestone.com/resultImages/meGuideEng/INFP.jpg',
      },
    ],
  },
  // meGuideJP
  {
    info: {
      mainTitle: '私の使い方のテスト',
      subTitle: '私をこうしてください | 私の使い方のテスト',
      mainUrl: 'meGuideJP',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/meGuideJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/meGuideJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '自分にもっと近いのは？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '人といるときエネルギが充電される。',
          },
          {
            type: 'I',
            score: 5,
            content: '一人でいるときエネルギが充電される。',
          },
        ],
      },
      {
        which: 'EI',
        question: '海外旅行に行くと私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '有名な都市らを観光し、新しい人々に出会える旅行',
          },
          {
            type: 'I',
            score: 5,
            content: '美しい景色を眺めながら思索に耽る旅行',
          },
        ],
      },
      {
        which: 'EI',
        question: '満員の居酒屋で昔よく聞いていたアイドルの曲が流れると？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '一緒に歌いながらテンションが上がる。',
          },
          {
            type: 'I',
            score: 5,
            content: '一緒に歌う友人に静かにしてと言う。',
          },
        ],
      },
      {
        which: 'SN',
        question: '眠る前に私は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '何も考えず眠りにつく。',
          },
          {
            type: 'N',
            score: 5,
            content: '様々な事を想像しながら眠りにつく。',
          },
        ],
      },
      {
        which: 'SN',
        question: '新しい電子製品を買った時に私は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'マニュアルを見て説明通りに使用してみる。',
          },
          {
            type: 'N',
            score: 5,
            content: 'まずは感であれこれ押しながら使ってみる。',
          },
        ],
      },
      {
        which: 'SN',
        question: '新しい業務を引き受けた時に私は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'ガイドラインが確実で、具体的に指示してくれることを望む。',
          },
          {
            type: 'N',
            score: 5,
            content: '方向性の提示と簡単な指示だけで、残りはまかせてほしい。',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '友人が作ったクッキーを食べて、すごく美味しかった時の私の反応は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'わあ～、これどうやって作ったの？本当に自分で作った？',
          },
          {
            type: 'F',
            score: 5,
            content: 'わあ～これすごく美味しい！お疲れ！',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'チームを分けて行うスポーツゲームで、私のチームが負けていると？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'どうすれば勝てるか方法を探して実行してみる。',
          },
          {
            type: 'F',
            score: 5,
            content:
              'メンバー達ががっかりしているかもしれないので励ましてファイトを叫ぶ。',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '明日まで出さなければならない重要な業務が途中システムエラーで吹っ飛んでしまったら？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '瞬間頭に来るが、素早く思い出せる部分から作成しなおす。',
          },
          {
            type: 'F',
            score: 5,
            content: 'メンタルが崩れて悲しんでから、やっと作成しなおす。',
          },
        ],
      },
      {
        which: 'JP',
        question: '友達と遊ぶ場所を移すとき私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'どこに行くか、何をするかをはっきり決めて出ようと言う。',
          },
          {
            type: 'P',
            score: 5,
            content: '一旦外に出てから決めようと言う。',
          },
        ],
      },
      {
        which: 'JP',
        question: '計画がずれた時に私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '頭に来て悔しいが、プランBを計画しておいたのでそれだけでも幸いだと思う。',
          },
          {
            type: 'P',
            score: 5,
            content: '困惑するが仕方ないので他の事を探してみる。',
          },
        ],
      },
      {
        which: 'JP',
        question: '私が見た時もっと不思議な友人は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '何も整理しないで流れるまま即興的に、何事もなく生きていく友人。',
          },
          {
            type: 'P',
            score: 5,
            content:
              '時間、分、秒単位で計画を立てエクセルで細かく日程を整理する友人。',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'mint',
        img_src: 'https://images.ktestone.com/resultImages/meGuideJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'yellow',
        img_src: 'https://images.ktestone.com/resultImages/meGuideJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'pink',
        img_src: 'https://images.ktestone.com/resultImages/meGuideJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'purple',
        img_src: 'https://images.ktestone.com/resultImages/meGuideJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'blue',
        img_src: 'https://images.ktestone.com/resultImages/meGuideJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'red',
        img_src: 'https://images.ktestone.com/resultImages/meGuideJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'orange',
        img_src: 'https://images.ktestone.com/resultImages/meGuideJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'olive',
        img_src: 'https://images.ktestone.com/resultImages/meGuideJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'burgundy',
        img_src: 'https://images.ktestone.com/resultImages/meGuideJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'beige',
        img_src: 'https://images.ktestone.com/resultImages/meGuideJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'navy',
        img_src: 'https://images.ktestone.com/resultImages/meGuideJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'grey',
        img_src: 'https://images.ktestone.com/resultImages/meGuideJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'khaki',
        img_src: 'https://images.ktestone.com/resultImages/meGuideJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'green',
        img_src: 'https://images.ktestone.com/resultImages/meGuideJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'brown',
        img_src: 'https://images.ktestone.com/resultImages/meGuideJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sky',
        img_src: 'https://images.ktestone.com/resultImages/meGuideJP/INFP.jpg',
      },
    ],
  },
  // smileColorTestCn
  {
    info: {
      mainTitle: '微笑约会测试',
      subTitle: '从笑脸看我的恋爱倾向 |  微笑约会测试',
      mainUrl: 'smileColorTestCn',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/smileColorTestCn-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/smileColorTestCn-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'CN',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '无所事事悠闲的周末我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '马上联系朋友们约时间见面。',
          },
          {
            type: 'I',
            score: 5,
            content: '一整天躺在床上休息。',
          },
        ],
      },
      {
        which: 'EI',
        question: '突然有出乎意料的约会时我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '真有意思！没什么特别的事的话会赴约。',
          },
          {
            type: 'I',
            score: 5,
            content: '不在我的计划之内..会找借口拒绝。',
          },
        ],
      },
      {
        which: 'EI',
        question: '朋友介绍参加某个聚会时我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '主动跟他人打招呼，尽量营造好气氛。',
          },
          {
            type: 'I',
            score: 5,
            content: '他人主动说话之前一直等待。',
          },
        ],
      },
      {
        which: 'SN',
        question: '坐上飞机准备去旅游时的我会想？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '啊，快点儿到就好了。',
          },
          {
            type: 'N',
            score: 5,
            content: '好期待哇！可是飞机起飞的原理是什么呢？',
          },
        ],
      },
      {
        which: 'SN',
        question: '吃饭时我的苦恼是？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '饭馆的两个我的最爱菜品中该选哪个呢？',
          },
          {
            type: 'N',
            score: 5,
            content: '吃着午饭就开始纠结晚饭该吃什么',
          },
        ],
      },
      {
        which: 'SN',
        question: '看歌唱竞选节目时的我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '哇~帅爆了！这个音源发布的话我会一整天都听TT',
          },
          {
            type: 'N',
            score: 5,
            content: '哇..这歌词是他的真实故事吗？可想而知他练习得有多努力',
          },
        ],
      },
      {
        which: 'TF',
        question: '听说抑郁的朋友说自己去购物时的我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '买什么啦？不会盲目地疯狂购物了吧？..',
          },
          {
            type: 'F',
            score: 5,
            content: '发生什么事了吗？怎么突然去购物了？',
          },
        ],
      },
      {
        which: 'TF',
        question: '朋友突然告诉我他考试合格时我的反应是？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '喔，什么考试呀？？总之祝贺你！',
          },
          {
            type: 'F',
            score: 5,
            content: '哇，祝贺祝贺！辛苦了辛苦了！',
          },
        ],
      },
      {
        which: 'TF',
        question: '女朋友珍惜的物件突然丢失时我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '怎么搞的怎么会丢了呀TT最后一次是在哪里看到的?',
          },
          {
            type: 'F',
            score: 5,
            content: '那是她非常珍惜的东西TT怎么办TT',
          },
        ],
      },
      {
        which: 'JP',
        question: '和恋人一起去美食餐馆时我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '提前预约好，也想好要吃什么菜品再出发。',
          },
          {
            type: 'P',
            score: 5,
            content: '临近约会日期的时候才着急地去预约。',
          },
        ],
      },
      {
        which: 'JP',
        question: '选择约会打扮时的我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '前一天就做好从头到脚的造型。',
          },
          {
            type: 'P',
            score: 5,
            content: '一边准备一边选自己相穿的衣服出门。',
          },
        ],
      },
      {
        which: 'JP',
        question: '决定和恋人一起去旅游时的我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '提前仔细列好每天的出行计划。',
          },
          {
            type: 'P',
            score: 5,
            content: '只要订好机票就觉得70%的准备已经做好了。',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'mint',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestCn/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'yellow',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestCn/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'pink',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestCn/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'purple',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestCn/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'blue',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestCn/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'red',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestCn/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'orange',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestCn/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'olive',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestCn/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'burgundy',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestCn/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'beige',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestCn/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'navy',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestCn/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'grey',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestCn/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'khaki',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestCn/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'green',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestCn/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'brown',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestCn/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sky',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestCn/INFP.jpg',
      },
    ],
  },
  // smileColorTestJP
  {
    info: {
      mainTitle: '笑顔の恋愛テスト',
      subTitle:
        'スマイルキャラクターで調べる私の恋愛性向は？ |  笑顔の恋愛テスト',
      mainUrl: 'smileColorTestJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/smileColorTestJP-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/smileColorTestJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'JP',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '特別な用事のない週末に私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '友達や知人にすぐ連絡して約束を取る。',
          },
          {
            type: 'I',
            score: 5,
            content: '一日中、ベッドに横になって休む。',
          },
        ],
      },
      {
        which: 'EI',
        question: '急に予定になかった約束ができると私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '楽しそう! 特別な用事がなければ約束に行く。',
          },
          {
            type: 'I',
            score: 5,
            content: '私の計画になかったのに。。。言い訳をする。',
          },
        ],
      },
      {
        which: 'EI',
        question: '友人の紹介である集まりに出たとき私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '先に主導的に話しかけ、雰囲気を盛り上げようと努力する。',
          },
          {
            type: 'I',
            score: 5,
            content: '人々が先に話しかけてくるまで待ってみる。',
          },
        ],
      },
      {
        which: 'SN',
        question: '旅行に行くため飛行機に乗った時、私が思うことは？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'あ～、早く着くといいな。',
          },
          {
            type: 'N',
            score: 5,
            content: '楽しそう！ところで飛行機はどういう原理で飛ぶだろう。',
          },
        ],
      },
      {
        which: 'SN',
        question: 'ご飯を食べるとき、私の悩みは？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '訪ねた食堂の最愛のメニュー二つの中で何を選ぶかの悩み。',
          },
          {
            type: 'N',
            score: 5,
            content: '昼食を食べながら夕飯は何にしようかなの悩み。',
          },
        ],
      },
      {
        which: 'SN',
        question: '音楽の競演プログラムを見たとき私は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'わぁ～最高だ。これ音源発売されると毎日聞くよ。',
          },
          {
            type: 'N',
            score: 5,
            content:
              'わぁ、この歌詞はあの人が経験した話かな。練習はどれほどやったのかな。',
          },
        ],
      },
      {
        which: 'TF',
        question: '友人が、憂鬱だったのでショッピングしたと言った時、私は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '何買ったの？なんでもかんでも買いまくってはいないよね。',
          },
          {
            type: 'F',
            score: 5,
            content: '何かあったの？急にショッピング？',
          },
        ],
      },
      {
        which: 'TF',
        question: '友人が急に試験に合格したと言った、私の反応は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'お～、何の試験だったの。おめでとう。',
          },
          {
            type: 'F',
            score: 5,
            content: 'お～、頑張ったね。お疲れ!',
          },
        ],
      },
      {
        which: 'TF',
        question: '彼女が突然大事にしていたものをなくした時、私は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'どうやって無くしてしまったの。最後に見たのはどこ？',
          },
          {
            type: 'F',
            score: 5,
            content: 'それ、君が大切にした物だったのに。。どうしよう。',
          },
        ],
      },
      {
        which: 'JP',
        question: '恋人と美味しいお店を訪ねようとする時、私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '前もって予約をして、何を食べるかメニューまで考えてから訪ねる。',
          },
          {
            type: 'P',
            score: 5,
            content: '約束の日が近づいた時、急いで探して予約する。',
          },
        ],
      },
      {
        which: 'JP',
        question: 'デート服を選ぶとき、私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '前日に頭から足先までセットしておく。',
          },
          {
            type: 'P',
            score: 5,
            content: '当日準備しながらひかれる服を着ていく。',
          },
        ],
      },
      {
        which: 'JP',
        question: '恋人と旅行に行こうとする時、私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '日別に計画を細かく、前もって立てておく。',
          },
          {
            type: 'P',
            score: 5,
            content: '飛行機のチケットさえ手に入れば7割は終わったと思う。',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'mint',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'yellow',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'pink',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'purple',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'blue',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'red',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'orange',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'olive',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'burgundy',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'beige',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'navy',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'grey',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'khaki',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'green',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'brown',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sky',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestJP/INFP.jpg',
      },
    ],
  },
  // loveWarning
  {
    info: {
      mainTitle: '연애 경고장 테스트',
      subTitle:
        '경고장으로 보는 나의 연애 성향은? | 경고 테스트 | 연애 경고 테스트',
      mainUrl: 'loveWarning',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/loveWarning-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/loveWarning-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '이성 친구들이 많은 모임에서 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '대화를 이끌고 적극적으로 이야기한다',
          },
          {
            type: 'I',
            score: 5,
            content: '질문에 잘 대답해 주고 공감해 준다',
          },
        ],
      },
      {
        which: 'EI',
        question: '간만에 찾아온 연휴 연인과 함께 여행을 간다면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '에너지를 얻는 신나고 재밌는 여행',
          },
          {
            type: 'I',
            score: 5,
            content: '에너지를 충전하는 차분한 힐링 여행',
          },
        ],
      },
      {
        which: 'EI',
        question: '연인과 데이트 중 연인의 친구를 마주쳤다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '자연스럽게 같이 이야기한다',
          },
          {
            type: 'I',
            score: 5,
            content: '인사만 하고 옆에서 가만히 있는다',
          },
        ],
      },
      {
        which: 'SN',
        question:
          '연락을 주고받던 썸 타던 이성이 갑자기 장시간 연락이 되지 않는다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '뭐지 바쁜가?',
          },
          {
            type: 'N',
            score: 5,
            content: '뭐지? 내가 싫어졌나?ㅠ 아니면 갑자기 잠수..?ㅠㅠ',
          },
        ],
      },
      {
        which: 'SN',
        question: '연인이 영화를 보고 나도 저런 삶을 살아보고 싶다고 한다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '그런 일은 일어나지 않겠지만, 일어난다면 진짜 좋긴 좋겠다!',
          },
          {
            type: 'N',
            score: 5,
            content:
              '나도 그런 생각해봤는데, 나는 그런 삶을 산다면 하고 싶은 게 많아!',
          },
        ],
      },
      {
        which: 'SN',
        question:
          '장기연애 중 연인이 “우리 벌써 사귄 지 이만큼이나 됐어!”라고 한다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '벌써 그렇게 됐구나! (시간 참 빠르다)',
          },
          {
            type: 'N',
            score: 5,
            content: '벌써 그렇게 됐네! (과연 애인과 결혼까지 할 수 있을까..?)',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '연인과 데이트 약속이 있는 날 피치 못할 사정으로 약속에 늦었을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '늦은 이유를 먼저 말하고 사과한다',
          },
          {
            type: 'F',
            score: 5,
            content: '사과를 먼저 하고 이유를 말한다',
          },
        ],
      },
      {
        which: 'TF',
        question: '연인과 아는 맛집에 갔는데, 연인이 별로 맛이 없다고 한다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜 맛이 없는지 물어보고, 이유가 타당하면 인정한다',
          },
          {
            type: 'F',
            score: 5,
            content: '입맛에 안 맞을 수 있지만 서운하고, 너무하다고 생각한다',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 갑자기 결혼을 한다고 한다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '갑자기? 왜? 누구랑? 뭐야 뭐야?',
          },
          {
            type: 'F',
            score: 5,
            content: '갑자기? 대박 축하해! 내 청첩장 잘 챙겨 놔~',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 선호하는 데이트 방식은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '하나에서 열까지 계획을 세워 둔 계획적인 데이트',
          },
          {
            type: 'P',
            score: 5,
            content: '모든 가능성을 열어 둔 틀만 정한 즉흥적인 데이트',
          },
        ],
      },
      {
        which: 'JP',
        question: '썸남 썸녀와의 첫 데이트 전날 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '이미 맛집은 예약했고, 갈 카페 위치, 입을 옷, 시간까지 철저하게 계획해 놓는다',
          },
          {
            type: 'P',
            score: 5,
            content:
              '맛집 예약만 겨우 하고, 옷은 내일 그냥 깔끔하게만 입자고 생각한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 가려고 했던 맛집에 도착했는데 웨이팅이 너무 길다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '계획이 틀어지는 게 싫어 기다리거나, 아쉽지만 미리 찾아 놓은 두 번째 맛집으로 옮긴다',
          },
          {
            type: 'P',
            score: 5,
            content: '아쉽지만 빠르게 다른 곳을 찾아본다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'dusen',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarning/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarning/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'qnsghd',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarning/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'wnghkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarning/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'Qkfrks',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarning/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'vkfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarning/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'chfhr',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarning/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'shfks',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarning/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'gksmftor',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarning/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tkfrntor',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarning/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'cjdfhrtor',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarning/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'skator',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarning/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'tnvtor',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarning/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ghltor',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarning/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'qkator',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarning/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src:
          'https://images.ktestone.com/resultImages/loveWarning/INFP.jpg',
      },
    ],
  },
  // meGuide
  {
    info: {
      mainTitle: '나 사용법 가이드 테스트',
      subTitle:
        '나를 이렇게 대해줘야 합니다 | 나 사용법 가이드 테스트 with 스마일 | 나 사용 가이드 테스트 with 스마일 | 나 테스트',
      mainUrl: 'meGuide',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/meGuide-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/meGuide-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '나와 더 가까운 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '사람들과 있을 때 에너지가 충전된다',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자 있을 때 에너지가 충전된다',
          },
        ],
      },
      {
        which: 'EI',
        question: '해외여행을 간다면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '유명한 도시들을 구경하며 새로운 사람들과 만날 수 있는 여행',
          },
          {
            type: 'I',
            score: 5,
            content: '아름다운 풍경을 보며 사색에 잠길 수 있는 여행',
          },
        ],
      },
      {
        which: 'EI',
        question: '사람 많은 술집에서 예전에 즐겨 듣던 아이돌 노래가 나온다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '따라 부르면서 흥에 취한다',
          },
          {
            type: 'I',
            score: 5,
            content: '따라 부르는 친구에게 조용히 하라고 한다',
          },
        ],
      },
      {
        which: 'SN',
        question: '잠들기 전 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '아무 생각 없이 잠을 청한다',
          },
          {
            type: 'N',
            score: 5,
            content: '온갖 상상을 하다가 잠든다',
          },
        ],
      },
      {
        which: 'SN',
        question: '새로운 전자제품을 샀을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '설명서를 보고 설명서 대로 사용해 본다',
          },
          {
            type: 'N',
            score: 5,
            content: '일단 감으로 이것저것 누르면서 사용해 본다',
          },
        ],
      },
      {
        which: 'SN',
        question: '새로운 업무를 받을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '가이드라인이 확실히 있고,\n구체적으로 지시해 주는 것을 원한다',
          },
          {
            type: 'N',
            score: 5,
            content: '방향 제시와 간단한 지시만 하고 맡겨주는 것을 원한다',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 만든 쿠키를 먹었는데 너무 맛있을 때 나의 반응은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '와 이거 어떻게 만듦?\n진짜 너가 만든 거 맞아?',
          },
          {
            type: 'F',
            score: 5,
            content: '와 이거 너무 맛있다! 만드느라 고생했겠다',
          },
        ],
      },
      {
        which: 'TF',
        question: '팀을 나눠서 하는 스포츠 게임 중 우리 팀이 지고 있다면 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '어떻게 하면 뒤집을 수 있을지 방법을 찾아서 실행해 본다',
          },
          {
            type: 'F',
            score: 5,
            content: '팀원들이 속상해할까 봐 격려하고 파이팅을 외쳐본다',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '내일까지 내야 하는 중요한 업무가 중간에 시스템 오류로 날아갔다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '순간 열이 받지만 빠르게 기억나는 부분부터 다시 시작한다',
          },
          {
            type: 'F',
            score: 5,
            content: '멘탈 부서지고 속상해하다가 겨우겨우 다시 작성한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구들과 노는 장소를 옮길 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '어디 갈지 무엇을 할 건지 확실히 정하고 나가자고 한다',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 나가서 보면서 정하자고 한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '계획이 틀어졌을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '화나고 속상하지만 플랜 B를 계획해 놔서 그나마 다행이라고 생각한다',
          },
          {
            type: 'P',
            score: 5,
            content: '당황스럽지만 어쩔 수 없다 다른 것을 찾아본다',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 봤을 때 더 신기한 친구는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '뭐든 정리 1도 안 하고 흘러가는 대로 즉흥적으로 잘 살아가는 친구',
          },
          {
            type: 'P',
            score: 5,
            content:
              '시간, 분, 초 단위로 계획해서 엑셀로 세세하게 일정을 정리하는 친구',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'mint',
        img_src: 'https://images.ktestone.com/resultImages/meGuide/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'yellow',
        img_src: 'https://images.ktestone.com/resultImages/meGuide/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'pink',
        img_src: 'https://images.ktestone.com/resultImages/meGuide/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'purple',
        img_src: 'https://images.ktestone.com/resultImages/meGuide/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'blue',
        img_src: 'https://images.ktestone.com/resultImages/meGuide/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'red',
        img_src: 'https://images.ktestone.com/resultImages/meGuide/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'orange',
        img_src: 'https://images.ktestone.com/resultImages/meGuide/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'olive',
        img_src: 'https://images.ktestone.com/resultImages/meGuide/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'burgundy',
        img_src: 'https://images.ktestone.com/resultImages/meGuide/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'beige',
        img_src: 'https://images.ktestone.com/resultImages/meGuide/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'navy',
        img_src: 'https://images.ktestone.com/resultImages/meGuide/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'grey',
        img_src: 'https://images.ktestone.com/resultImages/meGuide/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'khaki',
        img_src: 'https://images.ktestone.com/resultImages/meGuide/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'green',
        img_src: 'https://images.ktestone.com/resultImages/meGuide/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'brown',
        img_src: 'https://images.ktestone.com/resultImages/meGuide/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sky',
        img_src: 'https://images.ktestone.com/resultImages/meGuide/INFP.jpg',
      },
    ],
  },
  // memoEng
  {
    info: {
      mainTitle: 'Note Love Test',
      subTitle:
        'What is my dating tendency in terms of notes? | Memo test | Note test',
      mainUrl: 'memoEng',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/memoEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/memoEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Eng',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question:
          'What would you do if your lover suddenly wanted to meet his/her friend with you?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: "It is okay!\nLet's have fun together!",
          },
          {
            type: 'I',
            score: 5,
            content:
              "Suddenly? Well, that sounds a bit inconvenient,\ndon't you think?",
          },
        ],
      },
      {
        which: 'EI',
        question:
          'When the way home overlaps with someone who you are interested in,\nwhat will you do?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'I actively lead the conversation and ask some curious questions',
          },
          {
            type: 'I',
            score: 5,
            content: 'I will be a good listener',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'If you had the opportunity to travel,\nwhere would you like to go?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'I would like to visit famous tourist spots\nwhere I can interact with many people',
          },
          {
            type: 'I',
            score: 5,
            content:
              'I would expect a trip that I can enjoy nature and my own time',
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What can be your reaction when someone you have a crush on sends you a note?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'I will be thrilled and curious about what it will be about',
          },
          {
            type: 'N',
            score: 5,
            content: 'a note ?!..><\nI may imagine dating this person',
          },
        ],
      },
      {
        which: 'SN',
        question:
          'If you left your own home and went looking for a house,\nwhat would you do?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I would check all details of the house condition.',
          },
          {
            type: 'N',
            score: 5,
            content:
              'I would check the brightness,\nsize and coziness of the room.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do I think about in the early days of a relationship?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I should be happy and get along well~',
          },
          {
            type: 'N',
            score: 5,
            content:
              'I wonder if I can be happy for a long time\nwithout fighting with this person?',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What would you do if your lover was suddenly startled\nand spit out food while eating?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'What happen?\nAre you okay?',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Are you okay? You must have been very surprised,\ncan I get you some water?',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'When your lover is 30 minutes late for an appointment with you,\nwhat can be your reaction?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: "I may be a little sad and angry,\nbut I'll ask why.",
          },
          {
            type: 'F',
            score: 5,
            content:
              "I'm going to be emotionally upset because it makes me sad.",
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What do you want to hear when\ngiving your lover an anniversary gift?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'This is great and\nI am very impressed',
          },
          {
            type: 'F',
            score: 5,
            content: 'Thank you! I wanted this.\nYou are the best',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What do you do when the restaurant you want to go to\nwith your lover suddenly closes?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I may be upset because my original plan has been changed,\nbut I will try other restaurant without panic.',
          },
          {
            type: 'P',
            score: 5,
            content:
              "I'm sorry, but I can't help it,\nso I look for another place nearby.",
          },
        ],
      },
      {
        which: 'JP',
        question: 'What kind of date do you like?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I like dates booked through\nrestaurant, cafe, and movie reservations',
          },
          {
            type: 'P',
            score: 5,
            content: 'I like an impromptu date',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What would you do when your lover suddenly asked you to watch another movie?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I might be a little uncomfortable\nwith the thought of being so sudden,\nbut I watch any movie reluctantly ',
          },
          {
            type: 'P',
            score: 5,
            content:
              "I don't mind that much,\nso I'm willing to watch anything",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/memoEng/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/memoEng/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'qnsghd',
        img_src: 'https://images.ktestone.com/resultImages/memoEng/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/memoEng/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/memoEng/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/memoEng/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/memoEng/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/memoEng/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'gksmftor',
        img_src: 'https://images.ktestone.com/resultImages/memoEng/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tkfrntor',
        img_src: 'https://images.ktestone.com/resultImages/memoEng/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'cjdfhrtor',
        img_src: 'https://images.ktestone.com/resultImages/memoEng/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'skator',
        img_src: 'https://images.ktestone.com/resultImages/memoEng/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'tnvtor',
        img_src: 'https://images.ktestone.com/resultImages/memoEng/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ghltor',
        img_src: 'https://images.ktestone.com/resultImages/memoEng/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'qkator',
        img_src: 'https://images.ktestone.com/resultImages/memoEng/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/memoEng/INFP.png',
      },
    ],
  },
  // holidayLove
  {
    info: {
      mainTitle: '명절 연애 테스트',
      subTitle: '명절 스타일로 알아보는 연애 테스트 | 명절 테스트',
      mainUrl: 'holidayLove',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/holidayLove-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/holidayLove-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '와! 명절이다 명절날 공휴일에 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '명절 연휴는 당연히 친적들 보러 가야지! 너무 보고싶어',
          },
          {
            type: 'I',
            score: 5,
            content: '오랜만에 긴 연휴다.. 조용히 나 쉬는게 더 좋아',
          },
        ],
      },
      {
        which: 'EI',
        question: '큰집에 가게 된 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '요즘 나의 근황은 이렇고 저렇고 여럿이 모여서 이야기하는거 좋아!',
          },
          {
            type: 'I',
            score: 5,
            content: '예의있게 인사 드린 후 방안에서 스마트폰이랑 친구하기',
          },
        ],
      },
      {
        which: 'EI',
        question: '큰집 어르신이 모두 모여서 윳놀이 하자고 하실 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '재미있겠다! 내가 다 이길꺼야',
          },
          {
            type: 'I',
            score: 5,
            content: '사람도 많은데 이걸 굳이 다 같이 해야하나ㅠㅠ?',
          },
        ],
      },
      {
        which: 'SN',
        question: '오랜만에 만나서 반갑다고 용돈을 주셨을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '오 용돈 신나!',
          },
          {
            type: 'N',
            score: 5,
            content: '아 이걸로 뭐하지? 옷을 살까? 친구를 만날까?',
          },
        ],
      },
      {
        which: 'SN',
        question: '명절 음식을 하게 된 나 음식을 할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '레시피에 나온 정량대로 넣어서 음식을 만든다.',
          },
          {
            type: 'N',
            score: 5,
            content: '“대충 이정도 넣으면 되겠지” 생각하며 손대중으로 넣는다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '친구가 명절에 어땠냐고 물어볼 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '오랜만에 어르신들 인사드리고 재미있었어',
          },
          {
            type: 'N',
            score: 5,
            content: '윳놀이도 하고! 음식은 진짜 맛있었고! 용돈도 받았어!',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 명절에 혼자 보내야 한다고, 우울해 할 때 내 반응은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '혼자 심심하겠다',
          },
          {
            type: 'F',
            score: 5,
            content: '아 나라도 같이 있어줘야하나 외롭겠다..ㅠ',
          },
        ],
      },
      {
        which: 'TF',
        question: '오랜만에 친적들을 만났을 때 더 듣고 싶은 말은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '용돈 줄 테니 받아가라',
          },
          {
            type: 'F',
            score: 5,
            content: '올해는 모든 일이 다 잘 될 거다',
          },
        ],
      },
      {
        which: 'TF',
        question: '귀경길 친구가 차 사고가 났다고 했을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '괜찮아? 너 보험은 들어 놨어? 어떻게 사고가 난거야??',
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮아? 어디 다친 곳은 없어??ㅠ',
          },
        ],
      },
      {
        which: 'JP',
        question: '친척들과 저녁 외식을 위해 맛집을 찾게 된 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '어떤 식당인지 검색해서 리뷰, 별점, 후기 등을 찾아보고 심사숙고해서 방문한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '음식점 썸네일 이미지만 봐도 맛집인지 아닌지 느낌이 온다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '부모님이 명절에 받은 용돈으로 계획을 짜라 할때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '세세하게 하고 싶은거 사고 싶은거 정리해서 보여드린다.',
          },
          {
            type: 'P',
            score: 5,
            content: '금액에 맞는 사고 싶은 물건 하나를 말한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '이제 집에 돌아갈 시간이다 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '교통편, 차 안막히는 시간, 어느길로 가는게 좋은지 세세하게 검색해본다.',
          },
          {
            type: 'P',
            score: 5,
            content: '그냥 네비가 안내하는데로 간다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'ghqkrwjs',
        img_src:
          'https://images.ktestone.com/resultImages/holidayLove/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'Ejrrnr',
        img_src:
          'https://images.ktestone.com/resultImages/holidayLove/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'qhfmaekf',
        img_src:
          'https://images.ktestone.com/resultImages/holidayLove/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'rkdrkdtnffo',
        img_src:
          'https://images.ktestone.com/resultImages/holidayLove/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'dus',
        img_src:
          'https://images.ktestone.com/resultImages/holidayLove/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'rkfqlWla',
        img_src:
          'https://images.ktestone.com/resultImages/holidayLove/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rnfql',
        img_src:
          'https://images.ktestone.com/resultImages/holidayLove/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dbcshfdl',
        img_src:
          'https://images.ktestone.com/resultImages/holidayLove/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'rhwrka',
        img_src:
          'https://images.ktestone.com/resultImages/holidayLove/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'qka',
        img_src:
          'https://images.ktestone.com/resultImages/holidayLove/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'eocn',
        img_src:
          'https://images.ktestone.com/resultImages/holidayLove/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'tlrgP',
        img_src:
          'https://images.ktestone.com/resultImages/holidayLove/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'Rhclwjs',
        img_src:
          'https://images.ktestone.com/resultImages/holidayLove/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dirrhk',
        img_src:
          'https://images.ktestone.com/resultImages/holidayLove/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'cjdwn',
        img_src:
          'https://images.ktestone.com/resultImages/holidayLove/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'thdvus',
        img_src:
          'https://images.ktestone.com/resultImages/holidayLove/INFP.jpg',
      },
    ],
  },
  // proud
  {
    info: {
      mainTitle: '자부심 테스트',
      subTitle: '내 자부심은 몇 cm일까?',
      mainUrl: 'proud',
      scoreType: 'numberScoring',
      mainImage: 'https://images.ktestone.com/introImages/proud-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/proud-thumb.png',
      lang: 'Kor',
      category: 'etc',
    },
    questions: [
      {
        question: '나는 사람들 앞에 나서는게 부끄럽지 않고 떳떳하다.',
        answers: [
          {
            type: '사자',
            score: 1,
            content: '당연하지! 부끄러울 게 뭐가 있어!',
          },
          {
            type: '너구리',
            score: 0,
            content: '좀 민망해…ㅠ 되도록 안 나서고 싶어.',
          },
        ],
      },
      {
        question: '내 자부심은 크다고 생각한다.',
        answers: [
          {
            type: '사자',
            score: 1,
            content: '어마어마하지! 어디 가서도 안 꿀릴 걸?',
          },
          {
            type: '너구리',
            score: 0,
            content: '어디 내세우긴 아직… 좀 더 키워야 할 듯!',
          },
        ],
      },
      {
        question: '새로운 분야에 도전할 때 나의 마음가짐은?',
        answers: [
          {
            type: '사자',
            score: 1,
            content: '재밌겠다! 흥분되고 기대돼!',
          },
          {
            type: '너구리',
            score: 0,
            content: '잘할 수 있을까… 벌써 걱정되네ㅠ',
          },
        ],
      },
      {
        question: '나는 주변에서 자부심이 강하다는 소리를 여러 번 들어봤다.',
        answers: [
          {
            type: '사자',
            score: 1,
            content: '그렇다.',
          },
          {
            type: '너구리',
            score: 0,
            content: '아니다.',
          },
        ],
      },
      {
        question: '어려운 과제에 직면했을 때 나는?',
        answers: [
          {
            type: '사자',
            score: 1,
            content: '“일단 해보자!”, 시작부터 하고 보는 편',
          },
          {
            type: '너구리',
            score: 0,
            content: '“어떻게 해결해야하지…”, 시작하기 전에 걱정부터 하는 편',
          },
        ],
      },
      {
        question: '직업을 선택할 때 나한테 가장 중요한 요소는?',
        answers: [
          {
            type: '사자',
            score: 1,
            content: '자부심을 가질만한 명예와 직무',
          },
          {
            type: '너구리',
            score: 0,
            content: '높은 연봉과 복지',
          },
        ],
      },
      {
        question: '나는 내가 어떤일이든 해낼 수 있을거라고 생각한다.',
        answers: [
          {
            type: '사자',
            score: 1,
            content: '그렇다.',
          },
          {
            type: '너구리',
            score: 0,
            content: '아니다.',
          },
        ],
      },
      {
        question: '누군가가 내가 이뤄낸 업적을 폄하할 때 나는?',
        answers: [
          {
            type: '사자',
            score: 1,
            content:
              '“음… 저렇게 생각할 수도 있구나?, 그래도 내가 이뤄낸건 대단해!”',
          },
          {
            type: '너구리',
            score: 0,
            content: '“다른사람들도 저렇게 생각하려나…ㅠ”',
          },
        ],
      },
      {
        question: '미래를 계획할 때 나는?',
        answers: [
          {
            type: '사자',
            score: 1,
            content: '10년뒤까지 생각하며 짜는 편',
          },
          {
            type: '너구리',
            score: 0,
            content: '눈 앞에 보이는 사소한 계획부터 짜는 편',
          },
        ],
      },
      {
        question: '누군가가 나에게 “넌 꼭 성공할거야”라고 했을 때 나는?',
        answers: [
          {
            type: '사자',
            score: 1,
            content: '“당연한 소릴! 난 무조건 성공해!”',
          },
          {
            type: '너구리',
            score: 0,
            content: ' “내가? 성공까진 어려울 것 같은데…”',
          },
        ],
      },
    ],
    results: [
      {
        type: '',
        desc: '',
        query: 'dktldkrmq',
        img_src: 'https://images.ktestone.com/resultImages/proud/dktldkrmq.png',
      },
      {
        type: '',
        desc: '',
        query: 'dhtpdksldk',
        img_src:
          'https://images.ktestone.com/resultImages/proud/dhtpdksldk.png',
      },
      {
        type: '',
        desc: '',
        query: 'dbfjqrmq',
        img_src: 'https://images.ktestone.com/resultImages/proud/dbfjqrmq.png',
      },
      {
        type: '',
        desc: '',
        query: 'skadkapflzk',
        img_src:
          'https://images.ktestone.com/resultImages/proud/skadkapflzk.png',
      },
      {
        type: '',
        desc: '',
        query: 'dkvmflzk',
        img_src: 'https://images.ktestone.com/resultImages/proud/dkvmflzk.png',
      },
    ],
  },
  // despicable
  {
    info: {
      mainTitle: '싸가지 테스트',
      subTitle:
        '너 싸가지 없는 거야, 알아? | 싸가지 테스트 | 재수 테스트 | 재수 없음 테스트',
      mainUrl: 'despicable',
      scoreType: 'numberScoring',
      mainImage: 'https://images.ktestone.com/introImages/despicable-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/despicable-thumb.png',
      lang: 'Kor',
      category: 'etc',
    },
    questions: [
      {
        question: '친구와의 약속에서 10분정도 늦었다, 친구의 질책에 나는?',
        answers: [
          {
            type: '사자',
            score: 1,
            content: '‘10분 정도는 늦을수도 있지 않나…’ 라고 생각한다.',
          },
          {
            type: '너구리',
            score: 0,
            content: '웃으면서 ‘아 미안미안~’ 하며 넘어간다.',
          },
        ],
      },
      {
        question: '친구들한테 싸가지 없단 소리를 종종 듣곤한다.',
        answers: [
          {
            type: '사자',
            score: 1,
            content: '그렇다.',
          },
          {
            type: '너구리',
            score: 0,
            content: '아니다.',
          },
        ],
      },
      {
        question: '다른사람과 식당에 갔을 때 나는?',
        answers: [
          {
            type: '사자',
            score: 1,
            content: '먼저 수저를 놓거나 물을 따라주는 편이다.',
          },
          {
            type: '너구리',
            score: 0,
            content: '딱히 별다른 생각이 없는 편이다.',
          },
        ],
      },
      {
        question: '친구가 재미없는 얘기를 했을 때 내 대답은?',
        answers: [
          {
            type: '사자',
            score: 1,
            content: '“진짜 개노잼이다 너ㅋㅋㅋ”',
          },
          {
            type: '너구리',
            score: 0,
            content: '“물어본 사람?”',
          },
        ],
      },
      {
        question: '부모님의 잔소리에 나는?',
        answers: [
          {
            type: '사자',
            score: 1,
            content: '“내가 알아서 한다니까?”',
          },
          {
            type: '너구리',
            score: 0,
            content: '“나 애 아니야 이제, 걱정 그만해”',
          },
        ],
      },
      {
        question: '친구들이 도움을 요청할 때 나는?',
        answers: [
          {
            type: '사자',
            score: 1,
            content: '도와주려고 생각은 하지만 바쁠 땐 대차게 거절한다.',
          },
          {
            type: '너구리',
            score: 0,
            content: '무슨 이런 부탁을 하냐고 말하면서 도와준다.',
          },
        ],
      },
      {
        question: '길 가다 다른 사람과 부딪혔을 때 나는?',
        answers: [
          {
            type: '사자',
            score: 1,
            content: '길 가다 다른 사람과 부딪혔을 때 나는?',
          },
          {
            type: '너구리',
            score: 0,
            content: '“괜찮으세요?”, 다치지 않았는지 물어본다.',
          },
        ],
      },
      {
        question: '음식을 배달했는데 다른 음식이 배달 왔을 때 나는?',
        answers: [
          {
            type: '사자',
            score: 1,
            content: '음식점에 전화해서 화난 말투로 다시 배달해달라고 한다.',
          },
          {
            type: '너구리',
            score: 0,
            content: '온갖 욕을 하지만 그냥 먹는다.',
          },
        ],
      },
      {
        question: '친한친구가 고민상담을 할 때 나는?',
        answers: [
          {
            type: '사자',
            score: 1,
            content: '적당히 공감을 해주지만 속으로는 딴 생각을 한다.',
          },
          {
            type: '너구리',
            score: 0,
            content: '귀찮은 티를 내지만 진심 어린 충고를 해준다.',
          },
        ],
      },
      {
        question: '개인적인 이유로 기분이 안좋을 때 나는?',
        answers: [
          {
            type: '사자',
            score: 1,
            content: '본인의 감정이 잘 드러나는 편이다.',
          },
          {
            type: '너구리',
            score: 0,
            content: '기분이 나쁘더라도 다른 사람에겐 웃으며 대하는 편이다.',
          },
        ],
      },
    ],
    results: [
      {
        type: '',
        desc: '',
        query: 'akejxpfptk',
        img_src:
          'https://images.ktestone.com/resultImages/despicable/akejxpfptk.jpg',
      },
      {
        type: '',
        desc: '',
        query: 'woaalsdl',
        img_src:
          'https://images.ktestone.com/resultImages/despicable/woaalsdl.jpg',
      },
      {
        type: '',
        desc: '',
        query: 'dpawltpeo',
        img_src:
          'https://images.ktestone.com/resultImages/despicable/dpawltpeo.jpg',
      },
      {
        type: '',
        desc: '',
        query: 'tlqkrus',
        img_src:
          'https://images.ktestone.com/resultImages/despicable/tlqkrus.jpg',
      },
      {
        type: '',
        desc: '',
        query: 'diddkcl',
        img_src:
          'https://images.ktestone.com/resultImages/despicable/diddkcl.jpg',
      },
      {
        type: '',
        desc: '',
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/despicable/Rhseo.jpg',
      },
      {
        type: '',
        desc: '',
        query: 'ghkdqudwkd',
        img_src:
          'https://images.ktestone.com/resultImages/despicable/ghkdqudwkd.jpg',
      },
      {
        type: '',
        desc: '',
        query: 'anstlsqnffidqo',
        img_src:
          'https://images.ktestone.com/resultImages/despicable/anstlsqnffidqo.jpg',
      },
    ],
  },
  // paints
  {
    info: {
      mainTitle: '물감 성격 테스트',
      subTitle: '물감으로 나를 표현한다면? | 물감 테스트',
      mainUrl: 'paints',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/paints-intro.png',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/paints-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '오랜만에 만난 동창회 모임에서 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '동창회에 온 모두와 터울 없이 대화한다',
          },
          {
            type: 'I',
            score: 5,
            content: '주로 친했던 친구들과 대화를 한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '누군가 나에게 갑자기 어떤 질문을 한다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '생각하기 전에 말이 먼저 나올 때가 있다',
          },
          {
            type: 'I',
            score: 5,
            content: '충분히 생각하고 말을 한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '수업 들을 때 이해가 안가는 부분이 있을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '타이밍 봐서 바로 손 들고 질문을 한다',
          },
          {
            type: 'I',
            score: 5,
            content:
              '친구에게 슬쩍 물어보거나, 수업 끝나고 조용히 찾아가서 질문한다',
          },
        ],
      },
      {
        which: 'SN',
        question: '길 가다가 사람들이 많이 몰려 있는 것을 봤을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '가봤자 별거 있겠나 싶어 그냥 가던 길을 간다',
          },
          {
            type: 'N',
            score: 5,
            content: '연예인 왔나? 궁금해 하며 가본다',
          },
        ],
      },
      {
        which: 'SN',
        question: '잘 때 내가 더 꾸고 싶은 꿈은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '돈이 많은 부자가 되는 꿈',
          },
          {
            type: 'N',
            score: 5,
            content: '판타지 세상 속 주인공이 되는 꿈',
          },
        ],
      },
      {
        which: 'SN',
        question: '카페에서 모르는 이성과 자꾸만 눈이 마주 칠 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '뭘 자꾸 쳐다봐 생각하며 무시한다',
          },
          {
            type: 'N',
            score: 5,
            content: '왜 자꾸 나를 보고 있지?\n생각하며 의식하게 된다',
          },
        ],
      },
      {
        which: 'TF',
        question: '배달음식을 시켰는데 예상 시간이 많이 지나도 오지 않는다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '바로 전화를 걸어서 어떻게 된 건지 상황을 여쭤본다',
          },
          {
            type: 'F',
            score: 5,
            content: '뭔가 사정이 있겠지 싶어서 일단 좀 더 기다려 본다',
          },
        ],
      },
      {
        which: 'TF',
        question: '예상치 못한 문제가 생겼을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '생각할 시간에 빠르게 해결하려고 한다',
          },
          {
            type: 'F',
            score: 5,
            content: '침착하게 생각해보고 해결하려고 한다',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 다이어트 한다고 선언했을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜 갑자기? PT 끊었어?\n헬스장 어디야?',
          },
          {
            type: 'F',
            score: 5,
            content: '오 진짜?\n쉽지 않을텐데 고생이겠다 화이팅이다',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '친구와 여행을 간 첫날 예쁜 숙소에 도착하자 마자 내가 하는 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '예쁘다 감탄하고 일단 짐부터 정리하고 사진을 찍는다',
          },
          {
            type: 'P',
            score: 5,
            content: '예쁘다고 감탄하면서 일단 사진부터 찍고 짐을 정리한다',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '입장하자마자 첫 번째로 타려 했던 놀이기구가 갑자기 임시점검 상태라면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '첫 놀이기구부터 틀어진 것에 속상하다',
          },
          {
            type: 'P',
            score: 5,
            content: '아쉽지만 다른 놀이기구를 찾아본다',
          },
        ],
      },
      {
        which: 'JP',
        question: '하고 싶은게 생겼을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '계획 먼저 세우고 시작한다',
          },
          {
            type: 'P',
            score: 5,
            content: '하고자 하는 건 일단 바로 시작해 본다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'qnsghd',
        img_src: 'https://images.ktestone.com/resultImages/paints/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'shfkd',
        img_src: 'https://images.ktestone.com/resultImages/paints/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/paints/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/paints/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/paints/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/paints/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ghqkrwjs',
        img_src: 'https://images.ktestone.com/resultImages/paints/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/paints/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'tkftor',
        img_src: 'https://images.ktestone.com/resultImages/paints/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'rjawjd',
        img_src: 'https://images.ktestone.com/resultImages/paints/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'skator',
        img_src: 'https://images.ktestone.com/resultImages/paints/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'rkftor',
        img_src: 'https://images.ktestone.com/resultImages/paints/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gksmftor',
        img_src: 'https://images.ktestone.com/resultImages/paints/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ghltor',
        img_src: 'https://images.ktestone.com/resultImages/paints/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'cjdfhr',
        img_src: 'https://images.ktestone.com/resultImages/paints/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/paints/INFP.jpg',
      },
    ],
  },
  // orbuculum
  {
    info: {
      mainTitle: '마법의 유리구슬 운세테스트',
      subTitle: '나의 시크릿 복은 무엇일까? | 유리구슬 테스트 | 운세 테스트',
      mainUrl: 'orbuculum',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/orbuculum-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/orbuculum-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'saju',
    },
    questions: [
      {
        which: 'EI',
        question: '당신의 눈앞에 있는 원석 중 마음에 드는 색깔을 고르세요.',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '행복과 에너지의 노란색 원석',
          },
          {
            type: 'I',
            score: 5,
            content: '직관과 지혜의 보라색 원석',
          },
        ],
      },
      {
        which: 'EI',
        question: '작년 당신에게 부족했다고 생각되는 마음은 무엇인가요?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '따스한 감성으로 사랑하며 조화를 이루며 사는 ‘의’의 자세',
          },
          {
            type: 'I',
            score: 5,
            content: '세상을 향한 열정을 함께 꿈꿀 줄 아는 ‘예’의 자세',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '성공한 사람들의 ‘운’에 대한 이야기 중 당신의 마음에 꽂히는 말은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '대니얼 카너먼 – 성공은 재능과 운으로 결정된다.',
          },
          {
            type: 'I',
            score: 5,
            content: '빌게이츠 – 신은 운을 N분의 1로 고루 분배하지 않는다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '지금 당신 눈앞에 펼쳐졌으면 하는 장면을 고르세요.',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '어스름한 새벽, 차가운 이슬공기가 맺혀있는 울창한 숲',
          },
          {
            type: 'N',
            score: 5,
            content:
              '늦은 오후, 파스텔톤 노을이 펼쳐지고 약한 파도가 일렁이는 바다',
          },
        ],
      },
      {
        which: 'SN',
        question:
          '길을 걷던 중, 두 갈래 길이 있습니다. 어느 길로 가시겠습니까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '부러진 표지판이 가리키는 구불구불 예측할 수 없는 국도와 같은 길',
          },
          {
            type: 'N',
            score: 5,
            content:
              '정상적인 표지판이 가리키는 시원하게 쭉 펼쳐진 고속도로와 같은 길',
          },
        ],
      },
      {
        which: 'SN',
        question: '타로점을 보러 간 당신,\n어떤 사람에게 끌리나요?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '머리부터 발끝까지 정갈하게 단정된 차림의 타로마스터',
          },
          {
            type: 'N',
            score: 5,
            content: '오랜 내공이 느껴지는 히피차림의 타로마스터',
          },
        ],
      },
      {
        which: 'TF',
        question: '새해 당신에게 재물운이 펼쳐졌으면 하는 계절은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '여름/겨울',
          },
          {
            type: 'F',
            score: 5,
            content: '봄/가을',
          },
        ],
      },
      {
        which: 'TF',
        question: '이 중에 나를 올 한 해 주인공으로 만들어 줄 것 같은 카드는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '자신감과 권위의 에너지를 풍기는 황제 카드',
          },
          {
            type: 'F',
            score: 5,
            content: '신성한 영감과 창작의 아이디어를 받는 마법사 카드',
          },
        ],
      },
      {
        which: 'TF',
        question: '당신이 운세/타로/점을 본다면 그 이유는 무엇인가요?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '단순한 호기심',
          },
          {
            type: 'F',
            score: 5,
            content: '미래에 대한 불안함',
          },
        ],
      },
      {
        which: 'JP',
        question: '당신에게 타고난 돈그릇이 있다면 어떤 스타일일까요?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '세심하게 굴곡 하나 없이 반듯하게 빚은 백자',
          },
          {
            type: 'P',
            score: 5,
            content: '투박하고 거칠지만 자연의 매력이 느껴지는 분청사기',
          },
        ],
      },
      {
        which: 'JP',
        question: '풍수인테리어 아이템 중 당신의 취향은 무엇인가요?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '해바라기 그림',
          },
          {
            type: 'P',
            score: 5,
            content: '부엉이 장식',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '사실 지금까지의 모든 것은 꿈이었습니다, 당신이 눈을 뜬 곳은 어디일까요? ',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '깊은 심해 한가운데',
          },
          {
            type: 'P',
            score: 5,
            content: '사건의 지평선 한가운데',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'fpem',
        img_src: 'https://images.ktestone.com/resultImages/orbuculum/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'spdlvzmffhqj',
        img_src: 'https://images.ktestone.com/resultImages/orbuculum/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'akspzlspzh',
        img_src: 'https://images.ktestone.com/resultImages/orbuculum/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dlekffj',
        img_src: 'https://images.ktestone.com/resultImages/orbuculum/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'xhzlqkf',
        img_src: 'https://images.ktestone.com/resultImages/orbuculum/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'dusemd',
        img_src: 'https://images.ktestone.com/resultImages/orbuculum/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/orbuculum/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'akxmfytlzk',
        img_src: 'https://images.ktestone.com/resultImages/orbuculum/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ekffkghtm',
        img_src: 'https://images.ktestone.com/resultImages/orbuculum/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tntwkclf',
        img_src: 'https://images.ktestone.com/resultImages/orbuculum/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'skator',
        img_src: 'https://images.ktestone.com/resultImages/orbuculum/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'emflazocu',
        img_src: 'https://images.ktestone.com/resultImages/orbuculum/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'tkstpqpfldk',
        img_src: 'https://images.ktestone.com/resultImages/orbuculum/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'qhrwhfl',
        img_src: 'https://images.ktestone.com/resultImages/orbuculum/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'qndjddl',
        img_src: 'https://images.ktestone.com/resultImages/orbuculum/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'xjzltjr',
        img_src: 'https://images.ktestone.com/resultImages/orbuculum/INFP.jpg',
      },
    ],
  },
  // memo
  {
    info: {
      mainTitle: '쪽지 연애 테스트',
      subTitle:
        '쪽지로 보는 나의 연애 성향은? | 쪽지 테스트 | 메모 테스트 | 메모지 테스트',
      mainUrl: 'memo',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/memo-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/memo-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '연인이 갑자기 친구를 부른다고 한다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '불러 불러! 같이 놀자!',
          },
          {
            type: 'I',
            score: 5,
            content: '갑자기? 아 그건 좀..',
          },
        ],
      },
      {
        which: 'EI',
        question: '호감 가는 이성과 집에 가는 길이 겹쳤을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '적극적으로 대화를 이끌어가면서 궁금했던 질문을 한다',
          },
          {
            type: 'I',
            score: 5,
            content: '이야기를 들어주고 리액션을 잘 해준다',
          },
        ],
      },
      {
        which: 'EI',
        question: '여행을 간다면 나는 어디로 갈까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '유명한 관광지와 다양한 사람들과 교류할 수 있는 여행',
          },
          {
            type: 'I',
            score: 5,
            content: '아름다운 자연과 혼자 사색을 즐길 수 있는 여행',
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 호감 있던 이성이 나에게 쪽지를 건넸다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '설레지만 무슨 내용일까 궁금하다',
          },
          {
            type: 'N',
            score: 5,
            content:
              '나에게 쪽지를?!..><\n이 사람과의 연애를 하는 상상을 해본다',
          },
        ],
      },
      {
        which: 'SN',
        question: '자취를 하게 되었다 집 보러러 다니는 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '방 구조, 문 턱, 수압, 연식 등 꼼꼼하게 체크한다',
          },
          {
            type: 'N',
            score: 5,
            content: '방이 화사한지, 넓은지, 포근한지 등을 본다',
          },
        ],
      },
      {
        which: 'SN',
        question: '연애 초에 내가하는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '행복하고 예쁘게 잘 사귀어 야지~',
          },
          {
            type: 'N',
            score: 5,
            content: '내가 이 사람과 안 싸우고 오래 행복할 수 있을까?',
          },
        ],
      },
      {
        which: 'TF',
        question: '연인이 밥 먹다가 갑자기 화들짝 놀라며 음식을 뱉는다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜 뭐 때문에 그래? 괜찮아?',
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮아?! 깜짝 놀랐겠다 물 줄까?',
          },
        ],
      },
      {
        which: 'TF',
        question: '연인이 약속시간에 30분이나 늦었을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '서운하고 화도 나지만 일단 늦은 이유가 뭔지 물어본다',
          },
          {
            type: 'F',
            score: 5,
            content: '서운해서 감정적으로 화를 낸다',
          },
        ],
      },
      {
        which: 'TF',
        question: '기념일에 연인에게 선물을 주고 내가 듣고 싶은 말은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '어떻게 이런 걸 다 생각했어?\n센스 있다 감동이야',
          },
          {
            type: 'F',
            score: 5,
            content: '고마워 꼭 필요했던 건데 역시 너밖에 없어',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 가려고 했던 맛집이 갑자기 문을 닫았을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '원래 계획이 틀어진 것에 속이 상하지만 당황하지 않고,\n플랜 B 맛집으로 간다',
          },
          {
            type: 'P',
            score: 5,
            content: '아쉽지만 어쩔 수 없다 근처 다른 곳을 찾아본다',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 선호하는 데이트 방식은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '맛집부터, 카페, 영화 모든 것이 계획되어 있는 데이트',
          },
          {
            type: 'P',
            score: 5,
            content: '모든 가능성을 열어 둔 즉흥적인 데이트',
          },
        ],
      },
      {
        which: 'JP',
        question: 'A영화를 보기로 했는데 연인이 갑자기 B영화를 보자고 한다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '너무 갑자기라는 생각에 좀 불편하지만 마지못해 보자고 한다',
          },
          {
            type: 'P',
            score: 5,
            content: '아무거나 상관없어서 흔쾌히 그러자고 한다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'dusen',
        img_src: 'https://images.ktestone.com/resultImages/memo/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qhfk',
        img_src: 'https://images.ktestone.com/resultImages/memo/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'qnsghd',
        img_src: 'https://images.ktestone.com/resultImages/memo/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'wnghkd',
        img_src: 'https://images.ktestone.com/resultImages/memo/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'Qkfrks',
        img_src: 'https://images.ktestone.com/resultImages/memo/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'vkfks',
        img_src: 'https://images.ktestone.com/resultImages/memo/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'chfhr',
        img_src: 'https://images.ktestone.com/resultImages/memo/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'shfks',
        img_src: 'https://images.ktestone.com/resultImages/memo/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'gksmftor',
        img_src: 'https://images.ktestone.com/resultImages/memo/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tkfrntor',
        img_src: 'https://images.ktestone.com/resultImages/memo/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'cjdfhrtor',
        img_src: 'https://images.ktestone.com/resultImages/memo/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
              `,
        query: 'skator',
        img_src: 'https://images.ktestone.com/resultImages/memo/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'tnvtor',
        img_src: 'https://images.ktestone.com/resultImages/memo/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ghltor',
        img_src: 'https://images.ktestone.com/resultImages/memo/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'qkator',
        img_src: 'https://images.ktestone.com/resultImages/memo/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkdis',
        img_src: 'https://images.ktestone.com/resultImages/memo/INFP.jpg',
      },
    ],
  },
  // waterLoveEng
  {
    info: {
      mainTitle: 'Water Dating Test',
      subTitle:
        'What is my dating personality recognized as water? | Water Test | Water Love Test',
      mainUrl: 'waterLoveEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/waterLoveEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/waterLoveEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Eng',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question:
          'What would you do\nif you entered a new club\nand met someone you are interested in?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I would actively try to talk to the person',
          },
          {
            type: 'I',
            score: 5,
            content:
              'I would find opportunities to\nhang around him/her and talk',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'While dating a lover,\nif you meet your lover’s friend,\nwhat will you do?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I will say hello and talk naturally',
          },
          {
            type: 'I',
            score: 5,
            content: 'I just say hi and listen to their conversation',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Which date do you hate more?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'A boring and quiet date in\na small space all day long',
          },
          {
            type: 'I',
            score: 5,
            content: 'A date in a crowded and noisy place all day long',
          },
        ],
      },
      {
        which: 'SN',
        question:
          "When you lover is with his/her friends\nand you can't reach him,\nwhat can make you angry?",
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'Whatever the reason is,\nI will be angry at not being able to contact my lover',
          },
          {
            type: 'N',
            score: 5,
            content:
              "Because I can't get in touch,\nI will be mad at my lover for making me\nimagine all kinds of things",
          },
        ],
      },
      {
        which: 'SN',
        question:
          'When you confessed to the opposite sex you liked\nand the person accepted it, what would you do?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I would be just thrilled and happy at the moment',
          },
          {
            type: 'N',
            score: 5,
            content:
              'I would feel happy and imagine a happy future\nwith the person in advance',
          },
        ],
      },
      {
        which: 'SN',
        question:
          'How do you feel when you wake up the next morning\nafter dating someone you like?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'It will be same as usual morning',
          },
          {
            type: 'N',
            score: 5,
            content:
              'Even the morning sunlight coming through\nthe window seems to bless us',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What gift would you like to give your lover on the anniversary?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Practical items that my lover might need right now',
          },
          {
            type: 'F',
            score: 5,
            content: 'Things lovers talked about in the past',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What can be your reaction\nif your friend was depressed after a breakup?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Why did you break up ? did you eat?\nLet’s go out to eat with me',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Are you okay?\nIt must be hard.\nWould you like to have a drink with me tonight?',
          },
        ],
      },
      {
        which: 'TF',
        question: 'When you argue with your lover,\nwhat do you do?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Even when I am angry,\nI try to rationally figure out why I am angry.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'I have a lot to say,\nbut first I feel like crying out of anger and sadness.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What will you do when confessing to someone you like?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I will set a date, make a plan,\nand prepare for a perfect confession',
          },
          {
            type: 'P',
            score: 5,
            content:
              'Timing is important!\nI will wait for right time\nand atmosphere to confess',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What kind of date do you like more?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'A planed date after reservation at café or restaurant',
          },
          {
            type: 'P',
            score: 5,
            content:
              'An unplanned date,\nsuch as deciding what to do on the day\nof the date and doing this and that',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'If you lover suddenly visits over to your house,\nwhat can be your reaction?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "Because I always keep my house clean,\nso I'll just clean it lightly",
          },
          {
            type: 'P',
            score: 5,
            content: 'Urgently I will clear my house up',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ahsxksk',
        img_src:
          'https://images.ktestone.com/resultImages/waterLoveEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ehfkeh',
        img_src:
          'https://images.ktestone.com/resultImages/waterLoveEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dhfflqls',
        img_src:
          'https://images.ktestone.com/resultImages/waterLoveEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'fkdlxmtldka',
        img_src:
          'https://images.ktestone.com/resultImages/waterLoveEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'zhqkfxm',
        img_src:
          'https://images.ktestone.com/resultImages/waterLoveEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'apfleldjs',
        img_src:
          'https://images.ktestone.com/resultImages/waterLoveEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dpflskdlxm',
        img_src:
          'https://images.ktestone.com/resultImages/waterLoveEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'tmvlspf',
        img_src:
          'https://images.ktestone.com/resultImages/waterLoveEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'xkswkskdlxm',
        img_src:
          'https://images.ktestone.com/resultImages/waterLoveEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'xhfmakffls',
        img_src:
          'https://images.ktestone.com/resultImages/waterLoveEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlfmzhs',
        img_src:
          'https://images.ktestone.com/resultImages/waterLoveEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dprtlskdlxm',
        img_src:
          'https://images.ktestone.com/resultImages/waterLoveEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'eldhqxpdlwm',
        img_src:
          'https://images.ktestone.com/resultImages/waterLoveEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dpzkskdlxm',
        img_src:
          'https://images.ktestone.com/resultImages/waterLoveEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ansfkdlxm',
        img_src:
          'https://images.ktestone.com/resultImages/waterLoveEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tpdlwl',
        img_src:
          'https://images.ktestone.com/resultImages/waterLoveEng/INFP.jpg',
      },
    ],
  },
  // king
  {
    info: {
      mainTitle: '왕 성격 테스트',
      subTitle: '나는 무슨 왕일까? | 왕 테스트',
      mainUrl: 'king',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/king-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/king-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '모임에서 내가 더 가까운 쪽은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '활발하고 적극적으로 내 의견을 말함',
          },
          {
            type: 'I',
            score: 5,
            content: '조용하고 소극적으로 내 의견을 말함',
          },
        ],
      },
      {
        which: 'EI',
        question: '한 달에 주말도 없이 휴일이 딱 하루 주어진다면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구들이나 연인과 밖에서 신나게 논다',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자 집에서 푹 쉰다',
          },
        ],
      },
      {
        which: 'EI',
        question: '사람 많은 핫 한 맛집에서 직원을 불러야 한다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '바로 “저기요!~” 하고 큰소리로 부른다',
          },
          {
            type: 'I',
            score: 5,
            content: '손을 들거나, 올 때 까지 기다린다',
          },
        ],
      },
      {
        which: 'SN',
        question: '추억의 예능 프로그램을 볼 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '이건 다시 봐도 재밌네ㅋㅋㅋ',
          },
          {
            type: 'N',
            score: 5,
            content:
              '이거 다시 방영하면 좋을 텐데..\n다음에는 000이 MC로 나오면 더 재밌겠다',
          },
        ],
      },
      {
        which: 'SN',
        question:
          '놀이 공원을 갔는데 어떤 애기가 아이스크림을 들고 뛰고 있다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '애기 너무 귀엽다ㅎㅎ',
          },
          {
            type: 'N',
            score: 5,
            content:
              '저렇게 뛰다가 넘어지거나 아이스크림 떨어뜨리면 어떡하지..?',
          },
        ],
      },
      {
        which: 'SN',
        question: '과거로 돌아가면 뭘 하고 싶냐는 친구의 말에 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '그런 일은 일어나지 않아 친구야^^',
          },
          {
            type: 'N',
            score: 5,
            content: '로또 번호 외워서 가야지~ㅎㅎ',
          },
        ],
      },
      {
        which: 'TF',
        question: '내가 잘하는 무언가를 남에게 가르쳐 준다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '잘 할 수 있도록 자세히 알려주고,\n문제점을 찾아 고쳐준다',
          },
          {
            type: 'F',
            score: 5,
            content:
              '리액션과 칭찬을 자주하며,\n잘 할 수 있도록 자세히 알려준다',
          },
        ],
      },
      {
        which: 'TF',
        question: '갑자기 친구가 나랑 안 맞는다고 절교 선언했을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '어이없고 황당하지만,\n이유가 뭔지 물어본다',
          },
          {
            type: 'F',
            score: 5,
            content: '충격적이고 상처받아서 벙찐다',
          },
        ],
      },
      {
        which: 'TF',
        question: '호감가는 이성에게 내가 더 많이 하는 것은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '끊임 없는 질문과 관심',
          },
          {
            type: 'F',
            score: 5,
            content: '진심 가득한 리액션과 공감',
          },
        ],
      },
      {
        which: 'JP',
        question: '나의 메일함 상태는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '메일을 자주 확인하고,\n정리를 자주한다',
          },
          {
            type: 'P',
            score: 5,
            content: '메일 확인은 하지만 정리는 딱히 하지 않는다',
          },
        ],
      },
      {
        which: 'JP',
        question: '같이 여행가면 더 힘들 것 같은 사람은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '이미 짰었던 계획을 다 무시하고,\n자기 하고 싶은거 다 하려는 사람',
          },
          {
            type: 'P',
            score: 5,
            content:
              '무조건 자기 계획대로만 해야 하고,\n안 따라주면 분위기 망치는 사람',
          },
        ],
      },
      {
        which: 'JP',
        question: '나와 가까운 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '한 가지 목표를 정해 놓고 꾸준히 함',
          },
          {
            type: 'P',
            score: 5,
            content: '여러가지 목표를 설정하고 다양한 시도를 함',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'RHseo',
        img_src: 'https://images.ktestone.com/resultImages/king/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qksgkd',
        img_src: 'https://images.ktestone.com/resultImages/king/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'clsahr',
        img_src: 'https://images.ktestone.com/resultImages/king/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'rhkswhd',
        img_src: 'https://images.ktestone.com/resultImages/king/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'akfQkf',
        img_src: 'https://images.ktestone.com/resultImages/king/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ehwjs',
        img_src: 'https://images.ktestone.com/resultImages/king/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dmlfl',
        img_src: 'https://images.ktestone.com/resultImages/king/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'rmdwjd',
        img_src: 'https://images.ktestone.com/resultImages/king/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'wlswl',
        img_src: 'https://images.ktestone.com/resultImages/king/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ghsqkq',
        img_src: 'https://images.ktestone.com/resultImages/king/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'thtla',
        img_src: 'https://images.ktestone.com/resultImages/king/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'rnlcksg',
        img_src: 'https://images.ktestone.com/resultImages/king/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhwlq',
        img_src: 'https://images.ktestone.com/resultImages/king/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'thfwlr',
        img_src: 'https://images.ktestone.com/resultImages/king/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'clsarh',
        img_src: 'https://images.ktestone.com/resultImages/king/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tkdtkd',
        img_src: 'https://images.ktestone.com/resultImages/king/INFP.jpg',
      },
    ],
  },
  // musicNoteEng
  {
    info: {
      mainTitle: 'Note Charm test',
      subTitle: 'What is my charm in terms of musical notes? | Note test',
      mainUrl: 'musicNoteEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/musicNoteEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/musicNoteEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'What can be your noise level at a meeting with friends?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Mostly high',
          },
          {
            type: 'I',
            score: 5,
            content: 'Mostly low',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What do you usually do when attending a new meeting?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I tend to get involved in the overall conversation',
          },
          {
            type: 'I',
            score: 5,
            content: 'I tend to only answer incoming questions.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What can be your reaction when\na talkative person talks to you at a new gathering?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'I respond well naturally,\nand I mostly continue the conversation as well',
          },
          {
            type: 'I',
            score: 5,
            content: "I don't know what to say and it's burdensome",
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What can be your reaction when\nyour ideal type says you are attractive?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I wonder where that person is attracted to',
          },
          {
            type: 'N',
            score: 5,
            content: 'I am already fantasized about dating this person',
          },
        ],
      },
      {
        which: 'SN',
        question:
          'What would be your reaction when\na friend suddenly asked you what\nyou would be doing in 10 years?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: "I don't know?\nI have no idea",
          },
          {
            type: 'N',
            score: 5,
            content:
              'Will I ever be a successful person?\nI will have to buy some buildings perhaps',
          },
        ],
      },
      {
        which: 'SN',
        question: 'If you were given a new job,\nwhat would you choose?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Tasks with clear guidelines',
          },
          {
            type: 'N',
            score: 5,
            content: 'Tasks that I can freely apply my thoughts',
          },
        ],
      },
      {
        which: 'TF',
        question: 'What do you care more about when talking to someone?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Conversation topic and flow',
          },
          {
            type: 'F',
            score: 5,
            content: 'Speech and expression',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'When you see your friend do something wrong,\nwhat do you do?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I honestly talk to my friend about it',
          },
          {
            type: 'F',
            score: 5,
            content: "I make my friend aware of\nmy friend's faults indirectly",
          },
        ],
      },
      {
        which: 'TF',
        question:
          'A friend says the house he/she moved in is too cold.\nWhat can be your reaction?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Is the heater broken?\ndid you check the heater?',
          },
          {
            type: 'F',
            score: 5,
            content: 'It must have been too cold in this cold weather',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What can be your reaction when\na new cafe opens near your house?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "It's closer than the cafe I used to go to.\nI may go there tomorrow after work to try",
          },
          {
            type: 'P',
            score: 5,
            content: "Oh, it's a brand new cafe.\nI'll have to try it soon",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'When you invite your friends to your house,\nwhat do you do?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I clearly prepare the menu for right quantity in advance',
          },
          {
            type: 'P',
            score: 5,
            content:
              'I simply set the menu for the number of attendees,\nand if food is not enough,\nI plan to order more',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What do you do when you plan to go on a trip with your friends\nand no one says anything about it later?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I will wonder why no one is talking about it\nand worry about transportation,\naccommodation reservations as well',
          },
          {
            type: 'P',
            score: 5,
            content: 'Someday someone will mention about it',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dhfflavy',
        img_src:
          'https://images.ktestone.com/resultImages/musicNoteEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ejqmfdlteks',
        img_src:
          'https://images.ktestone.com/resultImages/musicNoteEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'wjavkfqns',
        img_src:
          'https://images.ktestone.com/resultImages/musicNoteEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'shvdmsdmawkfl',
        img_src:
          'https://images.ktestone.com/resultImages/musicNoteEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ehehfdl',
        img_src:
          'https://images.ktestone.com/resultImages/musicNoteEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'tkqns',
        img_src:
          'https://images.ktestone.com/resultImages/musicNoteEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'tlqdbrqns',
        img_src:
          'https://images.ktestone.com/resultImages/musicNoteEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'vkfqns',
        img_src:
          'https://images.ktestone.com/resultImages/musicNoteEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'wjatkqns',
        img_src:
          'https://images.ktestone.com/resultImages/musicNoteEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'dhsdma',
        img_src:
          'https://images.ktestone.com/resultImages/musicNoteEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tkqnstnla',
        img_src:
          'https://images.ktestone.com/resultImages/musicNoteEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dlqns',
        img_src:
          'https://images.ktestone.com/resultImages/musicNoteEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'sofla',
        img_src:
          'https://images.ktestone.com/resultImages/musicNoteEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'skwdmsdmawkfl',
        img_src:
          'https://images.ktestone.com/resultImages/musicNoteEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'vkfqnstnla',
        img_src:
          'https://images.ktestone.com/resultImages/musicNoteEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'dlqnstnla',
        img_src:
          'https://images.ktestone.com/resultImages/musicNoteEng/INFP.jpg',
      },
    ],
  },
  // waterLove
  {
    info: {
      mainTitle: '워터 연애 테스트',
      subTitle:
        '워터로 알아보는 나의 연애 성향은? | 워터 테스트 | 물 테스트 | 물 연애 테스트',
      mainUrl: 'waterLove',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/waterLove-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/waterLove-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '새로운 동호회에 들어갔는데 내 이상형이 있다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '적극적으로 다가가 대화를 시도한다',
          },
          {
            type: 'I',
            score: 5,
            content: '그 사람 주변에서 맴돌며 대화할 틈을 찾는다',
          },
        ],
      },
      {
        which: 'EI',
        question: '연인과 데이트 중 연인의 친구를 만났을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '반갑게 인사하고 자연스럽게 같이 대화를 한다',
          },
          {
            type: 'I',
            score: 5,
            content: '간단한 인사만 하고 옆에서 조용히 대화를 듣고 있는다',
          },
        ],
      },
      {
        which: 'EI',
        question: '둘 중 더 싫은 데이트는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '하루 종일 좁은 실내 안에서만 있는 지루하고 조용한 데이트',
          },
          {
            type: 'I',
            score: 5,
            content: '하루 종일 사람 많은 장소에서 시끄럽고 정신없는 데이트',
          },
        ],
      },
      {
        which: 'SN',
        question: '연인이 친구들과 노는데 연락이 안됐을 때 내가 화난 이유는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '이유가 뭐든 연락이 안되는 것 자체에 화가 난다',
          },
          {
            type: 'N',
            score: 5,
            content: '연락이 안되서 온 갖 상상에 빠지게 해서 화가 난다',
          },
        ],
      },
      {
        which: 'SN',
        question: '좋아하던 이성에게 고백했는데 상대가 받아줬을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '그저 지금 이 순간이 짜릿하고 행복하다',
          },
          {
            type: 'N',
            score: 5,
            content:
              '행복한 기분과 동시에\n이 사람과의 행복한 미래를 미리 상상해 본다',
          },
        ],
      },
      {
        which: 'SN',
        question: '그토록 좋아하던 사람과 사귀게 된 다음날 아침에 일어난 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '평소 아침과 별로 다를 것 없다',
          },
          {
            type: 'N',
            score: 5,
            content: '창문에 들어오는 아침 햇살 마저 우리를 축복 해주는 듯하다',
          },
        ],
      },
      {
        which: 'TF',
        question: '기념일에 내가 연인에게 주고 싶은 선물은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '지금 연인에게 필요할 것 같은 실용적인 물건',
          },
          {
            type: 'F',
            score: 5,
            content: '연인이 예전에 흘리듯이 말했던 물건',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 갑자기 헤어져서 기운이 없고 우울하다고 한다 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '왜 헤어짐?? 밥은 먹었어?\n밥을 안 먹으니까 기운이 없지 나와 밥 먹자',
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮아?ㅠ 너무 힘들겠다 오늘 한 잔 할까?',
          },
        ],
      },
      {
        which: 'TF',
        question: '연인과 싸웠을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '화나지만 일단 내가 지금 왜 화났는지 이성적으로 말해본다',
          },
          {
            type: 'F',
            score: 5,
            content: '할말은 많은데 화나고 서러워서 눈물부터 나올 것 같다',
          },
        ],
      },
      {
        which: 'JP',
        question: '좋아하는 사람에게 고백할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '날을 정해 놓고 계획을 세워 완벽한 고백 준비를 한다',
          },
          {
            type: 'P',
            score: 5,
            content: '사랑은 타이밍! 가능성을 열어놓고, 분위기 봐서 고백한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 더 선호하는 데이트 방식은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '카페, 음식점 예약, 영화 예매 등 미리 계획해 놓고 하는 데이트',
          },
          {
            type: 'P',
            score: 5,
            content:
              '데이트 장소 뭐 할지 간단하게 정하고,\n이것저것 해보는 데이트를 즐긴다',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인이 갑작스레 집에 놀러 온다고 한다면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '평소에 잘 치워 놔서 디테일한 부분만 가볍게 청소 한다',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 급하게 눈에 보이는 것만 치워 놓는다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ahsxksk',
        img_src: 'https://images.ktestone.com/resultImages/waterLove/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ehfkeh',
        img_src: 'https://images.ktestone.com/resultImages/waterLove/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dhfflqls',
        img_src: 'https://images.ktestone.com/resultImages/waterLove/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'fkdlxmtldka',
        img_src: 'https://images.ktestone.com/resultImages/waterLove/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'zhqkfxm',
        img_src: 'https://images.ktestone.com/resultImages/waterLove/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'apfleldjs',
        img_src: 'https://images.ktestone.com/resultImages/waterLove/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dpflskdlxm',
        img_src: 'https://images.ktestone.com/resultImages/waterLove/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'tmvlspf',
        img_src: 'https://images.ktestone.com/resultImages/waterLove/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'xkswkskdlxm',
        img_src: 'https://images.ktestone.com/resultImages/waterLove/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'xhfmakffls',
        img_src: 'https://images.ktestone.com/resultImages/waterLove/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlfmzhs',
        img_src: 'https://images.ktestone.com/resultImages/waterLove/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dprtlskdlxm',
        img_src: 'https://images.ktestone.com/resultImages/waterLove/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'eldhqxpdlwm',
        img_src: 'https://images.ktestone.com/resultImages/waterLove/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dpzkskdlxm',
        img_src: 'https://images.ktestone.com/resultImages/waterLove/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ansfkdlxm',
        img_src: 'https://images.ktestone.com/resultImages/waterLove/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tpdlwl',
        img_src: 'https://images.ktestone.com/resultImages/waterLove/INFP.jpg',
      },
    ],
  },
  // musicNote
  {
    info: {
      mainTitle: '음표 매력 테스트',
      subTitle: '음표로 보는 나의 매력은? | 음표 테스트',
      mainUrl: 'musicNote',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/musicNote-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/musicNote-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '평소 친구들과 모임에서 나의 데시벨은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '높은 편이다',
          },
          {
            type: 'I',
            score: 5,
            content: '낮은 편이다',
          },
        ],
      },
      {
        which: 'EI',
        question: '새로운 모임에서 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '전체적인 대화에 잘 끼어드는 편이다.',
          },
          {
            type: 'I',
            score: 5,
            content: '들어오는 질문에 대답하는 편이다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '새로운 모임에서 어떤 활발한 사람이 말을 걸 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '말을 자연스럽게 잘 받아 치고,\n나도 같이 대화를 이어 나간다.',
          },
          {
            type: 'I',
            score: 5,
            content: '무슨 말을 해야 할지 모르겠고 부담스럽다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '이상형이 나에게 매력적이라고 말했을 때 내 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '내 어디가 매력적인지 궁금해진다.',
          },
          {
            type: 'N',
            score: 5,
            content: '이 사람이랑 벌써 사귀고 연애하는 상상을 한다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '친구가 갑자기 10년 뒤에 뭐 하고 있을까 물어봤을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '몰라? 내가 어캐앎?',
          },
          {
            type: 'N',
            score: 5,
            content: '우리는 아마 성공한 사람이 되어있지 않을까?\n건물 사야지~',
          },
        ],
      },
      {
        which: 'SN',
        question: '나에게 새로운 업무가 주어진다면 내 선택은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '확실한 가이드라인이 있는 업무',
          },
          {
            type: 'N',
            score: 5,
            content: '자유롭게 내 생각을 적용해 볼 수 있는 업무',
          },
        ],
      },
      {
        which: 'TF',
        question: '내가 대화를 할 때 더 신경 쓰는 것은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '대화 주제와 흐름',
          },
          {
            type: 'F',
            score: 5,
            content: '말투와 표정',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 잘못을 저지른 것을 봤을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '솔직하게 친구가 한 잘못에 대해 이야기 해준다.',
          },
          {
            type: 'F',
            score: 5,
            content: '친구의 잘못을 최대한 돌려서 인지시켜준다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 이사한 집이 너무 춥다고 한다.\n나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '히터 고장 난거 아니야?\n히터 확인해 봤어?',
          },
          {
            type: 'F',
            score: 5,
            content: '이 추운 날씨에 너무 추웠겠다ㅠ',
          },
        ],
      },
      {
        which: 'JP',
        question: '집 근처에 새로운 카페가 생겼을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '원래 가던 카페보다 가까운 곳이네,\n맛을 한 번 봐야하니까 내일 퇴근길에 가봐야겠다.',
          },
          {
            type: 'P',
            score: 5,
            content: '오 새로운 카페네 조만간 가봐야지.',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구들을 집에 초대할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '음식 메뉴, 주문 수량, 인원까지 확실하게 정하고 미리 준비한다',
          },
          {
            type: 'P',
            score: 5,
            content:
              "'인원 파악, 메뉴만 정하고 모자라면 더 시키지 뭐' 하고 간단하게 준비한다",
          },
        ],
      },
      {
        which: 'JP',
        question: '친구들과 여행가기로 했는데 아무연락이 없을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '“왜 연락이 없지..?” 하며 각종 교통, 숙소 예약들을 걱정한다',
          },
          {
            type: 'P',
            score: 5,
            content: '“언젠가 말 나오겠지” 하며 별로 생각하지 않는다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dhfflavy',
        img_src: 'https://images.ktestone.com/resultImages/musicNote/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ejqmfdlteks',
        img_src: 'https://images.ktestone.com/resultImages/musicNote/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'wjavkfqns',
        img_src: 'https://images.ktestone.com/resultImages/musicNote/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'shvdmsdmawkfl',
        img_src: 'https://images.ktestone.com/resultImages/musicNote/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ehehfdl',
        img_src: 'https://images.ktestone.com/resultImages/musicNote/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'tkqns',
        img_src: 'https://images.ktestone.com/resultImages/musicNote/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'tlqdbrqns',
        img_src: 'https://images.ktestone.com/resultImages/musicNote/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'vkfqns',
        img_src: 'https://images.ktestone.com/resultImages/musicNote/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'wjatkqns',
        img_src: 'https://images.ktestone.com/resultImages/musicNote/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'dhsdma',
        img_src: 'https://images.ktestone.com/resultImages/musicNote/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tkqnstnla',
        img_src: 'https://images.ktestone.com/resultImages/musicNote/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dlqns',
        img_src: 'https://images.ktestone.com/resultImages/musicNote/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'sofla',
        img_src: 'https://images.ktestone.com/resultImages/musicNote/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'skwdmsdmawkfl',
        img_src: 'https://images.ktestone.com/resultImages/musicNote/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'vkfqnstnla',
        img_src: 'https://images.ktestone.com/resultImages/musicNote/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'dlqnstnla',
        img_src: 'https://images.ktestone.com/resultImages/musicNote/INFP.jpg',
      },
    ],
  },
  // bread2023
  {
    info: {
      mainTitle: '새해 식빵 테스트',
      subTitle:
        '식빵 캐릭터로 보는 새해 나의 성격은? | 식빵 테스트 | 2023 식빵 캐릭터 테스트',
      mainUrl: 'bread2023',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/bread2023-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/bread2023-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '나의 새해 인사 스타일은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '먼저 연락해서 새해 인사를 한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '먼저 새해 인사가 오면 답장을 한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '처음 보는 사람과 대화할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '적극적으로 대화를 이끌어 나간다.',
          },
          {
            type: 'I',
            score: 5,
            content: '상대방의 말에 맞춰주며 대화한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구와 약속 잡을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구에게 먼저 연락하는 편이다.',
          },
          {
            type: 'I',
            score: 5,
            content: '친구에게 먼저 연락이 오는 편이다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '해돋이 보러 가는 길에 휴게소 표지판이 보일 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '아직 00휴게소구나 아직 멀었네.',
          },
          {
            type: 'N',
            score: 5,
            content: '휴게소 가면 뭐 먹을지 미리 생각해 본다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '고속도로에 차가 너무 막힐 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '이래서 언제 도착하나 싶다.',
          },
          {
            type: 'N',
            score: 5,
            content: '목적지로 순간 이동하는 상상을 해본다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '가봤던 곳을 또 가게 되었을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '이미 가봤던 곳이니, 최대한 빠른 길로 간다.',
          },
          {
            type: 'N',
            score: 5,
            content: '이 길로는 한 번 가봤으니, 다른 길로도 한 번 가본다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 운전 중 졸려 할 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '휴게소, 졸음 쉼터 위치를 찾아 알려준다.',
          },
          {
            type: 'F',
            score: 5,
            content: '옆에서 쉴 틈 없이 계속 말을 걸어준다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '나에게 사과할 때 먼저 해야 하는 것은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '납득이 되는 자세한 상황 설명 먼저',
          },
          {
            type: 'F',
            score: 5,
            content: '진심 어린 사과 먼저',
          },
        ],
      },
      {
        which: 'TF',
        question: '내가 받고 싶은 새해 선물은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '지금 나에게 딱 필요했던 실용적인 선물',
          },
          {
            type: 'F',
            score: 5,
            content: '예전에 흘리듯 말했던 것을 기억했다가 사준 선물',
          },
        ],
      },
      {
        which: 'JP',
        question: '새해에 해돋이를 보러 가기 전 날 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '미리 차 막히는 구간,\n최적의 경로 등 세세하게 찾아보고 출발 준비를 한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '출발 시간만 정하고,\n차에 내비게이션이 있으니 일단 잔다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '새해에 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '새해 계획을 미리 다 짜놓았다.',
          },
          {
            type: 'P',
            score: 5,
            content: '아직 명확한 새해 계획은 없다.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '새해에는 뭐든 정리를 잘해보겠다는 다짐을 한다고 했을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '원래 정리 잘 해서 굳이 다짐할 필요까지는 없다.',
          },
          {
            type: 'P',
            score: 5,
            content: '약간 자신 없다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'gjtp',
        img_src: 'https://images.ktestone.com/resultImages/bread2023/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'RnfQkffj',
        img_src: 'https://images.ktestone.com/resultImages/bread2023/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'tkghltodghkf',
        img_src: 'https://images.ktestone.com/resultImages/bread2023/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dmlfl',
        img_src: 'https://images.ktestone.com/resultImages/bread2023/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shQKRN',
        img_src: 'https://images.ktestone.com/resultImages/bread2023/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'wkrtlatkadlf',
        img_src: 'https://images.ktestone.com/resultImages/bread2023/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dhwlfkv',
        img_src: 'https://images.ktestone.com/resultImages/bread2023/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'wmrgmd',
        img_src: 'https://images.ktestone.com/resultImages/bread2023/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ahqja',
        img_src: 'https://images.ktestone.com/resultImages/bread2023/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'rmrgustlfwndml',
        img_src: 'https://images.ktestone.com/resultImages/bread2023/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'rpdmfms',
        img_src: 'https://images.ktestone.com/resultImages/bread2023/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'rnlckslwma',
        img_src: 'https://images.ktestone.com/resultImages/bread2023/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'qnstjr',
        img_src: 'https://images.ktestone.com/resultImages/bread2023/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'akdldnpdl',
        img_src: 'https://images.ktestone.com/resultImages/bread2023/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ELdzld',
        img_src: 'https://images.ktestone.com/resultImages/bread2023/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'akdtkd',
        img_src: 'https://images.ktestone.com/resultImages/bread2023/INFP.jpg',
      },
    ],
  },
  // luckyBag2023
  {
    info: {
      mainTitle: '복주머니 테스트',
      subTitle:
        '나의 2023년 새해 운세는? | 복주머니 운세 테스트 | 2023 복주머니 테스트',
      mainUrl: 'luckyBag2023',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/luckyBag2023-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/luckyBag2023-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'saju',
    },
    questions: [
      {
        which: 'EI',
        question: '새해 운세를 점치고 싶을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '유명한 점집이나 사주카페를 방문하다',
          },
          {
            type: 'I',
            score: 5,
            content: '전화 신점이나 사주 어플을 이용한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '나는 보통 연휴에',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '그동안 못 봤던 친구들을 만나러 나간다',
          },
          {
            type: 'I',
            score: 5,
            content: '집에서 쉬면서 못 봤던 영화나 드라마를 본다',
          },
        ],
      },
      {
        which: 'EI',
        question: '새해 인사를 할 때는',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '내가 먼저 사람들에게 새해 인사를 하는 편이다',
          },
          {
            type: 'I',
            score: 5,
            content: '사람들에게 먼저 올 때까지 기다렸다 하는 편이다',
          },
        ],
      },
      {
        which: 'SN',
        question: '당장 내일 죽게 된다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '무슨 말도 안 되는 소리야?',
          },
          {
            type: 'N',
            score: 5,
            content: '죽으면 귀신이 될까? 천국과 지옥은 어떤 곳일까?',
          },
        ],
      },
      {
        which: 'SN',
        question: '가구를 조립할 때',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '설명서를 정독하고 정석 그대로 조립한다',
          },
          {
            type: 'N',
            score: 5,
            content: '설명서는 거들 뿐. 내 감대로 조립한다',
          },
        ],
      },
      {
        which: 'SN',
        question: '나는 영화를 볼 때',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '기승전결이 확실한 닫힌 결말을 선호한다',
          },
          {
            type: 'N',
            score: 5,
            content: '해석의 여지가 많은 열린 결말을 선호한다',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 어떤 일로 스트레스를 받고 있다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '현실적으로 해결 가능한 방안을 제시해 준다',
          },
          {
            type: 'F',
            score: 5,
            content: '친구가 느끼는 감정에 공감을 해주고 위로해 준다',
          },
        ],
      },
      {
        which: 'TF',
        question: '내가 더 중요하게 생각하는 것은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '과정보단 결과가 중요하다',
          },
          {
            type: 'F',
            score: 5,
            content: '결과보단 과정이 더 중요하다',
          },
        ],
      },
      {
        which: 'TF',
        question: '”생각해 볼게“ 는',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '곰곰이 정말 생각해 본다는 뜻이다',
          },
          {
            type: 'F',
            score: 5,
            content: '대충 거절이라는 뜻이다',
          },
        ],
      },
      {
        which: 'JP',
        question: '나는 물건을 쓰고',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '제자리에 잘 두는 편이다',
          },
          {
            type: 'P',
            score: 5,
            content: '아무 곳에 나 놔두는 편이다',
          },
        ],
      },
      {
        which: 'JP',
        question: '갑자기 생긴 신년 약속에',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '이미 할 일이 계획되어 있어서 거절한다',
          },
          {
            type: 'P',
            score: 5,
            content: '심심했는데 바로 알겠다고 한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '새해 신년 계획을 세운다면',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '월별로 구체적인 목표와 방향을 세운다',
          },
          {
            type: 'P',
            score: 5,
            content: '대충 큰 목표 하나만 정한다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'godqhr',
        img_src:
          'https://images.ktestone.com/resultImages/luckyBag2023/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'woanf',
        img_src:
          'https://images.ktestone.com/resultImages/luckyBag2023/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'gkrdjq',
        img_src:
          'https://images.ktestone.com/resultImages/luckyBag2023/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'tmdwls',
        img_src:
          'https://images.ktestone.com/resultImages/luckyBag2023/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'dlsrl',
        img_src:
          'https://images.ktestone.com/resultImages/luckyBag2023/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'tjdrhd',
        img_src:
          'https://images.ktestone.com/resultImages/luckyBag2023/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gkqrur',
        img_src:
          'https://images.ktestone.com/resultImages/luckyBag2023/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'tkfkd',
        img_src:
          'https://images.ktestone.com/resultImages/luckyBag2023/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dhlah',
        img_src:
          'https://images.ktestone.com/resultImages/luckyBag2023/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'smdfur',
        img_src:
          'https://images.ktestone.com/resultImages/luckyBag2023/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'goddns',
        img_src:
          'https://images.ktestone.com/resultImages/luckyBag2023/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dydrl',
        img_src:
          'https://images.ktestone.com/resultImages/luckyBag2023/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ekdldjxm',
        img_src:
          'https://images.ktestone.com/resultImages/luckyBag2023/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dugod',
        img_src:
          'https://images.ktestone.com/resultImages/luckyBag2023/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'rjsrkd',
        img_src:
          'https://images.ktestone.com/resultImages/luckyBag2023/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tkfka',
        img_src:
          'https://images.ktestone.com/resultImages/luckyBag2023/INFP.jpg',
      },
    ],
  },
  // fishShapedBunEng
  {
    info: {
      mainTitle: '붕어빵 성격 테스트',
      subTitle:
        '붕어빵으로 알아보는 나의 성격 | 붕어빵 성격 테스트 | 붕어빵 테스트',
      mainUrl: 'fishShapedBunEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/fishShapedBunEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/fishShapedBunEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          'When a new group chatting rom is created by a new group,\nI will?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'Say hello to break the ice and\nuse the reactions or emoticons.',
          },
          {
            type: 'I',
            score: 5,
            content: 'Just say hello and not leave any particular message.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What is my weekend plan?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'As I have to work in the office on weekdays,\nI will have a fun outside on weekends',
          },
          {
            type: 'I',
            score: 5,
            content:
              'As I have to work in the office on weekdays,\nI will take a full rest at home on weekends',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'After having a good time with friends,\non the way back home, I will',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I had a great fun!\nI recharged my energy fully',
          },
          {
            type: 'I',
            score: 5,
            content:
              'Ah, it was great! Now,\nI should go back home and have a rest..',
          },
        ],
      },
      {
        which: 'SN',
        question: 'When I watch a play,\nI will',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'I concentrate on watching the actors’ performances.\nI spend time focusing on the play itself.',
          },
          {
            type: 'N',
            score: 5,
            content:
              'I watch the play trying to\nfind a twist or hidden message',
          },
        ],
      },
      {
        which: 'SN',
        question: 'A day before making an overseas travel in my life,\nI will',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Go to sleep longing for the next day.',
          },
          {
            type: 'N',
            score: 5,
            content:
              'Imagine a pleasant travel laying on\nmy bed and fall in sleep.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'When taking a rest at home,\nI will',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Think nothing and just focus on the rest only',
          },
          {
            type: 'N',
            score: 5,
            content: 'Take a rest imaging this and that',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'When my friend says she buy an expensive product\nwith her first salary, I will ',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Say what a pretty thing it is! By the way,\nhow much did you pay for it?',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Say you feel confident as you have worked hard\nand paid for it with your first salary',
          },
        ],
      },
      {
        which: 'TF',
        question: 'When a friend tells you about her own privacy,\nI will',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Listen to her completely and\ngive her a piece of advice.',
          },
          {
            type: 'F',
            score: 5,
            content: 'Empathize with her and react well',
          },
        ],
      },
      {
        which: 'TF',
        question: 'When I buy a good,\nI will value more for',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Practical things such as price and function etc',
          },
          {
            type: 'F',
            score: 5,
            content: 'Pretty one is the best!\nDesign and sensibility',
          },
        ],
      },
      {
        which: 'JP',
        question: 'When I go for a blind date and choose clothes,\nI will',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Neatly set from head to toe on the previous day.',
          },
          {
            type: 'P',
            score: 5,
            content: 'Just pick up the closes I like on the day.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'When I subscribe a month free trial service\nbut don’t like it, I will',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Just unsubscribe at the time of one month passed',
          },
          {
            type: 'P',
            score: 5,
            content:
              'Forget to unsubscribe it and\nfinally unsubscribe it several months later',
          },
        ],
      },
      {
        which: 'JP',
        question: 'When my friends talk to me for gathering together,\nI will',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Feel anxious unless the time, the date,\nthe day for the meeting and things to do are firmly confirmed in detail.',
          },
          {
            type: 'P',
            score: 5,
            content: 'Think I follow them if they fix the schedule',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'clwm',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBunEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'chzh',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBunEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'Ekfrl',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBunEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'eksvkx',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBunEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gmrdlawk',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBunEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'alsxmchzh',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBunEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'wktorrhrnak',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBunEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'tbzmfla',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBunEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'rlacl',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBunEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'rjadmszhd',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBunEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'Tnr',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBunEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'tmdnltrkdl',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBunEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'eksghqkr',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBunEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'qksksk',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBunEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'EKdzhdqjxj',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBunEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shrck',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBunEng/INFP.jpg',
      },
    ],
  },
  // rabbit2023
  {
    info: {
      mainTitle: '2023 흑묘년 토끼 테스트',
      subTitle:
        '2023 흑묘년 나는 무슨 토끼일까? | 2023 흑묘년 토끼 성격 테스트 | 토끼 테스트',
      mainUrl: 'rabbit2023',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/rabbit2023-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/rabbit2023-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '2023년 1월 나의 모습은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '연말에 만나지 못했던 사람들 가득!\n신년회 약속으로 보낸다.',
          },
          {
            type: 'I',
            score: 5,
            content:
              '이제 새로운 한 해 시작이다!\n작년 한 해를 마무리하는 시간을 가지며 에너지를 충전한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구들과의 여행에서 선호하는 유형이 있다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '아쉽지 않게 여행지 구석구석을 돌아다니기!',
          },
          {
            type: 'I',
            score: 5,
            content: '조용한 숙소에서 우리끼리 휴식 취하기!',
          },
        ],
      },
      {
        which: 'EI',
        question: '일에 대한 고민이 생겼다,\n지인들과 어디까지 얘기를 할까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '다른 사람과 이야기했을 때 내가 생각지도 못한\n결과물이 나올 수 있기에 고민 상담을 최대한 한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '아주 가까운 지인 1명에게만 고민 상담을 한다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '2023년 친구들과 일출을 보러 간 나의 모습은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '어플을 보니 오늘 해는 7시 46분에 뜬다고 했어!\n얼른 사진 찍어서 SNS에 올려야지!',
          },
          {
            type: 'N',
            score: 5,
            content:
              '1년이라는 시간을 참 길었구나..\n올해에는 무슨 소원을 빌지?',
          },
        ],
      },
      {
        which: 'SN',
        question:
          '친구들과 새해 모임통장을 하기로 하였다!\n가장 먼저 하고 싶은 일은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '누구 계좌에 각자 얼마를 넣을지 먼저 얘기한다.',
          },
          {
            type: 'N',
            score: 5,
            content: '모임 통장으로 앞으로 어떻게 쓸 것인지 먼저 얘기힌다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 더 좋아하는 사람의 유형이 이 중에 있다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '이론을 적용하여 문제 해결에 기쁨을 느끼는 이과생',
          },
          {
            type: 'N',
            score: 5,
            content: '철학과 문학에 대한 사색과 토론을 즐기는 문과생',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '2023년 연인이 나를 위한 새해 이벤트를 준비했다면\n내가 할 칭찬은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '고마워 요즘 바쁠 텐데 언제 이런 걸 생각했어?',
          },
          {
            type: 'F',
            score: 5,
            content: '사랑해 최고야 나 정말 감동했어...',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 축- 쳐진 상태로 우울하다고 말했을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜 우울해?\n뭐 때문에 우울한 거야?',
          },
          {
            type: 'F',
            score: 5,
            content: '5초 만에 감정이입 완료..\n같이 글썽거린다',
          },
        ],
      },
      {
        which: 'TF',
        question: '연인이 나와 싸운 얘기를 친구들에게 하며 불만을 얘기했다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '자기도 완벽하지 않으면서 내 욕을 해?\n화가 난다.',
          },
          {
            type: 'F',
            score: 5,
            content: '어떻게 나에 대해 얘기할 수 있지?\n속상하다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '2023년 신년 계획을 세워본다면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '1월부터 12월까지 월단위로 계획을 세운다',
          },
          {
            type: 'P',
            score: 5,
            content: '언제까지 “이건 꼭 해야지”\n정도의 기한만 정해둔다',
          },
        ],
      },
      {
        which: 'JP',
        question: 'SNS핫플을 가기로 한 나,\n나들이 룩을 고를 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '전날에 머리부터 발끝까지 세팅해둔다.',
          },
          {
            type: 'P',
            score: 5,
            content: '나가기 직전 마음에 드는 옷을 입는다.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '2023년 어느 날,\n갑자기 내일 세상이 멸망한다고 하면\n오늘 하고 싶은 데이트는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '지금까지 지냈던 일상이 가장 소중해,\n특별한 것은 없지만 소소하게 평소처럼 보낸다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '지금까지 못해봤던 게 너무 억울해!\n시도해 보지 않았던 특별한 데이트로 꽉꽉 채운다!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qnrrmr',
        img_src: 'https://images.ktestone.com/resultImages/rabbit2023/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'xnfldksxk',
        img_src: 'https://images.ktestone.com/resultImages/rabbit2023/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'clsclffk',
        img_src: 'https://images.ktestone.com/resultImages/rabbit2023/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ejcl',
        img_src: 'https://images.ktestone.com/resultImages/rabbit2023/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'apffjfnem',
        img_src: 'https://images.ktestone.com/resultImages/rabbit2023/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'cpzmansml',
        img_src: 'https://images.ktestone.com/resultImages/rabbit2023/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'fkdldjsgpem',
        img_src: 'https://images.ktestone.com/resultImages/rabbit2023/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'fhqdldj',
        img_src: 'https://images.ktestone.com/resultImages/rabbit2023/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'xpelemdnjvm',
        img_src: 'https://images.ktestone.com/resultImages/rabbit2023/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gkqksk',
        img_src: 'https://images.ktestone.com/resultImages/rabbit2023/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'fprtm',
        img_src: 'https://images.ktestone.com/resultImages/rabbit2023/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'aktmzkfk',
        img_src: 'https://images.ktestone.com/resultImages/rabbit2023/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'zofflvhsldk',
        img_src: 'https://images.ktestone.com/resultImages/rabbit2023/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'tia',
        img_src: 'https://images.ktestone.com/resultImages/rabbit2023/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dldrmffltl',
        img_src: 'https://images.ktestone.com/resultImages/rabbit2023/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'todkd',
        img_src: 'https://images.ktestone.com/resultImages/rabbit2023/INFP.jpg',
      },
    ],
  },
  // fishShapedBun
  {
    info: {
      mainTitle: '붕어빵 성격 테스트',
      subTitle:
        '붕어빵으로 알아보는 나의 성격 | 붕어빵 성격 테스트 | 붕어빵 테스트',
      mainUrl: 'fishShapedBun',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/fishShapedBun-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/fishShapedBun-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '새로운 모임의 단톡방이 만들어 졌을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '어색한 분위기를 살리려고 인사하고, 리액션이나 이모티콘을 사용해 본다.',
          },
          {
            type: 'I',
            score: 5,
            content: '인사만 하고 별다른 톡은 남기지 않는다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '내가 세우는 주말 계획은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '평일에 회사에만 있었으니 주말엔 밖에서 신나게 놀아야지!',
          },
          {
            type: 'I',
            score: 5,
            content:
              '평일에 회사에만 나와있었으니까 주말엔 집에서 푹 쉬어야겠다',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구들과 알차게 놀고나서 집갈 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '오늘 너무 알차게 놀았다! 에너치 충전완료!',
          },
          {
            type: 'I',
            score: 5,
            content: '아 재밌었다! 이제 집에 가서 좀 쉬어야지..',
          },
        ],
      },
      {
        which: 'SN',
        question: '연극을 볼 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '배우들의 연기에 집중해서 본다. 연극 자체에 몰입해서 본다.',
          },
          {
            type: 'N',
            score: 5,
            content:
              '연극 중에 반전이나, 숨겨져 있는 메시지가 있지 않을까 생각하면서 본다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '처음으로 가보는 해외여행 전날 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '빨리 내일이 오기만 바라면서 바로 잔다.',
          },
          {
            type: 'N',
            score: 5,
            content: '누워서 여행가서 재밌게 노는 상상을 하다가 잠든다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '집에서 누워서 쉴 때 내가 하는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '아무 생각 안하고 그저 쉬는 것에 집중한다.',
          },
          {
            type: 'N',
            score: 5,
            content: '이런 저런 상상을 하면서 쉰다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 첫 월급으로 고가의 물건을 샀다고 했을 때 내 반응은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '와 예쁜데? 근데 겁나 비싸겠다 얼마임?',
          },
          {
            type: 'F',
            score: 5,
            content: '이열~ 열심히 일한 첫 월급으로 산거라 뿌듯하겠다 야',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 속 깊은 이야기를 털어놨을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '친구의 말을 끝까지 듣고 조언을 해준다.',
          },
          {
            type: 'F',
            score: 5,
            content: '친구의 말에 감정이입을 하며, 리액션을 잘 해준다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '물건을 살 때 내가 더 중요하게 보는 것은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '가격과 성능 등 실용적인 부분',
          },
          {
            type: 'F',
            score: 5,
            content: '예쁜게 최고지! 디자인과 감성',
          },
        ],
      },
      {
        which: 'JP',
        question: '소개팅 나갈 옷을 고를 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '전날부터 머리부터 발끝 까지 깔끔하게 세팅해 놓는다.',
          },
          {
            type: 'P',
            score: 5,
            content: '당일 날 준비하면서 마음에 드는 옷으로 입고 나간다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '한 달 무료체험 구독 서비스를 끊고 마음에 안 들었을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '한달이 지난 시점에 맞춰서 바로 해지 한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '해지 해야지 하고 까먹고 몇 달 더 사용하다가 해지한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구들과 모이자는 말이 나왔을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '몇 일, 몇 시, 어디서, 무엇을 할건지 세세하게 정해놓지 않으면 불안함을 느낀다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '다들 시간 되서 만난다고 확정나면 나머지는 어떻게든 된다고 생각한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'clwm',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBun/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'chzh',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBun/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'Ekfrl',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBun/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'eksvkx',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBun/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gmrdlawk',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBun/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'alsxmchzh',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBun/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'wktorrhrnak',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBun/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'tbzmfla',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBun/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'rlacl',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBun/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'rjadmszhd',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBun/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'Tnr',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBun/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'tmdnltrkdl',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBun/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'eksghqkr',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBun/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'qksksk',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBun/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'EKdzhdqjxj',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBun/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'shrck',
        img_src:
          'https://images.ktestone.com/resultImages/fishShapedBun/INFP.jpg',
      },
    ],
  },
  // chaebol
  {
    info: {
      mainTitle: '요즘 뜨는 재벌집 테스트',
      subTitle: '나는 어떤 인물과 같을까? | 재벌집 테스트',
      mainUrl: 'chaebol',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/chaebol-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/chaebol-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'etc',
    },
    questions: [
      {
        which: 'EI',
        question: '집에 누워있는데 갑자기 친한 동료가 만나자고 한다?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '‘오히려 좋아’, 바로 나갈게!',
          },
          {
            type: 'I',
            score: 5,
            content: '‘아 좀 귀찮은데…’, 미안 몸이 안좋아서ㅠ',
          },
        ],
      },
      {
        which: 'EI',
        question: '빈 강의실에서 나는 어디에 앉을까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '중앙 부분(주위에 사람들이 많아야 더 즐겁잖아)',
          },
          {
            type: 'I',
            score: 5,
            content: '벽 근처(벽에서 누군가 훅 치고 들어오진 않겠지)',
          },
        ],
      },
      {
        which: 'EI',
        question: '오랜만에 동창회에 참석했을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '오랜만에 만난 친구들과 신나게 이야기함',
          },
          {
            type: 'I',
            score: 5,
            content: '예전에 친하게 지냈었던 친구들하고만 이야기함',
          },
        ],
      },
      {
        which: 'SN',
        question:
          '회장님께 업무보고가 다가오는데 일이 손에 안잡힌다면, 무슨 생각을 할까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '‘어떤 질문을 하실까?’, ‘어떤 부분을 더 수정해야하지?’',
          },
          {
            type: 'N',
            score: 5,
            content:
              '‘일 없는 세상은 어떨까?’, ‘업무보고는 왜 존재하고 왜 해야만 할까?’ ',
          },
        ],
      },
      {
        which: 'SN',
        question: '업무보고(혹은 발표) 할 때 나의 스타일은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '이쪽 파트까지 5분 소요되니까, 두번째 파트와 세번째 파트에 3분씩 잡고 결론 부분은 4분정도 사용해야겠다',
          },
          {
            type: 'N',
            score: 5,
            content: '이정도면 어림잡아 15분 정도 나오겠지?',
          },
        ],
      },
      {
        which: 'SN',
        question: '비행기 타기 전 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '기내식 뭐 나오지?’, ‘좌석 불편하지 않을까?',
          },
          {
            type: 'N',
            score: 5,
            content:
              '비행기 떨어지면 어떡하지?’, ‘바다에 떨어지면 어떻게 살지? 구명조끼 챙겨야하나?',
          },
        ],
      },
      {
        which: 'TF',
        question: '“너 적성에 안 맞으니 그만둬”라는 말에 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '그렇게 보여? 내가 다른 사람보다 발전이 느린가?',
          },
          {
            type: 'F',
            score: 5,
            content: '너무 상처받게 말한다. 너무 싫어 그런말.',
          },
        ],
      },
      {
        which: 'TF',
        question: '축 처진 동료가 우울하다고 말했을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜 우울해? 뭐 때문에 우울한 거야?',
          },
          {
            type: 'F',
            score: 5,
            content: '5초 만에 감정이입 완료. 같이 글썽거린다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '내 꿈에 대해 설명했을 때 “그게 돈이 되는 기가?”라고 하면',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '그러게? 그 부분까진 생각 못해봤네 의견 묻길 잘했다',
          },
          {
            type: 'F',
            score: 5,
            content:
              '돈 얘기보단 같이 꿈에 대해 같이 얘기해주면 좋았을텐데… 속상해',
          },
        ],
      },
      {
        which: 'JP',
        question: '회장님과 내일 점심 식사를 하기로 했다.',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '아침은 가볍게 먹고,10시에 샴푸…11시 지하철이 빠르지, 먹고 나서 후식은…',
          },
          {
            type: 'P',
            score: 5,
            content: '이왕 이렇게 된 거 맛있게 먹고오자!',
          },
        ],
      },
      {
        which: 'JP',
        question: '친한 동료와 해외여행 계획 짜게 된 나는',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '할 거면 제대로! 일별로 세부 일정을 정리한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '비행기 표만 끊어두고 계획의 80% 끝난다고 생각한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '나는 어떤 삶을 더 선호?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '반복되는 일상/업무 선호, 편-안',
          },
          {
            type: 'P',
            score: 5,
            content: '쳇바퀴 같은 인생은 질색 매일매일 변화가 있어야 함',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'zkfltmak',
        img_src: 'https://images.ktestone.com/resultImages/chaebol/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'djdEndaofur',
        img_src: 'https://images.ktestone.com/resultImages/chaebol/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'smdfurdlTsms',
        img_src: 'https://images.ktestone.com/resultImages/chaebol/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dmldyraks',
        img_src: 'https://images.ktestone.com/resultImages/chaebol/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ekdehfgks',
        img_src: 'https://images.ktestone.com/resultImages/chaebol/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ghlwkdsla',
        img_src: 'https://images.ktestone.com/resultImages/chaebol/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'wkqhswndml',
        img_src: 'https://images.ktestone.com/resultImages/chaebol/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'wkdbfhdns',
        img_src: 'https://images.ktestone.com/resultImages/chaebol/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'wjtmdtkwk',
        img_src: 'https://images.ktestone.com/resultImages/chaebol/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'goruftk',
        img_src: 'https://images.ktestone.com/resultImages/chaebol/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'rydiddlTsms',
        img_src: 'https://images.ktestone.com/resultImages/chaebol/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'tmdnltrkdl',
        img_src: 'https://images.ktestone.com/resultImages/chaebol/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rPtkswjrdls',
        img_src: 'https://images.ktestone.com/resultImages/chaebol/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'wjsfirwjrdls',
        img_src: 'https://images.ktestone.com/resultImages/chaebol/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'wpsxmfgks',
        img_src: 'https://images.ktestone.com/resultImages/chaebol/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ENrtladlTsms',
        img_src: 'https://images.ktestone.com/resultImages/chaebol/INFP.jpg',
      },
    ],
  },
  // smileColorTestEng
  {
    info: {
      mainTitle: 'Smile Dating Test',
      subTitle:
        "Let's find out your dating style with a smiley character | Smile test | Smile love test | smile date test",
      mainUrl: 'smileColorTestEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/smileColorTestEng-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/smileColorTestEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Eng',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: 'When you have no plan for the weekend,\nwhat will you do?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'I will call my friends or\nacquaintances right away to make\nan appointment for the weekend.',
          },
          {
            type: 'I',
            score: 5,
            content: 'I will lie in bed all day and rest.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What will you do if you have\nan unexpected appointment suddenly?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'It will be fun!\nI will go to the appointment\nif I have nothing special.',
          },
          {
            type: 'I',
            score: 5,
            content:
              'Because it is not one of my plans,\nI will just make some excuses.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What will you do when a friend\ntakes you to a blind date?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'I will take the initiative first\nand try to improve the atmosphere.',
          },
          {
            type: 'I',
            score: 5,
            content: 'I will wait until someone talk to me first.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do you think when you get on a plane for a trip?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I wish it arrives ASAP.',
          },
          {
            type: 'N',
            score: 5,
            content:
              "It's gonna be fun!\nhowever I may wonder how this airplane can fly?",
          },
        ],
      },
      {
        which: 'SN',
        question: 'What are your concerns when you eat out?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              "I'm worried about which of\nthe two favorite foods to eat at the restaurant",
          },
          {
            type: 'N',
            score: 5,
            content:
              "While I'm eating,\nI may think about what to eat for next meal.",
          },
        ],
      },
      {
        which: 'SN',
        question: 'After you see a music contest,\nwhat can be your response?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'Wow it is really grand.\nI will listen to this song every day once it comes out.',
          },
          {
            type: 'N',
            score: 5,
            content:
              "Wow.. are those lyrics based on\nthe story of that person's experience?\nPerhaps, I wonder how long they practiced.",
          },
        ],
      },
      {
        which: 'TF',
        question:
          'How will you react when your friend tells you\nthat he/she went shopping because he/she was depressed?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              "What did you buy?\nIt wasn't an impulse purchase, was it?",
          },
          {
            type: 'F',
            score: 5,
            content: 'What happened?\nDid you go shopping all of a sudden?',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'A friend suddenly said that he just had passed an exam.\nWhat can be your reaction?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Oh what test was it?\ncongratulations!',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Wow congratulations!\nYou must have worked hard. Good job!',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'When your girlfriend suddenly loses something important,\nwhat can be your reaction?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'How did you lose it?\nWhere did you last see it?',
          },
          {
            type: 'F',
            score: 5,
            content: 'That was what you loved.\nI feel sorry. ',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What will you do when going to a restaurant with your lover?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I will make a reservation in advance and decide what to eat.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'Just before going to eat,\nI may rush to find a right place to make a reservation.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'How do you choose a date dress?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'The day before,\nI set from head to toe.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'I may choose my best clothes\njust before I go out on the day.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What will you do if you decide to go on a trip with your lover?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I will make a detailed plan for each day in advance.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'Just by buying a plane ticket,\nI think, 70% of preparation is done.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'mint',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'yellow',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'pink',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'purple',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'blue',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'red',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'orange',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'olive',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'burgundy',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'beige',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'navy',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'grey',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'khaki',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'green',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'brown',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sky',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTestEng/INFP.jpg',
      },
    ],
  },
  // snowflakeEng
  {
    info: {
      mainTitle: 'Snowflake Test',
      subTitle: 'What if I were a snowflake? | snowflake test',
      mainUrl: 'snowflakeEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/snowflakeEng-intro.jpeg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/snowflakeEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          'When you are resting at home on a day off\nand a friend suddenly asks to go out,\nwhat can be your reaction?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'I was just bored. Okay,\nI will get ready to go out right now.',
          },
          {
            type: 'I',
            score: 5,
            content:
              "I don't go out with an excuse\nbecause It's burdensome to go out suddenly.",
          },
        ],
      },
      {
        which: 'EI',
        question:
          'When you have to speak in front of many people,\nhow do you feel?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I feel good when\nmany people pay attention to me.',
          },
          {
            type: 'I',
            score: 5,
            content:
              "I'm uncomfortable with\na lot of people paying attention to me.",
          },
        ],
      },
      {
        which: 'EI',
        question:
          'When you are full of appointments on the weekend,\nwhat can be your reaction?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              "I'm going to have a good time\nand have fun this weekend!\nI feel great!",
          },
          {
            type: 'I',
            score: 5,
            content:
              'I need at least one day off during the weekend.\nWhat should I do?',
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do you think about most\nwhen you are at home?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I am not thinking much',
          },
          {
            type: 'N',
            score: 5,
            content: 'I often imagine this and that',
          },
        ],
      },
      {
        which: 'SN',
        question: 'How do you season food?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I follow a recipe.',
          },
          {
            type: 'N',
            score: 5,
            content: 'I just follow my intuition.',
          },
        ],
      },
      {
        which: 'SN',
        question:
          'When a friend asks about\nthe play you watched yesterday,\nwhat can be your reaction?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'It was a play about the conflict\nthat arises in the process of\nreuniting a couple on campus.',
          },
          {
            type: 'N',
            score: 5,
            content:
              'The main characters were so cool!\nIf it comes out as a movie or drama,\nI will definitely watch it!',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'When your friend gets depressed\nbecause he failed in the job interview,\nwhat can be your reaction?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Pity.. when is the next interview?',
          },
          {
            type: 'F',
            score: 5,
            content: 'I feel so sorry about that.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'What compliment do you want to hear most?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Well done!!\nBut it would be better if\nyou slightly modify certain part.',
          },
          {
            type: 'F',
            score: 5,
            content: 'Good job,\nyou did really well!',
          },
        ],
      },
      {
        which: 'TF',
        question: 'When your friend had a car accident,\nwhat will you do?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Are you okay?\nDo you have car insurance?\nHow it happened?',
          },
          {
            type: 'F',
            score: 5,
            content: 'Are you okay?\ndid you get hurt anywhere?',
          },
        ],
      },
      {
        which: 'JP',
        question: 'When ordering food for delivery,\nhow do I do?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'To order food,\nI have to look for review and rating,\netc, carefully.',
          },
          {
            type: 'P',
            score: 5,
            content: 'I can order just by\nlooking at the thumbnail image.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'When planning a trip,\nwhat do you do?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I need a detail and\norganized schedule for the trip.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'I think more than half of the plan\nis done if the plane ticket is ready.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'After making an appointment with a friend\nto go to a must-visit restaurant tomorrow,\nwhat will you do?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I will inform my friends about\ntransportation, time, and where to meet.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'I just feel happy at\nthe thought of eating delicious food.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'tjflRhcsns',
        img_src:
          'https://images.ktestone.com/resultImages/snowflakeEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'tptjfsns',
        img_src:
          'https://images.ktestone.com/resultImages/snowflakeEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'thasns',
        img_src:
          'https://images.ktestone.com/resultImages/snowflakeEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'aksmfsns',
        img_src:
          'https://images.ktestone.com/resultImages/snowflakeEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'djfdjasns',
        img_src:
          'https://images.ktestone.com/resultImages/snowflakeEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'tjtjfsns',
        img_src:
          'https://images.ktestone.com/resultImages/snowflakeEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dudnsns',
        img_src:
          'https://images.ktestone.com/resultImages/snowflakeEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'qhasns',
        img_src:
          'https://images.ktestone.com/resultImages/snowflakeEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'cjtsns',
        img_src:
          'https://images.ktestone.com/resultImages/snowflakeEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'thddlsns',
        img_src:
          'https://images.ktestone.com/resultImages/snowflakeEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'toqursns',
        img_src:
          'https://images.ktestone.com/resultImages/snowflakeEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'rkfnsns',
        img_src:
          'https://images.ktestone.com/resultImages/snowflakeEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gkaqkrsns',
        img_src:
          'https://images.ktestone.com/resultImages/snowflakeEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'vhtmfsns',
        img_src:
          'https://images.ktestone.com/resultImages/snowflakeEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'qkasns',
        img_src:
          'https://images.ktestone.com/resultImages/snowflakeEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tkfkdsns',
        img_src:
          'https://images.ktestone.com/resultImages/snowflakeEng/INFP.jpg',
      },
    ],
  },
  // smileColorTest
  {
    info: {
      mainTitle: '스마일 연애 테스트',
      subTitle:
        '스마일 캐릭터로 알아보는 나의 연애 성향 | 스마일 테스트 | 스마일 연애 테스트',
      mainUrl: 'smileColorTest',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/smileColorTest-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/smileColorTest-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '별일 없는 주말에 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구들 지인들에게 당장 연락해서\n약속을 잡는다.',
          },
          {
            type: 'I',
            score: 5,
            content: '하루 종일 침대에 누워서 쉰다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '갑자기 예정에 없던 약속이 생기면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '재밌겠다! 별일 없으면 약속에 간다.',
          },
          {
            type: 'I',
            score: 5,
            content: '내 계획에 없던 건데.\n핑계를 댄다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구의 소개로 어느 모임에 나왔을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '먼저 주도적으로 말을 걸고,\n분위기를 업 시키려고 노력한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '사람들이 먼저 말을 걸어올 때까지 기다려본다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '여행을 가려고 비행기에 탔을 때 내가 하는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '아 빨리 도착했으면 좋겠다.',
          },
          {
            type: 'N',
            score: 5,
            content: '재밌겠다!\n근데 비행기는 무슨 원리로 뜨는 거지?',
          },
        ],
      },
      {
        which: 'SN',
        question: '밥 먹을 때 내가 하는 고민은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '들어간 식당 최애음식 두 메뉴 중 뭐 먹을지 고민',
          },
          {
            type: 'N',
            score: 5,
            content: '점심 먹으면서 저녁 뭐 먹을지 고민',
          },
        ],
      },
      {
        which: 'SN',
        question: '음악 경연 프로그램을 봤을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '와~ 찢었다 이거 음원 나오면 맨날들을 거야ㅠ',
          },
          {
            type: 'N',
            score: 5,
            content:
              '와.. 저 가사는 저 사람이 겪은 이야기 인가?\n연습은 얼마나 했을까?',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 우울해서 쇼핑했다고 했을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '뭐 샀어?\n아무거나 막 지른 건 아니지..?',
          },
          {
            type: 'F',
            score: 5,
            content: '무슨 일 있었어?\n갑자기 쇼핑을 했다고?',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 갑자기 시험에 합격했다고 했다.\n나의 반응은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '오 무슨 시험이었어??\n축하해!',
          },
          {
            type: 'F',
            score: 5,
            content: '와 축하해!\n고생했겠다 수고했어!',
          },
        ],
      },
      {
        which: 'TF',
        question: '여자친구가 갑자기 아끼는 물건을 잃어버렸을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '어쩌다가 잃어버렸어ㅠ\n마지막으로 어디서 봤어?',
          },
          {
            type: 'F',
            score: 5,
            content: '그거 너가 아끼던 거였는데ㅠ\n어떡해ㅠ',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 맛집을 가려고 할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '미리 예약하고 뭐 먹을지 메뉴까지 생각해놓고 간다.',
          },
          {
            type: 'P',
            score: 5,
            content: '약속이 다가왔을 때 급하게 찾아서 예약한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '데이트 룩을 고를 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '전날 머리부터 발끝까지 세팅해 놓는다.',
          },
          {
            type: 'P',
            score: 5,
            content: '준비하면서 끌리는 옷으로 입고 나간다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 여행을 가기로 했을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '일별로 계획을 세세하게 미리 정해 놓는다.',
          },
          {
            type: 'P',
            score: 5,
            content: '비행기 표만 끊어도 70%는 끝났다고 생각한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'mint',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTest/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'yellow',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTest/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'pink',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTest/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'purple',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTest/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'blue',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTest/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'red',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTest/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'orange',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTest/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'olive',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTest/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'burgundy',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTest/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'beige',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTest/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'navy',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTest/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'grey',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTest/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'khaki',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTest/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'green',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTest/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'brown',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTest/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sky',
        img_src:
          'https://images.ktestone.com/resultImages/smileColorTest/INFP.jpg',
      },
    ],
  },
  // yearEndParty2022
  {
    info: {
      mainTitle: '연말 파티 테스트 2022',
      subTitle: '내가 연말 파티 필수템이라면?',
      mainUrl: 'yearEndParty2022',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/yearEndParty2022-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/yearEndParty2022-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '연말 파티가 끝난 후 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '아 올해 연말도 너무 잘 놀았다!\n에너지 충전 완료!',
          },
          {
            type: 'I',
            score: 5,
            content: '아 올해 연말 재밌었다~\n이제 집에가서 쉬어야지..',
          },
        ],
      },
      {
        which: 'EI',
        question: '나에게 맞는 주말 계획은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '주중에 회사 안에만 있었으니까\n주말엔 밖에서 알차게 보내야지!',
          },
          {
            type: 'I',
            score: 5,
            content:
              '주중에 회사에만 나와있었으니까\n주말에는 집에서 푹 쉬어야지!',
          },
        ],
      },
      {
        which: 'EI',
        question: '오랜만에 혼자 여행 가려고 숙소를 잡으려고 한다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '새로운 사람들과 함께 놀 수 있는 게스트 하우스',
          },
          {
            type: 'I',
            score: 5,
            content: '나 혼자만의 공간이 중요한 호텔',
          },
        ],
      },
      {
        which: 'SN',
        question:
          '친구가 “만약 너가 무인도에 가 그럼 어떻게 할래?”\n라고 한다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '내가 무인도를 왜 감?',
          },
          {
            type: 'N',
            score: 5,
            content: '진짜 무인도에 가서 뭘 할지 상상을 해본다',
          },
        ],
      },
      {
        which: 'SN',
        question: '드라마나 영화를 볼 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '배우들의 연기에 몰입하고 드라마/영화\n그 자체에 집중해서 본다.',
          },
          {
            type: 'N',
            score: 5,
            content:
              '이 드라마가 말하고 있는 숨겨진 메시지가 무엇인지,\n반전이 있을지 생각하면서 본다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '여행가기 전 날 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '빨리 가고 싶어서 짐 챙기고 바로 잠을 청한다.',
          },
          {
            type: 'N',
            score: 5,
            content:
              '침 챙기고 누워서 내일 여행가서\n재밌게 노는 상상을 하다가 잠든다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '갑자기 머리를 짧게 자른 친구에게 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '머리 잘랐네? 이쁘다 어디서 잘랐어?',
          },
          {
            type: 'F',
            score: 5,
            content: '무슨 일 있었어? 갑자기 머리를 잘랐네?',
          },
        ],
      },
      {
        which: 'TF',
        question: '준비한 PT발표가 끝났다 내가 듣고 싶은 칭찬은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '와 완전 인상적이더라~\n근데 이 부분 약간만 수정하면 더 좋겠다!',
          },
          {
            type: 'F',
            score: 5,
            content: '와 이거 준비 하는데 엄청 고생했겠는데?\n너무 잘했다~!',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 고민을 털어놨을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '친구의 이야기를 듣고 최대한 문제를 해결해 주려고 한다.',
          },
          {
            type: 'F',
            score: 5,
            content: '친구에게 감정이입을 하면서,\n리액션을 많이 한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '가려던 레스토랑이 예약이 다 차버렸을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '진작 예약하지 않은 나를 원망한다',
          },
          {
            type: 'P',
            score: 5,
            content: '아쉽다.. 다음에 딴데 가면되지 뭐',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구들과 연말 파티 계획을 짤 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '파티 장소, 시간, 준비물, 당일 무엇을 할 건지\n미리 세세하게 정해 놓는다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '시간과 장소만 정했다면\n준비는 거의 다 끝나간다고 생각한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '파티나 행사 룩을 고를 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '전날부터 머리부터 발끝까지 미리 세팅 해둔다.',
          },
          {
            type: 'P',
            score: 5,
            content: '당일 날 준비하면서 마음에 드는 옷으로 입고 간다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'vhrwnr',
        img_src:
          'https://images.ktestone.com/resultImages/yearEndParty2022/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkxldksrud',
        img_src:
          'https://images.ktestone.com/resultImages/yearEndParty2022/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'akdlzm',
        img_src:
          'https://images.ktestone.com/resultImages/yearEndParty2022/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'alfjqhf',
        img_src:
          'https://images.ktestone.com/resultImages/yearEndParty2022/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'vhffkfhdlem',
        img_src:
          'https://images.ktestone.com/resultImages/yearEndParty2022/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'tidemffldp',
        img_src:
          'https://images.ktestone.com/resultImages/yearEndParty2022/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkxlvlfl',
        img_src:
          'https://images.ktestone.com/resultImages/yearEndParty2022/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'tiavpdls',
        img_src:
          'https://images.ktestone.com/resultImages/yearEndParty2022/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'vkxlzjxms',
        img_src:
          'https://images.ktestone.com/resultImages/yearEndParty2022/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tmvlzj',
        img_src:
          'https://images.ktestone.com/resultImages/yearEndParty2022/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tnrcnlgothwp',
        img_src:
          'https://images.ktestone.com/resultImages/yearEndParty2022/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'zpdlzm',
        img_src:
          'https://images.ktestone.com/resultImages/yearEndParty2022/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vkxlrjfdl',
        img_src:
          'https://images.ktestone.com/resultImages/yearEndParty2022/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'zkvpxm',
        img_src:
          'https://images.ktestone.com/resultImages/yearEndParty2022/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'zosemf',
        img_src:
          'https://images.ktestone.com/resultImages/yearEndParty2022/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'vndtjs',
        img_src:
          'https://images.ktestone.com/resultImages/yearEndParty2022/INFP.jpg',
      },
    ],
  },
  // snowflake
  {
    info: {
      mainTitle: '크리스마스 눈송이 테스트',
      subTitle: '내가 눈송이라면 어떤 눈송이일까? | 눈송이 테스트',
      mainUrl: 'snowflake',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/snowflake-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/snowflake-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '쉬는 날 집에서 누워있는데 친구가 갑자기 보자고 할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '심심했는데 좋아!\n바로 나갈 준비를 한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '갑작스러운 약속이 부담스러워 핑계를 대고 나가지 않는다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '많은 사람들 앞에서 말을 해야할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '많은 사람들이 나에게 주목하면 기분이 좋다.',
          },
          {
            type: 'I',
            score: 5,
            content: '많은 사람들이 나에게 주목하는 자리가 불편하다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '주말에 약속이 꽉 찼을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '이번주 주말도 알차고 재밌게 보내겠다!\n기분이 좋아!',
          },
          {
            type: 'I',
            score: 5,
            content: '주말 중에 적어도 하루는 쉬어야 하는데..\n어떡하지..?',
          },
        ],
      },
      {
        which: 'SN',
        question: '집에 있을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '아무 생각도 하지 않는다',
          },
          {
            type: 'N',
            score: 5,
            content: '이런 저런 상상을 자주 한다',
          },
        ],
      },
      {
        which: 'SN',
        question: '요리중 간 할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '레시피에 나온 정량대로 넣어 간을 맞춘다.',
          },
          {
            type: 'N',
            score: 5,
            content: '“대충 이정도 넣으면 되겠지”생각하며 손 대중으로 넣는다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '친구가 어제 내가 본 연극에 대해 물어 볼 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '대학교에서 만난 연인들이 헤어졌는데,\n다시 만나는 과정에서 생기는 갈등을 다룬 사랑 관련 연극이었어.',
          },
          {
            type: 'N',
            score: 5,
            content:
              '주인공들이 너무 멋있었어!\n만약 영화나 드라마로도 나오면 꼭 볼 거야! ',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 준비하던 면접에서 떨어졌다고 우울해 할 때 내 반응은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '아쉽겠다.. 다음 면접은 또 언제야?',
          },
          {
            type: 'F',
            score: 5,
            content: '준비하느라 고생했을 텐데 많이 힘들겠다..ㅠ',
          },
        ],
      },
      {
        which: 'TF',
        question: '내가 더 듣고 싶은 칭찬은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '완전 잘했다!! \n이 부분을 약간만 수정하면 더 좋겠다!',
          },
          {
            type: 'F',
            score: 5,
            content: '진짜 열심히 했네 정말 고생 많았어!!',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 차 사고가 났다고 했을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '괜찮아? 너 보험은 들어 놨어?\n어떻게 사고가 난거야??',
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮아? 어디 다친 곳은 없어??ㅠ',
          },
        ],
      },
      {
        which: 'JP',
        question: '배달음식을 주문할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '어떤 식당인지 검색해서 리뷰, 별점, 후기 등을\n찾아보고 심사숙고해서 주문한다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '음식점 썸네일 이미지만 봐도\n맛집인지 아닌지 느낌이 온다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '여행 계획을 짤 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '일자 별로 세세하게 일정을 정리해 놓는다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '비행기 표를 끊었다면\n계획의 반 이상은 끝났다고 생각한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구와 내일 맛집을 가기로 약속을 잡고나서 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '교통편, 시간, 만남장소 등\n세세하고 정확하게 짜고 친구에게 알려준다.',
          },
          {
            type: 'P',
            score: 5,
            content: '맛있는 음식을 먹을 생각에 행복해 한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'tjflRhcsns',
        img_src: 'https://images.ktestone.com/resultImages/snowflake/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'tptjfsns',
        img_src: 'https://images.ktestone.com/resultImages/snowflake/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'thasns',
        img_src: 'https://images.ktestone.com/resultImages/snowflake/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'aksmfsns',
        img_src: 'https://images.ktestone.com/resultImages/snowflake/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'djfdjasns',
        img_src: 'https://images.ktestone.com/resultImages/snowflake/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'tjtjfsns',
        img_src: 'https://images.ktestone.com/resultImages/snowflake/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dudnsns',
        img_src: 'https://images.ktestone.com/resultImages/snowflake/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'qhasns',
        img_src: 'https://images.ktestone.com/resultImages/snowflake/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'cjtsns',
        img_src: 'https://images.ktestone.com/resultImages/snowflake/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'thddlsns',
        img_src: 'https://images.ktestone.com/resultImages/snowflake/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'toqursns',
        img_src: 'https://images.ktestone.com/resultImages/snowflake/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'rkfnsns',
        img_src: 'https://images.ktestone.com/resultImages/snowflake/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gkaqkrsns',
        img_src: 'https://images.ktestone.com/resultImages/snowflake/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'vhtmfsns',
        img_src: 'https://images.ktestone.com/resultImages/snowflake/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'qkasns',
        img_src: 'https://images.ktestone.com/resultImages/snowflake/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tkfkdsns',
        img_src: 'https://images.ktestone.com/resultImages/snowflake/INFP.jpg',
      },
    ],
  },
  // personalColor2023
  {
    info: {
      mainTitle: '퍼스널 컬러 테스트 2023',
      subTitle: '나에게 어울리는 컬러는 무엇일까?',
      mainUrl: 'personalColor2023',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/personalColor2023-intro_.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/personalColor2023-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '친구들하고 대화 할때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구들 사이에서 대화를 주도해 이끌어간다',
          },
          {
            type: 'I',
            score: 5,
            content: '친구들이 이야기 하는 것을 끝까지 다 듣고 공감해준다',
          },
        ],
      },
      {
        which: 'EI',
        question: '휴일에 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '이대로 휴일을 그냥 보낼 수 없어!\n약속을 만들어 활동적인 휴일을 보낸다',
          },
          {
            type: 'I',
            score: 5,
            content: '집이 최고! 그냥 집에서 푹 쉰다',
          },
        ],
      },
      {
        which: 'EI',
        question: '새로운 모임에 가게된 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '내가 먼저 다른사람들에게 밝게 인사한다',
          },
          {
            type: 'I',
            score: 5,
            content: '다른사람들이 먼저 나에게 인사를하는 경우가 많다',
          },
        ],
      },
      {
        which: 'SN',
        question: '챙겨 보던 드라마가 열린 결말로 끝이 났을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '이게 뭐야? 이렇게 끝난다고?\n작가의 의도를 생각해본다',
          },
          {
            type: 'N',
            score: 5,
            content: '드라마의 결말을 마음대로 상상해보고 만족해 한다',
          },
        ],
      },
      {
        which: 'SN',
        question: '평소에 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '관찰과 분석을 좋아 하고,\n현재에 일어나는 일에 집중한다',
          },
          {
            type: 'N',
            score: 5,
            content:
              '상상력이 풍부하고,\n일어나지 않은 일에 대한 걱정을 할때가 있다',
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 선호하는 데이트는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '일상적이고 익숙한 보편적인 데이트를 선호한다',
          },
          {
            type: 'N',
            score: 5,
            content: '매 번 새롭게 특별하고 이색적인 데이트를 선호한다',
          },
        ],
      },
      {
        which: 'TF',
        question: '거절해야 하는 상황일 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '단호하게 거절한다',
          },
          {
            type: 'F',
            score: 5,
            content: '고민하다가 대부분 들어준다',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 힘들다며 나에게 고민상담을 했을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '현실적인 해결책을 제시하고 도움을 주려고 노력한다',
          },
          {
            type: 'F',
            score: 5,
            content: '친구의 말에 공감하며,\n대부분 친구 편을 들어준다',
          },
        ],
      },
      {
        which: 'TF',
        question: '연인이 갑자기 배 아프다고 할때 내 반응에 가까운 것은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '배 어느 부분이 어떻게 아파??\n괜찮아? 같이 병원 가보자ㅠ',
          },
          {
            type: 'F',
            score: 5,
            content: '헐 괜찮아? 많이 아프지ㅠ\n약사다줄까?',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구들과 여행갈 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '세세하게 계획을 짜서 친구들과 공유하고,\n친구들에게 계획대로하자며 닦달한다',
          },
          {
            type: 'P',
            score: 5,
            content:
              '간단한 것들만 대충 정해 놓고,\n뭐 먹을지 생각하며 출발한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '나와 더 가까운 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '정리를 자주하고 내 물건들을 곧 잘 찾는다',
          },
          {
            type: 'P',
            score: 5,
            content: '분명 거기 놨었는데?\n하면서 한참을 찾는다',
          },
        ],
      },
      {
        which: 'JP',
        question: '여러가지의 업무를 맡았을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '계획을 세워 하나의 업무를 완벽히 다 끝내고 다음 업무를 시작한다',
          },
          {
            type: 'P',
            score: 5,
            content:
              '맡은 모든 업무들을 왔다갔다하며 융통성 있게 업무를 처리한다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'akflsk',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2023/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'vldptmxk',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2023/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'vpdlfqmffjtnl',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2023/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'zotxmfldk',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2023/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'qjsxmtldpsk',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2023/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'akdnsxlsapehdn',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2023/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'fhwlqmfkdns',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2023/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'wlswjfkdls',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2023/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'shxlffjtm',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2023/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ekvmsp',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2023/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'dlqmsldtnpehdn',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2023/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dhfflqmemfoqm',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2023/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'tmffpdlxmrmfpdl',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2023/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ekzmtldks',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2023/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'qjffldnem',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2023/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tpfpslxl',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2023/INFP.jpg',
      },
    ],
  },
  // personalScentBTI
  {
    info: {
      mainTitle: '퍼스널 향기 테스트',
      subTitle: '나의 퍼스널 향기는? - 퍼스널 향기 BTI',
      mainUrl: 'personalScentBTI',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/personalScentBTI-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/personalScentBTI-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          '내 마음에 쏙 든 향수를 발견했다!\n계산하려는데 직원이 바쁜것 같다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '저기요! 계산 먼저해주세요!',
          },
          {
            type: 'I',
            score: 5,
            content: '직원이 카운터로 올때까지 딴청피우며 눈치본다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '가게 직원이 따라다니면서 이것저것 추천해준다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '이건 기회야! 궁금했던 향수정보를 물어본다.',
          },
          {
            type: 'I',
            score: 5,
            content: '(제발 말걸지 말아주세요..) 아..감사합니다..',
          },
        ],
      },
      {
        which: 'EI',
        question: '이제 향수가게를 나와 밥을 먹으려한다. 어디로 갈까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '평소 가고 싶었던 요즘 유행하는 핫플!',
          },
          {
            type: 'I',
            score: 5,
            content: '사람이 별로 없는 여유롭고 조용한 식사.',
          },
        ],
      },
      {
        which: 'SN',
        question: '식당에 들어온 순간 가장 먼저 보이는 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '맛있는 음식들.',
          },
          {
            type: 'N',
            score: 5,
            content: '아기자기 예쁘게 꾸며둔 인테리어.',
          },
        ],
      },
      {
        which: 'SN',
        question: '딱 하나의 향수면 시향 할 수 있다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '평소 좋아하던 향기.',
          },
          {
            type: 'N',
            score: 5,
            content: '한번도 시도 안 해본 향기.',
          },
        ],
      },
      {
        which: 'SN',
        question:
          '집에 도착해 향수를 뜯어보았는데 패키지가 너무 예쁘다..어쩌지?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '어차피 쓰레긴데 그냥 버린다.',
          },
          {
            type: 'N',
            score: 5,
            content: '향수병 옆에 두면 너무 예쁘겠다!',
          },
        ],
      },
      {
        which: 'TF',
        question: '직원의 추천을 받은 향수! 냄새가 정말 너무 별로라면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '감사한데..\n제 취향은 아닌 것 같아요..',
          },
          {
            type: 'F',
            score: 5,
            content: '와.. 좋은 것 같아요!\n혹시 다른 향도 추천해주시겠어요?',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '직원이 실수로 진열장의 향수병을 전부 깨버렸다!\n당신의 속마음은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: "'와 저게 다 얼마야..'",
          },
          {
            type: 'F',
            score: 5,
            content: "'헉..어떻게..안다치셨나?..속상하시겠다..'",
          },
        ],
      },
      {
        which: 'TF',
        question: '친구의 생일에 카톡으로 선물을 보냈는데 답이 없다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '바쁜가보네',
          },
          {
            type: 'F',
            score: 5,
            content: '혹시 잘못보냈나? 생일이 아닌가?',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '내일은 향수를 사러가는 날!\n설레는 마음으로 침대에 누웠을때 드는 생각은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '오픈시간, 교통편 모두 확인했고.. 가기전에 한 번 더 확인해야겠다',
          },
          {
            type: 'P',
            score: 5,
            content: '어떤 향수가 있을까? 혹시 다 마음에 들면 어쩌지..',
          },
        ],
      },
      {
        which: 'JP',
        question: '다음날 아침, 향수가게에 도착한 당신! 근데 문이 닫혀있다.',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '이럴리가 없는데.. 가게 오픈시간을 확인해본다.',
          },
          {
            type: 'P',
            score: 5,
            content: '다른곳 구경하고 오면 열려있겠지~',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '이 향수 너무 맘에 든다! 곧 친구 생일인데..\n선물로 줘야겠다 언제사지?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '일주일전에 미리 미리 준비해준다.',
          },
          {
            type: 'P',
            score: 5,
            content: '당일에 사면 되겠지? 무슨 향을 좋아하려나?',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'citrus',
        img_src:
          'https://images.ktestone.com/resultImages/personalScentBTI/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gourmand',
        img_src:
          'https://images.ktestone.com/resultImages/personalScentBTI/ESTP.jpeg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'musk',
        img_src:
          'https://images.ktestone.com/resultImages/personalScentBTI/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'aqua',
        img_src:
          'https://images.ktestone.com/resultImages/personalScentBTI/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'herbal',
        img_src:
          'https://images.ktestone.com/resultImages/personalScentBTI/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'green',
        img_src:
          'https://images.ktestone.com/resultImages/personalScentBTI/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'metalic',
        img_src:
          'https://images.ktestone.com/resultImages/personalScentBTI/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'fruity',
        img_src:
          'https://images.ktestone.com/resultImages/personalScentBTI/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'floral',
        img_src:
          'https://images.ktestone.com/resultImages/personalScentBTI/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'oriental',
        img_src:
          'https://images.ktestone.com/resultImages/personalScentBTI/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'fresh',
        img_src:
          'https://images.ktestone.com/resultImages/personalScentBTI/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'powdery',
        img_src:
          'https://images.ktestone.com/resultImages/personalScentBTI/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'mossy',
        img_src:
          'https://images.ktestone.com/resultImages/personalScentBTI/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'aldehyde',
        img_src:
          'https://images.ktestone.com/resultImages/personalScentBTI/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'spicy',
        img_src:
          'https://images.ktestone.com/resultImages/personalScentBTI/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'woody',
        img_src:
          'https://images.ktestone.com/resultImages/personalScentBTI/INFP.jpg',
      },
    ],
  },
  // persoanlSixteenSkin
  {
    info: {
      mainTitle: '퍼스널 식스틴 스킨 테스트',
      subTitle: '나의 스킨 타입은? - 퍼스널 식스틴 스킨 테스트',
      mainUrl: 'persoanlSixteenSkin',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/persoanlSixteenSkin-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/persoanlSixteenSkin-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'etc',
    },
    questions: [
      {
        which: 'DO',
        question: '세안후 내 피부는?',
        answers: [
          {
            type: 'D',
            score: 2,
            content: '피부가 심하게 당긴다',
          },
          {
            type: 'O',
            score: 5,
            content: '당김이나 끈적임이 없다',
          },
        ],
      },
      {
        which: 'DO',
        question: '거울을 봤을 때 내 코는?',
        answers: [
          {
            type: 'D',
            score: 2,
            content: '모공이 잘보이지 않는다',
          },
          {
            type: 'O',
            score: 5,
            content: '모공이 잘보인다',
          },
        ],
      },
      {
        which: 'DO',
        question: '화장을 했을 때 나는?',
        answers: [
          {
            type: 'D',
            score: 2,
            content: '화장이 잘 먹지 않고 들뜬다',
          },
          {
            type: 'O',
            score: 5,
            content: '화장이 잘 지워진다',
          },
        ],
      },
      {
        which: 'RS',
        question: '모기에 물렸을 때 반응은?',
        answers: [
          {
            type: 'R',
            score: 2,
            content: '금방 사라진다',
          },
          {
            type: 'S',
            score: 5,
            content: '오래가고 잘 사라지지 않는다',
          },
        ],
      },
      {
        which: 'RS',
        question: '피부 때문에 병원에 가본 경험은?',
        answers: [
          {
            type: 'R',
            score: 2,
            content: '피부 관련 질환 치료를 받은적 없다',
          },
          {
            type: 'S',
            score: 5,
            content: '피부 관련 질환 치료를 받은적 있다',
          },
        ],
      },
      {
        which: 'RS',
        question: '얼굴에 뾰루지 낫을 때 나는?',
        answers: [
          {
            type: 'R',
            score: 2,
            content: '짜고나면 금방 사라진다',
          },
          {
            type: 'S',
            score: 5,
            content: '짜면 더 심해져서 가만히 둔다',
          },
        ],
      },
      {
        which: 'PN',
        question: '햇빛을 강하게 내리쬘때 나는?',
        answers: [
          {
            type: 'P',
            score: 2,
            content: '쉽게 피부가 붉어지고 잘 탄다',
          },
          {
            type: 'N',
            score: 5,
            content: '얼굴이 붉어지다 금방 돌아온다',
          },
        ],
      },
      {
        which: 'PN',
        question: '얼굴에 점이 났을 때 나는?',
        answers: [
          {
            type: 'P',
            score: 2,
            content: '평소에 좀 있는편이라 당연하다',
          },
          {
            type: 'N',
            score: 5,
            content: '엥?????말도 안돼',
          },
        ],
      },
      {
        which: 'TF',
        question: '내 피부의 색은?',
        answers: [
          {
            type: 'P',
            score: 2,
            content: '어두편에 속한다',
          },
          {
            type: 'N',
            score: 5,
            content: '하얀편에 속한다',
          },
        ],
      },
      {
        which: 'TW',
        question: '나의 나이는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '35세 이하다',
          },
          {
            type: 'W',
            score: 5,
            content: '35세 이상이다',
          },
        ],
      },
      {
        which: 'TW',
        question: '처음 본 사람이 동안이라고 했을 때 내 반응은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '자주 있는 일이라 아무렇지 않다',
          },
          {
            type: 'W',
            score: 5,
            content: '들어본적이 잘 없어 어색하다',
          },
        ],
      },
      {
        which: 'TW',
        question: '얼굴에 배개 자국이 생겼을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '금방 사라진다',
          },
          {
            type: 'W',
            score: 5,
            content: '자국이 오래 남는다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'DRNT',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'DRNT',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlSixteenSkin/DRNT.png',
      },
      {
        type: 'DRNW',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'DRNW',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlSixteenSkin/DRNW.png',
      },
      {
        type: 'DRPT',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'DRPT',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlSixteenSkin/DRPT.png',
      },
      {
        type: 'DRPW',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'DRPW',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlSixteenSkin/DRPW.png',
      },
      {
        type: 'DSNT',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'DSNT',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlSixteenSkin/DSNT.png',
      },
      {
        type: 'DSNW',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'DSNW',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlSixteenSkin/DSNW.png',
      },
      {
        type: 'DSPT',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'DSPT',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlSixteenSkin/DSPT.png',
      },
      {
        type: 'DSPW',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'DSPW',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlSixteenSkin/DSPW.png',
      },
      {
        type: 'ORNT',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ORNT',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlSixteenSkin/ORNT.png',
      },
      {
        type: 'ORNW',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ORNW',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlSixteenSkin/ORNW.png',
      },
      {
        type: 'ORPT',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ORPT',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlSixteenSkin/ORPT.png',
      },
      {
        type: 'ORPW',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ORPW',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlSixteenSkin/ORPW.png',
      },
      {
        type: 'OSNT',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'OSNT',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlSixteenSkin/OSNT.png',
      },
      {
        type: 'OSNW',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'OSNW',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlSixteenSkin/OSNW.png',
      },
      {
        type: 'OSPT',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'OSPT',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlSixteenSkin/OSPT.png',
      },
      {
        type: 'OSPW',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'OSPW',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlSixteenSkin/OSPW.png',
      },
    ],
  },
  // depressionBTI
  {
    info: {
      mainTitle: '우울증 BTI',
      subTitle: '나의 우울증 순위는? - 우울증 BTI',
      mainUrl: 'depressionBTI',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/depressionBTI-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/depressionBTI-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'etc',
    },
    questions: [
      {
        which: 'EI',
        question: '나의 에너지가 더 높아질 때는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구들과 만나서 파티하고 놀 때!',
          },
          {
            type: 'I',
            score: 5,
            content: '집에서 조용히 여유를 즐길 때!',
          },
        ],
      },
      {
        which: 'EI',
        question: '낯선 장소에 갔을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '낯선 곳도, 사람도 좋아! 일단 다 친해지자!',
          },
          {
            type: 'I',
            score: 5,
            content: '주변의 분위기와 무드를 고요히 즐긴다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '내가 생각하는 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '활발하고 적극적이고 사람들을 좋아하는 스타일.',
          },
          {
            type: 'I',
            score: 5,
            content: '조용하고 진중하고 나 스스로를 좋아하는 스타일.',
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 계획한 큰 일이 있을 때 더 걱정 되는 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '지금 당장 내가 무엇을 해결할 지 걱정.',
          },
          {
            type: 'N',
            score: 5,
            content: '이게 안됐을 때에 대한 상상과 걱정.',
          },
        ],
      },
      {
        which: 'SN',
        question: '새로운 전자기기를 선물 받았다 이 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '설명서를 보면서 차근차근 해본다.',
          },
          {
            type: 'N',
            score: 5,
            content: '설명서가 무엇? 일단 이리저리 만져본다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 배우고 싶은게 생겼다. 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '내가 배우고 싶었던 것 자체에 집중을한다.',
          },
          {
            type: 'N',
            score: 5,
            content: '이 것도 저 것도 배우고 싶어서 다방면적 집중을 원한다.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '친구가 고민을 이야기하는데 친구가 잘못한 것 같다. 이 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '너가 잘못한거 맞네.',
          },
          {
            type: 'F',
            score: 5,
            content: '내가 생각하기에는 이렇게 저렇게~ 한게 안좋지 않을까..?',
          },
        ],
      },
      {
        which: 'TF',
        question: '우울증이라는건 뭐라고 생각하는지?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '우울증은 사람을 만나면 해결된다! 으하하!',
          },
          {
            type: 'F',
            score: 5,
            content: '우울증은.. 내가 우울할 때 끝없이 우울해지는거..?',
          },
        ],
      },
      {
        which: 'TF',
        question: '누군가 나에게 우울하다고 할 때, 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '우울한건 취미와 사람을 만나면 해결 될거야!.',
          },
          {
            type: 'F',
            score: 5,
            content: '왜 우울해..? 요즘에 어려운 일 있어?',
          },
        ],
      },
      {
        which: 'JP',
        question: '계획을 세울 때, 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '지금 당장 해야하는 것 부터 계획의 끝까지 철저하게!',
          },
          {
            type: 'P',
            score: 5,
            content: '전체적인 아웃라인만 설계하고 융통성있게 처리!',
          },
        ],
      },
      {
        which: 'JP',
        question: '오랜만인 친구가 연락이 왔다, 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '오 잘지냈어? 한번 볼까? 언제 어디서 만날까?',
          },
          {
            type: 'P',
            score: 5,
            content: '잘 지냈지~~ 언제한번 봐야지',
          },
        ],
      },
      {
        which: 'JP',
        question: '우울증 해결을 위해 네이버 검색을 했다, 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '아.. 이런게 우울증이구나 이렇게 이렇게 해결하면 되겠군!',
          },
          {
            type: 'P',
            score: 5,
            content: '우울증 반대말은 조울증이네..오호?',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'gkdlemfpswldk',
        img_src:
          'https://images.ktestone.com/resultImages/depressionBTI/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'tmxpaflffl',
        img_src:
          'https://images.ktestone.com/resultImages/depressionBTI/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'qmffpdzpt',
        img_src:
          'https://images.ktestone.com/resultImages/depressionBTI/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'wlqthvlffk',
        img_src:
          'https://images.ktestone.com/resultImages/depressionBTI/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tlej',
        img_src:
          'https://images.ktestone.com/resultImages/depressionBTI/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'tlspstltm',
        img_src:
          'https://images.ktestone.com/resultImages/depressionBTI/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'xbfflq',
        img_src:
          'https://images.ktestone.com/resultImages/depressionBTI/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'vmflwldk',
        img_src:
          'https://images.ktestone.com/resultImages/depressionBTI/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'tjsvmffkdnj',
        img_src:
          'https://images.ktestone.com/resultImages/depressionBTI/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'dnlffhdn',
        img_src:
          'https://images.ktestone.com/resultImages/depressionBTI/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gpffldhxmfhvm',
        img_src:
          'https://images.ktestone.com/resultImages/depressionBTI/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'fkvldhffpvltm',
        img_src:
          'https://images.ktestone.com/resultImages/depressionBTI/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'flem',
        img_src:
          'https://images.ktestone.com/resultImages/depressionBTI/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ekvhelf',
        img_src:
          'https://images.ktestone.com/resultImages/depressionBTI/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'tpffksekdls',
        img_src:
          'https://images.ktestone.com/resultImages/depressionBTI/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'vlxhtmvhfna',
        img_src:
          'https://images.ktestone.com/resultImages/depressionBTI/INFP.png',
      },
    ],
  },
  // flowerLang
  {
    info: {
      mainTitle: '꽃말 BTI',
      subTitle: '꽃말 성격 유형 검사',
      mainUrl: 'flowerLang',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/flowerLang-intro.jpeg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/flowerLang-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '친구들과 이야기를 할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '하고싶은 얘기가 너무 많아서 친구의 말을 끊고 얘기하기도 한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '끝까지 얘기를 들어보고 천천히 얘기한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '궁금한게 있어서 카톡을 했는데 답장이 오지않을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구야.. 빨리 답장좀 해줄래? 급해.',
          },
          {
            type: 'I',
            score: 5,
            content: '바쁜거같은데? 답장 올 때 까지 기다리자.',
          },
        ],
      },
      {
        which: 'EI',
        question: '밥을 먹다가 직원을 불렀는데 직원이 오지 않을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '사장님!! 여기요!! 하고 큰 소리로 부른다.',
          },
          {
            type: 'I',
            score: 5,
            content: '왜 오지 않을까..? 하면서 조금 더 기다려본다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '친구들과 1차에 신나게 놀고 2차를 가자고 한다고 할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '애들아 미안 나 피곤해 집 갈게 안녕.',
          },
          {
            type: 'N',
            score: 5,
            content: '아 피곤한데 일단 가자!!!',
          },
        ],
      },
      {
        which: 'SN',
        question: '평소에 내가 사고싶었던 물건이 있을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '음.. 이번 달 텅장을 생각하면 다음으로 미뤄야겠어.',
          },
          {
            type: 'N',
            score: 5,
            content:
              '소비는 행복한 것이다. 다음에 사나 지금 사나 똑같지. 사자!!',
          },
        ],
      },
      {
        which: 'SN',
        question: '친구와 영화를 보는데 열린 결말로 끝났을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '감독의 의도가 뭘까? 왜 이런 결말일까? 찾아본다.',
          },
          {
            type: 'N',
            score: 5,
            content: '그 사람들은 행복하게 오래오래 살꺼야.. 만족 만족',
          },
        ],
      },
      {
        which: 'TF',
        question: '내 앞자리에서 술마시는 친구가 울고있을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '울어? 왜 울어? 왜? 왜?',
          },
          {
            type: 'F',
            score: 5,
            content: '뭐라고 위로를 해줘야할까…?',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 오늘 짜증나서 매운거를 먹는다 할 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '오 매운거 뭐먹게? 닭발?',
          },
          {
            type: 'F',
            score: 5,
            content: '어? 왜 짜증났어?',
          },
        ],
      },
      {
        which: 'TF',
        question: '거절을 해야 할 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '미안. 어려울 것 같아.',
          },
          {
            type: 'F',
            score: 5,
            content: '음 어려울 것 같은데… 고민한번 하고 해준다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '나와 더 맞는 것은 어떤 것일까?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '계획적',
          },
          {
            type: 'P',
            score: 5,
            content: '즉흥적',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구에게 안부 연락이 왔을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '잘지내지~~ 언제 만날래?',
          },
          {
            type: 'P',
            score: 5,
            content: '그럼 잘지내지~ 조만간 한번 보자구~',
          },
        ],
      },
      {
        which: 'JP',
        question: '약속시간이 다가오고 있을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '슬슬 출발해야 늦지 않겠네.. 지금오는 버스 타야지.',
          },
          {
            type: 'P',
            score: 5,
            content: '스펙타클하게 시간에 맞을 듯 안맞을 듯 간다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'gkdlemfpswldk',
        img_src: 'https://images.ktestone.com/resultImages/flowerLang/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'tmxpaflffl',
        img_src: 'https://images.ktestone.com/resultImages/flowerLang/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'qmffpdzpt',
        img_src: 'https://images.ktestone.com/resultImages/flowerLang/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'wlqthvlffk',
        img_src: 'https://images.ktestone.com/resultImages/flowerLang/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tlej',
        img_src: 'https://images.ktestone.com/resultImages/flowerLang/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'tlspstltm',
        img_src: 'https://images.ktestone.com/resultImages/flowerLang/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'xbfflq',
        img_src: 'https://images.ktestone.com/resultImages/flowerLang/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'vmflwldk',
        img_src: 'https://images.ktestone.com/resultImages/flowerLang/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'tjsvmffkdnj',
        img_src: 'https://images.ktestone.com/resultImages/flowerLang/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'dnlffhdn',
        img_src: 'https://images.ktestone.com/resultImages/flowerLang/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'gpffldhxmfhvm',
        img_src: 'https://images.ktestone.com/resultImages/flowerLang/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'fkvldhffpvltm',
        img_src: 'https://images.ktestone.com/resultImages/flowerLang/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'flem',
        img_src: 'https://images.ktestone.com/resultImages/flowerLang/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ekvhelf',
        img_src: 'https://images.ktestone.com/resultImages/flowerLang/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'tpffksekdls',
        img_src: 'https://images.ktestone.com/resultImages/flowerLang/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'vlxhtmvhfna',
        img_src: 'https://images.ktestone.com/resultImages/flowerLang/INFP.jpg',
      },
    ],
  },
  // KMBTI
  {
    info: {
      mainTitle: 'K-MBTI 검사',
      subTitle: '케이테스트 성격 유형 검사',
      mainUrl: 'kbmti',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/kbmti-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '평상시 나의 대화 스타일은',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '활발하게 대화하며 대화 주제가 다양하다.',
          },
          {
            type: 'I',
            score: 5,
            content: '말수가 적고 조용하며 필요한 때가 아니라면 가만히 있는다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '다른 사람이 보기에 나는',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '개방적이고 활동적인 밝은 에너지가 있는사람이다.',
          },
          {
            type: 'I',
            score: 5,
            content: '늘 신중하며 조용하고 깊은 사람이다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '새로운 장소나 모임에 갔을 때 나와 비슷한 것은',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '먼저 자기 자신을 소개하고 대화를 시도한다.',
          },
          {
            type: 'I',
            score: 5,
            content:
              '가만히 주변의 분위기를 살피며 질문보다는 대답을 하는 편이다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '무언가를 설치하거나 만들 때 나의 모습은',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '설명서에 나온 정석 그대로 설치하거나 만든다.',
          },
          {
            type: 'N',
            score: 5,
            content: '한번 보고 감으로 설치하거나 만든다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '나와 비슷한 유형은',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '현실적이고 세부사항을 잘 다루는 사람이다.',
          },
          {
            type: 'N',
            score: 5,
            content: '상상력과 아이디어가 풍부한 사람이다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '업무(공부)스타일과 비슷한 것은',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '지금 현재 주어진 일에만 집중한다.',
          },
          {
            type: 'N',
            score: 5,
            content:
              '한 가지만 하는 것보단 여러가지 일을 다양하게 하고 싶어한다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '고민 상담을 들어줄 때 나와 비슷한 것은',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '사실을 기반으로 현실적인 조언을 해준다.',
          },
          {
            type: 'F',
            score: 5,
            content: '그 사람의 입장이 되어 감정적인 공감을 해준다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '상대방과 논쟁 시 나는',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '논쟁에서 이기는 것이 중요하다.',
          },
          {
            type: 'F',
            score: 5,
            content: '상대방의 기분을 고려하는 게 마음이 편하다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '감정에 대한 나의 생각은',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '사람으로 인해 화가 나는 일이 드물며, 감정에 공감하는 일이 드물다.',
          },
          {
            type: 'F',
            score: 5,
            content:
              '사람에 의한 감정 이입이 쉽고 사람으로 인해 화가는 경우가 많다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '나에게 계획은',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '계획은 하나부터 열까지 세세하고 철저하게 세우는 편이다.',
          },
          {
            type: 'P',
            score: 5,
            content: '큰 틀만 잡고 나머지는 유연하게 처리하는 편이다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '일이나 공부하는 스타일로 나와 비슷한 것은',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '시간 단위와 계획을 세워 업무/공부를 끝낸다.',
          },
          {
            type: 'P',
            score: 5,
            content: '정해진 순서보다 그때그때 몰아서 끝낸다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '나의 여행스타일과 비슷한 것은',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '예측 가능하고 계획한 범위 내에서 여행한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '즉흥적으로 그날의 느낌에 따라 여행한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'gladeGreen',
        img_src: 'https://images.ktestone.com/resultImages/kbmti/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'tangerine',
        img_src: 'https://images.ktestone.com/resultImages/kbmti/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'cosmos',
        img_src: 'https://images.ktestone.com/resultImages/kbmti/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'froly',
        img_src: 'https://images.ktestone.com/resultImages/kbmti/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'mariner',
        img_src: 'https://images.ktestone.com/resultImages/kbmti/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'valencia',
        img_src: 'https://images.ktestone.com/resultImages/kbmti/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'aquamarine',
        img_src: 'https://images.ktestone.com/resultImages/kbmti/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'sunshade',
        img_src: 'https://images.ktestone.com/resultImages/kbmti/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'gainsboro',
        img_src: 'https://images.ktestone.com/resultImages/kbmti/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'bostonBlue',
        img_src: 'https://images.ktestone.com/resultImages/kbmti/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'grandis',
        img_src: 'https://images.ktestone.com/resultImages/kbmti/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'olivine',
        img_src: 'https://images.ktestone.com/resultImages/kbmti/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'bilobaFlower',
        img_src: 'https://images.ktestone.com/resultImages/kbmti/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'mayaBlue',
        img_src: 'https://images.ktestone.com/resultImages/kbmti/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'hawkesBlue',
        img_src: 'https://images.ktestone.com/resultImages/kbmti/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'genoa',
        img_src: 'https://images.ktestone.com/resultImages/kbmti/INFP.jpg',
      },
    ],
  },
  // halfTestSN
  {
    info: {
      mainTitle: '반쪽테스트 S와 N편',
      subTitle: '나의 성향은 S와 N 중 무엇일까?',
      mainUrl: 'halfTestSN',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/halfTestSN-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/halfTestSN-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'SN',
        question: '그냥 아무 생각하지마 라고 했을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '말그대로 아무 생각 안할 수 있다.',
          },
          {
            type: 'N',
            score: 5,
            content: '멍때리는 순간, 아무 생각도 하지 않는 것이 어렵다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '공부를 해야하지만 하기 싫을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '무슨 이유가 필요해? 그냥 내가 하기 싫다고.',
          },
          {
            type: 'N',
            score: 5,
            content:
              '아니 공부는 왜 해야하는 걸까? 공부를 해서 내가 먼 미래에 돈이라도 많이 버나?',
          },
        ],
      },
      {
        which: 'SN',
        question:
          '만약에 너가 500억을 주었어..그럼 어케 할거야? 라는 질문에 나의 대답과 비슷한 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '신고해서 사례금 받겠지? 대답은 하겠지만 솔직히 일어나지도 않는 일 왜 대답해야하나 싶음.',
          },
          {
            type: 'N',
            score: 5,
            content:
              '음…나는 일단 경찰에 신고를 했어…근데 알고보니 그 돈이 횡령이었던거야!! 난 사건을 해결하고 도움을 줘서 사례금도 받고, 뉴스에 나오고 용감한 신인상을 받게 되는거지.',
          },
        ],
      },
      {
        which: 'SN',
        question: '여행가기 전날 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '빨리 가고 싶다! 짐을 꾸린 후 바로 잠에 든다',
          },
          {
            type: 'N',
            score: 5,
            content:
              '자기 전 여행지에서 일어난 사건들에 대해 온갖 상상을 하다가 잠든다',
          },
        ],
      },
      {
        which: 'SN',
        question: '요리할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '레시피에 나온대로 정량을 딱딱 맞추려고 노력한다.',
          },
          {
            type: 'N',
            score: 5,
            content: '대충 이정도 넣으면 되겠지~ 하며 감으로 넣는다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '나에게 딱 맞는 업무는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '디테일한 세부사항을 다루는 업무',
          },
          {
            type: 'N',
            score: 5,
            content: '상상력과 아이디어가 요구되는 업무',
          },
        ],
      },
      {
        which: 'SN',
        question: '나의 가치관과 더 맞는 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '현재가 없으면 미래도 없다.',
          },
          {
            type: 'N',
            score: 5,
            content: '미래를 생각하지 않으면 발전이 없다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'S',
        desc: `
                `,
        query: 'S',
        img_src:
          'https://images.ktestone.com/resultImages/halfTestSN/S-result.jpg',
      },
      {
        type: 'N',
        desc: ``,
        query: 'N',
        img_src:
          'https://images.ktestone.com/resultImages/halfTestSN/N-result.jpg',
      },
    ],
  },
  // halfTestEI
  {
    info: {
      mainTitle: '반쪽테스트 E와 I편',
      subTitle: '나의 성향은 E와 I 중 무엇일까?',
      mainUrl: 'halfTestEI',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/halfTestEI-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/halfTestEI-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '대화할 때 모습과 비슷한 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '할 얘기가 넘쳐나 먼저 상대방의 말을 끊을 때도 있다.',
          },
          {
            type: 'I',
            score: 5,
            content: '일단 먼저 들어보고 할 얘기가 있으면 한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '내가 더 잘하는 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '상대방의 기분을 좋게 하는 방법을 알고 있어 분위기 전환에 능숙하다.',
          },
          {
            type: 'I',
            score: 5,
            content: '상대방의 기분을 나쁘지 않게 배려하고 만족스러워한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '몇 개월 동안 집에서 나가지 못한다면? ',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '친구들의 인스타 스토리를 보며, 너무 집에만 있는 건 고통스럽다.',
          },
          {
            type: 'I',
            score: 5,
            content: '티비, 간식거리, 포근한 이불 등 조건만 충족하면 가능하다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '오늘 하루 기똥차게 놀고 집에 들어갈 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '오늘 너무 잘놀았다! 안갔으면 후회할뻔! 또 놀아야지!',
          },
          {
            type: 'I',
            score: 5,
            content: '와~ 잘놀았다! 이제 집가면 충전하면서 푹 쉬어야겠다',
          },
        ],
      },
      {
        which: 'EI',
        question: '폰을 할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '카톡으로 답하다가 답답하면 바로 전화를 건다.',
          },
          {
            type: 'I',
            score: 5,
            content: '전화가 오면 망설이고 카톡도 안읽씹이 많다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '파티에 초대하고 싶은 사람은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구+친구의 친구 어느정도 친한 사람들 다 부르고 놀자!',
          },
          {
            type: 'I',
            score: 5,
            content: '진짜 친하다고 생각하는 친구들만 부르자!',
          },
        ],
      },
      {
        which: 'EI',
        question: '회의에 참여할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '일단 거침없이 떠오르는 좋은 생각에 대해 자신감 있게 말하는 편이다.',
          },
          {
            type: 'I',
            score: 5,
            content:
              '충분한 준비시간이 필요하며, 심사숙고하며 문맥을 정리한 뒤 말한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'E',
        desc: `
                `,
        query: 'E',
        img_src:
          'https://images.ktestone.com/resultImages/halfTestEI/E-result.png',
      },
      {
        type: 'I',
        desc: ``,
        query: 'I',
        img_src:
          'https://images.ktestone.com/resultImages/halfTestEI/I-result.png',
      },
    ],
  },
  // drinkBTI
  {
    info: {
      mainTitle: '드링크BTI(drinkBTI)',
      subTitle: '나의 업무 스타일 드링크 찾으러 가기',
      mainUrl: 'drinkBTI',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/drinkBTI-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/drinkBTI-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '문득 술이 땡기는 날 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구들에게 연락을 돌리며 술친구를 찾는다',
          },
          {
            type: 'I',
            score: 5,
            content: '집에서 사색을 즐기며 혼술 한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '술자리 중 친구가 아는 지인을 불러도 되냐고 물어본다면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '좋지! 불러 불러~!!',
          },
          {
            type: 'I',
            score: 5,
            content: '아 갑자기? 그냥 우리끼리 마시자~',
          },
        ],
      },
      {
        which: 'EI',
        question: '술집에서 벨을 눌렀는데 아무도 오지 않을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `큰 소리로 직원을 부른다`,
          },
          {
            type: 'I',
            score: 5,
            content: `손을 들거나, 벨을 한 번 더 누르고 기다린다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '술과 안주를 직원이 매우 불안하게 들고 온다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `저 직원 왜 저렇게 아슬아슬하게 들고 오는 거지??`,
          },
          {
            type: 'N',
            score: 5,
            content: `이미 내 앞에서 와장창! 하는 상상을 하며 불안해한다`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '술자리 먹다가 갑자기 친구가 만약에 로또 당첨되면 어떻게 할 거냐고 묻는다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '로또를 안 샀는데? 로또 사주고 물어봐 친구야^^',
          },
          {
            type: 'N',
            score: 5,
            content: `나는 하고 싶은 거 많아!! 사고 싶었던 거 다 사고 얼마는 저축하고… `,
          },
        ],
      },
      {
        which: 'SN',
        question: '새로 주문한 안주가 나왔는데 한 입 먹자마자 입에서 녹는다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `와 입에서 녹네! 너무 맛있는데?!`,
          },
          {
            type: 'N',
            score: 5,
            content: `와 입에서 녹네! 어떻게 만든 거지? 혹시 MSG 잔뜩 들어간 거 아니야 이거?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `내 옆자리에 앉은 친구가 술에 취해 갑자기 운다면 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '갑자기 왜 울어? 무슨 일이야? ',
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮아?? 울지마~ 나까지 슬프잖아 ㅠㅠ',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '옆자리 테이블과 시비가 붙었다 하지만 내 친구가 잘못해 일어난 일이라면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `바로 친구에게 너의 잘못이라고 말해준다`,
          },
          {
            type: 'F',
            score: 5,
            content: '최대한 돌려서 너의 잘못이었다고 말해준다 ',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구가 많이 취한 것 같다며 집에 간다고 한다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '너 별로 안 먹은 거 같은데? 벌써 가? 에이~',
          },
          {
            type: 'F',
            score: 5,
            content: '그래~ 많이 먹긴 했어~ ',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구들과 2차를 가는 곳을 정할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `일단 나가서 정하자~`,
          },
          {
            type: 'P',
            score: 5,
            content: `여기서 정하고 나가자, 여기는 어때?`,
          },
        ],
      },
      {
        which: 'JP',
        question: '2차로 정한 술집으로 갔는데, 웨이팅을 해야 한다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '그냥 다른 술집으로 가자고 한다',
          },
          {
            type: 'P',
            score: 5,
            content: '이미 정한 곳이니, 기다리자고 한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '거의 끝나가는 술자리, 계산할 때쯤 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '미리 정산해 놓고, 누가 계산하고, 어떻게 받을 건지 정하자고 한다',
          },
          {
            type: 'P',
            score: 5,
            content: '우리 계산 어떻게 할까?',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `
                `,
        query: 'pomegranate',
        img_src: 'https://images.ktestone.com/resultImages/drinkBTI/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: ``,
        query: 'meron',
        img_src: 'https://images.ktestone.com/resultImages/drinkBTI/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: ``,
        query: 'peach',
        img_src: 'https://images.ktestone.com/resultImages/drinkBTI/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: ``,
        query: 'lemon',
        img_src: 'https://images.ktestone.com/resultImages/drinkBTI/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: ``,
        query: 'grapefruit',
        img_src: 'https://images.ktestone.com/resultImages/drinkBTI/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `
                `,
        query: 'pineapple',
        img_src: 'https://images.ktestone.com/resultImages/drinkBTI/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: ``,
        query: 'watermelon',
        img_src: 'https://images.ktestone.com/resultImages/drinkBTI/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: ``,
        query: 'orange',
        img_src: 'https://images.ktestone.com/resultImages/drinkBTI/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: ``,
        query: 'strawberry',
        img_src: 'https://images.ktestone.com/resultImages/drinkBTI/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: ``,
        query: 'grape',
        img_src: 'https://images.ktestone.com/resultImages/drinkBTI/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: ``,
        query: 'mango',
        img_src: 'https://images.ktestone.com/resultImages/drinkBTI/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `
                `,
        query: 'apple',
        img_src: 'https://images.ktestone.com/resultImages/drinkBTI/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: ``,
        query: 'lime',
        img_src: 'https://images.ktestone.com/resultImages/drinkBTI/INTJ.png',
      },
      {
        type: 'INTP',
        desc: ``,
        query: 'mangosteen',
        img_src: 'https://images.ktestone.com/resultImages/drinkBTI/INTP.png',
      },
      {
        type: 'INFJ',
        desc: ``,
        query: 'greengrape',
        img_src: 'https://images.ktestone.com/resultImages/drinkBTI/INFJ.png',
      },
      {
        type: 'INFP',
        desc: ``,
        query: 'blueberry',
        img_src: 'https://images.ktestone.com/resultImages/drinkBTI/INFP.png',
      },
    ],
  },
  // dragonBTI
  {
    info: {
      mainTitle: '드래곤BTI(dragonBTI)',
      subTitle: '메타버스 세상 속, 나는 과연 어떤 드래곤일까?',
      mainUrl: 'dragonBTI',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/dragonBTI-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/dragonBTI-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'etc',
    },
    questions: [
      {
        which: 'EI',
        question: '월급이 스쳐간 텅장을 보고 있자니\n투자에 관심이 생겼다.',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '주변 지인에게 증권사에서 일하는 사람을 소개해 달라고 한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '경제신문이나 주식 컨설턴트의 동영상을 찾아본다.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '존버는 승리한다!\n투자에 대박이 나서 퇴직서를 내고 나왔다!\n일단은 간절하게 쉬고 싶다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '진짜 오랜만에 여행 좀 갈까?\n여행가서 사람들을 사귀면 좋겠다.',
          },
          {
            type: 'I',
            score: 5,
            content:
              '집에서 쉬면서 밀린 드라마나 봐야겠다.\n집에서 뭐 해먹을까?',
          },
        ],
      },
      {
        which: 'EI',
        question: '쉬는 동안 사업 구상도 해볼까!\n늘 하고 싶던 사업이 있었지.',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '유명한 셀럽, DJ가 찾아오는 시끌벅적한 클럽',
          },
          {
            type: 'I',
            score: 5,
            content: '예약제로 받는 프라이빗한 고품격 레스토랑',
          },
        ],
      },
      {
        which: 'SN',
        question:
          '투자를 계속하다보니 NFT라는,\n새롭게 개척되는 시장을 알게 되었다',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '당장 NFT를 어떻게 살 수 있는지부터 알아보자.\n어떤 게 제일 예쁘지? 지금 사면 왠지 모르게 행복할 것 같아',
          },
          {
            type: 'N',
            score: 5,
            content:
              '나중에 시장이 확대되면 집입하기 어려울 것 같은데?\n구체적으로 어떤 혜택이 있는지 알아 보고 얼른 투자해 봐야겠다.\n나도 곧 부자가 될 수 있는 건가! ',
          },
        ],
      },
      {
        which: 'SN',
        question:
          '최근 열심히 모은 돈으로 NFT를 구매했다.\n이 NFT는 Metaverse에서 살아갈 나를 대변한다는데...',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '재밌으려나?\n메타버스 로드맵, 사용법, 규칙부터 정확히 파악해 봐야겠다.\n내가 꾸준히 할 수 있으려나?',
          },
          {
            type: 'N',
            score: 5,
            content:
              '메타버스라니!\n엄청 자유로울 것 같아!\n열정이 솟아오른다!',
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 투자한 상품들의 가치가 하락하고 있는 것을 보며.',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              "회사의 '자본금, 통계 수치' 등을 따져봤을 때\n현실적으로 나의 선택은 틀리지 않을 거야",
          },
          {
            type: 'N',
            score: 5,
            content:
              "내가 투자한 회사의 '비전과 브랜드 아이덴티티'가 명확하기에 신경 쓰지 않아.",
          },
        ],
      },
      {
        which: 'TF',
        question:
          '비슷한 시기에 투자를 시작한 친구가 빚내서 투자했는데,\n손실이 엄청났다.',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '너, 그거 더 떨어지기 전에 손절해야겠다.',
          },
          {
            type: 'F',
            score: 5,
            content: '엥? 그거 잘 되고 있다고 하지 않았어?\n어떡하냐 정말…',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '나는 첫 투자에 성공했다!\n마침 계좌가 텅 비어가던 친구의 생일이 다가온다.',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '백화점가서 선물을 고르고 내 시간을 소비하느니 NFT를 보내는게 더 효율적이잖아?\nNFT를 보내줘야겠다!',
          },
          {
            type: 'F',
            score: 5,
            content:
              '친구가 좋아할만한 NFT를 선물해줘야지!\n이 NFT는 귀여우니까 분명 친구가 좋아할꺼야 후후!',
          },
        ],
      },
      {
        which: 'TF',
        question: '친구가 또 사고를 쳤다.\n이번에는 투자한 P2E게임이 망했단다.',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '친구가 투자한 P2E가 왜 망했는지 조목조목 설명해가며\n다시는 같은 실수를 번복하지 말라고 조언한다.',
          },
          {
            type: 'F',
            score: 5,
            content: '자신도 씁쓸했던 일화를 털어놓고 공감해주며 위로한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '월급을 받으면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '고정비, 유동비, 생활비, 교통비 등\n구체적으로 항목을 세분화하여 사용처를 나눈다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '이번달 빠져나갈 신용카드 값을 제외하고 나머지는 유동적으로 쓰는 편이다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '본격적으로 투자를 시작해볼까!',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '항목별 구매 내역, 판매 내역, 손익계산 등을 기록하여 체계적으로 관리한다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '일일이 기록하기엔 복잡해서\n입금액, 출금액만 확인해서 손익 파악을 한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '최근 유행하고 있는 P2E게임을 해보려고 한다.',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '홈페이지에서 가이드, 캐릭터 설명, 세계관 등을\n꼼꼼히 읽고 시작한다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '게임은 하면서 익히는거지!\n일단 이것저것 잡히는 대로 해보면서 그때 그때 적응해 나간다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `
                `,
        query: 'gydbfm',
        img_src: 'https://images.ktestone.com/resultImages/dragonBTI/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: ``,
        query: 'dkemqps',
        img_src: 'https://images.ktestone.com/resultImages/dragonBTI/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: ``,
        query: 'xhekrxh',
        img_src: 'https://images.ktestone.com/resultImages/dragonBTI/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: ``,
        query: 'vmflejam',
        img_src: 'https://images.ktestone.com/resultImages/dragonBTI/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: ``,
        query: 'sjfqmsti',
        img_src: 'https://images.ktestone.com/resultImages/dragonBTI/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `
                `,
        query: 'enakcl',
        img_src: 'https://images.ktestone.com/resultImages/dragonBTI/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: ``,
        query: 'wosmdaksha',
        img_src: 'https://images.ktestone.com/resultImages/dragonBTI/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: ``,
        query: 'dprxlqhfxk',
        img_src: 'https://images.ktestone.com/resultImages/dragonBTI/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: ``,
        query: 'ejclrclzm',
        img_src: 'https://images.ktestone.com/resultImages/dragonBTI/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: ``,
        query: 'dlselqlwbdjf',
        img_src: 'https://images.ktestone.com/resultImages/dragonBTI/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: ``,
        query: 'tjdtlfntm',
        img_src: 'https://images.ktestone.com/resultImages/dragonBTI/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `
                `,
        query: 'ghkgkqm',
        img_src: 'https://images.ktestone.com/resultImages/dragonBTI/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: ``,
        query: 'dkwm',
        img_src: 'https://images.ktestone.com/resultImages/dragonBTI/INTJ.png',
      },
      {
        type: 'INTP',
        desc: ``,
        query: 'xnwkem',
        img_src: 'https://images.ktestone.com/resultImages/dragonBTI/INTP.png',
      },
      {
        type: 'INFJ',
        desc: ``,
        query: 'ejrhehzm',
        img_src: 'https://images.ktestone.com/resultImages/dragonBTI/INFJ.png',
      },
      {
        type: 'INFP',
        desc: ``,
        query: 'tnwnqjr',
        img_src: 'https://images.ktestone.com/resultImages/dragonBTI/INFP.png',
      },
    ],
  },
  // flowerBTIJP
  {
    info: {
      mainTitle: 'flowerBTIJP',
      subTitle: 'What kind of flower is my love life?',
      mainUrl: 'flowerBTIJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/flowerBTIJP-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/flowerBTIJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'あなたの週末の過ごし方は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '家の中は退屈すぎる！\nどこか出かけよう！',
          },
          {
            type: 'I',
            score: 5,
            content: 'やっぱり週末には自宅でテレビ見て休むに限る。',
          },
        ],
      },
      {
        which: 'EI',
        question: '新学期の始まり！\n好みの異性を見つけたら？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '自分から近付いて挨拶する。',
          },
          {
            type: 'I',
            score: 5,
            content: '遠くでチラ見するだけ。',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '好きな異性の前に連れて行かれて、\n話しかけてみようよ、\nと友達に促された。',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '一緒に笑いながら楽しく会話する。',
          },
          {
            type: 'I',
            score: 5,
            content: 'ぎこちなくて照れくさくて静かに笑うだけ。',
          },
        ],
      },
      {
        which: 'SN',
        question: '好きな人のことを思うときの自分は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '現実的にこの恋は叶うだろうか？',
          },
          {
            type: 'N',
            score: 5,
            content:
              'もしあの人と付き合ったら…あれもこれもしたい～もう、\nときめきが止まらない！',
          },
        ],
      },
      {
        which: 'SN',
        question: '恋に落ちたあなたの目に、\n世界はどんな風に映っていますか？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '「山は山\n水は水」ありのままに見えている。',
          },
          {
            type: 'N',
            score: 5,
            content: '現実よりは自分だけの世界、\n妄想が広がる。',
          },
        ],
      },
      {
        which: 'SN',
        question: 'デート中、\nお腹が減ってきたところ丁度いい店を見つけた。',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'レビューを見たけど、\n良い店らしい！この店に入ろう！',
          },
          {
            type: 'N',
            score: 5,
            content: 'なんとなくだけど、\nこの店絶対に美味しいと思うよ。',
          },
        ],
      },
      {
        which: 'TF',
        question: 'あなたの「怒りタイプ」は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '怒った理由について理性的に話す。',
          },
          {
            type: 'F',
            score: 5,
            content: '怒りがこみ上げてきて涙が出る。',
          },
        ],
      },
      {
        which: 'TF',
        question: '好きな人が大変な状況に置かれたら？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '現実的な解決策を提示して応援する。',
          },
          {
            type: 'F',
            score: 5,
            content: 'その人の気持ちに共感してあげて、\n精神的に支えてあげる。',
          },
        ],
      },
      {
        which: 'TF',
        question: '好きな人と話すときの自分は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '相手のことについて知りたいので色々質問する。',
          },
          {
            type: 'F',
            score: 5,
            content: '相手の話にリアクションを打ちつつ、\n話に共感してあげる。',
          },
        ],
      },
      {
        which: 'JP',
        question: '突然済州島に行きたくなった。\n旅行に行く時はどうする？ ',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '曜日ごとに詳細な日程を計画する。',
          },
          {
            type: 'P',
            score: 5,
            content:
              '飛行機のチケットは予約した。\nこれで計画することはもう終わった。',
          },
        ],
      },
      {
        which: 'JP',
        question: '自分により近いタイプは？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '整理整頓上手で、\n物の置き場所を把握している。',
          },
          {
            type: 'P',
            score: 5,
            content:
              '物の置き場所をちゃんと把握しているつもりだったのに、\nいざ探すとその場には無い…',
          },
        ],
      },
      {
        which: 'JP',
        question: '好きな人に告白するなら？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '徹底的にシミュレーションして告白するタイミングを計らう。',
          },
          {
            type: 'P',
            score: 5,
            content: '雰囲気をみて、\n直感的に告白する。',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `
                `,
        query: 'mayweed',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIJP/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: ``,
        query: 'mango',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIJP/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: ``,
        query: 'grace',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIJP/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: ``,
        query: 'phlox',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIJP/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: ``,
        query: 'marigold',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIJP/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `
                `,
        query: 'ranunculus',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIJP/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: ``,
        query: 'janaRose',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIJP/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: ``,
        query: 'pompon',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIJP/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: ``,
        query: 'charment',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIJP/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: ``,
        query: 'lisianthus',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIJP/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: ``,
        query: 'hyacinth',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIJP/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `
                `,
        query: 'sweet',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIJP/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: ``,
        query: 'statice',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIJP/INTJ.png',
      },
      {
        type: 'INTP',
        desc: ``,
        query: 'sunflower',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIJP/INTP.png',
      },
      {
        type: 'INFJ',
        desc: ``,
        query: 'mini',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIJP/INFJ.png',
      },
      {
        type: 'INFP',
        desc: ``,
        query: 'freesia',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIJP/INFP.png',
      },
    ],
  },
  // flowerBTIEng
  {
    info: {
      mainTitle: 'flowerBTIEng',
      subTitle: 'What kind of flower is my love life?',
      mainUrl: 'flowerBTIEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/flowerBTIEng-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/flowerBTIEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'On a honey-like weekend?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'The house is so boring!\nI will go anywhere!',
          },
          {
            type: 'I',
            score: 5,
            content:
              'After all,\nI should take a rest while watching TV\nat home on weekends.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'The start of a new semester!\nI saw a guy I liked.',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Approach him first to say hello.',
          },
          {
            type: 'I',
            score: 5,
            content: "I'm just staring at you.",
          },
        ],
      },
      {
        which: 'EI',
        question:
          'Your friend asks you to drag you\nin front of the opposite sex and\nhave a conversation.',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Laugh and have fun talking together.',
          },
          {
            type: 'I',
            score: 5,
            content: "I'm shy,\nso I just smile quietly and be awkward.",
          },
        ],
      },
      {
        which: 'SN',
        question: 'When I think of the person I like,\nwhat do I do?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Can it be done realistically?',
          },
          {
            type: 'N',
            score: 5,
            content:
              'Ha... If I date that person,\nI will do this and that too~ Oh,\nit makes my heart flutter!',
          },
        ],
      },
      {
        which: 'SN',
        question: 'Me in love,\nwhen I look at the world?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              "'A mountain is a mountain.\nWater is water.’\nSee it as it is",
          },
          {
            type: 'N',
            score: 5,
            content:
              'Rather than reality,\nI spread my own world,\nmy imagination.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'During a date,\nI was hungry and found a restaurant.',
        answers: [
          {
            type: 'S',
            score: 2,
            content: "The review looks good!\nLet's go here!",
          },
          {
            type: 'N',
            score: 5,
            content: 'Oh, It smells a restaurant that looks perfect.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'What is my first reaction when I am angry?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Speak rationally about the reason for my anger.',
          },
          {
            type: 'F',
            score: 5,
            content: "I'm filled with anger and I'm crying.",
          },
        ],
      },
      {
        which: 'TF',
        question: 'The person I like is in a difficult situation.',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Propose and support realistic solutions.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Empathize with emotions and\nprovides psychological support.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'When I talk to someone I like,\nwhat do I do?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Ask questions and ask questions about him.',
          },
          {
            type: 'F',
            score: 5,
            content: 'React and empathize when he is talking to me.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Suddenly the mood Jeju Island!\nI want to go to Jeju Island!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Plan a detailed schedule for each day of the week',
          },
          {
            type: 'P',
            score: 5,
            content:
              'Flight ticket reservation complete!\nI think the plan is almost over.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Which one is closer to me?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I am well organized and find where my things are.',
          },
          {
            type: 'P',
            score: 5,
            content:
              "I think I know where my things are,\nbut when I look for them,\nI can't find them...",
          },
        ],
      },
      {
        which: 'JP',
        question: 'I try to confess to the person you like.',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Make a thorough plan and\naim for the right time to confess.',
          },
          {
            type: 'P',
            score: 5,
            content: 'Confess promptly based on the atmosphere.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `
                `,
        query: 'mayweed',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIEng/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: ``,
        query: 'mango',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIEng/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: ``,
        query: 'grace',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIEng/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: ``,
        query: 'phlox',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIEng/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: ``,
        query: 'marigold',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIEng/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `
                `,
        query: 'ranunculus',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIEng/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: ``,
        query: 'janaRose',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIEng/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: ``,
        query: 'pompon',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIEng/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: ``,
        query: 'charment',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIEng/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: ``,
        query: 'lisianthus',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIEng/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: ``,
        query: 'hyacinth',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIEng/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `
                `,
        query: 'sweet',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIEng/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: ``,
        query: 'statice',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIEng/INTJ.png',
      },
      {
        type: 'INTP',
        desc: ``,
        query: 'sunflower',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIEng/INTP.png',
      },
      {
        type: 'INFJ',
        desc: ``,
        query: 'mini',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIEng/INFJ.png',
      },
      {
        type: 'INFP',
        desc: ``,
        query: 'freesia',
        img_src:
          'https://images.ktestone.com/resultImages/flowerBTIEng/INFP.png',
      },
    ],
  },
  // flowerBTI
  {
    info: {
      mainTitle: '플라워BTI(FlowerBTI)',
      subTitle: '나의 연애 성격은 무슨 꽃일까?',
      mainUrl: 'flowerBTI',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/flowerBTI-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/flowerBTI-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          '새로운 모임에 들어가게 되었는데\n내 이상형을 발견했을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '일단 연인이 있는지 물어보고\n없다면 적극적으로 연락을 시도한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '그 사람 주위에만 맴돌며 기회를 노린다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '이상형의 그분과 단 둘이\n만나게 되었을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '어떤 스타일을 좋아하는지\n그 사람에게 궁금한 것들을 물어본다.',
          },
          {
            type: 'I',
            score: 5,
            content: '그 사람이 어떤 말을 하는지 경청하고 반응한다.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '그 분과 연인이 되었다!\n단 둘이 손을 잡으며 걷다가 연인의 친구를 만났을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '자연스럽게 웃으면서 대화를 같이 이어간다.',
          },
          {
            type: 'I',
            score: 5,
            content: '간단한 인사를 마지막으로 조용히 옆에 있는다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '자기 전 문득 든 생각…\n이 사람과 평생 함께 할 수 있을까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '현실적으로 불가능할 것 같다.',
          },
          {
            type: 'N',
            score: 5,
            content:
              '그래! 이 사람과 평생 함께 하면\n어떨지 머릿속으로 상상해본다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '연인이 친구들과 노는데\n연락이 안 됐을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '재밌게 놀고 있어 연락이 안 되나?\n하고 내 할 일을 한다.',
          },
          {
            type: 'N',
            score: 5,
            content: '왜 갑자기 연락이 안 되는 거지…?\n하며 불안해진다.',
          },
        ],
      },
      {
        which: 'SN',
        question:
          '연인과 판타지 영화를 보고 나서\n대화할 때 내 모습과 가까운 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '재밌긴 했는데 현실적으로 마법사가 있을 수는 없지',
          },
          {
            type: 'N',
            score: 5,
            content:
              '만약 너가 마법사가 된다면 어떤 마법사야?\n영웅이야? 악당이야?',
          },
        ],
      },
      {
        which: 'TF',
        question: '연인이 나의 남/여사친을 신경 쓰인다고 했을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '신경 쓰이긴 하겠지만 내 인간관계인 것을…\n그냥 친구인데',
          },
          {
            type: 'F',
            score: 5,
            content:
              '하긴… 입장 바꿔서 생각해보면 서운할 것 같다…\n좀 적당히 해야겠다ㅜ',
          },
        ],
      },
      {
        which: 'TF',
        question: '연인과 다퉜을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '이런 점은 고쳐줬으면 좋겠어!\n하며 차분하게 말하려고 노력한다.',
          },
          {
            type: 'F',
            score: 5,
            content:
              '나 진짜 이런 거 너무 서운해…\n하면서 눈물이 나올 것 같다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '둘 중 한 명만 만나야 한다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '공감 능력이 없는 연인',
          },
          {
            type: 'F',
            score: 5,
            content: '서운하다고 했는데 고칠 생각이 없이 사과만 하는 연인',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 데이트하기 전날 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '자기 전 내일 무엇을 입을지\n머리부터 발끝까지 생각하고 잔다.',
          },
          {
            type: 'P',
            score: 5,
            content: '나가기 직전 마음에 드는 옷을 입는다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인이 집에 놀러 온다고 했을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '한 김에 쓰레기도 버리고\n화장실 청소까지 말끔하게 다 한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '보이는 곳만 급하게 치우고\n안 보이는 곳에 넣어둔다!',
          },
        ],
      },
      {
        which: 'JP',
        question: '같이 커플 여행 계획을 짤 때\n원하는 연인의 스타일은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '일정을 세세하게 일별로 같이 정리하는 연인',
          },
          {
            type: 'P',
            score: 5,
            content:
              '비행기 표만 일단 끊고\n그날의 기분에 따라 자유롭게 여행하는 연인',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `
                `,
        query: 'mayweed',
        img_src: 'https://images.ktestone.com/resultImages/flowerBTI/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: ``,
        query: 'mango',
        img_src: 'https://images.ktestone.com/resultImages/flowerBTI/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: ``,
        query: 'grace',
        img_src: 'https://images.ktestone.com/resultImages/flowerBTI/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: ``,
        query: 'phlox',
        img_src: 'https://images.ktestone.com/resultImages/flowerBTI/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: ``,
        query: 'marigold',
        img_src: 'https://images.ktestone.com/resultImages/flowerBTI/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `
                `,
        query: 'ranunculus',
        img_src: 'https://images.ktestone.com/resultImages/flowerBTI/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: ``,
        query: 'janaRose',
        img_src: 'https://images.ktestone.com/resultImages/flowerBTI/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: ``,
        query: 'pompon',
        img_src: 'https://images.ktestone.com/resultImages/flowerBTI/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: ``,
        query: 'charment',
        img_src: 'https://images.ktestone.com/resultImages/flowerBTI/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: ``,
        query: 'lisianthus',
        img_src: 'https://images.ktestone.com/resultImages/flowerBTI/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: ``,
        query: 'hyacinth',
        img_src: 'https://images.ktestone.com/resultImages/flowerBTI/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `
                `,
        query: 'sweet',
        img_src: 'https://images.ktestone.com/resultImages/flowerBTI/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: ``,
        query: 'statice',
        img_src: 'https://images.ktestone.com/resultImages/flowerBTI/INTJ.png',
      },
      {
        type: 'INTP',
        desc: ``,
        query: 'sunflower',
        img_src: 'https://images.ktestone.com/resultImages/flowerBTI/INTP.png',
      },
      {
        type: 'INFJ',
        desc: ``,
        query: 'mini',
        img_src: 'https://images.ktestone.com/resultImages/flowerBTI/INFJ.png',
      },
      {
        type: 'INFP',
        desc: ``,
        query: 'freesia',
        img_src: 'https://images.ktestone.com/resultImages/flowerBTI/INFP.png',
      },
    ],
  },
  // travelBTI
  {
    info: {
      mainTitle: '여행BTI',
      subTitle: '내 성격의 여행스타일은 무엇일까?',
      mainUrl: 'travelBTI',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/travelBTI-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/travelBTI-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '여행의 감성을 느끼기 위해 게스트하우스를 예약했다.\n나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '다른 혼자 온 사람에게 말을 먼저 걸어본다.',
          },
          {
            type: 'I',
            score: 5,
            content: '그냥 먼저 인사할 때까지 가만히 있는다.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '게스트하우스 내에서 다들 친해졌다.\n모두가 모여서 대화를 하고 있을 때,\n나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '저 사람 말하는 거 끝나면 나는 이 말 해야지~',
          },
          {
            type: 'I',
            score: 5,
            content: '들어가기는 아쉽고 말하기는 싫으니 듣고만 있는다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '여행을 갈 때 선호하는 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '혼자 가는 여행은 외로워!\n가족이나 친구들과 함께 가야지.',
          },
          {
            type: 'I',
            score: 5,
            content: '난 혼자 가는 여행도 나름 재밌어!',
          },
        ],
      },
      {
        which: 'SN',
        question: '여행 가기 전날 나와 비슷한 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '숙소 예약 확인 후 내일 여행에\n지장이 없게 바로 잠든다.',
          },
          {
            type: 'N',
            score: 5,
            content:
              '숙소 예약 확인 후 설렘과 걱정에\n이것저것 생각하다 시간을 보고\n서둘러 잠든다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '여행 중 가야 할 곳이 있는데\n길을 잘못 들었다. ',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '다시 내비가 알려주는 정확한 경로에\n들어서야 마음이 편하다.',
          },
          {
            type: 'N',
            score: 5,
            content: '이 또한 추억이지 허헣\n어? 저기서 사진 찍고 갈까?',
          },
        ],
      },
      {
        which: 'SN',
        question: '숙소에 도착했을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '와 여기 숙소 너무 이쁘다.\n깔끔하고 좋네!',
          },
          {
            type: 'N',
            score: 5,
            content:
              '와 여기 숙소 대박이네!\n사장님 부자신가?\n뭐 하시길래 돈을 이렇게 많이 버셨을까?',
          },
        ],
      },
      {
        which: 'TF',
        question: '여행에 다녀온 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '사진 공유+ 정산 후 일상으로 복귀',
          },
          {
            type: 'F',
            score: 5,
            content:
              '여행 사진을 보며 엄청난 후유증으로\n다음 여행을 기다린다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '둘 중 더 서운한 것은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '내가 고른 여행지를 만족해하지 않는 것 같을 때.',
          },
          {
            type: 'F',
            score: 5,
            content:
              '기분이 좋아서 같이 간 친구에게\n리액션을 했는데 시큰둥할 때.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '여행지에서 어디갈 지 고르는데\n친구가 답변을 잘 안 한다.\n그때 원하는 사과 답변은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '미안ㅜㅜ 나 오늘 회사에서 중요한 일을 맡아서…\n집중이 안 됐다ㅜ 집에 가서 바로 찾아볼게…\n미안해 정말 !',
          },
          {
            type: 'F',
            score: 5,
            content:
              'ㅜㅜ 나때문에 마음 많이 상했지?\n나 오늘 회사에서 일이 있어서 집중을 못 했네…\n그래도 넌 열심히 찾아봤는데ㅜ\n미안해 퇴근하면 언넝 찾아볼게여ㅜ',
          },
        ],
      },
      {
        which: 'JP',
        question: '여행 계획을 짤 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '분 단위로 찾아 둬야\n나중에 못 가는 곳이 생겨도\n마음이 편하다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '대략 가고 싶은 곳만 정하고\n못 가면 그 때 그 상황에 찾아본다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '여행에 다녀왔을 때 나와 비슷한 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '몸이 힘들어도 오자마자 바로 짐 정리를 하고 쉰다.',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 내 몸이 힘든 게 우선이다.\n좀만 누워있자!',
          },
        ],
      },
      {
        which: 'JP',
        question: '여행 후 나의 사진 앨범은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '무엇이 베스트 컷인지 마음에 드는 것은\n하트를 누르고 아니면 삭제한다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '일단 나중에 시간여유가 생기면 해야지\n하면서 미뤄두다가 까먹는다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `
                `,
        query: 'USA',
        img_src: 'https://images.ktestone.com/resultImages/travelBTI/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: ``,
        query: 'Thailand',
        img_src: 'https://images.ktestone.com/resultImages/travelBTI/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: ``,
        query: 'Canada',
        img_src: 'https://images.ktestone.com/resultImages/travelBTI/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: ``,
        query: 'Australia',
        img_src: 'https://images.ktestone.com/resultImages/travelBTI/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: ``,
        query: 'Czech',
        img_src: 'https://images.ktestone.com/resultImages/travelBTI/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `
                `,
        query: 'Jeju',
        img_src: 'https://images.ktestone.com/resultImages/travelBTI/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: ``,
        query: 'Greece',
        img_src: 'https://images.ktestone.com/resultImages/travelBTI/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: ``,
        query: 'Busan',
        img_src: 'https://images.ktestone.com/resultImages/travelBTI/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: ``,
        query: 'London',
        img_src: 'https://images.ktestone.com/resultImages/travelBTI/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: ``,
        query: 'Turkey',
        img_src: 'https://images.ktestone.com/resultImages/travelBTI/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: ``,
        query: 'Italy',
        img_src: 'https://images.ktestone.com/resultImages/travelBTI/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `
                `,
        query: 'Hawaii',
        img_src: 'https://images.ktestone.com/resultImages/travelBTI/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: ``,
        query: 'Brazil',
        img_src: 'https://images.ktestone.com/resultImages/travelBTI/INTJ.png',
      },
      {
        type: 'INTP',
        desc: ``,
        query: 'France',
        img_src: 'https://images.ktestone.com/resultImages/travelBTI/INTP.png',
      },
      {
        type: 'INFJ',
        desc: ``,
        query: 'Japan',
        img_src: 'https://images.ktestone.com/resultImages/travelBTI/INFJ.png',
      },
      {
        type: 'INFP',
        desc: ``,
        query: 'Bali',
        img_src: 'https://images.ktestone.com/resultImages/travelBTI/INFP.png',
      },
    ],
  },
  // auditionBTI //
  {
    info: {
      mainTitle: '오디션BTI',
      subTitle: '내가 서바이벌 오디션에 출연한다면?',
      mainUrl: 'auditionBTI',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/auditionBTI-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/auditionBTI-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'etc',
    },
    questions: [
      {
        which: 'EI',
        question:
          '오디션장에 들어가니 앉아있는\n다른 참가자들이 보인다.\n그때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '먼저 다가가서 인사하고 말을 건다.',
          },
          {
            type: 'I',
            score: 5,
            content: '내 자리를 찾아가서 앉는다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '대기 중에 인터뷰 요청이 들어왔다!\n그때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '드디어 세상에 날 알릴 차례!\n즐거운 긴장감과 신난 마음으로 따라간다.',
          },
          {
            type: 'I',
            score: 5,
            content:
              '안 그래도 긴장되는데 뭐라고 말하지...?\n걱정과 근심을 가지고 따라간다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '다른 참가자들과 함께 대기하면서\n대화할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '화려한 리액션과 적극적인 공감 능력을\n선보이며 예능캐에 등극한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '고개를 끄덕이거나 상대의 얘기에 웃어주며 반응한다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '오디션 대기 중 음악이 나온다.\n음악을 들을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '멜로디가 좋으면 내 플레이리스트에 저장!\n가사보단 멜로디지!',
          },
          {
            type: 'N',
            score: 5,
            content:
              '멜로디와 가사가 좋으면 내 플레이리스트에 저장!\n가사를 들으며 노래 속 주인공이 되어간다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '오디션에서 미션이 주어졌다!\n내가 원하는 미션은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '해야 할 미션이 구체적으로 나와 있는 것!',
          },
          {
            type: 'N',
            score: 5,
            content: '큰 틀만 있고 내가 자유롭게 할 수 있는 미션!',
          },
        ],
      },
      {
        which: 'SN',
        question: '오디션 대기중 ‘나는 어떻게 될까?’라는\n생각이 들 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '혹시 1등을 하지 않을까 기대를 해본다.',
          },
          {
            type: 'N',
            score: 5,
            content: '이미 우주 대스타가 된 나를 상상 중이다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '오디션에서 혼자 있는 참가자를 본 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '혼자 생각 정리 하는 거겠지.\n내버려두자!',
          },
          {
            type: 'F',
            score: 5,
            content: '혼자 심심하겠다...\n내가 가서 말이라도 걸어볼까?',
          },
        ],
      },
      {
        which: 'TF',
        question: '오디션에 떨어졌다고 생각할 때\n더 두려운 것은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '내가 해내지 못했다는 사실.',
          },
          {
            type: 'F',
            score: 5,
            content: '내가 떨어졌을 때 다른 사람들이\n날 보는 시선.',
          },
        ],
      },
      {
        which: 'TF',
        question: '너무 긴장된다는 옆 참가자의 말에 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '오디션이니까 떨리는 게 당연해!',
          },
          {
            type: 'F',
            score: 5,
            content: '그러게, 나도 너무 긴장된다!\n힘내자... 휴... 떨려!',
          },
        ],
      },
      {
        which: 'JP',
        question: '팀 미션시 아이디어를 낼 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '전체적인 틀에 맞추어 아이디어를 제시한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '그 자리에서 즉흥적으로 떠오른 아이디어를 제시한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '나만의 무대를 꾸며야 할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '머릿속에 나만의 무대를 구체적으로\n계획하고 이미지를 그려본다.',
          },
          {
            type: 'P',
            score: 5,
            content: '당장 어떤 것부터 꾸미면 좋을지 영상을 찾아본다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '오디션 연습을 할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '평소 내가 제일 잘하는 것들로\n계획을 세운 후 잘하는 것을 더욱 연습한다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '연습 시작과 동시에 부족한 것을\n수정해 나가며 계속 보완한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `그린애플
                `,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTI/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `골드 샌드`,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTI/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `뉴욕핑크`,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTI/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `비터스윗`,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTI/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `체스넛 로즈`,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTI/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `히피핑크
                `,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTI/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `그린 헤이즈`,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTI/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `선셋 오렌지`,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTI/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `서프`,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTI/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `콜드 퍼플`,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTI/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `스카이 블루`,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTI/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `바얼리 화이트
                `,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTI/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `슬레이트 블루`,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTI/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `무디블루`,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTI/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `말리부`,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTI/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `오션그린`,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTI/INFP.png',
      },
    ],
  },
  // auditionBTIEng
  {
    info: {
      mainTitle: 'AuditionBTI',
      subTitle: 'What if I appeared on a survival audition program?',
      mainUrl: 'auditionBTIEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/auditionBTIEng-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/auditionBTIEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Eng',
      category: 'etc',
    },
    questions: [
      {
        which: 'EI',
        question:
          'I enter the audition hall and\nsee other contestants sitting in their seats.\nWhat would I do?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Approach first, say hello,\nand start a conversation.',
          },
          {
            type: 'I',
            score: 5,
            content: 'Find and take my seat.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'I got an interview request while waiting!\nWhat would I do?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              "It's finally time to let myself known to the world!\nI follow the interviewer with a good dose of nervousness and excitement.",
          },
          {
            type: 'I',
            score: 5,
            content:
              "I'm already nervous as it is! What do I say...?\nI follow the interviewer with worry and concern.",
          },
        ],
      },
      {
        which: 'EI',
        question: 'How am I when I talk while\nwaiting with other contestants?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'Become the entertainer of the group with\nsplendid reactions and active empathy.',
          },
          {
            type: 'I',
            score: 5,
            content:
              'Respond by nodding or smiling at\nwhat the other person is saying.',
          },
        ],
      },
      {
        which: 'SN',
        question:
          'Music comes out while waiting for the audition.\nWhat do I do when I listen to music?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'I save it to my playlist if\nI like the melody! Melody over lyrics!',
          },
          {
            type: 'N',
            score: 5,
            content:
              "I save it to my playlist if\nI like the melody and the lyrics! I become the main character of the song's story while listening to the lyrics.",
          },
        ],
      },
      {
        which: 'SN',
        question:
          "I'm given a mission during the audition!\nWhat mission do I want?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'The mission that clearly\nstates what I need to do!',
          },
          {
            type: 'N',
            score: 5,
            content:
              'The mission that only suggests an overall picture\nand lets me freely achieve the goal!',
          },
        ],
      },
      {
        which: 'SN',
        question:
          "While waiting for an audition, I start to wonder,\n'What will I become?' - What am I thinking?",
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I hope to maybe win 1st place.',
          },
          {
            type: 'N',
            score: 5,
            content: 'I am already imagining myself becoming a worldwide star.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What do I do when I see a contestant\nalone at the audition?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              "She must be gathering her own thoughts.\nI'll let her be!",
          },
          {
            type: 'F',
            score: 5,
            content:
              'She should be bored by herself...\nShall I go and chat with her?',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What scares me more when\nI think about failing the audition?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: "The fact that I didn't make it",
          },
          {
            type: 'F',
            score: 5,
            content: 'How other people would\nthink of me when I fail',
          },
        ],
      },
      {
        which: 'TF',
        question:
          "The contestant next to me says\nhe's too nervous – what do I say?",
        answers: [
          {
            type: 'T',
            score: 2,
            content: "It's an audition,\nso it's normal to be nervous!",
          },
          {
            type: 'F',
            score: 5,
            content:
              "I know, I'm so nervous, too!\nLet's do our best... Phew... I'm shaking!",
          },
        ],
      },
      {
        which: 'JP',
        question: 'How do I come up with\nan idea for a team mission?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Present ideas following\nthe overall guideline.',
          },
          {
            type: 'P',
            score: 5,
            content: 'Present ideas that come to\nmy mind at the time.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'How do I set up my own stage?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Plan my own stage in detail\nand imagine in my head.',
          },
          {
            type: 'P',
            score: 5,
            content: 'Find a video to see\nwhat to start first.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'How am I when I practice for the audition?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              "I make a plan with the things\nI'm usually good at and then practice more on those.",
          },
          {
            type: 'P',
            score: 5,
            content:
              'I start off the practice and\nkeep correcting the things that I lack.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `Green Apple
                `,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIEng/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `Gold Sand`,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIEng/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `Newyork Pink`,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIEng/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `Bitter Sweet`,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIEng/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `Chestnut Rose`,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIEng/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `Hippie Pink
                `,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIEng/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `Green Haze`,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIEng/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `Sunset Orange`,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIEng/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `Surf`,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIEng/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `Cold Purple`,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIEng/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `Sky Blue`,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIEng/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `Barley White
                `,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIEng/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `Slate Blue`,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIEng/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `Moody Blue`,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIEng/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `Malibu`,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIEng/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `Ocean Green`,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIEng/INFP.png',
      },
    ],
  },
  // auditionBTIJp
  {
    info: {
      mainTitle: 'オーディションBTI',
      subTitle: '私がサバイバルオーディションに 出演したら?',
      mainUrl: 'auditionBTIJp',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/auditionBTIJp-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/auditionBTIJp-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'JP',
      category: 'etc',
    },
    questions: [
      {
        which: 'EI',
        question:
          'オーディション会場に入ると、\n他の参加者たちが見える。\nその時、私はどうする？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '先にあいさつをして話かける。',
          },
          {
            type: 'I',
            score: 5,
            content: '何も言わず自分の席に座る。',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '待機中にインタービューの要請が入った！\nその時、私はどうする？',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'やっと世の中に自分の存在を知らせる時がきた！\nときめく緊張感と楽しい気持ちでついて行く。',
          },
          {
            type: 'I',
            score: 5,
            content:
              'ただでさえ緊張するのに何ていうのかな？\n心配しながらついて行く。',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '待機室で他の参加者たちと\nお話しをしている時の私はどうする？',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '面白いアクションと共通点を探すために積極的にお話しをする。\n芸能キャラを担当することになる。',
          },
          {
            type: 'I',
            score: 5,
            content: 'うなずいたり、\n相手の話に笑って反応する。',
          },
        ],
      },
      {
        which: 'SN',
        question:
          'オーディションの順番待ちの時に音楽が流れる。\nその時、私はどうする？',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'メロディーがよければ、\n私のプレイリストに共有！\n歌詞よりは断然メロディー！',
          },
          {
            type: 'N',
            score: 5,
            content:
              'メロディーと歌詞がよければ、\n私のプレイリストに共有！\n歌詞を聞きながら、歌の主人公に成りきる。',
          },
        ],
      },
      {
        which: 'SN',
        question:
          'オーディションでミッションが与えられた！\n自ら望むミッションはどっち？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'ミッションの内容が具体的に提案してるもの！',
          },
          {
            type: 'N',
            score: 5,
            content:
              '大きな枠だけがあって、\n後は自分らで自由に作り上げるミッション！',
          },
        ],
      },
      {
        which: 'SN',
        question:
          'オーディションの待機中に\n「私はどうなるんだろう」と\n心配をしている時の私は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'もしかしたら、\n優勝をするんじゃないかと期待をしてみる。',
          },
          {
            type: 'N',
            score: 5,
            content: '既に宇宙大スターになった\n自分を想像してしまう。',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'オーディションでひとりぼっちになった\n参加者を見た私はどうする？',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'やっぱひとりで考えをまとめるのが一番！\nほったらかしておこう。',
          },
          {
            type: 'F',
            score: 5,
            content: 'ひどりじゃ退屈そうに見えるから、\n声でもかけてみようか。',
          },
        ],
      },
      {
        which: 'TF',
        question: 'オーディションに落ちた時に\nもっとも怖いのは？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '自分が失敗したという事実',
          },
          {
            type: 'F',
            score: 5,
            content: '失敗したときに皆からの視線。',
          },
        ],
      },
      {
        which: 'TF',
        question: '他の参加者に「超緊張している」と言われたら？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'オーディションなんで\n緊張するのは当然だよ！',
          },
          {
            type: 'F',
            score: 5,
            content: 'そうだね。私も緊張してるよ！\n頑張ろう。ふぅ、緊張する！',
          },
        ],
      },
      {
        which: 'JP',
        question: 'チームミッションの時に\nどういう風にアイディアをいうの？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '全体的な枠に合わせて、\nアイディアを提案する。',
          },
          {
            type: 'P',
            score: 5,
            content: 'その場で浮かんだアイディアを即興的提案する。',
          },
        ],
      },
      {
        which: 'JP',
        question: '自分だけの舞台作りをするときに私はどうする？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '頭の中から、計画して自分だけの\n舞台イメージを具体的にかいてみる。',
          },
          {
            type: 'P',
            score: 5,
            content:
              '今すぐ、どんなことからすればいいか\nムービーまたは動画から探してみる。',
          },
        ],
      },
      {
        which: 'JP',
        question: 'オーディションの練習の時の私はどっち？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '普段、自分が一番上手なことを中心に計画を立てて、\nそれに集中してもっと連取をする。',
          },
          {
            type: 'P',
            score: 5,
            content:
              '練習をすると同時に足りないところを直しながら、\n補っていく。',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `Green Apple
                `,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `Gold Sand`,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `Newyork Pink`,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `Bitter Sweet`,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `Chestnut Rose`,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `Hippie Pink
                `,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `Green Haze`,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `Sunset Orange`,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `Surf`,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `Cold Purple`,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `Sky Blue`,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `Barley White
                `,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `Slate Blue`,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `Moody Blue`,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `Malibu`,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `Ocean Green`,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/INFP.png',
      },
    ],
  },
  // auditionBTICn
  {
    info: {
      mainTitle: '选秀BTI',
      subTitle: '如果我参加生存选秀的话？?',
      mainUrl: 'auditionBTICn',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/auditionBTICn-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/auditionBTICn-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'CN',
      category: 'etc',
    },
    questions: [
      {
        which: 'EI',
        question: '走进选秀现场后看到坐在里面的其他参赛者，这时的我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '主动上前打招呼。',
          },
          {
            type: 'I',
            score: 5,
            content: '找到自己的座位坐下。',
          },
        ],
      },
      {
        which: 'EI',
        question: '后台等候的时候突然接到采访邀请！这时的我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '想着终于轮到我啦！带着享受其中的紧张感和兴奋的心情去参加采访。',
          },
          {
            type: 'I',
            score: 5,
            content:
              '本来就已经很紧张了，想着到底该说什么呢…? 满怀担忧和顾虑去参加采访。',
          },
        ],
      },
      {
        which: 'EI',
        question: '和其他参赛者一起在后台等候的时候聊天的我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '反应比较夸张而且能积极地展现共情能力，是个综艺咖。',
          },
          {
            type: 'I',
            score: 5,
            content: '点点头或者在对方说话的时候笑一笑。',
          },
        ],
      },
      {
        which: 'SN',
        question: '选秀待机的时候突然音乐响起。听到音乐的我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '觉得旋律不错的话就会添加到我的播放列表！比起歌词更注重旋律！',
          },
          {
            type: 'N',
            score: 5,
            content:
              '觉得旋律和歌词都不错的话会收藏到我的播放列表！听着歌词会想象自己是歌曲中的主人公。',
          },
        ],
      },
      {
        which: 'SN',
        question: '选秀中突然接到任务！我想拿到的任务是？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '每一个步骤都有详细列出的任务！',
          },
          {
            type: 'N',
            score: 5,
            content: '只有一个大框架，需要自由发挥的任务',
          },
        ],
      },
      {
        which: 'SN',
        question: '后台等候时想象‘未来的我是什么样？’的我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '期待自己说不定能获得第1名。',
          },
          {
            type: 'N',
            score: 5,
            content: '想象自己已经成为宇宙大明星了。',
          },
        ],
      },
      {
        which: 'TF',
        question: '选秀中看到独处的参赛者时我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '应该是一个人在整理思绪。就别打扰人家了！',
          },
          {
            type: 'F',
            score: 5,
            content: '一个人肯定会无聊…我去跟他聊聊天？',
          },
        ],
      },
      {
        which: 'TF',
        question: '一想到自己落选更害怕的是？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '我未能完成使命的事实',
          },
          {
            type: 'F',
            score: 5,
            content: '落选后他人看待我的眼神',
          },
        ],
      },
      {
        which: 'TF',
        question: '看到身边紧张的参赛者时我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '选秀中紧张是理所应当的！',
          },
          {
            type: 'F',
            score: 5,
            content: '是诶，我也超紧张的！一起加油吧…呼…好紧张！',
          },
        ],
      },
      {
        which: 'JP',
        question: '团队任务中出点子时的我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '按照整个任务的大方向思考策略。',
          },
          {
            type: 'P',
            score: 5,
            content: '当场提出脑海里浮现的想法。',
          },
        ],
      },
      {
        which: 'JP',
        question: '打造专属自己的舞台时我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '现在头脑里策划专属自己的舞台，描绘出场景。',
          },
          {
            type: 'P',
            score: 5,
            content: '先找相关视频资料，然后决定该首先做什么。',
          },
        ],
      },
      {
        which: 'JP',
        question: '排练的过程中我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '根据平时自己擅长的制定计划后集中深入练习。',
          },
          {
            type: 'P',
            score: 5,
            content: '排练开始的同时会一边修改不足之处，持续完善。',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `Green Apple
                `,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `Gold Sand`,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `Newyork Pink`,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `Bitter Sweet`,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `Chestnut Rose`,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `Hippie Pink
                `,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `Green Haze`,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `Sunset Orange`,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `Surf`,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `Cold Purple`,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `Sky Blue`,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `Barley White
                `,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `Slate Blue`,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `Moody Blue`,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `Malibu`,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `Ocean Green`,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/auditionBTIJp/INFP.png',
      },
    ],
  },
  // percentageMBTI2022JP
  {
    info: {
      mainTitle: 'percentageMBTIJP',
      subTitle: 'percentageMBTIJP',
      mainUrl: 'percentageMBTI2022JP',
      scoreType: 'percentageMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/percentageMBTI2022JP-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/percentageMBTI2022JP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '他の人たちに見られた\n自分と最も近いのはどれ？',
        answers: [
          {
            type: 'E',
            content: '私と一緒にいる時に心が開かれるようだ。',
          },
          {
            type: 'I',
            content: 'うちきで自分の考えや\n気持ちが分かりにくいという。 ',
          },
        ],
      },
      {
        which: 'EI',
        question: '他人と一緒にいるときの自分は？',
        answers: [
          {
            type: 'E',
            content:
              'ひとりも悪くないけど、\n他の人と一緒にいるときにエネルギーをもらえる。',
          },
          {
            type: 'I',
            content: '一緒にいてもいいけど、\n一人の方がエネルギを得る方。',
          },
        ],
      },
      {
        which: 'EI',
        question: '人々との集まりの後の自分は？',
        answers: [
          {
            type: 'E',
            content:
              '一緒にいるのも楽しくて幸せだが、\n疲れ果たしてエネルギー放電。',
          },
          {
            type: 'I',
            content:
              '今日、一日つとても疲れたけど、\n共にできて楽しくて幸せ。\n逆にエネルギーをもらえる。',
          },
        ],
      },
      {
        which: 'EI',
        question: 'もっとストレスを感じる状況とは？',
        answers: [
          {
            type: 'E',
            content: '質問をしたけど、\n早く返答がないとストレスを感じる。',
          },
          {
            type: 'I',
            content:
              '誰かに質問されたときに返事をする\n時間を与えない時ストレスを感じる。',
          },
        ],
      },
      {
        which: 'EI',
        question: '新しいことを始める時の自分とは？',
        answers: [
          {
            type: 'E',
            content:
              'ともかくなんでも\n始めればグッドアイディアが\n浮かぶのでまず行動にでる。',
          },
          {
            type: 'I',
            content:
              '何事にも始まる前に十分な準備時間が\n必要なので深く考える。',
          },
        ],
      },
      {
        which: 'SN',
        question: 'もっとも望ましいと思うのはどれ？',
        answers: [
          {
            type: 'S',
            content: '確実で信頼できる事実であること。',
          },
          {
            type: 'N',
            content: '事実ではないけど、\n実験的だで未来的な考えであること。',
          },
        ],
      },
      {
        which: 'SN',
        question: '自分に最も近いのはどれ？',
        answers: [
          {
            type: 'S',
            content: '事実主義であり、\n行動が先にでるタイプ',
          },
          {
            type: 'N',
            content: '非現実的なことを考える。\n考えが次から次へと出てくる ',
          },
        ],
      },
      {
        which: 'SN',
        question: '仕事(課題)をするとに最も私に近いのはどれ？',
        answers: [
          {
            type: 'S',
            content:
              '一度に一つの作業を終わらせてから、\n次の業務に進む方がよい',
          },
          {
            type: 'N',
            content:
              'ひとつの事だけに没頭するのは退屈すぎるので、\nいわゆる業務を経験するのを好む',
          },
        ],
      },
      {
        which: 'SN',
        question:
          '他の人たちと会議をするこきに\n新しいアイディが浮かんだ時の私は？',
        answers: [
          {
            type: 'S',
            content: '誰かが提示したアイディアが\n現実可能なのか考えてみる。',
          },
          {
            type: 'N',
            content:
              '誰かが独創的なアイディアを提示すると\n想像と同時に新しいアイディアが浮かぶ。',
          },
        ],
      },
      {
        which: 'SN',
        question: '自分に近いのは２つのうちにどれ？',
        answers: [
          {
            type: 'S',
            content: '自分に与えられた既存のものをうまくやり遂げる。',
          },
          {
            type: 'N',
            content: '新しいことに挑戦するのが得意で怖いものなし。',
          },
        ],
      },
      {
        which: 'TF',
        question: '対話の時に気になると思うことは？',
        answers: [
          {
            type: 'T',
            content: '話しの主題から離れたり間違えた事実を話すとき',
          },
          {
            type: 'F',
            content: '他人の感情を無視するような発言をするとき',
          },
        ],
      },
      {
        which: 'TF',
        question: '何かを決定するときに最も大事だと思うところは？',
        answers: [
          {
            type: 'T',
            content: '相手が嫌がっても正しいと思ったら、\n決定を下す。',
          },
          {
            type: 'F',
            content: '自分の価値観と感情がよければ決める。',
          },
        ],
      },
      {
        which: 'TF',
        question: '「善意のウソ」について自分の\n考えともっとも近いのはどれ？',
        answers: [
          {
            type: 'T',
            content:
              'それでも、ウソは悪い事なので、\n「善意のウソ」という言葉は 矛盾だ。',
          },
          {
            type: 'F',
            content:
              '「善意の嘘」が度重なるのはよくないけど、\nなぜそうしたのか理由が分かれば理解できる。',
          },
        ],
      },
      {
        which: 'TF',
        question: '最も自分に近いのはどれ？',
        answers: [
          {
            type: 'T',
            content: '努力の過程よりは結果の方が大事',
          },
          {
            type: 'F',
            content: '結果よりは努力する過程の方が大事だ。',
          },
        ],
      },
      {
        which: 'TF',
        question: '誰かを説得するときの私は？',
        answers: [
          {
            type: 'T',
            content: '相手の意見よりもっと合理的で、\n論理的方案を提示する。',
          },
          {
            type: 'F',
            content:
              '相手の感情に共感しながら \n話しを通して説得しようと努力する。',
          },
        ],
      },
      {
        which: 'JP',
        question: '「計画」について自分の考えと最も近いのはどれ？',
        answers: [
          {
            type: 'J',
            content:
              'いかにも準備されているという\n安定感のためあらかじめ計画して\nおくと心が落ち着く。',
          },
          {
            type: 'P',
            content: '自分がやりたいことを\n自由にできるときに気が楽になる。',
          },
        ],
      },
      {
        which: 'JP',
        question: '新しい状況に接した時の自分は？',
        answers: [
          {
            type: 'J',
            content:
              'どんな状況であるか完璧に把握するまで \n見守りながら考えを整理する。',
          },
          {
            type: 'P',
            content:
              'まず状況に飛び込む。\n他の人々と考えを共有しながら問題を解決しようとする。',
          },
        ],
      },
      {
        which: 'JP',
        question: '何かを決める時の私は？',
        answers: [
          {
            type: 'J',
            content:
              '早く解決するためには正しくない\n決定より未決定の方がよくないと思う',
          },
          {
            type: 'P',
            content:
              'よりよい決定をするために少し時間を\nおいて情報収集を行い最後まで深く考える',
          },
        ],
      },
      {
        which: 'JP',
        question: '自分に最も近い業務処理タイプとは？',
        answers: [
          {
            type: 'J',
            content: '前もって計画を立てて余裕を持って終わらせる',
          },
          {
            type: 'P',
            content: '最後の瞬間に集中して終わらせる',
          },
        ],
      },
      {
        which: 'JP',
        question: '旅行に行った時に自分に一番近いのはどれ？',
        answers: [
          {
            type: 'J',
            content: '事前計画がダメになると不安だけどプランBを提案する。',
          },
          {
            type: 'P',
            content: 'その日の気分・天気によって流動的に計画を立てる',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'gladeGreen',
        color: `#587A4B`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022JP/ESTJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'tangerine',
        color: `#FF977B`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022JP/ESTP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'cosmos',
        color: `#FFCFCF`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022JP/ESFJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'froly',
        color: `#E0707E`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022JP/ESFP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'mariner',
        color: `#3462A3`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022JP/ENTJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'valencia',
        color: `#DD5843`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022JP/ENTP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'aquamarine',
        color: `#52CEB0`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022JP/ENFJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'sunshade',
        color: `#FFA348`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022JP/ENFP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'gainsboro',
        color: `#DDDDDD`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022JP/ISTJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'bostonBlue',
        color: `#4690B4`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022JP/ISTP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'grandis',
        color: `#FFCC71`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022JP/ISFJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'olivine',
        color: `#A6BF6F`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022JP/ISFP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'bilobaFlower',
        color: `#BAB0FF`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022JP/INTJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'mayaBlue',
        color: `#7FC2F4`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022JP/INTP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'hawkesBlue',
        color: `#C0D8FF`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022JP/INFJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'genoa',
        color: `#277A64`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022JP/INFP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/INFP.png',
      },
    ],
  },
  // percentageMBTI2022Cn
  {
    info: {
      mainTitle: 'percentageMBTICn',
      subTitle: 'percentageMBTICn',
      mainUrl: 'percentageMBTI2022Cn',
      scoreType: 'percentageMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/percentageMBTI2022Cn-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/percentageMBTI2022Cn-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'CN',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '他人看待我的时候，\n更接近的是？',
        answers: [
          {
            type: 'E',
            content: '和我在一起时心里会很舒服。',
          },
          {
            type: 'I',
            content: '属于内向型，\n很难了解我的想法和心思。',
          },
        ],
      },
      {
        which: 'EI',
        question: '和他人在一起时的我？',
        answers: [
          {
            type: 'E',
            content:
              '虽然也不是不喜欢独处，\n但和其他人在一起的话反而会获得能量。',
          },
          {
            type: 'I',
            content: '虽然在一起也很好，\n但一个人的时候才会获得能量。',
          },
        ],
      },
      {
        which: 'EI',
        question: '和他人聚会(见面)之后的我？',
        answers: [
          {
            type: 'E',
            content:
              '虽然今天一整天都很累，\n但和大家在一起反而感到很开心幸福，\n获得活力。',
          },
          {
            type: 'I',
            content:
              '虽然大家在一起会很开心幸福，\n但后来会感到很疲惫，\n活力都丧失了一样。',
          },
        ],
      },
      {
        which: 'EI',
        question: '以下哪种情况会让我感到有压力？',
        answers: [
          {
            type: 'E',
            content: '提问之后不能马上听到对\n方答复的时候会感到有压力。',
          },
          {
            type: 'I',
            content:
              '他人提问的时候需要时间来思考却\n不给我时间的时候会感到有压力。',
          },
        ],
      },
      {
        which: 'EI',
        question: '刚开始一件对于自己而言新颖的事情时？',
        answers: [
          {
            type: 'E',
            content:
              '觉得一旦开始了就会有新的想法，\n所以不管三七二十一先行动起来。',
          },
          {
            type: 'I',
            content: '开始之前需要充分的时间做准备，\n认为需要深思熟虑。',
          },
        ],
      },
      {
        which: 'SN',
        question: '我更想得到的是？',
        answers: [
          {
            type: 'S',
            content: '准确又值得信任的事实。',
          },
          {
            type: 'N',
            content: '即使不是事实也会认为具有挑战性，\n值得期待。',
          },
        ],
      },
      {
        which: 'SN',
        question: '以下更接近我的是？',
        answers: [
          {
            type: 'S',
            content: '先开始行动的现实主义者。',
          },
          {
            type: 'N',
            content: '会思考很多不现实的事情，\n思绪一个接着一个。',
          },
        ],
      },
      {
        which: 'SN',
        question: '工作(学业)中的我更倾向于？',
        answers: [
          {
            type: 'S',
            content: '更倾向于完全结束一项\n工作后再开始其它事情。',
          },
          {
            type: 'N',
            content:
              '仅专注于单一的事情上会感到很烦闷，\n所以更倾向于同时进行多项工作。',
          },
        ],
      },
      {
        which: 'SN',
        question: '和其他人一起开会时思考新点子时的我会？',
        answers: [
          {
            type: 'S',
            content: '其中一个人提出想法时会思考是否可行。',
          },
          {
            type: 'N',
            content:
              '其中一个人提出一个创新的\n主意时会激发自己的想象力，\n从而思考出新点子。',
          },
        ],
      },
      {
        which: 'SN',
        question: '以下更接近我的是？',
        answers: [
          {
            type: 'S',
            content: '我只擅长于做自己负责的事情。',
          },
          {
            type: 'N',
            content: '我喜欢挑战，\n途中毫不惧怕。',
          },
        ],
      },
      {
        which: 'TF',
        question: '和他人对话时，\n觉得心里不舒服的是？',
        answers: [
          {
            type: 'T',
            content: '对话脱离主题或包含与\n事实不相符的内容时',
          },
          {
            type: 'F',
            content: '话语中包含无视他人情绪时',
          },
        ],
      },
      {
        which: 'TF',
        question: '做决定时我更重视的是？',
        answers: [
          {
            type: 'T',
            content: '即使对方不看好，\n但只要是我认为是正确的就会做决定。',
          },
          {
            type: 'F',
            content: '我认为符合自己的\n价值观和情绪的话就会做决定。',
          },
        ],
      },
      {
        which: 'TF',
        question: '我认为更接近’善意的谎言’的情形是？',
        answers: [
          {
            type: 'T',
            content: '无论如何说谎是不对的，\n善意的谎言只是一个借口而已。',
          },
          {
            type: 'F',
            content: '反复的善意谎言虽然不对，\n但了解其中的缘由就会理解。',
          },
        ],
      },
      {
        which: 'TF',
        question: '以下更接近我的是？',
        answers: [
          {
            type: 'T',
            content: '我认为结果比努力的过程更重要。',
          },
          {
            type: 'F',
            content: '我认为努力获得结果的过程更重要。',
          },
        ],
      },
      {
        which: 'TF',
        question: '在说服他人时的我会？',
        answers: [
          {
            type: 'T',
            content: '提出比对方想法更合理更有道理的方案。',
          },
          {
            type: 'F',
            content:
              '先对对方的情绪产生共鸣，\n同时通过对话的方式努力说服对方。',
          },
        ],
      },
      {
        which: 'JP',
        question: '对于 ‘计划’而言更接近我的想法的是？',
        answers: [
          {
            type: 'J',
            content: '为了自己舒服，\n必须提前做好一切准备工作内心才会安稳。',
          },
          {
            type: 'P',
            content: '能自由自在地做自己想做的\n事情时内心最舒服。',
          },
        ],
      },
      {
        which: 'JP',
        question: '新状况发生时的我会？',
        answers: [
          {
            type: 'J',
            content:
              '在彻底了解到底是什么\n情况之前会小心翼翼地观察并整理思绪。',
          },
          {
            type: 'P',
            content: '会先闯进现场并和周围的人们一起分享想法，\n尝试解决问题。',
          },
        ],
      },
      {
        which: 'JP',
        question: '在做决策面前我会？',
        answers: [
          {
            type: 'J',
            content: '为了快速做出决策，\n认为不做决策不如做错决策。',
          },
          {
            type: 'P',
            content:
              '为了做出更好的决策会先暂时停下脚步，\n先收集一些有用的信息，经深思熟虑后做最终决策。',
          },
        ],
      },
      {
        which: 'JP',
        question: '工作中更接近我的作风的是？',
        answers: [
          {
            type: 'J',
            content: '提前做好准备和计划，\n然后轻轻松松地完成。',
          },
          {
            type: 'P',
            content: '集中注意力坚持到最后。',
          },
        ],
      },
      {
        which: 'JP',
        question: '旅途中更接近我的是？',
        answers: [
          {
            type: 'J',
            content:
              '如果情况打乱了我提前做好的准备就会内心不安，\n但会拿出准备好的B方案。',
          },
          {
            type: 'P',
            content: '根据当天的心情和天气随机树立计划。',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'gladeGreen',
        color: `#587A4B`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Cn/ESTJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'tangerine',
        color: `#FF977B`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Cn/ESTP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'cosmos',
        color: `#FFCFCF`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Cn/ESFJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'froly',
        color: `#E0707E`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Cn/ESFP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'mariner',
        color: `#3462A3`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Cn/ENTJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'valencia',
        color: `#DD5843`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Cn/ENTP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'aquamarine',
        color: `#52CEB0`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Cn/ENFJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'sunshade',
        color: `#FFA348`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Cn/ENFP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'gainsboro',
        color: `#DDDDDD`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Cn/ISTJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'bostonBlue',
        color: `#4690B4`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Cn/ISTP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'grandis',
        color: `#FFCC71`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Cn/ISFJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'olivine',
        color: `#A6BF6F`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Cn/ISFP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'bilobaFlower',
        color: `#BAB0FF`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Cn/INTJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'mayaBlue',
        color: `#7FC2F4`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Cn/INTP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'hawkesBlue',
        color: `#C0D8FF`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Cn/INFJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'genoa',
        color: `#277A64`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Cn/INFP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/INFP.png',
      },
    ],
  },
  // percentageMBTI2022Eng
  {
    info: {
      mainTitle: 'percentageMBTIEng',
      subTitle: 'percentageMBTIEng',
      mainUrl: 'percentageMBTI2022Eng',
      scoreType: 'percentageMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/percentageMBTI2022Eng-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/percentageMBTI2022Eng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'When other people look at me,\nwhat do they think?',
        answers: [
          {
            type: 'E',
            content: 'They think they can let their hearts open with me.',
          },
          {
            type: 'I',
            content: 'They think I’m introverted and hard to read my thoughts.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'When with other people,\nhow am I?',
        answers: [
          {
            type: 'E',
            content:
              'Being alone isn’t bad,\nbut I gain energy when I’m with others.',
          },
          {
            type: 'I',
            content:
              'I like being with people,\nbut I gain energy when I’m by myself.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'After meeting with others,\nhow am I?',
        answers: [
          {
            type: 'E',
            content:
              'It was a tiring day,\nbut it was happy and fun to be together,\nand I gained energy.',
          },
          {
            type: 'I',
            content:
              'It was happy and fun to be together,\nbut I’m tired and low on energy.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Which situation is more stressful to me?',
        answers: [
          {
            type: 'E',
            content:
              'I get stressed when I ask something,\nand the person doesn’t reply back quickly.',
          },
          {
            type: 'I',
            content:
              'I get stressed when somebody asks me a question,\nand I don’t get enough time to think about the answer.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'When I start a new thing,\nwhat do I do?',
        answers: [
          {
            type: 'E',
            content:
              'I start to act\nbecause better things come to my mind\nonce I get started.',
          },
          {
            type: 'I',
            content:
              'I think carefully because\neverything needs enough preparation time\nbefore getting started.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do I want more?',
        answers: [
          {
            type: 'S',
            content: 'Accurate and trustworthy fact.',
          },
          {
            type: 'N',
            content:
              'Experimental and futuristic thoughts,\neven if they are not facts.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'What’s closer to me?',
        answers: [
          {
            type: 'S',
            content: 'Realistic and tries to get things done first.',
          },
          {
            type: 'N',
            content:
              'I think a lot of unrealistic things and have a long train of thought.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'What’s similar to me when I’m working\n(or doing homework)?',
        answers: [
          {
            type: 'S',
            content: 'I like finishing one thing and getting onto the next.',
          },
          {
            type: 'N',
            content:
              'It’s boring to concentrate on one thing,\nso I prefer doing various things.',
          },
        ],
      },
      {
        which: 'SN',
        question:
          'When in a meeting with others and coming up with new ideas,\nhow am I?',
        answers: [
          {
            type: 'S',
            content: 'I start to think if the person’s idea is realistic.',
          },
          {
            type: 'N',
            content:
              'When someone throws a creative idea,\nI start imagining and come up with new ideas.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'Which one is closer to me?',
        answers: [
          {
            type: 'S',
            content: 'I’m good at accomplishing existing things.',
          },
          {
            type: 'N',
            content: 'I’m good at challenging new things without fear.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'When talking with other people,\nwhat annoys me?',
        answers: [
          {
            type: 'T',
            content:
              'When someone says something that’s out of the topic or saying wrong things.',
          },
          {
            type: 'F',
            content:
              'When someone says something that disregards other people’s feelings.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'When making a decision,\nwhat do I value more?',
        answers: [
          {
            type: 'T',
            content:
              'Even if the others hate it,\nI make the decision if I think it’s right.',
          },
          {
            type: 'F',
            content:
              'I make the decision if I think my values and\nemotions are in the right place.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'What is closer to my opinion about ‘white lies’?',
        answers: [
          {
            type: 'T',
            content:
              'Lies are inherently bad,\nso the term ‘white lies’ is a contradiction.',
          },
          {
            type: 'F',
            content:
              'It’s not great to repeat white lies,\nbut I can understand it if I know the reason.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'Which is closer to me?',
        answers: [
          {
            type: 'T',
            content: 'To me,\nthe result is more important than the process.',
          },
          {
            type: 'F',
            content:
              'To me,\nthe process of trying hard is more important than the result.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'When trying to persuade someone,\nwhat do I do?',
        answers: [
          {
            type: 'T',
            content:
              'I suggest a more rational\nand logical solution than\nthe other person’s opinion.',
          },
          {
            type: 'F',
            content:
              'I sympathize with the other person’s emotion\nand try to persuade through conversation.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What’s closer to my opinion on ‘plans’?',
        answers: [
          {
            type: 'J',
            content:
              'For the comfort of being prepared,\nI feel comfortable when I plan ahead.',
          },
          {
            type: 'P',
            content: 'I feel comfortable when\nI can freely do what I want.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'When something new happens,\nwhat do I do?',
        answers: [
          {
            type: 'J',
            content:
              'I observe until I understand\nthe situation thoroughly and\ngather my thoughts.',
          },
          {
            type: 'P',
            content:
              'I jump into the situation,\nshare my thoughts with others,\nand try to solve the problem.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'When deciding on something,\nwhat do I do?',
        answers: [
          {
            type: 'J',
            content:
              'I think not making decisions is\nworse than making quick bad decisions.',
          },
          {
            type: 'P',
            content:
              'To make better decisions,\nI put off deciding and think deeply\nuntil the last moment.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What’s closer to me\nwhen I’m getting work done?',
        answers: [
          {
            type: 'J',
            content:
              'I plan and prepare in advance\nand finish it with spare time.',
          },
          {
            type: 'P',
            content: 'I finish it by concentrating\nat the last moment.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What’s similar to me\nwhen I’m on a trip?',
        answers: [
          {
            type: 'J',
            content:
              'I feel uncomfortable if\nmy plan gets changed,\nbut I pull out my plan B.',
          },
          {
            type: 'P',
            content: 'I plan accordingly to the mood\nand weather of the day.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'gladeGreen',
        color: `#587A4B`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Eng/ESTJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'tangerine',
        color: `#FF977B`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Eng/ESTP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'cosmos',
        color: `#FFCFCF`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Eng/ESFJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'froly',
        color: `#E0707E`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Eng/ESFP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'mariner',
        color: `#3462A3`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Eng/ENTJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'valencia',
        color: `#DD5843`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Eng/ENTP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'aquamarine',
        color: `#52CEB0`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Eng/ENFJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'sunshade',
        color: `#FFA348`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Eng/ENFP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'gainsboro',
        color: `#DDDDDD`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Eng/ISTJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'bostonBlue',
        color: `#4690B4`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Eng/ISTP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'grandis',
        color: `#FFCC71`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Eng/ISFJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'olivine',
        color: `#A6BF6F`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Eng/ISFP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'bilobaFlower',
        color: `#BAB0FF`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Eng/INTJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'mayaBlue',
        color: `#7FC2F4`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Eng/INTP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'hawkesBlue',
        color: `#C0D8FF`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Eng/INFJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'genoa',
        color: `#277A64`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022Eng/INFP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/INFP.png',
      },
    ],
  },
  // percentageMBTI2022
  {
    info: {
      mainTitle: 'percentageMBTI',
      subTitle: 'percentageMBTI',
      mainUrl: 'percentageMBTI2022',
      scoreType: 'percentageMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/percentageMBTI2022-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/percentageMBTI2022-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '다른 사람들이 나를 볼 때\n나와 가까운 것은?',
        answers: [
          {
            type: 'E',
            content: '나와 함께 있을 때 마음이 열리는 것 같다.',
          },
          {
            type: 'I',
            content: '내성적이며 내 생각이나 마음을 알기 어렵다고 한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '다른 사람들과 함께 있을 때 나는?',
        answers: [
          {
            type: 'E',
            content: '혼자 있는 것도 나쁘지는 않지만\n다른 사람과 함께할 때.',
          },
          {
            type: 'I',
            content:
              '같이 있는 것도 좋지만\n혼자 있어야 에너지를 얻을 수 있다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '다른 사람들과 모임(미팅) 후 나는?',
        answers: [
          {
            type: 'E',
            content:
              '오늘 하루 너무 피곤했지만 다같이 함께하니\n행복하고 즐거우며 에너지를 얻는다.',
          },
          {
            type: 'I',
            content:
              '다 같이 있는 것도 즐겁고 행복했지만\n매우 피곤하고 에너지가 다 떨어졌다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '내가 더 스트레스받는 상황은?',
        answers: [
          {
            type: 'E',
            content:
              '질문을 했는데 답변이 빨리\n돌아오지 않으면 스트레스받는다.',
          },
          {
            type: 'I',
            content:
              '누군가 질문을 했을 때,\n대답할 생각이 필요하지만 생각할 시간조차\n주지 않을 때 스트레스 받는다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '새로운 것을 시작할 때 나는?',
        answers: [
          {
            type: 'E',
            content:
              '일단 시작하면 더 좋은 생각이 떠오르기\n때문에 먼저 행동으로 움직인다.',
          },
          {
            type: 'I',
            content:
              '모든 시작을 하기 전 충분한 준비 시간이\n필요하고 심사숙고하게 생각한다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 더 원하는 것은?',
        answers: [
          {
            type: 'S',
            content: '확실하고 믿을만한 사실이다.',
          },
          {
            type: 'N',
            content: '사실이 아니더라도 실험적이며 미래적인 생각이다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '나와 가까운 것은?',
        answers: [
          {
            type: 'S',
            content: '사실주의적이며 몸이 먼저 나서는 스타일이다.',
          },
          {
            type: 'N',
            content: '비현실적인 생각을 많이 하고 생각이 꼬리를 문다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '일(과제) 할 때 나와 비슷한 것은?',
        answers: [
          {
            type: 'S',
            content:
              '한 번에 하나의 작업을 끝낸 후\n다른 업무를 하는 것이 좋다.',
          },
          {
            type: 'N',
            content:
              '한 가지 일에만 몰두하면 지루하기 때문에\n여러 업무를 다양하게 하는 걸 선호한다.',
          },
        ],
      },
      {
        which: 'SN',
        question:
          '다른 사람들과 같이 회의하며\n새로운 아이디어를 생각할 때 나는?',
        answers: [
          {
            type: 'S',
            content: '누군가 제시한 아이디어가 현실 가능한지 생각해본다.',
          },
          {
            type: 'N',
            content:
              '누군가 독창적인 아이디어를 제시하면\n상상함과 동시에 새로운 아이디어들이 떠오른다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '둘 중 나와 더 가까운 것은?',
        answers: [
          {
            type: 'S',
            content: '난 나에게 주어진 기존의 것들을 잘 해낸다.',
          },
          {
            type: 'N',
            content: '난 새로운 도전을 잘하며 두려움이 없다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '다른 사람과 대화할 때,\n거슬린다고 생각되는 것은?',
        answers: [
          {
            type: 'T',
            content: '대화의 주제에 벗어나거나 잘못된 사실을 말할 때.',
          },
          {
            type: 'F',
            content: '다른 사람의 감정을 무시하는 표현을 할 때.',
          },
        ],
      },
      {
        which: 'TF',
        question: '결정할 때 내가 더\n중요시 생각하는 부분은?',
        answers: [
          {
            type: 'T',
            content: '다른 사람이 싫어하더라도\n옳은 것으로 생각하면 결정한다.',
          },
          {
            type: 'F',
            content: '내 가치관과 감정이 좋은 것이라고 생각되면 결정한다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '‘선의의 거짓말’에 대한\n내 생각과 비슷한 것은?',
        answers: [
          {
            type: 'T',
            content:
              '그래도 거짓말은 나쁜 것이니\n선의의 거짓말이라는 것은 말의 모순이다.',
          },
          {
            type: 'F',
            content:
              '선의의 거짓말이 거듭되면 나쁘긴 하지만\n왜 그랬는지 이유를 알면 이해가 간다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '나와 더 가까운 것은?',
        answers: [
          {
            type: 'T',
            content: '나는 노력하는 과정보다 결과가 중요하다.',
          },
          {
            type: 'F',
            content: '난 결과보다 노력해서 해내는 과정이 중요하다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '누군가를 설득할 때 나는?',
        answers: [
          {
            type: 'T',
            content:
              '상대방의 의견보다 더 합리적이고\n논리적인 방안을 제시한다.',
          },
          {
            type: 'F',
            content:
              '상대방의 감정을 공감하면서\n대화하며 설득하려고 노력한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '‘계획’에 대한 내 생각과 비슷한 것은?',
        answers: [
          {
            type: 'J',
            content:
              '준비되어 있다는 편안함을 위해\n미리미리 계획해야 마음의 안정감을 느낀다.',
          },
          {
            type: 'P',
            content:
              '내가 하고 싶은 일을 자유롭게\n할 수 있을 때 마음이 편하다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '새로운 상황이 일어났을 때 나는?',
        answers: [
          {
            type: 'J',
            content:
              '어떤 상황인지 완벽하게 파악하기\n전까지 지켜보며 생각을 정리한다.',
          },
          {
            type: 'P',
            content:
              '먼저 상황에 뛰어들며 다른 사람들과\n생각을 공유하며 문제를 해결하려고 한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '무언가를 결정할 때, 나는?',
        answers: [
          {
            type: 'J',
            content:
              '빨리 해결하기 위해 나쁜 결정보다\n결정하지 않는 것이 더 나쁘다고 생각한다.',
          },
          {
            type: 'P',
            content:
              '더 나은 결정을 하기 위해 잠시 보류하고\n정보를 수집한 뒤 마지막까지 심사숙고한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '업무를 처리하는\n나의 모습과 가까운 것은?',
        answers: [
          {
            type: 'J',
            content: '미리미리 준비하고 계획해서 여유롭게 끝낸다.',
          },
          {
            type: 'P',
            content: '마지막 순간에 집중해서 끝낸다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '여행을 갔을 때\n나의 모습과 비슷한 것은?',
        answers: [
          {
            type: 'J',
            content:
              '기존에 내가 미리 준비한 계획에 틀어지면\n마음이 불안하지만 플랜B를 꺼낸다.',
          },
          {
            type: 'P',
            content: '그날의 기분, 날씨에 따라 유동적으로 계획을 세운다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'gladeGreen',
        color: `#587A4B`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/color/ESTJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'tangerine',
        color: `#FF977B`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/color/ESTP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'cosmos',
        color: `#FFCFCF`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/color/ESFJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'froly',
        color: `#E0707E`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/color/ESFP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'mariner',
        color: `#3462A3`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/color/ENTJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'valencia',
        color: `#DD5843`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/color/ENTP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'aquamarine',
        color: `#52CEB0`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/color/ENFJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'sunshade',
        color: `#FFA348`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/color/ENFP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'gainsboro',
        color: `#DDDDDD`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/color/ISTJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'bostonBlue',
        color: `#4690B4`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/color/ISTP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'grandis',
        color: `#FFCC71`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/color/ISFJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'olivine',
        color: `#A6BF6F`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/color/ISFP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'bilobaFlower',
        color: `#BAB0FF`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/color/INTJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'mayaBlue',
        color: `#7FC2F4`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/color/INTP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'hawkesBlue',
        color: `#C0D8FF`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/color/INFJ.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'genoa',
        color: `#277A64`,
        img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/color/INFP.png',
        bg_img_src:
          'https://images.ktestone.com/resultImages/percentageMBTI2022/INFP.png',
      },
    ],
  },
  // personalColor2022CN
  {
    info: {
      mainTitle: '颜色心理测试性格篇 2022',
      subTitle: '最适合我的颜色是什么？ K测试 你的颜色是？',
      mainUrl: 'personalColor2022CN',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/personalColor2022CN-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/personalColor2022CN-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'CN',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '初次结识的朋友面前我是？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '通常我先说话。',
          },
          {
            type: 'I',
            score: 5,
            content: '通常对方先说话。',
          },
        ],
      },
      {
        which: 'EI',
        question: '周末我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '喜欢和其他人见面。',
          },
          {
            type: 'I',
            score: 5,
            content: '并不介意独自度过。',
          },
        ],
      },
      {
        which: 'EI',
        question: '和朋友们出行时我是？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '喜欢喧嚣热闹的气氛。',
          },
          {
            type: 'I',
            score: 5,
            content: '喜欢和少数朋友们一起聊天。',
          },
        ],
      },
      {
        which: 'SN',
        question: '我认为更重要的是？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '没有现在就意味着没有未来。',
          },
          {
            type: 'N',
            score: 5,
            content: '不考虑未来的话就不会有进步。',
          },
        ],
      },
      {
        which: 'SN',
        question: '工作时的我是',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '更偏向跟随别人的脚步。',
          },
          {
            type: 'N',
            score: 5,
            content: '更偏向采取自己的方式。',
          },
        ],
      },
      {
        which: 'SN',
        question: '周围的人认为我是？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '经常说我很有耐心和恒心。',
          },
          {
            type: 'N',
            score: 5,
            content: '经常说我很有自己独创的思维。',
          },
        ],
      },
      {
        which: 'TF',
        question: '遇到必须拒绝的情况时，\n我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '直接了当地拒绝。',
          },
          {
            type: 'F',
            score: 5,
            content: '深思熟虑后也往往会顺从。',
          },
        ],
      },
      {
        which: 'TF',
        question: '我生气的时候会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '说得很有道理，\n同时也很计较。',
          },
          {
            type: 'F',
            score: 5,
            content: '想说的话很多，但因为太生气，\n往往眼泪先流出来。',
          },
        ],
      },
      {
        which: 'TF',
        question: '朋友向我诉说烦恼，\n我却觉得是朋友的错时，\n我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '告诉他错的原因。',
          },
          {
            type: 'F',
            score: 5,
            content: '直接了当地说的话担心朋友会伤心，\n所以故意绕着弯子说。',
          },
        ],
      },
      {
        which: 'JP',
        question: '做准备工作时的我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '提前一天做好准备。',
          },
          {
            type: 'P',
            score: 5,
            content: '总想着‘明天再说明天再说’，\n经常忘记。',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '结束了一天的日程回家后准备学习，\n结果朋友们突然叫我出去玩，这时我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '不在我的计划范围内…很纠结。',
          },
          {
            type: 'P',
            score: 5,
            content: 'OK！果然人生不会按计划走！\n玩起！！！',
          },
        ],
      },
      {
        which: 'JP',
        question: '总体上我是？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '按我的计划进行！\n更偏向按计划的顺序进行！',
          },
          {
            type: 'P',
            score: 5,
            content: '想起什么就先做什么！\n更偏向灵活处理！',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'gladeGreen',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022CN/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'tangerine',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022CN/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'cosmos',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022CN/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'froly',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022CN/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'mariner',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022CN/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'valencia',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022CN/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'aquamarine',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022CN/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'sunshade',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022CN/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'gainsboro',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022CN/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'bostonBlue',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022CN/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'grandis',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022CN/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'olivine',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022CN/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'bilobaFlower',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022CN/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'mayaBlue',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022CN/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'hawkesBlue',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022CN/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'genoa',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022CN/INFP.png',
      },
    ],
  },
  // personalColor2022Eng
  {
    info: {
      mainTitle: 'Personal color test 2022',
      subTitle: 'What is my perosnal color?',
      mainUrl: 'personalColor2022Eng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/personalColor2022Eng-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/personalColor2022Eng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'What am I to my first-time friends?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I speak to others first.',
          },
          {
            type: 'I',
            score: 5,
            content: 'Most of the time,\nthe other friend speaks to you first.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What do I do during the weekend?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I enjoy meeting people.',
          },
          {
            type: 'I',
            score: 5,
            content: "I don't hate being alone.",
          },
        ],
      },
      {
        which: 'EI',
        question: 'What do I do when playing with my friends?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I like the chatty and exciting atmosphere.',
          },
          {
            type: 'I',
            score: 5,
            content:
              'I like to have small conversations\nwith a small number of friends.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do I think more important?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'No present, no future.',
          },
          {
            type: 'N',
            score: 5,
            content: 'No preparation for the future,\nno progress.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do I do when working?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'It is good to follow what others do.',
          },
          {
            type: 'N',
            score: 5,
            content: "It's good to go on your own way.",
          },
        ],
      },
      {
        which: 'SN',
        question: 'What are the others say to me?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'They often say that\nI am persistent and patient.',
          },
          {
            type: 'N',
            score: 5,
            content: 'They often say that\nI am creative and unique.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'What if I have to refuse?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: "I strongly say I can't do it.",
          },
          {
            type: 'F',
            score: 5,
            content:
              'I tend to accept most of what\nthey ask after a little hesitation.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'What if I am angry?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I speak logically and argue.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'I have a lot to say,\nbut I am so angry that \n start to tear up.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          "My friend talks about his worries.\nSeems to me, it's fault.\nThen, what is my response?",
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Tell the friend it is his fault.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Saying it directly may hurt his mind.\nI will tell him beating around the bush.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'When do I do when preparing the supplies?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Prepare in advance a day before.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '"I will prepare them tomorrow"\nbut I frequent forget and leave behind.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'After school, I want go home and study,\nbut my friends catch me up to play. Then?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: "It wasn't in the plan...\nVery embarrassing.",
          },
          {
            type: 'P',
            score: 5,
            content:
              "Okay! After all, life doesn't go as planned!\nLet's play!!!",
          },
        ],
      },
      {
        which: 'JP',
        question: 'In general, who am I?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'As I planned!\nI like to go through the order of things I made!',
          },
          {
            type: 'P',
            score: 5,
            content:
              'I take care of things as I think of!\nI love to be flexible!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'gladeGreen',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022Eng/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'tangerine',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022Eng/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'cosmos',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022Eng/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'froly',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022Eng/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'mariner',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022Eng/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'valencia',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022Eng/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'aquamarine',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022Eng/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'sunshade',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022Eng/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'gainsboro',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022Eng/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'bostonBlue',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022Eng/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'grandis',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022Eng/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'olivine',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022Eng/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'bilobaFlower',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022Eng/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'mayaBlue',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022Eng/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'hawkesBlue',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022Eng/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'genoa',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022Eng/INFP.png',
      },
    ],
  },
  // persoanlColor2022 //
  {
    info: {
      mainTitle: '퍼스널 컬러 테스트 2022',
      subTitle: '나에게 어울리는 컬러는 무엇일까?',
      mainUrl: 'personalColor2022',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/personalColor2022-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/personalColor2022-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '친구들이 말하는 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '활발하고 내 의견을 얘기할 때 당당한 편!',
          },
          {
            type: 'I',
            score: 5,
            content: '소극적이면서 조금 조용한 편.',
          },
        ],
      },
      {
        which: 'EI',
        question: '새로운 장소(모임, 수업, 회사)에\n가게 된 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '옆자리 사람에게 먼저 해맑게 인사한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '눈이 마주치면 조심스럽게 인사한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '밥을 먹다 직원을 불렀는데\n오지 않을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '저기요~ 하며 큰소리로 직원을 부른다.',
          },
          {
            type: 'I',
            score: 5,
            content:
              '앞에 있는 친구에게 ‘왜 안올까..?’\n하면서 올때까지 기다린다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '보이는 그대로 눈앞에 현재에 집중한다.',
          },
          {
            type: 'N',
            score: 5,
            content: '일어나지 않은 일에 대한\n걱정이 많고 상상력이 풍부하다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '티비를 볼 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'ㅋㅋㅋ웃기네 재밌다!',
          },
          {
            type: 'N',
            score: 5,
            content:
              '만약 내가 티비에 나간다면 나는 뭐라고할까?\n재밌게 말해서 이슈가 되면 재밌겠군.',
          },
        ],
      },
      {
        which: 'SN',
        question: '데이트를 한다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '유명 맛집이나 평소 가고 싶었던\n카페를 가는 일상 데이트!',
          },
          {
            type: 'N',
            score: 5,
            content: '익숙한 것도 좋지만 색다르고\n특별한 이색체험 데이트!',
          },
        ],
      },
      {
        which: 'TF',
        question: '거절해야 하는 상황이 오면 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '단호하게 할 수 없다고 말한다.',
          },
          {
            type: 'F',
            score: 5,
            content: '고민하다가 대부분 들어주는 편이다.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '고민을 얘기하는 친구, 듣다 보니\n친구의 잘못인 것 같다.\n그럴 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '논리적으로 친구의 잘못된 점을 말해준다.',
          },
          {
            type: 'F',
            score: 5,
            content:
              '직접적으로 말하면 친구가 그래 할까봐\n최대한 돌려서 말한다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '누군가 힘들다며 고민상담을 할 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '현실적인 해결책을 제시하고 응원한다.',
          },
          {
            type: 'F',
            score: 5,
            content: '그 사람의 감정에 공감하고 정신적인 지지를 해준다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '나랑 더 가까운 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '정리정돈을 잘하고 내 물건이 어딨는지 잘 찾는다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '내 물건들은 어디 있는지 잘 안다고 생각하지만,\n막상 찾아보면 없다…',
          },
        ],
      },
      {
        which: 'JP',
        question: '나는',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '내가 계획한대로! 계획적으로 일의\n순서대로 진행하는 것을 좋아한다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '그때그때 생각나는 대로 일 처리!\n융통성 있게 하는 것을 좋아한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '계획이란',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '세세하게 세우지 않더라도 전체적인 틀과\n그날 하루의 가이드라인 같은 것',
          },
          {
            type: 'P',
            score: 5,
            content: '계획? 무계획도 계획의 일부다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: '',
        query: 'gladeGreen',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: '',
        query: 'tangerine',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: '',
        query: 'cosmos',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: '',
        query: 'froly',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: '',
        query: 'mariner',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: '',
        query: 'valencia',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: '',
        query: 'aquamarine',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: '',
        query: 'sunshade',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: '',
        query: 'gainsboro',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: '',
        query: 'bostonBlue',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: '',
        query: 'grandis',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: '',
        query: 'olivine',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: '',
        query: 'bilobaFlower',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022/INTJ.png',
      },
      {
        type: 'INTP',
        desc: '',
        query: 'mayaBlue',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022/INTP.png',
      },
      {
        type: 'INFJ',
        desc: '',
        query: 'hawkesBlue',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022/INFJ.png',
      },
      {
        type: 'INFP',
        desc: '',
        query: 'genoa',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor2022/INFP.png',
      },
    ],
  },
  // sinjoer2022
  {
    info: {
      mainTitle: '2022 신조어 테스트',
      subTitle: '과연 나는 얼마나 많이 알고 있을까? 2022 신조어 테스트',
      mainUrl: 'sinjoer2022',
      scoreType: 'numberScoringImg',
      mainImage:
        'https://images.ktestone.com/introImages/sinjoer2022-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/sinjoer2022-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'etc',
    },
    questions: [
      {
        question: `다음 중 '당모치'의 뜻으로 옳은 것은?`,
        answers: [
          {
            type: '포메라니안',
            score: 0,
            content: '당당한 모습의 치와와',
          },
          {
            type: '치와와',
            score: 0,
            content: '당연히 모이면 치킨이지',
          },
          {
            type: '포메라니안',
            score: 1,
            content: '당연히 모든 치킨은 옳다',
          },
          {
            type: '치와와',
            score: 0,
            content: '당근은 모두 치워버려',
          },
        ],
        questionImage: '',
      },
      {
        question: `다음 중 '억텐'의 반댓말은?`,
        answers: [
          {
            type: '포메라니안',
            score: 0,
            content: '텐텐',
          },
          {
            type: '웰시코기',
            score: 1,
            content: '찐텐',
          },
          {
            type: '포메라니안',
            score: 0,
            content: '썬텐',
          },
          {
            type: '웰시코기',
            score: 0,
            content: '빡텐',
          },
        ],
        questionImage: '',
      },
      {
        question: `다음 중 갑통알의 뜻으로 옳은 것은?`,
        answers: [
          {
            type: '치와와',
            score: 0,
            content: '갑자기 통증이 알통에',
          },
          {
            type: '웰시코기',
            score: 1,
            content: '갑자기 통장을 보니 알바를 해야 할 것 같다',
          },
          {
            type: '웰시코기',
            score: 0,
            content: '갑오징어 통째로 먹으면 알라뷰',
          },
          {
            type: '웰시코기',
            score: 0,
            content: '갑자기 통통한 알이 가득한 알탕을 먹고싶다',
          },
        ],
        questionImage: '',
      },
      {
        question: `‘너또다’의 의미로 옳은 것은?`,
        answers: [
          {
            type: '비글',
            score: 0,
            content: '너 또라이다.',
          },
          {
            type: '리트리버',
            score: 1,
            content: '너 또라이라 다행이다.',
          },
          {
            type: '리트리버',
            score: 0,
            content: '너 또라이인건 다 알고 있지.',
          },
          {
            type: '리트리버',
            score: 0,
            content: '너 또라이 맞는데..다른 의견 있니?',
          },
        ],
        questionImage: '',
      },
      {
        question: `다음 중 들어갈 2022신조어로 알맞은 것은?\n"야 그건 000(이)야 아무도 모르는 거야"`,
        answers: [
          {
            type: '리트리버',
            score: 0,
            content: '사바사',
          },
          {
            type: '웰시코기',
            score: 1,
            content: '닝바닝',
          },
          {
            type: '리트리버',
            score: 0,
            content: '케바케',
          },
          {
            type: '웰시코기',
            score: 0,
            content: '너또다',
          },
        ],
        questionImage: '',
      },
      {
        question: '다음 중 의미가 다른 하나를 고르시오.',
        answers: [
          {
            type: '포메라니안',
            score: 0,
            content: '상사병',
          },
          {
            type: '비글',
            score: 0,
            content: '실어증',
          },
          {
            type: '포메라니안',
            score: 0,
            content: '알잘딱깔센',
          },
          {
            type: '비글',
            score: 1,
            content: '스라벨',
          },
        ],
        questionImage: '',
      },
      {
        question: "다음 중 '희연사'의 해석으로 맞는 것은?",
        answers: [
          {
            type: '비글',
            score: 0,
            content: '희연아 사랑해',
          },
          {
            type: '웰시코기',
            score: 0,
            content: '희망가득한 연말보내! 사랑해!',
          },
          {
            type: '비글',
            score: 0,
            content: '희미해진 연기 사이로',
          },
          {
            type: '비글',
            score: 1,
            content: '희귀 연예인 사진',
          },
        ],
        questionImage: '',
      },
      {
        question: `'제곧네'와 같은 뜻을 가진 신조어는?`,
        answers: [
          {
            type: '치와와',
            score: 0,
            content: '냉무',
          },
          {
            type: '리트리버',
            score: 0,
            content: '니곧내',
          },
          {
            type: '리트리버',
            score: 0,
            content: '완내스',
          },
          {
            type: '리트리버',
            score: 0,
            content: '쟤같네',
          },
        ],
        questionImage: '',
      },
      {
        question: `다음중 '법블레스'의 상황으로 맞는 것은?`,
        answers: [
          {
            type: '치와와',
            score: 1,
            content: '동생이 화나게 했을 때',
          },
          {
            type: '리트리버',
            score: 0,
            content: '누군가 재채기를 했을 때',
          },
          {
            type: '치와와',
            score: 0,
            content: '노블리스 오블리제를 실천할 때',
          },
          {
            type: '리트리버',
            score: 0,
            content: '친구가 취뽀했을때',
          },
        ],
        questionImage: '',
      },
      {
        question: "다음 중 '제당슈만'의 의미로 옳은 것은?",
        answers: [
          {
            type: '치와와',
            score: 1,
            content: '제가 당신을 꼭 슈퍼스타로 만들어 드릴게요.',
          },
          {
            type: '리트리버',
            score: 0,
            content: '제일 제당 슈가 만세',
          },
          {
            type: '치와와',
            score: 0,
            content: '제가 당신과 슈퍼에서 만나도 될까요?',
          },
          {
            type: '리트리버',
            score: 0,
            content: '제빵은 당연히 슈크림빵! 만들어볼까?',
          },
        ],
        questionImage: '',
      },
      {
        question: `다음 중 '스불재'의 뜻으로 옳은 것은?`,
        answers: [
          {
            type: '치와와',
            score: 0,
            content: '스스로 불고기 재료를 준비한다',
          },
          {
            type: '리트리버',
            score: 0,
            content: '스르륵 불러보는 재밌는 노래',
          },
          {
            type: '치와와',
            score: 0,
            content: '스님 불교 재밌나요?',
          },
          {
            type: '리트리버',
            score: 1,
            content: '스스로 불러온 재앙',
          },
        ],
        questionImage: '',
      },
      {
        question: '어쩔티비를 이기는 방법 중 알맞은 것은?',
        answers: [
          {
            type: '치와와',
            score: 0,
            content: '듣기 싫다며 소리를 지른다',
          },
          {
            type: '리트리버',
            score: 1,
            content: '안물안궁~ 안물티비~안궁티비~뇌절티비~',
          },
          {
            type: '치와와',
            score: 0,
            content: '한심하게 쳐다본뒤 한숨을 쉬며 고개를 절레 흔든다.',
          },
          {
            type: '리트리버',
            score: 0,
            content: '킹정하는 부분이다.',
          },
        ],
        questionImage: '',
      },
    ],
    results: [
      {
        type: '15%',
        desc: ``,
        query: 'dPttkfka',
        img_src:
          'https://images.ktestone.com/resultImages/sinjoer2022/sinjoer2022-15.png',
      },
      {
        type: '55%',
        desc: ``,
        query: 'snsxldfj',
        img_src:
          'https://images.ktestone.com/resultImages/sinjoer2022/sinjoer2022-55.png',
      },
      {
        type: '85%',
        desc: ``,
        query: 'tpalxmfpsej',
        img_src:
          'https://images.ktestone.com/resultImages/sinjoer2022/sinjoer2022-85.png',
      },
      {
        type: '98%',
        desc: ``,
        query: 'dlsrksxmfpsej',
        img_src:
          'https://images.ktestone.com/resultImages/sinjoer2022/sinjoer2022-98.png',
      },
    ],
  },
  // lovejewerlyEng
  {
    info: {
      mainTitle: 'Love jewerlyBTI',
      subTitle: 'What is the love jewelry that suits me?',
      mainUrl: 'lovejewerlyEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/lovejewerlyEng-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/lovejewerlyEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Eng',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: 'On a honey-like weekend?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'The house is so boring!\nI will go anywhere!',
          },
          {
            type: 'I',
            score: 5,
            content:
              'After all,\nI should take a rest while watching TV\nat home on weekends.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'The start of a new semester!\nI saw a guy I liked.',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Approach him first to say hello.',
          },
          {
            type: 'I',
            score: 5,
            content: " I'm just staring at you.",
          },
        ],
      },
      {
        which: 'EI',
        question:
          'Your friend asks you to drag you\nin front of the opposite gender and have a conversation.',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Laugh and have fun talking together.',
          },
          {
            type: 'I',
            score: 5,
            content: "I'm shy, so I just smile quietly and be awkward.",
          },
        ],
      },
      {
        which: 'SN',
        question: 'When I think of the person I like,\nwhat do I do?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Can it be done realistically?',
          },
          {
            type: 'N',
            score: 5,
            content:
              'Ha... If I date that person,\nI will do this and that too~ Oh, it makes my heart flutter!',
          },
        ],
      },
      {
        which: 'SN',
        question: 'Me in love,\nwhen I look at the world?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              "'A mountain is a mountain.\nWater is water.'\nSee it as it is,",
          },
          {
            type: 'N',
            score: 5,
            content:
              'Rather than reality,\nI spread my own world, my imagination.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'During a date,\nI was hungry and found a restaurant.',
        answers: [
          {
            type: 'S',
            score: 2,
            content: "The review looks good!\nLet's go here!",
          },
          {
            type: 'N',
            score: 5,
            content: 'Oh, It smells a restaurant that looks perfect.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'What is my first reaction when I am angry?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Speak rationally about the reason for my anger.',
          },
          {
            type: 'F',
            score: 5,
            content: "I'm filled with anger and I'm crying.",
          },
        ],
      },
      {
        which: 'TF',
        question: 'The person I like is in a difficult situation.',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Propose and support realistic solutions.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Empathize with emotions and provides psychological support.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'When I talk to someone I like,\nwhat do I do?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Ask questions and ask questions about him.',
          },
          {
            type: 'F',
            score: 5,
            content: 'React and empathize when he is talking to me.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Suddenly the mood Hawai!\nI want to go to Hawai!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Plan a detailed schedule for each day of the week',
          },
          {
            type: 'P',
            score: 5,
            content:
              'Flight ticket reservation complete!\nI think the plan is almost over.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Which one is closer to me?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I am well organized and find where my things are.',
          },
          {
            type: 'P',
            score: 5,
            content:
              "I think I know where my things are,\nbut when I look for them, I can't find them...",
          },
        ],
      },
      {
        which: 'JP',
        question: 'I try to confess to the person you like.',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Make a thorough plan and aim for the right time to confess.',
          },
          {
            type: 'P',
            score: 5,
            content: 'Confess promptly based on the atmosphere.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'zjspffldjs',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerlyEng/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'fhwlznjcm',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerlyEng/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'tmxmfhqpflznjcm',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerlyEng/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'rkspt',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerlyEng/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tkvkdldj',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerlyEng/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'fnql',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerlyEng/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dpapfkfem',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerlyEng/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'tlxmfls',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerlyEng/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ekdldkahsem',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerlyEng/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'xhvkwm',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerlyEng/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'dhvkf',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerlyEng/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'vpflehxm',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerlyEng/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dkaptltmxm',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerlyEng/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dkdldhffkdlxm',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerlyEng/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dkzndkakfls',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerlyEng/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'dkakwhskdlxm',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerlyEng/INFP.png',
      },
    ],
  },
  // lovejewerly //
  {
    info: {
      mainTitle: '러브 주얼리BTI',
      subTitle: '나에게 어울리는 러브 주얼리는 무엇일까?',
      mainUrl: 'lovejewerly',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/lovejewerly-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/lovejewerly-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '꿀 같은 주말에 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '집은 너무 심심해! 어디라도 가야겠다!',
          },
          {
            type: 'I',
            score: 5,
            content: '역시 주말엔 집에서 티비보면 쉬어야지~',
          },
        ],
      },
      {
        which: 'EI',
        question: '새 학기의 시작! 마음에 드는 이성을 봤다.',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '먼저 다가가서 인사한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '힐끔힐끔 바라만 본다.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '친구가 좋아하는 이성 앞에 끌고 가 같이 대화를 나누자고 한다.',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '같이 웃으며 즐겁게 대화한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '쑥스러워서 조용히 미소만 짓고 어색하게 있는다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '좋아하는 사람을 생각할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '현실적으로 이루어질 수 있을까?',
          },
          {
            type: 'N',
            score: 5,
            content: '하...그 사람이랑 사귀면 이것도 저것도 하고~ 아 설레네!',
          },
        ],
      },
      {
        which: 'SN',
        question: '사랑에 빠진 나, 내가 세상을 바라볼 때?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '‘산은 산이요. 물은 물이로다.’ 있는 그대로 본다.',
          },
          {
            type: 'N',
            score: 5,
            content: '현실보다는 나만의 세계, 상상의 나래를 펼친다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '데이트 중 슬슬 배가 고픈데 음식점을 발견했다.',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '리뷰 보니 괜찮다! 여기로 가자!',
          },
          {
            type: 'N',
            score: 5,
            content: '오오 보기에 딱 여긴 맛집 스멜난다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '화가 났을 때 나의 첫 반응은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '화난 이유에 대해 이성적으로 말한다.',
          },
          {
            type: 'F',
            score: 5,
            content: '분노에 차올라 눈물부터 난다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '좋아하는 사람이 힘든 상황에 처했다.',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '현실적인 해결책을 제시하고 응원한다.',
          },
          {
            type: 'F',
            score: 5,
            content: '감정을 공감하고 정신적인 지지를 해준다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '좋아하는 사람과 대화할 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '질문으로 상대방에 대해 물어보고 궁금해한다.',
          },
          {
            type: 'F',
            score: 5,
            content: '그 사람이 대화할 때 리액션하며 공감한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '갑자기 분위기 제주도! 제주도에 가야겠다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '요일별로 세부일정을 계획한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '비행기 표 예매 완료~! 계획은 거의 끝났다고 본다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '나랑 더 가까운 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '정리정돈을 잘하고 내 물건이 어딨는지 잘 찾는다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '내 물건들은 어디 있는지 잘 안다고 생각하지만, 막상 찾아보면 없다…',
          },
        ],
      },
      {
        which: 'JP',
        question: '좋아하는 사람에게 고백하려고 한다.',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '철저한 계획을 잡고 고백할 타이밍을 노린다.',
          },
          {
            type: 'P',
            score: 5,
            content: '분위기 봐서 즉흥적으로 고백한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: '',
        query: 'zjspffldjs',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerly/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: '',
        query: 'fhwlznjcm',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerly/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: '',
        query: 'tmxmfhqpflznjcm',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerly/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: '',
        query: 'rkspt',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerly/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: '',
        query: 'tkvkdldj',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerly/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: '',
        query: 'fnql',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerly/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: '',
        query: 'dpapfkfem',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerly/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: '',
        query: 'tlxmfls',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerly/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: '',
        query: 'ekdldkahsem',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerly/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: '',
        query: 'xhvkwm',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerly/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: '',
        query: 'dhvkf',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerly/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: '',
        query: 'vpflehxm',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerly/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: '',
        query: 'dkaptltmxm',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerly/INTJ.png',
      },
      {
        type: 'INTP',
        desc: '',
        query: 'dkdldhffkdlxm',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerly/INTP.png',
      },
      {
        type: 'INFJ',
        desc: '',
        query: 'dkzndkakfls',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerly/INFJ.png',
      },
      {
        type: 'INFP',
        desc: '',
        query: 'dkakwhskdlxm',
        img_src:
          'https://images.ktestone.com/resultImages/lovejewerly/INFP.png',
      },
    ],
  },
  // gyeonggiCulture //
  {
    info: {
      mainTitle: '나랑 잘 맞는 경기도 문화탐방지는?',
      subTitle: '여행스타일로 알아보는 맞춤 탐장지 추천',
      mainUrl: 'gyeonggiCulture',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/gyeonggiCulture-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/gyeonggiCulture-thumb.png',
      lang: 'Kor',
      category: 'etc',
    },
    questions: [
      {
        which: 'EI',
        question: '여행은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구들이나 가족 등 누군가와 함께 떠나는 게 좋아',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자 가는 여행도 나는 좋더라~',
          },
        ],
      },
      {
        which: 'EI',
        question: '숙소를 예약할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '뭔가 게스트 하우스 같은 곳에서 새로운 사람들과  재밌게 놀고 싶지 않아?',
          },
          {
            type: 'I',
            score: 5,
            content: '우리끼리 그냥 편하고 프라이빗하게 쉴래!',
          },
        ],
      },
      {
        which: 'EI',
        question: '여행 시 길을 잃었을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '지나가는 사람이나 가게에 들어가 물어본다.',
          },
          {
            type: 'I',
            score: 5,
            content: '물어보는 친구 옆에 있는 사람이 나다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '여행 갈 때 장소를 선택하는 기준은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '리뷰가 검증되고 유명한 곳!',
          },
          {
            type: 'N',
            score: 5,
            content: '아무도 가보지 못한 새로운 장소를 찾아보자!',
          },
        ],
      },
      {
        which: 'SN',
        question: '나와 가까운 여행 스타일은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '여행가서 보고! 먹고! 놀자!',
          },
          {
            type: 'N',
            score: 5,
            content: '이번 여행 컨셉 뭐야?',
          },
        ],
      },
      {
        which: 'SN',
        question: '여행 시 맛집을 고르는 나의 기준은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '확실한 리뷰와 맛 보장이 가능한 맛집 써치 후 방문한다.',
          },
          {
            type: 'N',
            score: 5,
            content: '지나가다 보이는 현지 맛집 스러운 분위기로 가자!',
          },
        ],
      },
      {
        which: 'TF',
        question: '여행을 다녀온 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '사진 공유와 정산 후 일상으로 바로 복귀',
          },
          {
            type: 'F',
            score: 5,
            content: '여행 사진을 보며 다음 여행을 기다리는 후유증',
          },
        ],
      },
      {
        which: 'TF',
        question: '맛집을 찾았는데 대기 시간이 너무 길다.',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '효율적이게 다른 곳으로 가자!',
          },
          {
            type: 'F',
            score: 5,
            content: '시간이 걸려도 일단은 기다려본다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '여행지에서 멋진 건축물을 봤을 때 먼저 드는 생각은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '이걸 어떻게 지었을까 하고 생각한다.',
          },
          {
            type: 'F',
            score: 5,
            content: '와…대박이라고 하며 감탄한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '나의 여행 계획 스타일은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '나는 무조건 계획이 필요해!',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 떠나자!! ',
          },
        ],
      },
      {
        which: 'JP',
        question: '여행 가기 전 나의 캐리어는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '가기 일주일 전부터 캐리어를 열어두고 하나둘 씩 챙긴다.',
          },
          {
            type: 'P',
            score: 5,
            content: '떠나기 하루 전 캐리어를 꺼내 짐을 챙긴다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '여행을 다녀온 후 나의 모습은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '나중되면 더 하기 싫으니까 미리 정리하자!',
          },
          {
            type: 'P',
            score: 5,
            content: '휴 일단 나중에 정리하고 쉬는 것까지가 여행이다!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: '',
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/gyeonggiCulture/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: '',
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/gyeonggiCulture/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: '',
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/gyeonggiCulture/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: '',
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/gyeonggiCulture/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: '',
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/gyeonggiCulture/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: '',
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/gyeonggiCulture/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: '',
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/gyeonggiCulture/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: '',
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/gyeonggiCulture/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: '',
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/gyeonggiCulture/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: '',
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/gyeonggiCulture/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: '',
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/gyeonggiCulture/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: '',
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/gyeonggiCulture/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: '',
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/gyeonggiCulture/INTJ.png',
      },
      {
        type: 'INTP',
        desc: '',
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/gyeonggiCulture/INTP.png',
      },
      {
        type: 'INFJ',
        desc: '',
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/gyeonggiCulture/INFJ.png',
      },
      {
        type: 'INFP',
        desc: '',
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/gyeonggiCulture/INFP.png',
      },
    ],
  },
  // idealType in English
  {
    info: {
      mainTitle: 'Ideal Type Fruits Test',
      subTitle: 'What is my ideal fruit character?',
      mainUrl: 'idealTypeEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/idealTypeEng-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/idealTypeEng-thumb.png',
      lang: 'Eng',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: 'What is my ideal type?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'A person who approaches me first and drops hints',
          },
          {
            type: 'I',
            score: 5,
            content:
              'A person who gives me space and waits for me to approach before showing the feelings',
          },
        ],
      },
      {
        which: 'EI',
        question: 'How does my ideal type hangs out with friends?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'A person who likes to be surrounded by many people and who is talkative and active',
          },
          {
            type: 'I',
            score: 5,
            content: 'A person who enjoys being in a small group',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What is the position of my ideal type at a party?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'A person who creates the vibe and makes everyone laugh',
          },
          {
            type: 'I',
            score: 5,
            content:
              'A person who smiles well and tends to show just reactions',
          },
        ],
      },
      {
        which: 'SN',
        question: 'What is my ideal type?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'A person who is patient',
          },
          {
            type: 'N',
            score: 5,
            content: 'A person who is creative and unique',
          },
        ],
      },
      {
        which: 'SN',
        question: 'How do you see your girl/boyfriend?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'A person who works hard at the moment, or the present',
          },
          {
            type: 'N',
            score: 5,
            content: 'A person who has a promising future',
          },
        ],
      },
      {
        which: 'SN',
        question:
          'How does my ideal type react when she/he sees a great restaurant?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'A person who reads the review and enters the restaurant',
          },
          {
            type: 'N',
            score: 5,
            content:
              'A person who judges by the look of the restaurant and enters',
          },
        ],
      },
      {
        which: 'TF',
        question: 'How does my ideal type say “No”?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'A person capable of directly saying what she/he can’t do',
          },
          {
            type: 'F',
            score: 5,
            content: 'A person who just does what she/he can do',
          },
        ],
      },
      {
        which: 'TF',
        question: 'How does my ideal type handle conflicts?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'A person who logically tells what is wrong',
          },
          {
            type: 'F',
            score: 5,
            content:
              'A person who becomes emotional, and asks to continue the conversation later',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'You are confronting the mistake you made. How does your ideal type react?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'A person who directly explains the mistakes I made',
          },
          {
            type: 'F',
            score: 5,
            content:
              'A person who indirectly explains thinking that I might get hurt',
          },
        ],
      },
      {
        which: 'JP',
        question: 'How does my ideal type prepare for a trip?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'A person who prepares and packs with a list of items in advance',
          },
          {
            type: 'P',
            score: 5,
            content:
              'A person who does not prepare in advance and goes to a store to buy one or two items left unpacked',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What is my ideal type like on a trip?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'A person who goes to places searched for in advance',
          },
          {
            type: 'P',
            score: 5,
            content:
              'Let’s see how it turns out! A person who decides at the site',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Which of the two is similar to my ideal type?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'A person with a drive and has a good concentration but is a little stubborn.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'A free-spirited person who doesn’t make plans and is spontaneous.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeEng/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeEng/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeEng/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeEng/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeEng/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeEng/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeEng/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeEng/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeEng/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeEng/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeEng/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeEng/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeEng/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeEng/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeEng/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/idealTypeEng/INFP.png',
      },
    ],
  },
  // idealType //
  {
    info: {
      mainTitle: '이상형 부캐 테스트',
      subTitle: '내 이상형의 과일 캐릭터는 무엇일까?',
      mainUrl: 'idealType',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/idealType-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/idealType-thumb.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '내가 원하는 이상형은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '나에게 먼저 다가와서 호감을 표현하는 사람',
          },
          {
            type: 'I',
            score: 5,
            content: '부담스럽게 너무 다가오기보다 내가 다가가면 표현하는 사람',
          },
        ],
      },
      {
        which: 'EI',
        question: '내가 원하는 이상형이 친구들과 놀 때 모습은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '많은 친구들과 소통하며 활발하게 노는 사람',
          },
          {
            type: 'I',
            score: 5,
            content: '소수의 친구들과 소소하게 즐기는 사람',
          },
        ],
      },
      {
        which: 'EI',
        question: '내 이상형의 술자리 포지션은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '분위기를 주도하며 웃음을 주는 타입',
          },
          {
            type: 'I',
            score: 5,
            content: '잘 웃어주며 리액션을 하는 타입',
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 원하는 이상형의 사람은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '참을성이 있고 꾸준한 사람',
          },
          {
            type: 'N',
            score: 5,
            content: '창의적이고 독창성이 있는 사람',
          },
        ],
      },
      {
        which: 'SN',
        question: '내 애인은 이랬으면 좋겠다.',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '지금, 현재를 열심히 잘 사는 사람',
          },
          {
            type: 'N',
            score: 5,
            content: '미래 발전 가능성이 높은 사람',
          },
        ],
      },
      {
        which: 'SN',
        question: '나의 이상형이 맛집을 발견했을 때의 모습은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '여기가 어떤지 리뷰를 찾아보고 들어간다',
          },
          {
            type: 'N',
            score: 5,
            content: '맛집 스러워보이는 외관을 보고 들어간다',
          },
        ],
      },
      {
        which: 'TF',
        question: '거절해야하는 상황에서 나의 이상형은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '할 수 없는 건 강력하게 할 수 없다고 말했으면 좋겠다.',
          },
          {
            type: 'F',
            score: 5,
            content: '그냥 할 수 있는건 해줬으면 좋겠다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '다툼이 생긴 상황에서 나의 이상형은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '논리적으로 무엇이 잘못된 건지 말하는 사람',
          },
          {
            type: 'F',
            score: 5,
            content: '일단 감정적으로 벅차 올라 나중에 다시 얘기하자는 사람',
          },
        ],
      },
      {
        which: 'TF',
        question: '고민상담을 하는데 나의 잘못이긴하다. 그때 나의 이상형은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '나의 잘못된 점을 직접적으로 설명해주는 사람',
          },
          {
            type: 'F',
            score: 5,
            content: '직접적으로 말하면 그럴까봐 돌려서 말해주는 사람',
          },
        ],
      },
      {
        which: 'JP',
        question: '놀러 가기로 했을 때 나의 이상형은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '하루전 날 미리 준비 하고 짐을 싸두며\n필요한 것 리스트 작성하는 사람',
          },
          {
            type: 'P',
            score: 5,
            content:
              '미리 챙기지 않아 한 두개 놓고 왔지만\n웃으며 사러가자고 하는 사람',
          },
        ],
      },
      {
        which: 'JP',
        question: '여행 갔을 때 내 이상형은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '가고 싶은 곳 미리 찾아오고 찾아온 곳 방문하는 사람',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 여행 떠나자! 가서 결정하는 사람',
          },
        ],
      },
      {
        which: 'JP',
        question: '둘 중 내 이상형과 가까운 사람은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '철저한 집중력과 추진력있지만 많이 고집스러움.',
          },
          {
            type: 'P',
            score: 5,
            content: '즉흥적이고 행동력이 낮으며 계획이 없이 자유로움.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: '',
        query: 'ESTJ',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: '',
        query: 'ESTP',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: '',
        query: 'ESFJ',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: '',
        query: 'ESFP',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: '',
        query: 'ENTJ',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: '',
        query: 'ENTP',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: '',
        query: 'ENFJ',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: '',
        query: 'ENFP',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: '',
        query: 'ISTJ',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: '',
        query: 'ISTP',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: '',
        query: 'ISFJ',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: '',
        query: 'ISFP',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: '',
        query: 'INTJ',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/INTJ.png',
      },
      {
        type: 'INTP',
        desc: '',
        query: 'INTP',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/INTP.png',
      },
      {
        type: 'INFJ',
        desc: '',
        query: 'INFJ',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/INFJ.png',
      },
      {
        type: 'INFP',
        desc: '',
        query: 'INFP',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/INFP.png',
      },
    ],
  },
  // fruitAlt in English
  {
    info: {
      mainTitle: 'Fruit Alternative Character Test',
      subTitle: 'If I were a fruit, what character would I be',
      mainUrl: 'fruitAltEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/fruitAltEng-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/fruitAltEng-thumb.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'On a honey-like weekend?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'The house is so boring! I will go anywhere!',
          },
          {
            type: 'I',
            score: 5,
            content:
              'After all, I should take a rest while watching TV at home on weekends.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'The start of a new semester! I saw a guy I liked.',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Approach him/her first to say hello.',
          },
          {
            type: 'I',
            score: 5,
            content: "I'm just staring at you.",
          },
        ],
      },
      {
        which: 'EI',
        question:
          'Your friend asks you to drag you\nin front of the opposite gender\nand have a conversation.',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Laugh and have fun talking together.',
          },
          {
            type: 'I',
            score: 5,
            content: "I'm shy,\nso I just smile quietly and be awkward.",
          },
        ],
      },
      {
        which: 'SN',
        question: 'When I think of the person I like,\nwhat do I do?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Can it be done realistically?',
          },
          {
            type: 'N',
            score: 5,
            content:
              'Ha... If I date that person,\nI will do this and that too~\nOh, it makes my heart flutter!',
          },
        ],
      },
      {
        which: 'SN',
        question: 'Me in love,\nwhen I look at the world?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              "'A mountain is a mountain.\nWater is water.’\nSee it as it is.",
          },
          {
            type: 'N',
            score: 5,
            content:
              'Rather than reality,\nI spread my own world,\nmy imagination.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'During a date,\nI was hungry and found a restaurant.',
        answers: [
          {
            type: 'S',
            score: 2,
            content: "The review looks good!\nLet's go here!",
          },
          {
            type: 'N',
            score: 5,
            content: 'Oh, It smells a restaurant that looks perfect.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'What is my first reaction when I am angry?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Speak rationally about the reason for my anger.',
          },
          {
            type: 'F',
            score: 5,
            content: "I'm filled with anger and I'm crying.",
          },
        ],
      },
      {
        which: 'TF',
        question: 'The person I like is in a difficult situation.',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Propose and support realistic solutions.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Empathize with emotions and\nprovides psychological support.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'When I talk to someone I like,\nwhat do I do?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Ask questions and ask questions about him.',
          },
          {
            type: 'F',
            score: 5,
            content: 'React and empathize when he is talking to me.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Suddenly the mood Jeju Island!\nI want to go to Jeju Island!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Plan a detailed schedule for each day of the week.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'Flight ticket reservation complete!\nI think the plan is almost over.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Which one is closer to me?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I am well organized and\nfind where my things are.',
          },
          {
            type: 'P',
            score: 5,
            content:
              "I think I know where my things are,\nbut when I look for them,\nI can't find them...",
          },
        ],
      },
      {
        which: 'JP',
        question: 'I try to confess to the person you like.',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Make a thorough plan and\naim for the right time to confess.',
          },
          {
            type: 'P',
            score: 5,
            content: 'Confess promptly based on the atmosphere.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/fruitAltEng/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/fruitAltEng/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/fruitAltEng/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/fruitAltEng/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/fruitAltEng/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/fruitAltEng/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/fruitAltEng/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/fruitAltEng/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/fruitAltEng/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/fruitAltEng/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/fruitAltEng/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/fruitAltEng/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/fruitAltEng/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/fruitAltEng/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/fruitAltEng/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/fruitAltEng/INFP.png',
      },
    ],
  },
  // fruitAlt //
  {
    info: {
      mainTitle: '과일 부캐 테스트',
      subTitle: '내가 과일이라면 무슨 캐릭터일까?',
      mainUrl: 'fruitAlt',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/fruitAlt-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/fruitAlt-thumb.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '꿀 같은 주말에 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '집은 너무 심심해! 어디라도 가야겠다!',
          },
          {
            type: 'I',
            score: 5,
            content: '역시 주말엔 집에서 티비보면 쉬어야지~',
          },
        ],
      },
      {
        which: 'EI',
        question: '새 학기의 시작! 마음에 드는 이성을 봤다.',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '먼저 다가가서 인사한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '힐끔힐끔 바라만 본다.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '친구가 좋아하는 이성 앞에 끌고 가 같이 대화를 나누자고 한다.',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '같이 웃으며 즐겁게 대화한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '쑥스러워서 조용히 미소만 짓고 어색하게 있는다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '좋아하는 사람을 생각할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '현실적으로 이루어질 수 있을까?',
          },
          {
            type: 'N',
            score: 5,
            content: '하...그 사람이랑 사귀면 이것도 저것도 하고~ 아 설레네!',
          },
        ],
      },
      {
        which: 'SN',
        question: '사랑에 빠진 나, 내가 세상을 바라볼 때?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '‘산은 산이요. 물은 물이로다.’ 있는 그대로 본다.',
          },
          {
            type: 'N',
            score: 5,
            content: '현실보다는 나만의 세계, 상상의 나래를 펼친다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '데이트 중 슬슬 배가 고픈데 음식점을 발견했다.',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '리뷰 보니 괜찮다! 여기로 가자!',
          },
          {
            type: 'N',
            score: 5,
            content: '오오 보기에 딱 여긴 맛집 스멜난다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '화가 났을 때 나의 첫 반응은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '화난 이유에 대해 이성적으로 말한다.',
          },
          {
            type: 'F',
            score: 5,
            content: '분노에 차올라 눈물부터 난다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '좋아하는 사람이 힘든 상황에 처했다.',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '현실적인 해결책을 제시하고 응원한다.',
          },
          {
            type: 'F',
            score: 5,
            content: '감정을 공감하고 정신적인 지지를 해준다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '좋아하는 사람과 대화할 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '질문으로 상대방에 대해 물어보고 궁금해한다.',
          },
          {
            type: 'F',
            score: 5,
            content: '그 사람이 대화할 때 리액션하며 공감한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '갑자기 분위기 제주도! 제주도에 가야겠다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '요일별로 세부일정을 계획한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '비행기 표 예매 완료~! 계획은 거의 끝났다고 본다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '나랑 더 가까운 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '정리정돈을 잘하고 내 물건이 어딨는지 잘 찾는다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '내 물건들은 어디 있는지 잘 안다고 생각하지만, 막상 찾아보면 없다…',
          },
        ],
      },
      {
        which: 'JP',
        question: '좋아하는 사람에게 고백하려고 한다.',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '철저한 계획을 잡고 고백할 타이밍을 노린다.',
          },
          {
            type: 'P',
            score: 5,
            content: '분위기 봐서 즉흥적으로 고백한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: '',
        query: 'ESTJ',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: '',
        query: 'ESTP',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: '',
        query: 'ESFJ',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: '',
        query: 'ESFP',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: '',
        query: 'ENTJ',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: '',
        query: 'ENTP',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: '',
        query: 'ENFJ',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: '',
        query: 'ENFP',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: '',
        query: 'ISTJ',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: '',
        query: 'ISTP',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: '',
        query: 'ISFJ',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: '',
        query: 'ISFP',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: '',
        query: 'INTJ',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/INTJ.png',
      },
      {
        type: 'INTP',
        desc: '',
        query: 'INTP',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/INTP.png',
      },
      {
        type: 'INFJ',
        desc: '',
        query: 'INFJ',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/INFJ.png',
      },
      {
        type: 'INFP',
        desc: '',
        query: 'INFP',
        img_src: 'https://images.ktestone.com/resultImages/fruitAlt/INFP.png',
      },
    ],
  },
  // union //
  {
    info: {
      mainTitle: '통일 후, 나에게 맞는 여행지는?',
      subTitle: '여행 스타일로 알아보는 맞춤 여행지 추천',
      mainUrl: 'union',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/union-intro.png',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/union-thumb.png',
      lang: 'Kor',
      category: 'etc',
    },
    questions: [
      {
        which: 'EI',
        question: '여행은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구들이나 가족 등 누군가와 함께 떠나는 게 좋아!',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자 가는 여행도 나는 좋더라~',
          },
        ],
      },
      {
        which: 'EI',
        question: '숙소를 예약할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '뭔가 게스트 하우스 같은 곳에서\n새로운 사람들과 재밌게 놀고 싶지 않아?',
          },
          {
            type: 'I',
            score: 5,
            content: '우리끼리 그냥 편하고 프라이빗하게 쉴래!',
          },
        ],
      },
      {
        which: 'EI',
        question: '여행 시 길을 잃었을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '지나가는 사람이나 가게에 들어가 물어본다.',
          },
          {
            type: 'I',
            score: 5,
            content: '물어보는 친구 옆에 있는 사람이 나다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '여행 갈 때 장소를 선택하는 기준은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '리뷰가 검증되고 유명한 곳!',
          },
          {
            type: 'N',
            score: 5,
            content: '아무도 가보지 못한 새로운 장소를 찾아보자!',
          },
        ],
      },
      {
        which: 'SN',
        question: '나와 가까운 여행 스타일은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '여행가서 보고! 먹고! 놀자!',
          },
          {
            type: 'N',
            score: 5,
            content: '이번 여행 컨셉 뭐야?',
          },
        ],
      },
      {
        which: 'SN',
        question: '여행 시 맛집을 고르는 나의 기준은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '확실한 리뷰와 맛 보장이 가능한 맛집 써치 후 방문한다.',
          },
          {
            type: 'N',
            score: 5,
            content: '지나가다 보이는 현지 맛집 스러운 분위기로 가자!',
          },
        ],
      },
      {
        which: 'TF',
        question: '여행을 다녀온 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '사진 공유와 정산 후 일상으로 바로 복귀.',
          },
          {
            type: 'F',
            score: 5,
            content: '여행 사진을 보며 다음 여행을 기다리는 후유증.',
          },
        ],
      },
      {
        which: 'TF',
        question: '맛집을 찾았는데 대기 시간이 너무 길다.',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '효율적이게 다른 곳으로 가자!',
          },
          {
            type: 'F',
            score: 5,
            content: '시간이 걸려도 일단은 기다려본다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '여행지에서 멋진 건축물을 봤을 때 먼저 드는 생각은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '이걸 어떻게 지었을까 하고 생각한다.',
          },
          {
            type: 'F',
            score: 5,
            content: '와…대박이라고 하며 감탄한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '나의 여행 계획 스타일은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '나는 무조건 계획이 필요해!',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 떠나자!!',
          },
        ],
      },
      {
        which: 'JP',
        question: '여행 가기 전 나의 캐리어는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '가기 일주일 전부터 캐리어를 열어두고 하나둘 씩 챙긴다.',
          },
          {
            type: 'P',
            score: 5,
            content: '떠나기 하루 전 캐리어를 꺼내 짐을 챙긴다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '여행을 다녀온 후 나의 모습은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '나중되면 더 하기 싫으니까 미리 정리하자!',
          },
          {
            type: 'P',
            score: 5,
            content: '휴 일단 나중에 정리하고 쉬는 것까지가 여행이다!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: '',
        query: 'ESTJ',
        img_src: 'https://images.ktestone.com/resultImages/union/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: '',
        query: 'ESTP',
        img_src: 'https://images.ktestone.com/resultImages/union/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: '',
        query: 'ESFJ',
        img_src: 'https://images.ktestone.com/resultImages/union/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: '',
        query: 'ESFP',
        img_src: 'https://images.ktestone.com/resultImages/union/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: '',
        query: 'ENTJ',
        img_src: 'https://images.ktestone.com/resultImages/union/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: '',
        query: 'ENTP',
        img_src: 'https://images.ktestone.com/resultImages/union/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: '',
        query: 'ENFJ',
        img_src: 'https://images.ktestone.com/resultImages/union/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: '',
        query: 'ENFP',
        img_src: 'https://images.ktestone.com/resultImages/union/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: '',
        query: 'ISTJ',
        img_src: 'https://images.ktestone.com/resultImages/union/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: '',
        query: 'ISTP',
        img_src: 'https://images.ktestone.com/resultImages/union/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: '',
        query: 'ISFJ',
        img_src: 'https://images.ktestone.com/resultImages/union/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: '',
        query: 'ISFP',
        img_src: 'https://images.ktestone.com/resultImages/union/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: '',
        query: 'INTJ',
        img_src: 'https://images.ktestone.com/resultImages/union/INTJ.png',
      },
      {
        type: 'INTP',
        desc: '',
        query: 'INTP',
        img_src: 'https://images.ktestone.com/resultImages/union/INTP.png',
      },
      {
        type: 'INFJ',
        desc: '',
        query: 'INFJ',
        img_src: 'https://images.ktestone.com/resultImages/union/INFJ.png',
      },
      {
        type: 'INFP',
        desc: '',
        query: 'INFP',
        img_src: 'https://images.ktestone.com/resultImages/union/INFP.png',
      },
    ],
  },
  // facialExpressionAnalyzerCN
  {
    info: {
      mainTitle: '表情分析仪',
      subTitle: '狗，猫，人人都能做地表情分析仪',
      mainUrl: 'facialExpressionAnalyzerCN',
      scoreType: 'facialExpression',
      mainImage:
        'https://images.ktestone.com/introImages/facialExpressionAnalyzerCN-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/facialExpressionAnalyzerCN-thumb.png',
      lang: 'CN',
      category: 'etc',
    },
    questions: [
      {
        question: '데이트 전 날 나의 모습은?',
        answers: [
          {
            type: '포메라니안',
            score: 2,
            content: '나가기 직전 마음에 드는 옷을 입는다.',
          },
          {
            type: '치와와',
            score: 5,
            content: '자기 전 머릿속으로 나의 스타일링을 생각하고 잔다.',
          },
          {
            type: '비글',
            score: 10,
            content: '전 날 머리부터 발끝까지 스타일링과 옷을 미리 준비한다.',
          },
        ],
      },
      {
        question: '갑자기 자취방에 놀러 온다는 애인!\n그때의 나는?',
        answers: [
          {
            type: '포메라니안',
            score: 2,
            content: '아아... 왜 갑자기 오는 거야?! 보이는 곳만 치워 둔다.',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '모아둔 쓰레기를 버리고 바로 청소를 시작한다.',
          },
          {
            type: '리트리버',
            score: 10,
            content: '평소 미리미리 해 두었기 때문에 바로 주소를 보낸다.',
          },
        ],
      },
      {
        question: '좋아하는 이성이 생겼을 때 나는?',
        answers: [
          {
            type: '치와와',
            score: 2,
            content: '상대의 SNS을 탐방하며, 미래의 우리 모습을 상상해본다.',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '좋아하는 이성의 친구에게 도움을 요청한다.',
          },
          {
            type: '리트리버',
            score: 10,
            content: '먼저 연락할 거리를 만들며 적극적으로 대시한다.',
          },
        ],
      },
      {
        question:
          '카페 안에 이상형인 사람과 계속 눈이 마주친다.\n 눈웃음까지 보내는데...?',
        answers: [
          {
            type: '비글',
            score: 2,
            content: '지금 말하면 좋은 말이 안 나올 것 같아. 좀 이따 연락할게.',
          },
          {
            type: '리트리버',
            score: 5,
            content: '아!!!!!!!짜증나!!!!!진짜!!!!!! 도대체 왜 그러는 거야?!?!',
          },
          {
            type: '포메라니안',
            score: 10,
            content:
              '이런 점에서 나는 화가 난 거야. 그런 부분은 고쳐줬으면 좋겠어.',
          },
        ],
      },
      {
        question:
          '쉬고 있는데 갑자기 걸려온 남사친/여사친의 전화, \n친구들이 같이 놀고 싶다며 나올 수 있냐고 물어보는데...?',
        answers: [
          {
            type: '리트리버',
            score: 2,
            content:
              '귀찮은데… 아쉽긴 하지만 “시간이 너무 늦었어… 나 집에서 쉴래!”',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '일단 끊고 수십 번 고민 끝에 나가기로 한다.',
          },
          {
            type: '비글',
            score: 10,
            content: '오케이~ 인연 한 번 만들어보자~ “갈게~~~~”',
          },
        ],
      },
      {
        question: '다가온 기념일! 나는 어떤 선물을 준비할까?',
        answers: [
          {
            type: '포메라니안',
            score: 2,
            content: '정성 어린 편지와 기억에 남을 만한 선물을 준비한다.',
          },
          {
            type: '비글',
            score: 5,
            content: '커플로 착용할 수 있는 아이템을 선물한다.',
          },
          {
            type: '리트리버',
            score: 10,
            content:
              '평소 사고 싶다거나 필요한 물건을 기억해 두었다가 서프라이즈로 선물한다.',
          },
        ],
      },
      {
        question: '내가 하고 싶은 연애 스타일은?',
        answers: [
          {
            type: '비글',
            score: 2,
            content:
              '다이나믹한 화려한 연애 (단, 너무 화려해서 바람 피울 가능성 50%)',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '나만 바라보는 연애 (단, 너무 좋아해서 집착 대마왕)',
          },
          {
            type: '포메라니안',
            score: 10,
            content: '친구같이 편안한 연애 (단, 가끔 친구인지 애인인지 헷갈림)',
          },
        ],
      },
      {
        question: '애인과 대화할 때 나는?',
        answers: [
          {
            type: '치와와',
            score: 5,
            content: '장난치고 티키타카가 잘 되는 대화가 좋다.',
          },
          {
            type: '리트리버',
            score: 10,
            content: '서로의 생각을 공유하는 대화가 너무 좋다.',
          },
        ],
      },
      {
        question: '친구가 나에게 고민상당을 한다. 나의 반응은?',
        answers: [
          {
            type: '포메라니안',
            score: 5,
            content: '고민에 대한 구체적인 해결책을 제시한다.',
          },
          {
            type: '웰시코기',
            score: 10,
            content: '고민에 대해 빠져들어 감정적으로 같이 공감해준다.',
          },
        ],
      },
      {
        question:
          '기다려왔던 짝사랑하던 그/그녀와의 데이트 날..\n갑자기 급한 일이 생겼다며 다음에 만나자고 한다.\n과연 나의 대답은?',
        answers: [
          {
            type: '비글',
            score: 2,
            content: '어쩔 수 없지… 알겠어 다음에 보자!',
          },
          {
            type: '치와와',
            score: 5,
            content: '알겠어…근데 심각한 일이야?',
          },
          {
            type: '웰시코기',
            score: 10,
            content: '아 진짜? 그럼 날짜 다시 조정하자! 언제가 괜찮아?',
          },
        ],
      },
    ],
    results: [
      {
        type: 'human',
        desc: '',
        query: 'human1',
        comment: '能抱抱我吗？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human2',
        comment: '能不能在我眼前消失？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human3',
        comment: '主谋 给我一杯水吧',
      },
      {
        type: 'human',
        desc: '',
        query: 'human4',
        comment: '今天要和我在一起吗？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human5',
        comment: '看准我的眼睛。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human6',
        comment: '想休息~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human7',
        comment: '明天不想出去工作',
      },
      {
        type: 'human',
        desc: '',
        query: 'human8',
        comment: '是单身还是单身的第年，我是谁？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human9',
        comment: '我是谁 这里是哪里',
      },
      {
        type: 'human',
        desc: '',
        query: 'human10',
        comment: '给我交个朋友吧...',
      },
      {
        type: 'human',
        desc: '',
        query: 'human11',
        comment: '人生还是一个人',
      },
      {
        type: 'human',
        desc: '',
        query: 'human12',
        comment: '_好孤独啊',
      },
      {
        type: 'human',
        desc: '',
        query: 'human13',
        comment: '是想要中彩票的天气',
      },
      {
        type: 'human',
        desc: '',
        query: 'human14',
        comment: '神啊，为什么让我出生在这个地球上呢？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human15',
        comment: '明天打算辞职。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human16',
        comment: '想一整天躺着玩手机',
      },
      {
        type: 'human',
        desc: '',
        query: 'human17',
        comment: '果然今晚要吃炒年糕',
      },
      {
        type: 'human',
        desc: '',
        query: 'human18',
        comment: '想去夏威夷旅行',
      },
      {
        type: 'human',
        desc: '',
        query: 'human19',
        comment: '我放的股票涨停吧！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human20',
        comment: '想成为有钱的无业游民',
      },
      {
        type: 'human',
        desc: '',
        query: 'human21',
        comment: '音乐是我唯一允许的毒品',
      },
      {
        type: 'human',
        desc: '',
        query: 'human22',
        comment: '我是个发财相',
      },
      {
        type: 'human',
        desc: '',
        query: 'human23',
        comment: '想去济州岛',
      },
      {
        type: 'human',
        desc: '',
        query: 'human24',
        comment: '别减肥了，想吃牛皱胃！！！！！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human25',
        comment: '别看我',
      },
      {
        type: 'human',
        desc: '',
        query: 'human26',
        comment: '能借我点钱吗？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human27',
        comment: '啊...年前比特币该买的...得买才行',
      },
      {
        type: 'human',
        desc: '',
        query: 'human28',
        comment: '中彩票的话 能给我一半吗？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human29',
        comment: '别跟我说话',
      },
      {
        type: 'human',
        desc: '',
        query: 'human30',
        comment: '你很快就会陷入我的魅力中^^',
      },
      {
        type: 'human',
        desc: '',
        query: 'human31',
        comment: '啊哈~ 就这样待在家里吗？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human32',
        comment: '去看电影吧~ 猜拳买电影票怎么样？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human33',
        comment: '家常饭都吃腻了~ 要不要出去吃好吃的？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human34',
        comment: '慢跑去吧! 你得运动一下了',
      },
      {
        type: 'human',
        desc: '',
        query: 'human35',
        comment: '感觉你迟早会像球一样滚来滚去 来! 得去运动了',
      },
      {
        type: 'human',
        desc: '',
        query: 'human36',
        comment: '看你的脖子, 乌龟要搭话了, 出去散散步吧',
      },
      {
        type: 'human',
        desc: '',
        query: 'human37',
        comment: '那边好像有好吃的了~ 上次是我买的 今天你买吧',
      },
      {
        type: 'human',
        desc: '',
        query: 'human38',
        comment: '去兜风吧！ 现在！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human39',
        comment: '我们去旅行吗？ 突然没有计划想去旅行',
      },
      {
        type: 'human',
        desc: '',
        query: 'human40',
        comment: '今天只待在家里 厌倦了~ 我要出去一下',
      },
      {
        type: 'human',
        desc: '',
        query: 'human41',
        comment: '现在几点了？啊 对了 今天是你请我吃好吃的日子！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human42',
        comment: '今天是星期几？ 啊 对了 我们说好今天打保龄球的',
      },
      {
        type: 'human',
        desc: '',
        query: 'human43',
        comment: '今天是几号？ 啊 对了 我们今天要去咖啡店玩Bingo',
      },
      {
        type: 'human',
        desc: '',
        query: 'human44',
        comment: '我们今天有约了 对！你说过要请我吃肉！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human45',
        comment: '现在出来~! 我在前面等你 到你来为止',
      },
      {
        type: 'human',
        desc: '',
        query: 'human46',
        comment: '今天我身体不好，生病了，给我送点吃的吧。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human47',
        comment: '我的腿疼 背我吧',
      },
      {
        type: 'human',
        desc: '',
        query: 'human48',
        comment: '我今天跟爱人有约 抱歉',
      },
      {
        type: 'human',
        desc: '',
        query: 'human49',
        comment: '你昨天跟谁在一起？ 我昨天看到你跟别人在一起了',
      },
      {
        type: 'human',
        desc: '',
        query: 'human50',
        comment: '他是谁？ 昨天不是牵着手走嘛',
      },
      {
        type: 'human',
        desc: '',
        query: 'human51',
        comment: '我昨晚和那个人一起去散步了 太...很好。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human52',
        comment: '我昨天和那个人散步了个小时',
      },
      {
        type: 'human',
        desc: '',
        query: 'human53',
        comment: '到此为止吧。 更..太勉强了',
      },
      {
        type: 'human',
        desc: '',
        query: 'human54',
        comment:
          '我都厌倦了写这句台词，无话可说，我不想再说了（嘘！希望社长不知道）',
      },
      {
        type: 'human',
        desc: '',
        query: 'human55',
        comment: '昨天去散步的时候发现了一个包，有亿...',
      },
      {
        type: 'human',
        desc: '',
        query: 'human56',
        comment: '昨天我去警察局了。 吁',
      },
      {
        type: 'human',
        desc: '',
        query: 'human57',
        comment: '昨天我..目击了杀人事件!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human58',
        comment: '今天做什么？和我...要约会吗？脚',
      },
      {
        type: 'human',
        desc: '',
        query: 'human59',
        comment: '对不起..我有了爱的人.今天见那个人',
      },
      {
        type: 'human',
        desc: '',
        query: 'human60',
        comment: '今天我特请。 准备愉快地玩吧。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human61',
        comment: '哦~ 我的心情就像新罗~~~~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human62',
        comment: '烦死了，求你走开。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human63',
        comment: '好孤独啊..霍格沃茨年级',
      },
      {
        type: 'human',
        desc: '',
        query: 'human64',
        comment: '所谓林生，就是疲惫不堪',
      },
      {
        type: 'human',
        desc: '',
        query: 'human65',
        comment: '因为心情不好，要不要喝一杯？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human66',
        comment: '为什么这么烦我？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human67',
        comment: '忧愁冲天',
      },
      {
        type: 'human',
        desc: '',
        query: 'human68',
        comment: '我爱你 李子硕啊',
      },
      {
        type: 'human',
        desc: '',
        query: 'human69',
        comment: '嘿！让我主持吧~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human70',
        comment: '我现在~~~~今晚~~~害怕黑暗~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human71',
        comment: '哎哟…感觉要吐了？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human72',
        comment: '哎呦 太无趣了 太无趣了',
      },
      {
        type: 'human',
        desc: '',
        query: 'human73',
        comment: '心情郁闷！ 没有什么有趣的吗？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human74',
        comment: '我今天想哭 拥抱我吧',
      },
      {
        type: 'human',
        desc: '',
        query: 'human75',
        comment: '一把手',
      },
      {
        type: 'human',
        desc: '',
        query: 'human76',
        comment: '饭吃得多吗？ 怪不得看起来那样。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human77',
        comment: '正在经历忍耐的极限',
      },
      {
        type: 'human',
        desc: '',
        query: 'human78',
        comment: '我想你。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human79',
        comment: '现在在想心爱的人。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human80',
        comment: '一想到今天也辛苦的我准备的炸鸡就激动不已！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human81',
        comment: '子道者也不解乏…',
      },
      {
        type: 'human',
        desc: '',
        query: 'human82',
        comment: '给我导航吧！ 老实呆着也很累的人！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human83',
        comment: '哦~ 不简单啊！？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human84',
        comment: '现在累了吗？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human85',
        comment: '悄然流出的叹息',
      },
      {
        type: 'human',
        desc: '',
        query: 'human86',
        comment: '彼此…珍爱…爱…',
      },
      {
        type: 'human',
        desc: '',
        query: 'human87',
        comment: '朋友们啊…谢谢…',
      },
      {
        type: 'human',
        desc: '',
        query: 'human88',
        comment: '不不不…不…不…眼泪…掉下来了…^^',
      },
      {
        type: 'human',
        desc: '',
        query: 'human89',
        comment: '不要说别说的话',
      },
      {
        type: 'human',
        desc: '',
        query: 'human90',
        comment: '呼~~~~派对啊~~~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human91',
        comment: '没有任何想法。 因为没有任何想法。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human92',
        comment: '我为什么在这里…',
      },
      {
        type: 'human',
        desc: '',
        query: 'human93',
        comment: '想回家…',
      },
      {
        type: 'human',
        desc: '',
        query: 'human94',
        comment: '开心吗..?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human95',
        comment: '哎呦 没意义',
      },
      {
        type: 'human',
        desc: '',
        query: 'human96',
        comment: '吃的时候不要碰',
      },
      {
        type: 'human',
        desc: '',
        query: 'human97',
        comment: '不值得听 哈 再见',
      },
      {
        type: 'human',
        desc: '',
        query: 'human98',
        comment: '怎么办...？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human99',
        comment: '真烦人。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human100',
        comment: '哎哟…不想做…',
      },
      {
        type: 'human',
        desc: '',
        query: 'human101',
        comment: '知道什么…我走我的路…',
      },
      {
        type: 'human',
        desc: '',
        query: 'human102',
        comment: '我听不见~ 听不见~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human103',
        comment: '我不出门，只待在家里！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human104',
        comment: '不要对我期待',
      },
      {
        type: 'human',
        desc: '',
        query: 'human105',
        comment: '血压上升中',
      },
      {
        type: 'human',
        desc: '',
        query: 'human106',
        comment: '我怎么了',
      },
      {
        type: 'human',
        desc: '',
        query: 'human107',
        comment: '想一个人待着',
      },
      {
        type: 'human',
        desc: '',
        query: 'human108',
        comment: '一整天都想躺着',
      },
      {
        type: 'human',
        desc: '',
        query: 'human109',
        comment: '好吧（麻烦）',
      },
      {
        type: 'human',
        desc: '',
        query: 'human110',
        comment: '人生本来就是随随便便的。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human111',
        comment: '反正我就是宇宙尘埃的存在…',
      },
      {
        type: 'human',
        desc: '',
        query: 'human112',
        comment: '坐卧人心',
      },
      {
        type: 'human',
        desc: '',
        query: 'human113',
        comment: '不满意',
      },
      {
        type: 'human',
        desc: '',
        query: 'human114',
        comment: '丧失生活欲望',
      },
      {
        type: 'human',
        desc: '',
        query: 'human115',
        comment: '好想中彩票啊...',
      },
      {
        type: 'human',
        desc: '',
        query: 'human116',
        comment: '今天就做到这里吧.明天的我来吧',
      },
      {
        type: 'human',
        desc: '',
        query: 'human117',
        comment: '那有什么意义呢~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human118',
        comment: '厌倦~~厌倦~~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human119',
        comment: '有时也会这样。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human120',
        comment: '还是放弃吧^_^',
      },
      {
        type: 'human',
        desc: '',
        query: 'human121',
        comment: '来了？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human122',
        comment: '啊，再见..!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human123',
        comment: '好好过吧！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human124',
        comment: '呀~ 身体变好了啊？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human125',
        comment: '真的好久不见！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human126',
        comment: '早上好！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human127',
        comment: '什么时候吃饭吧~ (礼貌的话)',
      },
      {
        type: 'human',
        desc: '',
        query: 'human128',
        comment: '喂 我走了！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human129',
        comment: '嗨！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human130',
        comment: '喂！是这里！这里！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human131',
        comment: '我们什么时候见一次面吧~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human132',
        comment: '（在路上遇到的概率更高）',
      },
      {
        type: 'human',
        desc: '',
        query: 'human133',
        comment: '喂！！！！！你是谁？？？！！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human134',
        comment: '(好久不见 很高兴见到大家 但是没什么可说的',
      },
      {
        type: 'human',
        desc: '',
        query: 'human135',
        comment: '尖叫着）',
      },
      {
        type: 'human',
        desc: '',
        query: 'human136',
        comment: '我先走了！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human137',
        comment: '要喝一杯吧？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human138',
        comment: '女！~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human139',
        comment: '你好像瘦了？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human140',
        comment: '晚安♥',
      },
      {
        type: 'human',
        desc: '',
        query: 'human141',
        comment: '再见..><',
      },
      {
        type: 'human',
        desc: '',
        query: 'human142',
        comment: '过得好吗？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human143',
        comment: '现在几点了？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human144',
        comment: '辛苦了！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human145',
        comment: '肚子饿！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human146',
        comment: '走的时候小心点！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human147',
        comment: '走好！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human148',
        comment: '吃饭了吗?..',
      },
      {
        type: 'human',
        desc: '',
        query: 'human149',
        comment: '要去哪里？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human150',
        comment: '喂 对不起 对不起 迟到了...',
      },
      {
        type: 'human',
        desc: '',
        query: 'human151',
        comment: '要做什么？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human152',
        comment: '哦莫 变漂亮了！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human153',
        comment: '我今天没什么变化吗？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human154',
        comment: 'Y氏 这是真的吗？我要洗漱吗？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human155',
        comment: '今天果断地刷牙传球。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human156',
        comment: '为什么要洗脸？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human157',
        comment: 'Take 呃 要洗澡啊 干爽干爽~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human158',
        comment: '我洗漱的时候先洗头 那个很好',
      },
      {
        type: 'human',
        desc: '',
        query: 'human159',
        comment: '夏天也用热水洗澡的我是辣酱~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human160',
        comment: '如果真的有清洗的机器，就先买股票。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human161',
        comment: '哇 今天太懒得洗漱了',
      },
      {
        type: 'human',
        desc: '',
        query: 'human162',
        comment: '能帮我洗洗吗？^^',
      },
      {
        type: 'human',
        desc: '',
        query: 'human163',
        comment: '水温怎么样？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human164',
        comment: '没有入浴剂就不洗澡。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human165',
        comment: '洗完澡，香蕉牛奶是国规',
      },
      {
        type: 'human',
        desc: '',
        query: 'human166',
        comment: '今天就干爽地走吧~~~~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human167',
        comment: '洗完澡后很困吗~啊~ 是啤酒！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human168',
        comment: '待会儿要洗漱 现在已经是凌晨了...ee',
      },
      {
        type: 'human',
        desc: '',
        query: 'human169',
        comment: '洗完澡照镜子！ 这程度就不错了。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human170',
        comment: '洗完澡每次都会有不同的人登场！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human171',
        comment: '不洗了…昨天洗过了…',
      },
      {
        type: 'human',
        desc: '',
        query: 'human172',
        comment: '最小洁癖...',
      },
      {
        type: 'human',
        desc: '',
        query: 'human173',
        comment: '使用洗发水护发素护发素沐浴露磨砂膏面膜。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human174',
        comment: '你身上总有一股清香的味道。 你洗了吗？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human175',
        comment: '你身上总是有屁味儿。 你没洗吗？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human176',
        comment: '还是洗洗吧。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human177',
        comment: '从头到脚~ 要洗干净~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human178',
        comment: '外出后洗手和洗脚',
      },
      {
        type: 'human',
        desc: '',
        query: 'human179',
        comment: '连指甲也干净地！ 清澈地！自信地~！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human180',
        comment: '不要漱口，要刷牙。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human181',
        comment: '嘴里发出那种味道就是要洗漱的意思',
      },
      {
        type: 'human',
        desc: '',
        query: 'human182',
        comment: '你是不是没有鼻子？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human183',
        comment: '闻到了~味道！ 飞起来了！~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human184',
        comment: '肚子饿，能给我点饭吗？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human185',
        comment: 'OO啊 汤有点咸',
      },
      {
        type: 'human',
        desc: '',
        query: 'human186',
        comment: '这是米饭吗？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human187',
        comment: '今天的菜是什么？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human188',
        comment: '我是素食主义者！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human189',
        comment: '没有肉就不吃饭！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human190',
        comment: '速食是最棒的！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human191',
        comment: '啊~肚子好饱~~~~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human192',
        comment: '讨厌蔬菜！！！！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human193',
        comment: '量太少了！ 再给我一点！！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human194',
        comment: '太多了~ 盛出来吧！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human195',
        comment: '咕噜噜...',
      },
      {
        type: 'human',
        desc: '',
        query: 'human196',
        comment: '今天是炒年糕！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human197',
        comment: '想要甜甜的~~~~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human198',
        comment: '太热了，吃冰淇淋怎么样？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human199',
        comment: '好冷...我想喝热汤！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human200',
        comment: '没胃口..我不吃了。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human201',
        comment: '糖稀中！！ 从我眼前收拾一下菜！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human202',
        comment: '尝一口~！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human203',
        comment: '什么呀~？超级好吃！！ JMT',
      },
      {
        type: 'human',
        desc: '',
        query: 'human204',
        comment: '这个也叫料理吗？ 不好吃 呸！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human205',
        comment: '来一杯凉爽的水吧~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human206',
        comment: '想去美食店旅游！ 出去吧~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human207',
        comment: '夜宵好~？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human208',
        comment: '猪蹄怎么样？大发吧~~？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human209',
        comment: '吃的时候连狗都不碰',
      },
      {
        type: 'human',
        desc: '',
        query: 'human210',
        comment: '请拿出八道套餐！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human211',
        comment: '请我吃牛肉吧。哦哦哦哦~~！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human212',
        comment: '为什么吃那个？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human213',
        comment: '别太担心，一切都会好起来的！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human214',
        comment: '过去的事就过去了~忘了',
      },
      {
        type: 'human',
        desc: '',
        query: 'human215',
        comment: '累的时候去散步怎么样？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human216',
        comment: '今天一天也辛苦了！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human217',
        comment: '悲伤的话可以依靠我',
      },
      {
        type: 'human',
        desc: '',
        query: 'human218',
        comment: '累的时候时间就是良药。 这也会成为过去!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human219',
        comment: '你身边有我。 加油吧！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human220',
        comment: '想哭的时候哭也没关系',
      },
      {
        type: 'human',
        desc: '',
        query: 'human221',
        comment: '希望我能给你带来安慰。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human222',
        comment: '不管谁说什么 你是最棒的',
      },
      {
        type: 'human',
        desc: '',
        query: 'human223',
        comment: '想想那些疼爱你的人吧！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human224',
        comment: '吃好吃的东西心情会好一点的！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human225',
        comment: '有什么事吗？我都听你的',
      },
      {
        type: 'human',
        desc: '',
        query: 'human226',
        comment: '是谁让你累的？ 带过来！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human227',
        comment: '你的存在本身就很耀眼',
      },
      {
        type: 'human',
        desc: '',
        query: 'human228',
        comment: '我会一直为你加油的 明天也加油！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human229',
        comment: '对我来说你是最棒的。 最珍贵',
      },
      {
        type: 'human',
        desc: '',
        query: 'human230',
        comment: '振作起来 你已经是帅气的人了',
      },
      {
        type: 'human',
        desc: '',
        query: 'human231',
        comment: '想哭的时候哭也没关系',
      },
      {
        type: 'human',
        desc: '',
        query: 'human232',
        comment: '不用跟别人比。 你本身就很耀眼',
      },
      {
        type: 'human',
        desc: '',
        query: 'human233',
        comment: '你也是对某些人来说很珍贵的人啊',
      },
      {
        type: 'human',
        desc: '',
        query: 'human234',
        comment: '今天也辛苦了。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human235',
        comment: '明天也会为你加油的',
      },
      {
        type: 'human',
        desc: '',
        query: 'human236',
        comment: '谢谢你一直以来的努力',
      },
      {
        type: 'human',
        desc: '',
        query: 'human237',
        comment: '别担心 做得够好了！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human238',
        comment: '没关系 只要像现在这样就行',
      },
      {
        type: 'human',
        desc: '',
        query: 'human239',
        comment: '忧郁的话去吃好吃的怎么样？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human240',
        comment: '心情郁闷的时候？ 去旅行吧~！',
      },
      {
        type: 'human',
        desc: '',
        query: 'human241',
        comment: '有时也需要拥有属于我的时间',
      },
      {
        type: 'human',
        desc: '',
        query: 'human242',
        comment: '幸福是从细微处寻找的',
      },
      {
        type: 'human',
        desc: '',
        query: 'human243',
        comment: '已经天没拉屎了，好累啊。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human244',
        comment: '哈..我的粪便味道太重了 我要带着喷雾器出门了。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human245',
        comment: '上次没上厕所 谁也不知道吧？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human246',
        comment: '早上起来拉屎就是幸福。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human247',
        comment: '现在不会把我当成了臭小子吧？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human248',
        comment: '我可以憋尿个小时。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human249',
        comment: '一吃蔬菜就会出来绿色的屎 很神奇吧？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human250',
        comment: '想拉屎。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human251',
        comment: '尿急。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human252',
        comment: '"这是忍到屎哗哗出来为止，然后拉下屎的人的表情。"',
      },
      {
        type: 'human',
        desc: '',
        query: 'human253',
        comment: '偶尔对不起我的心脏。 只送了太重的食物',
      },
      {
        type: 'human',
        desc: '',
        query: 'human254',
        comment: '喂 我真的忍不住了 上厕所吧',
      },
      {
        type: 'human',
        desc: '',
        query: 'human255',
        comment: '洗手间在哪里？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human256',
        comment: '我想念普世式卫生间 给力很方便',
      },
      {
        type: 'human',
        desc: '',
        query: 'human257',
        comment: '啊.昨天吃错什么了吗 肚子怎么这么疼',
      },
      {
        type: 'human',
        desc: '',
        query: 'human258',
        comment: '为什么腹泻时肚子疼？',
      },
      {
        type: 'human',
        desc: '',
        query: 'human259',
        comment: '听说以前有人在床上拉屎的时候会感到快感，那就是我。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human260',
        comment: '卫生间舒适的话心情会很好。',
      },
      {
        type: 'human',
        desc: '',
        query: 'human261',
        comment: '不管建筑多好，厕所脏的话，首先会感到烦躁动起来',
      },
      {
        type: 'human',
        desc: '',
        query: 'human262',
        comment: '我昨天尿床了。',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat1',
        comment: '把零食拿过来',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat2',
        comment: '不要碰我好吗？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat3',
        comment: '就让我一个人呆着吧',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat4',
        comment: '铲屎官别看别的猫！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat5',
        comment: '管家只看着我 只爱我 只照顾我',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat6',
        comment: '安静点',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat7',
        comment: '我最讨厌吵闹的了',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat8',
        comment: '想和我沟通的话 眨眼',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat9',
        comment: '好想和管家搓澡啊！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat10',
        comment: '只有在我允许的时候 才能触摸我',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat11',
        comment: '给我带点鱼吧',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat12',
        comment: '回家的时候把箱子拿过来吧',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat13',
        comment: '想一个人进塑料袋里待着',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat14',
        comment: '现在碰的话等着瞧吧！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat15',
        comment: '要不要挨打？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat16',
        comment: '把玩具拿来！ 跟我玩吧！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat17',
        comment: '我好无聊！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat18',
        comment: '困了就别管了 睡觉吧',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat19',
        comment: '伊洛瓦巴',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat20',
        comment: '能去那边吗？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat21',
        comment: '适当的温度 湿度 一切都喜欢 很舒服',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat22',
        comment: '能摸到我的氛围是下巴下面。 末梢',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat23',
        comment: '玩具拜托了 各种各样',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat24',
        comment: '我的脚是非常可爱的粉色Style ^>^',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat25',
        comment: '好好地过来吧',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat26',
        comment: '能买点新品吗？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat27',
        comment: '知道困了还怕自己的心情吗？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat28',
        comment: '铲屎官最近很满意了',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat29',
        comment: '没有什么有趣的事情吗？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat30',
        comment: '我可以用胡须统治这个世界',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat31',
        comment: '你不知道昨晚发生了什么事吧？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat32',
        comment: '你知道我不去散步吧？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat33',
        comment: '稍微散去的小狗…… 我是一只伟大的猫',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat34',
        comment: '伟大的猫咪不去散步',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat35',
        comment: '伟大的猫咪不会被牵着鼻子走',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat36',
        comment: '如果出去的话，就抱着我出去吧',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat37',
        comment: '如果知道漂亮的猫咪就给我介绍吧',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat38',
        comment: '饿了, 给我吃的',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat39',
        comment: '今天想休息, 别惹我',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat40',
        comment: '不想走太久。 我可爱的软糖会磨损的',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat41',
        comment: '我喜欢风凉飕飕的日子。 今天是那个时候',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat42',
        comment: '抱我的时候小心点儿，用两只手啊！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat43',
        comment: '踩土心情不好啊 拥抱我吧',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat44',
        comment: '柏油马路硬邦邦的, 不好受啊, 抱我',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat45',
        comment: '不要让阳光太强烈的日子出去, 保护我的皮肤',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat46',
        comment: '今天有点累啊，快来吃饭吧~',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat47',
        comment: '今天是毛团儿的一天啊, 梳理我的毛吧',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat48',
        comment: '外边怎么这样吵嚷嚷的, 去个安静的地方吧',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat49',
        comment: '哪天出去吃顿饭吧，吃腻了。',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat50',
        comment: '这阵子老不见老鼠了, 你出去找一找吧',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat51',
        comment: '啊，那是什么！ 好机灵啊！ 拦住他！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat52',
        comment: '今天不知为何是跳跃的好日子啊',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat53',
        comment: '原来想抓机灵鬼啊~ 今天是猎人日！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat54',
        comment: '捉老鼠 捉老鼠 吱吱',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat55',
        comment: '读过我的眼神吗！ 今天想要享受悠闲啊',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat56',
        comment: '我的动作像蜜蜂一样快，我的毛像棉花一样柔软！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat57',
        comment: '今天太有意思了 现在回家吧',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat58',
        comment: '好的 好的 出去吧！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat59',
        comment: '这阵子老呆在家里, 身上发闷了!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat60',
        comment: '感觉我变胖了吗？ 我们散散步吧？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat61',
        comment: '等一下 这是什么？ 我好像发现了什么',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat62',
        comment: '哇 今天怎么这么开心？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat63',
        comment: '哎一古 自盗者也困吗',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat64',
        comment: '什么都不想做啊',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat65',
        comment: '我有点敏感 你点钱吧',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat66',
        comment: '嘿嘿嘿嘿！心情都不好，给点零食吧',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat67',
        comment: '嗯？那是什么？ 很好奇。',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat68',
        comment: '为什么总是看我啊',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat69',
        comment: '那是什么?! 太可怕了',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat70',
        comment: '没关系吗？为什么这样？累吗？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat71',
        comment: '哇 太害怕了 瑟瑟发抖 真是的',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat72',
        comment: '我现在很集中 你说说看',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat73',
        comment: '不要让我执着~？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat74',
        comment: '如果可以的话，可以打一拳吗？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat75',
        comment: '极度的兴奋状态',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat76',
        comment: '是啊 是啊~ 我相信你~',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat77',
        comment: '没有什么有趣的吗？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat78',
        comment: '他为什么又这样？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat79',
        comment: '嘿嘿嘿嘿 心情真好！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat80',
        comment: '因为心情好 所以给你一个摸我的肚子的机会',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat81',
        comment: '我真的很喜欢你',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat82',
        comment: '一直很感谢你！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat83',
        comment: '无聊，可以和我玩吗？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat84',
        comment: '已经厌倦那个了！ 买新玩具吧！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat85',
        comment: '我玩得开心 呃呃呃呃！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat86',
        comment: '能喜欢我吗？ 不是请求而是命令！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat87',
        comment: '我很讨厌惹我。 我要一个人待着',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat88',
        comment: '你抱抱我！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat89',
        comment: '不理解你的话 你总是一个人吃不理解你吗？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat90',
        comment: '少喝点酒吧 人类~',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat91',
        comment: '你对我睡觉有什么不满吗？ 那就好好珍藏吧',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat92',
        comment: '管家。我现在很想念。 粪便',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat93',
        comment: '如果不马上把沙子磨成新的，我会把鞋柜弄乱的。',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat94',
        comment: '我身上要冒出一个巨大的东西。',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat95',
        comment: '我想小便，但我不想。',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat96',
        comment: '我经常想，卫生间不是我喜欢的类型。',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat97',
        comment: '对不起，上次我把尿泡在地上了。',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat98',
        comment: '刚才吃太多了吗？ 市场震荡。',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat99',
        comment: '管家。我地便气味有花香，你同意吗？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat100',
        comment: '快辩总是对的。',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat101',
        comment: '帮我换一下卫生间的位置。 向阳光照射的位置。',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat102',
        comment: '我毛里好像沾了尿 帮我确认一下。',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat103',
        comment: '换一下饲料怎么样？管家。 消化不好。',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat104',
        comment: '快到消化结束的时间了。 洗手间应该很干净吧？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat105',
        comment: '我有时管家睡着的时候想尿床。',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat106',
        comment: '我拉屎的时候总是要播放古典音乐。',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat107',
        comment: '为什么管家的卫生间和我的卫生间是不同的？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat108',
        comment: '喝水为什么想撒尿？ 说明吧',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat109',
        comment: '今天的便便颜色很好（满足）',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat110',
        comment: '管家，我拉完屎后，总是擦我的屁股。',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat111',
        comment: '确认一下我的粪便 确认一下我的健康 管家',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat112',
        comment: '别惹我...',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat113',
        comment: '啊 又开始了..',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat114',
        comment: '看什么呢',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat115',
        comment: '你现在叫我了吗？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat116',
        comment: '还是吃这个吧。 两重拳',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat117',
        comment: '别再拍照了。',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat118',
        comment: '无趣..没意思',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat119',
        comment: '你摸摸我',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat120',
        comment: '这些就够了吧？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat121',
        comment: '需要一个人的时间.别管了。',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat122',
        comment: '不要叫我 真的不要叫我...',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat123',
        comment: '该做的都做了 想回家..',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat124',
        comment: '我可以惹你。 但是你不行^_^',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat125',
        comment: '为什么总是看我呢？ （装作不知道）',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat126',
        comment: '我给你做点什么吧',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat127',
        comment: '把这个放下来',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat128',
        comment: '直接咬掉',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat129',
        comment: '把零食拿过来！！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat130',
        comment: '脖子上的这是什么？马上拔掉！！！！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat131',
        comment: 'Wyrano.wyrano…',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat132',
        comment: '不要过来！！！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat133',
        comment: '随便过吧',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat134',
        comment: '现在把心思放在你身上会怎么样？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat135',
        comment: '哎呦 那个人...',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat136',
        comment: '都是徒劳的',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat137',
        comment: '…（无视）',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat138',
        comment: '吵…',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat139',
        comment: '是要试试看吗..',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat140',
        comment: '真烦人...',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat141',
        comment: '铲屎官啊...你想被解雇吗...',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat142',
        comment: '哼！...（无视）',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat143',
        comment: '来了? 给我个吃零食的机会。',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat144',
        comment: '胆敢丢下我去了哪里 现在才进来吗？！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat145',
        comment: '快去快回！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat146',
        comment: '去不去小心~',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat147',
        comment: '你是谁啊，对我装懂吧？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat148',
        comment: '又喝酒？不要再喝酒了！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat149',
        comment: '为什么现在才来？ 我不是无聊了嘛！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat150',
        comment: '来的时候买适合我的高水平零食~',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat151',
        comment: '你是谁？？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat152',
        comment: '我不想一个人呆在家里！ 也带我去吧！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat153',
        comment: '干什么呢？来了就得先抚摸我啊？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat154',
        comment: '哎呦~ (摇头摇头)',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat155',
        comment: '今天早点来的话，我特别陪你玩！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat156',
        comment: '哼! 我不跟初次见面的人打招呼!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat157',
        comment: '看什么站着啊 快进来！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat158',
        comment: '为什么一来就来烦我！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat159',
        comment: '.手里拿着的难道是我的？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat160',
        comment: '快点进来抱我啊！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat161',
        comment: '讨厌客人~！ 我总是烦你啊',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat162',
        comment: '哦! 是来给我零食的人吗?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat163',
        comment: '虽然很简陋 但是进来吧',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat164',
        comment: '怎么空着手呢？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat165',
        comment: '再见~ 朋友们！！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat166',
        comment: '来了?..zZ',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat167',
        comment: '你是不是早一点去？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat168',
        comment: '我什么都没做！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat169',
        comment: '快点进来给我饭 好饿！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat170',
        comment: '如果不快点进来，家里就乱套了。',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat171',
        comment: '我的管家终于来了！ 现在让我们干活吧？ 准备好了吗？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat172',
        comment: '饭都吃完了，是吗？ 把饭拿出来',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat173',
        comment: '我只喝干净的水',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat174',
        comment: '给我吧！！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat175',
        comment: '我喜欢猫膝~~',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat176',
        comment: '想吃鲣鱼脯！！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat177',
        comment: '哇！不好吃！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat178',
        comment: '换饲料吧，吃腻了',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat179',
        comment: '好热啊~ 吃点凉爽的西瓜吧？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat180',
        comment: '能给我零食吗~玩吧~？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat181',
        comment: '没胃口，以后吃。',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat182',
        comment: '什么呀~？这个超好吃！！！！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat183',
        comment: '以为你喜欢鱼吧？ 其实我喜欢肉><',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat184',
        comment: '多吃点零食 多放点饲料！OK？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat185',
        comment: '没有鱼吗？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat186',
        comment: '没有肉吗？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat187',
        comment: '今天的饭，很好吃！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat188',
        comment: '肚子好饿，给我好吃的吧！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat189',
        comment: '吃饱了！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat190',
        comment: '想吃甜甜的水果~',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat191',
        comment: '再给点！再给点！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat192',
        comment: '如果是你，你会吃这个吗？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat193',
        comment: '饲料太少了！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat194',
        comment: '一起吃饭吗？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat195',
        comment: '我吃饭了，你吃了吗？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat196',
        comment: '比起饭，当然是CHOO了！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat197',
        comment: '这次零食不怎么样~',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat198',
        comment: '今天比起零食，更想吃肉。',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat199',
        comment: '太多了，给我盛一点吧。',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat200',
        comment: '吃饱了，肚子要撑爆了~',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat201',
        comment: '快点吃完躺下吧。',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat202',
        comment: '我很干净啊 为什么要洗漱啊...？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat203',
        comment: '放手！！！会出去！！！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat204',
        comment: '水太凉了.S',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat205',
        comment: '温度适中 很满意',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat206',
        comment: '我洗漱的话 你要给我做什么？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat207',
        comment: '洗完澡 确实很凉快',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat208',
        comment: '铲屎官呀，我害怕，一定要抓着！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat209',
        comment: '尽快结束吧',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat210',
        comment: '剪脚趾甲？！ 你要尝尝脚趾甲吗？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat211',
        comment: '把每一根毛都晒得细',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat212',
        comment: '刚才我做了Grumming 又洗漱了吗？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat213',
        comment: '身体湿透了，太冷了。',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat214',
        comment: '正是洗澡的好天气啊..嘿嘿',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat215',
        comment: '洗完澡出来的话 就要做一只Chuir~',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat216',
        comment: '看到了吧？我不是胖了 而是长毛了^_^',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat217',
        comment: '不想下水！！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat218',
        comment: '好凄凉..今天好像要洗澡了!!!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat219',
        comment: '现在结尾我会看着办的',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat220',
        comment: '暖和~~',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat221',
        comment: '玩水挺有意思的',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat222',
        comment: '哦 看来今天泡沫打得不错啊~？ 很柔软啊',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat223',
        comment: '现在没有想洗漱的心情...',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat224',
        comment: '口渴了…给我点水！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat225',
        comment: '水里面有奇怪的味道啊？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat226',
        comment: '既然洗漱了，就把脚指甲也剪了吧！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat227',
        comment: '管家啊……我不会放过你的…… 哆哆嗦嗦',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat228',
        comment: '手有点粗糙...？ 轻柔地清洗',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat229',
        comment: '想快点洗漱躺下！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat230',
        comment: '好疲惫啊..乔奥塔~!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat231',
        comment: '咔啊！耳朵进水了！！！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat232',
        comment: '管家，我希望你幸福！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat233',
        comment: '你看! 我给你拿来了!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat234',
        comment: '和我一起玩的你是最棒的！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat235',
        comment: '看起来心情不好.要和我玩球吗？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat236',
        comment: '今天让给你零食！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat237',
        comment: '有什么事吗？ 我来帮你弄',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat238',
        comment: '有铲屎官我很幸福！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat239',
        comment: '希望我能成为你的力量！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat240',
        comment: '听听塑料声音 心情会变好的',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat241',
        comment: '今天我特别陪你玩！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat242',
        comment: '铲屎官 今天休息一会儿也没关系',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat243',
        comment: '想一个人呆着的话，我会让开你的！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat244',
        comment: '今天一天也辛苦了！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat245',
        comment: '忧郁的话 就像我一样摇尾巴吧~',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat246',
        comment: '今天允许你摸肚子！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat247',
        comment: '有我在，你会忧郁吗？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat248',
        comment: '看我！这么可爱还郁闷？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat249',
        comment: '你已经是够帅的人了！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat250',
        comment: '对我来说你是最棒的',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat251',
        comment: '今天一天也辛苦了。',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat252',
        comment: '给你最珍惜的鱼。 放松心情~',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat253',
        comment: '要摸摸我的粉色软糖吗？',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat254',
        comment: '吃好吃的东西心情会好一点的！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat255',
        comment: '郁闷的时候看美丽的天空！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat256',
        comment: '悲伤的话可以依靠我',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat257',
        comment: '看起来好累啊.我听你说话！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat258',
        comment: '跟我玩的话心情会变好的？！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat259',
        comment: '为了让大家加油 国国在努力中！',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat260',
        comment: '无论何时我都会站在你这一边',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat261',
        comment: '以后会有很多好事发生的！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog1',
        comment: '等一下！ 闻到什么味道？',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog2',
        comment: '快点出去散步吧~ 天气太好了！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog3',
        comment: '哦~ 那只狗很漂亮啊~ 不能去那边吗？',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog4',
        comment: '这些朋友都很有趣，可不可以再玩一会儿？',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog5',
        comment: '今天想去与众不同的散步路线！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog6',
        comment: '口渴！想喝水！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog7',
        comment: '啊 等一下！ 嘘嘘！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog8',
        comment: '嗯 是什么味道呢？ 是很好闻的味道',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog9',
        comment: '现在累了~ 回家吧！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog10',
        comment: '那是什么? 有什么东西',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog11',
        comment: '我先跑过去！ 我想跑！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog12',
        comment: '快来！~快走吧！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog13',
        comment: '我也想像人一样走，想牵着手一起走！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog14',
        comment: '抱紧我 紧紧地抱住我！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog15',
        comment: '挖砂加仑',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog16',
        comment: '我休息一会儿再走。',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog17',
        comment: '很期待今晚会吃什么？',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog18',
        comment: '那里有很多朋友，想去看看！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog19',
        comment: '啊 那个狗好可怕！ 转过去吧！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog20',
        comment: '万岁 万岁 万岁~~好开心~',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog21',
        comment: '我今天有点不开心 不要惹我',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog22',
        comment: '心情不好，能给我点零食吗？',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog23',
        comment: '今天太开心了 开心！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog24',
        comment: '好像做了个可怕的梦 太吓人了ㅜㅜ',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog25',
        comment: '现在很幸福的感觉！ 嘿嘿嘿',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog26',
        comment: '好奇！好奇！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog27',
        comment: '太好了！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog28',
        comment: '关心我吧！！！！！！株！！！！！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog29',
        comment: '一整天一个人无聊了！ ㅜㅜ',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog30',
        comment: '能喜欢我吗？',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog31',
        comment: '我可爱吧？可爱吧？！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog32',
        comment: '嗯...',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog33',
        comment: '今天怎么了？',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog34',
        comment: '别管我了 JeVal',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog35',
        comment: '为什么这么烦？',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog36',
        comment: '够了..瑟瑟发抖',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog37',
        comment: '真的好生气啊',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog38',
        comment: '可爱啊><',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog39',
        comment: '为什么心情会这么低沉呢？',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog40',
        comment: '哎呦 好无聊啊',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog41',
        comment: '软糖...',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog42',
        comment: '点什么就拿点零食吧 主人啊',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog43',
        comment: '放过我吧',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog44',
        comment: '真不像话..呵',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog45',
        comment: '别开玩笑了，要挨骂了',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog46',
        comment: '嗯~ 不对',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog47',
        comment: '是要试一试吗？',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog48',
        comment: '真是烦人啊^_^',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog49',
        comment: '好了！够了！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog50',
        comment: '为什么在旁边更热闹呢',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog51',
        comment: '哎呦 (摇头)',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog52',
        comment: '又怎么了',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog53',
        comment: '你能不能放过我？',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog54',
        comment: '啊，好烦啊！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog55',
        comment: '哎嘿 那里停！ 不要靠近我。',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog56',
        comment: '你知道我的心吗..',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog57',
        comment: '烦躁。',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog58',
        comment: '不是说了讨厌嘛',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog59',
        comment: '不要叫我 千万不要叫我',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog60',
        comment: '好了吗...',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog61',
        comment: '好想睡觉！！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog62',
        comment: '让我一个人呆着吧！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog63',
        comment: '给我走开！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog64',
        comment: '想见朋友们！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog65',
        comment: '别从我这里掉下来。',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog66',
        comment: '一起玩吧！！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog67',
        comment: '我想出去玩。',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog68',
        comment: '给我开门！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog69',
        comment: '天天和我在一起吧！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog70',
        comment: '不能去哪！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog71',
        comment: '不能让我独自一人',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog72',
        comment: '你等着，我一个人在家，家里怎么样了。',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog73',
        comment: '就开玩笑吧 我会生气的',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog74',
        comment: '把玩具拿过来跟我玩吧',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog75',
        comment: '好好看着我的眼睛吧',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog76',
        comment: '摸摸我',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog77',
        comment: '换个新的排便垫吧',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog78',
        comment: '我想跑，就让我跑吧！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog79',
        comment: '能帮我交个朋友吗？',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog80',
        comment: '我是人！！ 我们是相同的立场',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog81',
        comment: '把我的名字换成别的吧',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog82',
        comment: '想做一碗凉水',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog83',
        comment:
          '帮我换一下垫子吧，不是总撒尿嘛。 但是踩到的话 会挨训的..想想好烦啊',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog84',
        comment: '我吃屎了 我可以舔主人吗？',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog85',
        comment: '我的粪便中蕴藏着灵魂..清理"狗屎"时，小心不要对视。',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog86',
        comment: '怎么这么难消化呢？',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog87',
        comment: '我想小便，但是我会忍住的',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog88',
        comment: '我好想拉屎，但是我会忍住的。',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog89',
        comment: '为什么粪便里总是有好闻的味道？ 想吃地',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog90',
        comment: '我不是很会排便嘛 称赞我吧',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog91',
        comment: '我没有在排便垫上撒尿..意思是我生气了..',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog92',
        comment: '吃完饭了，开始准备排泄吧？',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog93',
        comment: '但是我希望拉屎的时候不要看我 太认真了！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog94',
        comment: '别惹我生气了，我会把屎藏在家里的某个地方。',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog95',
        comment: '想去散步，在阳光下凉爽地排泄。 当然不是因为想去散步',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog96',
        comment: '不是 我的排便垫为什么会有别的家伙的味道呢？',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog97',
        comment:
          '你觉得我的小屁什么时候才能来？ 肚子疼的时候？不是拉屎的时候？不是 就是你快睡着了！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog98',
        comment: '新的饭好像不适合我啊？ 拉屎有点累。',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog99',
        comment: '用表情表达我的粪便味道',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog100',
        comment: '今天要在鞋柜里撒尿 让主人开心~',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog101',
        comment: '从现在开始，在排便垫的正中间进行排泄。 请给我零食。',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog102',
        comment: '给我饭 拉屎',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog103',
        comment: '这是什么饭？',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog104',
        comment: '没有肉吗？',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog105',
        comment: '干什么呢？把饭端出来',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog106',
        comment: '这是给你的饭吗？ 不好吃！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog107',
        comment: '这次饲料最棒！！ 太好吃了><',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog108',
        comment: '我想喝凉水',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog109',
        comment: '吃饭的时候连狗也给个案_+',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog110',
        comment: '吃什么？也给我吧~~',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog111',
        comment: '讨厌蔬菜！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog112',
        comment: '今天的菜单是什么？',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog113',
        comment: '没有小菜吗？',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog114',
        comment: '想吃零食',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog115',
        comment: '只有你吃吗？也给我好吃的吧！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog116',
        comment: '就给我肉！肉！！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog117',
        comment: '啊 好饱~~',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog118',
        comment: '想吃甜食~~！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog119',
        comment: '好饿！！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog120',
        comment: '嗯？这是什么啊 贴在谁的鼻子上',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog121',
        comment: '换饲料吧~ 腻了',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog122',
        comment: '我不会吐的！我不想吐！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog123',
        comment: '没有胃口，我不吃了。',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog124',
        comment: '觉得又累又郁闷的时候？ 跟我一起去公园散步吧！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog125',
        comment: '伤心的话就靠着我吧！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog126',
        comment: '今天一天也辛苦了~',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog127',
        comment: '没关系吗？我给你珍惜的玩具 加油ㅠㅠ',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog128',
        comment: '怎么回事！Deco哇，我要教训你！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog129',
        comment: '谁说的...累的时候时间就是良药',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog130',
        comment: '看看我的表情！ 搞笑吧？可爱吧~？',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog131',
        comment: '如果忧郁的话，跟我出去赛跑怎么样？！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog132',
        comment: '主人旁边有我啊！ 用力！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog133',
        comment: '没关系，以后会有更多好事的',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog134',
        comment: '想哭的时候哭也没关系！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog135',
        comment: '希望我能给主人带来安慰',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog136',
        comment: '别愁眉苦脸~ 有我啊！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog137',
        comment: '吃好吃的东西心情会好一点的！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog138',
        comment: '有什么事吗？我都答应你！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog139',
        comment: '别担心 做得已经够好了',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog140',
        comment: '因为有主人 我很幸福',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog141',
        comment: '对我来说主人是最棒的！ 最珍贵',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog142',
        comment: '我会为你加油的 明天也要加油！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog143',
        comment: '振作起来 主人已经是帅气的人了',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog144',
        comment: '再见！再见！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog145',
        comment: '为什么现在才来~~!!快点抱我！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog146',
        comment: '快去快回！！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog147',
        comment: '来了?! 玩吧! 玩吧!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog148',
        comment: '现在几点了？',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog149',
        comment: '不要丢下我走ㅠㅠ',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog150',
        comment: '一路走好。',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog151',
        comment: '路上小心。',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog152',
        comment: '呀呼~来了！！！好开心！陪我玩！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog153',
        comment: '你好！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog154',
        comment: '你是谁，在抚摸我吗？',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog155',
        comment: '很高兴见到你！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog156',
        comment: '来的时候吃Melona味的零食！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog157',
        comment: '睡得好吗？',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog158',
        comment: '…………（无视）',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog159',
        comment: '......(摇头摇头)',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog160',
        comment: '起来！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog161',
        comment: '朋友啊！！你好！！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog162',
        comment: '快来！给我一个零食是必须的！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog163',
        comment: '手上的是什么啊~？ 是为了给我买来的吗~？',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog164',
        comment: '回来了吗~~？ 今天一天也辛苦了！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog165',
        comment: '不想洗！放开我！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog166',
        comment: '今天洗漱很有意思！！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog167',
        comment: '这是什么味道？',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog168',
        comment: '好怕进水啊！...',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog169',
        comment: '想快点洗完玩！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog170',
        comment: '现在我太紧张了！ ㅠㅠ',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog171',
        comment: '太粗糙了！温柔地洗一下~',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog172',
        comment: '现在太幸福了！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog173',
        comment: '现在洗漱太累了！...',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog174',
        comment: '水中散发出奇怪的味道ㅠㅠ',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog175',
        comment: '身体湿透了，太冷了ㅠㅠ',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog176',
        comment: '洗漱的时候顺便把脚指甲也剪掉！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog177',
        comment: '现在没有想洗漱的心情...',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog178',
        comment: '洗完澡会给我零食吗？',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog179',
        comment: '一起打水玩儿吧！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog180',
        comment: '洗完澡太凉快了！',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog181',
        comment: '快点暖和地晾干身体吧~',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog182',
        comment: '现在太渴了...',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog183',
        comment: '好困啊 ZZZZZ...',
      },
    ],
  },
  // facialExpressionAnalyzerEng
  {
    info: {
      mainTitle: 'Facial Expression Analyzer',
      subTitle: 'Dogs, cats, anyone',
      mainUrl: 'facialExpressionAnalyzerEng',
      scoreType: 'facialExpression',
      mainImage:
        'https://images.ktestone.com/introImages/facialExpressionAnalyzerEng-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/facialExpressionAnalyzerEng-thumb.png',
      lang: 'Eng',
      category: 'etc',
    },
    questions: [
      {
        question: '데이트 전 날 나의 모습은?',
        answers: [
          {
            type: '포메라니안',
            score: 2,
            content: '나가기 직전 마음에 드는 옷을 입는다.',
          },
          {
            type: '치와와',
            score: 5,
            content: '자기 전 머릿속으로 나의 스타일링을 생각하고 잔다.',
          },
          {
            type: '비글',
            score: 10,
            content: '전 날 머리부터 발끝까지 스타일링과 옷을 미리 준비한다.',
          },
        ],
      },
      {
        question: '갑자기 자취방에 놀러 온다는 애인!\n그때의 나는?',
        answers: [
          {
            type: '포메라니안',
            score: 2,
            content: '아아... 왜 갑자기 오는 거야?! 보이는 곳만 치워 둔다.',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '모아둔 쓰레기를 버리고 바로 청소를 시작한다.',
          },
          {
            type: '리트리버',
            score: 10,
            content: '평소 미리미리 해 두었기 때문에 바로 주소를 보낸다.',
          },
        ],
      },
      {
        question: '좋아하는 이성이 생겼을 때 나는?',
        answers: [
          {
            type: '치와와',
            score: 2,
            content: '상대의 SNS을 탐방하며, 미래의 우리 모습을 상상해본다.',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '좋아하는 이성의 친구에게 도움을 요청한다.',
          },
          {
            type: '리트리버',
            score: 10,
            content: '먼저 연락할 거리를 만들며 적극적으로 대시한다.',
          },
        ],
      },
      {
        question:
          '카페 안에 이상형인 사람과 계속 눈이 마주친다.\n 눈웃음까지 보내는데...?',
        answers: [
          {
            type: '비글',
            score: 2,
            content: '지금 말하면 좋은 말이 안 나올 것 같아. 좀 이따 연락할게.',
          },
          {
            type: '리트리버',
            score: 5,
            content: '아!!!!!!!짜증나!!!!!진짜!!!!!! 도대체 왜 그러는 거야?!?!',
          },
          {
            type: '포메라니안',
            score: 10,
            content:
              '이런 점에서 나는 화가 난 거야. 그런 부분은 고쳐줬으면 좋겠어.',
          },
        ],
      },
      {
        question:
          '쉬고 있는데 갑자기 걸려온 남사친/여사친의 전화, \n친구들이 같이 놀고 싶다며 나올 수 있냐고 물어보는데...?',
        answers: [
          {
            type: '리트리버',
            score: 2,
            content:
              '귀찮은데… 아쉽긴 하지만 “시간이 너무 늦었어… 나 집에서 쉴래!”',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '일단 끊고 수십 번 고민 끝에 나가기로 한다.',
          },
          {
            type: '비글',
            score: 10,
            content: '오케이~ 인연 한 번 만들어보자~ “갈게~~~~”',
          },
        ],
      },
      {
        question: '다가온 기념일! 나는 어떤 선물을 준비할까?',
        answers: [
          {
            type: '포메라니안',
            score: 2,
            content: '정성 어린 편지와 기억에 남을 만한 선물을 준비한다.',
          },
          {
            type: '비글',
            score: 5,
            content: '커플로 착용할 수 있는 아이템을 선물한다.',
          },
          {
            type: '리트리버',
            score: 10,
            content:
              '평소 사고 싶다거나 필요한 물건을 기억해 두었다가 서프라이즈로 선물한다.',
          },
        ],
      },
      {
        question: '내가 하고 싶은 연애 스타일은?',
        answers: [
          {
            type: '비글',
            score: 2,
            content:
              '다이나믹한 화려한 연애 (단, 너무 화려해서 바람 피울 가능성 50%)',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '나만 바라보는 연애 (단, 너무 좋아해서 집착 대마왕)',
          },
          {
            type: '포메라니안',
            score: 10,
            content: '친구같이 편안한 연애 (단, 가끔 친구인지 애인인지 헷갈림)',
          },
        ],
      },
      {
        question: '애인과 대화할 때 나는?',
        answers: [
          {
            type: '치와와',
            score: 5,
            content: '장난치고 티키타카가 잘 되는 대화가 좋다.',
          },
          {
            type: '리트리버',
            score: 10,
            content: '서로의 생각을 공유하는 대화가 너무 좋다.',
          },
        ],
      },
      {
        question: '친구가 나에게 고민상당을 한다. 나의 반응은?',
        answers: [
          {
            type: '포메라니안',
            score: 5,
            content: '고민에 대한 구체적인 해결책을 제시한다.',
          },
          {
            type: '웰시코기',
            score: 10,
            content: '고민에 대해 빠져들어 감정적으로 같이 공감해준다.',
          },
        ],
      },
      {
        question:
          '기다려왔던 짝사랑하던 그/그녀와의 데이트 날..\n갑자기 급한 일이 생겼다며 다음에 만나자고 한다.\n과연 나의 대답은?',
        answers: [
          {
            type: '비글',
            score: 2,
            content: '어쩔 수 없지… 알겠어 다음에 보자!',
          },
          {
            type: '치와와',
            score: 5,
            content: '알겠어…근데 심각한 일이야?',
          },
          {
            type: '웰시코기',
            score: 10,
            content: '아 진짜? 그럼 날짜 다시 조정하자! 언제가 괜찮아?',
          },
        ],
      },
    ],
    results: [
      {
        type: 'human',
        desc: '',
        query: 'human1',
        comment: 'Can you give me a hug?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human2',
        comment: 'Can you get out of my sight?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human3',
        comment: 'Give me a glass of water.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human4',
        comment: 'Do you want to stay with me today?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human5',
        comment: 'Look me in the eye!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human6',
        comment: 'I want to take a rest.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human7',
        comment: "I don't want to go to work tomorrow.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human8',
        comment: 'Who have I been since I was single for  years?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human9',
        comment: 'Who am I? Where am I?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human10',
        comment: 'Make me a friend.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human11',
        comment: 'Life is all alone.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human12',
        comment: "You're lonely.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human13',
        comment: "It's the weather I want to win the lottery.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human14',
        comment: 'God, why did you make me born on this planet?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human15',
        comment: "I'm planning to quit tomorrow.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human16',
        comment: 'I want to lie down and play cell phone all day.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human17',
        comment: "As expected, tonight's dinner is tteokbokki.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human18',
        comment: 'I want to travel to Hawaii.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human19',
        comment: "Let's go to the stock ceiling I put in!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human20',
        comment: 'I want to be a rich unemployed man.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human21',
        comment: "Music is the only drug I'm allowed to do.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human22',
        comment: "I look like I'm going to be rich.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human23',
        comment: 'I want to leave for Jeju Island.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human24',
        comment: 'Stop dieting and I just want to eat yakchang!!!!!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human25',
        comment: 'Can you give me half if I win the lottery?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human26',
        comment: "Don't look at me.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human27',
        comment: 'Can you lend me some money?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human28',
        comment: "Oh, I should've bought Bitcoin  years ago.I had to buy it.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human29',
        comment: "Don't talk to me.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human30',
        comment: "You'll soon be hooked on my charm.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human31',
        comment: 'Are you going to stay at home like this?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human32',
        comment: "Let's go to the moviesHow about rockpaperscissors",
      },
      {
        type: 'human',
        desc: '',
        query: 'human33',
        comment: 'and buy a movie ticket?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human34',
        comment:
          "I'm sick of homecooked mealsDo you want to go out and eat something delicious?",
      },
      {
        type: 'human',
        desc: '',
        query: 'human35',
        comment: "Let's go jogging! I think you need to work out.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human36',
        comment:
          "I think you'll soon be roll like a ballCome on! I'm going to work out",
      },
      {
        type: 'human',
        desc: '',
        query: 'human37',
        comment:
          "Looking at your neck, the turtle will talk to you, let's go for a walk.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human38',
        comment:
          "There's something delicious over thereI bought it last time, so you buy it today Let's go for a drive! Now!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human39',
        comment:
          'Do you want to go on a trip? Suddenly, I want to travel without a plan.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human40',
        comment: "I'm sick of staying home todayI'm gonna go out for a second.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human41',
        comment:
          'What time is it now? Oh yeah, today is the day you buy me something delicious!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human42',
        comment:
          'What day is it today? Oh, yeahWe were supposed to bowl today.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human43',
        comment:
          "What's the date today? Oh, yeahWe're gonna go to a cafe and play bingo today.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human44',
        comment:
          "We had an appointment todayRight! You said you'd buy me meat!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human45',
        comment: "Come out now! I'll be waiting for you in front of me.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human46',
        comment:
          "I'm not feeling well today, I'm sick, so send me something to eat.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human47',
        comment: 'My legs hurtGive me a piggyback ride.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human48',
        comment: 'I have an appointment with my girlfriend todaySorry.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human49',
        comment:
          'Who were you with yesterday? I saw you with someone else yesterday.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human50',
        comment: "Who's that guy? You were holding hands yesterday.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human51',
        comment: 'I went for a walk with him(her) last night.It was fine.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human52',
        comment: 'I took another two hours walk with him(her) yesterday.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human53',
        comment: "Let's stop nowI don't want any more..That's too much",
      },
      {
        type: 'human',
        desc: '',
        query: 'human54',
        comment:
          "I'm tired of writing this comment, I have nothing to say, I want to stop.(ShhI don't want the boss to know.)",
      },
      {
        type: 'human',
        desc: '',
        query: 'human55',
        comment:
          'I found a bag while I was taking a walk yesterday, and there was  million won.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human56',
        comment: 'I went to the police station yesterdayPhew',
      },
      {
        type: 'human',
        desc: '',
        query: 'human57',
        comment: 'Yesterday I saw ..a murder case!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human58',
        comment: 'What are you doing today? Do you want to go on a date?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human59',
        comment: "I'm sorry, I have someone I loveI'm meeting him today.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human60',
        comment: 'I particularly sting today.Ready to have fun.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human61',
        comment: "I'm so excited.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human62',
        comment: "Please go away because it's annoying.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human63',
        comment: "I'm lonely  fifth grade at Hogwarts.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human64',
        comment: 'Life is… tough, tough.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human65',
        comment: "Would you like to have a drink because I'm in a bad mood?",
      },
      {
        type: 'human',
        desc: '',
        query: 'human66',
        comment: 'Why are you annoying me?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human67',
        comment: "It's depression that's the sky.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human68',
        comment: 'I love you, dude.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human69',
        comment: "Let's go ahead!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human70',
        comment: "I'm~~~~ Tonight~~~~~~ I'm scared of the dark~~",
      },
      {
        type: 'human',
        desc: '',
        query: 'human71',
        comment: "Oh..I think I'm going to throw up a little bit.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human72',
        comment: "Wow, it's really not funIt's so boring.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human73',
        comment: "Feel down!What's funny?",
      },
      {
        type: 'human',
        desc: '',
        query: 'human74',
        comment: 'Give me a hug because I want to cry today.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human75',
        comment: 'The number one who talks without thinking.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human76',
        comment: 'Did you eat a lot? No wonder it looked like that.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human77',
        comment: 'Experiencing the limitations of patience',
      },
      {
        type: 'human',
        desc: '',
        query: 'human78',
        comment: 'I miss you.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human79',
        comment: "I'm thinking about the person I love now.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human80',
        comment:
          "I'm excited to think of chicken for myself who had a hard day today!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human81',
        comment: "I can't relieve my fatigue even if I sleep….",
      },
      {
        type: 'human',
        desc: '',
        query: 'human82',
        comment:
          "Leave me alone! A person who's tired even if he doesn't do anything.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human83',
        comment: "Oh, it's not easy!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human84',
        comment: 'Are you tired now?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human85',
        comment: 'a sigh creeping out',
      },
      {
        type: 'human',
        desc: '',
        query: 'human86',
        comment: 'We care for each other...I love you...',
      },
      {
        type: 'human',
        desc: '',
        query: 'human87',
        comment: 'Thank you, my friends.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human88',
        comment: 'I sometimes shed tears.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human89',
        comment: "Don't tell me not to.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human90',
        comment: "Whoo~~~~~It's a party.~~~~~~~~~~~~~~~~!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human91',
        comment: "I am mindlessBecause I don't have any thoughts.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human92',
        comment: 'Why am I here...',
      },
      {
        type: 'human',
        desc: '',
        query: 'human93',
        comment: 'I want to go home...',
      },
      {
        type: 'human',
        desc: '',
        query: 'human94',
        comment: 'Do you like it?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human95',
        comment: "Oh, it's pointless.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human96',
        comment: "Don't touch me when I eat.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human97',
        comment: "It's not worth listening toBye.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human98',
        comment: 'So what?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human99',
        comment: "Oh, you're bothering me!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human100',
        comment: "Oh, I don't want to",
      },
      {
        type: 'human',
        desc: '',
        query: 'human101',
        comment: 'Who cares ..I made my way to go!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human102',
        comment: "I can't hear youI can't hear you~",
      },
      {
        type: 'human',
        desc: '',
        query: 'human103',
        comment: "I'm not leaving, I'm staying home!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human104',
        comment: "Don't expect that from me",
      },
      {
        type: 'human',
        desc: '',
        query: 'human105',
        comment: 'Blood pressure climbing in.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human106',
        comment: 'Why would I?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human107',
        comment: 'I want to be alone',
      },
      {
        type: 'human',
        desc: '',
        query: 'human108',
        comment: 'Lying all day and just want to',
      },
      {
        type: 'human',
        desc: '',
        query: 'human109',
        comment: "Ok…(it's a hassle.)",
      },
      {
        type: 'human',
        desc: '',
        query: 'human110',
        comment: 'Life is about living the way it is.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human111',
        comment: "I'm nothing but dust in the universe anyway...",
      },
      {
        type: 'human',
        desc: '',
        query: 'human112',
        comment: "I want to lie down if you sit people's minds",
      },
      {
        type: 'human',
        desc: '',
        query: 'human113',
        comment: "I don't like it.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human114',
        comment: "I've lost the will to live.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human115',
        comment: 'I want to be a Lottery winHah!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human116',
        comment: "That's it for todayI'll do it tomorrow.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human117',
        comment: 'What does that mean?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human118',
        comment: "I'm tired of it~~ I'm tired of it~~",
      },
      {
        type: 'human',
        desc: '',
        query: 'human119',
        comment: "That's a possibility.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human120',
        comment: "I'm gonna have to stop^_^",
      },
      {
        type: 'human',
        desc: '',
        query: 'human121',
        comment: 'Are you here?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human122',
        comment: 'Oh, hi!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human123',
        comment: 'Take care!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human124',
        comment: "Wow, you're feeling better.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human125',
        comment: "It's been a long time!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human126',
        comment: 'Good morning!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human127',
        comment: "Let's have a meal sometime.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human128',
        comment: '(a word of courtesy)',
      },
      {
        type: 'human',
        desc: '',
        query: 'human129',
        comment: "Hey, I'm leaving!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human130',
        comment: 'Hi!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human131',
        comment: "Hey! It's here! Here!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human132',
        comment: 'See you sometime, really.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human133',
        comment: '(more likely to bump into each other along the way)',
      },
      {
        type: 'human',
        desc: '',
        query: 'human134',
        comment: 'Hey!!! Who are you???',
      },
      {
        type: 'human',
        desc: '',
        query: 'human135',
        comment: "(It's been a while, but I have nothing to say.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human136',
        comment: 'Shouting)',
      },
      {
        type: 'human',
        desc: '',
        query: 'human137',
        comment: "Let's go first!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human138',
        comment: "Let's have a drink.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human139',
        comment: 'Hey~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human140',
        comment: "I think you've lost weight.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human141',
        comment: 'Good night ♥',
      },
      {
        type: 'human',
        desc: '',
        query: 'human142',
        comment: 'Hi...><',
      },
      {
        type: 'human',
        desc: '',
        query: 'human143',
        comment: 'How have you been?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human144',
        comment: 'What time is it now?..',
      },
      {
        type: 'human',
        desc: '',
        query: 'human145',
        comment: 'Great job!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human146',
        comment: "I'm hungry!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human147',
        comment: 'Be careful on your way!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human148',
        comment: 'Bye!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human149',
        comment: 'Do you eat?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human150',
        comment: 'Where do you want to go?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human151',
        comment: "Hey, I'm sorry, I'm late.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human152',
        comment: 'What do you want to do?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human153',
        comment: 'Oh my gosh, you look so pretty!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human154',
        comment: 'Have I changed anything today?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human155',
        comment: 'Is this real? Do I have to wash up?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human156',
        comment: "I won't brush my teeth boldly today.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human157',
        comment: 'Why do I have to wash my face?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human158',
        comment: "I'm going to take a showerMake it soft!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human159',
        comment: 'I wash my hair firstI like it.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human160',
        comment: 'I take a hot shower in the middle of summerHot sauce.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human161',
        comment:
          "If you get a machine that cleans people's bodies, you have to buy the company's stock first.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human162',
        comment: "Wow, I'm too lazy to wash up today.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human163',
        comment: 'Can you wash me up?^^',
      },
      {
        type: 'human',
        desc: '',
        query: 'human164',
        comment: "How's the water temperature?",
      },
      {
        type: 'human',
        desc: '',
        query: 'human165',
        comment: "If you don't have a bath, you won't take a bath.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human166',
        comment: 'After washing banana milk is necessary.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human167',
        comment: "Let's go soft today!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human168',
        comment: "Are you tired after the shower?It's beer!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human169',
        comment: "It's already dawn when I'm about to wash up.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human170',
        comment: 'Looking in the mirror after the shower, I look so good.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human171',
        comment: 'Every time I wash up, a different person appears!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human172',
        comment: "I don't want to wash..I washed yesterday...",
      },
      {
        type: 'human',
        desc: '',
        query: 'human173',
        comment: 'At least a neat freak,',
      },
      {
        type: 'human',
        desc: '',
        query: 'human174',
        comment: 'ShampooLinceTreatmentBody WashScrubPack.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human175',
        comment: 'You always smell sweetDid you wash up?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human176',
        comment: "You always smell like fartsDidn't you wash up?",
      },
      {
        type: 'human',
        desc: '',
        query: 'human177',
        comment: 'Just wash up.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human178',
        comment: "From head to toe~ I'm going to wash up~",
      },
      {
        type: 'human',
        desc: '',
        query: 'human179',
        comment: 'Washing hands and feet after going out',
      },
      {
        type: 'human',
        desc: '',
        query: 'human180',
        comment: 'Clean your nails, too! Clear! Confident!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human181',
        comment: "Don't just gargle and brush your teeth.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human182',
        comment:
          'If your breath smells like that, it means you need to wash it.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human183',
        comment: "Don't you have a nose by any chance?",
      },
      {
        type: 'human',
        desc: '',
        query: 'human184',
        comment: 'It smells.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human185',
        comment: "I'm hungryCan I have a meal?",
      },
      {
        type: 'human',
        desc: '',
        query: 'human186',
        comment: 'The soup is a bit salty.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human187',
        comment: "You're giving this to me as food?",
      },
      {
        type: 'human',
        desc: '',
        query: 'human188',
        comment: "What's today's side dish?",
      },
      {
        type: 'human',
        desc: '',
        query: 'human189',
        comment: "I'm a vegetarian!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human190',
        comment: "I don't eat without meat!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human191',
        comment: 'Instants are the best!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human192',
        comment: "Ah~I'm full~~~~",
      },
      {
        type: 'human',
        desc: '',
        query: 'human193',
        comment: "I don't like vegetables!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human194',
        comment: "It's too small! Give me a little more!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human195',
        comment: "There's too much~ Take it out!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human196',
        comment: 'Growl..LOL',
      },
      {
        type: 'human',
        desc: '',
        query: 'human197',
        comment: 'Today is tteokbokki!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human198',
        comment: 'I want something sweet~~~~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human199',
        comment: "Shall we eat ice cream since it's hot?",
      },
      {
        type: 'human',
        desc: '',
        query: 'human200',
        comment: "It's cold..I want some warm soup!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human201',
        comment: "I don't want to eat it.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human202',
        comment: 'Diet medium! Get the food out of my sight!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human203',
        comment: 'One bite~!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human204',
        comment: "What is it? It's so good! JMT",
      },
      {
        type: 'human',
        desc: '',
        query: 'human205',
        comment: "Did you say this was cooking too? It's not good!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human206',
        comment: 'Give me a glass of cold water.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human207',
        comment: "I want to go on a restaurant tour! Let's go out~",
      },
      {
        type: 'human',
        desc: '',
        query: 'human208',
        comment: 'Late night snack, okay~~~?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human209',
        comment: "How about jokbal? Isn't it great?",
      },
      {
        type: 'human',
        desc: '',
        query: 'human210',
        comment: "I don't give a dog to eat",
      },
      {
        type: 'human',
        desc: '',
        query: 'human211',
        comment: 'Give me a table of eight!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human212',
        comment: 'Buy me beef, please.Oh, oh, oh, oh~!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human213',
        comment: 'Why are you eating that?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human214',
        comment: "Don't worry too much, everything's gonna be fine!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human215',
        comment: 'Forget about the past.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human216',
        comment:
          "Why don't you go for a walk when you're tired and frustrated?",
      },
      {
        type: 'human',
        desc: '',
        query: 'human217',
        comment: 'You did a great job today!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human218',
        comment: "You can lean on me if you're sad.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human219',
        comment:
          "Time is medicine when you're having a hard timeThis too shall pass!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human220',
        comment: "I'm next to youCheer up!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human221',
        comment: 'You can cry when you want to.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human222',
        comment: 'I hope I can be a consolation to you.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human223',
        comment: "No matter what anyone says, you're the best.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human224',
        comment: 'Think about the people who care about you!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human225',
        comment: "Eat something delicious and you'll feel better!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human226',
        comment: "What's the matter? I'll listen to everything.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human227',
        comment: 'Who gave you a hard time? Bring him back!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human228',
        comment: 'Your existence itself shines.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human229',
        comment: "I'll always cheer for youCheer up for tomorrow!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human230',
        comment: "You're the best for meIt's the most precious thing.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human231',
        comment: "Come on, you're already a cool guy.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human232',
        comment: 'You can cry when you want to.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human233',
        comment:
          "You don't have to compare yourself to others! You're shining.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human234',
        comment: "You're already a valuable person to someone.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human235',
        comment: 'Thank you for the great work today.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human236',
        comment: "I'll be rooting for you tomorrow.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human237',
        comment: 'Thank you for always trying.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human238',
        comment: "Don't worry, you're doing well enough!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human239',
        comment: "It's all rightWe just have to do it like this.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human240',
        comment: "Should we go eat something delicious if you're depressed?",
      },
      {
        type: 'human',
        desc: '',
        query: 'human241',
        comment: "When you're down? Let's go on a trip!",
      },
      {
        type: 'human',
        desc: '',
        query: 'human242',
        comment: 'Sometimes also need to have my own time.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human243',
        comment: 'Happiness comes from small things.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human244',
        comment: "I haven't pooped in three days.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human245',
        comment: "Oh, my poop smells so bad, I'm gonna carry a room spray.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human246',
        comment:
          "I didn't flush the bathroom last time, but no one knows, right?",
      },
      {
        type: 'human',
        desc: '',
        query: 'human247',
        comment: 'The poop I take in the morning is happiness.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human248',
        comment: "You don't think I'm a pooch now, do you?",
      },
      {
        type: 'human',
        desc: '',
        query: 'human249',
        comment: 'I can take a piss for three hours.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human250',
        comment:
          "It's amazing that green poo comes out whenever you eat vegetables, right?",
      },
      {
        type: 'human',
        desc: '',
        query: 'human251',
        comment: 'I need to poop',
      },
      {
        type: 'human',
        desc: '',
        query: 'human252',
        comment: 'I need to pee.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human253',
        comment:
          'This is the look of a person who held back and wrapped himself until he was about to poo.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human254',
        comment:
          "Sometimes I feel sorry for my intestinesI've been sending you too much food.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human255',
        comment: "I really can't stand itGo to the bathroom.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human256',
        comment: 'Where is the restroom?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human257',
        comment: "I miss the pushy bathroomIt's easy to press.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human258',
        comment: 'Oh, I ate something wrong yesterdayMy stomach hurts so much.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human259',
        comment: 'Why does my stomach hurt when I have diarrhea?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human260',
        comment:
          "I heard a long time ago that there was a person who felt good while pooping on the bed, but that's me.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human261',
        comment: 'I feel good when the bathroom is pleasant.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human262',
        comment:
          "No matter how good the building is, if the bathroom is dirty, it's annoying.",
      },
      {
        type: 'human',
        desc: '',
        query: 'human263',
        comment: 'I peed on the blanket yesterday.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat1',
        comment: 'Get me a chur.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat2',
        comment: 'I need you to leave me alone.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat3',
        comment: "Butler, don't look at the other cat!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat4',
        comment: 'The butler only sees me, loves me, cares about me.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat5',
        comment: 'I need you to be quiet.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat6',
        comment: "I don't like being noisy.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat7',
        comment: 'Blink if you want to communicate with me.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat8',
        comment: 'I want to rub it against the butler!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat9',
        comment: 'You can only touch me when I let you.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat10',
        comment: 'Take care of the fish.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat11',
        comment: 'Bring me a box when you get home.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat12',
        comment: 'I want to go inside the vinyl and be alone.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat13',
        comment: "If you touch it now, you'll see!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat14',
        comment: 'Do you want to get punched?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat15',
        comment: 'Get me a toy! Play with me!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat16',
        comment: 'Come here.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat17',
        comment: 'Will you go over there?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat18',
        comment:
          "Moderate temperature, humidityI like everythingIt's comfortable.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat19',
        comment: 'You can touch me under your chinan end',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat20',
        comment: "I'd like to ask for a variety of toys.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat21',
        comment: 'My feet are a very cute pink style:>',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat22',
        comment: 'Come here so I can squeeze.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat23',
        comment: 'Can you get me some new churros?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat24',
        comment: "I'm sleepy, but do you know how cold it feels?",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat25',
        comment: "Butler, I've been loving you lately.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat26',
        comment: 'Is there anything fun going on?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat27',
        comment: 'I can rule the world with a beard.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat28',
        comment: "You don't know what happened last night, do you?",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat29',
        comment: "Did You know I don't like walking, right?",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat30',
        comment: "It's a walk for a dogI'm a great cat.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat31',
        comment: "A great cat doesn't go for a walk.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat32',
        comment: 'Great cats are not dragged around.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat33',
        comment: 'If you have work to go outside and go with meBe carefully',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat34',
        comment: 'If you know a pretty cat, please introduce it to me.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat35',
        comment: "I'm hungryGive me something to eat",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat36',
        comment: "I want to rest today, don't touch me.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat37',
        comment: "I don't want to walk longMy cute jelly is wearing out.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat38',
        comment: 'I like a day when the wind is coolToday is that time.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat39',
        comment: 'Be careful when you hug me, with your hands.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat40',
        comment: "It doesn't feel good to step on the dirt, hug me.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat41',
        comment: "I don't feel good about asphalt, hug me.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat42',
        comment: "Don't let the sun go out too strongProtect my skin",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat43',
        comment: "I'm a little tired today, get me a meal.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat44',
        comment: "It's a hairy dayBrush my hair.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat45',
        comment: "What's all the fuss outside? Let's go somewhere quiet.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat46',
        comment: "Let's go out to eat sometimeI'm sick of homecooked meals.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat47',
        comment: "You don't see any mice these days, go out and look for them.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat48',
        comment: "What's that?! What a quickie! Get him!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat49',
        comment: "It's a good day to jump for some reason.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat50',
        comment: 'I want to catch something quickToday is Hunting Day',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat51',
        comment: "Let's catch a mouseLet's catch a mouseSqueak, squeak.squeak",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat52',
        comment: 'Did you read my eyes? I want to relax today.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat53',
        comment:
          'My movements are as fast as bees, and my fur is as soft as cotton!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat54',
        comment: "You're having so much fun todayLet's go home.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat55',
        comment: "All right, all right, let's go!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat56',
        comment: 'I feel stuffy staying at home these days!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat57',
        comment: "Do I look fat? Why don't we take a walk?",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat58',
        comment: 'Wait, what is this? I think I found something.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat59',
        comment: 'Why am I in such a good mood today?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat60',
        comment: "God, I'm sleepy even though I keep sleeping.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat61',
        comment: "I don't want to do anything.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat62',
        comment: "Don't touch me because I'm sensitive.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat63',
        comment: 'hey! Can you give me a snack because I feel bad?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat64',
        comment: "What's that? I'm curious!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat65',
        comment: 'Why do you keep staring at me?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat66',
        comment: 'What is that? I am very scared,',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat67',
        comment: 'Are you okay? what is your problem?Are you tired?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat68',
        comment: "Wow, I'm so scared.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat69',
        comment: "I'm concentrating right now, so tell me.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat70',
        comment: "Don't make me clingy.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat71',
        comment: 'Can a slap if you can do?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat72',
        comment: 'an extreme state of excitement',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat73',
        comment: 'Yes, I believe youI will!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat74',
        comment: "Isn't there anything fun?",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat75',
        comment: "What's wrong with her again?",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat76',
        comment: 'Heheheh! I feel good!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat77',
        comment: "I'll give you a chance to pat me on the stomach.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat78',
        comment: 'I like you very, very much.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat79',
        comment: 'I always thank you!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat80',
        comment: "Playing the game with me since I'm bored?",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat81',
        comment: "I'm sick of that now! Buy me a new toy!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat82',
        comment: 'I want to play!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat83',
        comment:
          "Can you give me a warm heart? It's not a request, it's an order!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat84',
        comment: "I hate to mess with youI'm gonna be alone.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat85',
        comment: 'Give me a hug!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat86',
        comment:
          "I don't understand why you always eat delicious food by yourself.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat87',
        comment: 'Go easy on the alcohol.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat88',
        comment:
          "Do you have any complaints about me sleeping? Then it's worth keeping.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat89',
        comment: "Don't touch me...",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat90',
        comment: 'Ah, here we go again..',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat91',
        comment: 'What are you looking at?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat92',
        comment: 'Did you call me now?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat93',
        comment: 'Eat this..Cat Punch',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat94',
        comment: 'Stop taking pictures.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat95',
        comment: "It's not funny.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat96',
        comment: 'Give me a pat.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat97',
        comment: 'Is this enough?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat98',
        comment: 'I need some time aloneLeave me alone!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat99',
        comment: "I told you not to call meDon't call me...",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat100',
        comment: 'But I did as I want to go home.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat101',
        comment: "I don't mind touching myselfBut you can't^_^",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat102',
        comment: 'Why do you keep looking at? (ignore)',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat103',
        comment: "I'll do you a damn thing.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat104',
        comment: 'Let go of me.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat105',
        comment: "I'm gonna bite you.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat106',
        comment: 'Bring me a snack and support me!!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat107',
        comment: "What's this on your neck? Take it out right now!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat108',
        comment: "What's wrong?",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat109',
        comment: "Don't come.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat110',
        comment: "Let's live roughly.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat111',
        comment: "Why don't you mind your own business now?",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat112',
        comment: 'Phew, that guy.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat113',
        comment: "It's all for nothing.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat114',
        comment: '…(Ignore)',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat115',
        comment: 'What a noise!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat116',
        comment: 'You want to fight?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat117',
        comment: "It's annoying...",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat118',
        comment: 'Butler, do you want to be fired?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat119',
        comment: '(Ignore) Bah! ...',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat120',
        comment: 'Are you here? Give me a chance to give you a snack.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat121',
        comment: 'How dare you leave me behind and come in now?!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat122',
        comment: 'Hurry up and go!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat123',
        comment: 'Have a safe trip or not.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat124',
        comment: 'Who are you to pretend to know me?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat125',
        comment: 'Again? Stop drinking! Stop drinking!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat126',
        comment: 'Why are you here now? I was bored!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat127',
        comment: 'Buy a highquality snack for me when I come.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat128',
        comment: 'Who is this?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat129',
        comment: "I don't want to be home alone! Take me with you!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat130',
        comment: "What are you doing? If you're here, you should pet me first.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat131',
        comment: 'Phew~(Shake head)',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat132',
        comment: "If you come early today, I'll play with you!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat133',
        comment: "Humph! I don't say hello to strangers!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat134',
        comment: 'What are you looking at? Come on in!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat135',
        comment: 'Why are you bothering me as soon as you get here?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat136',
        comment: 'Do you have mine in your hand?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat137',
        comment: 'Come on in and give me a hug!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat138',
        comment: "I don't like customers! He keeps bothering me.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat139',
        comment: 'Oh! Are you the one who came to give me snacks?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat140',
        comment: 'mess, but come in.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat141',
        comment: 'Why is it empty handed?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat142',
        comment: 'Hello, friends!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat143',
        comment: "Are you here?(I'm sleepy...)",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat144',
        comment: "Why don't you go around early?",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat145',
        comment: "I didn't do anything!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat146',
        comment: "Come on in and feed meI'm hungry!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat147',
        comment: "If you don't come home soon, I'll make a mess of the house!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat148',
        comment:
          'My butler is finally here! Shall we get him to work now? Are you ready?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat149',
        comment: "We're out of riceGive me the rice.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat150',
        comment: "I'm only gonna drink clean water.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat151',
        comment: 'Give me Churu!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat152',
        comment: 'I like catnip~~',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat153',
        comment: 'I want to eat a crab fish jerky!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat154',
        comment: "Yikes, it's not good.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat155',
        comment: "Change the feedI'm sick of it.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat156',
        comment: "It's hotShould we eat some cool watermelon?",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat157',
        comment: 'Can you give me a snack?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat158',
        comment: "I'll have it later.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat159',
        comment: "What's this? This is so good!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat160',
        comment: "You thought I liked fish, didn't you? Actually, I like meat.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat161',
        comment: 'A lot of snacks and feed! Okay?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat162',
        comment: 'Do you have any fish?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat163',
        comment: "You don't have meat?",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat164',
        comment: "Today's meal was delicious!!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat165',
        comment: "I'm hungryGive me something delicious!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat166',
        comment: "I ate well! I'm full.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat167',
        comment: 'I want some sweet fruit.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat168',
        comment: 'Give me more! Give me more!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat169',
        comment: 'Would you eat this?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat170',
        comment: 'Too little feed!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat171',
        comment: 'Shall we eat together?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat172',
        comment: 'I had a meal, did you?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat173',
        comment: "It's churros over rice!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat174',
        comment: "I didn't like this snack.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat175',
        comment: "I'd rather have a longterm meal than a churro today.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat176',
        comment: "It's too muchTake some out.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat177',
        comment: "I'm so full, I'm going to be full~",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat178',
        comment: "Eat fast and lie downI'm gonna lie down.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat179',
        comment: 'ButlerI have to go nowshit',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat180',
        comment:
          "If you don't change the sand to a new one right away, I'll mess up your shoe rack.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat181',
        comment: 'Something huge is about to come out of my body',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat182',
        comment: "I need to pee, but I don't need to.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat183',
        comment: 'I always think the bathroom is not my style.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat184',
        comment: "I'm sorryI peed on the floor the other day.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat185',
        comment: 'Did I eat too much earlier? My intestines are shaking.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat186',
        comment: 'Deacon, do you agree that my stool smells like flowers?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat187',
        comment: 'Pleasure is always right.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat188',
        comment:
          'Change the location of the bathroomTo a place where the sunlight comes in.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat189',
        comment: 'I think I have a pee on my hairI need you to check.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat190',
        comment: "Why don't you change the feed, butler? I can't digest well.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat191',
        comment:
          "It's time for digestion to end soonThe bathroom must be clean, right?",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat192',
        comment:
          'Sometimes I want to pee on the blanket when the Butler falls asleep.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat193',
        comment: 'Always play classical music when I poop.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat194',
        comment: 'Why is the Butler’s bathroom different from mine?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat195',
        comment: 'Why do I have to pee when I drink water? Explain.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat196',
        comment: "Today's poop is very colourful.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat197',
        comment: 'Butler, always wipe my ass after I poop.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat198',
        comment: 'I need you to check my shit and check my healthButler.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat199',
        comment: "Why do I have to wash up when I'm clean?",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat200',
        comment: "Let go!!! I'm getting out!!!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat201',
        comment: 'The water is too cold–ㅅ',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat202',
        comment: 'Oh, reasonable temperature of satisfaction.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat203',
        comment: 'What are you gonna do for me when I wash up?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat204',
        comment: "It's cool after the shower.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat205',
        comment: "Butler, hold on to me because I'm scared.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat206',
        comment: 'Finish it as soon as possible.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat207',
        comment:
          "You're cutting my toenails?! Are you going to taste my toenails?",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat208',
        comment: 'Dry each hair nicely.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat209',
        comment: 'I got groomed earlierShould I wash up again?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat210',
        comment: "I'm so cold because I'm wet.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat211',
        comment: "It's a perfect day for a bathHaha",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat212',
        comment: "I'm going to eat a lot of snacks after I wash up.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat213',
        comment: "See? I'm not fat, I'm furry^_^",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat214',
        comment: "I don't want to go in the water!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat215',
        comment: "It's a badI feel like I'm going to take a bath today!!!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat216',
        comment: "I'll take care of it now.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat217',
        comment: "Ugh, it's warm.~~",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat218',
        comment: "It's fun to play in the water.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat219',
        comment: "Oh, I guess you did a good job todayIt's soft.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat220',
        comment: "I don't feel like washing up now...",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat221',
        comment: "I'm thirsty..give me some water!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat222',
        comment: 'A strange smell in the water?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat223',
        comment: "Cut my toenails while I'm washing up!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat224',
        comment: "Butler..you're not gonna let it go.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat225',
        comment: 'Your hands are a little rough todayWash gently.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat226',
        comment: "I can't wait to wash up and lie down!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat227',
        comment: 'I feel drowsy..good!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat228',
        comment: 'Oh, my! Water got in my ear!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat229',
        comment: 'Butler, I want you to be happy!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat230',
        comment: 'Look, I brought it for you!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat231',
        comment: "You're the best at playing with me!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat232',
        comment:
          "You look like you're in a bad moodDo you want to play with the ball?",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat233',
        comment: 'Today I give you the chur!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat234',
        comment: "What's the matter? I'll do it Squeeze for you.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat235',
        comment: "I'm so happy to have a butler!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat236',
        comment: 'I hope I can be of help to you!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat237',
        comment: 'Listen to happy so long as the sound of a plastic bag!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat238',
        comment: "I'll play with you specially today!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat239',
        comment: "It's okay to take a break today.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat240',
        comment: "If you want to be alone, I'll get out of your way.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat241',
        comment: 'You did a great job today!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat242',
        comment: "If you're depressed, wave your tail like me.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat243',
        comment: "I'll let you touch your belly today!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat244',
        comment: "Are you going to be depressed when I'm here?",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat245',
        comment: "Look at me! I'm so cute and depressed!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat246',
        comment: "You're already a cool enough person!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat247',
        comment: "You're the best for me, aren't you?",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat248',
        comment: 'Great job again today.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat249',
        comment: "I'll give you my favorite fishRelax~",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat250',
        comment: 'Do you want to touch my pink jelly?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat251',
        comment: "Eat something delicious and you'll feel better!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat252',
        comment: 'Look at the pretty sky when you feel stuffy!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat253',
        comment: "You can lean on me if you're sad.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat254',
        comment: "You look tiredI'll listen to you!",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat255',
        comment: "You'll feel better if you play with me.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat256',
        comment: "I'm working hard to cheer you up.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat257',
        comment: "I'm always on your side.",
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat258',
        comment: 'A lot of good things will happen in the future!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog1',
        comment: 'Hold on a second! What does it smell like?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog2',
        comment: "Let's go for a walkThe weather is so nice!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog3',
        comment: "Oh, that dog is prettyCan't we go over there?",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog4',
        comment: 'These guys go play some more fun?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog5',
        comment: 'I want to go on a different walking course today!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog6',
        comment: "I'm thirsty! I want to drink water!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog7',
        comment: "Wait a minute! I'm going to pee!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog8',
        comment: 'What does it smell like? It smells delicious.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog9',
        comment: "I'm tired nowLet's go home!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog10',
        comment: "What's that? There's something.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog11',
        comment: "I'm running first! I want to run!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog12',
        comment: "Come on! Let's go!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog13',
        comment:
          'I want to walk like a human beingI want to walk hand in hand!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog14',
        comment: 'Give me a hug! Give me a hug!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog15',
        comment: 'I want to dig in the sand.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog16',
        comment: "I'm gonna take a break!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog17',
        comment: "I'm looking forward to what we're having for dinner tonight.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog18',
        comment:
          'There are a lot of friends over there and I want to go there!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog19',
        comment: "I'm scared of that scary! Let's go around!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog20',
        comment: 'Hooray, hooray, hooray!~Excited~',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog21',
        comment: "I'm in a bad mood today, so don't touch me.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog22',
        comment: 'Can you give me a snack because I feel bad?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog23',
        comment: "I'm so excited today!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog24',
        comment: "I think I had a scary dreamI'm so scared.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog25',
        comment: 'I feel so happy now!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog26',
        comment: "I'm curious! I'm curious!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog27',
        comment: 'I love it!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog28',
        comment: 'Please pay attention to me!!!!!!!!!!!!!!!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog29',
        comment: "I've been bored all day!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog30',
        comment: 'Can you give me a warm heart?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog31',
        comment: "I'm cute, right? I'm lovely, right?",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog32',
        comment: 'Umm….',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog33',
        comment: "What's wrong with you today?",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog34',
        comment: 'Leave me alone, please.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog35',
        comment: 'Why am I so annoyed?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog36',
        comment: 'Stop it.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog37',
        comment: "I'm so angry.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog38',
        comment: 'Why do I feel so down?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog39',
        comment: "I'm so bored.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog40',
        comment: "Don't touch me.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog41',
        comment: 'If you want to order anything, bring me a snack, master!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog42',
        comment: "You'd better leave me alone..",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog43',
        comment: "It's pathetic.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog44',
        comment: "Don't mess with meI'll scold you.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog45',
        comment: "No, it's not.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog46',
        comment: 'You want to try?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog47',
        comment: "You're bothering me, man.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog48',
        comment: 'Stop it! Stop it!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog49',
        comment: 'Why are you making more noise next to me?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog50',
        comment: 'Ugh(Shaking his head)',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog51',
        comment: "What's wrong with him?",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog52',
        comment: "Why don't you just leave me alone?",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog53',
        comment: 'Oh, this is so annoying!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog54',
        comment: 'Hey, stop there! Stay away from me.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog55',
        comment: 'Do you think you did you know my mind.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog56',
        comment: "It's loud.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog57',
        comment: "You told me don't like it,",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog58',
        comment: "Don't call mePlease don't call me.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog59',
        comment: 'Is that enough?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog60',
        comment: 'I want to sleep!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog61',
        comment: 'Leave me alone!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog62',
        comment: 'Get away from me!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog63',
        comment: 'I want to meet my friends!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog64',
        comment: "Don't get away from me!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog65',
        comment: "Let's play together!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog66',
        comment: 'I want to go out and play!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog67',
        comment: 'Open the door!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog68',
        comment: 'Stay with me every day!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog69',
        comment: "You can't go anywhere!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog70',
        comment: "Don't leave me alone.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog71',
        comment: "If I'm home alone, wait and see what happens!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog72',
        comment: "Don't mess with meI'm gonna get mad.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog73',
        comment: 'Bring toys and play with me.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog74',
        comment: 'Look me in the eye.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog75',
        comment: 'I need you to pet me a pat.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog76',
        comment: 'Please change the potty pad to a new one.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog77',
        comment: 'I want to runLet me run!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog78',
        comment: 'Can you make me a friend?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog79',
        comment: "I'm a person!! We're on the same page.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog80',
        comment: 'Change my name to something else.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog81',
        comment: 'I want a bowl of cool water.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog82',
        comment:
          "I'm gonna step on the place where I peed, so please change my potty padBut if I step on it, you'll scold meCome to think of it, it's annoying.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog83',
        comment: 'I ate shitCan I lick you?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog84',
        comment:
          'There\'s a soul in my shit "Puppy shit." Be careful not to make eye contact when you clean up.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog85',
        comment: "Why can't I digest?",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog86',
        comment: "I need to pee, but I'll put up with it.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog87',
        comment: "I need to poop, but I'll put up with it.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog88',
        comment: 'Why does the poop always smell so good? I want to eat it.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog89',
        comment: "I'm good at defecatingPlease compliment me.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog90',
        comment:
          "The reason I didn't pee on the potty pad..It means I'm angry.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog91',
        comment: "Now that we've eaten, shall we get ready to defecate?",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog92',
        comment: "I don't want you to look at me when I poopI'm serious!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog93',
        comment:
          "Don't make me angryI'm gonna hide my shit somewhere in the house.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog94',
        comment:
          "I want to go for a walk and excrete it in the sunOf course, I'm not doing this because I want to go for a walk.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog95',
        comment: 'Why does my potty pad smell like another dog?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog96',
        comment:
          "When do you think my fart will come out? When you have a stomachache or when you poop? No, that's when you're about to go to sleep!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog97',
        comment:
          "I don't think the new meal suits me wellIt's a little hard to poop.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog98',
        comment: 'Express the smell of my poop with a facial expression.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog99',
        comment:
          "I'm going to pee on the shoe rack and make the owner happy today~",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog100',
        comment:
          'From now on, I will defecate exactly in the middle of the potty padSnacks, please.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog101',
        comment: "Give me foodI'll poop.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog102',
        comment: "What's wrong with your meal?",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog103',
        comment: "There's no meat?",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog104',
        comment: 'What are you doing? Give me the food.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog105',
        comment: "Did you give it to me to eat? It's not good!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog106',
        comment: "This feed is the best! It's so delicious><",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog107',
        comment: 'I want some cool water.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog108',
        comment: "They say you shouldn't touch the dog when it's eating_+",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog109',
        comment: 'What are you eating? Give it to me, too.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog110',
        comment: "I don't like vegetables!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog111',
        comment: "What's the menu for today?",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog112',
        comment: "Don't you have any side dishes?",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog113',
        comment: 'I want a snack.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog114',
        comment:
          'Are you the only one eating delicious food? Give me something delicious, too!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog115',
        comment: 'Just give me meat! Meat!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog116',
        comment: "I'm full.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog117',
        comment: 'I want something sweet.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog118',
        comment: "I'm hungry!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog119',
        comment: 'What’s this? That is a drop in the bucket.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog120',
        comment: "Change the feed~ I'm sick of it.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog121',
        comment: "I won't spit it out! I don't want to spit it out!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog122',
        comment: "I have no appetiteI don't want it.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog123',
        comment:
          "When you're tired and frustrated? Let's go for a walk in the park with me!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog124',
        comment: "If you're sad, lean on me!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog125',
        comment: 'You did a great job today.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog126',
        comment: "Are you okay? I'll give you my favorite toyCheer up.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog127',
        comment: "What's going on? Bring her back! I'll teach her a lesson!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog128',
        comment:
          "Someone told me that time is medicine when you're having a hard time.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog129',
        comment: "Look at my face! Isn't it funny? Isn't it cute?",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog130',
        comment:
          "If you're depressed, why don't you go out for a race with me?!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog131',
        comment: "I'm with you! Way to go!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog132',
        comment: 'It’s okayThere will be more good things ahead.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog133',
        comment: "It's okay to cry when you want to!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog134',
        comment: "I hope I'm comforting you.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog135',
        comment: "Don't frown~ I'm here!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog136',
        comment: "Eat something delicious and you'll feel better!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog137',
        comment: "What's the matter? I'll listen to everything!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog138',
        comment: "Don't worry, you're doing well enough!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog139',
        comment: "I'm so happy to be with you.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog140',
        comment:
          "You're the best for me! You're the most important thing to me!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog141',
        comment: "I'll cheer for youCheer up tomorrow!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog142',
        comment: "Cheer up, you're already a wonderful person.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog143',
        comment: 'Hi! Hi!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog144',
        comment: 'Why are you here now? Give me a hug!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog145',
        comment: 'Hurry up and come back!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog146',
        comment: "Are you here? Let's play!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog147',
        comment: 'What time is it now?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog148',
        comment: "Don't leave me.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog149',
        comment: 'Have a safe trip!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog150',
        comment: 'Take care.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog151',
        comment: "Yay! It's here! I'm excited! Play with me!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog152',
        comment: 'Hello!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog153',
        comment: "Who's petting me?",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog154',
        comment: 'Good to see you!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog155',
        comment: 'Delicious snacks on the way here!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog156',
        comment: 'Did you sleep well?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog157',
        comment: '....(Ignore)',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog158',
        comment: '….(Shaking My Head)',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog159',
        comment: 'Get up!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog160',
        comment: 'Hello, my friend!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog161',
        comment: 'Welcome!! Giving me a snack is a must!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog162',
        comment: "What's that in your hand? Did you buy it for me?",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog163',
        comment:
          'Did you have a good time? Thank you for your hard work today!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog164',
        comment: "I don't want to wash up! Let me go!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog165',
        comment: 'Washing up is fun today!!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog166',
        comment: "What's this smell?",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog167',
        comment: "I'm scared to go in the water!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog168',
        comment: "I can't wait to wash up and play!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog169',
        comment: "I'm so nervous right now!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog170',
        comment: "It's too rough! Wash it gently.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog171',
        comment: "I'm so happy right now!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog172',
        comment: "I'm too tired to wash up now!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog173',
        comment: 'The water smells weird.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog174',
        comment: "It's so cold because I'm wet.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog175',
        comment: 'Cut my toenails when I wash up.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog176',
        comment: "I don't feel like washing up now.",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog177',
        comment: 'Will you give me a snack after I wash up?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog178',
        comment: "Let's play with the water!",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog179',
        comment: 'I feel so cool after taking a shower!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog180',
        comment: 'Hurry up and dry my body warmly~',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog181',
        comment: "I'm so thirsty right now...",
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog182',
        comment: "I'm sleepyzzzzzz",
      },
    ],
  },
  // facialExpressionAnalyzer
  {
    info: {
      mainTitle: '표정 분석기',
      subTitle: '강아지, 고양이, 사람 누구나 가능한 표정 분석기',
      mainUrl: 'facialExpressionAnalyzer',
      scoreType: 'facialExpression',
      mainImage:
        'https://images.ktestone.com/introImages/facialExpressionAnalyzer-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/facialExpressionAnalyzer-thumb.png',
      lang: 'Kor',
      category: 'etc',
    },
    questions: [
      {
        question: '데이트 전 날 나의 모습은?',
        answers: [
          {
            type: '포메라니안',
            score: 2,
            content: '나가기 직전 마음에 드는 옷을 입는다.',
          },
          {
            type: '치와와',
            score: 5,
            content: '자기 전 머릿속으로 나의 스타일링을 생각하고 잔다.',
          },
          {
            type: '비글',
            score: 10,
            content: '전 날 머리부터 발끝까지 스타일링과 옷을 미리 준비한다.',
          },
        ],
      },
      {
        question: '갑자기 자취방에 놀러 온다는 애인!\n그때의 나는?',
        answers: [
          {
            type: '포메라니안',
            score: 2,
            content: '아아... 왜 갑자기 오는 거야?! 보이는 곳만 치워 둔다.',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '모아둔 쓰레기를 버리고 바로 청소를 시작한다.',
          },
          {
            type: '리트리버',
            score: 10,
            content: '평소 미리미리 해 두었기 때문에 바로 주소를 보낸다.',
          },
        ],
      },
      {
        question: '좋아하는 이성이 생겼을 때 나는?',
        answers: [
          {
            type: '치와와',
            score: 2,
            content: '상대의 SNS을 탐방하며, 미래의 우리 모습을 상상해본다.',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '좋아하는 이성의 친구에게 도움을 요청한다.',
          },
          {
            type: '리트리버',
            score: 10,
            content: '먼저 연락할 거리를 만들며 적극적으로 대시한다.',
          },
        ],
      },
      {
        question:
          '카페 안에 이상형인 사람과 계속 눈이 마주친다.\n 눈웃음까지 보내는데...?',
        answers: [
          {
            type: '비글',
            score: 2,
            content: '지금 말하면 좋은 말이 안 나올 것 같아. 좀 이따 연락할게.',
          },
          {
            type: '리트리버',
            score: 5,
            content: '아!!!!!!!짜증나!!!!!진짜!!!!!! 도대체 왜 그러는 거야?!?!',
          },
          {
            type: '포메라니안',
            score: 10,
            content:
              '이런 점에서 나는 화가 난 거야. 그런 부분은 고쳐줬으면 좋겠어.',
          },
        ],
      },
      {
        question:
          '쉬고 있는데 갑자기 걸려온 남사친/여사친의 전화, \n친구들이 같이 놀고 싶다며 나올 수 있냐고 물어보는데...?',
        answers: [
          {
            type: '리트리버',
            score: 2,
            content:
              '귀찮은데… 아쉽긴 하지만 “시간이 너무 늦었어… 나 집에서 쉴래!”',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '일단 끊고 수십 번 고민 끝에 나가기로 한다.',
          },
          {
            type: '비글',
            score: 10,
            content: '오케이~ 인연 한 번 만들어보자~ “갈게~~~~”',
          },
        ],
      },
      {
        question: '다가온 기념일! 나는 어떤 선물을 준비할까?',
        answers: [
          {
            type: '포메라니안',
            score: 2,
            content: '정성 어린 편지와 기억에 남을 만한 선물을 준비한다.',
          },
          {
            type: '비글',
            score: 5,
            content: '커플로 착용할 수 있는 아이템을 선물한다.',
          },
          {
            type: '리트리버',
            score: 10,
            content:
              '평소 사고 싶다거나 필요한 물건을 기억해 두었다가 서프라이즈로 선물한다.',
          },
        ],
      },
      {
        question: '내가 하고 싶은 연애 스타일은?',
        answers: [
          {
            type: '비글',
            score: 2,
            content:
              '다이나믹한 화려한 연애 (단, 너무 화려해서 바람 피울 가능성 50%)',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '나만 바라보는 연애 (단, 너무 좋아해서 집착 대마왕)',
          },
          {
            type: '포메라니안',
            score: 10,
            content: '친구같이 편안한 연애 (단, 가끔 친구인지 애인인지 헷갈림)',
          },
        ],
      },
      {
        question: '애인과 대화할 때 나는?',
        answers: [
          {
            type: '치와와',
            score: 5,
            content: '장난치고 티키타카가 잘 되는 대화가 좋다.',
          },
          {
            type: '리트리버',
            score: 10,
            content: '서로의 생각을 공유하는 대화가 너무 좋다.',
          },
        ],
      },
      {
        question: '친구가 나에게 고민상당을 한다. 나의 반응은?',
        answers: [
          {
            type: '포메라니안',
            score: 5,
            content: '고민에 대한 구체적인 해결책을 제시한다.',
          },
          {
            type: '웰시코기',
            score: 10,
            content: '고민에 대해 빠져들어 감정적으로 같이 공감해준다.',
          },
        ],
      },
      {
        question:
          '기다려왔던 짝사랑하던 그/그녀와의 데이트 날..\n갑자기 급한 일이 생겼다며 다음에 만나자고 한다.\n과연 나의 대답은?',
        answers: [
          {
            type: '비글',
            score: 2,
            content: '어쩔 수 없지… 알겠어 다음에 보자!',
          },
          {
            type: '치와와',
            score: 5,
            content: '알겠어…근데 심각한 일이야?',
          },
          {
            type: '웰시코기',
            score: 10,
            content: '아 진짜? 그럼 날짜 다시 조정하자! 언제가 괜찮아?',
          },
        ],
      },
    ],
    results: [
      {
        type: 'human',
        desc: '',
        query: 'human1',
        comment: '나 좀 안아줄 수있어?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human2',
        comment: '내눈 앞에서 혹시 사라져줄 수 있어 ?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human3',
        comment: '주모 여기 물 한잔만 주쇼',
      },
      {
        type: 'human',
        desc: '',
        query: 'human4',
        comment: '나랑 오늘 같이 있을래 ?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human5',
        comment: '내눈 좀 똑바로 봐!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human6',
        comment: '쉬고 싶다~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human7',
        comment: '내일 일 나가기 싫어',
      },
      {
        type: 'human',
        desc: '',
        query: 'human8',
        comment: '솔로인지 언 년 째 나는 누구인가?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human9',
        comment: '나는 누구인가 여긴 어디인가',
      },
      {
        type: 'human',
        desc: '',
        query: 'human10',
        comment: '친구 좀 만들어줘...',
      },
      {
        type: 'human',
        desc: '',
        query: 'human11',
        comment: '인생은 역시 혼자야',
      },
      {
        type: 'human',
        desc: '',
        query: 'human12',
        comment: '_ 고독 하구만',
      },
      {
        type: 'human',
        desc: '',
        query: 'human13',
        comment: '로또가 당첨 되고 싶은 날씨야',
      },
      {
        type: 'human',
        desc: '',
        query: 'human14',
        comment: '신이시여 왜 나를 이 지구에 태어난게 한것이오',
      },
      {
        type: 'human',
        desc: '',
        query: 'human15',
        comment: '내일 퇴사 예정 입니다.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human16',
        comment: '하루 종일 누워서 핸드폰 하고 싶다',
      },
      {
        type: 'human',
        desc: '',
        query: 'human17',
        comment: '역시 오늘 저녁은 떡볶이다',
      },
      {
        type: 'human',
        desc: '',
        query: 'human18',
        comment: '하와이로 여행 가고싶어',
      },
      {
        type: 'human',
        desc: '',
        query: 'human19',
        comment: '내가 넣은 주식 상한가 가즈아!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human20',
        comment: '돈 많은 백수가 되고 싶어',
      },
      {
        type: 'human',
        desc: '',
        query: 'human21',
        comment: '음악은 나에게 허락 된 유일한 마약이야',
      },
      {
        type: 'human',
        desc: '',
        query: 'human22',
        comment: '나는 부자가 될 상이야',
      },
      {
        type: 'human',
        desc: '',
        query: 'human23',
        comment: '제주도로 떠나고 싶어',
      },
      {
        type: 'human',
        desc: '',
        query: 'human24',
        comment: '다이어트 그만하고 그냥 막 왁창 먹고싶어!!!!!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human25',
        comment: '나 쳐다보지마',
      },
      {
        type: 'human',
        desc: '',
        query: 'human26',
        comment: '돈좀 빌려줄 수 있어 ?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human27',
        comment: '아..년전에 비트코인 살껄...삿어야했어',
      },
      {
        type: 'human',
        desc: '',
        query: 'human28',
        comment: '로또 당첨 되면 나 반 줄 수 있어?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human29',
        comment: '나한테 말 시키지마',
      },
      {
        type: 'human',
        desc: '',
        query: 'human30',
        comment: '내 매력에 넌 곧 빠져들꺼야 ^>^',
      },
      {
        type: 'human',
        desc: '',
        query: 'human31',
        comment: '어허~ 이렇게 집구석에만 있을꺼야?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human32',
        comment: '영화 보러가자~ 가위바위보 해서 영화 티켓구매 어때?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human33',
        comment: '집밥은 지겨워~ 맛있는거 먹으러 나갈래?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human34',
        comment: '조깅하러가자! 너 운동 좀 해야할꺼같아',
      },
      {
        type: 'human',
        desc: '',
        query: 'human35',
        comment: '넌 조만간 공처럼 굴러 다닐것같은데? 자! 운동하러가야지',
      },
      {
        type: 'human',
        desc: '',
        query: 'human36',
        comment:
          '너의 목을 보아하니, 거북이가 말을 걸겠구나, 잠깐 산책하러가자',
      },
      {
        type: 'human',
        desc: '',
        query: 'human37',
        comment:
          '저기 뭐 맛있는거 생겼데~ 저번에 내가 샀으니까 오늘 너가 좀 사',
      },
      {
        type: 'human',
        desc: '',
        query: 'human38',
        comment: '드라이브 하러 가자! 지금!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human39',
        comment: '우리 여행갈래? 갑자기 계획없이 여행 가보고싶어',
      },
      {
        type: 'human',
        desc: '',
        query: 'human40',
        comment: '오늘 집에만 있었더니 지겨워~ 잠깐 나갔다올래',
      },
      {
        type: 'human',
        desc: '',
        query: 'human41',
        comment: '지금 몇시지? 아 맞다 오늘 너가 나 맛있는거 사주는 날이다!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human42',
        comment: '오늘 무슨요일이지? 아 맞다 오늘 우리 볼링치기로 했었어',
      },
      {
        type: 'human',
        desc: '',
        query: 'human43',
        comment: '오늘 몇일이지? 아 맞다 오늘 우리 카페 가서 빙고 하기로 했어',
      },
      {
        type: 'human',
        desc: '',
        query: 'human44',
        comment: '오늘 우리 약속 있었는데? 맞아! 너가 고기 사준다고 했었어!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human45',
        comment: '지금 나와~! 앞에서 기다리고 있을께 너 올때까지 기다릴꺼야',
      },
      {
        type: 'human',
        desc: '',
        query: 'human46',
        comment: '오늘 나 몸이 안좋아 , 아프니까 먹을것좀 보내줘',
      },
      {
        type: 'human',
        desc: '',
        query: 'human47',
        comment: '나 다리 아파, 업어줘',
      },
      {
        type: 'human',
        desc: '',
        query: 'human48',
        comment: '나 애인이랑 오늘 약속있어 미안',
      },
      {
        type: 'human',
        desc: '',
        query: 'human49',
        comment: '너 어제 누구랑 있었어? 나 어제 너 다른사람이랑 있는거 봤어',
      },
      {
        type: 'human',
        desc: '',
        query: 'human50',
        comment: '그 사람 누구야? 어제 손잡고 가던데',
      },
      {
        type: 'human',
        desc: '',
        query: 'human51',
        comment: '나 어젯밤에 그 사람이랑 산책다녀왔어 너무 ..좋았어',
      },
      {
        type: 'human',
        desc: '',
        query: 'human52',
        comment: '나 어제 또 그 사람이랑 시간동안 산책하고 왔어',
      },
      {
        type: 'human',
        desc: '',
        query: 'human53',
        comment: '이제 그만하자더이상은 ..무리야',
      },
      {
        type: 'human',
        desc: '',
        query: 'human54',
        comment:
          '이 멘트 쓰는 것도 지겨워, 할말도 없어, 그만하고싶어 (쉿! 사장이 몰랐으면해)',
      },
      {
        type: 'human',
        desc: '',
        query: 'human55',
        comment: '어제 산책갔다가 가방을 하나 발견했는데,, 억이 있었어..',
      },
      {
        type: 'human',
        desc: '',
        query: 'human56',
        comment: '어제 나 경찰서 다녀왔어휴',
      },
      {
        type: 'human',
        desc: '',
        query: 'human57',
        comment: '어제 나.살인사건을 목격했어!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human58',
        comment: '오늘 뭐해? 나랑...데이트 할래? 풋',
      },
      {
        type: 'human',
        desc: '',
        query: 'human59',
        comment: '미안 .나 사랑하는 사람이 생겼어.오늘 그사람 만나',
      },
      {
        type: 'human',
        desc: '',
        query: 'human60',
        comment: '오늘은 내가 특별히 쏜다신나게 놀 준비 해.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human61',
        comment: '오~ 내 기분 마치 신라~~~~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human62',
        comment: '짜증나니까 제발 저리 가라.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human63',
        comment: '외롭구나.호그와트학년',
      },
      {
        type: 'human',
        desc: '',
        query: 'human64',
        comment: '린생이란…고단하다 고단해',
      },
      {
        type: 'human',
        desc: '',
        query: 'human65',
        comment: '기분 안좋으니까 한잔 할래?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human66',
        comment: '아니 왤케 짜증나게 해?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human67',
        comment: '우울함이 하늘을 찌르는 구나',
      },
      {
        type: 'human',
        desc: '',
        query: 'human68',
        comment: '사랑한다이자슥아',
      },
      {
        type: 'human',
        desc: '',
        query: 'human69',
        comment: '흣짜! 진행시켜~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human70',
        comment: '나 지금 ~~~ 오늘 밤은~~~ 어둠이 무서워용~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human71',
        comment: '어우…살짝 토할 것 같은데?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human72',
        comment: '어휴 개노잼 너무 노잼',
      },
      {
        type: 'human',
        desc: '',
        query: 'human73',
        comment: '기분이 우울하다! 뭐 재밌는 거 없니?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human74',
        comment: '나 오늘 울고싶으니까 안아주라',
      },
      {
        type: 'human',
        desc: '',
        query: 'human75',
        comment: '생각 없이 말하는 인자',
      },
      {
        type: 'human',
        desc: '',
        query: 'human76',
        comment: '밥 많이 먹었니? 어쩐지 그래보이더라.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human77',
        comment: '인내심의 한계를 경험하는 중',
      },
      {
        type: 'human',
        desc: '',
        query: 'human78',
        comment: '보고싶다',
      },
      {
        type: 'human',
        desc: '',
        query: 'human79',
        comment: '지금 사랑하는 사람을 생각하고 있어요.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human80',
        comment: '오늘 하루도 고생한 나를 위한 치킨을 생각하면 설레는 중!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human81',
        comment: '자도자도 피로가 안풀리냐…',
      },
      {
        type: 'human',
        desc: '',
        query: 'human82',
        comment: '나 좀 내비둬! 가만히 있어도 피곤한 사람을!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human83',
        comment: '오~ 쉽지 않은데!?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human84',
        comment: '지금 혹시 지쳤나요?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human85',
        comment: '슬슬 새어나오는 한숨',
      },
      {
        type: 'human',
        desc: '',
        query: 'human86',
        comment: '서로를…아끼고…사랑하고…',
      },
      {
        type: 'human',
        desc: '',
        query: 'human87',
        comment: '친구들아…고맙다…',
      },
      {
        type: 'human',
        desc: '',
        query: 'human88',
        comment: 'ㄴrㄴ…ㄱr끔…눈물을…흘린ㄷr…^^',
      },
      {
        type: 'human',
        desc: '',
        query: 'human89',
        comment: '하지말라는 것 좀 하지마',
      },
      {
        type: 'human',
        desc: '',
        query: 'human90',
        comment: '훠우~~~~파티구나나아아아~~~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human91',
        comment: '아무 생각이 없다왜냐면 아무 생각이 없기 때문이다.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human92',
        comment: '나 여기 왜 있지…',
      },
      {
        type: 'human',
        desc: '',
        query: 'human93',
        comment: '집에 가고 싶다…',
      },
      {
        type: 'human',
        desc: '',
        query: 'human94',
        comment: '좋냐..?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human95',
        comment: '아이고 의미없다',
      },
      {
        type: 'human',
        desc: '',
        query: 'human96',
        comment: '먹을 때 건드리지마',
      },
      {
        type: 'human',
        desc: '',
        query: 'human97',
        comment: '들을 가치가 없네ㅎ 안녕',
      },
      {
        type: 'human',
        desc: '',
        query: 'human98',
        comment: '어쩔..?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human99',
        comment: '아 드럽게 귀찮게 하네!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human100',
        comment: '아유… 하기 싫어…',
      },
      {
        type: 'human',
        desc: '',
        query: 'human101',
        comment: '알게뭐냐… 난 내 갈 길 간다…',
      },
      {
        type: 'human',
        desc: '',
        query: 'human102',
        comment: '나는 안 들린다~ 안 들려~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human103',
        comment: '안 나가고 집에만 있을 겁니다!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human104',
        comment: '그런 거 나한테 기대하지 마',
      },
      {
        type: 'human',
        desc: '',
        query: 'human105',
        comment: '혈압 오르는 중',
      },
      {
        type: 'human',
        desc: '',
        query: 'human106',
        comment: '제가 왜요',
      },
      {
        type: 'human',
        desc: '',
        query: 'human107',
        comment: '혼자 있고 싶어요',
      },
      {
        type: 'human',
        desc: '',
        query: 'human108',
        comment: '하루종일 누워있고만 싶어라',
      },
      {
        type: 'human',
        desc: '',
        query: 'human109',
        comment: '그래 (귀찮다)',
      },
      {
        type: 'human',
        desc: '',
        query: 'human110',
        comment: '인생은 원래 되는대로 사는 거야..',
      },
      {
        type: 'human',
        desc: '',
        query: 'human111',
        comment: '어차피 나는 우주의 먼지에 불과한 존재인 것을…',
      },
      {
        type: 'human',
        desc: '',
        query: 'human112',
        comment: '앉으면 눕고 싶은 것이 사람의 마음',
      },
      {
        type: 'human',
        desc: '',
        query: 'human113',
        comment: '못마땅',
      },
      {
        type: 'human',
        desc: '',
        query: 'human114',
        comment: '삶의 의욕 상실',
      },
      {
        type: 'human',
        desc: '',
        query: 'human115',
        comment: '로또 당첨되고 싶다..ㅎ',
      },
      {
        type: 'human',
        desc: '',
        query: 'human116',
        comment: '오늘은 여기까지해야겠다.내일의 내가 하겠지',
      },
      {
        type: 'human',
        desc: '',
        query: 'human117',
        comment: '그게 무슨 의미가 있나~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human118',
        comment: '지겨워~~ 지겨워~~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human119',
        comment: '그럴 수도 있지',
      },
      {
        type: 'human',
        desc: '',
        query: 'human120',
        comment: '때려쳐야겠다^_^',
      },
      {
        type: 'human',
        desc: '',
        query: 'human121',
        comment: '왔어?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human122',
        comment: '앗 안녕..!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human123',
        comment: '잘 지내!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human124',
        comment: '이야~ 몸 좀 좋아졌는데?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human125',
        comment: '야 진짜 오랜만이다!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human126',
        comment: '좋은 아침!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human127',
        comment: '언제 밥 한번 먹자~ (예의상 하는 말)',
      },
      {
        type: 'human',
        desc: '',
        query: 'human128',
        comment: '야 나 간다!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human129',
        comment: '하이!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human130',
        comment: '야! 여기야! 여기!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human131',
        comment: '진짜 언제 한번 보자~ (길가다 마주칠 확률이 더 높다)',
      },
      {
        type: 'human',
        desc: '',
        query: 'human132',
        comment:
          '.야!!!!! 너 뭐야???!! (오랜만이라 반가운데 할말은 없어서 일단',
      },
      {
        type: 'human',
        desc: '',
        query: 'human133',
        comment: '소리지른다)',
      },
      {
        type: 'human',
        desc: '',
        query: 'human134',
        comment: '먼저 간다!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human135',
        comment: '한잔 해야지?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human136',
        comment: '여!~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human137',
        comment: '너 살빠진거 같은데?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human138',
        comment: '잘자♥',
      },
      {
        type: 'human',
        desc: '',
        query: 'human139',
        comment: '안녕..><',
      },
      {
        type: 'human',
        desc: '',
        query: 'human140',
        comment: '잘 지냈어?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human141',
        comment: '지금 시간이 몇시야?..',
      },
      {
        type: 'human',
        desc: '',
        query: 'human142',
        comment: '수고했다!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human143',
        comment: '배고파!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human144',
        comment: '조심해서가!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human145',
        comment: '잘가!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human146',
        comment: '밥은 먹고 다니냐?..',
      },
      {
        type: 'human',
        desc: '',
        query: 'human147',
        comment: '어디갈래?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human148',
        comment: '야 미안미안 늦었다..',
      },
      {
        type: 'human',
        desc: '',
        query: 'human149',
        comment: '뭐 할래?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human150',
        comment: '어머 너무 예뻐졌다!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human151',
        comment: '나 오늘 뭐 달라진거 없어?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human152',
        comment: '와씨 실화냐? 나 씻어야해?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human153',
        comment: '오늘은 과감하게 양치질 패스한다.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human154',
        comment: '세수는 왜 해야하는건데?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human155',
        comment: '테이크어 샤워 해야지 뽀송뽀송~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human156',
        comment: '난 씻을 때 머리부터 감아 그게 좋거든',
      },
      {
        type: 'human',
        desc: '',
        query: 'human157',
        comment: '한 여름에도 뜨거운 물로 샤워하는 나는야 핫소스~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human158',
        comment: '진짜 씻겨주는 기계 나온다고 하면 주식부터 산다.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human159',
        comment: '와 오늘은 씻기 너무 귀찮은데',
      },
      {
        type: 'human',
        desc: '',
        query: 'human160',
        comment: '나 좀 씻겨 줄래?^^',
      },
      {
        type: 'human',
        desc: '',
        query: 'human161',
        comment: '물 온도 어때?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human162',
        comment: '입욕제 없으면 목욕 안함.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human163',
        comment: '씻고나면 바나나 우유는 국룰',
      },
      {
        type: 'human',
        desc: '',
        query: 'human164',
        comment: '오늘은 뽀송하게 가아~~~~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human165',
        comment: '샤워 후 노곤노곤하니~~~ 맥주다!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human166',
        comment: '잠깐 있다가 씻으려고 하니 벌써 새벽임..ee',
      },
      {
        type: 'human',
        desc: '',
        query: 'human167',
        comment: '샤워 하고 거울 탁! 이정도면 괜찮군!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human168',
        comment: '씻고 나면 매번 다른 사람 등장!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human169',
        comment: '안씻을래…어제 씻었어…',
      },
      {
        type: 'human',
        desc: '',
        query: 'human170',
        comment: '최소 결벽증…',
      },
      {
        type: 'human',
        desc: '',
        query: 'human171',
        comment: '샴푸린스트리트먼트바디워시스크럽팩 다함.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human172',
        comment: '너에게선 늘 향긋한 냄새가 나혹시 씻었니?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human173',
        comment: '너에게선 늘 방구 냄새가 나혹시 안 씻었니?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human174',
        comment: '그냥 좀 씻어라.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human175',
        comment: '머리부터 발끝까지~ 야무지게 씻어야징~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human176',
        comment: '외출후 손씻기와 발씻기',
      },
      {
        type: 'human',
        desc: '',
        query: 'human177',
        comment: '손톱까지 깨끗하게! 맑게! 자신있게에~!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human178',
        comment: '가글 말고 양치로 부탁해!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human179',
        comment: '입에서 그런 냄새가 난다는건 씻어야한다는 소리야',
      },
      {
        type: 'human',
        desc: '',
        query: 'human180',
        comment: '너는 코가 혹시 없는 것이니?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human181',
        comment: '난다요~냄새가! 난다요!~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human182',
        comment: '배고파 밥좀 줄래?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human183',
        comment: 'OO아 국이 좀 짜다',
      },
      {
        type: 'human',
        desc: '',
        query: 'human184',
        comment: '이걸 밥이라고 주는거니?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human185',
        comment: '오늘 반찬은 뭐야?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human186',
        comment: '나는 채식주의자야!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human187',
        comment: '고기없이는 밥 안먹어!!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human188',
        comment: '인스턴트가 최고야!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human189',
        comment: '아~배부르다~~~~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human190',
        comment: '야채싫어!!!!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human191',
        comment: '양이 너무 적어! 조금 더 줘!!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human192',
        comment: '너무 많아~ 덜어줘!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human193',
        comment: '꼬르륵.....ㄹㄹ',
      },
      {
        type: 'human',
        desc: '',
        query: 'human194',
        comment: '오늘은 떡볶이다!!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human195',
        comment: '달달한게 땡기네~~~~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human196',
        comment: '더우니까 아이스크림 먹을까??',
      },
      {
        type: 'human',
        desc: '',
        query: 'human197',
        comment: '추워..따뜻한 국물먹고싶어!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human198',
        comment: '입맛없어.안먹을래',
      },
      {
        type: 'human',
        desc: '',
        query: 'human199',
        comment: '다엿트 중!! 내 눈앞에서 음식 치워라!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human200',
        comment: '한입만~!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human201',
        comment: '뭐야~? 완전 맛있어!! JMT',
      },
      {
        type: 'human',
        desc: '',
        query: 'human202',
        comment: '이것도 요리라고 했냐? 맛없어 퉤!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human203',
        comment: '시~원한 물 한잔 내와라~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human204',
        comment: '맛집투어 하고싶어! 나가자~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human205',
        comment: '야식 콜 ~~?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human206',
        comment: '족발어때? 대박 좋지~~?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human207',
        comment: '먹을땐 개도 안건드려ㅡㅡ',
      },
      {
        type: 'human',
        desc: '',
        query: 'human208',
        comment: '첩반상을 내놓으시오!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human209',
        comment: '소고기 사주세요오오오오~~!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human210',
        comment: '윽..그거 왜 먹어?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human211',
        comment: '너무 걱정하지마 다 잘될거야!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human212',
        comment: '지나간 일은 지나간대로 잊어버려~',
      },
      {
        type: 'human',
        desc: '',
        query: 'human213',
        comment: '힘들고 답답할 땐 산책가는건 어때?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human214',
        comment: '오늘 하루도 정말 고생많았어!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human215',
        comment: '슬프면 나한테 기대도 돼',
      },
      {
        type: 'human',
        desc: '',
        query: 'human216',
        comment: '힘들 땐 시간이 약이래이 또한 지나가리라!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human217',
        comment: '네 옆에는 내가 있잖아힘내!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human218',
        comment: '울고 싶을 땐 울어도 괜찮아',
      },
      {
        type: 'human',
        desc: '',
        query: 'human219',
        comment: '너한테 내가 위로가 됐으면 좋겠어.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human220',
        comment: '누가 뭐라해도 너가 최고야',
      },
      {
        type: 'human',
        desc: '',
        query: 'human221',
        comment: '너를 아껴주는 사람들을 생각해봐!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human222',
        comment: '맛있는거 먹으면 기분이 좀 나아질거야!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human223',
        comment: '무슨일 있어? 내가 다 들어줄게',
      },
      {
        type: 'human',
        desc: '',
        query: 'human224',
        comment: '누가 널 힘들게 한거야? 데려와!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human225',
        comment: '네 존재 자체로도 빛이 나',
      },
      {
        type: 'human',
        desc: '',
        query: 'human226',
        comment: '언제나 응원할게 내일도 화이팅!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human227',
        comment: '나한테는 너가 최고야제일 소중해',
      },
      {
        type: 'human',
        desc: '',
        query: 'human228',
        comment: '기운내, 이미 넌 멋있는 사람이야',
      },
      {
        type: 'human',
        desc: '',
        query: 'human229',
        comment: '울고 싶을 땐 울어도 괜찮아',
      },
      {
        type: 'human',
        desc: '',
        query: 'human230',
        comment: '남들과 비교할 필요없어! 너 자체가 빛나',
      },
      {
        type: 'human',
        desc: '',
        query: 'human231',
        comment: '너도 이미 누군가에겐 소중한 사람이야',
      },
      {
        type: 'human',
        desc: '',
        query: 'human232',
        comment: '수고했어, 오늘도',
      },
      {
        type: 'human',
        desc: '',
        query: 'human233',
        comment: '응원할게, 내일도',
      },
      {
        type: 'human',
        desc: '',
        query: 'human234',
        comment: '고마워 항상 노력해줘서',
      },
      {
        type: 'human',
        desc: '',
        query: 'human235',
        comment: '걱정마 충분히 잘하고 있어!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human236',
        comment: '괜찮아 지금처럼만 하면돼',
      },
      {
        type: 'human',
        desc: '',
        query: 'human237',
        comment: '우울하면 맛있는거 먹으러 갈까?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human238',
        comment: '기분이 울적할 땐? 여행을 떠나요~!',
      },
      {
        type: 'human',
        desc: '',
        query: 'human239',
        comment: '때로는 나만의 시간을 갖는 것도 필요해',
      },
      {
        type: 'human',
        desc: '',
        query: 'human240',
        comment: '행복은 소소한 곳에서부터 찾는거래',
      },
      {
        type: 'human',
        desc: '',
        query: 'human241',
        comment: '똥을 일째 못싸서 힘들어..',
      },
      {
        type: 'human',
        desc: '',
        query: 'human242',
        comment: '하.내 똥냄새 너무 심한 것 같아 룸스프레이 들고다녀야겠다.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human243',
        comment: '저번에 화장실 물 안내렸는데 아무도 모르겠지?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human244',
        comment: '아침에 일어나서 싸는 똥은 행복입니다.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human245',
        comment: '지금 나를 똥쟁이로 생각하는건 아니겠지?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human246',
        comment: '나는 오줌을 시간이나 참을 수 있어.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human247',
        comment: '야채만 먹으면 초록색 똥이 나온데 신기하지?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human248',
        comment: '똥마려워',
      },
      {
        type: 'human',
        desc: '',
        query: 'human249',
        comment: '오줌마려워',
      },
      {
        type: 'human',
        desc: '',
        query: 'human250',
        comment: '똥 콸콸 나오기 직전까지 참았다가 싼 사람의 표정입니다(행복)',
      },
      {
        type: 'human',
        desc: '',
        query: 'human251',
        comment: '가끔은 내 장한테 미안해너무 무거운 음식만 보내서',
      },
      {
        type: 'human',
        desc: '',
        query: 'human252',
        comment: '야 나 진짜 못참겠어 화장실 좀',
      },
      {
        type: 'human',
        desc: '',
        query: 'human253',
        comment: '여기 화장실이 어디야?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human254',
        comment: '난 푸세식 화장실이 그리워 힘 주기가 편하거든',
      },
      {
        type: 'human',
        desc: '',
        query: 'human255',
        comment: '아.어제 뭐 잘못 먹었나 배가 왜이렇게 아프지',
      },
      {
        type: 'human',
        desc: '',
        query: 'human256',
        comment: '설사할 때 배가 아픈 이유는 뭘까?',
      },
      {
        type: 'human',
        desc: '',
        query: 'human257',
        comment:
          '옛날에 똥을 침대에 싸면서 쾌감을 느끼는 사람이 있다던데 그게 나야.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human258',
        comment: '화장실이 쾌적하면 기분이 좋아.',
      },
      {
        type: 'human',
        desc: '',
        query: 'human259',
        comment: '아무리 건물이 좋아도 화장실이 더러우면 짜증부터 나',
      },
      {
        type: 'human',
        desc: '',
        query: 'human260',
        comment: '나 어제 이불에 오줌쌌어.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat1',
        comment: '츄르 가져다줘',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat2',
        comment: '나 만지지 말아줄래 ?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat3',
        comment: '혼자 있게 냅둬줘',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat4',
        comment: '집사야 다른 고양이 쳐다보지마!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat5',
        comment: '집사는 나만보고 나만 사랑하고 나만을 챙겨',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat6',
        comment: '조용히좀 해줘',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat7',
        comment: '나는 시끄러운건 딱 질색이야',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat8',
        comment: '나랑 소통하고 싶으면 눈을 깜박여',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat9',
        comment: '집사한테 비비고 싶은데!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat10',
        comment: '내가 허락할 때만 날 만질 수 있어',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat11',
        comment: '생선좀 챙겨줘봐',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat12',
        comment: '집에 올 때 박스좀 가져와줘',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat13',
        comment: '비닐안에 들어가서 혼자있고 싶어',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat14',
        comment: '지금 건들면 두고봐!!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat15',
        comment: '냥냥펀치 맞아볼래 ?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat16',
        comment: '장난감 가져와! 나랑 놀자!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat17',
        comment: '나 심심해!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat18',
        comment: '졸리니까 가만히 냅둬 자게',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat19',
        comment: '일로와바',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat20',
        comment: '저리로 가줄래 ?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat21',
        comment: '적당한 온도,습도 모든게 맘에 들어 편해',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat22',
        comment: '날 만질 수 있는 분위는 턱 밑끝',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat23',
        comment: '장난감 좀 다양하게 부탁할게',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat24',
        comment: '내발은 아주 귀여운 핑크 스똬일이야 ^>^',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat25',
        comment: '꾹꾹이좀 하게 일로와바',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat26',
        comment: '신상 츄르좀 사올 수 있겠어 ?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat27',
        comment: '졸린데 자기 시른 이 기분을 알아 ?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat28',
        comment: '집사 요즘 너무 맘에 들어',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat29',
        comment: '재밌는 일이 좀 없을까 ?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat30',
        comment: '난 수염으로 이세상을 지배 할 수 있어',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat31',
        comment: '어제 밤에 무슨 일이 있었는지 모르지 ?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat32',
        comment: '산책 따위는 가지 않는다는 것을 알고 있겠지??',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat33',
        comment: '산책은 강아지나 가는거야.난 위대한 고양이다',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat34',
        comment: '위대한 고양이는 산책을 가지 않는다',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat35',
        comment: '위대한 고양이는 끌려다니지 않는다',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat36',
        comment: '밖에 나갈일 있다면 나를 안고 나가거라',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat37',
        comment: '이쁜 고양이 알고 있다면 나에게 소개해주어라',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat38',
        comment: '배가 고프구나, 먹을것을 달라',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat39',
        comment: '오늘은 쉬고 싶구나, 나를 건들지 마라',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat40',
        comment: '오래 걷기 싫다내 귀여운 젤리가 닳는단 말이다',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat41',
        comment: '나는 바람이 선선한 날이 좋다오늘이 그때다',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat42',
        comment: '나를 안을때 조심스레 안도록 하라, 두손으로 말이다!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat43',
        comment: '흙밟는건 기분이 좋지않구나 나를 안아라',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat44',
        comment: '아스팔트는 영 딱딱한게 기분이 좋지 않구나, 나를 안아라',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat45',
        comment: '햇빛이 너무 쎈날은 나가지 말도록하여라 나의 피부를 지키거라',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat46',
        comment: '오늘은 좀 피곤하구나, 식사를 대령하라~',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat47',
        comment: '털이 좀 뭉치는 하루구나 나의 털 빗어라',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat48',
        comment: '밖이 왜이리 소란이냐  조용한 곳으로 가자',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat49',
        comment: '언제 외식이나 한번 하러 가자 집밥이 지겹구나',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat50',
        comment: '요즘은 생쥐가 도통 보이지 않는구나 , 나가서 찾아보고오너라',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat51',
        comment: '앗 저건뭐지!! 날쌘 놈이 구나! 잡아라!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat52',
        comment: '오늘은 왠지 점프하기 좋은날이구나',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat53',
        comment: '뭔가 날쌘 놈을 잡고 싶구나~ 오늘은 헌팅데이다!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat54',
        comment: '쥐를 잡자 쥐를 잡자 찍찍찍',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat55',
        comment: '나의 눈빛을 읽었느냐! 오늘은 여유를 즐기고 싶구나',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat56',
        comment: '나의 동작은 벌처럼 빠르고, 나의 털은 솜처럼 부드럽다!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat57',
        comment: '오늘 너무 재미있구나 자 이제 집으로가자',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat58',
        comment: '좋아좋아 나가자!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat59',
        comment: '요즘 집에만 있었더니 몸이 답답하구나 !',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat60',
        comment: '나 살찐것 같아? 산책좀 해볼까?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat61',
        comment: '잠깐 이것은 뭐지? 나 뭔가 발견한것 같아',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat62',
        comment: '와 오늘 왤케 기분이 좋지?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat63',
        comment: '아이고 자도자도 졸리니',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat64',
        comment: '아무것도 하고 싶지 않구나',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat65',
        comment: '나 좀 예민하니까 돈터치해라',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat66',
        comment: '헤이헤이헤이! 기분도 꿀꿀한데 간식 좀 주라',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat67',
        comment: '에? 그거 뭐야? 궁금해',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat68',
        comment: '왜 자꾸 쳐다보는거야',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat69',
        comment: '저게 뭐야?! 너무 무서워ㅜㅠ',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat70',
        comment: '괜찮아? 왜구래? 힘드렁?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat71',
        comment: '와 너무 무서워 덜덜이다 진짜',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat72',
        comment: '나 지금 집중 하고 있으니까 말해봐',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat73',
        comment: '집착하게 하지마라~?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat74',
        comment: '할수만 있다면 한대 때려도 될까?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat75',
        comment: '극도의 흥분 상태',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat76',
        comment: '그래그래~ 믿어준다 내가~',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat77',
        comment: '뭐 좀 재밌는 거 없니?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat78',
        comment: '쟤는 왜 또 저러는거야?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat79',
        comment: '헤헤헤헤 기분 좋다!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat80',
        comment: '기분 좋으니까 내 배를 쓰담 해줄 수 있는 기회를 줄게',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat81',
        comment: '난 널 너무너무 좋아해',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat82',
        comment: '늘 고맙다!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat83',
        comment: '심심한데 나랑 놀아줄래?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat84',
        comment: '이제 저거 지겨워! 새로운 장난감 사주라!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat85',
        comment: '나 놀고시퍼어어어어!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat86',
        comment: '나 좀 예뻐해줄래? 부탁아니고 명령이야!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat87',
        comment: '나 건들이는 거 너무 싫어혼자 있을래',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat88',
        comment: '나 좀 안아주라!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat89',
        comment: '이해안가는게 맨날 너 혼자 맛있는 거 먹더라?ㅡㅡ',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat90',
        comment: '술 좀 작작마셔라 인간아~',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat91',
        comment: '내가 자는 거에 대해서 불만있어? 그럼 간직만해',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat92',
        comment: '집사나 지금 마렵다똥.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat93',
        comment: '당장 모래를 새걸로 갈지 않으면 신발장을 어지럽혀주겠어.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat94',
        comment: '내 몸에 거대한 무언가가 나오려고 한다',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat95',
        comment: '나는 오줌이 마려운데 마렵지 않다.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat96',
        comment: '항상 생각하지만 화장실이 내 스타일이 아니야.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat97',
        comment: '미안해저번에 내가 오줌을 바닥에 눴지.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat98',
        comment: '아까 너무 많이 먹었나장이 요동친다.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat99',
        comment: '집사내 변 냄새에는 꽃향기가 난다 동의하는가?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat100',
        comment: '쾌변은 항상 옳다.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat101',
        comment: '화장실의 위치를 바꿔줘라햇빛이 들어오는 위치로.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat102',
        comment: '내 털에 오줌이 묻은 것 같다 확인좀 해줘.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat103',
        comment: '사료를 좀 바꿔보는게 어떤가 집사소화가 잘 안된다.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat104',
        comment: '곧 소화가 끝날 시간이야화장실은 깨끗하겠지?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat105',
        comment: '난 가끔 집사가 잠에 들었을 때 이불에 오줌을 눠버리고 싶어.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat106',
        comment: '내가 똥 쌀때는 항상 클래식을 틀으란 말이야.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat107',
        comment: '왜 집사의 화장실이랑 나의 화장실은 다른 것이냐?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat108',
        comment: '물을 마시면 왜 오줌이 마려운 것이냐? 설명하거라',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat109',
        comment: '오늘 똥은 아주 색이 좋아 (흡족)',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat110',
        comment: '집사내가 똥을 싸고나면 항상 내 엉덩이를 닦아.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat111',
        comment: '내 똥을 확인해서 건강 체크좀 해줬으면 좋겠다 집사.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat112',
        comment: '건드리지 말거라…',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat113',
        comment: '아 또 시작이구나..',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat114',
        comment: '뭘 보느냐',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat115',
        comment: '너 지금 나를 불렀느냐?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat116',
        comment: '이거나 먹어라.냥냥펀치',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat117',
        comment: '사진 좀 그만 찍어..',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat118',
        comment: '노잼.재미없어',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat119',
        comment: '나 좀 쓰다듬어보거라',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat120',
        comment: '이 정도면 됐지?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat121',
        comment: '혼자만의 시간이 필요해.냅둬!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat122',
        comment: '나 부르지 마라 진짜 부르지마…',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat123',
        comment: '할 만큼 했어 집가고 싶어..',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat124',
        comment: '나는 건드려도 돼그런데 너는 안돼 ^_^',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat125',
        comment: '왜 자꾸 쳐다보는 거지? (모른척)',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat126',
        comment: '까짓 것 한번 해줄게',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat127',
        comment: '이거 놓아라',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat128',
        comment: '콱 그냥 물어버릴라',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat129',
        comment: '츄르를 가져다 받쳐라!!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat130',
        comment: '목에 이거 뭐야 당장 빼애액!!!!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat131',
        comment: 'Wyrano.wyrano…',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat132',
        comment: '오지마아악!!!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat133',
        comment: '대충살자',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat134',
        comment: '이제 니 일에나 신경쓰면 어떨까?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat135',
        comment: '아휴 저 인간..',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat136',
        comment: '다 부질없어',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat137',
        comment: '…..(무시)',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat138',
        comment: '시끄러워…',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat139',
        comment: '해보자는 것이냐..',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat140',
        comment: '귀찮네…',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat141',
        comment: '집사야.해고당하고 싶으냐…',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat142',
        comment: '흥!...(무시)',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat143',
        comment: '왔어? 내게 간식을 줄 기회를 주지.',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat144',
        comment: '감히 날 두고 어디갔다 이제 들어온거야?!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat145',
        comment: '빨리 갔다와!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat146',
        comment: '조심히 다녀오든가 말든가~',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat147',
        comment: '넌 누군데 나한테 아는 척을 하는거지?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat148',
        comment: '또 술? 술좀 그만 먹어 그만 먹으라고!!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat149',
        comment: '왜 이제왔어? 나 심심했잖아!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat150',
        comment: '올 때 나에게 맞는 수준 높은 간식을 사오라고~',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat151',
        comment: '누구신지??',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat152',
        comment: '나혼자 집에 있기 싫어! 나도 데려가!!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat153',
        comment: '뭐해? 왔으면 나부터 쓰다듬어 줘야지?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat154',
        comment: '어휴~(절레절레)',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat155',
        comment: '오늘 빨리오면 내가 특별히 놀아줄게!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat156',
        comment: '흥! 나는 처음보는 사람한테는 인사를 하지 않는다고!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat157',
        comment: '뭘 보고 서있어 빨리 들어와!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat158',
        comment: '오자마자 왜 귀찮게하는거야!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat159',
        comment: '.손에 들고 있는거 혹시 내거?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat160',
        comment: '빨리들어와서 나 안아줘야지!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat161',
        comment: '손님은 싫다~! 나 자꾸 귀찮게 한단 말이야',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat162',
        comment: '오우! 나에게 간식을 주러온 사람인가?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat163',
        comment: '누추하지만 들어와',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat164',
        comment: '어째서 빈손인거지??',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat165',
        comment: '안녕~ 친구들!!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat166',
        comment: '왔어?..zZ',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat167',
        comment: '일찍일찍 좀 다니지??',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat168',
        comment: '난 아무짓도 안했다고!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat169',
        comment: '얼른 들어와서 밥줘 배고파!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat170',
        comment: '빨리안들어오면 집을 난장판을 만들거야!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat171',
        comment: '내 집사가 드디어 왔군! 이제 일을 시켜볼까? 준비 됐나?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat172',
        comment: '밥 다 떨어졌다밥 내놔',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat173',
        comment: '난 깨끗한 물만 마실거야',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat174',
        comment: '츄르 주라!!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat175',
        comment: '난 캣닢이 좋아~~',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat176',
        comment: '가다랑어포 먹고싶어!!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat177',
        comment: '웩! 맛없어',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat178',
        comment: '사료 바꿔 이제 질려',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat179',
        comment: '더워~ 시원한 수박 조금만 먹어볼까?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat180',
        comment: '간식줄래 ~ 놀아줄래 ~?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat181',
        comment: '입맛없어..나중에 먹을게',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat182',
        comment: '뭐야~? 이거 완전 맛있잖아!!!!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat183',
        comment: '생선좋아하는 줄 알았지? 사실 고기 좋아해><',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat184',
        comment: '간식 많이 사료 조금 ! ㅇㅋ?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat185',
        comment: '생선없어?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat186',
        comment: '고기없어?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat187',
        comment: '오늘 밥,,맛있었다!!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat188',
        comment: '배고파 맛있는거 줘!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat189',
        comment: '잘~먹었다! 배불러',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat190',
        comment: '달달한 과일 먹고싶네~',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat191',
        comment: '더 줘! 또 줘!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat192',
        comment: '너 같으면 이거 먹겠어?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat193',
        comment: '사료가 너무 적잖아!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat194',
        comment: '같이 밥 먹을까?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat195',
        comment: '나는 밥 먹었는데 너는 먹었어?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat196',
        comment: '밥보단 츄르지!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat197',
        comment: '이번 간식 별로더라~',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat198',
        comment: '오늘은 츄르보다는 고기간식이 먹고싶어',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat199',
        comment: '너무 많아 조금 덜어줘',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat200',
        comment: '배불러 배 터지겠다~',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat201',
        comment: '빨리 먹고 누워야지',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat202',
        comment: '나 깨끗한데 왜 씻어야해..?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat203',
        comment: '놔랏!!! 나갈 것이야!!!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat204',
        comment: '물 너무 차갑다.–ㅅ',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat205',
        comment: '오 온도 적당해 만족스럽군',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat206',
        comment: '나 씻으면 너 나한테 뭐해줄건데?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat207',
        comment: '씻고 나니까 시원하긴 하구나',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat208',
        comment: '집사얌.나 무서우니까 꼭 잡고 있어..!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat209',
        comment: '최대한 빨리 끝내거라',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat210',
        comment: '발톱을 자른다고?! 발톱 맛 좀 볼테야?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat211',
        comment: '털 한 올 한 올 곱게 말려줘',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat212',
        comment: '아까 나 그루밍 했는데 또 씻어?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat213',
        comment: '몸이 젖어서 너무 춥구나..',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat214',
        comment: '목욕하기 딱 좋은 날씨구만..ㅎㅎ',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat215',
        comment: '씻고 나오면 츄르 한사바리 해야지~',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat216',
        comment: '봤지? 나 살찐 게 아니라 털 찐 거라니까 ^_^',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat217',
        comment: '물에 들어가기 싫어!!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat218',
        comment: '쎄하다.오늘 목욕을 당할 것만 같아!!!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat219',
        comment: '이제 마무리는 내가 알아서 할게',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat220',
        comment: '으어어 따뜻하다~~',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat221',
        comment: '물놀이 좀 재밌네?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat222',
        comment: '오 오늘 거품 좀 잘 냈나봐~? 부드럽네',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat223',
        comment: '지금 씻고 싶은 기분 아니다…',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat224',
        comment: '목 마르다… 물 좀!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat225',
        comment: '물에서 이상한 냄새 나는데?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat226',
        comment: '씻은 김에 발톱도 좀 잘라줘!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat227',
        comment: '집사자식.가만 두지 않겠어… 부들부들',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat228',
        comment: '손이 좀 거칠다..? 부드럽게 씻겨라',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat229',
        comment: '얼른 씻고 누워있고 싶어!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat230',
        comment: '노곤노곤하네.조오타~!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat231',
        comment: '카악! 귀에 물 들어갔잖아!!!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat232',
        comment: '집사야난 너가 행복하길 바래!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat233',
        comment: '이것 봐! 너 주려고 가져왔어!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat234',
        comment: '나랑 놀아주는 너가 최고야!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat235',
        comment: '기분이 안좋아보여.나랑 공가지고 놀까?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat236',
        comment: '오늘은 너에게 츄르를 양보한다!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat237',
        comment: '무슨 일 있어? 내가 꾹꾹이 해줄게',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat238',
        comment: '집사가 있어서 난 너무 행복해!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat239',
        comment: '내가 너한테 힘이 됐으면 좋겠어!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat240',
        comment: '비닐소리 들어봐 기분 좋아질걸?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat241',
        comment: '오늘은 내가 특별히 놀아준다!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat242',
        comment: '집사야 오늘은 좀 쉬어도 괜찮아',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat243',
        comment: '혼자 있고 싶으면 내가 비켜줄게..!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat244',
        comment: '오늘 하루도 고생많았어!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat245',
        comment: '우울하면 나처럼 꼬리를 흔들어봐~',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat246',
        comment: '오늘은 배 만지는거 허락해줄게!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat247',
        comment: '내가 있는데 우울해할거야?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat248',
        comment: '나를 봐! 이렇게 귀여운데 우울하다구?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat249',
        comment: '넌 이미 충분히 멋있는 사람이야!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat250',
        comment: '나한테는 너가 최고인걸?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat251',
        comment: '오늘 하루도 고생많았어',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat252',
        comment: '제일 아끼는 생선 줄게기분풀어~',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat253',
        comment: '내 핑크 젤리 만져볼래?',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat254',
        comment: '맛있는거 먹으면 기분이 좀 나아질거야!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat255',
        comment: '답답할 때는 이쁜 하늘을 봐!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat256',
        comment: '슬프면 나한테 기대도 돼',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat257',
        comment: '힘들어보이네.내가 얘기 들어줄게!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat258',
        comment: '나랑 놀다보면 기분좋아질걸?!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat259',
        comment: '힘내라고 꾹꾹이 열심히 해주는중!',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat260',
        comment: '언제나 난 너 편이야',
      },
      {
        type: 'cat',
        desc: '',
        query: 'cat261',
        comment: '앞으로 좋은 일이 많이 생길거야!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog1',
        comment: '잠깐만 기다려봐! 무슨냄새나는데 ?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog2',
        comment: '얼른 산책나가자~ 날씨가 너무 좋아!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog3',
        comment: '오~저 개 이쁜데~ 저쪽으로 가면 안돼??',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog4',
        comment: '이 친구들 재밌는데 좀만 더 놀다가면 안돼?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog5',
        comment: '오늘 색다른 산책코스로 가고 싶어!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog6',
        comment: '목말라! 물마시고싶어!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog7',
        comment: '앗 잠깐만! 쉬야좀 할게!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog8',
        comment: '엇 무슨냄새지? 맛있는 냄새닷',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog9',
        comment: '이제 힘든데 ~ 집에 들어가자!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog10',
        comment: '저게 뭐지? 뭐가 있는데',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog11',
        comment: '나 먼저 뛰어간다! 달리고싶어!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog12',
        comment: '빨리와!~어서가자!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog13',
        comment: '나도 사람처럼 걷고싶어 손잡고 같이 걷고싶어!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog14',
        comment: '안아줘 꼭 안아줘!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog15',
        comment: '모래 파고 갈랭',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog16',
        comment: '잠깐 쉬었다갈래!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog17',
        comment: '오늘 저녁 뭐먹을지 기대 되는데?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog18',
        comment: '저기 친구들 많이 모인데 가보고싶어!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog19',
        comment: '앗 저 개 무서워! 돌아서 가자!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog20',
        comment: '만세 만세 만세~~신난다~',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog21',
        comment: '나 오늘 좀 기분 안좋으니까 건들지마',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog22',
        comment: '기분이 꿀꿀하니 간식을 좀 줄래?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog23',
        comment: '오늘 너무 신이난다 신이나!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog24',
        comment: '무서운 꿈 꾼 것 같아 너무 무떠어어ㅜㅜ',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog25',
        comment: '지금 너무 행복한 느낌! 헤헤헿',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog26',
        comment: '궁금해요! 궁금해!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog27',
        comment: '너무 좋아!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog28',
        comment: '나한테 관심 좀 주라!!!! 주!!!!!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog29',
        comment: '하루종일 혼자 심심했어! ㅜㅜ',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog30',
        comment: '예뻐해줄래?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog31',
        comment: '저 귀엽죠? 사랑스럽죠?!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog32',
        comment: '음.....',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog33',
        comment: '오늘 왜 그러세여?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog34',
        comment: '저 좀 그냥 두세요 줴발',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog35',
        comment: '왤케 짜증이 나는거여?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog36',
        comment: '그므해라...ㅂㄷㅂㄷ',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog37',
        comment: '화난다 진짜ㅡㅡ',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog38',
        comment: '사랑스러워여><',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog39',
        comment: '왜이리 기분이 다운 되는 걸까요?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog40',
        comment: '아유 지루해 증말',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog41',
        comment: '근들즈므르...',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog42',
        comment: '뭐 시키려면 간식을 가져와라 주인아',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog43',
        comment: '나 가만 놔두는 게 좋을텐데',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog44',
        comment: '한심하다..ㅎ',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog45',
        comment: '장난치지마라 혼난다',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog46',
        comment: '응~ 아니야',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog47',
        comment: '해보자는 거야?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog48',
        comment: '귀찮게하네 진짜^_^',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog49',
        comment: '됐어! 그만해!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog50',
        comment: '왜 옆에서 더 난리여',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog51',
        comment: '에휴 (절레절레)',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog52',
        comment: '왜 또 저러니',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog53',
        comment: '나 좀 내버려 두면 안되겠니?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog54',
        comment: '아 짜증나!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog55',
        comment: '에헤이 거기 스탑! 가까이 오지마',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog56',
        comment: '니가 내 맘을 알겠니..',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog57',
        comment: '시끄러워',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog58',
        comment: '싫다고 했잖아',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog59',
        comment: '나 부르지마라 제발 부르지마라',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog60',
        comment: '됐냐...',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog61',
        comment: '자고 싶다!!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog62',
        comment: '혼자 있게 해줘!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog63',
        comment: '저리가줘 !',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog64',
        comment: '친구들 만나고 싶어!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog65',
        comment: '나한테서 떨어지지 마!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog66',
        comment: '같이 놀자!!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog67',
        comment: '나가서 놀고싶어!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog68',
        comment: '문 열어줘!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog69',
        comment: '맨날 나랑 같이 있자!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog70',
        comment: '어디가면 안돼!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog71',
        comment: '나 혼자 두면 안돼',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog72',
        comment: '집에 나 혼자 있으면 집이 어떻게 되어있는지 두고봐!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog73',
        comment: '장난 치기만 해봐 나 화낼꺼야',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog74',
        comment: '장난감 가져와서 나랑 놀자',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog75',
        comment: '내눈 똑바로 봐바',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog76',
        comment: '나 좀 쓰다듬어줘',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog77',
        comment: '배변 패드좀 새로운걸로 갈아줘봐',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog78',
        comment: '좀 뛰고싶은데 뛰게 해줘!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog79',
        comment: '친구 좀 만들어 줄 수 있어 ?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog80',
        comment: '난 사람이야!! 우린 똑같은 입장이야',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog81',
        comment: '내이름 다른걸로 바꿔줘',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog82',
        comment: '시원한 물 한사바리 하고싶어',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog83',
        comment:
          '패드좀 바꿔줘 자꾸 오줌밟잖아.근데 밟으면 혼낼거잖아.생각해보니 짜증나네',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog84',
        comment: '나 똥먹고 왔어 주인 핥아도 돼?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog85',
        comment:
          '내 똥엔 영혼이 깃들어있지..’강아지똥’ 치울 때 눈 안마주치게 조심하라구.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog86',
        comment: '왜이렇게 소화가 안되지?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog87',
        comment: '오줌 마렵다 근데 난 참을 것 이다.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog88',
        comment: '똥 마렵다 근데 난 참을 것 이다.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog89',
        comment: '왜 항상 똥에서는 좋은 냄새가 나지? 먹고싶게',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog90',
        comment: '나 배변 잘하잖아 칭찬좀 해줘.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog91',
        comment: '내가 배변패드에 오줌을 안싼건.내가 화났다는 뜻이야..',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog92',
        comment: '밥먹었으니 슬슬 배설을 준비해볼까?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog93',
        comment: '근데 나 똥쌀 때 좀 안쳐다봤으면 좋겠어 진지해!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog94',
        comment: '날 화나게 하지마 집 어딘가에 똥을 숨겨버릴테니까..',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog95',
        comment:
          '산책가서 햇빛받으면서 시원하게 배설하고 싶어물론 산책가고 싶어서 이러는건 아니야',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog96',
        comment: '아니 내 배변패드에서 왜 다른 녀석 냄새가 나는거지?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog97',
        comment:
          '내 방구가 언제나온다고 생각하나? 배아플 때? 아니야 똥쌀 때? 아니야 바로 너가 잠들기 직전이다!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog98',
        comment: '새로운 밥이 나랑 좀 안맞는 거 같은데? 똥싸기가 조금 힘들어.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog99',
        comment: '내 똥 냄새를 얼굴 표정으로 표현해주라',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog100',
        comment: '오늘은 신발장에 오줌싸서 주인을 즐겁게 해줘야지~',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog101',
        comment:
          '이제부터 배변패드 정 가운데에 정확히 배설을 하도록 하겠습니다간식주십쇼.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog102',
        comment: '밥줘 똥싸게',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog103',
        comment: '밥이 이게 뭐니?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog104',
        comment: '고기없어?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog105',
        comment: '뭐해? 밥내놔',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog106',
        comment: '이걸 밥이라고 준거야 ? 맛없어 !',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog107',
        comment: '이번 사료 최고야!! 너무 맛있어 ><',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog108',
        comment: '시원한 물 먹고싶어',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog109',
        comment: '밥 먹을땐 개도 안건드린다는데_+',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog110',
        comment: '뭐 먹어? 나도 주라~~',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog111',
        comment: '야채 싫어!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog112',
        comment: '오늘 메뉴는 뭐야?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog113',
        comment: '반찬 없어??',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog114',
        comment: '간식 먹고 싶어',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog115',
        comment: '너만 입이냐 나도 맛있는거 줘!!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog116',
        comment: '고기만 줘 고기!고기!!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog117',
        comment: '아 배부르다~~',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog118',
        comment: '달달한게 땡겨~~!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog119',
        comment: '배고파!!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog120',
        comment: '엥? 이게 뭐야 누구 코에 붙여 ㅡㅡ',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog121',
        comment: '사료 바꿔라~ 질린다',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog122',
        comment: '안 뱉을거야! 뱉기 싫어!!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog123',
        comment: '입맛없어 안먹을래.',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog124',
        comment: '힘들고 답답할떈? 나랑 공원산책가자!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog125',
        comment: '슬프면 나한테 기대!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog126',
        comment: '오늘 하루도 고생많았어~',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog127',
        comment: '괜찮아? 내가 아끼는 장난감 줄게 힘내ㅠㅠ',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog128',
        comment: '무슨일이야! 데꼬와 내가 혼내줄거야!!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog129',
        comment: '누가 그랬는데..힘들땐 시간이 약이래',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog130',
        comment: '내 표정 좀 봐! 웃기지? 귀엽지~?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog131',
        comment: '우울하면 나랑 달리기 시합하러 나갈까?!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog132',
        comment: '주인 곁엔 내가 있잖아! 힘!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog133',
        comment: '괜찮아앞으로 좋은 일이 더 많을거야',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog134',
        comment: '울고 싶을 땐 울어도 괜찮아!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog135',
        comment: '주인한테 내가 위로가 됐음 좋겠다',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog136',
        comment: '얼굴 찌푸리지 말아용~ 내가 있잖아!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog137',
        comment: '맛있는거 먹으면 기분이 좀 나아질거야!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog138',
        comment: '무슨일 있어? 내가 다 들어줄게!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog139',
        comment: '걱정마, 충분히 잘하고 있어',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog140',
        comment: '난 주인이가 있어서 너무 행복한걸?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog141',
        comment: '나한테는 주인이가 최고야! 제일 소중해',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog142',
        comment: '응원할게 내일도 화이팅!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog143',
        comment: '기운내, 주인은 이미 멋있는 사람이야',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog144',
        comment: '안녕!안녕!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog145',
        comment: '왜 이제야 왔어~~!!나 빨리 안아줘!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog146',
        comment: '빨리갔다와!!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog147',
        comment: '왔어?!놀자! 놀자!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog148',
        comment: '지금 시간이 몇시지?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog149',
        comment: '나 두고 가지마 ㅠㅠ',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog150',
        comment: '잘갔다와!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog151',
        comment: '조심히다녀와!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog152',
        comment: '야호~왔다왔어!!!신난다! 놀아줘!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog153',
        comment: '안녕하세요!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog154',
        comment: '누구신데 절 쓰다듬죠?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog155',
        comment: '반가워요!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog156',
        comment: '올때 메로나맛 간식!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog157',
        comment: '잘잤어?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog158',
        comment: '.......(무시)',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog159',
        comment: '.......(절레절레)',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog160',
        comment: '일어나!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog161',
        comment: '친구야!! 안녕!!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog162',
        comment: '어서와!! 나 에게 간식하나 씩주는 건 필수애!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog163',
        comment: '손에 든거 뭐야~? 나주려고 사온거야~?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog164',
        comment: '잘 다녀왔어~~? 오늘 하루도 수고많았어!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog165',
        comment: '씻기 싫어! 나를 놔줘!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog166',
        comment: '씻는거 오늘은 재밌네!!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog167',
        comment: '이건 무슨 냄새지?',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog168',
        comment: '물에 들어가기 무서워!...',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog169',
        comment: '얼른 다 씻고 놀고 싶어!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog170',
        comment: '지금 난 너무 긴장했어! ㅠㅠ',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog171',
        comment: '너무 거칠어! 부드럽게 씻겨줘~',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog172',
        comment: '지금 너무 행복해!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog173',
        comment: '씻기에는 지금 너무 피곤해!...',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog174',
        comment: '물에서 이상한 냄새가 나 ㅠㅠ',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog175',
        comment: '몸이 젖어서 너무 추워 ㅠㅠ',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog176',
        comment: '씻는 김에 발톱도 잘라줘!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog177',
        comment: '지금 씻고 싶은 기분 아니다...',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog178',
        comment: '씻고 나면 간식 줄거야??',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog179',
        comment: '같이 물장구 치며 놀자!!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog180',
        comment: '씻고 나니 너무 시원해!',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog181',
        comment: '얼른 따뜻하게 몸을 말려줘~',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog182',
        comment: '지금 너무 목이 말라...',
      },
      {
        type: 'dog',
        desc: '',
        query: 'dog183',
        comment: '졸려.ZZZZZZ...',
      },
    ],
  },
  // dogSoundsEng
  {
    info: {
      mainTitle: 'Dog Sound Translator',
      subTitle: 'If you want to know all the sound of my dog?',
      mainUrl: 'dogSoundsEng',
      scoreType: 'DogSounds',
      mainImage:
        'https://images.ktestone.com/introImages/dogSoundsEng-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/dogSoundsEng-thumb.png',
      lang: 'Eng',
      category: 'etc',
    },
    questions: [
      {
        question: '데이트 전 날 나의 모습은?',
        answers: [
          {
            type: '포메라니안',
            score: 2,
            content: '나가기 직전 마음에 드는 옷을 입는다.',
          },
          {
            type: '치와와',
            score: 5,
            content: '자기 전 머릿속으로 나의 스타일링을 생각하고 잔다.',
          },
          {
            type: '비글',
            score: 10,
            content: '전 날 머리부터 발끝까지 스타일링과 옷을 미리 준비한다.',
          },
        ],
      },
      {
        question: '갑자기 자취방에 놀러 온다는 애인!\n그때의 나는?',
        answers: [
          {
            type: '포메라니안',
            score: 2,
            content: '아아... 왜 갑자기 오는 거야?! 보이는 곳만 치워 둔다.',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '모아둔 쓰레기를 버리고 바로 청소를 시작한다.',
          },
          {
            type: '리트리버',
            score: 10,
            content: '평소 미리미리 해 두었기 때문에 바로 주소를 보낸다.',
          },
        ],
      },
      {
        question: '좋아하는 이성이 생겼을 때 나는?',
        answers: [
          {
            type: '치와와',
            score: 2,
            content: '상대의 SNS을 탐방하며, 미래의 우리 모습을 상상해본다.',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '좋아하는 이성의 친구에게 도움을 요청한다.',
          },
          {
            type: '리트리버',
            score: 10,
            content: '먼저 연락할 거리를 만들며 적극적으로 대시한다.',
          },
        ],
      },
      {
        question:
          '카페 안에 이상형인 사람과 계속 눈이 마주친다.\n 눈웃음까지 보내는데...?',
        answers: [
          {
            type: '비글',
            score: 2,
            content: '지금 말하면 좋은 말이 안 나올 것 같아. 좀 이따 연락할게.',
          },
          {
            type: '리트리버',
            score: 5,
            content: '아!!!!!!!짜증나!!!!!진짜!!!!!! 도대체 왜 그러는 거야?!?!',
          },
          {
            type: '포메라니안',
            score: 10,
            content:
              '이런 점에서 나는 화가 난 거야. 그런 부분은 고쳐줬으면 좋겠어.',
          },
        ],
      },
      {
        question:
          '쉬고 있는데 갑자기 걸려온 남사친/여사친의 전화, \n친구들이 같이 놀고 싶다며 나올 수 있냐고 물어보는데...?',
        answers: [
          {
            type: '리트리버',
            score: 2,
            content:
              '귀찮은데… 아쉽긴 하지만 “시간이 너무 늦었어… 나 집에서 쉴래!”',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '일단 끊고 수십 번 고민 끝에 나가기로 한다.',
          },
          {
            type: '비글',
            score: 10,
            content: '오케이~ 인연 한 번 만들어보자~ “갈게~~~~”',
          },
        ],
      },
      {
        question: '다가온 기념일! 나는 어떤 선물을 준비할까?',
        answers: [
          {
            type: '포메라니안',
            score: 2,
            content: '정성 어린 편지와 기억에 남을 만한 선물을 준비한다.',
          },
          {
            type: '비글',
            score: 5,
            content: '커플로 착용할 수 있는 아이템을 선물한다.',
          },
          {
            type: '리트리버',
            score: 10,
            content:
              '평소 사고 싶다거나 필요한 물건을 기억해 두었다가 서프라이즈로 선물한다.',
          },
        ],
      },
      {
        question: '내가 하고 싶은 연애 스타일은?',
        answers: [
          {
            type: '비글',
            score: 2,
            content:
              '다이나믹한 화려한 연애 (단, 너무 화려해서 바람 피울 가능성 50%)',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '나만 바라보는 연애 (단, 너무 좋아해서 집착 대마왕)',
          },
          {
            type: '포메라니안',
            score: 10,
            content: '친구같이 편안한 연애 (단, 가끔 친구인지 애인인지 헷갈림)',
          },
        ],
      },
      {
        question: '애인과 대화할 때 나는?',
        answers: [
          {
            type: '치와와',
            score: 5,
            content: '장난치고 티키타카가 잘 되는 대화가 좋다.',
          },
          {
            type: '리트리버',
            score: 10,
            content: '서로의 생각을 공유하는 대화가 너무 좋다.',
          },
        ],
      },
      {
        question: '친구가 나에게 고민상당을 한다. 나의 반응은?',
        answers: [
          {
            type: '포메라니안',
            score: 5,
            content: '고민에 대한 구체적인 해결책을 제시한다.',
          },
          {
            type: '웰시코기',
            score: 10,
            content: '고민에 대해 빠져들어 감정적으로 같이 공감해준다.',
          },
        ],
      },
      {
        question:
          '기다려왔던 짝사랑하던 그/그녀와의 데이트 날..\n갑자기 급한 일이 생겼다며 다음에 만나자고 한다.\n과연 나의 대답은?',
        answers: [
          {
            type: '비글',
            score: 2,
            content: '어쩔 수 없지… 알겠어 다음에 보자!',
          },
          {
            type: '치와와',
            score: 5,
            content: '알겠어…근데 심각한 일이야?',
          },
          {
            type: '웰시코기',
            score: 10,
            content: '아 진짜? 그럼 날짜 다시 조정하자! 언제가 괜찮아?',
          },
        ],
      },
    ],
    results: [
      {
        type: '',
        desc: '',
        query: '1',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/1.png',
      },
      {
        type: '',
        desc: '',
        query: '2',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/2.png',
      },
      {
        type: '',
        desc: '',
        query: '3',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/3.png',
      },
      {
        type: '',
        desc: '',
        query: '4',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/4.png',
      },
      {
        type: '',
        desc: '',
        query: '5',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/5.png',
      },
      {
        type: '',
        desc: '',
        query: '6',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/6.png',
      },
      {
        type: '',
        desc: '',
        query: '7',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/7.png',
      },
      {
        type: '',
        desc: '',
        query: '8',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/8.png',
      },
      {
        type: '',
        desc: '',
        query: '9',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/9.png',
      },
      {
        type: '',
        desc: '',
        query: '10',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/10.png',
      },
      {
        type: '',
        desc: '',
        query: '11',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/11.png',
      },
      {
        type: '',
        desc: '',
        query: '12',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/12.png',
      },
      {
        type: '',
        desc: '',
        query: '13',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/13.png',
      },
      {
        type: '',
        desc: '',
        query: '14',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/14.png',
      },
      {
        type: '',
        desc: '',
        query: '15',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/15.png',
      },
      {
        type: '',
        desc: '',
        query: '16',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/16.png',
      },
      {
        type: '',
        desc: '',
        query: '17',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/17.png',
      },
      {
        type: '',
        desc: '',
        query: '18',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/18.png',
      },
      {
        type: '',
        desc: '',
        query: '19',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/19.png',
      },
      {
        type: '',
        desc: '',
        query: '20',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/20.png',
      },
      {
        type: '',
        desc: '',
        query: '21',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/21.png',
      },
      {
        type: '',
        desc: '',
        query: '22',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/22.png',
      },
      {
        type: '',
        desc: '',
        query: '23',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/23.png',
      },
      {
        type: '',
        desc: '',
        query: '24',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/24.png',
      },
      {
        type: '',
        desc: '',
        query: '25',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/25.png',
      },
      {
        type: '',
        desc: '',
        query: '26',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/26.png',
      },
      {
        type: '',
        desc: '',
        query: '27',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/27.png',
      },
      {
        type: '',
        desc: '',
        query: '28',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/28.png',
      },
      {
        type: '',
        desc: '',
        query: '29',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/29.png',
      },
      {
        type: '',
        desc: '',
        query: '30',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/30.png',
      },
      {
        type: '',
        desc: '',
        query: '31',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/31.png',
      },
      {
        type: '',
        desc: '',
        query: '32',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/32.png',
      },
      {
        type: '',
        desc: '',
        query: '33',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/33.png',
      },
      {
        type: '',
        desc: '',
        query: '34',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/34.png',
      },
      {
        type: '',
        desc: '',
        query: '35',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/35.png',
      },
      {
        type: '',
        desc: '',
        query: '36',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/36.png',
      },
      {
        type: '',
        desc: '',
        query: '37',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/37.png',
      },
      {
        type: '',
        desc: '',
        query: '38',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/38.png',
      },
      {
        type: '',
        desc: '',
        query: '39',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/39.png',
      },
      {
        type: '',
        desc: '',
        query: '40',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/40.png',
      },
      {
        type: '',
        desc: '',
        query: '41',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/41.png',
      },
      {
        type: '',
        desc: '',
        query: '42',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/42.png',
      },
      {
        type: '',
        desc: '',
        query: '43',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/43.png',
      },
      {
        type: '',
        desc: '',
        query: '44',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/44.png',
      },
      {
        type: '',
        desc: '',
        query: '45',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/45.png',
      },
      {
        type: '',
        desc: '',
        query: '46',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/46.png',
      },
      {
        type: '',
        desc: '',
        query: '47',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/47.png',
      },
      {
        type: '',
        desc: '',
        query: '48',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/48.png',
      },
      {
        type: '',
        desc: '',
        query: '49',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/49.png',
      },
      {
        type: '',
        desc: '',
        query: '50',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/50.png',
      },
      {
        type: '',
        desc: '',
        query: '51',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/51.png',
      },
      {
        type: '',
        desc: '',
        query: '52',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/52.png',
      },
      {
        type: '',
        desc: '',
        query: '53',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/53.png',
      },
      {
        type: '',
        desc: '',
        query: '54',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/54.png',
      },
      {
        type: '',
        desc: '',
        query: '55',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/55.png',
      },
      {
        type: '',
        desc: '',
        query: '56',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/56.png',
      },
      {
        type: '',
        desc: '',
        query: '57',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/57.png',
      },
      {
        type: '',
        desc: '',
        query: '58',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/58.png',
      },
      {
        type: '',
        desc: '',
        query: '59',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/59.png',
      },
      {
        type: '',
        desc: '',
        query: '60',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/60.png',
      },
      {
        type: '',
        desc: '',
        query: '61',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/61.png',
      },
      {
        type: '',
        desc: '',
        query: '62',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/62.png',
      },
      {
        type: '',
        desc: '',
        query: '63',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/63.png',
      },
      {
        type: '',
        desc: '',
        query: '64',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/64.png',
      },
      {
        type: '',
        desc: '',
        query: '65',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/65.png',
      },
      {
        type: '',
        desc: '',
        query: '66',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/66.png',
      },
      {
        type: '',
        desc: '',
        query: '67',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/67.png',
      },
      {
        type: '',
        desc: '',
        query: '68',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/68.png',
      },
      {
        type: '',
        desc: '',
        query: '69',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/69.png',
      },
      {
        type: '',
        desc: '',
        query: '70',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/70.png',
      },
      {
        type: '',
        desc: '',
        query: '71',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/71.png',
      },
      {
        type: '',
        desc: '',
        query: '72',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/72.png',
      },
      {
        type: '',
        desc: '',
        query: '73',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/73.png',
      },
      {
        type: '',
        desc: '',
        query: '74',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/74.png',
      },
      {
        type: '',
        desc: '',
        query: '75',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/75.png',
      },
      {
        type: '',
        desc: '',
        query: '76',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/76.png',
      },
      {
        type: '',
        desc: '',
        query: '77',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/77.png',
      },
      {
        type: '',
        desc: '',
        query: '78',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/78.png',
      },
      {
        type: '',
        desc: '',
        query: '79',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/79.png',
      },
      {
        type: '',
        desc: '',
        query: '80',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/80.png',
      },
      {
        type: '',
        desc: '',
        query: '81',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/81.png',
      },
      {
        type: '',
        desc: '',
        query: '82',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/82.png',
      },
      {
        type: '',
        desc: '',
        query: '83',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/83.png',
      },
      {
        type: '',
        desc: '',
        query: '84',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/84.png',
      },
      {
        type: '',
        desc: '',
        query: '85',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/85.png',
      },
      {
        type: '',
        desc: '',
        query: '86',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/86.png',
      },
      {
        type: '',
        desc: '',
        query: '87',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/87.png',
      },
      {
        type: '',
        desc: '',
        query: '88',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/88.png',
      },
      {
        type: '',
        desc: '',
        query: '89',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/89.png',
      },
      {
        type: '',
        desc: '',
        query: '90',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/90.png',
      },
      {
        type: '',
        desc: '',
        query: '91',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/91.png',
      },
      {
        type: '',
        desc: '',
        query: '92',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/92.png',
      },
      {
        type: '',
        desc: '',
        query: '93',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/93.png',
      },
      {
        type: '',
        desc: '',
        query: '94',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/94.png',
      },
      {
        type: '',
        desc: '',
        query: '95',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/95.png',
      },
      {
        type: '',
        desc: '',
        query: '96',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/96.png',
      },
      {
        type: '',
        desc: '',
        query: '97',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/97.png',
      },
      {
        type: '',
        desc: '',
        query: '98',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/98.png',
      },
      {
        type: '',
        desc: '',
        query: '99',
        img_src: 'https://images.ktestone.com/resultImages/dogSoundsEng/99.png',
      },
      {
        type: '',
        desc: '',
        query: '100',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/100.png',
      },
      {
        type: '',
        desc: '',
        query: '101',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/101.png',
      },
      {
        type: '',
        desc: '',
        query: '102',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/102.png',
      },
      {
        type: '',
        desc: '',
        query: '103',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/103.png',
      },
      {
        type: '',
        desc: '',
        query: '104',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/104.png',
      },
      {
        type: '',
        desc: '',
        query: '105',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/105.png',
      },
      {
        type: '',
        desc: '',
        query: '106',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/106.png',
      },
      {
        type: '',
        desc: '',
        query: '107',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/107.png',
      },
      {
        type: '',
        desc: '',
        query: '108',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/108.png',
      },
      {
        type: '',
        desc: '',
        query: '109',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/109.png',
      },
      {
        type: '',
        desc: '',
        query: '110',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/110.png',
      },
      {
        type: '',
        desc: '',
        query: '111',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/111.png',
      },
      {
        type: '',
        desc: '',
        query: '112',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/112.png',
      },
      {
        type: '',
        desc: '',
        query: '113',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/113.png',
      },
      {
        type: '',
        desc: '',
        query: '114',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/114.png',
      },
      {
        type: '',
        desc: '',
        query: '115',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/115.png',
      },
      {
        type: '',
        desc: '',
        query: '116',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/116.png',
      },
      {
        type: '',
        desc: '',
        query: '117',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/117.png',
      },
      {
        type: '',
        desc: '',
        query: '118',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/118.png',
      },
      {
        type: '',
        desc: '',
        query: '119',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/119.png',
      },
      {
        type: '',
        desc: '',
        query: '120',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/120.png',
      },
      {
        type: '',
        desc: '',
        query: '121',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/121.png',
      },
      {
        type: '',
        desc: '',
        query: '122',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/122.png',
      },
      {
        type: '',
        desc: '',
        query: '123',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/123.png',
      },
      {
        type: '',
        desc: '',
        query: '124',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/124.png',
      },
      {
        type: '',
        desc: '',
        query: '125',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/125.png',
      },
      {
        type: '',
        desc: '',
        query: '126',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/126.png',
      },
      {
        type: '',
        desc: '',
        query: '127',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/127.png',
      },
      {
        type: '',
        desc: '',
        query: '128',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/128.png',
      },
      {
        type: '',
        desc: '',
        query: '129',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/129.png',
      },
      {
        type: '',
        desc: '',
        query: '130',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/130.png',
      },
      {
        type: '',
        desc: '',
        query: '131',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/131.png',
      },
      {
        type: '',
        desc: '',
        query: '132',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/132.png',
      },
      {
        type: '',
        desc: '',
        query: '133',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/133.png',
      },
      {
        type: '',
        desc: '',
        query: '134',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/134.png',
      },
      {
        type: '',
        desc: '',
        query: '135',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/135.png',
      },
      {
        type: '',
        desc: '',
        query: '136',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/136.png',
      },
      {
        type: '',
        desc: '',
        query: '137',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/137.png',
      },
      {
        type: '',
        desc: '',
        query: '138',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/138.png',
      },
      {
        type: '',
        desc: '',
        query: '139',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/139.png',
      },
      {
        type: '',
        desc: '',
        query: '140',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/140.png',
      },
      {
        type: '',
        desc: '',
        query: '141',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/141.png',
      },
      {
        type: '',
        desc: '',
        query: '142',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/142.png',
      },
      {
        type: '',
        desc: '',
        query: '143',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/143.png',
      },
      {
        type: '',
        desc: '',
        query: '144',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/144.png',
      },
      {
        type: '',
        desc: '',
        query: '145',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/145.png',
      },
      {
        type: '',
        desc: '',
        query: '146',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/146.png',
      },
      {
        type: '',
        desc: '',
        query: '147',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/147.png',
      },
      {
        type: '',
        desc: '',
        query: '148',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/148.png',
      },
      {
        type: '',
        desc: '',
        query: '149',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/149.png',
      },
      {
        type: '',
        desc: '',
        query: '150',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/150.png',
      },
      {
        type: '',
        desc: '',
        query: '151',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/151.png',
      },
      {
        type: '',
        desc: '',
        query: '152',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/152.png',
      },
      {
        type: '',
        desc: '',
        query: '153',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/153.png',
      },
      {
        type: '',
        desc: '',
        query: '154',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/154.png',
      },
      {
        type: '',
        desc: '',
        query: '155',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/155.png',
      },
      {
        type: '',
        desc: '',
        query: '156',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/156.png',
      },
      {
        type: '',
        desc: '',
        query: '157',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/157.png',
      },
      {
        type: '',
        desc: '',
        query: '158',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/158.png',
      },
      {
        type: '',
        desc: '',
        query: '159',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/159.png',
      },
      {
        type: '',
        desc: '',
        query: '160',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/160.png',
      },
      {
        type: '',
        desc: '',
        query: '161',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/161.png',
      },
      {
        type: '',
        desc: '',
        query: '162',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/162.png',
      },
      {
        type: '',
        desc: '',
        query: '163',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/163.png',
      },
      {
        type: '',
        desc: '',
        query: '164',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/164.png',
      },
      {
        type: '',
        desc: '',
        query: '165',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/165.png',
      },
      {
        type: '',
        desc: '',
        query: '166',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/166.png',
      },
      {
        type: '',
        desc: '',
        query: '167',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/167.png',
      },
      {
        type: '',
        desc: '',
        query: '168',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/168.png',
      },
      {
        type: '',
        desc: '',
        query: '169',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/169.png',
      },
      {
        type: '',
        desc: '',
        query: '170',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/170.png',
      },
      {
        type: '',
        desc: '',
        query: '171',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/171.png',
      },
      {
        type: '',
        desc: '',
        query: '172',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/172.png',
      },
      {
        type: '',
        desc: '',
        query: '173',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/173.png',
      },
      {
        type: '',
        desc: '',
        query: '174',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/174.png',
      },
      {
        type: '',
        desc: '',
        query: '175',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/175.png',
      },
      {
        type: '',
        desc: '',
        query: '176',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/176.png',
      },
      {
        type: '',
        desc: '',
        query: '177',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/177.png',
      },
      {
        type: '',
        desc: '',
        query: '178',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/178.png',
      },
      {
        type: '',
        desc: '',
        query: '179',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/179.png',
      },
      {
        type: '',
        desc: '',
        query: '180',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/180.png',
      },
      {
        type: '',
        desc: '',
        query: '181',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/181.png',
      },
      {
        type: '',
        desc: '',
        query: '182',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/182.png',
      },
      {
        type: '',
        desc: '',
        query: '183',
        img_src:
          'https://images.ktestone.com/resultImages/dogSoundsEng/183.png',
      },
    ],
  },
  // dogSounds
  {
    info: {
      mainTitle: '강아지 사운드 번역기',
      subTitle: '내 강아지의 숨소리, 짖는 소리 등등 모든 소리가 알고 싶다면?',
      mainUrl: 'dogSounds',
      scoreType: 'DogSounds',
      mainImage: 'https://images.ktestone.com/introImages/dogSounds-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/dogSounds-thumb.png',
      lang: 'Kor',
      category: 'etc',
    },
    questions: [
      {
        question: '데이트 전 날 나의 모습은?',
        answers: [
          {
            type: '포메라니안',
            score: 2,
            content: '나가기 직전 마음에 드는 옷을 입는다.',
          },
          {
            type: '치와와',
            score: 5,
            content: '자기 전 머릿속으로 나의 스타일링을 생각하고 잔다.',
          },
          {
            type: '비글',
            score: 10,
            content: '전 날 머리부터 발끝까지 스타일링과 옷을 미리 준비한다.',
          },
        ],
      },
      {
        question: '갑자기 자취방에 놀러 온다는 애인!\n그때의 나는?',
        answers: [
          {
            type: '포메라니안',
            score: 2,
            content: '아아... 왜 갑자기 오는 거야?! 보이는 곳만 치워 둔다.',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '모아둔 쓰레기를 버리고 바로 청소를 시작한다.',
          },
          {
            type: '리트리버',
            score: 10,
            content: '평소 미리미리 해 두었기 때문에 바로 주소를 보낸다.',
          },
        ],
      },
      {
        question: '좋아하는 이성이 생겼을 때 나는?',
        answers: [
          {
            type: '치와와',
            score: 2,
            content: '상대의 SNS을 탐방하며, 미래의 우리 모습을 상상해본다.',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '좋아하는 이성의 친구에게 도움을 요청한다.',
          },
          {
            type: '리트리버',
            score: 10,
            content: '먼저 연락할 거리를 만들며 적극적으로 대시한다.',
          },
        ],
      },
      {
        question:
          '카페 안에 이상형인 사람과 계속 눈이 마주친다.\n 눈웃음까지 보내는데...?',
        answers: [
          {
            type: '비글',
            score: 2,
            content: '지금 말하면 좋은 말이 안 나올 것 같아. 좀 이따 연락할게.',
          },
          {
            type: '리트리버',
            score: 5,
            content: '아!!!!!!!짜증나!!!!!진짜!!!!!! 도대체 왜 그러는 거야?!?!',
          },
          {
            type: '포메라니안',
            score: 10,
            content:
              '이런 점에서 나는 화가 난 거야. 그런 부분은 고쳐줬으면 좋겠어.',
          },
        ],
      },
      {
        question:
          '쉬고 있는데 갑자기 걸려온 남사친/여사친의 전화, \n친구들이 같이 놀고 싶다며 나올 수 있냐고 물어보는데...?',
        answers: [
          {
            type: '리트리버',
            score: 2,
            content:
              '귀찮은데… 아쉽긴 하지만 “시간이 너무 늦었어… 나 집에서 쉴래!”',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '일단 끊고 수십 번 고민 끝에 나가기로 한다.',
          },
          {
            type: '비글',
            score: 10,
            content: '오케이~ 인연 한 번 만들어보자~ “갈게~~~~”',
          },
        ],
      },
      {
        question: '다가온 기념일! 나는 어떤 선물을 준비할까?',
        answers: [
          {
            type: '포메라니안',
            score: 2,
            content: '정성 어린 편지와 기억에 남을 만한 선물을 준비한다.',
          },
          {
            type: '비글',
            score: 5,
            content: '커플로 착용할 수 있는 아이템을 선물한다.',
          },
          {
            type: '리트리버',
            score: 10,
            content:
              '평소 사고 싶다거나 필요한 물건을 기억해 두었다가 서프라이즈로 선물한다.',
          },
        ],
      },
      {
        question: '내가 하고 싶은 연애 스타일은?',
        answers: [
          {
            type: '비글',
            score: 2,
            content:
              '다이나믹한 화려한 연애 (단, 너무 화려해서 바람 피울 가능성 50%)',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '나만 바라보는 연애 (단, 너무 좋아해서 집착 대마왕)',
          },
          {
            type: '포메라니안',
            score: 10,
            content: '친구같이 편안한 연애 (단, 가끔 친구인지 애인인지 헷갈림)',
          },
        ],
      },
      {
        question: '애인과 대화할 때 나는?',
        answers: [
          {
            type: '치와와',
            score: 5,
            content: '장난치고 티키타카가 잘 되는 대화가 좋다.',
          },
          {
            type: '리트리버',
            score: 10,
            content: '서로의 생각을 공유하는 대화가 너무 좋다.',
          },
        ],
      },
      {
        question: '친구가 나에게 고민상당을 한다. 나의 반응은?',
        answers: [
          {
            type: '포메라니안',
            score: 5,
            content: '고민에 대한 구체적인 해결책을 제시한다.',
          },
          {
            type: '웰시코기',
            score: 10,
            content: '고민에 대해 빠져들어 감정적으로 같이 공감해준다.',
          },
        ],
      },
      {
        question:
          '기다려왔던 짝사랑하던 그/그녀와의 데이트 날..\n갑자기 급한 일이 생겼다며 다음에 만나자고 한다.\n과연 나의 대답은?',
        answers: [
          {
            type: '비글',
            score: 2,
            content: '어쩔 수 없지… 알겠어 다음에 보자!',
          },
          {
            type: '치와와',
            score: 5,
            content: '알겠어…근데 심각한 일이야?',
          },
          {
            type: '웰시코기',
            score: 10,
            content: '아 진짜? 그럼 날짜 다시 조정하자! 언제가 괜찮아?',
          },
        ],
      },
    ],
    results: [
      {
        type: '',
        desc: '',
        query: '1',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/1.png',
      },
      {
        type: '',
        desc: '',
        query: '2',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/2.png',
      },
      {
        type: '',
        desc: '',
        query: '3',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/3.png',
      },
      {
        type: '',
        desc: '',
        query: '4',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/4.png',
      },
      {
        type: '',
        desc: '',
        query: '5',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/5.png',
      },
      {
        type: '',
        desc: '',
        query: '6',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/6.png',
      },
      {
        type: '',
        desc: '',
        query: '7',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/7.png',
      },
      {
        type: '',
        desc: '',
        query: '8',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/8.png',
      },
      {
        type: '',
        desc: '',
        query: '9',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/9.png',
      },
      {
        type: '',
        desc: '',
        query: '10',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/10.png',
      },
      {
        type: '',
        desc: '',
        query: '11',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/11.png',
      },
      {
        type: '',
        desc: '',
        query: '12',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/12.png',
      },
      {
        type: '',
        desc: '',
        query: '13',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/13.png',
      },
      {
        type: '',
        desc: '',
        query: '14',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/14.png',
      },
      {
        type: '',
        desc: '',
        query: '15',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/15.png',
      },
      {
        type: '',
        desc: '',
        query: '16',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/16.png',
      },
      {
        type: '',
        desc: '',
        query: '17',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/17.png',
      },
      {
        type: '',
        desc: '',
        query: '18',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/18.png',
      },
      {
        type: '',
        desc: '',
        query: '19',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/19.png',
      },
      {
        type: '',
        desc: '',
        query: '20',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/20.png',
      },
      {
        type: '',
        desc: '',
        query: '21',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/21.png',
      },
      {
        type: '',
        desc: '',
        query: '22',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/22.png',
      },
      {
        type: '',
        desc: '',
        query: '23',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/23.png',
      },
      {
        type: '',
        desc: '',
        query: '24',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/24.png',
      },
      {
        type: '',
        desc: '',
        query: '25',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/25.png',
      },
      {
        type: '',
        desc: '',
        query: '26',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/26.png',
      },
      {
        type: '',
        desc: '',
        query: '27',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/27.png',
      },
      {
        type: '',
        desc: '',
        query: '28',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/28.png',
      },
      {
        type: '',
        desc: '',
        query: '29',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/29.png',
      },
      {
        type: '',
        desc: '',
        query: '30',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/30.png',
      },
      {
        type: '',
        desc: '',
        query: '31',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/31.png',
      },
      {
        type: '',
        desc: '',
        query: '32',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/32.png',
      },
      {
        type: '',
        desc: '',
        query: '33',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/33.png',
      },
      {
        type: '',
        desc: '',
        query: '34',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/34.png',
      },
      {
        type: '',
        desc: '',
        query: '35',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/35.png',
      },
      {
        type: '',
        desc: '',
        query: '36',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/36.png',
      },
      {
        type: '',
        desc: '',
        query: '37',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/37.png',
      },
      {
        type: '',
        desc: '',
        query: '38',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/38.png',
      },
      {
        type: '',
        desc: '',
        query: '39',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/39.png',
      },
      {
        type: '',
        desc: '',
        query: '40',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/40.png',
      },
      {
        type: '',
        desc: '',
        query: '41',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/41.png',
      },
      {
        type: '',
        desc: '',
        query: '42',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/42.png',
      },
      {
        type: '',
        desc: '',
        query: '43',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/43.png',
      },
      {
        type: '',
        desc: '',
        query: '44',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/44.png',
      },
      {
        type: '',
        desc: '',
        query: '45',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/45.png',
      },
      {
        type: '',
        desc: '',
        query: '46',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/46.png',
      },
      {
        type: '',
        desc: '',
        query: '47',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/47.png',
      },
      {
        type: '',
        desc: '',
        query: '48',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/48.png',
      },
      {
        type: '',
        desc: '',
        query: '49',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/49.png',
      },
      {
        type: '',
        desc: '',
        query: '50',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/50.png',
      },
      {
        type: '',
        desc: '',
        query: '51',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/51.png',
      },
      {
        type: '',
        desc: '',
        query: '52',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/52.png',
      },
      {
        type: '',
        desc: '',
        query: '53',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/53.png',
      },
      {
        type: '',
        desc: '',
        query: '54',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/54.png',
      },
      {
        type: '',
        desc: '',
        query: '55',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/55.png',
      },
      {
        type: '',
        desc: '',
        query: '56',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/56.png',
      },
      {
        type: '',
        desc: '',
        query: '57',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/57.png',
      },
      {
        type: '',
        desc: '',
        query: '58',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/58.png',
      },
      {
        type: '',
        desc: '',
        query: '59',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/59.png',
      },
      {
        type: '',
        desc: '',
        query: '60',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/60.png',
      },
      {
        type: '',
        desc: '',
        query: '61',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/61.png',
      },
      {
        type: '',
        desc: '',
        query: '62',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/62.png',
      },
      {
        type: '',
        desc: '',
        query: '63',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/63.png',
      },
      {
        type: '',
        desc: '',
        query: '64',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/64.png',
      },
      {
        type: '',
        desc: '',
        query: '65',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/65.png',
      },
      {
        type: '',
        desc: '',
        query: '66',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/66.png',
      },
      {
        type: '',
        desc: '',
        query: '67',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/67.png',
      },
      {
        type: '',
        desc: '',
        query: '68',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/68.png',
      },
      {
        type: '',
        desc: '',
        query: '69',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/69.png',
      },
      {
        type: '',
        desc: '',
        query: '70',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/70.png',
      },
      {
        type: '',
        desc: '',
        query: '71',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/71.png',
      },
      {
        type: '',
        desc: '',
        query: '72',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/72.png',
      },
      {
        type: '',
        desc: '',
        query: '73',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/73.png',
      },
      {
        type: '',
        desc: '',
        query: '74',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/74.png',
      },
      {
        type: '',
        desc: '',
        query: '75',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/75.png',
      },
      {
        type: '',
        desc: '',
        query: '76',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/76.png',
      },
      {
        type: '',
        desc: '',
        query: '77',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/77.png',
      },
      {
        type: '',
        desc: '',
        query: '78',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/78.png',
      },
      {
        type: '',
        desc: '',
        query: '79',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/79.png',
      },
      {
        type: '',
        desc: '',
        query: '80',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/80.png',
      },
      {
        type: '',
        desc: '',
        query: '81',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/81.png',
      },
      {
        type: '',
        desc: '',
        query: '82',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/82.png',
      },
      {
        type: '',
        desc: '',
        query: '83',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/83.png',
      },
      {
        type: '',
        desc: '',
        query: '84',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/84.png',
      },
      {
        type: '',
        desc: '',
        query: '85',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/85.png',
      },
      {
        type: '',
        desc: '',
        query: '86',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/86.png',
      },
      {
        type: '',
        desc: '',
        query: '87',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/87.png',
      },
      {
        type: '',
        desc: '',
        query: '88',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/88.png',
      },
      {
        type: '',
        desc: '',
        query: '89',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/89.png',
      },
      {
        type: '',
        desc: '',
        query: '90',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/90.png',
      },
      {
        type: '',
        desc: '',
        query: '91',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/91.png',
      },
      {
        type: '',
        desc: '',
        query: '92',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/92.png',
      },
      {
        type: '',
        desc: '',
        query: '93',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/93.png',
      },
      {
        type: '',
        desc: '',
        query: '94',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/94.png',
      },
      {
        type: '',
        desc: '',
        query: '95',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/95.png',
      },
      {
        type: '',
        desc: '',
        query: '96',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/96.png',
      },
      {
        type: '',
        desc: '',
        query: '97',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/97.png',
      },
      {
        type: '',
        desc: '',
        query: '98',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/98.png',
      },
      {
        type: '',
        desc: '',
        query: '99',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/99.png',
      },
      {
        type: '',
        desc: '',
        query: '100',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/100.png',
      },
      {
        type: '',
        desc: '',
        query: '101',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/101.png',
      },
      {
        type: '',
        desc: '',
        query: '102',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/102.png',
      },
      {
        type: '',
        desc: '',
        query: '103',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/103.png',
      },
      {
        type: '',
        desc: '',
        query: '104',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/104.png',
      },
      {
        type: '',
        desc: '',
        query: '105',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/105.png',
      },
      {
        type: '',
        desc: '',
        query: '106',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/106.png',
      },
      {
        type: '',
        desc: '',
        query: '107',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/107.png',
      },
      {
        type: '',
        desc: '',
        query: '108',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/108.png',
      },
      {
        type: '',
        desc: '',
        query: '109',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/109.png',
      },
      {
        type: '',
        desc: '',
        query: '110',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/110.png',
      },
      {
        type: '',
        desc: '',
        query: '111',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/111.png',
      },
      {
        type: '',
        desc: '',
        query: '112',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/112.png',
      },
      {
        type: '',
        desc: '',
        query: '113',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/113.png',
      },
      {
        type: '',
        desc: '',
        query: '114',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/114.png',
      },
      {
        type: '',
        desc: '',
        query: '115',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/115.png',
      },
      {
        type: '',
        desc: '',
        query: '116',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/116.png',
      },
      {
        type: '',
        desc: '',
        query: '117',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/117.png',
      },
      {
        type: '',
        desc: '',
        query: '118',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/118.png',
      },
      {
        type: '',
        desc: '',
        query: '119',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/119.png',
      },
      {
        type: '',
        desc: '',
        query: '120',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/120.png',
      },
      {
        type: '',
        desc: '',
        query: '121',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/121.png',
      },
      {
        type: '',
        desc: '',
        query: '122',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/122.png',
      },
      {
        type: '',
        desc: '',
        query: '123',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/123.png',
      },
      {
        type: '',
        desc: '',
        query: '124',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/124.png',
      },
      {
        type: '',
        desc: '',
        query: '125',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/125.png',
      },
      {
        type: '',
        desc: '',
        query: '126',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/126.png',
      },
      {
        type: '',
        desc: '',
        query: '127',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/127.png',
      },
      {
        type: '',
        desc: '',
        query: '128',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/128.png',
      },
      {
        type: '',
        desc: '',
        query: '129',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/129.png',
      },
      {
        type: '',
        desc: '',
        query: '130',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/130.png',
      },
      {
        type: '',
        desc: '',
        query: '131',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/131.png',
      },
      {
        type: '',
        desc: '',
        query: '132',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/132.png',
      },
      {
        type: '',
        desc: '',
        query: '133',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/133.png',
      },
      {
        type: '',
        desc: '',
        query: '134',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/134.png',
      },
      {
        type: '',
        desc: '',
        query: '135',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/135.png',
      },
      {
        type: '',
        desc: '',
        query: '136',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/136.png',
      },
      {
        type: '',
        desc: '',
        query: '137',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/137.png',
      },
      {
        type: '',
        desc: '',
        query: '138',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/138.png',
      },
      {
        type: '',
        desc: '',
        query: '139',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/139.png',
      },
      {
        type: '',
        desc: '',
        query: '140',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/140.png',
      },
      {
        type: '',
        desc: '',
        query: '141',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/141.png',
      },
      {
        type: '',
        desc: '',
        query: '142',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/142.png',
      },
      {
        type: '',
        desc: '',
        query: '143',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/143.png',
      },
      {
        type: '',
        desc: '',
        query: '144',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/144.png',
      },
      {
        type: '',
        desc: '',
        query: '145',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/145.png',
      },
      {
        type: '',
        desc: '',
        query: '146',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/146.png',
      },
      {
        type: '',
        desc: '',
        query: '147',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/147.png',
      },
      {
        type: '',
        desc: '',
        query: '148',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/148.png',
      },
      {
        type: '',
        desc: '',
        query: '149',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/149.png',
      },
      {
        type: '',
        desc: '',
        query: '150',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/150.png',
      },
      {
        type: '',
        desc: '',
        query: '151',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/151.png',
      },
      {
        type: '',
        desc: '',
        query: '152',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/152.png',
      },
      {
        type: '',
        desc: '',
        query: '153',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/153.png',
      },
      {
        type: '',
        desc: '',
        query: '154',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/154.png',
      },
      {
        type: '',
        desc: '',
        query: '155',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/155.png',
      },
      {
        type: '',
        desc: '',
        query: '156',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/156.png',
      },
      {
        type: '',
        desc: '',
        query: '157',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/157.png',
      },
      {
        type: '',
        desc: '',
        query: '158',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/158.png',
      },
      {
        type: '',
        desc: '',
        query: '159',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/159.png',
      },
      {
        type: '',
        desc: '',
        query: '160',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/160.png',
      },
      {
        type: '',
        desc: '',
        query: '161',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/161.png',
      },
      {
        type: '',
        desc: '',
        query: '162',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/162.png',
      },
      {
        type: '',
        desc: '',
        query: '163',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/163.png',
      },
      {
        type: '',
        desc: '',
        query: '164',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/164.png',
      },
      {
        type: '',
        desc: '',
        query: '165',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/165.png',
      },
      {
        type: '',
        desc: '',
        query: '166',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/166.png',
      },
      {
        type: '',
        desc: '',
        query: '167',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/167.png',
      },
      {
        type: '',
        desc: '',
        query: '168',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/168.png',
      },
      {
        type: '',
        desc: '',
        query: '169',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/169.png',
      },
      {
        type: '',
        desc: '',
        query: '170',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/170.png',
      },
      {
        type: '',
        desc: '',
        query: '171',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/171.png',
      },
      {
        type: '',
        desc: '',
        query: '172',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/172.png',
      },
      {
        type: '',
        desc: '',
        query: '173',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/173.png',
      },
      {
        type: '',
        desc: '',
        query: '174',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/174.png',
      },
      {
        type: '',
        desc: '',
        query: '175',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/175.png',
      },
      {
        type: '',
        desc: '',
        query: '176',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/176.png',
      },
      {
        type: '',
        desc: '',
        query: '177',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/177.png',
      },
      {
        type: '',
        desc: '',
        query: '178',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/178.png',
      },
      {
        type: '',
        desc: '',
        query: '179',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/179.png',
      },
      {
        type: '',
        desc: '',
        query: '180',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/180.png',
      },
      {
        type: '',
        desc: '',
        query: '181',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/181.png',
      },
      {
        type: '',
        desc: '',
        query: '182',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/182.png',
      },
      {
        type: '',
        desc: '',
        query: '183',
        img_src: 'https://images.ktestone.com/resultImages/dogSounds/183.png',
      },
    ],
  },
  // oneSidedLove in English
  {
    info: {
      mainTitle: 'Unrequited love candy test',
      subTitle: 'What is my unrequited love cnady type?',
      mainUrl: 'oneSidedLoveEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/oneSidedLoveEng-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/oneSidedLoveEng-thumb.png',
      lang: 'Eng',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: 'Who am I my friends talk about?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Energetic and confident when I speak of my opinion',
          },
          {
            type: 'I',
            score: 5,
            content: 'passive and a bit quite.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'Start up of new semester!\nHow to approach to a friend of the next seat?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Say hello to him first.',
          },
          {
            type: 'I',
            score: 5,
            content: 'Wait until he speaks to me.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'Your friend asks you to drag you\nin front of the opposite gender\nand have a conversation.',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Laugh and have fun talking together.',
          },
          {
            type: 'I',
            score: 5,
            content: "I'm shy,\nso I just smile quietly and be awkward.",
          },
        ],
      },
      {
        which: 'SN',
        question: 'When I think of the person I like,\nwhat do I do?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Can it be done realistically?',
          },
          {
            type: 'N',
            score: 5,
            content:
              'Ha... If I date that person,\nI will do this and that too~\nOh, it makes my heart flutter!',
          },
        ],
      },
      {
        which: 'SN',
        question: 'Me in love,\nwhen I look at the world?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              "'A mountain is a mountain.\nWater is water.’\nSee it as it is.",
          },
          {
            type: 'N',
            score: 5,
            content:
              'Rather than reality,\nI spread my own world,\nmy imagination.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'During a date,\nI was hungry and found a restaurant.',
        answers: [
          {
            type: 'S',
            score: 2,
            content: "The review looks good!\nLet's go here!",
          },
          {
            type: 'N',
            score: 5,
            content: 'Oh, It smells a restaurant that looks perfect.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'What is my first reaction when I am angry?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Speak rationally about the reason for my anger.',
          },
          {
            type: 'F',
            score: 5,
            content: "I'm filled with anger and I'm crying.",
          },
        ],
      },
      {
        which: 'TF',
        question: 'The person I like is in a difficult situation.',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Propose and support realistic solutions.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Empathize with emotions and\nprovides psychological support.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'When I talk to someone I like,\nwhat do I do?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Ask questions and ask questions about him.',
          },
          {
            type: 'F',
            score: 5,
            content: 'React and empathize when he is talking to me.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Suddenly the mood Jeju Island!\nI want to go to Jeju Island!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Plan a detailed schedule for each day of the week.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'Flight ticket reservation complete!\nI think the plan is almost over.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Which one is closer to me?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I am well organized and\nfind where my things are.',
          },
          {
            type: 'P',
            score: 5,
            content:
              "I think I know where my things are,\nbut when I look for them,\nI can't find them...",
          },
        ],
      },
      {
        which: 'JP',
        question: 'I try to confess to the person you like.',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Make a thorough plan and\naim for the right time to confess.',
          },
          {
            type: 'P',
            score: 5,
            content: 'Confess promptly based on the atmosphere.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveEng/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveEng/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveEng/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveEng/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveEng/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveEng/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveEng/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveEng/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveEng/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveEng/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveEng/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveEng/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveEng/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveEng/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveEng/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveEng/INFP.png',
      },
    ],
  },
  // oneSidedLove in Chinese
  {
    info: {
      mainTitle: '单恋糖果测试',
      subTitle: '我的单恋糖果类型是什么呢？',
      mainUrl: 'oneSidedLoveCN',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/oneSidedLoveCN-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/oneSidedLoveCN-thumb.png',
      lang: 'CN',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '朋友们口中的我是？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '活泼开朗，\n表达自己的想法时很有信心！',
          },
          {
            type: 'I',
            score: 5,
            content: '比较被动，很安静',
          },
        ],
      },
      {
        which: 'EI',
        question: '新学期开学啦！一个朋友坐在你身旁，你会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '会主动先跟朋友打招呼。',
          },
          {
            type: 'I',
            score: 5,
            content: '朋友先打招呼之前不会主动问候。',
          },
        ],
      },
      {
        which: 'EI',
        question: '被拉去朋友喜欢的异性面前交谈。',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '一起愉快地聊天。',
          },
          {
            type: 'I',
            score: 5,
            content: '很害羞的你保持安静，\n尴尬地微笑。',
          },
        ],
      },
      {
        which: 'SN',
        question: '想喜欢的人时我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '思考这段恋情是否现实？',
          },
          {
            type: 'N',
            score: 5,
            content: '哇…和他在一起的话一定要做这个做那个~啊~好心动！',
          },
        ],
      },
      {
        which: 'SN',
        question: '陷入爱河的我，\n看待世界时？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '‘山是山，水是水’\n看起来都一样。',
          },
          {
            type: 'N',
            score: 5,
            content: '比起现实更专注自己的世界，\n会展开想象幻想未来。',
          },
        ],
      },
      {
        which: 'SN',
        question: '约会时肚子饿了，\n这时发现了一家饭馆。',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '评论看起来还不错！\n那就这里了！',
          },
          {
            type: 'N',
            score: 5,
            content: '哇塞，这里一看就不错，\n味道好棒呀。',
          },
        ],
      },
      {
        which: 'TF',
        question: '生气时我的第一个反应是？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '理性地说自己为什么生气。',
          },
          {
            type: 'F',
            score: 5,
            content: '愤怒逼心，\n眼泪先出来。',
          },
        ],
      },
      {
        which: 'TF',
        question: '喜欢的人陷入困境时。',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '提出比较现实的解决方案并给对方加油打气。',
          },
          {
            type: 'F',
            score: 5,
            content: '理解对方并给予精神上的支持。',
          },
        ],
      },
      {
        which: 'TF',
        question: '和喜欢的人聊天时我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '通过提问了解对方，\n越来越好奇。',
          },
          {
            type: 'F',
            score: 5,
            content: '和对方对话的时候给与相应的反应并产生共鸣。',
          },
        ],
      },
      {
        which: 'JP',
        question: '突然氛围聊起济州岛！\n济州岛走起吧！',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '详细地计划每天的日程',
          },
          {
            type: 'P',
            score: 5,
            content: '机票订好啦~！\n认为计划几乎完成了。',
          },
        ],
      },
      {
        which: 'JP',
        question: '更接近我的类型是？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '擅长于整理整顿，\n知道自己的东西在哪里，很快能找出来。',
          },
          {
            type: 'P',
            score: 5,
            content:
              '虽然自己认为知道自己的东西在哪儿，\n但突然找起来的时候却找不到…',
          },
        ],
      },
      {
        which: 'JP',
        question: '准备向喜欢的人表白时。',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '做好一切准备，\n抓住表白的最佳时机。',
          },
          {
            type: 'P',
            score: 5,
            content: '看情况行事，\n直接表白。',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveCN/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveCN/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveCN/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveCN/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveCN/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveCN/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveCN/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveCN/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveCN/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveCN/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveCN/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveCN/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveCN/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveCN/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveCN/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveCN/INFP.png',
      },
    ],
  },
  // oneSidedLove for Russia
  {
    info: {
      mainTitle: 'Тест честной любовной конфеты',
      subTitle: 'Что такое мой тип взаимной любви к конфету?',
      mainUrl: 'oneSidedLoveRus',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/oneSidedLoveRus-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/oneSidedLoveRus-thumb.png',
      lang: 'Rus',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: 'Как друзья о мне думают?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Уверенно говорю о свой мысли, и активный!',
          },
          {
            type: 'I',
            score: 5,
            content: 'Несмелый, тихи характер.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'Началось новый семестр! Одно курсник сел на соседний стол как я буду себя вести?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Первый будет здороваться.',
          },
          {
            type: 'I',
            score: 5,
            content: 'Жду, что бы друг первым здоровалось.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Когда друг позвал любимого человека просто поболтать',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Вместе весело общаемся.',
          },
          {
            type: 'I',
            score: 5,
            content: 'Стиснительно тихо улыбаюсь не чего не говоря',
          },
        ],
      },
      {
        which: 'SN',
        question: 'Как я себя виду, Когда думаю о любимому человека?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Реально мы можем встречаться?',
          },
          {
            type: 'N',
            score: 5,
            content:
              'Ам... Если будем встречаться то куда пойдем что будем делать и.т.д',
          },
        ],
      },
      {
        which: 'SN',
        question: 'Когда я в любляюсь как смотрю на мир',
        answers: [
          {
            type: 'S',
            score: 2,
            content: "'Гора это гора, Вода это вода' смотрю как есть",
          },
          {
            type: 'N',
            score: 5,
            content:
              'Вместо реальности я распространяю свой мир, свое воображение.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'На свидании я проголодался и нашел ресторан.',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Обзор выглядит неплохо! Пойдем сюда!',
          },
          {
            type: 'N',
            score: 5,
            content: 'Ох, этот ресторан выглядит идеально для Смельнанды.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'Моя первая реакция, когда я злюсь?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Объясняйте, почему вы злитесь, рационально.',
          },
          {
            type: 'F',
            score: 5,
            content: 'Меня переполняет гнев, и я плачу.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'Любимый человек находится в сложной ситуации.',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Предлагайте реалистичные решения и поддерживайте их.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Он сопереживает эмоциям и оказывает психологическую поддержку.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'Когда общаюсь с любимым человеком?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Спрашиваю постоянно интересуюсь о любимому человеку.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Слушаю что говорит, хорошо соглашиваюсь и делаю хороший реакцию.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Внезапно захотелось путешествовать! Надо поехать!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Составьте подробный график.',
          },
          {
            type: 'P',
            score: 5,
            content: 'Бронирование авиабилетов завершено! планы закончилось.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Какой мне ближе?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Я хорошо организован и нахожу, где мои вещи.',
          },
          {
            type: 'P',
            score: 5,
            content: 'Я думаю, что знаю, где мои вещи, но не могу их найти ...',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Попробуем признаться любимому человеку.',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Составьте тщательный план и постарайтесь найти подходящее время для признания.',
          },
          {
            type: 'P',
            score: 5,
            content: 'Глядя на атмосферу, признаюсь на месте.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveRus/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveRus/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveRus/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveRus/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveRus/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveRus/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveRus/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveRus/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveRus/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveRus/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveRus/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveRus/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveRus/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveRus/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveRus/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveRus/INFP.png',
      },
    ],
  },
  // oneSidedLove for Japan
  {
    info: {
      mainTitle: '片思いキャンディテストです。',
      subTitle: '私の片思いキャンディタイプは何でしょうか。',
      mainUrl: 'oneSidedLoveJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/oneSidedLoveJP-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/oneSidedLoveJP-thumb.png',
      lang: 'JP',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '友達から見た自分の印象は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '明るくて自分の意見を堂々とした態度で言える人。',
          },
          {
            type: 'I',
            score: 5,
            content: '大人しくて内向的な人。',
          },
        ],
      },
      {
        which: 'EI',
        question: '新学期の始まり！隣の席にクラスメイトに挨拶するときは？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '自分から挨拶をする。',
          },
          {
            type: 'I',
            score: 5,
            content: '相手から挨拶されるのを待つ。',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '好きな異性の前に連れて行かれて、話しかけてみようよ、と友達に促された。',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '一緒に笑いながら楽しく会話する。',
          },
          {
            type: 'I',
            score: 5,
            content: 'ぎこちなくて照れくさくて静かに笑うだけ。',
          },
        ],
      },
      {
        which: 'SN',
        question: '好きな人のことを思うときの自分は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '現実的にこの恋は叶うだろうか？',
          },
          {
            type: 'N',
            score: 5,
            content:
              'もしあの人と付き合ったら…あれもこれもしたい～もう、ときめきが止まらない！',
          },
        ],
      },
      {
        which: 'SN',
        question: '恋に落ちたあなたの目に、世界はどんな風に映っていますか？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '「山は山　 水は水」ありのままに見えている。',
          },
          {
            type: 'N',
            score: 5,
            content: '現実よりは自分だけの世界、妄想が広がる。',
          },
        ],
      },
      {
        which: 'SN',
        question: 'デート中、お腹が減ってきたところ丁度いい店を見つけた。',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'レビューを見たけど、良い店らしい！この店に入ろう！',
          },
          {
            type: 'N',
            score: 5,
            content: 'なんとなくだけど、この店絶対に美味しいと思うよ。',
          },
        ],
      },
      {
        which: 'TF',
        question: 'あなたの「怒りタイプ」は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '怒った理由について理性的に話す。',
          },
          {
            type: 'F',
            score: 5,
            content: '怒りがこみ上げてきて涙が出る。',
          },
        ],
      },
      {
        which: 'TF',
        question: '好きな人が大変な状況に置かれたら？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '現実的な解決策を提示して応援する。',
          },
          {
            type: 'F',
            score: 5,
            content: 'その人の気持ちに共感してあげて、精神的に支えてあげる。',
          },
        ],
      },
      {
        which: 'TF',
        question: '好きな人と話すときの自分は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '相手のことについて知りたいので色々質問する。',
          },
          {
            type: 'F',
            score: 5,
            content: '相手の話にリアクションを打ちつつ、話に共感してあげる。',
          },
        ],
      },
      {
        which: 'JP',
        question: '突然沖縄に行きたくなった。旅行に行く時はどうする？ ',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '曜日ごとに詳細な日程を計画する。',
          },
          {
            type: 'P',
            score: 5,
            content:
              '飛行機のチケットは予約した。これで計画することはもう終わった。',
          },
        ],
      },
      {
        which: 'JP',
        question: '自分により近いタイプは？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '整理整頓上手で、物の置き場所を把握している。',
          },
          {
            type: 'P',
            score: 5,
            content:
              '物の置き場所をちゃんと把握しているつもりだったのに、いざ探すとその場には無い…',
          },
        ],
      },
      {
        which: 'JP',
        question: '好きな人に告白するなら？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '徹底的にシミュレーションして告白するタイミングを計らう。',
          },
          {
            type: 'P',
            score: 5,
            content: '雰囲気をみて、直感的に告白する。',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveJP/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveJP/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveJP/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveJP/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveJP/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveJP/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveJP/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveJP/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveJP/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveJP/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveJP/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveJP/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveJP/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveJP/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveJP/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLoveJP/INFP.png',
      },
    ],
  },
  // oneSidedLove //
  {
    info: {
      mainTitle: '짝사랑 캔디 테스트',
      subTitle: '나의 짝사랑 캔디 타입은 무엇일까?',
      mainUrl: 'oneSidedLove',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/oneSidedLove-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/oneSidedLove-thumb.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        which: 'EI',
        question: '친구들이 말하는 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '활발하고 내 의견을 얘기할 때 당당한 편!',
          },
          {
            type: 'I',
            score: 5,
            content: '소극적이면서 조금 조용한 편',
          },
        ],
      },
      {
        which: 'EI',
        question: '새 학기의 시작! 옆자리 앉은 친구에게 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구에게 먼저 인사한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '친구가 먼저 인사할 때 까지 기다린다.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '친구가 좋아하는 이성 앞에 끌고 가 같이 대화를 나누자고 한다.',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '같이 웃으며 즐겁게 대화한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '쑥스러워서 조용히 미소만 짓고 어색하게 있는다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '좋아하는 사람을 생각할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '현실적으로 이루어질 수 있을까?',
          },
          {
            type: 'N',
            score: 5,
            content: '하...그 사람이랑 사귀면 이것도 저것도 하고~ 아 설레네!',
          },
        ],
      },
      {
        which: 'SN',
        question: '사랑에 빠진 나, 내가 세상을 바라볼 때?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '‘산은 산이요. 물은 물이로다.’ 있는 그대로 본다.',
          },
          {
            type: 'N',
            score: 5,
            content: '현실보다는 나만의 세계, 상상의 나래를 펼친다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '데이트 중 슬슬 배가 고픈데 음식점을 발견했다.',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '리뷰 보니 괜찮다! 여기로 가자!',
          },
          {
            type: 'N',
            score: 5,
            content: '오오 보기에 딱 여긴 맛집 스멜난다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '화가 났을 때 나의 첫 반응은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '화난 이유에 대해 이성적으로 말한다.',
          },
          {
            type: 'F',
            score: 5,
            content: '분노에 차올라 눈물부터 난다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '좋아하는 사람이 힘든 상황에 처했다.',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '현실적인 해결책을 제시하고 응원한다.',
          },
          {
            type: 'F',
            score: 5,
            content: '감정을 공감하고 정신적인 지지를 해준다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '좋아하는 사람과 대화할 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '질문으로 상대방에 대해 물어보고 궁금해한다.',
          },
          {
            type: 'F',
            score: 5,
            content: '그 사람이 대화할 때 리액션하며 공감한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '갑자기 분위기 제주도! 제주도에 가야겠다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '요일별로 세부일정을 계획한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '비행기 표 예매 완료~! 계획은 거의 끝났다고 본다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '나랑 더 가까운 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '정리정돈을 잘하고 내 물건이 어딨는지 잘 찾는다.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '내 물건들은 어디 있는지 잘 안다고 생각하지만, 막상 찾아보면 없다…',
          },
        ],
      },
      {
        which: 'JP',
        question: '좋아하는 사람에게 고백하려고 한다.',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '철저한 계획을 잡고 고백할 타이밍을 노린다.',
          },
          {
            type: 'P',
            score: 5,
            content: '분위기 봐서 즉흥적으로 고백한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: '',
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLove/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: '',
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLove/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: '',
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLove/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: '',
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLove/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: '',
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLove/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: '',
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLove/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: '',
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLove/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: '',
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLove/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: '',
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLove/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: '',
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLove/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: '',
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLove/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: '',
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLove/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: '',
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLove/INTJ.png',
      },
      {
        type: 'INTP',
        desc: '',
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLove/INTP.png',
      },
      {
        type: 'INFJ',
        desc: '',
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLove/INFJ.png',
      },
      {
        type: 'INFP',
        desc: '',
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/oneSidedLove/INFP.png',
      },
    ],
  },
  // hanbokBTIJP
  {
    info: {
      mainTitle: '韓服BTI',
      subTitle: '韓服BTI 韓服テスト',
      mainUrl: 'hanbokBTIJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/hanbokBTIJP-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/hanbokBTIJP-thumb.png',
      // horizontalBanner: "https://images.ktestone.com/horizontalNewTest/Japan/hanbokBTIJP.png",
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          '時代劇ドラマの主人公の韓服、真似してみようよ。\nと友達に誘われたら？',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'いいね！じゃあ、私はファン・ジニの衣装が着てみたい！\nあなたは誰がいい？',
          },
          {
            type: 'I',
            score: 5,
            content: 'えっ、ちょっと、恥ずかしいよ…',
          },
        ],
      },
      {
        which: 'SN',
        question:
          '韓服体験をすることになった。\n韓服を着た自分を想像してみるなら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '実際着てみないと、\n想像したって意味ないよ',
          },
          {
            type: 'N',
            score: 5,
            content:
              'うーん。こんな感じはどうかな。\nどれが似合うんだろう？と、イメージを膨らます。',
          },
        ],
      },
      {
        which: 'TF',
        question: '友達とランチをするときの自分は？',
        answers: [
          {
            type: 'F',
            score: 2,
            content: '友達が食べたいものに合わせる。',
          },
          {
            type: 'T',
            score: 5,
            content: '自分が食べたいものを積極的にアピール！',
          },
        ],
      },
      {
        which: 'JP',
        question: '民俗村に遊びに行って、\n伝統体験をするならどうする？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'どんな体験ができるのか、\nあらかじめネットで調べておく！',
          },
          {
            type: 'P',
            score: 5,
            content: 'とりあえず行って、\n気になることから体験する！',
          },
        ],
      },
      {
        which: 'SN',
        question: '韓服を着るときの自分は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '伝統の着付けを守って着る',
          },
          {
            type: 'N',
            score: 5,
            content: '自分流で好きなように着る！',
          },
        ],
      },
      {
        which: 'SN',
        question: 'お悩みの相談に乗るときの自分は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '共感より現実的なアドバイスをする。',
          },
          {
            type: 'N',
            score: 5,
            content: 'その人の感情に共感してあげる。',
          },
        ],
      },
      {
        which: 'JP',
        question: '今日はお酒が飲みたいな、\nと思って友達にLINEをした。',
        answers: [
          {
            type: 'P',
            score: 2,
            content: '今夜焼酎飲もうよ。',
          },
          {
            type: 'J',
            score: 5,
            content: '週末空いてる？\n焼酎でも飲まない？',
          },
        ],
      },
      {
        which: 'JP',
        question: '民俗村に行くことになった。\nその日の予定を立てるなら？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '必須コースは必ず行かなきゃ！\n予定通りに動く！',
          },
          {
            type: 'P',
            score: 5,
            content:
              '計画はあくまでも計画に過ぎない。\n歩き回って予定になかった場所にも立ち寄る。',
          },
        ],
      },
      {
        which: 'TF',
        question: '韓服体験どうだった？と友達に聞かれたら、\nどう答える？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '久しぶりに韓服が着れて、\n良い経験だったよ。',
          },
          {
            type: 'F',
            score: 5,
            content:
              '韓服って本当にきれいだね！\n時代劇ドラマの主人公になったような気分だった。',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '友達に悩みを相談されたけど、\nよくよく聞いたら友達の方が悪い。',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '友人の悪いところをちゃんと説明してあげる。',
          },
          {
            type: 'F',
            score: 5,
            content: '直接非難するよりは、\n遠回しに言う。',
          },
        ],
      },
      {
        which: 'EI',
        question: '韓服を着て、\nせっかくだから友達と二人で写真を撮りたい。',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '周りの人にお願いして、\nその人達の写真を撮ってあげた後、\nこちらの写真も撮ってもらう。',
          },
          {
            type: 'I',
            score: 5,
            content:
              '誰かに「写真撮ってください」とお願いされたら、\nついでに「こちらも」言う。',
          },
        ],
      },
      {
        which: 'EI',
        question: '花の金曜日、そして週末。\n何をする？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '友達数人で遊びまくってリフレッシュする。',
          },
          {
            type: 'I',
            score: 5,
            content: '自宅で休んでリフレッシュする。',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `ESTJ
                `,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIJP/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `ESTP`,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `ESFJ`,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIJP/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `ESFP`,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIJP/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `ENTJ`,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIJP/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `ENTP
                `,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIJP/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `ENFJ`,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIJP/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `ENFP`,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIJP/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `ISTJ`,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIJP/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `ISTP`,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIJP/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `ISFJ`,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIJP/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `ISFP
                `,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `INTJ`,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIJP/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `INTP`,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIJP/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `INFJ`,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIJP/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `INFP`,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIJP/INFP.png',
      },
    ],
  },
  // hanbokBTIEng
  {
    info: {
      mainTitle: 'HanbokBTI',
      subTitle: 'Which Hanbok style suits me? Korean traditional clothes test',
      mainUrl: 'hanbokBTIEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/hanbokBTIEng-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/hanbokBTIEng-thumb.png',
      // horizontalBanner: "https://images.ktestone.com/horizontalNewTest/USA/hanbokBTIEng.png",
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          "Let's try on a Hanbok,\nfollowing the heroine of our historical drama!\nMy friend!",
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              "Yes, yes!\nThen I'll play the role of Hwang Jin-i!\nWhich character you will choose?",
          },
          {
            type: 'I',
            score: 5,
            content: "Ah… I'm a little shy.... Hehehe",
          },
        ],
      },
      {
        which: 'SN',
        question:
          'I, who decide to make experience for a Hanbok,\nimagine myself wearing a Hanbok.',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'What can I only if I imagine wearing it on myself?\nI think I should wear it myself.',
          },
          {
            type: 'N',
            score: 5,
            content:
              'Well? Shall we wear it like this?\nWhat would suit me?\nI imagine alone.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'Friends who want to have lunch with me!',
        answers: [
          {
            type: 'F',
            score: 2,
            content: 'Follow what your friends ask to eat!',
          },
          {
            type: 'T',
            score: 5,
            content: 'I actively recommend what I want to eat!',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'I decided to go to the folk village\nand experience the tradition. I do?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Searching the internet,\nI go there for the experiences you can have in the folk village! ',
          },
          {
            type: 'P',
            score: 5,
            content: 'Go there first and do what you like!',
          },
        ],
      },
      {
        which: 'SN',
        question: 'What if I wore a Hanbok?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Wear it in the traditional way',
          },
          {
            type: 'N',
            score: 5,
            content: 'Wear it uniquely in my way!',
          },
        ],
      },
      {
        which: 'SN',
        question: 'When consulting for others,\nshall I?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Gives them realistic advices rather than empathy.',
          },
          {
            type: 'N',
            score: 5,
            content: 'I empathizes with them emotionally.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'As I want to drink liquor,\nI did a Kakaako to my friend!',
        answers: [
          {
            type: 'P',
            score: 2,
            content: 'Soju tonight?',
          },
          {
            type: 'J',
            score: 5,
            content:
              'What are you going to do this weekend?\nHow about drinking Soju with me?',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'I decided to go to the folk village\nand made a plan for the day!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Make sure to go to the “must” course!\nFollow the plan!',
          },
          {
            type: 'P',
            score: 5,
            content:
              'The plan is just a plan only~\nGo around as planned but go somewhere else!',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'For my acquaintance’s question of\n“How was your Hanbok experience?” Shall I?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I had a good experience wearing a Hanbok after a while.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Wow, Korean Hanbok is so pretty!\nI looked like I was the main character of a historical drama',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'A friend who talks about his worries to me,\nwhich I think it may be from his fault most.',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Explain him about the wrong part carefully.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Explain him, beating around bush instead of telling him directly.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'As I am wearing a Hanbok,\nI want to take a picture with my friend.',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Ask a person passing to take a picture for us.',
          },
          {
            type: 'I',
            score: 5,
            content: 'Speak to someone to asks for taking a picture.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'During a gorgeous and hot Friday and golden weekend,\nshall I?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'Recharge myself while playing actively with several people.',
          },
          {
            type: 'I',
            score: 5,
            content: 'Recharge myself staying at home.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `ESTJ
                `,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIEng/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `ESTP`,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `ESFJ`,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `ESFP`,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `ENTJ`,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `ENTP
                `,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `ENFJ`,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIEng/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `ENFP`,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIEng/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `ISTJ`,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `ISTP`,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `ISFJ`,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `ISFP
                `,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `INTJ`,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `INTP`,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `INFJ`,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `INFP`,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/hanbokBTIEng/INFP.jpg',
      },
    ],
  },
  // hanbokBTI //
  {
    info: {
      mainTitle: '한복BTI hanbokBTI',
      subTitle: '나에게 어울리는 한복스타일은? 한복BTI 한복테스트',
      mainUrl: 'hanbokBTI',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/hanbokBTI-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/hanbokBTI-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/hanbokBTI.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '우리 사극드라마 주인공 한복을 따라 입어보자! 라는 친구!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '그래그래! 나 그럼 황진이할래! 너 뭐할랭?',
          },
          {
            type: 'I',
            score: 5,
            content: '아…조금 부끄럽네…ㅎ',
          },
        ],
      },
      {
        which: 'SN',
        question: '한복 체험을 하기로 한 나, 한복을 입은 나를 상상해보았다.',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '뭐 직접 입어봐야 알지 상상해봐야 뭐하겠니',
          },
          {
            type: 'N',
            score: 5,
            content:
              '음? 이렇게 입어볼까? 뭐가 어울릴까? 혼자 상상의 나래를 펼친다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '점심을 먹자고 하는 친구들!',
        answers: [
          {
            type: 'F',
            score: 2,
            content: '친구들이 먹자고 하는 거에 따른다!',
          },
          {
            type: 'T',
            score: 5,
            content: '내가 먹고 싶은 걸 적극적으로 추천한다!',
          },
        ],
      },
      {
        which: 'JP',
        question: '민속촌에 가서 전통체험을 하기로 했다. 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '민속촌에 무슨 체험이 있는지 인터넷 써치해보고 간다!',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 가서 먼저 마음에 드는 거 먼저 한다!',
          },
        ],
      },
      {
        which: 'SN',
        question: '한복을 입을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '전통 방식 그대로 따라 입는다 ',
          },
          {
            type: 'N',
            score: 5,
            content: '나만의 방법으로 색다르게 입어본다! ',
          },
        ],
      },
      {
        which: 'SN',
        question: '고민 상담을 들어줄 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '공감보다는 현실적인 조언을 해준다.',
          },
          {
            type: 'N',
            score: 5,
            content: '감정적으로 공감해준다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '술이 마시고 싶은 나는 친구에게 카톡을 했다!',
        answers: [
          {
            type: 'P',
            score: 2,
            content: '오늘 저녁에 소주 긔?',
          },
          {
            type: 'J',
            score: 5,
            content: '주말에 머해? 소주 기기할랭?',
          },
        ],
      },
      {
        which: 'JP',
        question: '민속촌을 가기로 해서 하루의 계획을 짜보기로 했다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '필수코스는 반드시 가자! 계획대로 움직이기!',
          },
          {
            type: 'P',
            score: 5,
            content: '계획은 계획일 뿐~ 돌아다니다가 다른 곳으로 가버리기!',
          },
        ],
      },
      {
        which: 'TF',
        question: '한복 체험 어땠어? 라고 물어보는 지인의 말에 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '오랜만에 한복도 입어보고 좋은 경험했어!',
          },
          {
            type: 'F',
            score: 5,
            content:
              '와 진짜 우리나라 한복 너무 이뻐! 나 무슨 사극 드라마 주인공 된줄 알았잖아',
          },
        ],
      },
      {
        which: 'TF',
        question: '고민을 얘기 하는 친구, 들어보니 친구의 잘못이 크다',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '친구의 잘못된 부분을 잘 설명해준다.',
          },
          {
            type: 'F',
            score: 5,
            content: '직접적이기보다 돌려서 잘 설명해준다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '한복도 입어봤으니 친구랑 둘이 사진을 찍고 싶다.',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '지나가는 사람에게 다가가서 먼저 찍어주고 우리도 찍어달라고 한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '누군가 우리에게 찍어 달라고 하면 말한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '화려한 불금 그리고 황금 같은 주말에 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '여러명과 함께 신나게 놀면서 에너지를 채운다.',
          },
          {
            type: 'I',
            score: 5,
            content: '집에서 휴식을 취하며 에너지를 채운다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '내가 생각 하는 한복은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '우리의 전통미를 상징하는 한복이다.',
          },
          {
            type: 'I',
            score: 5,
            content:
              '한민족의 고유한 의복으로 예부터 전해 내려오는 전통의상이다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `도도하고 시크한 카리스마가 느껴지는 깔끔한 전통 한복’ \n
                `,
        query: 'ESTJ',
        img_src: 'https://images.ktestone.com/resultImages/hanbokBTI/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `개방적이고 활발한 생활 한복\n`,
        query: 'ESTP',
        img_src: 'https://images.ktestone.com/resultImages/hanbokBTI/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `따뜻하면서 밝은 분위기를 돋보이게 하는 전통 한복\n`,
        query: 'ESFJ',
        img_src: 'https://images.ktestone.com/resultImages/hanbokBTI/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `트렌드를 넘어선, 화려하고 고혹적인 한복\n`,
        query: 'ESFP',
        img_src: 'https://images.ktestone.com/resultImages/hanbokBTI/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `늘 자신감이 넘치고 당당한 나에게 어울리는 세련된 한복\n`,
        query: 'ENTJ',
        img_src: 'https://images.ktestone.com/resultImages/hanbokBTI/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `늘 자신감 넘치는 나만의 개성이 드러난, 화려한 그라데이션 한복 \n
                `,
        query: 'ENTP',
        img_src: 'https://images.ktestone.com/resultImages/hanbokBTI/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `나만의 포인트 아이템을 더한, 단정하면서 부드러운 느낌의 한복\n`,
        query: 'ENFJ',
        img_src: 'https://images.ktestone.com/resultImages/hanbokBTI/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `기존의 웨딩 한복을 벗어난 매력적인 한복 드레스\n`,
        query: 'ENFP',
        img_src: 'https://images.ktestone.com/resultImages/hanbokBTI/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `깔끔하면서 고급스러운 느낌을 주는 생활 한복\n`,
        query: 'ISTJ',
        img_src: 'https://images.ktestone.com/resultImages/hanbokBTI/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `활동적이고 편안한, 담백한 감성의 생활 한복\n`,
        query: 'ISTP',
        img_src: 'https://images.ktestone.com/resultImages/hanbokBTI/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `차분하고 온화한 분위기의 전통 한복\n`,
        query: 'ISFJ',
        img_src: 'https://images.ktestone.com/resultImages/hanbokBTI/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `단정하면서 무난한데 나만의 포인트를 더한 한복\n
                `,
        query: 'ISFP',
        img_src: 'https://images.ktestone.com/resultImages/hanbokBTI/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `부족한 나의 감성을 다시 살려줄 낭만적인 한복\n`,
        query: 'INTJ',
        img_src: 'https://images.ktestone.com/resultImages/hanbokBTI/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `느긋하고 편안하게 움직일 수 있는, 개성 있는 생활한복\n`,
        query: 'INTP',
        img_src: 'https://images.ktestone.com/resultImages/hanbokBTI/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `차분하면서 고급스럽고, 단정하며 캐주얼한 느낌의 한복\n`,
        query: 'INFJ',
        img_src: 'https://images.ktestone.com/resultImages/hanbokBTI/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `섬세하고 비밀스러운 개성이 드러나는 한복\n`,
        query: 'INFP',
        img_src: 'https://images.ktestone.com/resultImages/hanbokBTI/INFP.png',
      },
    ],
  },
  // constellation
  {
    info: {
      mainTitle: '별자리 테스트',
      subTitle: '별자리로 알아보는 내 성격',
      mainUrl: 'constellation',
      scoreType: 'Constellation',
      mainImage:
        'https://images.ktestone.com/introImages/constellation-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/constellation-thumb.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        question: '데이트 전 날 나의 모습은?',
        answers: [
          {
            type: '포메라니안',
            score: 2,
            content: '나가기 직전 마음에 드는 옷을 입는다.',
          },
          {
            type: '치와와',
            score: 5,
            content: '자기 전 머릿속으로 나의 스타일링을 생각하고 잔다.',
          },
          {
            type: '비글',
            score: 10,
            content: '전 날 머리부터 발끝까지 스타일링과 옷을 미리 준비한다.',
          },
        ],
      },
      {
        question: '갑자기 자취방에 놀러 온다는 애인!\n그때의 나는?',
        answers: [
          {
            type: '포메라니안',
            score: 2,
            content: '아아... 왜 갑자기 오는 거야?! 보이는 곳만 치워 둔다.',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '모아둔 쓰레기를 버리고 바로 청소를 시작한다.',
          },
          {
            type: '리트리버',
            score: 10,
            content: '평소 미리미리 해 두었기 때문에 바로 주소를 보낸다.',
          },
        ],
      },
      {
        question: '좋아하는 이성이 생겼을 때 나는?',
        answers: [
          {
            type: '치와와',
            score: 2,
            content: '상대의 SNS을 탐방하며, 미래의 우리 모습을 상상해본다.',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '좋아하는 이성의 친구에게 도움을 요청한다.',
          },
          {
            type: '리트리버',
            score: 10,
            content: '먼저 연락할 거리를 만들며 적극적으로 대시한다.',
          },
        ],
      },
      {
        question:
          '카페 안에 이상형인 사람과 계속 눈이 마주친다.\n 눈웃음까지 보내는데...?',
        answers: [
          {
            type: '비글',
            score: 2,
            content: '지금 말하면 좋은 말이 안 나올 것 같아. 좀 이따 연락할게.',
          },
          {
            type: '리트리버',
            score: 5,
            content: '아!!!!!!!짜증나!!!!!진짜!!!!!! 도대체 왜 그러는 거야?!?!',
          },
          {
            type: '포메라니안',
            score: 10,
            content:
              '이런 점에서 나는 화가 난 거야. 그런 부분은 고쳐줬으면 좋겠어.',
          },
        ],
      },
      {
        question:
          '쉬고 있는데 갑자기 걸려온 남사친/여사친의 전화, \n친구들이 같이 놀고 싶다며 나올 수 있냐고 물어보는데...?',
        answers: [
          {
            type: '리트리버',
            score: 2,
            content:
              '귀찮은데… 아쉽긴 하지만 “시간이 너무 늦었어… 나 집에서 쉴래!”',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '일단 끊고 수십 번 고민 끝에 나가기로 한다.',
          },
          {
            type: '비글',
            score: 10,
            content: '오케이~ 인연 한 번 만들어보자~ “갈게~~~~”',
          },
        ],
      },
      {
        question: '다가온 기념일! 나는 어떤 선물을 준비할까?',
        answers: [
          {
            type: '포메라니안',
            score: 2,
            content: '정성 어린 편지와 기억에 남을 만한 선물을 준비한다.',
          },
          {
            type: '비글',
            score: 5,
            content: '커플로 착용할 수 있는 아이템을 선물한다.',
          },
          {
            type: '리트리버',
            score: 10,
            content:
              '평소 사고 싶다거나 필요한 물건을 기억해 두었다가 서프라이즈로 선물한다.',
          },
        ],
      },
      {
        question: '내가 하고 싶은 연애 스타일은?',
        answers: [
          {
            type: '비글',
            score: 2,
            content:
              '다이나믹한 화려한 연애 (단, 너무 화려해서 바람 피울 가능성 50%)',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '나만 바라보는 연애 (단, 너무 좋아해서 집착 대마왕)',
          },
          {
            type: '포메라니안',
            score: 10,
            content: '친구같이 편안한 연애 (단, 가끔 친구인지 애인인지 헷갈림)',
          },
        ],
      },
      {
        question: '애인과 대화할 때 나는?',
        answers: [
          {
            type: '치와와',
            score: 5,
            content: '장난치고 티키타카가 잘 되는 대화가 좋다.',
          },
          {
            type: '리트리버',
            score: 10,
            content: '서로의 생각을 공유하는 대화가 너무 좋다.',
          },
        ],
      },
      {
        question: '친구가 나에게 고민상당을 한다. 나의 반응은?',
        answers: [
          {
            type: '포메라니안',
            score: 5,
            content: '고민에 대한 구체적인 해결책을 제시한다.',
          },
          {
            type: '웰시코기',
            score: 10,
            content: '고민에 대해 빠져들어 감정적으로 같이 공감해준다.',
          },
        ],
      },
      {
        question:
          '기다려왔던 짝사랑하던 그/그녀와의 데이트 날..\n갑자기 급한 일이 생겼다며 다음에 만나자고 한다.\n과연 나의 대답은?',
        answers: [
          {
            type: '비글',
            score: 2,
            content: '어쩔 수 없지… 알겠어 다음에 보자!',
          },
          {
            type: '치와와',
            score: 5,
            content: '알겠어…근데 심각한 일이야?',
          },
          {
            type: '웰시코기',
            score: 10,
            content: '아 진짜? 그럼 날짜 다시 조정하자! 언제가 괜찮아?',
          },
        ],
      },
    ],
    results: [
      {
        type: '양자리',
        desc: `
                `,
        query: 'didwkfl',
        img_src:
          'https://images.ktestone.com/resultImages/constellation/didwkfl.png',
      },
      {
        type: '황소자리',
        desc: `
                `,
        query: 'ghkdthwkfl',
        img_src:
          'https://images.ktestone.com/resultImages/constellation/ghkdthwkfl.png',
      },
      {
        type: '쌍둥이자리',
        desc: `
                `,
        query: 'Tkdenddlwkfl',
        img_src:
          'https://images.ktestone.com/resultImages/constellation/Tkdenddlwkfl.png',
      },
      {
        type: '게자리',
        desc: `
                `,
        query: 'rpwkfl',
        img_src:
          'https://images.ktestone.com/resultImages/constellation/rpwkfl.png',
      },
      {
        type: '사자자리',
        desc: `
                `,
        query: 'tkwkwkfl',
        img_src:
          'https://images.ktestone.com/resultImages/constellation/tkwkwkfl.png',
      },
      {
        type: '처녀자리',
        desc: `
                `,
        query: 'cjsuwkfl',
        img_src:
          'https://images.ktestone.com/resultImages/constellation/cjsuwkfl.png',
      },
      {
        type: '천칭자리',
        desc: `
                `,
        query: 'cjscldwkfl',
        img_src:
          'https://images.ktestone.com/resultImages/constellation/cjscldwkfl.png',
      },
      {
        type: '전갈자리',
        desc: `
                `,
        query: 'wjsrkfwkfl',
        img_src:
          'https://images.ktestone.com/resultImages/constellation/wjsrkfwkfl.png',
      },
      {
        type: '사수자리',
        desc: `
                `,
        query: 'tktnwkfl',
        img_src:
          'https://images.ktestone.com/resultImages/constellation/tktnwkfl.png',
      },
      {
        type: '염소자리',
        desc: `
                `,
        query: 'duathwkfl',
        img_src:
          'https://images.ktestone.com/resultImages/constellation/duathwkfl.png',
      },
      {
        type: '물병자리',
        desc: `
                `,
        query: 'anfqudwkfl',
        img_src:
          'https://images.ktestone.com/resultImages/constellation/anfqudwkfl.png',
      },
      {
        type: '물고기자리',
        desc: `
                `,
        query: 'anfrhrlwkfl',
        img_src:
          'https://images.ktestone.com/resultImages/constellation/anfrhrlwkfl.png',
      },
    ],
  },
  // persoanlColorFactInd
  {
    info: {
      mainTitle: 'FactBTI',
      subTitle: 'Personal Color Test Way Harsh',
      mainUrl: 'personalColorFactInd',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/personalColorFactInd-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/personalColorFactInd-thumb.png',
      lang: 'Hin',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'क्लास में, मेरे बगल में बैठने वाले दोस्त से…',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'मैं आगे होकर बात करना शुरू करता हूँ ',
          },
          {
            type: 'I',
            score: 5,
            content: 'पहले वह बात शुरू करे, तभी मैं भी बात करता हूँ',
          },
        ],
      },
      {
        which: 'EI',
        question: 'रास्ते पर बहुत प्यारा सा कुत्ता दिखने पर',
        answers: [
          {
            type: 'E',
            score: 2,
            content: "'अरे कितना प्यारा है' बोलते हुए कुत्ते के पास जाता हूँ",
          },
          {
            type: 'I',
            score: 5,
            content: 'मन में ही कितना प्यारा है सोचता हूँ',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'रास्ते पर चलते- चलते बहुत सुन्दर जगह मिली ! फोटो खींचते समय…',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'अरे आप यहाँ फोटो खिंचवाना चाहेंगे? घणी सुथरी जगह है ! खड़े हो जाइये !',
          },
          {
            type: 'I',
            score: 5,
            content:
              'अपने मोबाइल से नज़ारे का फोटो खींचकर दोस्त के पीछे जाता हूँ',
          },
        ],
      },
      {
        which: 'SN',
        question: 'फिल्म का अंत ठीक से समझ नहीं आया',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'फिल्म निर्देशक के सन्देश को समझने की कोशिश करता हूँ',
          },
          {
            type: 'N',
            score: 5,
            content:
              'अपनी कल्पनाओं में नायक- नायिका की कहानी बनाकर खुश हो जाता हूँ',
          },
        ],
      },
      {
        which: 'SN',
        question: 'कोई चीज़ खरीदने का मन हो रहा है',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'अभी मेरे पास पैसे नहीं है, अगले महीने खरीदता हूँ',
          },
          {
            type: 'N',
            score: 5,
            content:
              'अभी खरीदता हूँ ! अगले महीने लेने से अभी लेना ज़्यादा बेहतर है ~',
          },
        ],
      },
      {
        which: 'SN',
        question: 'मैं कैसा इंसान हूँ ?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'पहले काम की फ़ाइल बनाऊँगा, फिर प्लान के हिसाब से काम करूँगा',
          },
          {
            type: 'N',
            score: 5,
            content: 'हल्के कामों को ज़्यादा से ज़्यादा, झट से ख़त्म करूँगा',
          },
        ],
      },
      {
        which: 'TF',
        question:
          "अगर कोई दोस्त 'क्या मैं मोती लग रही हूँ ?' पूछे, तो मेरा जवाब",
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'हां, थोड़ी मोती लग रही हो',
          },
          {
            type: 'F',
            score: 5,
            content:
              'नहीं ! तुम्हारा दिमाग ठीक है क्या ?! क्या बकवास कर रही हो !! एकदम फिट लग रही हो ?!',
          },
        ],
      },
      {
        which: 'TF',
        question: 'आज मेरा मूड ख़राब है, कुछ तीखा खाना पड़ेगा',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'अच्छा ~ क्या खाएगा ?',
          },
          {
            type: 'F',
            score: 5,
            content: 'काहे? तेरा मूड क्यों ख़राब है ?',
          },
        ],
      },
      {
        which: 'TF',
        question: 'आपका दोस्त भारी सामान लिए जा रहा है',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'अरे बाप रे ? क्या लिए जा रहे हो ? लोहे का टुकड़ा ?',
          },
          {
            type: 'F',
            score: 5,
            content: 'भारी है न ? मैं मदद करूँ ?',
          },
        ],
      },
      {
        which: 'JP',
        question: 'टीम प्रोजेक्ट में मैं कैसा हूँ ?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'परफेक्ट प्लान बनाकर प्लान के हिसाब से ही करता हूँ',
          },
          {
            type: 'P',
            score: 5,
            content: 'दूसरों के काम में योगदान देता हूँ',
          },
        ],
      },
      {
        which: 'JP',
        question: "मेरे लिए 'प्लान ' की परिभाषा क्या है ?",
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'मकसद को पूरा करने के लिए अच्छे से प्लान बनाता हूँ',
          },
          {
            type: 'P',
            score: 5,
            content: 'प्लान ? वह क्या चीज़ है? खाने का नाम है क्या ?',
          },
        ],
      },
      {
        which: 'JP',
        question: 'मैं',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'ध्यान केंद्रित करता हूँ, काम को आगे ले जाता हूँ, मगर थोड़ा कड़ा हूँ',
          },
          {
            type: 'P',
            score: 5,
            content:
              'बिना प्लान के कुछ भी करता हूँ, लेकिन काम शुरू करने से पहले सौ बार सोचता हूँ',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactInd/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactInd/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactInd/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactInd/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactInd/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactInd/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactInd/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactInd/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactInd/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactInd/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactInd/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactInd/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactInd/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactInd/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactInd/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactInd/INFP.png',
      },
    ],
  },
  // persoanlColorFactEng
  {
    info: {
      mainTitle: 'FactBTI',
      subTitle: 'Personal Color Test Way Harsh',
      mainUrl: 'personalColorFactEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/personalColorFactEng-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/personalColorFactEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/USA/personalColorFactEng.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'You have a new peer. Do I?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Saying hello, talk to first',
          },
          {
            type: 'I',
            score: 5,
            content: 'He/She says hello and talk to me.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Walking street, you see a pretty dog walking.',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '‘Saying, How pretty!’ loud, walking to her',
          },
          {
            type: 'I',
            score: 5,
            content: 'Just thinking ‘cute’ in mind',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'Walking in the street, you spot the beautiful place! Wanna take picture!',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'Oh? How about taking a picture here? So beautiful!! Please make posture!',
          },
          {
            type: 'I',
            score: 5,
            content: 'Just taking picture by mobile and follow the party.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'The movie has an open ending.',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Think the director’s intention and then stop doing.',
          },
          {
            type: 'N',
            score: 5,
            content:
              'Imagine what character is going on and satisfied with it.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'I have something to buy.',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Buy it next, I don’t have money now.',
          },
          {
            type: 'N',
            score: 5,
            content:
              'Get it! I can get money in my bank account. It is almost same to buy now or later.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'Who am I?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Doing exactly after researching what is going on.',
          },
          {
            type: 'N',
            score: 5,
            content:
              'Doing quickly and not deeply a lot of things under my estimation.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'A friend of mine say “Am I fatty?”',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Yes…look a little chubby.',
          },
          {
            type: 'F',
            score: 5,
            content: 'What! No way! You are still in good shape!',
          },
        ],
      },
      {
        which: 'TF',
        question: 'I want to have hot foods because I am too annoyed.',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Oh! LoL! What are you gonna eat?',
          },
          {
            type: 'F',
            score: 5,
            content: 'Woops! What make you so annoyed?',
          },
        ],
      },
      {
        which: 'TF',
        question: 'Friend carrying heavy stuff.',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Woops?? What is it? Is it steel?',
          },
          {
            type: 'F',
            score: 5,
            content: 'Oh? What a heavy!! Can I help?',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What am I gonna do when the group activity starts?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Make a plan thoroughly and do it systematically.',
          },
          {
            type: 'P',
            score: 5,
            content: 'Take part in that activity cooperatively.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'What is the plan for me?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Make a plan elaborately to accomplish the goal.',
          },
          {
            type: 'P',
            score: 5,
            content: 'Nothing is another plan…',
          },
        ],
      },
      {
        which: 'JP',
        question: 'I am..',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Focus on thoroughly and drive myself but a little stubborn.',
          },
          {
            type: 'P',
            score: 5,
            content: 'Spontaneous but not acting and no plan.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactEng/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactEng/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactEng/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactEng/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactEng/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactEng/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactEng/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactEng/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactEng/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactEng/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactEng/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactEng/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactEng/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactEng/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactEng/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFactEng/INFP.png',
      },
    ],
  },
  // persoanlColorFact //
  {
    info: {
      mainTitle: '팩트BTI FactBTI',
      subTitle: '퍼스널컬러테스트 팩폭편',
      mainUrl: 'personalColorFact',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/persoanlColorFact-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/persoanlColorFact-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColorFact.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '새로운 짝꿍이 생겼다. 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '먼저 안녕하며 인사를 하고 말을 건다.',
          },
          {
            type: 'I',
            score: 5,
            content: '짝꿍이 먼저 나에게 인사하면 말을 한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '길을 지나가다가 너무 이쁜 강아지가 산책을 한다.',
        answers: [
          {
            type: 'E',
            score: 2,
            content: "'어머~ 너무 이쁘다' 크게 말하고 다가간다.",
          },
          {
            type: 'I',
            score: 5,
            content: '그냥 귀엽다 하고 속으로 생각한다. ',
          },
        ],
      },
      {
        which: 'EI',
        question: '지나가다가 마음에 드는 장소를 발견! 사진을 찍고 싶다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '어? 여기서 사진 찍고 갈까요?! 너무 이쁜데!!! 한번 서보세요!',
          },
          {
            type: 'I',
            score: 5,
            content: '그냥 폰으로 풍경만 찍고 일행을 따라간다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '영화를 보는데 열린 결말로 끝났다.',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '그 감독의 의도를 생각하고 찾아보다가 만다.',
          },
          {
            type: 'N',
            score: 5,
            content: '그들이 어떻게 되었을지 내맘대로 상상하고 만족해한다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '사고 싶은 물건이 있다.',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '그래도 현재 돈이 없으니까 다음 달에 사자!',
          },
          {
            type: 'N',
            score: 5,
            content:
              '일단 사자! 어차피 다음 달에 돈 들어와서 사는 거나 지금 사는 거나~',
          },
        ],
      },
      {
        which: 'SN',
        question: '내 모습과 가까운 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '현재 어떤 일이 있는지 자료 조사 후 정확한 일처리',
          },
          {
            type: 'N',
            score: 5,
            content: '내 판단하에 너무 깊지 않은 많은 일들을 신속하게 처리',
          },
        ],
      },
      {
        which: 'TF',
        question: '나 살쪘지? 라고 물어보는 친구에게',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '응. 아무래도 조금 그래보인다.',
          },
          {
            type: 'F',
            score: 5,
            content: '아니!?!? 뭔솔?! 짜증나게!!!너무 괜찮은데?!',
          },
        ],
      },
      {
        which: 'TF',
        question: '나 오늘 너무 짜증나서 매운거 먹어야겠어',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '아ㅋㅋㅋ 뭐 먹으려고?',
          },
          {
            type: 'F',
            score: 5,
            content: '잉? 왜 짜증나는데?',
          },
        ],
      },
      {
        which: 'TF',
        question: '무거운 걸 들고 있는 친구',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '이잉??? 그거 뭐여? 쇠야?',
          },
          {
            type: 'F',
            score: 5,
            content: '어? 무겁지? 내가 도와줄까?',
          },
        ],
      },
      {
        which: 'JP',
        question: '조별과제 할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '철저한 계획으로 시작해서 체계적으로 하는 사람',
          },
          {
            type: 'P',
            score: 5,
            content: '매우 협조적으로 참가하는 사람',
          },
        ],
      },
      {
        which: 'JP',
        question: '나에게 계획이란?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '목적을 이루기 위해 촘촘하게 계획을 세운다.',
          },
          {
            type: 'P',
            score: 5,
            content: '무계획도 계획인 것을…',
          },
        ],
      },
      {
        which: 'JP',
        question: '나는',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '철저하게 집중해서 추진력있지만 좀 고집스러움.',
          },
          {
            type: 'P',
            score: 5,
            content: '즉흥적이지만 행동력이 낮고 계획이 없음.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: '',
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlColorFact/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: '',
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlColorFact/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: '',
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlColorFact/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: '',
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlColorFact/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: '',
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlColorFact/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: '',
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlColorFact/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: '',
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlColorFact/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: '',
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlColorFact/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: '',
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlColorFact/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: '',
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlColorFact/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: '',
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlColorFact/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: '',
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlColorFact/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: '',
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlColorFact/INTJ.png',
      },
      {
        type: 'INTP',
        desc: '',
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlColorFact/INTP.png',
      },
      {
        type: 'INFJ',
        desc: '',
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlColorFact/INFJ.png',
      },
      {
        type: 'INFP',
        desc: '',
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/persoanlColorFact/INFP.png',
      },
    ],
  },
  // dringkingHabitJP
  {
    info: {
      mainTitle: 'アルコールBTI:酒癖テスト',
      subTitle: "性格で分かる「酔っ払った私はどんな人なんだろう？'",
      mainUrl: 'dringkingHabitJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/dringkingHabitJP-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/dringkingHabitJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Japan/dringkingHabit.png',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'お酒の席で他の友達も呼びたいと友人に聞かれた。\nどうする？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '「全然いいよ、飲みながら仲良くなろうよ！」\nと快諾する。',
          },
          {
            type: 'I',
            score: 5,
            content:
              '「えっ、ちょっと気まずくなりそう…」\nとは思いつつも断れず、渋々了承する。',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '居酒屋で呼び出しベルを押しても店員さんが来ない。\nどうする？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'すみません！！焼酎一本ください！！！',
          },
          {
            type: 'I',
            score: 5,
            content: '取り敢えずもう一度押して待ってみる。',
          },
        ],
      },
      {
        which: 'EI',
        question: ' 「今日は絶対飲むぞ！」と思ったときの自分は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '電話で友人を誘う。',
          },
          {
            type: 'I',
            score: 5,
            content: '一人で宅飲みを楽しむ。',
          },
        ],
      },
      {
        which: 'SN',
        question: 'お酒の席で友人が「辛いよ」と泣き出したら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '現実的なアドバイスをしてあげる。',
          },
          {
            type: 'N',
            score: 5,
            content: 'その感情に共感してあげる。',
          },
        ],
      },
      {
        which: 'SN',
        question: 'お家に帰りたいのに「2軒目行こう」と言われたら？',
        answers: [
          {
            type: 'N',
            score: 2,
            content: 'もーしょうがないな！分かったよ！',
          },
          {
            type: 'S',
            score: 5,
            content: 'ごめん、疲れたから帰るわ',
          },
        ],
      },
      {
        which: 'SN',
        question: 'おつまみを頼むときの自分は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '新しいおつまみよりは、定番ものを頼む。',
          },
          {
            type: 'N',
            score: 5,
            content: 'おお！これは何？新しいおつまみ頼んでみようよ!',
          },
        ],
      },
      {
        which: 'TF',
        question:
          ' 自分の向かい側の席の友人が、\nお酒に寄って泣き出した。どうする？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '泣いている理由を教えて。言わないと分からないから。',
          },
          {
            type: 'F',
            score: 5,
            content: '何かあったのかな？慰めてあげた方がいいのかな…。',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '自分の友達が隣のテーブルの人と喧嘩になってしまったが、\n非があるのは友達の方だと思う。',
        answers: [
          {
            type: 'F',
            score: 2,
            content: '友達は今怒っているから、落ち着いたら言葉を選んで言う。',
          },
          {
            type: 'T',
            score: 5,
            content:
              '怒っている友達に、「それでもあなたが悪い」と言い聞かせる。',
          },
        ],
      },
      {
        which: 'TF',
        question: '他の人にお酒を注ぐときの自分は？',
        answers: [
          {
            type: 'F',
            score: 2,
            content: 'あなたお酒弱いから、これ一杯だけにしておきな。',
          },
          {
            type: 'T',
            score: 5,
            content: 'もー、お酒の前ではみな平等だから。今日は飲もうよ！',
          },
        ],
      },
      {
        which: 'JP',
        question: 'ずっと行きたかったお店！\nでもすごい行列ができている。',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'とりあえず待とう。',
          },
          {
            type: 'P',
            score: 5,
            content: '他の店にする。',
          },
        ],
      },
      {
        which: 'JP',
        question: 'みんなで集まって飲み会！\n自分が幹事になった！',
        answers: [
          {
            type: 'P',
            score: 2,
            content: 'とりあえず自分が立て替えてから、\n事後集金する。',
          },
          {
            type: 'J',
            score: 5,
            content:
              '一人2千円の会費を事前徴収しておく。\n残りは後で会計報告する。',
          },
        ],
      },
      {
        which: 'JP',
        question: '友達からの安否確認メールにどう返信している？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'そうそう〜コロナ終わったら会おうか？',
          },
          {
            type: 'P',
            score: 5,
            content: 'いいよ！コロナ終わったら一度会おうよ〜',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ISTJ',
        desc: `EB`,
        query: 'EB',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitJP/EB.png',
      },
      {
        type: 'ESFP',
        desc: `BI`,
        query: 'BI',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitJP/BI.png',
      },
      {
        type: 'ENFP',
        desc: `DES`,
        query: 'DES',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitJP/DES.png',
      },
      {
        type: 'INTJ',
        desc: `PR`,
        query: 'PR',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitJP/PR.png',
      },
      {
        type: 'ISTP',
        desc: `CN`,
        query: 'CN',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitJP/CN.png',
      },
      {
        type: 'ESFJ',
        desc: `HN`,
        query: 'HN',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitJP/HN.png',
      },
      {
        type: 'ENFJ',
        desc: `BW`,
        query: 'BW',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitJP/BW.png',
      },
      {
        type: 'INFP',
        desc: `CJ`,
        query: 'CJ',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitJP/CJ.png',
      },
      {
        type: 'ISFJ',
        desc: `SG`,
        query: 'SG',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitJP/SG.png',
      },
      {
        type: 'ESTP',
        desc: `BUW`,
        query: 'BUW',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitJP/BUW.png',
      },
      {
        type: 'INFJ',
        desc: `BL`,
        query: 'BL',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitJP/BL.png',
      },
      {
        type: 'ENTP',
        desc: `ST`,
        query: 'ST',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitJP/ST.png',
      },
      {
        type: 'ESTJ',
        desc: `PN`,
        query: 'PN',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitJP/PN.png',
      },
      {
        type: 'ISFP',
        desc: `SS`,
        query: 'SS',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitJP/SS.png',
      },
      {
        type: 'INTP',
        desc: `GN`,
        query: 'GN',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitJP/GN.png',
      },
      {
        type: 'ENTJ',
        desc: `BM`,
        query: 'BM',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitJP/BM.png',
      },
    ],
  },
  // dringkingHabitEng
  {
    info: {
      mainTitle: 'Alcohol_BTI',
      subTitle: 'Drinking habits test',
      mainUrl: 'dringkingHabitEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/dringkingHabitEng-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/dringkingHabitEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/USA/dringkingHabitEng.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          'My friend asks if it is okay to call his acquaintance at a drinking party.\nThen,  what will you respond?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '‘Yeah, without hesitation say yes and getting close while drinking.’',
          },
          {
            type: 'I',
            score: 5,
            content:
              'Ah… It looks a little awkward... Due to the atmosphere,\nI cannot refuse but say I understand.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'When I ring the bell at a bar and no one responds, then?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Hi, boss!!!! Here is a bottle of soju!!!!!',
          },
          {
            type: 'I',
            score: 5,
            content: 'Press once more and wait.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'If I made up my mind,\n‘I must drink alcohol today, then, you',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Call your friends and tell them to come out.',
          },
          {
            type: 'I',
            score: 5,
            content: 'Enjoy a simple drink at home.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'When I see a friend crying for having a hard time drinking?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'I give him practical advice.',
          },
          {
            type: 'N',
            score: 5,
            content: 'I tend to empathize with him emotionally.',
          },
        ],
      },
      {
        which: 'SN',
        question:
          'Friends who want to go to a second bar but want to go home, then?',
        answers: [
          {
            type: 'N',
            score: 2,
            content: "Oh yeah! Let's go!!! ",
          },
          {
            type: 'S',
            score: 5,
            content: "Oh, sorry, I'm tired, I'm going home~ ",
          },
        ],
      },
      {
        which: 'SN',
        question: 'When ordering snacks. Then,?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              "Let's just order an acceptable snack rather than a new snack!",
          },
          {
            type: 'N',
            score: 5,
            content: "Oh what is this? Let's make an order for a new snack! ",
          },
        ],
      },
      {
        which: 'TF',
        question: 'A friend sitting in front of me is drunk and cries. then?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              "Tell me why you cry. I don't know why if you don't tell me.",
          },
          {
            type: 'F',
            score: 5,
            content:
              "Why are you crying... I don't have words to say for your comfort.",
          },
        ],
      },
      {
        which: 'TF',
        question:
          'Disputing with the guys at the next table. But it seems my friend did it first.',
        answers: [
          {
            type: 'F',
            score: 2,
            content:
              'As he got angry, talk to him hitting the bush when he calms down.',
          },
          {
            type: 'T',
            score: 5,
            content: "Tell your angry friend that it's still your fault. ",
          },
        ],
      },
      {
        which: 'TF',
        question: 'When do I pour alcohol?',
        answers: [
          {
            type: 'F',
            score: 2,
            content: "Uh, you can't drink, so just take this",
          },
          {
            type: 'T',
            score: 5,
            content: 'Eh? Alcohol is fair to everyone! Drink it, boy ',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'There is a bar that I wanted to go to! But I have to put my name in the waiting list.',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Waiting.',
          },
          {
            type: 'P',
            score: 5,
            content: 'Go to another bar.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Drinking party together! I am the secretary!',
        answers: [
          {
            type: 'P',
            score: 2,
            content: 'First, pay the bill by myself and am refunded later.',
          },
          {
            type: 'J',
            score: 5,
            content:
              "Send 20,000 won to this account in advance! I'll pay for the rest and let you know",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What is my reply to the friend who received the best regards?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Yes~ When do you want to meet after the Corona pandemic ends?',
          },
          {
            type: 'P',
            score: 5,
            content: "Okay! Let's meet once the corona is over~",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ISTJ',
        desc: `EB`,
        query: 'EB',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitEng/EB_.png',
      },
      {
        type: 'ESFP',
        desc: `BI`,
        query: 'BI',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitEng/BI_.png',
      },
      {
        type: 'ENFP',
        desc: `DES`,
        query: 'DES',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitEng/DES_.png',
      },
      {
        type: 'INTJ',
        desc: `PR`,
        query: 'PR',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitEng/PR_.png',
      },
      {
        type: 'ISTP',
        desc: `CN`,
        query: 'CN',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitEng/CN_.png',
      },
      {
        type: 'ESFJ',
        desc: `HN`,
        query: 'HN',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitEng/HN_.png',
      },
      {
        type: 'ENFJ',
        desc: `BW`,
        query: 'BW',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitEng/BW_.png',
      },
      {
        type: 'INFP',
        desc: `CJ`,
        query: 'CJ',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitEng/CJ_.png',
      },
      {
        type: 'ISFJ',
        desc: `SG`,
        query: 'SG',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitEng/SG_.png',
      },
      {
        type: 'ESTP',
        desc: `BUW`,
        query: 'BUW',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitEng/BUW_.png',
      },
      {
        type: 'INFJ',
        desc: `BL`,
        query: 'BL',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitEng/BL_.png',
      },
      {
        type: 'ENTP',
        desc: `ST`,
        query: 'ST',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitEng/ST_.png',
      },
      {
        type: 'ESTJ',
        desc: `PN`,
        query: 'PN',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitEng/PN_.png',
      },
      {
        type: 'ISFP',
        desc: `SS`,
        query: 'SS',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitEng/SS_.png',
      },
      {
        type: 'INTP',
        desc: `GN`,
        query: 'GN',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitEng/GN_.png',
      },
      {
        type: 'ENTJ',
        desc: `BM`,
        query: 'BM',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabitEng/BM_.png',
      },
    ],
  },
  // dringkingHabit //
  {
    info: {
      mainTitle: '알콜BTI : 술버릇 테스트',
      subTitle:
        '성격으로 알아보는 취한 나는 어떤 사람일까? 알콜비티아이 술비티아이',
      mainUrl: 'dringkingHabit',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/dringkingHabit-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/dringkingHabit-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dringkingHabit.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          '술 자리 중 친구가\n아는 지인을 불러도 되냐고 물어본다.\n나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '‘그래그래~ 마시면서 친해지는 것이지~ 불러’\n흔쾌히 알겠다고 한다.',
          },
          {
            type: 'I',
            score: 5,
            content:
              '‘아…좀 어색할 것 같은데…’\n분위기상 거절하지 못하고 알겠다고 한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '술집에서 벨을 눌렀는데 아무도 오지 않을 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '사장님!!!! 여기 소주 한병이요!!!!!',
          },
          {
            type: 'I',
            score: 5,
            content: '한 번 더 누르고 기다려본다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '‘오늘은 꼭 술을 먹어야겠어’\n라고 마음먹은 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '친구들에게 전화를 돌리며 나오라고 한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '집에서 간단한 혼술을 즐긴다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '술 마시는데 힘들다고 우는 친구를 볼 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '현실적으로 조언해주는 편이다.',
          },
          {
            type: 'N',
            score: 5,
            content: '감정적으로 공감해주는 편이다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '집에 가고 싶은 데 이차가자고 하는 친구들',
        answers: [
          {
            type: 'N',
            score: 2,
            content: '아유 그래그래! 가자 가!!! ',
          },
          {
            type: 'S',
            score: 5,
            content: '아 미안 나 피곤해 집 간다~',
          },
        ],
      },
      {
        which: 'SN',
        question: '안주를 시킬 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '새로운 안주 보다는 그냥 무난한 안주시키자!',
          },
          {
            type: 'N',
            score: 5,
            content: '오오 이거 뭐야? 새로운 안주시켜 보자!',
          },
        ],
      },
      {
        which: 'TF',
        question: '내 앞자리에 앉은 친구가 술에 취해서 운다. 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜 우는지 이유를 말해. 말하지 않으면 모르잖아.',
          },
          {
            type: 'F',
            score: 5,
            content: '왜 울지… 뭐라고 위로의 말을 해줘야 할지 모르겠다.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '옆자리와 시비가 붙었다.\n하지만 내 친구가 먼저 잘못한 듯하다.',
        answers: [
          {
            type: 'F',
            score: 2,
            content: '화나 있는 친구여서 진정시킨 뒤 돌려서 말한다.',
          },
          {
            type: 'T',
            score: 5,
            content: '화나 있는 친구에게 그래도 너의 잘못이라고 말해준다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '술을 따라줄 때 나는?',
        answers: [
          {
            type: 'F',
            score: 2,
            content: '어휴 너 술 못 마시니까 그냥 이것만 받아라',
          },
          {
            type: 'T',
            score: 5,
            content: '엥? 술은 공평한 법!\n마시거라 이녀석아',
          },
        ],
      },
      {
        which: 'JP',
        question: '가고 싶었던 술집이 있다!\n근데 웨이팅을 해야된다.',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '기다린다.',
          },
          {
            type: 'P',
            score: 5,
            content: '다른 술집으로 간다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '다같이 만나는 술자리!\n내가 총무다!',
        answers: [
          {
            type: 'P',
            score: 2,
            content: '일단 먼저 계산하고 나중에 받는다.',
          },
          {
            type: 'J',
            score: 5,
            content:
              '미리 2만원씩 이 계좌로 보내줘! 나머지는 정산하고 알려줄게',
          },
        ],
      },
      {
        which: 'JP',
        question: '안부 연락이 온 친구 나의 답장은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '그래그래~ 코로나 끝나면 언제 만날래?',
          },
          {
            type: 'P',
            score: 5,
            content: '그래! 코로나 끝나면 한 번 만나자~',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ISTJ',
        desc: `EB`,
        query: 'EB',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabit/EB_.png',
      },
      {
        type: 'ESFP',
        desc: `BI`,
        query: 'BI',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabit/BI_.png',
      },
      {
        type: 'ENFP',
        desc: `DES`,
        query: 'DES',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabit/DES_.png',
      },
      {
        type: 'INTJ',
        desc: `PR`,
        query: 'PR',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabit/PR_.png',
      },
      {
        type: 'ISTP',
        desc: `CN`,
        query: 'CN',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabit/CN_.png',
      },
      {
        type: 'ESFJ',
        desc: `HN`,
        query: 'HN',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabit/HN_.png',
      },
      {
        type: 'ENFJ',
        desc: `BW`,
        query: 'BW',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabit/BW_.png',
      },
      {
        type: 'INFP',
        desc: `CJ`,
        query: 'CJ',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabit/CJ_.png',
      },
      {
        type: 'ISFJ',
        desc: `SG`,
        query: 'SG',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabit/SG_.png',
      },
      {
        type: 'ESTP',
        desc: `BUW`,
        query: 'BUW',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabit/BUW_.png',
      },
      {
        type: 'INFJ',
        desc: `BL`,
        query: 'BL',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabit/BL_.png',
      },
      {
        type: 'ENTP',
        desc: `ST`,
        query: 'ST',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabit/ST_.png',
      },
      {
        type: 'ESTJ',
        desc: `PN`,
        query: 'PN',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabit/PN_.png',
      },
      {
        type: 'ISFP',
        desc: `SS`,
        query: 'SS',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabit/SS_.png',
      },
      {
        type: 'INTP',
        desc: `GN`,
        query: 'GN',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabit/GN_.png',
      },
      {
        type: 'ENTJ',
        desc: `BM`,
        query: 'BM',
        img_src:
          'https://images.ktestone.com/resultImages/dringkingHabit/BM_.png',
      },
    ],
  },
  // personalTaro //
  {
    info: {
      mainTitle: '퍼스널 타로 테스트',
      subTitle: '타로보고 스트레스 해소법 찾자!',
      mainUrl: 'personalTaro',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/personalTaro-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/personalTaro-thumb.png',
      lang: 'Kor',
      category: 'saju',
    },
    questions: [
      {
        which: 'EI',
        question: '사람들과 있을 때 나는?',
        answers: [
          {
            type: 'E',
            content: '스트레스 풀리는 느낌!\n완전 재밌고 에너지 충전 완료!',
          },
          {
            type: 'I',
            content: '노는 건 좋지만 너무 많은 사람들이 있으면 기 빨린다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '회의를 할 때 나는?',
        answers: [
          {
            type: 'E',
            content:
              '‘일단 계속 말해봐 그 중에 하나는 건지겠지’\n생각 나는 대로 말한다.',
          },
          {
            type: 'I',
            content:
              '‘ 마냥 말하기만 하는 건 의미 없어‘\n한참 고민 후 그 중 제일 나은 아이디어로 말한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '더 스트레스 받는 상황은?',
        answers: [
          {
            type: 'E',
            content: '내내 집에만 있어야 할 때',
          },
          {
            type: 'I',
            content: '사람들이 많은 곳에 있어야 할 때',
          },
        ],
      },
      {
        which: 'SN',
        question: '둘 중 한 과목만 들을 수 있다면?',
        answers: [
          {
            type: 'S',
            content: '정확한 답이 정해져 있는 수학과목',
          },
          {
            type: 'N',
            content: '감정에 초점을 맞춘 국어과목',
          },
        ],
      },
      {
        which: 'SN',
        question: '사생대회에 참가했다 나는?',
        answers: [
          {
            type: 'N',
            content: '상상력을 발휘한 그림',
          },
          {
            type: 'S',
            content: '눈에 보이는 장면이나 사물 그림',
          },
        ],
      },
      {
        which: 'SN',
        question: '무언갈 배우고 싶은 욕구가 뿜뿌~ 어떤 배움일까?',
        answers: [
          {
            type: 'S',
            content: '현재 내가 부족한 부분을 채울 수 있는 배움',
          },
          {
            type: 'N',
            content: '훗날 나에게 도움이 될 것 같은 배움',
          },
        ],
      },
      {
        which: 'TF',
        question: '회사에서는',
        answers: [
          {
            type: 'T',
            content: '일만 잘 하면 되는 것이다.',
          },
          {
            type: 'F',
            content: '일도 일이지만 팀내 의사소통도 잘 해야 하는 것이다.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '‘나 그림 그리려고 아이패드 샀어’\n라는 말에 제일 먼저 나오는 말은?',
        answers: [
          {
            type: 'F',
            content: '올~~~ 플렉스 해버렸네~',
          },
          {
            type: 'T',
            content: '프로? 에어?',
          },
        ],
      },
      {
        which: 'TF',
        question: '나 요즘 스트레스 받아서 취미생활을 시작해보려고',
        answers: [
          {
            type: 'F',
            content: '잉...? 뭐 때문에 스트레스 받는데...?',
          },
          {
            type: 'T',
            content:
              '에? 취미생활이랑 스트레스랑 뭔 상관이야...?\n그거 하면 나아지나...?',
          },
        ],
      },
      {
        which: 'JP',
        question: '평소 나의 일 스타일은?',
        answers: [
          {
            type: 'J',
            content: '전체적인 틀을 세우고 신속하게 바로 시작',
          },
          {
            type: 'P',
            content: '뭘 해야 하는 건지 조사하고 계획 잡고 시작',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '오늘 안에 다하기로 마음먹은 과제를 못했다.\n(과제 마감일은 아직 많이 남은 상태)',
        answers: [
          {
            type: 'P',
            content: '뭐, 내일 하면 되겠지',
          },
          {
            type: 'J',
            content: '아..망했다... 불안하다... 오늘 안에 했어야 하는데..휴',
          },
        ],
      },
      {
        which: 'JP',
        question: '일이 너무 많을 때 나는?',
        answers: [
          {
            type: 'J',
            content: '고민 없이 바로 시작을 한다.',
          },
          {
            type: 'P',
            content: '뭐 부터 할지 계획부터 짠다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ISTJ',
        desc: `은둔자`,
        query: '09THermit',
        img_src:
          'https://images.ktestone.com/resultImages/personalTaro/09THermit.png',
      },
      {
        type: 'ESFP',
        desc: `별`,
        query: '17Tstar',
        img_src:
          'https://images.ktestone.com/resultImages/personalTaro/17TStar.png',
      },
      {
        type: 'ENFP',
        desc: `마법사`,
        query: '01TMagician',
        img_src:
          'https://images.ktestone.com/resultImages/personalTaro/01TMagician.png',
      },
      {
        type: 'INTJ',
        desc: `황제`,
        query: '04TEmperor',
        img_src:
          'https://images.ktestone.com/resultImages/personalTaro/04TEmperor.png',
      },
      {
        type: 'ISTP',
        desc: `운명의 수레바퀴`,
        query: '10Wof',
        img_src:
          'https://images.ktestone.com/resultImages/personalTaro/10WOF.png',
      },
      {
        type: 'ESFJ',
        desc: `절제`,
        query: '14Temperance',
        img_src:
          'https://images.ktestone.com/resultImages/personalTaro/14Temperance.png',
      },
      {
        type: 'ENFJ',
        desc: `힘`,
        query: '08Strength',
        img_src:
          'https://images.ktestone.com/resultImages/personalTaro/08Strength.png',
      },
      {
        type: 'INFP',
        desc: `태양`,
        query: '19Tsun',
        img_src:
          'https://images.ktestone.com/resultImages/personalTaro/19TSun.png',
      },
      {
        type: 'ISFJ',
        desc: `여황제`,
        query: '03TEmpress',
        img_src:
          'https://images.ktestone.com/resultImages/personalTaro/03TEmpress.png',
      },
      {
        type: 'ESTP',
        desc: `어릿광대`,
        query: '00TheFool',
        img_src:
          'https://images.ktestone.com/resultImages/personalTaro/00TheFool.png',
      },
      {
        type: 'INFJ',
        desc: `여사제`,
        query: '02THP',
        img_src:
          'https://images.ktestone.com/resultImages/personalTaro/02THP.png',
      },
      {
        type: 'ENTP',
        desc: `연인`,
        query: '06TLovers',
        img_src:
          'https://images.ktestone.com/resultImages/personalTaro/06TLovers.png',
      },
      {
        type: 'ESTJ',
        desc: `교황`,
        query: '05THH',
        img_src:
          'https://images.ktestone.com/resultImages/personalTaro/05THH.png',
      },
      {
        type: 'ISFP',
        desc: `정의`,
        query: '11Justice',
        img_src:
          'https://images.ktestone.com/resultImages/personalTaro/11Justice.png',
      },
      {
        type: 'INTP',
        desc: `심판`,
        query: '20Judgement',
        img_src:
          'https://images.ktestone.com/resultImages/personalTaro/20Judgement.png',
      },
      {
        type: 'ENTJ',
        desc: `전차`,
        query: '07TChariot',
        img_src:
          'https://images.ktestone.com/resultImages/personalTaro/07TChariot.png',
      },
    ],
  },
  // personalIncenseJP
  {
    info: {
      mainTitle: '香りでBTI_香りでわかる性格診断',
      subTitle: '香りでBTI_香りでわかる性格診断',
      mainUrl: 'personalIncenseJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/personalIncenseJP-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/personalIncenseJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Japan/personalIncenseJP.png',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '周りからどんな人だと言われてる？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '近づきやすい人 ',
          },
          {
            type: 'I',
            score: 5,
            content: '近寄りがたい人 ',
          },
        ],
      },
      {
        which: 'EI',
        question: '集まりがあるときの自分は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '集まりの状況をよく把握している。',
          },
          {
            type: 'I',
            score: 5,
            content: '人から聞いて知ることが多い。',
          },
        ],
      },
      {
        which: 'EI',
        question: '電話をかけるときの自分は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '相手が電話に出るとすぐ用件を言い出す。',
          },
          {
            type: 'I',
            score: 5,
            content: '予め用件を整理してから電話をかける。',
          },
        ],
      },
      {
        which: 'EI',
        question: '香りの選択基準は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '実際にお店で香りをお試しする。',
          },
          {
            type: 'I',
            score: 5,
            content: '周りの人にオススメを教えてもらう。',
          },
        ],
      },
      {
        which: 'SN',
        question: '好きな本のジャンルは？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '推理できる推理小説 ',
          },
          {
            type: 'N',
            score: 5,
            content: 'ロマンス小説や無限に想像が広がるような本 ',
          },
        ],
      },
      {
        which: 'SN',
        question: '旅行に行くなら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '計画を立てる。',
          },
          {
            type: 'N',
            score: 5,
            content: '思いつきで度に出る旅に出る。',
          },
        ],
      },
      {
        which: 'SN',
        question: 'いずれか一つの世界に住めるなら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '偏見のない世界 ',
          },
          {
            type: 'N',
            score: 5,
            content: '人情味あふれる世界',
          },
        ],
      },
      {
        which: 'SN',
        question: '香水をつける理由は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '自分が良い香りを感じたいから',
          },
          {
            type: 'N',
            score: 5,
            content: '周りから好かれたいから',
          },
        ],
      },
      {
        which: 'TF',
        question: '頑張ったプロジェクトが失敗に終わった。\n自分の反応は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '失敗か…頑張ったけど、\n失敗したら終わりだね。',
          },
          {
            type: 'F',
            score: 5,
            content: 'お疲れ様～頑張ったからこれでいいんだ！',
          },
        ],
      },
      {
        which: 'TF',
        question: '自分はどのタイプ？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '公正な判断ができる人',
          },
          {
            type: 'F',
            score: 5,
            content: '寛大な心を持つ人',
          },
        ],
      },
      {
        which: 'TF',
        question: '人に頼み事をするときの自分は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '本論に入る前の前置きが長い。',
          },
          {
            type: 'F',
            score: 5,
            content: '早速本論に入る。',
          },
        ],
      },
      {
        which: 'JP',
        question: '人と待ち合わせをするときの自分は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '迷いなくすぐにOK！',
          },
          {
            type: 'P',
            score: 5,
            content: 'どうしようかな…ちょっと待って…うーん… ',
          },
        ],
      },
      {
        which: 'JP',
        question: '今日の予定は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '早朝に起きて運動して、\n朝ごはん食べたら勉強しようか…はっ、\nでもやってないな(笑)',
          },
          {
            type: 'P',
            score: 5,
            content: '予定ね…呼吸をすることくらい？',
          },
        ],
      },
      {
        which: 'JP',
        question: '学校の課題（宿題）があるときの自分は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '計画を立てるところから。',
          },
          {
            type: 'P',
            score: 5,
            content: 'まずはやり始めよう。',
          },
        ],
      },
      {
        which: 'JP',
        question: '香りを嗅ぐときの自分は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '甘い匂いがするけど、バニラかな？',
          },
          {
            type: 'P',
            score: 5,
            content: 'おっ、良いね！私好みの香りだよ～',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ISTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Ssoapy',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseJP/Ssoapy.png',
      },
      {
        type: 'ESFP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'BabyS',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseJP/BabyS.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'PBreeze',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseJP/PBreeze.png',
      },
      {
        type: 'INTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'CMusk',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseJP/CMusk.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ACotton',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseJP/ACotton.png',
      },
      {
        type: 'ESFJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'SDilicious',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseJP/SDilicious.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'SDelight',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseJP/SDelight.png',
      },
      {
        type: 'INFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'Intensive',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseJP/Intensive.png',
      },
      {
        type: 'ISFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'LBlanc',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseJP/LBlanc.png',
      },
      {
        type: 'ESTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'CF',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseJP/CF.png',
      },
      {
        type: 'INFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'SFlower',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseJP/SFlower.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'LBloom',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseJP/LBloom.png',
      },
      {
        type: 'ESTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'HGreen',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseJP/HGreen.png',
      },
      {
        type: 'ISFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'BFleur',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseJP/BFleur.png',
      },
      {
        type: 'INTP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'SRose',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseJP/SRose.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'PViolet',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseJP/PViolet.png',
      },
    ],
  },
  // personalIncenseEng
  {
    info: {
      mainTitle: 'Incense BTI_My Personality reflected by Incense',
      subTitle: 'Incense BTI_My Personality reflected by Incense',
      mainUrl: 'personalIncenseEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/personalFragranceEng-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/personalIncenseEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/USA/personalIncenseEng.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'Who am I to others?!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'A person who is accessible with ease',
          },
          {
            type: 'I',
            score: 5,
            content: 'A person who is accessible with caution',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What is my position in the meeting?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Understand the situation of the meeting very well',
          },
          {
            type: 'I',
            score: 5,
            content: 'Usually be informed by others',
          },
        ],
      },
      {
        which: 'EI',
        question: 'How do I look when calling?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Speak out to the call promptly.',
          },
          {
            type: 'I',
            score: 5,
            content: 'Prepare what to say and make a call.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'How do I choose fragrance?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Closely approach and smell the fragrance.',
          },
          {
            type: 'I',
            score: 5,
            content: "Listen to other's recommendation",
          },
        ],
      },
      {
        which: 'SN',
        question: 'What is your favorite book genre?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Mystery novels',
          },
          {
            type: 'N',
            score: 5,
            content: 'Romance novels or an infinite imaginable books',
          },
        ],
      },
      {
        which: 'SN',
        question: 'What if you are going for a trip?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Make an impromptu trip.',
          },
          {
            type: 'N',
            score: 5,
            content: 'Make a plan and go.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'What if I choose to live in one of the two?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'A world without prejudice',
          },
          {
            type: 'N',
            score: 5,
            content: 'A world with a lot of compassion',
          },
        ],
      },
      {
        which: 'SN',
        question: 'What is your reason for spraying perfume?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'To smell a good smell',
          },
          {
            type: 'N',
            score: 5,
            content: 'To be favorable around me',
          },
        ],
      },
      {
        which: 'TF',
        question: 'The project I worked hard on failed.\nWhat is my reaction?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              "I couldn't win the top...\nWorked very hard but couldn't win.\nThat's it.",
          },
          {
            type: 'F',
            score: 5,
            content: "Good job~ Anyway, you did your best!!\nThat's it.",
          },
        ],
      },
      {
        which: 'TF',
        question: 'I am this kind of person.',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Someone like a fair judge',
          },
          {
            type: 'F',
            score: 5,
            content: 'A generous person',
          },
        ],
      },
      {
        which: 'TF',
        question: 'When do I ask for something from others?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'The introduction lengthens before going into the main subject.',
          },
          {
            type: 'F',
            score: 5,
            content: 'Directly go into the main point',
          },
        ],
      },
      {
        which: 'JP',
        question: 'When I make an appointment?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Okay! right away without worrying!',
          },
          {
            type: 'P',
            score: 5,
            content: 'Stop worrying... Wait... Um...',
          },
        ],
      },
      {
        which: 'JP',
        question: "What is today's schedule?",
        answers: [
          {
            type: 'J',
            score: 2,
            content: "Let's do some... Later, but I didn't do it ^^",
          },
          {
            type: 'P',
            score: 5,
            content: 'Schedule?... just breathing?',
          },
        ],
      },
      {
        which: 'JP',
        question: 'When do I do my homework?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Start from the sweet one as planned',
          },
          {
            type: 'P',
            score: 5,
            content: 'Start first to catch the plan',
          },
        ],
      },
      {
        which: 'JP',
        question: 'When I smell the incense',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Is it vanilla that smells sweet?',
          },
          {
            type: 'P',
            score: 5,
            content: "Oh~ Good! It's my style~",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ISTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Ssoapy',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseEng/Ssoapy.png',
      },
      {
        type: 'ESFP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'BabyS',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseEng/BabyS.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'PBreeze',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseEng/PBreeze.png',
      },
      {
        type: 'INTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'CMusk',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseEng/CMusk.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ACotton',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseEng/ACotton.png',
      },
      {
        type: 'ESFJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'SDilicious',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseEng/SDilicious.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'SDelight',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseEng/SDelight.png',
      },
      {
        type: 'INFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'Intensive',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseEng/Intensive.png',
      },
      {
        type: 'ISFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'LBlanc',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseEng/LBlanc.png',
      },
      {
        type: 'ESTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'CF',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseEng/CF.png',
      },
      {
        type: 'INFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'SFlower',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseEng/SFlower.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'LBloom',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseEng/LBloom.png',
      },
      {
        type: 'ESTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'HGreen',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseEng/HGreen.png',
      },
      {
        type: 'ISFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'BFleur',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseEng/BFleur.png',
      },
      {
        type: 'INTP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'SRose',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseEng/SRose.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'PViolet',
        img_src:
          'https://images.ktestone.com/resultImages/personalIncenseEng/PViolet.png',
      },
    ],
  },
  // personalIncense //
  {
    info: {
      mainTitle: '퍼스널 향 테스트',
      subTitle: '나에게 어울리는 향은 무엇일까? 향비티아이',
      mainUrl: 'personalIncense',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/duftDoft-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/duftDoft-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalIncense.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '남들에게 나는 어떤 사람?!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '쉽게 다가갈 수 있는 사람',
          },
          {
            type: 'I',
            score: 5,
            content: '조심스럽게 다가가야 하는 사람',
          },
        ],
      },
      {
        which: 'EI',
        question: '무리에서 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '무리의 상황을 잘 파악하고 있다.',
          },
          {
            type: 'I',
            score: 5,
            content: '보통 다른 사람이 소식을 전해준다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '전화할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '그냥 바로 전화받자마자 말한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '할 말 미리 준비해두고 전화한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '내가 향을 선택할 때?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '주변인들에게 추천을 받는다.',
          },
          {
            type: 'I',
            score: 5,
            content: '직접 가서 향을 맡아본다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 좋아하는 책은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '추리 가능한 추리소설',
          },
          {
            type: 'N',
            score: 5,
            content: '로맨스 소설이나 무한으로 상상이 가능한 책',
          },
        ],
      },
      {
        which: 'SN',
        question: '여행을 간다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '즉흥적으로 여행을 간다',
          },
          {
            type: 'N',
            score: 5,
            content: '계획을 세우고 여행을 간다',
          },
        ],
      },
      {
        which: 'SN',
        question: '둘 중 한곳에 살아야 한다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '편견 없는 세상',
          },
          {
            type: 'N',
            score: 5,
            content: '인정이 많은 세상',
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 향수를 뿌리는 이유는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '내가 좋은 향을 맡기 위해 ',
          },
          {
            type: 'N',
            score: 5,
            content: '주변 사람에게 잘 보이기 위해',
          },
        ],
      },
      {
        which: 'TF',
        question: '열심히 한 프로젝트가 실패했다',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '일등을 못 했다니... 열심히 했어도 일등 못했으니 끝이지 뭐',
          },
          {
            type: 'F',
            score: 5,
            content: '수고했어~ 열심히 했으니까 된 거야! ',
          },
        ],
      },
      {
        which: 'TF',
        question: '나는 이런 사람이다.',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '공정한 판사 같은 사람',
          },
          {
            type: 'F',
            score: 5,
            content: '궁예처럼 관대한 사람',
          },
        ],
      },
      {
        which: 'TF',
        question: '난 부탁할 때',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '본론을 꺼내기 위해 서론이 길어진다.',
          },
          {
            type: 'F',
            score: 5,
            content: '바로 본론부터',
          },
        ],
      },
      {
        which: 'JP',
        question: '약속을 잡을 때 나는',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '고민 없이 바로 오카이!',
          },
          {
            type: 'P',
            score: 5,
            content: '멈칫멈칫 고민... 잠깐만... 음...',
          },
        ],
      },
      {
        which: 'JP',
        question: '오늘의 일정은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '아침에 일찍 일어나서 운동하고 밥 먹고 공부 좀 하자...\n후 근데 안 했네 ^^',
          },
          {
            type: 'P',
            score: 5,
            content: '일정이라... 그냥 숨쉬기?',
          },
        ],
      },
      {
        which: 'JP',
        question: '과제 할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '시작은 달콤하게 계획부터',
          },
          {
            type: 'P',
            score: 5,
            content: '각 잡으려면 일단 시작해',
          },
        ],
      },
      {
        which: 'JP',
        question: '나는 향을 맡을 때',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '달달한 냄새가 나는 게 바닐라인가?',
          },
          {
            type: 'P',
            score: 5,
            content: '오~ 굿! 마이스타일~',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ISTJ',
        desc: '',
        query: 'Ssoapy',
        img_src: 'https://images.ktestone.com/resultImages/duftDoft/Ssoapy.png',
      },
      {
        type: 'ESFP',
        desc: '',
        query: 'BabyS',
        img_src: 'https://images.ktestone.com/resultImages/duftDoft/BabyS.png',
      },
      {
        type: 'ENFP',
        desc: '',
        query: 'PBreeze',
        img_src:
          'https://images.ktestone.com/resultImages/duftDoft/PBreeze.png',
      },
      {
        type: 'INTJ',
        desc: '',
        query: 'CMusk',
        img_src: 'https://images.ktestone.com/resultImages/duftDoft/CMusk.png',
      },
      {
        type: 'ISTP',
        desc: '',
        query: 'ACotton',
        img_src:
          'https://images.ktestone.com/resultImages/duftDoft/ACotton.png',
      },
      {
        type: 'ESFJ',
        desc: '',
        query: 'SDilicious',
        img_src:
          'https://images.ktestone.com/resultImages/duftDoft/SDilicious.png',
      },
      {
        type: 'ENFJ',
        desc: '',
        query: 'SDelight',
        img_src:
          'https://images.ktestone.com/resultImages/duftDoft/SDelight.png',
      },
      {
        type: 'INFP',
        desc: '',
        query: 'Intensive',
        img_src:
          'https://images.ktestone.com/resultImages/duftDoft/intensive.png',
      },
      {
        type: 'ISFJ',
        desc: '',
        query: 'LBlanc',
        img_src: 'https://images.ktestone.com/resultImages/duftDoft/LBlanc.png',
      },
      {
        type: 'ESTP',
        desc: '',
        query: 'CF',
        img_src: 'https://images.ktestone.com/resultImages/duftDoft/CF.png',
      },
      {
        type: 'INFJ',
        desc: '',
        query: 'SFlower',
        img_src:
          'https://images.ktestone.com/resultImages/duftDoft/SFlower.png',
      },
      {
        type: 'ENTP',
        desc: '',
        query: 'LBloom',
        img_src: 'https://images.ktestone.com/resultImages/duftDoft/LBloom.png',
      },
      {
        type: 'ESTJ',
        desc: '',
        query: 'HGreen',
        img_src: 'https://images.ktestone.com/resultImages/duftDoft/HGreen.png',
      },
      {
        type: 'ISFP',
        desc: '',
        query: 'BFleur',
        img_src: 'https://images.ktestone.com/resultImages/duftDoft/BFleur.png',
      },
      {
        type: 'INTP',
        desc: '',
        query: 'SRose',
        img_src: 'https://images.ktestone.com/resultImages/duftDoft/SRose.png',
      },
      {
        type: 'ENTJ',
        desc: '',
        query: 'PViolet',
        img_src:
          'https://images.ktestone.com/resultImages/duftDoft/PViolet.png',
      },
    ],
  },
  // persoanlColor in French
  {
    info: {
      mainTitle: 'Test de couleur personnelle_Version française',
      subTitle: 'Quelle est ma couleur perosale?',
      mainUrl: 'personalColorFra',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/personalColorFra-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/personalColorFra-thumb.png',
      lang: 'Fra',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'Moi avec des gens que\nje rencontre pour la première fois ?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Je leur parle avant eux.',
          },
          {
            type: 'I',
            score: 5,
            content: 'Dans la plupart du temps,\nils me parlent avant moi.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Moi, le week-end?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'J’aime rencontrer les gens.',
          },
          {
            type: 'I',
            score: 5,
            content: 'Rester seul à la maison ne me dérange pas.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Moi avec mes amis pour nous amuser?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'J’aime une ambiance bien animée pleine de gaieté.',
          },
          {
            type: 'I',
            score: 5,
            content: 'J’aime parler dans le calme avec quelques amis.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'Ce qui est plus important pour moi?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Pas de présent,\npas de futur.',
          },
          {
            type: 'N',
            score: 5,
            content: 'Sans penser au futur,\npas de progrès.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'Moi au travail?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'J’aime bien suivre ce que les autres font.',
          },
          {
            type: 'N',
            score: 5,
            content: 'J’aime créer ma propre méthode.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'Mon entourage me dit souvent?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Tu es constant(e) et patient(e).',
          },
          {
            type: 'N',
            score: 5,
            content: 'Tu es créatif(ve) et unique.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'Dans une situation que je dois dire non,\nmoi alors?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Je dis fermement que je ne peux pas.',
          },
          {
            type: 'F',
            score: 5,
            content: 'Souvent,\nje ne dis pas non après avoir un peu réfléchi.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'Quand je suis en colère?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Je parle très logiquement et argumente.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Je pleure d’abord sans pouvoir supporter\nla colère alors que j’ai beaucoup de choses à dire.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'Un(e) ami(e) me confie son souci,\nil semble cependant que ce soit à cause de sa faute.\nMoi alors?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Je lui remarque ses erreurs.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'J’en parle indirectement de peur que mon ami(e) se sente mal.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Quand je dois faire des préparatifs?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Je les prépare depuis la veille.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'Je les oublie souvent en me disant\n« je vais les préparer demain ».',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'J’allais travailler à la maison,\nmes amis me retiennent cependant pour jouer ensemble.\nJe dis alors?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '« Heu… ce n’est pas quelque chose de prévu…\nje ne sais pas du tout… »',
          },
          {
            type: 'P',
            score: 5,
            content:
              '« Ça marche!\nC’est comme ça la vie qui ne va pas comme prévu!\nAllons-y ! »',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Moi, en général?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Je fais ce que j’ai organisé comme prévu!\nJ’aime bien faire quelque chose en ordre!',
          },
          {
            type: 'P',
            score: 5,
            content:
              'J’aime bien spontanément faire quelque chose!\nJ’aime bien la flexibilité!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFra/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFra/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFra/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFra/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFra/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFra/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFra/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFra/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFra/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFra/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFra/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFra/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFra/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFra/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFra/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorFra/INFP.png',
      },
    ],
  },
  // persoanlColor in German
  {
    info: {
      mainTitle: 'Persönliche Farbprüfung',
      subTitle: 'Was ist meine persische Farbe?',
      mainUrl: 'personalColorGer',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/personalColorGer-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/personalColorGer-thumb.png',
      lang: 'Ger',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'Fremden,\ndie ich zum ersten Mal treffe...',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'spreche ich erst an.',
          },
          {
            type: 'I',
            score: 5,
            content: 'spricht anderer Freund erst an.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Am Wochenende...',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'treffe ich gern mit Leuten.',
          },
          {
            type: 'I',
            score: 5,
            content: 'genieße ich, allein Zeit zu verbringen.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Wenn ich mit Freunden etwas unternehme...',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'mag ich eine laute und aktive Stimmung.',
          },
          {
            type: 'I',
            score: 5,
            content:
              'habe ich lieber ein kleines\nGespräch mit wenigen Freunden.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'Was finde ich wichtiger?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Ohne Gegenwart gibt es keine Zukunft.',
          },
          {
            type: 'N',
            score: 5,
            content: 'Ohne Zukunft gibt es keine Entwicklung.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'Bei der Arbeit...',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'mache ich selber meinen eigenen Weg.',
          },
          {
            type: 'N',
            score: 5,
            content: 'folge ich, wie die anderen Leute machen.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'Leute, die mich kennen,\nsagen mir oft...',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'dass ich ständig und geduldig bin.',
          },
          {
            type: 'N',
            score: 5,
            content: 'dass ich kreativ und unkonventionell bin.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'Wenn ich versagen soll...',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'sage ich stark, dass ich nicht kann.',
          },
          {
            type: 'F',
            score: 5,
            content: 'denke ich nach und sage meistens zu.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'Wenn ich ärgerlich bin...',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'sage ich logisch und stelle klar.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'habe ich viel zu sagen,\naber ich kann meinen Ärger nicht überwinden,\ndeshalb weine ich einfach.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'Ein Freund/Eine Freundin sagt mir seine/ihre Sorge.\nEs klingt so, dass es seine/ihre Schuld ist.\nIn diesem Fall...',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'sage ich ihm/ihr,\nwas genau das Problem ist.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'sage ich indirekt,\nansonsten fühlt sich er/sie nicht gut.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Beim Einpacken meiner Sachen...',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'bereite ich vor einem Tag schon vor.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'denke ich nur im Kopf\n“Ich muss morgen einpacken.”\nund dann oft vergesse.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Ich wollte nach der Schule zu Hause lernen,\naber Freunde schalgen vor,\netwas zu unternehmen.\nDann mache ich so',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Das steht nicht auf dem Plan...\nSehr ratlos.',
          },
          {
            type: 'P',
            score: 5,
            content: 'OK! Das ist ja das Leben!\nGehen wir rausss!!!',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Meistens bin ich so',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Nach meinem Plan!\nIch arbeite gern der Ordnung nach ordentlich.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'Von Fall zu Fall arbeite ich frei!\nIch arbeite gern flexibel.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorGer/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorGer/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorGer/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorGer/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorGer/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorGer/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorGer/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorGer/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorGer/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorGer/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorGer/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorGer/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorGer/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorGer/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorGer/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorGer/INFP.png',
      },
    ],
  },
  // persoanlColor in Malay
  {
    info: {
      mainTitle: 'Ujian Personaliti Warna_Personaliti',
      subTitle: 'Warna apa yang sesuai dengan saya?',
      mainUrl: 'personalColorMalay',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/personalColorMalay-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/personalColorMalay-thumb.png',
      lang: 'Bahasa',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'Bagaimana sikap saya terhadap rakan-rakan baru?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Saya yang akan tegur mereka dulu.',
          },
          {
            type: 'I',
            score: 5,
            content: 'Selalunya,\nmereka yang akan mula tegur saya dulu.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Apa saya buat pada hujung minggu?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Saya suka jumpa orang dan bersosial.',
          },
          {
            type: 'I',
            score: 5,
            content: 'Saya tak kisah untuk bersendirian.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Apa saya buat bila berjumpa rakan?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Saya suka berborak dan mewujudkan suasana ceria.',
          },
          {
            type: 'I',
            score: 5,
            content:
              'Saya suka perbualan kecil bersama sebilangan kecil rakan-rakan.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'Mana satu yang lagi penting bagi saya?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'Kalau tak manfaatkan masa sekarang,\nmasa depan takkan wujud. ',
          },
          {
            type: 'N',
            score: 5,
            content: 'Kalau tak fikirkan masa depan,\nakan tiada perkembangan.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'Bagaimana sikap saya semasa bekerja?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Lebih baik ikut apa yang orang lain buat.',
          },
          {
            type: 'N',
            score: 5,
            content: 'Lebih baik selesaikan sesuatu dengan cara saya sendiri.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'Apa pandangan orang lain terhadap saya?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Saya seorang yang tabah dan penyabar.',
          },
          {
            type: 'N',
            score: 5,
            content: 'Saya seorang yang kreatif dan unik.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'Apa yang saya akan buat bila perlu menolak sesuatu?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Saya akan tegas mengatakan saya tak dapat melakukannya.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Setelah berfikir sejenak,\nsaya akur pada kehendak orang lain.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'Apa jadi bila saya marah?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Saya akan behujah secara logik. ',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Banyak yang saya nak cakap,\ntapi akhirnya saya menangis kerana terlalu marah.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'Kawan saya mengadu masalahnya,\ntapi bila dengar, macam dia yang bersalah.\nApa akan saya buat?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Saya beritahu rakan saya apa kesalahannya.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Saya akan pusing-pusing ayat kerana risau dia terasa hati.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Apa sikap saya semasa menyediakan barang keperluan?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Saya akan sediakan semuanya sehari sebelum.',
          },
          {
            type: 'P',
            score: 5,
            content:
              '‘Saya akan sediakan semuanya esok’\ntapi akhirnya lupa dan barangnya pun tertinggal.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Sekolah dah tamat, jadi saya nak balik rumah dan belajar,\ntapi rakan ajak lepak pula.\nApa akan saya buat?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Tapi tiada dalam jadual saya hari ini…\nsaya terkejut dan bingung.',
          },
          {
            type: 'P',
            score: 5,
            content: 'Okey! Hidup tak perlu terlalu serius.\nJom lepak!!!',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Secara amnya,\nsaya jenis orang macam mana?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Asalkan semua benda dah rancang, saya okey!\nSaya suka bila semuanya tersusun!',
          },
          {
            type: 'P',
            score: 5,
            content:
              'Saya urus benda ikut apa yang saya fikir masa tu!\nSaya seorang yang fleksibel!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorMalay/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorMalay/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorMalay/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorMalay/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorMalay/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorMalay/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorMalay/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorMalay/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorMalay/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorMalay/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorMalay/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorMalay/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorMalay/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorMalay/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorMalay/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorMalay/INFP.png',
      },
    ],
  },
  // persoanlColor in Russian
  {
    info: {
      mainTitle: 'Персональный цветовой тест_ Личность',
      subTitle: 'Какой цвет вам подходит?',
      mainUrl: 'personalColorRus',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/personalColorRus-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/personalColorRus-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/ETC/personalColorRus.png',
      lang: 'Rus',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'Когда вы видите человека в первый раз?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Вы начинаете разговор.',
          },
          {
            type: 'I',
            score: 5,
            content: 'Обычно с вами начинают разговор первыми.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Чем обычно занимаетесь в выходные?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Наслаждаетесь общением с людьми.',
          },
          {
            type: 'I',
            score: 5,
            content: 'Предпочитаете быть в одиночку.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Когда проводите время с друзьями, то?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Любите шумную компанию.',
          },
          {
            type: 'I',
            score: 5,
            content:
              'Предпочитаете разговаривать с небольшим количеством друзей.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'Что вы считаете более важным?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Если нет настоящего, нет и будущего.',
          },
          {
            type: 'N',
            score: 5,
            content: 'Если не задумываться о будущем, не будет развития.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'На работе вы?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Делаете так, как все.',
          },
          {
            type: 'N',
            score: 5,
            content: 'Придумываете свой способ действия.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'Что обычно говорят вам окружающие?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Стабильный и терпеливый',
          },
          {
            type: 'N',
            score: 5,
            content: 'Творческий и экстраординарный.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'Когда нужно отказать, вы?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Даете твердый отказ.',
          },
          {
            type: 'F',
            score: 5,
            content: 'Сразу не соглашаетесь, но все равно помогаете.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'Когда вы злитесь, то...?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Логически высказываете свою точку зрения',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Вам есть что сказать,\nно так злы, что начинаете плакать.\nего чувства, поэтому говорите завуалированно.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'Друг рассказал о своей проблеме,\nно вам кажется это его вина. Ваши действия?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Расскажете другу, в чем его ошибка.',
          },
          {
            type: 'F',
            score: 5,
            content: 'Если сказать прямо, это может ранить',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Когда вы готовитесь к чему-то?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'отовитесь заранее накануне.',
          },
          {
            type: 'P',
            score: 5,
            content: 'Оставляете дела на завтра,\nа потом забываете.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'После занятий вам нужно домой учиться,\nно друзья зовут гулять.\nВаши действия?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Я это не планировал(а)..Не знаю,\nчто и делать.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'ОК! Все-таки жизнь-это когда все идет не по плану!\nГуляем!!!',
          },
        ],
      },
      {
        which: 'JP',
        question: 'В целом как вы себя опишите?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Мне нравится,\nкогда всё идет по плану!',
          },
          {
            type: 'P',
            score: 5,
            content: 'Думаю на месте!\nЛюблю адаптироваться к ситуации!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorRus/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorRus/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorRus/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorRus/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorRus/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorRus/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorRus/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorRus/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorRus/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorRus/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorRus/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorRus/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorRus/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorRus/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorRus/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorRus/INFP.png',
      },
    ],
  },
  // persoanlColor in Indian
  {
    info: {
      mainTitle: 'Personal color test',
      subTitle: 'What is my perosnal color?',
      mainUrl: 'personalColorInd',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/personalColorInd-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/personalColorInd-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/ETC/personalColorInd.png',
      lang: 'Hin',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'पहली मुलाक़ात में',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'पहले मैं बात शुरू करता हूँ',
          },
          {
            type: 'I',
            score: 5,
            content: 'सामने वाले के बात शुरू करने का इंतज़ार करता हूँ ',
          },
        ],
      },
      {
        which: 'EI',
        question: 'वीकेंड को मैं',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'लोगों से मिलना पसंद करता हूँ',
          },
          {
            type: 'I',
            score: 5,
            content: 'अकेले रहना भी ठीक है',
          },
        ],
      },
      {
        which: 'EI',
        question: 'दोस्तों के साथ ',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'शोर मचाते हुए ज़ोर से बात करना पसंद है',
          },
          {
            type: 'I',
            score: 5,
            content: 'कम दोस्तों के साथ छोटी सी बातें करना पसंद है',
          },
        ],
      },
      {
        which: 'SN',
        question: 'मेरे लिए ज़्यादा ज़रूरी क्या है ?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'इस पल की ख़ुशी के बिना भविष्य भी बेकार है',
          },
          {
            type: 'N',
            score: 5,
            content: 'भविष्य की योजना के बिना विकास नहीं होगा',
          },
        ],
      },
      {
        which: 'SN',
        question: 'मेरा काम करने का तरीका',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'दूसरे लोगों के तरीके से काम करना पसंद है',
          },
          {
            type: 'N',
            score: 5,
            content: 'अपना तरीका खुद बनाना पसंद है',
          },
        ],
      },
      {
        which: 'SN',
        question: 'दूसरे लोग मेरे बारे में क्या बोलते हैं ?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'तुम हमेशा काम को सम्पूर्ण करते हो, और तुम्हारी सहनशक्ति बहुत मज़बूत है',
          },
          {
            type: 'N',
            score: 5,
            content: 'तुम बहुत अलग और अनोखे इंसान हो',
          },
        ],
      },
      {
        which: 'TF',
        question: 'अगर किसी को मना करना पड़े तो',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'सख्ती से मना करता हूँ',
          },
          {
            type: 'F',
            score: 5,
            content: 'बहुत सोचने के बाद मना नहीं कर पाता',
          },
        ],
      },
      {
        which: 'TF',
        question: 'अगर मुझे गुस्सा आए तो',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'बातों से सामने वाले को समझाता हूँ',
          },
          {
            type: 'F',
            score: 5,
            content: 'बोलने के लिए बहुत कुछ है लेकिन आँसू ही पहले निकलते हैं',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'दोस्त अपनी दुःख की बात शेयर कर रहा है,\nमगर उसकी ही गलती लगती है। तो मैं क्या करता हूँ ?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'सीधे उसकी गलती को समझाता हूँ',
          },
          {
            type: 'F',
            score: 5,
            content:
              'बात को घुमा-फिराकर दोस्त को समझाता हूँ ताकि उसे चोट न लगे',
          },
        ],
      },
      {
        which: 'JP',
        question: 'स्कूल में कोई चीज़ ले जाना है तो',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'एक दिन पहले से तैयार करके रखता हूँ',
          },
          {
            type: 'P',
            score: 5,
            content: "कल तैयार करूँगा' बोलकर भूल जाता हूँ",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'घर जाकर पढ़ाई करने की सोची थी,\nअचानक दोस्त खेल पर बुला रहे हैं',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'यह तो प्लान के बाहर है। सोचना पड़ेगा',
          },
          {
            type: 'P',
            score: 5,
            content:
              'कोई बात नहीं! ज़िंदगी कहाँ प्लान से चलती है? जमकर मज़ा करता हूँ !',
          },
        ],
      },
      {
        which: 'JP',
        question: 'आम तौर पर मैं',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'अपने प्लान के हिसाब से, बारी के अनुसार काम करना पसंद करता हूँ',
          },
          {
            type: 'P',
            score: 5,
            content:
              'जब मूड हो तब कर लूँगा ~ हालत के हिसाब से काम करना पसंद करता हूँ',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorInd/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorInd/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorInd/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorInd/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorInd/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorInd/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorInd/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorInd/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorInd/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorInd/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorInd/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorInd/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorInd/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorInd/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorInd/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorInd/INFP.png',
      },
    ],
  },
  // persoanlColor in Arabian
  {
    info: {
      mainTitle: 'اختبار الألوان الرئيسي - لوحة الشخصيات',
      subTitle: 'ما هو اللون المناسب لي؟',
      mainUrl: 'personalColorArb',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/personalColorArb-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/personalColorArb-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/ETC/personalColorArab.png',
      lang: 'Arb',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'كيف أكون بالنسبة لشخص أقابله للمرة الأولى؟',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'عادة ما ابدأ أنا الحديث أولا',
          },
          {
            type: 'I',
            score: 5,
            content: 'عادة ما يبدأ الشخص الآخر الحديث أولا',
          },
        ],
      },
      {
        which: 'EI',
        question: 'كيف أكون في عطلات نهاية الأسبوع؟',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'أستمتع بمقابلة الآخرين',
          },
          {
            type: 'I',
            score: 5,
            content: 'لا أكره البقاء وحدي',
          },
        ],
      },
      {
        which: 'EI',
        question: 'كيف أكون وأنا ألعب مع أصدقائي؟',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'أحب الأجواء الصاخبة الحماسية',
          },
          {
            type: 'I',
            score: 5,
            content: 'أحب المحادثات الصغيرة مع عدد قليل من الأصدقاء',
          },
        ],
      },
      {
        which: 'SN',
        question: 'ما هو الشيء الأكثر أهمية بالنسبة لي؟',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'بدون حاضر، لا مستقبل',
          },
          {
            type: 'N',
            score: 5,
            content: 'إذا لم أفكر في المستقبل لن أتطور',
          },
        ],
      },
      {
        which: 'SN',
        question: 'كيف أكون أثناء العمل؟',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'أحب وضع طريقة خاصة بي بنفسي',
          },
          {
            type: 'N',
            score: 5,
            content: 'أفضل اتباع ما يفعله الآخرون',
          },
        ],
      },
      {
        which: 'SN',
        question: 'ماذا يقول الآخرون لي؟',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'أنني مثابر وصبور',
          },
          {
            type: 'N',
            score: 5,
            content: 'أنني مبدع وفريد',
          },
        ],
      },
      {
        which: 'TF',
        question: 'عندما يتعين علي رفض شيء ما؟',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'أرفض بقوة',
          },
          {
            type: 'F',
            score: 5,
            content: 'عادة ما أقبل بعد تفكير',
          },
        ],
      },
      {
        which: 'TF',
        question: 'ماذا أفعل عندما أغضب؟',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'أفند الموقف مع الحديث على نحو منطقي',
          },
          {
            type: 'F',
            score: 5,
            content:
              'عادة ما أبدأ في البكاء لشدة غضبي، مع أنني أرغب في قول الكثير',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'صديقي يحدثني عن شيء يقلقه،وعلى ما يبدو  هو من أخطأ، ماذا أفعل؟',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'أخبره بالنقطة التي أخطأ بها',
          },
          {
            type: 'F',
            score: 5,
            content: 'إذا أخبرته بشكل مباشر قد أجرحه، لذا أخبره بشكل غير مباشر',
          },
        ],
      },
      {
        which: 'JP',
        question: 'عند تحضير بعض الاحتياجات ماذا أفعل؟',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'أحضر ما أحتاجه قبل يوم',
          },
          {
            type: 'P',
            score: 5,
            content: "أقول 'سأحضرها غدا'، ولكن عادة ما أنسى",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'بعد المدرسة كنت أنوي الذهاب إلى البيت للدراسة، ولكن أصدقائي يلحون علي لألعب معهم، أنا؟',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'هذا أمر لم أكن أخطط له... هذا الموقف يثير ارتباكي.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'حسنا، كما هو متوقع الحياة لا تسير وفقا لما هو مخطط لها! فلنلعب!',
          },
        ],
      },
      {
        which: 'JP',
        question: 'بشكل عام، أنا؟',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'أحب ممارسة الأمور وفقا لما هو مخطط له، وبالترتيب المخطط له',
          },
          {
            type: 'P',
            score: 5,
            content: 'أتمم الأمور عندما أتذكرها! أحب العمل بمرونة',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorArb/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorArb/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorArb/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorArb/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorArb/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorArb/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorArb/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorArb/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorArb/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorArb/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorArb/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorArb/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorArb/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorArb/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorArb/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorArb/INFP.png',
      },
    ],
  },
  // persoanlColor in Spanish
  {
    info: {
      mainTitle: 'Testeo de colores personales',
      subTitle: '¿Cuál es el color que mejor me queda?',
      mainUrl: 'personalColorES',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/personalColorES-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/personalColorES-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/ETC/personalColorES.png',
      lang: 'ES',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '¿Cómo soy con nuevos amigos?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Yo le hablo primero.',
          },
          {
            type: 'I',
            score: 5,
            content: 'Espero a que esa persona me hable primero.',
          },
        ],
      },
      {
        which: 'EI',
        question: '¿Qué hago los fines de semana?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Disfruto encontrarme con gente.',
          },
          {
            type: 'I',
            score: 5,
            content: 'No me disgusta estar solo.',
          },
        ],
      },
      {
        which: 'EI',
        question: '¿Cómo soy cuando estoy con amigos?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Me gustan ambientes alegres y bulliciosos.',
          },
          {
            type: 'I',
            score: 5,
            content: 'Prefiero conversar tranquilo con pocos amigos.',
          },
        ],
      },
      {
        which: 'SN',
        question: '¿Qué es más importante para mí?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Sin el presente, no existe un futuro.',
          },
          {
            type: 'N',
            score: 5,
            content: 'Debo pensar en el futuro para desarrollarme.',
          },
        ],
      },
      {
        which: 'SN',
        question: '¿Cómo soy cuando trabajo?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Me gusta armar mis propios métodos.',
          },
          {
            type: 'N',
            score: 5,
            content: 'Me gusta seguir los métodos de otros.',
          },
        ],
      },
      {
        which: 'SN',
        question: '¿Qué me dice la gente que me rodea?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Me dicen que soy constante y paciente.',
          },
          {
            type: 'N',
            score: 5,
            content: 'Me dicen que soy creativo e ingenioso.',
          },
        ],
      },
      {
        which: 'TF',
        question: '¿Cómo reacciono ante una situación en donde debo rechazar?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Soy firme con mi postura.',
          },
          {
            type: 'F',
            score: 5,
            content: 'Suelo pensar y luego accedo.',
          },
        ],
      },
      {
        which: 'TF',
        question: '¿Cómo soy cuando me enojo?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Hablo tranquilo con lógica.',
          },
          {
            type: 'F',
            score: 5,
            content: 'Tengo mucho para decir, pero empiezo a llorar del enojo.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'Un amigo me cuenta su problema, pero creo que él tiene la culpa. ¿Qué hago en esta situación?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Le digo cuáles son sus errores.',
          },
          {
            type: 'F',
            score: 5,
            content: 'Trato de no ser tan directo para no incomodarlo.',
          },
        ],
      },
      {
        which: 'JP',
        question: '¿Cómo soy para preparar las cosas?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Las preparo un día antes.',
          },
          {
            type: 'P',
            score: 5,
            content: 'Lo dejo para otro día y me olvido.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Mi plan era ir a casa a estudiar,\npero mis amigos me invitan a salir.\n¿Qué hago?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '¡Está bien! La vida no sale como uno planea. ¡¡¡Me divertiré!!!',
          },
          {
            type: 'P',
            score: 5,
            content: 'Me siento confundido porque no estaba en mis planes.',
          },
        ],
      },
      {
        which: 'JP',
        question: '¿Cómo soy por lo general?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '¡Me gusta que todo salga acorde a mis planes, como lo planeé!',
          },
          {
            type: 'P',
            score: 5,
            content: '¡Resuelvo espontáneamente, me gusta ser flexible!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorES/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorES/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorES/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorES/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorES/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorES/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorES/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorES/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorES/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorES/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorES/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorES/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorES/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorES/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorES/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorES/INFP.png',
      },
    ],
  },
  // persoanlColor in Chinese
  {
    info: {
      mainTitle: '颜色心理测试性格篇',
      subTitle: '最适合我的颜色是什么？ K测试 你的颜色是？',
      mainUrl: 'personalColorCN',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/personalColorCN-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/personalColorCN-thumb.png',
      lang: 'CN',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '初次结识的朋友面前我是？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '通常我先说话。',
          },
          {
            type: 'I',
            score: 5,
            content: '通常对方先说话。',
          },
        ],
      },
      {
        which: 'EI',
        question: '周末我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '喜欢和其他人见面。',
          },
          {
            type: 'I',
            score: 5,
            content: '并不介意独自度过。',
          },
        ],
      },
      {
        which: 'EI',
        question: '和朋友们出行时我是？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '喜欢喧嚣热闹的气氛。',
          },
          {
            type: 'I',
            score: 5,
            content: '喜欢和少数朋友们一起聊天。',
          },
        ],
      },
      {
        which: 'SN',
        question: '我认为更重要的是？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '没有现在就意味着没有未来。',
          },
          {
            type: 'N',
            score: 5,
            content: '不考虑未来的话就不会有进步。',
          },
        ],
      },
      {
        which: 'SN',
        question: '工作时的我是？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '更偏向跟随别人的脚步。',
          },
          {
            type: 'N',
            score: 5,
            content: '更偏向采取自己的方式。',
          },
        ],
      },
      {
        which: 'SN',
        question: '周围的人认为我是？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '经常说我很有耐心和恒心。',
          },
          {
            type: 'N',
            score: 5,
            content: '经常说我很有自己独创的思维。',
          },
        ],
      },
      {
        which: 'TF',
        question: '遇到必须拒绝的情况时，我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '直接了当地拒绝。',
          },
          {
            type: 'F',
            score: 5,
            content: '深思熟虑后也往往会顺从。',
          },
        ],
      },
      {
        which: 'TF',
        question: '我生气的时候会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '说得很有道理，同时也很计较。',
          },
          {
            type: 'F',
            score: 5,
            content: '想说的话很多，但因为太生气，往往眼泪先流出来。',
          },
        ],
      },
      {
        which: 'TF',
        question: '朋友向我诉说烦恼，我却觉得是朋友的错时，我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '告诉他错的原因。',
          },
          {
            type: 'F',
            score: 5,
            content: '直接了当地说的话担心朋友会伤心，所以故意绕着弯子说。',
          },
        ],
      },
      {
        which: 'JP',
        question: '做准备工作时的我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '提前一天做好准备。',
          },
          {
            type: 'P',
            score: 5,
            content: '总想着‘明天再说明天再说’，经常忘记。',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '结束了一天的日程回家后准备学习，结果朋友们突然叫我出去玩，这时我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '不在我的计划范围内…很纠结。',
          },
          {
            type: 'P',
            score: 5,
            content: 'OK！果然人生不会按计划走！玩起！！！',
          },
        ],
      },
      {
        which: 'JP',
        question: '总体上我是？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '按我的计划进行！更偏向按计划的顺序进行！',
          },
          {
            type: 'P',
            score: 5,
            content: '想起什么就先做什么！更偏向灵活处理！',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorCN/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorCN/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorCN/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorCN/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorCN/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorCN/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorCN/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorCN/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorCN/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorCN/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorCN/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorCN/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorCN/INTJ.png',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorCN/INTP.png',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorCN/INFJ.png',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorCN/INFP.png',
      },
    ],
  },
  // persoanlColor in English
  {
    info: {
      mainTitle: 'Personal color test',
      subTitle: 'What is my perosnal color?',
      mainUrl: 'personalColorEng',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/personalColorEng-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/personalColorEng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/USA/personalColorEng.png',
      lang: 'Eng',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: 'What am I to my first-time friends?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I speak to others first.',
          },
          {
            type: 'I',
            score: 5,
            content: 'Most of the time, the other friend speaks to you first.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What do I do during the weekend?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I enjoy meeting people.',
          },
          {
            type: 'I',
            score: 5,
            content: "I don't hate being alone.",
          },
        ],
      },
      {
        which: 'EI',
        question: 'What do I do when playing with my friends?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I like the chatty and exciting atmosphere.',
          },
          {
            type: 'I',
            score: 5,
            content:
              'I like to have small conversations with a small number of friends.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do I think more important?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'No present, no future.',
          },
          {
            type: 'N',
            score: 5,
            content: 'No preparation for the future, no progress.',
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do I do when working?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'It is good to follow what others do.',
          },
          {
            type: 'N',
            score: 5,
            content: "It's good to go on your own way.",
          },
        ],
      },
      {
        which: 'SN',
        question: 'What are the others say to me?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'They often say that I am persistent and patient.',
          },
          {
            type: 'N',
            score: 5,
            content: 'They often say that I am creative and unique.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'What if I have to refuse?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: "I strongly say I can't do it.",
          },
          {
            type: 'F',
            score: 5,
            content:
              'I tend to accept most of what they ask after a little hesitation.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'What if I am angry?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I speak logically and argue.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'I have a lot to say,\nbut I am so angry that I start to tear up.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          "My friend talks about his worries.\nSeems to me, it's fault.\nThen, what is my response?",
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Tell the friend it is his fault.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Saying it directly may hurt his mind.\nI will tell him beating around the bush. ',
          },
        ],
      },
      {
        which: 'JP',
        question: 'When do I do when preparing the supplies?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Prepare in advance a day before.',
          },
          {
            type: 'P',
            score: 5,
            content:
              "'I will prepare them tomorrow'\nbut I frequent forget and leave behind.",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'After school, I want go home and study,\nbut my friends catch me up to play. Then?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: "It wasn't in the plan...\nVery embarrassing.",
          },
          {
            type: 'P',
            score: 5,
            content:
              "Okay! After all, life doesn't go as planned!\nLet's play!!!",
          },
        ],
      },
      {
        which: 'JP',
        question: 'In general, who am I?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'As I planned! I like to go through the order of things I made!',
          },
          {
            type: 'P',
            score: 5,
            content:
              'I take care of things as I think of!\nI love to be flexible!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorEng/ESTJ_.png',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorEng/ESTP_.png',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorEng/ESFJ_.png',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorEng/ESFP_.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorEng/ENTJ_.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorEng/ENTP_.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorEng/ENFJ_.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorEng/ENFP_.png',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorEng/ISTJ_.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorEng/ISTP_.png',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorEng/ISFJ_.png',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorEng/ISFP_.png',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorEng/INTJ_.png',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorEng/INTP_.png',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorEng/INFJ_.png',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorEng/INFP_.png',
      },
    ],
  },
  // persoanlColor for Japan
  {
    info: {
      mainTitle: 'パーソナルカラー診断_性格編',
      subTitle:
        '自分に似合う色は何だろう？ パーソナルカラーテスト - ケイテスト',
      mainUrl: 'personalColorJP',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/personalColorJP-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/personalColorJP-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Japan/personalColorJP.png',
      lang: 'JP',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '初対面の人とあったときの自分は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '自分から声をかける。',
          },
          {
            type: 'I',
            score: 5,
            content: 'ほとんどの場合、\nその人から声をかけてくれる。',
          },
        ],
      },
      {
        which: 'EI',
        question: ' 週末の過ごし方は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'よく人と会って過ごす。',
          },
          {
            type: 'I',
            score: 5,
            content: '一人で過ごすのも嫌いじゃない。',
          },
        ],
      },
      {
        which: 'EI',
        question: '友達と遊ぶときの自分は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'ワイワイと賑やかな雰囲気が好き。',
          },
          {
            type: 'I',
            score: 5,
            content: '少人数でのんびりおしゃべりすることが好き。',
          },
        ],
      },
      {
        which: 'SN',
        question: '自分がより重要だと思う価値観は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '今を生きないと将来もない。',
          },
          {
            type: 'N',
            score: 5,
            content: '将来のことを考えないと成長もない。',
          },
        ],
      },
      {
        which: 'SN',
        question: '仕事するときの自分は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '人が作ったレールに沿って行きたい。',
          },
          {
            type: 'N',
            score: 5,
            content: '自分だけのやり方を作りたい。',
          },
        ],
      },
      {
        which: 'SN',
        question: '周りの人によく言われる言葉は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '着実で忍耐強いとよく言われる。',
          },
          {
            type: 'N',
            score: 5,
            content: 'ユニークで創造的だとよく言われる。',
          },
        ],
      },
      {
        which: 'TF',
        question: '断らなきゃいけないときはどうする？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'できないものはできないと強く言う。',
          },
          {
            type: 'F',
            score: 5,
            content: '悩んだあげく、\nだいたいは聞いてあげる。',
          },
        ],
      },
      {
        which: 'TF',
        question: '怒ったときの自分は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '論理的に話しつつ問い詰める。',
          },
          {
            type: 'F',
            score: 5,
            content:
              '言いたいことはたくさんあるけど、\n悔しすぎて涙が出てしまう。',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '友達にお悩み相談をされたけど、\nよくよく聞いたらその友達のせいっぽい。\nそういうときの自分は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '友達の悪いところを指摘Ｓる。',
          },
          {
            type: 'F',
            score: 5,
            content: '友達を配慮してオブラートに包んで言ってあげる。',
          },
        ],
      },
      {
        which: 'JP',
        question: '明日の持ち物を用意するときの自分は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '前日から前もっと用意しておいく。',
          },
          {
            type: 'P',
            score: 5,
            content:
              '「明日用意しよう」と思って、\nいざ当日になると忘れてしまうことが多い。',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '終わったらお家に帰って勉強しよう、\nと思っていたら友達に遊びに誘われた。\nどうする？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '計画になかったし、\n非常に困るなぁ。',
          },
          {
            type: 'P',
            score: 5,
            content:
              'オッケー！計画通りにならないのが人生だし！\n遊んじゃおう！',
          },
        ],
      },
      {
        which: 'JP',
        question: 'おおむね自分はこんな感じです！',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '全ては計画したとおり！\n計画通りに物事を進めることが好き。',
          },
          {
            type: 'P',
            score: 5,
            content:
              'その場の思いつきによって物事を進める。\n柔軟に対応することが好き。',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorJP/ESTJ_.png',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorJP/ESTP_.png',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorJP/ESFJ_.png',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorJP/ESFP_.png',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorJP/ENTJ_.png',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorJP/ENTP_.png',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorJP/ENFJ_.png',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorJP/ENFP_.png',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorJP/ISTJ_.png',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorJP/ISTP_.png',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorJP/ISFJ_.png',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorJP/ISFP_.png',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorJP/INTJ_.png',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorJP/INTP_.png',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorJP/INFJ_.png',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColorJP/INFP_.png',
      },
    ],
  },
  // persoanlColor//
  {
    info: {
      mainTitle: '퍼스널 컬러 테스트',
      subTitle: '나에게 어울리는 컬러는 무엇일까?',
      mainUrl: 'personalColor',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/personalColor-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/personalColor-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '처음 보는 친구들에게 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '내가 먼저 말을 건낸다.',
          },
          {
            type: 'I',
            score: 5,
            content: '대부분 다른 친구가 먼저 말을 건낸다. ',
          },
        ],
      },
      {
        which: 'EI',
        question: '주말에 난?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '사람들과 만나는 것을 즐긴다.',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자 있는 게 싫지 않은 편이다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구들과 놀 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '왁자지껄한 신나는 분위기를 좋아한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '소수의 친구들과 소소하게 대화하는 것을 좋아한다. ',
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 더 중요하게 생각하는 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '현재가 없으면 미래도 없다.',
          },
          {
            type: 'N',
            score: 5,
            content: '미래를 생각하지 않으면 발전이 없다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '일할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '남들이 하는 대로 따라 가는 것이 좋다.',
          },
          {
            type: 'N',
            score: 5,
            content: '스스로 나만의 방법을 만드는 게 좋다. ',
          },
        ],
      },
      {
        which: 'SN',
        question: '주변 사람들이 나에게 하는 말은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '꾸준하고 참을성이 있다는 말을 자주한다.',
          },
          {
            type: 'N',
            score: 5,
            content: '창의적이고 독창적이라는 말을 자주한다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '거절해야 하는 상황이 오면 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '강력하게 할 수 없다고 말한다.',
          },
          {
            type: 'F',
            score: 5,
            content: '고민하다가 대부분 들어주는 편이다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '화났을 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '논리적으로 잘 말하면서 따진다. ',
          },
          {
            type: 'F',
            score: 5,
            content: '할말이 많지만 너무 분해서 눈물부터 난다.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '고민을 얘기하는 친구, 듣다 보니 친구의 잘못인 것 같다. 그럴 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '친구의 잘못된 점을 말해준다.',
          },
          {
            type: 'F',
            score: 5,
            content: '직접적으로 말하면 친구가 그래 할까봐 돌려 말한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '준비물을 준비할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '하루 전 날 미리 준비한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '‘내일 챙겨야지’ 하다가 까먹고 놓고 오는 일이 많다.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '끝나고 집에 가서 공부하려 했는데, 친구들이 놀자고 붙잡는다. 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '계획에 없던 일인데…매우 당황스럽다.',
          },
          {
            type: 'P',
            score: 5,
            content: '오케이! 역시 계획대로 안 되는 것이 인생! 놀자!!!!',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 가고싶은 여행지 스타일은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '내가 계획한대로! 계획적으로 일의 순서대로 진행하는 것을 좋아해!',
          },
          {
            type: 'P',
            score: 5,
            content:
              '그때그때 생각나는 대로 일 처리! 융통성 있게 하는 것을 좋아해!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: ``,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: ``,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: ``,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: ``,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: ``,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: ``,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: ``,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: ``,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: ``,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: ``,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: ``,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: ``,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: ``,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor/INTJ.png',
      },
      {
        type: 'INTP',
        desc: ``,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor/INTP.png',
      },
      {
        type: 'INFJ',
        desc: ``,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor/INFJ.png',
      },
      {
        type: 'INFP',
        desc: ``,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/personalColor/INFP.png',
      },
    ],
  },
  // MALINGOETZ
  {
    info: {
      mainTitle: 'MALIN+GOETZ 퍼퓸 코드 테스트',
      subTitle: 'MALIN+GOETZ 퍼퓸 코드 테스트',
      mainUrl: 'MALINGOETZ',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/MALINGOETZ-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/MALINGOETZ-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '새로운 사람들을 만나 친해지는 것에 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '에너지를 얻는 편',
          },
          {
            type: 'I',
            score: 5,
            content: '에너지를 빼앗기는 편',
          },
        ],
      },
      {
        which: 'EI',
        question: '내가 나를 닮은 향수를 고른다면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '활기차고 시원하며 다양한 향이 섞인 향수',
          },
          {
            type: 'I',
            score: 5,
            content: '조용하고 부드러우며 나만의 향이 나는 향수',
          },
        ],
      },
      {
        which: 'EI',
        question: '내 감정을 표현할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `일단 말로 먼저 표현한 후에 생각함`,
          },
          {
            type: 'I',
            score: 5,
            content: `생각을 먼저 정리한 후에 말로 표현한`,
          },
        ],
      },
      {
        which: 'SN',
        question: '나는 무언가를 구매할 때?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `현실적으로 필요한 것만 구매함`,
          },
          {
            type: 'N',
            score: 5,
            content: `지금 안 사서 나중에 후회할 내 모습이 상상되며 추가로 구매함`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 좋아하는 향을 설명할 때 어떨까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '과일이랑 자스민이 섞인 향을 좋아해',
          },
          {
            type: 'N',
            score: 5,
            content: `달콤하면서도 우드 향이 나는 그런 우아한 느낌의 향을 좋아해`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 더 잘 하는 것은 무엇일까?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `기존에 계속하던 일`,
          },
          {
            type: 'N',
            score: 5,
            content: `새롭게 시작하는 일`,
          },
        ],
      },
      {
        which: 'TF',
        question: `뭔가를 판단할 때 내 기준은?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '어떤 게 더 확실하게 맞고 틀린 지의 기준',
          },
          {
            type: 'F',
            score: 5,
            content: '어떤 게 더 좋고 나쁜지의 기준',
          },
        ],
      },
      {
        which: 'TF',
        question: '오늘따라 우울해 보이는 친구를 보면 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `크게 신경 쓰이지 않음`,
          },
          {
            type: 'F',
            score: 5,
            content: '무슨 일 있는지 걱정되고 신경 쓰임',
          },
        ],
      },
      {
        which: 'TF',
        question: `친구들끼리 모여 관심 없는 대화를 나눌 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '듣지 않고 다른 걸 하거나 재미없다고 말함',
          },
          {
            type: 'F',
            score: 5,
            content: '관심 없더라도 듣고 리액션 해줌',
          },
        ],
      },
      {
        which: 'JP',
        question: '대략 큰 일정만 적어둔 계획표를 보면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `이게 어떻게 계획표야.. 적어도 시간별로는 나눠야지..`,
          },
          {
            type: 'P',
            score: 5,
            content: `이 정도면 J 아냐? 충분하지!!`,
          },
        ],
      },
      {
        which: 'JP',
        question: '나는 보통 약속 시간에?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '대부분 늦지 않고 약속 시간 전에 도착한다',
          },
          {
            type: 'P',
            score: 5,
            content: '아슬아슬하게 도착하거나 늦는다',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 한번 결정을 내리면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '결정을 내리면 거기서 끝 번복하지 않음',
          },
          {
            type: 'P',
            score: 5,
            content: '더 나은 대안이 나오면 결정 번복',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `
                `,
        query: 'ESTJ',
        img_src: 'https://images.ktestone.com/resultImages/MALINGOETZ/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: ``,
        query: 'ESTP',
        img_src: 'https://images.ktestone.com/resultImages/MALINGOETZ/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: ``,
        query: 'ESFJ',
        img_src: 'https://images.ktestone.com/resultImages/MALINGOETZ/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: ``,
        query: 'ESFP',
        img_src: 'https://images.ktestone.com/resultImages/MALINGOETZ/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: ``,
        query: 'ENTJ',
        img_src: 'https://images.ktestone.com/resultImages/MALINGOETZ/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `
                `,
        query: 'ENTP',
        img_src: 'https://images.ktestone.com/resultImages/MALINGOETZ/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: ``,
        query: 'ENFJ',
        img_src: 'https://images.ktestone.com/resultImages/MALINGOETZ/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: ``,
        query: 'ENFP',
        img_src: 'https://images.ktestone.com/resultImages/MALINGOETZ/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: ``,
        query: 'ISTJ',
        img_src: 'https://images.ktestone.com/resultImages/MALINGOETZ/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: ``,
        query: 'ISTP',
        img_src: 'https://images.ktestone.com/resultImages/MALINGOETZ/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: ``,
        query: 'ISFJ',
        img_src: 'https://images.ktestone.com/resultImages/MALINGOETZ/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `
                `,
        query: 'ISFP',
        img_src: 'https://images.ktestone.com/resultImages/MALINGOETZ/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: ``,
        query: 'INTJ',
        img_src: 'https://images.ktestone.com/resultImages/MALINGOETZ/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: ``,
        query: 'INTP',
        img_src: 'https://images.ktestone.com/resultImages/MALINGOETZ/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: ``,
        query: 'INFJ',
        img_src: 'https://images.ktestone.com/resultImages/MALINGOETZ/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: ``,
        query: 'INFP',
        img_src: 'https://images.ktestone.com/resultImages/MALINGOETZ/INFP.jpg',
      },
    ],
  },
  // gardenflower
  {
    info: {
      mainTitle: '정원에 핀 성격 테스트',
      subTitle: '내 정원에는 어떤 꽃이 피었을까?',
      mainUrl: 'gardenflower',
      scoreType: 'typeCountingMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/gardenflower-intro.jpg',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/gardenflower-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          '여행 장소인 이탈리아 피렌체에 도착했다!\n두오모 대성당 앞 수많은 사람들을 보고 드는 생각은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '사람 진짜 많다! 역시 유명한 관광지야!\n너무 신나는데?',
          },
          {
            type: 'I',
            score: 5,
            content: '사람이 이렇게 많다고…?\n벌써부터 기 빨린다..',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '피렌체에서 열리는 가든 파티에 초대되었다!\n내가 가고 싶은 파티는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '새로운 사람들과 다 함께 즐길 수 있는 오픈 파티',
          },
          {
            type: 'I',
            score: 5,
            content: '소수의 정예 인원으로 하는 프라이빗 파티',
          },
        ],
      },
      {
        which: 'EI',
        question: '나는 파티에서 주로 어떻게 시간을 보낼까?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `파티의 중심에서 대화와 분위기를 주도한다`,
          },
          {
            type: 'I',
            score: 5,
            content: `같이 온 일행들이랑만 한적한 공간에서 티타임을 즐긴다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '레스토랑에서 파스타를 먹는데 너무 맛있다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `너무 맛있어서 다음에 또 오고 싶다는 생각이 든다`,
          },
          {
            type: 'N',
            score: 5,
            content: `너무 맛있어서 어떻게 만들었을지 레시피를 상상해 본다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '정원에 있는 분수대를 보며 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '여기에 분수대가 있었구나~',
          },
          {
            type: 'N',
            score: 5,
            content: `음.. 왠지 피렌체 정원이 떠오르지 않아?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '주변이 온통 외국인 뿐이라며 신기해하는 친구에게 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `당연히 외국에 왔으니 다 외국인들이지~`,
          },
          {
            type: 'N',
            score: 5,
            content: `그치! 다른 세상에 온 것 같지 않아? 너무 신기해!`,
          },
        ],
      },
      {
        which: 'TF',
        question: `여행 중 친구가 뿌리고 있는 향수 냄새가 너무 좋다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '향수 브랜드 어디 거야? 가격은??',
          },
          {
            type: 'F',
            score: 5,
            content: '이 향 너랑 잘 어울린다~ 나도 사고 싶어!',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '친구가 숙소에서 늦게 나와 미술관 예약 시간을 놓쳤다!\n내가 먼저 듣고 싶은 말은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `늦을 수밖에 없었던 명확한 이유`,
          },
          {
            type: 'F',
            score: 5,
            content: '진심을 담은 미안하다는 사과',
          },
        ],
      },
      {
        which: 'TF',
        question: `피렌체에서 산타 마리아 노벨라 성당을 보며 소감을 이야기할 때 나는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '역시 피렌체의 명소답게 르네상스 건축 양식 요소가 돋보이네\n(관련된 역사 지식을 찾아본다)',
          },
          {
            type: 'F',
            score: 5,
            content: '조화롭고 고귀한 분위기가 느껴져… 너무 아름답다',
          },
        ],
      },
      {
        which: 'JP',
        question: '시간별로 빽빽하게 짜인 여행 일정표를 보면 드는 생각은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `안정감 있고 좋은데?\n최대한 일정에 맞춰서 다니자`,
          },
          {
            type: 'P',
            score: 5,
            content: `꼭 지켜야 하나..\n난 자유롭게 다니고 싶은데ㅜ`,
          },
        ],
      },
      {
        which: 'JP',
        question: '여행지에서 기념품을 구입할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '미리 적어놨던 기념품 목록을 보고 구매함',
          },
          {
            type: 'P',
            score: 5,
            content: '그날 내가 끌리는 기념품을 즉흥으로 구매함',
          },
        ],
      },
      {
        which: 'JP',
        question: '파티에 입고 갈 옷을 고를 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '전날에 미리 날씨와 일정을 고려해 옷을 골라 코디해둠',
          },
          {
            type: 'P',
            score: 5,
            content: '어떤 옷을 입을지 대략 정해두었지만 당일 기분에 따라 바뀜',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/gardenflower/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/gardenflower/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/gardenflower/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/gardenflower/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/gardenflower/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/gardenflower/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/gardenflower/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/gardenflower/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/gardenflower/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/gardenflower/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/gardenflower/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/gardenflower/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/gardenflower/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/gardenflower/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/gardenflower/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/gardenflower/INFP.jpg',
      },
    ],
  },
  // Cyworld
  {
    info: {
      mainTitle: '케이테스트 도토리테스트',
      subTitle: '나의 도토리력은? , 싸이월드테스트, 도토리테스트',
      mainUrl: 'dotori',
      scoreType: 'numberScoringImg',
      mainImage: 'https://images.ktestone.com/introImages/cyworld-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/cyworld-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/dotori.png',
      lang: 'Kor',
      category: 'etc',
    },
    questions: [
      {
        question: '싸이월드의 캐쉬는?',
        answers: [
          {
            type: '포메라니안',
            score: 0,
            content: '도토리묵',
          },
          {
            type: '치와와',
            score: 0,
            content: '밤',
          },
          {
            type: '포메라니안',
            score: 0,
            content: '쿠키',
          },
          {
            type: '치와와',
            score: 1,
            content: '도토리',
          },
        ],
        questionImage: '',
      },
      {
        question: '다음중 미니홈피에서 서로 친구를 맺는 단어는?',
        answers: [
          {
            type: '포메라니안',
            score: 0,
            content: '이종사촌',
          },
          {
            type: '웰시코기',
            score: 0,
            content: '버디버디',
          },
          {
            type: '포메라니안',
            score: 1,
            content: '일촌',
          },
          {
            type: '웰시코기',
            score: 0,
            content: '서이추',
          },
        ],
        questionImage: '',
      },
      {
        question: "'ㅈㅉ ㅈㅅ'의 뜻은 무엇인가?",
        answers: [
          {
            type: '치와와',
            score: 0,
            content: '진짜 죄송합니다',
          },
          {
            type: '웰시코기',
            score: 1,
            content: '전체쪽지 죄송합니다',
          },
          {
            type: '웰시코기',
            score: 0,
            content: '전체쪽지 조심하세요',
          },
        ],
        questionImage: '',
      },
      {
        question: '박태준이 유명해진 이유는?',
        answers: [
          {
            type: '비글',
            score: 0,
            content: '외모지상주의 웹툰',
          },
          {
            type: '리트리버',
            score: 1,
            content: '가캐 주인공이라',
          },
          {
            type: '리트리버',
            score: 0,
            content: '잘생긴 웹툰 작가라서',
          },
        ],
        questionImage: '',
      },
      {
        question: '다음 중 가끔 눈물 흘리는 사람은?',
        answers: [
          {
            type: '리트리버',
            score: 0,
            content: '광수',
          },
          {
            type: '웰시코기',
            score: 0,
            content: '지석진',
          },
          {
            type: '리트리버',
            score: 1,
            content: '채연',
          },
          {
            type: '웰시코기',
            score: 0,
            content: '홍록기',
          },
        ],
        questionImage: '',
      },
      {
        question: '다음중 미니홈피 bgm과 어울리지 않는 노래는?',
        answers: [
          {
            type: '포메라니안',
            score: 0,
            content: '에픽하이 LoveLoveLove',
          },
          {
            type: '비글',
            score: 1,
            content: '방탄소년단 피땀눈물',
          },
          {
            type: '포메라니안',
            score: 0,
            content: '프리스타일 Y',
          },
          {
            type: '비글',
            score: 0,
            content: '엠씨몽 I Love u Oh Thank U',
          },
        ],
        questionImage: '',
      },
      {
        question: '다음중 빈칸에 들어갈 순서로 알맞는 것은?',
        answers: [
          {
            type: '비글',
            score: 0,
            content: '교실, 교복, 출소',
          },
          {
            type: '웰시코기',
            score: 0,
            content: '집, 유니폼, 출소',
          },
          {
            type: '비글',
            score: 1,
            content: '교실, 교복, 석방',
          },
        ],
        questionImage:
          'https://images.ktestone.com/storyImages/cyworld/cyworld_option_7.jpeg',
      },
      {
        question: '다음 사진의 명칭은?',
        answers: [
          {
            type: '치와와',
            score: 0,
            content: '플로피',
          },
          {
            type: '리트리버',
            score: 1,
            content: '플로피디스켓',
          },
          {
            type: '리트리버',
            score: 0,
            content: '디스켓플로피',
          },
        ],
        questionImage:
          'https://images.ktestone.com/storyImages/cyworld/cyworld_option_8.jpeg',
      },
      {
        question: '쥬니어 네이버의 슈 게임이 아닌것은? \n (슈의는 생략)',
        answers: [
          {
            type: '치와와',
            score: 0,
            content: '미용실',
          },
          {
            type: '리트리버',
            score: 0,
            content: '신속배달',
          },
          {
            type: '치와와',
            score: 0,
            content: '라면가게',
          },
          {
            type: '리트리버',
            score: 1,
            content: '동물농장',
          },
        ],
        questionImage: '',
      },
      {
        question: '도토리 한 개의 가격은?',
        answers: [
          {
            type: '치와와',
            score: 0,
            content: '10원',
          },
          {
            type: '리트리버',
            score: 0,
            content: '90원',
          },
          {
            type: '치와와',
            score: 1,
            content: '100원',
          },
          {
            type: '리트리버',
            score: 0,
            content: '110원',
          },
        ],
        questionImage: '',
      },
    ],
    results: [
      {
        type: '20%',
        desc: ``,
        query: 'alemdfhr',
        score_range: range(2),
        img_src:
          'https://images.ktestone.com/resultImages/cyworld/cyworld-1.png',
      },
      {
        type: '40%',
        desc: ``,
        query: 'ghldnjsrkdlq',
        score_range: range(2, 4),
        img_src:
          'https://images.ktestone.com/resultImages/cyworld/cyworld-2.png',
      },
      {
        type: '60%',
        desc: ``,
        query: 'gmrdurtk',
        score_range: range(4, 6),
        img_src:
          'https://images.ktestone.com/resultImages/cyworld/cyworld-3.png',
      },
      {
        type: '80%',
        desc: ``,
        query: 'rkRmasnsanf',
        score_range: range(6, 8),
        img_src:
          'https://images.ktestone.com/resultImages/cyworld/cyworld-4.png',
      },
      {
        type: '100%',
        desc: ``,
        query: 'vmfhaktmxj',
        score_range: range(8, 11),
        img_src:
          'https://images.ktestone.com/resultImages/cyworld/cyworld-5.png',
      },
    ],
  },
  // peopleColor
  {
    info: {
      mainTitle: '퍼스널 컬러 궁합 테스트',
      subTitle: '우리에게 어울리는 컬러는 무엇일까?',
      mainUrl: 'peopleColor',
      scoreType: 'dualMBTI',
      mainImage:
        'https://images.ktestone.com/introImages/peopleColor-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/peopleColor-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/peopleColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '처음 보는 친구들에게 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '내가 먼저 말을 건낸다.',
          },
          {
            type: 'I',
            score: 5,
            content: '대부분 다른 친구가 먼저 말을 건낸다. ',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ENFJENFJ',
        desc: '',
        query: 'ENFJENFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/48/47/SmSkS45c_o.png',
      },
      {
        type: 'ENFJENFP',
        desc: '',
        query: 'ENFJENFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/07/90/BFjToDFl_o.png',
      },
      {
        type: 'ENFJENTJ',
        desc: '',
        query: 'ENFJENTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/8b/c3/SCYb83tN_o.png',
      },
      {
        type: 'ENFJENTP',
        desc: '',
        query: 'ENFJENTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/b4/51/6zjsgIao_o.png',
      },
      {
        type: 'ENFJESFJ',
        desc: '',
        query: 'ENFJESFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/64/33/I3UR8J1i_o.png',
      },
      {
        type: 'ENFJESFP',
        desc: '',
        query: 'ENFJESFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/b1/34/bZEkwLsd_o.png',
      },
      {
        type: 'ENFJESTJ',
        desc: '',
        query: 'ENFJESTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/bc/c1/wJHJE3k2_o.png',
      },
      {
        type: 'ENFJESTP',
        desc: '',
        query: 'ENFJESTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/4a/f7/cIhh5beS_o.png',
      },
      {
        type: 'ENFJINFJ',
        desc: '',
        query: 'ENFJINFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/a0/6e/1ppyBzeh_o.png',
      },
      {
        type: 'ENFJINFP',
        desc: '',
        query: 'ENFJINFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/1c/71/CrtccNKr_o.png',
      },
      {
        type: 'ENFJINTJ',
        desc: '',
        query: 'ENFJINTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/80/09/ENeXRrVn_o.png',
      },
      {
        type: 'ENFJINTP',
        desc: '',
        query: 'ENFJINTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/60/66/jCWrdOES_o.png',
      },
      {
        type: 'ENFJISFJ',
        desc: '',
        query: 'ENFJISFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/fe/02/dnKiyPAe_o.png',
      },
      {
        type: 'ENFJISFP',
        desc: '',
        query: 'ENFJISFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/dd/94/w960F4K8_o.png',
      },
      {
        type: 'ENFJISTJ',
        desc: '',
        query: 'ENFJISTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/28/4e/5T1h8VEP_o.png',
      },
      {
        type: 'ENFJISTP',
        desc: '',
        query: 'ENFJISTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/48/18/2SqzfNg2_o.png',
      },
      {
        type: 'ENFPENFJ',
        desc: '',
        query: 'ENFPENFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/0f/fa/7QmkNI9G_o.png',
      },
      {
        type: 'ENFPENFP',
        desc: '',
        query: 'ENFPENFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/63/2b/kjhkZayy_o.png',
      },
      {
        type: 'ENFPENTJ',
        desc: '',
        query: 'ENFPENTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/0e/fc/GsmAJ585_o.png',
      },
      {
        type: 'ENFPENTP',
        desc: '',
        query: 'ENFPENTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/32/26/pG2aJHHz_o.png',
      },
      {
        type: 'ENFPESFJ',
        desc: '',
        query: 'ENFPESFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/66/cf/nAxBF11G_o.png',
      },
      {
        type: 'ENFPESFP',
        desc: '',
        query: 'ENFPESFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/48/f6/uTqr3jm1_o.png',
      },
      {
        type: 'ENFPESTJ',
        desc: '',
        query: 'ENFPESTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/98/b6/oUJyb1d0_o.png',
      },
      {
        type: 'ENFPESTP',
        desc: '',
        query: 'ENFPESTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/c2/5a/JKidTl2U_o.png',
      },
      {
        type: 'ENFPINFJ',
        desc: '',
        query: 'ENFPINFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/8e/01/acsdQV0M_o.png',
      },
      {
        type: 'ENFPINFP',
        desc: '',
        query: 'ENFPINFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/99/ab/CGY7n2zQ_o.png',
      },
      {
        type: 'ENFPINTJ',
        desc: '',
        query: 'ENFPINTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/a8/a1/4k8iJkyk_o.png',
      },
      {
        type: 'ENFPINTP',
        desc: '',
        query: 'ENFPINTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/8a/9b/E6O3cdj6_o.png',
      },
      {
        type: 'ENFPISFJ',
        desc: '',
        query: 'ENFPISFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/26/7f/GDAHt265_o.png',
      },
      {
        type: 'ENFPISFP',
        desc: '',
        query: 'ENFPISFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/08/c8/HBaqOZHu_o.png',
      },
      {
        type: 'ENFPISTJ',
        desc: '',
        query: 'ENFPISTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/6e/5c/erp0gcrD_o.png',
      },
      {
        type: 'ENFPISTP',
        desc: '',
        query: 'ENFPISTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/1e/d2/9LVjdaSK_o.png',
      },
      {
        type: 'ENTJENFJ',
        desc: '',
        query: 'ENTJENFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/32/44/znqVZzUp_o.png',
      },
      {
        type: 'ENTJENFP',
        desc: '',
        query: 'ENTJENFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/74/0c/CGSirPJc_o.png',
      },
      {
        type: 'ENTJENTJ',
        desc: '',
        query: 'ENTJENTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/9a/4f/oH5rKjN1_o.png',
      },
      {
        type: 'ENTJENTP',
        desc: '',
        query: 'ENTJENTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/9b/7f/yTiZxSOM_o.png',
      },
      {
        type: 'ENTJESFJ',
        desc: '',
        query: 'ENTJESFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/12/58/hmnhOSg7_o.png',
      },
      {
        type: 'ENTJESFP',
        desc: '',
        query: 'ENTJESFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/8e/8c/5Gnt0sN4_o.png',
      },
      {
        type: 'ENTJESTJ',
        desc: '',
        query: 'ENTJESTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/57/74/OH3FrKRb_o.png',
      },
      {
        type: 'ENTJESTP',
        desc: '',
        query: 'ENTJESTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/3d/84/odDGKuOA_o.png',
      },
      {
        type: 'ENTJINFJ',
        desc: '',
        query: 'ENTJINFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/65/d3/RfaUXAnf_o.png',
      },
      {
        type: 'ENTJINFP',
        desc: '',
        query: 'ENTJINFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/78/71/O1CHydaL_o.png',
      },
      {
        type: 'ENTJINTJ',
        desc: '',
        query: 'ENTJINTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/89/f9/WW4Wtie1_o.png',
      },
      {
        type: 'ENTJINTP',
        desc: '',
        query: 'ENTJINTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/1c/5d/WshQ4ztg_o.png',
      },
      {
        type: 'ENTJISFJ',
        desc: '',
        query: 'ENTJISFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/fc/0c/YD474g0P_o.png',
      },
      {
        type: 'ENTJISFP',
        desc: '',
        query: 'ENTJISFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/24/62/7238W08Z_o.png',
      },
      {
        type: 'ENTJISTJ',
        desc: '',
        query: 'ENTJISTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/5d/bc/nNOkRa9T_o.png',
      },
      {
        type: 'ENTJISTP',
        desc: '',
        query: 'ENTJISTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/92/26/ZNsDlmUB_o.png',
      },
      {
        type: 'ENTPENFJ',
        desc: '',
        query: 'ENTPENFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/e7/a9/smf5Qkhd_o.png',
      },
      {
        type: 'ENTPENFP',
        desc: '',
        query: 'ENTPENFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/ea/6f/v1l6d6Xb_o.png',
      },
      {
        type: 'ENTPENTJ',
        desc: '',
        query: 'ENTPENTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/90/d6/kQcwcDjg_o.png',
      },
      {
        type: 'ENTPENTP',
        desc: '',
        query: 'ENTPENTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/da/51/ikNA7T0A_o.png',
      },
      {
        type: 'ENTPESFJ',
        desc: '',
        query: 'ENTPESFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/ce/de/JIxXPi26_o.png',
      },
      {
        type: 'ENTPESFP',
        desc: '',
        query: 'ENTPESFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/1f/9c/oDyKN5ZA_o.png',
      },
      {
        type: 'ENTPESTJ',
        desc: '',
        query: 'ENTPESTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/b9/b9/3M7V1R4c_o.png',
      },
      {
        type: 'ENTPESTP',
        desc: '',
        query: 'ENTPESTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/c8/c8/8HaLzGSg_o.png',
      },
      {
        type: 'ENTPINFJ',
        desc: '',
        query: 'ENTPINFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/20/b5/ZnQXDZrw_o.png',
      },
      {
        type: 'ENTPINFP',
        desc: '',
        query: 'ENTPINFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/2d/d1/zLnw2b7F_o.png',
      },
      {
        type: 'ENTPINTJ',
        desc: '',
        query: 'ENTPINTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/95/15/WLZ5O5W2_o.png',
      },
      {
        type: 'ENTPINTP',
        desc: '',
        query: 'ENTPINTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/1f/d4/oYUURy4G_o.png',
      },
      {
        type: 'ENTPISFJ',
        desc: '',
        query: 'ENTPISFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/91/e7/CHZJGLea_o.png',
      },
      {
        type: 'ENTPISFP',
        desc: '',
        query: 'ENTPISFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/db/07/NYY6tJMq_o.png',
      },
      {
        type: 'ENTPISTJ',
        desc: '',
        query: 'ENTPISTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/5a/d0/xS72ioyO_o.png',
      },
      {
        type: 'ENTPISTP',
        desc: '',
        query: 'ENTPISTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/af/3c/5P1TlROr_o.png',
      },
      {
        type: 'ESFJENFJ',
        desc: '',
        query: 'ESFJENFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/52/b9/SzId3Xxy_o.png',
      },
      {
        type: 'ESFJENFP',
        desc: '',
        query: 'ESFJENFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/e1/c3/Enee0zej_o.png',
      },
      {
        type: 'ESFJENTJ',
        desc: '',
        query: 'ESFJENTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/59/58/mlC61DCj_o.png',
      },
      {
        type: 'ESFJENTP',
        desc: '',
        query: 'ESFJENTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/71/e7/iubqVkjY_o.png',
      },
      {
        type: 'ESFJESFJ',
        desc: '',
        query: 'ESFJESFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/6c/78/iCbLbk3z_o.png',
      },
      {
        type: 'ESFJESFP',
        desc: '',
        query: 'ESFJESFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/fb/7d/4CgWzgaM_o.png',
      },
      {
        type: 'ESFJESTJ',
        desc: '',
        query: 'ESFJESTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/03/cf/Yv5J7djk_o.png',
      },
      {
        type: 'ESFJESTP',
        desc: '',
        query: 'ESFJESTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/90/bd/DBc5gTNJ_o.png',
      },
      {
        type: 'ESFJINFJ',
        desc: '',
        query: 'ESFJINFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/57/f9/kgxb2ypz_o.png',
      },
      {
        type: 'ESFJINFP',
        desc: '',
        query: 'ESFJINFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/07/75/4khg8s4A_o.png',
      },
      {
        type: 'ESFJINTJ',
        desc: '',
        query: 'ESFJINTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/5c/4b/6FG9fBHA_o.png',
      },
      {
        type: 'ESFJINTP',
        desc: '',
        query: 'ESFJINTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/d0/01/2OrPAGxK_o.png',
      },
      {
        type: 'ESFJISFJ',
        desc: '',
        query: 'ESFJISFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/1e/5b/3UIXm8g0_o.png',
      },
      {
        type: 'ESFJISFP',
        desc: '',
        query: 'ESFJISFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/4d/d0/ZPGHCTCm_o.png',
      },
      {
        type: 'ESFJISTJ',
        desc: '',
        query: 'ESFJISTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/7e/3d/ewMDgBCU_o.png',
      },
      {
        type: 'ESFJISTP',
        desc: '',
        query: 'ESFJISTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/7a/16/JNdn7J4M_o.png',
      },
      {
        type: 'ESFPENFJ',
        desc: '',
        query: 'ESFPENFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/8e/b5/Kt73B0no_o.png',
      },
      {
        type: 'ESFPENFP',
        desc: '',
        query: 'ESFPENFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/da/2e/va03wgTx_o.png',
      },
      {
        type: 'ESFPENTJ',
        desc: '',
        query: 'ESFPENTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/08/c1/BycOPVzc_o.png',
      },
      {
        type: 'ESFPENTP',
        desc: '',
        query: 'ESFPENTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/cb/f4/wyLCmvCu_o.png',
      },
      {
        type: 'ESFPESFJ',
        desc: '',
        query: 'ESFPESFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/05/46/adxkK0bN_o.png',
      },
      {
        type: 'INTJINTJ',
        desc: '',
        query: 'INTJINTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/73/55/qVw9dV2U_o.png',
      },
      {
        type: 'INTJINTP',
        desc: '',
        query: 'INTJINTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/62/2f/iM1hHZZF_o.png',
      },
      {
        type: 'INTJISFJ',
        desc: '',
        query: 'INTJISFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/33/da/8ianBhcr_o.png',
      },
      {
        type: 'INTJISFP',
        desc: '',
        query: 'INTJISFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/74/e3/UaK0b4dH_o.png',
      },
      {
        type: 'INTJISTJ',
        desc: '',
        query: 'INTJISTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/aa/56/SZZElC3g_o.png',
      },
      {
        type: 'INTJISTP',
        desc: '',
        query: 'INTJISTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/24/8e/eBZuNo0s_o.png',
      },
      {
        type: 'INTPENFJ',
        desc: '',
        query: 'INTPENFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/a0/84/ZaDHjW0R_o.png',
      },
      {
        type: 'INTPENFP',
        desc: '',
        query: 'INTPENFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/a0/f2/bfoygVrt_o.png',
      },
      {
        type: 'INTPENTJ',
        desc: '',
        query: 'INTPENTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/7e/30/SfctuFL8_o.png',
      },
      {
        type: 'INTPENTP',
        desc: '',
        query: 'INTPENTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/f7/f8/d5npwAId_o.png',
      },
      {
        type: 'INTPESFJ',
        desc: '',
        query: 'INTPESFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/af/2b/vJlLtr0n_o.png',
      },
      {
        type: 'INTPESFP',
        desc: '',
        query: 'INTPESFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/c3/ec/s2WZS7XT_o.png',
      },
      {
        type: 'INTPESTJ',
        desc: '',
        query: 'INTPESTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/1c/23/1pYKTN6s_o.png',
      },
      {
        type: 'INTPESTP',
        desc: '',
        query: 'INTPESTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/6f/76/2RkGm25y_o.png',
      },
      {
        type: 'INTPINFJ',
        desc: '',
        query: 'INTPINFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/9e/f7/PTLlcQw8_o.png',
      },
      {
        type: 'INTPINFP',
        desc: '',
        query: 'INTPINFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/f5/9a/tViS9cgb_o.png',
      },
      {
        type: 'INTPINTJ',
        desc: '',
        query: 'INTPINTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/bd/4a/xnUduVfw_o.png',
      },
      {
        type: 'INTPINTP',
        desc: '',
        query: 'INTPINTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/fc/69/Qj5e6TAo_o.png',
      },
      {
        type: 'INTPISFJ',
        desc: '',
        query: 'INTPISFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/19/8e/1W7wZMRj_o.png',
      },
      {
        type: 'INTPISFP',
        desc: '',
        query: 'INTPISFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/ab/27/fysq7vNu_o.png',
      },
      {
        type: 'INTPISTJ',
        desc: '',
        query: 'INTPISTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/4d/50/kXUgxgX5_o.png',
      },
      {
        type: 'INTPISTP',
        desc: '',
        query: 'INTPISTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/09/bd/MEcV9UrD_o.png',
      },
      {
        type: 'ISFJENFJ',
        desc: '',
        query: 'ISFJENFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/ff/05/qbjhcHhi_o.png',
      },
      {
        type: 'ISFJENFP',
        desc: '',
        query: 'ISFJENFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/80/73/tkCN9KOj_o.png',
      },
      {
        type: 'ISFJENTJ',
        desc: '',
        query: 'ISFJENTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/d0/8c/13Bd0SMI_o.png',
      },
      {
        type: 'ISFJENTP',
        desc: '',
        query: 'ISFJENTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/6b/66/33a56SN4_o.png',
      },
      {
        type: 'ISFJESFJ',
        desc: '',
        query: 'ISFJESFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/5a/90/Vsks390c_o.png',
      },
      {
        type: 'ISFJESFP',
        desc: '',
        query: 'ISFJESFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/53/ac/VjTl3w9P_o.png',
      },
      {
        type: 'ISFJESTJ',
        desc: '',
        query: 'ISFJESTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/b3/4f/1gj2tY6O_o.png',
      },
      {
        type: 'ISFJESTP',
        desc: '',
        query: 'ISFJESTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/13/e2/2HlWcnvy_o.png',
      },
      {
        type: 'ISFJINFJ',
        desc: '',
        query: 'ISFJINFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/b3/4f/1gj2tY6O_o.png',
      },
      {
        type: 'ISFJINFP',
        desc: '',
        query: 'ISFJINFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/cc/a8/WD7rbn5R_o.png',
      },
      {
        type: 'ISFJINTJ',
        desc: '',
        query: 'ISFJINTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/13/6b/7nUsJUQ4_o.png',
      },
      {
        type: 'ISFJINTP',
        desc: '',
        query: 'ISFJINTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/fd/bc/h9tg4lMx_o.png',
      },
      {
        type: 'ISFJISFJ',
        desc: '',
        query: 'ISFJISFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/83/33/L5vFO8b4_o.png',
      },
      {
        type: 'ISFJISFP',
        desc: '',
        query: 'ISFJISFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/38/3a/4VrYBZoh_o.png',
      },
      {
        type: 'ISFJISTJ',
        desc: '',
        query: 'ISFJISTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/53/d2/ntUIrK3Q_o.png',
      },
      {
        type: 'ISFJISTP',
        desc: '',
        query: 'ISFJISTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/c0/45/NRssoaUT_o.png',
      },
      {
        type: 'ISFPENFJ',
        desc: '',
        query: 'ISFPENFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/ba/5e/Fz3SlVeu_o.png',
      },
      {
        type: 'ISFPENFP',
        desc: '',
        query: 'ISFPENFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/1e/1e/20o9E3Ro_o.png',
      },
      {
        type: 'ISFPENTJ',
        desc: '',
        query: 'ISFPENTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/68/f8/Yl1C6paf_o.png',
      },
      {
        type: 'ISFPENTP',
        desc: '',
        query: 'ISFPENTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/b6/09/WGv41c3y_o.png',
      },
      {
        type: 'ISFPESFJ',
        desc: '',
        query: 'ISFPESFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/2d/d2/tesmQZzM_o.png',
      },
      {
        type: 'ISFPESFP',
        desc: '',
        query: 'ISFPESFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/e7/f5/vkVQKYbf_o.png',
      },
      {
        type: 'ISFPESTJ',
        desc: '',
        query: 'ISFPESTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/62/5e/5jAklNyJ_o.png',
      },
      {
        type: 'ISFPESTP',
        desc: '',
        query: 'ISFPESTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/f1/6b/AZApQvqe_o.png',
      },
      {
        type: 'ISFPINFJ',
        desc: '',
        query: 'ISFPINFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/c6/88/VuMiRfEn_o.png',
      },
      {
        type: 'ISFPINFP',
        desc: '',
        query: 'ISFPINFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/6a/35/i878ewsu_o.png',
      },
      {
        type: 'ISFPINTJ',
        desc: '',
        query: 'ISFPINTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/95/72/vF5somU5_o.png',
      },
      {
        type: 'ISFPINTP',
        desc: '',
        query: 'ISFPINTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/46/1d/8XCkNf8W_o.png',
      },
      {
        type: 'ISFPISFJ',
        desc: '',
        query: 'ISFPISFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/34/85/18Z4NtzQ_o.png',
      },
      {
        type: 'ISFPISFP',
        desc: '',
        query: 'ISFPISFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/c5/e3/G0NLO9Yn_o.png',
      },
      {
        type: 'ISFPISTJ',
        desc: '',
        query: 'ISFPISTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/e7/cf/GGiA5GFc_o.png',
      },
      {
        type: 'ISFPISTP',
        desc: '',
        query: 'ISFPISTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/e3/e0/gLRrYeBz_o.png',
      },
      {
        type: 'ISTJENFJ',
        desc: '',
        query: 'ISTJENFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/d0/95/cBFJ36pX_o.png',
      },
      {
        type: 'ISTJENFP',
        desc: '',
        query: 'ISTJENFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/a9/af/wMR7fFOa_o.png',
      },
      {
        type: 'ISTJENTJ',
        desc: '',
        query: 'ISTJENTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/99/83/M6b507AY_o.png',
      },
      {
        type: 'ISTJENTP',
        desc: '',
        query: 'ISTJENTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/b8/5a/rqL5VdRC_o.png',
      },
      {
        type: 'ISTJESFJ',
        desc: '',
        query: 'ISTJESFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/d6/3c/1BEr8bod_o.png',
      },
      {
        type: 'ISTJESFP',
        desc: '',
        query: 'ISTJESFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/d5/89/wCUAdCv2_o.png',
      },
      {
        type: 'ISTJESTJ',
        desc: '',
        query: 'ISTJESTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/72/68/GxHYqx8y_o.png',
      },
      {
        type: 'ISTJESTP',
        desc: '',
        query: 'ISTJESTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/7e/db/YWHsIomL_o.png',
      },
      {
        type: 'ISTJINFJ',
        desc: '',
        query: 'ISTJINFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/4b/9b/52bXn5Qi_o.png',
      },
      {
        type: 'ISTJINFP',
        desc: '',
        query: 'ISTJINFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/5b/a0/ReQdzcTg_o.png',
      },
      {
        type: 'ISTJINTJ',
        desc: '',
        query: 'ISTJINTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/6e/28/RiR69DMB_o.png',
      },
      {
        type: 'ISTJINTP',
        desc: '',
        query: 'ISTJINTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/14/1b/dhorX23t_o.png',
      },
      {
        type: 'ISTJISFJ',
        desc: '',
        query: 'ISTJISFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/77/89/bnyff17X_o.png',
      },
      {
        type: 'ISTJISFP',
        desc: '',
        query: 'ISTJISFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/90/e7/P3uUBDEJ_o.png',
      },
      {
        type: 'ISTJISTJ',
        desc: '',
        query: 'ISTJISTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/b3/e9/i4nZk2Dw_o.png',
      },
      {
        type: 'ISTJISTP',
        desc: '',
        query: 'ISTJISTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/fd/55/4CaNssLJ_o.png',
      },
      {
        type: 'ISTPENFJ',
        desc: '',
        query: 'ISTPENFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/a2/fc/IgGE5ail_o.png',
      },
      {
        type: 'ISTPENFP',
        desc: '',
        query: 'ISTPENFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/35/39/TDQVJOnT_o.png',
      },
      {
        type: 'ISTPENTJ',
        desc: '',
        query: 'ISTPENTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/0a/28/BtshzsDa_o.png',
      },
      {
        type: 'ISTPENTP',
        desc: '',
        query: 'ISTPENTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/f3/3d/aPY9iYVt_o.png',
      },
      {
        type: 'ISTPESFJ',
        desc: '',
        query: 'ISTPESFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/da/b9/n3BrX9ut_o.png',
      },
      {
        type: 'ISTPESFP',
        desc: '',
        query: 'ISTPESFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/be/75/Mo8Yn50v_o.png',
      },
      {
        type: 'ISTPESTJ',
        desc: '',
        query: 'ISTPESTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/bb/5e/VScimGcn_o.png',
      },
      {
        type: 'ISTPESTP',
        desc: '',
        query: 'ISTPESTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/11/5b/RCUUBHUc_o.png',
      },
      {
        type: 'ISTPINFJ',
        desc: '',
        query: 'ISTPINFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/26/17/pOfTM06i_o.png',
      },
      {
        type: 'ISTPINFP',
        desc: '',
        query: 'ISTPINFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/6b/84/ItJckarI_o.png',
      },
      {
        type: 'ISTPINTJ',
        desc: '',
        query: 'ISTPINTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/c8/7f/XUkn8Cdo_o.png',
      },
      {
        type: 'ISTPINTP',
        desc: '',
        query: 'ISTPINTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/f6/84/nAae2vnJ_o.png',
      },
      {
        type: 'ISTPISFJ',
        desc: '',
        query: 'ISTPISFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/e5/f5/pQKYtvCc_o.png',
      },
      {
        type: 'ISTPISFP',
        desc: '',
        query: 'ISTPISFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/70/2b/5ei47CC8_o.png',
      },
      {
        type: 'ISTPISTJ',
        desc: '',
        query: 'ISTPISTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/f9/75/lp7QwCbV_o.png',
      },
      {
        type: 'ISTPISTP',
        desc: '',
        query: 'ISTPISTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/d9/67/ieVg5IW0_o.png',
      },
      {
        type: 'ESFPESFP',
        desc: '',
        query: 'ESFPESFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/6b/b5/LmLn8T1t_o.png',
      },
      {
        type: 'ESFPESTJ',
        desc: '',
        query: 'ESFPESTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/93/a8/Yn9NeuZC_o.png',
      },
      {
        type: 'ESFPESTP',
        desc: '',
        query: 'ESFPESTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/54/29/G52uIjfC_o.png',
      },
      {
        type: 'ESFPINFJ',
        desc: '',
        query: 'ESFPINFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/61/ec/BVZvLqiV_o.png',
      },
      {
        type: 'ESFPINFP',
        desc: '',
        query: 'ESFPINFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/e0/99/dTyPeNGJ_o.png',
      },
      {
        type: 'ESFPINTJ',
        desc: '',
        query: 'ESFPINTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/ab/bf/dj26DznQ_o.png',
      },
      {
        type: 'ESFPINTP',
        desc: '',
        query: 'ESFPINTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/18/df/AfqJf6Wr_o.png',
      },
      {
        type: 'ESFPISFJ',
        desc: '',
        query: 'ESFPISFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/4a/60/Sji6vBsA_o.png',
      },
      {
        type: 'ESFPISFP',
        desc: '',
        query: 'ESFPISFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/a5/9a/CVQSstW0_o.png',
      },
      {
        type: 'ESFPISTJ',
        desc: '',
        query: 'ESFPISTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/32/49/8Aodjynk_o.png',
      },
      {
        type: 'ESFPISTP',
        desc: '',
        query: 'ESFPISTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/d5/02/ZG8nc2wP_o.png',
      },
      {
        type: 'ESTJENFJ',
        desc: '',
        query: 'ESTJENFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/4b/ea/tdWmQrhA_o.png',
      },
      {
        type: 'ESTJENFP',
        desc: '',
        query: 'ESTJENFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/1d/00/JQCYziUW_o.png',
      },
      {
        type: 'ESTJENTJ',
        desc: '',
        query: 'ESTJENTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/dd/e4/q80A2bWz_o.png',
      },
      {
        type: 'ESTJENTP',
        desc: '',
        query: 'ESTJENTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/7f/d9/AdWQD5jb_o.png',
      },
      {
        type: 'ESTJESFJ',
        desc: '',
        query: 'ESTJESFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/5c/46/en0auJhE_o.png',
      },
      {
        type: 'ESTJESFP',
        desc: '',
        query: 'ESTJESFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/7e/63/AzqXao4G_o.png',
      },
      {
        type: 'ESTJESTJ',
        desc: '',
        query: 'ESTJESTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/15/fc/uVs9GqSO_o.png',
      },
      {
        type: 'ESTJESTP',
        desc: '',
        query: 'ESTJESTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/92/12/EGrE1mgq_o.png',
      },
      {
        type: 'ESTJINFJ',
        desc: '',
        query: 'ESTJINFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/21/c5/DziKD2kN_o.png',
      },
      {
        type: 'ESTJINFP',
        desc: '',
        query: 'ESTJINFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/5d/0c/IlH5TmoH_o.png',
      },
      {
        type: 'ESTJINTJ',
        desc: '',
        query: 'ESTJINTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/42/4e/hnXqJbY1_o.png',
      },
      {
        type: 'ESTJINTP',
        desc: '',
        query: 'ESTJINTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/44/2c/Rs2ADbtK_o.png',
      },
      {
        type: 'ESTJISFJ',
        desc: '',
        query: 'ESTJISFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/a5/e4/ezGRC0uc_o.png',
      },
      {
        type: 'ESTJISFP',
        desc: '',
        query: 'ESTJISFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/c8/0a/yyrRq762_o.png',
      },
      {
        type: 'ESTJISTJ',
        desc: '',
        query: 'ESTJISTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/fd/64/0ED0GmkP_o.png',
      },
      {
        type: 'ESTJISTP',
        desc: '',
        query: 'ESTJISTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/64/cd/gejiWIiy_o.png',
      },
      {
        type: 'ESTPENFJ',
        desc: '',
        query: 'ESTPENFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/7c/e2/Ye2OS7Ud_o.png',
      },
      {
        type: 'ESTPENFP',
        desc: '',
        query: 'ESTPENFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/03/74/qsdAhSXU_o.png',
      },
      {
        type: 'ESTPENTJ',
        desc: '',
        query: 'ESTPENTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/2e/e7/Lv2UWgCv_o.png',
      },
      {
        type: 'ESTPENTP',
        desc: '',
        query: 'ESTPENTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/19/1c/RPozTENf_o.png',
      },
      {
        type: 'ESTPESFJ',
        desc: '',
        query: 'ESTPESFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/2a/92/eSf0QgsQ_o.png',
      },
      {
        type: 'ESTPESFP',
        desc: '',
        query: 'ESTPESFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/05/43/bMfIWvcc_o.png',
      },
      {
        type: 'ESTPESTJ',
        desc: '',
        query: 'ESTPESTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/53/4b/DthRm4b4_o.png',
      },
      {
        type: 'ESTPESTP',
        desc: '',
        query: 'ESTPESTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/0c/c3/w17H3B0n_o.png',
      },
      {
        type: 'ESTPINFJ',
        desc: '',
        query: 'ESTPINFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/fa/d2/j6G6MT0p_o.png',
      },
      {
        type: 'ESTPINFP',
        desc: '',
        query: 'ESTPINFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/c3/97/BYUn0Rnf_o.png',
      },
      {
        type: 'ESTPINTJ',
        desc: '',
        query: 'ESTPINTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/6d/a3/3Te4b1WG_o.png',
      },
      {
        type: 'ESTPINTP',
        desc: '',
        query: 'ESTPINTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/8f/e8/wx0XMta2_o.png',
      },
      {
        type: 'ESTPISFJ',
        desc: '',
        query: 'ESTPISFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/92/7b/16QClwaA_o.png',
      },
      {
        type: 'ESTPISFP',
        desc: '',
        query: 'ESTPISFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/ab/83/arVOcb1P_o.png',
      },
      {
        type: 'ESTPISTJ',
        desc: '',
        query: 'ESTPISTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/1e/18/AhRiZHoZ_o.png',
      },
      {
        type: 'ESTPISTP',
        desc: '',
        query: 'ESTPISTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/c4/69/5sZOVL0a_o.png',
      },
      {
        type: 'INFJENFJ',
        desc: '',
        query: 'INFJENFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/6e/da/20CvAvyn_o.png',
      },
      {
        type: 'INFJENFP',
        desc: '',
        query: 'INFJENFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/b7/90/IAfsWQDx_o.png',
      },
      {
        type: 'INFJENTJ',
        desc: '',
        query: 'INFJENTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/33/96/5Csaquk5_o.png',
      },
      {
        type: 'INFJENTP',
        desc: '',
        query: 'INFJENTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/ca/44/CUhGVy9h_o.png',
      },
      {
        type: 'INFJESFJ',
        desc: '',
        query: 'INFJESFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/71/17/tjuluqPl_o.png',
      },
      {
        type: 'INFJESFP',
        desc: '',
        query: 'INFJESFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/85/05/i9Cmgzn4_o.png',
      },
      {
        type: 'INFJESTJ',
        desc: '',
        query: 'INFJESTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/65/6e/tREdLhrs_o.png',
      },
      {
        type: 'INFJESTP',
        desc: '',
        query: 'INFJESTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/43/17/HewmxUfG_o.png',
      },
      {
        type: 'INFJINFJ',
        desc: '',
        query: 'INFJINFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/f3/cc/7fgKu36s_o.png',
      },
      {
        type: 'INFJINFP',
        desc: '',
        query: 'INFJINFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/71/4d/Ve4A7Dfi_o.png',
      },
      {
        type: 'INFJINTJ',
        desc: '',
        query: 'INFJINTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/52/96/7Ng7quF8_o.png',
      },
      {
        type: 'INFJINTP',
        desc: '',
        query: 'INFJINTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/d5/ab/oNDMutgx_o.png',
      },
      {
        type: 'INFJISFJ',
        desc: '',
        query: 'INFJISFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/2d/26/3LBhotDY_o.png',
      },
      {
        type: 'INFJISFP',
        desc: '',
        query: 'INFJISFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/20/e3/4wlvwU4X_o.png',
      },
      {
        type: 'INFJISTJ',
        desc: '',
        query: 'INFJISTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/fd/4e/5mBxtGZZ_o.png',
      },
      {
        type: 'INFJISTP',
        desc: '',
        query: 'INFJISTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/4f/ab/R4t1iXZ3_o.png',
      },
      {
        type: 'INFPENFJ',
        desc: '',
        query: 'INFPENFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/ef/69/jHNKtPXg_o.png',
      },
      {
        type: 'INFPENFP',
        desc: '',
        query: 'INFPENFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/3a/6c/37oehQdA_o.png',
      },
      {
        type: 'INFPENTJ',
        desc: '',
        query: 'INFPENTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/2b/79/j4yFwCV4_o.png',
      },
      {
        type: 'INFPENTP',
        desc: '',
        query: 'INFPENTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/f4/03/iehkdApO_o.png',
      },
      {
        type: 'INFPESFJ',
        desc: '',
        query: 'INFPESFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/b0/e4/ouRZ7Sy6_o.png',
      },
      {
        type: 'INFPESFP',
        desc: '',
        query: 'INFPESFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/01/84/j3seR5io_o.png',
      },
      {
        type: 'INFPESTJ',
        desc: '',
        query: 'INFPESTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/fd/2c/qmAq2Bey_o.png',
      },
      {
        type: 'INFPESTP',
        desc: '',
        query: 'INFPESTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/62/a2/YXlkfFVL_o.png',
      },
      {
        type: 'INFPINFJ',
        desc: '',
        query: 'INFPINFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/d3/b9/7DzDXsF9_o.png',
      },
      {
        type: 'INFPINFP',
        desc: '',
        query: 'INFPINFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/e1/c2/P1Xo0ITU_o.png',
      },
      {
        type: 'INFPINTJ',
        desc: '',
        query: 'INFPINTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/e5/5c/AQVYeveF_o.png',
      },
      {
        type: 'INFPINTP',
        desc: '',
        query: 'INFPINTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/9d/e8/szVIjgik_o.png',
      },
      {
        type: 'INFPISFJ',
        desc: '',
        query: 'INFPISFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/a3/ab/EVPreGWc_o.png',
      },
      {
        type: 'INFPISFP',
        desc: '',
        query: 'INFPISFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/a0/2f/EJdjXHpI_o.png',
      },
      {
        type: 'INFPISTJ',
        desc: '',
        query: 'INFPISTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/c4/c3/5mqmYa84_o.png',
      },
      {
        type: 'INFPISTP',
        desc: '',
        query: 'INFPISTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/c7/12/zcAFIHal_o.png',
      },
      {
        type: 'INTJENFJ',
        desc: '',
        query: 'INTJENFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/3c/c4/Ed7RIAae_o.png',
      },
      {
        type: 'INTJENFP',
        desc: '',
        query: 'INTJENFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/21/87/daeay6Dd_o.png',
      },
      {
        type: 'INTJENTJ',
        desc: '',
        query: 'INTJENTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/06/3e/1aYwER7z_o.png',
      },
      {
        type: 'INTJENTP',
        desc: '',
        query: 'INTJENTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/f8/4c/JAx6Kx7n_o.png',
      },
      {
        type: 'INTJESFJ',
        desc: '',
        query: 'INTJESFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/bb/8a/wpuLwCd6_o.png',
      },
      {
        type: 'INTJESFP',
        desc: '',
        query: 'INTJESFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/8f/b6/7Y8vlWl3_o.png',
      },
      {
        type: 'INTJESTJ',
        desc: '',
        query: 'INTJESTJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/40/5e/gFJw4qFu_o.png',
      },
      {
        type: 'INTJESTP',
        desc: '',
        query: 'INTJESTP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/0b/41/SEScECq0_o.png',
      },
      {
        type: 'INTJINFJ',
        desc: '',
        query: 'INTJINFJ',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/d3/1c/fIgRSTVT_o.png',
      },
      {
        type: 'INTJINFP',
        desc: '',
        query: 'INTJINFP',
        score_range: range(0, 26),
        img_src: 'https://images2.imgbox.com/b8/6a/hpTeIuvC_o.png',
      },
    ],
  },
  // otherLookMe
  {
    info: {
      mainTitle: '남들이 보는 나는?',
      subTitle: '남들이 보는 나는 과연 어떤 모습일까?',
      mainUrl: 'otherlookme',
      scoreType: 'numberScoring',
      mainImage:
        'https://images.ktestone.com/introImages/otherlookme-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/otherlookme-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/otherlookme.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        question: '내가 가장 좋아하는 색깔은?',
        answers: [
          {
            type: '사자',
            score: 1,
            content: '갈색 or 회색',
          },
          {
            type: '너구리',
            score: 2,
            content: '화이트',
          },
          {
            type: '펭귄',
            score: 3,
            content: '진파랑 or 보라',
          },
          {
            type: '사자',
            score: 4,
            content: '녹색',
          },
          {
            type: '너구리',
            score: 5,
            content: '노랑 or 연파랑',
          },
          {
            type: '펭귄',
            score: 6,
            content: '빨강 or 주황',
          },
          {
            type: '펭귄',
            score: 7,
            content: '검정',
          },
        ],
      },
      {
        question: '파티 날 나의 등장은?',
        answers: [
          {
            type: '사자',
            score: 2,
            content: '최대한 시선이 집중되지 않도록 조용히 들어간다.',
          },
          {
            type: '너구리',
            score: 4,
            content: '차분하게 들어가 주변을 두리번거린다.',
          },
          {
            type: '펭귄',
            score: 6,
            content: '화려하게 모두가 날 볼 수 있도록 등장',
          },
        ],
      },
      {
        question: '잠들기 전 나의 포즈는?',
        answers: [
          {
            type: '사자',
            score: 1,
            content: '이불을 머리 끝까지 덮고 자는 자세',
          },
          {
            type: '너구리',
            score: 2,
            content: '한 팔을 베고 있는 자세',
          },
          {
            type: '펭귄',
            score: 3,
            content: '옆으로 누워 웅크린 자세',
          },
          {
            type: '너구리',
            score: 4,
            content: '엎드려서 자는 자세',
          },
          {
            type: '펭귄',
            score: 5,
            content: '몸을 바르게 펴고 누운 자세',
          },
        ],
      },
      {
        question: '간만에 집중하고 있는 날 누군가 방해한다.',
        answers: [
          {
            type: '사자',
            score: 2,
            content: '나 지금 짜증났다고 투덜거린다',
          },
          {
            type: '너구리',
            score: 4,
            content: '속으로 짜증내지만 티 안내기',
          },
          {
            type: '펭귄',
            score: 6,
            content: '오케이! 이 기회에 휴식타임',
          },
        ],
      },
      {
        question: '편히 앉으라고 했을 때 나는?',
        answers: [
          {
            type: '사자',
            score: 1,
            content: '한 쪽 다리를 접어 깔고 앉기',
          },
          {
            type: '너구리',
            score: 2,
            content: '다리를 쭉 펴고 앉기',
          },
          {
            type: '펭귄',
            score: 4,
            content: '정자세로 앉기 (지하철에서의 자세) ',
          },
          {
            type: '펭귄',
            score: 6,
            content: '다리 꼬고 앉기',
          },
        ],
      },
      {
        question: '배꼽이 빠질 듯 재밌는 일이 있을 때 나는?',
        answers: [
          {
            type: '사자',
            score: 3,
            content: '미소 천사처럼 웃음 소리 내지 않기',
          },
          {
            type: '너구리',
            score: 4,
            content: '조용한 소리로 웃기',
          },
          {
            type: '펭귄',
            score: 5,
            content: '적당한 웃음 소리로 별로 크지 않게 웃기',
          },
          {
            type: '펭귄',
            score: 6,
            content:
              '나 지금 웃고 있다는 사실을 알리는 것처럼 숨기지 않고 크게 웃기',
          },
        ],
      },
      {
        question: '사람들과 대화할 때 나는',
        answers: [
          {
            type: '사자',
            score: 2,
            content: '두 손을 잡으면서',
          },
          {
            type: '너구리',
            score: 4,
            content: '셀프 팔짱 끼고 서서',
          },
          {
            type: '펭귄',
            score: 5,
            content: '한 손이나 양손을 허리에 올리고',
          },
          {
            type: '너구리',
            score: 6,
            content: '내 몸이나 머리카락은 만지작거리며',
          },
          {
            type: '펭귄',
            score: 7,
            content: '상대를 터치하며',
          },
        ],
      },
      {
        question: '나는 평소 걸을 때',
        answers: [
          {
            type: '사자',
            score: 1,
            content: '느리게 걷기',
          },
          {
            type: '너구리',
            score: 2,
            content: '바닥을 보며 빠르지 않게',
          },
          {
            type: '펭귄',
            score: 4,
            content: '보폭은 좁게 빨리',
          },
          {
            type: '너구리',
            score: 6,
            content: '보폭은 넓고 빨리',
          },
          {
            type: '펭귄',
            score: 7,
            content: '정면을 바라보며 너무 빠르지 않게',
          },
        ],
      },
      {
        question: '하루 중 가장 기분이 좋을 때',
        answers: [
          {
            type: '사자',
            score: 2,
            content: '아침',
          },
          {
            type: '너구리',
            score: 4,
            content: '오후나 이른 저녁',
          },
          {
            type: '펭귄',
            score: 6,
            content: '늦은 밤',
          },
        ],
      },
      {
        question: '내가 주로 꾸는 꿈은?',
        answers: [
          {
            type: '사자',
            score: 1,
            content: '기분 좋은 느낌인 꿈',
          },
          {
            type: '너구리',
            score: 2,
            content: '싸우는 꿈',
          },
          {
            type: '펭귄',
            score: 3,
            content: '계속 찾는 꿈',
          },
          {
            type: '사자',
            score: 4,
            content: '어디서 떨어지는 꿈',
          },
          {
            type: '너구리',
            score: 5,
            content: '날거나 떠오르는 그런 꿈?',
          },
          {
            type: '펭귄',
            score: 6,
            content: '꿈은 잘...',
          },
        ],
      },
    ],
    results: [
      {
        type: '나는야 소심쟁이',
        desc: `나는야 소심쟁이\n
                ✓   남들이 보는 당신은 부끄럼이 많고, 조금 소심하며 우유부단해 보입니다.\n
                ✓   본인의 일을 잘 결정을 못하는 선택장애라 조금 답답해 보이고 뭔가 대신해줘야 할 것 같은…\n
                ✓   보호본능을 일으킨 달까?\n
                ✓   또한 걱정을 사서하는 타입!\n
                ✓   그래서 친해지면 아니라는 걸 알지만,\n
                ✓   당신과 친해지기 전까지 잘 모르는 사람은 당신을 조금 지루한 사람이라고 생각하기도 합니다.
                `,
        query: 'thtlawoddl',
        score_range: range(21),
        img_src:
          'https://images.ktestone.com/resultImages/otherlookme/otherlookme-1.png',
      },
      {
        type: '나는야 깐깐쟁이',
        desc: `나는야 깐깐쟁이\n
                ✓   남들이 보는 당신은 조금 집요하고 고집 센 깐깐쟁이로 보입니다.\n
                ✓   상당히 신중하고 조심스러운 사람이라 조금 느릴 수 있지만 꾸준하게 성실하고 전진하는 사람.\n
                ✓   그래서 당신의 충동적인 행동은 주변 친구들에게 충격으로 다가온다고요!\n
                ✓   무슨 일이든 꼼꼼하게 계획하며 결정하는 것을 선호하는 당신!\n
                ✓   No!라고 당당하게 말하기 때문에 대부분 퇴짜 놓는 사람이 되어버리는…
                `,
        query: 'RksRkswoddl',
        score_range: range(21, 31),
        img_src:
          'https://images.ktestone.com/resultImages/otherlookme/otherlookme-2.png',
      },
      {
        type: '나는야 겸손쟁이',
        desc: `나는야 겸손쟁이\n
                ✓   남들이 보는 당신은 현명하고 신중한 겸손쟁이입니다.\n
                ✓   당신은 똑똑하고 재능과 능력이 있지만 겸손한 자세로 주변에서 인정받는 사람이네요.\n
                ✓   그러한 당신은 사람을 사귈 때 다급하게 결정하지 않고 가볍게 접근하지 않으며,\n
                ✓   나의 사람들에겐 매우 잘해주고 자신도 그만큼의 대우를 받기 원합니다!\n
                ✓   신뢰는 인간관계에서 가장 중요한 것!\n
                ✓   그 신뢰가 무너지면 극복이 힘들어요…
                `,
        query: 'ruathswoddl',
        score_range: range(31, 41),
        img_src:
          'https://images.ktestone.com/resultImages/otherlookme/otherlookme-3.png',
      },
      {
        type: '나는야 매력쟁이',
        desc: `나는야 매력쟁이\n
                ✓   남들이 보는 당신은 매우 발랄하고, 매력적이며, 재밌고 현실적인 사람이라고 생각해요.\n
                ✓   어디서든 주목을 사로잡는 사람이라고 생각하고 적당한 눈치도 가지고 있어,\n
                ✓   교만해지지 않고 주위를 잘 살핀다고 느껴요.\n
                ✓   다정한 부분도 있고 친절하며 이해심이 많아 다른 사람을 잘 도와줍니다!\n
                ✓   그런 당신은 늘 분위기 메이커!
                `,
        query: 'aofurwoddl',
        score_range: range(41, 51),
        img_src:
          'https://images.ktestone.com/resultImages/otherlookme/otherlookme-4.png',
      },
      {
        type: '나는야 행동쟁이',
        desc: `나는야 행동쟁이\n
                ✓   남들이 보는 당신은 잘 흥분하고, 변덕스러우며 충동적인 사람이라고 생각해요.\n
                ✓   그래서 리더형으로 빠른 결정을 내릴 수 있고 과감하고 모험성이 있는 타입~\n
                ✓   호기심이 매우 있는 편이라 모든 해보려고 하는 당신!\n
                ✓   그런 당신의 친구들은 당신이 발하는 강렬하고 진취적인 모습에 강력하게 끌립니다!
                `,
        query: 'godehdwoddl',
        score_range: range(51, 61),
        img_src:
          'https://images.ktestone.com/resultImages/otherlookme/otherlookme-5.png',
      },
      {
        type: '나는야 지배쟁이',
        desc: `태양처럼 주위를 밝게 비추는 타입\n
                ✓   남들이 보는 당신은 약간의 허영심이 있고,\n
                ✓   남을 지배하고 싶은 욕구가 강해 보입니다.\n
                ✓   남이 해주는 칭찬을 좋아하고 칭찬에 약하지만,\n
                ✓   남에게 칭찬을 잘 못해주는 양면적인 성격!\n
                ✓   그런 당신의 당당한 성격을 부러워하고 우러러보는,\n
                ✓   사람들이 있지만 정작 그렇게 될까 두려워할 수도 있어요!
                `,
        query: 'wlqowoddl',
        score_range: range(61, 101),
        img_src:
          'https://images.ktestone.com/resultImages/otherlookme/otherlookme-6.png',
      },
    ],
  },
  // latte
  {
    info: {
      mainTitle: '라떼 테스트',
      subTitle: '과연 나는 라떼일까?',
      mainUrl: 'latte',
      scoreType: 'numberScoring',
      mainImage: 'https://images.ktestone.com/introImages/latte-main.png',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/latte-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/latte.png',
      lang: 'Kor',
      category: 'etc',
    },
    questions: [
      {
        question: '누군가 내 의견에 반대하면?',
        answers: [
          {
            type: '포메라니안',
            score: 1,
            content: '그럴 수도 있지! ',
          },
          {
            type: '치와와',
            score: 2,
            content: '가슴속에서 분노가…',
          },
        ],
      },
      {
        question: '처음 만나는 사람을 보면?',
        answers: [
          {
            type: '포메라니안',
            score: 1,
            content: '나이부터 확인한다.',
          },
          {
            type: '웰시코기',
            score: 2,
            content: '상대방이 먼저 나이를 물어본다.',
          },
        ],
      },
      {
        question: '여러 사람들과 밥을 먹을 때',
        answers: [
          {
            type: '치와와',
            score: 1,
            content: '가위/바위/보나 분할해 계좌 이체하기',
          },
          {
            type: '웰시코기',
            score: 2,
            content: '연장자가 계산한다',
          },
        ],
      },
      {
        question: '회식 때 나의 모습은?',
        answers: [
          {
            type: '비글',
            score: 1,
            content: '마지막까지 남아 있어야지!',
          },
          {
            type: '리트리버',
            score: 2,
            content: '눈치껏 집에 가서 쉬어야지!',
          },
        ],
      },
      {
        question: '고기 먹을 때',
        answers: [
          {
            type: '리트리버',
            score: 1,
            content: '제일 어린 사람이 굽지 않고 먹기만 하면 화가나!',
          },
          {
            type: '웰시코기',
            score: 2,
            content: '그냥 누가 구워도 상관 노노!',
          },
        ],
      },
      {
        question: '회사에서 보너스를 받았다.',
        answers: [
          {
            type: '포메라니안',
            score: 1,
            content: '나를 위해 써야지~사고 싶은 거 구입하기!',
          },
          {
            type: '비글',
            score: 2,
            content: '미래를 위해 저축해두자! 통장에 입금하기!',
          },
        ],
      },
      {
        question: '퇴근할 때 나는?',
        answers: [
          {
            type: '비글',
            score: 1,
            content: '바로 퇴근한다.',
          },
          {
            type: '웰시코기',
            score: 2,
            content: '주변 눈치를 보며 퇴근 타이밍을 본다.',
          },
        ],
      },
      {
        question: '식당에 가면?',
        answers: [
          {
            type: '치와와',
            score: 1,
            content: '수저, 젓가락을 먼저 세팅한다.',
          },
          {
            type: '리트리버',
            score: 2,
            content: '누군가 늘 미리 먼저 세팅해 놓는다.',
          },
        ],
      },
    ],
    results: [
      {
        type: '따뜻한 우유',
        desc: ``,
        query: 'milk',
        score_range: range(11),
        img_src: 'https://images.ktestone.com/resultImages/latte/milk.png',
      },
      {
        type: '밀크 커피',
        desc: ``,
        query: 'milkCoffee',
        score_range: range(11, 14),
        img_src:
          'https://images.ktestone.com/resultImages/latte/milkCoffee.png',
      },
      {
        type: '라떼',
        desc: ``,
        query: 'latte',
        score_range: range(14, 20),
        img_src: 'https://images.ktestone.com/resultImages/latte/latte.png',
      },
    ],
  },
  // whosFault
  {
    info: {
      mainTitle: '누구의 잘못일까?',
      subTitle: '도대체 누구의 잘못일까?',
      mainUrl: 'whosFault',
      scoreType: 'storyTelling',
      mainImage: 'https://images.ktestone.com/introImages/whosFault-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/whosFault-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/whosFault.png',
      lang: 'Kor',
      category: 'etc',
    },
    questions: [
      {
        question: 'whosFault-0',
        answers: [
          {
            type: 'whosFault-0',
            score: 0,
            content:
              'https://images.ktestone.com/storyImages/whosFault/whosFault-0.png',
          },
        ],
      },
      {
        question: 'whosFault-1',
        answers: [
          {
            type: 'whosFault-1',
            score: 0,
            content:
              'https://images.ktestone.com/storyImages/whosFault/whosFault-1.png',
          },
        ],
      },
      {
        question: 'whosFault-2',
        answers: [
          {
            type: 'whosFault-2',
            score: 0,
            content:
              'https://images.ktestone.com/storyImages/whosFault/whosFault-2.png',
          },
        ],
      },
      {
        question: 'whosFault-3',
        answers: [
          {
            type: 'whosFault-3',
            score: 0,
            content:
              'https://images.ktestone.com/storyImages/whosFault/whosFault-3.png',
          },
        ],
      },
      {
        question: 'whosFault-4',
        answers: [
          {
            type: 'whosFault-4',
            score: 0,
            content:
              'https://images.ktestone.com/storyImages/whosFault/whosFault-4.png',
          },
        ],
      },
      {
        question: 'whosFault-5',
        answers: [
          {
            type: 'whosFault-5',
            score: 0,
            content:
              'https://images.ktestone.com/storyImages/whosFault/whosFault-5.png',
          },
        ],
      },
      {
        question: 'whosFault-6',
        answers: [
          {
            type: 'whosFault-6',
            score: 0,
            content:
              'https://images.ktestone.com/storyImages/whosFault/whosFault-6.png',
          },
        ],
      },
      {
        question: 'whosFault-7',
        answers: [
          {
            type: 'whosFault-7',
            score: 0,
            content:
              'https://images.ktestone.com/storyImages/whosFault/whosFault-7.png',
          },
        ],
      },
    ],
    results: [
      {
        type: 'whosFault-result',
        desc: `whosFault-result`,
        query: 'snrndmlwkfaht',
        score_range: range(2),
        img_src:
          'https://images.ktestone.com/storyImages/whosFault/whosFault-8.png',
      },
    ],
  },
  // birthNum
  {
    info: {
      mainTitle: '탄생수 테스트',
      subTitle: '탄생숫자로 알아보는 숨겨진 나의 성격',
      mainUrl: 'birthNum',
      scoreType: 'birthdayCalc',
      mainImage: 'https://images.ktestone.com/introImages/birthNum-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/birthNum-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/birthNum.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        question: '데이트 전 날 나의 모습은?',
        answers: [
          {
            type: '포메라니안',
            score: 2,
            content: '나가기 직전 마음에 드는 옷을 입는다.',
          },
          {
            type: '치와와',
            score: 5,
            content: '자기 전 머릿속으로 나의 스타일링을 생각하고 잔다.',
          },
          {
            type: '비글',
            score: 10,
            content: '전 날 머리부터 발끝까지 스타일링과 옷을 미리 준비한다.',
          },
        ],
      },
      {
        question: '갑자기 자취방에 놀러 온다는 애인!\n그때의 나는?',
        answers: [
          {
            type: '포메라니안',
            score: 2,
            content: '아아... 왜 갑자기 오는 거야?! 보이는 곳만 치워 둔다.',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '모아둔 쓰레기를 버리고 바로 청소를 시작한다.',
          },
          {
            type: '리트리버',
            score: 10,
            content: '평소 미리미리 해 두었기 때문에 바로 주소를 보낸다.',
          },
        ],
      },
      {
        question: '좋아하는 이성이 생겼을 때 나는?',
        answers: [
          {
            type: '치와와',
            score: 2,
            content: '상대의 SNS을 탐방하며, 미래의 우리 모습을 상상해본다.',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '좋아하는 이성의 친구에게 도움을 요청한다.',
          },
          {
            type: '리트리버',
            score: 10,
            content: '먼저 연락할 거리를 만들며 적극적으로 대시한다.',
          },
        ],
      },
      {
        question:
          '카페 안에 이상형인 사람과 계속 눈이 마주친다.\n 눈웃음까지 보내는데...?',
        answers: [
          {
            type: '비글',
            score: 2,
            content: '지금 말하면 좋은 말이 안 나올 것 같아. 좀 이따 연락할게.',
          },
          {
            type: '리트리버',
            score: 5,
            content: '아!!!!!!!짜증나!!!!!진짜!!!!!! 도대체 왜 그러는 거야?!?!',
          },
          {
            type: '포메라니안',
            score: 10,
            content:
              '이런 점에서 나는 화가 난 거야. 그런 부분은 고쳐줬으면 좋겠어.',
          },
        ],
      },
      {
        question:
          '쉬고 있는데 갑자기 걸려온 남사친/여사친의 전화, \n친구들이 같이 놀고 싶다며 나올 수 있냐고 물어보는데...?',
        answers: [
          {
            type: '리트리버',
            score: 2,
            content:
              '귀찮은데… 아쉽긴 하지만 “시간이 너무 늦었어… 나 집에서 쉴래!”',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '일단 끊고 수십 번 고민 끝에 나가기로 한다.',
          },
          {
            type: '비글',
            score: 10,
            content: '오케이~ 인연 한 번 만들어보자~ “갈게~~~~”',
          },
        ],
      },
      {
        question: '다가온 기념일! 나는 어떤 선물을 준비할까?',
        answers: [
          {
            type: '포메라니안',
            score: 2,
            content: '정성 어린 편지와 기억에 남을 만한 선물을 준비한다.',
          },
          {
            type: '비글',
            score: 5,
            content: '커플로 착용할 수 있는 아이템을 선물한다.',
          },
          {
            type: '리트리버',
            score: 10,
            content:
              '평소 사고 싶다거나 필요한 물건을 기억해 두었다가 서프라이즈로 선물한다.',
          },
        ],
      },
      {
        question: '내가 하고 싶은 연애 스타일은?',
        answers: [
          {
            type: '비글',
            score: 2,
            content:
              '다이나믹한 화려한 연애 (단, 너무 화려해서 바람 피울 가능성 50%)',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '나만 바라보는 연애 (단, 너무 좋아해서 집착 대마왕)',
          },
          {
            type: '포메라니안',
            score: 10,
            content: '친구같이 편안한 연애 (단, 가끔 친구인지 애인인지 헷갈림)',
          },
        ],
      },
      {
        question: '애인과 대화할 때 나는?',
        answers: [
          {
            type: '치와와',
            score: 5,
            content: '장난치고 티키타카가 잘 되는 대화가 좋다.',
          },
          {
            type: '리트리버',
            score: 10,
            content: '서로의 생각을 공유하는 대화가 너무 좋다.',
          },
        ],
      },
      {
        question: '친구가 나에게 고민상당을 한다. 나의 반응은?',
        answers: [
          {
            type: '포메라니안',
            score: 5,
            content: '고민에 대한 구체적인 해결책을 제시한다.',
          },
          {
            type: '웰시코기',
            score: 10,
            content: '고민에 대해 빠져들어 감정적으로 같이 공감해준다.',
          },
        ],
      },
      {
        question:
          '기다려왔던 짝사랑하던 그/그녀와의 데이트 날..\n갑자기 급한 일이 생겼다며 다음에 만나자고 한다.\n과연 나의 대답은?',
        answers: [
          {
            type: '비글',
            score: 2,
            content: '어쩔 수 없지… 알겠어 다음에 보자!',
          },
          {
            type: '치와와',
            score: 5,
            content: '알겠어…근데 심각한 일이야?',
          },
          {
            type: '웰시코기',
            score: 10,
            content: '아 진짜? 그럼 날짜 다시 조정하자! 언제가 괜찮아?',
          },
        ],
      },
    ],
    results: [
      {
        type: '탄생수 1',
        desc: `태양처럼 주위를 밝게 비추는 타입\n
                ✓   넘치는 열정과 자신감이 최대 장점, 상황이 어떻든 당당하게 자신의 의견을 말하는 용감한 사람\n
                ✓   강력하고 개인적이며 창조적인 인간.\n
                ✓   정의로운 성격이기에 목적을 위해 편법을 쓰거나 비굴하게 굴지 않는다.\n
                ✓   리더십/행동력/포용력 어느 하나 빠지는 것이 없이 리더감으로 최고\n
                ✓   지배적인 리더십…하지만 폭군일 가능성이 크다.\n
                ✓   무뚝뚝하고 개인적인 관계보다 사회적 활동에 많은 에너지를 쏟는다.
                `,
        query: 'xkstodtn1',
        score_range: range(2),
        img_src:
          'https://images.ktestone.com/resultImages/birthNum/birthNum_1.png',
      },
      {
        type: '탄생수 2',
        desc: `부드러운 여성스러운 타입\n
                ✓   친절함/섬세함을 장점으로 여러 사람들과 잘 어울린다.\n
                ✓   하지만 속으로는 강한 소유욕과 뚜렷한 호 불호를 지닌…\n
                ✓   행동보다는 생각을 많이 하는 타입 \n
                ✓   평화주의자, 본인의 원래 성격을 숨기는데 스트레스 받아서 집에서 폭발. \n
                ✓   예민 보스, 변덕스럽다. 감수성이 풍부, 모성/부성애가 강한 스타일\n
                ✓   풍부한 상상력, 너무 매력적인 스타일이지만 과하면 최악, 스스로 컨트롤해야 함.
                `,
        query: 'xkstodtn2',
        score_range: range(2, 3),
        img_src:
          'https://images.ktestone.com/resultImages/birthNum/birthNum_2.png',
      },
      {
        type: '탄생수 3',
        desc: `자유로운 영혼 스타일 \n
                ✓   구속받는 것을 싫어하는 낙천적이고 쾌활한 타입, 엔터 쪽 분야에서 많이 보임. \n
                ✓   다재다능해 어떤 일이든 보통 이상의 성공을 이룰 가능성이 큼.\n
                ✓   하지만 너무 자유분방해서 가끔 스스로 수습하기 힘든 상황인 데도 주변에 도와 달라는 말 잘 안 함.\n
                ✓   특출난 재능인들이 많음 \n
                ✓   자존심 강하고 독립적인 성향 그렇지만 매우 활발 \n
                ✓   교제를 좋아하고 친절하며 우정이 두터워 인기쟁이임!
                `,
        query: 'xkstodtn3',
        score_range: range(3, 4),
        img_src:
          'https://images.ktestone.com/resultImages/birthNum/birthNum_3.png',
      },
      {
        type: '탄생수 4',
        desc: `근면/성실 노력파 타입 \n
                ✓   겉보기에는 소극적인 것처럼 보이지만 내면은 열정과 냉철함 \n
                ✓   아니면 아닌 거지 라는 마음으로 내가 좋아하는 상대방에게 표현하는 사람들이에요. \n
                ✓   목표 있으면 끈질기고 신중하게 전진하는 타입 \n
                ✓   착실하고 성실하지만 집순이/집돌이 \n
                ✓   가까운 사람에겐 누구보다 잘해주고 다정하지만 낯가림 \n
                ✓   다른 사람보다 조금 느긋하고 느리지만 독립적으로 나아가 최종적으로 상장한 발전을 이루는 타입 \n
                ✓   참을성이 많고, 실용적인 성격
                `,
        query: 'xkstodtn4',
        score_range: range(4, 5),
        img_src:
          'https://images.ktestone.com/resultImages/birthNum/birthNum_4.png',
      },
      {
        type: '탄생수 5',
        desc: `날카로운 지성이 빛나는 타입 \n
                ✓   재치 있고 활기찬 성격으로 감각적이며 즐거움을 추구 \n
                ✓   그래서인지 단순하고 반복적인 일상은 못 견디는 타입 \n
                ✓   늘 신선한 거, 지적인 것을 채울 수 있도록 도전하지만 얼마 못가 질려 하는 스타일 \n
                ✓   미지의 영역을 손대는… 덕분에 업적이라도 남길 수도… \n
                ✓   특유의 친화력과 재치로 인싸 중 인싸 \n
                ✓   붙임성이 좋지만 반대로 마음은 비판적이고 분석적인 사고 지님 \n
                ✓   마음속 여러 가치관이 공존해서 때로 혼란과 분열의 원인이 된다. \n
                ✓   압도적인 개성, 아무도 생각하지 못한 아이디어와 대처능력의 소유자.
                `,
        query: 'xkstodtn5',
        score_range: range(5, 6),
        img_src:
          'https://images.ktestone.com/resultImages/birthNum/birthNum_5.png',
      },
      {
        type: '탄생수 6',
        desc: `센스와 배려를 타고난 천사표 타입 \n
                ✓   봄처럼 온화한 성품으로 다른 이의 어려움도 자기 일처럼 나섬. 믿음직스럽고 신뢰 가는 타입 \n
                ✓   학교나 회사 동료들에게 인기1이 될 것 같은 사람 \n
                ✓   스스로 만든 룰 때문에 괴로워함. \n
                ✓   거절해야 할 필요성을 매우 느끼고 있지만 누군가 도와 달라고 하면 거절 못 하고 도와주고 있음. \n
                ✓   한번 화나면 주변에서 손댈 수 없을 정도로 몹시 거침. \n
                ✓   분노가 가장 오를 경우는 강렬한 나의 소유욕을 건드렸을 때 \n
                ✓   이성으로 사람으로서 너무 매력적이며 미적 센스와 언제나 자신감이 넘친다. \n
                ✓   책임감도 있어 인내 강해, 하지만 큰 변화를 싫어한다. \n
                ✓   하지만 고집 센 금사빠인 사랑의 사냥꾼
                `,
        query: 'xkstodtn6',
        score_range: range(6, 7),
        img_src:
          'https://images.ktestone.com/resultImages/birthNum/birthNum_6.png',
      },
      {
        type: '탄생수 7',
        desc: `외로운 천재 타입 \n
                ✓   정신적으로 색채가 강하고 신비한 느낌 \n
                ✓   매우 날카로운 통찰력과 비판력, 풍부한 독창성을 가지고 있지만 극단적일 경우는 괴짜 \n
                ✓   무슨 생각을 하는지 파악이 어려움. \n
                ✓   모순된 행동으로 인해 주변인을 당황시키고는 날 이해해 주는 사람 없다며 외로워함. \n
                ✓   천재적인 독창성, 발명, 영감이 마구 떠오름. \n
                ✓   너무 앞선 미래를 생각해 비로소 나중에야 빛을 바람. \n
                ✓   친구, 애인 누구에게나 평등하고 박애에 가까운 애정의 소유자 \n
                ✓   지위나 돈보다는 꿈보다 희망. 가치관을 이해해 주는 사람과 만나면 행복함. \n
                ✓   학문이나 연구적인 분야에서의 재능이 매우 뛰어남. 호기심 덩어리
                `,
        query: 'xkstodtn7',
        score_range: range(7, 8),
        img_src:
          'https://images.ktestone.com/resultImages/birthNum/birthNum_7.png',
      },
      {
        type: '탄생수 8',
        desc: `폼생폼사에 죽는 유아독존 타입 \n
                ✓   큰 의지력과 개성이 있는 삶에 위험이 없으면 짜릿한 스릴을 느낄 수 없다는 대담한 타입 \n
                ✓   무언가 꽂히면 자신의 판단이 옳다는 자신감으로 몰두하기 때문에 아랫사람으로부터 신뢰감 뿜뿜 \n
                ✓   타인을 지배하려는 타입과 급한 성격, 굽히는 걸 싫어하려는 성격, 우유부단/애매모호 극혐 \n
                ✓   냉혹한 면모가 있어 다른 사람이 오해할 수도… \n
                ✓   매우 적극적이다. 야망이 큰 편, 목표 달성을 위한 전진 \n
                ✓   성공에 대한 욕심이 있다 \n
                ✓   내 안은 자기중심적인 나와 남을 위한 내가 존재 
                `,
        query: 'xkstodtn8',
        score_range: range(8, 9),
        img_src:
          'https://images.ktestone.com/resultImages/birthNum/birthNum_8.png',
      },
      {
        type: '탄생수 9',
        desc: `부끄러움이 많은 포커페이스 \n
                ✓   되고 싶은 성격에 따라 기분에 따라 표정을 연기하고 숨길 수 있음. \n
                ✓   자신을 드러내기 꺼려 하는 부끄럼쟁이지만 때로는 자신의 의견을 강력하게 말하는 .. 고집도… \n
                ✓   불의 보면 못 참아, 투쟁의 중심… 대체로 성공하는 케이스 \n
                ✓   사람을 도울 때 기쁨을 느끼고 자신을 희생하는 봉사정신 \n
                ✓   머리가 좋고 박식하며 이해력이 좋아 다양한 가능성을 숨기고 있는 사람 \n
                ✓   성격이 불같아 감정 변화 크지만 봉사정신이 투철하고 영웅적인 면모도! \n
                ✓   로맨티시스트, 이상을 꿈꾸는 걸 좋아하지만 계산적인 현실적인 면도 있는…
                `,
        query: 'xkstodtn9',
        score_range: range(9, 10),
        img_src:
          'https://images.ktestone.com/resultImages/birthNum/birthNum_9.png',
      },
    ],
  },
  // loveLevel
  {
    info: {
      mainTitle: '연애고수 테스트',
      subTitle: '나의 연애 점수는?',
      mainUrl: 'lovelevel',
      scoreType: 'numberScoring',
      mainImage: 'https://images.ktestone.com/introImages/lovelevel-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/lovelevel-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/lovelevel.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        question: '데이트 전 날 나의 모습은?',
        answers: [
          {
            type: '포메라니안',
            score: 2,
            content: '나가기 직전 마음에 드는 옷을 입는다.',
          },
          {
            type: '치와와',
            score: 5,
            content: '자기 전 머릿속으로 나의 스타일링을 생각하고 잔다.',
          },
          {
            type: '비글',
            score: 10,
            content: '전 날 머리부터 발끝까지 스타일링과 옷을 미리 준비한다.',
          },
        ],
      },
      {
        question: '갑자기 자취방에 놀러 온다는 애인!\n그때의 나는?',
        answers: [
          {
            type: '포메라니안',
            score: 2,
            content: '아아... 왜 갑자기 오는 거야?! 보이는 곳만 치워 둔다.',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '모아둔 쓰레기를 버리고 바로 청소를 시작한다.',
          },
          {
            type: '리트리버',
            score: 10,
            content: '평소 미리미리 해 두었기 때문에 바로 주소를 보낸다.',
          },
        ],
      },
      {
        question: '좋아하는 이성이 생겼을 때 나는?',
        answers: [
          {
            type: '치와와',
            score: 2,
            content: '상대의 SNS을 탐방하며, 미래의 우리 모습을 상상해본다.',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '좋아하는 이성의 친구에게 도움을 요청한다.',
          },
          {
            type: '리트리버',
            score: 10,
            content: '먼저 연락할 거리를 만들며 적극적으로 대시한다.',
          },
        ],
      },
      {
        question:
          '카페 안에 이상형인 사람과 계속 눈이 마주친다.\n 눈웃음까지 보내는데...?',
        answers: [
          {
            type: '비글',
            score: 2,
            content:
              '지금 말하면 좋은 말이 안 나올 것 같아. 일단 모르는 척 침묵.',
          },
          {
            type: '리트리버',
            score: 5,
            content: '나?! 진짜 나?! 하면서 흘깃흘깃 몰래보기',
          },
          {
            type: '포메라니안',
            score: 10,
            content: '나도 눈웃음 보내다가, 바로 번호 물어보기!',
          },
        ],
      },
      {
        question:
          '쉬고 있는데 갑자기 걸려온 남사친/여사친의 전화, \n친구들이 같이 놀고 싶다며 나올 수 있냐고 물어보는데...?',
        answers: [
          {
            type: '리트리버',
            score: 2,
            content:
              '귀찮은데… 아쉽긴 하지만 “시간이 너무 늦었어… 나 집에서 쉴래!”',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '일단 끊고 수십 번 고민 끝에 나가기로 한다.',
          },
          {
            type: '비글',
            score: 10,
            content: '오케이~ 인연 한 번 만들어보자~ “갈게~~~~”',
          },
        ],
      },
      {
        question: '다가온 기념일! 나는 어떤 선물을 준비할까?',
        answers: [
          {
            type: '포메라니안',
            score: 2,
            content: '정성 어린 편지와 기억에 남을 만한 선물을 준비한다.',
          },
          {
            type: '비글',
            score: 5,
            content: '커플로 착용할 수 있는 아이템을 선물한다.',
          },
          {
            type: '리트리버',
            score: 10,
            content:
              '평소 사고 싶다거나 필요한 물건을 기억해 두었다가 서프라이즈로 선물한다.',
          },
        ],
      },
      {
        question: '내가 하고 싶은 연애 스타일은?',
        answers: [
          {
            type: '비글',
            score: 2,
            content:
              '다이나믹한 화려한 연애 (단, 너무 화려해서 바람 피울 가능성 50%)',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '나만 바라보는 연애 (단, 너무 좋아해서 집착 대마왕)',
          },
          {
            type: '포메라니안',
            score: 10,
            content: '친구같이 편안한 연애 (단, 가끔 친구인지 애인인지 헷갈림)',
          },
        ],
      },
      {
        question: '애인과 대화할 때 나는?',
        answers: [
          {
            type: '치와와',
            score: 5,
            content: '장난치고 티키타카가 잘 되는 대화가 좋다.',
          },
          {
            type: '리트리버',
            score: 10,
            content: '서로의 생각을 공유하는 대화가 너무 좋다.',
          },
        ],
      },
      {
        question: '친구가 나에게 고민상당을 한다. 나의 반응은?',
        answers: [
          {
            type: '포메라니안',
            score: 5,
            content: '고민에 대한 구체적인 해결책을 제시한다.',
          },
          {
            type: '웰시코기',
            score: 10,
            content: '고민에 대해 빠져들어 감정적으로 같이 공감해준다.',
          },
        ],
      },
      {
        question:
          '기다려왔던 짝사랑하던 그/그녀와의 데이트 날..\n갑자기 급한 일이 생겼다며 다음에 만나자고 한다.\n과연 나의 대답은?',
        answers: [
          {
            type: '비글',
            score: 2,
            content: '어쩔 수 없지… 알겠어 다음에 보자!',
          },
          {
            type: '치와와',
            score: 5,
            content: '알겠어…근데 심각한 일이야?',
          },
          {
            type: '웰시코기',
            score: 10,
            content: '아 진짜? 그럼 날짜 다시 조정하자! 언제가 괜찮아?',
          },
        ],
      },
    ],
    results: [
      {
        type: '프로망상러',
        desc: `연애는 어케하세요...? \n
                ✓   연애 시작도 전에 상상만으로 연애부터 이별까지 다하는 프로망상러들!\n
                ✓   감정 표현이 서툴러서 늘 고민하고 짝사랑만 하다가 끝나는 경우가 많아요.\n
                ✓   애정표현도 스킨십도 너무 북흐북흐! \n
                ✓   다가오는 사람이 있어도 나도 모르게 철벽을 치는! \n
                ✓   본인은 표현한다고 하지만… \n
                ✓   그런 식이면 상대방은 절대 알지 못해요. \n
                ✓   싫은 건 싫다! 좋은 건 좋다! \n
                ✓   의사 표현을 잘 해야 합니다.  \n
                ✓   안 그럼 나만 힘들어~ 계속 의심만 하지 말고 이제 마음을 열고 연애해봐요~ \n
                ✓   안나 언니가 말하잖아요! 사랑은 열린 문이라고 !! \n
                `,
        query: 'vmfhakdtkdfj',
        score_range: range(31),
        img_src:
          'https://images.ktestone.com/resultImages/lovelevel/lovelevel_1.png',
      },
      {
        type: '연애꾸러기',
        desc: `이제 밀당은 가능해요 \n
                ✓   나 나름 연애 잘하는데?라고 생각하는 연애 꾸러기들!\n
                ✓   하지만 현실은 아기에 불과하죠.\n
                ✓   표현을 잘하긴 하지만 가끔은 너무 감정적이어서 상대방이 지칠 수 있어요. \n
                ✓   이제 연애하기 시작하는데 이 사람을 놓치기 싫은 마음에 많은 사랑을 요구하죠. \n
                ✓   그런 부분이 상대방을 지치게 할 수 있어요! \n
                ✓   너무 상대방만 바라보지 말고 나 자신도 돌봐주세요! \n
                ✓   내가 아니라고 하는 사람에게 굳이 노력할 필요 없잖아요! \n
                ✓   가끔은 나 자신도 소중하게 생각해 봐요~  \n
                ✓   세상은 넓고 내 사람은 분명 찾을 수 있으니까요! 걱정하지 마세요!
                `,
        query: 'dusdoRNfjrl',
        score_range: range(31, 66),
        img_src:
          'https://images.ktestone.com/resultImages/lovelevel/lovelevel_2.png',
      },
      {
        type: '아수라백작',
        desc: `눈치 만렙 보스?! 오늘은 어떤모습을? \n
                ✓   연애는 연애! 나는 나! \n
                ✓   애정표현이나 스킨십에도 적극적인 친구들! \n
                ✓   때로는 수줍게 때로는 직진러로 상황에 맞게 아수라 백작 같은!! \n
                ✓   서로의 생활은 소중하게~ 연애할 땐 연애에 충실하게! \n
                ✓   컨트롤이 가능한 나! 사이보그 아닌가요? \n
                ✓   좋아하는 사람이 다가오면 포커페이스 유지 가능한 나란 사람~ \n
                ✓   관심 없는 척하지만 뒤에선 아주아주 사람을 잘 유혹하는 엄청난 능력이 있는 사람들이랍니다! \n
                ✓   과거에 속앓이 하던 나에서 안녕~~~~ \n
                ✓   다 나를 위함이다~ 상처는 덜 받자! \n
                ✓   표현은 잘하자~ 연애 학습형 결과랍니다!
                `,
        query: 'dusdoRnfjrl',
        score_range: range(66, 86),
        img_src:
          'https://images.ktestone.com/resultImages/lovelevel/lovelevel_3.png',
      },
      {
        type: '프로직진러',
        desc: `연애하고 싶으면 표현해 \n
                ✓   쿨내 풀풀 나는 프로직진러들은 표현하는 것에 대해 혹은 거절에 대해 두려워하지 않아요! \n
                ✓   아니면 아닌 거지 라는 마음으로 내가 좋아하는 상대방에게 표현하는 사람들이에요. \n
                ✓   왜냐면 말하지 않으면 알 수가 없으니까요. \n
                ✓   주변에 친구들도 많은 인싸력! 이상형을 만나면 어떻게든 성공시키는! \n
                ✓   열 번 찍어 안 넘어가는 나무 없잖아요! \n
                ✓   상대방의 눈치를 잘 보지 않고 표현하는 직진러! \n
                ✓   하지만 너무 나에게 집착하는 건 싫어요! \n
                ✓   외로운 것도 싫어서 늘 연애를 하고 있는 사람들! \n
                ✓   사람은 사람으로 잊는 거죠! 
                `,
        query: 'vmfhwlrwlsfj',
        score_range: range(86, 101),
        img_src:
          'https://images.ktestone.com/resultImages/lovelevel/lovelevel_4.png',
      },
    ],
  },
  // humanWall
  {
    info: {
      mainTitle: '철벽남녀 테스트',
      subTitle: '이성이 다가올 때 나의 반응은?',
      mainUrl: 'humanwall',
      scoreType: 'typeCounting',
      mainImage: 'https://images.ktestone.com/introImages/humanWall-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/humanWall-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/humanwall.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        question: '남녀사이에 우정이란?',
        answers: [
          {
            type: '철벽인',
            score: 2,
            content: '있을 수 없어. 같이 있으면 있을수록 감정은 생기기 마련!',
          },
          {
            type: '자동문',
            score: 5,
            content: '친구는 친구지. 이성으로 생각한 적 1도 없다! ',
          },
        ],
      },
      {
        question: '마음에 드는 이성과 함께 있을 때 나는?',
        answers: [
          {
            type: '철벽인',
            score: 2,
            content:
              '표현하지 않지만 이미 머릿속으로 이 사람과 사귀게 됐을 때 모습을 상상한다.',
          },
          {
            type: '자동문',
            score: 5,
            content: '적극적으로 리액션 하며 호감을 표현하려고 노력한다.',
          },
        ],
      },
      {
        question: '좋아하는 이성이 다가올 때 나는?',
        answers: [
          {
            type: '철벽인',
            score: 2,
            content: '너무 좋아했지만 막상 나에게 다가오면 피하게 된다.',
          },
          {
            type: '자동문',
            score: 5,
            content: '서로의 마음이 어느 정도 확인했으니 적극적으로 표현한다.',
          },
        ],
      },
      {
        question: '헤어질 것이 뻔한 인연이라는 걸 알고 있을 때 나의 선택은?',
        answers: [
          {
            type: '철벽인',
            score: 2,
            content: '헤어질 인연이라면 시작하지도 않겠다.',
          },
          {
            type: '자동문',
            score: 5,
            content: '헤어질 걸 알아도 시작은 해본다.',
          },
        ],
      },
      {
        question: '마음에 들지 않는 상대에게 계속 연락이 온다.',
        answers: [
          {
            type: '철벽인',
            score: 2,
            content: '연락을 그만 했으면 좋겠다는 의사를 확실하게 표현한다.',
          },
          {
            type: '자동문',
            score: 5,
            content: '드문드문 답장하거나 안읽씹한다.',
          },
        ],
      },
      {
        question: '생전 처음 보는 사람과 과제를 같이 하게 되었다.',
        answers: [
          {
            type: '철벽인',
            score: 2,
            content: '상대방이 먼저 말 걸어줄 때까지 가만히 있는 편이다.',
          },
          {
            type: '자동문',
            score: 5,
            content: '먼저 다가가 말을 걸며 인사하는 편이다.',
          },
        ],
      },
      {
        question: '소개팅을 제안하는 친구!',
        answers: [
          {
            type: '철벽인',
            score: 2,
            content: '소개팅…하고는 싶지만 어색함 너무 싫어 난 자만추 스타일!',
          },
          {
            type: '자동문',
            score: 5,
            content: '무조건 오케이~ 사람 인연이라는 것이 어디서 만날지 몰라~',
          },
        ],
      },
      {
        question: '내가 더 선호하는 연애스타일은?',
        answers: [
          {
            type: '철벽인',
            score: 5,
            content: '친구 같은 편안한 연애',
          },
          {
            type: '자동문',
            score: 10,
            content: '매 순간에 설렘이 가득한 연애 ',
          },
        ],
      },
      {
        question: '연애할 때 나는?',
        answers: [
          {
            type: '철벽인',
            score: 5,
            content: '사주나 타로 어플을 자주 이용한다.',
          },
          {
            type: '자동문',
            score: 10,
            content: '나는 내 스타일대로~ 내 맘대로 해야지!',
          },
        ],
      },
      {
        question: '나는 연애의 대한 고민상담을',
        answers: [
          {
            type: '철벽인',
            score: 2,
            content: '들어주는 편이다.',
          },
          {
            type: '자동문',
            score: 5,
            content: '친구들에게 요청하는 편이다.',
          },
        ],
      },
    ],
    results: [
      {
        type: '철벽인',
        desc: `접근금지 확실한 철벽수비수 \n
                ✓   연애는 하고 싶지만 연애에 대한 환상이 크고,\n
                ✓   자존심이 높아 자신의 이상형에 미치지 못하는 사람들이 아예 접근하지 못하도록 방어하는 당신은 ‘철벽인’\n
                ✓   만리장성은 저리 가라 할 정도로 엄청난 철벽을 치고 있는 당신! \n
                ✓   남들의 연애 상담은 빠삭하지만 실전은 너무 약한... \n
                ✓   상대방을 너무 좋아하지만 정작 상대방이 적극적으로 다가오면 부담스러워서 피하고, \n
                ✓   상대방의 이상한 오점을 발견하면 바로 마음을 접어버려요. \n
                ✓   나는 눈이 낮다고 하지만 높은 거랍니다. \n
                ✓   이제 너무 철벽만 치지 마세요! \n
                ✓   그 벽 얼른 허무시고 행복하고 즐거운 연애해야죠!
                `,
        query: 'cjfqurdls',
        score_range: range(31),
        img_src:
          'https://images.ktestone.com/resultImages/humanwall/humanwall-1.png',
      },
      {
        type: '자동문',
        desc: `철벽이 뭐죠? 오픈마인드의 소유자 \n
                ✓   철벽이 뭐죠?\n
                ✓   연애 경험은 많을수록 좋은 거라고 했어요~ \n
                ✓   철벽이라는 단어 자체가 어울리지 않는 당신! \n
                ✓   들어오는 소개팅, 미팅은 다 받는데! \n
                ✓   인연이면 만나는 거고 아니면 친구로 만들어버리는~ \n
                ✓   살짝 금사빠 기질도? \n
                ✓   한번 사랑에 빠지면 간이고 쓸개고 다 줄 것처럼 직진하는 직진인!  \n
                ✓   하지만 포인트 하나 나가면 식는 것도 한순간인 당신! \n
                ✓   공백기가 짧은 타입이랍니다! \n
                ✓   세상은 넓고 인연은 많으니 이쁜 사랑 많이 하자~ \n
                ✓   많이 만나야 미래의 배우자를 잘 만나지! ><
                `,
        query: 'wkehdans',
        score_range: range(31, 66),
        img_src:
          'https://images.ktestone.com/resultImages/humanwall/humanwall-2.png',
      },
    ],
  },
  // wind
  {
    info: {
      mainTitle: '바람끼 테스트',
      subTitle: '내 애인의 바람끼는 어느 정도일까.?',
      mainUrl: 'wind',
      scoreType: 'numberScoring',
      mainImage: 'https://images.ktestone.com/introImages/wind-intro.png',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/wind-thumb.png',
      lang: 'Kor',
      category: 'love',
    },
    questions: [
      {
        question: '난 친구들의 기분 변화를 잘 캐치한다.',
        answers: [
          {
            type: '포메라니안',
            score: 2,
            content:
              '기분이 좋지 않을 땐 혼자 있다가 기분이 나아 질 때 힘차게 돌아온다.',
          },
          {
            type: '치와와',
            score: 5,
            content: '음 때에 따라 다른 것 같아요.',
          },
          {
            type: '비글',
            score: 10,
            content:
              '넹, 친구들이 기분이 나쁘거나 안 좋은 거 있음 먼저 눈치채는 편이죠.',
          },
        ],
      },
      {
        question: '회사 내 사내연애 커플이 탄생했다. \n 나의 반응은?',
        answers: [
          {
            type: '포메라니안',
            score: 2,
            content: '엥??? 둘이요???? 그 둘이 사귄다고요???',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '아 진짜? 나는 그냥 친한 사이인 줄 알았는데. ',
          },
          {
            type: '리트리버',
            score: 10,
            content: '모두가 아는 그 비밀연애?! 알고 있었어! 티 나더라.',
          },
        ],
      },
      {
        question:
          '놀이공원에 간 당신! \n친구들이 제일 스릴넘치는 롤러코스터를 타자고 한다!',
        answers: [
          {
            type: '치와와',
            score: 2,
            content: '아 미안 난 여기서 기다리고 있을게 타고와..!',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '아 싫은데...그래도 타자니까 다같이 타야지ㅜ',
          },
          {
            type: '리트리버',
            score: 10,
            content: '그렇지~ 놀이공원 왔으면 타야지~',
          },
        ],
      },
      {
        question:
          '카페 안에 이상형인 사람과 계속 눈이 마주친다.\n 눈웃음까지 보내는데...?',
        answers: [
          {
            type: '비글',
            score: 2,
            content: '부끄럽기 때문에 그냥 수줍게 눈을 피한다.',
          },
          {
            type: '리트리버',
            score: 5,
            content: '그냥 똑같이 눈을 보며 웃어준다.',
          },
          {
            type: '포메라니안',
            score: 10,
            content: '너무 떨리지만 용기를 내 먼저 가서 번호를 물어본다.',
          },
        ],
      },
      {
        question: '친구들과 있을 때 나의 포지션은??',
        answers: [
          {
            type: '리트리버',
            score: 2,
            content:
              '내가 중심! 말을 먼저 꺼내고 분위기를 으쌰 하게 만드는 분위기메이커~!',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '리액션을 많이 하고 잘 웃으며, 박수친다.',
          },
          {
            type: '비글',
            score: 10,
            content: '웃긴 웃지만 대부분 조용조용한 편이다.',
          },
        ],
      },
      {
        question:
          '친구들과의 약속에 늦은 당신! \n준비도 하나도 못했는데..\n이 때 당신은?',
        answers: [
          {
            type: '포메라니안',
            score: 2,
            content: '꾸미는 건 사치, 일단 집에서 나간다.',
          },
          {
            type: '비글',
            score: 5,
            content: '먼저 사과하고, 최소한의 준비만 한 뒤 나간다.',
          },
          {
            type: '리트리버',
            score: 10,
            content:
              '카톡이나 전화로 먼저 가라고 말한 뒤, 모든 준비를 다 하고 나간다.',
          },
        ],
      },
      {
        question: '소개팅을 할 때 가장 많이 보는 것은?',
        answers: [
          {
            type: '비글',
            score: 2,
            content:
              '음 나는 외모도 외모! 성격도 성격! 둘다 중요한 것같아! 평범한게 최고!',
          },
          {
            type: '웰시코기',
            score: 5,
            content:
              '외모도 외모인데 나는 성격이 더 좋아야해! 잘생기고 이쁘면 인물값 한다잖아.',
          },
          {
            type: '포메라니안',
            score: 10,
            content: '첫인상의 90%는 외모지! 외모가 호감이 가야 좋아지는거지!',
          },
        ],
      },
      {
        question:
          '너무 좋아했던 짝사랑이 애인때문에 \n 힘들어 헤어진다는데 나의 대답은?',
        answers: [
          {
            type: '비글',
            score: 2,
            content:
              '그래도 마지막으로 대화는 한번 더 해봐 라고 하며 위로해준다.',
          },
          {
            type: '치와와',
            score: 5,
            content: '그래! 헤어져버려!!',
          },
          {
            type: '리트리버',
            score: 10,
            content: '너 마음가는대로 해...',
          },
        ],
      },
      {
        question: '나의 이별 유형은?',
        answers: [
          {
            type: '비글',
            score: 2,
            content: '그동안 못 만난 친구들을 만나고 나만의 시간을 갖는다.',
          },
          {
            type: '포메라니안',
            score: 5,
            content:
              '새로운 사랑...? 난 이제 사랑 같은 거 못해..\n슬픔에 잠기는 스타일이다.',
          },
          {
            type: '웰시코기',
            score: 10,
            content:
              '이별의 아픔은 새로운 사랑으로 치유하는 것, 소개를 받는다.',
          },
        ],
      },
      {
        question: '오늘부터 운동을 시작한 당신! 당신의 복장은?',
        answers: [
          {
            type: '비글',
            score: 2,
            content: '운동하기 편안한 티셔츠와 신기 편한 운동화',
          },
          {
            type: '치와와',
            score: 5,
            content: '유행을 따라간 스타일의 운동복과 포인트인 운동화',
          },
          {
            type: '웰시코기',
            score: 10,
            content: '전문가의 느낌이 나는 트레이닝복',
          },
        ],
      },
    ],
    results: [
      {
        type: '해바라기',
        desc: `밀당이 뭔데? '해바라기' 그것이 바로 나야 \n
                ✓   바람이 뭐야? 해바라기 스타일\n
                ✓   한 사람에게 빠지면 그 사람만 바라보는 당신은 해바라기 같은 사람! \n
                ✓   자기도 바쁘면서 남을 도우려고 먼저 양보하는 사람! \n
                ✓   이런 사람과 만나면 늘 사람 자체만으로 힐링 된다고 하는 사람들이 많아~ \n
                ✓   가끔 사랑을 주는 것만큼 되돌려 받지 못해 서운해하거나 속상해 하는 일이 많은 사람들이야. \n
                ✓   사랑을 바라는 것뿐인데 상대방이 떠나갈 때도 있지.  \n
                ✓   사랑을 더 주는건 손해가 아닌데도 손해를 보는 것같은 느낌이 들곤 하지.\n
                ✓   모두 나와 같은 사람들이면 좋을텐데! \n
                ✓   하지만 말하지 않으면 몰라! 더 표현해야해. \n
                ✓   싫은건 싫다 좋은 건 좋다고 말해보자~ \n
                ✓   같이 사랑하는 거니까! \n
                `,
        query: 'goqkfkrl',
        score_range: range(51),
        img_src:
          'https://images.ktestone.com/resultImages/wind/wind_goqofkrl.png',
      },
      {
        type: '관심러',
        desc: `애인이 있을 땐 눈 안돌리는 스타일 \n 친화력은 100점 바람기는 50점! \n
                ✓   바람은 아니지만 주변에 관심이 많아요~ \n
                ✓   잘생긴 사람! 이쁜 사람 좋아하는 건 그냥 좋아하는 것 뿐이지 내 사랑은 너야! \n
                ✓   그들은 그들인거고! 이쁜걸 누가 안좋아해? \n
                ✓   절대 바람은 피지 않지만 속으로는 여러 관심이 많은 사람들이죠! \n
                ✓   누구나 다 그렇잖아요~ 그냥 평범한 사랑을 하는 사람들이에요! \n
                ✓   지금도 나쁘지 않죠! 잘생긴고 이쁜 건 늘 짜릿하니까\n
                ✓   내사람은 당신! 내사람을 만날 때는 최선을 다하는! \n
                ✓   다른 이성을 좋아하는건 이해가 가지 않아요!
                `,
        query: 'rhkstlafj',
        score_range: range(51, 81),
        img_src:
          'https://images.ktestone.com/resultImages/wind/wind_rhkstlafj.png',
      },
      {
        type: '매력쟁이',
        desc: `난 매력쟁이지~ \n 주변에서 날 가만히 놔두지 않아! \n
                ✓   사람을 좋아하고 놀기를 너무 좋아하는 사람들!\n
                ✓   에너지 자체가 넘치고 활기찬 사람들이야~\n
                ✓   스킨쉽도 너무 좋아하고 늘 먼저 시도하려고 하는 사람들이 많지? \n
                ✓   사람을 너무 좋아해서 그래! \n
                ✓   하지만 이런 사람들도 예민한 부분이 많다고 해. \n
                ✓   관계에 갈등이 있거나 상대방이 나에게 호감이 없다는 느낌이 오면 지나치게 감정적으로 행동하게 되어 갈등 해결을 포기하고 다른 사람을 만나기도 한데! \n
                ✓   상대방이 날 안 좋아하니 방어책을 만들어 놓기 위함이지~ \n
                ✓   외모도 출중하니까 모두에게 인기가 많아 이성접근이 쉽다는데~~~ \n
                ✓   하지만 모두 그러는 건 아니니 조심하자구~
                `,
        query: 'aofurwoddl',
        score_range: range(81, 101),
        img_src:
          'https://images.ktestone.com/resultImages/wind/wind_aofurwoddl.png',
      },
    ],
  },
  // daengdaeng
  {
    info: {
      mainTitle: '강아지로 보는 성격 테스트',
      subTitle: '내가 강아지 라면?',
      mainUrl: 'daengdaeng',
      scoreType: 'typeCounting',
      mainImage: 'https://images.ktestone.com/introImages/daengdaeng-intro.png',
      thumbImage:
        'https://images.ktestone.com/main-thumbnail/daenngdaeng-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/daengdaeng.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        question: '기분이 좋지 않을 때 난',
        answers: [
          {
            type: '포메라니안',
            score: 2,
            content:
              '기분이 좋지 않을 땐 혼자 있다가 기분이 나아 질 때 힘차게 돌아온다.',
          },
          {
            type: '치와와',
            score: 5,
            content:
              '기분이 좋지 않을 때 누군가 날 건들이면 그 사람에 대해 참고 있던 게 폭발한다. ',
          },
          {
            type: '비글',
            score: 10,
            content: '“아 몰랑! 그냥 신경 안쓸래에에에에!!!” 하고 털어낸다.',
          },
          {
            type: '리트리버',
            score: 10,
            content: '세상 살며 기분 안 좋을 일이 별로 없다.',
          },
        ],
      },
      {
        question: '다음 중 나의 연애 스타일과 가장 비슷한 것은?',
        answers: [
          {
            type: '포메라니안',
            score: 2,
            content: '애인도! 친구도! 다 같이 놀면 얼마나 좋아!? 둘 다 내꺼! ',
          },
          {
            type: '웰시코기',
            score: 5,
            content:
              '집착 싫어! 집착은NO! 알아서 잘할 게! 의심하지 말아줬으면 좋겠어!',
          },
          {
            type: '리트리버',
            score: 10,
            content: '질투는 잘 안 하는 편인데... 집착도 딱히…?',
          },
          {
            type: '치와와',
            score: 10,
            content:
              '난 질투쟁이인데? 질투를 하는 만큼 그 사람을 좋아한다는 거 아냐?\n ...넌 나만 바라봐.',
          },
        ],
      },
      {
        question: '첫 조별과제 날, 과연 나의 역할은?',
        answers: [
          {
            type: '치와와',
            score: 2,
            content: '모두를 컨트롤 하는 리더십의 끝판 왕 조장',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '화려한 나의 발표실력을 보여주지, 발표자',
          },
          {
            type: '리트리버',
            score: 10,
            content: '꼼꼼함은 필수! 자료조사',
          },
          {
            type: '포메라니안',
            score: 10,
            content: '미적 센스가 필요한 PPT',
          },
        ],
      },
      {
        question: '드디어 동창회날, 과연 나는?',
        answers: [
          {
            type: '비글',
            score: 2,
            content:
              '여기저기 아는 사람과 반갑게 인사하며, 돌아다니면서 노는 인싸 스타일',
          },
          {
            type: '리트리버',
            score: 5,
            content:
              '가만히 있어도 주변에 친구들이 먼저 다가오는 인기쟁이 스타일',
          },
          {
            type: '포메라니안',
            score: 10,
            content:
              '처음에 낯을 가려 조금 어색해하지만 금세 친해져서 재밌게 분위기를 주도하는 반인싸스타일',
          },
          {
            type: '치와와',
            score: 10,
            content:
              '아는 친구들은 많지 않지만 친한 친구들과는 재밌게 노는 스타일',
          },
        ],
      },
      {
        question: '드디어 기다렸던 주말! 나의 모습은?',
        answers: [
          {
            type: '리트리버',
            score: 2,
            content:
              '집밖은 위험해~잠을 자거나 침대 위에서 크게 벗어나지 않는 휴식',
          },
          {
            type: '웰시코기',
            score: 5,
            content:
              '집에서 쉬는 건 너무 시간이 안 가 친구들에게 만나자고 연락한다.',
          },
          {
            type: '비글',
            score: 10,
            content:
              '주말에는 무조건 나가야지! 평일에 미리 봐두었던 힙한 곳과 핫플인 곳을 찾아간다.',
          },
          {
            type: '치와와',
            score: 10,
            content:
              '평일에 받은 스트레스를 맛난 음식을 먹으며, 미뤄왔던 영화나 드라마를 본다.',
          },
        ],
      },
      {
        question: '인형을 선물 받았다. 어떤 인형일까?',
        answers: [
          {
            type: '포메라니안',
            score: 2,
            content: '극세사로 만든 복슬거리는 중간사이즈 강아지 인형',
          },
          {
            type: '비글',
            score: 5,
            content: '껴안고 자기 좋은 길다란 바나나 인형',
          },
          {
            type: '리트리버',
            score: 10,
            content: '완전 큰 곰인형',
          },
          {
            type: '웰시코기',
            score: 10,
            content: '납작해서 베개로 사용하기도 딱 좋은 원숭이 인형',
          },
        ],
      },
      {
        question: '놀이공원에서 당신의 모습은?',
        answers: [
          {
            type: '비글',
            score: 2,
            content: '귀신의 집, 롤러코스터 등 가능한 모든 기구를 다 타본다!',
          },
          {
            type: '웰시코기',
            score: 5,
            content: '딱히 불평하지 않고 다 탄다!',
          },
          {
            type: '포메라니안',
            score: 10,
            content:
              '궁금은 하지만 겁이 많아서 고민하거나 무서운 건 타지 않는다.',
          },
          {
            type: '치와와',
            score: 10,
            content: '타긴 다 타지만 힘들면 못타는 사람과 함께 쉰다.',
          },
        ],
      },
      {
        question: '맛집을 가자는 친구들의 말에 나는?',
        answers: [
          {
            type: '비글',
            score: 2,
            content: '맛집 리스트 보유자라면서 적극적으로 맛집을 홍보한다.',
          },
          {
            type: '치와와',
            score: 5,
            content: '맛집이라고 추천하는 친구의 말에 따라간다.',
          },
          {
            type: '리트리버',
            score: 10,
            content: '아무 곳이든 상관 ㄴㄴ 별생각이 없다.',
          },
          {
            type: '웰시코기',
            score: 10,
            content: '일단 만나 돌아다니며 맛집스러워 보이는 곳에 들어간다.',
          },
        ],
      },
      {
        question: '파티가 있을 때 나의 드레스 코드는?',
        answers: [
          {
            type: '비글',
            score: 2,
            content:
              '주인공은 바로 나, 돋보일 수 있게 화려하고 멋있게 최대한 꾸미고 간다.',
          },
          {
            type: '포메라니안',
            score: 5,
            content: '나의 장점을 최대한으로 이끌 수 있게 스타일링을 한다.',
          },
          {
            type: '웰시코기',
            score: 10,
            content: '깔끔하지만 스타일리시한 포인트를 준다.',
          },
          {
            type: '리트리버',
            score: 10,
            content: '기본템을 장착한 댄디하고 캐주얼한 느낌',
          },
        ],
      },
      {
        question: '내가 가고싶은 여행지 스타일은?',
        answers: [
          {
            type: '비글',
            score: 2,
            content: '네온사인 가득~ 신나는 파라다이스 놀이공원',
          },
          {
            type: '치와와',
            score: 5,
            content: '이곳의 유명한 박물관이나 건물들은 꼭 가봐야지',
          },
          {
            type: '웰시코기',
            score: 10,
            content: '꽃, 나무 자연이 가득한 여행지',
          },
          {
            type: '포메라니안',
            score: 10,
            content: '친구들끼리 프라이빗하게 놀 수 있는 수영장이 있는 풀빌라',
          },
        ],
      },
    ],
    results: [
      {
        type: '포메라니안',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                ✓   친구들에게 인기가 많은 타입으로 주변에 사람이 많고 늘 주인공 역할을 해요! \n
                ✓   활발하고 호기심이 많지만 겁이 너무 많아요 \n
                ✓   살짝 성격이 지랄맞을 때도 있는데 그럴 땐 좀 냅둬야 합니다. 풀어주려고 하지 말고 가만히 냅두세요. 알아서 풀리고 다시 기분이 좋아집니다.\n
                ✓   외모에 관심이 많은 스타일이라 스타일링을 바꾸고 싶어하지만 결국 다시 원래의 스타일로 돌아오곤 하죠.\n
                ✓   자기가 기분 좋을 땐 세상 애교쟁이, 기분 안 좋으면 세상 지랄견!\n
                ✓   사람을 너무 좋아해서 이것저것 다 퍼줍니다. \n
                ✓   기분 안 좋은 티를 숨기지 못해 가끔 표정관리가 안돼요.\n
                ✓   우리 친구들은 낯을 많이 가리는데 시간이 지나면서 친해지면 미친듯이 활기차게 놀아요.\n
                ✓   한 번 화나면 불같이 화를 내는 다혈질 적인 성격이 있어요.\n
                `,
        query: '1chqhwlqzhrfj',
        score_range: range(26),
        img_src:
          'https://images.ktestone.com/resultImages/daengdaeng/daengdaeng_pome.png',
      },
      {
        type: '치와와',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n
                ✓   뽈뽈 돌아다니면서 많은 참견을 하는 참견쟁이들\n
                ✓   내 사람은 너무너무 소중하지만, 친하지 않은 사람들에겐 낯을 많이 가려요. 경계심 대마왕이라서 섣불리 다가가면 안 됩니다. 치와와 친구들에게 시간을 주세요!\n
                ✓   질투심이 많아서 조심해야해요. 잘못걸리는 순간 그날은 전쟁입니다. 나에게 질투에 대한 실험을 하지마세요!!\n
                ✓   불의를 보면 물불 가리지 않는 겁이 없는 용감한 용자! 으르렁!\n
                ✓   스트레스는 음식으로 풀 때가 있어요.\n
                ✓   내사람을 너무 좋아하다 보니 가끔 외로움을 많이 타요.\n
                ✓   눈치가 빨라서 행동에 막힘이 없이 재빠르게 움직이는 부지런쟁이들입니다.\n
                ✓   기부니가 좋을 땐 애교쟁이><기분이가 안 좋을 땐 까칠쟁이 -_-\n
                ✓   가끔 앞 뒤가 다른 사회생활 만랩이랍니다~\n
                ✓   참는 걸 극도로 싫어해요.`,
        query: '2tjsxorgudwlqzhrfj',
        score_range: range(26, 51),
        img_src:
          'https://images.ktestone.com/resultImages/daengdaeng/daengdaeng_chiwawa.png',
      },
      {
        type: '웰시코기',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n
                ✓   상당히 활동적인 스타일, 열정이 가득해요. 허허..\n
                ✓   가끔 에너지가 너무 넘쳐나서 가끔 주변인들이 감당하기 힘들어요\n
                ✓   집에 있으면 시간이 너무 안가는 느낌, 밖을 나가야합니다.\n
                ✓   날 가두는 느낌 너무 힘들어.. 나에게 집착하지 말아줄래? 난 자유로운 영혼이거든! 알아서 잘하니까 너무 의심하거나 집착하지 말아주세요.\n
                ✓   하는 행동에 있어 단단함이 있고 자신감이 넘쳐나지만 친절해서 주위에 사람들이 넘쳐나요. 학생 시절엔 반장, 부반장의 경험 많죠. \n
                ✓   저 사람은 너무 순딩해하는 소리를 많이 듣지만 사실 나는 단호박입니다. \n
                ✓   눈으로 말을 하는 것 같은..뭔가…매력이 있어….왜지…눈으로 사람 설레게 하기 만랩\n
                ✓   이성에게 인기가 많은 타입이에요. 난 그냥 이 사람을 도와준 것 뿐인데 보면 저를 좋아하고 있더라구요. 왤까요? 하면서 다른 사람이 가지지 못한 마성의 매력`,
        query: '3ehfdusqusdlwlqzhrfj',
        score_range: range(51, 75),
        img_src:
          'https://images.ktestone.com/resultImages/daengdaeng/daengdaeng_wealthy.png',
      },
      {
        type: '리트리버',
        desc: `친숙한 댕댕이 , 리트리버\n
                ✓   순한 성격의 소유자, 이런들 어떠하리~ 저런들 어떠하리~ 이해심이 넘쳐나요\n
                ✓   사람을 잘 따르고 상냥한 친절한 성격을 지니고 있어 주변에서 은근 좋아하는 이성이 많아요. 하지만 본인은 모르죠. 주인공아닌가요? \n
                ✓   어쩜 이렇게 화를 안낼까 하지만 화를 내면 세상 제일 무서워요.\n
                ✓   은근 아무것도 하기 싫고 잘 누워있는 편이라 주변에서 놀자고 해야해요. 아니면 집 밖에 잘 안나오려고 해서… 누군가라도 만나자고 하면 일단 만나니까 놀아줘요.\n
                ✓   순둥순둥해서 걱정하겠지만 은근히 모든 걸 다 잘해냅니다.\n
                ✓   한 번 놀 때 미친듯이 놀면 적당한 휴식이 꼭 필요해요\n
                ✓   사람 자체가 너무 편안해서 같이 있으면 그냥 행복해지는 에너자이저~\n
                ✓   인내심 최강자이면서 예의도 바르지만 또 장난꾸러기 하지만 머리도 좋아 뭐야 못하는게 뭐야~~완전 워너비 애인상이로구나!`,
        query: '4vmfhwlqzhrfj',
        score_range: range(76, 101),
        img_src:
          'https://images.ktestone.com/resultImages/daengdaeng/daengdaeng_retriever.png',
      },
      {
        type: '비글',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n
                ✓   체력이 대단하다고 생각할 정도로 미친 텐션의 소유자 비글!\n
                ✓   영리하고 머리가 좋아요~ 그래서 눈치도 수준급! \n
                ✓   여기저기 맛집 탐방을 좋아하고 음식을 너무 좋아해요. 맛집 물어보면 왠만한 건 다 알정도로 아주 맛집 리스트를 가지고 있죠.\n
                ✓   악마견이라 불리지만 사실은 에너지가 넘쳐나고 사람을 너무 좋아해서 관심을 달라는 말이에요. \n
                ✓   외로움을 많이 타서 늘 밖으로 나가고 싶어해요. 누구든 만나서 힘차게 놀아야 외롭지 않거든요. 혼자 있는 거 너무 싫어!!! 스트레스 만땅!\n
                ✓   노는게 제일 좋아~ 친구들 모여라~ 언제나! 즐거워!\n
                ✓   될 대로 돼라~~~라는 마인드 늘 긍정적이고 낙천적인 마인드의 소유자`,
        query: '4vmfhwlqzhrfj',
        score_range: range(76, 101),
        img_src:
          'https://images.ktestone.com/resultImages/daengdaeng/daengdaeng_beagle.png',
      },
    ],
  },
  // zipkok
  {
    info: {
      mainTitle: '프로 집콕러 테스트',
      subTitle: '이시국엔 집에 붙어 있자 꼭!',
      mainUrl: 'zipkok',
      scoreType: 'numberScoring',
      mainImage: 'https://images.ktestone.com/introImages/zipkok-intro.png',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/zipkok-thumb.png',
      lang: 'Kor',
      category: 'etc',
    },
    questions: [
      {
        question: '주말에 만나자는 친구! \n 하지만 집밖은 위험해! 나의 답장은?',
        answers: [
          {
            type: '사자',
            score: 2,
            content: '그래도 친구가 오래간만에 만나자니 나간다.',
          },
          {
            type: '너구리',
            score: 5,
            content: '귀찮지만 뭐하는 지에 따라 나갈지 말지 결정한다.',
          },
          {
            type: '펭귄',
            score: 10,
            content: '귀찮은 건 no! 약속 있는 척하고 집에서 쉰다.',
          },
        ],
      },
      {
        question: '나에게 소확행이란?',
        answers: [
          {
            type: '사자',
            score: 2,
            content: '남자친구와 주말에 걸어다니면서 소소한 데이트하기',
          },
          {
            type: '너구리',
            score: 5,
            content: '친구들이랑 다같이 모여서 캠핑이나 1박 여행을 간다.',
          },
          {
            type: '펭귄',
            score: 10,
            content:
              "행복이란 \n '내침대에 따뜻한 전기장판과 부드러운 극세사 이불을 덮고 밀린 영화나 드라마를 귤을 까먹으면 보는 것'..",
          },
        ],
      },
      {
        question: '아무런 약속이 없는 주말, 뭐할까?',
        answers: [
          {
            type: '사자',
            score: 2,
            content: '밥먹고 샤워를 한 뒤 밀린 방 청소나 할일을 찾아 움직인다.',
          },
          {
            type: '너구리',
            score: 5,
            content: '밥먹고 세수와 양치만 하고 쇼파에 앉아 티비를 본다.',
          },
          {
            type: '펭귄',
            score: 10,
            content: '양치만 하고 누워서 넷플릭스나 왓챠를 켜본다.',
          },
        ],
      },
      {
        question: '드디어 금요일, 주말에 뭐할까?',
        answers: [
          {
            type: '사자',
            score: 2,
            content:
              '집에 있는 건 너무 심심해! \n 친구들한테 만나자며 먼저 연락한다.',
          },
          {
            type: '너구리',
            score: 5,
            content:
              '집에 있다가 만나자는 연락을 받으면 저녁에 슬슬 기어나간다.',
          },
          {
            type: '펭귄',
            score: 10,
            content:
              '주말에..왜 약속을.. \n 난 쉴랭! 굳이 먼저 약속을 따로 잡지 않는다.',
          },
        ],
      },
      {
        question: '집에 있을 때 나의 모습은?',
        answers: [
          {
            type: '사자',
            score: 2,
            content:
              '으 시간이 왜 이렇게 안가... \n 주변 친구들에게 연락해서 나갈 각 잡기',
          },
          {
            type: '너구리',
            score: 5,
            content: '낮엔 자고 밤에 나가야지~ \n 8.9시쯤 나갈 준비를 해본다.',
          },
          {
            type: '펭귄',
            score: 10,
            content: '밀린 드라마나 영화가 너무 많아... \n 하루가 너무 짧다.',
          },
        ],
      },
      {
        question: '배달 앱 속 나의 등급은?',
        answers: [
          {
            type: '사자',
            score: 2,
            content: '배달은 별로, 난 맛집 탐방가임',
          },
          {
            type: '너구리',
            score: 5,
            content: '그냥 가끔 집에 있거나 친구들이랑 파티할 때 시킴',
          },
          {
            type: '펭귄',
            score: 10,
            content: 'vip 이하로 떨어져본적이 없수다',
          },
        ],
      },
      {
        question: '갑자기 일이생겨 못만난다고 하는 친구!',
        answers: [
          {
            type: '사자',
            score: 2,
            content: '아,,어쩔 수 없지 하면서 바로 만날 친구에게 전화를 건다.',
          },
          {
            type: '너구리',
            score: 5,
            content:
              '밖에 있으면 누구라도 나오겟지. \n 카페나 pc방에서 친구들을 부른다.',
          },
          {
            type: '펭귄',
            score: 10,
            content: '안타까워하는 척 하면서 집에서 쉴 생각에 기분이 좋아진다.',
          },
        ],
      },
      {
        question: '집에만 있으니 심심한 당신!\n생각에 빠지게 되는데...',
        answers: [
          {
            type: '사자',
            score: 2,
            content:
              '휴 이렇게 집에만 있으니 좀 그렇다..\n 라고 생각하며 당장 뭘 배워야할지 찾아보고 상담예약을 잡아본다.',
          },
          {
            type: '너구리',
            score: 5,
            content:
              '당분간은 계속 집에 있어야하는데.. \n 먹을 간식과 생필품을 구입한다.',
          },
          {
            type: '펭귄',
            score: 10,
            content:
              '나는.. 아무생각이 없다. \n 왜냐면 아무생각이 없기 때문이다.',
          },
        ],
      },
      {
        question: '약속 시간 전 당신의 모습은?',
        answers: [
          {
            type: '사자',
            score: 2,
            content:
              '옷은 전 날에 미리 골라놨으니 메이크업, 헤어 등 섬세하게 꾸민다',
          },
          {
            type: '너구리',
            score: 5,
            content:
              '약속 2시간전 부터 일어나서 준비중이지만 세월아 네월아한다.',
          },
          {
            type: '펭귄',
            score: 10,
            content:
              '아 귀찮다...나가지말까 \n 하고 늘어져있다가 시간을 보고 재빨리 준비한다.',
          },
        ],
      },
      {
        question: '친구들과의 약속이 있을때 나의 모습은?',
        answers: [
          {
            type: '사자',
            score: 2,
            content: '10분 전에 미리 도착해 친구들을 기다린다.',
          },
          {
            type: '너구리',
            score: 5,
            content: '조금 늦는다고 친구들이 다 가있을때 그 장소로 간다.',
          },
          {
            type: '펭귄',
            score: 10,
            content:
              '진짜 되도록 안나가지만 굳이 약속이 있다면 정각에 도착한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'Level 1. 초보 집콕러',
        desc: '집에 있으면 있지만 심심하면 집 밖을 나가고 싶어하는 \n 초보집콕러~ \n 아직은 집이랑 서먹서먹한 42~ \n 이틀이상 집에 있으면 나가고 싶어서 안달난 마치 라푼젤같은 그대! \n 집에 있다가도 나오라면 바로 나오는 오케이맨~!',
        query: 'chqhwlqzhrfj',
        score_range: range(26),
        img_src:
          'https://images.ktestone.com/resultImages/zipkok/zipkok_chqhwlqzhrfj.png',
      },
      {
        type: 'Level 2. 선택형 집콕러',
        desc: '주말엔 집에서 쉬는게 최고지만~~ \n 날 만나고 싶다면 ~ 나가줘야지~~~~ 뭐할건데? \n 꼭 재밌는 일이 있으면 무조건 튀어나가는 그대는 바로 \n 선택형 집콕러! \n 당신은 집에 있는 걸 좋아하지만 선택장애의 면모가 리를 빗 \n 있기때문에 친구들의 설득과 흥미 딘딘한 일이 있으면 \n 바로 집에서 튀어나가버리곤 하지~~',
        query: 'tjsxorgudwlqzhrfj',
        score_range: range(26, 51),
        img_src:
          'https://images.ktestone.com/resultImages/zipkok/zipkok_tjsxorgudwlqzhrfj.png',
      },
      {
        type: 'Level 3. 돌연변이 집콕러',
        desc: '극과극인 정말 집콕하고 싶을때 집에서 절대 나오지 않z...! \n 하지만 어느순간 또 약속 줄줄 잡아 버리는 그대! \n 성수기와 비성수기가 확실한 당신은 바로 \n 돌연변이 집콕러! \n 집에서도 밖에서도 최선을 다해 노는 당신! \n 한번 놀고 나면 꼭 충분한 휴식이 필요하지~',
        query: 'ehfdusqusdlwlqzhrfj',
        score_range: range(51, 75),
        img_src:
          'https://images.ktestone.com/resultImages/zipkok/zipkok_ehfdusqusdlwlqzhrfj.png',
      },
      {
        type: 'Level 4. 프로 집콕러',
        desc: '집이 곧 나의 삶의 전부일세, 당신은 바로 \n 프로 집콕러~ \n 집을 왜 나가~~ 집밖은 위험해~ \n 주변에 아무리 불러도 순순히 나가지 않지!! 마치 나는 신비주의~!! \n 하지만 그냥 나가기 귀찮은 사람 ^^ \n 개인적인 시간은 필수! 집에서 나가는 것 조차 큰 결심이 필요해요~ \n 집에 놀거리 가득하면 집 밖에 더더욱 안나갈 자신이 있어~~~~~',
        query: 'vmfhwlqzhrfj',
        score_range: range(76, 101),
        img_src:
          'https://images.ktestone.com/resultImages/zipkok/zipkok_vmfhwlqzhrfj.png',
      },
    ],
  },
  // kakaoMobility
  {
    info: {
      mainTitle: '운전 MBTI',
      subTitle: '운전 MBTI',
      mainUrl: 'driveTest',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/test-thumb.png',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      lang: '',
      category: 'etc',
    },
    questions: [
      {
        which: 'EI',
        question: '차가 있는 데 급 만나자는 친구!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '오케이 마시다가 대리하는 거지~',
          },
          {
            type: 'I',
            score: 5,
            content: '앗! 오늘은 무리야 차가 있어서~',
          },
        ],
      },
      {
        which: 'SN',
        question: '길안내가 의심스러울 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '에이… 별일 있겠어? 믿고 내비대로 간다!',
          },
          {
            type: 'N',
            score: 5,
            content: '머야? 오류인가봐!! 내비를 무시하고 내 뜻대로 간다',
          },
        ],
      },
      {
        which: 'TF',
        question: '나는 어떤 사람?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '내 차 안의 모든 기능을 다룰 수 있다.',
          },
          {
            type: 'F',
            score: 5,
            content: '쓰는 것만 사용한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '드라이브 가자!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '드라이브하기 좋은 명소 찾은 후 내비 찍고 이동하기',
          },
          {
            type: 'P',
            score: 5,
            content: '안전운전 키고 일단 운전하며 목적지 정하기',
          },
        ],
      },
      {
        which: 'EI',
        question: '차 안에서 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '조용한 건 싫다! 통화하거나,\n신나게 노래 부르면서 탄다.',
          },
          {
            type: 'I',
            score: 5,
            content:
              '할 말 미리 준비해두고 전화한다.\n엔진소리를 느끼며 홀로 드라이브를 즐긴다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '운전할 때 끼어드는 차량을 보면',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '아놔..진짜 저@#$%$#!\n누군 안 급한가? 왜 끼어들어?',
          },
          {
            type: 'N',
            score: 5,
            content: '오…화장실 마려운 감?\n허허허 많이 급한 가 보군',
          },
        ],
      },
      {
        which: 'SN',
        question: '사고가 난 장면을 보고 제일 첫번째로 드는 생각은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '누구의 과실일까 먼저 생각한다.',
          },
          {
            type: 'N',
            score: 5,
            content: '안 다쳤나?를 먼저 생각한다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '내비를 쓰는 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '즐겨찾기에 온갖 맛집,\n여행지 다 저장하는 스타일',
          },
          {
            type: 'N',
            score: 5,
            content: '집, 회사 끝! 정리는 귀찮아~ㅎㅎ',
          },
        ],
      },
      {
        which: 'SN',
        question: '차를 고를 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '옵션, 연비, 성능까지 꼼꼼하게 체크해 봐야 지!',
          },
          {
            type: 'N',
            score: 5,
            content: '일단 내 마음에 쏙 든다! 이거 지~ 너로 정했다',
          },
        ],
      },
      {
        which: 'TF',
        question: '앞에 차들이 느릿느릿 갈 때 드는 생각은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '사고 났나? 도로가 공사 중인가?',
          },
          {
            type: 'F',
            score: 5,
            content: '아니 앞 차는 뭐하나~~~ 왜 이리 안가는 겨!',
          },
        ],
      },
      {
        which: 'EI',
        question: '카풀 할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '사람만나는 거 완전 짜릿하고 재밌어!',
          },
          {
            type: 'I',
            score: 5,
            content: '어우 어색하고 기 빨리는 기분',
          },
        ],
      },
      {
        which: 'SN',
        question: '나의 차상태는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '깔끔하게 관리한다. 언제나 세차도 정성스럽게…',
          },
          {
            type: 'N',
            score: 5,
            content: '차는 차일 뿐! 청소도 내가 하고싶을 때 한다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '운전 하다가 경미한 사고가 났다는 친구!',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '괜찮아? 보험은 잘 들어 놨지?',
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮아? 아고 조심하지ㅜㅜ 다친 곳은?',
          },
        ],
      },
      {
        which: 'JP',
        question: '운전 알려주라! 라는 친구의 부탁',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '운전 하는 법을 세세하게 옆에서 알려준다.',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 하면 느는거야~',
          },
        ],
      },
      {
        which: 'JP',
        question: '나의 여행 스타일은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '계획한 여행이 고생도 안하고 편하고 좋지~',
          },
          {
            type: 'P',
            score: 5,
            content: '가끔은 그냥 훌훌 떠나보자~ 차박도 추억이지~',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ISTJ',
        desc: `“이게 코너링이 아주 기본적이면서도~” 기본적인거 잘하고 운전자 준수 잘 지키는 생충이 아빠 기택\n\n

                나의 운전 스타일은?\n
                늘 차분하고 착실한 모범생 같은 당신은! 운전 또한 차분하고 규칙에 대해 매우 엄격하게 생각하기 때문에 굉장히 규칙적으로 운전합니다! 차분하지만 운전을 잘해서 반전 매력이!? 하지만 사람이 많은 곳을 가면 금방 지치기 때문에 되도록이면 혼자 운전 하는 것을 좋아해요! 카플도 나와 비슷한 사람! 약속시간 잘 지키고 예의가 바른 사람이랑은 할 것 같아요!\n\n
                
                이런 건 어때요?\n
                침착하고 차분하게 운전하는 당신! 워낙 안전 운전과 동시에 신호 하나라도 철저하게 지키고 있군요! 하지만 당신과 함께 가는 친구나 지인 동료는 조금 당신을 재미없다고 생각 할 수도 있어요! 정말 너무 잔잔하고 조용한 당신과 당신의 차…상대방은 조수석에 졸면 안 된다는 생각을 하고 있는데 말이죠…! 말도 없고 잠이 쏟아지는 상대방을 위해 가끔은 신나는 분위기의 음악이나 대화를 나누는 건 어떨까요?\n\n
                
                같이 드라이브하면 좋은 친구\n“마포대교는 무너졌냐” 내비는 저리가라, 내가 바로 인간 내비 곽철용이\n\n
                드라이브하다가 뛰어내릴 친구\n“자…운전이라는 건 말이야~...” 입으로는 운전 박사 급 납득이`,
        query: 'todcnddl',
        img_src: 'https://images.ktestone.com/resultImages/',
      },
      {
        type: 'ESFP',
        desc: `“아아가서 드라이브 한잔하지” 흥 넘치고 드라이브 좋아하는 안상구\n\n

                나의 운전 스타일은?\n
                졸음운전은 저리 가라! 나와 함께 있으면 시끄럽고 왁자지껄한 분위기 속에서 신나게 놀 수 있어요! 운전도 안전하게! 하지만 플레이리스트는 신나는 곡으로 선정! 여행을 갈 때도 계획 보다는 즉흥으로! 조용한 분위기 보다는 신나는 분위기가 너무 좋아요! 하고 싶은 건 무조건 해야하는 ! 그래서 성격이 조금 급하기도 하고 충동적인 물건 구매도 많아요! 그래서 여행만 다녀오면 트렁크에 짐이 한가득~?\n\n
                
                이런 건 어때요?\n
                당신이 조수석에 앉아있는 동승자가 된다면 조금 조용한 걸 좋아하고 내성적인 사람이 엄청 부담스러워할 수도 있어요! 운전자는 조용하게 가고 싶은데 신나는 거 좋아하는 당신이라 떠들기도 좋아하고 정적을 싫어하는 것도 알지만 운전하는 가장 중요한 사람이 어떤 사람인지 파악하고 행동해주세요! 졸음 운전은 안 하긴 하는데...귀에서 피가 날 수도..\n\n
                
                같이 드라이브하면 좋은 친구\n”이게 코너링이 아주 기본적이면서도~” 기본적인거 잘하고 운전자 준수 잘 지키는 생충이 아빠 기택\n\n
                
                드라이브하다가 뛰어내릴 친구\n“지금까지 이런 초보는 없었다. 이것은 엑셀인가 브레이크인가…?” 몰라도 일단 운전하는 게 즐거운 고반장`,
        query: 'dkstkdrn',
        img_src: 'https://images.ktestone.com/resultImages/',
      },
      {
        type: 'ENFP',
        desc: `“지금까지 이런 초보는 없었다. 이것은 엑셀인가 브레이크인가…?” 몰라도 일단 운전하는 게 즐거운 고반장\n\n
                나의 운전 스타일은?\n
                계획적인 거 싫어하는 나는 야 자유로운 영혼! 그래서 이리저리 돌아다니는 것도 너무 좋아요! 자동차만 있다면 언제든지 좋아하는 내 친구들과 함께 창문 다~ 열어 놓고 바람을 느끼면 신나는 노래와 떠나고 싶어요! 하지만 가끔은 귀찮을 때도? 늘 트렁크엔 집이 한가득! 그냥 떠나는 상황 자체를 너무너무 즐겨요! 감정이 풍부하고 충동적이며 좋고 싫음이 티나는 성격입니다!\n\n
                
                이런 건 어때요?\n
                너무 즉흥적이고 자유로운 당신은 고민하지 않고 바로 실천해버리는 성격입니다! 정작 해야 할  당신의 일들을 잊어버리고 떠나는 그런 행동은 이제 그만해야 합니다. 반복적이고 지루한 일상이 싫다고 떠나버리는 행동은 회피입니다! 이제 우선순위를 정해서 하나하나 실천하세요! 갑자기 떠나는 당신! 늘 차량점검도 미리미리 해요! 도로위에 멈춘 차를 보면서도 이또한 추억이지~ 하고 넘어가지 말도록!!!! 차량점검은 늘 필수!\n\n
                 
                같이 드라이브하면 좋은 친구\n”아직 1KM 더 남았다" 겉으로는 냉정해보이지만 속으론 따뜻한 놤좌 차태시기\n\n
                드라이브하다가 뛰어내릴 친구\n꼭 그렇게 운전해야만 속이 후련했냐!?” 끼어드는 차들 때문에 화가나는 오태시기`,
        query: 'rhqkswkd',
        img_src: 'https://images.ktestone.com/resultImages/',
      },
      {
        type: 'INTJ',
        desc: `“니가해라. 운전” 고집이 강력한 츤데레 동수\n\n

                나의 운전 스타일은?\n
                내가 좋아하는 건 한없이 무한 집중력의 끝판왕을 보여주는 고집쟁이인 당신! 내 사람들에겐 선의를 베풀고 잘해 주지만 선 넘으면 그냥 다시는 보지 않아요! 친절하기도 하기도 하고 매정할 때도 있어요! 그래서 차를 태워 달라고 하는 직장 동료의 말에 태워 주기는 하지만 선이 넘거나 좀 신경에 거슬리면 웃고 있긴 하지만 속으로는 욕하고 있기도 합니다. 친한 사람과 함께 운전을 할 때 아닌 것 같은 일엔 놀러가는 상황이든 어떤 상황이든 그건 아니지 않니? 하고 분위기를 차갑게 만들 수도…?\n\n
                
                이런 건 어때요?\n
                워낙 남 눈치를 보지 않는 사람이라 기분이 나쁠 땐 대놓고 싫다고 말하며 상대방 기분보다 잘못된 팩트에 관해서 얘기하는 경향이 있어요! 당신이 화가 다 풀려서 다시 기분이 좋아져도 상대방은 당신의 말로 인해서 이미 기분이 좋지 않은 상황들이 생기곤 합니다. 늘 나의 감정이 앞서길 보다 주위 사람들을 잘 신경 쓰도록 해야 해요! 특히 이런 상황이 놀러갈 때 생긴다면 완전 최악~ \n\n
                
                같이 드라이브하면 좋은 친구\n“드루와~ 드루와~들어올 수 있으면 드러와이~!” 끼어들기 절대 안 봐주는 정청이\n\n
                드라이브하다가 뛰어내릴 친구\n“누구든 내 옆자리에만 앉아~ 운전은 내가 한다” _ 불편한 친구가 없어요 왜냐면 운전은 내가 할 거니까`,
        query: 'ehdtn',
        img_src: 'https://images.ktestone.com/resultImages/',
      },
      {
        type: 'ISTP',
        desc: `“꼭 그렇게 운전해야만 속이 후련했냐?!”\n끼어드는 차들 때문에 화가나는 오태시기\n\n나의 운전스타일은?\n
                늘 간섭 받기 싫어하는 당신! 평상시엔 조근조근 하지만 나를 간섭하면 화가 나요. 기계를 잘 다루고 관심이 많아서 차도 너무 좋은데 다만 조금 귀찮아요… 하지만 막상 운전하면 역시 직접 해봐야지 하면서 차 자체, 하나하나를 다 느껴요. 장거리 운전도 별로 힘들어하지 않아요! 좋아하는 노래 틀어 놓고 혼자 운전을 즐기는~? 하지만 워낙 귀찮아 해서 나가기 까지가 힘들죠! 차안에서 누군가와 있어도 정적을 어색해하지 않고, 꽂히는 건 무조건 해야 하는 사람입니다!\n\n
                이런 건 어때요? \n
                막상 운전을 하면 당신은 너무 재밌어서 매번 운전을 하고 싶어해요. 운전에 관심이 생기면 친구 옆에 앉아서 하나하나 다 관찰하고 차에 대해서 미친듯이 파고들곤 하죠. 운전할 때 스릴을 즐기는 편이라 음악 크게 틀고 속도를 즐기는 데 … 늘 사고가 날 수 있다는 생각으로 안전운전 해주세요! 스트레스 받는 다고 운전으로 해소하는 건 절대 no! 
                \n\n
                같이 드라이브하면 좋은 친구\n“여러분~ 미안해요...이렇게 밖에 할 수 없는 나~ 용서해줘~” 운전만 하면 미안한 일 많아지는 엽기적인 그녀\n\n
                드라이브하다가 뛰어내릴 친구\n“니가해라. 운전” 고집이 강력한 츤데레 동수

                `,
        query: 'dhxotlrl',
        img_src: 'https://images.ktestone.com/resultImages/',
      },
      {
        type: 'ESFJ',
        desc: `“여러분~ 미안해요...이렇게 운전할 수밖에 할 수 없는 나~ 용서해줘~” 운전만 하면 미안한 일 많아지는 엽기적인 그녀!\n\n

                나의 운전 스타일은?\n
                당신은 완전 분위기 메이커! 친구, 가족 내 사람 엄청 잘 챙기고 좋아해요! 그래서 다같이 놀러 다니는 것도 좋아하고 다같이 노는 거 너무 좋아해요! 하지만 걱정이 많아요! 여행을 시작하면 누구보다 재밌게 놀고 음악 크게 틀고 신나게 놀지만 떠나기 전엔 우리 사고…괜찮겠지 하면서 일어나지도 않을 일에 대해서 걱정하죠…하지만 잠시뿐이 랍니다! 그리고 누군가 나의 운전 실력을 칭찬해주면 기부니가 좋아지죠~ 넌 오늘 집에 데려다준다^^\n\n
                
                이런 건 어때요?\n
                너무 즐거운 거 즉흥적인 거 내가 좋은 걸 너무 원하는 사람이라 계획적인 것이 필요합니다! 일이 안 풀리거나 답답할 때 모든 걸 던지고 떠나는 행동! 아주 가끔 정말 특별한 날 아니고 서는 제발 그 행동은 이제 그만! 나머지 당신의 일을 처리하는 것도 당신의 몫입니다!!! 매번 놀러 다니는 것도 좋지만 우리 좀 자중해요~ 그리고 너무 신나서 운전 할 때 미안할 일이 많아지죠 “끼어들어서 미안해요~ 어머 미안해요~” 운전은 조심 또 조심해요!\n\n
                
                같이 드라이브하면 좋은 친구\n“ 너는 다 계획이 있구나?” 언제 어디로 어떻게 갈지 큰그림 그리는 생충이 아들 기우\n\n
                드라이브하다가 뛰어내릴 친구\n”아직 1KM 더 남았다" 겉으로는 냉정해보이지만 속으론 따뜻한 놤좌 차태시기`,
        query: 'duqrlwjrdls',
        img_src: 'https://images.ktestone.com/resultImages/',
      },
      {
        type: 'ENFJ',
        desc: `“자...운전이라는 건 말이야~..” 입으로는 운전 박사 급 납득이\n\n

                나의 운전 스타일은?\n
                대인관계가 좋고 주변에 인기가 많으며 사람을 좋아하고 잘 믿어요! 나보다 남을 먼저 생각하는 성격이고 갈등상황을 싫어해서 인지 억지로 끼어드는 차량도 웬만하면 다 받아줍니다. 하지만 나에게 고맙다고 깜빡이를 하지 않으면 급 울컥해요! 하지만 이내 다시 또 신나는 음악이나 발라드를 따라 부르면서 속상했던 순간을 잊어버리곤 합니다! 운전만 할 수 있다면! 차만 있다면 언제든지 친구들을 픽업하고 놀러 다니면서 힐링~\n\n
                
                이런 건 어때요?\n
                갑자기 순간 욱하는 당신의 성격! 친구가 있어도 누가 있어도 욱하거나 분노가 치밀어 오르면 화를 내는 당신! 안 그러던 사람이 그러면 주변에서 당신의 눈치를 보게 될 수도…? 늘 친절하고 상냥하지만 기복이 롤러코스터 같은 사람입니다! 또 친한 사람의 거절을 못하는 사람이라 주변에서 가는 김에 태워줘! 라는 부탁도 거절할 줄 알아야합니다! 매번 들어주는 거 곤란해요!’\n\n
                
                같이 드라이브하면 좋은 친구\n“날 지나가랏…” 여유 넘치는 운전자 최재현 중위\n\n
                드라이브하다가 뛰어내릴 친구\n“이게 코너링이 아주 기본적이면서도~” 기본적인거 잘하고 운전자 준수 잘 지키는 생충이 아빠 기택`,
        query: 'skqemrdl',
        img_src: 'https://images.ktestone.com/resultImages/',
      },
      {
        type: 'INFP',
        desc: `“…너나 잘하세요…” 들릴 듯 말듯 소심한 의견 한 스푼 금자씨\n\n
                나의 운전 스타일은?\n
                완벽하게 하지 못할 거 라면 시작하지 않아요. 남들에 가치관에 대해서 뭐라하지 않는 만큼 나의 가치관 가지고 뭐라고 하면 싫어요. 그만큼 나의 세계는 확고해요! 남들이 좋다고 해도 내 맘에 들지 않으면 하지 않죠! 내 개인적인 얘기 싫어하고 공간에 침범하는 것도 싫어서 운전은 되도록 혼자 하는 게 편하고 남들에게 관심도 없어요! 차량에 문제가 생긴다면 하나하나 다 조사하고 알아보지만 결국 카센터에 맡기는 시작은 열심히 하지만 끝은 흐지부지~\n\n
                
                이런 건 어때요?\n
                남에게 의지하는 것도 의지하기도 싫어하는 당신이라 스트레스 받거나 화가 났을 때 혼자 있어야하는 사람입니다! 생각이 많아서 운전에 집중이 잘 안되고 가끔 멍 때릴 때가 있는데… 너무 생각이 많고 힘이 든다면 운전대를 잡지 말고 가끔은 친구들과 함께 스트레스를 풀러 떠나보는 건 어떨까요? 친구가 운전하는 차를 타고 힐링을 하러 떠나봐요!\n\n
                
                같이 드라이브하면 좋은 친구\n“자…운전이라는 건 말이야~...” 입으로는 운전 박사 급 납득이\n\n
                드라이브하다가 뛰어내릴 친구\n“운전대를 잡을 명분이 없다 아임니까 명분이” – 사전 계획 철저하고 냉정한 형배`,
        query: 'rmqwkTl',
        img_src: 'https://images.ktestone.com/resultImages/',
      },
      {
        type: 'ISFJ',
        desc: `“너는 다 계획이 있구나?” 언제 어디로 어떻게 갈지 큰 그림 그리는 생충이 아들 기우\n\n나의 운전 스타일은?\n
                계획 없인 차를 잘 몰 일이 없습니다! 어디로 가야할 지 내비보고 착실 하게 가는 당신! 외로움을 많이 타긴 하지만 많은 사람들이랑 있는 건 싫어서 친한 친구나 지인들만 내 차에 탑니다! 성격이 대체로 온화하고 배려심이 깊어 장거리 운전을 해도 혼자 그냥 묵묵히 잘 견뎌내는 스타일! 그래서 인지 여행갈 때도 내 차 끌고 가니까 내가 들고 갈게 하며 깊은 배려심으로 주변 사람들을 편안하게 해줍니다.\n\n
                
                이런 건 어때요?\n
                워낙 싫은 소리를 못해서 무조건 응~ 괜찮아 라고 하지만 힘들면 힘들다고 말해봐요! 나의 의견을 확실하게 전달할 수 있어야해요! “나 지금 운전 좀 힘드니 바꿔줄래?”라고 말하면 아마 당신의 노고를 안 모두가 “그래!” 라고 흔쾌히 답할 것입니다! 만약 싫어하는 내색을 보이는 사람은 멀리하세요! 이제 너무 혼자 배려하는 마음은 좀 접어두고 당신도 편하게 여행해봐요!\n\n
                
                같이 드라이브하면 좋은 친구\n”아아가서 드라이브 한잔하지" 흥 넘치고 드라이브 좋아하는 안상구\n\n
                드라이브하다가 뛰어내릴 친구\n“…너나 잘하세요…” 들릴 듯 말듯 소심한 의견 한 스푼, 금자씨`,
        query: 'rldn',
        img_src: 'https://images.ktestone.com/resultImages/',
      },
      {
        type: 'ESTP',
        desc: `“마포대교는 무너졌냐” 내비는 저리가라, 내가 바로 인간 내비 곽철용이\n\n
                나의 운전 스타일은?\n
                쉽게 욱하고 금방 잊어버리는 다혈질인 당신! 즉흥적이고 순발력이 좋으며 임기응변이 뛰어납니다. 그래서인지 내비를 보다 가도 혼자 나만의 길을 개척해서 나가는 스타일! 스릴 넘치는 것도 좋아하고 내기도 너무 좋아하는 스타일이라 운전에도 재미를 잘 느낍니다! 내기와 같은 스릴을 좋아하고 경쟁심이 있어서 운전 또한 누구보다 내가 잘해야합니다.\n\n
                
                이런 건 어때요?\n
                돌려 말하는 사람을 답답해하는 당신! 또한 표현이 직설적이어서 운전은 내가 제일 잘 해야 하면서도 누군가 운전을 못하는 친구가 있으면 살짝 무시하는 경향이 있어요! 우리 표현을 조금만 돌려서 말해봐요~ 소비 습관이 남다른 당신은 또 차에 관해 좋다고 하면 무조건 사야해~ 하는 당신의 소유욕 이젠 줄이도록 해보아요.\n\n
                
                같이 드라이브하면 좋은 친구\n“너는 다 계획이 있구나?” 언제 어디로 어떻게 갈지 큰 그림 그리는 생충이 아들 기우\n\n
                드라이브하다가 뛰어내릴 친구\n“자…운전이라는 건 말이야~..” 입으로는 운전 박사 급 납득이`,
        query: 'rhkrcnfdyddl',
        img_src: 'https://images.ktestone.com/resultImages/',
      },
      {
        type: 'INFJ',
        desc: `"아직 1KM 더 남았다" 겉으로는 냉정해보이지만 속으론 따뜻한 놤좌 차태시기\n\n

                나의 운전 스타일은?\n
                내가 비록 피곤할지라도 남을 먼저 배려하는 게 마음이 편한 당신! 그래서 어딜 갈 때 누가 운전하지? 하면 다들 곤란해할 때 비로소 본인이 손을 들어요! 감정이 욱해질 땐 혼자 생각할 시간이 필요해 가끔은 혼자 드라이브를 즐기며 심신안정의 시간이 필요하곤 하죠! 친하고 내 마음 맞는 사람들이랑 함께 하는 걸 좋아합니다! 집에 있는 걸 좋아하지만 여행가는 것도 너무 좋아요! 내가 하고 싶은 건 열심히 하는 조용하지만 튀고 싶은 나!\n\n
                
                이런 건 어때요?\n
                당신의 속마음을 말하지 않고 혼자서 정해진 선에서 상대방이 선 넘으면 상대하지 않아요! 말하지 않으면 상대방은 모르겠죠? 속으로 욕하지 말고 겉으로 이제 당당하게 표현하세요! 당신이 말을 아끼고 행동하는 것을 아끼다가 가끔 분출되는 똘끼… 적응하기 힘들어합니다. 그리고 동승자가 있으면 말도 걸어주고 해주세요! 민망해요!!\n\n
                
                같이 드라이브하면 좋은 친구\n“드루와~ 드루와~들어올 수 있으면 드러와이~!” 끼어들기 절대 안 봐주는 정청이\n\n  
                드라이브하다가 뛰어내릴 친구\n”아아가서 드라이브 한잔하지" 흥 넘치고 드라이브 좋아하는 안상구`,
        query: 'ckxotlrl',
        img_src: 'https://images.ktestone.com/resultImages/',
      },
      {
        type: 'ENTP',
        desc: `드루와~ 드루와~들어올 수 있으면 드러와이~!” 끼어들기 절대 안 봐주는 정청이\n\n
                나의 운전스타일은?\n\n
                직설적이고 표현 잘 하는 성격처럼 운전도 내가 하고 싶은 대로 합니다! 상황 보고 안전하면 무법운전도 하는 당신! 감정 기복도 심해서 기분에 따라 수십 번 변하는 나의 플레이리스트! 사람들을 좋아하고 외부적인 활동도 좋아하지만 의외로 또 귀찮아 하기도 해요! 하지만 자기주장이 강하고 늘 확고한 성격이라 문득 발견한 기스 하나라도 누가 그랬는지 꼭 잡으려고 합니다. 계획적이면서도 즉흥적인 당신! 불의를 당하거나 틀린 것이 있으면 참지 않고 직설적으로 말하는 사람이죠!\n\n
                이런 건 어때요?\n
                하고 싶은 건 다해야하는 당신! 한적한 도로나 조용한 도로 위에서 가끔 안전을 확인하고 무법운전을 하는 행동은 이제 그만! 사고는 나만 조심해서 발생하는 게 아니랍니다! 늘 안전! 또 안전! 그리고 표현에 있어 직설적이기 때문에 당신의 표현으로 인해 싸움이나 감정이 상할 수 있으니 늘 동승자와 함께 있을 때 말을 조심해주세요! 
                \n\n
                같이 드라이브하면 좋은 친구\n“지금까지 이런 초보는 없었다. 이것은 엑셀인가 브레이크인가…?” 몰라도 일단 운전하는 게 즐거운 고반장\n\n
                드라이브하다가 뛰어내릴 친구\n“누구든 내 옆자리에만 앉아~ 운전은 내가 한다” _ 불편한 친구가 없어요 왜냐면 운전은 내가 할 거니까`,
        query: 'wjdcjddl',
        img_src: 'https://images.ktestone.com/resultImages/',
      },
      {
        type: 'ESTJ',
        desc: `“운전대를 잡을 명분이 없다 아임니까 명분이” – 사전 계획 철저하고 냉정한 형배\n\n
                나의 운전스타일은?\n
                목표를 한 번 정하면 목표가 이루어질 때까지 한 우물만 파는 고집쟁이 +확실한 거 좋아하는 엄격한 나! 운전을 할 때도 계획적이어서 내가 거기에 언제까지 도착할 것 같은 지 미리 내비를 보고 판단해서 준비를 해요. 이것저것 배우는 거 좋아해서 차에 대해서도 완벽하게 잘 알고 있어요! 가만히 있지 못하는 워커홀릭인 나도 가끔은 비 오는 날 감성에 취해 노래를 따라 부르면서 즐기기도 합니다.\n\n
                
                이런 건 어때요?\n
                시간 약속 어기는 것을 극혐하는 당신! 친구가 ‘도로가 막혀서 늦었어’ 라고 해도 당신은 절대 이해 못해주죠... 왜냐면 어디가 막히는 지 미리 확인했으니까요 하지만 조금 유연한 마음을 가지고 이해해보도록 하는 건 어떨까요~? 차가 막히는 건 어쩔 수 없는 문제 잖아요! 그리고 놀 때도 늘 일 생각만 하는 당신! 좀 놀러갈 땐 맘 편하게 즐겨보세요! 놀 땐 놀고 일할 땐 일 해요!\n\n
                
                같이 드라이브하면 좋은 친구\n꼭 그렇게 운전해야만 속이 후련했냐!?” 끼어드는 차들 때문에 화가나는 오태시기\n\n
                드라이브하다가 뛰어내릴 친구\n“자…운전이라는 건 말이야~..” 입으로는 운전 박사 급 납득이`,
        query: 'gudqo',
        img_src: 'https://images.ktestone.com/resultImages/',
      },
      {
        type: 'ISFP',
        desc: `“날 지나가랏...” 여유 넘치는 운전자 최재현 중위\n\n

                나의 운전스타일은?\n
                뭐가 저리 급한지~ 서둘러서 가는 차량들을 봐도 느긋하게 양보해주거나 신경 쓰지 않아요! 하지만 갑자기 매너없이 끼어든다면 순간 욱! 하지만 워낙 순딩하고 착한 사람이라 그게 다예요! 운전은 하고 싶지만 귀차니즘의 성격 때문에 아직 면허가 없다면 따야 하는데~ 하고 미루거나 운전을 해야 할 상황이 오면 집에 있고 싶어요! 하지만 막상 하면 재밌다고 잘합니다! 낯을 워낙 많이 가려서 동승자는 내가 허락한 예상 가능 한 친구들 만 태우고 다니죠!\n\n
                
                이런 건 어때요?\n
                차 안 동승자가 과자 부스러기를 흘리면서 먹는다 거나 나의 눈에 거슬리는 행동을 하면 마음에 담아두고 있다가 나의 기준을 넘으면 다시는 태우지 않으려고 해요. 상대방은 영문도 모르고 응? 왜 그러지? 나 피하나? 라고 생각할 수 있으니 우리 일단 참지 말고 말해보는 건 어떨까요? 그리고 운전을 해야할 땐 귀찮다고 생각하지 말아요~ 나가면 잘 놀거면서~\n\n
                
                같이 드라이브하면 좋은 친구\n“여러분~ 미안해요…이렇게 밖에 할 수 없는 나~ 용서해줘~” 운전만 하면 미안한 일 많아지는 엽기적인 그녀\n\n
                드라이브하다가 뛰어내릴 친구\n“지금까지 이런 초보는 없었다. 이것은 엑셀인가 브레이크인가…?” 몰라도 일단 운전하는 게 즐거운 고반장`,
        query: 'chleogus',
        img_src: 'https://images.ktestone.com/resultImages/',
      },
      {
        type: 'INTP',
        desc: `"아~ 운전하기 딱 좋은 날씨다"운전에도 다 논리적인 이유가 있는 중구\n\n

                나의 운전 스타일은?\n
                평상시 조용하고 얌전한 스타일! 하지만 나의 차 안 플레이 리스트는 나만의 독특한 스타일로 구성되어 있어요! 워낙 평범함 과는 거리가 달라서 늘 나의 기분이나 감정도 한 번 더 생각하고 표현하는 사람입니다! 평소 말이 없지만 내가 좋아하는 것에 서는 말을 많이 해요! 그래서 옆에서 누가 뭘 말해도 영혼 없는 리액션을 하다가 관심사가 나오면 어? 하고 갑자기 말아지는 서타일~ 길을 가다가 막혀서 모두가 짜증 낸다면 과감하게 돌아서 빨리 가는 길을 찾는 센스도 있는 사람! \n\n

                이런 건 어때요?\n
                평범함 과는 거리가 먼 살짝 독특한 관심사가 있어서 만약 누군가 당신의 관심사를 건드리면 한없이 신나서 얘기하지만…. 보통 사람들과의 관심사가 너무 다르다보니 상대방은 지루하거나…쟤 왜 저러나 싶은 생각을 할 수도 있습니다. 그럴 땐 흥분을 가라 앉히고 상대방의 기분을 살펴보도록 하세요! 그리고 똑똑하지 않은 사람도 운전은 잘 할 수 있습니다! 무시하지 말고 그들의 의견에도 집중해주세요!\n\n
                같이 드라이브하면 좋은 친구\n“내가 마! 고속타로도 타고! 흐잉?! 다했어 마!” 운전에 대한 자신감이 넘치는 최익혀니\n\n
                드라이브하다가 뛰어내릴 친구\n“누구든 내 옆자리에만 앉아~ 운전은 내가 한다” _ 불편한 친구가 없어요 왜냐면 운전은 내가 할 거니까`,
        query: 'wndrn',
        img_src: 'https://images.ktestone.com/resultImages/',
      },
      {
        type: 'ENTJ',
        desc: `“내가 마! 고속도로도 타고! 흐잉? 다했어 마!”_운전에 자신감이 넘치는 최익혀니\n\n
                나의 운전스타일은?\n\n
                매사에 자신감이 넘치고 목표가 확실한 당신! 운전에 대한 자신감도 한껏~ 운전도 남들이 답답하게 하면 그냥 차라리 내가 하는게 편해요! 여행을 갈 때도 일단 고! 참을성이 조금은 부족해서 일단 실천부터 하는 추친력이 아주 대단한 사람입니다! 운전을 잘하는 내 자신이 너무 멋있어 보인다고 생각하는 자기애가 강한스타일! 엄청 현실적이고 논리적인 사람이며 머리가 똑똑해요! 트렁크도 애초에 더러운 적이 없는 당신은 차량검사도 미리미리 하는 철두철미한 모습이 있군요
                \n\n
                이런 건 어때요?\n\n
                운전에 대한 자신감이 넘치다 보니 가끔 운전을 서툴게 하는 사람을 보면 왜 그런 식으로 운전을 하냐며 직설적이게 말 표현을 할 수 있어요. 당신의 답답한 마음도 이해를 하지만 냉정하게 말하지 말고 생각하고 말 해주세요. 특히 친구들이나 지인 들과 여행을 간다면… 그들이 운전을 하든 길을 찾던 ..의도적으로 무시하는 행동은 이제 그만!
                \n\n
                드라이브하면 좋은 친구\n“아~ 운전하기 딱 좋은 날씨다” 운전에도 다 논리적인 이유가 있는 중구행님\n\n
                드라이브하면 불편한 친구\n“누구든 내 옆자리에만 앉아~ 운전은 내가 한다” _ 불편한 친구가 없어요 왜냐면 운전은 내가 할 거니까`,
        query: 'chldlrgusl',
        img_src: 'https://images.ktestone.com/resultImages/',
      },
    ],
  },
  // wealthluck
  {
    info: {
      mainTitle: '재물운 MBTI',
      subTitle: '재물운 MBTI',
      mainUrl: 'wealthluck',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/wealthluck-intro.png',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      lang: '',
      category: 'saju',
    },
    questions: [
      {
        which: 'EI',
        question: '다가오는 주말! 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '주말이니까 밖에서 지인, 친구, 가족 등 과 함께 시간을 보내고 싶다.',
          },
          {
            type: 'I',
            score: 5,
            content: '그냥 집에서 유튜브, 넷플릭스등을 보며 쉬고 싶다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '대화할 때의 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '나는 주로 말소리가 크고 말이 빠르다.',
          },
          {
            type: 'I',
            score: 5,
            content: '나는 목소리가 작고 조용하며 천천히 말한다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구들과 있을 때 나의 역할은?',
        answers: [
          {
            type: 'I',
            score: 2,
            content: '대화를 잘 들어주며 그에 대한 적절한 리액션.',
          },
          {
            type: 'E',
            score: 5,
            content: '분위기를 주도하고 먼저 대화를 이끌며 화려한 리액션.',
          },
        ],
      },
      {
        which: 'SN',
        question: '나의 표현방식과 유사한 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '현실적이면서 구체적이고 사실적인 표현을 잘한다.',
          },
          {
            type: 'N',
            score: 5,
            content: '미래지향적이며 창의적이고 은유,추상적인 표현을 잘한다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '더 이해하기 힘든 사람은?',
        answers: [
          {
            type: 'N',
            score: 2,
            content: '현실에만 만족하고 다른 도전하지 못하는 사람',
          },
          {
            type: 'S',
            score: 5,
            content: '현실을 직시하지 못하고 비현실적인 얘기만 하는 사람',
          },
        ],
      },
      {
        which: 'SN',
        question: '둘 중 더 하고 싶은 일은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '늘 해왔던 일이나 실제 경험이나 사실이 필요한 일',
          },
          {
            type: 'N',
            score: 5,
            content: '새로운 일이나 상상력이 필요한 일',
          },
        ],
      },
      {
        which: 'TF',
        question: '감정적으로 너무 힘든 상황이 생기면 나는?',
        answers: [
          {
            type: 'F',
            score: 2,
            content: '참으려고 했지만 참았던 눈물이 나오려고 한다.',
          },
          {
            type: 'T',
            score: 5,
            content: '힘들어도 왠만하면 울지 않는다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '무언가를 선택 해야할 때 나는?',
        answers: [
          {
            type: 'F',
            score: 2,
            content: '선택장애가 있는 것 처럼 쉽게 결정하기 어렵다.',
          },
          {
            type: 'T',
            score: 5,
            content: '쉽고 빠르게 결정하는 편이다.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '영화 속 악당은 어쩔수 없이 나쁜 짓을 해야하는 상황이었다. 그럴 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '그래도 악당은 악당이지. 왜 그걸 굳이 그렇게 해? 당하는 장면이 통쾌하다.',
          },
          {
            type: 'F',
            score: 5,
            content: '어쩔 수 없는 환경이 그렇게 만들었네.. 안타깝고 불쌍하다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '쇼핑하러 갈 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '뭘 사야할지 머릿 속이나 메모를 한뒤 쇼핑을 하러간다.',
          },
          {
            type: 'P',
            score: 5,
            content: '적지 않고 둘러보다 사거나 즉흥적으로 가는 편이다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '일처리를 할 때 나의 스타일에 가까운 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '계획적으로 마감일 전까지 일의 순서에 맞게 진행한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '여유롭게 하다가 마감일에 임박했을 때 신속하게 진행한다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 결정한 것에 있어서 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '한 번 결정하면 잘 변경하지 않는다.',
          },
          {
            type: 'P',
            score: 5,
            content: '상황에 따라 융통성이 있게 변경한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: ``,
        query: 'ESTJ',
        img_src: 'https://images.ktestone.com/resultImages/wealthluck/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: ``,
        query: 'ESTP',
        img_src: 'https://images.ktestone.com/resultImages/wealthluck/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: ``,
        query: 'ESFJ',
        img_src: 'https://images.ktestone.com/resultImages/wealthluck/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: ``,
        query: 'ESFP',
        img_src: 'https://images.ktestone.com/resultImages/wealthluck/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: ``,
        query: 'ENTJ',
        img_src: 'https://images.ktestone.com/resultImages/wealthluck/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: ``,
        query: 'ENTP',
        img_src: 'https://images.ktestone.com/resultImages/wealthluck/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: ``,
        query: 'ENFJ',
        img_src: 'https://images.ktestone.com/resultImages/wealthluck/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: ``,
        query: 'ENFP',
        img_src: 'https://images.ktestone.com/resultImages/wealthluck/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: ``,
        query: 'ISTJ',
        img_src: 'https://images.ktestone.com/resultImages/wealthluck/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: ``,
        query: 'ISTP',
        img_src: 'https://images.ktestone.com/resultImages/wealthluck/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: ``,
        query: 'ISFJ',
        img_src: 'https://images.ktestone.com/resultImages/wealthluck/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: ``,
        query: 'ISFP',
        img_src: 'https://images.ktestone.com/resultImages/wealthluck/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: ``,
        query: 'INTJ',
        img_src: 'https://images.ktestone.com/resultImages/wealthluck/INTJ.png',
      },
      {
        type: 'INTP',
        desc: ``,
        query: 'INTP',
        img_src: 'https://images.ktestone.com/resultImages/wealthluck/INTP.png',
      },
      {
        type: 'INFJ',
        desc: ``,
        query: 'INFJ',
        img_src: 'https://images.ktestone.com/resultImages/wealthluck/INFJ.png',
      },
      {
        type: 'INFP',
        desc: ``,
        query: 'INFP',
        img_src: 'https://images.ktestone.com/resultImages/wealthluck/INFP.png',
      },
    ],
  },
  // jaetech
  {
    info: {
      mainTitle: '재테크 MBTI',
      subTitle: '재테크 MBTI',
      mainUrl: 'jaetech',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/jaetech-intro.png',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      lang: '',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question: '처음 참석한 모임에서의 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '사람들에게 먼저 말 걸고 친해지려고 한다.',
          },
          {
            type: 'I',
            score: 5,
            content: '먼저 말 걸까말까 고민하는 중에 누군가 먼저 말 걸어준다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '누군가에게 전화를 해야할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '전화 목록에서 바로 전화걸기',
          },
          {
            type: 'I',
            score: 5,
            content: '무슨 말을 먼저 꺼내야 할지 고민하고 생각후 전화걸기',
          },
        ],
      },
      {
        which: 'EI',
        question: '처음보는 사람들과 새로운 관계를 만드는 것이',
        answers: [
          {
            type: 'I',
            score: 2,
            content: '가끔은 부담스럽다고 생각한다.',
          },
          {
            type: 'E',
            score: 5,
            content: '즐겁고 늘 새롭다고 생각한다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 세우고자 하는 목표에 가까운 것은?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '당장 일단 500만원 부터 모아볼까?',
          },
          {
            type: 'N',
            score: 5,
            content: '향후 5-10년 후의 나를 위한 재테크를 해보자!',
          },
        ],
      },
      {
        which: 'SN',
        question: '일할 때 나의 모습과 가까운 것은?',
        answers: [
          {
            type: 'N',
            score: 2,
            content: '스스로 나만의 방법을 만드는 게 좋다.',
          },
          {
            type: 'S',
            score: 5,
            content: '남들이 하는대로 따라가는 게 좋다.',
          },
        ],
      },
      {
        which: 'SN',
        question: '업무 (과제)를 할때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '꾸준하고 참을성 있게 일하는 스타일',
          },
          {
            type: 'N',
            score: 5,
            content: '창의적이고 독창적인 아이디어를 제시하며 일하는 스타일',
          },
        ],
      },
      {
        which: 'TF',
        question: '보험 상담 받을 때 나는?',
        answers: [
          {
            type: 'F',
            score: 2,
            content:
              '미래 보장에 대해 내가 아팠을 때를 상상하며 들어야하나 고민함.',
          },
          {
            type: 'T',
            score: 5,
            content: '어느 보장이 되는 건지 확실히 물어보고 고민함.',
          },
        ],
      },
      {
        which: 'TF',
        question: '약속 장소에 가는 중 사고가 나 지인에게 말할 때 나는?',
        answers: [
          {
            type: 'F',
            score: 2,
            content: '사고가 났다는 사실을 알리며 걱정을 바란다.',
          },
          {
            type: 'T',
            score: 5,
            content: '사고가 나 약속에 가지 못할 상황만 알린다.',
          },
        ],
      },
      {
        which: 'TF',
        question: '드디어 적금 만기가 되었다는 친구의 말에',
        answers: [
          {
            type: 'F',
            score: 2,
            content: '와~ 2년을~? 대단하다!',
          },
          {
            type: 'T',
            score: 5,
            content: '오! 2년동안 얼마씩 모았는데?',
          },
        ],
      },
      {
        which: 'JP',
        question: '오랜만에 만나자고 연락온 친구의 말에 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '그래! 진짜 오랜만이다! 언제 볼래?',
          },
          {
            type: 'P',
            score: 5,
            content:
              '그래그래ㅜㅜ 우리 진짜 안본지 오래됐다 ㅜ 한번 만나야하는데!',
          },
        ],
      },
      {
        which: 'JP',
        question: '평소 나의 모습과 가까운 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '미리미리 준비 해서 여유롭게 끝낸다.',
          },
          {
            type: 'P',
            score: 5,
            content: '여유롭다가 마지막 순간에 집중해서 한번에 끝낸다.',
          },
        ],
      },
      {
        which: 'JP',
        question: '재테크를 시작할 때 나는 ?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '재테크에 대한 계획을 세우고 빠르게 실행!',
          },
          {
            type: 'P',
            score: 5,
            content: '재테크는 어떤 것들이 있을까? 알아보고 느긋하게 실행!',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: ``,
        query: 'ESTJ',
        img_src: 'https://images.ktestone.com/resultImages/jaetech/ESTJ.png',
      },
      {
        type: 'ESTP',
        desc: ``,
        query: 'ESTP',
        img_src: 'https://images.ktestone.com/resultImages/jaetech/ESTP.png',
      },
      {
        type: 'ESFJ',
        desc: ``,
        query: 'ESFJ',
        img_src: 'https://images.ktestone.com/resultImages/jaetech/ESFJ.png',
      },
      {
        type: 'ESFP',
        desc: ``,
        query: 'ESFP',
        img_src: 'https://images.ktestone.com/resultImages/jaetech/ESFP.png',
      },
      {
        type: 'ENTJ',
        desc: ``,
        query: 'ENTJ',
        img_src: 'https://images.ktestone.com/resultImages/jaetech/ENTJ.png',
      },
      {
        type: 'ENTP',
        desc: ``,
        query: 'ENTP',
        img_src: 'https://images.ktestone.com/resultImages/jaetech/ENTP.png',
      },
      {
        type: 'ENFJ',
        desc: ``,
        query: 'ENFJ',
        img_src: 'https://images.ktestone.com/resultImages/jaetech/ENFJ.png',
      },
      {
        type: 'ENFP',
        desc: ``,
        query: 'ENFP',
        img_src: 'https://images.ktestone.com/resultImages/jaetech/ENFP.png',
      },
      {
        type: 'ISTJ',
        desc: ``,
        query: 'ISTJ',
        img_src: 'https://images.ktestone.com/resultImages/jaetech/ISTJ.png',
      },
      {
        type: 'ISTP',
        desc: ``,
        query: 'ISTP',
        img_src: 'https://images.ktestone.com/resultImages/jaetech/ISTP.png',
      },
      {
        type: 'ISFJ',
        desc: ``,
        query: 'ISFJ',
        img_src: 'https://images.ktestone.com/resultImages/jaetech/ISFJ.png',
      },
      {
        type: 'ISFP',
        desc: ``,
        query: 'ISFP',
        img_src: 'https://images.ktestone.com/resultImages/jaetech/ISFP.png',
      },
      {
        type: 'INTJ',
        desc: ``,
        query: 'INTJ',
        img_src: 'https://images.ktestone.com/resultImages/jaetech/INTJ.png',
      },
      {
        type: 'INTP',
        desc: ``,
        query: 'INTP',
        img_src: 'https://images.ktestone.com/resultImages/jaetech/INTP.png',
      },
      {
        type: 'INFJ',
        desc: ``,
        query: 'INFJ',
        img_src: 'https://images.ktestone.com/resultImages/jaetech/INFJ.png',
      },
      {
        type: 'INFP',
        desc: ``,
        query: 'INFP',
        img_src: 'https://images.ktestone.com/resultImages/jaetech/INFP.png',
      },
    ],
  },
  // FriendFactPok
  {
    info: {
      mainTitle: '내 팩폭력 지수 테스트',
      subTitle: '내 팩폭력 지수 테스트하기',
      mainUrl: 'FriendFactPok',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          '기분 너무 좋은 주말!\n친구들과 만나기로 했는데 약속 시간에\n늦는다고 할 때,\n내 친구의 반응은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '(컬러링소리) 여보세요?\n야 너 왜 이렇게 안 와!\n어제 술 먹느라 늦게 일어났지 빨리 와라 ㅂㄷㅂㄷ',
          },
          {
            type: 'I',
            score: 5,
            content: '뭐 기다리면 오겠지..\n그래도 카톡이나 하나 남겨봐야겠다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '점심으로 어제 먹었던 음식을 먹자고 한다!\n이때, 내 친구는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '투쟁심을 발휘하여 적극적으로 반대한다.\n“나 그거 어제 먹었는데. 제발 다른 거 먹자!!”',
          },
          {
            type: 'I',
            score: 5,
            content:
              '어제 먹긴 했지만 그냥 좋은 게 좋은 거다.\n“그래. 어제 먹긴 했는데 그냥 먹자 뭐~~”',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '식당에 와서 주문해야 한다!\n하지만 사장님이 우리를 보지 않을 때 내 친구 행동은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '저~기~요 사장님~~\n여기 주문 좀 할게요!',
          },
          {
            type: 'I',
            score: 5,
            content: '바쁘시니까 뭐..\n아까 눈 마주쳤는데 기다리면 오시겠지',
          },
        ],
      },
      {
        which: 'SN',
        question:
          '친구들과 영화를 보러 왔다!\n주인공이 상대에게 팩폭을 묵직하게 한다!\n이때 내 친구가 드는 생각은? ',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '와..팩폭 딜량 장난 아니네.\n주인공 진짜 화 많이 났나 보다.\n뒤에 뭐 설명하겠지.',
          },
          {
            type: 'N',
            score: 5,
            content:
              '음.. 저 친구는 뭘 잘못했길래\n주인공한테 저렇게 팩트로 후드려 맞을까?\n내 생각엔 바람핀 거 같아.',
          },
        ],
      },
      {
        which: 'SN',
        question:
          '기분 좋게 술을 마시러 가는 중\n타로 집을 발견했다.\n그런데.. 친구들이 타로 집 앞에\n서성거릴 때 내 친구는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '얘들아..\n안 하던 거 하지 말고 빨리 술이나 먹으러 가자.',
          },
          {
            type: 'N',
            score: 5,
            content:
              '옼ㅋㅋㅋㅋㅋ\n타로다 한번 보고 싶은데 한번 스근하게 보고 가자.',
          },
        ],
      },
      {
        which: 'SN',
        question: `깻잎 논쟁 말고 이제는 핸드크림 논쟁! 내 친구의 선택은?\n
                상황 설명 :\n
                단체모임 중, 내 애인이 가방에서 핸드크림 좀 꺼내달라고 했다.\n
                나는 잠시 다른 걸 하느라 그 말을 미처 듣지 못했는데,\n
                친구가 애인의 가방을 뒤져 핸드크림을 꺼내더니 애인 손에 직접 짜줬다면??\n`,
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '내가 못들은 것 같아서 그랬나부지...\n좀 거슬리지만 좋게 생각하고 넘어간다.',
          },
          {
            type: 'N',
            score: 5,
            content:
              '나한테 말해주면 되지 그걸 왜 본인이 직접?\n부들부들하다가 친구한테 슬쩍 눈치를 준다.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '휴가 계획 중에 내가 해외여행 행선지 제안을 했다!\n내 친구가 기분이 더 안 좋을 때는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '번뜩이는 감각으로 행선지를 제안했지만\n의견이 수용되지 않을 때.',
          },
          {
            type: 'F',
            score: 5,
            content:
              '자신 있게 행선지 얘기를 했지만\n아무도 리액션을 안 해줬을 때.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '친구가 기분이 너무 안 좋아서\n좋은 향을 맡고 싶다고 한다.\n내 친구의 대답은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '좋은 향 어떤 거?\n시트러스? ㅋㅋㅋㅋ',
          },
          {
            type: 'F',
            score: 5,
            content: '에..? 기분이 왜 안 좋아?\n무슨 일 있었나?',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '집에 돌아가는 길!\n그런데 두 명이서 싸우고 있고,\n한 명이 일방적으로 폭행을 당하고 있다.\n내 친구의 반응은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '어우 주먹 좀 쓰시는 형님이네 ㄷㄷ\n엮이면 피곤한데 신고하면 보상금 주나?',
          },
          {
            type: 'F',
            score: 5,
            content:
              '좀 말려야 되는 거 아닐까?..\n저러다가 크게 다치는 거 아닐까?',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '즐겁게 놀고 집에 들어왔다!\n이때 내 친구의 모습과 가까운 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '좀 씻고, 옷 정리하고,\n알람 확인하고 천천히 SNS 체크~\n하루 마감 루틴 정석으로 간다!',
          },
          {
            type: 'P',
            score: 5,
            content:
              '일단 소파에 누워서 SNS 체크도 좀 하고\n오늘 찍은 사진도 한번 쓱 보자~',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '내 친구가 SNS를 키는 순간!\n애인이 내 생일 일주일 전에 바람피운 걸 알았다면,\n내 친구는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '내가 여태 해온 게 있으니..\n선물 받고 헤어진다.',
          },
          {
            type: 'P',
            score: 5,
            content: '나는 참을 수 없다..\n당장 헤어지자고 선포한다.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '이제 침대에 누워서 잘 시간.\n오늘 만난 친구 선물을 골라야 하는데,\n내 친구의 방법은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '위시리스트에 핸드크림이 있네?\n정확하게 필요한 핸드크림을 선물한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '쓱쓱 보다가 핸드크림이 뭔가 필요할 거 같아서 선물한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ESTJ',
        img_src:
          'https://images.ktestone.com/resultImages/yourFactPok/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ESTP',
        img_src:
          'https://images.ktestone.com/resultImages/yourFactPok/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ESFJ',
        img_src:
          'https://images.ktestone.com/resultImages/yourFactPok/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ESFP',
        img_src:
          'https://images.ktestone.com/resultImages/yourFactPok/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ENTJ',
        img_src:
          'https://images.ktestone.com/resultImages/yourFactPok/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ENTP',
        img_src:
          'https://images.ktestone.com/resultImages/yourFactPok/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ENFJ',
        img_src:
          'https://images.ktestone.com/resultImages/yourFactPok/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ENFP',
        img_src:
          'https://images.ktestone.com/resultImages/yourFactPok/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ISTJ',
        img_src:
          'https://images.ktestone.com/resultImages/yourFactPok/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISTP',
        img_src:
          'https://images.ktestone.com/resultImages/yourFactPok/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISFJ',
        img_src:
          'https://images.ktestone.com/resultImages/yourFactPok/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ISFP',
        img_src:
          'https://images.ktestone.com/resultImages/yourFactPok/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'INTJ',
        img_src:
          'https://images.ktestone.com/resultImages/yourFactPok/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'INTP',
        img_src:
          'https://images.ktestone.com/resultImages/yourFactPok/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'INFJ',
        img_src:
          'https://images.ktestone.com/resultImages/yourFactPok/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'INFP',
        img_src:
          'https://images.ktestone.com/resultImages/yourFactPok/INFP.jpg',
      },
    ],
  },
  // MyFactPok
  {
    info: {
      mainTitle: '내 팩폭력 지수 테스트',
      subTitle: '내 팩폭력 지수 테스트하기',
      mainUrl: 'MyFactPok',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
    },
    questions: [
      {
        which: 'EI',
        question:
          '기분 너무 좋은 주말!\n친구들과 만나기로 했는데 약속 시간에\n늦는다고 할 때,\n나의 반응은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '(컬러링소리) 여보세요?\n야 너 왜 이렇게 안 와!\n어제 술 먹느라 늦게 일어났지 빨리 와라 ㅂㄷㅂㄷ',
          },
          {
            type: 'I',
            score: 5,
            content: '뭐 기다리면 오겠지..\n그래도 카톡이나 하나 남겨봐야겠다.',
          },
        ],
      },
      {
        which: 'EI',
        question: '점심으로 어제 먹었던 음식을 먹자고 한다!\n이때, 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '투쟁심을 발휘하여 적극적으로 반대한다.\n“나 그거 어제 먹었는데. 제발 다른 거 먹자!!”',
          },
          {
            type: 'I',
            score: 5,
            content:
              '어제 먹긴 했지만 그냥 좋은 게 좋은 거다.\n“그래. 어제 먹긴 했는데 그냥 먹자 뭐~~”',
          },
        ],
      },
      {
        which: 'EI',
        question:
          '식당에 와서 주문해야 한다!\n하지만 사장님이 우리를 보지 않을 때 내 행동은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '저~기~요 사장님~~\n여기 주문 좀 할게요!',
          },
          {
            type: 'I',
            score: 5,
            content: '바쁘시니까 뭐..\n아까 눈 마주쳤는데 기다리면 오시겠지',
          },
        ],
      },
      {
        which: 'SN',
        question:
          '친구들과 영화를 보러 왔다!\n주인공이 상대에게 팩폭을 묵직하게 한다!\n이때 내가 드는 생각은? ',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '와..팩폭 딜량 장난 아니네.\n주인공 진짜 화 많이 났나 보다.\n뒤에 뭐 설명하겠지.',
          },
          {
            type: 'N',
            score: 5,
            content:
              '음.. 저 친구는 뭘 잘못했길래\n주인공한테 저렇게 팩트로 후드려 맞을까?\n내 생각엔 바람핀 거 같아.',
          },
        ],
      },
      {
        which: 'SN',
        question:
          '기분 좋게 술을 마시러 가는 중\n타로 집을 발견했다.\n그런데.. 친구들이 타로 집 앞에\n서성거릴 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '얘들아..\n안 하던 거 하지 말고 빨리 술이나 먹으러 가자.',
          },
          {
            type: 'N',
            score: 5,
            content:
              '옼ㅋㅋㅋㅋㅋ\n타로다 한번 보고 싶은데 한번 스근하게 보고 가자.',
          },
        ],
      },
      {
        which: 'SN',
        question: `깻잎 논쟁 말고 이제는 핸드크림 논쟁! 나의 선택은?\n
                상황 설명 :\n
                단체모임 중, 내 애인이 가방에서 핸드크림 좀 꺼내달라고 했다.\n
                나는 잠시 다른 걸 하느라 그 말을 미처 듣지 못했는데,\n
                친구가 애인의 가방을 뒤져 핸드크림을 꺼내더니 애인 손에 직접 짜줬다면??\n`,
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              '내가 못들은 것 같아서 그랬나부지...\n좀 거슬리지만 좋게 생각하고 넘어간다.',
          },
          {
            type: 'N',
            score: 5,
            content:
              '나한테 말해주면 되지 그걸 왜 본인이 직접?\n부들부들하다가 친구한테 슬쩍 눈치를 준다.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '휴가 계획 중에 내가 해외여행 행선지 제안을 했다!\n내가 기분이 더 안 좋을 때는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '번뜩이는 감각으로 행선지를 제안했지만\n의견이 수용되지 않을 때.',
          },
          {
            type: 'F',
            score: 5,
            content:
              '자신 있게 행선지 얘기를 했지만\n아무도 리액션을 안 해줬을 때.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '친구가 기분이 너무 안 좋아서\n좋은 향을 맡고 싶다고 한다.\n나의 대답은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '좋은 향 어떤 거?\n시트러스? ㅋㅋㅋㅋ',
          },
          {
            type: 'F',
            score: 5,
            content: '에..? 기분이 왜 안 좋아?\n무슨 일 있었나?',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '집에 돌아가는 길!\n그런데 두 명이서 싸우고 있고,\n한 명이 일방적으로 폭행을 당하고 있다.\n나의 반응은?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '어우 주먹 좀 쓰시는 형님이네 ㄷㄷ\n엮이면 피곤한데 신고하면 보상금 주나?',
          },
          {
            type: 'F',
            score: 5,
            content:
              '좀 말려야 되는 거 아닐까?..\n저러다가 크게 다치는 거 아닐까?',
          },
        ],
      },
      {
        which: 'JP',
        question: '즐겁게 놀고 집에 들어왔다!\n이때 나의 모습과 가까운 것은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '좀 씻고, 옷 정리하고,\n알람 확인하고 천천히 SNS 체크~\n하루 마감 루틴 정석으로 간다!',
          },
          {
            type: 'P',
            score: 5,
            content:
              '일단 소파에 누워서 SNS 체크도 좀 하고\n오늘 찍은 사진도 한번 쓱 보자~',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '내가 SNS를 키는 순간!\n애인이 내 생일 일주일 전에 바람피운 걸 알았다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '내가 여태 해온 게 있으니..\n선물 받고 헤어진다.',
          },
          {
            type: 'P',
            score: 5,
            content: '나는 참을 수 없다..\n당장 헤어지자고 선포한다.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '이제 침대에 누워서 잘 시간.\n오늘 만난 친구 선물을 골라야 하는데,\n나의 방법은?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '위시리스트에 핸드크림이 있네?\n정확하게 필요한 핸드크림을 선물한다.',
          },
          {
            type: 'P',
            score: 5,
            content: '쓱쓱 보다가 핸드크림이 뭔가 필요할 거 같아서 선물한다.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ESTJ',
        img_src: 'https://images.ktestone.com/resultImages/myFactPok/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ESTP',
        img_src: 'https://images.ktestone.com/resultImages/myFactPok/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ESFJ',
        img_src: 'https://images.ktestone.com/resultImages/myFactPok/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ESFP',
        img_src: 'https://images.ktestone.com/resultImages/myFactPok/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ENTJ',
        img_src: 'https://images.ktestone.com/resultImages/myFactPok/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ENTP',
        img_src: 'https://images.ktestone.com/resultImages/myFactPok/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'ENFJ',
        img_src: 'https://images.ktestone.com/resultImages/myFactPok/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ENFP',
        img_src: 'https://images.ktestone.com/resultImages/myFactPok/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ISTJ',
        img_src: 'https://images.ktestone.com/resultImages/myFactPok/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISTP',
        img_src: 'https://images.ktestone.com/resultImages/myFactPok/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'ISFJ',
        img_src: 'https://images.ktestone.com/resultImages/myFactPok/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'ISFP',
        img_src: 'https://images.ktestone.com/resultImages/myFactPok/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'INTJ',
        img_src: 'https://images.ktestone.com/resultImages/myFactPok/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'INTP',
        img_src: 'https://images.ktestone.com/resultImages/myFactPok/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'INFJ',
        img_src: 'https://images.ktestone.com/resultImages/myFactPok/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'INFP',
        img_src: 'https://images.ktestone.com/resultImages/myFactPok/INFP.jpg',
      },
    ],
  },
  // loveCharacterFemaleIT
  {
    info: {
      mainTitle: "Test sul carattere dell'amore",
      subTitle: 'Quali sono le mie tendenze romantiche come personaggio?',
      mainUrl: 'loveCharacterFemaleIT',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'IT',
      category: 'love',
      dualQueries: true,
    },
    questions: [
      {
        which: 'EI',
        question:
          'Quando si va ad un appuntamento al buio, come ci si comporta?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'Non riesco a sopportare l imbarazzo e cerco di dire qualcosa o di fare qualche domanda',
          },
          {
            type: 'I',
            score: 5,
            content:
              'Odio l imbarazzo, ma aspetterò fino a quando l altra persona non dirà qualcosa',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'Quando un amico vi chiede di uscire in coppia, quale può essere la vostra reazione?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'Sarà divertente, naturalmente, andremo a un appuntamento di coppia insieme. Chiederò subito al mio amante',
          },
          {
            type: 'I',
            score: 5,
            content:
              "All'improvviso? Chiederò prima al mio amante e ci penserò (Dovrei dire che non vado...)",
          },
        ],
      },
      {
        which: 'EI',
        question: "Cosa si fa all'inizio di una relazione?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Tendo a fare molte domande`,
          },
          {
            type: 'I',
            score: 5,
            content: `Tendo ad essere reattivo e ad empatizzare per primo`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'Come reagirebbe se, sfogliando le foto della sua infanzia, trovasse una foto di voi due insieme?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Wow, il mondo è davvero piccolo Miracoloso! Come è potuto accadere?`,
          },
          {
            type: 'N',
            score: 5,
            content: `Wow, sembra un film. Deve essere il nostro destino! Forse ci siamo incontrati in una vita passata`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'Cosa fate se vi svegliate da un brutto sogno?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Sembra così reale! Comunque, devo tornare a dormire',
          },
          {
            type: 'N',
            score: 5,
            content: `Perché faccio un sogno così strano? Devo preoccuparmi di qualcosa?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'Quando si va in un ristorante imperdibile, quale strada si vuole percorrere?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Non mi interessano le visite turistiche lungo il percorso, quindi preferisco prendere delle scorciatoie`,
          },
          {
            type: 'N',
            score: 5,
            content: `Anche se ci vuole un po' di tempo in più, se è possibile, prenderò la strada panoramica`,
          },
        ],
      },
      {
        which: 'TF',
        question: `Se fate ascoltare al vostro amante una canzone che vi piace e a lui non piace, quale può essere la vostra reazione?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Potrei chiedermi perché al mio amante non piace questo capolavoro',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Capisco che ogni persona abbia gusti musicali diversi, ma io potrei sentirmi triste per niente',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'Quando ricevete una telefonata in cui il vostro amante litiga con il suo amico, cosa fareste?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Perché? Quali sono i motivi?`,
          },
          {
            type: 'F',
            score: 5,
            content:
              'Stai bene? Devi esserti arrabbiato, il tuo amico deve aver fatto qualcosa di sbagliato, giusto?',
          },
        ],
      },
      {
        which: 'TF',
        question: `Come reagireste se il vostro amante non potesse venire al vostro anniversario?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Chiederò una buona ragione, ma in ogni caso è così triste',
          },
          {
            type: 'F',
            score: 5,
            content:
              "Sono arrabbiata e dispiaciuta di non aver potuto incontrare il mio amante nel giorno dell'anniversario che avevo anticipato",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Che cosa fareste se il vostro amante vi chiedesse di vedere un film mentre voi dovete lavorare?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Oggi ho qualcosa da fare. Che ne dici di domani alle 15?`,
          },
          {
            type: 'P',
            score: 5,
            content: `Ho del lavoro da fare, ma credo di avere molto tempo a disposizione, ok andiamo a vederlo!`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'Quando avete un conflitto con il vostro amante, cosa fate?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Devo prima definirlo chiaramente e poi risolverlo',
          },
          {
            type: 'P',
            score: 5,
            content:
              "Osservo prima la situazione e decido se risolverla subito o meno a seconda dell'umore",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Cosa fate dopo aver fatto un viaggio divertente con il vostro amante?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Disfo i bagagli, faccio una doccia e mi riposo',
          },
          {
            type: 'P',
            score: 5,
            content: 'Mi riposerò dopo la doccia e sistemerò le cose più tardi',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'wksthfleoakdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleIT/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dhlrkdsodb',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleIT/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ekwjdekrka',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleIT/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dyffhfkdlvm',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleIT/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tmdqntk',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleIT/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dkfEMftlswkq',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleIT/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhfemsflxmflqj',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleIT/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'fldortusaksfpq',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleIT/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleIT/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlqehfdl',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleIT/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wkdrldusdo',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleIT/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Whfqh',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleIT/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gjekd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleIT/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dnjzjghfflr',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleIT/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ajdEoflsms',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleIT/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sowjrrhkswhd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleIT/INFP.jpg',
      },
    ],
  },
  // loveCharacterMaleIT
  {
    info: {
      mainTitle: "Test sul carattere dell'amore",
      subTitle: 'Quali sono le mie tendenze romantiche come personaggio?',
      mainUrl: 'loveCharacterMaleIT',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'IT',
      category: 'love',
      dualQueries: true,
    },
    questions: [
      {
        which: 'EI',
        question:
          'Quando si va ad un appuntamento al buio, come ci si comporta?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'Non riesco a sopportare l imbarazzo e cerco di dire qualcosa o di fare qualche domanda',
          },
          {
            type: 'I',
            score: 5,
            content:
              'Odio l imbarazzo, ma aspetterò fino a quando l altra persona non dirà qualcosa',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'Quando un amico vi chiede di uscire in coppia, quale può essere la vostra reazione?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'Sarà divertente, naturalmente, andremo a un appuntamento di coppia insieme. Chiederò subito al mio amante',
          },
          {
            type: 'I',
            score: 5,
            content:
              "All'improvviso? Chiederò prima al mio amante e ci penserò (Dovrei dire che non vado...)",
          },
        ],
      },
      {
        which: 'EI',
        question: "Cosa si fa all'inizio di una relazione?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: `Tendo a fare molte domande`,
          },
          {
            type: 'I',
            score: 5,
            content: `Tendo ad essere reattivo e ad empatizzare per primo`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'Come reagirebbe se, sfogliando le foto della sua infanzia, trovasse una foto di voi due insieme?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Wow, il mondo è davvero piccolo Miracoloso! Come è potuto accadere?`,
          },
          {
            type: 'N',
            score: 5,
            content: `Wow, sembra un film. Deve essere il nostro destino! Forse ci siamo incontrati in una vita passata`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'Cosa fate se vi svegliate da un brutto sogno?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Sembra così reale! Comunque, devo tornare a dormire',
          },
          {
            type: 'N',
            score: 5,
            content: `Perché faccio un sogno così strano? Devo preoccuparmi di qualcosa?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'Quando si va in un ristorante imperdibile, quale strada si vuole percorrere?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Non mi interessano le visite turistiche lungo il percorso, quindi preferisco prendere delle scorciatoie`,
          },
          {
            type: 'N',
            score: 5,
            content: `Anche se ci vuole un po' di tempo in più, se è possibile, prenderò la strada panoramica`,
          },
        ],
      },
      {
        which: 'TF',
        question: `Se fate ascoltare al vostro amante una canzone che vi piace e a lui non piace, quale può essere la vostra reazione?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Potrei chiedermi perché al mio amante non piace questo capolavoro',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Capisco che ogni persona abbia gusti musicali diversi, ma io potrei sentirmi triste per niente',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'Quando ricevete una telefonata in cui il vostro amante litiga con il suo amico, cosa fareste?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Perché? Quali sono i motivi?`,
          },
          {
            type: 'F',
            score: 5,
            content:
              'Stai bene? Devi esserti arrabbiato, il tuo amico deve aver fatto qualcosa di sbagliato, giusto?',
          },
        ],
      },
      {
        which: 'TF',
        question: `Come reagireste se il vostro amante non potesse venire al vostro anniversario?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Chiederò una buona ragione, ma in ogni caso è così triste',
          },
          {
            type: 'F',
            score: 5,
            content:
              "Sono arrabbiata e dispiaciuta di non aver potuto incontrare il mio amante nel giorno dell'anniversario che avevo anticipato",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Che cosa fareste se il vostro amante vi chiedesse di vedere un film mentre voi dovete lavorare?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Oggi ho qualcosa da fare. Che ne dici di domani alle 15?`,
          },
          {
            type: 'P',
            score: 5,
            content: `Ho del lavoro da fare, ma credo di avere molto tempo a disposizione, ok andiamo a vederlo!`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'Quando avete un conflitto con il vostro amante, cosa fate?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Devo prima definirlo chiaramente e poi risolverlo',
          },
          {
            type: 'P',
            score: 5,
            content:
              "Osservo prima la situazione e decido se risolverla subito o meno a seconda dell'umore",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Cosa fate dopo aver fatto un viaggio divertente con il vostro amante?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Disfo i bagagli, faccio una doccia e mi riposo',
          },
          {
            type: 'P',
            score: 5,
            content: 'Mi riposerò dopo la doccia e sistemerò le cose più tardi',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'wksthfleoakdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleIT/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dhlrkdsodb',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleIT/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ekwjdekrka',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleIT/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dyffhfkdlvm',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleIT/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tmdqntk',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleIT/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dkfEMftlswkq',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleIT/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhfemsflxmflqj',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleIT/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'fldortusaksfpq',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleIT/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleIT/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlqehfdl',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleIT/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wkdrldusdo',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleIT/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Whfqh',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleIT/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gjekd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleIT/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dnjzjghfflr',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleIT/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ajdEoflsms',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleIT/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sowjrrhkswhd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleIT/INFP.jpg',
      },
    ],
  },
  // loveCharacterFemaleJP
  {
    info: {
      mainTitle: '恋愛キャラクターテスト',
      subTitle: '私の恋愛キャラクターはどんなキャラクターかな?',
      mainUrl: 'loveCharacterFemaleJP',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'JP',
      category: 'love',
      dualQueries: true,
    },
    questions: [
      {
        which: 'EI',
        question: '合コンに出るとき私と近いのは？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '気まずさに耐えられずなんでも聞いてみる。',
          },
          {
            type: 'I',
            score: 5,
            content: '気まずさが嫌だけど相手が何かを話すまで待ってみる。',
          },
        ],
      },
      {
        which: 'EI',
        question: '友人のカップルに団体カップルデートの誘いが来ると私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '面白そう、オッケー！彼氏(彼女)にすぐに聞いてみるよ。',
          },
          {
            type: 'I',
            score: 5,
            content:
              '急に？まず彼氏(彼女)に聞いてから考えてみる。(行かないと言おう．．)',
          },
        ],
      },
      {
        which: 'EI',
        question: '恋愛の初めに私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '質問をもっとする方である。',
          },
          {
            type: 'I',
            score: 5,
            content: 'リアクションと先に共感をする方である。',
          },
        ],
      },
      {
        which: 'SN',
        question:
          '恋人と幼い頃の写真を見ていて偶然二人が一緒に取られた写真を見つけたら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'うわぁ～世の中本当に狭いね、不思議だ。こんな事どうやってあり得るんだろう。',
          },
          {
            type: 'N',
            score: 5,
            content:
              'うわぁ～、何か映画みたい。私たち運命かも～！前生でも私たち出会っていたのかな？',
          },
        ],
      },
      {
        which: 'SN',
        question: '気持ち悪い夢の途中目が覚めた時私は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '夢がすごくリアルで現実だと思ったよ。また寝よう。',
          },
          {
            type: 'N',
            score: 5,
            content:
              'どうしてこういう夢を見るのだろう。気になる、何かあったらどうしよう。',
          },
        ],
      },
      {
        which: 'SN',
        question: `恋人と美味しいお店を訪ねるとき、私が歩きたい経路は？`,
        answers: [
          {
            type: 'S',
            score: 2,
            content: '見物はないけどお店に一番早く着ける近道。',
          },
          {
            type: 'N',
            score: 5,
            content: 'やや遅くなるが綺麗な見物がある道。',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '恋人に私が好きな歌を聞かせてあげたとき、自分のタイプではないと言うと？',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '一体どういう好みの持ち主なのか、こんなに最高の曲がタイプではないなんて。',
          },
          {
            type: 'F',
            score: 5,
            content: '歌は好みだとはわかっているが、なんとなく寂しい。',
          },
        ],
      },
      {
        which: 'TF',
        question: '恋人から友達と喧嘩したと連絡があったとき私は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'なんで？どうして喧嘩したの？',
          },
          {
            type: 'F',
            score: 5,
            content: '大丈夫？悔しいでしょう、友達が悪いよ。',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '待ちに待った記念日！しかし恋人がごめんと謝りながら今日会えなさそうだと言うと？',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'すごくがっかりするけど、まずはどうして会えないのか、妥当な理由であるのか聞いてみる。',
          },
          {
            type: 'F',
            score: 5,
            content:
              '前から話した記念日に会えないことに苛立ちと寂しさを感じて怒る。',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'やらなければならない仕事はあるけど、恋人が急に前から見たかった映画に誘ったら私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '今日は仕事があって、明日の午後3時はどう？',
          },
          {
            type: 'P',
            score: 5,
            content:
              '仕事があるけど。。しかし時間十分だし、まあ～いいか、見に行こう！',
          },
        ],
      },
      {
        which: 'JP',
        question: '恋人とある葛藤が生じたときに私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '今すぐどうやって解決するか、今後どうやっていくのか明確に決めて晴らしたい。',
          },
          {
            type: 'P',
            score: 5,
            content: '状況を見ながら雰囲気次第で今すぐ解決するかどうか決める。',
          },
        ],
      },
      {
        which: 'JP',
        question: '恋人と楽しい旅行から戻ってきた後、私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'すぐ荷物から整理してシャワーを浴びて休む。',
          },
          {
            type: 'P',
            score: 5,
            content: 'まず休んでからシャワー後に整理する。',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'wksthfleoakdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dhlrkdsodb',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ekwjdekrka',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dyffhfkdlvm',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tmdqntk',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dkfEMftlswkq',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhfemsflxmflqj',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'fldortusaksfpq',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlqehfdl',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wkdrldusdo',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Whfqh',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gjekd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dnjzjghfflr',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ajdEoflsms',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sowjrrhkswhd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleJP/INFP.jpg',
      },
    ],
  },
  // loveCharacterMaleJP
  {
    info: {
      mainTitle: '恋愛キャラクターテスト',
      subTitle: '私の恋愛キャラクターはどんなキャラクターかな?',
      mainUrl: 'loveCharacterMaleJP',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'JP',
      category: 'love',
      dualQueries: true,
    },
    questions: [
      {
        which: 'EI',
        question: '合コンに出るとき私と近いのは？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '気まずさに耐えられずなんでも聞いてみる。',
          },
          {
            type: 'I',
            score: 5,
            content: '気まずさが嫌だけど相手が何かを話すまで待ってみる。',
          },
        ],
      },
      {
        which: 'EI',
        question: '友人のカップルに団体カップルデートの誘いが来ると私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '面白そう、オッケー！彼氏(彼女)にすぐに聞いてみるよ。',
          },
          {
            type: 'I',
            score: 5,
            content:
              '急に？まず彼氏(彼女)に聞いてから考えてみる。(行かないと言おう．．)',
          },
        ],
      },
      {
        which: 'EI',
        question: '恋愛の初めに私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '質問をもっとする方である。',
          },
          {
            type: 'I',
            score: 5,
            content: 'リアクションと先に共感をする方である。',
          },
        ],
      },
      {
        which: 'SN',
        question:
          '恋人と幼い頃の写真を見ていて偶然二人が一緒に取られた写真を見つけたら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'うわぁ～世の中本当に狭いね、不思議だ。こんな事どうやってあり得るんだろう。',
          },
          {
            type: 'N',
            score: 5,
            content:
              'うわぁ～、何か映画みたい。私たち運命かも～！前生でも私たち出会っていたのかな？',
          },
        ],
      },
      {
        which: 'SN',
        question: '気持ち悪い夢の途中目が覚めた時私は？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '夢がすごくリアルで現実だと思ったよ。また寝よう。',
          },
          {
            type: 'N',
            score: 5,
            content:
              'どうしてこういう夢を見るのだろう。気になる、何かあったらどうしよう。',
          },
        ],
      },
      {
        which: 'SN',
        question: `恋人と美味しいお店を訪ねるとき、私が歩きたい経路は？`,
        answers: [
          {
            type: 'S',
            score: 2,
            content: '見物はないけどお店に一番早く着ける近道。',
          },
          {
            type: 'N',
            score: 5,
            content: 'やや遅くなるが綺麗な見物がある道。',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '恋人に私が好きな歌を聞かせてあげたとき、自分のタイプではないと言うと？',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '一体どういう好みの持ち主なのか、こんなに最高の曲がタイプではないなんて。',
          },
          {
            type: 'F',
            score: 5,
            content: '歌は好みだとはわかっているが、なんとなく寂しい。',
          },
        ],
      },
      {
        which: 'TF',
        question: '恋人から友達と喧嘩したと連絡があったとき私は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'なんで？どうして喧嘩したの？',
          },
          {
            type: 'F',
            score: 5,
            content: '大丈夫？悔しいでしょう、友達が悪いよ。',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '待ちに待った記念日！しかし恋人がごめんと謝りながら今日会えなさそうだと言うと？',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'すごくがっかりするけど、まずはどうして会えないのか、妥当な理由であるのか聞いてみる。',
          },
          {
            type: 'F',
            score: 5,
            content:
              '前から話した記念日に会えないことに苛立ちと寂しさを感じて怒る。',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'やらなければならない仕事はあるけど、恋人が急に前から見たかった映画に誘ったら私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '今日は仕事があって、明日の午後3時はどう？',
          },
          {
            type: 'P',
            score: 5,
            content:
              '仕事があるけど。。しかし時間十分だし、まあ～いいか、見に行こう！',
          },
        ],
      },
      {
        which: 'JP',
        question: '恋人とある葛藤が生じたときに私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '今すぐどうやって解決するか、今後どうやっていくのか明確に決めて晴らしたい。',
          },
          {
            type: 'P',
            score: 5,
            content: '状況を見ながら雰囲気次第で今すぐ解決するかどうか決める。',
          },
        ],
      },
      {
        which: 'JP',
        question: '恋人と楽しい旅行から戻ってきた後、私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'すぐ荷物から整理してシャワーを浴びて休む。',
          },
          {
            type: 'P',
            score: 5,
            content: 'まず休んでからシャワー後に整理する。',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'wksthfleoakdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dhlrkdsodb',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ekwjdekrka',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dyffhfkdlvm',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tmdqntk',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dkfEMftlswkq',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhfemsflxmflqj',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'fldortusaksfpq',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlqehfdl',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wkdrldusdo',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Whfqh',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gjekd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dnjzjghfflr',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ajdEoflsms',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sowjrrhkswhd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleJP/INFP.jpg',
      },
    ],
  },
  // loveCharacterFemaleES
  {
    info: {
      mainTitle: 'Test de Personalidad en noviazgo.',
      subTitle:
        '¿Cuál es mi inclinación a enamorarme como personaje? | para hombres, para mujeres',
      mainUrl: 'loveCharacterFemaleES',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'ES',
      category: 'love',
      dualQueries: true,
    },
    questions: [
      {
        which: 'EI',
        question: 'En una cita a ciegas, ¿qué tipo de personas eres?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'No soporto atmósfera incómoda, digo cualquier cosa y hago cualquier pregunta.',
          },
          {
            type: 'I',
            score: 5,
            content:
              'Aunque no me gusta lo incómodo, espero hasta que otro diga algo.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'Si una pareja de tus amigos te propone una cita doble, ¿qué harías?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'Suena divertido, ¡claro que sí! ¡De inmediato voy a preguntarle a mi novio/a!',
          },
          {
            type: 'I',
            score: 5,
            content:
              'No me lo esperaba... Después de preguntarle a mi novio/a, voy a pensar. (No voy a hacerlo)',
          },
        ],
      },
      {
        which: 'EI',
        question: 'En un novizago, al principio, ¿qué tipo de personas eres?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Hago preguntas más.',
          },
          {
            type: 'I',
            score: 5,
            content: 'Muestro reacción y mi empatía.',
          },
        ],
      },
      {
        which: 'SN',
        question:
          'Al mirar las fotografías de tu novio/a de pequeño/a, por casualidad has encontrado una foto donde salen tu novio/a y tú. ¿Qué dirías?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'Guau, el mundo es muy pequeño. ¡Qué milagro!¿Cómo puede ser?',
          },
          {
            type: 'N',
            score: 5,
            content:
              'Guau. Es como una película. Creo que íbamos a quedar. ¿Tal vez nos vimos en la vida anterior también?',
          },
        ],
      },
      {
        which: 'SN',
        question: 'Si te despiertas desde un mal sueño, ¿qué harías?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'El sueño fue muy realista. Creía que era algo real. Tendría que dormir otra vez.',
          },
          {
            type: 'N',
            score: 5,
            content: 'Me siento mal por el sueño. ¿Algo pasaría?',
          },
        ],
      },
      {
        which: 'SN',
        question: `Vas a ir a un restaurante rico con tu novio/a, ¿cuál ruta elegirías?`,
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Un atajo, que no tiene nada intersante.',
          },
          {
            type: 'N',
            score: 5,
            content: 'Un camino un poquito sinuoso, pero más hermoso.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'Has puesto tu tema favorito para tu novio/a. Pero te dice que no le gusta. ¿Cómo te sentirías?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Tienes curiosidad cómo es su gustao musical y cómo no le gusta este temazo.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Aunque sabes que se trata de gusto personal, te sientes triste.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'Tu novio/a dice que ha peleado con su amigo. ¿Qué le dirías?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '¿Por qué? ¿Por qué has peleado?',
          },
          {
            type: 'F',
            score: 5,
            content: '¿Estás bien? Deberías estar triste. Es su culpa.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'Has esperado aniversario con ansias. Pero tu novio/a se disculpa, diciendo que hoy no puede quedar contigo. ¿Qué harías?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Aunque estoy triste, le pregunto por qué no puede quedar conmigo y si es algo justificable.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Estoy triste y enfadado/a por no poder quedar con él/ella en el aniversario que he esperado con ansias.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Tienes trabajo que hacer. Pero de repente tu novio/a te prpone ir a ver una pelí que quería ver. ¿Qué harías?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Hoy tengo que hacer algo... ¿Qué te parece a las tres de la tarde mañana?',
          },
          {
            type: 'P',
            score: 5,
            content:
              'Aunque tengo que hacer algo, tengo suficiente tiempo. Así que, claro, ¡vamos!',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Cuando tienes conflicto con tu novio/a, ¿qué harías?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'De inmediato quiero decidir cómo solucionarlo y qué hacer adelante de manera clara para resolverelo.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'Dependiendo de la atmósfera de la situación, puedo solucionarlo ahora o no.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Después de un viaje divertido con tu novio/a, ¿qué harías?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Arreglo mi equipaje primero, me ducho y luego descanso.',
          },
          {
            type: 'P',
            score: 5,
            content: 'Primero descanso, me ducho y luego arreglo.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'wksthfleoakdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleES/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dhlrkdsodb',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleES/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ekwjdekrka',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleES/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dyffhfkdlvm',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleES/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tmdqntk',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleES/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dkfEMftlswkq',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleES/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhfemsflxmflqj',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleES/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'fldortusaksfpq',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleES/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleES/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlqehfdl',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleES/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wkdrldusdo',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleES/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Whfqh',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleES/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gjekd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleES/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dnjzjghfflr',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleES/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ajdEoflsms',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleES/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sowjrrhkswhd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleES/INFP.jpg',
      },
    ],
  },
  // loveCharacterMaleES
  {
    info: {
      mainTitle: 'Test de Personalidad en noviazgo.',
      subTitle:
        '¿Cuál es mi inclinación a enamorarme como personaje? | para hombres, para mujeres',
      mainUrl: 'loveCharacterMaleES',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'ES',
      category: 'love',
      dualQueries: true,
    },
    questions: [
      {
        which: 'EI',
        question: 'En una cita a ciegas, ¿qué tipo de personas eres?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'No soporto atmósfera incómoda, digo cualquier cosa y hago cualquier pregunta.',
          },
          {
            type: 'I',
            score: 5,
            content:
              'Aunque no me gusta lo incómodo, espero hasta que otro diga algo.',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'Si una pareja de tus amigos te propone una cita doble, ¿qué harías?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'Suena divertido, ¡claro que sí! ¡De inmediato voy a preguntarle a mi novio/a!',
          },
          {
            type: 'I',
            score: 5,
            content:
              'No me lo esperaba... Después de preguntarle a mi novio/a, voy a pensar. (No voy a hacerlo)',
          },
        ],
      },
      {
        which: 'EI',
        question: 'En un novizago, al principio, ¿qué tipo de personas eres?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Hago preguntas más.',
          },
          {
            type: 'I',
            score: 5,
            content: 'Muestro reacción y mi empatía.',
          },
        ],
      },
      {
        which: 'SN',
        question:
          'Al mirar las fotografías de tu novio/a de pequeño/a, por casualidad has encontrado una foto donde salen tu novio/a y tú. ¿Qué dirías?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'Guau, el mundo es muy pequeño. ¡Qué milagro!¿Cómo puede ser?',
          },
          {
            type: 'N',
            score: 5,
            content:
              'Guau. Es como una película. Creo que íbamos a quedar. ¿Tal vez nos vimos en la vida anterior también?',
          },
        ],
      },
      {
        which: 'SN',
        question: 'Si te despiertas desde un mal sueño, ¿qué harías?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'El sueño fue muy realista. Creía que era algo real. Tendría que dormir otra vez.',
          },
          {
            type: 'N',
            score: 5,
            content: 'Me siento mal por el sueño. ¿Algo pasaría?',
          },
        ],
      },
      {
        which: 'SN',
        question: `Vas a ir a un restaurante rico con tu novio/a, ¿cuál ruta elegirías?`,
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Un atajo, que no tiene nada intersante.',
          },
          {
            type: 'N',
            score: 5,
            content: 'Un camino un poquito sinuoso, pero más hermoso.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'Has puesto tu tema favorito para tu novio/a. Pero te dice que no le gusta. ¿Cómo te sentirías?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Tienes curiosidad cómo es su gustao musical y cómo no le gusta este temazo.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Aunque sabes que se trata de gusto personal, te sientes triste.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'Tu novio/a dice que ha peleado con su amigo. ¿Qué le dirías?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '¿Por qué? ¿Por qué has peleado?',
          },
          {
            type: 'F',
            score: 5,
            content: '¿Estás bien? Deberías estar triste. Es su culpa.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'Has esperado aniversario con ansias. Pero tu novio/a se disculpa, diciendo que hoy no puede quedar contigo. ¿Qué harías?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Aunque estoy triste, le pregunto por qué no puede quedar conmigo y si es algo justificable.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Estoy triste y enfadado/a por no poder quedar con él/ella en el aniversario que he esperado con ansias.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Tienes trabajo que hacer. Pero de repente tu novio/a te prpone ir a ver una pelí que quería ver. ¿Qué harías?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Hoy tengo que hacer algo... ¿Qué te parece a las tres de la tarde mañana?',
          },
          {
            type: 'P',
            score: 5,
            content:
              'Aunque tengo que hacer algo, tengo suficiente tiempo. Así que, claro, ¡vamos!',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Cuando tienes conflicto con tu novio/a, ¿qué harías?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'De inmediato quiero decidir cómo solucionarlo y qué hacer adelante de manera clara para resolverelo.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'Dependiendo de la atmósfera de la situación, puedo solucionarlo ahora o no.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Después de un viaje divertido con tu novio/a, ¿qué harías?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Arreglo mi equipaje primero, me ducho y luego descanso.',
          },
          {
            type: 'P',
            score: 5,
            content: 'Primero descanso, me ducho y luego arreglo.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'wksthfleoakdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleES/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dhlrkdsodb',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleES/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ekwjdekrka',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleES/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dyffhfkdlvm',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleES/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tmdqntk',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleES/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dkfEMftlswkq',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleES/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhfemsflxmflqj',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleES/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'fldortusaksfpq',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleES/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleES/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlqehfdl',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleES/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wkdrldusdo',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleES/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Whfqh',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleES/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gjekd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleES/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dnjzjghfflr',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleES/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ajdEoflsms',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleES/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sowjrrhkswhd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleES/INFP.jpg',
      },
    ],
  },
  // loveCharacterFemaleGer
  {
    info: {
      mainTitle: 'Beziehungstypen Test',
      subTitle:
        'Was für ein Charakter ist mein Liebescharakter? | BeziehungstypenTest - Freund, Freundin',
      mainUrl: 'loveCharacterFemaleGer',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Ger',
      category: 'love',
      dualQueries: true,
    },
    questions: [
      {
        which: 'EI',
        question: 'Wie tickst du eher beim Blind Date?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'Ich bin eine kommunikative Person und fange gerne mit dem Gespräch an.',
          },
          {
            type: 'I',
            score: 5,
            content: 'Lieber warte ich ab, bis sie/er mich anspricht.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Deine Freunde möchten Pärchendates machen. ',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'Klar, es wird sicher lustig!! Ich werde meine/n FreundIn sofort fragen! ',
          },
          {
            type: 'I',
            score: 5,
            content: 'Was? Ähm.. Mal gucken... (Ich habe kein Bock drauf...)',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Wie verhälst du dich am Anfang der Beziehung?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Ich stelle ihr/ihn viele Fragen.',
          },
          {
            type: 'I',
            score: 5,
            content: 'Ich bin ein guter Zuhörer. ',
          },
        ],
      },
      {
        which: 'SN',
        question:
          'Wie würdest du dich reagieren,\nwenn du zufällig ein Kindheitsfoto gemeinsam\nmit deiner/m FreundIn finden würde?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Ich kann es nicht fassen. Wie klein die Welt ist!',
          },
          {
            type: 'N',
            score: 5,
            content: 'Ach du meine Güte, das muss ein Schicksal sein!',
          },
        ],
      },
      {
        which: 'SN',
        question: 'Gerade hattest du einen Albtraum.',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'Das war aber richtig realistisch.\nAch was, ich gehe wieder schlafen.',
          },
          {
            type: 'N',
            score: 5,
            content:
              'Was war denn das für ein Traum?\nHat er etwa eine Bedeutung?',
          },
        ],
      },
      {
        which: 'SN',
        question: `Du und dein/e FreundIn möchten ein Restaurant besuchen.\nWelchen Weg möchtest du eher nehmen?`,
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Einen zwar langweiligen, aber kürzeren Weg.',
          },
          {
            type: 'N',
            score: 5,
            content:
              'Einen zwar längeren,\naber schönen Weg mit viel Sehenswürdigkeiten.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'Deiner/m FreundIn gefällt dein Lieblingslied nicht.',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Wie kann es ihr/ihm nicht gefallen?\nIch frage sie/ihn nach dem Grund.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Ich respektiere ihren/seinen Geschmack, trotzdem bin ich ein wenig traurig.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'Dein/e FreundIn hat sich mit einem Freund gestritten.',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Wieso? Weswegen habt ihr euch gestritten?',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Oh, das muss dich echt geärgert haben.\nIch kann dich gut verstehen.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'Dein/e FreundIn sagt,\ndass ihr euch am Jahrestag nicht treffen können.',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Sie/er muss einen angemessenen Grund dafür haben.\nIch frage sie/ihn zunächst,\nwarum wir uns nicht sehen können.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Sie/er weiß doch ganz genau,\nwie sehr ich mich auf diesen Tag gewartet habe!\nIch bin total traurig und wütend!',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Du musst etwas erledigen,\naber dein/e FreundIn möchte kurzfristig mit dir ins Kino gehen.',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Sorry, aber ich muss das unbedingt heute hinter mich bringen.\nWie wäre es mit morgen 15 Uhr?',
          },
          {
            type: 'P',
            score: 5,
            content: 'Ähm… Okay, das kann ich doch auf später verlegen! ',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Wie gehst du mit der Konflikt mit deiner/m FreundIn um?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Ich möchte sofort diskutieren,\nwie wir sie lösen wollen, oder zumindest festlegen,\nob wir jetzt oder später darüber reden möchten.',
          },
          {
            type: 'P',
            score: 5,
            content: 'Es kommt auf die Situation an.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Du bist gerade vom Pärchenurlaub zurückgekommen.',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Ich ruhe mich erst dann aus,\nnachdem ich meinen Koffer ausgepackt und mich geduscht habe.',
          },
          {
            type: 'P',
            score: 5,
            content: 'Zuerst muss ich mal ausruhen. Alles andere kommt danach.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'wksthfleoakdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleGer/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dhlrkdsodb',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleGer/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ekwjdekrka',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleGer/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dyffhfkdlvm',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleGer/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tmdqntk',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleGer/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dkfEMftlswkq',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleGer/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhfemsflxmflqj',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleGer/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'fldortusaksfpq',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleGer/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleGer/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlqehfdl',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleGer/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wkdrldusdo',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleGer/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Whfqh',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleGer/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gjekd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleGer/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dnjzjghfflr',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleGer/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ajdEoflsms',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleGer/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sowjrrhkswhd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleGer/INFP.jpg',
      },
    ],
  },
  // loveCharacterMaleGer
  {
    info: {
      mainTitle: 'Beziehungstypen Test',
      subTitle:
        'Was für ein Charakter ist mein Liebescharakter? | BeziehungstypenTest - Freund, Freundin',
      mainUrl: 'loveCharacterMaleGer',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Ger',
      category: 'love',
      dualQueries: true,
    },
    questions: [
      {
        which: 'EI',
        question: 'Wie tickst du eher beim Blind Date?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'Ich bin eine kommunikative Person und fange gerne mit dem Gespräch an.',
          },
          {
            type: 'I',
            score: 5,
            content: 'Lieber warte ich ab, bis sie/er mich anspricht.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Deine Freunde möchten Pärchendates machen. ',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'Klar, es wird sicher lustig!! Ich werde meine/n FreundIn sofort fragen! ',
          },
          {
            type: 'I',
            score: 5,
            content: 'Was? Ähm.. Mal gucken... (Ich habe kein Bock drauf...)',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Wie verhälst du dich am Anfang der Beziehung?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Ich stelle ihr/ihn viele Fragen.',
          },
          {
            type: 'I',
            score: 5,
            content: 'Ich bin ein guter Zuhörer. ',
          },
        ],
      },
      {
        which: 'SN',
        question:
          'Wie würdest du dich reagieren,\nwenn du zufällig ein Kindheitsfoto gemeinsam\nmit deiner/m FreundIn finden würde?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Ich kann es nicht fassen. Wie klein die Welt ist!',
          },
          {
            type: 'N',
            score: 5,
            content: 'Ach du meine Güte, das muss ein Schicksal sein!',
          },
        ],
      },
      {
        which: 'SN',
        question: 'Gerade hattest du einen Albtraum.',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'Das war aber richtig realistisch.\nAch was, ich gehe wieder schlafen.',
          },
          {
            type: 'N',
            score: 5,
            content:
              'Was war denn das für ein Traum?\nHat er etwa eine Bedeutung?',
          },
        ],
      },
      {
        which: 'SN',
        question: `Du und dein/e FreundIn möchten ein Restaurant besuchen.\nWelchen Weg möchtest du eher nehmen?`,
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Einen zwar langweiligen, aber kürzeren Weg.',
          },
          {
            type: 'N',
            score: 5,
            content:
              'Einen zwar längeren,\naber schönen Weg mit viel Sehenswürdigkeiten.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'Deiner/m FreundIn gefällt dein Lieblingslied nicht.',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Wie kann es ihr/ihm nicht gefallen?\nIch frage sie/ihn nach dem Grund.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Ich respektiere ihren/seinen Geschmack, trotzdem bin ich ein wenig traurig.',
          },
        ],
      },
      {
        which: 'TF',
        question: 'Dein/e FreundIn hat sich mit einem Freund gestritten.',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Wieso? Weswegen habt ihr euch gestritten?',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Oh, das muss dich echt geärgert haben.\nIch kann dich gut verstehen.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'Dein/e FreundIn sagt,\ndass ihr euch am Jahrestag nicht treffen können.',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Sie/er muss einen angemessenen Grund dafür haben.\nIch frage sie/ihn zunächst,\nwarum wir uns nicht sehen können.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Sie/er weiß doch ganz genau,\nwie sehr ich mich auf diesen Tag gewartet habe!\nIch bin total traurig und wütend!',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Du musst etwas erledigen,\naber dein/e FreundIn möchte kurzfristig mit dir ins Kino gehen.',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Sorry, aber ich muss das unbedingt heute hinter mich bringen.\nWie wäre es mit morgen 15 Uhr?',
          },
          {
            type: 'P',
            score: 5,
            content: 'Ähm… Okay, das kann ich doch auf später verlegen! ',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Wie gehst du mit der Konflikt mit deiner/m FreundIn um?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Ich möchte sofort diskutieren,\nwie wir sie lösen wollen, oder zumindest festlegen,\nob wir jetzt oder später darüber reden möchten.',
          },
          {
            type: 'P',
            score: 5,
            content: 'Es kommt auf die Situation an.',
          },
        ],
      },
      {
        which: 'JP',
        question: 'Du bist gerade vom Pärchenurlaub zurückgekommen.',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Ich ruhe mich erst dann aus,\nnachdem ich meinen Koffer ausgepackt und mich geduscht habe.',
          },
          {
            type: 'P',
            score: 5,
            content: 'Zuerst muss ich mal ausruhen. Alles andere kommt danach.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'wksthfleoakdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleGer/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dhlrkdsodb',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleGer/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ekwjdekrka',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleGer/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dyffhfkdlvm',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleGer/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tmdqntk',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleGer/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dkfEMftlswkq',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleGer/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhfemsflxmflqj',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleGer/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'fldortusaksfpq',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleGer/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleGer/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlqehfdl',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleGer/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wkdrldusdo',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleGer/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Whfqh',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleGer/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gjekd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleGer/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dnjzjghfflr',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleGer/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ajdEoflsms',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleGer/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sowjrrhkswhd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleGer/INFP.jpg',
      },
    ],
  },
  // loveCharacterMaleCN
  {
    info: {
      mainTitle: '恋爱角色测试',
      subTitle: '我的爱情角色是什么样的角色？ | 恋爱角色测试 - 女方,男方',
      mainUrl: 'loveCharacterMaleCN',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'CN',
      category: 'love',
      dualQueries: true,
    },
    questions: [
      {
        which: 'EI',
        question: '相亲时我更倾向于？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '受不了尴尬的气氛，不管什么话题都会先主动说话或提问',
          },
          {
            type: 'I',
            score: 5,
            content: '虽然不太喜欢尴尬的气氛，但也一直等对方先主动说话',
          },
        ],
      },
      {
        which: 'EI',
        question: '几对朋友情侣提出团体情侣约会时我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '应该会很有趣哇，当然爽快答应喽！！马上去问问恋人！',
          },
          {
            type: 'I',
            score: 5,
            content: '这么突然呀..?先问一下恋人再考虑吧..（还是说不参加了吧…）',
          },
        ],
      },
      {
        which: 'EI',
        question: '恋爱初期的我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '问题一大堆',
          },
          {
            type: 'I',
            score: 5,
            content: '给予一定的反应并与对方产生共鸣',
          },
        ],
      },
      {
        which: 'SN',
        question:
          '和恋人一起看小时候的照片时突然发现有两个人偶然拍在同一张照片里的时候我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '哇！世界真的好小呀，好神奇，怎么会有这种偶然呢？',
          },
          {
            type: 'N',
            score: 5,
            content:
              '哇！简直跟电影一样呢，我们真是命中注定的呢~！前世说不定也见过面呢？',
          },
        ],
      },
      {
        which: 'SN',
        question: '做不好的梦突然惊醒时的我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '这梦可太真了，简直跟现实一样，再睡一会吧..',
          },
          {
            type: 'N',
            score: 5,
            content: '怎么会做这种梦呢，真让人难受，不会有什么不好的事发生吧？',
          },
        ],
      },
      {
        which: 'SN',
        question: `约恋人一起去美食餐馆时我想走的路线是？`,
        answers: [
          {
            type: 'S',
            score: 2,
            content: '虽然没什么值得观赏的，但能最快抵达餐馆的捷径',
          },
          {
            type: 'N',
            score: 5,
            content: '虽然有些绕路，但有很多美丽的风景可观赏的路线',
          },
        ],
      },
      {
        which: 'TF',
        question: '给恋人听我喜欢的音乐，但对方却说不是自己喜欢的风格时我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '好奇对方到底喜欢什么音乐风格，居然连这种名歌都不喜欢',
          },
          {
            type: 'F',
            score: 5,
            content: '虽然每个人喜欢的音乐风格不一样，但也会有一点点小伤心',
          },
        ],
      },
      {
        which: 'TF',
        question: '恋人联系我说和朋友吵架了时我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '为什么？因为什么吵起来的呀？',
          },
          {
            type: 'F',
            score: 5,
            content: '没事吧？肯定很伤心呢，朋友太过分啦',
          },
        ],
      },
      {
        which: 'TF',
        question: '久等的纪念日！但恋人却说抱歉今天不能见面时我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '虽然很伤心，但会先问为什么见不了面，仔细询问正当理由',
          },
          {
            type: 'F',
            score: 5,
            content:
              '提前说好的纪念日约会却不能见面，因此很生气也很伤心，对对方发火',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '我有很重要的事情要去做，但恋人却突然说想一起去看想看的电影时我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '今天有必须完成的工作..明天下午3点怎么样?',
          },
          {
            type: 'P',
            score: 5,
            content: '虽然有必须去做的事情，但时间还充足，就一起去看电影吧！',
          },
        ],
      },
      {
        which: 'JP',
        question: '和恋人产生矛盾时我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '决定好现在马上该如何去解决矛盾，以后应该怎么相处，然后再做出行动',
          },
          {
            type: 'P',
            score: 5,
            content: '根据具体情况和氛围再决定要不要立刻解决还是再拖一拖',
          },
        ],
      },
      {
        which: 'JP',
        question: '和恋人一起开心地旅游回来之后我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '马上整理行李，然后洗澡后休息',
          },
          {
            type: 'P',
            score: 5,
            content: '先休息一会儿，然后洗个澡再整理行李',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'wksthfleoakdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleCN/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dhlrkdsodb',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleCN/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ekwjdekrka',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleCN/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dyffhfkdlvm',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleCN/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tmdqntk',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleCN/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dkfEMftlswkq',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleCN/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhfemsflxmflqj',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleCN/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'fldortusaksfpq',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleCN/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleCN/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlqehfdl',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleCN/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wkdrldusdo',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleCN/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Whfqh',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleCN/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gjekd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleCN/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dnjzjghfflr',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleCN/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ajdEoflsms',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleCN/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sowjrrhkswhd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleCN/INFP.jpg',
      },
    ],
  },
  // loveCharacterFemaleCN
  {
    info: {
      mainTitle: '恋爱角色测试',
      subTitle: '我的爱情角色是什么样的角色？ | 恋爱角色测试 - 女方,男方',
      mainUrl: 'loveCharacterFemaleCN',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'CN',
      category: 'love',
      dualQueries: true,
    },
    questions: [
      {
        which: 'EI',
        question: '相亲时我更倾向于？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '受不了尴尬的气氛，不管什么话题都会先主动说话或提问',
          },
          {
            type: 'I',
            score: 5,
            content: '虽然不太喜欢尴尬的气氛，但也一直等对方先主动说话',
          },
        ],
      },
      {
        which: 'EI',
        question: '几对朋友情侣提出团体情侣约会时我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '应该会很有趣哇，当然爽快答应喽！！马上去问问恋人！',
          },
          {
            type: 'I',
            score: 5,
            content: '这么突然呀..?先问一下恋人再考虑吧..（还是说不参加了吧…）',
          },
        ],
      },
      {
        which: 'EI',
        question: '恋爱初期的我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '问题一大堆',
          },
          {
            type: 'I',
            score: 5,
            content: '给予一定的反应并与对方产生共鸣',
          },
        ],
      },
      {
        which: 'SN',
        question:
          '和恋人一起看小时候的照片时突然发现有两个人偶然拍在同一张照片里的时候我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '哇！世界真的好小呀，好神奇，怎么会有这种偶然呢？',
          },
          {
            type: 'N',
            score: 5,
            content:
              '哇！简直跟电影一样呢，我们真是命中注定的呢~！前世说不定也见过面呢？',
          },
        ],
      },
      {
        which: 'SN',
        question: '做不好的梦突然惊醒时的我会？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '这梦可太真了，简直跟现实一样，再睡一会吧..',
          },
          {
            type: 'N',
            score: 5,
            content: '怎么会做这种梦呢，真让人难受，不会有什么不好的事发生吧？',
          },
        ],
      },
      {
        which: 'SN',
        question: `约恋人一起去美食餐馆时我想走的路线是？`,
        answers: [
          {
            type: 'S',
            score: 2,
            content: '虽然没什么值得观赏的，但能最快抵达餐馆的捷径',
          },
          {
            type: 'N',
            score: 5,
            content: '虽然有些绕路，但有很多美丽的风景可观赏的路线',
          },
        ],
      },
      {
        which: 'TF',
        question: '给恋人听我喜欢的音乐，但对方却说不是自己喜欢的风格时我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '好奇对方到底喜欢什么音乐风格，居然连这种名歌都不喜欢',
          },
          {
            type: 'F',
            score: 5,
            content: '虽然每个人喜欢的音乐风格不一样，但也会有一点点小伤心',
          },
        ],
      },
      {
        which: 'TF',
        question: '恋人联系我说和朋友吵架了时我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '为什么？因为什么吵起来的呀？',
          },
          {
            type: 'F',
            score: 5,
            content: '没事吧？肯定很伤心呢，朋友太过分啦',
          },
        ],
      },
      {
        which: 'TF',
        question: '久等的纪念日！但恋人却说抱歉今天不能见面时我会？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '虽然很伤心，但会先问为什么见不了面，仔细询问正当理由',
          },
          {
            type: 'F',
            score: 5,
            content:
              '提前说好的纪念日约会却不能见面，因此很生气也很伤心，对对方发火',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '我有很重要的事情要去做，但恋人却突然说想一起去看想看的电影时我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '今天有必须完成的工作..明天下午3点怎么样?',
          },
          {
            type: 'P',
            score: 5,
            content: '虽然有必须去做的事情，但时间还充足，就一起去看电影吧！',
          },
        ],
      },
      {
        which: 'JP',
        question: '和恋人产生矛盾时我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '决定好现在马上该如何去解决矛盾，以后应该怎么相处，然后再做出行动',
          },
          {
            type: 'P',
            score: 5,
            content: '根据具体情况和氛围再决定要不要立刻解决还是再拖一拖',
          },
        ],
      },
      {
        which: 'JP',
        question: '和恋人一起开心地旅游回来之后我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '马上整理行李，然后洗澡后休息',
          },
          {
            type: 'P',
            score: 5,
            content: '先休息一会儿，然后洗个澡再整理行李',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'wksthfleoakdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleCN/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dhlrkdsodb',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleCN/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ekwjdekrka',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleCN/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dyffhfkdlvm',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleCN/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tmdqntk',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleCN/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dkfEMftlswkq',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleCN/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhfemsflxmflqj',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleCN/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'fldortusaksfpq',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleCN/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleCN/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlqehfdl',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleCN/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wkdrldusdo',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleCN/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Whfqh',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleCN/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gjekd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleCN/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dnjzjghfflr',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleCN/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ajdEoflsms',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleCN/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sowjrrhkswhd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleCN/INFP.jpg',
      },
    ],
  },
  // loveCharacterFemaleEng
  {
    info: {
      mainTitle: 'Love Character Test - Female',
      subTitle:
        'What kind of my character is my love character? - Female | Love chracter test - male female boyfriend girlfriend',
      mainUrl: 'loveCharacterFemaleEng',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Eng',
      category: 'love',
      dualQueries: true,
    },
    questions: [
      {
        which: 'EI',
        question: 'When you go on a blind date, how do you do?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              "I can't stand the awkwardness and try to say anything or ask some questions",
          },
          {
            type: 'I',
            score: 5,
            content:
              'I hate awkwardness, but I will wait until the other person says something',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'When your friend asks you to go on a couple date, what can be your reaction?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              "It's going to be fun, of course, we'll go on a couple date together. I'll ask my lover right away",
          },
          {
            type: 'I',
            score: 5,
            content:
              "Suddenly? I'll ask my lover first and think about it (Should I say I'm not going...)",
          },
        ],
      },
      {
        which: 'EI',
        question: 'What do you do at the beginning of a relationship?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I tend to ask lots of questions',
          },
          {
            type: 'I',
            score: 5,
            content: 'I tend to be responsive and empathize first',
          },
        ],
      },
      {
        which: 'SN',
        question:
          'How would you react if you were looking through your childhood photos and found a picture of the two of you together?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'Wow, the world is really small Miraculous! How could this happen?',
          },
          {
            type: 'N',
            score: 5,
            content:
              "Wow, it's like a movie. It must be our destiny! Maybe we met in a past life",
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do you do if you woke up from a bad dream?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'It looks so real! anyway, I need to go back to sleep.',
          },
          {
            type: 'N',
            score: 5,
            content:
              'Oh, why do I have such a strange dream? Should I be worried about something?',
          },
        ],
      },
      {
        which: 'SN',
        question: `When you go to a must-eat restaurant, which route do you want to take?`,
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              "I'm not interested in sightseeing along the way, so I prefer to take shortcuts.",
          },
          {
            type: 'N',
            score: 5,
            content:
              'Even if it takes a little longer, if possible, I will go the scenic route',
          },
        ],
      },
      {
        which: 'TF',
        question:
          "If you play your lover a song you like and he/she doesn't like it, what can be your reaction?",
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'I might wonder why my lover does not like this masterpiece',
          },
          {
            type: 'F',
            score: 5,
            content:
              'I understand that each person has a different taste in music, but I might feel sad for nothing.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'When you received a call that your lover argues with his/her friend, what would you do?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Why? what are the reasons for that?',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Are you okay? You must have been upset, your friend must have done something wrong right?',
          },
        ],
      },
      {
        which: 'TF',
        question:
          "How would you react if your lover couldn't come to your anniversary?",
        answers: [
          {
            type: 'T',
            score: 2,
            content: "I'll ask for a good reason, but anyway it's so sad",
          },
          {
            type: 'F',
            score: 5,
            content:
              "I'm upset and regretful that I couldn't meet my lover on the anniversary I mentioned in advance.",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What would you do if your lover asked you to see a movie while you had work to do?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I have something to do today. How about tomorrow at 3pm?',
          },
          {
            type: 'P',
            score: 5,
            content:
              "I have work to do, but I think I do have plenty of time, okay let's go see it!",
          },
        ],
      },
      {
        which: 'JP',
        question: 'When you have a conflict with your lover, what do you do?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I must first clearly define and then solve it.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'I look at the situation first and decide whether or not to solve it right away depending on the mood',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What do you do right after going on a fun trip with your lover?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: "I'll unpack my travel luggage, take a shower, and rest",
          },
          {
            type: 'P',
            score: 5,
            content: 'I will rest after shower and sort things out later',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'rjfzmfjtnl',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dlsaordhkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'vudghkwndmlwk',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'akdldnpdl',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'eksghqkr',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qnfehwj',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'eodeodal',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'qnsdnlrlapdlzj',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'fkEpsms',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlqtnsdl',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'dhfkrrkfkr',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'thtlawoddl',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cmsepfp',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'wkdsuwowlf',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'rhdrkadhkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sowjrrhkswhd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemaleEng/INFP.jpg',
      },
    ],
  },
  // loveCharacterMaleEng
  {
    info: {
      mainTitle: 'Love Character Test - Male',
      subTitle:
        'What kind of my character is my love character? - Male | Love chracter test - male female boyfriend girlfriend',
      mainUrl: 'loveCharacterMaleEng',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Eng',
      category: 'love',
      dualQueries: true,
    },
    questions: [
      {
        which: 'EI',
        question: 'When you go on a blind date, how do you do?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              "I can't stand the awkwardness and try to say anything or ask some questions",
          },
          {
            type: 'I',
            score: 5,
            content:
              'I hate awkwardness, but I will wait until the other person says something',
          },
        ],
      },
      {
        which: 'EI',
        question:
          'When your friend asks you to go on a couple date, what can be your reaction?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              "It's going to be fun, of course, we'll go on a couple date together. I'll ask my lover right away",
          },
          {
            type: 'I',
            score: 5,
            content:
              "Suddenly? I'll ask my lover first and think about it (Should I say I'm not going...)",
          },
        ],
      },
      {
        which: 'EI',
        question: 'What do you do at the beginning of a relationship?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I tend to ask lots of questions',
          },
          {
            type: 'I',
            score: 5,
            content: 'I tend to be responsive and empathize first',
          },
        ],
      },
      {
        which: 'SN',
        question:
          'How would you react if you were looking through your childhood photos and found a picture of the two of you together?',
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              'Wow, the world is really small Miraculous! How could this happen?',
          },
          {
            type: 'N',
            score: 5,
            content:
              "Wow, it's like a movie. It must be our destiny! Maybe we met in a past life",
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do you do if you woke up from a bad dream?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'It looks so real! anyway, I need to go back to sleep.',
          },
          {
            type: 'N',
            score: 5,
            content:
              'Oh, why do I have such a strange dream? Should I be worried about something?',
          },
        ],
      },
      {
        which: 'SN',
        question: `When you go to a must-eat restaurant, which route do you want to take?`,
        answers: [
          {
            type: 'S',
            score: 2,
            content:
              "I'm not interested in sightseeing along the way, so I prefer to take shortcuts.",
          },
          {
            type: 'N',
            score: 5,
            content:
              'Even if it takes a little longer, if possible, I will go the scenic route',
          },
        ],
      },
      {
        which: 'TF',
        question:
          "If you play your lover a song you like and he/she doesn't like it, what can be your reaction?",
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'I might wonder why my lover does not like this masterpiece',
          },
          {
            type: 'F',
            score: 5,
            content:
              'I understand that each person has a different taste in music, but I might feel sad for nothing.',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'When you received a call that your lover argues with his/her friend, what would you do?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Why? what are the reasons for that?',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Are you okay? You must have been upset, your friend must have done something wrong right?',
          },
        ],
      },
      {
        which: 'TF',
        question:
          "How would you react if your lover couldn't come to your anniversary?",
        answers: [
          {
            type: 'T',
            score: 2,
            content: "I'll ask for a good reason, but anyway it's so sad",
          },
          {
            type: 'F',
            score: 5,
            content:
              "I'm upset and regretful that I couldn't meet my lover on the anniversary I mentioned in advance.",
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What would you do if your lover asked you to see a movie while you had work to do?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I have something to do today. How about tomorrow at 3pm?',
          },
          {
            type: 'P',
            score: 5,
            content:
              "I have work to do, but I think I do have plenty of time, okay let's go see it!",
          },
        ],
      },
      {
        which: 'JP',
        question: 'When you have a conflict with your lover, what do you do?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I must first clearly define and then solve it.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'I look at the situation first and decide whether or not to solve it right away depending on the mood',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What do you do right after going on a fun trip with your lover?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: "I'll unpack my travel luggage, take a shower, and rest",
          },
          {
            type: 'P',
            score: 5,
            content: 'I will rest after shower and sort things out later',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'wksthfleoakdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dhlrkdsodb',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ekwjdekrka',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dyffhfkdlvm',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tmdqntk',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dkfEMftlswkq',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhfemsflxmflqj',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'fldortusaksfpq',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlqehfdl',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wkdrldusdo',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Whfqh',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gjekd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dnjzjghfflr',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ajdEoflsms',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sowjrrhkswhd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMaleEng/INFP.jpg',
      },
    ],
  },
  // loveCharacterFemale
  {
    info: {
      mainTitle: '연애 캐릭터 테스트 - 여자편',
      subTitle:
        '캐릭터로 보는 나의 연애 성향은? - 여자편 | 연애 캐릭터 테스트 남자편 여자편',
      mainUrl: 'loveCharacterFemale',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'love',
      dualQueries: true,
    },
    questions: [
      {
        which: 'EI',
        question: '소개팅 할때 나와 가까운 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '어색함을 견디지 못하고 무슨 말이든 질문이든 해본다',
          },
          {
            type: 'I',
            score: 5,
            content:
              '어색함이 싫지만,\n상대가 무슨 말이든 할 때 까지 기다려 본다',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구 커플들에게 단체 커플 데이트 약속이 들어온다면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '재밌겠다 콜 이지!!\n애인한테 바로 물어볼게!',
          },
          {
            type: 'I',
            score: 5,
            content:
              '갑자기..? 일단 애인에게 물어보고 생각해 볼게..(안 간다고 해야지..)',
          },
        ],
      },
      {
        which: 'EI',
        question: '연애 초반에 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '질문을 더 많이 하는 편이다',
          },
          {
            type: 'I',
            score: 5,
            content: '리액션과 선공감을 하는 편이다',
          },
        ],
      },
      {
        which: 'SN',
        question:
          '연인과 어렸을 때 사진을 구경하던 중에\n우연히 둘이 같이 찍힌 사진을 발견했다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '우와 세상 진짜 좁네 신기하다\n어떻게 이럴 수가 있지? ',
          },
          {
            type: 'N',
            score: 5,
            content:
              '우와 무슨 영화 같다 우리 운명인가봐~!\n우리 전생에도 만났을까?',
          },
        ],
      },
      {
        which: 'SN',
        question: '안 좋은 꿈을 꾸다가 잠에서 깼을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '꿈 한번 리얼하네 현실인 줄 다시 자야지..',
          },
          {
            type: 'N',
            score: 5,
            content:
              '무슨 이런 꿈을 꾸지 괜히 찜찜하게 무슨 일 나는거 아니겠지?',
          },
        ],
      },
      {
        which: 'SN',
        question: `연인과 맛집을 가기로 했는데 내가 가고 싶은 경로는?`,
        answers: [
          {
            type: 'S',
            score: 2,
            content: '볼건 없지만 맛집에 빠르게 갈 수 있는 지름길',
          },
          {
            type: 'N',
            score: 5,
            content: '조금 더디지만 예쁜 구경거리가 있는 길',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '연인에게 내가 좋아하는 노래를 들려줬는데 자기 취향이 아니라고 한다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '노래 취향이 어떻길래 이 띵곡이 취향이 아닌 건지 궁금해 한다',
          },
          {
            type: 'F',
            score: 5,
            content: '노래는 취향인걸 알지만 괜히 서운하다',
          },
        ],
      },
      {
        which: 'TF',
        question: '연인이 친구랑 싸웠다고 연락이 온다면 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜? 뭐 때문에 싸운거야?',
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮아?\n속상하겠다 친구가 잘 못했네',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '기다리던 기념일! 하지만 연인이 미안하다며 오늘 못 만날 것 같다고 한다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '너무 서운하지만 일단 왜 못 만나는지,\n정당한 이유인지 물어본다',
          },
          {
            type: 'F',
            score: 5,
            content:
              '미리 말했던 기념일에 못 만난다는 것에 화나고 서러워서 화를 낸다',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '해야 할 일이 있는데 연인이 갑자기 보고싶었던 영화를 보러 가자고 한다면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '오늘은 해야 할게 있어서..\n내일 오후 3시에 어때?',
          },
          {
            type: 'P',
            score: 5,
            content:
              '해야 할게 있긴 하지만,\n시간은 충분하니까 뭐~ 그래 보러가자!',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 어떤 갈등이 생겼을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '지금 당장 어떻게 해결할 건지,\n앞으로 어떻게 할 건지,\n명확하게 정해 놓고 해결하고 싶어한다',
          },
          {
            type: 'P',
            score: 5,
            content: '상황을 보고 분위기에 따라 당장 해결 하든 말든 한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 재밌는 여행을 다녀온 후 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '바로 짐부터 정리하고 샤워 후에 쉰다',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 쉬다가 샤워 후 나중에 정리한다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'rjfzmfjtnl',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemale/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dlsaordhkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemale/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'vudghkwndmlwk',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemale/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'akdldnpdl',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemale/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'eksghqkr',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemale/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'qnfehwj',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemale/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'eodeodal',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemale/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'qnsdnlrlapdlzj',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemale/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'fkEpsms',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemale/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlqtnsdl',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemale/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'dhfkrrkfkr',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemale/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'thtlawoddl',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemale/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'cmsepfp',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemale/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'wkdsuwowlf',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemale/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'rhdrkadhkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemale/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sowjrrhkswhd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterFemale/INFP.jpg',
      },
    ],
  },
  // loveCharacterMale
  {
    info: {
      mainTitle: '연애 캐릭터 테스트 - 남자편',
      subTitle:
        '캐릭터로 보는 나의 연애 성향은? - 남자편 | 연애 캐릭터 테스트 남자편 여자편',
      mainUrl: 'loveCharacterMale',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'love',
      dualQueries: true,
    },
    questions: [
      {
        which: 'EI',
        question: '소개팅 할때 나와 가까운 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '어색함을 견디지 못하고 무슨 말이든 질문이든 해본다',
          },
          {
            type: 'I',
            score: 5,
            content:
              '어색함이 싫지만,\n상대가 무슨 말이든 할 때 까지 기다려 본다',
          },
        ],
      },
      {
        which: 'EI',
        question: '친구 커플들에게 단체 커플 데이트 약속이 들어온다면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '재밌겠다 콜 이지!!\n애인한테 바로 물어볼게!',
          },
          {
            type: 'I',
            score: 5,
            content:
              '갑자기..? 일단 애인에게 물어보고 생각해 볼게..(안 간다고 해야지..)',
          },
        ],
      },
      {
        which: 'EI',
        question: '연애 초반에 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '질문을 더 많이 하는 편이다',
          },
          {
            type: 'I',
            score: 5,
            content: '리액션과 선공감을 하는 편이다',
          },
        ],
      },
      {
        which: 'SN',
        question:
          '연인과 어렸을 때 사진을 구경하던 중에\n우연히 둘이 같이 찍힌 사진을 발견했다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '우와 세상 진짜 좁네 신기하다\n어떻게 이럴 수가 있지? ',
          },
          {
            type: 'N',
            score: 5,
            content:
              '우와 무슨 영화 같다 우리 운명인가봐~!\n우리 전생에도 만났을까?',
          },
        ],
      },
      {
        which: 'SN',
        question: '안 좋은 꿈을 꾸다가 잠에서 깼을 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '꿈 한번 리얼하네 현실인 줄 다시 자야지..',
          },
          {
            type: 'N',
            score: 5,
            content:
              '무슨 이런 꿈을 꾸지 괜히 찜찜하게 무슨 일 나는거 아니겠지?',
          },
        ],
      },
      {
        which: 'SN',
        question: `연인과 맛집을 가기로 했는데 내가 가고 싶은 경로는?`,
        answers: [
          {
            type: 'S',
            score: 2,
            content: '볼건 없지만 맛집에 빠르게 갈 수 있는 지름길',
          },
          {
            type: 'N',
            score: 5,
            content: '조금 더디지만 예쁜 구경거리가 있는 길',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '연인에게 내가 좋아하는 노래를 들려줬는데 자기 취향이 아니라고 한다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '노래 취향이 어떻길래 이 띵곡이 취향이 아닌 건지 궁금해 한다',
          },
          {
            type: 'F',
            score: 5,
            content: '노래는 취향인걸 알지만 괜히 서운하다',
          },
        ],
      },
      {
        which: 'TF',
        question: '연인이 친구랑 싸웠다고 연락이 온다면 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: '왜? 뭐 때문에 싸운거야?',
          },
          {
            type: 'F',
            score: 5,
            content: '괜찮아?\n속상하겠다 친구가 잘 못했네',
          },
        ],
      },
      {
        which: 'TF',
        question:
          '기다리던 기념일! 하지만 연인이 미안하다며 오늘 못 만날 것 같다고 한다면?',
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '너무 서운하지만 일단 왜 못 만나는지,\n정당한 이유인지 물어본다',
          },
          {
            type: 'F',
            score: 5,
            content:
              '미리 말했던 기념일에 못 만난다는 것에 화나고 서러워서 화를 낸다',
          },
        ],
      },
      {
        which: 'JP',
        question:
          '해야 할 일이 있는데 연인이 갑자기 보고싶었던 영화를 보러 가자고 한다면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '오늘은 해야 할게 있어서..\n내일 오후 3시에 어때?',
          },
          {
            type: 'P',
            score: 5,
            content:
              '해야 할게 있긴 하지만,\n시간은 충분하니까 뭐~ 그래 보러가자!',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 어떤 갈등이 생겼을 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '지금 당장 어떻게 해결할 건지,\n앞으로 어떻게 할 건지,\n명확하게 정해 놓고 해결하고 싶어한다',
          },
          {
            type: 'P',
            score: 5,
            content: '상황을 보고 분위기에 따라 당장 해결 하든 말든 한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 재밌는 여행을 다녀온 후 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '바로 짐부터 정리하고 샤워 후에 쉰다',
          },
          {
            type: 'P',
            score: 5,
            content: '일단 쉬다가 샤워 후 나중에 정리한다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'wksthfleoakdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMale/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dhlrkdsodb',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMale/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ekwjdekrka',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMale/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dyffhfkdlvm',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMale/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tmdqntk',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMale/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dkfEMftlswkq',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMale/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhfemsflxmflqj',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMale/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'fldortusaksfpq',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMale/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMale/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlqehfdl',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMale/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wkdrldusdo',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMale/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Whfqh',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMale/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gjekd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMale/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dnjzjghfflr',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMale/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ajdEoflsms',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMale/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sowjrrhkswhd',
        img_src:
          'https://images.ktestone.com/resultImages/loveCharacterMale/INFP.jpg',
      },
    ],
  },
  // coupleCharacterFemaleIT
  {
    info: {
      mainTitle: 'Test del carattere della coppia',
      subTitle:
        'Quali sono le mie tendenze romantiche come personaggio di coppia?',
      mainUrl: 'coupleCharacterFemaleIT',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'IT',
      category: 'love',
      dualQueries: true,
    },
    questions: [
      {
        which: 'EI',
        question:
          'Quando devo decidere quale appuntamento avere con il mio partner, lo faccio?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Iniziativa e proattività',
          },
          {
            type: 'I',
            score: 5,
            content:
              "Reagisco e sono d'accordo con le decisioni del mio amante",
          },
        ],
      },
      {
        which: 'EI',
        question: 'Qual è il più vicino a voi?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'Parlo molto quando sono con le persone e ancora di più quando sono con il mio amante',
          },
          {
            type: 'I',
            score: 5,
            content:
              'Parlo molto quando sono con il mio amante, ma non quando sono con qualcun altro',
          },
        ],
      },
      {
        which: 'EI',
        question:
          "Come reagireste se, all'inizio della vostra relazione, voi e il vostro partner vi frequentaste ininterrottamente nei fine settimana?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: `È stato un appuntamento molto soddisfacente e felice. Mi ha guarito completamente!`,
          },
          {
            type: 'I',
            score: 5,
            content: `È stato un appuntamento divertente, ma dovrei prendere uno dei due giorni liberi della prossima settimana...?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'Se mentre parlavate al telefono il vostro amante vi dicesse: "È ora di dormire", come reagireste?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `È vero, è tardi, quindi andiamo a letto.`,
          },
          {
            type: 'N',
            score: 5,
            content: `Mi domanderei perché all'improvviso vuoi dormire? Sei annoiato di me?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'Come reagireste se mangiaste un cibo molto delizioso in un ristorante?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'È così delizioso che voglio tornare la prossima volta.',
          },
          {
            type: 'N',
            score: 5,
            content: `E 'così delizioso, vorrei provare a immaginare come fare questo cibo`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'Quando cucinate per il vostro amante, cosa rispondereste se vi chiedessero come avete fatto a renderlo così delizioso?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Rispondevo: "Ho seguito la ricetta."`,
          },
          {
            type: 'N',
            score: 5,
            content: `Io risponderei: "L'ho fatto con il mio gusto"`,
          },
        ],
      },
      {
        which: 'TF',
        question: `E se un ragazzo per strada vi dicesse che siete il suo compagno ideale e vi chiedesse il numero?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Ne sono felice e mi chiedo cosa gli piaccia di me.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Mi sento bene e immagino come sarebbe uscire con questa persona',
          },
        ],
      },
      {
        which: 'TF',
        question: "Com'ero quando ho iniziato a frequentare qualcuno?",
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Ho molte domande e interesse`,
          },
          {
            type: 'F',
            score: 5,
            content: 'Ho molta empatia e reazioni',
          },
        ],
      },
      {
        which: 'TF',
        question: `Se il tuo amante mi dà da mangiare, cosa mi impressiona?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Sarei impressionato dalla qualità e dal gusto del cibo',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Mi commuoveva il fatto che il mio amante cucinasse per me',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Cosa comprereste se vi fermaste in un supermercato mentre andate in campeggio con il vostro amante?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Deciderei in anticipo cosa comprare e comprerei solo ciò di cui abbiamo bisogno.`,
          },
          {
            type: 'P',
            score: 5,
            content: `Penserei a cosa comprare mentre mi guardo intorno al negozio`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Dopo aver mangiato cibo consegnato in casa, come si pulisce?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Devo pulire subito.',
          },
          {
            type: 'P',
            score: 5,
            content: 'Faccio una pausa, parlo e pulisco lentamente più tardi',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Cosa fate quando andate in un ristorante famoso con il vostro amante?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Preparo e verifico in anticipo il modo più semplice per arrivarci',
          },
          {
            type: 'P',
            score: 5,
            content:
              "All'ultimo minuto, di solito mi sono affrettato a trovare un modo per arrivare lì",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'wksthfleoakdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleIT/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dhlrkdsodb',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleIT/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ekwjdekrka',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleIT/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dyffhfkdlvm',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleIT/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tmdqntk',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleIT/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dkfEMftlswkq',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleIT/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhfemsflxmflqj',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleIT/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'fldortusaksfpq',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleIT/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleIT/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlqehfdl',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleIT/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wkdrldusdo',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleIT/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Whfqh',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleIT/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gjekd',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleIT/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dnjzjghfflr',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleIT/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ajdEoflsms',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleIT/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sowjrrhkswhd',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleIT/INFP.jpg',
      },
    ],
  },
  // coupleCharacterMaleIT
  {
    info: {
      mainTitle: 'Test del carattere della coppia',
      subTitle:
        'Quali sono le mie tendenze romantiche come personaggio di coppia?',
      mainUrl: 'coupleCharacterMaleIT',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'IT',
      category: 'love',
      dualQueries: true,
    },
    questions: [
      {
        which: 'EI',
        question:
          'Quando devo decidere quale appuntamento avere con il mio partner, lo faccio?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'Iniziativa e proattività',
          },
          {
            type: 'I',
            score: 5,
            content:
              "Reagisco e sono d'accordo con le decisioni del mio amante",
          },
        ],
      },
      {
        which: 'EI',
        question: 'Qual è il più vicino a voi?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'Parlo molto quando sono con le persone e ancora di più quando sono con il mio amante',
          },
          {
            type: 'I',
            score: 5,
            content:
              'Parlo molto quando sono con il mio amante, ma non quando sono con qualcun altro',
          },
        ],
      },
      {
        which: 'EI',
        question:
          "Come reagireste se, all'inizio della vostra relazione, voi e il vostro partner vi frequentaste ininterrottamente nei fine settimana?",
        answers: [
          {
            type: 'E',
            score: 2,
            content: `È stato un appuntamento molto soddisfacente e felice. Mi ha guarito completamente!`,
          },
          {
            type: 'I',
            score: 5,
            content: `È stato un appuntamento divertente, ma dovrei prendere uno dei due giorni liberi della prossima settimana...?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'Se mentre parlavate al telefono il vostro amante vi dicesse: "È ora di dormire", come reagireste?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `È vero, è tardi, quindi andiamo a letto.`,
          },
          {
            type: 'N',
            score: 5,
            content: `Mi domanderei perché all'improvviso vuoi dormire? Sei annoiato di me?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'Come reagireste se mangiaste un cibo molto delizioso in un ristorante?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'È così delizioso che voglio tornare la prossima volta.',
          },
          {
            type: 'N',
            score: 5,
            content: `E 'così delizioso, vorrei provare a immaginare come fare questo cibo`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'Quando cucinate per il vostro amante, cosa rispondereste se vi chiedessero come avete fatto a renderlo così delizioso?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `Rispondevo: "Ho seguito la ricetta."`,
          },
          {
            type: 'N',
            score: 5,
            content: `Io risponderei: "L'ho fatto con il mio gusto"`,
          },
        ],
      },
      {
        which: 'TF',
        question: `E se un ragazzo per strada vi dicesse che siete il suo compagno ideale e vi chiedesse il numero?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Ne sono felice e mi chiedo cosa gli piaccia di me.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Mi sento bene e immagino come sarebbe uscire con questa persona',
          },
        ],
      },
      {
        which: 'TF',
        question: "Com'ero quando ho iniziato a frequentare qualcuno?",
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Ho molte domande e interesse`,
          },
          {
            type: 'F',
            score: 5,
            content: 'Ho molta empatia e reazioni',
          },
        ],
      },
      {
        which: 'TF',
        question: `Se il tuo amante mi dà da mangiare, cosa mi impressiona?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'Sarei impressionato dalla qualità e dal gusto del cibo',
          },
          {
            type: 'F',
            score: 5,
            content:
              'Mi commuoveva il fatto che il mio amante cucinasse per me',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Cosa comprereste se vi fermaste in un supermercato mentre andate in campeggio con il vostro amante?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `Deciderei in anticipo cosa comprare e comprerei solo ciò di cui abbiamo bisogno.`,
          },
          {
            type: 'P',
            score: 5,
            content: `Penserei a cosa comprare mentre mi guardo intorno al negozio`,
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Dopo aver mangiato cibo consegnato in casa, come si pulisce?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Devo pulire subito.',
          },
          {
            type: 'P',
            score: 5,
            content: 'Faccio una pausa, parlo e pulisco lentamente più tardi',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'Cosa fate quando andate in un ristorante famoso con il vostro amante?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'Preparo e verifico in anticipo il modo più semplice per arrivarci',
          },
          {
            type: 'P',
            score: 5,
            content:
              "All'ultimo minuto, di solito mi sono affrettato a trovare un modo per arrivare lì",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'wksthfleoakdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleIT/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dhlrkdsodb',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleIT/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ekwjdekrka',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleIT/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dyffhfkdlvm',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleIT/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tmdqntk',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleIT/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dkfEMftlswkq',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleIT/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhfemsflxmflqj',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleIT/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'fldortusaksfpq',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleIT/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleIT/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlqehfdl',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleIT/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wkdrldusdo',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleIT/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Whfqh',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleIT/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gjekd',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleIT/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dnjzjghfflr',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleIT/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ajdEoflsms',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleIT/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sowjrrhkswhd',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleIT/INFP.jpg',
      },
    ],
  },
  // coupleCharacterFemaleJP
  {
    info: {
      mainTitle: 'カップルキャラクターテスト - 女方,男方',
      subTitle: '私が花びらだったら、果たしてどんな花びらだろう？ - 女方,男方',
      mainUrl: 'coupleCharacterFemaleJP',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'JP',
      category: 'love',
      dualQueries: true,
    },
    questions: [
      {
        which: 'EI',
        question: '恋人とどんなデートをするか決めるとき、私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '主体的で積極的な方',
          },
          {
            type: 'I',
            score: 5,
            content: '恋人の決定に反応して同意する方',
          },
        ],
      },
      {
        which: 'EI',
        question: '自分に近いものは？',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '人といるときも口数が多く、恋人といるときはもっと口数が多い',
          },
          {
            type: 'I',
            score: 5,
            content: '人といるときは無口で、恋人といるときは口数が多い',
          },
        ],
      },
      {
        which: 'EI',
        question: '恋愛初期の恋人と週末に充実したデートをしたとしたら、私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `とても充実した幸せなデートでした（笑）癒されました！`,
          },
          {
            type: 'I',
            score: 5,
            content: `楽しいデートでした（笑）でも、来週の2日のうち1日は休もうかな...？`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '遅くまで恋人との連絡中に、恋人が「もう早く寝よう」と言ったら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `そうだ～、時間が遅くなったけど、もう寝ようかな`,
          },
          {
            type: 'N',
            score: 5,
            content: `なんで急に寝ようって言うんだ？ もしかして俺が面倒くさいのか...？`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '恋人とレストランで食事をするとき、あまりにも美味しかったら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'とても美味しくて、また来たいと思う',
          },
          {
            type: 'N',
            score: 5,
            content: `とても美味しいので、この料理をどうやって作ったのか想像してみる`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '恋人に料理を作ってあげたら、恋人がとても美味しいと言いながら、どうやって作ったのか聞いてきたら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `レシピ通りに作ったと教えてくれる`,
          },
          {
            type: 'N',
            score: 5,
            content: `私は、"自分のセンスで作った "と答えるでしょう`,
          },
        ],
      },
      {
        which: 'TF',
        question: `道すがら、ある異性が私に理想型だと言って番号を聞いたら？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '気分はいいけど、自分のどこが理想的なのか気になる',
          },
          {
            type: 'F',
            score: 5,
            content: '気分が良く、この人と付き合ったらどうなるか想像してみる',
          },
        ],
      },
      {
        which: 'TF',
        question: '恋愛初めの連絡時に私は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `質問や関心が多かった`,
          },
          {
            type: 'F',
            score: 5,
            content: '共感とリアクションが多かった',
          },
        ],
      },
      {
        which: 'TF',
        question: `恋人が私に料理を作ってくれたら、私の感動ポイントは？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '恋人が作った料理のクオリティと味に感動する',
          },
          {
            type: 'F',
            score: 5,
            content: '私のために食べ物を作ってくれたこと自体に感動する',
          },
        ],
      },
      {
        which: 'JP',
        question: '恋人と一緒にキャンプに来て、スーパーに立ち寄るなら？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `買うべきものをあらかじめ決めて、買うべきものだけを買ってキャンプ場に戻る`,
          },
          {
            type: 'P',
            score: 5,
            content: `使う可能性を考えて、あれこれ見て回りながら買って帰る`,
          },
        ],
      },
      {
        which: 'JP',
        question: '恋人と室内で出前を頼んで食べた後の片付けは？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '食べた食べ物はすぐにきれいに片付けなければならない',
          },
          {
            type: 'P',
            score: 5,
            content: '少し休憩して会話しながらゆっくり片付ける',
          },
        ],
      },
      {
        which: 'JP',
        question: '恋人と有名なグルメを食べに行くなら私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '最適なルートと最短時間を探して、早く行くための準備をする',
          },
          {
            type: 'P',
            score: 5,
            content: '行く前に余裕を持って、急いで到着する方法を探してみる',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'wksthfleoakdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dhlrkdsodb',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ekwjdekrka',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dyffhfkdlvm',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tmdqntk',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dkfEMftlswkq',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhfemsflxmflqj',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'fldortusaksfpq',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlqehfdl',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wkdrldusdo',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Whfqh',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gjekd',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dnjzjghfflr',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ajdEoflsms',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sowjrrhkswhd',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleJP/INFP.jpg',
      },
    ],
  },
  // coupleCharacterMaleJP
  {
    info: {
      mainTitle: 'カップルキャラクターテスト - 女方,男方',
      subTitle: '私が花びらだったら、果たしてどんな花びらだろう？ - 女方,男方',
      mainUrl: 'coupleCharacterMaleJP',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'JP',
      category: 'love',
      dualQueries: true,
    },
    questions: [
      {
        which: 'EI',
        question: '恋人とどんなデートをするか決めるとき、私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '主体的で積極的な方',
          },
          {
            type: 'I',
            score: 5,
            content: '恋人の決定に反応して同意する方',
          },
        ],
      },
      {
        which: 'EI',
        question: '自分に近いものは？',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '人といるときも口数が多く、恋人といるときはもっと口数が多い',
          },
          {
            type: 'I',
            score: 5,
            content: '人といるときは無口で、恋人といるときは口数が多い',
          },
        ],
      },
      {
        which: 'EI',
        question: '恋愛初期の恋人と週末に充実したデートをしたとしたら、私は？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `とても充実した幸せなデートでした（笑）癒されました！`,
          },
          {
            type: 'I',
            score: 5,
            content: `楽しいデートでした（笑）でも、来週の2日のうち1日は休もうかな...？`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '遅くまで恋人との連絡中に、恋人が「もう早く寝よう」と言ったら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `そうだ～、時間が遅くなったけど、もう寝ようかな`,
          },
          {
            type: 'N',
            score: 5,
            content: `なんで急に寝ようって言うんだ？ もしかして俺が面倒くさいのか...？`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '恋人とレストランで食事をするとき、あまりにも美味しかったら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'とても美味しくて、また来たいと思う',
          },
          {
            type: 'N',
            score: 5,
            content: `とても美味しいので、この料理をどうやって作ったのか想像してみる`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '恋人に料理を作ってあげたら、恋人がとても美味しいと言いながら、どうやって作ったのか聞いてきたら？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `レシピ通りに作ったと教えてくれる`,
          },
          {
            type: 'N',
            score: 5,
            content: `私は、"自分のセンスで作った "と答えるでしょう`,
          },
        ],
      },
      {
        which: 'TF',
        question: `道すがら、ある異性が私に理想型だと言って番号を聞いたら？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '気分はいいけど、自分のどこが理想的なのか気になる',
          },
          {
            type: 'F',
            score: 5,
            content: '気分が良く、この人と付き合ったらどうなるか想像してみる',
          },
        ],
      },
      {
        which: 'TF',
        question: '恋愛初めの連絡時に私は？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `質問や関心が多かった`,
          },
          {
            type: 'F',
            score: 5,
            content: '共感とリアクションが多かった',
          },
        ],
      },
      {
        which: 'TF',
        question: `恋人が私に料理を作ってくれたら、私の感動ポイントは？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '恋人が作った料理のクオリティと味に感動する',
          },
          {
            type: 'F',
            score: 5,
            content: '私のために食べ物を作ってくれたこと自体に感動する',
          },
        ],
      },
      {
        which: 'JP',
        question: '恋人と一緒にキャンプに来て、スーパーに立ち寄るなら？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `買うべきものをあらかじめ決めて、買うべきものだけを買ってキャンプ場に戻る`,
          },
          {
            type: 'P',
            score: 5,
            content: `使う可能性を考えて、あれこれ見て回りながら買って帰る`,
          },
        ],
      },
      {
        which: 'JP',
        question: '恋人と室内で出前を頼んで食べた後の片付けは？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '食べた食べ物はすぐにきれいに片付けなければならない',
          },
          {
            type: 'P',
            score: 5,
            content: '少し休憩して会話しながらゆっくり片付ける',
          },
        ],
      },
      {
        which: 'JP',
        question: '恋人と有名なグルメを食べに行くなら私は？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '最適なルートと最短時間を探して、早く行くための準備をする',
          },
          {
            type: 'P',
            score: 5,
            content: '行く前に余裕を持って、急いで到着する方法を探してみる',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'wksthfleoakdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleJP/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dhlrkdsodb',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleJP/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ekwjdekrka',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleJP/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dyffhfkdlvm',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleJP/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tmdqntk',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleJP/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dkfEMftlswkq',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleJP/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhfemsflxmflqj',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleJP/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'fldortusaksfpq',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleJP/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleJP/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlqehfdl',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleJP/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wkdrldusdo',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleJP/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Whfqh',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleJP/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gjekd',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleJP/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dnjzjghfflr',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleJP/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ajdEoflsms',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleJP/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sowjrrhkswhd',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleJP/INFP.jpg',
      },
    ],
  },
  // coupleCharacterFemaleCN
  {
    info: {
      mainTitle: '情侣性格测试 - 女方,男方',
      subTitle: '以情侣类型看我在恋爱中的性格是？ - 女方,男方',
      mainUrl: 'coupleCharacterFemaleCN',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'CN',
      category: 'love',
      dualQueries: true,
    },
    questions: [
      {
        which: 'EI',
        question: '和恋人定约会地点时，我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '由我主导并积极选择',
          },
          {
            type: 'I',
            score: 5,
            content: '对恋人的决定做出反应并同意',
          },
        ],
      },
      {
        which: 'EI',
        question: '和我性格相近的是？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '和人们在一起时爱聊天，和恋人在一起时更爱聊天',
          },
          {
            type: 'I',
            score: 5,
            content: '和人们在一起时不爱聊天，和恋人在一起时很爱聊天',
          },
        ],
      },
      {
        which: 'EI',
        question: '如果在恋爱初期，周末两天都在跟恋人约会的话，我会觉得？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `是很充裕而幸福的约会呢，哈哈，治愈完毕`,
          },
          {
            type: 'I',
            score: 5,
            content: `约会还挺有意思的，哈哈，但下周要不要说两天中个想休息一天呢…`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '在和恋人聊到很晚的时候，恋人说“现在该睡觉了”的话，你会觉得？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `是啊~已经很晚了，是该睡觉了`,
          },
          {
            type: 'N',
            score: 5,
            content: `为什么突然说要睡觉呢？该不会觉得我烦吧..`,
          },
        ],
      },
      {
        which: 'SN',
        question: '和恋人在餐厅吃饭，觉得食物特别好吃的话？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '觉得很好吃，想下次再来',
          },
          {
            type: 'N',
            score: 5,
            content: `觉得很好吃，还会想象这个东西是怎么做的`,
          },
        ],
      },
      {
        which: 'SN',
        question: '给恋人做了一顿饭，恋人觉得很好吃，问是怎么做的的话？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `会说按食谱做的`,
          },
          {
            type: 'N',
            score: 5,
            content: `会说是因为我亲手做的`,
          },
        ],
      },
      {
        which: 'TF',
        question: `走在路上，有异性过来说我是理想型，还问我的联系方式的话？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '觉得开心，但好奇那一部分算理想型',
          },
          {
            type: 'F',
            score: 5,
            content: '觉得开心，还会想象和这个人恋爱会怎么样',
          },
        ],
      },
      {
        which: 'TF',
        question: '在恋爱初期，联系的时候，我？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `问问题和关心居多`,
          },
          {
            type: 'F',
            score: 5,
            content: '共情和做反应居多',
          },
        ],
      },
      {
        which: 'TF',
        question: `和如果恋人给我做一顿饭，我会感动的点是？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '会因为恋人食物的品质与味道感动',
          },
          {
            type: 'F',
            score: 5,
            content: '会因为为我做一顿饭本身感动',
          },
        ],
      },
      {
        which: 'JP',
        question: '和恋人一起去露营，需要去超市的话？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `提前定好需要购买的东西，买好就回露营地`,
          },
          {
            type: 'P',
            score: 5,
            content: `想一想功能，逛一逛超市，然后买完回露营地`,
          },
        ],
      },
      {
        which: 'JP',
        question: '和恋人在室内点餐，需要整理的时候，我通常会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '吃过的东西要马上整理干净',
          },
          {
            type: 'P',
            score: 5,
            content: '先休息一会儿，聊聊天之后慢慢整理',
          },
        ],
      },
      {
        which: 'JP',
        question: '打算和恋人去网红店，我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '搜索最合适的方法及所需最短时间，为最短时间到而做准备',
          },
          {
            type: 'P',
            score: 5,
            content: '出发前会慢悠悠的准备，然后急忙搜索到达的方法',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'wksthfleoakdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleCN/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dhlrkdsodb',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleCN/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ekwjdekrka',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleCN/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dyffhfkdlvm',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleCN/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tmdqntk',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleCN/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dkfEMftlswkq',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleCN/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhfemsflxmflqj',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleCN/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'fldortusaksfpq',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleCN/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleCN/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlqehfdl',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleCN/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wkdrldusdo',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleCN/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Whfqh',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleCN/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gjekd',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleCN/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dnjzjghfflr',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleCN/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ajdEoflsms',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleCN/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sowjrrhkswhd',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleCN/INFP.jpg',
      },
    ],
  },
  // coupleCharacterMaleCN
  {
    info: {
      mainTitle: '情侣性格测试 - 女方,男方',
      subTitle: '以情侣类型看我在恋爱中的性格是？ - 女方,男方',
      mainUrl: 'coupleCharacterMaleCN',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'CN',
      category: 'love',
      dualQueries: true,
    },
    questions: [
      {
        which: 'EI',
        question: '和恋人定约会地点时，我会？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '由我主导并积极选择',
          },
          {
            type: 'I',
            score: 5,
            content: '对恋人的决定做出反应并同意',
          },
        ],
      },
      {
        which: 'EI',
        question: '和我性格相近的是？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '和人们在一起时爱聊天，和恋人在一起时更爱聊天',
          },
          {
            type: 'I',
            score: 5,
            content: '和人们在一起时不爱聊天，和恋人在一起时很爱聊天',
          },
        ],
      },
      {
        which: 'EI',
        question: '如果在恋爱初期，周末两天都在跟恋人约会的话，我会觉得？',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `是很充裕而幸福的约会呢，哈哈，治愈完毕`,
          },
          {
            type: 'I',
            score: 5,
            content: `约会还挺有意思的，哈哈，但下周要不要说两天中个想休息一天呢…`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '在和恋人聊到很晚的时候，恋人说“现在该睡觉了”的话，你会觉得？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `是啊~已经很晚了，是该睡觉了`,
          },
          {
            type: 'N',
            score: 5,
            content: `为什么突然说要睡觉呢？该不会觉得我烦吧..`,
          },
        ],
      },
      {
        which: 'SN',
        question: '和恋人在餐厅吃饭，觉得食物特别好吃的话？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '觉得很好吃，想下次再来',
          },
          {
            type: 'N',
            score: 5,
            content: `觉得很好吃，还会想象这个东西是怎么做的`,
          },
        ],
      },
      {
        which: 'SN',
        question: '给恋人做了一顿饭，恋人觉得很好吃，问是怎么做的的话？',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `会说按食谱做的`,
          },
          {
            type: 'N',
            score: 5,
            content: `会说是因为我亲手做的`,
          },
        ],
      },
      {
        which: 'TF',
        question: `走在路上，有异性过来说我是理想型，还问我的联系方式的话？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '觉得开心，但好奇那一部分算理想型',
          },
          {
            type: 'F',
            score: 5,
            content: '觉得开心，还会想象和这个人恋爱会怎么样',
          },
        ],
      },
      {
        which: 'TF',
        question: '在恋爱初期，联系的时候，我？',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `问问题和关心居多`,
          },
          {
            type: 'F',
            score: 5,
            content: '共情和做反应居多',
          },
        ],
      },
      {
        which: 'TF',
        question: `和如果恋人给我做一顿饭，我会感动的点是？`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '会因为恋人食物的品质与味道感动',
          },
          {
            type: 'F',
            score: 5,
            content: '会因为为我做一顿饭本身感动',
          },
        ],
      },
      {
        which: 'JP',
        question: '和恋人一起去露营，需要去超市的话？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `提前定好需要购买的东西，买好就回露营地`,
          },
          {
            type: 'P',
            score: 5,
            content: `想一想功能，逛一逛超市，然后买完回露营地`,
          },
        ],
      },
      {
        which: 'JP',
        question: '和恋人在室内点餐，需要整理的时候，我通常会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '吃过的东西要马上整理干净',
          },
          {
            type: 'P',
            score: 5,
            content: '先休息一会儿，聊聊天之后慢慢整理',
          },
        ],
      },
      {
        which: 'JP',
        question: '打算和恋人去网红店，我会？',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '搜索最合适的方法及所需最短时间，为最短时间到而做准备',
          },
          {
            type: 'P',
            score: 5,
            content: '出发前会慢悠悠的准备，然后急忙搜索到达的方法',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'wksthfleoakdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleCN/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dhlrkdsodb',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleCN/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ekwjdekrka',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleCN/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dyffhfkdlvm',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleCN/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tmdqntk',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleCN/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dkfEMftlswkq',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleCN/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhfemsflxmflqj',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleCN/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'fldortusaksfpq',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleCN/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleCN/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlqehfdl',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleCN/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wkdrldusdo',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleCN/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Whfqh',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleCN/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gjekd',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleCN/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dnjzjghfflr',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleCN/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ajdEoflsms',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleCN/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sowjrrhkswhd',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleCN/INFP.jpg',
      },
    ],
  },
  // coupleCharacterFemaleEng
  {
    info: {
      mainTitle: 'Couple character test - Male | Female',
      subTitle: 'Your dating personality as couple character? - Male | Female',
      mainUrl: 'coupleCharacterFemaleEng',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Eng',
      category: 'love',
      dualQueries: true,
    },
    questions: [
      {
        which: 'EI',
        question: 'When you go a date with your lover, how do you do?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'I am very initiative and proactive in deciding where to go and what to do.',
          },
          {
            type: 'I',
            score: 5,
            content:
              'I am very initiative and proactive in deciding where to go and what to do.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Which one is closest to you?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              "I talk a lot when I'm with people and even more when I'm with my lover.",
          },
          {
            type: 'I',
            score: 5,
            content:
              "I talk a lot when I'm with my lover, but not when I'm with someone else.",
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What would be your reaction if you went on a fully planned date with your lover on a weekend in the early days of your relationship?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `It was a very satisfying and happy date. It's totally healing me!`,
          },
          {
            type: 'I',
            score: 5,
            content: `It was a funny date, haha. But, should I ask for a day off next week?`,
          },
        ],
      },
      {
        which: 'SN',
        question: `If you were talking on the phone, your lover said, "It's time to sleep," how would you react?`,
        answers: [
          {
            type: 'S',
            score: 2,
            content: `That's right~ It's late, so let's go to bed now.`,
          },
          {
            type: 'N',
            score: 5,
            content: `I would question myself why do you want to sleep all of a sudden? Are you bored of me?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'How would you react if you ate very delicious food at a restaurant?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: "It's so delicious that I want to come again next time.",
          },
          {
            type: 'N',
            score: 5,
            content: `It’s so delicious, I would try to imagine how to make this food`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'When cooking for your lover, what would be your answer if asked how you made it so delicious?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I would reply, "I followed the recipe."`,
          },
          {
            type: 'N',
            score: 5,
            content: `I would reply, "I made it with my own taste”.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `How would you react if someone asked for your phone number by saying "I like you" on the street?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'I feel happy about it and wonder what he/she likes about me',
          },
          {
            type: 'F',
            score: 5,
            content:
              'I feel good and imagine what it would be like to go out with this person',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What do you do when you contact someone in the beginning of a relationship?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I have a lot of questions and interest`,
          },
          {
            type: 'F',
            score: 5,
            content: 'I have a lot of empathy and reactions',
          },
        ],
      },
      {
        which: 'TF',
        question: `How would you react if your lover cooked food for you?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'I would be impressed by the quality and the taste of food.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'I would be moved by the fact that my lover cooked food for me.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What would you buy if you stopped by a mart on the way to the camping ground with your lover?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I would decide in advance what to buy and buy only what we need.`,
          },
          {
            type: 'P',
            score: 5,
            content: `I would think about what to buy while looking around the mart.`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'After eating food delivered indoors, how do you clean up?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I have to clean up right away.',
          },
          {
            type: 'P',
            score: 5,
            content: 'I take a break, talk, and clean up slowly later.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What do you do when you go to a famous restaurant with your lover?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I prepare and check in advance the easiest way to get there.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'At the last minute, I usually rushed to find a way to get there.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'wksthfleoakdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dhlrkdsodb',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ekwjdekrka',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dyffhfkdlvm',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tmdqntk',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dkfEMftlswkq',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhfemsflxmflqj',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'fldortusaksfpq',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlqehfdl',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wkdrldusdo',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Whfqh',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gjekd',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dnjzjghfflr',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ajdEoflsms',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sowjrrhkswhd',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemaleEng/INFP.jpg',
      },
    ],
  },
  // coupleCharacterMaleEng
  {
    info: {
      mainTitle: 'Couple character test - Male | Female',
      subTitle: 'Your dating personality as couple character? - Male | Female',
      mainUrl: 'coupleCharacterMaleEng',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Eng',
      category: 'love',
      dualQueries: true,
    },
    questions: [
      {
        which: 'EI',
        question: 'When you go a date with your lover, how do you do?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'I am very initiative and proactive in deciding where to go and what to do.',
          },
          {
            type: 'I',
            score: 5,
            content:
              'I am very initiative and proactive in deciding where to go and what to do.',
          },
        ],
      },
      {
        which: 'EI',
        question: 'Which one is closest to you?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              "I talk a lot when I'm with people and even more when I'm with my lover.",
          },
          {
            type: 'I',
            score: 5,
            content:
              "I talk a lot when I'm with my lover, but not when I'm with someone else.",
          },
        ],
      },
      {
        which: 'EI',
        question:
          'What would be your reaction if you went on a fully planned date with your lover on a weekend in the early days of your relationship?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `It was a very satisfying and happy date. It's totally healing me!`,
          },
          {
            type: 'I',
            score: 5,
            content: `It was a funny date, haha. But, should I ask for a day off next week?`,
          },
        ],
      },
      {
        which: 'SN',
        question: `If you were talking on the phone, your lover said, "It's time to sleep," how would you react?`,
        answers: [
          {
            type: 'S',
            score: 2,
            content: `That's right~ It's late, so let's go to bed now.`,
          },
          {
            type: 'N',
            score: 5,
            content: `I would question myself why do you want to sleep all of a sudden? Are you bored of me?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'How would you react if you ate very delicious food at a restaurant?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: "It's so delicious that I want to come again next time.",
          },
          {
            type: 'N',
            score: 5,
            content: `It’s so delicious, I would try to imagine how to make this food`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'When cooking for your lover, what would be your answer if asked how you made it so delicious?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I would reply, "I followed the recipe."`,
          },
          {
            type: 'N',
            score: 5,
            content: `I would reply, "I made it with my own taste”.`,
          },
        ],
      },
      {
        which: 'TF',
        question: `How would you react if someone asked for your phone number by saying "I like you" on the street?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'I feel happy about it and wonder what he/she likes about me',
          },
          {
            type: 'F',
            score: 5,
            content:
              'I feel good and imagine what it would be like to go out with this person',
          },
        ],
      },
      {
        which: 'TF',
        question:
          'What do you do when you contact someone in the beginning of a relationship?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I have a lot of questions and interest`,
          },
          {
            type: 'F',
            score: 5,
            content: 'I have a lot of empathy and reactions',
          },
        ],
      },
      {
        which: 'TF',
        question: `How would you react if your lover cooked food for you?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'I would be impressed by the quality and the taste of food.',
          },
          {
            type: 'F',
            score: 5,
            content:
              'I would be moved by the fact that my lover cooked food for me.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What would you buy if you stopped by a mart on the way to the camping ground with your lover?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I would decide in advance what to buy and buy only what we need.`,
          },
          {
            type: 'P',
            score: 5,
            content: `I would think about what to buy while looking around the mart.`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'After eating food delivered indoors, how do you clean up?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I have to clean up right away.',
          },
          {
            type: 'P',
            score: 5,
            content: 'I take a break, talk, and clean up slowly later.',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'What do you do when you go to a famous restaurant with your lover?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              'I prepare and check in advance the easiest way to get there.',
          },
          {
            type: 'P',
            score: 5,
            content:
              'At the last minute, I usually rushed to find a way to get there.',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'wksthfleoakdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dhlrkdsodb',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ekwjdekrka',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dyffhfkdlvm',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tmdqntk',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dkfEMftlswkq',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhfemsflxmflqj',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'fldortusaksfpq',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlqehfdl',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wkdrldusdo',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Whfqh',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gjekd',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dnjzjghfflr',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ajdEoflsms',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sowjrrhkswhd',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMaleEng/INFP.jpg',
      },
    ],
  },
  // coupleCharacterFemale
  {
    info: {
      mainTitle: '커플 캐릭터 테스트 - 여자편 | 남자편',
      subTitle:
        '커플 캐릭터로 보는 나의 연애 성향은? - 여자편 | 남자편 | 커플 캐릭터 테스트 여자편 남자편',
      mainUrl: 'coupleCharacterFemale',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'love',
      dualQueries: true,
    },
    questions: [
      {
        which: 'EI',
        question: '연인과 어떤 데이트를 할지 정할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '주도적이고 적극적인 편이다',
          },
          {
            type: 'I',
            score: 5,
            content: '연인의 결정에 반응하고 동의하는 편이다',
          },
        ],
      },
      {
        which: 'EI',
        question: '나와 가까운 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '사람들과 있을 때도 말이 많고 연인과 있을 때는 말이 더 많다',
          },
          {
            type: 'I',
            score: 5,
            content:
              '사람들과 있을 때는 말이 별로 없고, 연인과 있을 때 말이 많다',
          },
        ],
      },
      {
        which: 'EI',
        question: '연애 초 연인이랑 주말동안 꽉 찬 데이트를 했다면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `너무 알차고 행복한 데이트였다ㅎㅎ 힐링 완료!`,
          },
          {
            type: 'I',
            score: 5,
            content: `재밌는 데이트였다ㅎㅎ 그래도 다음주 이틀 중 하루는 쉬자고 할까…?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '늦게까지 연인과 연락 중에 연인이 “이제 얼른 자자” 라고 한다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `그래~ 시간이 늦긴 했지 이제 자야겠다`,
          },
          {
            type: 'N',
            score: 5,
            content: `왜 갑자기 자자고 그러지? 혹시 내가 귀찮나..?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인과 식당에서 음식을 먹는데 너무 맛있다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '너무 맛있고 다음에 또 오고 싶다는 생각이 든다',
          },
          {
            type: 'N',
            score: 5,
            content: `너무 맛있는데 이 음식을 어떻게 만들었을 지 상상해 본다`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '연인에게 음식을 해줬는데 연인이 너무 맛있다며 어떻게 만들었냐고 묻는다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `레시피 대로 만들었다고 말해준다`,
          },
          {
            type: 'N',
            score: 5,
            content: `나의 손맛으로 만들었다고 말해준다`,
          },
        ],
      },
      {
        which: 'TF',
        question: `길 가다 어떤 이성이 나에게 이상형이라며 번호를 물어본다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '기분은 좋지만, 내 어디가 이상형인지 궁금하다',
          },
          {
            type: 'F',
            score: 5,
            content: '기분이 좋고, 이 사람이랑 사귀면 어떨지 상상해 본다',
          },
        ],
      },
      {
        which: 'TF',
        question: '연애 초 연락할 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `질문과 관심이 많았다`,
          },
          {
            type: 'F',
            score: 5,
            content: '공감과 리액션이 많았다',
          },
        ],
      },
      {
        which: 'TF',
        question: `연인이 나에게 음식을 해준다면 나의 감동 포인트는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '연인이 만든 음식의 퀄리티와 맛에 감동한다',
          },
          {
            type: 'F',
            score: 5,
            content: '나를 위해 음식을 한 것 자체에 감동한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 함께 캠핑을 와서 마트를 들린다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `사야할 것을 미리 정해서 사야하는 것만 사서 캠핑장으로 돌아간다`,
          },
          {
            type: 'P',
            score: 5,
            content: `써야할 가능성을 생각해서 이것저것 구경해보면서 사서 돌아간다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 실내에서 배달 음식을 시켜 먹고 난 후 정리할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '먹은 음식은 바로바로 깨끗하게 치워야 한다',
          },
          {
            type: 'P',
            score: 5,
            content: '좀 쉬고 대화하다가 천천히 치운다',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 유명 맛집을 가려고 한다면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '최적의 경로와 최단 시간을 찾아 빠르게 가기 위한 준비를 한다',
          },
          {
            type: 'P',
            score: 5,
            content: '가기전까지 여유 부리다가 급하게 도착하는 방법을 찾아본다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'wksthfleoakdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemale/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dhlrkdsodb',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemale/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ekwjdekrka',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemale/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dyffhfkdlvm',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemale/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tmdqntk',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemale/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dkfEMftlswkq',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemale/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhfemsflxmflqj',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemale/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'fldortusaksfpq',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemale/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemale/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlqehfdl',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemale/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wkdrldusdo',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemale/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Whfqh',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemale/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gjekd',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemale/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dnjzjghfflr',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemale/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ajdEoflsms',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemale/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sowjrrhkswhd',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterFemale/INFP.jpg',
      },
    ],
  },
  // coupleCharacterMale
  {
    info: {
      mainTitle: '커플 캐릭터 테스트 - 남자편 | 여자편',
      subTitle:
        '커플 캐릭터로 보는 나의 연애 성향은? - 남자편 | 여자편 | 커플 캐릭터 테스트 남자편 여자편',
      mainUrl: 'coupleCharacterMale',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'love',
      dualQueries: true,
    },
    questions: [
      {
        which: 'EI',
        question: '연인과 어떤 데이트를 할지 정할 때 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '주도적이고 적극적인 편이다',
          },
          {
            type: 'I',
            score: 5,
            content: '연인의 결정에 반응하고 동의하는 편이다',
          },
        ],
      },
      {
        which: 'EI',
        question: '나와 가까운 것은?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              '사람들과 있을 때도 말이 많고 연인과 있을 때는 말이 더 많다',
          },
          {
            type: 'I',
            score: 5,
            content:
              '사람들과 있을 때는 말이 별로 없고, 연인과 있을 때 말이 많다',
          },
        ],
      },
      {
        which: 'EI',
        question: '연애 초 연인이랑 주말동안 꽉 찬 데이트를 했다면 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `너무 알차고 행복한 데이트였다ㅎㅎ 힐링 완료!`,
          },
          {
            type: 'I',
            score: 5,
            content: `재밌는 데이트였다ㅎㅎ 그래도 다음주 이틀 중 하루는 쉬자고 할까…?`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '늦게까지 연인과 연락 중에 연인이 “이제 얼른 자자” 라고 한다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `그래~ 시간이 늦긴 했지 이제 자야겠다`,
          },
          {
            type: 'N',
            score: 5,
            content: `왜 갑자기 자자고 그러지? 혹시 내가 귀찮나..?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '연인과 식당에서 음식을 먹는데 너무 맛있다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '너무 맛있고 다음에 또 오고 싶다는 생각이 든다',
          },
          {
            type: 'N',
            score: 5,
            content: `너무 맛있는데 이 음식을 어떻게 만들었을 지 상상해 본다`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          '연인에게 음식을 해줬는데 연인이 너무 맛있다며 어떻게 만들었냐고 묻는다면?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `레시피 대로 만들었다고 말해준다`,
          },
          {
            type: 'N',
            score: 5,
            content: `나의 손맛으로 만들었다고 말해준다`,
          },
        ],
      },
      {
        which: 'TF',
        question: `길 가다 어떤 이성이 나에게 이상형이라며 번호를 물어본다면?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '기분은 좋지만, 내 어디가 이상형인지 궁금하다',
          },
          {
            type: 'F',
            score: 5,
            content: '기분이 좋고, 이 사람이랑 사귀면 어떨지 상상해 본다',
          },
        ],
      },
      {
        which: 'TF',
        question: '연애 초 연락할 때 나는?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `질문과 관심이 많았다`,
          },
          {
            type: 'F',
            score: 5,
            content: '공감과 리액션이 많았다',
          },
        ],
      },
      {
        which: 'TF',
        question: `연인이 나에게 음식을 해준다면 나의 감동 포인트는?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '연인이 만든 음식의 퀄리티와 맛에 감동한다',
          },
          {
            type: 'F',
            score: 5,
            content: '나를 위해 음식을 한 것 자체에 감동한다',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 함께 캠핑을 와서 마트를 들린다면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `사야할 것을 미리 정해서 사야하는 것만 사서 캠핑장으로 돌아간다`,
          },
          {
            type: 'P',
            score: 5,
            content: `써야할 가능성을 생각해서 이것저것 구경해보면서 사서 돌아간다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 실내에서 배달 음식을 시켜 먹고 난 후 정리할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '먹은 음식은 바로바로 깨끗하게 치워야 한다',
          },
          {
            type: 'P',
            score: 5,
            content: '좀 쉬고 대화하다가 천천히 치운다',
          },
        ],
      },
      {
        which: 'JP',
        question: '연인과 유명 맛집을 가려고 한다면 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content:
              '최적의 경로와 최단 시간을 찾아 빠르게 가기 위한 준비를 한다',
          },
          {
            type: 'P',
            score: 5,
            content: '가기전까지 여유 부리다가 급하게 도착하는 방법을 찾아본다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'wksthfleoakdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMale/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dhlrkdsodb',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMale/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ekwjdekrka',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMale/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dyffhfkdlvm',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMale/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tmdqntk',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMale/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dkfEMftlswkq',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMale/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhfemsflxmflqj',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMale/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'fldortusaksfpq',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMale/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMale/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlqehfdl',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMale/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wkdrldusdo',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMale/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Whfqh',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMale/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gjekd',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMale/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dnjzjghfflr',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMale/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ajdEoflsms',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMale/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sowjrrhkswhd',
        img_src:
          'https://images.ktestone.com/resultImages/coupleCharacterMale/INFP.jpg',
      },
    ],
  },
  // haGenderFemale
  {
    info: {
      mainTitle: '하남자 하여자 테스트 - 남자편 | 여자편',
      subTitle: '나는 과연 몇% 확률로 하남자/하여자 일까? - 남자편 | 여자편',
      mainUrl: 'haGenderFemale',
      scoreType: 'numberScoring',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
      dualQueries: true,
    },
    questions: [
      {
        which: '',
        question: '요거트 먹을 때 뚜껑에 묻은 요거트를 나는?',
        answers: [
          {
            type: '',
            score: 0,
            content: '습관처럼 핥아먹는다',
          },
          {
            type: '',
            score: 1,
            content: '아무 생각없이 쿨 하게 버린다',
          },
          {
            type: '',
            score: 0,
            content: '고민하다가 아까워서 핥아먹는다',
          },
          {
            type: '',
            score: 0,
            content: '안 좋은 성분이 있을 것 같아서 버린다',
          },
        ],
      },
      {
        which: '',
        question: '눈물 나게 매운 음식을 먹는다면?',
        answers: [
          {
            type: '',
            score: 0,
            content: '우유를 찾는다',
          },
          {
            type: '',
            score: 1,
            content: '중독성 있어서 오히려 좋다 이겨낸다',
          },
          {
            type: '',
            score: 0,
            content: '물을 찾는다',
          },
          {
            type: '',
            score: 0,
            content: '입에다가 찬물을 갖다 댄다',
          },
        ],
      },
      {
        which: 'EI',
        question: '가을(9월~11월)과 봄(3월~6월)에 나는?',
        answers: [
          {
            type: '',
            score: 0,
            content: '에어컨과 히터를 사용한다',
          },
          {
            type: '',
            score: 1,
            content: '에어컨과 히터는 쳐다도 안 본다',
          },
          {
            type: '',
            score: 0,
            content: '선풍기와 전기난로를 사용한다',
          },
          {
            type: '',
            score: 0,
            content: '상황에 따라 에어컨과 히터를 사용한다 ',
          },
        ],
      },
      {
        which: 'EI',
        question: '전에 만나던 X에게 전화가 온다면?',
        answers: [
          {
            type: '',
            score: 0,
            content: '무슨 말 할지 궁금해서 받는다',
          },
          {
            type: '',
            score: 1,
            content: '진작에 차단해서 전화 올 일 없다',
          },
          {
            type: '',
            score: 0,
            content: '받아서 욕을 하거나 화를 낸다',
          },
          {
            type: '',
            score: 0,
            content: '모솔이라 해당 사항 없다',
          },
        ],
      },
      {
        which: 'EI',
        question: '상대방에게는 곤란하지만 하고 싶은 말이 있을 때 나는?',
        answers: [
          {
            type: '',
            score: 0,
            content: '돌려서 말한다',
          },
          {
            type: '',
            score: 1,
            content: '바로 말한다',
          },
          {
            type: '',
            score: 0,
            content: '그냥 말하지 않는다',
          },
          {
            type: '',
            score: 0,
            content: '고민하다가 나중에 말한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '나는 불의를 본다면?',
        answers: [
          {
            type: '',
            score: 0,
            content: '쳐다는 보지만 그냥 지나친다',
          },
          {
            type: '',
            score: 1,
            content: '못 참지! 바로 참교육하러 간다',
          },
          {
            type: '',
            score: 0,
            content: '괜히 불똥 튈 까봐 몸을 사린다',
          },
          {
            type: '',
            score: 0,
            content: '도망치듯 지나간다',
          },
        ],
      },
      {
        which: 'EI',
        question: '거울을 봤을 때 나는?',
        answers: [
          {
            type: '',
            score: 0,
            content: '솔직히 평타는 치는 것 같다',
          },
          {
            type: '',
            score: 1,
            content: '세상에서 내가 제일 예쁘고 잘 생겼다',
          },
          {
            type: '',
            score: 0,
            content: '나는 조금 못난 것 같다',
          },
          {
            type: '',
            score: 0,
            content: '솔직히 평타 이상인 것 같다',
          },
        ],
      },
      {
        which: 'EI',
        question: '내가 놀라는 소리는?',
        answers: [
          {
            type: '',
            score: 0,
            content: '풍선이 터지는 소리',
          },
          {
            type: '',
            score: 1,
            content: '친구의 ㄱ소리',
          },
          {
            type: '',
            score: 0,
            content: '사람 비명소리',
          },
          {
            type: '',
            score: 0,
            content: '총소리',
          },
        ],
      },
      {
        which: 'EI',
        question: '톡을 보냈는데 오타가 났다면?',
        answers: [
          {
            type: '',
            score: 0,
            content: '삭제하고 다시 적는다',
          },
          {
            type: '',
            score: 1,
            content: '그대로 놔둔다 ',
          },
          {
            type: '',
            score: 0,
            content: '미안하다고 사과하고 다시 적는다',
          },
          {
            type: '',
            score: 0,
            content: '오타 난 부분만 따로 적어 놓는다',
          },
        ],
      },
      {
        which: 'EI',
        question: '부모님에게 혼난 후 나의 행동은?',
        answers: [
          {
            type: '',
            score: 0,
            content: '방문 세게 닫음',
          },
          {
            type: '',
            score: 1,
            content: '그냥 아무렇지 않게 내 할거함',
          },
          {
            type: '',
            score: 0,
            content: '집 나감',
          },
          {
            type: '',
            score: 0,
            content: '씩씩댐',
          },
        ],
      },
    ],
    results: [
      {
        type: '',
        desc: ``,
        query: 'rntlqrn',
        img_src:
          'https://images.ktestone.com/resultImages/haGenderFemale/1.jpg',
      },
      {
        type: '',
        desc: ``,
        query: 'vkftlq',
        img_src:
          'https://images.ktestone.com/resultImages/haGenderFemale/2.jpg',
      },
      {
        type: '',
        desc: ``,
        query: 'dbrtlqdh',
        img_src:
          'https://images.ktestone.com/resultImages/haGenderFemale/3.jpg',
      },
      {
        type: '',
        desc: ``,
        query: 'dltlqdh',
        img_src:
          'https://images.ktestone.com/resultImages/haGenderFemale/4.jpg',
      },
      {
        type: '',
        desc: ``,
        query: 'dlfvmfh',
        img_src:
          'https://images.ktestone.com/resultImages/haGenderFemale/5.jpg',
      },
    ],
  },
  // haGenderMale
  {
    info: {
      mainTitle: '하남자 하여자 테스트 - 남자편 | 여자편',
      subTitle: '나는 과연 몇% 확률로 하남자/하여자 일까? - 남자편 | 여자편',
      mainUrl: 'haGenderMale',
      scoreType: 'numberScoring',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'characteristic',
      dualQueries: true,
    },
    questions: [
      {
        which: '',
        question: '요거트 먹을 때 뚜껑에 묻은 요거트를 나는?',
        answers: [
          {
            type: '',
            score: 0,
            content: '습관처럼 핥아먹는다',
          },
          {
            type: '',
            score: 1,
            content: '아무 생각없이 쿨 하게 버린다',
          },
          {
            type: '',
            score: 0,
            content: '고민하다가 아까워서 핥아먹는다',
          },
          {
            type: '',
            score: 0,
            content: '안 좋은 성분이 있을 것 같아서 버린다',
          },
        ],
      },
      {
        which: '',
        question: '눈물 나게 매운 음식을 먹는다면?',
        answers: [
          {
            type: '',
            score: 0,
            content: '우유를 찾는다',
          },
          {
            type: '',
            score: 1,
            content: '중독성 있어서 오히려 좋다 이겨낸다',
          },
          {
            type: '',
            score: 0,
            content: '물을 찾는다',
          },
          {
            type: '',
            score: 0,
            content: '입에다가 찬물을 갖다 댄다',
          },
        ],
      },
      {
        which: 'EI',
        question: '가을(9월~11월)과 봄(3월~6월)에 나는?',
        answers: [
          {
            type: '',
            score: 0,
            content: '에어컨과 히터를 사용한다',
          },
          {
            type: '',
            score: 1,
            content: '에어컨과 히터는 쳐다도 안 본다',
          },
          {
            type: '',
            score: 0,
            content: '선풍기와 전기난로를 사용한다',
          },
          {
            type: '',
            score: 0,
            content: '상황에 따라 에어컨과 히터를 사용한다 ',
          },
        ],
      },
      {
        which: 'EI',
        question: '전에 만나던 X에게 전화가 온다면?',
        answers: [
          {
            type: '',
            score: 0,
            content: '무슨 말 할지 궁금해서 받는다',
          },
          {
            type: '',
            score: 1,
            content: '진작에 차단해서 전화 올 일 없다',
          },
          {
            type: '',
            score: 0,
            content: '받아서 욕을 하거나 화를 낸다',
          },
          {
            type: '',
            score: 0,
            content: '모솔이라 해당 사항 없다',
          },
        ],
      },
      {
        which: 'EI',
        question: '상대방에게는 곤란하지만 하고 싶은 말이 있을 때 나는?',
        answers: [
          {
            type: '',
            score: 0,
            content: '돌려서 말한다',
          },
          {
            type: '',
            score: 1,
            content: '바로 말한다',
          },
          {
            type: '',
            score: 0,
            content: '그냥 말하지 않는다',
          },
          {
            type: '',
            score: 0,
            content: '고민하다가 나중에 말한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '나는 불의를 본다면?',
        answers: [
          {
            type: '',
            score: 0,
            content: '쳐다는 보지만 그냥 지나친다',
          },
          {
            type: '',
            score: 1,
            content: '못 참지! 바로 참교육하러 간다',
          },
          {
            type: '',
            score: 0,
            content: '괜히 불똥 튈 까봐 몸을 사린다',
          },
          {
            type: '',
            score: 0,
            content: '도망치듯 지나간다',
          },
        ],
      },
      {
        which: 'EI',
        question: '거울을 봤을 때 나는?',
        answers: [
          {
            type: '',
            score: 0,
            content: '솔직히 평타는 치는 것 같다',
          },
          {
            type: '',
            score: 1,
            content: '세상에서 내가 제일 예쁘고 잘 생겼다',
          },
          {
            type: '',
            score: 0,
            content: '나는 조금 못난 것 같다',
          },
          {
            type: '',
            score: 0,
            content: '솔직히 평타 이상인 것 같다',
          },
        ],
      },
      {
        which: 'EI',
        question: '내가 놀라는 소리는?',
        answers: [
          {
            type: '',
            score: 0,
            content: '풍선이 터지는 소리',
          },
          {
            type: '',
            score: 1,
            content: '친구의 ㄱ소리',
          },
          {
            type: '',
            score: 0,
            content: '사람 비명소리',
          },
          {
            type: '',
            score: 0,
            content: '총소리',
          },
        ],
      },
      {
        which: 'EI',
        question: '톡을 보냈는데 오타가 났다면?',
        answers: [
          {
            type: '',
            score: 0,
            content: '삭제하고 다시 적는다',
          },
          {
            type: '',
            score: 1,
            content: '그대로 놔둔다 ',
          },
          {
            type: '',
            score: 0,
            content: '미안하다고 사과하고 다시 적는다',
          },
          {
            type: '',
            score: 0,
            content: '오타 난 부분만 따로 적어 놓는다',
          },
        ],
      },
      {
        which: 'EI',
        question: '부모님에게 혼난 후 나의 행동은?',
        answers: [
          {
            type: '',
            score: 0,
            content: '방문 세게 닫음',
          },
          {
            type: '',
            score: 1,
            content: '그냥 아무렇지 않게 내 할거함',
          },
          {
            type: '',
            score: 0,
            content: '집 나감',
          },
          {
            type: '',
            score: 0,
            content: '씩씩댐',
          },
        ],
      },
    ],
    results: [
      {
        type: '',
        desc: ``,
        query: 'rntlqrn',
        img_src: 'https://images.ktestone.com/resultImages/haGenderMale/1.jpg',
      },
      {
        type: '',
        desc: ``,
        query: 'vkftlq',
        img_src: 'https://images.ktestone.com/resultImages/haGenderMale/2.jpg',
      },
      {
        type: '',
        desc: ``,
        query: 'dbrtlqdh',
        img_src: 'https://images.ktestone.com/resultImages/haGenderMale/3.jpg',
      },
      {
        type: '',
        desc: ``,
        query: 'dltlqdh',
        img_src: 'https://images.ktestone.com/resultImages/haGenderMale/4.jpg',
      },
      {
        type: '',
        desc: ``,
        query: 'dlfvmfh',
        img_src: 'https://images.ktestone.com/resultImages/haGenderMale/5.jpg',
      },
    ],
  },
  // maleFemaleCharMale
  {
    info: {
      mainTitle: '남녀 성격 풀이법 테스트 - 남자편 | 여자편',
      subTitle: '내 성격 풀이법을 알려줄게! - 남자편 | 여자편',
      mainUrl: 'maleFemaleCharMale',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'love',
      dualQueries: true,
    },
    questions: [
      {
        which: 'EI',
        question: '나는 친구들과의 모임에 나가면?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '더 놀자고 조르는 편',
          },
          {
            type: 'I',
            score: 5,
            content: '이제 그만 가고 싶다고 눈치 보는 편',
          },
        ],
      },
      {
        which: 'EI',
        question: '평소에 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '행동이 먼저 앞서서 충분히 생각할 시간이 없다',
          },
          {
            type: 'I',
            score: 5,
            content: '생각을 많이 해서 행동으로 재빨리 옮기지 못한다',
          },
        ],
      },
      {
        which: 'EI',
        question: '그렇게 친하지 않은 지인이 나에게 팔짱을 꼈다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `어머 뭐야 오늘부터 우린 절친?`,
          },
          {
            type: 'I',
            score: 5,
            content: `불편해.. 숨 막히게 어색하다.. 빼줬으면..`,
          },
        ],
      },
      {
        which: 'SN',
        question: '인터넷으로 옷을 구매할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `정확한 사이즈 표를 보고 후기를 꼼꼼하게 보고 구매`,
          },
          {
            type: 'N',
            score: 5,
            content: `그 옷을 입어본 나를 상상하며 잘 어울릴 것 같은 느낌이 들면 구매`,
          },
        ],
      },
      {
        which: 'SN',
        question: '눈앞에 광활하고 아름다운 자연 풍경의 그림이 있다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '예쁘다 잘 그렸네',
          },
          {
            type: 'N',
            score: 5,
            content: `자연 속에 들어가 있는 듯한 벅찬 느낌이야`,
          },
        ],
      },
      {
        which: 'SN',
        question: '내가 무언가를 기억할 때?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `실제 일어난 사건을 사진 장면처럼 기억한다`,
          },
          {
            type: 'N',
            score: 5,
            content: `그 상황에 대해 내가 해석한 대로 사건을 기억한다`,
          },
        ],
      },
      {
        which: 'TF',
        question: `나는 평소에 행동을?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              '주변에서 나를 어떻게 보던지 상관없기 때문에 원하는 대로 행동한다',
          },
          {
            type: 'F',
            score: 5,
            content:
              '주변에서 나를 어떻게 생각할지 신경 쓰여서 조심히 행동한다',
          },
        ],
      },
      {
        which: 'TF',
        question: '남친이 아프다며 계속 걱정하고 있는 친구!',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `감기? 병원 가라 그래~`,
          },
          {
            type: 'F',
            score: 5,
            content: '걱정되겠다.. 남친한테 가봐야 하는 거 아냐?',
          },
        ],
      },
      {
        which: 'TF',
        question: `열심히 모은 적금이 만기 되었다!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '적금이 만기 되었다는 결과가 너무 뿌듯함',
          },
          {
            type: 'F',
            score: 5,
            content: '열심이 돈을 모았던 과정이 너무 뿌듯함',
          },
        ],
      },
      {
        which: 'JP',
        question: '동생이 내 옷을 빌려 입고 아무 자리에나 두었다!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `빌려 입어 놓고 원래 있던 제자리에 왜 안 두는지 이해가 안 간다.`,
          },
          {
            type: 'P',
            score: 5,
            content: `정해진 자리는 딱히 없었기 때문에 상관없다`,
          },
        ],
      },
      {
        which: 'JP',
        question: '나는 약속이 끝나고 집에 가면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '옷, 가방 정리 등 할 일 먼저 하고 쉼',
          },
          {
            type: 'P',
            score: 5,
            content: '내일 치운다는 생각으로 일단 다 던져놓고 씻고 쉼',
          },
        ],
      },
      {
        which: 'JP',
        question: '시간별로 꽉 찬 계획표를 보면?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '마음이 편안하고 안정된다',
          },
          {
            type: 'P',
            score: 5,
            content: '숨이 턱턱 막히고 이걸 어떻게 지키나 싶다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'wksthfleoakdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemale/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dhlrkdsodb',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemale/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ekwjdekrka',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemale/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dyffhfkdlvm',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemale/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tmdqntk',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemale/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dkfEMftlswkq',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemale/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhfemsflxmflqj',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemale/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'fldortusaksfpq',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemale/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemale/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlqehfdl',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemale/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wkdrldusdo',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemale/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Whfqh',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemale/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gjekd',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemale/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dnjzjghfflr',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemale/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ajdEoflsms',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemale/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sowjrrhkswhd',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemale/INFP.jpg',
      },
    ],
  },
  // maleFemaleCharFemale
  {
    info: {
      mainTitle: '남녀 성격 풀이법 테스트 - 남자편 | 여자편',
      subTitle: '내 성격 풀이법을 알려줄게! - 남자편 | 여자편',
      mainUrl: 'maleFemaleCharFemale',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Kor',
      category: 'love',
      dualQueries: true,
    },
    questions: [
      {
        which: 'EI',
        question: '나에게 큰 고민이 생겨 생각을 정리하고 싶다!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '누군가에게 털어놓으며 생각을 정리',
          },
          {
            type: 'I',
            score: 5,
            content: '혼자만의 공간에서 조용히 생각을 정리',
          },
        ],
      },
      {
        which: 'EI',
        question: '사람이 많은 모임에서 나는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: '주목받고 싶어서 리액션 크게 함',
          },
          {
            type: 'I',
            score: 5,
            content: '눈에 띄고 싶지 않아서 조용히 있음',
          },
        ],
      },
      {
        which: 'EI',
        question: '내가 원하는 대인관계는?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `다양하고 폭넓은 관계`,
          },
          {
            type: 'I',
            score: 5,
            content: `밀접하고 친밀한 관계`,
          },
        ],
      },
      {
        which: 'SN',
        question: '엄청 비싸고 좋은 차가 지나간다!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `차 엄청 좋네.. 부럽다 비싸겠지`,
          },
          {
            type: 'N',
            score: 5,
            content: `저런 차는 모는 사람은 직업이 도대체 뭘까?`,
          },
        ],
      },
      {
        which: 'SN',
        question: '어떤 일을 할 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: '일정한 속도로 일한다',
          },
          {
            type: 'N',
            score: 5,
            content: `폭발적인 에너지로 일한다`,
          },
        ],
      },
      {
        which: 'SN',
        question: '판타지 영화를 볼 때 나는?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `스토리 몰입된다 배우가 여신이네`,
          },
          {
            type: 'N',
            score: 5,
            content: `저런 연출은 초록색 쫄쫄이 입고 연기하는 건가?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `여친이 이별을 통보했다며 울음을 보이는 친구!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '뭘 남자가 그런 거로 울고 그럼? 이겨내!!',
          },
          {
            type: 'F',
            score: 5,
            content: '왜 울고 그러냐.. 다시 연락 올 수도 있지 괜찮아!',
          },
        ],
      },
      {
        which: 'TF',
        question: '나는 주변 사람들에게?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `관심이 없고 별로 신경 쓰지 않는다.`,
          },
          {
            type: 'F',
            score: 5,
            content: '관심이 많고 신경 쓰인다.',
          },
        ],
      },
      {
        which: 'TF',
        question: `지인 중 한 명이 나를 싫어한다는 소리를 들었다.`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: '나를 왜 싫어해?ㅋㅋ',
          },
          {
            type: 'F',
            score: 5,
            content: '내가 뭐.. 기분 나쁘게 한 적 있었나..?',
          },
        ],
      },
      {
        which: 'JP',
        question: '친구들에게 난 어떤 사람일까?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `늦지 말라고 잔소리를 하는 애`,
          },
          {
            type: 'P',
            score: 5,
            content: `맨날 늦는다고 잔소리를 듣는 애`,
          },
        ],
      },
      {
        which: 'JP',
        question: '내가 일을 하면서 만족감을 느낄 때는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '일을 끝낸 것에서 만족',
          },
          {
            type: 'P',
            score: 5,
            content: '일을 시작하는 데서 만족',
          },
        ],
      },
      {
        which: 'JP',
        question: '어떤 행동을 할 때 나는?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: '분명한 목적이 있다',
          },
          {
            type: 'P',
            score: 5,
            content: '뚜렷한 목적은 없이 일단 움직인다',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'wksthfleoakdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMale/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dhlrkdsodb',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMale/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ekwjdekrka',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMale/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dyffhfkdlvm',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMale/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tmdqntk',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMale/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dkfEMftlswkq',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMale/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhfemsflxmflqj',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMale/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'fldortusaksfpq',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMale/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMale/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlqehfdl',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMale/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wkdrldusdo',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMale/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Whfqh',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMale/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gjekd',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMale/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dnjzjghfflr',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMale/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ajdEoflsms',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMale/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sowjrrhkswhd',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMale/INFP.jpg',
      },
    ],
  },
  // maleFemaleCharMaleEng
  {
    info: {
      mainTitle: 'Personality solving method test - Male | Female',
      subTitle: "I'll teach you how to solve my personality! - Male | Female",
      mainUrl: 'maleFemaleCharMaleEng',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Eng',
      category: 'love',
      dualQueries: true,
    },
    questions: [
      {
        which: 'EI',
        question: 'What if I go to a meeting with my friends?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: "I want to play more and I don't want to go home",
          },
          {
            type: 'I',
            score: 5,
            content: 'I want to stop playing and go home',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What about me?',
        answers: [
          {
            type: 'E',
            score: 2,
            content:
              'There is no time to think enough before the action is taken',
          },
          {
            type: 'I',
            score: 5,
            content: "I can't quickly put it into action because I think a lot",
          },
        ],
      },
      {
        which: 'EI',
        question:
          "An acquaintance who wasn't that close put his arm around me!",
        answers: [
          {
            type: 'E',
            score: 2,
            content: `What? We're best friends from today?`,
          },
          {
            type: 'I',
            score: 5,
            content: `It's uncomfortable... It's breathtakingly awkward.. If you took it out...`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What about me when I buy clothes on the Internet?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I look at the exact size table and carefully look at the reviews and buy it`,
          },
          {
            type: 'N',
            score: 5,
            content: `I imagine myself in that outfit and buy it if I feel like it will suit me`,
          },
        ],
      },
      {
        which: 'SN',
        question:
          'There is a well-drawn painting of vast and beautiful natural scenery in front of my eyes!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: "It's pretty. You drew it well",
          },
          {
            type: 'N',
            score: 5,
            content: `I feel overwhelmed as if I'm in nature`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do you do when you have to remember?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `I remember the actual incident as if it were a photo scene`,
          },
          {
            type: 'N',
            score: 5,
            content: `I remember the events as I interpreted the situation`,
          },
        ],
      },
      {
        which: 'TF',
        question: `When I want to do something?`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "I don't care how you see me, so do what I want",
          },
          {
            type: 'F',
            score: 5,
            content:
              "I'm worried about what people around me think of me, so I act carefully",
          },
        ],
      },
      {
        which: 'TF',
        question: 'A friend who keeps worrying that her boyfriend is sick!',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `Cold? Tell him to go to the hospital`,
          },
          {
            type: 'F',
            score: 5,
            content:
              "You must be worried.. Shouldn't you go see your boyfriend?",
          },
        ],
      },
      {
        which: 'TF',
        question: `I saved my money and achieved my goal!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: 'I am proud of the result of achieving my goal',
          },
          {
            type: 'F',
            score: 5,
            content: 'I am proud of the process of saving money hard',
          },
        ],
      },
      {
        which: 'JP',
        question:
          'I borrowed my clothes and put them on and left them anywhere!',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `I don't understand why you don't put it where it was`,
          },
          {
            type: 'P',
            score: 5,
            content: `It doesn't matter much where you put it`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'What about me when I get home after the appointment?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I take a break after I organize my clothes, bags, etc.',
          },
          {
            type: 'P',
            score: 5,
            content:
              "I'm going to take a break with the thought of cleaning up tomorrow",
          },
        ],
      },
      {
        which: 'JP',
        question: "If you look at the schedule that's full by the hour?",
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'I feel at ease and at ease',
          },
          {
            type: 'P',
            score: 5,
            content: "I'm suffocating and I wonder how I can protect this",
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'wksthfleoakdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemaleEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dhlrkdsodb',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemaleEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ekwjdekrka',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemaleEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dyffhfkdlvm',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemaleEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tmdqntk',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemaleEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dkfEMftlswkq',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemaleEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhfemsflxmflqj',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemaleEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'fldortusaksfpq',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemaleEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemaleEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlqehfdl',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemaleEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wkdrldusdo',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemaleEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Whfqh',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemaleEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gjekd',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemaleEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dnjzjghfflr',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemaleEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ajdEoflsms',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemaleEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sowjrrhkswhd',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharFemaleEng/INFP.jpg',
      },
    ],
  },
  // maleFemaleCharFemaleEng
  {
    info: {
      mainTitle: 'Personality solving method test - Male | Female',
      subTitle: "I'll teach you how to solve my personality! - Male | Female",
      mainUrl: 'maleFemaleCharFemaleEng',
      scoreType: 'typeCountingMBTI',
      mainImage: 'https://images.ktestone.com/introImages/kbmti-intro.jpg',
      thumbImage: 'https://images.ktestone.com/main-thumbnail/test-thumb.png',
      horizontalBanner:
        'https://images.ktestone.com/horizontalNewTest/Korea/personalColor.png',
      lang: 'Eng',
      category: 'love',
      dualQueries: true,
    },
    questions: [
      {
        which: 'EI',
        question: 'I have a big problem and want to organize my thoughts!',
        answers: [
          {
            type: 'E',
            score: 2,
            content: "To confide in someone and organize one's thoughts",
          },
          {
            type: 'I',
            score: 5,
            content: 'Organize your thoughts quietly in your own space',
          },
        ],
      },
      {
        which: 'EI',
        question: 'What about me in a crowded meeting?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: 'I wanted to get attention, so I reacted loudly',
          },
          {
            type: 'I',
            score: 5,
            content: "I don't want to stand out, so I'll keep quiet",
          },
        ],
      },
      {
        which: 'EI',
        question: 'What kind of interpersonal relationship do I want?',
        answers: [
          {
            type: 'E',
            score: 2,
            content: `A wide range of relationships with a wide range of people`,
          },
          {
            type: 'I',
            score: 5,
            content: `A close relationship between a few`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'A very expensive and good car is passing by!',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `The car is really nice.. I envy you. It must be expensive`,
          },
          {
            type: 'N',
            score: 5,
            content: `What's your job for a person who drives a car like that?`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What is your style when you work?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: 'Work at a constant speed',
          },
          {
            type: 'N',
            score: 5,
            content: `Work with explosive energy`,
          },
        ],
      },
      {
        which: 'SN',
        question: 'What do you think when you watch fantasy movies?',
        answers: [
          {
            type: 'S',
            score: 2,
            content: `The story is fun and the actor is pretty`,
          },
          {
            type: 'N',
            score: 5,
            content: `What if something like that actually happens?`,
          },
        ],
      },
      {
        which: 'TF',
        question: `A friend who cries because his girlfriend notified him of the breakup!`,
        answers: [
          {
            type: 'T',
            score: 2,
            content:
              'Why would a man cry over something like that? Get over it!',
          },
          {
            type: 'F',
            score: 5,
            content: "Don't cry... You'll hear from me again.. It's okay",
          },
        ],
      },
      {
        which: 'TF',
        question: 'To the people around me?',
        answers: [
          {
            type: 'T',
            score: 2,
            content: `I don't care much`,
          },
          {
            type: 'F',
            score: 5,
            content: 'Be interested in the people around one',
          },
        ],
      },
      {
        which: 'TF',
        question: `I heard that one of my acquaintances hated me`,
        answers: [
          {
            type: 'T',
            score: 2,
            content: "Why don't you like me?LOL ",
          },
          {
            type: 'F',
            score: 5,
            content: "I don't.. Have you ever offended anyone?",
          },
        ],
      },
      {
        which: 'JP',
        question: 'What kind of person am I to my friends?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: `The one who nags me not to be late`,
          },
          {
            type: 'P',
            score: 5,
            content: `The one who always nags me for being late`,
          },
        ],
      },
      {
        which: 'JP',
        question: 'When do you feel satisfied while working?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Satisfied with finishing work',
          },
          {
            type: 'P',
            score: 5,
            content: 'Satisfied with starting work',
          },
        ],
      },
      {
        which: 'JP',
        question: 'When I do something?',
        answers: [
          {
            type: 'J',
            score: 2,
            content: 'Have a clear purpose',
          },
          {
            type: 'P',
            score: 5,
            content: 'It moves for now without a clear purpose',
          },
        ],
      },
    ],
    results: [
      {
        type: 'ESTJ',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'wksthfleoakdhkd',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMaleEng/ESTJ.jpg',
      },
      {
        type: 'ESTP',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'dhlrkdsodb',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMaleEng/ESTP.jpg',
      },
      {
        type: 'ESFJ',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'ekwjdekrka',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMaleEng/ESFJ.jpg',
      },
      {
        type: 'ESFP',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'dyffhfkdlvm',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMaleEng/ESFP.jpg',
      },
      {
        type: 'ENTJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'tmdqntk',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMaleEng/ENTJ.jpg',
      },
      {
        type: 'ENTP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'dkfEMftlswkq',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMaleEng/ENTP.jpg',
      },
      {
        type: 'ENFJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'rhfemsflxmflqj',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMaleEng/ENFJ.jpg',
      },
      {
        type: 'ENFP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'fldortusaksfpq',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMaleEng/ENFP.jpg',
      },
      {
        type: 'ISTJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'Rhseo',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMaleEng/ISTJ.jpg',
      },
      {
        type: 'ISTP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wlqehfdl',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMaleEng/ISTP.jpg',
      },
      {
        type: 'ISFJ',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'wkdrldusdo',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMaleEng/ISFJ.jpg',
      },
      {
        type: 'ISFP',
        desc: `세상 제일 귀엽지만 세상 제일 지랄견인 나는 ‘포메라니안’ \n
                `,
        query: 'Whfqh',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMaleEng/ISFP.jpg',
      },
      {
        type: 'INTJ',
        desc: `작지만 용감한 난 질투쟁이 치와와~ 난 참지 않Z…!\n`,
        query: 'gjekd',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMaleEng/INTJ.jpg',
      },
      {
        type: 'INTP',
        desc: `도비가 부러운 자유로운 영혼인, 웰시코기\n`,
        query: 'dnjzjghfflr',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMaleEng/INTP.jpg',
      },
      {
        type: 'INFJ',
        desc: `친숙한 댕댕이 , 리트리버\n`,
        query: 'ajdEoflsms',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMaleEng/INFJ.jpg',
      },
      {
        type: 'INFP',
        desc: `지치는 게 뭐야? ~~세상 미친 텐션 비글!\n`,
        query: 'sowjrrhkswhd',
        img_src:
          'https://images.ktestone.com/resultImages/maleFemaleCharMaleEng/INFP.jpg',
      },
    ],
  },
];

export const mbti_tests_list = TESTS.map((test) => ({
  mainUrl: test.info.mainUrl,
  from: 'MBTI',
}));

export const dual_qurie_test_list = [
  {
    mainUrl: 'loveCharacterIT',
    lang: 'IT',
  },
  {
    mainUrl: 'loveCharacterES',
    lang: 'ES',
  },
  {
    mainUrl: 'loveCharacterJP',
    lang: 'JP',
  },
  {
    mainUrl: 'loveCharacterGer',
    lang: 'Ger',
  },
  {
    mainUrl: 'loveCharacterCN',
    lang: 'CN',
  },
  {
    mainUrl: 'loveCharacterEng',
    lang: 'Eng',
  },
  {
    mainUrl: 'loveCharacter',
    lang: 'Kor',
  },
  {
    mainUrl: 'coupleCharacter',
    lang: 'Kor',
  },
  {
    mainUrl: 'haGender',
    lang: 'Kor',
  },
  {
    mainUrl: 'maleFemaleChar',
    lang: 'Kor',
  },
  {
    mainUrl: 'coupleCharacterIT',
    lang: 'IT',
  },
  {
    mainUrl: 'coupleCharacterJP',
    lang: 'JP',
  },
  {
    mainUrl: 'coupleCharacterCN',
    lang: 'CN',
  },
  {
    mainUrl: 'coupleCharacterEng',
    lang: 'Eng',
  },
  {
    mainUrl: 'maleFemaleCharEng',
    lang: 'Eng',
  },
];
